import { patchApiRequest } from 'api';

import { MeetingFileData } from '.';

export interface ChangeMeetingFileParams {
    id: number;
    lessonSchedule?: number | null;
    lesson?: number | null;
    useOnMeeting?: boolean;
    isAvailableToStudents?: boolean;
}

export const changeMeetingFile = ({ id, ...params }: ChangeMeetingFileParams): Promise<MeetingFileData> => {
    return patchApiRequest(`/meeting/files/partial-update/${id}/`, params);
};

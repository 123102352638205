import { Parallel } from './parallel';
export interface EduCalendarPlan {
    id: number;
    title: string;
}
export enum EducationalPlanType {
    MAIN = 'main',
    TRIAL_DAYS = 'trial_days',
}

export enum EduCourseType {
    COMMON = 'common',
    NON_SUBJECT = 'non_subject',
    GIA_PREPARATION = 'gia_preparation',
    ELECTIVE = 'elective',
}

export interface EduCourse {
    id: number;
    title: string;
    lessonCount: number;
}

export interface EducationalPlanItem {
    id: number;
    course: EduCourse;
    type: EduCourseType;
    takeIntoAccount: boolean;
    yearLessonsCount: number;
    weekLessonsCount: number;
    yearOnlineLessonsCount: number;
    weekOnlineLessonsCount: number;
    yearFlowLecturesCount: number;
    weekFlowLecturesCount: number;
    evaluationType: string | null;
}

export interface EducationalPlan {
    id: number;
    title: string;
    parallel: Parallel;
    calendarPlan: EduCalendarPlan;
    freeHours?: number;
    minHours?: number;
    type?: EducationalPlanType;
    educationalPlanItems: EducationalPlanItem[];
}

export interface ResponseEducationalPlanItem {
    id: number;
    course: EduCourse;
    type: EduCourseType;
    educationalPlan: number;
    yearLessonsCount: number;
    weekLessonsCount: number;
    yearOnlineLessonsCount: number;
    weekOnlineLessonsCount: number;
    yearFlowLecturesCount: number;
    weekFlowLecturesCount: number;
    evaluationType: string | null;
    takeIntoAccount: boolean;
}

export interface ResponseEducationPlan {
    id: number;
    title: string;
    parallel: Parallel;
    calendarPlan: EduCalendarPlan;
    freeHours?: number;
    minHours?: number;
    type?: EducationalPlanType;
    educationalPlanItems: ResponseEducationalPlanItem[];
}

import React, { useMemo } from 'react';
import { CloseBlue } from '@lms-elements/icons';
import { TScheduleTooltipProps } from 'src-new/ui/schedules/app-day-schedule/AppDaySchedule.types';

import './style.scss';

const SchedulesTooltip = (props: TScheduleTooltipProps): JSX.Element => {
    const { style, arrowPosition, arrowBottom, TooltipContent, onClose } = { ...props };

    const arrowStyle = useMemo(() => {
        if (arrowBottom) {
            return {
                bottom: `${arrowBottom}px`,
            };
        } else {
            return {
                top: '70px',
            };
        }
    }, [arrowBottom]);
    const handleTooltipClose = (
        event: React.MouseEvent<SVGSVGElement, MouseEvent> | React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        event.stopPropagation();
        onClose();
    };
    const handleTooltipClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    };

    return (
        <div className={'schedule-tooltip'}>
            <div className={'schedule-tooltip__overlay'} onClick={(event) => handleTooltipClose(event)} />
            <div className={`schedule-tooltip__body`} style={style} onClick={(event) => handleTooltipClick(event)}>
                <div className={`arrow arrow-${arrowPosition ? arrowPosition : 'none'}`} style={arrowStyle} />
                <CloseBlue
                    className={'schedule-tooltip__body_close-btn'}
                    onClick={(event) => handleTooltipClose(event)}
                />
                <div className={'schedule-tooltip__body_content'}>
                    <TooltipContent />
                </div>
            </div>
        </div>
    );
};

export default SchedulesTooltip;

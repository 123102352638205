import React from 'react';
import { cn } from '@bem-react/classname';

import './Input.scss';

const CnInput = cn('input');

interface IInputProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    error?: boolean;
    as: 'textarea' | 'input';
    className?: string;
}

export const Input: React.FC<IInputProps> = ({ error, as, className, ...props }) => {
    return React.createElement(as, { ...props, className: `${CnInput({ error })} ${className ? className : ''}` });
};

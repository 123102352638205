import i18next from 'i18next';

void i18next.init({
    i18nFormat: {
        localeData: 'ru',
    },
    lng: 'ru',
    debug: true,
    resources: {
        ru: {
            translation: {
                task_0: '{{count}} задание',
                task_1: '{{count}} задания',
                task_2: '{{count}} заданий',

                teacher_0: '{{count}} учитель',
                teacher_1: '{{count}} учителя',
                teacher_2: '{{count}} учителей',

                lesson_0: '{{count}} уроку',
                lesson_1: '{{count}} урокам',
                lesson_2: '{{count}}  урокам',

                lessons_0: '{{count}} урок',
                lessons_1: '{{count}} урока',
                lessons_2: '{{count}} уроков',

                subject_0: '{{count}} предмета',
                subject_1: '{{count}} предметов',
                subject_2: '{{count}} предметов',

                calendarLesson_0: '{{count}} занятие',
                calendarLesson_1: '{{count}} занятия',
                calendarLesson_2: '{{count}} занятий',

                scheduleLesson_0: '{{count}} урок',
                scheduleLesson_1: '{{count}} урока',
                scheduleLesson_2: '{{count}} уроков',

                lessonWithoutCount_0: 'урок',
                lessonWithoutCount_1: 'урока',
                lessonWithoutCount_2: 'уроков',

                meeting_0: '{{count}} собрание',
                meeting_1: '{{count}} собрания',
                meeting_2: '{{count}} собраний',

                hours_0: '{{count}} час',
                hours_1: '{{count}} часа',
                hours_2: '{{count}} часов',

                hoursWithoutCount_0: 'час',
                hoursWithoutCount_1: 'часа',
                hoursWithoutCount_2: 'часов',

                minutesWithoutCount_0: 'минута',
                minutesWithoutCount_1: 'минуты',
                minutesWithoutCount_2: 'минут',

                questions_0: '{{count}} вопрос',
                questions_1: '{{count}} вопроса',
                questions_2: '{{count}} вопросов',

                unpublished_0: '{{count}} не утвержден',
                unpublished_1: '{{count}} не утверждено',
                unpublished_2: '{{count}} не утверждено',

                skill_0: '{{count}} навык',
                skill_1: '{{count}} навыка',
                skill_2: '{{count}} навыков',

                group_0: '{{count}} группа',
                group_1: '{{count}} группы',
                group_2: '{{count}} групп',

                student_0: '{{count}} ученик',
                student_1: '{{count}} ученика',
                student_2: '{{count}} учеников',

                topic_0: '{{count}} тема',
                topic_1: '{{count}} темы',
                topic_2: '{{count}} тем',

                selectedTypes_0: '{{count}} тип выбран',
                selectedTypes_1: '{{count}} типа выбраны',
                selectedTypes_2: '{{count}} типов выбраны',

                correctAnswers_0: '{{count}} правильным ответом',
                correctAnswers_1: '{{count}} правильными ответами',
                correctAnswers_2: '{{count}} правильными ответами',

                variableAnswers_0: '{{count}} вариант',
                variableAnswers_1: '{{count}} варианта',
                variableAnswers_2: '{{count}} вариантов',

                search_0: '{{count}} поиск',
                search_1: '{{count}} поиска',
                search_2: '{{count}} поисков',

                selectedQuestions_0: '{{count}} отобранный вопрос',
                selectedQuestions_1: '{{count}} отобранных вопроса',
                selectedQuestions_2: '{{count}} отобранных вопросов',

                attempt_0: '{{count}} попытка',
                attempt_1: '{{count}} попытки',
                attempt_2: '{{count}} попыток',

                correctAnswer_0: '{{count}} правильный ответ',
                correctAnswer_1: '{{count}} правильных ответа',
                correctAnswer_2: '{{count}} правильных ответов',

                question_0: '{{count}} вопроса',
                question_1: '{{count}} вопросов',
                question_2: '{{count}} вопросов',

                parallel_0: 'параллель',
                parallel_1: 'параллели',
                parallel_2: 'параллелей',

                class_0: 'класс',
                class_1: 'классы',
                class_2: 'классов',

                assignedTask_0: 'для {{count}} задания',
                assignedTask_1: 'для {{count}} заданий',
                assignedTask_2: 'для {{count}} заданий',

                studyWeeks_0: '{{count}} учебная неделя',
                studyWeeks_1: '{{count}} учебные недели',
                studyWeeks_2: '{{count}} учебных недель',

                studyDays_0: '{{count}} учебный день',
                studyDays_1: '{{count}} учебных дня',
                studyDays_2: '{{count}} учебных дней',

                rooms_0: '{{count}} комната',
                rooms_1: '{{count}} комнаты',
                rooms_2: '{{count}} комнат',
            },
        },
    },
});

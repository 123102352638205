import { CodifierElementIconEnum } from '@lms-elements/atomic';
import { ContentMaterialTypeEnum, MaterialType } from 'types/materials';

export const typesOfMaterials: Record<ContentMaterialTypeEnum, MaterialType> = {
    [ContentMaterialTypeEnum.text]: {
        title: 'Текст',
        description: 'Форматирование, формулы',
        type: ContentMaterialTypeEnum.text,
        icon: CodifierElementIconEnum.text,
    },
    [ContentMaterialTypeEnum.table]: {
        title: 'Таблица',
        description: 'Данные, ячейки',
        type: ContentMaterialTypeEnum.table,
        icon: CodifierElementIconEnum.table,
    },
    [ContentMaterialTypeEnum.header]: {
        title: 'Заголовок',
        description: 'Заголовки, текст, формула',
        type: ContentMaterialTypeEnum.header,
        icon: CodifierElementIconEnum.header,
    },
    [ContentMaterialTypeEnum.audio]: {
        title: 'Аудиофайл',
        description: 'Ссылки и файлы',
        type: ContentMaterialTypeEnum.audio,
        icon: CodifierElementIconEnum.audio,
    },
    [ContentMaterialTypeEnum.video]: {
        title: 'Видеофайл',
        description: 'Ссылки и файлы',
        type: ContentMaterialTypeEnum.video,
        icon: CodifierElementIconEnum.video,
    },
    [ContentMaterialTypeEnum.kinescope]: {
        title: 'Видео из Kinescope',
        description: 'Ссылки',
        type: ContentMaterialTypeEnum.kinescope,
        icon: CodifierElementIconEnum.video,
    },
    [ContentMaterialTypeEnum.document]: {
        title: 'Документ - файл',
        description: 'Docx, pdf, pptx',
        type: ContentMaterialTypeEnum.document,
        icon: CodifierElementIconEnum.document,
    },
    [ContentMaterialTypeEnum.photo]: {
        title: 'Изображение',
        description: 'Jpeg, png, bmp, tiff',
        type: ContentMaterialTypeEnum.photo,
        icon: CodifierElementIconEnum.image,
    },
    [ContentMaterialTypeEnum.exercise]: {
        title: 'Упражнение',
        description: 'Автопроверка',
        type: ContentMaterialTypeEnum.exercise,
        icon: CodifierElementIconEnum.exercise,
    },
};

export const getTypeOfMaterials = (types: ContentMaterialTypeEnum[]): MaterialType[] => {
    return types.map((type) => typesOfMaterials[type]);
};

import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { error } from 'store/reducers/error';

const mapDispatchToProps = {
    dispatchError: (payload: { message: string; extra: Record<string, unknown> }) => error(payload),
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
interface IErrorBoundaryProps extends PropsFromRedux {
    children: React.ReactNode;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps> {
    constructor(props: IErrorBoundaryProps) {
        super(props);
    }

    componentDidCatch(e: Error): void {
        this.props.dispatchError({
            message: 'Что-то пошло не так! Попробуйте перезагрузить страницу!',
            extra: { error: e.message },
        });
    }

    render(): React.ReactNode {
        return this.props.children;
    }
}

export const ErrorBounderyWithRedux = connector(ErrorBoundary);

import { getApiRequest } from 'api';
import { CourseType } from 'types/course';
import { Parallel } from 'types/parallel';

export interface CourseDetails {
    id: number;
    title: string;
    description?: string;
    aboutTeacher?: string;
    task?: string;
    preview?: File;
    courseVideo?: File;
    subject: {
        id: number;
        title: string;
    };
    subjectAlterTitle?: string;
    courseType?: CourseType;
    topics: {
        id: number;
        title: string;
    };
    topicsQuantity: number;
    isBlockEdit?: boolean;
    lessonCount: number;
    endToEndGeneralLessonOrdering: number[];
    isPublished: boolean;
    duration?: number;
    parallels?: Parallel[];
}

export const getCourseDetails = (id: number): Promise<CourseDetails> => {
    return getApiRequest(`/course/${id}/`);
};

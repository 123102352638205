import { Parallel } from '@lms-elements/atomic';
import { patchApiRequest } from 'api';

export interface PatchMissedLessonParams {
    id: number;
    recordViewed: boolean;
}

export interface MissedLesson {
    id: number;
    lesson: {
        id: number;
        title: string;
    };
    subject: {
        id: number;
        title: string;
    };
    parallels: Parallel[];
}

export const patchMissedLesson = ({ id, ...missed }: PatchMissedLessonParams): Promise<MissedLesson> => {
    return patchApiRequest(`/missed-lesson/partial-update/${id}/`, missed);
};

import { api, deleteApiRequest } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { IHomeworkItem } from 'types/homework';

export const getStudentTasks = (
    studentId: number,
    lessonId: number,
    isMandatory?: boolean,
): Promise<IHomeworkItem[]> => {
    const url = isMandatory
        ? `/assignment-progress/?student=${studentId}&lesson=${lessonId}&is_mandatory=${isMandatory}`
        : `/assignment-progress/?student=${studentId}&lesson=${lessonId}`;
    return api
        .get<never, AxiosResponse<IHomeworkItem[]>>(url)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

// fetching the student homeworks. Used only for student role;
export const fetchStudentHomeworksByLesson = async (
    lessonId: number,
    isMandatory?: boolean,
): Promise<IHomeworkItem[]> => {
    const url = isMandatory
        ? `/assignment-progress/?lesson=${lessonId}&status=assigned,on_check,completed&is_mandatory=${isMandatory}`
        : `/assignment-progress/?lesson=${lessonId}&status=assigned,on_check,completed`;

    return await api
        .get<never, AxiosResponse<IHomeworkItem[]>>(url)
        .then((res) => res.data || undefined)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const fetchStudentHomeworkById = async (id: number): Promise<IHomeworkItem> => {
    return await api
        .get<never, AxiosResponse<IHomeworkItem>>(`/assignment-progress/${id}/`)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const deleteStudentTask = async (id: number): Promise<number> => {
    return await deleteApiRequest(`/assignment-progress/delete/${id}/`);
};

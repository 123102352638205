import { getApiRequest } from 'api';

import { AttendanceResponseData } from '.';

export const getLessonAttendance = async (params: {
    lessonId?: number;
    lessonIds?: number[];
    courseGroupId?: number;
    studentIds?: number[];
    eventRoom?: number;
}): Promise<AttendanceResponseData[]> => {
    return await getApiRequest(`/attendance-lesson/`, {
        params: {
            lesson: params.lessonIds?.join(',') ?? params.lessonId,
            student_ids: params.studentIds?.join(','),
            course_group: params.courseGroupId,
            event_room: params.eventRoom,
        },
    });
};

import { getApiRequest } from 'api';

import { ScheduleResponseData } from '.';

/**
 *
 * @param start - date 'YYYY-MM-DD'
 * @param end - date 'YYYY-MM-DD'
 * @returns
 */

export const getUserScheduleByDateRange = async (
    userId: number,
    start: string,
    end: string,
    isPublished?: boolean,
): Promise<ScheduleResponseData> => {
    return await getApiRequest(
        `/schedule/user/${userId}/?datetime_start=${start}&datetime_end=${end}${
            isPublished ? '&lesson_is_published=true' : ''
        }`,
    );
};

import { getApiRequest } from 'api';
import { Region } from 'types/reporting';
import { User } from 'types/user';

export interface StudentGroupStatistics {
    id: number;
    title: string;
    region: Region;
    studentsCount: number;
    maxStudentsCount: number;
    blockedStudentsCount: number;
    tariff: number;
    dateCreate: string;
    curators: User[];
}

export const getStudentGroupStatistics = (): Promise<StudentGroupStatistics[]> => {
    return getApiRequest('/student-group/statistics/');
};

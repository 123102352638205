import { getApiRequest } from 'api';
import { CourseGroupExpelledStudents } from 'types/courseGroup';

export const getCourseGroupExpelledStudents = async ({
    courseGroupId,
    lessonId,
}: {
    courseGroupId: number;
    lessonId: number;
}): Promise<CourseGroupExpelledStudents> => {
    return await getApiRequest(`/course-groups/${courseGroupId}/expelled-students/?lesson=${lessonId}`);
};

import { ReactElement } from 'react';
import { AnswerAttemptPostResponse } from 'api/services/answerAttempt';
import moment from 'moment';
import { getCurrentDateByMoscow } from 'src-new/utils';
import { AssignmentProgressStatus } from 'types/assignmentProgress';
import { IHomeworkItem } from 'types/homework';
import { MarkData } from 'types/mark';

interface TabMark extends MarkData {
    valueCanChange: boolean;
    teacherMark?: boolean;
    attemptMarkNumber?: number;
    canDelete?: boolean;
}

interface Tabs {
    id: number;
    studentId: number;
    subjectId: number;
    teacherId?: number;
    title: string;
    passed?: string;
    marks?: TabMark[];
    isSelected?: boolean;
    deadline?: string;
    isStudent?: boolean;
    isPassed: boolean;
    completed?: boolean;
    timeToComplete: number;
    status: AssignmentProgressStatus;
    assignedDate?: string;
    extraInfo?: { IconElement?: ReactElement; label: string };
}

export const createTabs = (
    tasks: IHomeworkItem[],
    data: AnswerAttemptPostResponse[] | null,
    isPrincipal?: boolean,
    isStudent?: boolean,
    teacherId?: number,
): Tabs[] => {
    const tabs: Tabs[] = [];
    tasks.forEach((task) => {
        const teacherMarks: Array<TabMark> = task.teacherMark.map((mark) => ({
            ...mark,
            author: mark.author?.id,
            valueCanChange: Boolean(teacherId && teacherId === mark.author?.id),
            teacherMark: true,
        }));

        const marksToPreview: Array<TabMark> = [];
        if (task.marks) {
            marksToPreview.push(
                ...task.marks.map((mark) => ({
                    ...mark,
                    author: mark?.author?.id,
                    valueCanChange: Boolean(teacherId && teacherId === mark.author?.id),
                    attemptMarkNumber: data?.find((el) => el.mark?.id === mark.id)?.attempt,
                    authorLastName: mark.author?.lastName,
                    authorFirstName: mark.author?.firstName,
                    authorPatronymic: mark.author?.patronymic,
                })),
            );
        }
        marksToPreview.push(...teacherMarks);

        const deadlineForComplete = moment(task.deadlineForComplete);
        const hardDeadline = moment(task.hardDeadline);
        const minDeadline = deadlineForComplete.isSameOrAfter(hardDeadline) ? hardDeadline : deadlineForComplete;
        const currentDate = moment(getCurrentDateByMoscow());
        const isMinDeadlineLeft = currentDate.isAfter(minDeadline);
        const deadline = task.timeToComplete && !isMinDeadlineLeft ? minDeadline.format() : task.deadlineForComplete;

        tabs.push({
            id: Number(task.id),
            title: task.description || task.title || '',
            passed: task.completeDate != null ? task.completeDate : '',
            isPassed: task.status === AssignmentProgressStatus.ON_CHECK,
            completed: task.status === AssignmentProgressStatus.COMPLETED,
            marks: marksToPreview,
            deadline: deadline,
            timeToComplete: task.timeToComplete,
            isSelected: false,
            isStudent,
            teacherId,
            studentId: task.student.id,
            subjectId: task.subject?.id || -1,
            status: task.status,
            assignedDate: task.assignedDate,
            extraInfo: { label: task.type.isMandatory ? 'Обязательное задание' : 'Рекомендованное задание' },
        });
    });
    return tabs;
};

export const isEmptyAttempts = (attempts?: AnswerAttemptPostResponse[] | null): boolean => {
    return attempts?.filter((attemp) => !attemp.answers.every(({ questionId }) => questionId === null)).length === 0;
};

import React from 'react';
import { cn } from '@bem-react/classname';
import { Radio } from 'antd';
import { AppRadio } from 'src-new/ui';

import { IAppRadio } from './AppRadioGroup.types';

const CnAppRadioGroup = cn('app-radio-group');

const AppRadioGroup: React.FC<IAppRadio> = (props) => {
    const { items, selectedValue, onChange } = props;

    return (
        <Radio.Group prefixCls={CnAppRadioGroup()} onChange={onChange} value={selectedValue}>
            {items.map((item, index) => (
                <AppRadio key={`radio.${index}.${item.value}`} item={item} />
            ))}
        </Radio.Group>
    );
};

export default AppRadioGroup;

export enum DropdownActionsFormValuesFields {
    title = 'title',
    codifierValue = 'codifierValue',
    order = 'order',
}

export type DropdownActionsFormValues = {
    [key in keyof typeof DropdownActionsFormValuesFields]?: string;
} & {
    [DropdownActionsFormValuesFields.title]: string;
    [DropdownActionsFormValuesFields.order]: string;
};

export interface DropdownActionsFormProps {
    onChangeAdd: (values: Record<string, DropdownActionsFormValues>) => void;
    onClickCancel: () => void;
    name: string;
    textButtonAdd?: string;
    allCodifierValues?: (string | undefined)[];
    placeholder: string;
    options?: string[];
    isNeedTextField?: boolean;
    needTextarea?: boolean;
    needCodifierValue?: boolean;
    initialValue?: Record<string, DropdownActionsFormValues>;
    title?: string;
    noSkills?: boolean;
    isSection?: boolean;
    needDisableOrder?: boolean;
}

import { useMemo } from 'react';
import { useQuery } from 'hooks/useQuery';
import moment from 'moment';

interface SchedulePeriodParams {
    periodTime: 'day' | 'week' | 'month';
    periodLabel: string;
    currentDate: string;
}

export const useGetSchedulePeriod = (): SchedulePeriodParams => {
    const query = useQuery(); // period: 'month' | 'week' | 'day'; currentDay: string;
    const [periodTime, periodLabel] = useMemo((): ['day' | 'week' | 'month', string] => {
        switch (query.get('period')) {
            case 'month':
                return ['month', 'Месяц'];
            case 'week':
                return ['week', 'Неделя'];
            default:
                return ['day', 'День'];
        }
    }, [query]);
    const currentDate = useMemo(() => query.get('date') || moment().format('YYYY-MM-DD'), [query]);

    return {
        periodTime,
        periodLabel,
        currentDate,
    };
};

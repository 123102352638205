export const findAndReplace = <ArrayItem>(
    array: ArrayItem[],
    findFunction: (item: ArrayItem, index: number) => boolean,
    replaceItems: ArrayItem[],
): ArrayItem[] => {
    return replaceItems.reduce(
        (prev, replaceItem, curIndex) => prev.map((item) => (findFunction(item, curIndex) ? replaceItem : item)),
        array,
    );
};

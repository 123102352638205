import React from 'react';

import { TKinescopeVideo } from './KinescopeVideo.types';
import { transformVideoUrlToShow } from './KinescopeVideo.utils';

import './KinescopeVideo.style.scss';
export const KinescopeVideo: React.FC<TKinescopeVideo> = (props) => {
    const { url, allowFullScreen = true, allowAutoplay = false, videoWidth = 470, videoHeight = 270 } = props;

    return (
        <div className={'kinescope-video'} style={{ width: videoWidth, height: videoHeight }}>
            <div className={'kinescope-video__wrapper'}>
                <iframe
                    src={transformVideoUrlToShow(url)}
                    allow={`accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture ${
                        allowAutoplay && '; autoplay;'
                    }`}
                    frameBorder="0"
                    allowFullScreen={allowFullScreen}
                ></iframe>
            </div>
        </div>
    );
};

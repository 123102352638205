import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { IHomeworkItem } from 'types/homework';

export const getAssignedHomeworks = async (isMandatory?: boolean): Promise<IHomeworkItem[]> => {
    const url = isMandatory
        ? `/assignment-progress/?status=assigned,deferred_assignment&is_mandatory=${isMandatory}`
        : `/assignment-progress/?status=assigned,deferred_assignment`;
    return await api
        .get<never, AxiosResponse<IHomeworkItem[]>>(url)
        .then((res) => res.data || undefined)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

import { isEmptyList } from '@frontend-modules/frontend-utils';
import { TQuestionsCreatedDiagramData } from '@fsd-shared/models/statisticsMonitoring';
import { TChartDataItem } from '@fsd-shared/ui-kit';
import { getDaysInMonth } from '@fsd-shared/utils';
import dayjs from 'dayjs';

import { TParsedCriteriaData } from './QuestionsCreatedContent.types';

export const getParsedCriteriaData = (
    selectedMonth: string[] = [],
    showFullAxis: boolean,
    criteriaData?: TQuestionsCreatedDiagramData,
): TParsedCriteriaData => {
    const maxDaysCount = getMaxDaysInMonths(selectedMonth);

    const tableData = criteriaData?.table?.map((item) => {
        const { month, currentEasy, minEasy, currentHard, minHard, currentMedium, minMedium } = item;

        const allCurrentItem =
            currentEasy === null || currentMedium === null || currentHard === null
                ? '-'
                : currentEasy + currentMedium + currentHard;
        const allMinItem =
            minEasy === null || minMedium === null || minHard === null ? '-' : minEasy + minMedium + minHard;

        const formattedMonth = dayjs(month).format('MMMM, YYYY');
        return {
            month: formattedMonth[0].toUpperCase() + formattedMonth.slice(1),
            easy: `${currentEasy ?? '-'}/${minEasy ?? '-'}`,
            medium: `${currentMedium ?? '-'}/${minMedium ?? '-'}`,
            hard: `${currentHard ?? '-'}/${minHard ?? '-'}`,
            all: `${allCurrentItem}/${allMinItem}`,
        };
    });
    const tableColumns = [
        {
            title: 'Месяц',
            name: 'month',
        },
        {
            title: 'Лёгкие',
            name: 'easy',
        },
        {
            title: 'Средние',
            name: 'medium',
        },
        {
            title: 'Тяжелые',
            name: 'hard',
        },
        {
            title: 'Всего',
            name: 'all',
        },
    ];

    const chartData = criteriaData ? getChartData(criteriaData, maxDaysCount, selectedMonth) : undefined;
    const chartOptions = criteriaData ? getChartOptions(criteriaData, maxDaysCount, showFullAxis) : undefined;
    return {
        tableData,
        tableColumns,
        chartData,
        chartOptions,
    };
};
const getChartData = (
    criteriaData: TQuestionsCreatedDiagramData,
    daysCountInMonth: number,
    selectedMonth: string[],
) => {
    const filteredData = criteriaData.diagram.data.filter(({ points }) => !isEmptyList(points));

    const dataStartValue: TChartDataItem[][] = [
        [
            { type: 'number', role: 'domain' },
            { type: 'string', role: 'tooltip', p: { html: true } },
            ...filteredData?.map(({ label }) => label),
        ],
    ];

    [...Array(daysCountInMonth)].forEach((_, index) => {
        const chartPoints = filteredData?.reduce((acc, res): {
            month: string;
            value: number | null;
            color: string;
        }[] => {
            const point = res.points.find((item) => item.label === `${index + 1}`);
            acc.push({
                month: res.label,
                value: point ? point.value : null,
                color: res.color,
            });
            return acc;
        }, [] as { month: string; value: number | null; color: string }[]);

        const tooltip = getTooltipContent(index + 1, chartPoints);
        dataStartValue.push([index + 1, tooltip, ...chartPoints.map(({ value }) => value)]);
    });
    return dataStartValue;
};
const getChartOptions = (
    criteriaData: TQuestionsCreatedDiagramData,
    daysCountInMonth: number,
    showAllHorizontalTicks: boolean,
) => {
    const horizontalPoints: number[] = [...Array(daysCountInMonth)]
        .map((_, index) => index + 1)
        .filter((val) => (showAllHorizontalTicks ? val : val % 2 !== 0));
    const filteredData = criteriaData.diagram.data.filter(({ points }) => !isEmptyList(points));

    const maxValue = filteredData.reduce((acc, res): number => {
        const values = res.points.map(({ value }) => value);
        const currMaxValue = Math.max(...values);
        if (currMaxValue > acc) {
            acc = currMaxValue;
        }
        return acc;
    }, 2);

    return {
        vAxis: {
            minValue: 0,
            maxValue: maxValue ?? 2,
        },
        hAxis: {
            ticks: horizontalPoints,
            minValue: 1,
        },
        tooltip: {
            ignoreBounds: showAllHorizontalTicks,
        },
        series: filteredData.map(({ color }) => {
            return {
                color: color,
                type: 'line',
            };
        }),
    };
};
const getMaxDaysInMonths = (selectedMonth: string[]): number => {
    return selectedMonth.reduce((acc, res): number => {
        const count = getDaysInMonth(dayjs(res));
        acc = acc > count ? acc : count;
        return acc;
    }, 0);
};
const getTooltipContent = (dayNum: number, data: { month: string; value: number | null; color: string }[]) => {
    const tooltipContentTitle = `${dayNum}-е число месяца`;

    return `<div class="chart-tooltip">
                <div class="chart-tooltip__title">${tooltipContentTitle}</div>
                ${data
                    .map(({ month, value, color }, index) => {
                        const style = `color:${color};`;
                        return value !== null
                            ? `<div class="chart-tooltip__row" style=${style}><div>${month}</div><div>${value}</div></div>`
                            : null;
                    })
                    .join('')}
    </div>`;
};

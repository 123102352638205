import { QueryParams } from 'types/lessonTask';

export const isFiltersUpdated = (first: QueryParams, second: QueryParams, considerExcludedIds = false): boolean => {
    return (
        (first?.excludeIds?.join() !== second?.excludeIds?.join() && considerExcludedIds) ||
        first?.answersType?.join() !== second?.answersType?.join() ||
        first?.difficulty?.join() !== second?.difficulty?.join() ||
        first?.text !== second?.text ||
        first?.topics?.join() !== second?.topics?.join() ||
        first?.skillsAnd?.join() !== second?.skillsAnd?.join() ||
        first?.skillsOr?.join() !== second?.skillsOr?.join()
    );
};
export const isFiltersEqual = (first: QueryParams, second: QueryParams): boolean => {
    return (
        first?.answersType?.join() === second?.answersType?.join() &&
        first?.difficulty?.join() === second?.difficulty?.join() &&
        first?.text === second?.text &&
        first?.topics?.join() === second?.topics?.join() &&
        first?.skillsAnd?.join() === second?.skillsAnd?.join() &&
        first?.skillsOr?.join() === second?.skillsOr?.join()
    );
};

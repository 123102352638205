import React, { useEffect, useState } from 'react';
import { getNoun } from '@frontend-modules/frontend-utils';
import { AppText } from '@frontend-modules/ui-kit';
import { TMultipleSelectorProps } from 'src-new/components/lesson-task';
import { E_COLORS } from 'src-new/constants';
import { AppSelect } from 'src-new/ui';

import './style.scss';
export const MultipleSelector: React.FC<TMultipleSelectorProps> = (props) => {
    const { label, options, placeholder = '', valueType = 'list', defaultValue, handleMultipleSelect } = props;
    const [selectedOptions, setSelectedOptions] = useState<{ label: React.ReactNode; value: string }[]>([]);

    const TagRender = () => {
        const getText = () => {
            const selectedCount = selectedOptions.length;
            const listTypeText = selectedOptions?.map(({ value }) => value)?.join(', ');
            const countTypeText =
                selectedCount === options.length
                    ? 'Все типы'
                    : `${selectedCount} ${getNoun(selectedCount, 'тип', 'типа', 'типов')} ${getNoun(
                          selectedCount,
                          'выбран',
                          'выбрано',
                          'выбрано',
                      )}`;

            return valueType === 'list' ? listTypeText : countTypeText;
        };

        return <AppText text={getText()} textStyle={'DesktopFontBody'} />;
    };

    const getNormalizedOptions = (options: string[] | undefined) => {
        return options
            ? options.map((item) => {
                  return {
                      label: <AppText text={item} textStyle={'DesktopFontBody'} />,
                      value: item,
                  };
              })
            : [];
    };

    const normalizeOptions = getNormalizedOptions(options);
    const normalizeDefaultValue = getNormalizedOptions(defaultValue);

    const handleMultipleSelectLocal = (val: { label: React.ReactNode; value: string }[]) => {
        const list = val.length ? val : normalizeOptions;

        setSelectedOptions(list);
        handleMultipleSelect(list.map(({ value }) => value));
    };

    useEffect(() => {
        if (defaultValue && defaultValue.length) {
            setSelectedOptions(normalizeDefaultValue);
        } else {
            setSelectedOptions(normalizeOptions);
        }
    }, [options, defaultValue]);

    return (
        <div className={'multiple-selector'}>
            <AppText text={label} textStyle={'DesktopFootNotes'} color={E_COLORS.light_grey_color_2} />
            <AppSelect
                placeholder={placeholder}
                maxTagCount={1}
                mode={'multiple'}
                key={options?.join()}
                options={normalizeOptions}
                value={selectedOptions}
                tagRender={TagRender}
                showSearch={false}
                showArrow
                allowClear={false}
                handleMultipleSelect={handleMultipleSelectLocal}
            />
        </div>
    );
};

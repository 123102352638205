import { IControl, IFormData } from '@frontend-modules/form-generator/dist/config/formGenerator.types';
import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { IGroupActionFilters, TGroupActionTableItem } from 'src-new/components/group-actions';
import {
    actionDetailsAction,
    createActionProgressAction,
    getActionCreateAction,
    getActionsListAction,
    getActionStudentsAction,
    getMoreActionsListAction,
} from 'store/actions/groupActions';
import {
    getUpdatedActionList,
    getUpdatedDetailsItem,
    normalizeActionsList,
    normalizeTableData,
} from 'store/normalizers/groupActions';
import { FetchStatus } from 'types/api';
import {
    IAction,
    IActionDetailsResponse,
    IActionStudentsResponsePagination,
    IGetActionCreateResponse,
} from 'types/groupActions';

export interface IActionsDetailsItem extends IActionDetailsResponse {
    savedData?: IActionDetailsResponse['data'];
}

export interface IActionsDetails {
    items?: {
        [key: string]: IActionsDetailsItem;
    };
    actionsDetailsStatus: FetchStatus;
    actionsDetailsError?: any;
}

interface ICurrentModalData extends Omit<IFormData, 'controls'> {
    controls?: Array<IControl & { actionType?: string }>;
}
interface IGroupActionsState {
    actionStudents?: {
        tableData?: Array<TGroupActionTableItem>;
        tablePagination?: IActionStudentsResponsePagination;
        tableDataStatus: FetchStatus;
        tableDataError: any;
    };
    actionsHistory?: {
        historyListData?: Array<IAction>;
        actionsHistoryPagination?: IActionStudentsResponsePagination;
        actionsHistoryStatus?: FetchStatus;
        actionsHistoryMoreStatus?: FetchStatus;
        actionsHistoryError?: any;
    };
    actionsPlanned?: {
        plannedListData?: Array<IAction>;
        actionsPlannedPagination?: IActionStudentsResponsePagination;
        actionsPlannedStatus?: FetchStatus;
        actionsPlannedMoreStatus?: FetchStatus;
        actionsPlannedError?: any;
    };
    createActionModal?: {
        currentModalData?: ICurrentModalData;
        nextStepModalData?: IGetActionCreateResponse['actions'];
        createActionModalStatus?: FetchStatus;
        createActionModalError?: any;
    };
    actionsDetails?: IActionsDetails;
    query?: IGroupActionFilters;
}

const initialState = {
    tableData: undefined,
    actionsHistory: undefined,
    actionsPlanned: undefined,
    actionsDetails: undefined,
    createActionModal: undefined,
    query: undefined,
};

const groupActionsSlice = createSlice<IGroupActionsState, SliceCaseReducers<IGroupActionsState>>({
    name: 'groupActions',
    initialState,
    reducers: {
        setActionsQuery(state, action: PayloadAction<IGroupActionFilters>) {
            state.query = action.payload;
        },
        clearDetails(state) {
            state.actionsDetails = undefined;
        },
        clearModalData(state) {
            state.createActionModal = undefined;
        },
        changeCurrentModalData(state, action: PayloadAction<IFormData>) {
            if (state.createActionModal) {
                state.createActionModal.currentModalData = action.payload;
            }
        },
        clearTableError(state) {
            if (state.actionStudents) {
                state.actionStudents.tableDataError = undefined;
            }
        },
        changeNextStepModalData(state, action: PayloadAction<IGetActionCreateResponse['actions']>) {
            if (state.createActionModal) {
                state.createActionModal.nextStepModalData = action.payload;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getActionStudentsAction.pending, (state) => {
                const newData = {
                    tableDataStatus: FetchStatus.FETCHING,
                    tableDataError: null,
                };
                state.actionStudents = newData;
            })
            .addCase(getActionStudentsAction.fulfilled, (state, { payload }) => {
                if (payload) {
                    const newData = {
                        tableDataStatus: FetchStatus.FETCHED,
                        tableDataError: null,
                        tablePagination: payload.pagination,
                        tableData: normalizeTableData(payload.results),
                    };

                    state.actionStudents = newData;
                }
            })
            .addCase(getActionStudentsAction.rejected, (state, { error }) => {
                let errorText;
                try {
                    const errorFields = JSON.parse(error?.message ?? '')?.extra?.fields;
                    errorText = Array.isArray(errorFields) ? errorFields.join('\n') : 'Что то пошло не так';
                } catch (e) {
                    errorText = 'Что то пошло не так';
                }
                const newData = {
                    tableDataStatus: FetchStatus.ERROR,
                    tableDataError: errorText,
                };
                state.actionStudents = newData;
            })
            .addCase(getActionsListAction.pending, (state, { meta }) => {
                if (meta.arg.type === 'history') {
                    const newHistoryData = {
                        ...state.actionsHistory,
                        actionsHistoryStatus: FetchStatus.FETCHING,
                        actionsHistoryError: null,
                    };
                    state.actionsHistory = newHistoryData;
                } else {
                    const newPlannedData = {
                        ...state.actionsPlanned,
                        actionsPlannedStatus: FetchStatus.FETCHING,
                        actionsPlannedError: null,
                    };
                    state.actionsPlanned = newPlannedData;
                }
            })
            .addCase(getActionsListAction.fulfilled, (state, { payload, meta }) => {
                const normalizedPayloadResult = normalizeActionsList(payload.results);

                if (meta.arg.type === 'history') {
                    const newHistoryData = {
                        actionsHistoryStatus: FetchStatus.FETCHED,
                        actionsHistoryError: null,
                        historyListData: normalizedPayloadResult,
                        actionsHistoryPagination: payload.pagination,
                    };
                    state.actionsHistory = newHistoryData;
                } else {
                    const newPlannedData = {
                        actionsPlannedStatus: FetchStatus.FETCHED,
                        actionsPlannedError: null,
                        plannedListData: normalizedPayloadResult,
                        actionsPlannedPagination: payload.pagination,
                    };
                    state.actionsPlanned = newPlannedData;
                }
            })
            .addCase(getActionsListAction.rejected, (state, { error, meta }) => {
                if (meta.arg.type === 'history') {
                    const newHistoryData = {
                        actionsHistoryStatus: FetchStatus.ERROR,
                        actionsHistoryError: error,
                    };
                    state.actionsHistory = newHistoryData;
                } else {
                    const newPlannedData = {
                        actionsPlannedStatus: FetchStatus.ERROR,
                        actionsPlannedError: error,
                    };
                    state.actionsPlanned = newPlannedData;
                }
            })
            .addCase(getMoreActionsListAction.pending, (state, { meta }) => {
                if (meta.arg.type === 'history') {
                    const newHistoryData = {
                        ...state.actionsHistory,
                        actionsHistoryMoreStatus: FetchStatus.FETCHING,
                        actionsHistoryError: null,
                    };
                    state.actionsHistory = newHistoryData;
                } else {
                    const newPlannedData = {
                        ...state.actionsPlanned,
                        actionsPlannedMoreStatus: FetchStatus.FETCHING,
                        actionsPlannedError: null,
                    };
                    state.actionsPlanned = newPlannedData;
                }
            })
            .addCase(getMoreActionsListAction.fulfilled, (state, { payload, meta }) => {
                const normalizedPayloadResult = normalizeActionsList(payload.results);

                if (meta.arg.type === 'history') {
                    const newHistoryData = {
                        actionsHistoryMoreStatus: FetchStatus.FETCHED,
                        actionsHistoryError: null,
                        historyListData: [...(state.actionsHistory?.historyListData ?? []), ...normalizedPayloadResult],
                        actionsHistoryPagination: payload.pagination,
                    };
                    state.actionsHistory = newHistoryData;
                } else {
                    const newPlannedData = {
                        actionsPlannedMoreStatus: FetchStatus.FETCHED,
                        actionsPlannedError: null,
                        plannedListData: [...(state.actionsPlanned?.plannedListData ?? []), ...normalizedPayloadResult],
                        actionsPlannedPagination: payload.pagination,
                    };
                    state.actionsPlanned = newPlannedData;
                }
            })
            .addCase(getMoreActionsListAction.rejected, (state, { error, meta }) => {
                if (meta.arg.type === 'history') {
                    const newHistoryData = {
                        actionsHistoryMoreStatus: FetchStatus.ERROR,
                        actionsHistoryError: error,
                    };
                    state.actionsHistory = newHistoryData;
                } else {
                    const newPlannedData = {
                        actionsPlannedMoreStatus: FetchStatus.ERROR,
                        actionsPlannedError: error,
                    };
                    state.actionsPlanned = newPlannedData;
                }
            })
            .addCase(actionDetailsAction.pending, (state) => {
                const newDetailsData = {
                    ...state.actionsDetails,
                    actionsDetailsStatus: FetchStatus.FETCHING,
                    actionsDetailsError: null,
                };
                state.actionsDetails = newDetailsData;
            })
            .addCase(actionDetailsAction.fulfilled, (state, { payload, meta }) => {
                const oldItems = state.actionsDetails?.items;
                const { type, id: targetId, cancel, filters } = meta.arg;
                const { historyListData } = state.actionsHistory || {};
                const { plannedListData } = state.actionsPlanned || {};
                const isHistory = type === 'history';
                // если данные были запрошены без фильтров или сортировки, то вернуться те данные, для которых сохранены фильтры
                const isSavedData = !filters;

                if (isHistory) {
                    const newList = getUpdatedActionList(historyListData, targetId, 'updateItem', payload);
                    state.actionsHistory = {
                        ...state.actionsHistory,
                        historyListData: newList,
                    };
                } else {
                    const updateType = cancel ? 'removeItem' : 'updateItem';
                    const newList = getUpdatedActionList(plannedListData, targetId, updateType, payload);
                    state.actionsPlanned = {
                        ...state.actionsPlanned,
                        plannedListData: newList,
                    };
                }

                state.actionsDetails = {
                    items: getUpdatedDetailsItem(oldItems, payload, isSavedData),
                    actionsDetailsStatus: FetchStatus.FETCHED,
                    actionsDetailsError: null,
                };
            })
            .addCase(actionDetailsAction.rejected, (state, { error }) => {
                const newDetailsData = {
                    ...state.actionsDetails,
                    actionsDetailsStatus: FetchStatus.ERROR,
                    actionsDetailsError: error,
                };
                state.actionsDetails = newDetailsData;
            })
            .addCase(getActionCreateAction.pending, (state) => {
                const newData = {
                    createActionModalStatus: FetchStatus.FETCHING,
                    createActionModalError: null,
                };
                state.createActionModal = newData;
            })
            .addCase(getActionCreateAction.fulfilled, (state, { payload }) => {
                const { actions, ...currentModalData } = payload;
                const newData = {
                    currentModalData: currentModalData,
                    nextStepModalData: actions,
                    createActionModalStatus: FetchStatus.FETCHED,
                    createActionModalError: null,
                };
                state.createActionModal = newData;
            })
            .addCase(getActionCreateAction.rejected, (state, { error }) => {
                const newData = {
                    ...state.createActionModal,
                    createActionModalStatus: FetchStatus.ERROR,
                    createActionModalError: error,
                };
                state.createActionModal = newData;
            })
            .addCase(createActionProgressAction.pending, (state) => {
                const loadingControls = [
                    {
                        actionType: 'loading',
                        label: 'Загрузка',
                        method: 'post',
                        isLoading: true,
                        type: 'main_button',
                        url: '',
                    },
                ];

                const newData = {
                    ...state.createActionModal,
                    currentModalData: { ...state.createActionModal?.currentModalData, controls: loadingControls },
                    createActionModalError: null,
                };
                // @ts-ignore
                state.createActionModal = newData;
            })
            .addCase(createActionProgressAction.fulfilled, (state, { payload }) => {
                const creatingControls = [
                    {
                        actionType: 'success',
                        label: 'Успешно',
                        method: 'post',
                        type: 'main_button',
                        url: '',
                    },
                ];
                const newData = {
                    ...state.createActionModal,
                    currentModalData: { ...state.createActionModal?.currentModalData, controls: creatingControls },
                    createActionModalError: null,
                };
                // @ts-ignore
                state.createActionModal = newData;
            })
            .addCase(createActionProgressAction.rejected, (state, { error }) => {
                const creatingControls = [
                    {
                        actionType: 'error',
                        label: 'Закрыть',
                        method: 'post',
                        type: 'main_button',
                        url: '',
                    },
                ];
                let errorText;
                try {
                    const errorFields = JSON.parse(error?.message ?? '')?.extra?.fields;
                    errorText = Array.isArray(errorFields) ? errorFields.join('\n') : errorFields?.avgScoreFilters;
                } catch (e) {
                    errorText = 'Что то пошло не так';
                }
                const newData = {
                    ...state.createActionModal,
                    currentModalData: {
                        label: 'Ошибка',
                        url: '',
                        widgetList: [
                            {
                                value: errorText ?? 'Что то пошло не так',
                                field: 'info_field',
                                name: 'error',
                                type: 'info_field',
                                textColor: '#ea5959',
                                mainColor: '#ea5959',
                                backgroundColor: '#FFFFFF',
                            },
                        ],
                        controls: creatingControls,
                    },
                    createActionModalError: errorText,
                };
                // @ts-ignore
                state.createActionModal = newData;
            });
    },
});
export const groupActionsReducer = groupActionsSlice.reducer;
export const {
    setActionsQuery,
    changeNextStepModalData,
    clearDetails,
    changeCurrentModalData,
    clearModalData,
    clearTableError,
} = groupActionsSlice.actions;

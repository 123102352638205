import { patchApiRequest } from 'api';

interface PatchThemeOrderingParams {
    courseId: number;
    ordering: number[];
}

export const patchThemesOrdering = ({
    courseId,
    ...params
}: PatchThemeOrderingParams): Promise<{ ordering: number[] }> => {
    return patchApiRequest(`/course/${courseId}/topics/ordering/`, params);
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { Period } from 'api/services/courseGroups/getPeriods';
import {
    getPeriodMark,
    getPeriodMarkFinal,
    getPeriodStudentMark,
    getPeriodStudentMarkFinal,
    patchPeriodMark,
} from 'api/services/periodMark';
import { PatchPeriodMarkParams } from 'api/services/periodMark/patchPeriodMark';
import {
    AcademicStudent,
    getQuarterFinalMarks,
    getQuarterMarks,
    getQuarterStudentFinalMarks,
    getQuarterStudentMarks,
} from 'pages/AcademicPerformance/utils';

import { Course } from 'utils/academicPerformance';

export const patchPeriodMarkAction = createAsyncThunk('periodMark/patchMark', async (params: PatchPeriodMarkParams) => {
    return await patchPeriodMark(params);
});

export const getPeriodMarkAction = createAsyncThunk(
    'periodMark/getMark',
    async (params: {
        course: number;
        students: number[];
        periods: Period[];
        groupId: number;
        student: AcademicStudent[];
        period: number[];
        periodMarkType: string;
        needFinalMark: boolean;
        needRewrite: boolean;
    }) => {
        const mark = await getPeriodMark({ ...params, period: params.period });
        const finalMark = params.needFinalMark ? await getPeriodMarkFinal(params) : [];
        return {
            periodMarks: getQuarterMarks(
                params.student,
                mark,
                params.periods,
                params.groupId,
                params.periodMarkType,
            ).concat(
                getQuarterFinalMarks(
                    params.student,
                    finalMark,
                    params.groupId,
                    params.periods.length,
                    params.periodMarkType,
                ),
            ),
            needRewrite: params.needRewrite,
        };
    },
);

export const getPeriodStudentMarkAction = createAsyncThunk(
    'periodMark/getStudentMark',
    async (params: { student: number; periods: Period[]; course: Course[]; needFinalMark: boolean }) => {
        const allPeriods = params?.periods?.map((el) => el.id);
        const finalMark = params.needFinalMark ? await getPeriodStudentMarkFinal(params.student) : [];
        const mark = await getPeriodStudentMark({ periods: allPeriods, id: params.student });

        return getQuarterStudentMarks(mark, params.periods, params.course).concat(
            getQuarterStudentFinalMarks(params.course, finalMark, params.periods.length, params.student),
        );
    },
);

import { ActionCreatorWithoutPayload, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { QuestionStatus, ResponseQuestionDetailed } from 'api/services/questionBank';
import { DifficultyEnum, GetSearchQuestions, SearchBlockGet } from 'api/services/questionCreate';
import { IQuestion } from 'containers/QuestionSearchResult/QuestionSearchResult';
import { postNewAssignmentAction, updateAssignmentNewAction } from 'store/actions/assignment';
import {
    addEmptyFormValuesStore,
    addEmptyQuestionsStore,
    fetchAssignmentSearchBlocksAction,
    postSearchQuestionAction,
    questionBlockAction,
    questionDetailAction,
    resetSearchBlocksStore,
    saveCreateQuestionsStore,
    saveStateSearchPageStore,
    SearchPageState,
    SingleQuestions,
} from 'store/actions/questionCreate';
import { FetchStatus } from 'types/api';
import { TrueFalseQuestionData } from 'types/question';

import { AnswersTypeEnum } from 'components/QuestionPopUp/QuestionPopUp.types';

export interface QuestionSearchBlockState {
    postSearchBlock: FetchStatus;
    patchSearchBlock: FetchStatus;
    getDetailQuestion: FetchStatus;
    questionBlockStatus: FetchStatus;
    fetchAssignmentSearchBlocksStatus: FetchStatus;
    questionDetail: ResponseQuestionDetailed;
    searchQuestionBlock: SearchBlockGet;
    searchQuestionBlocksArray: SearchBlockGet[];
    assignmentSearchBlocks: SearchBlockGet[];
    questionBlock: GetSearchQuestions[];
    formValues: SearchPageState[];
    questions: SingleQuestions[];
    createdQuestions: SingleQuestions[];
    error: unknown;
    isUpdate: boolean;
    stateUpdated: boolean;
}

export const initialState: QuestionSearchBlockState = {
    postSearchBlock: FetchStatus.INITIAL,
    patchSearchBlock: FetchStatus.INITIAL,
    getDetailQuestion: FetchStatus.INITIAL,
    questionBlockStatus: FetchStatus.INITIAL,
    fetchAssignmentSearchBlocksStatus: FetchStatus.INITIAL,
    questionDetail: {
        id: 0,
        text: '',
        difficulty: DifficultyEnum.easy,
        answersType: AnswersTypeEnum.test,
        status: QuestionStatus.NotPublished,
        timestamp: '',
        author: {
            firstName: '',
            lastName: '',
            patronymic: '',
            mainRole: '',
        },
        topics: [],
        skills: [],
        data: {} as TrueFalseQuestionData,
    },
    searchQuestionBlock: {
        assignment: 0,
        questionNumber: 0,
        single: false,
        filters: {
            text: '',
            topics: [],
            difficulty: [DifficultyEnum.easy],
            answersType: [AnswersTypeEnum.test],
            skillsOr: [],
            skillsAnd: [],
            excludeIds: [],
            id: 0,
        },
        quantity: 0,
    },
    searchQuestionBlocksArray: [],
    assignmentSearchBlocks: [],
    questionBlock: [],
    formValues: [
        {
            filters: [],
            rightSearchBlock: [],
            leftSearchBlock: [],
            questionNum: 1,
            excludesIds: [],
        },
    ],
    questions: [
        {
            questions: [],
            questionNum: 1,
        },
    ],
    createdQuestions: [
        {
            questions: [],
            questionNum: 1,
        },
    ],
    error: null,
    isUpdate: false,
    stateUpdated: false,
};

const questionsSearchBlockSlice = createSlice<QuestionSearchBlockState, SliceCaseReducers<QuestionSearchBlockState>>({
    name: 'questionsSearchBlock',
    initialState,
    reducers: {
        deleteFormValuesStore: (state, action: PayloadAction<number>) => {
            const formValues = state.formValues
                .filter((_, index) => index !== action.payload - 1)
                .map((value, index) => ({
                    ...value,
                    questionNum: index + 1,
                }));
            const questions = state.questions
                .filter((_, index) => index !== action.payload - 1)
                .map((value, index) => ({
                    ...value,
                    questionNum: index + 1,
                }));

            return {
                ...state,
                formValues,
                questions,
                stateUpdated: false,
            };
        },
        updateFormValuesAction: (state, action: PayloadAction<SearchPageState[]>) => {
            return {
                ...state,
                formValues: action.payload,
                stateUpdated: true,
            };
        },
        setUpdateFlag: (state) => {
            return {
                ...state,
                isUpdate: true,
            };
        },
        resetUpdateFlag: (state) => {
            return {
                ...state,
                isUpdate: false,
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postSearchQuestionAction.pending, (state) => {
                state.postSearchBlock = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(postSearchQuestionAction.fulfilled, (state, { payload }) => {
                state.postSearchBlock = FetchStatus.FETCHED;
                state.searchQuestionBlock = payload;
                state.error = null;
            })
            .addCase(postSearchQuestionAction.rejected, (state, { error }) => {
                state.postSearchBlock = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(questionDetailAction.pending, (state) => {
                state.getDetailQuestion = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(questionDetailAction.fulfilled, (state, { payload }) => {
                state.getDetailQuestion = FetchStatus.FETCHED;
                state.questionDetail = payload;
                state.error = null;
            })
            .addCase(questionDetailAction.rejected, (state, { error }) => {
                state.getDetailQuestion = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(questionBlockAction.pending, (state) => {
                state.questionBlockStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(questionBlockAction.fulfilled, (state, { payload }) => {
                state.questionBlockStatus = FetchStatus.FETCHED;
                state.questionBlock = payload;
                state.error = null;
            })
            .addCase(questionBlockAction.rejected, (state, { error }) => {
                state.questionBlockStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(fetchAssignmentSearchBlocksAction.pending, (state) => {
                state.fetchAssignmentSearchBlocksStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(fetchAssignmentSearchBlocksAction.fulfilled, (state, { payload }) => {
                state.fetchAssignmentSearchBlocksStatus = FetchStatus.FETCHED;
                state.formValues = payload.formValues;
                state.questions = payload.questions;
                state.assignmentSearchBlocks = payload.assignmentSearchBlocks;
                state.error = null;
            })
            .addCase(fetchAssignmentSearchBlocksAction.rejected, (state, { error }) => {
                state.fetchAssignmentSearchBlocksStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder.addCase(saveStateSearchPageStore, (state, { payload }) => {
            const filterArray = payload.values.leftSearchBlock.filter((question) =>
                question.questions.filter((item) => !payload.values.excludesIds.includes(item.id)),
            );
            payload.values.leftSearchBlock = filterArray;
            state.formValues = [
                ...state.formValues.filter((item) => item.questionNum !== payload.values.questionNum),
                payload.values,
            ].sort((a, b) => a.questionNum - b.questionNum);
        });
        builder.addCase(resetSearchBlocksStore, () => ({ ...initialState }));
        builder.addCase(saveCreateQuestionsStore, (state, { payload }) => {
            const oldQuestions = state.questions
                .filter((question) => question.questionNum === payload.questions.questionNum)
                .pop()?.questions;
            const newValue = {
                questionNum: payload.questions.questionNum,
                questions: payload.questions.questions.concat(oldQuestions ? (oldQuestions as IQuestion[]) : []),
            };
            state.questions = [
                ...state.questions.filter((question) => question.questionNum !== payload.questions.questionNum),
                payload.action === 'create' ? newValue : payload.questions,
            ].sort((a, b) => a.questionNum - b.questionNum);
            if (payload.action === 'create') {
                const oldCreatedQuestions = state.createdQuestions
                    .filter((question) => question.questionNum === payload.questions.questionNum)
                    .pop()?.questions;
                const newCreatedValue = {
                    questionNum: payload.questions.questionNum,
                    questions: payload.questions.questions.concat(
                        oldCreatedQuestions ? (oldCreatedQuestions as IQuestion[]) : [],
                    ),
                };
                state.createdQuestions = [
                    ...state.createdQuestions.filter(
                        (question) => question.questionNum !== payload.questions.questionNum,
                    ),
                    newCreatedValue,
                ].sort((a, b) => a.questionNum - b.questionNum);
            }
        });
        builder.addCase(addEmptyFormValuesStore, (state) => {
            state.formValues = [
                ...state.formValues,
                {
                    filters: [],
                    rightSearchBlock: [],
                    leftSearchBlock: [],
                    questionNum: state.formValues.length + 1,
                    excludesIds: [],
                },
            ];
        });
        builder.addCase(addEmptyQuestionsStore, (state) => {
            state.questions = [...state.questions, { questions: [], questionNum: state.questions.length + 1 }];
            state.createdQuestions = [
                ...state.createdQuestions,
                { questions: [], questionNum: state.createdQuestions.length + 1 },
            ];
        });
        builder
            .addCase(postNewAssignmentAction.fulfilled, (state, { payload }) => {
                state.postSearchBlock = FetchStatus.FETCHED;
                state.assignmentSearchBlocks = [...state.assignmentSearchBlocks, ...payload.postedSearchBlocks];
                state.error = null;
            })
            .addCase(updateAssignmentNewAction.fulfilled, (state, { payload }) => {
                state.assignmentSearchBlocks = [
                    ...state.assignmentSearchBlocks,
                    ...payload.postedSearchBlocks,
                    ...payload.patchedSearchBlocks,
                ].filter((searchBlock) => !(searchBlock.id && payload.deletedSearchBlocks.includes(searchBlock.id)));
            });
    },
});

export const { deleteFormValuesStore, updateFormValuesAction } = questionsSearchBlockSlice.actions;
export const setUpdateFlag = questionsSearchBlockSlice.actions.setUpdateFlag as ActionCreatorWithoutPayload<string>;
export const resetUpdateFlag = questionsSearchBlockSlice.actions.resetUpdateFlag as ActionCreatorWithoutPayload<string>;

export const questionsSearchBlockReducer = questionsSearchBlockSlice.reducer;

import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { CloseIcon } from 'assets';
import { TTeacherSubjects } from 'src-new/components/new-subjects';
import { AppModal, AppSkeleton, AppText } from 'src-new/ui';
import { resetTeacherInfoAction } from 'store/actions/newSubjects';

import { getInfoFields } from './TeachersInfoModal.config';
import { TFieldName, TTeachersInfoModalParams } from './TeachersInfoModal.types';

import './style.scss';

export const TeachersInfoModal: React.FC<TTeachersInfoModalParams> = ({ teacherInfo, isFetching }) => {
    const dispatch = useDispatch();
    const { lastName, firstName, patronymic, img, id, externalId } = { ...teacherInfo?.user };

    const isModalShow = useMemo(() => !!id, [id]);

    const getInfoContent = (fieldName: TFieldName): React.ReactNode => {
        const value = teacherInfo?.[fieldName];

        if (Array.isArray(value)) {
            // TODO: использовать isListExist
            return value?.length ? (
                value.map((value: string | TTeacherSubjects, index: number) => {
                    return typeof value !== 'string' ? (
                        <AppText key={`${fieldName}-${index}`} text={value?.title || '-'} fontStyle={'H4'} />
                    ) : (
                        <AppText key={`${fieldName}-${index}`} text={value || '-'} fontStyle={'H4'} />
                    );
                })
            ) : (
                <AppText text={'-'} fontStyle={'H4'} />
            );
        } else {
            return <AppText text={`${value || '-'}`} fontStyle={'H4'} />;
        }
    };
    const handleClose = () => {
        dispatch(resetTeacherInfoAction());
    };

    return (
        <AppModal
            className={'teachers-info-modal'}
            isShow={isModalShow}
            closeIcon={<CloseIcon />}
            afterClose={handleClose}
        >
            <div className={'teachers-info-modal-content'}>
                {isFetching ? (
                    <AppSkeleton height={200} />
                ) : (
                    <>
                        <div className={'teachers-info-modal-content__header'}>
                            <div className={'teachers-info-modal-content__header_person'}>
                                <div className={'teacher-avatar'}>
                                    {!img ? (
                                        <img src={img} alt={'teachers-avatar'} />
                                    ) : (
                                        <div className={'empty-image'}>
                                            <AppText text={`${lastName?.[0]}${firstName?.[0]}`} fontStyle={'Title'} />
                                        </div>
                                    )}
                                </div>
                                <AppText text={`${lastName} ${firstName} ${patronymic}`} fontStyle={'H3'} />
                            </div>
                        </div>
                        <div className={'teachers-info-modal-content__info'}>
                            {getInfoFields.map((item, index) => {
                                const content = teacherInfo?.[item.fieldName];
                                const isEmpty = Array.isArray(content) ? !content.length : !content;

                                return !isEmpty ? (
                                    <div key={index} className={'teachers-info-modal-content__info-point'}>
                                        <div className={'teachers-info-modal-content__info_title'}>
                                            <AppText text={item.title} fontStyle={'footnotes'} />
                                        </div>
                                        <div className={'teachers-info-modal-content__info_list'}>
                                            {getInfoContent(item.fieldName)}
                                        </div>
                                    </div>
                                ) : undefined;
                            })}
                        </div>
                    </>
                )}
            </div>
        </AppModal>
    );
};

import { postApiRequest } from 'api';

interface NewsMetaData {
    id: number;
    dateCreate: string;
    author: number;
}

export const createNews = (): Promise<NewsMetaData> => {
    return postApiRequest('/news/create/');
};

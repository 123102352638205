import { getApiRequest } from 'api';

export interface SchoolPartner {
    id: number;
    title: string;
    studentsCount: number;
}

export const getSchoolPartners = (): Promise<SchoolPartner[]> => {
    return getApiRequest(`/school-partner/statistics/`);
};

import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { getParallelsAction } from 'store/actions/edu';
import { FetchStatus } from 'types/api';
import { Parallel } from 'types/parallel';

export interface EduState {
    parallels: Parallel[];
    getParallelsStatus: FetchStatus;
    error: unknown;
}

export const initialState: EduState = {
    parallels: [],
    getParallelsStatus: FetchStatus.INITIAL,
    error: null,
};

const eduSlice = createSlice<EduState, SliceCaseReducers<EduState>>({
    name: 'edu',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getParallelsAction.pending, (state) => {
                state.getParallelsStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getParallelsAction.fulfilled, (state, { payload }) => {
                state.getParallelsStatus = FetchStatus.FETCHED;
                state.error = null;
                state.parallels = payload;
            })
            .addCase(getParallelsAction.rejected, (state, { error }) => {
                state.getParallelsStatus = FetchStatus.ERROR;
                state.error = error;
            });
    },
});

export const eduReducer = eduSlice.reducer;

import { getApiRequest } from 'api';

type Params = {
    lesson_schedule?: number;
    event_room?: number;
    assembly?: number;
};

export const getMeetingJoinRedirect = (params: Params): Promise<{ link?: string; block: boolean }> => {
    return getApiRequest('meeting/join/', { params });
};

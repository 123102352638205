import { deleteApiRequest, getApiRequest, patchApiRequest, postApiRequest } from '@fsd-shared/utils';
import { apiMonitoring } from 'api';
import { AxiosRequestConfig } from 'axios';

export const getMonitoringRequest = <ResponseType, BodyType>(link: string, body?: BodyType): Promise<ResponseType> => {
    return getApiRequest(apiMonitoring, link, body);
};
export const patchMonitoringRequest = <ResponseType, BodyType>(
    link: string,
    body?: BodyType,
    config?: AxiosRequestConfig,
): Promise<ResponseType> => {
    return patchApiRequest(apiMonitoring, link, body, config);
};
export const postMonitoringRequest = <ResponseType, BodyType>(
    link: string,
    body?: BodyType,
    config?: AxiosRequestConfig,
): Promise<ResponseType> => {
    return postApiRequest(apiMonitoring, link, body, config);
};
export const deleteMonitoringRequest = <ResponseType, BodyType>(
    link: string,
    params?: BodyType,
): Promise<ResponseType> => {
    return deleteApiRequest(apiMonitoring, link, params);
};

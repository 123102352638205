import { getNoun } from '@frontend-modules/frontend-utils';
import moment, { Moment } from 'moment';

export type TInterval = {
    start: string;
    end: string;
};
const TIME_FORMAT = 'HH:mm';
const DATE_FORMAT = 'DD.MM';

export const getTimeByMoscow = (date: string, format = TIME_FORMAT): string => {
    const moscow_offset = 3 * 60;

    return moment(date).utcOffset(moscow_offset).format(format);
};
export const getDateByMoscow = (date: string, format = DATE_FORMAT): string => {
    const moscow_offset = 3 * 60;

    return moment(date).utcOffset(moscow_offset).format(format);
};
export const getTimeFromDate = (date: string): string => {
    return moment(date).format(TIME_FORMAT);
};
export const getMomentFromString = (time: string): Moment => {
    return moment(time, TIME_FORMAT);
};
export const getMaxTime = (timesList: Moment[]): string => {
    return moment.max(timesList).format(TIME_FORMAT).toString();
};
export const getMinTime = (timesList: Moment[]): string => {
    return moment.min(timesList).format(TIME_FORMAT).toString();
};
export const getDifferentInMinutes = (start: string, end: string): number => {
    const startTime = getMomentFromString(start);
    const endTime = getMomentFromString(end);

    return endTime.diff(startTime, 'minutes');
};

//возвращает сколько времени осталось/прошло относительно заданной даты
export const getTimeUntilDateInMinutes = (date: string, direction: 'before' | 'after'): string => {
    let startTime, endTime;

    if (direction === 'before') {
        startTime = moment(new Date());
        endTime = moment(date);
    } else {
        startTime = moment(date);
        endTime = moment(new Date());
    }
    const resInMin = endTime.diff(startTime, 'minutes');

    const days = Math.floor(resInMin / 60 / 24);
    const hours = Math.floor((resInMin - days * 24 * 60) / 60);
    const minutes = Math.floor(resInMin - days * 24 * 60 - hours * 60);
    const daysText = days ? `${days}д ` : '';
    const hoursText = hours ? `${hours}ч ` : '';
    const minutesText = minutes ? `${minutes}м ` : '';

    const resultString = `${daysText}${hoursText}${minutesText}`;
    return resultString.trim();
};

export const getTimeInMinutesFormat = (time: string): number => {
    const moment = getMomentFromString(time);

    return moment.minutes() + moment.hours() * 60;
};
export const getMinutesInTimeFormat = (minutes: number): string => {
    const timeMinutes = minutes % 60;

    return `${(minutes - timeMinutes) / 60}:${timeMinutes ? timeMinutes : '00'}`;
};
export const addMinutesToTime = (offset: number, currentTime: string): string => {
    const time = getMomentFromString(currentTime).add(offset, 'minutes');

    return time.format(TIME_FORMAT);
};
export const isTimeInInterval = (interval: TInterval, time: string): boolean => {
    const intervalInMinutes = {
        start: getMomentFromString(interval.start),
        end: getMomentFromString(interval.end),
    };
    const currentTime = getMomentFromString(time);

    return currentTime.isSameOrAfter(intervalInMinutes.start) && currentTime.isBefore(intervalInMinutes.end);
};
export const isIntervalContainsInterval = (intervalExt: TInterval, intervalIn: TInterval): boolean => {
    const intervalInMinutes = {
        start: getMomentFromString(intervalExt.start),
        end: getMomentFromString(intervalExt.end),
    };
    const lessonIntervalInMinutes = {
        start: getMomentFromString(intervalIn.start),
        end: getMomentFromString(intervalIn.end),
    };

    return (
        lessonIntervalInMinutes.start.isSameOrAfter(intervalInMinutes.start) &&
        lessonIntervalInMinutes.end.isSameOrBefore(intervalInMinutes.end)
    );
};

export const getTimeline = (startTime: string, intervalInMinutes: number, count: number): TInterval[] => {
    const timeline: TInterval[] = [];
    for (let i = 1; i <= count; i++) {
        if (!timeline.length) {
            timeline.push({
                start: startTime,
                end: addMinutesToTime(50, startTime),
            });
        } else {
            const lastIntervalIndex = timeline.length - 1;

            timeline.push({
                start: timeline[lastIntervalIndex].end,
                end: addMinutesToTime(50, timeline[lastIntervalIndex].end),
            });
        }
    }
    return timeline;
};

import Cookies, { CookieAttributes } from 'js-cookie';

const getCookie = (name: string): string => {
    return Cookies.get(name) || '';
};

const setCookie = (name: string, value: string | number, config?: CookieAttributes): string | undefined => {
    return Cookies.set(name, String(value), config);
};

const removeCookie = (name: string, config?: CookieAttributes): void => {
    return Cookies.remove(name, config);
};

export { getCookie, removeCookie, setCookie };

import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import {
    createMeetingFileAction,
    deleteMeetingFileAction,
    getCourseGroupMeetingFilesAction,
    getCourseMeetingFilesAction,
    updateMeetingFileAction,
} from 'store/actions/meetingFiles';
import { FetchStatus } from 'types/api';
import { TPaginate } from 'types/libraryTypes';
import { TCourseGroupMeetingFile, TMeetingFile } from 'types/meetingFiles';

interface MeetingFilesState {
    getMeetingFilesStatus: FetchStatus;
    createMeetingFileStatus: FetchStatus;
    updateMeetingFileStatus: FetchStatus;
    deleteMeetingFileStatus: FetchStatus;
    courseMeetingFiles: TMeetingFile[];
    courseMeetingPaginate: TPaginate | undefined;
    courseGroupMeetingFiles: TCourseGroupMeetingFile[];
    courseGroupMeetingPaginate: TPaginate | undefined;
    error: unknown;
}

const initialState: MeetingFilesState = {
    getMeetingFilesStatus: FetchStatus.INITIAL,
    createMeetingFileStatus: FetchStatus.INITIAL,
    updateMeetingFileStatus: FetchStatus.INITIAL,
    deleteMeetingFileStatus: FetchStatus.INITIAL,
    courseMeetingFiles: [],
    courseGroupMeetingFiles: [],
    courseGroupMeetingPaginate: undefined,
    courseMeetingPaginate: undefined,
    error: null,
};

export const meetingFilesSlice = createSlice<MeetingFilesState, SliceCaseReducers<MeetingFilesState>>({
    name: 'meetingFiles',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getCourseMeetingFilesAction.pending, (state) => {
                state.getMeetingFilesStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getCourseMeetingFilesAction.fulfilled, (state, { payload }) => {
                const { pagination, results } = payload;

                state.courseMeetingPaginate = pagination;
                state.courseMeetingFiles = results;
                state.error = null;
                state.getMeetingFilesStatus = FetchStatus.FETCHED;
            })
            .addCase(getCourseMeetingFilesAction.rejected, (state, { error }) => {
                state.getMeetingFilesStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getCourseGroupMeetingFilesAction.pending, (state) => {
                state.getMeetingFilesStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getCourseGroupMeetingFilesAction.fulfilled, (state, { payload }) => {
                const { pagination, results } = payload;

                state.courseGroupMeetingPaginate = pagination;
                state.courseGroupMeetingFiles = results;
                state.error = null;
                state.getMeetingFilesStatus = FetchStatus.FETCHED;
            })
            .addCase(getCourseGroupMeetingFilesAction.rejected, (state, { error }) => {
                state.getMeetingFilesStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(createMeetingFileAction.pending, (state) => {
                state.getMeetingFilesStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(createMeetingFileAction.fulfilled, (state, { payload }) => {
                const { isCourseGroup, list, uploadedBy } = payload;

                if (isCourseGroup) {
                    state.courseGroupMeetingFiles.push(list as TCourseGroupMeetingFile);
                } else {
                    if (uploadedBy) {
                        state.courseMeetingFiles.push({ ...(list as TMeetingFile), uploadedBy });
                    } else {
                        state.courseMeetingFiles.push(list as TMeetingFile);
                    }
                }
                state.error = null;
                state.getMeetingFilesStatus = FetchStatus.FETCHED;
            })
            .addCase(createMeetingFileAction.rejected, (state, { error }) => {
                state.getMeetingFilesStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(updateMeetingFileAction.pending, (state) => {
                state.getMeetingFilesStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(updateMeetingFileAction.fulfilled, (state, { payload }) => {
                const { isCourseGroup, updatedFile, courseGroupMeetingFiles } = payload;

                if (isCourseGroup) {
                    if (courseGroupMeetingFiles) {
                        state.courseGroupMeetingFiles = courseGroupMeetingFiles.results;
                        state.courseGroupMeetingPaginate = courseGroupMeetingFiles.pagination;
                    } else {
                        state.courseGroupMeetingFiles = state.courseGroupMeetingFiles.map((file) => {
                            if (file.id === updatedFile.id) {
                                return updatedFile as TCourseGroupMeetingFile;
                            }
                            return file;
                        });
                    }
                } else {
                    state.courseMeetingFiles = state.courseMeetingFiles.map((file) => {
                        if (file.id === updatedFile.id) {
                            return updatedFile as TMeetingFile;
                        }
                        return file;
                    });
                }
                state.error = null;
                state.getMeetingFilesStatus = FetchStatus.FETCHED;
            })
            .addCase(updateMeetingFileAction.rejected, (state, { error }) => {
                state.getMeetingFilesStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(deleteMeetingFileAction.pending, (state) => {
                state.getMeetingFilesStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(deleteMeetingFileAction.fulfilled, (state, { payload }) => {
                const { isCourseGroup, list } = payload;

                if (isCourseGroup) {
                    state.courseGroupMeetingFiles = state.courseGroupMeetingFiles.filter(
                        ({ id }) => !list.delete.includes(id),
                    );
                } else {
                    state.courseMeetingFiles = state.courseMeetingFiles.filter(({ id }) => !list.delete.includes(id));
                }
                state.error = null;
                state.getMeetingFilesStatus = FetchStatus.FETCHED;
            })
            .addCase(deleteMeetingFileAction.rejected, (state, { error }) => {
                state.getMeetingFilesStatus = FetchStatus.ERROR;
                state.error = error;
            });
    },
});

export const resetMeetingFilesState = meetingFilesSlice.actions.reset as ActionCreatorWithoutPayload;
export const meetingFilesReducer = meetingFilesSlice.reducer;

import moment from 'moment';

export interface DateComponents {
    month: number;
    year: number;
    dateWithoutTime?: string;
    time?: string;
    dateWithMonth?: string;
    hours: number;
    date: number;
    weekday: number;
}
// From lms-elements
export const getDateComponents = (date?: string | Date): DateComponents => {
    return {
        month: date ? moment(date)?.get('month') : moment().get('month'),
        year: date ? moment(date)?.get('year') : moment().get('year'),
        dateWithoutTime: date ? moment(date)?.format('YYYY-MM-DD') || moment().format('YYYY-MM-DD') : undefined,
        time: date ? moment(date)?.format('HH:mm') || moment(date).format('HH:mm') : undefined,
        dateWithMonth: date
            ? moment().isBefore(date) && moment().format('D') !== moment(date).format('D')
                ? getCapitalizeMonth(date)
                : 'сегодня'
            : undefined,
        date: moment(date).get('date'),
        hours: moment(date).hours(),
        weekday: moment(date).weekday(),
    };
};
export const getCapitalizeMonth = (date?: Date | string): string => {
    const result = moment(date).format('D MMMM');

    return result
        .split(' ')
        .map((word, index) => {
            if (index === 1) {
                return word[0].toUpperCase() + word.slice(1);
            }

            return word;
        })
        .join(' ');
};

import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { CourseDetails, ResponseCourse, ResponseCourseWithAssignments } from 'api/services/course';
import { ResponseTheme } from 'api/services/courseThemes';
import {
    blockCourseAction,
    changeCourseAction,
    CourseHoursWeekWithField,
    createCourseAction,
    deleteCourseAction,
    getAllCoursesForParallel,
    getCanFillCourseListAction,
    getCourseBySubjectAction,
    getCourseDetailsAction,
    getCoursesAction,
    getCoursesBySubject,
    getCourseWithThemesAction,
    publishCourseAction,
} from 'store/actions/course';
import { patchCourseGroupsAction } from 'store/actions/courseGroup';
import { changeThemeAction } from 'store/actions/courseThemes';
import { FetchStatus } from 'types/api';
import { CanFillCourse, CourseData } from 'types/course';
import { CourseGroup } from 'types/courseGroup';

export type AllCoursesCalendarData = { [calendarPlanId: number]: { courses: CourseData[] } };

export type AllCoursesParallelsData = {
    [parallelId: number]: AllCoursesCalendarData;
};

export type AllCourseHoursWeekData = { [courseId: number]: { hoursPerWeek: number } };

export type AllCalendarCourseHoursWeekData = { [calendarPlanId: number]: AllCourseHoursWeekData };

export interface CourseState {
    getStatus: FetchStatus;
    postStatus: FetchStatus;
    patchStatus: FetchStatus;
    deleteStatus: FetchStatus;
    publishStatus: FetchStatus;
    getAllCoursesStatus: FetchStatus;
    getCourseDetailStatus: FetchStatus;
    getCoursesHoursWeekStatus: FetchStatus;
    data: ResponseCourseWithAssignments[];
    allCoursesParallelsData: AllCoursesParallelsData;
    allCoursesCalendarData: AllCoursesCalendarData;
    courseHoursWeekWithField: CourseHoursWeekWithField;
    canFillCourse: CanFillCourse[];
    getStudCourses: FetchStatus;
    courses: ResponseCourse[];
    themes: ResponseTheme[];
    courseGroups: CourseGroup[];
    courseDetail: CourseDetails;
    error: unknown;
}

export const initialState: CourseState = {
    getStatus: FetchStatus.INITIAL,
    postStatus: FetchStatus.INITIAL,
    patchStatus: FetchStatus.INITIAL,
    publishStatus: FetchStatus.INITIAL,
    deleteStatus: FetchStatus.INITIAL,
    getStudCourses: FetchStatus.INITIAL,
    getAllCoursesStatus: FetchStatus.INITIAL,
    getCoursesHoursWeekStatus: FetchStatus.INITIAL,
    courses: [],
    data: [],
    themes: [],
    courseGroups: [],
    allCoursesParallelsData: {} as AllCoursesParallelsData,
    allCoursesCalendarData: {} as AllCoursesCalendarData,
    courseHoursWeekWithField: {} as CourseHoursWeekWithField,
    canFillCourse: [],
    getCourseDetailStatus: FetchStatus.INITIAL,
    courseDetail: {} as CourseDetails,
    error: null,
};

const courseSlice = createSlice<CourseState, SliceCaseReducers<CourseState>>({
    name: 'course',
    initialState,
    reducers: {
        reset: () => initialState,
        resetCourseCalendarParallelState: (state) => {
            state.allCoursesParallelsData = {} as AllCoursesParallelsData;
            state.allCoursesCalendarData = {} as AllCoursesCalendarData;
            state.courseHoursWeekWithField = {} as CourseHoursWeekWithField;
            state.getCoursesHoursWeekStatus = FetchStatus.INITIAL;
            state.getAllCoursesStatus = FetchStatus.INITIAL;
        },
        resetGetAllCoursesStatus: (state) => {
            state.getAllCoursesStatus = FetchStatus.INITIAL;
        },
        resetGetCoursesHoursWeekStatus: (state) => {
            state.getCoursesHoursWeekStatus = FetchStatus.INITIAL;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCoursesAction.pending, (state) => {
            state.getStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getCoursesAction.fulfilled, (state, { payload }) => {
            state.getStatus = FetchStatus.FETCHED;
            state.data = payload[0];
            state.canFillCourse = payload[1];
            state.error = null;
        });
        builder.addCase(getCoursesAction.rejected, (state, { error }) => {
            state.getStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(getCourseWithThemesAction.pending, (state) => {
            state.getStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getCourseWithThemesAction.fulfilled, (state, { payload }) => {
            state.getStatus = FetchStatus.FETCHED;
            state.courseDetail = payload[0];
            state.themes = payload[1];
            state.error = null;
        });
        builder.addCase(getCourseWithThemesAction.rejected, (state, { error }) => {
            state.getStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(getCanFillCourseListAction.pending, (state) => {
            state.getStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getCanFillCourseListAction.fulfilled, (state, { payload }) => {
            state.getStatus = FetchStatus.FETCHED;
            state.canFillCourse = payload;
            state.error = null;
        });
        builder.addCase(getCanFillCourseListAction.rejected, (state, { error }) => {
            state.getStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(getCoursesBySubject.fulfilled, (state, { payload }) => {
            state.data = payload.courses;
            state.courseGroups = payload.courseGroups;
            state.getStatus = FetchStatus.FETCHED;
        });
        builder.addCase(patchCourseGroupsAction.fulfilled, (state, { payload }) => {
            state.courseGroups = state.courseGroups.map((courseGroup) =>
                courseGroup.id === payload.id ? payload : courseGroup,
            );
        });

        builder.addCase(publishCourseAction.pending, (state) => {
            state.publishStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(publishCourseAction.fulfilled, (state) => {
            state.publishStatus = FetchStatus.FETCHED;
            state.error = null;
        });
        builder.addCase(publishCourseAction.rejected, (state, { error }) => {
            state.publishStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(createCourseAction.pending, (state) => {
            state.postStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(createCourseAction.fulfilled, (state, { payload }) => {
            state.postStatus = FetchStatus.FETCHED;
            state.data.push(payload);
            state.error = null;
        });
        builder.addCase(createCourseAction.rejected, (state, { error }) => {
            state.postStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(changeCourseAction.pending, (state) => {
            state.patchStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(changeCourseAction.fulfilled, (state, { payload }) => {
            state.patchStatus = FetchStatus.FETCHED;
            state.data = state.data.map((course) => {
                if (course.id === payload?.id) {
                    return {
                        ...payload,
                    };
                }

                return course;
            });
            state.error = null;
        });
        builder.addCase(changeCourseAction.rejected, (state, { error }) => {
            state.patchStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(deleteCourseAction.pending, (state) => {
            state.deleteStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(deleteCourseAction.fulfilled, (state, { payload }) => {
            state.deleteStatus = FetchStatus.FETCHED;
            state.data = state.data.filter((course) => course.id !== payload);
            state.error = null;
        });
        builder.addCase(deleteCourseAction.rejected, (state, { error }) => {
            state.deleteStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(getCourseDetailsAction.pending, (state) => {
            state.getCourseDetailStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getCourseDetailsAction.fulfilled, (state, { payload }) => {
            state.getCourseDetailStatus = FetchStatus.FETCHED;
            state.courseDetail = payload;
            state.error = null;
        });
        builder.addCase(getCourseDetailsAction.rejected, (state, { error }) => {
            state.getCourseDetailStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(changeThemeAction.fulfilled, (state, { payload }) => {
            state.courses = state.courses.map((stateCourse) => {
                if (stateCourse.id === payload.course.id) {
                    return {
                        ...stateCourse,
                        endToEndGeneralLessonOrdering: payload.course.endToEndGeneralLessonOrdering,
                    };
                }

                return stateCourse;
            });
        });
        builder
            .addCase(getCourseBySubjectAction.pending, (state) => {
                state.getStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getCourseBySubjectAction.fulfilled, (state, { payload }) => {
                state.getStatus = FetchStatus.FETCHED;
                state.data = payload;
                state.error = null;
            })
            .addCase(getCourseBySubjectAction.rejected, (state, { error }) => {
                state.getStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder.addCase(blockCourseAction.fulfilled, (state, { payload }) => {
            if (state.courseDetail.id === payload.id) {
                state.courseDetail = {
                    ...state.courseDetail,
                    isBlockEdit: payload.isBlockEdit,
                };
            }
        });

        builder
            .addCase(getAllCoursesForParallel.pending, (state) => {
                state.getAllCoursesStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getAllCoursesForParallel.fulfilled, (state, { payload }) => {
                state.getAllCoursesStatus = FetchStatus.FETCHED;
                state.allCoursesCalendarData[payload.calendarPlanId] = {
                    courses: payload.courses,
                };
                state.allCoursesParallelsData[payload.parallelId] = state.allCoursesCalendarData;
                state.error = null;
            })
            .addCase(getAllCoursesForParallel.rejected, (state, { error }) => {
                state.getAllCoursesStatus = FetchStatus.ERROR;
                state.error = error;
            });
    },
});

export const resetCourseState = courseSlice.actions.reset as ActionCreatorWithoutPayload<string>;

export const resetCourseCalendarParallelState = courseSlice.actions
    .resetCourseCalendarParallelState as ActionCreatorWithoutPayload<string>;

export const resetGetAllCoursesStatus = courseSlice.actions
    .resetGetAllCoursesStatus as ActionCreatorWithoutPayload<string>;

export const resetGetCoursesHoursWeekStatus = courseSlice.actions
    .resetGetCoursesHoursWeekStatus as ActionCreatorWithoutPayload<string>;

export const courseReducer = courseSlice.reducer;

export enum EventTypes {
    TEST = 'test_lesson',
}

export enum EventActions {
    JOIN = 'join',
    LEAVE = 'leave',
}

export interface StudentEvent {
    id: number;
    date: string;
    course: string;
    isStarted: boolean;
    subjectId?: number;
    lessonId?: number;
    courseGroupId?: number;
}

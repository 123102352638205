import React from 'react';
import { cn } from '@bem-react/classname';
import { IDropdownValues } from '@lms-elements/atomic';
import { AnswerAttemptPostResponse } from 'api/services/answerAttempt';
import { getBestAttempt } from 'containers/StudentTask/utils';

import { AttendanceMark } from 'components/AttendanceMark';

const CnMarkClassName = cn('attemptDropdownMark');

export const getAttemptLabel = (attempt: Pick<AnswerAttemptPostResponse, 'attempt'>): string =>
    `Попытка №${attempt.attempt}, оценка`;

export const getAttemptDropdownOptions = (
    attempt: Pick<AnswerAttemptPostResponse, 'id' | 'mark' | 'attempt'>,
): IDropdownValues => ({
    id: attempt.id,
    title: getAttemptLabel(attempt),
    children: (
        <span className={CnMarkClassName()}>
            <AttendanceMark marks={attempt.mark ? [attempt.mark] : []} isNeedAddMark={false} needHideWeight />
        </span>
    ),
});

export const getAttemptDropdownOptionsList = (
    attempts: Pick<AnswerAttemptPostResponse, 'id' | 'mark' | 'attempt'>[],
): IDropdownValues[] => attempts.map(getAttemptDropdownOptions);

export const getAttemptsIdsByLabels = (
    attempts: Pick<AnswerAttemptPostResponse, 'attempt' | 'mark' | 'id'>[],
): Record<string, number> => {
    const attemptsIdsByLabelsAccumulator: Record<string, number> = {};

    attempts?.forEach((attempt) => {
        attemptsIdsByLabelsAccumulator[getAttemptLabel(attempt)] = attempt.id;
    });

    return attemptsIdsByLabelsAccumulator;
};

export const getAttemptByLabel = (
    attempts: AnswerAttemptPostResponse[],
    label: string,
): AnswerAttemptPostResponse | null =>
    attempts.find((attempt) => attempt.id === getAttemptsIdsByLabels(attempts)[label]) || getBestAttempt(attempts);

import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { AssignmentResponse } from 'types/assignment';

export interface PatchAssignmentParams {
    id: number;
    title?: string;
    lesson?: number;
    type?: number;
    description?: string;
    isTemplate?: boolean;
    courseGroup?: number;
}

export const patchAssignment = (params: PatchAssignmentParams): Promise<AssignmentResponse> => {
    const { id, isTemplate, courseGroup, ...value } = params;

    return api
        .patch<Promise<AssignmentResponse>, AxiosResponse<AssignmentResponse>>(`/assignment/partial-update/${id}/`, {
            ...value,
            isTemplate,
            courseGroup: isTemplate ? undefined : courseGroup,
        })
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

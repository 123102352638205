import { useMemo } from 'react';
import { useQuery } from 'hooks/useQuery';
import { HomeworkEnum } from 'types/homework';

export enum HomeworkDisplay {
    CHECKED = 'checked',
    PENDING_CHECK = 'pending_check',
    OVERDUE = 'overdue',
    TOMORROW = 'tomorrow',
    ARCHIVE = 'archive',
    ALL = 'all',
    AVAILABLE_FOR_EXECUTION = 'available_for_execution',
}

interface HomeworkFiltersParams {
    display: HomeworkDisplay;
    filterCourses: string | null;
    filterTypes: HomeworkEnum | null;
}

export function useGetHomeworkFilters(isStudent: boolean): HomeworkFiltersParams {
    const query = useQuery();

    const display = useMemo((): HomeworkDisplay => {
        const displayQuery = query.get('display');
        switch (true) {
            case isStudent && displayQuery === 'overdue':
                return HomeworkDisplay.OVERDUE;
            case isStudent && displayQuery === 'tomorrow':
                return HomeworkDisplay.TOMORROW;
            case isStudent && displayQuery === 'archive':
                return HomeworkDisplay.ARCHIVE;
            case isStudent && displayQuery === 'available_for_execution':
                return HomeworkDisplay.AVAILABLE_FOR_EXECUTION;
            case isStudent:
                return HomeworkDisplay.ALL;
            case displayQuery === 'checked':
                return HomeworkDisplay.CHECKED;
            default:
                return HomeworkDisplay.PENDING_CHECK;
        }
    }, [query, isStudent]);
    const filterCourses = useMemo(() => query.get('filterCourses'), [query]);
    const filterTypes = useMemo(() => query.get('filterTypes') as HomeworkEnum, [query]);

    return {
        display,
        filterCourses,
        filterTypes,
    };
}

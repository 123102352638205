import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { oauthAction } from 'store/actions/oauth';
import { FetchStatus } from 'types/api';

export interface OAuthState {
    oauthStatus: FetchStatus;
    error: unknown;
}

const initialState: OAuthState = {
    oauthStatus: FetchStatus.INITIAL,
    error: null,
};

const oauthSlice = createSlice<OAuthState, SliceCaseReducers<OAuthState>>({
    name: 'oauth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(oauthAction.pending, (state) => {
                state.oauthStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(oauthAction.fulfilled, (state) => {
                state.oauthStatus = FetchStatus.FETCHED;
            })
            .addCase(oauthAction.rejected, (state, { error }) => {
                state.oauthStatus = FetchStatus.ERROR;
                state.error = error;
            });
    },
});

export const oauthReducer = oauthSlice.reducer;

import { Dayjs } from 'dayjs';
import moment from 'moment';

export const getCurrentTime = (format?: string): string => {
    const offset = new Date().getTimezoneOffset();
    const timezone = -offset / 60;

    return moment()
        .utcOffset(timezone)
        .format(format || 'HH:mm');
};
export const getCurrentTimeByMoscow = (format?: string): string => {
    const moscow_offset = 3 * 60;

    return moment()
        .utcOffset(moscow_offset)
        .format(format || 'HH:mm');
};

export const getCurrenData = (format: 'DD MMMM YYYY' | 'YYYY-MM-DD'): string => {
    const offset = new Date().getTimezoneOffset();
    const timezone = -offset / 60;

    return moment().utcOffset(timezone).format(format);
};

export const getCurrentDateByMoscow = (): string => {
    const moscow_offset = 3 * 60;

    return moment().utcOffset(moscow_offset).toISOString();
};

export const getCurrentDay = (date: string): string => {
    return moment(date).format('DD MMMM YYYY');
};

export const getCorrectDateFromDayjs = (date: Dayjs, format: 'DD MMMM YYYY' | 'YYYY-MM-DD'): string => {
    return moment(`${date}`).format(format);
};

export const isTimeExpired = (timeEnd: string): boolean => {
    const offset = new Date().getTimezoneOffset();
    const timezone = -offset / 60;

    const currentDate = moment().utcOffset(timezone);
    const dateToCheck = moment(timeEnd).utcOffset(timezone);

    return dateToCheck.isBefore(currentDate);
};

export const isValidInterval = (timeStart: string, timeEnd: string): boolean => {
    const start = moment(timeStart);
    const end = moment(timeEnd);

    return end.isSameOrAfter(start);
};

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGetPopupsState } from 'src-new/components/layouts/Modals/Modals.utils';
import { isEmptyList } from 'src-new/utils';
import { loaded, loading } from 'store/reducers/loader';
import { FetchStatus } from 'types/api';

export const useLoader = (statuses: FetchStatus[]): void => {
    const dispatch = useDispatch();

    const { mountedPopups } = useGetPopupsState();

    useEffect(() => {
        const isFetching = statuses.reduce((result, status) => {
            return result || status === FetchStatus.FETCHING;
        }, false);

        // Если есть открытые сложные модалки, то не фетчим контент
        if (isFetching && isEmptyList(mountedPopups)) {
            dispatch(loading());
        } else {
            dispatch(loaded());
        }
    }, [statuses, dispatch]);
};

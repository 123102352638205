import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import {
    FileLink,
    HistoryQuestion,
    PostQuestionResponse,
    ResponseQuestion,
    ResponseQuestionDetailed,
} from 'api/services/questionBank';
import {
    deleteQuestionAction,
    getAssignmentProgressQuestionsAction,
    getHistoryQuestionAction,
    getQuestionDetailedAction,
    getQuestionsAction,
    partialUpdateQuestionAction,
    patchQuestionAction,
    postQuestionAction,
    postQuestionFileAction,
    refreshStatusesQuestions,
    setMaterialIdToEdit,
} from 'store/actions/questionBank';
import { FetchStatus } from 'types/api';

export interface QuestionsState {
    fetchStatusQuestions: FetchStatus;
    fetchStatusDetailedQuestion: FetchStatus;
    fetchHistoryStatus: FetchStatus;
    postStatusQuestion: FetchStatus;
    patchStatusQuestion: FetchStatus;
    partialUpdateStatusQuestion: FetchStatus;
    deleteStatusQuestion: FetchStatus;
    postStatusQuestionFile: FetchStatus;
    patchStatusQuestionFile: FetchStatus;
    questions: ResponseQuestion[];
    detailedQuestion: ResponseQuestionDetailed;
    questionCreateId: number;
    questionCreated: PostQuestionResponse | undefined;
    editedQuestionsList: { oldId: number; newId: number }[];
    studentQuestions: ResponseQuestion[];
    fileLink: FileLink[];
    historyQuestion: HistoryQuestion[];
    error: unknown;
    materialIdToEdit: number;
}

export const initialState: QuestionsState = {
    fetchStatusQuestions: FetchStatus.INITIAL,
    fetchStatusDetailedQuestion: FetchStatus.INITIAL,
    fetchHistoryStatus: FetchStatus.INITIAL,
    postStatusQuestion: FetchStatus.INITIAL,
    patchStatusQuestion: FetchStatus.INITIAL,
    partialUpdateStatusQuestion: FetchStatus.INITIAL,
    deleteStatusQuestion: FetchStatus.INITIAL,
    postStatusQuestionFile: FetchStatus.INITIAL,
    patchStatusQuestionFile: FetchStatus.INITIAL,
    questions: [],
    historyQuestion: [],
    detailedQuestion: {} as ResponseQuestionDetailed,
    questionCreateId: 0,
    studentQuestions: [],
    editedQuestionsList: [],
    fileLink: [],
    questionCreated: undefined,
    error: undefined,
    materialIdToEdit: 0,
};

const questionsSlice = createSlice<QuestionsState, SliceCaseReducers<QuestionsState>>({
    name: 'questions',
    initialState,
    reducers: {
        resetPostQuestion(state) {
            state.postStatusQuestion = FetchStatus.INITIAL;
            state.questionCreateId = 0;
        },
        reset(state) {
            state.fetchStatusQuestions = FetchStatus.INITIAL;
            state.fetchStatusDetailedQuestion = FetchStatus.INITIAL;
            state.postStatusQuestion = FetchStatus.INITIAL;
            state.patchStatusQuestion = FetchStatus.INITIAL;
            state.deleteStatusQuestion = FetchStatus.INITIAL;
            state.postStatusQuestionFile = FetchStatus.INITIAL;
            state.patchStatusQuestionFile = FetchStatus.INITIAL;
            state.questions = [];
            state.detailedQuestion = {} as ResponseQuestionDetailed;
            state.questionCreateId = 0;
            state.studentQuestions = [];
            state.editedQuestionsList = [];
            state.error = undefined;
            state.fileLink = [];
            state.materialIdToEdit = 0;
        },
        resetStatusDetailedQuestion(state) {
            state.fetchStatusDetailedQuestion = FetchStatus.INITIAL;
        },
        resetStatusPartialUpdateQuestion(state) {
            state.partialUpdateStatusQuestion = FetchStatus.INITIAL;
        },
        resetQuestionCreated(state) {
            state.postStatusQuestion = FetchStatus.INITIAL;
            state.questionCreateId = 0;
            state.questionCreated = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getQuestionsAction.pending, (state) => {
                state.fetchStatusQuestions = FetchStatus.FETCHING;
                state.error = undefined;
            })
            .addCase(getQuestionsAction.fulfilled, (state, { payload }) => {
                state.fetchStatusQuestions = FetchStatus.FETCHED;
                state.questions = payload;
                state.error = undefined;
            })
            .addCase(getQuestionsAction.rejected, (state, { error }) => {
                state.fetchStatusQuestions = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(postQuestionAction.pending, (state) => {
                state.postStatusQuestion = FetchStatus.FETCHING;
                state.error = undefined;
            })
            .addCase(postQuestionAction.fulfilled, (state, { payload }) => {
                state.postStatusQuestion = FetchStatus.FETCHED;
                state.questionCreateId = payload.id;
                state.questionCreated = payload;
                state.error = undefined;
            })
            .addCase(postQuestionAction.rejected, (state, { error }) => {
                state.postStatusQuestion = FetchStatus.ERROR;
                state.error = error;
            });
        builder.addCase(refreshStatusesQuestions, (state) => {
            state.fetchStatusDetailedQuestion = FetchStatus.INITIAL;
            state.postStatusQuestion = FetchStatus.INITIAL;
        });
        builder
            .addCase(getQuestionDetailedAction.pending, (state) => {
                state.fetchStatusDetailedQuestion = FetchStatus.FETCHING;
                state.error = undefined;
            })
            .addCase(getQuestionDetailedAction.fulfilled, (state, { payload }) => {
                state.fetchStatusDetailedQuestion = FetchStatus.FETCHED;
                const content = typeof payload.content === 'string' ? payload.content : JSON.stringify(payload.content);
                state.detailedQuestion = { ...payload, content };
                state.error = undefined;
            })
            .addCase(getQuestionDetailedAction.rejected, (state, { error }) => {
                state.fetchStatusDetailedQuestion = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(deleteQuestionAction.pending, (state) => {
                state.deleteStatusQuestion = FetchStatus.FETCHING;
            })
            .addCase(deleteQuestionAction.fulfilled, (state, { payload }) => {
                state.deleteStatusQuestion = FetchStatus.FETCHED;
                state.questions = state.questions.filter((question) => question.id !== payload);
                state.error = undefined;
            })
            .addCase(deleteQuestionAction.rejected, (state, { error }) => {
                state.deleteStatusQuestion = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(patchQuestionAction.pending, (state) => {
                state.patchStatusQuestion = FetchStatus.FETCHING;
            })
            .addCase(patchQuestionAction.fulfilled, (state, { payload }) => {
                // @ts-ignore
                state.editedQuestionsList = [{ ...payload }];

                state.patchStatusQuestion = FetchStatus.FETCHED;
                state.error = undefined;
            })
            .addCase(patchQuestionAction.rejected, (state, { error }) => {
                state.patchStatusQuestion = FetchStatus.ERROR;
                state.error = error;
            });
        builder.addCase(setMaterialIdToEdit.fulfilled, (state, { payload }) => {
            state.materialIdToEdit = payload;
        });
        builder
            .addCase(getAssignmentProgressQuestionsAction.pending, (state) => {
                state.fetchStatusQuestions = FetchStatus.FETCHING;
                state.error = undefined;
            })
            .addCase(getAssignmentProgressQuestionsAction.fulfilled, (state, { payload }) => {
                state.fetchStatusQuestions = FetchStatus.FETCHED;
                state.studentQuestions = payload;
                state.error = undefined;
            })
            .addCase(getAssignmentProgressQuestionsAction.rejected, (state, { error }) => {
                state.fetchStatusQuestions = FetchStatus.ERROR;
                state.error = error;
            });

        builder
            .addCase(postQuestionFileAction.pending, (state) => {
                state.postStatusQuestionFile = FetchStatus.FETCHING;
                state.error = undefined;
            })
            .addCase(postQuestionFileAction.fulfilled, (state, { payload }) => {
                state.postStatusQuestionFile = FetchStatus.FETCHED;
                state.fileLink.push(payload);
                state.error = undefined;
            })
            .addCase(postQuestionFileAction.rejected, (state, { error }) => {
                state.postStatusQuestionFile = FetchStatus.ERROR;
                state.error = error;
            });

        builder
            .addCase(partialUpdateQuestionAction.pending, (state) => {
                state.partialUpdateStatusQuestion = FetchStatus.FETCHING;
                state.error = undefined;
            })
            .addCase(partialUpdateQuestionAction.fulfilled, (state) => {
                state.partialUpdateStatusQuestion = FetchStatus.FETCHED;
                state.error = undefined;
            })
            .addCase(partialUpdateQuestionAction.rejected, (state, { error }) => {
                state.partialUpdateStatusQuestion = FetchStatus.ERROR;
                state.error = error;
            });

        builder
            .addCase(getHistoryQuestionAction.pending, (state) => {
                state.fetchHistoryStatus = FetchStatus.FETCHING;
                state.error = undefined;
            })
            .addCase(getHistoryQuestionAction.fulfilled, (state, { payload }) => {
                state.fetchHistoryStatus = FetchStatus.FETCHED;
                state.historyQuestion = payload;
                state.error = undefined;
            })
            .addCase(getHistoryQuestionAction.rejected, (state, { error }) => {
                state.fetchHistoryStatus = FetchStatus.ERROR;
                state.error = error;
            });
    },
});

export const resetStatusDetailedQuestion = questionsSlice.actions
    .resetStatusDetailedQuestion as ActionCreatorWithoutPayload<string>;
export const resetPostQuestion = questionsSlice.actions.resetPostQuestion as ActionCreatorWithoutPayload<string>;
export const resetQuestionCreated = questionsSlice.actions.resetQuestionCreated as ActionCreatorWithoutPayload<string>;
export const resetQuestionBankState = questionsSlice.actions.reset as ActionCreatorWithoutPayload<string>;
export const resetStatusPartialUpdateQuestion = questionsSlice.actions
    .resetStatusPartialUpdateQuestion as ActionCreatorWithoutPayload<string>;
export const questionsReducer = questionsSlice.reducer;

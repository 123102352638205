import { questionsBankPageData } from 'constants/cypress/question-bank-page';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Checkbox } from '@lms-elements/atomic';
import { useCodifierContext } from 'containers/CodifierSelector/context';
import i18next from 'i18next';
import { useAppSelector } from 'store/store';

import { CodifierEventType } from 'components/Codifier/Codifier';

import './TopicElement.scss';

const cx = cn('topic-element');

interface TopicElementProps {
    topicId: number;
    topicIndex?: number;
}

export const TopicElement: React.FC<TopicElementProps> = React.memo(({ topicId, topicIndex }) => {
    const topicData = useAppSelector((store) => store.codifier.topicsMap[topicId]);

    const {
        onElementClick,
        selectedElement: { topics },
        withCheckboxes,
    } = useCodifierContext();

    const [isSelected, setIsSelected] = useState(
        withCheckboxes ? Boolean(topics?.includes(topicId)) : Boolean(topics?.length) && topics?.[0] === topicId,
    );

    useEffect(() => {
        setIsSelected(
            withCheckboxes ? Boolean(topics?.includes(topicId)) : Boolean(topics?.length) && topics?.[0] === topicId,
        );
    }, [topics?.length, withCheckboxes, topics, topicId]);

    const handleSubjectClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement>) => {
            e.stopPropagation();
            if ((!isSelected || withCheckboxes) && onElementClick) {
                onElementClick(CodifierEventType.TOPIC, topicId);
            }
        },
        [isSelected, onElementClick, topicId, withCheckboxes],
    );

    const questionsInfo = useMemo(
        () =>
            topicData.questionsQuantity
                ? `${i18next.t('questions', {
                      count: topicData.questionsQuantity,
                  })}`
                : 'нет вопросов',
        [topicData.questionsQuantity],
    );

    const revisionQuestions = `${topicData.questionsQuantityRevision} на доработке`;

    const unpublishedQuestions = i18next.t('unpublished', { count: topicData.questionsQuantityUnpublished });

    const questionUnpublished = useMemo(() => {
        if (topicData.questionsQuantityUnpublished && topicData.questionsQuantityRevision) {
            return `(${unpublishedQuestions}, ${revisionQuestions})`;
        }
        if (topicData.questionsQuantityUnpublished) {
            return `(${unpublishedQuestions})`;
        }
        if (topicData.questionsQuantityRevision) {
            return `(${revisionQuestions})`;
        }
        return '';
    }, [topicData.questionsQuantityUnpublished, topicData.questionsQuantityRevision]);

    return (
        <div
            className={cx()}
            data-cy={typeof topicIndex === 'number' ? questionsBankPageData.getTopicContainer(topicIndex) : undefined}
        >
            <div className={cx('container', { selected: isSelected && !withCheckboxes })}>
                {withCheckboxes && (
                    <Checkbox defaultChecked={isSelected} onChange={handleSubjectClick} label={topicData.title} />
                )}
                {!withCheckboxes && (
                    <>
                        <div className={cx('header', { selected: isSelected })} onClick={handleSubjectClick}>
                            <div className={cx('title')}>{topicData.title}</div>
                        </div>
                        <div className={cx('questions-container')}>
                            <div className={cx('questions-label', { inactive: !topicData.questionsQuantity })}>
                                {questionsInfo}
                            </div>
                            <div
                                className={cx('questions-label', {
                                    inactive: true,
                                })}
                            >
                                {questionUnpublished}
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
});

import React, { useMemo } from 'react';
import { PlusIcon } from 'assets';
import { AppProgressBar } from 'src-new/ui';

import { BarItemDescription, BarItemIcon } from './bar-item-entities';
import { TQuestionsListBarProps } from './QuestionsListBar.types';

import './style.scss';

export const QuestionsListBar: React.FC<TQuestionsListBarProps> = (props) => {
    const {
        questionList,
        handleChangeList,
        handleQuestionClick,
        orientation = 'vertical',
        activeNumber = 1,
        isNoType,
    } = props;

    const handleAddEmptyQuestion = (questionNum: number) => {
        handleChangeList(questionNum, 'add');
    };
    const handleDeleteQuestion = (questionNum: number) => {
        handleChangeList(questionNum, 'delete');
    };

    const questionsListNormalized = useMemo(() => {
        const list = questionList.map((question) => {
            const { questionNumber, questionsCount, searchBlocksCount } = question;
            const numText = questionNumber === 1 ? `№ 1` : questionNumber;
            const isEmptyQuestion = !questionsCount && !searchBlocksCount;
            const isLastEmptyQuestion = questionList.length === 1 && isEmptyQuestion;
            const numClass = orientation === 'horizontal' && activeNumber !== questionNumber ? 'common' : 'active';

            return {
                title: '',
                icon: (
                    <BarItemIcon
                        disabled={!!isNoType}
                        numClass={numClass}
                        numText={`${numText}`}
                        handleQuestionClick={handleQuestionClick.bind(this, questionNumber)}
                    />
                ),
                description: (
                    <BarItemDescription
                        orientation={orientation}
                        isEmptyQuestion={isEmptyQuestion}
                        questionNumber={questionNumber}
                        isNoType={!!isNoType}
                        questionsCount={questionsCount}
                        searchBlocksCount={searchBlocksCount}
                        handleQuestionClick={handleQuestionClick}
                        handleDeleteQuestion={handleDeleteQuestion}
                        isLastEmptyQuestion={isLastEmptyQuestion}
                    />
                ),
            };
        });
        list.push({
            title: '',
            icon: (
                <div
                    className={`question-number add ${isNoType ? 'disabled' : ''}`}
                    onClick={handleAddEmptyQuestion.bind(this, list.length + 1)}
                >
                    <PlusIcon />
                </div>
            ),
            description: <></>,
        });
        return list;
    }, [questionList, activeNumber, isNoType, orientation, handleQuestionClick]);

    return (
        <div className={'questions-list-bar'}>
            <AppProgressBar current={0} initial={0} direction={orientation} items={questionsListNormalized} />
        </div>
    );
};

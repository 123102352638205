import { BlockMap, EditorState, Modifier } from 'draft-js';
import { getSelectedBlock } from 'draftjs-utils';
// @ts-ignore
import htmlToDraft from 'html-to-draftjs';
import { List, Map } from 'immutable';
import { ActionBlockTypes, CommonBlocks } from 'src-new/components/lms-elements/CustomEditor/types/shared.types';

const shouldUseText = (blockType: string) =>
    blockType === CommonBlocks.CODE ||
    blockType === ActionBlockTypes.INFO_BLOCK ||
    blockType === ActionBlockTypes.WARNING_BLOCK ||
    blockType === ActionBlockTypes.THEOREM_BLOCK ||
    blockType === ActionBlockTypes.QUOTE_BLOCK ||
    blockType === ActionBlockTypes.DEFINITION_BLOCK;

export const handlePastedText = (
    text: string,
    html: string,
    editorState: EditorState,
    onChange: (state: EditorState) => void,
    savePastedStyles: boolean,
): boolean => {
    const selectedBlock = getSelectedBlock(editorState);
    const blockType = selectedBlock?.getType();
    if (shouldUseText(blockType)) {
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            text,
            editorState.getCurrentInlineStyle(),
        );

        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
        return true;
    }

    if (html && savePastedStyles) {
        const contentBlock = htmlToDraft(html);
        let contentState = editorState.getCurrentContent();
        (contentBlock.entityMap as Map<string, Record<string, unknown>>).forEach((value, key) => {
            if (key && value) {
                contentState = contentState.mergeEntityData(key, value);
            }
        });
        contentState = Modifier.replaceWithFragment(
            contentState,
            editorState.getSelection(),
            (List(contentBlock.contentBlocks) as unknown) as BlockMap,
        );
        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
        return true;
    }

    if (!savePastedStyles) {
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            text,
            editorState.getCurrentInlineStyle(),
        );

        onChange(EditorState.push(editorState, contentState, 'insert-characters'));
        return true;
    }

    return false;
};

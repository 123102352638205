import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    createRevisionQuestion,
    deleteRevisionQuestion,
    PatchRevisionQuestionParams,
    PostRevisionQuestionParams,
    updateRevisionQuestion,
} from 'api/services/revisionQuestion';

export const createRevisionQuestionAction = createAsyncThunk(
    'revision/createRevisionQuestion',
    async (params: PostRevisionQuestionParams) => await createRevisionQuestion(params),
);

export const updateRevisionQuestionAction = createAsyncThunk(
    'revision/updateRevisionQuestion',
    async (params: PatchRevisionQuestionParams) => await updateRevisionQuestion(params),
);

export const deleteRevisionQuestionAction = createAsyncThunk(
    'revision/deleteRevisionQuestion',
    async (id: number) => await deleteRevisionQuestion(id),
);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getSchoolPartners } from 'api/services/schoolPartner';
import { getStudentsStatistics, getStudentsStatisticsTariff } from 'api/services/student';
import { getStudentGroupStatisticsParallels } from 'api/services/studentGroups/getStudentGroupStatisticsParallels';
import { getStudentGroupStatistics } from 'api/services/studentGroups/getStudentsGroupStatistics';
import { getStudentGroupStatisticsTariffs } from 'api/services/studentGroups/getStudentsGroupStatisticsTariffs';

export const getActiveClassesAction = createAsyncThunk('activeClasses', () => {
    return Promise.all([
        getSchoolPartners(),
        getStudentsStatistics(),
        getStudentsStatisticsTariff(),
        getStudentGroupStatistics(),
        getStudentGroupStatisticsTariffs(),
        getStudentGroupStatisticsParallels(),
    ]);
});

import {
    IExercise,
    IInsertWordsCheckedAnswer,
    IQuestionResponse,
    ITestAnswerData,
    ITestCheckedAnswer,
    ITextCheckedAnswer,
    ITrueFalseCheckedAnswer,
    TCheckTrainingQuestionResponse,
    TQuestionType,
} from '@fsd-shared/models/materials';

import { normalizeInsertWordCorrectAnswerData, normalizeInsertWordQuestion } from './insertWordsQuestionNormalizer';
import { normalizeTestAnswerData, normalizeTestCorrectAnswerData } from './testQuestionNormalizer';
import { normalizeTextCorrectAnswerData } from './textQuestionNormalizer';
import { normalizeTrueFalseAnswerData, normalizeTrueFalseCorrectAnswerData } from './trueFasleQuestionNormalizer';

const normalizeAnswerData = (questionData: IQuestionResponse['data'], type: TQuestionType): IExercise['answerData'] => {
    switch (type) {
        case 'true_false':
            return normalizeTrueFalseAnswerData();
        case 'test':
            return normalizeTestAnswerData(questionData);
        default:
            return {};
    }
};

const normalizeExerciseQuestion = (question: IQuestionResponse): IExercise['question'] => {
    const { text, html, answersType } = question;
    const questionValue = html || text;
    switch (answersType) {
        case 'insert_words':
            return normalizeInsertWordQuestion(questionValue);
        default:
            return questionValue;
    }
};
export const normalizeQuestion = (question: IQuestionResponse | undefined): IExercise | undefined => {
    if (question) {
        const { data, answersType, multiply, id } = question;
        return {
            id: id,
            type: answersType,
            multiply: multiply,
            question: normalizeExerciseQuestion(question),
            answerData: normalizeAnswerData(data, answersType),
        };
    }
    return question;
};

const normalizeCorrectAnswerData = (
    type: TQuestionType,
    questionData: IExercise['answerData'],
    correctAnswer: TCheckTrainingQuestionResponse,
): IExercise['answerData'] => {
    switch (type) {
        case 'true_false':
            return normalizeTrueFalseCorrectAnswerData(correctAnswer as ITrueFalseCheckedAnswer);
        case 'test':
            return normalizeTestCorrectAnswerData(questionData as ITestAnswerData, correctAnswer as ITestCheckedAnswer);
        case 'insert_words':
            return normalizeInsertWordCorrectAnswerData(correctAnswer as IInsertWordsCheckedAnswer);
        case 'text':
            return normalizeTextCorrectAnswerData(correctAnswer as ITextCheckedAnswer);
        default:
            return {};
    }
};

export const normalizeCorrectAnswerToQuestion = (
    correctAnswer: TCheckTrainingQuestionResponse,
    question?: IExercise,
): IExercise | undefined => {
    if (question) {
        const { type, answerData } = question;

        return {
            ...question,
            answerData: normalizeCorrectAnswerData(type, answerData, correctAnswer),
        };
    }

    return undefined;
};

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ValidationErrors } from 'final-form';
import { setFormError } from 'store/reducers/formError';

export const useFormError = (formName: string): ((state: { errors: ValidationErrors }) => void) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState<ValidationErrors>();

    useEffect(() => {
        if (errors) {
            dispatch(setFormError({ errors, formName }));
        }
    }, [errors, dispatch, formName]);

    const handleFormSpyChange = useCallback((state: { errors: ValidationErrors }) => {
        setTimeout(() => {
            setErrors(state.errors);
        }, 0);
    }, []);

    return handleFormSpyChange;
};

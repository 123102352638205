import { CourseGroup } from 'types/courseGroup';
import { Student } from 'types/students';

type CourseGroupsMap = {
    [groupId: number]: CourseGroup & { endToEndLessonOrdering?: number[]; students?: Student[] };
};

export const getCourseGroupsMap = (
    courseGroups: (CourseGroup & { endToEndLessonOrdering?: number[]; students?: Student[] })[],
    studentsData?: { id: number; students: Student[] },
): CourseGroupsMap => {
    return courseGroups.reduce((groupsMap, group) => {
        if (studentsData?.id === group.id) {
            groupsMap[group.id] = {
                ...group,
                students: studentsData.students,
            };
        } else {
            groupsMap[group.id] = group;
        }

        return groupsMap;
    }, {} as CourseGroupsMap);
};

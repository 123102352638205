import React, { useEffect } from 'react';
import { useOGChatWidget } from '@fsd-shared/hooks/use-og-chat-widget';
import * as Sentry from '@sentry/react';
import { TARIFF_CURIOUS_TITLE } from 'App/constants';
import { useCheckPackageVersion } from 'hooks';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';

// import { gteUserLink } from './WithChatwoot.utils';

export const WithChatwoot: React.FC = ({ children }) => {
    const { userInfo, userInfofetchStatus, logoutStatus } = useAppSelector((store) => ({
        userInfo: store.user.info,
        userInfofetchStatus: store.user.fetchStatus,
        logoutStatus: store.auth.logoutFetchStatus,
    }));

    useCheckPackageVersion();

    useEffect(() => {
        if (userInfofetchStatus === FetchStatus.FETCHED && userInfo && !(window as { Cypress?: boolean }).Cypress) {
            const { id, email, userRoles, firstName, lastName, patronymic, photoUrl } = userInfo;

            // todo добавить если надо будет
            // const link = gteUserLink(userInfo);

            window.$chatwoot?.setUser(id, {
                name: `${lastName} ${firstName} ${patronymic}`,
                email: email || '',
                avatar_url: photoUrl,
                userRoles: userRoles || [],
                link: '',
            });

            Sentry.setUser({
                id: String(userInfo.id),
                email: userInfo.email,
                username: `${userInfo?.lastName ?? ''} ${userInfo?.firstName ?? ''}`,
            });
        }

        if (
            !userInfo &&
            logoutStatus === FetchStatus.FETCHED &&
            ((window as unknown) as { $chatwoot?: { reset: () => void } }).$chatwoot &&
            !(window as { Cypress?: boolean }).Cypress
        ) {
            ((window as unknown) as { $chatwoot?: { reset: () => void } }).$chatwoot?.reset();

            Sentry.configureScope((scope) => scope.setUser(null));
        }
    }, [userInfofetchStatus, userInfo, logoutStatus]);

    useOGChatWidget(userInfo && userInfo?.tariffTitle !== TARIFF_CURIOUS_TITLE);
    return <>{children}</>;
};

import { TARIFF_CURIOUS_TITLE } from 'App/constants';
import { useAppSelector } from 'store/store';
import { UserRoles } from 'types/user';

interface ReturnType {
    userId?: number;
    isMethodist?: boolean;
    isStudent?: boolean;
    isTeacher?: boolean;
    isCurator?: boolean;
    isPrincipal?: boolean;
    isLibrarian?: boolean;
    isProctor?: boolean;
    isStaff?: boolean;
    isSupport?: boolean;
    isAppUsed?: boolean;
    isTutor?: boolean;
    datetimeStartUsedApp?: null | string;
    isOlBlocked?: boolean;
    isNewsEditor?: boolean;
    isCurious?: boolean;
}

export const useRole = (): ReturnType => {
    const { info } = useAppSelector((state) => state.user);
    return {
        userId: info?.id,
        isCurious: info?.tariffTitle === TARIFF_CURIOUS_TITLE,
        isTeacher: info?.userRoles?.includes(UserRoles.TEACHER),
        isMethodist: info?.userRoles?.includes(UserRoles.METHODIST),
        isStudent: info?.userRoles?.includes(UserRoles.STUDENT),
        isCurator: info?.userRoles?.includes(UserRoles.CURATOR),
        isTutor: info?.userRoles?.includes(UserRoles.TUTOR),
        isPrincipal: info?.userRoles?.includes(UserRoles.PRINCIPAL),
        isSupport: info?.userRoles?.includes(UserRoles.SUPPORT),
        isProctor: info?.userRoles?.includes(UserRoles.PROCTOR),
        isNewsEditor: info?.userRoles?.includes(UserRoles.NEWS_EDITOR),
        isLibrarian: info?.userRoles?.includes(UserRoles.LIBRARIAN),
        isStaff:
            info?.userRoles?.includes(UserRoles.TEACHER) ||
            info?.userRoles?.includes(UserRoles.METHODIST) ||
            info?.userRoles?.includes(UserRoles.PRINCIPAL) ||
            info?.userRoles?.includes(UserRoles.SUPPORT),
        isAppUsed: info?.isAppUsed,
        datetimeStartUsedApp: info?.datetimeStartUsedApp,
        isOlBlocked: info?.isOlBlocked,
    };
};

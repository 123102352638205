import { INSERT_WORD_REPLACE } from '@lms-elements/custom-editor';
import { answerType, IQuestion } from '@lms-elements/test-task/build/@types/packages/TestTask/src/Answer';
import { CheckAnswerResponse } from 'api/services/materials';
import { QuestionStatus, ResponseQuestion, ResponseStudentQuestion } from 'api/services/questionBank';
import {
    EssayAnswerData,
    InsertWordsAnswerData,
    InsertWordsAnswerDataResponse,
    TestAnswerData,
    TestAnswerDataResponse,
    TextAnswerData,
    TrueFalseAnswerData,
} from 'types/answerAttempt';
import { EssayQuestionData, questionType, TestQuestionData } from 'types/question';

import { AnswersTypeEnum } from 'components/QuestionPopUp/QuestionPopUp.types';

interface AnswerOption {
    id: string;
    content: string;
    html?: string;
    text: string;
    order: number;
    acceptFile: boolean | null;
    acceptText: boolean | null;
}

interface ParsedQuestionContent {
    blocks: {
        text: string;
    }[];
    entityMap: {
        data: {
            text: string;
            options: string[];
        };
    }[];
}

export const getInsertWordsPreviewHtml = ({
    content,
    html,
    text,
}: Pick<ResponseQuestion, 'content' | 'html' | 'text'>): string => {
    if (content && html) {
        const parsedContent = JSON.parse(content) as ParsedQuestionContent;

        return Object.values(parsedContent.entityMap).reduce(
            (htmlAccumulator, entity) =>
                htmlAccumulator.replace(
                    INSERT_WORD_REPLACE,
                    `<input type="text" disabled value="${entity.data.text}" />`,
                ),
            html || '',
        );
    }

    return text || '';
};

export const getStudentQuestionsData = (questions: ResponseQuestion[] = []): ResponseStudentQuestion[] => {
    return questions.map((question) => {
        const {
            id,
            text,
            content,
            difficulty,
            answersType,
            status,
            timestamp,
            html,
            data,
            multiply,
            isActual,
        } = question;

        let answerOptionsQuantity = 0;
        let correctAnswerOptionsQuantity = 0;
        let answerOptions: AnswerOption[] = [];
        let randomOrder = false;

        if (answersType === AnswersTypeEnum.test) {
            answerOptions = (data as TestQuestionData).answerOptions?.map((option, index) => {
                const { correct, uuid, ...restData } = option;

                answerOptionsQuantity += 1;

                if (correct) {
                    correctAnswerOptionsQuantity += 1;
                }

                return {
                    ...restData,
                    id: uuid || String(index),
                    order: index,
                    acceptFile: null,
                    acceptText: null,
                };
            });
            randomOrder = (data as TestQuestionData).randomAnswerOrder;
        }

        return {
            id,
            text,
            content,
            difficulty,
            answersType,
            answerOptionsQuantity,
            correctAnswerOptionsQuantity,
            randomOrder,
            answerOptions,
            isActual,
            isTemplate: status === QuestionStatus.Published,
            timestamp,
            html,
            multiply,
            acceptFile: (data as EssayQuestionData).acceptFile,
            acceptText: (data as EssayQuestionData).acceptText,
        } as ResponseStudentQuestion;
    });
};

interface GetInitValuesQuestionTypeParams {
    type: AnswersTypeEnum;
    multiply?: boolean;
}

const getInitValuesQuestionType = ({ type, multiply }: GetInitValuesQuestionTypeParams): answerType => {
    switch (true) {
        case type === AnswersTypeEnum.essay:
            return 'detailed';
        case type === AnswersTypeEnum.test && multiply:
            return 'multiple';
        case type === AnswersTypeEnum.test:
            return 'single';
        case type === AnswersTypeEnum.true_false:
            return 'binary';
        case type === AnswersTypeEnum.insert_words:
            return 'insert-words';
        default:
            return 'open';
    }
};

export interface StudentAnswerData {
    data: CheckAnswerResponse;
    answers: TextAnswerData | TrueFalseAnswerData | InsertWordsAnswerData | TestAnswerData | EssayAnswerData;
}

const getAnswerData = (
    type: AnswersTypeEnum,
    answer?: StudentAnswerData,
): { answer: string | string[] | undefined; correctAnswers: string | string[] | undefined } => {
    if (!answer) {
        return {
            answer: undefined,
            correctAnswers: undefined,
        };
    }

    const { data, answers } = answer;

    switch (type) {
        case AnswersTypeEnum.true_false:
            return {
                answer: String((answers as TrueFalseAnswerData)?.answer),
                correctAnswers: data.isCorrect
                    ? String((answers as TrueFalseAnswerData)?.answer)
                    : String(!(answers as TrueFalseAnswerData)?.answer),
            };
        case AnswersTypeEnum.test:
            return {
                answer: (answers as TestAnswerData)?.selectedOptions,
                correctAnswers: data.incomplete
                    ? [
                          ...((data.data as TestAnswerDataResponse).selectedOptions
                              ?.filter((option) => option.isCorrect)
                              ?.map((option) => option.uuid) || []),
                          '-1',
                      ]
                    : (data.data as TestAnswerDataResponse).selectedOptions
                          ?.filter((option) => option.isCorrect)
                          ?.map((option) => option.uuid) || [],
            };
        case AnswersTypeEnum.text:
            return {
                answer: data.isCorrect
                    ? (data.data as TextAnswerData)?.selectedOption
                    : (answers as TextAnswerData)?.selectedOption,
                correctAnswers: data.isCorrect
                    ? (data.data as TextAnswerData)?.selectedOption
                    : `${(answers as TextAnswerData)?.selectedOption}_wrong`,
            };
        case AnswersTypeEnum.insert_words:
            return {
                answer:
                    (data.data as InsertWordsAnswerDataResponse).selectedOptions?.reduce((result, option, index) => {
                        result[option.gapIndex] = option.isCorrect
                            ? option.selectedWord
                            : (answers as InsertWordsAnswerData).selectedOptions[index].selectedWord;

                        return result;
                    }, [] as string[]) ||
                    (answers as InsertWordsAnswerData).selectedOptions?.reduce((result, option) => {
                        result[option.gapIndex] = option.selectedWord;

                        return result;
                    }, [] as string[]) ||
                    [],
                correctAnswers: data.incomplete
                    ? (data.data as InsertWordsAnswerDataResponse).selectedOptions?.reduce((result, option) => {
                          result[option.gapIndex] = option.isCorrect
                              ? option.selectedWord
                              : `${option.selectedWord}_wrong`;

                          return result;
                      }, [] as string[]) || []
                    : (data.data as InsertWordsAnswerDataResponse).selectedOptions
                          ?.filter((option) => option.isCorrect)
                          ?.reduce((result, option) => {
                              result[option.gapIndex] = option.selectedWord;

                              return result;
                          }, [] as string[]) || [],
            };
        case AnswersTypeEnum.essay:
            return {
                answer: 'text' in answer.data.data ? answer.data.data.text : undefined,
                correctAnswers: 'essay',
            };
        default:
            return {
                answer: undefined,
                correctAnswers: undefined,
            };
    }
};

export const normalizeQuestionData = (
    questionData: ResponseStudentQuestion,
    studentAnswers?: StudentAnswerData,
    isDraft?: boolean,
): IQuestion & { id: number } => {
    const {
        answersType: type,
        multiply,
        answerOptions: answers,
        id,
        acceptFile,
        acceptText,
        randomOrder,
    } = questionData;

    const answerOptions =
        type === AnswersTypeEnum.true_false
            ? [
                  {
                      id: '0',
                      markup: 'Неверно',
                  },
                  {
                      id: '1',
                      markup: 'Верно',
                  },
              ]
            : answers?.map((answer) => {
                  return {
                      id: String(answer.id),
                      markup:
                          answer.html ||
                          ((JSON.parse(answer.content) as Record<string, [Record<string, string>]>)['blocks'] as Array<{
                              text?: string;
                          }>)[0]['text'] ||
                          '',
                  };
              });

    const { answer, correctAnswers } = getAnswerData(type, studentAnswers);

    return {
        id,
        answerOptions,
        randomOrder,
        answer,
        correctAnswers: isDraft ? undefined : correctAnswers,
        file: studentAnswers?.data?.file
            ? studentAnswers?.data?.file.map((el) => {
                  return {
                      ...el,
                      id: String(el.id),
                      name: el.title,
                  };
              })
            : undefined,
        type: getInitValuesQuestionType({ type, multiply }),
        question: questionData.html || questionData.text,
        isFileAvailable: acceptFile,
        isTextAvailable: acceptText,
    };
};

export const getQuestionTypeForBackend = (questionType: questionType): AnswersTypeEnum => {
    switch (questionType) {
        case 'multiple':
            return AnswersTypeEnum.test;
        case 'detailed':
            return AnswersTypeEnum.essay;
        case 'binary':
            return AnswersTypeEnum.true_false;
        case 'open':
            return AnswersTypeEnum.text;
        case 'insert-words':
            return AnswersTypeEnum.insert_words;
    }
};

export const getQuestionType = (questionType: string | undefined): questionType => {
    switch (questionType) {
        case AnswersTypeEnum.test:
            return 'multiple';
        case AnswersTypeEnum.essay:
            return 'detailed';
        case AnswersTypeEnum.true_false:
            return 'binary';
        case AnswersTypeEnum.insert_words:
            return 'insert-words';
        default:
            return 'open';
    }
};

export const getHeaderForForm = (questionType: questionType): string => {
    switch (questionType) {
        case 'multiple':
            return 'Вопрос - тестовый, автопроверка';
        case 'detailed':
            return 'Открытый вопрос - эссе, ручная проверка';
        case 'binary':
            return 'Вопрос - верно или нет, автопроверка';
        case 'open':
            return 'Вопрос - тестовый, с текстовым ответом, автопроверка';
        case 'insert-words':
            return 'Вопрос - вставить слова, автопроверка';
    }
};

import React, { FC, useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';
import { AppFontIcon, AppModal, AppText } from '@frontend-modules/ui-kit';
import { getInsertWordsPreviewHtml, getQuestionData } from '@fsd-entities/materials';
import { EQuestionStatus, ITrainingQuestionDataResponse } from '@fsd-shared/models/materials';
import { AppSkeletonCard } from '@fsd-shared/ui';
import { getUserName } from '@fsd-shared/utils';
import { Skeleton } from 'antd';
import { E_COLORS } from 'src-new/constants';

import { IExerciseInfoModalProps } from './ExerciseInfoModal.types';
import { getQuestionMeta } from './ExerciseInfoModal.utils';

import './ExerciseInfoModal.styles.scss';
const LOADER_LIST = ['25%', '35%', '22%', '40%'];

const CnExerciseInfoModal = cn('exercise-info-modal');

export const ExerciseInfoModal: FC<IExerciseInfoModalProps> = (props) => {
    const { questionId, ...rest } = props;
    const [questionData, setQuestionData] = useState<ITrainingQuestionDataResponse | undefined>();
    const [metaInfoList, setMetaInfoList] = useState<Array<string> | undefined>();
    const { author, text, answersType, content, html, status } = questionData || {};
    const { firstName, lastName, patronymic, avatar } = author || {};
    const fullName = getUserName({ name: firstName, surname: lastName, patronymic }, 'NN:_:PP:_:SS');
    const isShowLoader = !questionData;
    const isShowStatus = status && status === 'published';
    const questionTitle =
        answersType === 'insert_words' ? getInsertWordsPreviewHtml(content, html, text) : html || text;

    useEffect(() => {
        if (questionId) {
            void getQuestionData(questionId).then((res) => {
                setQuestionData(res);
                setMetaInfoList(getQuestionMeta(res));
            });
        }
    }, [questionId]);

    const metaInfoItems = isShowLoader ? LOADER_LIST : metaInfoList;

    return (
        <AppModal className={CnExerciseInfoModal()} isShowCloseIcon={false} width={700} {...rest}>
            <div className={CnExerciseInfoModal()}>
                <div className={CnExerciseInfoModal('tag-list')}>
                    {metaInfoItems?.map((item) =>
                        isShowLoader ? (
                            <AppSkeletonCard key={item} height={24} borderRadius={12} width={item} />
                        ) : (
                            <div key={item} className={CnExerciseInfoModal('tag')}>
                                <AppText
                                    text={item}
                                    textStyle={'DesktopFootNotes'}
                                    color={E_COLORS.light_grey_color_2}
                                />
                            </div>
                        ),
                    )}
                </div>
                {isShowLoader ? (
                    <AppSkeletonCard height={24} width={'20%'} />
                ) : isShowStatus && status ? (
                    <div className={CnExerciseInfoModal('status')}>
                        <AppFontIcon icon={'check-s'} size={24} color={E_COLORS.mint_color} />
                        <AppText text={EQuestionStatus[status]} textStyle={'DesktopH4'} color={E_COLORS.mint_color} />
                    </div>
                ) : null}
                {isShowLoader ? (
                    <AppSkeletonCard height={21.25} />
                ) : (
                    <div
                        className={CnExerciseInfoModal('question')}
                        dangerouslySetInnerHTML={{ __html: questionTitle ?? '' }}
                    />
                )}
                <div className={CnExerciseInfoModal('author')}>
                    <AppText text={'Автор'} textStyle={'DesktopFootNotes'} color={E_COLORS.light_grey_color_2} />
                    <div className={CnExerciseInfoModal('author-info')}>
                        {isShowLoader ? (
                            <>
                                <Skeleton.Avatar active shape={'circle'} size={'default'} />
                                <AppSkeletonCard height={20} width={'60%'} />
                            </>
                        ) : (
                            <>
                                <img src={avatar} alt={'avatar'} className={CnExerciseInfoModal('author-info-image')} />
                                <AppText text={fullName} textStyle={'DesktopFontBody'} />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </AppModal>
    );
};

import { TIME_AFTER_END_OF_LESSON } from 'constants/date';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { Button, ButtonViewEnum, Mark } from '@lms-elements/atomic';
import { IQuestion } from '@lms-elements/test-task/build/@types/packages/TestTask/src/Answer';
import { checkIsAfter } from '@lms-elements/utils';
import { AnswerAttemptPostResponse } from 'api/services/answerAttempt';
import { overdueHomework } from 'assets';
import arrayMutators from 'final-form-arrays';
import { usePreventUnload } from 'hooks';
import moment from 'moment';
import { AppSkeleton } from 'src-new/ui';
import { getCurrentTimeByMoscow, getDifferentInMinutes, isTimeExpired } from 'src-new/utils';
import {
    createQuestionFileAction,
    getAnswerAttemptByProgressAction,
    patchAnswerAttemptAction,
    postEvaluateAction,
    updateAttemptDraft,
} from 'store/actions/answerAttempt';
import { setError } from 'store/actions/error';
import { getAssignmentProgressQuestionsAction } from 'store/actions/questionBank';
import { getStudentTaskById } from 'store/actions/studentTasks';
import { deleteQuestionFile, resetAnswerAttemptsData, resetAnswerAttemptsState } from 'store/reducers/answerAttempts';
import { resetQuestionBankState } from 'store/reducers/questionBank';
import { resetStudentTask } from 'store/reducers/studentTasks';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';
import { AssignmentProgressStatus } from 'types/assignmentProgress';
import { MarkData } from 'types/mark';

import { AttendanceMark } from 'components/AttendanceMark';
import { MarkField } from 'components/AttendanceMark/AttendanceMarkForm';
import { addMinutes } from 'utils/date';
import { getStudentQuestionsData } from 'utils/questions';
import { isEmptyAttempts } from 'utils/studentsTasks';

import { RateButton } from './RateButton/RateButton';
import { AnswerWrapper } from './AnswerWrapper';
import { AttemptDropdown } from './AttemptDropdown';
import { DraftComponent } from './DraftComponent';
import { DraftTime } from './DraftTime';
import {
    checkIsHardDeadlineLeft,
    getAnswerAttempts,
    getAttemptNumber,
    getBestAttempt,
    getInitValues,
    getMarkComentCallback,
    IFormValues,
    questionsValidation,
    showHardDeadlineLeftToast,
} from './utils';

import './StudentTask.scss';

const CnStudentTaskTab = cn('student-task');
const CnAnswer = cn('student-task-answer');

interface TaskCheckParams {
    mark: MarkField;
    answerAttempt: AnswerAttemptPostResponse;
}

interface IStudetnTaskProps {
    lessonId: number;
    assignmentId: number;
    assignmentProgressId: number;
    marks?: MarkData[];
    submitChangeMark?: (values: { selectedMark?: number; comment?: string; markId: number }[]) => void;
    onTaskCheck?: (values: TaskCheckParams) => void;
    isTeacher?: boolean;
    isStudent?: boolean;
    studentId: number;
    createNewAttempt?: () => void;
    deadline?: string;
    isTimeLeft?: boolean;
}

export const StudentTask: React.FC<IStudetnTaskProps> = ({
    lessonId,
    assignmentProgressId,
    isTeacher,
    isStudent,
    submitChangeMark,
    onTaskCheck,
    studentId,
    createNewAttempt,
    deadline,
    isTimeLeft,
}) => {
    const dispatch = useDispatch();
    const [shouldShowLastAttempt, setShouldShowLastAttempt] = useState(isTeacher);
    const [isHardDeadlineLeft, setIsHardDeadlineLeft] = useState(false);
    const [isJustFileUploaded, setIsJustFileUploaded] = useState(false);

    const history = useHistory();
    const { task, taskFetchStatus } = useAppSelector((state) => state.studentTasks);
    const { fetchStatusQuestions, studentQuestions } = useAppSelector((state) => state.questions);
    const {
        fetchStatus: fetchAnswerAttemptsStatus,
        data,
        lastAttempt,
        attemptsQuantity,
        postAnswerAttemptStatus,
        questionFiles,
        postEvaluateStatus,
        patchAnswerAttemptStatus,
        patchDraftStatus,
        createQuestionFileStatus,
    } = useAppSelector((state) => state.answerAttempts);
    const { draftAttempId, draftData } = useAppSelector((state) => state.answerAttempts);
    const draftTime = useAppSelector((store) => store.answerAttempts.draftTime);

    const answerAttemptsData = useMemo(() => {
        return isTeacher ? data?.filter((attempt) => attempt.student === studentId) || [] : data;
    }, [data, isTeacher, studentId]);

    useEffect(() => {
        dispatch(getStudentTaskById(assignmentProgressId));
    }, [assignmentProgressId, dispatch]);

    const isDeferredAssignmentTimeExpired = useMemo(
        () =>
            !!task?.timeToComplete &&
            (isTimeLeft ||
                ((deadline || task?.hardDeadline) &&
                    isTimeExpired(
                        moment(deadline ?? task?.hardDeadline)
                            .subtract(3, 'seconds')
                            .format(),
                    ))),
        [patchDraftStatus, task, deadline, isTimeLeft],
    );

    // Отслеживает выход со страницы,
    // и если последний черновик был сохранен более 3х минут назад, устанавливает ошибку
    useEffect(() => {
        if (isStudent) {
            const unlisten = history.listen(() => {
                const currTime = getCurrentTimeByMoscow();
                const diff = draftTime ? getDifferentInMinutes(draftTime, currTime) : -1;

                if (diff > 3 && !isDeferredAssignmentTimeExpired && !isEmptyAttempts(data) && !isHardDeadlineLeft) {
                    dispatch(setError('Последняя попытка сохранения черновика была неудачной!'));
                }
            });
            return () => {
                unlisten();
            };
        }
    }, [history, draftTime, isStudent, isDeferredAssignmentTimeExpired]);

    useEffect(() => {
        if (fetchAnswerAttemptsStatus === FetchStatus.INITIAL) {
            dispatch(getAnswerAttemptByProgressAction(assignmentProgressId));
            setShowAttempt(true);
        }
    }, [dispatch, assignmentProgressId, fetchAnswerAttemptsStatus, postAnswerAttemptStatus]);

    const [selectedAttempt, setSelectedAttempt] = useState<AnswerAttemptPostResponse | null>(null);

    const [showAttempt, setShowAttempt] = useState(true);

    useEffect(() => {
        if (fetchAnswerAttemptsStatus !== FetchStatus.FETCHED || answerAttemptsData?.length === 0) {
            return setSelectedAttempt(null);
        }

        if (task?.status === AssignmentProgressStatus.ON_CHECK || isTeacher) {
            return setSelectedAttempt(answerAttemptsData?.find((attempt) => attempt.attempt === lastAttempt) || null);
        }

        setSelectedAttempt(() => {
            if (!answerAttemptsData?.length || !showAttempt) {
                return null;
            }

            const draft = answerAttemptsData?.find((elem) => elem.isDraft);
            if (draft) {
                return draft;
            }

            return (
                answerAttemptsData?.find((attempt) => attempt.attempt === lastAttempt) ??
                getBestAttempt(answerAttemptsData)
            );
        });
    }, [
        answerAttemptsData,
        fetchAnswerAttemptsStatus,
        isTeacher,
        lastAttempt,
        postAnswerAttemptStatus,
        shouldShowLastAttempt,
        showAttempt,
        task?.status,
    ]);

    const needResetShowAttemptState =
        fetchAnswerAttemptsStatus === FetchStatus.FETCHED &&
        answerAttemptsData &&
        (answerAttemptsData.length === 0 ||
            (isTeacher && answerAttemptsData.filter(({ isDraft }) => !isDraft).length === 0)) &&
        showAttempt;

    useEffect(() => {
        if (needResetShowAttemptState) {
            setShowAttempt(false);
        }
    }, [needResetShowAttemptState]);

    const selectedQuestion = useMemo(() => {
        if (selectedAttempt?.selectedQuestions && showAttempt) {
            return selectedAttempt.selectedQuestions;
        }

        if (task?.selectedQuestions && !showAttempt) {
            return task.selectedQuestions;
        }
    }, [selectedAttempt?.selectedQuestions, showAttempt, task?.selectedQuestions]);
    const canFetchQuestion =
        fetchAnswerAttemptsStatus === FetchStatus.FETCHED && postAnswerAttemptStatus === FetchStatus.INITIAL;

    useEffect(() => {
        if (canFetchQuestion && selectedQuestion?.length) {
            dispatch(getAssignmentProgressQuestionsAction(selectedQuestion));
        }
    }, [canFetchQuestion, dispatch, selectedQuestion]);

    const studentQuestionsData = useMemo(() => getStudentQuestionsData(studentQuestions), [studentQuestions]);

    const initialValues = useMemo(() => {
        return getInitValues(studentQuestionsData, selectedQuestion || [], selectedAttempt);
    }, [selectedAttempt, selectedQuestion, studentQuestionsData]);

    const attemptNumber = useMemo(() => (task ? getAttemptNumber(task, selectedAttempt) : ''), [task, selectedAttempt]);

    const markComment = useCallback(
        (attemptData: AnswerAttemptPostResponse) => {
            const getComment = getMarkComentCallback(studentQuestionsData);

            return getComment(attemptData);
        },
        [studentQuestionsData],
    );

    const timeIsUp = useMemo(() => {
        return task?.hardDeadline ? isTimeExpired(task?.hardDeadline) : false;
    }, [patchDraftStatus, task]);
    const overdue = isEmptyAttempts(data) && timeIsUp;

    const hasAttempts = useMemo(() => {
        if (
            fetchAnswerAttemptsStatus === FetchStatus.FETCHED &&
            lastAttempt !== undefined &&
            attemptsQuantity !== undefined
        ) {
            return lastAttempt < attemptsQuantity;
        }

        return false;
    }, [attemptsQuantity, fetchAnswerAttemptsStatus, lastAttempt]);

    const isOnCheck = useMemo(
        () =>
            task?.status === AssignmentProgressStatus.ON_CHECK ||
            (selectedAttempt?.mark && task?.status !== AssignmentProgressStatus.COMPLETED),
        [selectedAttempt?.mark, task],
    );
    const isComplete = useMemo(() => task?.status === AssignmentProgressStatus.COMPLETED, [task?.status]);
    const marks = useMemo(() => {
        if (selectedAttempt?.mark) {
            return [selectedAttempt?.mark];
        }

        return [];
    }, [selectedAttempt]);

    const showDropdown = attemptsQuantity && attemptsQuantity > 1;

    const canReupload = initialValues.questions.some((question) => question.type === 'detailed')
        ? task?.status === AssignmentProgressStatus.ON_CHECK && !timeIsUp
        : false;

    const [onReapload, setOnReapload] = useState(false);

    const actionButtonText = useMemo(() => {
        switch (true) {
            case onReapload:
                return 'Отправить файлы';
            case canReupload:
                return 'Изменить прикрепленные файлы';
            case showAttempt && !selectedAttempt?.isDraft:
                return 'Попробовать еще раз';
            default:
                return 'Сдать';
        }
    }, [canReupload, onReapload, selectedAttempt?.isDraft, showAttempt]);

    const needAttemptUpdate =
        isStudent && (selectedAttempt?.isDraft || !showAttempt) && !isOnCheck && !isDeferredAssignmentTimeExpired;

    const needAttemptUpdateRef = useRef(needAttemptUpdate);

    useEffect(() => {
        needAttemptUpdateRef.current = needAttemptUpdate;
    }, [needAttemptUpdate]);

    useEffect(() => {
        const isLeft = checkIsHardDeadlineLeft(!!isOnCheck || isComplete, task);
        if (isLeft) {
            showHardDeadlineLeftToast();
        }
        setIsHardDeadlineLeft(isLeft);
    }, [draftData, task, patchDraftStatus, isOnCheck, isComplete]);

    useEffect(() => {
        if (fetchAnswerAttemptsStatus === FetchStatus.FETCHING) {
            setOnReapload(false);
        }
    }, [fetchAnswerAttemptsStatus]);

    const handleReuploadFiles = useCallback(() => {
        setOnReapload((prev) => !prev);
    }, []);

    const handleMarkAdd = useCallback(
        (marks: MarkField[]) => {
            if (answerAttemptsData && lastAttempt && marks[0] && onTaskCheck)
                onTaskCheck({
                    mark: marks[0],
                    answerAttempt: answerAttemptsData[lastAttempt - 1],
                });
        },
        [answerAttemptsData, lastAttempt, onTaskCheck],
    );

    const handleFormSubmit = useCallback(
        (values: IFormValues) => {
            if (showAttempt && !selectedAttempt?.isDraft && !(canReupload && !onReapload)) {
                setShowAttempt(false);
                dispatch(getStudentTaskById(assignmentProgressId));
                if (task?.timeToComplete) {
                    createNewAttempt?.();
                } else {
                    dispatch(resetAnswerAttemptsData());
                }
                return;
            }

            if (task) {
                const answerAttempts = getAnswerAttempts(values, task, selectedAttempt, questionFiles);
                if (
                    initialValues.questions.some((question) => question.type === 'detailed')
                        ? task?.status === AssignmentProgressStatus.ON_CHECK
                        : false
                ) {
                    dispatch(patchAnswerAttemptAction({ answersData: answerAttempts }));
                } else {
                    dispatch(
                        postEvaluateAction({
                            lessonId,
                            id: assignmentProgressId,
                        }),
                    );
                }
            }
        },
        [
            assignmentProgressId,
            canReupload,
            dispatch,
            initialValues.questions,
            lessonId,
            onReapload,
            questionFiles,
            selectedAttempt,
            showAttempt,
            task,
        ],
    );

    useEffect(() => {
        if (postEvaluateStatus === FetchStatus.FETCHED) {
            setShowAttempt(true);
            setShouldShowLastAttempt(true);
            dispatch(resetAnswerAttemptsState());
            dispatch(resetQuestionBankState());
        }
    }, [dispatch, postEvaluateStatus]);

    const handleFileDelete = useCallback(
        (questionId: number, fileId: number) => {
            dispatch(deleteQuestionFile({ fileId, questionId }));
        },
        [dispatch],
    );
    const handleFileUpload = useCallback(
        (
            questionId: number,
            file: File,
            index: number,
            handleUploadProgress: (index: number, percent: number) => void,
        ) => {
            const isAcceptCreation = isHardDeadlineLeft || checkIsHardDeadlineLeft(!!isOnCheck || isComplete, task);

            if (isAcceptCreation) {
                setIsHardDeadlineLeft(isAcceptCreation);
                showHardDeadlineLeftToast();
            } else {
                dispatch(
                    createQuestionFileAction({
                        file,
                        questionId,
                        index,
                        onUploadProgress: handleUploadProgress.bind(null, index),
                    }),
                );
                setIsJustFileUploaded(true);
            }
        },
        [dispatch, isHardDeadlineLeft, isOnCheck, isComplete, task],
    );

    const handlerPatch = useCallback(() => {
        if (needAttemptUpdate && !isHardDeadlineLeft) {
            dispatch(updateAttemptDraft());

            return 'Вы уверены, что хотите перезагрузить страницу?';
        }
    }, [dispatch, needAttemptUpdate, isHardDeadlineLeft]);

    usePreventUnload(handlerPatch);

    useEffect(() => {
        return () => {
            if (needAttemptUpdateRef.current && !isHardDeadlineLeft) {
                dispatch(updateAttemptDraft());
            }
            setSelectedAttempt(null);
            dispatch(resetAnswerAttemptsState());
            dispatch(resetStudentTask());
            dispatch(resetQuestionBankState());
        };
    }, [assignmentProgressId, dispatch]);
    useEffect(() => {
        if (isJustFileUploaded && createQuestionFileStatus === FetchStatus.FETCHED) {
            dispatch(updateAttemptDraft());
            setIsJustFileUploaded(false);
        }
    }, [isJustFileUploaded, createQuestionFileStatus]);

    const [allFilesUploaded, setAllFilesUploaded] = useState<boolean>(true);

    const onChangeFilesProgress = (progresses: number[]) => {
        if (progresses.some((progress) => progress < 100)) {
            setAllFilesUploaded(false);
            return;
        }

        setAllFilesUploaded(true);
    };

    if (taskFetchStatus === FetchStatus.FETCHING) {
        return <AppSkeleton width={'100%'} height={400} />;
    }

    if (overdue) {
        return (
            <div className={CnStudentTaskTab('overdueHomework')}>
                <img src={overdueHomework} className={CnStudentTaskTab('overdueHomework-img')} />
                <h2 className={CnStudentTaskTab('overdueHomework-title')}>
                    {isTeacher ? 'Задание не было выполнено в срок' : 'Вы не выполнили задание в срок'}
                </h2>
            </div>
        );
    }

    const hasEmptyAnswers = (values: IFormValues) => {
        return values.questions?.some((item) =>
            typeof item?.answer === 'string' ? !item?.answer?.trim() : !!item?.answer?.find((text) => !text?.trim()),
        )
            ? { err: 'Необходимо ответить на все вопросы!' }
            : { err: undefined };
    };

    return (
        <>
            {fetchStatusQuestions === FetchStatus.FETCHED && Boolean(studentQuestions?.length) && (
                <Form<IFormValues>
                    onSubmit={handleFormSubmit}
                    mutators={{
                        ...arrayMutators,
                    }}
                    initialValues={initialValues}
                    subscription={{ invalid: true }}
                    validate={hasEmptyAnswers}
                >
                    {({ handleSubmit, invalid }): React.ReactElement => {
                        return (
                            <form onSubmit={handleSubmit} className={CnStudentTaskTab('form')}>
                                {needAttemptUpdate && (
                                    <DraftComponent
                                        allFilesUploaded={allFilesUploaded}
                                        studentId={studentId}
                                        selectedAttempt={selectedAttempt}
                                        isHardDeadlineLeft={isHardDeadlineLeft}
                                    />
                                )}
                                {isStudent && <DraftTime />}
                                <div className={CnStudentTaskTab('assignment-header')}>
                                    <div className={CnStudentTaskTab('assignment-description')}>
                                        {task && (
                                            <h3 className={CnStudentTaskTab('assignment-title')}>
                                                {task.description || task.title}
                                            </h3>
                                        )}
                                        <p className={CnStudentTaskTab('assignment-attemptNumber')}>{attemptNumber}</p>
                                        {task?.assignment && (
                                            <p className={CnStudentTaskTab('assignment-taskDescription')}>
                                                {task.assignment.description}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className={CnStudentTaskTab('check-buttons')}>
                                    {isTeacher && isOnCheck && <RateButton onAddMark={handleMarkAdd} />}
                                    {showDropdown && isTeacher && isComplete && (
                                        <AttemptDropdown
                                            name="attempt"
                                            answerAttempts={answerAttemptsData || []}
                                            onSelectedAttemptChange={setSelectedAttempt}
                                        />
                                    )}
                                    {!showDropdown && isTeacher && isComplete && marks.length && (
                                        <AttendanceMark
                                            marks={marks}
                                            submitChangeMark={submitChangeMark}
                                            isNeedAddMark={false}
                                            needHideWeight
                                        />
                                    )}
                                </div>
                                <div className={CnAnswer('container')}>
                                    <FieldArray<IQuestion & { id: number }>
                                        name="questions"
                                        validate={
                                            isTeacher || (showAttempt && !selectedAttempt?.isDraft)
                                                ? undefined
                                                : questionsValidation
                                        }
                                        subscription={{}}
                                    >
                                        {({
                                            fields: questions,
                                        }: FieldArrayRenderProps<IQuestion & { id: number }, HTMLInputElement>) =>
                                            questions?.map((name, index) => {
                                                return (
                                                    <AnswerWrapper
                                                        key={index}
                                                        name={name}
                                                        disabled={
                                                            (showAttempt && !selectedAttempt?.isDraft) || isTeacher
                                                        }
                                                        needHideFileAddButton={
                                                            isOnCheck
                                                                ? !canReupload || !onReapload
                                                                : (showAttempt && !selectedAttempt?.isDraft) ||
                                                                  isTeacher
                                                        }
                                                        needValidation={!showAttempt || selectedAttempt?.isDraft}
                                                        needHideFileDeleteButton={
                                                            isOnCheck
                                                                ? !canReupload || !onReapload
                                                                : (showAttempt && !selectedAttempt?.isDraft) ||
                                                                  isTeacher
                                                        }
                                                        isOnCheck={Boolean(isOnCheck)}
                                                        onDeleteFile={handleFileDelete}
                                                        onUploadFile={handleFileUpload}
                                                        needQuestionLink={isTeacher}
                                                        isDraft={
                                                            typeof draftAttempId === 'number' ||
                                                            selectedAttempt?.isDraft
                                                        }
                                                        onChangeFilesProgress={onChangeFilesProgress}
                                                    />
                                                );
                                            })
                                        }
                                    </FieldArray>
                                </div>
                                {isOnCheck && !isTeacher && (
                                    <div className={CnStudentTaskTab('is-on-check-label')}>
                                        Задание отправлено на проверку
                                    </div>
                                )}
                                <div className={CnStudentTaskTab('btn-block')}>
                                    {((hasAttempts && !timeIsUp) || canReupload || selectedAttempt?.isDraft) &&
                                    !isHardDeadlineLeft &&
                                    (!isDeferredAssignmentTimeExpired || (showAttempt && !selectedAttempt?.isDraft)) ? (
                                        !isTeacher && (
                                            <Button
                                                size="m"
                                                view={ButtonViewEnum.action}
                                                customClasses={CnStudentTaskTab('action-btn')}
                                                type={canReupload && onReapload ? 'button' : 'submit'}
                                                disabled={
                                                    postEvaluateStatus !== FetchStatus.INITIAL ||
                                                    postAnswerAttemptStatus === FetchStatus.FETCHING ||
                                                    patchAnswerAttemptStatus === FetchStatus.FETCHING ||
                                                    patchDraftStatus === FetchStatus.FETCHING ||
                                                    !allFilesUploaded ||
                                                    invalid
                                                }
                                                onClick={canReupload ? handleReuploadFiles : undefined}
                                            >
                                                {actionButtonText}
                                            </Button>
                                        )
                                    ) : (
                                        <span className={CnStudentTaskTab('no-attempts-message')}>
                                            {(timeIsUp && hasAttempts) || isHardDeadlineLeft
                                                ? 'Время истекло'
                                                : isDeferredAssignmentTimeExpired
                                                ? `Время на выполнение истекло, задание отправлено на проверку`
                                                : 'Попытки закончились'}
                                        </span>
                                    )}
                                    {!isOnCheck && showAttempt && selectedAttempt && selectedAttempt.mark && (
                                        <div className={CnStudentTaskTab('mark')}>
                                            <Mark
                                                value={selectedAttempt.mark.score}
                                                markId={String(selectedAttempt.mark.id)}
                                                comment={markComment(selectedAttempt)}
                                                showComment
                                                needTrimComment={false}
                                            />
                                        </div>
                                    )}
                                </div>
                            </form>
                        );
                    }}
                </Form>
            )}
        </>
    );
};

import React, { useState } from 'react';
import { AppButton, AppLoader, AppModal, AppText } from '@frontend-modules/ui-kit';
import { SubjectsElementsSkeleton, WarningSign } from 'src-new/components/new-subjects';
import LessonTypeTag from 'src-new/components/students-schedule/lesson-type-tag/LessonTypeTag';
import { EMPTY_ARRAY_2 } from 'src-new/constants';
import { AppScrollPaginateContainer } from 'src-new/ui';
import { getDateByMoscow, getTimeByMoscow, getUid } from 'src-new/utils';

import { TAddLessonModal } from './AddLessonModal.types';

import './style.scss';
export const AddLessonModal: React.FC<TAddLessonModal> = (props: TAddLessonModal) => {
    const [selectedLessonId, setSelectedLessonId] = useState<number | undefined>(undefined);
    const { orderNumber, lessonsList, isFetching, paginate, error, onModalClose, onAddLesson, handlePaginate } = props;
    const { currentPage, maxPage } = paginate;
    const isFirstFetching = isFetching && !lessonsList.length;
    const isPaginationFetching = isFetching && lessonsList.length;

    const getErrorMessage = (): string => {
        if (error) {
            // @ts-ignore
            const errorData = JSON.parse(error.message) as { message: string; extra: { fields: string[] } };

            return errorData.extra.fields[0] ?? 'Не удалось переместить урок';
        }
        return '';
    };
    const onScrollHandle = (currPage: number) => {
        if (!isFirstFetching) {
            handlePaginate(currPage);
        }
    };
    const onLessonSelect = (id: number) => {
        setSelectedLessonId(id === selectedLessonId ? undefined : id);
    };
    return (
        <AppModal
            isShow={orderNumber !== undefined}
            setShow={() => undefined}
            isShowCloseIcon={false}
            wrapClassName={'add-lesson-modal__wrapper'}
            width={'90%'}
            centered
            getContainer={() => document.getElementById('lesson-col') as HTMLElement}
        >
            <div className={'add-lesson-modal'}>
                {isFirstFetching ? (
                    <>
                        <SubjectsElementsSkeleton type={'title'} />
                        <div className={'add-lesson-modal__lessons'}>
                            <SubjectsElementsSkeleton key={getUid()} type={'lesson-card'} />
                        </div>
                        <div className={'add-lesson-modal__btns'}>
                            {EMPTY_ARRAY_2.map(() => (
                                <SubjectsElementsSkeleton key={getUid()} type={'button'} />
                            ))}
                        </div>
                    </>
                ) : (
                    <>
                        <div className={'add-lesson-modal__title'}>
                            <AppText text={'Вставка резервного урока'} textStyle={'DesktopH3'} />
                        </div>
                        {getErrorMessage() && <WarningSign message={getErrorMessage()} type={'error'} />}
                        {lessonsList.length ? (
                            <AppScrollPaginateContainer
                                customClassName={'library-modal-content__container-scrollable'}
                                onScrollHandle={onScrollHandle}
                                needReset={currentPage === 1}
                                maxPage={maxPage}
                            >
                                <div className={'add-lesson-modal__lessons'}>
                                    {lessonsList.map(({ id, title, lessonSchedules }) => {
                                        const isLessonSelected = id === selectedLessonId;
                                        const textColor = isLessonSelected ? '#ffffff' : '#252232';

                                        return (
                                            <div
                                                className={`add-lesson-modal__lessons_card ${
                                                    isLessonSelected ? 'selected' : ''
                                                }`}
                                                key={id}
                                                onClick={onLessonSelect.bind(this, id)}
                                            >
                                                <div className={'lesson-card__titles'}>
                                                    <AppText
                                                        text={'Урок'}
                                                        textStyle={'DesktopFootNotes'}
                                                        color={textColor}
                                                    />
                                                    <AppText text={title} textStyle={'DesktopH4'} color={textColor} />
                                                </div>
                                                <div className={'lesson-card__types'}>
                                                    {lessonSchedules.map((lessonSchedule) => {
                                                        const { id, type, datetimeStart, datetimeEnd } = lessonSchedule;
                                                        const hasDates = !!datetimeStart || !!datetimeEnd;
                                                        const startDay = getDateByMoscow(datetimeStart, 'LL'),
                                                            endDay = getDateByMoscow(datetimeEnd, 'LL'),
                                                            startTime = getTimeByMoscow(datetimeStart),
                                                            endTime = getTimeByMoscow(datetimeEnd);

                                                        const dateString =
                                                            startDay === endDay
                                                                ? `${startDay} в ${startTime} - ${endTime}`
                                                                : `${startDay} в ${startTime} - ${endDay} в ${endTime} -`;

                                                        return (
                                                            <div className={'lesson-card__types_item'} key={id}>
                                                                <LessonTypeTag type={type} />
                                                                {hasDates && (
                                                                    <AppText
                                                                        text={dateString}
                                                                        textStyle={'DesktopFootNotes'}
                                                                        color={textColor}
                                                                    />
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                    {isPaginationFetching && (
                                                        <div className={'lesson-card__types_loader'}>
                                                            <AppLoader />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </AppScrollPaginateContainer>
                        ) : (
                            <div className={'add-lesson-modal__lessons'}>
                                <AppText text={'Нет доступных уроков'} color={'#919399'} />
                            </div>
                        )}

                        <div className={'add-lesson-modal__btns'}>
                            <AppButton label={'Отмена'} type={'outline'} onClick={onModalClose} />
                            <AppButton
                                label={'Вставить'}
                                disabled={!selectedLessonId}
                                onClick={onAddLesson.bind(this, selectedLessonId ?? 0, orderNumber)}
                            />
                        </div>
                    </>
                )}
            </div>
        </AppModal>
    );
};

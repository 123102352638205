import { PatchAssignmentMarkParams } from 'api/services/marks';

export const getChangeAttemptMarkParams = (
    mark: { score: number; comment?: string },
    attemptMark: { id: number },
): PatchAssignmentMarkParams => {
    return {
        id: attemptMark.id,
        score: mark.score,
        comment: mark.comment,
    };
};

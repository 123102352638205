/**
 *
 * @param value
 * @param mask
 * @param withDot
 */
const getMaskedString = (value?: string | null, mask?: string, withDot?: boolean) => {
    if (!value) return '';

    if (mask?.length === 1) {
        return `${value[0]}${withDot ? '.' : ''}`;
    }
    return value;
};

/**
 * @description возвращает инициалы и полное имя, если имени нет, то email
 * data - { name: 'Имя', surname: 'Фамилия', patronymic: 'Отчество' }
 * mask - 'N:NN:S:SS:P:_:PP' - маска значение через ":", в любом порядке
 * где N - имя, S - фамилия, P - отчество, _ - пробел
 * если N - то первая буква имени, если S - то первая буква фамилии, если P - то первая буква отчества
 * если NN - то полное имя и так далее
 * withDots - нужно ли добавлять точки между инициалами
 * @param data
 * @param data.name
 * @param data.surname
 * @param data.patronymic
 * @param mask
 * @param withDots
 */
const getUserName = (
    data: { name?: string | null; surname?: string | null; patronymic?: string | null },
    mask?: string,
    withDots?: boolean,
): string => {
    const { name, surname, patronymic } = data;
    const maskArray = mask?.split(':');
    let fullName = '';
    maskArray?.forEach((item) => {
        if (item === 'N' || item === 'NN') {
            fullName += getMaskedString(name, item, withDots);
        } else if (item === 'S' || item === 'SS') {
            fullName += getMaskedString(surname, item, withDots);
        } else if (item === 'P' || item === 'PP') {
            fullName += getMaskedString(patronymic, item, withDots);
        } else if (item === '_') {
            fullName += ' ';
        }
    });

    return fullName.trim();
};

export { getUserName };

import { CuratorDashboard } from 'api/services/curatorDashboard';
import { Teacher } from 'types/teacher';

export const getAbsentTeacherList = (
    curatorDashboard: CuratorDashboard[],
): (Teacher & { lessonScheduleId: number })[] => {
    const teachers = curatorDashboard.map((curator) => {
        const usersId = curator.usersInMeeting.map((user) => user.id);
        return curator.courseGroup.teachers
            .filter((teacher) => !usersId.includes(teacher.id))
            .map((teacher) => ({
                ...teacher,
                lessonScheduleId: curator.id,
            }));
    });
    return teachers.flat();
};

import React from 'react';
import { Typography } from 'antd';

import { TAppText } from './AppTextTypes';

import './style.scss';

const { Text: Text } = Typography;

const AppText = (props: TAppText): JSX.Element => {
    const { textStyle, fontStyle, text } = props;
    const classNameString = `app-text ${(textStyle || 'default').toString()} ${(fontStyle || 'default').toString()}`;

    return (
        <Text className={classNameString} {...props}>
            {text}
        </Text>
    );
};

export default AppText;

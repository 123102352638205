export const timezoneError = {
    error: 'На вашем устройстве неправильно настроен часовой пояс.',
    descriptions: [
        'При неправильно настроенном часовом поясе вы не сможете зайти на урок!',
        'Исправьте в настройках устройства часовой пояс!',
        'При возникновении трудностей обратитесь в техподдержку!',
    ],
};

export const nonPaymentError = {
    error: 'Доступ к онлайн-занятиям заблокирован.',
    descriptions: 'Обратитесь к родителям.',
};

export const wrongClientError = {
    error: 'Скачайте приложение',
};

export const lastAttemptDeleting = {
    error: 'Вы действительно хотите удалить последнюю попытку?',
    descriptions:
        'Вы уверены, что хотите удалить последнюю попытку выполнения данного задания? После этого задание будет удалено для ученика.',
};

export const ApprovingMedicalCertificate = {
    error: 'Утверждение справки',
    descriptions: 'Вы действительно хотите утвердить справку? Данные утвержденной справки нельзя будет изменить.',
};

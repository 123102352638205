import { ContentBlock, ContentState, EditorState } from 'draft-js';

export enum InlineStyles {
    BOLD = 'BOLD',
    ITALIC = 'ITALIC',
    UNDERLINE = 'UNDERLINE',
    STRIKETHROUGH = 'STRIKETHROUGH',
}

export enum CommonBlocks {
    UNSTYLED = 'unstyled',
    H1 = 'header-one',
    H2 = 'header-two',
    H3 = 'header-three',
    H4 = 'header-four',
    H5 = 'header-five',
    H6 = 'header-six',
    BLOCKQUOTE = 'blockquote',
    CODE = 'pre',
}

export enum ListTypes {
    ORDERED_LIST_ITEM = 'ordered-list-item',
    UNORDERED_LIST_ITEM = 'unordered-list-item',
}

export enum ActionBlockTypes {
    INFO_BLOCK = 'INFO_BLOCK',
    WARNING_BLOCK = 'WARNING_BLOCK',
    THEOREM_BLOCK = 'THEOREM_BLOCK',
    QUOTE_BLOCK = 'QUOTE_BLOCK',
    DEFINITION_BLOCK = 'DEFINITION_BLOCK',
    CODE_BLOCK = 'CODE_BLOCK',
}

export type BlockTypes = CommonBlocks | ListTypes | ActionBlockTypes;

export interface ICustomOptionProps {
    onChange: React.Dispatch<React.SetStateAction<EditorState>>;
    editorState: EditorState;
}

export enum EntityTypes {
    LATEX = 'LATEX',
    LINK = 'LINK',
    INSERT_WORD = 'INSERT_WORD',
    MENTION = 'MENTION',
    IMAGE = 'IMAGE',
    EMBEDDED_LINK = 'EMBEDDED_LINK',
}

export const INSERT_WORD_REPLACE = '<input type="insert-word" />';
export const NEW_LINE_DELIMETER = '<break />';

export interface IBlockProps<T = Record<string, unknown>> {
    block: ContentBlock;
    contentState: ContentState;
    blockProps: T;
}

export interface IBlockRendererObject<T = Record<string, unknown>> {
    component: React.FC<IBlockProps<T>>;
    editable?: boolean;
    props?: T;
}

import { HARD_MODAL_ENUMS } from 'src-new/components/layouts/Modals/Modals.config';
import { useQueryPopupsParams } from 'src-new/hooks';
import { TStudentTasksViewQuery } from 'types/lessonTask';

import { TStudentTasksViewPopup, TStudentTasksViewPopupParams } from './useStudentTasksViewPopup.types';

const useStudentTasksViewPopup = (): TStudentTasksViewPopup => {
    const Popup = useQueryPopupsParams();

    const getQuery = (params: TStudentTasksViewPopupParams): TStudentTasksViewQuery => {
        const { courseGroupId, lessonId, taskId, studentId, type = 'TASKS_VIEW', subjectId } = params;

        return {
            type: HARD_MODAL_ENUMS[type],
            courseGroupId,
            lessonId,
            taskId,
            studentId,
            subjectId,
        };
    };
    const openStudentTasksViewPopup = (params: TStudentTasksViewPopupParams) => {
        Popup.openPopup(getQuery(params));
    };

    const closeLastStudentTasksViewPopup = () => {
        Popup.closeLastPopup();
    };

    const replaceLastStudentTasksViewPopup = (params: TStudentTasksViewPopupParams) => {
        Popup.replaceLastPopup(getQuery(params));
    };
    const isCurrent = Popup.isCurrentPopup(HARD_MODAL_ENUMS.TASKS_VIEW);
    return {
        isCurrent,
        openStudentTasksViewPopup,
        closeLastStudentTasksViewPopup,
        replaceLastStudentTasksViewPopup,
    };
};

export default useStudentTasksViewPopup;

import {
    ICreateTrainingRequest,
    ICreateTrainingResponse,
    IMaterialsMetaResponse,
    ITrainingCountInfo,
    ITrainingCountRequest,
    ITrainingProgressRequest,
    ITrainingProgressResponse,
    ITrainingQuestionDataResponse,
    ITrainingQuestionRequest,
    ITrainingQuestionResponse,
    ITrainingSearchBlockRequest,
    ITrainingSearchBlockResponse,
} from '@fsd-shared/models/materials';
import { getApiRequest, postApiRequest } from 'api';

export const getTrainingProgress = (params: ITrainingProgressRequest): Promise<ITrainingProgressResponse> => {
    return postApiRequest(`api/v2/training/progress/`, params);
};

export const getMaterialsMeta = (course: number): Promise<IMaterialsMetaResponse> =>
    postApiRequest(`meta/course/`, { course });

export const getTrainingQuestions = (params: ITrainingQuestionRequest): Promise<ITrainingQuestionResponse> => {
    return postApiRequest(`api/v2/training/questions/`, params);
};

export const postCreateTraining = (params: ICreateTrainingRequest): Promise<ICreateTrainingResponse> => {
    return postApiRequest(`api/v2/training/create/`, params);
};

export const getQuestionData = (questionId: number): Promise<ITrainingQuestionDataResponse> => {
    return getApiRequest(`api/v2/training/questions/${questionId}/`);
};

export const getTrainingCount = (params: ITrainingCountRequest): Promise<ITrainingCountInfo> => {
    return postApiRequest(`api/v2/training/`, params);
};

export const postTrainingSearchBlocks = (
    params: ITrainingSearchBlockRequest,
): Promise<Array<ITrainingSearchBlockResponse>> => {
    return postApiRequest(`api/v2/training/search-blocks/`, params);
};

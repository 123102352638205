import React, { useCallback, useRef } from 'react';
import { cn } from '@bem-react/classname';
import { Button, ButtonViewEnum } from '@lms-elements/atomic';
import { useOuterClick } from '@lms-elements/hooks';

import { IOptionPopUpWrapperProps } from './OptionPopUpWrapper.types';

import './OptionPopUpWrapper.scss';

const OptionPopUpWrapperCn = cn('option-popup-wrapper');

export const OptionPopUpWrapper: React.FC<IOptionPopUpWrapperProps> = ({
    setIsShown,
    onSaveClick,
    onCancelClick,
    onOutsideClick,
    children,
    isUseOuterClickRequired = true,
    isSaveDisabled = false,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    useOuterClick(ref, onOutsideClick || setIsShown);

    const handleSaveClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();

            onSaveClick();
        },
        [onSaveClick],
    );

    const handleCancel = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();

            if (onCancelClick) {
                onCancelClick();
            }
            setIsShown(false);
        },
        [onCancelClick, setIsShown],
    );

    return (
        <div ref={isUseOuterClickRequired ? ref : null} className={OptionPopUpWrapperCn('container')}>
            <div className={OptionPopUpWrapperCn()}>
                <div className={OptionPopUpWrapperCn('child-wrapper')}>{children}</div>
                <div className={OptionPopUpWrapperCn('btns')}>
                    <Button
                        view={ButtonViewEnum.action}
                        size="m"
                        customClasses={OptionPopUpWrapperCn('save')}
                        onClick={handleSaveClick}
                        disabled={isSaveDisabled}
                    >
                        Сохранить
                    </Button>
                    <Button
                        view={ButtonViewEnum.bordered}
                        size="m"
                        customClasses={OptionPopUpWrapperCn('cancel')}
                        onClick={handleCancel}
                    >
                        Отмена
                    </Button>
                </div>
            </div>
        </div>
    );
};

import { getApiRequest } from 'api';
import { Parallel } from 'types/parallel';
import { LessonTypes } from 'types/schedule';

export interface LessonScheduleResponse {
    id: number;
    lesson: number;
    type: LessonTypes;
    datetimeStart?: string;
    datetimeEnd?: string;
    parallels: Parallel[];
}

export const getLessonSchedule = async (
    lessonId: number[],
    courseGroupId?: number,
): Promise<LessonScheduleResponse[]> => {
    return await getApiRequest(`/lesson-schedule/`, {
        params: {
            lesson_ids: lessonId.join(','),
            course_group: courseGroupId,
        },
    });
};

import { useCallback, useEffect, useState } from 'react';

export const useWindowResize = (): boolean => {
    const [isTablet, setIsTablet] = useState(window.innerWidth <= 1024);

    const handleWindowResize = useCallback((event: UIEvent) => {
        const target = event.currentTarget as Window;
        setIsTablet(target.innerWidth <= 1024);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, [handleWindowResize]);

    return isTablet;
};

export const useWindowWidth = (targetWidth: number): boolean => {
    const [isSizeReached, setIsSizeReached] = useState(window.innerWidth <= targetWidth);

    const handleWindowResize = useCallback((event: UIEvent) => {
        const target = event.currentTarget as Window;
        setIsSizeReached(target.innerWidth <= targetWidth);
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, [handleWindowResize]);

    return isSizeReached;
};

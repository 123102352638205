import { questionsBankPageData } from 'constants/cypress/question-bank-page';

import React, { useCallback, useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';
import { LittleArrow } from '@lms-elements/icons';
import { BlockElement } from 'containers/CodifierSelector/BlockElement';
import { useCodifierContext } from 'containers/CodifierSelector/context';
import { TopicElement } from 'containers/CodifierSelector/TopicElement';
import { useAppSelector } from 'store/store';

import { CodifierEventType } from 'components/Codifier/Codifier';

import './SectionElement.scss';

const cx = cn('section-element');

interface SectionElementProps {
    sectionId: number;
    sectionIndex?: number;
}

export const SectionElement: React.FC<SectionElementProps> = ({ sectionId, sectionIndex }) => {
    const sectionData = useAppSelector((store) => store.codifier.sectionsMap[sectionId]);

    const {
        onElementClick,
        selectedElement: { sections, blocks, topics },
        withCheckboxes,
    } = useCodifierContext();

    const [isSelected, setIsSelected] = useState(false);
    const [isOpened, setIsOpened] = useState(false);

    useEffect(() => {
        setIsSelected(!withCheckboxes && !blocks?.length && !topics?.length && sections?.[0] === sectionId);
        setIsOpened(Boolean(sections?.includes(sectionId)));
    }, [blocks?.length, sectionId, sections, topics?.length, withCheckboxes]);

    const isDisabled = sectionData.blocks.length === 0 && sectionData.topics.length === 0;

    const handleSubjectClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            if (!isDisabled && !isSelected) {
                onElementClick(CodifierEventType.SECTION, sectionId);
            }
        },
        [isDisabled, isSelected, onElementClick, sectionId],
    );

    return (
        <div className={cx()}>
            <div
                className={cx('container', { selected: isSelected })}
                data-cy={
                    typeof sectionIndex === 'number'
                        ? questionsBankPageData.getSectionContainer(sectionIndex)
                        : undefined
                }
            >
                <div
                    className={cx('header', { selected: isSelected, disabled: isDisabled })}
                    onClick={handleSubjectClick}
                >
                    <div className={cx('open', { opened: isOpened, disabled: isDisabled })}>
                        <LittleArrow />
                    </div>
                    <div className={cx('title')}>{sectionData.title}</div>
                </div>
            </div>
            {isOpened && !isDisabled && (
                <div className={cx('elements-container', { selected: isSelected })}>
                    {sectionData.blocks.map((blockId, blockIndex) => (
                        <BlockElement key={blockId} blockId={blockId} blockIndex={blockIndex} />
                    ))}
                    {sectionData.topics.map((topicId) => (
                        <TopicElement key={topicId} topicId={topicId} />
                    ))}
                </div>
            )}
        </div>
    );
};

import { INotification, INotificationMessageProps, StyledNumberColor } from '@lms-elements/atomic';
import { Notification } from 'api/services/notifications';
import i18next from 'i18next';
import moment from 'moment';
import { NotificationType } from 'types/notifications';

type InfoForConverters = Pick<Notification, 'timeCreate' | 'data'> & {
    subjectTitle: string;
    courseGroupTitle?: string;
};

const convertToOverdueNotificationMessageProps = ({
    timeCreate,
    data,
}: InfoForConverters): INotificationMessageProps => {
    const tasksCount = data.count ?? 1;

    return {
        message: `${i18next.t('task', { count: tasksCount })}. Просрочен срок проверки`,
        time: moment(timeCreate).format('HH:mm'),
        number: {
            value: tasksCount,
            color: StyledNumberColor.red,
        },
    };
};

const convertToCheckNotificationMessageProps = ({ timeCreate, data }: InfoForConverters): INotificationMessageProps => {
    const tasksCount = data.count ?? 1;

    return {
        message: `${i18next.t('task', { count: tasksCount })} сдано в проверку`,
        time: moment(timeCreate).format('HH.mm'),
        number: {
            value: tasksCount,
            color: StyledNumberColor.green,
        },
    };
};

const convertToDeadlineNotificationMessageProps = ({
    timeCreate,
    subjectTitle,
    courseGroupTitle,
}: InfoForConverters): INotificationMessageProps => {
    return {
        message: `Необходимо проверить задание по предмету ${subjectTitle}${
            courseGroupTitle ? `, ${courseGroupTitle}` : ''
        }`,
        time: moment(timeCreate).format('HH.mm'),
    };
};

const convertToAssignmentNotificationMessageProps = ({
    timeCreate,
    subjectTitle,
}: InfoForConverters): INotificationMessageProps => {
    return {
        message: `У вас появилось задание${subjectTitle ? ` по предмету ${subjectTitle}` : ''}`,
        time: moment(timeCreate).format('HH.mm'),
    };
};

const convertToCompletedNotificationMessageProps = ({
    timeCreate,
    subjectTitle,
}: InfoForConverters): INotificationMessageProps => {
    return {
        message: `Ваше задание${subjectTitle ? ` по предмету ${subjectTitle}` : ''} проверили`,
        time: moment(timeCreate).format('HH.mm'),
    };
};

const notificationConvertersByType: Record<
    NotificationType,
    (notification: InfoForConverters) => INotificationMessageProps
> = {
    [NotificationType.ASSIGNMENTPROGRESS_OVERDUE]: convertToOverdueNotificationMessageProps,
    [NotificationType.ASSIGNMENTPROGRESS_CHECK]: convertToCheckNotificationMessageProps,
    [NotificationType.ASSIGNMENTPROGRESS_DEADLINE]: convertToDeadlineNotificationMessageProps,
    [NotificationType.ASSIGNMENTPROGRESS_ASSIGNMENT]: convertToAssignmentNotificationMessageProps,
    [NotificationType.ASSIGNMENTPROGRESS_COMPLETED]: convertToCompletedNotificationMessageProps,
};

export const normalizeNotification = (notification: Notification): INotification | null => {
    const { type, id, timeCreate, data } = notification;
    const notificationMessageProps = notificationConvertersByType[type]?.({
        ...notification,
        subjectTitle: data.subject.title,
        courseGroupTitle: data.courseGroup?.title,
    });

    if (notificationMessageProps === null) {
        return null;
    }

    return {
        id: String(id),
        date: moment(timeCreate).format(),
        notificationMessageProps: notificationMessageProps,
    };
};

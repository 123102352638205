import React from 'react';
import { AppSkeleton } from '@frontend-modules/ui-kit';

import { TCardWrapperProps } from './CardWrapper.types';

import './CardWrapper.style.scss';
export const CardWrapper: React.FC<TCardWrapperProps> = (props) => {
    const { children, isLoading = false, additionalCls = '' } = props;

    return (
        <div className={`card-wrapper ${isLoading ? 'fetching' : ''} ${additionalCls}`}>
            {isLoading ? (
                <AppSkeleton.Button shape={'square'} active prefixCls={'card-wrapper__skelet'} />
            ) : (
                <>{children}</>
            )}
        </div>
    );
};

import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { CodifierResponseData, CodifierSkillsResponseData, Section, Topic } from 'api/services/codifier';
import { WritableDraft } from 'immer/dist/internal';
import {
    deleteCodifierBlockAction,
    deleteCodifierMetasubjectAction,
    deleteCodifierParallelAction,
    deleteCodifierSectionAction,
    deleteCodifierSkillAction,
    deleteCodifierTopicAction,
    fetchCodifierDataAction,
    fetchCodifierSkillsDataAction,
    getCodifierDataByTopicsAction,
    getCodifierSubjectDataAction,
    getCodifierSubjectsAction,
    getSubjectsInCodifierAction,
    patchCodifierBlockAction,
    patchCodifierMetasubjectAction,
    patchCodifierParallelAction,
    patchCodifierSectionAction,
    patchCodifierSkillAction,
    patchCodifierTopicAction,
    postCodifierBlockAction,
    postCodifierMetasubjectAction,
    postCodifierParallelAction,
    postCodifierSectionAction,
    postCodifierSkillAction,
    postCodifierTopicAction,
} from 'store/actions/codifier';
import { getUserInfoAction } from 'store/actions/user';
import {
    CodifierBlockData,
    CodifierParallelData,
    CodifierSectionData,
    CodifierSubjectData,
    CodifierTopicData,
    getCodifierMaps,
} from 'store/normalizers/codifier';
import { getSubjectsInCodifier, getSubjectsMaps } from 'store/normalizers/codifier/getSubjectsMaps';
import { FetchStatus } from 'types/api';
import { UserRoles } from 'types/user';

interface CodifierState {
    fetchStatus: FetchStatus;
    getCodifierSubjectDataStatus: FetchStatus;
    fetchStatusSkills: FetchStatus;
    postDataStatus: FetchStatus;
    postCodifierBlockStatus: FetchStatus;
    patchDataStatus: FetchStatus;
    isMethodistFlag: boolean;
    codifierData: CodifierResponseData[];
    codifierSkillsData: CodifierSkillsResponseData[];
    subjectsIds: number[];
    subjectsMap: {
        [id: number]: CodifierSubjectData;
    };
    parallelsMap: {
        [id: number]: CodifierParallelData;
    };
    sectionsMap: {
        [id: number]: CodifierSectionData;
    };
    blocksMap: {
        [id: number]: CodifierBlockData;
    };
    topicsMap: {
        [id: number]: CodifierTopicData;
    };
    getSubjectsStatus: FetchStatus;
    error: unknown;
}

const initialState: CodifierState = {
    fetchStatus: FetchStatus.INITIAL,
    getCodifierSubjectDataStatus: FetchStatus.INITIAL,
    fetchStatusSkills: FetchStatus.INITIAL,
    postDataStatus: FetchStatus.INITIAL,
    postCodifierBlockStatus: FetchStatus.INITIAL,
    isMethodistFlag: false,
    patchDataStatus: FetchStatus.INITIAL,
    getSubjectsStatus: FetchStatus.INITIAL,
    codifierData: [],
    codifierSkillsData: [],
    subjectsIds: [],
    subjectsMap: {},
    parallelsMap: {},
    sectionsMap: {},
    blocksMap: {},
    topicsMap: {},
    error: null,
};

const codifierSlice = createSlice<CodifierState, SliceCaseReducers<CodifierState>>({
    name: 'codifier',
    initialState,
    reducers: {
        resetFetchStatuses(state) {
            state.fetchStatus = FetchStatus.INITIAL;
            state.fetchStatusSkills = FetchStatus.INITIAL;
            state.postDataStatus = FetchStatus.INITIAL;
            state.patchDataStatus = FetchStatus.INITIAL;
        },
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUserInfoAction.fulfilled, (state, { payload }) => {
            state.isMethodistFlag = payload.userRoles.includes(UserRoles.METHODIST) || false;
        });
        builder.addCase(fetchCodifierDataAction.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(fetchCodifierDataAction.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            const codifierDataWithSortedParallel =
                payload?.map((subject) => {
                    subject.parallels = subject.parallels?.sort((a, b) => a.grade - b.grade);
                    return subject;
                }) || [];
            state.codifierData = codifierDataWithSortedParallel;

            const { subjectIds, subjectsMap, parallelsMap, sectionsMap, blocksMap, topicsMap } = getCodifierMaps(
                codifierDataWithSortedParallel,
            );

            state.subjectsIds = subjectIds;
            state.subjectsMap = subjectsMap;
            state.parallelsMap = parallelsMap;
            state.sectionsMap = sectionsMap;
            state.blocksMap = blocksMap;
            state.topicsMap = topicsMap;
            state.error = null;
        });
        builder.addCase(fetchCodifierDataAction.rejected, (state, { error }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(postCodifierParallelAction.pending, (state) => {
            state.postDataStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(postCodifierParallelAction.fulfilled, (state, { payload }) => {
            state.postDataStatus = FetchStatus.FETCHED;

            if (state.fetchStatus == FetchStatus.FETCHED) {
                state.codifierData = state.codifierData.map((subject) => {
                    if (subject.id === payload.subject) {
                        if (subject.parallels) {
                            subject.parallels.push({
                                id: payload.id,
                                grade: payload.grade,
                                sections: [],
                            });
                            subject.parallels = subject.parallels.sort((a, b) => a.grade - b.grade);
                        } else {
                            subject.parallels = [
                                {
                                    id: payload.id,
                                    grade: payload.grade,
                                    sections: [],
                                },
                            ];
                        }
                    }

                    return subject;
                });
            }

            if (state.fetchStatusSkills === FetchStatus.FETCHED) {
                state.codifierSkillsData = state.codifierSkillsData.map((subject) => {
                    if (subject.id === payload.subject) {
                        if (subject.parallels) {
                            subject.parallels.push({
                                id: payload.id,
                                grade: payload.grade,
                                metasubjects: [],
                            });
                            subject.parallels = subject.parallels.sort((a, b) => a.grade - b.grade);
                        } else {
                            subject.parallels = [
                                {
                                    id: payload.id,
                                    grade: payload.grade,
                                    metasubjects: [],
                                },
                            ];
                        }
                    }

                    return subject;
                });
            }
            state.error = null;
        });
        builder.addCase(postCodifierParallelAction.rejected, (state, { error }) => {
            state.postDataStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(deleteCodifierParallelAction.pending, (state) => {
            state.postDataStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(deleteCodifierParallelAction.fulfilled, (state, { payload }) => {
            state.postDataStatus = FetchStatus.FETCHED;

            if (state.fetchStatus == FetchStatus.FETCHED) {
                state.codifierData = state.codifierData.map((subject) => {
                    subject.parallels = subject.parallels?.filter((parallel) => parallel.id !== payload);

                    return subject;
                });
            }

            if (state.fetchStatusSkills === FetchStatus.FETCHED) {
                state.codifierSkillsData = state.codifierSkillsData.map((subject) => {
                    subject.parallels = subject.parallels?.filter((parallel) => parallel.id !== payload);

                    return subject;
                });
            }

            state.error = null;
        });
        builder.addCase(deleteCodifierParallelAction.rejected, (state, { error }) => {
            state.postDataStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(postCodifierSectionAction.pending, (state) => {
            state.postDataStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(postCodifierSectionAction.fulfilled, (state, { payload }) => {
            state.postDataStatus = FetchStatus.FETCHED;
            state.codifierData = state.codifierData.map((subject) => {
                subject.parallels = subject.parallels?.map((parallel) => {
                    if (parallel.id === payload[1]) {
                        parallel.sections = payload[0].ordering.map((elem, index) => {
                            const section = parallel.sections?.find((el) => el.id === elem);
                            return {
                                id: section?.id ?? elem,
                                title: section?.title ?? payload[2],
                                order: index,
                                blocks: section?.blocks,
                            };
                        });
                    }
                    return parallel;
                });

                return subject;
            });
            state.error = null;
        });
        builder.addCase(postCodifierSectionAction.rejected, (state, { error }) => {
            state.postDataStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(deleteCodifierSectionAction.pending, (state) => {
            state.postDataStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(deleteCodifierSectionAction.fulfilled, (state, { payload }) => {
            state.postDataStatus = FetchStatus.FETCHED;
            state.codifierData = state.codifierData.map((subject) => {
                subject.parallels = subject.parallels?.map((parallel) => {
                    parallel.sections = parallel.sections
                        ?.filter((section) => section.id !== payload)
                        .map((section, index) => {
                            return {
                                ...section,
                                order: index,
                            };
                        });
                    return parallel;
                });

                return subject;
            });
            state.error = null;
        });
        builder.addCase(deleteCodifierSectionAction.rejected, (state, { error }) => {
            state.postDataStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(postCodifierBlockAction.pending, (state) => {
            state.postDataStatus = FetchStatus.FETCHING;
            state.postCodifierBlockStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(postCodifierBlockAction.fulfilled, (state, { payload }) => {
            state.postDataStatus = FetchStatus.FETCHED;
            state.postCodifierBlockStatus = FetchStatus.FETCHED;
            state.codifierData = state.codifierData.map((subject) => {
                subject.parallels = subject.parallels?.map((parallel) => {
                    parallel.sections = parallel.sections?.map((section) => {
                        if (section.id === payload[3]) {
                            section.blocks = payload[0].ordering.map((elem, index) => {
                                const block = section.blocks?.find((el) => el.id === elem);
                                return {
                                    id: block?.id ?? elem,
                                    title: block?.title ?? payload[2],
                                    order: index,
                                    section: section.id,
                                    topics: block?.topics ?? [],
                                };
                            });
                        }

                        return section;
                    });

                    return parallel;
                });

                return subject;
            });
            state.error = null;
        });
        builder.addCase(postCodifierBlockAction.rejected, (state, { error }) => {
            state.postDataStatus = FetchStatus.ERROR;
            state.postCodifierBlockStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(deleteCodifierBlockAction.pending, (state) => {
            state.postDataStatus = FetchStatus.FETCHING;

            state.error = null;
        });
        builder.addCase(deleteCodifierBlockAction.fulfilled, (state, { payload }) => {
            state.postDataStatus = FetchStatus.FETCHED;
            state.codifierData = state.codifierData.map((subject) => {
                subject.parallels = subject.parallels?.map((parallel) => {
                    parallel.sections = parallel.sections?.map((section) => {
                        section.blocks = section.blocks
                            ?.filter((block) => block.id !== payload)
                            .map((block, index) => {
                                return {
                                    ...block,
                                    order: index,
                                };
                            });

                        return section;
                    });
                    return parallel;
                });

                return subject;
            });
            state.error = null;
        });
        builder.addCase(deleteCodifierBlockAction.rejected, (state, { error }) => {
            state.postDataStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(postCodifierTopicAction.pending, (state) => {
            state.postDataStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(postCodifierTopicAction.fulfilled, (state, { meta, payload }) => {
            state.postDataStatus = FetchStatus.FETCHED;
            state.codifierData = state.codifierData.map((subject) => {
                subject.parallels = subject.parallels?.map((parallel) => {
                    parallel.sections = parallel.sections?.map((section) => {
                        if (section.id === meta.arg.section) {
                            section.blocks?.map((block) => {
                                if (block.id === meta.arg.block) {
                                    const newTopicId = payload[0]?.ordering?.[meta.arg.order - 1];
                                    const newTopic: WritableDraft<Topic> = {
                                        id: newTopicId ?? 0,
                                        title: meta.arg.title,
                                        order: meta.arg.order,
                                        questionsQuantity: 0,
                                        questionsQuantityUnpublished: 0,
                                        questionsQuantityRevision: 0,
                                    };
                                    const topics = block.topics ?? [];
                                    topics.push(newTopic);
                                    //@ts-ignore
                                    block.topics = payload[0]?.ordering
                                        .map((order, index) => {
                                            const item = topics.find((topic) => topic.id === order);
                                            return {
                                                ...item,
                                                order: index,
                                            };
                                        })
                                        .sort((a, b) => a.order - b.order);
                                }
                            });
                        }
                        return section;
                    });

                    return parallel;
                });

                return subject;
            });
            state.error = null;
        });
        builder.addCase(postCodifierTopicAction.rejected, (state, { error }) => {
            state.postDataStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(deleteCodifierTopicAction.pending, (state) => {
            state.postDataStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(deleteCodifierTopicAction.fulfilled, (state, { payload }) => {
            state.postDataStatus = FetchStatus.FETCHED;
            state.codifierData = state.codifierData.map((subject) => {
                subject.parallels = subject.parallels?.map((parallel) => {
                    parallel.sections = parallel.sections?.map((section) => {
                        section.blocks?.map((block) => {
                            if (block.id === payload.blockId) {
                                block.topics = block.topics
                                    ?.filter((topic) => topic.id !== payload?.id)
                                    .map((topic, index) => {
                                        return {
                                            ...topic,
                                            order: index,
                                        };
                                    });
                            }
                        });
                        return section;
                    });
                    return parallel;
                });

                return subject;
            });
            state.error = null;
        });
        builder.addCase(deleteCodifierTopicAction.rejected, (state, { error }) => {
            state.postDataStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(fetchCodifierSkillsDataAction.pending, (state) => {
            state.fetchStatusSkills = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(fetchCodifierSkillsDataAction.fulfilled, (state, { payload }) => {
            state.fetchStatusSkills = FetchStatus.FETCHED;
            state.codifierSkillsData =
                payload?.map((subject) => {
                    subject.parallels = subject.parallels?.sort((a, b) => a.grade - b.grade);
                    return subject;
                }) || [];
            state.error = null;
        });
        builder.addCase(fetchCodifierSkillsDataAction.rejected, (state, { error }) => {
            state.fetchStatusSkills = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(postCodifierMetasubjectAction.pending, (state) => {
            state.postDataStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(postCodifierMetasubjectAction.fulfilled, (state, { payload }) => {
            state.postDataStatus = FetchStatus.FETCHED;
            state.codifierSkillsData = state.codifierSkillsData.map((subject) => {
                subject.parallels = subject.parallels?.map((parallel) => {
                    if (parallel.id === payload.parallel) {
                        if (parallel.metasubjects) {
                            parallel.metasubjects.push({
                                id: payload.id,
                                title: payload.title,
                                skills: [],
                            });
                        } else {
                            parallel.metasubjects = [
                                {
                                    id: payload.id,
                                    title: payload.title,
                                    skills: [],
                                },
                            ];
                        }
                    }
                    return parallel;
                });

                return subject;
            });
            state.error = null;
        });
        builder.addCase(postCodifierMetasubjectAction.rejected, (state, { error }) => {
            state.postDataStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(deleteCodifierMetasubjectAction.pending, (state) => {
            state.postDataStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(deleteCodifierMetasubjectAction.fulfilled, (state, { payload }) => {
            state.postDataStatus = FetchStatus.FETCHED;
            state.codifierSkillsData = state.codifierSkillsData.map((subject) => {
                subject.parallels = subject.parallels?.map((parallel) => {
                    parallel.metasubjects = parallel.metasubjects?.filter((metasubject) => metasubject.id !== payload);
                    return parallel;
                });

                return subject;
            });
            state.error = null;
        });
        builder.addCase(deleteCodifierMetasubjectAction.rejected, (state, { error }) => {
            state.postDataStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(postCodifierSkillAction.pending, (state) => {
            state.postDataStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(postCodifierSkillAction.fulfilled, (state, { payload }) => {
            state.postDataStatus = FetchStatus.FETCHED;
            state.codifierSkillsData = state.codifierSkillsData.map((subject) => {
                subject.parallels = subject.parallels?.map((parallel) => {
                    parallel.metasubjects = parallel.metasubjects?.map((metasubject) => {
                        if (metasubject.id === payload.metasubject) {
                            if (metasubject.skills) {
                                metasubject.skills.push({
                                    id: payload.id,
                                    title: payload.title,
                                    codifier: payload.codifier,
                                });
                            } else {
                                metasubject.skills = [
                                    {
                                        id: payload.id,
                                        title: payload.title,
                                    },
                                ];
                            }
                        }

                        return metasubject;
                    });

                    return parallel;
                });

                return subject;
            });
            state.error = null;
        });
        builder.addCase(postCodifierSkillAction.rejected, (state, { error }) => {
            state.postDataStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(deleteCodifierSkillAction.pending, (state) => {
            state.postDataStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(deleteCodifierSkillAction.fulfilled, (state, { payload }) => {
            state.postDataStatus = FetchStatus.FETCHED;
            state.codifierSkillsData = state.codifierSkillsData.map((subject) => {
                subject.parallels = subject.parallels?.map((parallel) => {
                    parallel.metasubjects = parallel.metasubjects?.map((metasubject) => {
                        metasubject.skills = metasubject.skills?.filter((skill) => skill.id !== payload);

                        return metasubject;
                    });
                    return parallel;
                });

                return subject;
            });
            state.error = null;
        });
        builder.addCase(patchCodifierParallelAction.pending, (state) => {
            state.patchDataStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(patchCodifierParallelAction.fulfilled, (state, { meta }) => {
            state.patchDataStatus = FetchStatus.FETCHED;
            if (state.fetchStatus == FetchStatus.FETCHED) {
                state.codifierData = state.codifierData.map((subject) => {
                    subject.parallels = subject.parallels
                        ?.map((parallel) => {
                            if (parallel.id === meta.arg.id) {
                                return { ...parallel, grade: meta.arg.grade };
                            }
                            return parallel;
                        })
                        .sort((a, b) => a.grade - b.grade);
                    return subject;
                });
            }

            if (state.fetchStatusSkills === FetchStatus.FETCHED) {
                state.codifierSkillsData = state.codifierSkillsData.map((subject) => {
                    subject.parallels = subject.parallels
                        ?.map((parallel) => {
                            if (parallel.id === meta.arg.id) {
                                return { ...parallel, grade: meta.arg.grade };
                            }
                            return parallel;
                        })
                        .sort((a, b) => a.grade - b.grade);
                    return subject;
                });
            }
            state.error = null;
        });
        builder.addCase(patchCodifierParallelAction.rejected, (state, { error }) => {
            state.patchDataStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(patchCodifierSectionAction.pending, (state) => {
            state.patchDataStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(patchCodifierSectionAction.fulfilled, (state, { meta, payload }) => {
            const selectedSectionBlock = state.codifierData
                .find((el) => el.id === meta.arg.subject)
                ?.parallels?.find((el) => el.id === meta.arg.oldParallel)
                ?.sections?.find((el) => el.id === meta.arg.id)?.blocks;

            state.patchDataStatus = FetchStatus.FETCHED;
            if (state.fetchStatus == FetchStatus.FETCHED) {
                state.codifierData = state.codifierData.map((subject) => {
                    subject.parallels = subject.parallels?.map((parallel) => {
                        if (parallel.id === meta.arg.oldParallel) {
                            parallel.sections = parallel.sections
                                ?.filter((item) => item.id !== meta.arg.id)
                                .map((section, index) => {
                                    return {
                                        ...section,
                                        order: index,
                                    };
                                });
                        } else if (parallel.id === meta.arg.parallel) {
                            parallel.sections = payload[0].ordering.map((elem, index) => {
                                const section = parallel.sections?.find((el) => el.id === elem);
                                return {
                                    id: section?.id ?? elem,
                                    title:
                                        meta.arg.id === elem
                                            ? payload[1]
                                            : parallel.sections?.find((el) => el.id === elem)?.title ?? '',
                                    order: index,
                                    blocks: !section?.id ? selectedSectionBlock : section?.blocks,
                                };
                            });
                        }

                        return parallel;
                    });
                    return subject;
                });
            }

            state.error = null;
        });
        builder.addCase(patchCodifierSectionAction.rejected, (state, { error }) => {
            state.patchDataStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(patchCodifierBlockAction.pending, (state) => {
            state.patchDataStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(patchCodifierBlockAction.fulfilled, (state, { payload, meta }) => {
            state.patchDataStatus = FetchStatus.FETCHED;
            if (state.fetchStatus == FetchStatus.FETCHED) {
                state.codifierData = state.codifierData.map((subject) => {
                    subject.parallels = subject.parallels?.map((parallel) => {
                        // темы, которые нужно перенести из одной секции в другую
                        const topics: WritableDraft<Topic>[] | undefined = meta.arg.oldSection
                            ? parallel.sections
                                  ?.find(({ id }) => id === meta.arg.oldSection)
                                  ?.blocks?.find(({ id }) => id === meta.arg.id)?.topics
                            : [];
                        parallel.sections = parallel.sections?.map((section) => {
                            //удаляем блок из старой секции
                            if (section.id === meta.arg.oldSection) {
                                section.blocks = section.blocks
                                    ?.filter((el) => el.id !== meta.arg.id)
                                    .map((block, index) => {
                                        return {
                                            ...block,
                                            order: index,
                                        };
                                    });
                            }
                            //добавляем блок в новую секцию
                            if (section.id === meta.arg.section) {
                                section.blocks = payload[0].ordering.map((elem, index) => {
                                    const block = section.blocks?.find((el) => el.id === elem);

                                    return {
                                        id: block?.id ?? elem,
                                        title:
                                            meta.arg.id === elem
                                                ? payload[1]
                                                : section.blocks?.find((el) => el.id === elem)?.title ?? '',
                                        order: index,
                                        section: section.id,
                                        topics: block?.topics ?? topics,
                                    };
                                });
                            }
                            return section;
                        });
                        return parallel;
                    });
                    return subject;
                });
            }

            state.error = null;
        });
        builder.addCase(patchCodifierBlockAction.rejected, (state, { error }) => {
            state.patchDataStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(patchCodifierTopicAction.pending, (state) => {
            state.patchDataStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(patchCodifierTopicAction.fulfilled, (state, { meta, payload }) => {
            state.patchDataStatus = FetchStatus.FETCHED;
            if (state.fetchStatus == FetchStatus.FETCHED) {
                state.codifierData = state.codifierData.map((subject) => {
                    subject.parallels = subject.parallels?.map((parallel) => {
                        const topicToTransfer: WritableDraft<Topic> | undefined = parallel.sections
                            ?.find(({ id }) => id === meta.arg.oldSection)
                            ?.blocks?.find(({ id }) => id === meta.arg.oldBlock)
                            ?.topics?.find(({ id }) => id === meta.arg.id);

                        parallel.sections = parallel.sections?.map((section) => {
                            if (section.id === meta.arg.oldSection) {
                                section.blocks = section.blocks?.map((block) => {
                                    if (block.id === meta.arg.oldBlock) {
                                        const list = block?.topics
                                            ?.filter(({ id }) => id !== meta.arg.id)
                                            .map((el, index) => {
                                                return {
                                                    ...el,
                                                    order: index,
                                                };
                                            });
                                        return {
                                            ...block,
                                            topics: list,
                                        };
                                    }
                                    return block;
                                });
                            }
                            if (section.id === meta.arg.section) {
                                section.blocks = section.blocks?.map((block) => {
                                    if (block.id === meta.arg.block) {
                                        //удаляем тему, если она уже существует и добавляем ее же с измененными данными
                                        let newBlocksTopicList =
                                            block.topics?.filter((item) => item.id !== meta.arg.id) ?? [];
                                        const newTopic: WritableDraft<Topic> = {
                                            id: meta.arg.id ?? topicToTransfer?.id ?? 0,
                                            title: meta.arg.title ?? topicToTransfer?.title ?? '',
                                            order: meta.arg.order ?? topicToTransfer?.order ?? 0,
                                            questionsQuantity: topicToTransfer?.questionsQuantity ?? 0,
                                            questionsQuantityRevision: topicToTransfer?.questionsQuantityRevision ?? 0,
                                            questionsQuantityUnpublished:
                                                topicToTransfer?.questionsQuantityUnpublished ?? 0,
                                        };

                                        newBlocksTopicList?.push(newTopic);
                                        // @ts-ignore
                                        newBlocksTopicList = payload[0]?.ordering
                                            .map((order, index) => {
                                                const item = newBlocksTopicList.find((topic) => topic.id === order);
                                                return {
                                                    ...item,
                                                    order: index,
                                                };
                                            })
                                            .sort((a, b) => a.order - b.order);
                                        return {
                                            ...block,
                                            topics: newBlocksTopicList,
                                        };
                                    }

                                    return block;
                                });
                            }
                            return section;
                        });
                        return parallel;
                    });
                    return subject;
                });
            }

            state.error = null;
        });
        builder.addCase(patchCodifierTopicAction.rejected, (state, { error }) => {
            state.patchDataStatus = FetchStatus.ERROR;
            state.error = error;
        });
        //PatchSkills
        builder.addCase(patchCodifierMetasubjectAction.pending, (state) => {
            state.patchDataStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(patchCodifierMetasubjectAction.fulfilled, (state, { meta }) => {
            state.patchDataStatus = FetchStatus.FETCHED;
            if (state.fetchStatusSkills == FetchStatus.FETCHED) {
                state.codifierSkillsData = state.codifierSkillsData.map((subject) => {
                    subject.parallels = subject.parallels?.map((parallel) => {
                        parallel.metasubjects = parallel.metasubjects?.map((metasubject) => {
                            if (metasubject.id === meta.arg.id) {
                                return { ...metasubject, title: meta.arg.title };
                            }
                            return metasubject;
                        });
                        return parallel;
                    });
                    return subject;
                });
            }

            state.error = null;
        });
        builder.addCase(patchCodifierMetasubjectAction.rejected, (state, { error }) => {
            state.patchDataStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(patchCodifierSkillAction.pending, (state) => {
            state.patchDataStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(patchCodifierSkillAction.fulfilled, (state, { meta, payload }) => {
            state.patchDataStatus = FetchStatus.FETCHED;
            if (state.fetchStatusSkills == FetchStatus.FETCHED) {
                state.codifierSkillsData = state.codifierSkillsData.map((subject) => {
                    subject.parallels = subject.parallels?.map((parallel) => {
                        parallel.metasubjects = parallel.metasubjects?.map((metasubject) => {
                            metasubject.skills = metasubject.skills?.map((skill) => {
                                if (skill.id === meta.arg.id) {
                                    return { ...skill, title: meta.arg.title, codifier: payload.codifier };
                                }
                                return skill;
                            });
                            return metasubject;
                        });
                        return parallel;
                    });
                    return subject;
                });
            }

            state.error = null;
        });
        builder.addCase(patchCodifierSkillAction.rejected, (state, { error }) => {
            state.patchDataStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder
            .addCase(getCodifierSubjectsAction.pending, (state) => {
                state.getSubjectsStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getCodifierSubjectsAction.fulfilled, (state, { payload }) => {
                state.getSubjectsStatus = FetchStatus.FETCHED;
                const { subjectsIds, subjectsMap } = getSubjectsMaps(payload);
                state.subjectsIds = subjectsIds;
                state.subjectsMap = subjectsMap;
                state.codifierData = getSubjectsInCodifier(payload);
                state.error = null;
            })
            .addCase(getCodifierSubjectsAction.rejected, (state, { error }) => {
                state.getSubjectsStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getSubjectsInCodifierAction.pending, (state) => {
                state.getSubjectsStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getSubjectsInCodifierAction.fulfilled, (state, { payload }) => {
                state.getSubjectsStatus = FetchStatus.FETCHED;
                state.codifierData = getSubjectsInCodifier(payload);
                state.error = null;
            })
            .addCase(getSubjectsInCodifierAction.rejected, (state, { error }) => {
                state.getSubjectsStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getCodifierSubjectDataAction.pending, (state) => {
                state.fetchStatus = FetchStatus.FETCHING;
                state.getCodifierSubjectDataStatus = FetchStatus.FETCHING;
                state.postCodifierBlockStatus = FetchStatus.INITIAL;
                state.error = null;
            })
            .addCase(getCodifierSubjectDataAction.fulfilled, (state, { payload }) => {
                state.fetchStatus = FetchStatus.FETCHED;
                state.getCodifierSubjectDataStatus = FetchStatus.FETCHED;

                const codifierDataWithSortedParallel =
                    payload?.map((subject) => {
                        subject.parallels = subject.parallels?.sort((a, b) => a.grade - b.grade);
                        return subject;
                    }) || [];
                const { subjectsMap, parallelsMap, sectionsMap, blocksMap, topicsMap } = getCodifierMaps(
                    codifierDataWithSortedParallel,
                );

                state.codifierData = state.codifierData.length
                    ? state.codifierData.map((elem) => {
                          if (elem.id === payload[0].id) return codifierDataWithSortedParallel[0];
                          else return elem;
                      })
                    : payload;

                state.subjectsMap = {
                    ...state.subjectsMap,
                    ...subjectsMap,
                };
                state.parallelsMap = {
                    ...state.parallelsMap,
                    ...parallelsMap,
                };
                state.sectionsMap = {
                    ...state.sectionsMap,
                    ...sectionsMap,
                };
                state.blocksMap = {
                    ...state.blocksMap,
                    ...blocksMap,
                };
                state.topicsMap = {
                    ...state.topicsMap,
                    ...topicsMap,
                };
                state.error = null;
            })
            .addCase(getCodifierSubjectDataAction.rejected, (state, { error }) => {
                state.fetchStatus = FetchStatus.ERROR;
                state.getCodifierSubjectDataStatus = FetchStatus.ERROR;

                state.error = error;
            });
        builder
            .addCase(getCodifierDataByTopicsAction.pending, (state) => {
                state.fetchStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getCodifierDataByTopicsAction.fulfilled, (state, { payload }) => {
                state.fetchStatus = FetchStatus.FETCHED;
                const codifierDataWithSortedParallel =
                    payload?.map((subject) => {
                        subject.parallels = subject.parallels?.sort((a, b) => a.grade - b.grade);
                        return subject;
                    }) || [];
                const { subjectsMap, parallelsMap, sectionsMap, blocksMap, topicsMap } = getCodifierMaps(
                    codifierDataWithSortedParallel,
                );

                state.codifierData = state.codifierData
                    .filter((subject) => subject.id !== payload[0]?.id)
                    .concat(codifierDataWithSortedParallel);

                state.subjectsMap = {
                    ...state.subjectsMap,
                    ...subjectsMap,
                };
                state.parallelsMap = {
                    ...state.parallelsMap,
                    ...parallelsMap,
                };
                state.sectionsMap = {
                    ...state.sectionsMap,
                    ...sectionsMap,
                };
                state.blocksMap = {
                    ...state.blocksMap,
                    ...blocksMap,
                };
                state.topicsMap = {
                    ...state.topicsMap,
                    ...topicsMap,
                };
                state.error = null;
            })
            .addCase(getCodifierDataByTopicsAction.rejected, (state, { error }) => {
                state.fetchStatus = FetchStatus.ERROR;
                state.error = error;
            });
    },
});

const resetFetchStatuses = codifierSlice.actions.resetFetchStatuses as ActionCreatorWithoutPayload;
const resetCodifierState = codifierSlice.actions.reset as ActionCreatorWithoutPayload;
export { resetCodifierState, resetFetchStatuses };
export const codifierReducer = codifierSlice.reducer;

import { useMemo } from 'react';
import { useRole } from 'hooks';
import { TAssigmentProgresses, TSubjectLesson, TTeachersAssigmentProgresses } from 'src-new/components/new-subjects';

import { TUseGetLessonHomeworksResponse } from './useGetLessonHomeworks.types';

export const useGetLessonHomeworks = (lessonInfo: TSubjectLesson): TUseGetLessonHomeworksResponse => {
    const { assignmentProgresses } = lessonInfo;
    const { isStudent, isTeacher } = useRole();

    const getTitleForStudent = (progress: TAssigmentProgresses[]): string => {
        const homeworksCount = progress?.length;
        const completedHomeworksCount = progress?.filter((item) => item.status === 'completed')?.length;
        const unissuedHomeworksCount = progress?.filter((item) => item.status === 'deferred_assignment')?.length;

        if (!homeworksCount) {
            return 'Нет заданий';
        } else {
            if (homeworksCount === unissuedHomeworksCount) {
                return 'ДЗ не было выдано';
            } else {
                return `Задано: ${homeworksCount - unissuedHomeworksCount} ДЗ, Сдано: ${completedHomeworksCount} ДЗ`;
            }
        }
    };
    const getTitleForTeacher = (progress: TTeachersAssigmentProgresses): string => {
        const { assigned, completed } = progress;

        if (!assigned) {
            return 'Нет заданий';
        } else {
            return `Задано: ${assigned} ДЗ, Сдано: ${completed} ДЗ`;
        }
    };

    const homeworkTitle = useMemo(() => {
        if (isStudent) {
            return getTitleForStudent(assignmentProgresses as TAssigmentProgresses[]);
        }
        if (isTeacher) {
            return getTitleForTeacher(assignmentProgresses as TTeachersAssigmentProgresses);
        }
        return '';
    }, [isStudent, isTeacher, assignmentProgresses]);

    return {
        homeworkTitle,
    };
};

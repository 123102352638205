export const PIXELS_PER_MINUTE = 3;
export const INTERVAL_DURATION = 50;
export const CARD_WIDTH = 150;
export const CARD_OFFSET = 4;
export const TOOLTIP_OFFSET = 20;
export const SCHEDULE_PADDING_TOP = 24;
export const CHART_WIDTH = 200;

export enum EAppDaySchedule {
    PIXELS_PER_MINUTE,
    INTERVAL_DURATION,
    CARD_WIDTH,
    CARD_OFFSET,
    TOOLTIP_OFFSET,
    SCHEDULE_PADDING_TOP,
    CHART_WIDTH,
}

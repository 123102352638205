import React, { useEffect, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { TextareaField } from '@lms-elements/atomic';
import { getObjectPropertyByString } from '@lms-elements/utils';

import { ITextareaFieldWrapperProps } from './TextareaFieldWrapper.types';

import './TextareaFieldWrapper.scss';

const TextareaFieldWrapperCn = cn('expanded-question-textarea');

export const TextareaFieldWrapper: React.FC<ITextareaFieldWrapperProps> = ({ name, placeholder }) => {
    const { values } = useFormState();
    const { change } = useForm();

    const [value, setValue] = useState(getObjectPropertyByString<string>(values, name) || '');

    // !!! DONT TOUCH
    useEffect(() => {
        setValue(() => getObjectPropertyByString<string>(values, name) || '');
    }, [name, values]);

    return (
        <Field name={name} validate={(value: string) => (value ? undefined : 'error')}>
            {() => (
                <TextareaField
                    className={TextareaFieldWrapperCn()}
                    isField={false}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={() => change(name, value)}
                    isError={value.trim().length === 0}
                    onClear={() => {
                        setValue('');
                        change(name, '');
                    }}
                />
            )}
        </Field>
    );
};

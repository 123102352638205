import { useMemo } from 'react';
import { useQuery } from 'hooks/useQuery';

interface CourseParams {
    courseId: number | undefined;
}

export const useGetCourseQuery = (): CourseParams => {
    const query = useQuery();
    const courseId = useMemo(() => {
        const course = query.get('courseId');
        const courseId = Number(course);

        if (isNaN(courseId)) {
            return undefined;
        }

        return courseId;
    }, [query]);

    return { courseId };
};

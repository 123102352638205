import { api } from 'api';
import { NewsFile } from 'api/services/news';
import { AxiosError, AxiosResponse } from 'axios';

export const createNewsFile = (newsElement: number, file: File): Promise<NewsFile> => {
    const data = new FormData();
    data.append('file', file);
    data.append('newsElement', String(newsElement));

    return api
        .post<Promise<NewsFile>, AxiosResponse<NewsFile>>(`/news-element-file/create/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
                Accept: 'application/json',
            },
        })
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

import React, { useContext } from 'react';
import { SelectedElement } from 'containers/CodifierSelector/utils';

import { CodifierEventType } from 'components/Codifier/Codifier';

interface Context {
    onElementClick: (type: CodifierEventType, id: number) => void;
    selectedElement: SelectedElement;
    withCheckboxes?: boolean;
}

export const CodifierContext = React.createContext({} as Context);

export function useCodifierContext(): Context {
    return useContext(CodifierContext);
}

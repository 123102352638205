import { createSlice } from '@reduxjs/toolkit';
import {
    createMedicalCertificateAction,
    getMedicalCertificatesAction,
    updateMedicalCertificateAction,
} from 'store/actions/medicalCertificate';
import { FetchStatus } from 'types/api';
import { MedicalCertificates } from 'types/certificates';

interface MedicalCertificateState {
    updateCertificateStatus: FetchStatus;
    getCertificateStatus: FetchStatus;
    postCertificateStatus: FetchStatus;
    approvedCertificates: MedicalCertificates[];
    notApprovedCertificates: MedicalCertificates[];
    error: unknown;
}

const initialState: MedicalCertificateState = {
    updateCertificateStatus: FetchStatus.INITIAL,
    getCertificateStatus: FetchStatus.INITIAL,
    postCertificateStatus: FetchStatus.INITIAL,
    approvedCertificates: [],
    notApprovedCertificates: [],
    error: null,
};

const medicalCertificateSlice = createSlice({
    name: 'medicalCertificate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMedicalCertificatesAction.pending, (state) => {
            state.getCertificateStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getMedicalCertificatesAction.fulfilled, (state, { payload }) => {
            const approved: MedicalCertificates[] = [];
            const notApproved: MedicalCertificates[] = [];
            payload.map((certificate) => {
                if (certificate.isApproved === true) approved.push(certificate);
                if (certificate.isApproved === null) notApproved.push(certificate);
            });

            state.getCertificateStatus = FetchStatus.FETCHED;
            state.approvedCertificates = approved;
            state.notApprovedCertificates = notApproved;
            state.error = null;
        });
        builder.addCase(getMedicalCertificatesAction.rejected, (state, { error }) => {
            state.getCertificateStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(createMedicalCertificateAction.pending, (state) => {
            state.updateCertificateStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(createMedicalCertificateAction.fulfilled, (state, { payload }) => {
            state.updateCertificateStatus = FetchStatus.FETCHED;
            state.approvedCertificates = state.approvedCertificates.concat(payload);
            state.notApprovedCertificates = state.notApprovedCertificates.filter(
                (certificate) => certificate.id !== payload.id,
            );
            state.error = null;
        });
        builder.addCase(createMedicalCertificateAction.rejected, (state, { error }) => {
            state.updateCertificateStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(updateMedicalCertificateAction.pending, (state) => {
            state.postCertificateStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(updateMedicalCertificateAction.fulfilled, (state, { payload }) => {
            state.postCertificateStatus = FetchStatus.FETCHED;
            if (payload?.isApproved === true) {
                state.approvedCertificates = state.approvedCertificates.concat(payload);
                state.notApprovedCertificates = state.notApprovedCertificates.filter(
                    (certificate) => certificate.id !== payload.id,
                );
            }
            if (payload?.isApproved === null) {
                state.notApprovedCertificates = state.notApprovedCertificates.concat(payload);
                state.approvedCertificates = state.approvedCertificates.filter(
                    (certificate) => certificate.id !== payload.id,
                );
            }
            if (payload?.isApproved === false) {
                state.notApprovedCertificates = state.notApprovedCertificates.filter(
                    (certificate) => certificate.id !== payload.id,
                );
            }
            state.error = null;
        });
        builder.addCase(updateMedicalCertificateAction.rejected, (state, { error }) => {
            state.postCertificateStatus = FetchStatus.ERROR;
            state.error = error;
        });
    },
});

export const medicalCertificateReducer = medicalCertificateSlice.reducer;

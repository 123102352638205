import React, { FC } from 'react';
import { cn } from '@bem-react/classname';
import { AppText } from '@frontend-modules/ui-kit';
import { SleepingOwlPng, TwoPeopleLookingForPng } from '@fsd-shared/assets';

import { IEmptyTrainerCardProps } from './EmptyTrainerCard.types';

import './EmptyTrainerCard.scss';
const CmdEmptyTrainerCard = cn('empty-trainer-card');

export const EmptyTrainerCard: FC<IEmptyTrainerCardProps> = (props) => {
    const { type, height = `calc(100vh - 200px)` } = props;
    const isNoAccess = type === 'noAccess';
    const image = isNoAccess ? TwoPeopleLookingForPng : SleepingOwlPng;
    const text = isNoAccess ? 'У вас нет доступа к данному разделу' : 'Вопросы для тренажёра ещё не добавлены';

    return (
        <div className={CmdEmptyTrainerCard()} style={{ height }}>
            <AppText text={text} textStyle={'SubTitle'} className={CmdEmptyTrainerCard('text')} />
            <img className={CmdEmptyTrainerCard('image')} src={image} alt="empty-iamge" />
        </div>
    );
};

import { INewNotification, INotification } from '@lms-elements/atomic';

export enum NotificationType {
    ASSIGNMENTPROGRESS_OVERDUE = 'ASSIGNMENTPROGRESS_OVERDUE',
    ASSIGNMENTPROGRESS_CHECK = 'ASSIGNMENTPROGRESS_CHECK',
    ASSIGNMENTPROGRESS_ASSIGNMENT = 'ASSIGNMENTPROGRESS_ASSIGNMENT',
    ASSIGNMENTPROGRESS_COMPLETED = 'ASSIGNMENTPROGRESS_COMPLETED',
    ASSIGNMENTPROGRESS_DEADLINE = 'ASSIGNMENTPROGRESS_DEADLINE',
}

export interface NotificationExtraInfo {
    subjectId: number;
    lessonId: number;
    courseGroupId?: number;
    type: NotificationType;
}

export type NewNotificationWithExtraInfo = INewNotification & NotificationExtraInfo;

export type ViewedNotificationWithExtraInfo = INotification & NotificationExtraInfo;

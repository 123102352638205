import React from 'react';
import { TProgress } from 'src-new/components/new-subjects';

import './style.scss';

/**
 * @description Component for displaying lesson/theme/course progress (solid line for 30 and more items, dashed line otherwise)
 * @param {TProgress[]} progress
 * */
export const ProgressBar: React.FC<{ progress: TProgress[] }> = ({ progress }) => {
    const isSolid = progress.length >= 30;

    return (
        <section className={`progress-bar ${isSolid ? 'solid' : 'dashed'}`}>
            {progress.map((value, index) => (
                <article key={index} className={`progress-bar__cell`}>
                    <span className={`progress-bar__cell_deg progress-bar__cell_deg-main ${value.status}`} />
                    {!value.isViewed && <span className={'progress-bar__cell_deg progress-bar__cell_deg-under'} />}
                </article>
            ))}
        </section>
    );
};

import { codifierPageData } from 'constants/cypress/codifier-page';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Dots, LittleArrow, Plus } from '@lms-elements/icons';
import { useOuterClickField } from 'hooks/useOuterClickField';

import { CodifierEventType } from 'components/Codifier/Codifier';
import { DropdownWithActions } from 'components/DropdownWithActions';
import { ThemeTooltip } from 'components/ThemeTooltip';

import './CodifierDropdownSection.scss';

const cnCodifierDropdownSection = cn('codifier_dropdown_section');

type CreateParams = {
    id: number;
    parentId?: number;
    order?: number;
};

interface ICodifierDropdownSectionProps {
    id: number;
    parentId?: number;
    type: CodifierEventType;
    order: number;
    childrenOrder?: number;
    isTheme: boolean;
    themeNext?: number;
    themeLessonNext: number;
    handleCopyClick?: () => void;
    onCreateClick?: (
        params: CreateParams,
        values: Record<string, { title: string; order: string }>,
        parallel: number,
    ) => void;
    onDeleteClick?: (type: CodifierEventType, id: number) => void;
    onReplaceClick?: (type: CodifierEventType, id: number) => void;
    onEditClick?: (
        id: number,
        values: Record<string, { title: string; order: string }>,
        section: number,
        parallel?: number,
    ) => void;
    onSelect?: (type: CodifierEventType, id: number) => void;
    title: string;
    isEditable?: boolean;
    isOpenedInit?: boolean;
    isHighlighted?: boolean;
    disabled?: boolean;
    canHover?: boolean;
    parallel?: number;
    handleCanHover?: () => void;
    index: number;
    canDelete?: boolean;
    canReplace?: boolean;
    needHideBlue?: boolean;
}

export const CodifierDropdownSection: React.FC<ICodifierDropdownSectionProps> = ({
    id,
    type,
    order,
    isTheme,
    title,
    themeNext = 1,
    themeLessonNext,
    handleCopyClick,
    onDeleteClick,
    onReplaceClick,
    onSelect,
    onCreateClick,
    onEditClick,
    isEditable = true,
    isOpenedInit = false,
    isHighlighted = false,
    children,
    parentId,
    disabled = false,
    canHover = true,
    handleCanHover,
    index,
    canDelete = true,
    canReplace = true,
    needHideBlue = false,
    parallel,
}) => {
    const [isButtonsVisible, setIsButtonsVisible] = useState(false);
    const [isOpened, setIsOpened] = useState(isOpenedInit);
    const [isCreated, setIsCreated] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [isEditedForm, setIsEditedForm] = useState(false);
    const [isAdded, setIsAdded] = useState(false);

    const tooltipRef = useRef(null);
    const hideTooltip = useCallback(() => {
        setIsButtonsVisible(false);
        setIsEdited(false);
        if (handleCanHover) {
            handleCanHover();
        }
    }, [handleCanHover]);
    useOuterClickField(tooltipRef, hideTooltip);
    const correctThemeTitle = useMemo(() => (isTheme ? `Блок ${order}` : `Раздел ${order}`), [isTheme, order]);

    const handleHoverButtons = useCallback(
        (value) => {
            if (canHover) {
                setIsButtonsVisible(value);
            }
        },
        [canHover],
    );

    const handleHeaderClick = useCallback(() => {
        if (!disabled) {
            if (!needHideBlue) setIsOpened(!isOpened);
            if (onSelect) {
                onSelect(type, id);
            }
        }
    }, [disabled, id, isOpened, needHideBlue, onSelect, type]);

    const handleDeleteClick = useCallback(() => {
        onDeleteClick?.(type, id);
    }, [id, onDeleteClick, type]);

    const handleReplaceClick = useCallback(() => {
        onReplaceClick?.(type, id);
    }, [id, onReplaceClick, type]);

    const handleCreateButtonClick = useCallback(() => {
        if (!isEdited && !isEditedForm) {
            setIsCreated(!isCreated);
            if (handleCanHover) {
                handleCanHover();
            }
            setIsButtonsVisible(false);
        }
    }, [isCreated, handleCanHover, isEditedForm, isEdited]);

    const handleEditButtonClick = useCallback(() => {
        if (!isEditedForm && !isCreated) {
            setIsEdited(!isEdited);
            if (handleCanHover) {
                handleCanHover();
            }
        }
    }, [isEdited, handleCanHover, isEditedForm, isCreated]);

    const handleEditFormClick = useCallback(() => {
        setIsEditedForm((prev) => !prev);
        if (handleCanHover && !canHover && !isEdited) {
            handleCanHover();
        }
        setIsEdited(false);
        setIsButtonsVisible(false);
    }, [canHover, handleCanHover, isEdited]);

    const handleAddFormClick = useCallback(
        (values: Record<string, { title: string; order: string }>) => {
            if (onCreateClick) {
                onCreateClick({ id: id, parentId: parentId }, values, Number(parentId));
            }
            setIsAdded(true);
        },
        [onCreateClick, id, parentId],
    );

    const handleEditFormSubmit = useCallback(
        (values: Record<string, { title: string; order: string }>) => {
            if (onEditClick) {
                onEditClick(id, values, Number(parentId), Number(parallel));
            }
        },
        [onEditClick, id, parentId, parallel],
    );

    useEffect(() => {
        if (!(isEdited || isCreated || isEditedForm)) {
            if (disabled && isOpened && !isAdded) {
                setIsOpened(false);
            }
            if (!disabled && isAdded) {
                setIsOpened(true);
                setIsAdded(false);
            }
        }
    }, [disabled, isOpened, isEdited, isCreated, isEditedForm, isAdded]);

    return (
        <>
            <div
                className={cnCodifierDropdownSection('container', {
                    highlighted: isHighlighted || isEdited || isCreated || isEditedForm,
                })}
                onMouseEnter={handleHoverButtons.bind(null, true)}
                onMouseLeave={handleHoverButtons.bind(null, false)}
            >
                <div
                    className={cnCodifierDropdownSection('header', { disabled })}
                    onClick={handleHeaderClick}
                    data-cy={
                        type === CodifierEventType.SECTION
                            ? codifierPageData.getSectionContainerHeader(index)
                            : codifierPageData.getBlockContainerHeader(index)
                    }
                >
                    <LittleArrow
                        className={cnCodifierDropdownSection('arrow', {
                            arrow_is_opened: isOpened || isEdited || isCreated || isEditedForm,
                            disabled,
                        })}
                    />
                    <div
                        className={cnCodifierDropdownSection('text', {
                            highlighted: isHighlighted || isEdited || isCreated || isEditedForm,
                        })}
                    >{`${correctThemeTitle}: ${title}`}</div>
                </div>
                <div className={cnCodifierDropdownSection('buttons')}>
                    {(isButtonsVisible || isEdited) && isEditable && (
                        <>
                            <div className={cnCodifierDropdownSection('button')} onClick={handleCreateButtonClick}>
                                <Plus />
                            </div>
                            <div className={cnCodifierDropdownSection('button')} onClick={handleEditButtonClick}>
                                <Dots />
                            </div>
                        </>
                    )}
                    {isEdited && (
                        <div ref={tooltipRef}>
                            <ThemeTooltip
                                isTheme={false}
                                handleCopyClick={handleCopyClick}
                                handleDeleteClick={handleDeleteClick}
                                handleEditClick={handleEditFormClick}
                                handleReplaceClick={handleReplaceClick}
                                canDelete={canDelete}
                                canReplace={canReplace}
                            />
                        </div>
                    )}
                </div>
            </div>
            {(isOpened || isCreated || isEditedForm) && (
                <div
                    className={cnCodifierDropdownSection('children_container', {
                        opened: (isCreated && isTheme) || (isOpened && (themeNext > 1 || themeLessonNext > 1)),
                        section: !isTheme,
                    })}
                >
                    {isCreated && (
                        <DropdownWithActions
                            isNeedTextField={true}
                            name={isTheme ? 'createTheme' : 'createBlock'}
                            initialValue={
                                isTheme
                                    ? { createTheme: { title: ``, order: String(themeLessonNext) } }
                                    : { createBlock: { title: ``, order: String(themeNext) } }
                            }
                            placeholder={`Название`}
                            onChangeAdd={handleAddFormClick}
                            onClickCancel={handleCreateButtonClick}
                            textButtonAdd={'Создать'}
                            noSkills
                            isSection={type === CodifierEventType.SECTION}
                            title={isTheme ? 'Тема' : 'Блок'}
                        />
                    )}
                    {isEditedForm && (
                        <DropdownWithActions
                            name={`edit${isTheme ? 'Block' : 'Section'}`}
                            isNeedTextField
                            placeholder={`${order}. ${isTheme ? 'Тема' : 'Раздел'}: введите название`}
                            initialValue={
                                isTheme
                                    ? { editBlock: { title: `${title}`, order: String(order) } }
                                    : { editSection: { title: `${title}`, order: String(order) } }
                            }
                            onChangeAdd={handleEditFormSubmit}
                            onClickCancel={handleEditFormClick}
                            textButtonAdd={'Изменить'}
                            noSkills
                            title={isTheme ? 'Блок' : 'Раздел'}
                        />
                    )}
                    {isOpened && children}
                </div>
            )}
        </>
    );
};

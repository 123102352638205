import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';

import { MeetingFileData } from '.';

export interface CreateMeetingFileParam {
    lessonSchedule?: number;
    lesson?: number;
    isAvailableToStudents: boolean;
    file: File;
}

export const createMeetingFile = ({
    lessonSchedule,
    lesson,
    isAvailableToStudents,
    file,
}: CreateMeetingFileParam): Promise<MeetingFileData> => {
    const data = new FormData();

    data.append('file', file);
    data.append('isAvailableToStudents', String(isAvailableToStudents));

    if (lessonSchedule) {
        data.append('lessonSchedule', String(lessonSchedule));
    }

    if (lesson) {
        data.append('lesson', String(lesson));
    }

    return api
        .post<Promise<MeetingFileData>, AxiosResponse<MeetingFileData>>('meeting/files/create/', data, {
            headers: {
                'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
                Accept: 'application/json',
            },
        })
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

import { getApiRequest } from 'api';
import { IAssignmentItem } from 'types/assignment';

type RequestParams = {
    lesson: number;
    course_group?: number;
};

export const getAssignmentsByLesson = (params: RequestParams): Promise<IAssignmentItem[]> => {
    return getApiRequest(`/assignment/`, { params });
};

import { getQuestionsByQuery } from 'api/services/lessonTask';
import { Status } from 'pages/QuestionBankPage/types';
import { TAssignedQuestions, TResponseQuestion, TSearchBlockGet } from 'types/lessonTask';

import { createUrlQuery } from 'utils/createUrlQuery';

export const getGroupedTasks = async (
    taskData: TSearchBlockGet[],
    allSingleQuestionsList: TResponseQuestion[],
): Promise<TAssignedQuestions> => {
    const assignedQuestionsList: TAssignedQuestions = {};

    const questionNumbersList = Array.from(new Set(taskData.map((item) => item.questionNumber)));

    for (const num of questionNumbersList) {
        // все вопросы, относящиеся к данному номеру в задании
        const currentNumItems = taskData.filter(({ questionNumber }) => questionNumber === num);

        const singleQuestionsList = currentNumItems.map((item) => {
            const { filters, single } = item;
            if (single) {
                return {
                    ...item,
                    questionContent: allSingleQuestionsList.find(({ id }) => id === filters.id),
                };
            }
        });
        const searchBlocksList = await Promise.all(
            currentNumItems.map(async (item) => {
                const { filters, single } = item;
                const { topics, text, difficulty, answersType, skillsAnd, skillsOr, excludeIds } = filters;

                if (!single) {
                    const filtersQuery = createUrlQuery({
                        topics: topics,
                        text: text,
                        difficulty: difficulty,
                        answers_type: answersType,
                        skills_and: skillsAnd,
                        skills_or: skillsOr,
                        // exclude_ids: excludeIds,
                        status: Status.Published,
                        all_user_questions: 'true',
                    });
                    const content = await getQuestionsByQuery(filtersQuery);

                    return {
                        ...item,
                        questionsContentList: content
                            .filter((item) => item)
                            .map((content) => {
                                return {
                                    ...item,
                                    questionContent: content,
                                };
                            }),
                    };
                }
            }),
        );

        assignedQuestionsList[num] = {
            // @ts-ignore
            singleQuestionsList: singleQuestionsList.filter((item) => !!item),
            // @ts-ignore
            searchBlocksList: searchBlocksList.filter((item) => !!item),
        };
    }
    return assignedQuestionsList;
};

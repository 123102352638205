import { TAssignedQuestions, TSearchBlock, TSearchResults, TSingleQuestion } from 'types/lessonTask';

export const replaceAssignmentValue = (
    obj: TAssignedQuestions | TSearchResults | undefined,
    newAssignment: number,
    type: 'assignedQuestions' | 'searchResults',
): TAssignedQuestions | TSearchResults | undefined => {
    if (obj) {
        if (type === 'assignedQuestions') {
            const newObj = {} as TAssignedQuestions;
            Object.entries(obj).forEach(([key, value]) => {
                newObj[+key] = {
                    singleQuestionsList: value?.singleQuestionsList?.map((item: TSingleQuestion) => ({
                        ...item,
                        assignment: newAssignment,
                    })),
                    searchBlocksList: value?.searchBlocksList?.map((item: TSearchBlock) => ({
                        ...item,
                        assignment: newAssignment,
                    })),
                };
            });
            return newObj;
        } else if (type === 'searchResults') {
            const newObj = {} as TSearchResults;
            Object.entries(obj).forEach(([key, value]) => {
                newObj[+key] = value?.map((item: TSearchBlock) => ({ ...item, assignment: newAssignment }));
            });
            return newObj;
        }
    }

    return obj;
};

import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';

export interface ResponseTheme {
    id: number;
    order: number;
    title: string;
    description: string;
    isPublished?: boolean;
}

/**
 * Return course themes
 * @param courseId
 * @returns
 */

export const getThemes = (courseId: number): Promise<ResponseTheme[]> => {
    return api
        .get<never, AxiosResponse<ResponseTheme[]>>(`/topic/?course=${courseId}`)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const publishTheme = (topicId: number): Promise<number> =>
    api
        .patch<void>(`/topic/publish/${topicId}/`)
        .then(() => topicId)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });

export interface PostThemeParams {
    course: number;
    title: string;
    description: string;
}

export interface PostResponseTheme {
    id: number;
    title: string;
    description: string;
    course: string;
    isPublished: boolean;
}

/**
 * Creates new theme
 * @param themes theme data
 */

export const postTheme = (themes: PostThemeParams): Promise<PostResponseTheme> => {
    return api
        .post<Promise<PostResponseTheme>, AxiosResponse<PostResponseTheme>>(`/topic/create/`, {
            course: themes.course,
            title: themes.title,
            description: themes.description,
        })
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export interface PatchedTheme {
    id: number;
    title: string;
    description: string;
}

export interface PatchedThemeResponse {
    id: number;
    title: string;
    description: string;
    isPublished: boolean;
}

/**
 *
 * @param data: {
 * id - theme id,
 * themeData - theme data
 * }
 * @returns theme
 */

export const patchTheme = ({ id, ...themeData }: PatchedTheme): Promise<PatchedThemeResponse> => {
    return api
        .patch<Promise<PatchedThemeResponse>, AxiosResponse<PatchedThemeResponse>>(
            `/topic/partial-update/${id}/`,
            themeData,
        )
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

/**
 *
 * @param id theme id
 */

export const deleteTheme = (id: number): Promise<number> => {
    return api
        .delete<Promise<never>, AxiosResponse<never>>(`/topic/delete/${id}/`)
        .then(() => id)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

import { getApiRequest } from 'api';
import { NotificationType } from 'types/notifications';

export interface UnreadNotificationsCount {
    count: number;
}
export interface UnreadMessagesCount {
    unreadMessagesCount: number;
}

export interface NotificationTaskData {
    subject: {
        id: number;
        title: string;
    };
    lesson: number;
    courseGroup: {
        id: number;
        title: string;
    } | null;
    count?: number;
}

export interface Notification {
    id: number;
    timeCreate: string;
    isRead: boolean;
    type: NotificationType;
    data: NotificationTaskData;
}

export interface NotificationsArchivePage {
    count: number;
    next: string | null;
    previous: string | null;
    data: Notification[];
}

export const getUnreadNotificationsCount = (): Promise<UnreadNotificationsCount> =>
    getApiRequest('notifications/unread/');
export const getUnreadMessagesCount = (): Promise<UnreadMessagesCount> =>
    getApiRequest('rocket-chat/unread-messages-count/');

export const getNotificationsPage = (): Promise<Notification[]> => getApiRequest('notifications/');

export const getFirstArchiveNotificationsPage = (): Promise<NotificationsArchivePage> =>
    getApiRequest(`notifications/archieve/`);

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { AppText } from '@frontend-modules/ui-kit';
import { ReturnButton } from '@lms-elements/atomic';
import { insertationToTemplate } from '@lms-elements/custom-editor';
import { IQuestionForTeacher } from '@lms-elements/test-task';
import { questionType } from '@lms-elements/test-task/build/@types/packages/TestTask/src/Question/Minimized/MinimizedQuestion.types';
import { QuestionStatus } from 'api/services/questionBank';
import { StrokeArrowRightIcon } from 'assets';
import { PageLoader } from 'containers/PageLoader';
import { QuestionPool } from 'containers/QuestionPool';
import { EditorState } from 'draft-js';
import arrayMutators from 'final-form-arrays';
import { useLoader } from 'hooks';
import { debounce } from 'lodash-es';
import { Status } from 'pages/QuestionBankPage/types';
import { IFormValuesCreateQuestions, ISelectedValues } from 'pages/QuestionCreationPage/QuestionCreationPage.types';
import { Questions } from 'pages/QuestionCreationPage/Questions';
import { useSubmit } from 'pages/QuestionCreationPage/useSubmit';
import {
    getInitFormValue,
    getInitFormValues,
    getQuestionContent,
    getQuestionText,
    validateQuestion,
} from 'pages/QuestionCreationPage/utils';
import { HardModal } from 'src-new/components/hard-modal';
import { useCreateQuestionPopup, useGetPopupLessonData, useSubjectsRedirect } from 'src-new/hooks';
import {
    fetchCodifierSkillsDataAction,
    getCodifierDataByTopicsAction,
    getCodifierSubjectDataAction,
    getCodifierSubjectsAction,
} from 'store/actions/codifier';
import { getLessonDataAction } from 'store/actions/courseLessons';
import { addCreatedQuestionAction } from 'store/actions/lessonTask';
import { addNewQuestionForExerciseMaterialAction } from 'store/actions/material';
import {
    deleteQuestionAction,
    getHistoryQuestionAction,
    getQuestionDetailedAction,
    refreshStatusesQuestions,
} from 'store/actions/questionBank';
import { saveCreateQuestionsStore } from 'store/actions/questionCreate';
import { resetFetchStatuses } from 'store/reducers/codifier';
import { resetPostQuestion, resetQuestionBankState, resetQuestionCreated } from 'store/reducers/questionBank';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';
import { ICreateQuestionPopup, TResponseQuestion } from 'types/lessonTask';
import { UserRoles } from 'types/user';

import { PropertyMenu } from 'components/PropertyMenu';
import { getQuestionTypeForBackend } from 'utils/questions';
import { getPathParts } from 'utils/url';

import './style.scss';
const cnQuestionCreationPage = cn('question_creation_page');
const ARRAY_MUTATORS = { ...arrayMutators };
const SUBSCRIPTIONS_STAB = {};

const CreateQuestionPopup: React.FC<ICreateQuestionPopup> = (props) => {
    const { urlProps, ...rest } = props;
    const { lessonId, courseGroupId, taskId, num, purpose, isTrainer, assignment } = urlProps;
    //TODO: isFromMaterials и isSearchPage - заглушки на время, пока не будет готов функционал по созданию резервных уроков
    const isFromMaterials = false;
    const isSearchPage = true;
    const isFillTrainer = isTrainer;
    const isEditPage = purpose === 'edit';

    const history = useHistory();
    const CreateQuestionPopup = useCreateQuestionPopup();
    const { courseInfo, topicInfo, lessonInfo } = useGetPopupLessonData({
        lessonId,
        courseGroupId,
        taskId,
        isNeedFetch: CreateQuestionPopup.isCurrent,
    });
    useSubjectsRedirect({
        modalType: 'CREATE_QUESTION',
        urlProps: urlProps,
    });

    const handleClosePopup = () => {
        CreateQuestionPopup.closeLastCreateQuestionPopup();
    };

    const breadcrumbsHeader = useMemo(() => {
        return courseInfo?.title && topicInfo?.title ? (
            <div className={'create-question-popup__title'}>
                <div className={'create-question-popup__title_return-btn'} onClick={() => handleClosePopup()}>
                    <ReturnButton />
                </div>
                <div className={'create-question-popup__title_breadcrumbs'}>
                    <AppText text={courseInfo.title ?? ''} textStyle={'DesktopH4'} color={'#919399'} />
                    <StrokeArrowRightIcon width={4} height={8} />
                    <AppText text={topicInfo.title ?? ''} textStyle={'DesktopH4'} color={'#919399'} />
                    <StrokeArrowRightIcon width={4} height={8} />
                    <AppText text={lessonInfo?.title ?? ''} textStyle={'DesktopH4'} color={'#919399'} />
                    <StrokeArrowRightIcon width={4} height={8} />
                    <AppText text={'Создание вопроса'} textStyle={'DesktopH4'} />
                </div>
            </div>
        ) : (
            <></>
        );
    }, [courseInfo, topicInfo]);

    // Ниже логика, перенесенная со старой страницы создания вопроса
    // (без рефакторинга, только адаптированная под модалку и немного причесанная)
    const dispatch = useDispatch();
    const location = useLocation();

    const handleFormValuesChange = useRef(
        debounce((state: { values: IFormValuesCreateQuestions }) => {
            setFormValuesState(state.values);
        }, 500),
    );
    const isInitValueEdit = useRef(false);

    const {
        materialIdToEdit,
        info,
        fetchStatus,
        codifierData,
        fetchStatusSkills,
        codifierSkillsData,
        getSubjectsStatus,
        postStatusQuestion,
        questionCreateId,
        fetchStatusDetailedQuestion,
        detailedQuestion,
        fileLink,
        questionCreated,
        createdQuestions,
    } = useAppSelector((store) => ({
        materialIdToEdit: store.questions.materialIdToEdit,
        postStatusQuestion: store.questions.postStatusQuestion,
        questionCreateId: store.questions.questionCreateId,
        fetchStatusDetailedQuestion: store.questions.fetchStatusDetailedQuestion,
        detailedQuestion: store.questions.detailedQuestion,
        fileLink: store.questions.fileLink,
        questionCreated: store.questions.questionCreated,
        info: store.user.info,
        fetchStatus: store.codifier.fetchStatus,
        codifierData: store.codifier.codifierData,
        fetchStatusSkills: store.codifier.fetchStatusSkills,
        codifierSkillsData: store.codifier.codifierSkillsData,
        getSubjectsStatus: store.codifier.getSubjectsStatus,
        createdQuestions: store.searchBlock.createdQuestions,
    }));

    const isMethodistRole = info?.userRoles.includes(UserRoles.METHODIST);
    const isPrincipalRole = info?.userRoles.includes(UserRoles.PRINCIPAL);
    const isSupport = info?.userRoles.includes(UserRoles.SUPPORT);
    // TODO: изменить, когда будут известны все отличия между support и завучем. Сейчас support видит все тоже самое что и завуч
    const isPrincipal = isPrincipalRole || isSupport;
    // TODO: изменить, когда будут известны все отличия между методистом и завучем. Сейчас завуч видит все тоже самое что и методист
    const isMethodist = isMethodistRole || isPrincipal;
    const isCreatingForMaterials = isFromMaterials || !!isFillTrainer;
    const needFetchSkills = fetchStatusSkills === FetchStatus.INITIAL;
    const showForm =
        (isFromMaterials && getSubjectsStatus === FetchStatus.FETCHED && fetchStatusSkills === FetchStatus.FETCHED) ||
        (!isEditPage && getSubjectsStatus === FetchStatus.FETCHED && fetchStatusSkills === FetchStatus.FETCHED) ||
        (isEditPage && fetchStatus === FetchStatus.FETCHED && fetchStatusDetailedQuestion === FetchStatus.FETCHED);

    const initFormValue = useMemo(
        () =>
            getInitFormValue(
                isCreatingForMaterials,
                new URLSearchParams(history.location.search).get('status') === Status.Published && isMethodist,
            ),
        [history.location.search, isCreatingForMaterials, isMethodist],
    );

    const [isQuestionCreated, setIsQuestionCreated] = useState(false);
    const [canDrop, setCanDrop] = useState(!isEditPage);
    const [stateGettingInitValue, setStateGettingInitValue] = useState(
        isEditPage ? FetchStatus.FETCHING : FetchStatus.FETCHED,
    );
    const [topicsLocation, setTopicsLocation] = useState('');
    const [selectedTopics, setSelectedTopics] = useState<number[]>(
        new URLSearchParams(location.search).get('topics')?.split(',').map(Number) ?? [],
    );
    const [formValuesState, setFormValuesState] = useState<IFormValuesCreateQuestions>(initFormValue);
    const [flagRefresh, setFlagRefresh] = useState(false);
    const [flagDelete, setFlagDelete] = useState(false);
    const [questionType, setQuestionType] = useState('');
    const [selectedSkills, setSelectedSkills] = useState<ISelectedValues>({
        ids: [],
        location: '',
    });
    const [initValueEdit, setInitValueEdit] = useState(initFormValue);
    const [changedSubject, setChangedSubject] = useState<number>();

    const editOrCreate = useMemo(() => getPathParts(history.location.pathname)[3], [history]);
    const isTextEditor = useMemo(() => {
        if (isEditPage && stateGettingInitValue === FetchStatus.FETCHED) {
            return detailedQuestion.content !== '{}';
        }
        if (!isEditPage) {
            return true;
        }
        return null;
    }, [detailedQuestion.content, isEditPage, stateGettingInitValue]);
    const canEdit = useMemo(
        () =>
            (isEditPage && isMethodist) ||
            ((detailedQuestion?.author?.id === undefined || info?.id === detailedQuestion?.author?.id) &&
                detailedQuestion.status !== QuestionStatus.Published),
        [detailedQuestion?.author?.id, info?.id, isEditPage, isMethodist, detailedQuestion?.status],
    );
    const isDisabledButton = useMemo(() => {
        if (!formValuesState.topicsLocation) {
            return true;
        }

        if (
            canDrop ||
            (isEditPage && JSON.stringify(formValuesState) === JSON.stringify(initValueEdit)) ||
            postStatusQuestion !== FetchStatus.INITIAL ||
            isQuestionCreated
        ) {
            return true;
        }

        if (formValuesState?.questions[0]) {
            const error = validateQuestion(formValuesState.questions[0]);

            return Boolean(error);
        }
    }, [canDrop, formValuesState, initValueEdit, isEditPage, isQuestionCreated, postStatusQuestion]);

    useLoader([fetchStatusDetailedQuestion, stateGettingInitValue, fetchStatus, fetchStatusSkills]);
    const { handleSubmitForm, getMarkup, render } = useSubmit({
        isEditPage,
        questionType: questionType as questionType,
        idPath: Number(lessonId),
        selectedTopics,
        selectedSkills,
        formValuesState,
        materialIdToEdit,
        fileIds: fileLink.map((val) => val.id),
    });

    const handleSelectLesson = useCallback((values: ISelectedValues) => {
        setSelectedTopics(values.ids);
        setTopicsLocation(values.location);
    }, []);

    const handleSelectSkills = useCallback((values: ISelectedValues) => {
        setSelectedSkills({ ids: values.ids.map((value) => value), location: values.location });
    }, []);

    const handleHistoryClick = useCallback(() => {
        dispatch(getHistoryQuestionAction(Number(lessonId)));
    }, [dispatch, lessonId]);

    const handleDeleteClick = useCallback(() => {
        if (lessonId) {
            dispatch(deleteQuestionAction(Number(lessonId)));
            CreateQuestionPopup.closeLastCreateQuestionPopup();
        }
    }, [lessonId, dispatch, history]);

    const handleCancelClick = useCallback(() => {
        CreateQuestionPopup.closeLastCreateQuestionPopup();
        dispatch(resetQuestionCreated());
    }, [history]);

    const handleQuestionDelete = useCallback(
        (fieldsProps: FieldArrayRenderProps<IQuestionForTeacher, HTMLInputElement>) => {
            setQuestionType('');
            if (fieldsProps.fields.length === 1) {
                setCanDrop(true);
            }
            setFlagDelete(true);
            setFormValuesState((prev) => ({
                ...prev,
                questions: [],
            }));
        },
        [],
    );
    const handleSelectSubject = useCallback(
        (subjectId: number) => {
            dispatch(getCodifierSubjectDataAction(subjectId));
            setChangedSubject(subjectId);
        },
        [dispatch],
    );

    useEffect(() => {
        dispatch(resetQuestionBankState());
    }, [dispatch]);
    useEffect(() => {
        if (getSubjectsStatus === FetchStatus.INITIAL) {
            dispatch(getCodifierSubjectsAction());
        }
    }, [dispatch, getSubjectsStatus]);
    useEffect(() => {
        if (isSearchPage) {
            dispatch(getLessonDataAction(Number(lessonId)));
        }

        if (isEditPage) {
            dispatch(getQuestionDetailedAction(Number(lessonId)));
        }
    }, [dispatch, lessonId, isEditPage, isSearchPage]);

    useEffect(() => {
        if (isEditPage && fetchStatusDetailedQuestion === FetchStatus.FETCHED && detailedQuestion?.topics) {
            dispatch(getCodifierDataByTopicsAction(detailedQuestion.topics.map((topic) => topic.id)));
        }

        if (Number(lessonId)) {
            dispatch(getCodifierDataByTopicsAction([Number(lessonId)]));
        }
    }, [detailedQuestion.topics, dispatch, fetchStatusDetailedQuestion, lessonId, isEditPage]);
    useEffect(() => {
        dispatch(refreshStatusesQuestions());
        setFlagRefresh(true);
    }, [dispatch]);
    useEffect(() => {
        if (
            isEditPage &&
            fetchStatusDetailedQuestion === FetchStatus.FETCHED &&
            fetchStatusSkills === FetchStatus.FETCHED &&
            fetchStatus === FetchStatus.FETCHED &&
            flagRefresh
        ) {
            setStateGettingInitValue(FetchStatus.FETCHED);
        }
    }, [fetchStatus, fetchStatusDetailedQuestion, fetchStatusSkills, flagRefresh, isEditPage]);

    useEffect(() => {
        if (isEditPage && stateGettingInitValue === FetchStatus.FETCHED && !isInitValueEdit.current) {
            const formValue = getInitFormValues(detailedQuestion, codifierData, codifierSkillsData, isFromMaterials);

            setSelectedTopics(detailedQuestion?.topics?.map((l) => l.id) || []);
            setTopicsLocation(formValue.topicsLocation);
            setSelectedSkills({
                ids: detailedQuestion.skills ? detailedQuestion.skills.map((s) => s.id) : [],
                location: formValue.skillsLocation,
            });
            setQuestionType(formValue.questions.length ? formValue.questions[0].type : 'binary');
            setInitValueEdit(formValue);
            isInitValueEdit.current = true;
            return;
        }
    }, [
        codifierData,
        codifierSkillsData,
        detailedQuestion,
        detailedQuestion.skills,
        detailedQuestion?.topics,
        fetchStatus,
        lessonId,
        initValueEdit,
        isEditPage,
        stateGettingInitValue,
    ]);

    useEffect(() => {
        const searchParams = new URLSearchParams(history.location.search);
        const subjectId = searchParams.get('subjects');
        if (!isEditPage && subjectId && !isNaN(Number(subjectId))) {
            dispatch(getCodifierSubjectDataAction(Number(subjectId)));
        }

        if (needFetchSkills) {
            dispatch(fetchCodifierSkillsDataAction());
        }
    }, [dispatch, needFetchSkills, history.location.search, isEditPage]);
    useEffect(() => {
        if (postStatusQuestion === FetchStatus.FETCHED) {
            setIsQuestionCreated(true);
        }
    }, [postStatusQuestion]);
    useEffect(() => {
        if (questionCreateId !== 0 && isQuestionCreated) {
            const markups = getMarkup();
            const question = formValuesState.questions[0]?.question || '';
            const newQuestion = {
                id: questionCreateId,
                text: getQuestionText(question),
                content: getQuestionContent(question),
                html:
                    questionType === 'insert-words' && question instanceof EditorState
                        ? insertationToTemplate(question)
                        : markups[0]?.questionHTML,
            };
            if (
                isSearchPage &&
                !createdQuestions.find((value) => value.questions.find((question) => question.id === questionCreateId))
            ) {
                dispatch(
                    saveCreateQuestionsStore(
                        {
                            questionNum: Number(num),
                            questions: [newQuestion],
                        },
                        'create',
                    ),
                );
            }

            // Обновить кодификатор после создания вопросов
            dispatch(resetFetchStatuses());

            if (isFromMaterials) {
                dispatch(addNewQuestionForExerciseMaterialAction(questionCreateId));
            }
        }

        return () => {
            if (postStatusQuestion === FetchStatus.FETCHED) {
                dispatch(resetPostQuestion());
            }
        };
    }, [
        createdQuestions,
        dispatch,
        formValuesState.questions,
        getMarkup,
        history,
        isFillTrainer,
        isFromMaterials,
        isQuestionCreated,
        isSearchPage,
        postStatusQuestion,
        questionCreateId,
        num,
        questionType,
    ]);
    useEffect(() => {
        if (questionCreated) {
            const question: TResponseQuestion = {
                ...questionCreated,
                topics: selectedTopics.map((id) => {
                    return { id };
                }),
                skills: selectedSkills.ids.map((id) => {
                    return {
                        id,
                        title: '',
                    };
                }),
                answersType: getQuestionTypeForBackend(questionType as questionType),
                author: {
                    id: info?.id,
                    firstName: info?.firstName ?? '',
                    lastName: info?.lastName ?? '',
                    patronymic: info?.patronymic ?? '',
                    mainRole: 'teacher',
                },
            };
            dispatch(
                addCreatedQuestionAction({
                    question,
                    questionNum: +num,
                    assignment: assignment ? +assignment : undefined,
                }),
            );

            dispatch(resetQuestionCreated());
            CreateQuestionPopup.closeLastCreateQuestionPopup();
        }
    }, [questionCreated]);
    useEffect(() => {
        if (isQuestionCreated && !editOrCreate) {
            dispatch(resetPostQuestion());
        }
    }, [dispatch, editOrCreate, history, isQuestionCreated]);

    return (
        <HardModal
            key={'create-question'}
            className={'create-question-popup'}
            size={'large'}
            placement={'left'}
            getContainer={false}
            title={breadcrumbsHeader}
            customWrapperStyle={{
                width: '100%',
            }}
            autoFocus={false}
            {...rest}
        >
            <div className={'create-question-popup__container'}>
                <div className={cnQuestionCreationPage()}>
                    <div className={cnQuestionCreationPage('questions')}>
                        <QuestionPool
                            isDisabled={!canDrop}
                            setCanDrop={setCanDrop}
                            setIsQuestionType={setQuestionType}
                            autoCheckOnly={isFromMaterials || isFillTrainer}
                            revisions={detailedQuestion?.revisions ?? []}
                        />
                    </div>
                    <div className={cnQuestionCreationPage('content')}>
                        <div className={cnQuestionCreationPage('main_container')}>
                            {!showForm && <PageLoader showLoading={true} />}
                            {showForm && (
                                <Form<IFormValuesCreateQuestions>
                                    onSubmit={handleSubmitForm}
                                    mutators={ARRAY_MUTATORS}
                                    initialValues={isEditPage ? initValueEdit : initFormValue}
                                    subscription={SUBSCRIPTIONS_STAB}
                                    validateOnBlur
                                >
                                    {({ handleSubmit }) => (
                                        <form onSubmit={handleSubmit}>
                                            <FormSpy
                                                subscription={{ values: true, errors: true }}
                                                onChange={handleFormValuesChange.current}
                                            />
                                            <PropertyMenu
                                                isMethodist={isMethodist}
                                                onClickCancel={handleCancelClick}
                                                selectedTopics={selectedTopics}
                                                topicsLocation={topicsLocation}
                                                selectedSkills={selectedSkills}
                                                isForMaterials={isCreatingForMaterials}
                                                handleSelectSkills={handleSelectSkills}
                                                handleSelectLesson={handleSelectLesson}
                                                handleClickDelete={handleDeleteClick}
                                                isEditPage={isEditPage}
                                                isDisabledButton={isDisabledButton}
                                                toggleChecked={formValuesState.toggle}
                                                canEdit={canEdit}
                                                isDisabledCancelButton={
                                                    isQuestionCreated || postStatusQuestion !== FetchStatus.INITIAL
                                                }
                                                onSelectSubject={handleSelectSubject}
                                                isSearchPage={isSearchPage}
                                                initSelectedSubject={isEditPage ? changedSubject : undefined}
                                                onHistoryClick={handleHistoryClick}
                                            />
                                            <h2>Тип</h2>
                                            <Questions
                                                canDrop={canDrop}
                                                setCanDrop={setCanDrop}
                                                questionType={questionType as questionType}
                                                onDelete={handleQuestionDelete}
                                                isTextEditor={Boolean(isTextEditor)}
                                                flagDelete={flagDelete}
                                            />
                                        </form>
                                    )}
                                </Form>
                            )}
                        </div>
                    </div>
                </div>
                {render()}
            </div>
        </HardModal>
    );
};

export default CreateQuestionPopup;

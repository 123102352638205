import React, { useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Select } from 'antd';

import { TAppPaginateSelect } from './AppPaginateSelect.types';

import './style.scss';

const CnAppPaginateSelect = cn('app-paginate-select');

export const AppPaginateSelect: React.FC<TAppPaginateSelect> = (props: TAppPaginateSelect) => {
    const [searchWord, setSearchWord] = useState<string>('');

    const {
        customClassName,
        options,
        placeholder,
        mode,
        maxPage,
        currPage,
        isFetching,
        handleSelect,
        handleSearch,
        handleScroll,
        handleVisibleChange,
        ...rest
    } = { ...props };

    useEffect(() => handleSearch(searchWord), [searchWord]);

    const onScroll = (event: React.UIEventHandler<HTMLDivElement>) => {
        const isLastPage = maxPage === 1 || currPage === maxPage;
        //@ts-ignore
        const target = event.target as HTMLDivElement;
        if (!isLastPage && target.scrollTop + target.offsetHeight === target.scrollHeight) {
            handleScroll();
        }
    };

    return (
        <Select
            className={`${customClassName || ''} lms-paginate-select`}
            mode={mode}
            placeholder={placeholder}
            onChange={handleSelect}
            options={options}
            labelInValue
            showSearch
            //@ts-ignore
            filterOption={() => options}
            loading={isFetching}
            notFoundContent={'Ничего не найдено'}
            onSearch={setSearchWord}
            onSelect={() => setSearchWord('')}
            //@ts-ignore
            onPopupScroll={(event) => onScroll(event)}
            onDropdownVisibleChange={handleVisibleChange}
            prefixCls={CnAppPaginateSelect()}
            {...rest}
        />
    );
};

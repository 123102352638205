import { nonPaymentError, wrongClientError } from 'constants/errors';

import { isMobile } from 'react-device-detect';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMissedLesson } from 'api/services/lesson/getMissedLesson';
import { MissedLesson, patchMissedLesson, PatchMissedLessonParams } from 'api/services/lesson/patchMissedLesson';
import { getLessonSchedule, getMeetingJoinRedirect } from 'api/services/lessonSchedule';
import moment from 'moment';
import { error } from 'store/reducers/error';

type GetLessonScheduleParams = {
    lessonIds: number[];
    courseGroupId?: number;
};

export const getLessonScheduleAction = createAsyncThunk(
    'schedule/getLessonSchedule',
    ({ lessonIds, courseGroupId }: GetLessonScheduleParams) => getLessonSchedule(lessonIds, courseGroupId),
);

export const patchMissedLessonAction = createAsyncThunk('missed-lesson/', (params: PatchMissedLessonParams) => {
    return getMissedLesson(params.id).then((elem) =>
        elem.length ? patchMissedLesson({ id: elem[0].id, recordViewed: true }) : ({} as MissedLesson),
    );
});

export const getMissedLessonAction = createAsyncThunk('missed-lesson/getMissedLessons', () => {
    return getMissedLesson();
});

type GetMeetingJoinRedirectParams = {
    lessonSchedule?: number;
    eventRoom?: number;
    assembly?: number;
};

export const getMeetingJoinRedirectAction = createAsyncThunk(
    'schedule/getMeetingJoinRedirect',
    ({ lessonSchedule, eventRoom, assembly }: GetMeetingJoinRedirectParams, { dispatch }) => {
        return getMeetingJoinRedirect({
            lesson_schedule: lessonSchedule,
            event_room: eventRoom,
            assembly: assembly,
        }).then(({ link, block }) => {
            if (block || !link) {
                dispatch(
                    error({
                        message: nonPaymentError.error,
                        extra: {
                            isOlBlocked: true,
                            needHideErrorIcon: true,
                            isBlockLesson: true,
                            isFrontend: false,
                            customDescription: nonPaymentError.descriptions,
                            disableCancelButton: true,
                        },
                    }),
                );
                return;
            }
            if (isMobile) {
                document.location.href = link;
                return;
            }
            window.open(link, '_blank', 'noopener noreferrer');
        });
    },
);

export const getMeetingWithCheckAction = createAsyncThunk(
    'schedule/getMeetingWithCheck',
    (
        params: {
            lessonSchedule?: number;
            eventRoom?: number;
            assembly?: number;
            isAppUsed?: boolean;
            datetimeStartUsedApp?: string | null;
            isMacOrApp: boolean;
            handleActionButtonClick?: () => void;
            handleCancelButtonClick?: () => void;
        },
        { dispatch },
    ) => {
        if (
            (params.isAppUsed && !params.isMacOrApp) ||
            (typeof params.datetimeStartUsedApp === 'string' &&
                ((moment().diff(params.datetimeStartUsedApp) < 0 && !params.isAppUsed) ||
                    (moment().diff(params.datetimeStartUsedApp) >= 0 && params.isAppUsed)) &&
                !params.isMacOrApp)
        ) {
            dispatch(
                error({
                    message: wrongClientError.error,
                    extra: {
                        needHideErrorIcon: true,
                        isBlockLesson: true,
                        isFrontend: false,
                        customDescription: `С ${moment(params.datetimeStartUsedApp).format(
                            'LL',
                        )} вход на онлайн-занятия будет доступен только через приложение "Онлайн Гимназия №1". Пожалуйста, установите приложение, чтобы не прерывать процесс обучения`,
                        disableCancelButton: moment().diff(params.datetimeStartUsedApp) >= 0 && params.isAppUsed,
                    },
                    actionButtonClick: params.handleActionButtonClick,
                    cancelButtonClick: params.handleCancelButtonClick,
                }),
            );
            return;
        }

        void dispatch(
            getMeetingJoinRedirectAction({
                lessonSchedule: params.lessonSchedule,
                eventRoom: params.eventRoom,
                assembly: params.assembly,
            }),
        );
    },
);

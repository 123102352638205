import { useLocation } from 'react-router-dom';
import { useWindowResize } from '@frontend-modules/frontend-utils';

export const useShowSidebar = (): boolean => {
    const { isMobile } = useWindowResize();
    const location = useLocation();

    const isNeedHideSideBar =
        isMobile ||
        location.pathname === '/signin' ||
        location.pathname === '/restore' ||
        location.pathname.includes('/question-edit') ||
        location.pathname.includes('/question-create') ||
        location.pathname.includes('/search-questions') ||
        location.pathname.includes('/materials/edit') ||
        location.pathname.includes('/materials/create') ||
        location.pathname.includes('/create-question') ||
        location.pathname.startsWith('/questions/edit') ||
        location.pathname.startsWith('/questions/create') ||
        location.pathname.startsWith('/o/auth') ||
        location.pathname.startsWith('/news/create') ||
        location.pathname.startsWith('/news/edit') ||
        location.pathname.includes('/anydesk');

    return !isNeedHideSideBar;
};

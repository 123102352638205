import { getApiRequest } from 'api';
import { News } from 'api/services/news';

interface NewsFilters {
    date_before?: string;
    date_after?: string;
}

export const getNews = (params: NewsFilters): Promise<News[]> => {
    return getApiRequest('/news/', { params: { date_before: params?.date_before, date_after: params?.date_after } });
};

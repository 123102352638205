import { api } from 'api';
import { AxiosError } from 'axios';
import { IAssignmentItem } from 'types/assignment';

export const fetchAssignments = async (id: number): Promise<IAssignmentItem[]> => {
    return await api
        .get<IAssignmentItem[] | IAssignmentItem>(`/assignment/${id}`)
        .then((res) => (Array.isArray(res?.data) ? res?.data : [res?.data]))
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

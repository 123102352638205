import React from 'react';
import { AppText } from '@frontend-modules/ui-kit';
import { E_COLORS } from 'src-new/constants';

import { TFractionContentProps } from './FractionContent.types';

import './FractionContent.style.scss';
export const FractionContent = (props: TFractionContentProps): React.ReactElement => {
    const { currentAmount, maxAmount, withPercent = false } = props;

    return (
        <div className={'fraction-content'}>
            <AppText
                text={`${currentAmount}${withPercent ? '%' : ''}`}
                textStyle={'DesktopH1'}
                color={E_COLORS.firm_blue_color}
            />
            <AppText
                text={`/ ${maxAmount}${withPercent ? '%' : ''}`}
                textStyle={'DesktopFootNotes'}
                color={E_COLORS.light_grey_color_2}
            />
        </div>
    );
};

import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { AssignmentResponse } from 'types/assignment';

export interface PostAssignmentParams {
    title: string;
    lesson: number;
    type: number;
    description?: string;
    isTemplate: boolean;
    courseGroup?: number;
}

export const postAssignment = (value: PostAssignmentParams): Promise<AssignmentResponse> => {
    const { isTemplate, courseGroup, ...restParams } = value;
    return api
        .post<Promise<AssignmentResponse>, AxiosResponse<AssignmentResponse>>(`/assignment/create/`, {
            ...restParams,
            isTemplate,
            courseGroup: isTemplate ? undefined : courseGroup,
        })
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

import { ReactElement } from 'react';

import { Parallel } from './parallel';

export enum LessonTypes {
    ONLINE = 'online_lesson',
    SELFPREPARATION = 'selfpreparation_lesson',
    TEST = 'test_lesson',
    PARENT_MEETING = 'parent_meeting',
}

export enum AssemblyTypes {
    PARENT_MEETING = 'parent_meeting',
}

export interface ScheduleEvent {
    id: number;
    lesson: {
        id: number;
        title: string;
    };
    type: string;
    subject: {
        id: number;
        title: string;
        shortName?: string;
    };
    courseGroup: {
        id: number;
        title: string;
    };
    meeting: {
        id: number;
        title: string;
    };
    parallels: Parallel[];
    topic: string;
    users: number[];
    parentName: string;
    date: string;
    datetimeStart: string;
    datetimeEnd: string;
    IconElement?: ReactElement;
    customTitle?: string;
    isEventRoom?: boolean;
    isAssembly?: boolean;
}

export interface ScheduleData {
    [key: string]: {
        date: string;
        data: ScheduleEvent[];
    };
}

/**
 * @description удаляет пустые свойства объекта
 * @param {any} params
 * @return {Type | undefined}
 * @protected
 */
function deleteEmptyParams<Type>(params: Type): Type | undefined {
    for (const key in params) {
        if (params[key] === undefined) {
            delete params[key];
        } else {
            if (typeof params[key] == 'object') {
                if (Object.keys(params[key]).length == 0) {
                    delete params[key];
                }
            }
        }
    }
    return params;
}

export { deleteEmptyParams };

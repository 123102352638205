import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { PeriodMark } from 'api/services/periodMark';
import { PeriodMarkFinal } from 'api/services/periodMark/getPeriodMarkFinal';
import { IQuarterMark } from 'src-new/components/lms-elements/PerformanceTable/types';
import { getPeriodMarkAction, getPeriodStudentMarkAction, patchPeriodMarkAction } from 'store/actions/periodMark';
import { FetchStatus } from 'types/api';

interface PeriodMarkState {
    getPeriodMarkStatus: FetchStatus;
    patchPeriodMarkStatus: FetchStatus;
    getPeriodMarkFinalStatus: FetchStatus;
    periodMark: PeriodMark[];
    quarterMarks: IQuarterMark[];
    quarterStudentMarks: IQuarterMark[];
    periodsCache: number[];
    courseGroupCache: number;
    periodMarkFinal: PeriodMarkFinal[];
    mark: PeriodMark;
    error: unknown;
}

const initialState = {
    getPeriodMarkStatus: FetchStatus.INITIAL,
    patchPeriodMarkStatus: FetchStatus.INITIAL,
    getPeriodMarkFinalStatus: FetchStatus.INITIAL,
    periodMark: [],
    quarterMarks: [],
    periodsCache: [],
    courseGroupCache: -1,
    periodMarkFinal: [],
    quarterStudentMarks: [],
    mark: {
        id: 0,
        score: 0,
        student: 0,
        period: 0,
        course: 0,
        avgScore: 0,
        passed: null,
        notCertified: false,
    },
    error: null,
};

const periodMarkSlice = createSlice<PeriodMarkState, SliceCaseReducers<PeriodMarkState>>({
    name: 'periodMark',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder

            .addCase(getPeriodMarkAction.fulfilled, (state, { payload }) => {
                state.getPeriodMarkStatus = FetchStatus.FETCHED;
                const { periodMarks, needRewrite } = payload;
                if (needRewrite) state.quarterMarks = periodMarks;
                else state.quarterMarks = state.quarterMarks.concat(periodMarks);
                state.error = null;
            })
            .addCase(getPeriodMarkAction.rejected, (state, { error }) => {
                state.getPeriodMarkStatus = FetchStatus.ERROR;
                state.error = error;
            })

            .addCase(getPeriodStudentMarkAction.fulfilled, (state, { payload }) => {
                state.getPeriodMarkStatus = FetchStatus.FETCHED;
                state.quarterStudentMarks = state.quarterStudentMarks.concat(payload);
                state.error = null;
            })
            .addCase(getPeriodStudentMarkAction.rejected, (state, { error }) => {
                state.getPeriodMarkStatus = FetchStatus.ERROR;
                state.error = error;
            })

            .addCase(patchPeriodMarkAction.pending, (state) => {
                state.patchPeriodMarkStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(patchPeriodMarkAction.fulfilled, (state, { payload }) => {
                state.patchPeriodMarkStatus = FetchStatus.FETCHED;
                state.mark = payload;
                state.quarterMarks = state.quarterMarks.map((periodMark) => {
                    if (periodMark.id === payload.id) {
                        return {
                            ...periodMark,
                            score: payload.notCertified ? -1 : payload.score !== null ? payload.score : undefined,
                            testApproved: payload.passed !== null ? payload.passed : undefined,
                        };
                    }
                    return periodMark;
                });
                state.error = null;
            })
            .addCase(patchPeriodMarkAction.rejected, (state, { error }) => {
                state.patchPeriodMarkStatus = FetchStatus.ERROR;
                state.error = error;
            });
    },
});

export const resetPeriodMarkState = periodMarkSlice.actions.reset as ActionCreatorWithoutPayload<string>;
export const periodMarkReducer = periodMarkSlice.reducer;

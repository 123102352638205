import React from 'react';
import { Field, FieldInputProps } from 'react-final-form';
import { DatePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { AppDatePicker } from 'src-new/ui';

import { getTimeZoneOffsetHours } from 'components/Task/utils';

import { ITaskDatePickerProps } from './TaskDatePicker.types';

const dateFormat = 'DD.MM.YYYY';
const timeFormat = 'HH:mm';

/**
 *
 * @param props
 * @constructor
 */
export const TaskDatePicker: React.FC<ITaskDatePickerProps> = (props: ITaskDatePickerProps) => {
    const { value, fieldName, placeholder, status, disabledDate, disabledTime } = props;
    const offset = getTimeZoneOffsetHours();
    const handleDateChange = (input: FieldInputProps<string, HTMLElement>, value: Dayjs) => {
        // округляем минуты до 10 частей
        const minute = value.minute();
        const minute10 = minute / 10;
        const newMinute = minute10 !== 0 ? Math.round(minute10) * 10 : minute;
        const newValue = value.minute(newMinute).valueOf();
        // так как все относительно московского времени добавляем смещение относительного текущего часового пояса
        // (offset отрицательный
        input.onChange(dayjs(newValue).subtract(offset, 'hours').toISOString());
    };

    const customFormat: DatePickerProps['format'] = (value) =>
        `${value.format(dateFormat)} в ${value.format(timeFormat)}`;

    const dayValue = value ? dayjs(value)?.add(offset, 'hours') : undefined;

    return (
        <Field name={fieldName}>
            {({ input }) => (
                // @ts-ignore
                <AppDatePicker
                    showTime={{ format: 'HH:mm' }}
                    format={customFormat}
                    placeholder={placeholder}
                    minuteStep={10}
                    showNow={false}
                    // а здесь наоборот отнимаем, что бы все верно отображалось
                    value={dayValue}
                    disabledDate={disabledDate}
                    onSelect={handleDateChange.bind(null, input)}
                    disabledTime={disabledTime}
                    status={status}
                    allowClear={false}
                />
            )}
        </Field>
    );
};

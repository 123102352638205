import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import {
    getFirstArchiveNotificationsPageAction,
    getNextArchiveNotificationsPageAction,
    getNotificationsPageAction,
    getUnreadMessagesCountAction,
    getUnreadNotificationsCountAction,
} from 'store/actions/notifications';
import { normalizeViewedNotifications } from 'store/normalizers/notifications/normalizeArchiveNotifications';
import { normalizeNewNotifications } from 'store/normalizers/notifications/normalizeNewNotifications';
import { FetchStatus } from 'types/api';
import { NewNotificationWithExtraInfo, ViewedNotificationWithExtraInfo } from 'types/notifications';

export interface NotificationsState {
    unreadNotificationsCount: number;
    unreadMessagesCount: number;
    getUnreadNotificationsCountStatus: FetchStatus;
    getUnreadMessagesCountStatus: FetchStatus;
    newNotifications: NewNotificationWithExtraInfo[];
    getNotificationsPageStatus: FetchStatus;
    viewedNotifications: ViewedNotificationWithExtraInfo[];
    nextArchiveNotificationsPageLink: string | null;
    getArchiveNotificationsPageStatus: FetchStatus;
    error: unknown;
}

export const initialState: NotificationsState = {
    unreadNotificationsCount: 0,
    unreadMessagesCount: 0,
    getUnreadNotificationsCountStatus: FetchStatus.INITIAL,
    getUnreadMessagesCountStatus: FetchStatus.INITIAL,
    newNotifications: [],
    getNotificationsPageStatus: FetchStatus.INITIAL,
    viewedNotifications: [],
    nextArchiveNotificationsPageLink: null,
    getArchiveNotificationsPageStatus: FetchStatus.INITIAL,
    error: null,
};

const notificationsSlice = createSlice<NotificationsState, SliceCaseReducers<NotificationsState>>({
    name: 'notifications',
    initialState,
    reducers: {
        clearNotifications(state) {
            state.newNotifications = [];
            state.getNotificationsPageStatus = FetchStatus.INITIAL;
            state.viewedNotifications = [];
            state.getArchiveNotificationsPageStatus = FetchStatus.INITIAL;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUnreadNotificationsCountAction.pending, (state) => {
                state.getUnreadNotificationsCountStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getUnreadNotificationsCountAction.fulfilled, (state, { payload }) => {
                state.unreadNotificationsCount = payload.count;
                state.getUnreadNotificationsCountStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(getUnreadNotificationsCountAction.rejected, (state, { error }) => {
                state.getUnreadNotificationsCountStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getUnreadMessagesCountAction.pending, (state) => {
                state.getUnreadMessagesCountStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getUnreadMessagesCountAction.fulfilled, (state, { payload }) => {
                state.getUnreadMessagesCountStatus = FetchStatus.FETCHED;

                state.unreadMessagesCount = payload.unreadMessagesCount;
                state.error = null;
            })
            .addCase(getUnreadMessagesCountAction.rejected, (state, { error }) => {
                state.getUnreadMessagesCountStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getNotificationsPageAction.pending, (state) => {
                state.getNotificationsPageStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getNotificationsPageAction.fulfilled, (state, { payload }) => {
                state.newNotifications = [...state.newNotifications, ...normalizeNewNotifications(payload)];
                state.getNotificationsPageStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(getNotificationsPageAction.rejected, (state, { error }) => {
                state.getNotificationsPageStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getFirstArchiveNotificationsPageAction.pending, (state) => {
                state.getArchiveNotificationsPageStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getFirstArchiveNotificationsPageAction.fulfilled, (state, { payload }) => {
                state.viewedNotifications = [...state.newNotifications, ...normalizeViewedNotifications(payload.data)];
                state.nextArchiveNotificationsPageLink = payload.next;
                state.getArchiveNotificationsPageStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(getFirstArchiveNotificationsPageAction.rejected, (state, { error }) => {
                state.getArchiveNotificationsPageStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getNextArchiveNotificationsPageAction.pending, (state) => {
                state.getArchiveNotificationsPageStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getNextArchiveNotificationsPageAction.fulfilled, (state, { payload }) => {
                if (payload === null) {
                    state.getArchiveNotificationsPageStatus = FetchStatus.FETCHED;
                    state.error = null;
                    return;
                }
                state.viewedNotifications = [...state.newNotifications, ...normalizeViewedNotifications(payload.data)];
                state.nextArchiveNotificationsPageLink = payload.next;
                state.getArchiveNotificationsPageStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(getNextArchiveNotificationsPageAction.rejected, (state, { error }) => {
                state.getArchiveNotificationsPageStatus = FetchStatus.ERROR;
                state.error = error;
            });
    },
});

const { clearNotifications } = notificationsSlice.actions as Record<string, ActionCreatorWithoutPayload<string>>;
export { clearNotifications };
export const notificationsReducer = notificationsSlice.reducer;

import React from 'react';
import { cn } from '@bem-react/classname';
import { KinescopeVideoCard } from '@fsd-widgets/kinescope-video';
import { ExpandedDocumentMaterialStudent, ExpandedDocumentMaterialTeacher } from '@lms-elements/materials-document';
import { ExpandedHeaderMaterial } from '@lms-elements/materials-header';
import { ExpandedImageMaterial } from '@lms-elements/materials-image';
import { ExpandedAudioMaterial, ExpandedVideoMaterial } from '@lms-elements/materials-media';
import { ExpandedTableMaterial } from '@lms-elements/materials-table';
import { ExpandedTextMaterial } from '@lms-elements/materials-text';
import { AppText } from 'src-new/ui';
import { ContentMaterialTypeEnum } from 'types/materials';

import { ExpandedMaterialExerciseContent } from './ExpandedMaterialExerciseContent/ExpandedMaterialExerciseContent';
import { IExpandedMaterialContentProps } from './types';

import './ExpandedMaterialContent.scss';

const cnTable = cn('expanded-material-table-cell');
const cnText = cn('expanded-material-text');
const cnPreview = cn('expanded-material-preview');

const functionStab = () => undefined;

export const ExpandedMaterialContent: React.FC<IExpandedMaterialContentProps> = ({
    name,
    type,
    onDelete = functionStab,
    isEditorOnText,
    areDragging,
    isStudentView,
    url,
    editorFieldName,
    htmlFieldName,
    videoUrl,
    percent,
    isLastQuestion,
    handleUploadImage,
    handleDeleteImage,
    onCreateQuestion,
    onSelectQuestion,
    onChangeQuestion,
    onEditQuestion,
    onCheckAnswer,
    isPreview,
}) => {
    switch (type) {
        case ContentMaterialTypeEnum.audio:
            return (
                <ExpandedAudioMaterial name={name} onDelete={onDelete} audioUrl={url} showForStudents={isStudentView} />
            );
        case ContentMaterialTypeEnum.video:
            return (
                <ExpandedVideoMaterial
                    name={name}
                    videoUrl={videoUrl}
                    onDelete={onDelete}
                    showForStudents={isStudentView}
                />
            );
        case ContentMaterialTypeEnum.kinescope:
            return <KinescopeVideoCard name={name} url={videoUrl} onDelete={onDelete} isEditable={!isStudentView} />;
        case ContentMaterialTypeEnum.text:
            return (
                <ExpandedTextMaterial
                    name={name}
                    onDelete={onDelete}
                    isEditor={isEditorOnText}
                    isBlocked={areDragging}
                    isStudentView={isStudentView}
                    editorFieldName={editorFieldName}
                    htmlFieldName={htmlFieldName}
                    studentViewClassName={cnText()}
                />
            );
        case ContentMaterialTypeEnum.photo:
            return (
                <ExpandedImageMaterial
                    name={name}
                    onDelete={onDelete}
                    showForStudents={isStudentView}
                    onUploadImage={handleUploadImage}
                    onDeleteImage={handleDeleteImage}
                />
            );
        case ContentMaterialTypeEnum.document:
            return isStudentView ? (
                <ExpandedDocumentMaterialStudent name={name} />
            ) : (
                <ExpandedDocumentMaterialTeacher name={name} onDelete={onDelete} />
            );
        case ContentMaterialTypeEnum.header:
            return (
                <ExpandedHeaderMaterial
                    name={name}
                    onDelete={onDelete}
                    isStudentView={isStudentView}
                    customClassName={isPreview ? cnPreview('header') : ''}
                />
            );
        case ContentMaterialTypeEnum.table:
            return (
                <ExpandedTableMaterial
                    fieldName={name}
                    showForStudents={isStudentView}
                    onDelete={onDelete}
                    cellClassName={cnTable()}
                />
            );
        case ContentMaterialTypeEnum.exercise:
            return (
                <div className={'exercise-block'}>
                    {Number(percent) < 100 && isLastQuestion && (
                        <>
                            <AppText
                                textStyle={'blue-mark'}
                                fontStyle={'footnotes'}
                                text={`Вы изучили ${
                                    percent || 0
                                }% материалов. Выполните упражнение, чтобы открыть следующий блок и продолжить изучение материалов`}
                            />
                        </>
                    )}
                    <ExpandedMaterialExerciseContent
                        name={name}
                        isStudentView={isStudentView}
                        onDelete={onDelete}
                        onCreateQuestion={onCreateQuestion}
                        onSelectQuestion={onSelectQuestion}
                        onChangeQuestion={onChangeQuestion}
                        onEditQuestion={onEditQuestion}
                        onCheckAnswer={onCheckAnswer}
                    />
                    {Number(percent) === 100 && isLastQuestion && (
                        <AppText
                            fontStyle={'fontbutton'}
                            textStyle={'blue-mark'}
                            text={`Поздравляем, вы изучили все материалы!`}
                        />
                    )}
                </div>
            );
        default:
            return null;
    }
};

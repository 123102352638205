import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { SchoolPartner } from 'api/services/schoolPartner';
import { StudentsStatistics, StudentsStatisticsTariff } from 'api/services/student';
import { StudentGroupStatistics } from 'api/services/studentGroups/getStudentsGroupStatistics';
import { StudentGroupStatisticsTariff } from 'api/services/studentGroups/getStudentsGroupStatisticsTariffs';
import { getActiveClassesAction } from 'store/actions/activeClasses';
import { normalizeGridCells } from 'store/normalizers/activeClasses/normalizeGridCells';
import { studentGroupStatisticCells } from 'store/normalizers/activeClasses/normalizeStudentGroupStatistic';
import { FetchStatus } from 'types/api';
import { StudentGroupStatistic } from 'types/reporting';

export interface ActiveClassesState {
    partnerStatistics: SchoolPartner[];
    studentsStatistics: StudentsStatistics | null;
    studentsStatisticsTariff: StudentsStatisticsTariff[];
    studentGroupStatisticsTariffs: StudentGroupStatisticsTariff[];
    getActiveClassesStatus: FetchStatus;
    gridCells: (StudentGroupStatistics & { tariff: string })[][];
    studentGroupStatistic: StudentGroupStatistic | null;
    error: unknown;
}

const initialState: ActiveClassesState = {
    partnerStatistics: [],
    studentsStatistics: null,
    studentsStatisticsTariff: [],
    studentGroupStatisticsTariffs: [],
    gridCells: [],
    studentGroupStatistic: null,
    getActiveClassesStatus: FetchStatus.INITIAL,
    error: null,
};

const activeClassesSlice = createSlice<ActiveClassesState, SliceCaseReducers<ActiveClassesState>>({
    name: 'activeClasses',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getActiveClassesAction.pending, (state) => {
            state.getActiveClassesStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getActiveClassesAction.fulfilled, (state, { payload }) => {
            state.getActiveClassesStatus = FetchStatus.FETCHED;
            state.partnerStatistics = payload[0];
            state.studentsStatistics = payload[1];
            state.studentsStatisticsTariff = payload[2];
            state.studentGroupStatisticsTariffs = payload[4];
            state.gridCells = normalizeGridCells({
                studentGroupStatistics: payload[3],
                studentsStatisticsTariff: payload[2],
            });
            state.studentGroupStatistic = studentGroupStatisticCells({
                studentGroupStatistics: payload[3],
                studentGroupStatisticsParallels: payload[5],
            });
            state.error = null;
        });
        builder.addCase(getActiveClassesAction.rejected, (state, { error }) => {
            state.getActiveClassesStatus = FetchStatus.ERROR;
            state.error = error;
        });
    },
});

export const resetActiveClassesState = activeClassesSlice.actions.reset as ActionCreatorWithoutPayload;
export const activeClassesReducer = activeClassesSlice.reducer;

import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { CourseGroupDetails } from 'types/courseGroup';

export const getCourseGroupDetails = async (id: number): Promise<CourseGroupDetails> => {
    return await api
        .get<never, AxiosResponse<CourseGroupDetails>>(`/course-groups/${id}/`)
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

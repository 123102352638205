import { host } from 'constants/apiMethods';

import { api, getApiRequest, patchApiRequest } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { StudentProfile } from 'types/students';

export interface PostStudentProfile {
    isAdaptation: boolean;
}

export interface PostData {
    id: string;
    data: PostStudentProfile;
}

export interface StudentsStatistics {
    studentsCount: number;
    studentsWithoutSchoolPartnerCount: number;
    studentsWithoutTariffCount: number;
}

export interface StudentsStatisticsTariff {
    id: number;
    title: string;
    studentsCount: number;
}

export const fetchStudentData = async (userId: number): Promise<StudentProfile> => {
    return await api
        .get<never, AxiosResponse<StudentProfile>>(`${host}/student-profile/${userId}/`)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const createStudentProfile = async (params: PostData): Promise<StudentProfile> => {
    return await patchApiRequest<StudentProfile, PostStudentProfile>(
        `/student-profile/partial-update/${params.id}/`,
        params.data,
    );
};

export const getStudentsStatistics = (): Promise<StudentsStatistics> => {
    return getApiRequest(`/students/statistics/`);
};

export const getStudentsStatisticsTariff = (): Promise<StudentsStatisticsTariff[]> => {
    return getApiRequest(`/students/statistics-tariff/`);
};

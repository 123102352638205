import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCalendarPlanList } from 'api/services/calendarPlan';
import {
    deleteEducationalPlan,
    deleteEducationalPlanItem,
    editEducationalPlanItem,
    getAllEducationalPlans,
    getEducationalPlan,
    patchEducationalPlan,
    PostEducationalPlan,
    postEducationalPlan,
    PostEducationalPlanItem,
} from 'api/services/educationalPlan';
import { createEducationalPlanItem, PostItem } from 'api/services/educationalPlan/createEducationalPlanItem';
import { getEducationalParallels } from 'api/services/educationalPlan/getEducationalParallels';
import { getNormalizedItemsMap } from 'store/normalizers/educationalPlan/getNormalizedItemsMap';
import { ResponseEducationalPlanItem } from 'types/educationalPlan';

export const getEducationalPlanAction = createAsyncThunk('course/getEducationalPlan', async (id: number) => {
    return await Promise.all([getEducationalPlan(id), getCalendarPlanList(), getEducationalParallels()]);
});

export const postEducationalPlanAction = createAsyncThunk(
    'course/postEducationalPlan',
    async ({ educationalPlanItems, ...params }: PostEducationalPlan) => {
        const educationalPlan = await postEducationalPlan({ ...params, educationalPlanItems: [] });

        let newItems: ResponseEducationalPlanItem[] = [];

        if (educationalPlanItems && educationalPlanItems.length > 0) {
            newItems = await createEducationalPlanItem({
                educationalPlanItems: getNormalizedItemsMap(educationalPlanItems, Number(educationalPlan.id)),
            } as PostItem);
        }

        return {
            ...educationalPlan,
            educationalPlanItems: newItems,
        };
    },
);

type patchEducationalPlanActionData = {
    params: PostEducationalPlan;
    idsWillDelete: number[];
};

export const patchEducationalPlanAction = createAsyncThunk(
    'course/patchEducationalPlan',
    async ({ params, idsWillDelete }: patchEducationalPlanActionData) => {
        const { educationalPlanItems = [], ...data } = params;
        if (idsWillDelete.length > 0) {
            await deleteEducationalPlanItem({ data: { id: idsWillDelete } });
        }

        const newItems: PostEducationalPlanItem[] = [];
        const newItemsOrder: number[] = [];
        const patchItems: PostEducationalPlanItem[] = [];

        const ordering = educationalPlanItems.map((item, index) => {
            if (item.id && item.id < 0) {
                newItems.push(item);
                newItemsOrder.push(index);
                return item.id ?? -1;
            }

            patchItems.push(item);
            return item.id ?? -1;
        });

        if (newItems.length > 0) {
            const returnedItems = await createEducationalPlanItem({
                educationalPlanItems: getNormalizedItemsMap(newItems, Number(data.id)),
            } as PostItem);
            returnedItems.forEach((item, index) => {
                ordering[newItemsOrder[index]] = item.id;
            });
        }

        if (patchItems.length > 0) {
            await editEducationalPlanItem({
                educationalPlanItems: getNormalizedItemsMap(patchItems, Number(data.id)),
            });
        }

        return await patchEducationalPlan({
            ...data,
            ordering,
        });
    },
);

export const deleteEducationalPlanAction = createAsyncThunk('course/deleteEducationalPlan', async (id: number) => {
    return await deleteEducationalPlan(id);
});

export const getAllCalendarPlansAction = createAsyncThunk('course/getAllCalendarPlans', async () => {
    return await getCalendarPlanList();
});

export const getEducationalParallelsAction = createAsyncThunk('course/getEducationalParallels', async () => {
    return await getEducationalParallels();
});

export const getEducationPLanWithParallelsAndCalendar = createAsyncThunk(
    'courseGroup/getEducationPLanWithParallelsAndCalendar',
    async () => {
        return await Promise.all([getAllEducationalPlans(), getCalendarPlanList(), getEducationalParallels()]);
    },
);

export const getAllEducationalPlanAction = createAsyncThunk('courseGroup/getAllEducationalPlan', async () => {
    return await getAllEducationalPlans();
});

export const deleteEducationalPlanItemAction = createAsyncThunk(
    'course/deleteEducationalPlanItem',
    async (params: { data: { id: number[] } }) => {
        return await deleteEducationalPlanItem(params);
    },
);

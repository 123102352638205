import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { SingleQuestionContainer, TSingleQuestionContainerDrag } from 'src-new/components/lesson-task';

import './style.scss';

/**
 * @description SingleQuestionContainerDrag - обертка над SingleQuestionContainer для реализации перетаскивания
 * @props TSingleQuestionContainer
 * dragData - используется для dnd
 * mainProps - используется для QuestionsBlockContainer
 * */
export const SingleQuestionContainerDrag: React.FC<TSingleQuestionContainerDrag> = (props) => {
    const { canDrag, dragData, mainProps, isIndependent = true } = props;

    const [{ isDragging }, dragSingle, preview] = useDrag(
        () => ({
            item: () => {
                return {
                    mainProps,
                    dragData,
                };
            },
            type: isIndependent ? 'single' : 'block-single',
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [props],
    );
    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    return (
        <div ref={canDrag ? dragSingle : null} className={`drag-container ${isDragging ? 'isDragging' : ''}`}>
            <SingleQuestionContainer {...mainProps} />
        </div>
    );
};

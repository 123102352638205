import { ContentMaterialTypeEnum, IMaterial, IMaterialDocument } from 'types/materials';

export const getMaterialsFilesDeleteArray = (
    oldMaterial: IMaterial | undefined,
    newMaterial: IMaterial,
): number[] | undefined => {
    if (!oldMaterial) {
        return;
    }

    if (oldMaterial.type === ContentMaterialTypeEnum.document) {
        const oldFiltered = oldMaterial.documentData.filter(
            (value) => !(typeof value === 'string' || value instanceof File),
        ) as IMaterialDocument[];
        const newFiltered = newMaterial.documentData.filter(
            (value) => !(typeof value === 'string' || value instanceof File),
        ) as IMaterialDocument[];
        const deleted = oldFiltered
            .filter(
                (value: IMaterialDocument) =>
                    value.id && !newFiltered.some((newValue: IMaterialDocument) => value.id === newValue.id),
            )
            .map((value) => value.id) as number[];
        return deleted;
    }

    if (oldMaterial.type === ContentMaterialTypeEnum.video) {
        const newVideoFile = newMaterial.videoData.file;
        if (newVideoFile || newMaterial.videoData.url) {
            // удалить все прошлые файлы
            return newMaterial.files.map((file) => file.id);
        }
    }
};

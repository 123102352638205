import React, { FC, useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';
import { isEmptyList } from '@frontend-modules/frontend-utils';
import { AppFontIcon, AppText } from '@frontend-modules/ui-kit';
import { EmptyTrainerCard, getTrainingQuestionsAction } from '@fsd-entities/materials';
import { ITrainingQuestionInfo } from '@fsd-shared/models/materials';
import { AppSkeletonCard } from '@fsd-shared/ui';
import { ExerciseInfoModal } from '@fsd-widgets/materials/TeacherTrainingList/ExerciseInfoModal';
import { E_COLORS } from 'src-new/constants';
import { ScrollPaginateContainer } from 'src-new/ui';
import { useAppDispatch, useAppSelector } from 'store/store';

import { TeacherTrainingItem } from './TeacherTrainingItem';
import { ITeacherTrainingListProps } from './TeacherTrainingList.types';

import './TeacherTrainingList.styles.scss';

const CnTeacherTrainingList = cn('teacher-training-list');

export const TeacherTrainingList: FC<ITeacherTrainingListProps> = (props) => {
    const { lessonId, courseGroupId } = props;
    const dispatch = useAppDispatch();
    const { list, pagination, isLoadingMore, isLoading } =
        useAppSelector((store) => store.newMaterials?.training?.questionList) || {};
    const { currentPage, maxPage } = pagination || {};
    const [isShowModal, setIsShowModal] = useState(false);
    const [selectedQuestionId, setSelectedQuestionId] = useState<undefined | number>();

    const isShowSkeleton = isLoading && isEmptyList(list);
    const isEmptyData = isLoading === false && isEmptyList(list);
    const itemsList = isShowSkeleton ? Array(7).fill({}) : list;

    const onExerciseClick = (item: ITrainingQuestionInfo) => {
        setSelectedQuestionId(item.id);
        setIsShowModal(true);
    };

    const loadQuestionList = (page: number, isMore?: boolean) => {
        if (lessonId && courseGroupId) {
            const params = {
                filters: {
                    lesson: lessonId,
                    courseGroup: courseGroupId,
                },
                currentPage: page,
                limit: 50,
                isLoadingMore: isMore,
            };
            void dispatch(getTrainingQuestionsAction(params));
        }
    };

    const onLoadMore = () => {
        if (currentPage && maxPage && currentPage < maxPage && !isLoadingMore) {
            loadQuestionList(currentPage + 1, true);
        }
    };

    useEffect(() => {
        loadQuestionList(1);
    }, [lessonId, courseGroupId]);

    if (isEmptyData) {
        return <EmptyTrainerCard type={'noMaterials'} />;
    }

    return (
        <>
            <div className={CnTeacherTrainingList()}>
                {!isShowSkeleton && (
                    <div className={CnTeacherTrainingList('info')}>
                        <AppFontIcon icon={'check-l'} color={E_COLORS.mint_color} />
                        <div className={CnTeacherTrainingList('info-text')}>
                            <AppText className={CnTeacherTrainingList('info-text-title')} text={'Отбор в тренажёр'} />
                            <AppText
                                text={'*будет задан 1 случайный вопрос из списка'}
                                textStyle={'DesktopFootNotes'}
                                color={E_COLORS.light_grey_color_2}
                            />
                        </div>
                    </div>
                )}
                <div className={CnTeacherTrainingList('list')}>
                    <ScrollPaginateContainer
                        className={CnTeacherTrainingList('scroll-container')}
                        onEndReached={onLoadMore}
                        onEndReachedThreshold={0.9}
                        paddingBottom={0}
                    >
                        {itemsList?.map((item, index) =>
                            isShowSkeleton ? (
                                <AppSkeletonCard key={index} height={47} borderRadius={14} />
                            ) : (
                                <TeacherTrainingItem key={item.id} {...item} onClick={() => onExerciseClick(item)} />
                            ),
                        )}
                        {isLoadingMore ? (
                            <div className={CnTeacherTrainingList('loader')}>
                                <AppFontIcon className={'rotating'} icon={'reboot-l'} color={'#0088e9'} />
                            </div>
                        ) : null}
                    </ScrollPaginateContainer>
                </div>
            </div>
            {isShowModal && (
                <ExerciseInfoModal questionId={selectedQuestionId} isShow={isShowModal} setShow={setIsShowModal} />
            )}
        </>
    );
};

import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { AssignmentProgressStatus } from 'types/assignmentProgress';
import { FilterTypes, IHomeworkItem } from 'types/homework';

interface Params {
    deadline_for_complete_after?: string;
    deadline_for_complete_before?: string;
    deadline_for_check_after?: string;
    deadline_for_check_before?: string;
    assigned_date_after?: string;
    assigned_date_before?: string;
    status?: string;
    my_course_groups?: string;
    is_mandatory?: boolean;
}

export const getHomeworksBetweenDates = async (
    filterType: FilterTypes,
    dateStart: string,
    dateEnd: string,
    statusFilters?: AssignmentProgressStatus[],
    isFilterCourseGroup?: boolean,
    isMandatory?: boolean,
): Promise<IHomeworkItem[]> => {
    let params: Params;

    switch (filterType) {
        case FilterTypes.DEADLINE_FOR_COMPLETE:
            params = {
                deadline_for_complete_after: dateStart,
                deadline_for_complete_before: dateEnd,
            };
            break;
        case FilterTypes.DEADLINE_FOR_CHECK:
            params = {
                deadline_for_check_after: dateStart,
                deadline_for_check_before: dateEnd,
            };
            break;
        case FilterTypes.ASSIGNED:
        default:
            params = {
                assigned_date_after: dateStart,
                assigned_date_before: dateEnd,
            };
            break;
    }

    if (statusFilters && statusFilters.length > 0) {
        params.status = statusFilters.join(',');
    }

    if (isFilterCourseGroup !== undefined) {
        params.my_course_groups = String(isFilterCourseGroup);
    }
    if (isMandatory) {
        params.is_mandatory = isMandatory;
    }

    return await api
        .get<never, AxiosResponse<IHomeworkItem[]>>(`/assignment-progress/`, { params })
        .then((res) => res.data || [])
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

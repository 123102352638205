import { lastAttemptDeleting } from 'constants/errors';

import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteAnswerAttempts } from 'api/services/answerAttempt';
import {
    changeAssignmentMark,
    changeTeacherMarks,
    createTeacherMarks,
    deleteTeacherMarks,
    PatchAssignmentMarkParams,
    PatchTeacherMarkParams,
    PostAssignmentMark,
} from 'api/services/marks';
import { getTransferredMarks, TransferredMarksParams } from 'api/services/marks/getTransferredMarks';
import { error } from 'store/reducers/error';

import { getAnswerAttemptByProgressAction } from './answerAttempt';
import { getStudentTaskById } from './studentTasks';

export const createAssignmentAdditionalMarkAction = createAsyncThunk(
    'marks/createAssignmentAdditionalMark',
    (params: PostAssignmentMark[]) => {
        return createTeacherMarks(params);
    },
);

type ChangeTeacherMarksParams = {
    taskId: number;
    marks: PatchTeacherMarkParams[];
};

export const changeAssignmentAdditionalMarkAction = createAsyncThunk(
    'marks/changeAssignmentAdditionalMark',
    ({ marks }: ChangeTeacherMarksParams) => {
        return changeTeacherMarks(marks.map((mark) => ({ ...mark, comment: mark.comment || '' })));
    },
);

export const changeAssignmentMarkAction = createAsyncThunk(
    'marks/changeAssignmentMark',
    ({ taskId, ...params }: PatchAssignmentMarkParams & { taskId: number }, { dispatch }) => {
        return changeAssignmentMark(params).then(() => {
            void dispatch(getStudentTaskById(taskId));
            void dispatch(getAnswerAttemptByProgressAction(taskId));
        });
    },
);

type ChangeAssignmentMarksParams = {
    taskId: number;
    marks: PatchAssignmentMarkParams[];
};

export const changeAssignmentMarksAction = createAsyncThunk(
    'marks/changeAssignmentsMark',
    ({ marks }: ChangeAssignmentMarksParams) => {
        return Promise.all(marks.map((mark) => changeAssignmentMark({ ...mark, comment: mark.comment ?? '' })));
    },
);

type deleteAddittionalMarksParams = {
    marks: number[];
    taskId: number;
};

export const deleteAssignmentAdditionalMarkAction = createAsyncThunk(
    'marks/deleteAssignmentAdditionalMark',
    ({ marks, taskId }: deleteAddittionalMarksParams, { dispatch }) => {
        return deleteTeacherMarks(marks).then(() => {
            void dispatch(getStudentTaskById(taskId));
        });
    },
);

export const deleteAssignmentAdditionalMarkWithCheckAction = createAsyncThunk(
    'marks/deleteAssignmentAdditionalMarkWithCheck',
    async (
        {
            marks,
            taskId,
            handleCancelButtonClick,
            handleActionButtonClick,
            teacherMarks,
            allMarks,
        }: {
            marks: number[];
            taskId: number;
            teacherMarks: number[];
            numberOfMarks: number;
            allMarks?: number;
            handleCancelButtonClick?: () => void;
            handleActionButtonClick?: () => void;
        },
        { dispatch },
    ) => {
        if (teacherMarks.length > 0) {
            await deleteTeacherMarks(teacherMarks);
        }

        if (!marks.length) {
            return dispatch(getStudentTaskById(taskId));
        }

        if (allMarks && allMarks > marks.length) {
            return deleteAnswerAttempts(marks).then(() => {
                void dispatch(getStudentTaskById(taskId));
            });
        }

        dispatch(
            error({
                message: lastAttemptDeleting.error,
                extra: {
                    needHideErrorIcon: true,
                    isFrontend: false,
                    customDescription: lastAttemptDeleting.descriptions,
                    isLastAttemptDeliting: true,
                    disableCancelButton: false,
                },
                actionButtonClick: handleActionButtonClick,
                cancelButtonClick: handleCancelButtonClick,
            }),
        );
    },
);

export const getTransferredMarksAction = createAsyncThunk(
    'marks/getTransferredMarks',
    (params: TransferredMarksParams) => {
        return getTransferredMarks(params);
    },
);

import React from 'react';
import { cn } from '@bem-react/classname';
import { ConfigProvider, DatePicker, DatePickerProps } from 'antd';
import ru from 'antd/locale/ru_RU';
import dayjs from 'dayjs';

import 'dayjs/locale/ru';
dayjs.locale('ru');
import { CalendarIcon } from 'assets';

import './AppDatePicker.scss';

const CnAppDatepicker = cn('app-datepicker');

export const AppDatePicker: React.FC<DatePickerProps> = (props) => {
    return (
        <ConfigProvider locale={ru}>
            {/* @ts-ignore конфликт с версией @types/react не стал обновлять до 18 версии от греха подальше*/}
            <DatePicker prefixCls={CnAppDatepicker()} suffixIcon={<CalendarIcon />} {...props} />
        </ConfigProvider>
    );
};

import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { changeAdaptationAction } from 'store/actions/adaptation';
import { getAdaptationProgressAction } from 'store/actions/adaptationProgress';
import { FetchStatus } from 'types/api';
import { AdaptationStudent } from 'types/students';

export interface AdaptationProgressState {
    getAdaptationProgressStatus: FetchStatus;
    adaptationProgressStudents: AdaptationStudent[];
    error: unknown;
}

export const initialState: AdaptationProgressState = {
    getAdaptationProgressStatus: FetchStatus.INITIAL,
    adaptationProgressStudents: [],
    error: null,
};

const adaptationProgressSlice = createSlice<AdaptationProgressState, SliceCaseReducers<AdaptationProgressState>>({
    name: 'adaptationProgress',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAdaptationProgressAction.pending, (state) => {
                state.getAdaptationProgressStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getAdaptationProgressAction.fulfilled, (state, { payload }) => {
                state.getAdaptationProgressStatus = FetchStatus.FETCHED;
                state.adaptationProgressStudents = payload;
                state.error = null;
            })
            .addCase(getAdaptationProgressAction.rejected, (state, { error }) => {
                state.getAdaptationProgressStatus = FetchStatus.ERROR;
                state.error = error;
            })
            .addCase(changeAdaptationAction.fulfilled, (state, action) => {
                state.adaptationProgressStudents = state.adaptationProgressStudents.map((adaptationProgress) => {
                    if (adaptationProgress.student.id === action.meta.arg.studentId) {
                        return {
                            ...adaptationProgress,
                            adaptationSkills: adaptationProgress.adaptationSkills?.map((skill) => {
                                const adaptation = action.payload.find((item) => skill.id === item.id);
                                if (adaptation) {
                                    return {
                                        ...adaptation,
                                        title: adaptation.adaptation,
                                    };
                                }

                                return skill;
                            }),
                        };
                    }

                    return adaptationProgress;
                });
            });
    },
});

export const adaptationProgressReducer = adaptationProgressSlice.reducer;

import { NotificationViewType } from '@lms-elements/atomic';
import { Notification } from 'api/services/notifications';
import { NewNotificationWithExtraInfo, ViewedNotificationWithExtraInfo } from 'types/notifications';

import { normalizeNotification } from './normalizeNotification';

export const normalizeNewNotifications = (notifications: Notification[]): NewNotificationWithExtraInfo[] => {
    const baseNormalizedNotifications: ViewedNotificationWithExtraInfo[] = [];

    notifications.forEach((notification) => {
        const normalizedNotification = normalizeNotification(notification);
        if (normalizedNotification) {
            baseNormalizedNotifications.push({
                ...normalizedNotification,
                lessonId: notification.data.lesson,
                subjectId: notification.data.subject.id,
                courseGroupId: notification.data.courseGroup?.id,
                type: notification.type,
            });
        }
    });

    return baseNormalizedNotifications.map((notification) => ({
        ...notification,
        makeViewedOn: NotificationViewType.click,
    }));
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { createStudentProfile, fetchStudentData, PostData } from 'api/services/student';

export const getStudentDataAction = createAsyncThunk(
    'student/getStudentData',
    async (studentId: number) => await fetchStudentData(studentId),
);

export const postStudentProfileData = createAsyncThunk('student/addStudentProfileData', async (params: PostData) => {
    return await createStudentProfile(params);
});

import { codifierPageData } from 'constants/cypress/codifier-page';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Checkbox } from '@lms-elements/atomic';
import { GetMore } from '@lms-elements/icons';
import { useOuterClickField } from 'hooks/useOuterClickField';
import i18next from 'i18next';

import { CodifierEventType } from 'components/Codifier/Codifier';
import { DropdownActionsFormValues, DropdownWithActions } from 'components/DropdownWithActions';
import { ThemeTooltip } from 'components/ThemeTooltip';

import './ThemeCodifier.scss';

const CnThemeCodifier = cn('themeCodifier');

interface stateMetaArea {
    subjectId: number;
    gradeId: number;
    metaAreaId: number;
}
interface stateSkill {
    subjectId: number;
    gradeId: number;
    metaAreaId: number;
    skillId: number;
}

interface IThemeCodifierProps {
    id?: number;
    subjectId?: number;
    type: CodifierEventType;
    isThemeSubject: boolean;
    title: string;
    numQuestions: number;
    numQuestionsUnpublished: number;
    isSelectedInit: boolean;
    isCheckedInit?: boolean;
    themeNumber: number;
    codifierValue?: string;
    allCodifierValues?: (string | undefined)[];
    onCreateClick?: (id: number, alues: Record<string, DropdownActionsFormValues>) => void;
    onClickAdd?: (id: number) => () => void;
    handleCopyClick?: () => void;
    handleCreateQuestion?: (id: number, subject?: number) => void;
    onDeleteClick?: (type: CodifierEventType, id: number) => void;
    onEditClick?: (
        id: number,
        values: Record<string, DropdownActionsFormValues>,
        parallel: number,
        block: number,
        section?: number,
    ) => void;
    onReplaceClick?: (type: CodifierEventType, id: number) => void;
    handleSelectThemeLesson?: (type: CodifierEventType, id: number) => void;
    handleSelectCheckbox?: (id: number) => void;
    onClickMore?: () => void;
    isClickMore?: boolean;
    isEditable?: boolean;
    hasCheckbox?: boolean;
    skillsTheme?: boolean;
    numSkill?: number;
    isChild?: boolean;
    setSelectedMetaArea?: ({ subjectId, gradeId, metaAreaId }: stateMetaArea) => void;
    selectedMetaArea?: { subjectId: number; gradeId: number; metaAreaId: number };
    subjectIndex?: number;
    parallelIndex?: number;
    metaAreaIndex?: number;
    setSelectedSkill?: ({ subjectId, gradeId, metaAreaId, skillId }: stateSkill) => void;
    selectedSkill?: { subjectId: number; gradeId: number; metaAreaId: number; skillId: number };
    isOpenedInit?: boolean;
    canHover?: boolean;
    handleCanHover?: () => void;
    themeIndex: number;
    blockIndex?: number;
    canDelete?: boolean;
    canReplace?: boolean;
    needOrder?: boolean;
    parallel?: number;
    section?: number;
    block?: number;
    needDisableOrder?: boolean;
}
const test = false;
export const ThemeCodifier: React.FC<IThemeCodifierProps> = ({
    type,
    isThemeSubject,
    title,
    numQuestions,
    numQuestionsUnpublished,
    isSelectedInit,
    isCheckedInit = false,
    themeNumber,
    id = -1,
    isEditable = true,
    codifierValue,
    allCodifierValues = [],
    handleCreateQuestion,
    handleCopyClick,
    onCreateClick,
    onDeleteClick,
    onReplaceClick,
    handleSelectThemeLesson,
    handleSelectCheckbox,
    onClickAdd = () => undefined,
    isClickMore = false,
    hasCheckbox = false,
    skillsTheme = false,
    onEditClick,
    numSkill = 1,
    children,
    isChild = false,
    setSelectedMetaArea = () => undefined,
    selectedMetaArea = { subjectId: 0, gradeId: 0, metaAreaId: 0 },
    subjectIndex = 0,
    parallelIndex = 0,
    metaAreaIndex = 0,
    setSelectedSkill = () => undefined,
    isOpenedInit = false,
    canHover = true,
    handleCanHover,
    subjectId,
    themeIndex,
    needOrder = false,
    blockIndex,
    canDelete = true,
    canReplace = true,
    parallel,
    section,
    block,
    needDisableOrder = false,
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isCreated, setIsCreated] = useState(false);
    const [isSelected, setIsSelected] = useState(isSelectedInit);
    const [isChecked, setIsChecked] = useState(isCheckedInit);
    const [showOptions, setShowOptions] = useState(false);
    const [isOpened, setIsOpened] = useState(isOpenedInit);
    const [isEditedForm, setIsEditedForm] = useState(false);

    const tooltipRef = useRef(null);
    const hideTooltip = useCallback(() => {
        setShowOptions(false);
        setIsHovered(false);
        if (handleCanHover) {
            handleCanHover();
        }
    }, [handleCanHover]);
    useOuterClickField(tooltipRef, hideTooltip);

    const toggleHover = useCallback(
        (value) => {
            if (canHover) {
                setIsHovered(value);
            }
        },
        [canHover],
    );

    const clickMore = useMemo(() => isClickMore && isSelectedInit, [isClickMore, isSelectedInit]);

    const oneSelectedArea = useMemo(
        () =>
            JSON.stringify(selectedMetaArea) ===
                JSON.stringify({ subjectId: subjectIndex, gradeId: parallelIndex, metaAreaId: metaAreaIndex }) &&
            isOpened,
        [parallelIndex, subjectIndex, metaAreaIndex, selectedMetaArea, isOpened],
    );

    const handleEditButtonClick = useCallback(() => {
        if (handleCanHover) {
            handleCanHover();
        }
        if (!isSelected) {
            setIsSelected(true);
        }
        setShowOptions(!showOptions);
        setSelectedMetaArea({ subjectId: subjectIndex, gradeId: parallelIndex, metaAreaId: metaAreaIndex });
        if (isChild) {
            setSelectedSkill({
                subjectId: subjectIndex,
                gradeId: parallelIndex,
                metaAreaId: metaAreaIndex,
                skillId: themeNumber - 1,
            });
        }
    }, [
        handleCanHover,
        isSelected,
        showOptions,
        setSelectedMetaArea,
        subjectIndex,
        parallelIndex,
        metaAreaIndex,
        isChild,
        themeNumber,
        setSelectedSkill,
    ]);

    useEffect(() => {
        if (!oneSelectedArea && isOpened) {
            setIsOpened(false);
        }
    }, [oneSelectedArea, isOpened]);

    const handleSelectLesson = useCallback(() => {
        if (handleSelectThemeLesson) {
            handleSelectThemeLesson(type, id);
        }
    }, [handleSelectThemeLesson, id, type]);

    const handleChangeCheckbox = useCallback(() => {
        if (handleSelectCheckbox) {
            handleSelectCheckbox(id);
            setIsChecked(!isChecked);
        }
    }, [handleSelectCheckbox, id, isChecked]);

    const handleCreateButtonClick = useCallback(() => {
        if (!showOptions) {
            setIsCreated(!isCreated);
            setShowOptions(false);
            setIsHovered(false);
            if (handleCanHover) {
                handleCanHover();
            }
            onClickAdd(id);
        }
    }, [isCreated, handleCanHover, showOptions, onClickAdd, id]);

    const handleAddSkill = useCallback(() => {
        setIsCreated(!isCreated);
        setShowOptions(!showOptions);
        setIsHovered(false);
    }, [isCreated, setIsCreated, showOptions]);

    const handleAddFormClick = useCallback(
        (values: Record<string, DropdownActionsFormValues>) => {
            if (onCreateClick) {
                onCreateClick(id, values);
            }
            setIsOpened(true);
        },
        [id, onCreateClick],
    );

    const handleEditFormSubmit = useCallback(
        (values: Record<string, DropdownActionsFormValues>) => {
            if (onEditClick) {
                onEditClick(id, values, Number(parallel), block ?? 0, needDisableOrder ? undefined : Number(section));
            }
        },
        [block, id, needDisableOrder, onEditClick, parallel, section],
    );

    const handleEditFormClick = useCallback(() => {
        setIsEditedForm((prev) => !prev);
        if (handleCanHover && !canHover && !showOptions) {
            handleCanHover();
        }
        setShowOptions(false);
        setIsHovered(false);
    }, [canHover, handleCanHover, showOptions]);

    const onClickOpenInit = useCallback(() => {
        if (!isChild) {
            if (!isSelected && isEditable) {
                setIsSelected(!isSelected);
            }
            setIsOpened(!isOpened);
            setShowOptions(false);
            setSelectedMetaArea({ subjectId: subjectIndex, gradeId: parallelIndex, metaAreaId: metaAreaIndex });
        }
    }, [isOpened, isSelected, subjectIndex, parallelIndex, metaAreaIndex, setSelectedMetaArea, isChild, isEditable]);

    const canSelect = useMemo(() => isEditedForm || isCreated || showOptions || isOpened, [
        isCreated,
        isEditedForm,
        isOpened,
        showOptions,
    ]);

    const titleText = useMemo(() => {
        if (skillsTheme) {
            return isChild ? `${numSkill}. ${title}` : `${title}`;
        }

        return `Тема ${themeNumber}: ${title}`;
    }, [isChild, numSkill, skillsTheme, themeNumber, title]);

    const skillsLabel = useMemo(() => {
        if (skillsTheme) {
            return numSkill ? `${i18next.t('skill', { count: numSkill })}` : 'нет навыков';
        }
        return numQuestions ? `${i18next.t('questions', { count: numQuestions })}` : 'нет вопросов';
    }, [numQuestions, numSkill, skillsTheme]);

    const unpublishedQuestions = useMemo(() => {
        if (!skillsTheme && numQuestionsUnpublished) {
            return `(${i18next.t('unpublished', { count: numQuestionsUnpublished })})`;
        }
        return '';
    }, [numQuestionsUnpublished, skillsTheme]);

    const handleTextClick = useMemo(() => {
        if (hasCheckbox) {
            return handleChangeCheckbox;
        }

        if (!numSkill) {
            return undefined;
        }

        return skillsTheme ? onClickOpenInit : handleSelectLesson;
    }, [handleChangeCheckbox, handleSelectLesson, hasCheckbox, numSkill, onClickOpenInit, skillsTheme]);

    const handleDeleteClick = useCallback(() => {
        if (onDeleteClick && id > 0) {
            onDeleteClick(type, id);
        }
    }, [id, onDeleteClick, type]);

    const handleReplaceClick = useCallback(() => {
        onReplaceClick?.(type, id);
    }, [id, onReplaceClick, type]);

    const skillCreateFormRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isCreated && skillsTheme && skillCreateFormRef.current) {
            skillCreateFormRef.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        }
    }, [isCreated, skillsTheme]);

    return (
        <>
            <div
                className={CnThemeCodifier({
                    isSelected: (canSelect && !hasCheckbox) || isSelectedInit,
                    isChild,
                    inactive: !numSkill,
                })}
                onMouseEnter={toggleHover.bind(null, true)}
                onMouseLeave={toggleHover.bind(null, false)}
                onClick={hasCheckbox ? undefined : handleSelectLesson}
                data-cy={codifierPageData.getThemeContainer(themeIndex, blockIndex)}
            >
                <div className={CnThemeCodifier('title')}>
                    {hasCheckbox && <Checkbox checked={isChecked} onChange={handleChangeCheckbox} />}
                    <div
                        className={CnThemeCodifier('text', {
                            isSelected: (canSelect && !hasCheckbox) || isSelectedInit,
                            skillsTheme,
                            isChild,
                            hasCheckbox,
                            inactive: !numSkill,
                        })}
                        onClick={handleTextClick}
                    >
                        {titleText}
                        {isChild && codifierValue && (
                            <span className={CnThemeCodifier('codifierValue')}> ({codifierValue})</span>
                        )}
                    </div>
                    <div className={CnThemeCodifier('icons')}>
                        {(isHovered || showOptions || test) && !hasCheckbox && isEditable && (
                            <div
                                className={CnThemeCodifier('icon', { isSelected, clickMore: clickMore })}
                                onClick={handleEditButtonClick}
                                data-cy={codifierPageData.getThemeContainerIcon(themeIndex, blockIndex)}
                            >
                                <GetMore />
                            </div>
                        )}
                        {showOptions && (
                            <div ref={tooltipRef}>
                                <ThemeTooltip
                                    isTheme={isThemeSubject}
                                    handleCreateQuestion={
                                        skillsTheme ? handleAddSkill : handleCreateQuestion?.bind(null, id, subjectId)
                                    }
                                    handleCopyClick={handleCopyClick}
                                    handleDeleteClick={handleDeleteClick}
                                    handleEditClick={handleEditFormClick}
                                    skillsTooltip={skillsTheme}
                                    isChild={isChild}
                                    canDelete={canDelete}
                                    handleReplaceClick={handleReplaceClick}
                                    canReplace={canReplace}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {!hasCheckbox && !isChild && (
                    <div className={CnThemeCodifier('questions-container')}>
                        <div className={CnThemeCodifier('questions', { inactive: !numSkill })}>{skillsLabel}</div>
                        <div className={CnThemeCodifier('questions', { inactive: true })}>{unpublishedQuestions}</div>
                    </div>
                )}
            </div>
            {isEditedForm && (
                <DropdownWithActions
                    name={!skillsTheme ? 'editLesson' : isChild ? 'editSkill' : 'editSection'}
                    placeholder={
                        isChild ? `${numSkill}. Навык: введите название` : `${themeNumber}. Навык: введите название`
                    }
                    onChangeAdd={handleEditFormSubmit}
                    initialValue={
                        !skillsTheme
                            ? { editLesson: { title: title, order: String(themeNumber) } }
                            : isChild
                            ? { editSkill: { title, codifierValue, order: String(themeNumber) } }
                            : { editSection: { title, order: String(themeNumber) } }
                    }
                    onClickCancel={handleEditFormClick}
                    textButtonAdd={'Изменить'}
                    needTextarea={isChild}
                    needCodifierValue={isChild}
                    isNeedTextField={type === CodifierEventType.TOPIC ? needOrder : !isChild}
                    allCodifierValues={allCodifierValues}
                    title={'Тема'}
                    noSkills={!skillsTheme}
                    needDisableOrder={needDisableOrder}
                />
            )}
            {(oneSelectedArea || isOpened || isOpenedInit) && children}
            {isCreated && skillsTheme && (
                <div ref={skillCreateFormRef}>
                    <DropdownWithActions
                        name={'createSection'}
                        placeholder={`${numSkill + 1}. Навык: введите название`}
                        onChangeAdd={handleAddFormClick}
                        onClickCancel={handleCreateButtonClick}
                        textButtonAdd={'Создать'}
                        needTextarea={true}
                        needCodifierValue
                        allCodifierValues={allCodifierValues}
                    />
                </div>
            )}
        </>
    );
};

import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { AuthFooter, AuthHeader, AuthLoginCard, AuthorizationLayout } from '@frontend-modules/elements';
import { LogoWithTextSvg } from 'assets';
import { useLoader } from 'hooks';
import { AuthBanner } from 'src-new/components/auth/AuthBanner';
import { fetchLogin, setIsLoggedInAction } from 'store/actions/auth';
import { resetAuthErrors } from 'store/reducers/auth';
import { setPasswordRecoveryEmail } from 'store/reducers/passwordRecovery';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';
import { TLogin } from 'types/auth';

/** */
export const Login: FC = () => {
    const router = useHistory();
    const dispatch = useDispatch();
    const { isLoggedIn, fetchStatus, error } = useAppSelector((state) => state.auth);

    const isLoading = fetchStatus === FetchStatus.FETCHING;

    const authUserAction = (credentials: TLogin) => {
        dispatch(fetchLogin(credentials));
    };

    const localSetPasswordRecoveryEmail = (email: string) => {
        dispatch(setPasswordRecoveryEmail(email));
    };

    const localResetIsLoggedIn = () => {
        dispatch(setIsLoggedInAction(false));
    };

    useLoader([]);

    useEffect(() => {
        return () => {
            void dispatch(resetAuthErrors({}));
        };
    }, []);

    if (isLoggedIn) {
        // @ts-ignore
        return <Redirect to={'/'} />;
    }

    return (
        <AuthorizationLayout banner={<AuthBanner />}>
            <AuthHeader logoWithText={<LogoWithTextSvg />} />
            <AuthLoginCard
                router={router}
                isLoggedIn={!!isLoggedIn}
                isLoading={isLoading}
                authUserError={error ? { extra: { fields: { non_field_errors: 'Неверный логин или пароль' } } } : null}
                passwordRecoveryPath={'/restore'}
                authUserAction={authUserAction}
                setPasswordRecoveryEmail={localSetPasswordRecoveryEmail}
                resetIsLoggedIn={localResetIsLoggedIn}
            />
            <AuthFooter router={router} />
        </AuthorizationLayout>
    );
};

import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { TSubjectLesson, TSubjectsTopic, TTeacherProfile } from 'src-new/components/new-subjects';
import {
    changeAssignmentMarkAction,
    changeAssignmentMarksAction,
    createAssignmentAdditionalMarkAction,
} from 'store/actions/marks';
import * as NewSubjectService from 'store/actions/newSubjects';
import { FetchStatus } from 'types/api';
import {
    TCourseGroup,
    TCourseGroupsLesson,
    TGetCourseTreePayload,
    TGetLessonsStatisticPayload,
    TGetStudentsProgressListResponse,
    TPaginate,
    TSelectedCourse,
    TStudentItem,
} from 'types/newSubjectsTypes';

export interface NewSubjectsState {
    getCourseTreeStatus: FetchStatus;
    getTopicsTreeStatus: FetchStatus;
    getLessonsTreeStatus: FetchStatus;
    getTeacherInfoStatus: FetchStatus;
    getStudentsListStatus: FetchStatus;
    getStudentsProgressListStatus: FetchStatus;
    getCourseGroupsLessonsStatus: FetchStatus;
    handleLessonScheduleStatus: FetchStatus;
    setMaterialViewedStatus: FetchStatus;
    getTreeByURLStatus: FetchStatus;
    postStatus: FetchStatus;
    changeAssignmentMarksStatus: FetchStatus;
    courseTree: TGetCourseTreePayload | TCourseGroup[] | undefined;
    topicsTree: TSubjectsTopic[];
    lessonsTree: TSubjectLesson[];
    lessonsStatistic: TGetLessonsStatisticPayload;
    selectedCourse: TSelectedCourse;
    teacherInfo: TTeacherProfile;
    selectedTopicId: number | undefined;
    selectedLessonId: number;
    studentsList: TStudentItem[];
    studentsListPaginate: TPaginate;
    courseGroupLessonsList: TCourseGroupsLesson[];
    courseGroupLessonsListPaginate: TPaginate;
    studentsProgressList: TGetStudentsProgressListResponse[];
    error: unknown;
    handleLessonScheduleError: unknown;
}
export const initialState: NewSubjectsState = {
    getCourseTreeStatus: FetchStatus.INITIAL,
    getTopicsTreeStatus: FetchStatus.INITIAL,
    getLessonsTreeStatus: FetchStatus.INITIAL,
    getTeacherInfoStatus: FetchStatus.INITIAL,
    getStudentsProgressListStatus: FetchStatus.INITIAL,
    setMaterialViewedStatus: FetchStatus.INITIAL,
    getCourseGroupsLessonsStatus: FetchStatus.INITIAL,
    postStatus: FetchStatus.INITIAL,
    getTreeByURLStatus: FetchStatus.INITIAL,
    getStudentsListStatus: FetchStatus.INITIAL,
    handleLessonScheduleStatus: FetchStatus.INITIAL,
    changeAssignmentMarksStatus: FetchStatus.INITIAL,
    lessonsStatistic: {} as TGetLessonsStatisticPayload,
    courseTree: undefined,
    topicsTree: [],
    lessonsTree: [],
    selectedCourse: {} as TSelectedCourse,
    selectedTopicId: 0,
    selectedLessonId: 0,
    teacherInfo: {} as TTeacherProfile,
    studentsList: [],
    studentsProgressList: [],
    studentsListPaginate: {} as TPaginate,
    courseGroupLessonsList: [],
    courseGroupLessonsListPaginate: {} as TPaginate,
    error: null,
    handleLessonScheduleError: null,
};

const NewSubjectsSlice = createSlice<NewSubjectsState, SliceCaseReducers<NewSubjectsState>>({
    name: 'newSubjects',
    initialState,
    reducers: {
        reset: () => initialState,
        resetLessonHandlersErrors(state) {
            state.handleLessonScheduleError = null;
            state.handleLessonScheduleStatus = FetchStatus.INITIAL;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(NewSubjectService.resetTeacherInfoAction, (state, { payload }) => {
            state.teacherInfo = payload;
        });
        builder.addCase(NewSubjectService.setSelectedCourseAction, (state, { payload }) => {
            state.selectedCourse = payload;
            state.getLessonsTreeStatus = FetchStatus.INITIAL;
        });
        builder.addCase(NewSubjectService.setSelectedTopicIdAction, (state, { payload }) => {
            state.selectedTopicId = payload;
        });
        builder.addCase(NewSubjectService.setSelectedLessonIdAction, (state, { payload }) => {
            state.selectedLessonId = payload;
        });

        builder
            .addCase(NewSubjectService.getStudentsTreeByURLAction.pending, (state) => {
                state.getTreeByURLStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(NewSubjectService.getStudentsTreeByURLAction.fulfilled, (state, { payload }) => {
                const { courseTree, topicTree, lessonTree, selectedCourse, selectedTopicId } = payload;

                state.getTreeByURLStatus = FetchStatus.FETCHED;
                state.getTopicsTreeStatus = FetchStatus.FETCHED;
                state.getLessonsTreeStatus = FetchStatus.FETCHED;

                state.courseTree = courseTree;
                state.topicsTree = topicTree;
                state.lessonsTree = lessonTree;
                state.selectedCourse = selectedCourse;
                state.selectedTopicId = selectedTopicId;

                state.error = null;
            })
            .addCase(NewSubjectService.getStudentsTreeByURLAction.rejected, (state, { error }) => {
                state.getTreeByURLStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(NewSubjectService.getTeachersTreeByURLAction.pending, (state) => {
                state.getTreeByURLStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(NewSubjectService.getTeachersTreeByURLAction.fulfilled, (state, { payload }) => {
                const { courseTree, topicTree, lessonTree, selectedCourse, selectedTopicId } = payload;

                state.getTreeByURLStatus = FetchStatus.FETCHED;
                state.getTopicsTreeStatus = FetchStatus.FETCHED;
                state.getLessonsTreeStatus = FetchStatus.FETCHED;

                state.courseTree = courseTree;
                state.topicsTree = topicTree;
                state.lessonsTree = lessonTree;
                state.selectedCourse = selectedCourse;
                state.selectedTopicId = selectedTopicId;

                state.error = null;
            })
            .addCase(NewSubjectService.getTeachersTreeByURLAction.rejected, (state, { error }) => {
                state.getTreeByURLStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(NewSubjectService.getStudentsCourseTreeAction.pending, (state) => {
                state.getCourseTreeStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(NewSubjectService.getStudentsCourseTreeAction.fulfilled, (state, { payload }) => {
                state.getCourseTreeStatus = FetchStatus.FETCHED;
                state.getTreeByURLStatus = FetchStatus.INITIAL;

                state.courseTree = payload.courseTree;
                state.selectedCourse = payload.selectedCourse;
                state.error = null;
            })
            .addCase(NewSubjectService.getStudentsCourseTreeAction.rejected, (state, { error }) => {
                state.getCourseTreeStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(NewSubjectService.getTeachersCourseTreeAction.pending, (state) => {
                state.getCourseTreeStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(NewSubjectService.getTeachersCourseTreeAction.fulfilled, (state, { payload }) => {
                state.getCourseTreeStatus = FetchStatus.FETCHED;
                state.getTreeByURLStatus = FetchStatus.INITIAL;

                state.courseTree = payload.courseTree;
                state.selectedCourse = payload.selectedCourse;
                state.error = null;
            })
            .addCase(NewSubjectService.getTeachersCourseTreeAction.rejected, (state, { error }) => {
                state.getCourseTreeStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(changeAssignmentMarksAction.pending, (state) => {
                state.changeAssignmentMarksStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(changeAssignmentMarksAction.fulfilled, (state) => {
                state.changeAssignmentMarksStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(changeAssignmentMarksAction.rejected, (state, { error }) => {
                state.changeAssignmentMarksStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(changeAssignmentMarkAction.pending, (state) => {
                state.changeAssignmentMarksStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(changeAssignmentMarkAction.fulfilled, (state) => {
                state.changeAssignmentMarksStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(changeAssignmentMarkAction.rejected, (state, { error }) => {
                state.changeAssignmentMarksStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(createAssignmentAdditionalMarkAction.pending, (state) => {
                state.changeAssignmentMarksStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(createAssignmentAdditionalMarkAction.fulfilled, (state) => {
                state.changeAssignmentMarksStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(createAssignmentAdditionalMarkAction.rejected, (state, { error }) => {
                state.changeAssignmentMarksStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(NewSubjectService.getLessonsStatisticAction.pending, (state) => {
                state.error = null;
            })
            .addCase(NewSubjectService.getLessonsStatisticAction.fulfilled, (state, { payload }) => {
                state.lessonsStatistic = payload;
                state.error = null;
            })
            .addCase(NewSubjectService.getLessonsStatisticAction.rejected, (state, { error }) => {
                state.error = error;
            });
        builder
            .addCase(NewSubjectService.setLearningMaterialViewedAction.pending, (state) => {
                state.setMaterialViewedStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(NewSubjectService.setLearningMaterialViewedAction.fulfilled, (state) => {
                state.setMaterialViewedStatus = FetchStatus.FETCHED;

                state.error = null;
            })
            .addCase(NewSubjectService.setLearningMaterialViewedAction.rejected, (state, { error }) => {
                state.setMaterialViewedStatus = FetchStatus.ERROR;
                state.error = error;
            });

        builder
            .addCase(NewSubjectService.getTreeByFirstItemsAction.pending, (state) => {
                state.getTopicsTreeStatus = FetchStatus.FETCHING;
                state.getLessonsTreeStatus = FetchStatus.FETCHING;

                state.error = null;
            })
            .addCase(NewSubjectService.getTreeByFirstItemsAction.fulfilled, (state, { payload }) => {
                const { topicTree, lessonTree, selectedTopicId } = payload;

                state.getTopicsTreeStatus = FetchStatus.FETCHED;
                state.getLessonsTreeStatus = FetchStatus.FETCHED;
                state.getTreeByURLStatus = FetchStatus.INITIAL;

                state.topicsTree = topicTree;
                state.lessonsTree = lessonTree;
                state.selectedTopicId = selectedTopicId;
                state.error = null;
            })
            .addCase(NewSubjectService.getTreeByFirstItemsAction.rejected, (state, { error }) => {
                state.getTopicsTreeStatus = FetchStatus.ERROR;
                state.getLessonsTreeStatus = FetchStatus.ERROR;

                state.error = error;
            });
        builder
            .addCase(NewSubjectService.getTreeByKnownItemsAction.pending, (state) => {
                state.getTopicsTreeStatus = FetchStatus.FETCHING;
                state.getLessonsTreeStatus = FetchStatus.FETCHING;

                state.error = null;
            })
            .addCase(NewSubjectService.getTreeByKnownItemsAction.fulfilled, (state, { payload }) => {
                const { topicTree, lessonTree, selectedTopicId } = payload;

                state.getTopicsTreeStatus = FetchStatus.FETCHED;
                state.getLessonsTreeStatus = FetchStatus.FETCHED;
                state.getTreeByURLStatus = FetchStatus.INITIAL;

                state.topicsTree = topicTree;
                state.lessonsTree = lessonTree;
                state.selectedTopicId = selectedTopicId;

                state.error = null;
            })
            .addCase(NewSubjectService.getTreeByKnownItemsAction.rejected, (state, { error }) => {
                state.getTopicsTreeStatus = FetchStatus.ERROR;
                state.getLessonsTreeStatus = FetchStatus.ERROR;
                state.error = error;
            });

        builder
            .addCase(NewSubjectService.getStudentsTreeByTimeItemsAction.pending, (state) => {
                state.getTopicsTreeStatus = FetchStatus.FETCHING;
                state.getLessonsTreeStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(NewSubjectService.getStudentsTreeByTimeItemsAction.fulfilled, (state, { payload }) => {
                const { topicTree, lessonTree, selectedTopicId } = payload;

                state.getTopicsTreeStatus = FetchStatus.FETCHED;
                state.getLessonsTreeStatus = FetchStatus.FETCHED;
                state.getTreeByURLStatus = FetchStatus.INITIAL;

                state.topicsTree = topicTree;
                state.lessonsTree = lessonTree;
                state.selectedTopicId = selectedTopicId;

                state.error = null;
            })
            .addCase(NewSubjectService.getStudentsTreeByTimeItemsAction.rejected, (state, { error }) => {
                state.getTopicsTreeStatus = FetchStatus.ERROR;
                state.getLessonsTreeStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(NewSubjectService.getTeachersTreeByTimeItemsAction.pending, (state) => {
                state.getTopicsTreeStatus = FetchStatus.FETCHING;
                state.getLessonsTreeStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(NewSubjectService.getTeachersTreeByTimeItemsAction.fulfilled, (state, { payload }) => {
                const { topicTree, lessonTree, selectedTopicId } = payload;

                state.getTopicsTreeStatus = FetchStatus.FETCHED;
                state.getLessonsTreeStatus = FetchStatus.FETCHED;
                state.getTreeByURLStatus = FetchStatus.INITIAL;

                state.topicsTree = topicTree;
                state.lessonsTree = lessonTree;
                state.selectedTopicId = selectedTopicId;

                state.error = null;
            })
            .addCase(NewSubjectService.getTeachersTreeByTimeItemsAction.rejected, (state, { error }) => {
                state.getTopicsTreeStatus = FetchStatus.ERROR;
                state.getLessonsTreeStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(NewSubjectService.getLessonsBySelectedCourseAndTopicTreeAction.pending, (state) => {
                state.getLessonsTreeStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(NewSubjectService.getLessonsBySelectedCourseAndTopicTreeAction.fulfilled, (state, { payload }) => {
                const { lessonsTree, topic } = payload;
                state.getLessonsTreeStatus = FetchStatus.FETCHED;
                state.getTreeByURLStatus = FetchStatus.INITIAL;
                state.lessonsTree = lessonsTree;
                state.selectedTopicId = topic;
                state.error = null;
            })
            .addCase(NewSubjectService.getLessonsBySelectedCourseAndTopicTreeAction.rejected, (state, { error }) => {
                state.getLessonsTreeStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(NewSubjectService.getTeacherProfileAction.pending, (state) => {
                state.getTeacherInfoStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(NewSubjectService.getTeacherProfileAction.fulfilled, (state, { payload }) => {
                state.getTeacherInfoStatus = FetchStatus.FETCHED;
                state.teacherInfo = payload;
                state.error = null;
            })
            .addCase(NewSubjectService.getTeacherProfileAction.rejected, (state, { error }) => {
                state.getTeacherInfoStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(NewSubjectService.getStudentsListAction.pending, (state) => {
                state.getStudentsListStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(NewSubjectService.getStudentsListAction.fulfilled, (state, { payload }) => {
                if (payload.pagination.currentPage > 1) {
                    state.studentsList.push(...payload.results);
                } else {
                    state.studentsList = payload.results;
                }
                state.studentsListPaginate = payload.pagination;
                state.error = null;
                state.getStudentsListStatus = FetchStatus.FETCHED;
            })
            .addCase(NewSubjectService.getStudentsListAction.rejected, (state, { error }) => {
                state.getStudentsListStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(NewSubjectService.getStudentsProgressListAction.pending, (state) => {
                state.getStudentsProgressListStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(NewSubjectService.getStudentsProgressListAction.fulfilled, (state, { payload }) => {
                state.studentsProgressList = payload;
                state.error = null;
                state.getStudentsProgressListStatus = FetchStatus.FETCHED;
            })
            .addCase(NewSubjectService.getStudentsProgressListAction.rejected, (state, { error }) => {
                state.getStudentsProgressListStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(NewSubjectService.getCourseGroupsLessonsAction.pending, (state) => {
                state.getCourseGroupsLessonsStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(NewSubjectService.getCourseGroupsLessonsAction.fulfilled, (state, { payload }) => {
                if (payload.pagination.currentPage > 1) {
                    state.courseGroupLessonsList.push(...payload.results);
                } else {
                    state.courseGroupLessonsList = payload.results;
                }
                state.courseGroupLessonsListPaginate = payload.pagination;
                state.error = null;
                state.getCourseGroupsLessonsStatus = FetchStatus.FETCHED;
            })
            .addCase(NewSubjectService.getCourseGroupsLessonsAction.rejected, (state, { error }) => {
                state.getCourseGroupsLessonsStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(NewSubjectService.handleLessonScheduleAction.pending, (state) => {
                state.handleLessonScheduleStatus = FetchStatus.FETCHING;
                state.handleLessonScheduleError = null;
            })
            .addCase(NewSubjectService.handleLessonScheduleAction.fulfilled, (state, { payload }) => {
                state.handleLessonScheduleError = null;
                state.handleLessonScheduleStatus = FetchStatus.FETCHED;
            })
            .addCase(NewSubjectService.handleLessonScheduleAction.rejected, (state, { error }) => {
                state.handleLessonScheduleStatus = FetchStatus.ERROR;
                state.handleLessonScheduleError = error;
            });
    },
});
export const resetNewSubjectsState = NewSubjectsSlice.actions.reset as ActionCreatorWithoutPayload;
export const resetLessonHandlersErrors = NewSubjectsSlice.actions
    .resetLessonHandlersErrors as ActionCreatorWithoutPayload;

export const NewSubjectsReducer = NewSubjectsSlice.reducer;

import { getApiRequest } from 'api';

export interface TariffsVacancies {
    id: number;
    title: string;
    vacanciesCount: number;
}

export interface StudentGroupStatisticsTariff {
    id: number;
    title: string;
    titleShort: string;
    tariffs: TariffsVacancies[];
}

export const getStudentGroupStatisticsTariffs = (): Promise<StudentGroupStatisticsTariff[]> => {
    return getApiRequest('/student-group/statistics-tariffs/');
};

import { AssignedHomeWork, AssignedTasks, AssignmentProgress, Task } from 'types/homework';
import { Student } from 'types/students';

export const getAssignedHomeworks = (
    students: Student[],
    tasks: Task[],
    assignmentProgress?: AssignmentProgress[],
): AssignedHomeWork[] => {
    return students.map((student) => {
        const studentHomeworks = assignmentProgress?.filter((task) => task.studentId === student.id) || [];
        const studentTasks = tasks.map((task) => {
            const assignedTasks = studentHomeworks
                .reduce((assignedTask, homework) => {
                    if (homework.taskId === task.id) assignedTask.push(homework);
                    return assignedTask;
                }, [] as AssignedTasks[])
                .sort((a, b) => a.id - b.id);
            if (assignedTasks.length > 0) {
                return assignedTasks;
            } else {
                return null;
            }
        });
        return {
            studentId: student.id,
            tasks: studentTasks || [],
            status: student.status,
            lateDuration: student.lateDuration,
            attendanceId: student?.attendanceId,
        };
    });
};

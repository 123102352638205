import React, { useState } from 'react';
import { Field, FieldInputProps } from 'react-final-form';
import { AppButton, AppText } from '@frontend-modules/ui-kit';
import { TimePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { E_COLORS } from 'src-new/constants';
import { AppTimePicker } from 'src-new/ui';

import { ITaskTimePickerProps } from './TaskTimePicker.types';

import './style.scss';
const timeFormat = 'HH:mm';

/**
 *
 * @param props
 * @constructor
 */
export const TaskTimePicker: React.FC<ITaskTimePickerProps> = (props: ITaskTimePickerProps) => {
    const { value, fieldName, placeholder, status, nullTimeText, valuePrefix = 'Через' } = props;
    const [open, setOpen] = useState(false);

    const handleTimeChange = (input: FieldInputProps<string, HTMLElement>, value: Dayjs) => {
        const formattedTime = value
            .format(timeFormat)
            .split(':')
            .map((item) => item.replace(/^0+/, ''));
        const minutes = formattedTime[1] ? +formattedTime[1] : 0;
        const hours = formattedTime[0] ? +formattedTime[0] : 0;
        input.onChange(hours * 60 + minutes);
    };

    const customFormat: TimePickerProps['format'] = (value) => {
        const formattedTime = value
            .format(timeFormat)
            .split(':')
            .map((item) => item.replace(/^0+/, ''));

        const minutes = formattedTime[1] ? `${formattedTime[1]} мин` : '';
        const hours = formattedTime[0] ? `${formattedTime[0]} ч` : '';
        const text = minutes || hours ? `${valuePrefix} ${hours} ${minutes}` : nullTimeText ?? '00:00';

        return text.trim();
    };

    const time = (): Dayjs | undefined => {
        if (value !== undefined) {
            const onlyMinutes = value < 60;

            const hours = onlyMinutes ? 0 : Math.round(value / 60);
            const minutes = onlyMinutes ? value : value - hours * 60;

            return dayjs().hour(hours).minute(minutes);
        } else {
            return undefined;
        }
    };

    return (
        <Field name={fieldName}>
            {({ input }) => (
                // @ts-ignore
                <AppTimePicker
                    showTime={{ format: 'HH:mm' }}
                    format={customFormat}
                    open={open}
                    onOpenChange={setOpen}
                    value={time()}
                    placeholder={placeholder}
                    minuteStep={1}
                    showNow={false}
                    status={status}
                    allowClear={false}
                    renderExtraFooter={() => (
                        <>
                            <div className="timePickerHeader">
                                <div className="timePickerHeader__item">
                                    <AppText
                                        text={'Ч'}
                                        textStyle={'DesktopFontBody'}
                                        color={E_COLORS.light_grey_color_2}
                                    />
                                </div>
                                <div className="timePickerHeader__item">
                                    <AppText
                                        text={'Мин'}
                                        textStyle={'DesktopFontBody'}
                                        color={E_COLORS.light_grey_color_2}
                                    />
                                </div>
                            </div>
                            <div className="timePickerFooter">
                                <AppButton label={'Применить'} size={'small'} onClick={setOpen.bind(this, false)} />
                            </div>
                        </>
                    )}
                    onSelect={handleTimeChange.bind(null, input)}
                />
            )}
        </Field>
    );
};

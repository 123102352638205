import { AcademicYear } from 'api/services/academicYear';
import { IAppRadioData } from 'src-new/ui/toggle/app-radio/AppRadio.types';

const formatAcademicYears = (years: Array<AcademicYear>): Array<IAppRadioData> => {
    const formatYears = years?.map((item) => {
        return {
            label: item.title,
            value: item.id,
        };
    });
    return formatYears ?? [];
};

export { formatAcademicYears };

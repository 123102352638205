import { getApiRequest } from 'api';
import { Region } from 'types/reporting';

export interface StudentGroup {
    id: number;
    region: Region;
    studentsCount: string;
}

export interface Parallel {
    id: number;
    studentGroup: StudentGroup[];
}

export const getStudentGroupStatisticsParallels = (): Promise<Parallel[]> => {
    return getApiRequest('/student-group/statistics-parallels/');
};

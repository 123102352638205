import React from 'react';
import { ConfigProvider, Select } from 'antd';

import { TAppSelect } from './AppSelect.types';

const CnAppSelect = cn('app-select');

import { cn } from '@bem-react/classname';
import { ThemeConfig } from 'antd/lib/config-provider/context';
import { SmallArrowIcon } from 'assets';

import './AppSelect.scss';

const theme: ThemeConfig = {
    token: {
        colorPrimary: '#0088E9',
        colorError: '#EA5959',
        colorBorder: '#EEF4FB',
    },
};

export const AppSelect: React.FC<TAppSelect> = (props: TAppSelect) => {
    const { customClassName, options, placeholder, isBoldText, mode, handleSelect, handleMultipleSelect, ...rest } = {
        ...props,
    };

    const onChange = (val: any) => {
        if (handleSelect) {
            handleSelect(val);
        } else {
            handleMultipleSelect?.(val);
        }
    };

    return (
        <ConfigProvider theme={theme}>
            <Select
                getPopupContainer={(trigger) => trigger?.parentElement}
                prefixCls={CnAppSelect()}
                className={`${customClassName} ${CnAppSelect({ isBoldText })}`}
                mode={mode}
                placeholder={placeholder}
                onChange={onChange}
                options={options}
                labelInValue
                size={'large'}
                suffixIcon={<SmallArrowIcon />}
                notFoundContent={'Ничего не найдено'}
                {...rest}
            />
        </ConfigProvider>
    );
};

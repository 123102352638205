import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { changeAdaptationAction, getAdaptationAction } from 'store/actions/adaptation';
import { normalizeAdaptation } from 'store/normalizers/adaptation/normalizeAdaptation';
import { FetchStatus } from 'types/api';
import { CheckboxItem } from 'types/checkbox';

import { findAndReplace } from 'utils/findAndReplace';

export interface AdaptationState {
    adaptationCheckboxes: CheckboxItem[];
    getAdaptationStatus: FetchStatus;
    changeAdaptationStatus: FetchStatus;
    error: unknown;
}

export const initialState: AdaptationState = {
    adaptationCheckboxes: [],
    getAdaptationStatus: FetchStatus.INITIAL,
    changeAdaptationStatus: FetchStatus.INITIAL,
    error: null,
};

const adaptationSlice = createSlice<AdaptationState, SliceCaseReducers<AdaptationState>>({
    name: 'adaptation',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAdaptationAction.pending, (state) => {
                state.getAdaptationStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getAdaptationAction.fulfilled, (state, { payload }) => {
                state.adaptationCheckboxes = normalizeAdaptation(payload);
                state.getAdaptationStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(getAdaptationAction.rejected, (state, { error }) => {
                state.getAdaptationStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(changeAdaptationAction.pending, (state) => {
                state.changeAdaptationStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(changeAdaptationAction.fulfilled, (state, { payload }) => {
                state.adaptationCheckboxes = findAndReplace(
                    state.adaptationCheckboxes,
                    ({ id }, index) => id === payload[index].id,
                    normalizeAdaptation(payload),
                );
                state.changeAdaptationStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(changeAdaptationAction.rejected, (state, { error }) => {
                state.changeAdaptationStatus = FetchStatus.ERROR;
                state.error = error;
            });
    },
});

export const adaptationReducer = adaptationSlice.reducer;

import { getApiRequest, patchApiRequest, postApiRequest } from 'api';
import { TSubjectLesson, TSubjectsTopic, TTeacherProfile } from 'src-new/components/new-subjects';
import {
    TCourseGroup,
    TGetCourseGroupsLessonsProps,
    TGetCourseGroupsLessonsResponse,
    TGetCourseTreeParams,
    TGetCourseTreePayload,
    TGetLessonsStatisticPayload,
    TGetLessonsTreeParams,
    TGetStudentsListParams,
    TGetStudentsListResponse,
    TGetStudentsProgressListProps,
    TGetStudentsProgressListResponse,
    TGetTopicsTreeParams,
} from 'types/newSubjectsTypes';

export const getLessonsStatistic = (courseId: number): Promise<TGetLessonsStatisticPayload> => {
    return getApiRequest(`/course-groups/${courseId}/lessons-statistics/`);
};

export const getCourseTree = (params?: TGetCourseTreeParams): Promise<TGetCourseTreePayload | TCourseGroup[]> => {
    const { studentGroup, role = 'student' } = params ?? {};
    const payload = studentGroup ? { studentGroup } : undefined;

    return postApiRequest(`/${role}/courses/`, payload);
};

export const getTopicsBySelectedCourse = (params: TGetTopicsTreeParams): Promise<TSubjectsTopic[]> => {
    const { courseGroup, role = 'student' } = params ?? {};
    const payload = { courseGroup };

    return postApiRequest(`/${role}/topics/`, payload);
};

export const getLessonsBySelectedTopic = (params: TGetLessonsTreeParams): Promise<TSubjectLesson[]> => {
    const { courseGroup, topic, role = 'student' } = params ?? {};
    const payload = { courseGroup, topic };

    return postApiRequest(`/${role}/lessons/`, payload);
};

export const getTeacherProfile = (id: number): Promise<TTeacherProfile> => {
    return getApiRequest(`/staff-profile/${id}/`);
};
export const getStudentsList = (params: TGetStudentsListParams): Promise<TGetStudentsListResponse> => {
    const { courseGroup, currentPage = 1, limit = 10 } = params;
    return postApiRequest('/students/', { courseGroup, currentPage, limit });
};
export const setLearningMaterialViewed = (params: { exercises: number[] }): Promise<void> => {
    return patchApiRequest(`/learning-material/set-viewed/`, params);
};
export const getStudentsProgressList = (
    params: TGetStudentsProgressListProps,
): Promise<TGetStudentsProgressListResponse[]> => {
    return postApiRequest('/teacher/students-progress/', params);
};
export const getCourseGroupsLessons = (
    params: TGetCourseGroupsLessonsProps,
): Promise<TGetCourseGroupsLessonsResponse> => {
    const { courseGroup, lessonType = 'reserve', currentPage = 1, limit = 10 } = params;

    return postApiRequest('/course-groups/lessons/', { courseGroup, lessonType, currentPage, limit });
};

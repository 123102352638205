import React, { useEffect, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import moment from 'moment';

import './Timer.scss';

const CnTimer = cn('timer');

export interface TimerProps {
    deadline: string;
    timeToFinish?: number;
    onTimeIsLeft?: () => void;
}

const TEN_MINUTES = 600;
const RADIUS = 54;

export const Timer: React.FC<TimerProps> = ({ deadline, onTimeIsLeft, timeToFinish = TEN_MINUTES }) => {
    const initialTime = useRef(moment(deadline).diff(moment(new Date()).format(), 'second'));
    const [time, setTime] = useState(moment(deadline).diff(moment(new Date()).format(), 'second'));

    useEffect(() => {
        const timer = setInterval(() => {
            setTime(moment(deadline).diff(moment(new Date()).format(), 'second'));
        }, 1000);

        initialTime.current = moment(deadline).diff(moment(new Date()).format(), 'second');

        return () => {
            clearInterval(timer);
        };
    }, [deadline]);

    const timeIsLeft = time <= 0;

    useEffect(() => {
        if (timeIsLeft) {
            onTimeIsLeft?.();
        }
    }, [timeIsLeft, onTimeIsLeft]);

    const seconds = Math.floor(time % 60) >= 0 ? Math.floor(time % 60) : 0;
    const minutes = Math.floor(time / 60) >= 0 ? Math.floor(time / 60) : 0;

    const percent = ((timeToFinish - minutes * 60 - seconds) / timeToFinish) * 100;

    // Показываем таймер, если осталось еще время и это последние 10 минут либо время на выполнение задания
    return initialTime.current > 0 && time <= timeToFinish ? (
        <div className={CnTimer()}>
            <svg>
                <circle
                    className={CnTimer('circle')}
                    cx="60"
                    cy="60"
                    r={RADIUS}
                    strokeWidth={2}
                    fill="none"
                    stroke="#EEF4FB"
                />
                <circle
                    r={RADIUS}
                    cx="60"
                    cy="60"
                    strokeWidth={2}
                    fill="none"
                    stroke={seconds <= 59 && minutes === 0 ? 'red' : '#0088E9'}
                    strokeLinecap="round"
                    transform="rotate(-90 60 60)"
                    strokeDashoffset={`${(percent / 100) * 2 * Math.PI * RADIUS}`}
                    strokeDasharray={2 * Math.PI * RADIUS}
                />
            </svg>
            <div className={CnTimer('text')}>
                {minutes === 0 && seconds === 0 ? (
                    <div className={CnTimer('time', { isEnd: minutes === 0 && seconds === 0 })}>время вышло</div>
                ) : (
                    <div className={CnTimer('time')}>
                        {minutes >= 1 ? `${minutes} мин ` : ''} {minutes >= 1 && <br />} {seconds < 10 ? '0' : ''}
                        {seconds} сек
                    </div>
                )}
                {minutes === 0 && seconds === 0 ? '' : 'осталось'}
            </div>
        </div>
    ) : null;
};

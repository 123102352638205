import { AcademicYear } from 'api/services/academicYear';
import { AssignmentMark } from 'api/services/assignment/getAssignmentMarks';
import { AttendanceResponseData } from 'api/services/attendance';
import { AttendanceMark } from 'api/services/attendance/getAttendanceMarks';
import { Absence } from 'api/services/attendance/getLessonAbsence';
import { Period } from 'api/services/courseGroups/getPeriods';
import { PeriodMark } from 'api/services/periodMark';
import { PeriodMarkFinal } from 'api/services/periodMark/getPeriodMarkFinal';
import { UserInfo } from 'api/services/user';
import moment from 'moment';
import { IOptions } from 'pages/ProctoringPage/types';
import {
    ICourseLesson,
    ICourseQuarter,
    ICourseStructure,
    ICourseTopic,
    IMark,
    IQuarterMark,
    IStatus,
    SubjectType,
} from 'src-new/components/lms-elements/PerformanceTable/types';
import { PeriodTopics } from 'store/actions/courseGroup';
import { FetchStatus } from 'types/api';
import { CourseGroup } from 'types/courseGroup';
import { Student } from 'types/students';
import { SubjectCourse } from 'types/subject';

import { Course } from 'utils/academicPerformance';

type SubjectsMap = {
    [subjectId: number]: {
        id: number;
        title: string;
        courses: SubjectCourse[];
        status: FetchStatus;
    };
};

interface YearOptions {
    id: number;
    title: string;
}

export interface AcademicStudent {
    id: number;
    firstName: string;
    lastName: string;
    photoUrl: string;
    school: string;
    enrollDate?: string;
    dismissDate?: string;
}

export interface Teacher {
    id: number;
    firstName: string;
    lastName: string;
    patronymic: string;
    photoUrl?: string;
    mainRole: string;
}

export const normalizeCourseGroups = (courseGroups: CourseGroup[]): Course[] => {
    return courseGroups.map((group) => ({
        id: group.id,
        courseId: group.course.id,
        groupId: group.id,
        title: group.course ? `${group?.course.title}, ${group.title}` : `${group.title}`,
        type: SubjectType.COMMON,
    }));
};

export const getTeachersList = (courseGroups: CourseGroup): Teacher[] => {
    return courseGroups.teachers.map((group) => ({
        id: group.id,
        firstName: group.firstName,
        lastName: group.lastName,
        patronymic: group.patronymic,
        photoUrl: group.photoUrl,
        mainRole: group.mainRole,
    }));
};

export const normalizeStudentProfile = (item: UserInfo): AcademicStudent[] => {
    const student = {
        id: item.id,
        firstName: item.firstName,
        lastName: item.lastName,
        photoUrl: item.photoUrl,
        school: 'Без прикрепления',
    };
    return Array.of(student);
};

export const normalizeStudents = (dateStart?: string, dateEnd?: string, students?: Student[]): AcademicStudent[] => {
    return (
        students?.map((el) => {
            const isExpulDatesNotEqual =
                el.expulsionDate && dateEnd && moment(el.expulsionDate).format('L') !== moment(dateEnd).format('L');
            // const isEnrolDatesNotEqual =
            //     el.enrolmentDate && dateStart && moment(el.enrolmentDate).format('L') !== moment(dateStart).format('L');
            return {
                ...el,
                school: el.school?.title ? el.school.title : 'Без прикрепления',
                enrollDate: el.enrolmentDate ? moment(el.enrolmentDate).format('L') : undefined,
                dismissDate: isExpulDatesNotEqual ? moment(el.expulsionDate).format('L') : undefined,
            };
        }) ?? []
    );
};

export const normalizeSubjects = (teacher: SubjectsMap): YearOptions[] => {
    return Object.values(teacher)
        .map((teach) => ({
            id: teach.id,
            title: teach.title,
        }))
        .sort((a, b) => (a.title > b.title ? 1 : -1));
};

export const checkMarkIsBetween = (start?: Date | string, end?: Date | string, markDate?: Date | string): boolean => {
    return markDate ? moment(markDate).isBetween(start, end, 'day', '[]') : false;
};
export const checkIsNearestPeriod = (start?: Date | string, end?: Date | string, markDate?: Date | string): boolean => {
    return markDate ? moment(markDate).isSameOrBefore(start, 'month') : false;
};

export const normalizeYearState = (id: number, title?: string): YearOptions => {
    return { id: id, title: title ? `${title}` : '' };
};

export const normalizeYear = (academicYear: AcademicYear[]): YearOptions[] => {
    return academicYear.map((year) => ({
        id: year.id,
        title: year.title,
    }));
};

export const getCourseStructure = (subject: number, periodTopics: PeriodTopics[]): ICourseStructure[] => {
    const returnArray: ICourseStructure[] = [];

    const value: ICourseStructure = {
        subject: subject,
        quarters: Array<ICourseQuarter>(),
    };
    for (let i = 0; i < periodTopics.length; i++) {
        const quarter = {
            quarter: i + 1,
            topics: Array<ICourseTopic>(),
        };
        for (let j = 0; j < periodTopics[i].topics.length; j++) {
            const topic = {
                id: periodTopics[i].topics[j].id,
                title: periodTopics[i].topics[j].title ? `${j + 1}. ${periodTopics[i].topics[j].title}` : '',
                lessons: Array<ICourseLesson>(),
            };
            let coun = 1;
            for (let k = periodTopics[i].topics[j].lesson.length; k > 0; k--) {
                topic.lessons.push({
                    id: periodTopics[i].topics[j].lesson[k - 1].id,
                    title: periodTopics[i].topics[j].lesson[k - 1].title
                        ? `${coun}. ${periodTopics[i].topics[j].lesson[k - 1].title}`
                        : '',
                });
                coun++;
            }
            quarter.topics.push(topic);
        }
        value.quarters.push(quarter);
    }
    returnArray.push(value);

    return returnArray;
};

export const getMarksAndStats = (
    attendanceMarks: AttendanceMark[],
    assignmentMarks: AssignmentMark[],
    attendanceResponseData: AttendanceResponseData[],
    course: ICourseStructure[],
    students: AcademicStudent[],
    currentQuarter: number,
): { marks: IMark[]; stats: IStatus[] } => {
    const marks: IMark[] = [];
    const stats: IStatus[] = [];

    course.forEach((subject) => {
        subject.quarters.forEach((quarter) => {
            const quarterForMark = quarter.quarter - 1;
            if (quarterForMark < 4 && quarterForMark + 1 === currentQuarter) {
                quarter.topics.forEach((topic) => {
                    topic.lessons.forEach((lesson) => {
                        students.forEach((student) => {
                            if (attendanceMarks.length !== 0 && lesson.id !== undefined) {
                                attendanceMarks.forEach((attendanceMark) => {
                                    if (
                                        attendanceMark.lesson.id === lesson.id &&
                                        attendanceMark.student === student.id
                                    ) {
                                        attendanceMark.marks.forEach((mark) => {
                                            marks.push({
                                                id: mark.id,
                                                subject: subject.subject,
                                                topic: topic.id,
                                                lesson: lesson.id,
                                                quarter: quarterForMark,
                                                comment: mark.comment,
                                                score: mark.score,
                                                student: student.id,
                                                weight: mark.weight,
                                                date: mark.date,
                                                needTrimComment: false,
                                                lessonType: 'attendance',
                                            });
                                        });
                                    }
                                });
                            }
                            if (assignmentMarks.length !== 0 && lesson.id !== undefined) {
                                const filteredAssignmentMarks = assignmentMarks.filter(
                                    (el) => el.lesson === lesson.id && el.student.id === student.id,
                                );
                                filteredAssignmentMarks
                                    .flatMap(({ teacherMark }) => teacherMark)
                                    .forEach((teacherMark) => {
                                        marks.push({
                                            id: teacherMark.id,
                                            subject: subject.subject,
                                            topic: topic.id,
                                            lesson: lesson.id,
                                            quarter: quarterForMark,
                                            comment: teacherMark.comment,
                                            score: teacherMark.score,
                                            student: student.id,
                                            weight: teacherMark.weight,
                                            date: teacherMark.date,
                                            needTrimComment: false,
                                            lessonType: 'assignment',
                                        });
                                    });
                                filteredAssignmentMarks.forEach((assignmentMarks) => {
                                    if (assignmentMarks.marks.some((mark) => mark.score !== null)) {
                                        assignmentMarks.marks.forEach((mark) => {
                                            if (mark.score !== null || mark.comment !== '') {
                                                marks.push({
                                                    id: assignmentMarks.id,
                                                    subject: subject.subject,
                                                    topic: topic.id,
                                                    lesson: lesson.id,
                                                    quarter: quarterForMark,
                                                    comment: mark.comment,
                                                    score: mark.score,
                                                    student: student.id,
                                                    weight: mark.weight,
                                                    date: mark.date,
                                                    needTrimComment: false,
                                                    lessonType: 'assignment',
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                            if (attendanceResponseData.length !== 0 && lesson.id !== undefined) {
                                attendanceResponseData.forEach((element) => {
                                    if (element.lesson === lesson.id && element.student.id === student.id) {
                                        stats.push({
                                            id: element.id,
                                            subject: subject.subject,
                                            topic: topic.id,
                                            lesson: lesson.id,
                                            quarter: quarterForMark,
                                            student: student.id,
                                            status: element.status,
                                        });
                                    }
                                });
                            }
                        });
                    });
                });
            }
        });
    });
    return { marks, stats };
};

const convertDate = (date: string) => {
    return moment(date).format('YYYY-MM-DD');
};

export const getStudentMarksAndStatuses = (
    attendanceMarks: AttendanceMark[],
    assignmentMarks: AssignmentMark[],
    lessonAbsence: Absence[],
    course: Course[],
    quarter: number,
    student: number,
): { marks: IMark[]; stats: IStatus[] } => {
    const marks: IMark[] = [];
    const stats: IStatus[] = [];

    course.forEach((subject) => {
        attendanceMarks.forEach((attendanceMark) => {
            if (attendanceMark.course === subject.courseId) {
                attendanceMark.marks.forEach((mark) => {
                    marks.push({
                        id: mark.id,
                        subject: subject.courseId,
                        topic: subject.courseId,
                        lesson: attendanceMark.lesson.id,
                        comment: mark.comment,
                        score: mark.score,
                        weight: mark.weight,
                        quarter: quarter,
                        student: student,
                        date: mark.date ? convertDate(mark.date) : '',
                        needTrimComment: false,
                        lessonType: 'attendance',
                    });
                });
            }
        });
        assignmentMarks.forEach((assignmentMark) => {
            if (
                assignmentMark.student.id === student &&
                assignmentMark.marks.some((mark) => mark.score !== null && assignmentMark.course === subject.courseId)
            ) {
                assignmentMark.marks.forEach((mark) => {
                    if (mark.score !== null || mark.comment !== '') {
                        marks.push({
                            id: mark.id,
                            subject: subject.courseId,
                            topic: subject.courseId,
                            lesson: assignmentMark.lesson,
                            quarter: quarter,
                            comment: mark.comment,
                            score: mark.score,
                            student: student,
                            weight: mark.weight,
                            date: mark.date ? convertDate(mark.date) : '',
                            needTrimComment: false,
                            lessonType: 'assignment',
                        });
                    }
                });
                assignmentMark.teacherMark.forEach((teacherMark) => {
                    marks.push({
                        id: teacherMark.id,
                        subject: subject.courseId,
                        topic: subject.courseId,
                        lesson: assignmentMark.lesson,
                        quarter: quarter,
                        comment: teacherMark.comment,
                        score: teacherMark.score,
                        student: student,
                        weight: teacherMark.weight,
                        date: teacherMark.date ? convertDate(teacherMark.date) : '',
                        needTrimComment: false,
                        lessonType: 'assignment',
                    });
                });
            }
        });
        lessonAbsence.forEach((status) => {
            if (status.course === subject.courseId) {
                stats.push({
                    id: status.id,
                    subject: subject.courseId,
                    topic: subject.courseId,
                    lesson: status.lesson,
                    quarter: quarter,
                    student: student,
                    status: status.status,
                    date: status.datetimeStart ? convertDate(status.datetimeStart) : '',
                });
            }
        });
    });

    return { marks, stats };
};

export const getMultipleSchoolsInputValue = (options: string[], values: { [key: string]: IOptions }): string => {
    const selectedValues = options.filter((_, index) => values[`${index}`]?.isSelected);

    if (selectedValues.length === options.length) {
        return 'Все школы';
    }

    const emptyPlaceholder = 'Выберите школу';

    if (selectedValues.length === 0) {
        return emptyPlaceholder;
    }

    return selectedValues.reduce((result, value, index, { length }) => {
        const valueWithoutIndex = value.split('--')[0];
        switch (true) {
            case index === 0:
                return valueWithoutIndex;
            case length === index + 1:
                return `${result}, ${valueWithoutIndex.toLowerCase()}`;
            default:
                return `${result}, ${valueWithoutIndex.toLowerCase()}`;
        }
    });
};

export const getQuarterMarks = (
    students: AcademicStudent[],
    mark: PeriodMark[],
    periods: Period[],
    courseGroupId: number,
    type: string,
): IQuarterMark[] => {
    const marks: IQuarterMark[] = [];

    periods.forEach((period) => {
        students.forEach((student) => {
            mark.forEach((element) => {
                if (element.student === student.id && element.period === period.id) {
                    marks.push({
                        id: element.id,
                        subject: courseGroupId,
                        quarter: periods.indexOf(period),
                        average: element.avgScore,
                        student: student.id,
                        score:
                            element.notCertified && element.score === null
                                ? -1
                                : type !== 'binary' && element.score !== null
                                ? element.score
                                : undefined,
                        isTest: type === 'binary' ? true : false,
                        testApproved: type === 'binary' && element.passed !== null ? element.passed : undefined,
                    });
                }
            });
        });
    });
    return marks;
};

export const getQuarterStudentMarks = (mark: PeriodMark[], periods: Period[], course: Course[]): IQuarterMark[] => {
    const marks: IQuarterMark[] = [];

    periods.forEach((period) => {
        course.forEach((course) => {
            mark.forEach((element) => {
                if (element.period === period.id && element.course === course.courseId) {
                    marks.push({
                        id: element.id,
                        subject: course.courseId,
                        quarter: periods.indexOf(period),
                        average: element.avgScore,
                        student: element.student,
                        score: element.notCertified
                            ? -1
                            : course.periodType !== 'binary' && element.score !== null
                            ? element.score
                            : undefined,
                        isTest: course.periodType === 'binary' ? true : false,
                        testApproved:
                            course.periodType === 'binary' && element.passed !== null ? element.passed : undefined,
                    });
                }
            });
        });
    });
    return marks;
};

export const getQuarterFinalMarks = (
    students: AcademicStudent[],
    mark: PeriodMarkFinal[],
    courseGroupId: number,
    periodsLength: number,
    type: string,
): IQuarterMark[] => {
    const marks: IQuarterMark[] = [];

    students.forEach((student) => {
        mark.forEach((element) => {
            if (element.student === student.id) {
                marks.push({
                    id: student.id,
                    subject: courseGroupId,
                    quarter: periodsLength,
                    average: element.finalScore,
                    student: student.id,
                    score:
                        type !== 'binary' && element.finalScore !== null
                            ? element.finalScore
                            : !element.finalNotCertified && type === 'binary' && !element.finalPassed
                            ? -1
                            : undefined,
                    isTest: type === 'binary',
                    testApproved: type === 'binary' ? element.finalPassed : undefined,
                });
            }
        });
    });
    return marks;
};

export const getQuarterStudentFinalMarks = (
    course: Course[],
    mark: PeriodMarkFinal[],
    periodsLength: number,
    student: number,
): IQuarterMark[] => {
    const marks: IQuarterMark[] = [];

    course.forEach((course) => {
        mark.forEach((element) => {
            if (element.course === course.courseId) {
                marks.push({
                    id: course.courseId,
                    subject: course.courseId,
                    quarter: periodsLength,
                    average: element.finalScore,
                    student: student,
                    score:
                        course.periodType !== 'binary' && element.finalScore !== null
                            ? element.finalScore
                            : element.finalNotCertified
                            ? -1
                            : undefined,
                    isTest: course.periodType === 'binary',
                    testApproved: course.periodType === 'binary' ? element.finalPassed : undefined,
                });
            }
        });
    });
    return marks;
};

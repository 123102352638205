import React, { useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';
import { PlusIcon, TrashBasketIcon } from 'assets';
import { AppInput } from 'src-new/ui';

import { IAppTableCell } from './AppTableCell.types';

import './AppTableCell.scss';

const CnAppTableCell = cn('app-table-cell');

export const AppTableCell: React.FC<IAppTableCell> = (props) => {
    const {
        id,
        type,
        title,
        subtitle,
        rowIndex,
        cellIndex,
        isActive,
        getActiveElementData,
        cellWidth,
        isFill,
        fillColor,
        isBoldTitle,
        noCanEmpty,
        isHover,
        isDeleteRow,
        isAddRow,
        isInput,
        customElem,
    } = props;
    const [text, setText] = useState('');
    const color = isFill ? (fillColor ? fillColor : '#F9FAFD') : '';
    const isCanHovered = isHover || isDeleteRow || isAddRow;
    const isCanClick = isActive || isDeleteRow || isAddRow;
    const isDate = type == 'date';
    const isInputNumber = type == 'inputNumber';
    const isEmptyDate = isDate && !title?.toString().length;
    const defaultCellTitle = isEmptyDate ? 'Добавить' : title;
    const isEmptyInput = (noCanEmpty && text == '') || text == undefined;

    const cellStyle = {
        minWidth: cellWidth,
        maxWidth: cellWidth,
        background: color,
    };

    const returnClickType = () => {
        switch (true) {
            case isAddRow:
                return 'addRow';
            default:
                return undefined;
        }
    };
    const returnActiveData = (clickType?: string, inputText?: string) => {
        const cellType = clickType ?? returnClickType();

        if (rowIndex !== undefined && cellIndex !== undefined) {
            const returnData = {
                row: rowIndex,
                cell: cellIndex,
                id: id,
                type: cellType ?? type,
                inputText: inputText ?? text,
                title: title,
            };
            getActiveElementData?.(returnData);
        }
    };

    const onCellClick = () => {
        if (isCanClick) {
            returnActiveData();
        }
    };

    const onRemoveClick = () => {
        if (isCanClick) {
            setText('');
            returnActiveData('deleteRow');
        }
    };

    const onEndEdit = () => {
        returnActiveData('inputText');
    };
    const onChange = (val: string) => {
        setText(val);
        returnActiveData('inputText', val);
    };

    const getAddRow = () => {
        return (
            <div className={CnAppTableCell('add-item-container')} onClick={onCellClick}>
                <div className={CnAppTableCell('title')} style={{ color: '#0088e9' }}>
                    {title}
                </div>
                <div className={CnAppTableCell('plus-icon')}>
                    <PlusIcon />
                </div>
            </div>
        );
    };

    const getDefaultElem = () => {
        return (
            <>
                <div
                    className={CnAppTableCell('text-container', { isDeleteRow, isError: isEmptyInput })}
                    onClick={onCellClick}
                >
                    {isInput ? (
                        <AppInput
                            isSmall
                            status={'error'}
                            bordered={false}
                            value={text ?? title}
                            onBlur={onEndEdit}
                            type={isInputNumber ? 'inputNumber' : undefined}
                            onChange={(e) => onChange(e.currentTarget.value)}
                        />
                    ) : (
                        <>
                            {defaultCellTitle && (
                                <div className={CnAppTableCell('title', { isBold: isBoldTitle, isEmptyDate })}>
                                    {defaultCellTitle}
                                </div>
                            )}
                            {subtitle && <div className={CnAppTableCell('subtitle')}>{subtitle}</div>}
                        </>
                    )}
                </div>
                {isDeleteRow && (
                    <div className={CnAppTableCell('delete-row-icon')} onClick={onRemoveClick}>
                        <TrashBasketIcon />
                    </div>
                )}
            </>
        );
    };

    const getElem = () => {
        switch (true) {
            case !!customElem:
                return customElem;
            case isAddRow:
                return getAddRow();
            default:
                return getDefaultElem();
        }
    };

    useEffect(() => {
        setText(title?.toString() ?? '');
    }, [title]);

    return (
        <div
            className={CnAppTableCell({ isFill, isCanHovered, isEmptyDate, isCanClick: isCanClick && !isInput })}
            style={cellStyle}
        >
            {getElem()}
        </div>
    );
};

const getCodifierPage = (): string => {
    return `cy-codifier-codifier-page`;
};

const getSubjectNameTitle = (index: number): string => {
    return `cy-codifier-subject-name-title-${index}`;
};

const getGradeContainer = (index: number): string => {
    return `cy-codifier-grade-container-${index}`;
};

const getSectionContainerHeader = (index: number): string => {
    return `cy-codifier-section-container-header-${index}`;
};

const getBlockContainerHeader = (index: number): string => {
    return `cy-codifier-block-container-header-${index}`;
};

const getThemeContainer = (theme: number, block?: number): string => {
    return `cy-codifier${block !== undefined ? `-block-${block}` : ''}-theme-container-${theme}`;
};

const getThemeContainerIcon = (theme: number, block?: number): string => {
    return `cy-codifier${block !== undefined ? `-block-${block}` : ''}-theme-container-icon-${theme}`;
};

export const codifierPageData = {
    getCodifierPage,
    selectedSubjectIndex: 0,
    getSubjectNameTitle,
    selectedGradeIndex: 2,
    getGradeContainer,
    selectedSectionIndex: 4,
    getSectionContainerHeader,
    selectedBlockIndex: 1,
    getBlockContainerHeader,
    selectedThemeIndex: 0,
    getThemeContainer,
    getThemeContainerIcon,
};

import { patchApiRequest } from 'api';

interface NewsLikesInfo {
    id: number;
    likesCount: number;
    like: boolean;
}

export const updateNews = (id: number, like: boolean): Promise<NewsLikesInfo> => {
    return patchApiRequest(`/news/update/${id}/`, { like });
};

import { postApiRequest } from 'api';
import { ContentMaterialTypeEnum } from 'types/materials';

export interface CreateNewsElement {
    news: number;
    content: string;
    html: string;
    typeOfContent: ContentMaterialTypeEnum;
}

export interface CreateNewsElementResponse {
    id: number;
    news: number;
    typeOfContent: ContentMaterialTypeEnum;
    content: string;
    html: string;
    order: number;
}

export const createNewsElements = (params: CreateNewsElement[]): Promise<CreateNewsElementResponse[]> => {
    return postApiRequest('/news-element/bulk-create/', params);
};

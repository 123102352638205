import React from 'react';
import { Plus } from '@lms-elements/icons';

import './AddOptionButton.scss';

interface IAddOptionButtonProps {
    text: string;
    onClick: () => void;
}

const AddOptionButton: React.FC<IAddOptionButtonProps> = ({ text, onClick }) => {
    return (
        <button type="button" className="add-option-buttton" onClick={onClick}>
            <Plus />
            <p>{text}</p>
        </button>
    );
};

export default AddOptionButton;

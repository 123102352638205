import React from 'react';
import { AppText } from 'src-new/ui';

export enum ETags {
    test_lesson = 'Контрольная работа',
    online_lesson = 'Онлайн-занятие',
    selfpreparation_lesson = 'Самоподготовка',
}
type TLessonTypeTagProps = {
    type: keyof typeof ETags;
};

import { useWindowResize } from 'hooks';

import './style.scss';

const LessonTypeTag: React.FC<TLessonTypeTagProps> = ({ type }) => {
    const isMobile = useWindowResize();

    return (
        <div className={`tag tag-${type}`}>
            <AppText text={isMobile ? ETags[type].split(' ')[0] : ETags[type]} fontStyle={'footnotes'} />
        </div>
    );
};
export default LessonTypeTag;

import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { AppFontIcon, AppText } from '@frontend-modules/ui-kit';
import { RoomCard } from '@lms-elements/atomic';
import { checkIsBeetween, decreaseDate } from '@lms-elements/utils';
import { emptyRecordPreview } from 'assets';
import { PageLoader } from 'containers/PageLoader';
import { useRole } from 'hooks';
import { FilesContainer } from 'src-new/components/new-subjects';
import { AppSwitch } from 'src-new/ui';
import { getDateWithTime } from 'src-new/utils';
import { patchMissedLessonAction } from 'store/actions/lessonSchedule';
import { getMeetingRecordingsAction, postMeetingRecordingAction } from 'store/actions/meeting';
import { joinRoomAction, rejoinRoomAction } from 'store/actions/proctoring';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';
import { EventTypes } from 'types/events';

import { getFiles } from './utils';

import './OnlineLessonTab.scss';

const CnOnlineLessonTab = cn('onlineLessonTab');

interface IOnlineLessonTabProps {
    lessonId: number;
    lessonScheduleId?: number;
    courseGroupId?: number;
    isOvered?: boolean;
    isStarted?: boolean;
    isTest?: boolean;
}

export const OnlineLessonTab: React.FC<IOnlineLessonTabProps> = ({
    lessonId,
    lessonScheduleId,
    courseGroupId,
    isOvered,
    isStarted,
    isTest,
}) => {
    const dispatch = useDispatch();
    const { isStudent } = useRole();
    const history = useHistory();

    const { fetchMeetingStatus, meeting, meetingFiles, fetchMeetingFilesStatus } = useAppSelector(
        (store) => store.meeting,
    );
    const { eventRooms, getEventRoomsStatus, changeEventRoomStatus } = useAppSelector((store) => store.proctoring);

    const { info } = useAppSelector((store) => store.user);

    const currentEventRoom = useMemo(() => eventRooms.find(({ isJoined }) => isJoined), [eventRooms]);

    const isLoading =
        fetchMeetingStatus === FetchStatus.FETCHING ||
        fetchMeetingFilesStatus === FetchStatus.FETCHING ||
        changeEventRoomStatus === FetchStatus.FETCHING ||
        getEventRoomsStatus === FetchStatus.FETCHING;

    useEffect(() => {
        if (isOvered && lessonScheduleId && !meeting[lessonScheduleId]) {
            dispatch(getMeetingRecordingsAction({ lessonSchedule: lessonScheduleId }));
        }
    }, [isOvered, dispatch, lessonScheduleId, meeting]);

    const selectedMeeting = useMemo(() => {
        if (lessonScheduleId) {
            return meeting[lessonScheduleId];
        }
    }, [lessonScheduleId, meeting]);

    const handleVideoCheckboxChange = useCallback(
        (isChecked: boolean, id: number) => {
            if (lessonScheduleId && id) {
                dispatch(
                    postMeetingRecordingAction({
                        lessonSchedule: lessonScheduleId,
                        id: id,
                        isPublished: isChecked,
                    }),
                );
            }
        },
        [lessonScheduleId, dispatch],
    );

    const files = useMemo(() => getFiles(meetingFiles), [meetingFiles]);

    const records = useMemo(() => {
        if (selectedMeeting) {
            return selectedMeeting.recordings;
        }
    }, [selectedMeeting]);

    const isEmpty = !records?.length && !files?.length && !eventRooms?.length && !lessonScheduleId;

    const handleJoinClick = useCallback(
        (id: number) => {
            dispatch(joinRoomAction({ newId: id, lesson: lessonId, studentId: info?.id, event_type: EventTypes.TEST }));
        },
        [dispatch, info?.id, lessonId],
    );

    const handleRecordClick = useCallback(
        (id: number) => () => {
            if (isStudent) dispatch(patchMissedLessonAction({ id: id, recordViewed: true }));
        },
        [dispatch, isStudent],
    );

    const handleRejoinClick = useCallback(
        (id: number) => {
            const leaveId = eventRooms.find((el) => el.students?.some((el) => el.id === info?.id))?.id;
            if (leaveId !== undefined) {
                dispatch(
                    rejoinRoomAction({
                        leaveId: leaveId,
                        newId: id,
                        studentId: info?.id,
                        lesson: lessonId,
                        event_type: EventTypes.TEST,
                    }),
                );
            }
        },
        [dispatch, eventRooms, info?.id, lessonId],
    );

    const handleRoomClick = useCallback(
        (id: number) => {
            history.push(`/proctoring/event-room/${id}`);
        },
        [history],
    );

    const needHideButton =
        !isStudent ||
        checkIsBeetween(
            decreaseDate({ date: currentEventRoom?.datetimeStart ?? '', amount: 10, unit: 'm' }),
            currentEventRoom?.datetimeEnd ?? '',
        );

    if (isLoading) {
        return <PageLoader showLoading={isLoading} />;
    }
    if (isStudent && isEmpty && !isLoading) {
        return (
            <div className={CnOnlineLessonTab('empty')}>
                {!isStarted ? (
                    <h2 className={CnOnlineLessonTab('empty-title')}>
                        {isTest && !lessonScheduleId ? 'Комнаты еще не созданы!' : 'Онлайн-занятие будет позже!'}
                    </h2>
                ) : (
                    <h2 className={CnOnlineLessonTab('empty-title')}>Запись онлайн занятия будет позже!</h2>
                )}
            </div>
        );
    }

    return (
        <div className={CnOnlineLessonTab({ isOvered, isStudent })}>
            {isTest && eventRooms.length !== 0 && (
                <>
                    <div className={CnOnlineLessonTab('roomsContainer')}>
                        <div className={CnOnlineLessonTab('rooms')}>
                            {eventRooms.map((el) => (
                                <RoomCard
                                    {...el}
                                    key={el.id}
                                    onJoinClick={el.isJoined ? undefined : handleJoinClick}
                                    onRejoinClick={handleRejoinClick}
                                    onCardClick={isStudent ? undefined : handleRoomClick}
                                    needHideButton={needHideButton}
                                    isStudent
                                />
                            ))}
                        </div>
                    </div>
                </>
            )}
            {isOvered && !!records?.length && fetchMeetingStatus === FetchStatus.FETCHED && (
                <div className={'onlineLessonTab__video-list'}>
                    {records?.map(({ id, link, duration, preview, isPublished, datetimeStart }, index) => {
                        return (
                            <div className={'onlineLessonTab__video-list_item'} key={id}>
                                <div className={'video-title'}>
                                    <AppText
                                        text={`Запись занятия ${datetimeStart ? getDateWithTime(datetimeStart) : ''}`}
                                        textStyle={'DesktopH3'}
                                    />
                                    {!isStudent && (
                                        <div className={'video-title__switcher'}>
                                            <AppText text={'Доступна'} textStyle={'DesktopFontBody'} />
                                            <AppSwitch
                                                withIcons
                                                defaultChecked={isPublished}
                                                disabled={!id}
                                                onChange={(state) => handleVideoCheckboxChange(state, id)}
                                            />
                                        </div>
                                    )}
                                </div>
                                <a
                                    className={'video-player'}
                                    key={link}
                                    href={link}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={handleRecordClick(lessonId)}
                                >
                                    <img
                                        className={'video-player__preview'}
                                        src={preview ?? emptyRecordPreview}
                                        alt="record"
                                    />
                                    <div className={'video-player__duration'}>
                                        <AppText text={duration} textStyle={'DesktopFootNotes'} color={'#ffffff'} />
                                    </div>
                                    <div className={'video-player__play'}>
                                        <AppFontIcon icon={'drops-right-s'} color={'#ffffff'} size={32} />
                                    </div>
                                </a>
                            </div>
                        );
                    })}
                </div>
            )}
            <FilesContainer
                lessonScheduleId={lessonScheduleId}
                lessonId={lessonId}
                isOvered={isOvered}
                isTest={isTest}
                courseGroupId={courseGroupId}
            />
        </div>
    );
};

import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { changeAssignmentAdditionalMarkAction, changeAssignmentMarksAction } from 'store/actions/marks';
import {
    deleteStudentTaskAction,
    fetchStudentHomeworksAction,
    getStudentTaskById,
    getStudentTaskPreview,
    getStudentTasksAction,
} from 'store/actions/studentTasks';
import { FetchStatus } from 'types/api';
import { AssignmentProgressPreview } from 'types/assignmentProgress';
import { IHomeworkItem } from 'types/homework';
import { LessonDetails } from 'types/lesson';
import { MarkScore } from 'types/mark';
import { StudentProfile } from 'types/students';

export interface StudentTasksState {
    fetchStatus: FetchStatus;
    studentTasks: IHomeworkItem[];
    studentData?: StudentProfile;
    lesson?: LessonDetails;
    taskFetchStatus: FetchStatus;
    task?: IHomeworkItem;
    taskPreviewStatus: FetchStatus;
    taskDeletingStatus: FetchStatus;
    taskPreview: AssignmentProgressPreview[];
    error: unknown;
}

export const initialState: StudentTasksState = {
    fetchStatus: FetchStatus.INITIAL,
    taskFetchStatus: FetchStatus.INITIAL,
    taskDeletingStatus: FetchStatus.INITIAL,
    studentTasks: [],
    taskPreview: [],
    taskPreviewStatus: FetchStatus.INITIAL,
    error: null,
};

const studentTasksSlice = createSlice<StudentTasksState, SliceCaseReducers<StudentTasksState>>({
    name: 'StudentTasks',
    initialState,
    reducers: {
        resetStudentTaskData() {
            return initialState;
        },
        resetStudentTask(state) {
            state.task = undefined;
        },
        resetStudentTaskStatus(state) {
            state.taskFetchStatus = FetchStatus.INITIAL;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getStudentTasksAction.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getStudentTasksAction.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.studentTasks = payload[0] || [];
            state.lesson = payload[1];
            state.studentData = payload[2];
            state.error = null;
        });
        builder.addCase(getStudentTasksAction.rejected, (state, { error }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(fetchStudentHomeworksAction.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(fetchStudentHomeworksAction.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.studentTasks = payload;
            state.error = null;
        });
        builder.addCase(fetchStudentHomeworksAction.rejected, (state, { error }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(getStudentTaskById.pending, (state) => {
            state.taskFetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getStudentTaskById.fulfilled, (state, { payload }) => {
            state.taskFetchStatus = FetchStatus.FETCHED;
            state.task = payload;
            state.studentTasks = state.studentTasks.map((task) => {
                if (task.id === payload.id) {
                    return payload;
                }

                return task;
            });
            state.error = null;
        });
        builder.addCase(getStudentTaskById.rejected, (state, { error }) => {
            state.taskFetchStatus = FetchStatus.ERROR;
            state.task = undefined;
            state.error = error;
        });
        builder
            .addCase(getStudentTaskPreview.pending, (state) => {
                state.taskPreviewStatus = FetchStatus.FETCHING;
            })
            .addCase(getStudentTaskPreview.fulfilled, (state, { payload }) => {
                state.taskPreviewStatus = FetchStatus.FETCHED;
                state.taskPreview = payload;
            });
        builder
            .addCase(deleteStudentTaskAction.pending, (state) => {
                state.taskDeletingStatus = FetchStatus.FETCHING;
            })
            .addCase(deleteStudentTaskAction.fulfilled, (state) => {
                state.taskDeletingStatus = FetchStatus.FETCHED;
            });
        builder
            .addCase(changeAssignmentMarksAction.fulfilled, (state, { meta, payload }) => {
                state.studentTasks = state.studentTasks.map((task) => {
                    if (Number(task.id) === meta.arg.taskId) {
                        return {
                            ...task,
                            marks:
                                task.marks?.map((mark) => {
                                    const changedMark = payload.find((newMark) => newMark.id === mark.id);
                                    if (changedMark) {
                                        return {
                                            ...mark,
                                            ...changedMark,
                                            score: changedMark.score as MarkScore,
                                        };
                                    }
                                    return mark;
                                }) ?? [],
                        };
                    }

                    return task;
                });
            })
            .addCase(changeAssignmentAdditionalMarkAction.fulfilled, (state, { meta, payload }) => {
                state.studentTasks = state.studentTasks.map((task) => {
                    if (Number(task.id) === meta.arg.taskId) {
                        return {
                            ...task,
                            teacherMark:
                                task.teacherMark?.map((mark) => {
                                    const changedMark = payload.find((newMark) => newMark.id === mark.id);
                                    if (changedMark) {
                                        return {
                                            ...mark,
                                            ...changedMark,
                                            score: changedMark.score as MarkScore,
                                        };
                                    }
                                    return mark;
                                }) ?? [],
                        };
                    }

                    return task;
                });
            });
    },
});

export const resetStudentTaskData = studentTasksSlice.actions
    .resetStudentTaskData as ActionCreatorWithoutPayload<string>;
export const resetStudentTask = studentTasksSlice.actions.resetStudentTask as ActionCreatorWithoutPayload;
export const resetStudentTaskStatus = studentTasksSlice.actions.resetStudentTaskStatus as ActionCreatorWithoutPayload;
export const studentTasksReducer = studentTasksSlice.reducer;

import { Header, HeaderSize } from '@lms-elements/materials-header';
import { IMaterialAudio } from '@lms-elements/materials-media/build/@types/packages/MaterialsMedia/src/Audio/Expanded/ExpandedAudioMaterial.types';
import { EditorState } from 'draft-js';
import { ContentMaterialTypeEnum, ExerciseData, IMaterial, IMaterialImage, IMaterialTable } from 'types/materials';

export const createDefaultMaterial = (
    id: number,
    type: ContentMaterialTypeEnum,
    isEditor: boolean,
): IMaterial | undefined => {
    switch (type) {
        case ContentMaterialTypeEnum.text:
            return {
                id: id,
                type: type,
                textData: isEditor ? EditorState.createEmpty() : '',
                documentData: [],
                headerData: {} as Header,
                imageData: {} as IMaterialImage,
                audioData: {} as IMaterialAudio,
                videoData: { url: '' },
                files: [],
                html: '',
                url: '',
                tableData: {
                    table: {
                        table: [[EditorState.createEmpty()]],
                        editorBuffer: EditorState.createEmpty(),
                    },
                    title: '',
                } as IMaterialTable,
                exerciseData: {} as ExerciseData,
                isDeleted: false,
            };
        case ContentMaterialTypeEnum.audio:
        case ContentMaterialTypeEnum.video:
            return {
                id: id,
                type: type,
                textData: '',
                documentData: [],
                headerData: {} as Header,
                imageData: {} as IMaterialImage,
                audioData: {} as IMaterialAudio,
                videoData: { url: '' },
                files: [],
                html: '',
                url: '',
                tableData: {
                    table: {
                        table: [[EditorState.createEmpty()]],
                        editorBuffer: EditorState.createEmpty(),
                    },
                    title: '',
                } as IMaterialTable,
                exerciseData: {} as ExerciseData,
                isDeleted: false,
            };
        case ContentMaterialTypeEnum.kinescope:
            return {
                id: id,
                type: type,
                textData: '',
                documentData: [],
                headerData: {} as Header,
                imageData: {} as IMaterialImage,
                audioData: {} as IMaterialAudio,
                videoData: { url: '' },
                files: [],
                html: '',
                url: '',
                tableData: {
                    table: {
                        table: [[EditorState.createEmpty()]],
                        editorBuffer: EditorState.createEmpty(),
                    },
                    title: '',
                } as IMaterialTable,
                exerciseData: {} as ExerciseData,
                isDeleted: false,
            };
        case ContentMaterialTypeEnum.photo:
            return {
                id: id,
                type: type,
                textData: '',
                documentData: [],
                headerData: {} as Header,
                imageData: {
                    data: [],
                    description: '',
                },
                audioData: {} as IMaterialAudio,
                videoData: { url: '' },
                files: [],
                html: '',
                url: '',
                tableData: {
                    table: {
                        table: [[EditorState.createEmpty()]],
                        editorBuffer: EditorState.createEmpty(),
                    },
                    title: '',
                } as IMaterialTable,
                exerciseData: {} as ExerciseData,
                isDeleted: false,
            };
        case ContentMaterialTypeEnum.document:
            return {
                id: id,
                type: type,
                textData: '',
                documentData: [],
                headerData: {} as Header,
                imageData: {
                    data: [],
                    description: '',
                },
                audioData: {} as IMaterialAudio,
                videoData: { url: '' },
                files: [],
                html: '',
                url: '',
                tableData: {
                    table: {
                        table: [[EditorState.createEmpty()]],
                        editorBuffer: EditorState.createEmpty(),
                    },
                    title: '',
                } as IMaterialTable,
                exerciseData: {} as ExerciseData,
                isDeleted: false,
            };
        case ContentMaterialTypeEnum.header:
            return {
                id: id,
                type: type,
                textData: '',
                documentData: [],
                headerData: {
                    size: HeaderSize.LARGE,
                    text: '',
                },
                imageData: {
                    data: [],
                    description: '',
                },
                audioData: {} as IMaterialAudio,
                videoData: { url: '' },
                files: [],
                html: '',
                url: '',
                tableData: {
                    table: {
                        table: [[EditorState.createEmpty()]],
                        editorBuffer: EditorState.createEmpty(),
                    },
                    title: '',
                } as IMaterialTable,
                exerciseData: {} as ExerciseData,
                isDeleted: false,
            };
        case ContentMaterialTypeEnum.table:
            return {
                id: id,
                type: type,
                textData: '',
                documentData: [],
                headerData: {} as Header,
                imageData: {} as IMaterialImage,
                audioData: {} as IMaterialAudio,
                videoData: { url: '' },
                files: [],
                html: '',
                url: '',
                tableData: {
                    table: {
                        size: { x: 0, y: 0 },
                        table: [],
                        html: [],
                        widths: [],
                        heights: [],
                        minHeights: [],
                        editorBuffer: EditorState.createEmpty(),
                    },
                    title: '',
                },
                exerciseData: {} as ExerciseData,
                isDeleted: false,
            };
        case ContentMaterialTypeEnum.exercise:
            return {
                id: id,
                type: type,
                textData: '',
                documentData: [],
                headerData: {} as Header,
                imageData: {} as IMaterialImage,
                audioData: {} as IMaterialAudio,
                videoData: { url: '' },
                files: [],
                html: '',
                url: '',
                tableData: {
                    table: {
                        table: [[EditorState.createEmpty()]],
                        editorBuffer: EditorState.createEmpty(),
                    },
                    title: '',
                } as IMaterialTable,
                exerciseData: {
                    isFull: false,
                } as ExerciseData,
                isDeleted: false,
            };
        default:
            return undefined;
    }
};

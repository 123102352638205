//Меняет яркость и насыщенность цвета
export const transformHexColor = (hex: string, lightAmount: number, saturationFactor = 1): string => {
    // Преобразуем HEX в число.
    const color = parseInt(hex.startsWith('#') ? hex.slice(1) : hex, 16);
    // Регулируем каждый компонент RGB в пределах 0-255.
    const r = Math.min(255, Math.max(0, (color >> 16) + lightAmount));
    const g = Math.min(255, Math.max(0, ((color & 0x00ff00) >> 8) + lightAmount));
    const b = Math.min(255, Math.max(0, (color & 0x0000ff) + lightAmount));

    const reducedG = g * saturationFactor;

    // Возвращаем исправленный цвет в HEX-формате.
    return `#${((r << 16) | (reducedG << 8) | b).toString(16).padStart(6, '0')}`;
};

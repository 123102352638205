import { AdaptationItem } from 'api/services/adaptation';
import { CheckboxItem } from 'types/checkbox';

export const normalizeAdaptation = (adaptationItems: AdaptationItem[]): CheckboxItem[] =>
    adaptationItems.map((adaptationItems) => {
        return {
            id: adaptationItems.id,
            label: adaptationItems.adaptation,
            checked: adaptationItems.done,
        };
    });

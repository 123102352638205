import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import {
    createCalendarPlanAction,
    createCalendarPlanPeriodAction,
    createSchoolHolidaysAction,
    deleteCalendarPlanAction,
    getCalendarPlanAction,
    getCalendarPlanListAction,
    patchCalendarPlanAction,
    patchCalendarPlanPeriodAction,
    patchSchoolHolidaysAction,
} from 'store/actions/calendarPlan';
import { FetchStatus } from 'types/api';
import { ICalendarPlan } from 'types/calendarPlan';

interface CalendarPlanState {
    calendarPlan?: ICalendarPlan;
    createdPlanId?: number;
    calendarPlanStatus: FetchStatus;
    calendarPlanList?: Array<ICalendarPlan>;
    calendarPlanListStatus: FetchStatus;
    createCalendarPlanStatus: FetchStatus;
    createCalendarPlanPeriodStatus: FetchStatus;
    createSchoolHolidaysStatus: FetchStatus;
    deleteCalendarPlanStatus: FetchStatus;
    patchCalendarPlanPeriodStatus: FetchStatus;
    patchCalendarPlanStatus: FetchStatus;
    patchSchoolHolidaysStatus: FetchStatus;
    isEditCalendarPlan?: boolean;
    error?: unknown;
}

const initialState = {
    calendarPlanStatus: FetchStatus.INITIAL,
    calendarPlanListStatus: FetchStatus.INITIAL,
    createCalendarPlanStatus: FetchStatus.INITIAL,
    createCalendarPlanPeriodStatus: FetchStatus.INITIAL,
    createSchoolHolidaysStatus: FetchStatus.INITIAL,
    deleteCalendarPlanStatus: FetchStatus.INITIAL,
    patchCalendarPlanPeriodStatus: FetchStatus.INITIAL,
    patchCalendarPlanStatus: FetchStatus.INITIAL,
    patchSchoolHolidaysStatus: FetchStatus.INITIAL,
};

const calendarPlanSlice = createSlice<CalendarPlanState, SliceCaseReducers<CalendarPlanState>>({
    name: 'calendarPlan',
    initialState,
    reducers: {
        reset: () => initialState,
        editCalendarPlan: (state) => {
            state.isEditCalendarPlan = true;
        },
        resetCreatedPlanId: (state) => {
            state.createdPlanId = undefined;
        },
        resetCalendarPlan: (state) => {
            state.calendarPlan = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createCalendarPlanAction.pending, (state) => {
                state.createCalendarPlanStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(createCalendarPlanAction.fulfilled, (state, { payload }) => {
                state.createCalendarPlanStatus = FetchStatus.FETCHED;
                state.calendarPlan = payload;
                state.createdPlanId = payload.id;
                state.error = null;
            })
            .addCase(createCalendarPlanAction.rejected, (state, { error }) => {
                state.createCalendarPlanStatus = FetchStatus.ERROR;
                state.error = error;
            })
            .addCase(createCalendarPlanPeriodAction.pending, (state) => {
                state.createCalendarPlanPeriodStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(createCalendarPlanPeriodAction.fulfilled, (state) => {
                state.createCalendarPlanPeriodStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(createCalendarPlanPeriodAction.rejected, (state, { error }) => {
                state.createCalendarPlanPeriodStatus = FetchStatus.ERROR;
                state.error = error;
            })
            .addCase(createSchoolHolidaysAction.pending, (state) => {
                state.createSchoolHolidaysStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(createSchoolHolidaysAction.fulfilled, (state) => {
                state.createSchoolHolidaysStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(createSchoolHolidaysAction.rejected, (state, { error }) => {
                state.createSchoolHolidaysStatus = FetchStatus.ERROR;
                state.error = error;
            })
            .addCase(deleteCalendarPlanAction.pending, (state, action) => {
                state.deleteCalendarPlanStatus = FetchStatus.FETCHING;
                state.calendarPlanList = state.calendarPlanList?.filter((item) => item.id !== action.meta.arg);
                state.error = null;
            })
            .addCase(deleteCalendarPlanAction.fulfilled, (state) => {
                state.deleteCalendarPlanStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(deleteCalendarPlanAction.rejected, (state, { error }) => {
                state.deleteCalendarPlanStatus = FetchStatus.ERROR;
                state.error = error;
            })
            .addCase(getCalendarPlanAction.pending, (state) => {
                state.calendarPlanListStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getCalendarPlanAction.fulfilled, (state, { payload }) => {
                state.calendarPlanListStatus = FetchStatus.FETCHED;
                state.calendarPlan = payload;
                state.isEditCalendarPlan = false;
                state.error = null;
            })
            .addCase(getCalendarPlanAction.rejected, (state, { error }) => {
                state.calendarPlanListStatus = FetchStatus.ERROR;
                state.error = error;
            })
            .addCase(getCalendarPlanListAction.pending, (state) => {
                state.calendarPlanListStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getCalendarPlanListAction.fulfilled, (state, { payload }) => {
                state.calendarPlanListStatus = FetchStatus.FETCHED;
                state.calendarPlanList = payload;
                state.error = null;
            })
            .addCase(getCalendarPlanListAction.rejected, (state, { error }) => {
                state.calendarPlanListStatus = FetchStatus.ERROR;
                state.error = error;
            })
            .addCase(patchCalendarPlanAction.pending, (state) => {
                state.patchCalendarPlanStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(patchCalendarPlanAction.fulfilled, (state) => {
                state.patchCalendarPlanStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(patchCalendarPlanAction.rejected, (state, { error }) => {
                state.patchCalendarPlanStatus = FetchStatus.ERROR;
                state.error = error;
            })
            .addCase(patchCalendarPlanPeriodAction.pending, (state) => {
                state.patchCalendarPlanPeriodStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(patchCalendarPlanPeriodAction.fulfilled, (state) => {
                state.patchCalendarPlanPeriodStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(patchCalendarPlanPeriodAction.rejected, (state, { error }) => {
                state.patchCalendarPlanPeriodStatus = FetchStatus.ERROR;
                state.error = error;
            })

            .addCase(patchSchoolHolidaysAction.pending, (state) => {
                state.patchSchoolHolidaysStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(patchSchoolHolidaysAction.fulfilled, (state) => {
                state.patchSchoolHolidaysStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(patchSchoolHolidaysAction.rejected, (state, { error }) => {
                state.patchSchoolHolidaysStatus = FetchStatus.ERROR;
                state.error = error;
            });
    },
});

export const editCalendarPlan = calendarPlanSlice.actions.editCalendarPlan as ActionCreatorWithoutPayload<string>;
export const resetCalendarPlanState = calendarPlanSlice.actions.reset as ActionCreatorWithoutPayload<string>;
export const resetCalendarPlan = calendarPlanSlice.actions.resetCalendarPlan as ActionCreatorWithoutPayload<string>;
export const resetCreatedPlanId = calendarPlanSlice.actions.resetCreatedPlanId as ActionCreatorWithoutPayload<string>;
export const calendarPlanReducer = calendarPlanSlice.reducer;

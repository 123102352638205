import React, { useEffect, useMemo, useRef, useState } from 'react';
import { GoogleChartWrapper } from 'react-google-charts';
import { Moment } from 'moment';
import { AppAreaChart, AppSkeletonCustom, AppText } from 'src-new/ui';
import {
    addMinutesToTime,
    getCurrentTime,
    getDifferentInMinutes,
    getMaxTime,
    getMinTime,
    getMomentFromString,
    getTimeFromDate,
    getTimeline,
    isIntervalContainsInterval,
    isTimeInInterval,
} from 'src-new/utils/date-n-time';
import { TInterval } from 'src-new/utils/date-n-time/timeСonversion';

import ScheduleTimeline from './schedule-timeline/ScheduleTimeline';
import SchedulesCard from './shedules-card/SchedulesCard';
import SchedulesTooltip from './shedules-tooltip/ShedulesTooltip';
import { TAppDayScheduleProps, TCardsRow, TScheduleCardBase, TTooltipConfig } from './AppDaySchedule.types';
import * as SCHEDULE_CONST from './AppDayScheduleConstants';
import { EAppDaySchedule } from './AppDayScheduleConstants';

import './style.scss';

const AppDaySchedule = <TScheduleCard extends TScheduleCardBase>(
    props: TAppDayScheduleProps<TScheduleCard>,
): JSX.Element => {
    const {
        cardsList,
        config,
        CardContent,
        TooltipContent,
        ChartTooltipContent,
        handleTooltipOpen,
        handleTooltipClose,
        handleChartSelected,
        chartData,
        needChart,
        needCurrentTimeLine = true,
        isLoading = false,
    } = { ...props };
    const [selectedCard, setSelectedCard] = useState<TScheduleCard | undefined>(undefined);
    const [tooltipConfig, setTooltipConfig] = useState<TTooltipConfig>({} as TTooltipConfig);

    const [chartTooltipConfig, setChartTooltipConfig] = useState<TTooltipConfig>({} as TTooltipConfig);
    const [isChartTooltipVisible, setIsChartTooltipVisible] = useState<boolean>(false);

    const cardsContainer = useRef<HTMLDivElement>(null);
    const tooltipContainer = useRef<HTMLDivElement>(null);
    const chartTooltipContainer = useRef<HTMLDivElement>(null);

    const normalizeCardsList = useMemo(() => {
        return cardsList.map((item) => {
            return {
                ...item,
                datetimeStart: getTimeFromDate(item.datetimeStart),
                datetimeEnd: getTimeFromDate(item.datetimeEnd),
            };
        });
    }, [cardsList]);
    const SCHEDULE_CONFIG: { [key in keyof typeof EAppDaySchedule | 'INTERVALS_COUNT']: number } = useMemo(() => {
        return {
            PIXELS_PER_MINUTE: config?.pixelsPerMinute || SCHEDULE_CONST.PIXELS_PER_MINUTE,
            INTERVAL_DURATION: config?.intervalDuration || SCHEDULE_CONST.INTERVAL_DURATION,
            CARD_WIDTH: config?.cardWidth || SCHEDULE_CONST.CARD_WIDTH,
            CARD_OFFSET: config?.cardsOffset || SCHEDULE_CONST.CARD_OFFSET,
            TOOLTIP_OFFSET: SCHEDULE_CONST.TOOLTIP_OFFSET,
            INTERVALS_COUNT: config.intervalsCount,
            SCHEDULE_PADDING_TOP: SCHEDULE_CONST.SCHEDULE_PADDING_TOP,
            CHART_WIDTH: SCHEDULE_CONST.CHART_WIDTH,
        };
    }, [config]);

    let currentTime = getCurrentTime();

    useEffect(() => {
        const gettingCurrentTime = setInterval(() => (currentTime = getCurrentTime()), 60 * 1000);

        return () => {
            clearInterval(gettingCurrentTime);
        };
    }, []);

    const timeIntervals = useMemo(() => {
        const starts = normalizeCardsList.map((item) => getMomentFromString(item.datetimeStart));
        const minTimeInList = normalizeCardsList.length ? getMinTime(starts) : '09:00';
        const minDefaultTime = '09:00';
        const isMinTimeInListEarly = getDifferentInMinutes(minDefaultTime, minTimeInList);
        const defaultStartTime = isMinTimeInListEarly < 0 ? minTimeInList : minDefaultTime;

        const intervalList = getTimeline(
            config.intervalStartTime || defaultStartTime,
            SCHEDULE_CONFIG.INTERVAL_DURATION,
            SCHEDULE_CONFIG.INTERVALS_COUNT,
        );

        const lastIntervalEnd = intervalList[intervalList.length - 1].end;
        //@ts-ignore
        const overtimeLessonsEnds: Moment[] = normalizeCardsList
            .map((item) => {
                if (getDifferentInMinutes(lastIntervalEnd, item.datetimeEnd) > 0) {
                    return getMomentFromString(item.datetimeEnd);
                }
            })
            .filter((item) => !!item);
        if (overtimeLessonsEnds.length) {
            const lastOvertimeLessonTime = getMaxTime(overtimeLessonsEnds);

            while (!isTimeInInterval(intervalList[intervalList.length - 1], lastOvertimeLessonTime)) {
                const lastIntervalListIndex = intervalList.length - 1;

                intervalList.push({
                    start: intervalList[lastIntervalListIndex].end,
                    end: addMinutesToTime(SCHEDULE_CONFIG.INTERVAL_DURATION, intervalList[lastIntervalListIndex].end),
                });
            }
        }

        return intervalList;
    }, [normalizeCardsList]);
    const getCardsRows = useMemo((): TCardsRow<TScheduleCard> => {
        const rows: TCardsRow<TScheduleCard> = {};
        const emptyCard = {} as TScheduleCard;
        const longLessons: { intervalIndexList: number[]; cardInterval: { start: string; end: string } }[] = [];

        timeIntervals.forEach((interval, intervalIndex) => {
            //@ts-ignore
            rows[intervalIndex] = normalizeCardsList
                .map((item) => {
                    const lessonInterval = {
                        start: item.datetimeStart,
                        end: item.datetimeEnd,
                    };
                    const isIntervalContainsLessonFully = isIntervalContainsInterval(interval, lessonInterval);
                    const isIntervalContainsLessonPartially = isTimeInInterval(interval, item.datetimeStart);

                    if (isIntervalContainsLessonFully) {
                        return item;
                    } else if (isIntervalContainsLessonPartially) {
                        const lessonDuration = getDifferentInMinutes(item.datetimeStart, item.datetimeEnd);
                        const lessonStartOffset = getDifferentInMinutes(
                            item.datetimeStart,
                            timeIntervals[intervalIndex].start,
                        );
                        //если карточка охватывает более одного временного интервала, заносим ее в список длинных карточек
                        const countOfOverlays = Math.ceil(
                            (lessonDuration + lessonStartOffset) / SCHEDULE_CONFIG.INTERVAL_DURATION,
                        );
                        if (countOfOverlays > 1) {
                            const list = [];
                            for (let i = 1; i < countOfOverlays; i++) {
                                list.push(+intervalIndex + i);
                            }
                            longLessons.push({
                                intervalIndexList: list,
                                cardInterval: {
                                    start: item.datetimeStart,
                                    end: item.datetimeEnd,
                                },
                            });
                        }
                        return item;
                    }
                })
                .filter((item) => item);
        });

        //добавляем пустые карточки на места пересечения с длинными уроками, чтобы добиться корректного смещения на доске
        longLessons.forEach((long) => {
            const longCardIndex = rows[long.intervalIndexList[0] - 1].findIndex(
                (card) => long.cardInterval.start === card.datetimeStart && long.cardInterval.end === card.datetimeEnd,
            );

            long.intervalIndexList.forEach((intervalIndex) => {
                const spliceIndex = rows[intervalIndex].findIndex(
                    (card, cardIndex) =>
                        isTimeInInterval(long.cardInterval, card.datetimeStart) && cardIndex === longCardIndex,
                );

                if (spliceIndex >= 0) {
                    rows[intervalIndex].splice(spliceIndex, 0, emptyCard);
                }
            });
        });
        return rows;
    }, [timeIntervals]);
    const getCurrentTimeTopOffset = useMemo((): number | null => {
        const interval = timeIntervals.find((interval) => isTimeInInterval(interval, currentTime));
        const top =
            getDifferentInMinutes(timeIntervals[0].start, currentTime) * SCHEDULE_CONFIG.PIXELS_PER_MINUTE +
            SCHEDULE_CONFIG.SCHEDULE_PADDING_TOP;

        return interval ? top : null;
    }, [currentTime, timeIntervals]);
    const cardsRowMaxLength = useMemo(() => Math.max(...Object.values(getCardsRows).map((list) => list.length)), [
        getCardsRows,
    ]);
    const chartOptions = useMemo(() => {
        const countList = chartData?.map((item, index) => (index ? (item[1] as number) : 0));
        const maxCount = countList ? Math.max(...countList) : 0;

        return {
            legend: 'none',
            hAxis: {
                textPosition: 'none',
                gridlines: {
                    color: 'transparent',
                },
                minValue: 0,
                maxValue: maxCount * 1.3,
                baselineColor: 'transparent',
            },
            vAxis: {
                textPosition: 'none',
                gridlines: {
                    color: 'transparent',
                },
            },
            chartArea: {
                width: '100%',
                height: '100%',
                backgroundColor: {
                    stroke: 'transparent',
                    strokeWidth: 0,
                },
            },
            backgroundColor: 'transparent',
            colors: ['#8F4AB0', '#8F4AB0'],
            areaOpacity: 0.2,
            orientation: 'vertical',
            tooltip: {
                ignoreBounds: true,
                isHtml: true,
                trigger: 'focus',
            },
            pointSize: 4,
            pointsVisible: false,
            crosshair: {
                trigger: 'both',
                orientation: 'horizontal',
                color: '#8F4AB0',
            },
        };
    }, [chartData]);

    const getCardTopOffset = (timeStart: string, intervalIndex: number) => {
        const offset = getDifferentInMinutes(timeIntervals[intervalIndex].start, timeStart);
        return offset > 0 ? offset * SCHEDULE_CONFIG.PIXELS_PER_MINUTE : 0;
    };
    const getCardsMaxCountInRow = (cardWidth: number) => {
        if (cardsContainer.current) {
            const { clientWidth } = cardsContainer.current;
            const maxWidth = clientWidth * 0.8;

            return maxWidth / cardWidth;
        }
        return 768 / cardWidth;
    };
    const getOffsetMultiplier = useMemo(() => {
        const MAX_MULTIPLIER = 0.5,
            MIN_MULTIPLIER = 0.1;
        let MULTIPLIER = MAX_MULTIPLIER;
        let hasOnceChanged = false;

        for (let i = MULTIPLIER; i >= MIN_MULTIPLIER; i -= 0.1) {
            const capacity = getCardsMaxCountInRow(SCHEDULE_CONFIG.CARD_WIDTH * i);
            const counter = Math.round(i * 100) / 100;

            if (cardsRowMaxLength <= capacity && !hasOnceChanged) {
                MULTIPLIER = counter;
                hasOnceChanged = true;
            } else if (!hasOnceChanged && counter === MIN_MULTIPLIER) {
                MULTIPLIER = MIN_MULTIPLIER;
            }
        }
        return MULTIPLIER;
    }, [cardsRowMaxLength]);

    const getCardLeftOffset = (index: number) => {
        const cardsMaxCountInRow = getCardsMaxCountInRow(SCHEDULE_CONFIG.CARD_WIDTH + SCHEDULE_CONFIG.CARD_OFFSET);

        if (cardsRowMaxLength > cardsMaxCountInRow) {
            return SCHEDULE_CONFIG.CARD_WIDTH * getOffsetMultiplier * index;
        }
        return (SCHEDULE_CONFIG.CARD_WIDTH + SCHEDULE_CONFIG.CARD_OFFSET) * index;
    };

    const getCoordinates = (cardIndex: number, intervalIndex: number, card: TScheduleCard): TTooltipConfig => {
        const newCoordinate = {} as TTooltipConfig;
        const cardLeft = getCardLeftOffset(cardIndex);
        const cardHeight =
            getDifferentInMinutes(card.datetimeStart, card.datetimeEnd) * SCHEDULE_CONFIG.PIXELS_PER_MINUTE;
        const cardTop =
            getCardTopOffset(card.datetimeStart, intervalIndex) +
            intervalIndex * SCHEDULE_CONFIG.INTERVAL_DURATION * SCHEDULE_CONFIG.PIXELS_PER_MINUTE;
        const scrollContainer = cardsContainer.current?.parentElement?.parentElement;
        const tooltipElement = tooltipContainer.current?.firstElementChild?.childNodes[1] as HTMLElement;
        const tooltipElementWidth = tooltipElement.clientWidth;

        if (scrollContainer) {
            const { scrollLeft, offsetLeft, offsetWidth, offsetHeight } = scrollContainer;
            const chartWidth = SCHEDULE_CONFIG.CHART_WIDTH;
            const rightOffset =
                offsetWidth +
                scrollLeft -
                cardLeft -
                offsetLeft -
                SCHEDULE_CONFIG.CARD_WIDTH -
                SCHEDULE_CONFIG.TOOLTIP_OFFSET;

            const needReplaceVertically = offsetHeight - cardTop < tooltipElement.clientHeight;
            const canPlacedRight = scrollLeft
                ? rightOffset + chartWidth > tooltipElementWidth
                : offsetWidth - cardLeft - SCHEDULE_CONFIG.CARD_WIDTH + chartWidth > tooltipElementWidth;

            if (needReplaceVertically) {
                newCoordinate.bottom = -offsetHeight;
                newCoordinate.arrowBottom = offsetHeight - cardTop - cardHeight;
            } else {
                newCoordinate.top = cardTop + SCHEDULE_CONFIG.CARD_OFFSET;
            }
            if (canPlacedRight) {
                newCoordinate.left = scrollLeft
                    ? cardLeft - scrollLeft + SCHEDULE_CONFIG.CARD_WIDTH + SCHEDULE_CONFIG.TOOLTIP_OFFSET + 10
                    : cardLeft + SCHEDULE_CONFIG.CARD_WIDTH + SCHEDULE_CONFIG.TOOLTIP_OFFSET + 10;
                newCoordinate.arrowPosition = 'left';
            } else {
                newCoordinate.left = scrollLeft
                    ? cardLeft - scrollLeft - tooltipElementWidth - offsetLeft + SCHEDULE_CONFIG.TOOLTIP_OFFSET
                    : cardLeft - tooltipElementWidth - offsetLeft + SCHEDULE_CONFIG.TOOLTIP_OFFSET;
                newCoordinate.arrowPosition = 'right';
            }
        }

        return newCoordinate;
    };
    const getChartData = (interval: TInterval): (string | number)[][] => {
        // @ts-ignore
        const arr: (string | number)[][] = [['Y', 'X', { type: 'string', role: 'tooltip', p: { html: true } }]];

        if (chartData) {
            const startIndex = chartData?.findIndex((item) => item[0] === interval.start);
            const endIndex = chartData?.findIndex((item) => item[0] === interval.end);
            const slicedChartData = chartData?.slice(
                startIndex,
                chartData.length - 1 === endIndex ? endIndex : endIndex + 1,
            );

            if (startIndex >= 0 && endIndex >= 0) {
                slicedChartData.forEach((item) => {
                    arr.push([item[0], item[1], `<div class="chart-tooltip-text">${item[1]}</div>`]);
                });
            }
        }
        if (arr.length === 1) {
            arr.push(['', '']);
        }
        return arr;
    };

    const closeTooltip = () => {
        setSelectedCard(undefined);

        if (handleTooltipClose) {
            handleTooltipClose();
        }
    };
    const closeChartTooltip = () => {
        setChartTooltipConfig({} as TTooltipConfig);
        setIsChartTooltipVisible(false);
    };

    const handleCardClick = (card: TScheduleCard, coordinate: TTooltipConfig) => {
        if (card.id === selectedCard?.id) {
            setSelectedCard(undefined);
            setTooltipConfig({} as TTooltipConfig);
            if (handleTooltipClose) {
                handleTooltipClose();
            }
        } else {
            setSelectedCard(card);
            setTooltipConfig(coordinate);
            if (handleTooltipOpen) {
                void handleTooltipOpen(card);
            }
        }
    };
    const onChartSelect = (chartWrapper: GoogleChartWrapper, intervalIndex: number) => {
        const countList = chartData?.map((item, index) => (index ? (item[1] as number) : 0));
        const maxCount = countList ? Math.max(...countList) : 0;
        const chart = chartWrapper.getChart();
        const dataTable = chartWrapper.getDataTable();

        if (chart.getSelection() && chart.getSelection()[0] && chart.getSelection()[0].row) {
            const YValue = dataTable?.getValue(chart.getSelection()[0].row, 0);
            const XValue = chartData?.find((item) => item[0] === YValue)?.[1];

            if (YValue && XValue) {
                const YOffset =
                    getCardTopOffset(`${YValue}`, intervalIndex) +
                    intervalIndex * SCHEDULE_CONFIG.INTERVAL_DURATION * SCHEDULE_CONFIG.PIXELS_PER_MINUTE -
                    60;
                //TODO: придумать, как считать отступ слева, чтобы подсказка была около точки
                const XOffset = (+XValue * 100) / (maxCount * 1.3) - 30;

                const scrollContainer = cardsContainer.current?.parentElement?.parentElement;
                const tooltipElement = chartTooltipContainer.current?.firstElementChild?.childNodes[1] as HTMLElement;

                if (scrollContainer) {
                    const { offsetHeight } = scrollContainer;

                    const needReplaceVertically = offsetHeight - YOffset < tooltipElement.clientHeight;

                    if (needReplaceVertically) {
                        setChartTooltipConfig({
                            left: XOffset,
                            bottom: -offsetHeight,
                            arrowPosition: 'right',
                            //TODO: придумать, как считать отступ снизу для стрелки, чтобы она была напротив точки
                            arrowBottom: offsetHeight - YOffset - 106 + 15,
                        });
                    } else {
                        setChartTooltipConfig({
                            left: XOffset,
                            top: YOffset,
                            arrowPosition: 'right',
                        });
                    }
                }

                handleChartSelected?.(`${YValue}`);
                setIsChartTooltipVisible(true);
            }
        }
    };

    return (
        <>
            <div className={'app-day-schedule'}>
                <div className={'app-day-schedule__body'}>
                    {!!cardsList.length && getCurrentTimeTopOffset && needCurrentTimeLine && (
                        <div className={'current-time'} style={{ top: `${getCurrentTimeTopOffset}px` }}>
                            <div className={'current-time_label'}>
                                <AppText text={currentTime} fontStyle={'descriptor'} />
                            </div>
                        </div>
                    )}
                    {!!TooltipContent && (
                        <div
                            ref={tooltipContainer}
                            style={{ position: 'absolute', visibility: selectedCard ? 'visible' : 'hidden' }}
                        >
                            <SchedulesTooltip
                                onClose={closeTooltip}
                                TooltipContent={() => TooltipContent}
                                style={{
                                    left: `${tooltipConfig.left}px`,
                                    top: tooltipConfig.top ? `${tooltipConfig.top}px` : 'unset',
                                    bottom: tooltipConfig.bottom ? `${tooltipConfig.bottom}px` : 'unset',
                                }}
                                arrowPosition={tooltipConfig.arrowPosition}
                                arrowBottom={tooltipConfig.arrowBottom}
                            />
                        </div>
                    )}
                    <div className={'app-day-schedule__body_time-col'}>
                        <ScheduleTimeline
                            intervalList={timeIntervals}
                            intervalHeight={SCHEDULE_CONFIG.INTERVAL_DURATION * SCHEDULE_CONFIG.PIXELS_PER_MINUTE}
                        />
                    </div>
                    <div className={'app-day-schedule__body_cards-col'}>
                        <div className={'app-day-schedule__body_cards-col-background'}>
                            {timeIntervals.map((timeItem, intervalIndex) => {
                                return (
                                    <div
                                        key={`cards-col: ${timeItem.start}`}
                                        ref={cardsContainer}
                                        className={'app-day-schedule__body_row'}
                                        style={{
                                            height: `${
                                                SCHEDULE_CONFIG.INTERVAL_DURATION * SCHEDULE_CONFIG.PIXELS_PER_MINUTE
                                            }px`,
                                        }}
                                    >
                                        {getCardsRows[intervalIndex].map((card, index) => {
                                            if (card?.datetimeStart) {
                                                return (
                                                    <SchedulesCard
                                                        key={`card_${timeItem.start}_${index}`}
                                                        style={{
                                                            position: 'absolute',
                                                            width: SCHEDULE_CONFIG.CARD_WIDTH,
                                                            height: `${
                                                                getDifferentInMinutes(
                                                                    card.datetimeStart,
                                                                    card.datetimeEnd,
                                                                ) * SCHEDULE_CONFIG.PIXELS_PER_MINUTE
                                                            }px`,
                                                            left: `${getCardLeftOffset(index)}px`,
                                                            top: `${getCardTopOffset(
                                                                card.datetimeStart,
                                                                intervalIndex,
                                                            )}px`,
                                                            zIndex: +index + 1,
                                                        }}
                                                        selectedCardId={selectedCard?.id}
                                                        card={card}
                                                        CardContent={CardContent}
                                                        onCardClick={() =>
                                                            handleCardClick(
                                                                card,
                                                                getCoordinates(index, intervalIndex, card),
                                                            )
                                                        }
                                                    />
                                                );
                                            }
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                        <AppSkeletonCustom isFetching={isLoading} />
                    </div>
                </div>
                <div className={'app-day-schedule__chart'}>
                    {!!cardsList.length && getCurrentTimeTopOffset && needCurrentTimeLine && (
                        <div className={'current-time'} style={{ top: `${getCurrentTimeTopOffset}px` }}>
                            <div className={'current-time_label'}>
                                <AppText text={currentTime} fontStyle={'descriptor'} />
                            </div>
                        </div>
                    )}
                    <div className={'app-day-schedule__chart_title'}>
                        <AppText text={'Кол-во детей вне уроков'} fontStyle={'footnotes'} />
                    </div>
                    <div className={'app-day-schedule__chart_container'}>
                        {!!ChartTooltipContent && (
                            <div
                                ref={chartTooltipContainer}
                                style={{
                                    position: 'absolute',
                                    visibility: isChartTooltipVisible ? 'visible' : 'hidden',
                                }}
                            >
                                <SchedulesTooltip
                                    onClose={closeChartTooltip}
                                    TooltipContent={ChartTooltipContent}
                                    style={{
                                        left: `${chartTooltipConfig.left}px`,
                                        top: chartTooltipConfig.top ? `${chartTooltipConfig.top}px` : 'unset',
                                        bottom: chartTooltipConfig.bottom ? `${chartTooltipConfig.bottom}px` : 'unset',
                                        transform: 'translateX(-100%)',
                                    }}
                                    arrowPosition={chartTooltipConfig.arrowPosition}
                                    arrowBottom={chartTooltipConfig.arrowBottom}
                                />
                            </div>
                        )}
                        <div className={'app-day-schedule__chart_container-timeline'}>
                            <ScheduleTimeline
                                intervalList={timeIntervals}
                                intervalHeight={SCHEDULE_CONFIG.INTERVAL_DURATION * SCHEDULE_CONFIG.PIXELS_PER_MINUTE}
                            />
                        </div>
                        {needChart && (
                            <div className={'app-day-schedule__chart_container-component'}>
                                <AppSkeletonCustom isFetching={isLoading} />
                                {!!chartData?.length && (
                                    <div className={'app-day-schedule__chart_chart-container'}>
                                        {timeIntervals.map((item, index) => {
                                            const appAreaChartData = getChartData(item);
                                            const hoverClass =
                                                appAreaChartData.length === 2 && !appAreaChartData[1][0]
                                                    ? 'unknown-data'
                                                    : '';

                                            return (
                                                <div key={item.start} className={`chart-wrapper ${hoverClass}`}>
                                                    <AppAreaChart
                                                        data={appAreaChartData}
                                                        options={chartOptions}
                                                        onChartSelect={(chartWrapper) =>
                                                            onChartSelect(chartWrapper, index)
                                                        }
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AppDaySchedule;

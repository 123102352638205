import React from 'react';
import { AppPopover, AppText } from '@frontend-modules/ui-kit';
import { getDateWithTime } from '@lms-elements/utils';
import { TExercisesProgress } from 'types/newSubjectsTypes';

import './style.scss';

/**
 * @description Component for displaying materials resolve progress
 * @param {TExercisesProgress[]} progress
 * */
export const ProgressBarAdvanced: React.FC<{ progress: TExercisesProgress[] }> = ({ progress }) => {
    const getPopoverContent = (data: TExercisesProgress) => {
        const { attemptsCount, title, completedDate } = data;
        const popoverTitle = !isNaN(Number(title)) ? `Упражнение №${title}` : title;

        return (
            <div className={'progress-bar-advanced__popover'}>
                <AppText text={popoverTitle} textStyle={'DesktopFontBody'} color={'#919399'} />
                <div className={'progress-bar-advanced__popover_row'}>
                    <AppText text={'Попыток'} textStyle={'DesktopFontBody'} />
                    <AppText text={attemptsCount.toString()} textStyle={'DesktopFontBody'} />
                </div>
                {completedDate && (
                    <div className={'progress-bar-advanced__popover_row'}>
                        <AppText text={'Выполнено'} textStyle={'DesktopFontBody'} />
                        <AppText text={getDateWithTime(completedDate)} textStyle={'DesktopFontBody'} />
                    </div>
                )}
            </div>
        );
    };

    return (
        <section className={`progress-bar-advanced`}>
            {progress.map((value, index) => {
                const { status } = value;

                return (
                    <article key={index} className={`progress-bar-advanced__cell`}>
                        <AppPopover content={getPopoverContent(value)}>
                            <span
                                className={`progress-bar-advanced__cell_deg progress-bar-advanced__cell_deg-main ${status}`}
                            />
                        </AppPopover>
                    </article>
                );
            })}
        </section>
    );
};

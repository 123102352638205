import { patchApiRequest } from 'api';
import { CreateRoomResponse } from 'api/services/proctoring';
import { payloadTimeFormatter } from 'src-new/utils/date-n-time';
import { EventActions } from 'types/events';

export interface ChangeRoomData {
    id: number;
    action?: EventActions;
    datetimeStart?: string;
    duration?: number;
    maxStudents?: number;
    leaders?: number[];
}

export const changeEventRoom = ({ id, ...roomData }: ChangeRoomData): Promise<CreateRoomResponse> => {
    const { datetimeStart, action, duration, maxStudents, leaders } = { ...roomData };
    const startDate = datetimeStart ? payloadTimeFormatter(datetimeStart) : datetimeStart;

    const payload: typeof roomData = {
        action,
        duration,
        maxStudents,
        leaders,
    };
    if (startDate) {
        payload.datetimeStart = startDate;
    }
    return patchApiRequest(`/event-room/update/${id}/`, payload);
};

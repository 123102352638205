import { convertToRaw, EditorState } from 'draft-js';
import { ContentMaterialTypeEnum, IMaterial } from 'types/materials';

export const materialFieldValidation = (value?: IMaterial): string | undefined => {
    if (value?.isDeleted) {
        return undefined;
    }

    switch (value?.type) {
        case ContentMaterialTypeEnum.exercise:
            return !value.exerciseData.questionId ? 'Упражнение нельзя создать без вопроса' : undefined;
        case ContentMaterialTypeEnum.header:
            return !value.headerData.text ? 'Заголовок не может быть пустым' : undefined;
        case ContentMaterialTypeEnum.audio:
            return !value.audioData.file ? 'Нужно добавить файлы' : undefined;
        case ContentMaterialTypeEnum.video:
        case ContentMaterialTypeEnum.kinescope:
            return !value.videoData.url && !value.videoData ? 'Нужно добавить ссылку на видео' : undefined;
        case ContentMaterialTypeEnum.document:
            return !value.documentData.length ? 'Материал "документ" не может быть пустым' : undefined;
        case ContentMaterialTypeEnum.photo:
            return !value.imageData?.data.length ? 'Нет выбранных изображений' : undefined;
        case ContentMaterialTypeEnum.text:
            return !convertToRaw((value.textData as EditorState).getCurrentContent()).blocks.some((block) =>
                Boolean(block.text),
            )
                ? 'Отсутсвует текст'
                : undefined;
        case ContentMaterialTypeEnum.table:
            return !value.tableData?.table?.widths?.length ? 'Таблица не инициализирована' : undefined;
        default:
            return undefined;
    }
};

export const materialFormValidation = (values: {
    materials: IMaterial[];
}): Record<string, Array<string | undefined>> => {
    const error = { materials: [] } as Record<string, Array<string | undefined>>;

    values.materials.forEach((value, index) => {
        error.materials[index] = materialFieldValidation(value);
    });

    return error;
};

import React from 'react';
import { AppFontIcon } from '@frontend-modules/ui-kit';
import { E_COLORS } from 'src-new/constants';

import { TBooleanStatusIndicator } from './BooleanStatusIndicator.types';

import './BooleanStatusIndicator.style.scss';
export const BooleanStatusIndicator: React.FC<TBooleanStatusIndicator> = (props) => {
    const { isPositive, color } = props;

    return isPositive ? (
        <div className={'boolean-status-indicator'} style={{ background: `${color}30` }}>
            <AppFontIcon icon={'check-s'} color={color} size={8} />
        </div>
    ) : (
        <div className={'boolean-status-indicator'}>
            <AppFontIcon icon={'minus-l'} color={E_COLORS.light_grey_color_1} />
        </div>
    );
};

import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { ResponseTheme } from 'api/services/courseThemes';
import {
    changeThemeAction,
    createThemeAction,
    deleteThemeAction,
    getThemesAction,
    publishThemeAction,
} from 'store/actions/courseThemes';
import { FetchStatus } from 'types/api';

export interface ThemeState {
    getStatusThemes: FetchStatus;
    postStatusThemes: FetchStatus;
    patchStatusThemes: FetchStatus;
    deleteStatusThemes: FetchStatus;
    publishStatusTheme: FetchStatus;
    themes: ResponseTheme[];
    error: unknown;
}

export const initialState: ThemeState = {
    getStatusThemes: FetchStatus.INITIAL,
    postStatusThemes: FetchStatus.INITIAL,
    patchStatusThemes: FetchStatus.INITIAL,
    deleteStatusThemes: FetchStatus.INITIAL,
    publishStatusTheme: FetchStatus.INITIAL,
    themes: [],
    error: null,
};

const themeSlice = createSlice<ThemeState, SliceCaseReducers<ThemeState>>({
    name: 'theme',
    initialState,
    reducers: {
        resetCourseThemesState(state) {
            state.getStatusThemes = FetchStatus.INITIAL;
            state.postStatusThemes = FetchStatus.INITIAL;
            state.patchStatusThemes = FetchStatus.INITIAL;
            state.deleteStatusThemes = FetchStatus.INITIAL;
            state.themes = [];
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getThemesAction.pending, (state) => {
            state.getStatusThemes = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getThemesAction.fulfilled, (state, { payload }) => {
            state.getStatusThemes = FetchStatus.FETCHED;
            state.publishStatusTheme = FetchStatus.INITIAL;
            state.themes = payload.sort((a, b) => a.order - b.order) || [];
            state.error = null;
        });
        builder.addCase(getThemesAction.rejected, (state, { error }) => {
            state.getStatusThemes = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(publishThemeAction.pending, (state) => {
            state.publishStatusTheme = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(publishThemeAction.fulfilled, (state) => {
            state.publishStatusTheme = FetchStatus.FETCHED;
            state.error = null;
        });
        builder.addCase(publishThemeAction.rejected, (state, { error }) => {
            state.publishStatusTheme = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(createThemeAction.pending, (state) => {
            state.postStatusThemes = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(createThemeAction.fulfilled, (state, { payload }) => {
            state.postStatusThemes = FetchStatus.FETCHED;
            const { newTheme, ordering } = payload;
            const newState =
                ordering?.map((themeId, index) => {
                    if (themeId === newTheme.id) {
                        return {
                            ...newTheme,
                            isPublished: newTheme.isPublished ?? true,
                        };
                    }

                    return {
                        ...(state.themes.find((theme) => theme.id === themeId) as ResponseTheme),
                        order: index,
                    };
                }) || [];
            state.themes = newState;
            state.error = null;
        });
        builder.addCase(createThemeAction.rejected, (state, { error }) => {
            state.postStatusThemes = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(changeThemeAction.pending, (state) => {
            state.patchStatusThemes = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(changeThemeAction.fulfilled, (state, { payload }) => {
            state.patchStatusThemes = FetchStatus.FETCHED;
            const { newTheme, ordering } = payload;
            const newState =
                ordering?.map((themeId, index) => {
                    if (themeId === newTheme.id) {
                        return {
                            ...newTheme,
                            isPublished: newTheme.isPublished ?? true,
                        };
                    }

                    return {
                        ...(state.themes.find((theme) => theme.id === themeId) as ResponseTheme),
                        order: index,
                    };
                }) || [];
            state.themes = newState;
            state.error = null;
        });
        builder.addCase(changeThemeAction.rejected, (state, { error }) => {
            state.patchStatusThemes = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(deleteThemeAction.pending, (state) => {
            state.deleteStatusThemes = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(deleteThemeAction.fulfilled, (state, { payload }) => {
            state.deleteStatusThemes = FetchStatus.FETCHED;
            const { ordering } = payload;
            state.themes = ordering.map((themeId, order) => ({
                ...(state.themes.find((theme) => theme.id === themeId) as ResponseTheme),
                order,
            }));
            state.error = null;
        });
        builder.addCase(deleteThemeAction.rejected, (state, { error }) => {
            state.deleteStatusThemes = FetchStatus.ERROR;
            state.error = error;
        });
    },
});

export const resetCourseThemesState = themeSlice.actions.resetCourseThemesState as ActionCreatorWithoutPayload<string>;
export const courseThemeReducer = themeSlice.reducer;

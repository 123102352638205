import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    changeEventRoom,
    createEventRoom,
    CreateRoomData,
    deleteEventRoom,
    getEventRoomDetails,
    getEventRooms,
    getEvents,
    getProctors,
} from 'api/services/proctoring';
import { EventActions, EventTypes } from 'types/events';

export const getEventsByDateAction = createAsyncThunk('proctoring/getEvents', (date: string) => {
    return getEvents({ date });
});

export const getStudentEventsAction = createAsyncThunk('proctoring/getStudentEvents', () => {
    return getEvents({});
});

export const getProctorsAction = createAsyncThunk('proctoring/getProctors', () => {
    return getProctors();
});

type GetEventRoomsByLessonAndTypeParams = {
    lesson: number;
    event_type: EventTypes;
    student?: number;
    id?: number;
};

export const getEventRoomsByLessonAndTypeAction = createAsyncThunk(
    'proctoring/getEventRoomsByLessonAndType',
    ({ lesson, event_type }: GetEventRoomsByLessonAndTypeParams) => {
        return getEventRooms({ lesson, event_type });
    },
);

type GetEventRoomsByDatesParams = {
    datetimeEnd: string;
    datetimeStart: string;
    eventType: EventTypes;
};

type rejoinRoomsParams = {
    leaveId: number;
    newId: number;
    studentId?: number;
    lesson: number;
    event_type: EventTypes;
};

type joinRoomsParams = {
    newId: number;
    studentId?: number;
    lesson: number;
    event_type: EventTypes;
};

export const getEventRoomsByDates = createAsyncThunk(
    'proctoring/getEventRoomsByDates',
    ({ datetimeEnd, datetimeStart, eventType }: GetEventRoomsByDatesParams) => {
        return getEventRooms({
            datetime_start_before: datetimeEnd,
            datetime_start_after: datetimeStart,
            event_type: eventType,
        });
    },
);

export const getEventRoomDetailsAction = createAsyncThunk(
    'proctoring/getEventRoomDetails',
    (id: number, { dispatch }) => {
        return getEventRoomDetails(id).then((room) => {
            void dispatch(getEventsByDateAction(room.datetimeStart));

            return room;
        });
    },
);

export const createEventRoomAction = createAsyncThunk('proctoring/createEventRoom', (data: CreateRoomData) => {
    return createEventRoom(data);
});

type ChangeRoomActionParams = {
    id: number;
    datetimeStart?: string;
    duration?: number;
    maxStudents?: number;
    leaders?: number[];
    action?: EventActions;
};

export const changeEventRoomAction = createAsyncThunk('proctoring/changeEventRoom', (data: ChangeRoomActionParams) => {
    return changeEventRoom(data);
});

export const getStudentsEventRoomsByLessonAndTypeAction = createAsyncThunk(
    'proctoring/getStudentsEventRoomsByLessonAndType',
    ({ lesson, event_type, id }: GetEventRoomsByLessonAndTypeParams) => {
        return Promise.all([getEventRooms({ lesson, event_type }), id]);
    },
);

export const rejoinRoomAction = createAsyncThunk('proctoring/rejoinEventRoom', (data: rejoinRoomsParams) => {
    return changeEventRoom({ id: data.leaveId, action: EventActions.LEAVE }).then(() =>
        changeEventRoom({ id: data.newId, action: EventActions.JOIN }).then(() =>
            Promise.all([getEventRooms({ lesson: data.lesson, event_type: data.event_type }), data.studentId]),
        ),
    );
});

export const joinRoomAction = createAsyncThunk('proctoring/joinEventRoom', (data: joinRoomsParams) => {
    return changeEventRoom({ id: data.newId, action: EventActions.JOIN }).then(() =>
        Promise.all([getEventRooms({ lesson: data.lesson, event_type: data.event_type }), data.studentId]),
    );
});

export const deleteEventRoomAction = createAsyncThunk('proctoring/deleteEventRoom', (id: number) => {
    return deleteEventRoom(id);
});

import React, { Fragment, useCallback } from 'react';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { cn } from '@bem-react/classname';
import { Checkbox, DropdownLabel, StatusLabelEnum } from '@lms-elements/atomic';
import { AttendanceStatus } from 'src-new/components/lms-elements/PerformanceTable/types';

import './HomeworkLine.scss';

interface HomeworkLineProps {
    name: string;
    onChange?: (data: { taskIndex: number; taskId: number; oldValue: boolean }) => void;
    repeatTask?: boolean;
    withAttendance?: boolean;
    status?: AttendanceStatus;
    needDisableCheckboxes?: boolean;
}

const CnHomeworkLine = cn('homeworkLine');

export const HomeworkLine: React.FC<HomeworkLineProps> = ({
    name,
    onChange,
    repeatTask,
    withAttendance,
    status,
    needDisableCheckboxes,
}) => {
    const makeHandlerCheckboxChange = useCallback(
        (
            index: number,
            taskId: number,
            checked: boolean,
            handler: (e: React.ChangeEvent<HTMLInputElement>) => void,
        ): ((e: React.ChangeEvent<HTMLInputElement>) => void) => {
            return (e) => {
                if (onChange) {
                    onChange({ taskIndex: index, taskId, oldValue: checked });
                }
                handler(e);
            };
        },
        [onChange],
    );

    return (
        <>
            {withAttendance && (
                <td>
                    {' '}
                    {status && (
                        <DropdownLabel
                            status={
                                status === AttendanceStatus.LATE
                                    ? StatusLabelEnum.wasInClass
                                    : StatusLabelEnum.wasAbsent
                            }
                            needHideIcon
                        />
                    )}
                </td>
            )}
            <FieldArray name={name}>
                {({ fields }) => {
                    const fieldsValue = fields.value as {
                        taskId?: number;
                        blocked?: boolean;
                        overdued?: boolean;
                        repeatBlocked?: boolean;
                        disabled?: boolean;
                    }[];
                    return fields.map((name, index) => {
                        return (
                            <Fragment key={String(index)}>
                                <td className={CnHomeworkLine({ blocked: fieldsValue[index].blocked })}>
                                    <Field type="checkbox" name={`${name}.checked`}>
                                        {({ input: { onChange, checked } }) => {
                                            const isDisabled =
                                                fieldsValue[index]?.disabled ||
                                                (!repeatTask && fieldsValue[index].blocked && !checked) ||
                                                (repeatTask && fieldsValue[index].repeatBlocked) ||
                                                needDisableCheckboxes;
                                            return (
                                                <Checkbox
                                                    checked={checked}
                                                    handleCheckboxChange={makeHandlerCheckboxChange(
                                                        index,
                                                        fieldsValue[index].taskId || 0,
                                                        Boolean(checked),
                                                        onChange,
                                                    )}
                                                    blocked={fieldsValue[index].blocked && !isDisabled}
                                                    disabled={isDisabled}
                                                />
                                            );
                                        }}
                                    </Field>
                                </td>
                            </Fragment>
                        );
                    });
                }}
            </FieldArray>
        </>
    );
};

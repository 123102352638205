import { ProctoringEvent } from 'api/services/proctoring';
import moment from 'moment';
import { StudentEvent } from 'types/events';

import { checkIsDayBefore, checkIsSameDates } from 'utils/date';

export const getStudentEvents = (events: ProctoringEvent[]): StudentEvent[] => {
    return events.map((event) => ({
        id: event.id,
        date: event.dateStart,
        course: event.course.title,
        isStarted: checkIsSameDates(moment(), event.dateStart, 'day') || checkIsDayBefore(event.dateStart),
        lessonId: event.lesson.id,
        subjectId: event.subject,
        courseGroupId: event.courseGroup,
    }));
};

import { IAppTableItems } from './app-table/AppTable.types';
import { IAppTableCell } from './app-table-cell';

const changeCell = (table: IAppTableItems, newCell: IAppTableCell, row: number, cell: number) => {
    return [
        ...table.slice(0, row),
        [...table[row].slice(0, cell), newCell, ...table[row].slice(cell + 1)],
        ...table.slice(row + 1),
    ];
};
const updateTableCellTitle = (
    table: IAppTableItems,
    row: number,
    cell: number,
    newTitle: string | number,
): IAppTableItems => {
    const oldCell = table?.[row]?.[cell];

    if (oldCell) {
        const newCell = { ...oldCell, title: newTitle };
        return changeCell(table, newCell, row, cell);
    }

    return table;
};

export { updateTableCellTitle };

import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import {
    changeLessonTopicAction,
    createLessonAction,
    deleteLessonAction,
    getLessonDataAction,
    getLessonsAction,
    getLessonsAndHomeworksAction,
    handleLessonScheduleAction,
} from 'store/actions/courseLessons';
import { blockEditMaterialsAction } from 'store/actions/material';
import { normalizeCourseLesson } from 'store/normalizers/lesson/normalizeCourseLesson';
import { FetchStatus } from 'types/api';
import { CourseLesson, LessonDetails } from 'types/lesson';
import { LessonTypes } from 'types/schedule';

import { insertItemIntoArray } from 'utils/insertItemIntoArray';

export interface LessonState {
    getStatusLessons: FetchStatus;
    postStatusLessons: FetchStatus;
    patchStatusLessons: FetchStatus;
    deleteStatusLessons: FetchStatus;
    publishStatusLesson: FetchStatus;
    lessons: CourseLesson[];
    lessonDetails: {
        [lessonId: number]: LessonDetails;
    };
    error: unknown;
}

export const initialState: LessonState = {
    getStatusLessons: FetchStatus.INITIAL,
    postStatusLessons: FetchStatus.INITIAL,
    patchStatusLessons: FetchStatus.INITIAL,
    deleteStatusLessons: FetchStatus.INITIAL,
    publishStatusLesson: FetchStatus.INITIAL,
    lessons: [],
    lessonDetails: {},
    error: null,
};

const lessonSlice = createSlice<LessonState, SliceCaseReducers<LessonState>>({
    name: 'Lesson',
    initialState,
    reducers: {
        resetCourseLessonState(state) {
            state.getStatusLessons = FetchStatus.INITIAL;
            state.postStatusLessons = FetchStatus.INITIAL;
            state.patchStatusLessons = FetchStatus.INITIAL;
            state.deleteStatusLessons = FetchStatus.INITIAL;
            state.lessons = [];
            state.error = null;
        },
        resetStatuses(state) {
            state.getStatusLessons = FetchStatus.INITIAL;
            state.patchStatusLessons = FetchStatus.INITIAL;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createLessonAction.pending, (state) => {
                state.postStatusLessons = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(createLessonAction.fulfilled, (state, { payload }) => {
                state.postStatusLessons = FetchStatus.FETCHED;

                state.lessons = insertItemIntoArray(
                    state.lessons.filter((item) => item.id !== payload.id),
                    { ...payload, type: 'common', isPublished: true },
                    payload.order,
                );
                state.error = null;
            })
            .addCase(createLessonAction.rejected, (state, { error }) => {
                state.postStatusLessons = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(handleLessonScheduleAction.pending, (state) => {
                state.patchStatusLessons = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(handleLessonScheduleAction.fulfilled, (state, { payload }) => {
                const { lessonSchedule, lesson } = payload;
                if (lessonSchedule) {
                    const { updatedLessonSchedules, createdLessonSchedules, deletedLessonSchedules } = lessonSchedule;

                    if (updatedLessonSchedules) {
                        const lessonSchedule = {
                            ...updatedLessonSchedules,
                            order:
                                updatedLessonSchedules.order ??
                                state.lessons.find(({ id }) => id === updatedLessonSchedules.id)?.order ??
                                1,
                        };
                        state.lessons = insertItemIntoArray(
                            state.lessons.filter((item) => item.id !== lessonSchedule.id),
                            lessonSchedule,
                            lessonSchedule.order,
                        );
                    }
                    if (createdLessonSchedules) {
                        const lessonSchedule = {
                            ...createdLessonSchedules,
                            order: createdLessonSchedules.order ? createdLessonSchedules.order : state.lessons.length,
                        };
                        state.lessons = insertItemIntoArray(
                            state.lessons.filter((item) => item.id !== lessonSchedule.id),
                            lessonSchedule,
                            lessonSchedule.order,
                        );
                    }
                    if (deletedLessonSchedules) {
                        state.lessons = state.lessons.filter((item) => item.id !== deletedLessonSchedules.id);
                    }
                } else if (lesson) {
                    const newLesson = {
                        ...lesson,
                        order: lesson.order ?? state.lessons.find(({ id }) => id === lesson.id)?.order ?? 1,
                    };
                    state.lessons = insertItemIntoArray(
                        state.lessons.filter((item) => item.id !== newLesson.id),
                        { ...newLesson, type: 'common' },
                        newLesson.order,
                    );
                }

                state.patchStatusLessons = FetchStatus.FETCHED;
                state.getStatusLessons = FetchStatus.INITIAL;
                state.error = null;
            })
            .addCase(handleLessonScheduleAction.rejected, (state, { error }) => {
                state.patchStatusLessons = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(deleteLessonAction.pending, (state) => {
                state.deleteStatusLessons = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(deleteLessonAction.fulfilled, (state, { payload }) => {
                state.deleteStatusLessons = FetchStatus.FETCHED;
                state.lessons = state.lessons.filter((lesson) => lesson.id !== payload.lessonId);
                state.error = null;
            })
            .addCase(deleteLessonAction.rejected, (state, { error }) => {
                state.deleteStatusLessons = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getLessonDataAction.pending, (state) => {
                state.getStatusLessons = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getLessonDataAction.fulfilled, (state, { payload }) => {
                state.getStatusLessons = FetchStatus.FETCHED;
                state.lessonDetails[payload.id] = payload;
                state.error = null;
            })
            .addCase(getLessonDataAction.rejected, (state, { error }) => {
                state.getStatusLessons = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getLessonsAndHomeworksAction.pending, (state) => {
                state.getStatusLessons = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getLessonsAndHomeworksAction.fulfilled, (state, { payload }) => {
                const { lessons = [], lessonSchedules = [] } = payload;
                state.getStatusLessons = FetchStatus.FETCHED;
                state.lessons = normalizeCourseLesson(lessons, lessonSchedules);
                state.error = null;
            })
            .addCase(getLessonsAndHomeworksAction.rejected, (state, { error }) => {
                state.getStatusLessons = FetchStatus.ERROR;
                state.error = error;
            });

        builder
            .addCase(getLessonsAction.pending, (state) => {
                state.getStatusLessons = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getLessonsAction.fulfilled, (state, { payload }) => {
                state.getStatusLessons = FetchStatus.FETCHED;
                state.publishStatusLesson = FetchStatus.INITIAL;
                state.lessons = payload.map((lesson) => ({
                    ...lesson,
                    lessonItems: [
                        {
                            type: LessonTypes.TEST,
                            label: 'Контрольная',
                            datetimeStart: '',
                            datetimeEnd: '',
                            checked: lesson?.isTest,
                            canEdit: true,
                        },
                    ],
                }));
                state.error = null;
            })
            .addCase(getLessonsAction.rejected, (state, { error }) => {
                state.getStatusLessons = FetchStatus.ERROR;
                state.error = error;
            });
        builder.addCase(blockEditMaterialsAction.fulfilled, (state, { payload }) => {
            if (state.lessonDetails[payload.update.id]) {
                state.lessonDetails[payload.update.id] = {
                    ...state.lessonDetails[payload.update.id],
                    isBlockEdit: payload.update.isBlockEdit,
                };
            }
        });

        builder
            .addCase(changeLessonTopicAction.pending, (state) => {
                state.getStatusLessons = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(changeLessonTopicAction.fulfilled, (state, { payload }) => {
                state.lessons = state.lessons.filter((lesson) => lesson.id !== payload.update.id);
                state.getStatusLessons = FetchStatus.FETCHED;
            })
            .addCase(changeLessonTopicAction.rejected, (state, { error }) => {
                state.getStatusLessons = FetchStatus.ERROR;
                state.error = error;
            });
    },
});
export const resetCourseLessonState = lessonSlice.actions.resetCourseLessonState as ActionCreatorWithoutPayload<string>;
export const resetStatuses = lessonSlice.actions.resetStatuses as ActionCreatorWithoutPayload<string>;
export const courseLessonReducer = lessonSlice.reducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    blockCourse,
    CourseParams,
    deleteCourse,
    getCanFillCourseList,
    getCourseDetails,
    getCourses,
    patchCourse,
    PatchCourseParams,
    postCourse,
    PostCourseParams,
    publishCourse,
} from 'api/services/course';
import { getCourseGroups } from 'api/services/courseGroups';
import { getThemes } from 'api/services/courseThemes';
import { CourseData, CourseType } from 'types/course';

export const getCoursesAction = createAsyncThunk('course/getCoursesInSubject', async (subjectId: number) => {
    return await Promise.all([getCourses({ subject_id: subjectId }), getCanFillCourseList(subjectId)]);
});

export const getCoursesBySubject = createAsyncThunk('courses/getCoursesBySubject', (subjectId: number) => {
    return getCourses({ subject_id: subjectId }).then(async (courses) => {
        return {
            subjectId,
            courses,
            courseGroups: await getCourseGroups({ course: courses.map(({ id }) => id).join(',') }),
        };
    });
});

export const getCanFillCourseListAction = createAsyncThunk('course/getCanFillCourse', async (subjectId: number) => {
    return await getCanFillCourseList(subjectId);
});

export const publishCourseAction = createAsyncThunk('course/publishCourse', async (courseId: number) => {
    return await publishCourse(courseId);
});

export const createCourseAction = createAsyncThunk('course/createCourse', async (values: PostCourseParams) => {
    return await postCourse(values);
});

export const changeCourseAction = createAsyncThunk('course/changeCourse', async (courseData: PatchCourseParams) => {
    return await patchCourse(courseData);
});

export const deleteCourseAction = createAsyncThunk('course/deleteCourse', async (id: number) => {
    return await deleteCourse(id);
});

export const getStudentCoursesAction = createAsyncThunk('course/getStudentCourse', async (params?: CourseParams) => {
    return await getCourses(params);
});

export const getAllCourses = createAsyncThunk('course/getCourses', async (params?: CourseParams) => {
    return await getCourses(params);
});

export const getCourseDetailsAction = createAsyncThunk('course/getCourseDetails', async (courseId: number) => {
    return await getCourseDetails(courseId);
});

export const getCourseWithThemesAction = createAsyncThunk('course/getCourseWithThemes', async (courseId: number) => {
    return await Promise.all([getCourseDetails(courseId), getThemes(courseId)]);
});

export const getCourseBySubjectAction = createAsyncThunk(
    'course/getCourseBySubjectAction',
    async (subjectId: number) => {
        return await getCourses({ subject_id: subjectId });
    },
);

type BlockCourseParams = {
    courseId: number;
    isBlockEdit: boolean;
};

export const blockCourseAction = createAsyncThunk(
    'course/blockCourse',
    ({ courseId, isBlockEdit }: BlockCourseParams) => {
        return blockCourse(courseId, isBlockEdit);
    },
);

interface CourseWithHoursWeekParams {
    subject_id?: number;
    course_type?: CourseType;
    parallels?: number;
    calendar_plan: number;
}

export interface CourseForParallelData {
    parallelId: number;
    calendarPlanId: number;
    courses: CourseData[];
}

export const getAllCoursesForParallel = createAsyncThunk(
    'course/getAllCoursesWithHoursWeek',
    async ({
        subject_id,
        course_type,
        parallels,
        calendar_plan,
    }: CourseWithHoursWeekParams): Promise<CourseForParallelData> => {
        const courses = await getCourses({ subject_id, course_type, parallels }).then((res) => res);
        return { calendarPlanId: calendar_plan, parallelId: Number(parallels), courses };
    },
);

export interface CourseHoursWeekWithFieldParams {
    calendar_plan: number;
    course: number;
    fieldName: string;
}

export interface CourseHoursWeekWithField {
    hoursPerWeek: number;
    fieldName: string;
}

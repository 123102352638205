import { Block, MetaSubjects, Parallel, ParallelSkills, Section, Topic } from 'api/services/codifier';

export const noQuestionInTheme = (topic: Topic): boolean => {
    return topic.questionsQuantity + topic.questionsQuantityUnpublished === 0;
};

export const noQuestionInBlock = (block: Block, topics: Topic[]): boolean => {
    const noQuestions = topics.reduce(
        (noQuestions, topic) =>
            topic.block === block.id || topic.block === undefined || topic.block === null
                ? noQuestions && noQuestionInTheme(topic)
                : noQuestions,
        true,
    );
    return noQuestions;
};

export const noQuestionInSection = (section: Section): boolean => {
    const noQuestions = section.blocks
        ? section.blocks.reduce(
              (noQuestions, block) => noQuestions && noQuestionInBlock(block, section?.topics ?? []),
              true,
          )
        : true;
    return noQuestions;
};

export const noQuestionInParallel = (parallel: Parallel): boolean => {
    const noQuestions = parallel.sections
        ? parallel.sections.reduce((noQuestions, section) => noQuestions && noQuestionInSection(section), true)
        : true;
    return noQuestions;
};

export const noSkillInArea = (areas: MetaSubjects): boolean => {
    return areas?.skills?.length === 0 ?? true;
};

export const noSkillInParallel = (parallel: ParallelSkills): boolean => {
    const noSkills = (parallel.metasubjects ?? []).reduce((noSkills, area) => noSkills && noSkillInArea(area), true);
    return noSkills;
};

import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { CourseGroup } from 'types/courseGroup';

interface Params {
    course?: number | string;
    subject?: number | string;
}

export const getCourseGroups = async (params?: Params): Promise<CourseGroup[]> => {
    return await api
        .get<never, AxiosResponse<CourseGroup[]>>('/course-groups/', { params })
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

import { TQuestionItemRequest } from 'types/lessonTask';

import { isFiltersUpdated } from './isFiltersUpdated';

type TGetUpdatedQuestions = Omit<TQuestionItemRequest, 'id'> & {
    id: number;
};
export const getUpdatedQuestions = (
    newList: TQuestionItemRequest[],
    oldList: TQuestionItemRequest[],
): TGetUpdatedQuestions[] => {
    const list = newList
        ?.filter(({ id }) => !!id && !!oldList.find((item) => item.id === id))
        ?.map((newItem) => {
            const oldItem = oldList
                .filter(({ id }) => !!id)
                .find(({ id, questionNumber }) => id === newItem.id && questionNumber === newItem.questionNumber);

            const oldItemsByNumber = oldList.filter(
                ({ id, questionNumber }) => !!id && questionNumber === newItem.questionNumber,
            );

            const isUpdatedNumber = !oldItemsByNumber.map((item) => item.id).includes(newItem.id);

            const isUpdatedFilters =
                oldItem && newItem ? isFiltersUpdated(newItem.filters, oldItem.filters, true) : false;
            if (isUpdatedFilters || isUpdatedNumber) {
                return newItem;
            }
        });

    //@ts-ignore
    return list.filter(Boolean).sort((a, b) => a.questionNumber - b.questionNumber);
};

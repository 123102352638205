import { getApiSsoRequest, postApiSsoRequest } from 'api';
import {
    ssoLoginUrl,
    ssoLogoutAllUrl,
    ssoLogoutUrl,
    ssoRefreshUrl,
    ssoResetPasswordConfirmUrl,
    ssoResetPasswordUrl,
    ssoResetPasswordValidateUrl,
    ssoVerifyUrl,
} from 'src-new/constants/authUrl';
import { TAccess, TLogin, TLoginRes } from 'types/auth';

import { getRefreshToken } from 'utils/token';

export const ssoLogin = (params: TLogin): Promise<TLoginRes> => {
    return postApiSsoRequest(ssoLoginUrl, { ...params });
};
export const ssoLogoutAll = (refreshToken: string): Promise<void> => {
    return postApiSsoRequest(ssoLogoutAllUrl, { refresh: refreshToken });
};
export const ssoLogout = (params?: { refresh?: string }): Promise<void> => {
    return postApiSsoRequest(ssoLogoutUrl, params);
};
export const ssoRefreshToken = (): Promise<TAccess> => {
    const refresh = getRefreshToken();
    if (refresh) {
        return postApiSsoRequest(ssoRefreshUrl, { refresh });
    }
    return postApiSsoRequest(ssoRefreshUrl);
};
export const ssoVerifyToken = (token: string): Promise<void> => {
    return postApiSsoRequest(ssoVerifyUrl, { token: token });
};
export const ssoPasswordReset = (params: { email: string; realm: string }): Promise<void> => {
    return postApiSsoRequest(ssoResetPasswordUrl, params);
};
export const ssoPasswordResetConfirm = (params: { password: string; token: string }): Promise<void> => {
    return postApiSsoRequest(ssoResetPasswordConfirmUrl, params);
};
export const ssoPasswordResetValidate = (token: string): Promise<void> => {
    return getApiSsoRequest(`${ssoResetPasswordValidateUrl}?token=${token}`);
};

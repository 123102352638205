import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    createCourseGroupMeetingFile,
    createCourseMeetingFile,
    deleteCourseGroupMeetingFile,
    deleteCourseMeetingFile,
    getCourseGroupMeetingFiles,
    getCourseMeetingFiles,
    updateCourseGroupMeetingFile,
    updateCourseMeetingFile,
} from 'api/services/meetingFiles';
import { UserInfo } from 'api/services/user';
import {
    TCreateCourseGroupMeetingFilePayload,
    TCreateCourseMeetingFilePayload,
    TDeleteMeetingFilePayload,
    TGetCourseGroupMeetingFilesPayload,
    TGetCourseMeetingFilesPayload,
    TUpdateCourseGroupMeetingFilePayload,
    TUpdateCourseMeetingFilePayload,
} from 'types/meetingFiles';

// Получение списка файлов к уроку
export const getCourseMeetingFilesAction = createAsyncThunk(
    'meetingFiles/getCourseMeetingFiles',
    async (params: TGetCourseMeetingFilesPayload) => {
        return await getCourseMeetingFiles(params);
    },
);
export const getCourseGroupMeetingFilesAction = createAsyncThunk(
    'meetingFiles/getCourseGroupMeetingFiles',
    async (params: TGetCourseGroupMeetingFilesPayload) => {
        return await getCourseGroupMeetingFiles(params);
    },
);

// Создание файла к уроку
export const createMeetingFileAction = createAsyncThunk(
    'meetingFiles/createMeetingFile',
    async (payload: {
        params: TCreateCourseMeetingFilePayload | TCreateCourseGroupMeetingFilePayload;
        isCourseGroup: boolean;
        userInfo?: UserInfo;
    }) => {
        const { params, isCourseGroup, userInfo } = payload;

        return {
            isCourseGroup,
            list: isCourseGroup
                ? await createCourseGroupMeetingFile(params as TCreateCourseGroupMeetingFilePayload)
                : await createCourseMeetingFile(params as TCreateCourseMeetingFilePayload),
            uploadedBy: userInfo
                ? {
                      firstName: userInfo.firstName,
                      lastName: userInfo.lastName,
                      patronymic: userInfo.patronymic,
                      id: userInfo.id,
                  }
                : undefined,
        };
    },
);

// Редактирование файла
export const updateMeetingFileAction = createAsyncThunk(
    'meetingFiles/updateMeetingFile',
    async (payload: {
        params: TUpdateCourseMeetingFilePayload | TUpdateCourseGroupMeetingFilePayload;
        isCourseGroup: boolean;
        lessonScheduleId?: number;
    }) => {
        const { params, isCourseGroup, lessonScheduleId } = payload;

        let courseGroupMeetingFiles = undefined;
        const res = isCourseGroup
            ? await updateCourseGroupMeetingFile(params as TDeleteMeetingFilePayload)
            : await updateCourseMeetingFile(params as TUpdateCourseMeetingFilePayload);

        if (isCourseGroup && params.isTemplate === false && lessonScheduleId) {
            courseGroupMeetingFiles = await getCourseGroupMeetingFiles({ lessonSchedule: lessonScheduleId });
        }
        return {
            updatedFile: res.update[0],
            isCourseGroup,
            courseGroupMeetingFiles,
        };
    },
);

// Удаление файла
export const deleteMeetingFileAction = createAsyncThunk(
    'meetingFiles/deleteMeetingFile',
    async (params: TDeleteMeetingFilePayload) => {
        const { id, isCourseGroup } = params;

        return {
            isCourseGroup,
            list: isCourseGroup ? await deleteCourseGroupMeetingFile([id]) : await deleteCourseMeetingFile([id]),
        };
    },
);

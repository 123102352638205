import { api, patchApiRequest } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { CourseType } from 'types/course';

import { ResponseCourseType } from '.';

export interface PatchCourseParams {
    id: number;
    subjectAlterTitle?: string;
    parallels?: number[];
    title?: string;
    description?: string;
    aboutTeacher?: string;
    task?: string;
    lessonCount?: number;
    courseType?: CourseType[];
    duration?: number;
    preview?: File | string;
    courseVideo?: File | string;
    methodists?: number[];
}

export const patchCourse = async (params: PatchCourseParams): Promise<ResponseCourseType> => {
    const {
        id,
        subjectAlterTitle,
        parallels,
        title,
        description,
        aboutTeacher,
        task,
        lessonCount,
        courseType,
        duration,
        preview,
        courseVideo,
        methodists,
    } = params;

    const hasMediaFiles = preview !== undefined || courseVideo !== undefined;
    const hasOtherData =
        subjectAlterTitle !== undefined ||
        parallels !== undefined ||
        title !== undefined ||
        description !== undefined ||
        aboutTeacher !== undefined ||
        task !== undefined ||
        lessonCount !== undefined ||
        courseType !== undefined ||
        duration !== undefined ||
        methodists !== undefined;

    const jsonParams = { ...params };
    delete jsonParams.preview;
    delete jsonParams.courseVideo;

    let mediaRes = undefined,
        otherDataRes = undefined;

    if (hasMediaFiles) {
        const formData = new FormData();
        if (preview !== undefined) formData.append('preview', preview);
        if (courseVideo !== undefined) formData.append('courseVideo', courseVideo);

        mediaRes = await api
            .patch<never, AxiosResponse<ResponseCourseType>>(`/course/partial-update/${id}/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Accept: 'application/json',
                },
            })
            .then((res) => ({
                ...res?.data,
                id: params.id,
            }))
            .catch((err: AxiosError<Record<string, string>>) => {
                throw JSON.stringify(err.response?.data);
            });
    }
    if (hasOtherData) {
        otherDataRes = await api
            .patch<never, AxiosResponse<ResponseCourseType>>(`/course/partial-update/${id}/`, jsonParams)
            .then((res) => ({
                ...res?.data,
                id: params.id,
            }))
            .catch((err: AxiosError<Record<string, string>>) => {
                throw JSON.stringify(err.response?.data);
            });
    }

    if (mediaRes && otherDataRes) {
        return {
            ...mediaRes,
            ...otherDataRes,
        };
    } else {
        //@ts-ignore
        return mediaRes ? mediaRes : otherDataRes;
    }
};

import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { IHomeworkItem } from 'types/homework';

import { createUrlQuery } from 'utils/createUrlQuery';

export const getStudentsTasks = async (lessons: number[], students: number[]): Promise<IHomeworkItem[]> => {
    const queryString = createUrlQuery({
        lesson_ids: lessons,
        student_ids: students,
    });

    return await api
        .get<never, AxiosResponse<IHomeworkItem[]>>(`assignment-progress/${queryString}`)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

import React from 'react';
import { cn } from '@bem-react/classname';
import { Button, ConfigProvider } from 'antd';

import { TAppButtonProps } from './AppButton.types';
const CnAppCalendar = cn('app-button');
import { LoaderIcon } from 'assets';
import { AppText } from 'src-new/ui';

import { getAppButtonTheme, getButtonLoaderColor } from './AppButton.utils';

import './AppButton.scss';

const AppButton = (props: TAppButtonProps): JSX.Element => {
    const {
        type = 'primary',
        mainColor = 'blue',
        isLoading,
        size,
        text,
        disabled,
        leftElem,
        rightElem,
        onButtonClick,
    } = props;

    const theme = getAppButtonTheme(type, mainColor, disabled);

    return (
        <ConfigProvider theme={theme}>
            <Button
                prefixCls={CnAppCalendar()}
                className={CnAppCalendar({ isNoText: !text })}
                type={type}
                size={size ? size : 'middle'}
                disabled={disabled || isLoading}
                onClick={() => onButtonClick()}
            >
                {isLoading && (
                    <div className={CnAppCalendar(`loader rotating ${getButtonLoaderColor(mainColor, type)}`)}>
                        <LoaderIcon />
                    </div>
                )}
                <div className={CnAppCalendar('body', { isLoading })}>
                    {leftElem && <div className={CnAppCalendar('elem', { isDisabled: disabled })}>{leftElem}</div>}
                    {text && <AppText text={text} fontStyle={'fontBody'} />}
                    {rightElem && <div className={CnAppCalendar('elem', { isDisabled: disabled })}>{rightElem}</div>}
                </div>
            </Button>
        </ConfigProvider>
    );
};

export default AppButton;

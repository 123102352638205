import { MeetingFileData } from 'api/services/meeting';
import { IFile } from 'types/file';

type OnlineLessonTabFile = IFile & { isTemplate?: boolean; fileName: string };

export const getFiles = (meetingFiles?: MeetingFileData[]): OnlineLessonTabFile[] => {
    return (
        meetingFiles?.map((file) => ({
            id: file.id,
            title: file.title ?? '',
            fileExtension: file.title ? file.title?.split('.')[file.title?.split('.').length - 1] : '',
            fileUrl: file.fileUrl,
            isAvailableToStudents: file.isAvailableToStudents,
            shouldUseOnConference: file.useOnMeeting,
            fileName: file.title ?? '',
            isTemplate: file.lesson !== null,
        })) ?? []
    );
};

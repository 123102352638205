import moment from 'moment';

import { getEarliestDate } from './date';

type Progress = {
    id: number | string;
    title?: string;
    assignment: {
        id: number;
    };
    courseGroup: {
        id: number;
    };
    deadlineForComplete?: string;
};

/**
 *
 * @param assignmentProgresses assignemnt-progresses from server
 * @returns combined homeworks for render in pages
 */
export function combineHomeworks<T extends Progress>(
    assignmentProgresses: Array<T>,
): Array<T & { assignmentCount?: number }> {
    return assignmentProgresses
        .reduce((homeworkItems, hwProgress) => {
            const hwProgressIndex = homeworkItems.findIndex(
                (item) =>
                    item.assignment.id === hwProgress.assignment.id &&
                    item.courseGroup?.id === hwProgress.courseGroup?.id,
            );

            if (hwProgressIndex >= 0) {
                const currentItem = { ...homeworkItems[hwProgressIndex] };
                const deadline = getEarliestDate([
                    currentItem.deadlineForComplete || '',
                    hwProgress.deadlineForComplete || '',
                ]);
                homeworkItems[hwProgressIndex] = {
                    ...currentItem,
                    id: `${currentItem.id}`,
                    deadlineForComplete: deadline,
                    assignmentCount: currentItem?.assignmentCount ? currentItem.assignmentCount + 1 : 1,
                };
            } else {
                homeworkItems.push({
                    ...hwProgress,
                    id: `${hwProgress.id}`,
                    title: hwProgress.title,
                    assignmentCount: 1,
                });
            }

            return homeworkItems;
        }, [] as Array<T & { assignmentCount?: number }>)
        .slice()
        .sort((a, b) => moment(a.deadlineForComplete).diff(b.deadlineForComplete));
}

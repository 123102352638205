import { IAppTableItems, IAppTableRow } from 'src-new/ui/table/app-table/AppTable.types';
import { deleteEmptyParams, formatJSToTableDate, getTableDateToParamsDate } from 'src-new/utils';
import { ICreateSchoolHolidaysParam, ISchoolHoliday, IUpdateSchoolHolidaysParam } from 'types/calendarPlan';

const HOLIDAY_FIRST_ROW_CELL_WIDTH = 200;

const holidayHeaderRow: IAppTableRow = [
    { title: 'Каникулы', cellWidth: HOLIDAY_FIRST_ROW_CELL_WIDTH },
    { title: 'Дата начала', subtitle: '(задается вручную)' },
    { title: 'Дата окончания', subtitle: '(задается вручную)' },
];

const holidayFooterRow: IAppTableRow = [
    { isAddRow: true, title: 'Добавить период', cellWidth: HOLIDAY_FIRST_ROW_CELL_WIDTH },
    { title: '' },
];

/**
 * @description проверяем на наличие ненулевых значений кол-ва учебных недель
 * @param {IAppTableItems} table
 * @return boolean
 */
const isEmptyWeeksCount = (table?: IAppTableItems): boolean => {
    const slicedTable = table?.slice(1, -1) ?? [];
    if (slicedTable.length == 0) {
        return false;
    }
    return slicedTable.some((item) => {
        const title = item.find((cell) => !!cell.isInput)?.title;
        if (title !== undefined) {
            return title === '' || title === 0;
        }
    });
};

/**
 * @description проверяем у всех ли строк есть названия
 * @param {IAppTableItems} table
 * @return boolean
 */
const isEmptyHolidayName = (table?: IAppTableItems): boolean => {
    const slicedTable = table?.slice(1, -1) ?? [];
    if (slicedTable.length == 0) {
        return false;
    }
    return !slicedTable.every((item) => {
        const { title } = item?.[0] || {};
        return title != undefined && title != '';
    });
};

/**
 * @description формируем строку для таблицы каникул
 * @param {ISchoolHoliday} item
 * @return IAppTableRow
 */
const getHolidayRow = (item?: ISchoolHoliday): IAppTableRow => {
    const { title, dateStart, dateEnd, id } = item || {};
    return [
        {
            title: title,
            id: id,
            isInput: true,
            noCanEmpty: true,
            cellWidth: HOLIDAY_FIRST_ROW_CELL_WIDTH,
            isDeleteRow: true,
        },
        { title: dateStart ? formatJSToTableDate(dateStart) : undefined, isActive: true, type: 'date' },
        { title: dateEnd ? formatJSToTableDate(dateEnd) : undefined, isActive: true, type: 'date' },
    ];
};

/**
 * @description удаляем строку
 * @param {IAppTableItems} holidays
 * @param {number} row
 * @return IAppTableItems
 */
const delRowFromHolidayTable = (holidays: IAppTableItems, row: number): IAppTableItems => {
    return [...holidays.slice(0, row), ...holidays.slice(row + 1)];
};

/**
 * @description добавляем строку
 * @param {IAppTableItems} holidays
 * @return IAppTableItems
 */
const addNewRowToHolidayTable = (holidays: IAppTableItems): IAppTableItems => {
    const holidaysLength = holidays.length;
    return [...holidays.slice(0, holidaysLength - 1), getHolidayRow(), ...holidays.slice(holidaysLength - 1)];
};

/**
 * @description формируем ОБНОВЛЕННЫЕ данные о каникулах для отправки на бэк
 * @param {IAppTableItems} table
 * @return Array<IUpdateSchoolHolidaysParam
 */
const getUpdateHolidayParams = (table?: IAppTableItems): Array<IUpdateSchoolHolidaysParam> | undefined => {
    const slicedTable = table?.slice(1, -1);
    const params = slicedTable?.map((item) => {
        const { id, title } = item[0];
        const { title: dateStart } = item[1];
        const { title: dateEnd } = item[2];
        if (id && title) {
            const formatDateStart = dateStart ? getTableDateToParamsDate(dateStart?.toString()) : undefined;
            const formatDateEnd = dateEnd ? getTableDateToParamsDate(dateEnd?.toString()) : undefined;

            return (
                deleteEmptyParams({
                    id: Number(id),
                    title: title?.toString(),
                    dateStart: formatDateStart,
                    dateEnd: formatDateEnd,
                }) ?? ({} as IUpdateSchoolHolidaysParam)
            );
        }
        return {} as IUpdateSchoolHolidaysParam;
    });
    const filteredParams = params?.filter((item) => Object.keys(item).length !== 0);
    return filteredParams?.length == 0 ? undefined : filteredParams;
};

/**
 * @description формируем СОЗДАННЫЕ данные о каникулах для отправки на бэк
 * @param {number} planId
 * @param {IAppTableItems} table
 * @return Array<IUpdateSchoolHolidaysParam
 */
const getCreateHolidayParams = (
    planId: number,
    table?: IAppTableItems,
): Array<ICreateSchoolHolidaysParam> | undefined => {
    const slicedTable = table?.slice(1, -1);
    const params = slicedTable?.map((item) => {
        const { id, title } = item[0];
        const { title: dateStart } = item[1];
        const { title: dateEnd } = item[2];
        if (title && !id) {
            const formatDateStart = dateStart ? getTableDateToParamsDate(dateStart?.toString()) : undefined;
            const formatDateEnd = dateEnd ? getTableDateToParamsDate(dateEnd?.toString()) : undefined;
            const params = {
                calendarPlan: planId,
                title: title?.toString(),
                dateStart: formatDateStart,
                dateEnd: formatDateEnd,
            };
            return deleteEmptyParams(params) ?? ({} as ICreateSchoolHolidaysParam);
        }
        return {} as ICreateSchoolHolidaysParam;
    });
    const filteredParams = params?.filter((item) => Object.keys(item).length !== 0);
    return filteredParams?.length == 0 ? undefined : filteredParams;
};

/**
 * @description формируем таблицу с каникулами
 * @param {Array<ISchoolHoliday>} schoolHolidays
 * @return IAppTableItems
 */
const getHolidayTable = (schoolHolidays?: Array<ISchoolHoliday>): IAppTableItems => {
    const rows = schoolHolidays?.map((item) => getHolidayRow(item));
    if (rows) {
        return [holidayHeaderRow, ...rows, holidayFooterRow];
    }
    return [holidayHeaderRow, holidayFooterRow];
};

export {
    addNewRowToHolidayTable,
    delRowFromHolidayTable,
    getCreateHolidayParams,
    getHolidayTable,
    getUpdateHolidayParams,
    isEmptyHolidayName,
    isEmptyWeeksCount,
};

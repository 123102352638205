import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AppCheckbox, AppFontIcon, AppText } from '@frontend-modules/ui-kit';
import { useRole } from 'hooks';
import { MAX_FILE_SIZE_MB } from 'src-new/constants';
import { AppFileUploader } from 'src-new/ui';
import { setMessage } from 'src-new/utils/message';
import {
    createMeetingFileAction,
    deleteMeetingFileAction,
    getCourseGroupMeetingFilesAction,
    getCourseMeetingFilesAction,
    updateMeetingFileAction,
} from 'store/actions/meetingFiles';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';
import {
    TCreateCourseGroupMeetingFilePayload,
    TCreateCourseMeetingFilePayload,
    TUpdateCourseGroupMeetingFilePayload,
    TUpdateCourseMeetingFilePayload,
} from 'types/meetingFiles';

import { TFilesContainerProps } from './FilesContainer.types';

import './FilesContainer.scss';

type TFile = {
    id: number;
    isAvailableToStudents?: boolean;
    useOnMeeting?: boolean;
    isTemplate: boolean;
    meetingFileId?: number;
    fileUrl: string;
    title: string;
    extension: string;
};
export const FilesContainer: React.FC<TFilesContainerProps> = ({
    lessonId,
    lessonScheduleId,
    courseGroupId,
    isOvered,
}) => {
    const dispatch = useDispatch();
    const { isTeacher } = useRole();

    const { courseMeetingFiles, courseGroupMeetingFiles, getMeetingFilesStatus } = useAppSelector(
        (store) => store.meetingFiles,
    );
    const isCourseGroup = !!courseGroupId && !!lessonScheduleId;

    const files: TFile[] = useMemo(() => {
        if (courseGroupMeetingFiles.length) {
            return courseGroupMeetingFiles.map((file) => {
                const { id, isAvailableToStudents, meetingFile, useOnMeeting } = file;
                const { id: meetingFileId, fileUrl, isTemplate, title, extension } = meetingFile;
                return {
                    id,
                    isAvailableToStudents,
                    useOnMeeting,
                    isTemplate,
                    meetingFileId,
                    fileUrl,
                    title,
                    extension,
                };
            });
        }
        if (courseMeetingFiles.length) {
            return courseMeetingFiles;
        }
        return [];
    }, [courseMeetingFiles, courseGroupMeetingFiles]);

    const needFetchFiles = getMeetingFilesStatus === FetchStatus.INITIAL;

    const isCanUpload = isTeacher && isCourseGroup;
    const isShowContent = !!files.length || isCanUpload;

    useEffect(() => {
        if (needFetchFiles) {
            if (isCourseGroup) {
                //@ts-ignore
                dispatch(getCourseGroupMeetingFilesAction({ lessonSchedule: lessonScheduleId }));
            } else if (lessonId && !courseGroupId) {
                dispatch(getCourseMeetingFilesAction({ lesson: lessonId }));
            }
        }
    }, [dispatch]);

    const handleUploadFile = useCallback(
        (file: File) => {
            const isCourseGroup = !!lessonScheduleId;

            const params: TCreateCourseMeetingFilePayload | TCreateCourseGroupMeetingFilePayload = lessonScheduleId
                ? {
                      file,
                      lessonSchedule: lessonScheduleId,
                  }
                : {
                      file,
                      lesson: lessonId,
                  };

            if (file) {
                dispatch(
                    createMeetingFileAction({
                        params,
                        isCourseGroup,
                    }),
                );
            }
        },
        [dispatch, isTeacher, lessonId, lessonScheduleId],
    );

    const onFileCheckbox = (
        isChecked: boolean,
        fileId: number,
        fieldName: 'isAvailableToStudents' | 'useOnMeeting',
    ) => {
        const params: TUpdateCourseMeetingFilePayload | TUpdateCourseGroupMeetingFilePayload = {
            id: fileId,
        };
        params[fieldName] = isChecked;
        dispatch(
            updateMeetingFileAction({
                params,
                isCourseGroup,
            }),
        );
    };
    const onFileClick = (url: string) => {
        window.open(url);
    };
    const onDeleteFile = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, fileId: number) => {
        event.stopPropagation();

        dispatch(
            deleteMeetingFileAction({
                id: fileId,
                isCourseGroup,
            }),
        );
    };
    const onCopyFileUrl = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, fileUrl: string) => {
        event.stopPropagation();

        void navigator.clipboard
            .writeText(fileUrl)
            .then(() => {
                setMessage({ text: 'Ссылка успешно скопирована!', status: 'success' });
            })
            .catch(() => {
                setMessage({ text: 'Не удалось скопировать ссылку!', status: 'error' });
            });
    };

    return (
        <div className={'lessons-file-container'}>
            {!isShowContent ? (
                <></>
            ) : (
                <>
                    <AppText text={'Файлы занятия'} textStyle={'DesktopH3'} />
                    <div className={`lessons-file-container__files ${isOvered ? 'isOvered' : ''}`}>
                        {!!files.length && (
                            <div className={'lessons-file-container__files_row'}>
                                <AppText text={'Имя файла'} color={'#C0C2C9'} textStyle={'Little'} />
                                {isCourseGroup && (
                                    <>
                                        <AppText
                                            className={'access-col'}
                                            text={'Доступно ученикам'}
                                            color={'#C0C2C9'}
                                            textStyle={'Little'}
                                        />
                                        <AppText
                                            className={'usage-col'}
                                            text={'Использовать на онлайн-занятии'}
                                            color={'#C0C2C9'}
                                            textStyle={'Little'}
                                        />
                                    </>
                                )}
                            </div>
                        )}
                        {files?.map((file) => {
                            const {
                                id,
                                title,
                                fileUrl,
                                isTemplate,
                                isAvailableToStudents,
                                useOnMeeting,
                                meetingFileId,
                                extension,
                            } = file;
                            const name = `${title}.${extension}`;
                            const fileName = name.length > 55 ? `${name.slice(0, 30)}...${name.slice(-10)}` : name;
                            return (
                                <div key={meetingFileId} className={'lessons-file-container__files_row'}>
                                    <div className={'name-col'} onClick={() => onFileClick(fileUrl)}>
                                        <div
                                            className={`name-col__file-title ${name.length > 55 ? 'hasAttr' : ''}`}
                                            data-title={name}
                                        >
                                            <AppFontIcon
                                                icon={isTemplate ? 'noteblank-shb-l' : 'noteblank-m'}
                                                color={'#0088E9'}
                                                size={24}
                                            />
                                            <AppText text={fileName} />
                                        </div>
                                        <div className={'name-col__file-controls'}>
                                            <div
                                                className={'name-col__file-controls_btn'}
                                                onClick={(event) => onCopyFileUrl(event, fileUrl)}
                                            >
                                                <AppFontIcon icon={'copy-l'} color={'#0088E9'} />
                                            </div>
                                            {!isTemplate && isCourseGroup && (
                                                <div
                                                    className={'name-col__file-controls_btn'}
                                                    onClick={(event) => onDeleteFile(event, id)}
                                                >
                                                    <AppFontIcon icon={'delete-m'} color={'#ea5959'} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {isCourseGroup && (
                                        <>
                                            <div className={'access-col'}>
                                                <AppCheckbox
                                                    label={''}
                                                    onChange={(isChecked) =>
                                                        onFileCheckbox(isChecked, id, 'isAvailableToStudents')
                                                    }
                                                    defaultValue={!!isAvailableToStudents}
                                                />
                                            </div>
                                            <div className={'usage-col'}>
                                                <AppCheckbox
                                                    label={''}
                                                    onChange={(isChecked) =>
                                                        onFileCheckbox(isChecked, id, 'useOnMeeting')
                                                    }
                                                    defaultValue={!!useOnMeeting}
                                                />
                                            </div>
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    {isCanUpload && (
                        <div className={'lessons-file-container__add-file'}>
                            <AppFileUploader
                                isLoading={getMeetingFilesStatus === FetchStatus.FETCHING}
                                handleFileUpload={handleUploadFile}
                            />

                            <div className={'lessons-file-container__add-file_title'}>
                                <AppText text={'Добавить файл'} textStyle={'DesktopFontBody'} />
                                <AppText
                                    text={`(Объём файла до ${MAX_FILE_SIZE_MB}mb)`}
                                    textStyle={'DesktopFootNotes'}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

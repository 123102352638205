import { CodifierEventType } from 'components/Codifier/Codifier';

export type SelectedElement = {
    subjects?: number[];
    parallels?: number[];
    sections?: number[];
    blocks?: number[];
    topics?: number[];
};

const getElementsIds = (elements: number[] = [], id: number, withCheckboxes?: boolean): number[] =>
    withCheckboxes
        ? elements.includes(id)
            ? elements.filter((elementId) => elementId !== id)
            : [...elements, id]
        : [id];

export const getSelectedElements = (
    currentElements: SelectedElement,
    type: CodifierEventType,
    id: number,
    withCheckboxes?: boolean,
): SelectedElement => {
    const { subjects, parallels, sections, blocks, topics } = currentElements;
    switch (type) {
        case CodifierEventType.SUBJECT:
            return {
                subjects: getElementsIds(subjects, id, withCheckboxes),
                topics: withCheckboxes ? topics : [],
            };
        case CodifierEventType.PARALLEL:
            return {
                subjects,
                parallels: getElementsIds(parallels, id, withCheckboxes),
                topics: withCheckboxes ? topics : [],
            };
        case CodifierEventType.SECTION:
            return {
                subjects,
                parallels,
                sections: getElementsIds(sections, id, withCheckboxes),
                topics: withCheckboxes ? topics : [],
            };
        case CodifierEventType.BLOCK:
            return {
                subjects,
                parallels,
                sections,
                blocks: getElementsIds(blocks, id, withCheckboxes),
                topics: withCheckboxes ? topics : [],
            };
        case CodifierEventType.TOPIC:
            return {
                subjects,
                parallels,
                sections,
                blocks,
                topics: getElementsIds(topics, id, withCheckboxes),
            };
        default:
            return currentElements;
    }
};

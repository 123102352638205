import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { AppButton, AppModal, AppText } from '@frontend-modules/ui-kit';
import { Basket } from '@lms-elements/icons';
import { TaskProgressInterval } from 'containers/TaskBlock/TaskBlock.types';
import { Dayjs } from 'dayjs';
import { ValidationErrors } from 'final-form';
import { useRole } from 'hooks';
import { useFormError } from 'hooks/useFormError';
import moment, { Moment } from 'moment';
import { AppSelect } from 'src-new/ui';
import { useAppSelector } from 'store/store';

import { TaskView } from './TaskView/TaskView';
import { TaskDatePicker } from './TaskDatePicker';
import { TaskTimePicker } from './TaskTimePicker';
import {
    dateValidator,
    disabledDate,
    disabledHours,
    disabledMinutes,
    EDateTimeTypesList,
    getTaskDateInitialValues,
    getTimeDisableValue,
} from './utils';

import './Task.scss';

export interface ITaskDate {
    timeStart?: string;
    timeEnd?: string;
    startTimeDelay?: number;
    endTimeDelay?: number;
    timeToComplete?: number;
    assignedType?: EDateTimeTypesList;
    deadlineType: EDateTimeTypesList;
    durationType: EDateTimeTypesList;
}

interface ITaskProps {
    id?: number;
    title: string;
    isTemplate?: boolean;
    isForMethodist?: boolean;
    isPosted?: boolean;
    dates?: ITaskDate;
    offsetY?: number;
    handleSubmit?: (id: number, dates: ITaskDate) => void;
    handleDelete?: (id: number) => void;
    handleScroll?: (value: number) => void;
    onTitleClick?: (id: number) => void;
    onSelect?: (isSelect: boolean) => void;
    onDateSelect?: (dates: ITaskDate) => void;
    onTeacherSelect?: (id: number) => void;
    onlyDatesOptions?: boolean;
    disabledSelect?: boolean;
    canRepeatTask?: boolean;
    interval?: TaskProgressInterval;
}

const validate = (value: string) => (value ? undefined : 'error');

const CnTask = cn('task_block');

export const Task: React.FC<ITaskProps> = ({
    id = 0,
    title,
    isTemplate = false,
    isForMethodist = false,
    isPosted = false,
    dates,
    handleSubmit,
    handleDelete,
    onTitleClick,
    onSelect,
    onDateSelect,
    onTeacherSelect,
    onlyDatesOptions,
    disabledSelect,
    canRepeatTask = false,
    interval,
}) => {
    const { teachersList } = useAppSelector((store) => store.assignemnt);
    const { isPrincipal, isMethodist, isProctor } = useRole();
    const [isSelected, setIsSelected] = useState(canRepeatTask);
    const [isHovered, setIsHovered] = useState(false);
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);

    const [calendarStartType, setCalendarStartType] = useState<EDateTimeTypesList | undefined>(undefined);
    const [calendarEndType, setCalendarEndType] = useState<EDateTimeTypesList>(EDateTimeTypesList.NONE);
    const [calendarDurationType, setCalendarDurationType] = useState<EDateTimeTypesList>(EDateTimeTypesList.NONE);

    const handleMouseLeave = useCallback(() => {
        setIsHovered(false);
    }, []);

    const handleMouseEnter = useCallback(() => {
        setIsHovered(true);
    }, []);

    const handleDateTimeChanged = (type: 'start' | 'end' | 'duration', val: EDateTimeTypesList) => {
        switch (type) {
            case 'start':
                setCalendarStartType((prevState) => (prevState !== val ? val : EDateTimeTypesList.NONE));
                break;
            case 'end':
                setCalendarEndType(val);
                break;
            default:
            case 'duration':
                setCalendarDurationType((prevState) => (prevState !== val ? val : EDateTimeTypesList.NONE));
                break;
        }
    };

    const handleSelect = useCallback(() => {
        if (onSelect) {
            onSelect(isSelected);
        }
        setIsSelected(!isSelected);
    }, [isSelected, onSelect]);

    const options = useMemo(() => getTaskDateInitialValues(), []);

    const handleFormSubmit = useCallback(
        (value) => {
            if (handleSubmit) {
                handleSubmit(id, value);
            }
        },
        [handleSubmit, id],
    );

    const handleTitleClick = useCallback(() => onTitleClick?.(id), [onTitleClick, id]);

    const onCloseDeleteModal = () => {
        setIsShowDeleteModal(false);
    };

    const handleDeleteSubmit = useCallback(() => {
        if (handleDelete) {
            handleDelete(id);
            setIsSelected(false);
        }
    }, [handleDelete, id]);

    const handleFormError = useFormError('taskForm');

    const handleFormChange = useCallback(
        (state: { values: ITaskDate; errors: ValidationErrors }) => {
            handleFormError(state);
            const isEditableTask = isSelected && (!isPosted || canRepeatTask);
            if (onDateSelect && isEditableTask) {
                setTimeout(() => {
                    onDateSelect?.({
                        ...state.values,
                        assignedType: calendarStartType,
                        deadlineType: calendarEndType,
                        durationType: calendarDurationType,
                    });
                }, 0);
            }
        },
        [onDateSelect, handleFormError, calendarStartType, calendarEndType, calendarDurationType],
    );

    const taskBlockRef = useRef<HTMLDivElement>(null);

    const handleDropdownClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        const dropdownOptions = e.currentTarget?.querySelector('.select-suggest_focus');

        if (dropdownOptions) {
            dropdownOptions.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        }
    }, []);

    useEffect(() => {
        if (isSelected) {
            taskBlockRef.current?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        }
    }, [isSelected, calendarStartType, calendarEndType]);

    const handleTeacherSelect = (teacher: { value: string; label: ReactNode }) => {
        if (teacher) {
            onTeacherSelect?.(+teacher.value);
        } else {
            onTeacherSelect?.(0);
        }
    };

    const [momentDateTimeStart, momentDateTimeEnd] = useMemo<[Moment, Moment]>(
        () => [moment(interval?.datetimeStart), moment(interval?.datetimeEnd)],
        [interval],
    );

    const [formattedDayAndMonth, formattedDatetimeStart, formattedDatetimeEnd] = useMemo<[string, string, string]>(
        () => [
            momentDateTimeStart.format('DD.MM'),
            momentDateTimeStart.format('HH:mm'),
            momentDateTimeEnd.format('HH:mm'),
        ],
        [momentDateTimeStart, momentDateTimeEnd],
    );

    const teachersListNormalized = useMemo(() => {
        return teachersList.length
            ? teachersList
                  .map((item) => {
                      return {
                          value: item.id,
                          label: `${item.lastName} ${item.firstName} ${item.patronymic}`,
                      };
                  })
                  .sort((a, b) => (a.label > b.label ? 1 : -1))
            : [];
    }, [teachersList]);

    return (
        <>
            <Form
                onSubmit={handleFormSubmit}
                initialValues={dates}
                validate={(values) => dateValidator(values, interval, calendarStartType, calendarDurationType)}
            >
                {({ form, handleSubmit, errors }): ReactElement => (
                    <form onSubmit={handleSubmit}>
                        <div className={CnTask()} ref={taskBlockRef}>
                            <div
                                className={CnTask('title-buttons', { disabledSelect })}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <TaskView
                                    title={title}
                                    isTemplate={isTemplate}
                                    isPosted={isPosted}
                                    canRepeatTask={canRepeatTask}
                                    isForMethodist={isForMethodist}
                                    handleSelect={handleSelect}
                                    onTitleClick={handleTitleClick}
                                    withCheckbox={!disabledSelect}
                                />
                                {isHovered && (
                                    <div className={CnTask('buttons-block')}>
                                        {(!isTemplate || isForMethodist) && !isPosted && (
                                            <button type="button" onClick={() => setIsShowDeleteModal(true)}>
                                                <Basket />
                                            </button>
                                        )}
                                    </div>
                                )}
                            </div>
                            {interval && (
                                <p className={CnTask('interval')}>
                                    {formattedDayAndMonth} С {formattedDatetimeStart} по {formattedDatetimeEnd}
                                </p>
                            )}
                            {isSelected && (!isPosted || canRepeatTask) && (
                                <>
                                    <div className={CnTask('form')}>
                                        <div className={CnTask('form-block')} onClick={handleDropdownClick}>
                                            <span>Ученики начнут</span>
                                            <FormSpy<{ timeStart?: string }> subscription={{ values: true }}>
                                                {() => {
                                                    return (
                                                        <Field name={'timeStart'}>
                                                            {({ input }) => (
                                                                <AppSelect
                                                                    options={options.dateStartList}
                                                                    placeholder={'Выберите тип выдачи'}
                                                                    value={options.dateStartList.find(
                                                                        ({ value }) => value === calendarStartType,
                                                                    )}
                                                                    handleSelect={({ value }) => {
                                                                        handleDateTimeChanged(
                                                                            'start',
                                                                            value as EDateTimeTypesList,
                                                                        );
                                                                        form.change('startTimeDelay', undefined);
                                                                        form.change('timeStart', undefined);
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    );
                                                }}
                                            </FormSpy>
                                        </div>
                                        {!!calendarStartType && (
                                            <FormSpy<{ timeStart?: string; startTimeDelay?: number }>
                                                subscription={{ values: true }}
                                            >
                                                {({ values }) => {
                                                    const disabledTime = (date?: Dayjs) => {
                                                        const disableValues = getTimeDisableValue(date, interval);

                                                        return {
                                                            disabledHours: () =>
                                                                disabledHours(values.timeStart, disableValues),
                                                            disabledMinutes: (hour: number) =>
                                                                disabledMinutes(hour, values.timeStart, disableValues),
                                                        };
                                                    };

                                                    return calendarStartType === EDateTimeTypesList.TIME ? (
                                                        <TaskTimePicker
                                                            fieldName={'startTimeDelay'}
                                                            placeholder={'Через'}
                                                            status={errors?.startTimeDelay}
                                                            value={values?.startTimeDelay}
                                                            nullTimeText={'В начале занятия'}
                                                        />
                                                    ) : (
                                                        <TaskDatePicker
                                                            fieldName={'timeStart'}
                                                            placeholder={'Выберите время'}
                                                            value={values?.timeStart}
                                                            disabledDate={(date) => disabledDate(date, interval)}
                                                            disabledTime={disabledTime}
                                                            status={errors?.timeStart}
                                                        />
                                                    );
                                                }}
                                            </FormSpy>
                                        )}
                                        <div className={CnTask('form-block')} onClick={handleDropdownClick}>
                                            <span>Должны сдать</span>
                                            <AppSelect
                                                options={options.dateEndList}
                                                placeholder={'Выберите тип сдачи'}
                                                value={options.dateEndList.find(
                                                    ({ value }) => value === calendarEndType,
                                                )}
                                                handleSelect={({ value }) => {
                                                    handleDateTimeChanged('end', value as EDateTimeTypesList);
                                                    form.change('timeEnd', undefined);
                                                    form.change('endTimeDelay', undefined);
                                                }}
                                            />
                                        </div>
                                        {!!calendarEndType && (
                                            <FormSpy<{ timeEnd?: string; timeStart?: string; endTimeDelay?: number }>
                                                subscription={{ values: true }}
                                            >
                                                {({ values }) => {
                                                    const disabledTime = (date?: Dayjs) => {
                                                        const disableValues = getTimeDisableValue(date, interval);

                                                        return {
                                                            disabledHours: () =>
                                                                disabledHours(values.timeEnd, disableValues),
                                                            disabledMinutes: (hour: number) =>
                                                                disabledMinutes(hour, values.timeEnd, disableValues),
                                                        };
                                                    };

                                                    return calendarEndType === EDateTimeTypesList.TIME ? (
                                                        <TaskTimePicker
                                                            fieldName={'endTimeDelay'}
                                                            placeholder={'Через'}
                                                            status={errors?.endTimeDelay}
                                                            value={values?.endTimeDelay}
                                                        />
                                                    ) : (
                                                        <TaskDatePicker
                                                            fieldName={'timeEnd'}
                                                            placeholder={'Выберите время'}
                                                            value={values?.timeEnd}
                                                            disabledDate={(date) => disabledDate(date, interval)}
                                                            disabledTime={disabledTime}
                                                            status={errors?.timeEnd}
                                                        />
                                                    );
                                                }}
                                            </FormSpy>
                                        )}
                                        <div className={CnTask('form-block')} onClick={handleDropdownClick}>
                                            <span>Время на выполнение задания</span>
                                            <FormSpy<{ timeToComplete?: number }> subscription={{ values: true }}>
                                                {() => {
                                                    return (
                                                        <Field name={'timeToComplete'}>
                                                            {({ input }) => (
                                                                <AppSelect
                                                                    options={options.timeToComplete}
                                                                    placeholder={'Выберите время'}
                                                                    value={options.timeToComplete?.find(
                                                                        ({ value }) => value === calendarDurationType,
                                                                    )}
                                                                    defaultValue={options.timeToComplete?.[0]}
                                                                    handleSelect={({ value }) => {
                                                                        handleDateTimeChanged(
                                                                            'duration',
                                                                            value as EDateTimeTypesList,
                                                                        );
                                                                        if (!value) {
                                                                            form.change('timeToComplete', undefined);
                                                                        } else {
                                                                            form.change('timeToComplete', 0);
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                    );
                                                }}
                                            </FormSpy>
                                        </div>
                                        {!!calendarDurationType && (
                                            <FormSpy<{ timeToComplete?: number }> subscription={{ values: true }}>
                                                {({ values }) => {
                                                    return (
                                                        <TaskTimePicker
                                                            fieldName={'timeToComplete'}
                                                            placeholder={'Выберите время'}
                                                            status={errors?.timeToComplete}
                                                            value={values?.timeToComplete ?? 0}
                                                            valuePrefix={''}
                                                        />
                                                    );
                                                }}
                                            </FormSpy>
                                        )}
                                    </div>
                                    {(isPrincipal || isMethodist || isProctor) && (
                                        <div className={CnTask('teacher')}>
                                            <AppText
                                                text={'Ответственный преподаватель'}
                                                textStyle={'DesktopFootNotes'}
                                            />
                                            <AppSelect
                                                placeholder="Aвтоматически"
                                                options={teachersListNormalized}
                                                showSearch
                                                size={'large'}
                                                notFoundContent={'Ничего не найдено'}
                                                filterOption={(input, option) =>
                                                    (String(option?.label).toLowerCase() ?? '').includes(
                                                        input.toLowerCase(),
                                                    )
                                                }
                                                allowClear
                                                handleSelect={(teacher) => handleTeacherSelect(teacher)}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        <FormSpy subscription={{ errors: true, values: true }} onChange={handleFormChange} />
                    </form>
                )}
            </Form>
            {isShowDeleteModal && (
                <AppModal
                    width={320}
                    isShow={isShowDeleteModal}
                    onCancel={onCloseDeleteModal}
                    setShow={setIsShowDeleteModal}
                    className={CnTask('delete-modal')}
                    isShowCloseIcon={false}
                    title={'Вы действительно хотите удалить задание?'}
                >
                    <div className={CnTask('delete-modal-buttons')}>
                        <AppButton label={'Удалить'} onClick={handleDeleteSubmit} type={'primary'} />
                        <AppButton label={'Отмена'} onClick={onCloseDeleteModal} type={'outline'} />
                    </div>
                </AppModal>
            )}
        </>
    );
};

import { getApiRequest, patchApiRequest } from 'api';
import { StaffProfile } from 'types/staffProfile';
import { UserRoles } from 'types/user';

export type UpdateStaffProfileData = {
    subjectIds: number[];
    about: string;
    specialization: string[];
    currentPosition: string[];
    education: string[];
    achievements: string[];
    upProfessional: string[];
};

export const getStaffProfiles = async (params: {
    userRole?: UserRoles;
    subjects?: number[];
}): Promise<StaffProfile> => {
    return await getApiRequest('/staff-profile/', {
        params: {
            user_role: params.userRole,
            subject: params.subjects?.join(','),
        },
    });
};

export const updateStaffProfile = async (params: {
    data: UpdateStaffProfileData;
    staffProfileId: number;
}): Promise<StaffProfile> => {
    return await patchApiRequest(`/staff-profile/partial-update/${params.staffProfileId}/`, params.data);
};

export const getStaffProfileById = async (staffProfileId: number): Promise<StaffProfile> => {
    return await getApiRequest(`/staff-profile/${staffProfileId}/`);
};

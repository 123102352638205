/**
 * @description проверяет переменную на пустоту
 * @param value
 * @param isCheckZeroInNumber - если true будет проверять число на 0 значение
 */
const isEmpty = (value: string | number | undefined | null | object, isCheckZeroInNumber?: boolean): boolean => {
    const isZero = isCheckZeroInNumber ? value === 0 : false;
    return value === null || value === undefined || value === '' || isZero;
};

/**
 * @description проверяет переменную на НЕ пустоту ))
 * @param value
 * @param isCheckZeroInNumber
 */
const isExist = (value: string | number | undefined | null | object, isCheckZeroInNumber?: boolean): boolean => {
    return !isEmpty(value, isCheckZeroInNumber);
};

/**
 * @description проверяет список на пустоту
 * @param value
 */
const isEmptyList = <T>(value?: Array<T>): boolean => {
    return value === undefined || value === null || value?.length <= 0;
};

/**
 * @description проверяет список на существование
 * @param value
 */
const isListExist = <T>(value?: Array<T>): boolean => {
    return !isEmptyList(value);
};

export { isEmpty, isEmptyList, isExist, isListExist };

export const getQueryStringParams = (query: string): { [key: string]: string } => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
              const [key, value] = param.split('=');
              // @ts-ignore
              params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
              return params;
          }, {})
        : {};
};

export const getUrlParamsString = (params: { [key: string]: any }): string => {
    return Object.entries(params).reduce((result, [key, val]) => {
        if (!result && val !== undefined && val !== null) {
            result = `?${key}=${val}`;
        } else if (val !== undefined && val !== null) {
            result += `&${key}=${val}`;
        }
        return result;
    }, '');
};

import {
    TGetCriteriaGroupsProps,
    TGetCriteriaSettingProps,
    TGetMonitoringStaffListProps,
    TGetMonitoringSubjectsListProps,
    TGetQuestionsCreatedProgressProps,
    TGetSummaryTableDataProps,
    TGetTeachersCriteriaStatisticProps,
    TGetTeachesWorkDaysProps,
    TQuestionsCreatedDiagramDataFilters,
    TQuestionsCreatedTableDataProps,
    TSummaryChartDataFilters,
} from '@fsd-shared/models/statisticsMonitoring';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
    getCriteriaGroupsList,
    getCriteriaSetting,
    getMonitoringStaffList,
    getMonitoringSubjectsList,
    getQuestionsCreatedDiagramData,
    getQuestionsCreatedProgress,
    getQuestionsCreatedTableData,
    getSummaryChartData,
    getSummaryTableData,
    getTeacherMonitoringData,
    getTeachersCriteriaStatistic,
    getTeachersQuestionsCreatedDiagram,
    getTeachesInfo,
    getTeachesWorkDays,
} from './statisticsMonitoringApi';

//список учителей
export const getMonitoringStaffListAction = createAsyncThunk(
    'statisticsMonitoring/getMonitoringStaffList',
    async (props?: TGetMonitoringStaffListProps) => {
        const res = await getMonitoringStaffList({
            ...props,
            roles: ['teacher'],
            currentPage: props?.currentPage ?? 1,
            limit: props?.limit ?? 10,
        });
        return {
            ...res,
            search: props?.search,
        };
    },
);
//список предметов
export const getMonitoringSubjectsListAction = createAsyncThunk(
    'statisticsMonitoring/getMonitoringSubjectsList',
    async (props?: TGetMonitoringSubjectsListProps) => {
        const res = await getMonitoringSubjectsList({
            search: props?.search,
            currentPage: props?.currentPage ?? 1,
            limit: props?.limit ?? 10,
        });
        return {
            ...res,
            search: props?.search,
        };
    },
);

export const getTeacherMonitoringDataAction = createAsyncThunk(
    'statisticsMonitoring/getTeacherMonitoringData',
    async (date?: string) => {
        return getTeacherMonitoringData(date);
    },
);
//список групп критериев
export const getCriteriaGroupsListAction = createAsyncThunk(
    'statisticsMonitoring/getCriteriaGroupsList',
    async (props?: TGetCriteriaGroupsProps) => {
        return getCriteriaGroupsList(props);
    },
);

//настройка критериев за месяц
export const getCriteriaSettingAction = createAsyncThunk(
    'statisticsMonitoring/getCriteriaSetting',
    async (props: TGetCriteriaSettingProps) => {
        return await getCriteriaSetting(props);
    },
);
//сводная таблица
export const getSummaryTableDataAction = createAsyncThunk(
    'statisticsMonitoring/getSummaryTableData',
    async (props: TGetSummaryTableDataProps) => {
        const defaultProps = {
            ...props,
            currentPage: props.currentPage ?? 1,
            limit: props.limit ?? 10,
        };
        return await getSummaryTableData(defaultProps);
    },
);
//информация об учителе из сводной таблицы
export const getTeachersCriteriaStatisticAction = createAsyncThunk(
    'statisticsMonitoring/getTeachersCriteriaStatistic',
    async (props: TGetTeachersCriteriaStatisticProps) => {
        return await getTeachersCriteriaStatistic(props);
    },
);
export const getTeachesInfoAction = createAsyncThunk('statisticsMonitoring/getTeachesInfo', async (id: string) => {
    return await getTeachesInfo(id);
});
export const getTeachesWorkDaysAction = createAsyncThunk(
    'statisticsMonitoring/getTeachesWorkDays',
    async (props: TGetTeachesWorkDaysProps) => {
        return await getTeachesWorkDays(props);
    },
);
export const updateTeachesWorkDaysAction = createAsyncThunk(
    'statisticsMonitoring/updateTeachesWorkDays',
    async (props: TGetTeachesWorkDaysProps) => {
        const workDays = await getTeachesWorkDays(props);
        const questionsCreated = await getQuestionsCreatedProgress({ user: props.user, date: props.date });
        return {
            workDays,
            questionsCreated,
        };
    },
);
export const getQuestionsCreatedProgressAction = createAsyncThunk(
    'statisticsMonitoring/getQuestionsCreatedProgress',
    async (props: TGetQuestionsCreatedProgressProps) => {
        return await getQuestionsCreatedProgress(props);
    },
);
//сводный график
export const getSummaryChartDataAction = createAsyncThunk(
    'statisticsMonitoring/getSummaryChartData',
    async (filters: TSummaryChartDataFilters) => {
        return await getSummaryChartData(filters);
    },
);
//добавление в банк вопросов
export const getQuestionsCreatedTableDataAction = createAsyncThunk(
    'statisticsMonitoring/getQuestionsCreatedTableData',
    async (props: TQuestionsCreatedTableDataProps) => {
        const defaultProps = {
            ...props,
            currentPage: props.currentPage ?? 1,
            limit: props.limit ?? 10,
        };
        return await getQuestionsCreatedTableData(defaultProps);
    },
);
export const getQuestionsCreatedDiagramDataAction = createAsyncThunk(
    'statisticsMonitoring/getQuestionsCreatedDiagramData',
    async (filters: TQuestionsCreatedDiagramDataFilters) => {
        return await getQuestionsCreatedDiagramData(filters);
    },
);
export const getTeachersQuestionsCreatedDiagramAction = createAsyncThunk(
    'statisticsMonitoring/getTeachersQuestionsCreatedDiagram',
    async (date?: string) => {
        return await getTeachersQuestionsCreatedDiagram(date);
    },
);

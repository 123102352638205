import { createAsyncThunk } from '@reduxjs/toolkit';
import { oauth } from 'api/services/oauth';

type OAuthActionParams = {
    clientId: string;
    redirectUri: string;
    responseType: string;
    state: string;
    scope: string;
    csrfmiddlewaretoken?: string;
};

export const oauthAction = createAsyncThunk('oauth/postData', (params: OAuthActionParams) => {
    return oauth(params).then(({ url }) => {
        document.location.href = url;
    });
});

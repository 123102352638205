import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRole } from 'hooks';
import { TTeacher } from 'src-new/components/new-subjects';
import { getStudentsListAction } from 'store/actions/newSubjects';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';
import { TCourseGroup, TGetCourseTreePayload, TSelectedCourse } from 'types/newSubjectsTypes';

import { TUseGetSubjectsTreeDataResponse } from './useGetSubjectsTreeData.types';

/**
 * @description Хук для получения доп информации для предметов в зависимости от роли пользователя
 * @return {TUseGetSubjectsTreeDataResponse}
 */
export const useGetSubjectsTreeData = (): TUseGetSubjectsTreeDataResponse => {
    const { isStudent, isTeacher } = useRole();
    const [teachersList, setTeachersList] = useState<TTeacher[]>([]);

    const dispatch = useDispatch();
    const { courseTree, selectedCourse, studentsList, studentsListPaginate, getCourseTreeStatus } = useAppSelector(
        (store) => store.newSubject,
    );

    useEffect(() => {
        if (isStudent) {
            getTeachersList(selectedCourse);
        }
        if (isTeacher) {
            dispatch(getStudentsListAction({ courseGroup: selectedCourse.id }));
        }
    }, [selectedCourse]);

    /**
     * @description setTeachersList - достает список учителей по выбранному курсу
     * @param courseParam
     */
    const getTeachersList = (courseParam: TSelectedCourse) => {
        const { id, type } = courseParam;
        if (courseTree) {
            const {
                common: commonCourses,
                choice: choiceCourses,
                additional: additionalCourses,
            } = courseTree as TGetCourseTreePayload;
            let teachers: TTeacher[] = [];
            switch (type) {
                case 'common':
                    commonCourses?.forEach((item) => {
                        const course = item?.studentGroup?.courseGroups?.find((point) => point.id === id);
                        if (course) {
                            teachers = course?.teachers ?? [];
                        }
                    });
                    break;
                case 'choice':
                    teachers = choiceCourses?.find((item) => item.id === id)?.teachers || [];
                    break;
                default:
                case 'additional':
                    teachers = additionalCourses?.find((item) => item.id === id)?.teachers || [];
                    break;
            }
            setTeachersList(teachers);
        }
    };

    const noCourses = useMemo(() => {
        if (isStudent && courseTree) {
            const {
                common: commonCourses,
                choice: choiceCourses,
                additional: additionalCourses,
            } = courseTree as TGetCourseTreePayload;
            return (
                getCourseTreeStatus === FetchStatus.FETCHED &&
                !commonCourses?.length &&
                !choiceCourses?.length &&
                !additionalCourses?.length
            );
        }
        if (isTeacher && courseTree) {
            return getCourseTreeStatus === FetchStatus.FETCHED && !(courseTree as TCourseGroup[])?.length;
        }
        return false;
    }, [isStudent, isTeacher, getCourseTreeStatus, courseTree]);

    return {
        studentsList,
        studentsListPaginate,
        teachersList,
        noCourses,
    };
};

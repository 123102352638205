import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppButton, AppCheckbox, AppFontIcon, AppRadio, AppText } from '@frontend-modules/ui-kit';
import { E_ANSWERS_TYPE, E_COLORS, E_QUESTION_TYPE_INFO } from 'src-new/constants';
import { useDownloadTxtFile, useGetQuestionInfo } from 'src-new/hooks';
import { AppModal, AppSkeleton } from 'src-new/ui';
import { getSafetyHtml } from 'src-new/utils';
import { resetOpenedQuestionState } from 'store/reducers/lessonTask';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';
import { TResponseQuestionDetailed } from 'types/lessonTask';
import { TrueFalseQuestionData } from 'types/question';

import { TModalButtonType, TQuestionInfoModal } from './QuestionInfoModal.types';

import './style.scss';

export const QuestionInfoModal: React.FC<TQuestionInfoModal> = (props) => {
    const {
        canAdd,
        canRemove,
        canExclude,
        canInclude,
        canPaginateLeft,
        canPaginateRight,
        handleModalButtonClick,
        handleQuestionsPaginate,
    } = props;
    const containerRef = useRef<HTMLDivElement>(null);
    useDownloadTxtFile(containerRef);

    const { questionDetails = {} as TResponseQuestionDetailed, openedQuestionId, getStatusQuestion } = useAppSelector(
        (store) => store.lessonTask,
    );

    const dispatch = useDispatch();
    const {
        correctAnswersList,
        answersInfo,
        correctAnswersInfo,
        questionTitle,
        topicsInfo,
        skillsInfo,
        authorFullName,
        essayAnswerFormat,
        difficultyText,
        answersType,
        data,
        id,
    } = useGetQuestionInfo(questionDetails);

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        setIsOpen(!!openedQuestionId);
    }, [openedQuestionId]);

    const isLoading = getStatusQuestion === FetchStatus.FETCHING;

    const handleCloseModal = () => {
        dispatch(resetOpenedQuestionState());
    };
    const handleModalButtonClickLocal = (id: number, type: TModalButtonType) => {
        handleModalButtonClick?.(id, type);
        setIsOpen(false);
    };

    return (
        <AppModal
            isShow={isOpen}
            className={'question-info-modal__wrapper'}
            width={'100%'}
            afterClose={handleCloseModal}
        >
            {isLoading ? (
                <div className={'question-info-modal'}>
                    <AppSkeleton width={'70%'} />
                    <AppSkeleton height={300} />
                </div>
            ) : (
                <div ref={containerRef} className={'question-info-modal'}>
                    {canPaginateLeft && (
                        <div
                            className={'question-info-modal__button-left'}
                            onClick={handleQuestionsPaginate?.bind(this, 'prev')}
                        >
                            <AppFontIcon icon={'arrow-left-s'} color={E_COLORS.firm_blue_color} size={32} />
                        </div>
                    )}
                    <div className={'question-info-modal__header'}>
                        {!!correctAnswersInfo.count && (
                            <AppText
                                text={correctAnswersInfo.statistic}
                                textStyle={'DesktopFootNotes'}
                                color={E_COLORS.light_grey_color_2}
                            />
                        )}
                        {!!answersInfo.count && (
                            <AppText text={answersInfo.statistic} color={E_COLORS.light_grey_color_2} />
                        )}
                        {!!difficultyText && <AppText text={difficultyText} color={E_COLORS.light_grey_color_2} />}
                        {!!answersType && (
                            <AppText text={E_QUESTION_TYPE_INFO[answersType]} color={E_COLORS.light_grey_color_2} />
                        )}
                    </div>
                    <div className={'question-info-modal__content'}>
                        <div className={'question-info-modal__content_view'}>
                            <div
                                className={'question-body'}
                                dangerouslySetInnerHTML={{ __html: getSafetyHtml(questionTitle) }}
                            />

                            <div className={'answers-list'}>
                                {answersType === E_ANSWERS_TYPE.test &&
                                    correctAnswersList.map((answer, index) => (
                                        <div className={'answers-list__answer-option'} key={index}>
                                            <AppCheckbox
                                                onChange={() => undefined}
                                                defaultValue={answer.isCorrect}
                                                disabled
                                                label={''}
                                                key={`check-${answer.id}`}
                                            />
                                            <span
                                                className={'option'}
                                                dangerouslySetInnerHTML={{
                                                    __html: getSafetyHtml(answer.html || answer.text),
                                                }}
                                            />
                                        </div>
                                    ))}
                                {answersType === E_ANSWERS_TYPE.text &&
                                    correctAnswersList.map((answer) => (
                                        <div className={'text_type'} key={answer.id}>
                                            <span
                                                className={'option'}
                                                dangerouslySetInnerHTML={{
                                                    __html: getSafetyHtml(answer.html || answer.text),
                                                }}
                                            />
                                            <span className={'dash'}>—</span>
                                            <span className={'percent'}>{answer.percentOfScore}% от оценки</span>
                                        </div>
                                    ))}
                                {answersType === E_ANSWERS_TYPE.true_false && (
                                    <>
                                        <div className={'answers-list__answer-option'}>
                                            <AppRadio disabled checked={(data as TrueFalseQuestionData).valid} />
                                            <AppText text={'Верно'} />
                                        </div>
                                        <div className={'answers-list__answer-option'}>
                                            <AppRadio disabled checked={!(data as TrueFalseQuestionData).valid} />
                                            <AppText text={'Неверно'} />
                                        </div>
                                    </>
                                )}

                                {answersType === E_ANSWERS_TYPE.essay &&
                                    correctAnswersList.map((answer, index) => (
                                        <div key={answer.id}>
                                            <div className={'checkbox'}>
                                                <label className={'label'}>Принимать ответы в виде</label>
                                                <AppCheckbox
                                                    onChange={() => undefined}
                                                    defaultValue={answer.acceptFile}
                                                    disabled
                                                    label={'Файла'}
                                                    key={`check-essay-${answer.id}`}
                                                />
                                                <AppCheckbox
                                                    onChange={() => undefined}
                                                    defaultValue={answer.acceptText}
                                                    disabled
                                                    label={'Текстового поля'}
                                                    key={`text-field-${index + Math.random()}`}
                                                />
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                        <span className={'question-info-modal__content_divider'} />
                        <div className={'question-info-modal__content_info'}>
                            <div className={'question-info-modal__content_info-row'}>
                                <AppText
                                    text={'Автор: '}
                                    color={E_COLORS.dark_grey_color_1}
                                    textStyle={'DesktopFootNotes'}
                                />
                                <AppText text={authorFullName} textStyle={'DesktopFootNotes'} />
                            </div>
                            {!!topicsInfo.count && (
                                <div className={'question-info-modal__content_info-row'}>
                                    <AppText
                                        text={'Содержится в:  '}
                                        color={E_COLORS.dark_grey_color_1}
                                        textStyle={'DesktopFootNotes'}
                                    />
                                    <AppText text={topicsInfo.text} textStyle={'DesktopFootNotes'} />
                                </div>
                            )}
                            {answersType === E_ANSWERS_TYPE.essay && (
                                <div className={'question-info-modal__content_info-row'}>
                                    <AppText
                                        text={'Формат ответа:  '}
                                        color={E_COLORS.dark_grey_color_1}
                                        textStyle={'DesktopFootNotes'}
                                    />
                                    <AppText text={essayAnswerFormat} textStyle={'DesktopFootNotes'} />
                                </div>
                            )}
                            {!!skillsInfo.count && (
                                <div className={'question-info-modal__content_info-row'}>
                                    <AppText
                                        text={skillsInfo.statistic}
                                        color={E_COLORS.dark_grey_color_1}
                                        textStyle={'DesktopFootNotes'}
                                    />
                                    <AppText text={skillsInfo.text} textStyle={'DesktopFootNotes'} />
                                </div>
                            )}
                        </div>
                        <div className={'question-info-modal__content_buttons'}>
                            {canAdd && (
                                <AppButton
                                    label={'Добавить в список'}
                                    onClick={handleModalButtonClickLocal.bind(this, id, 'add')}
                                />
                            )}
                            {canRemove && (
                                <AppButton
                                    label={'Убрать из списка'}
                                    type={'outline'}
                                    onClick={handleModalButtonClickLocal.bind(this, id, 'remove')}
                                />
                            )}
                            {canInclude && (
                                <AppButton
                                    label={'Включить в поиск'}
                                    type={'outline'}
                                    onClick={handleModalButtonClickLocal.bind(this, id, 'include')}
                                />
                            )}
                            {canExclude && (
                                <AppButton
                                    type={'danger-outline'}
                                    label={'Исключить из поиска'}
                                    onClick={handleModalButtonClickLocal.bind(this, id, 'exclude')}
                                />
                            )}
                        </div>
                    </div>
                    {canPaginateRight && (
                        <div
                            className={'question-info-modal__button-right'}
                            onClick={handleQuestionsPaginate?.bind(this, 'next')}
                        >
                            <AppFontIcon icon={'arrow-right-s'} color={E_COLORS.firm_blue_color} size={32} />
                        </div>
                    )}
                </div>
            )}
        </AppModal>
    );
};

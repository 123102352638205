import React from 'react';
import { LittleArrow } from '@lms-elements/icons';
import { Collapse } from 'antd';

const { Panel } = Collapse;

import { AppCollapseTypes } from './AppCollapseTypes';

import './style.scss';

const AppCollapse = (props: AppCollapseTypes): JSX.Element => {
    const { collapsible = 'header', expandIconPosition = 'start', list, defaultActiveKey } = props;

    return (
        <Collapse
            ghost
            prefixCls={'app-collapse'}
            collapsible={collapsible}
            expandIcon={({ isActive }) => <LittleArrow className={isActive ? 'opened' : ''} />}
            defaultActiveKey={defaultActiveKey}
            expandIconPosition={expandIconPosition}
        >
            {list.map((item) => {
                return (
                    <Panel header={item.header} key={item?.key}>
                        {item.content}
                    </Panel>
                );
            })}
        </Collapse>
    );
};

export default AppCollapse;

import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { CourseType } from 'types/course';

import { ResponseCourse } from '.';

export interface CourseParams {
    subject_id?: number;
    course_type?: CourseType;
    parallels?: number;
}

export const getCourses = async (params?: CourseParams): Promise<ResponseCourse[]> => {
    return await api
        .get<never, AxiosResponse<ResponseCourse[]>>('/course/', { params })
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

import React from 'react';
import { AppText } from '@frontend-modules/ui-kit';
import { E_COLORS } from 'src-new/constants';

import { TChartTooltip } from './SummaryCriteriaChart.types';

export const ChartTooltip: React.FC<TChartTooltip> = (props) => {
    const { payload, label, radarList, criteriaList, isPositive } = props;

    const title = `Количество учителей ${isPositive ? '' : 'не'} выполнивших критерий "${criteriaList[label - 1]}"`;
    if (payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <AppText text={title} textStyle={'DesktopFontBody'} color={E_COLORS.light_grey_color_2} />
                {payload.map(({ name, value }, index) => {
                    const color = radarList.find(({ title }) => title === name)?.color ?? E_COLORS.firm_blue_color;
                    return (
                        <div key={`data-item-${index}`} className="custom-tooltip__data-item">
                            <AppText text={name ?? ''} textStyle={'DesktopFontBody'} color={color} />
                            <AppText text={`${value}`} textStyle={'DesktopFontBody'} color={color} />
                        </div>
                    );
                })}
            </div>
        );
    }

    return null;
};

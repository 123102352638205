import { isFiltersEqual, isFiltersUpdated } from 'src-new/components/lesson-task';
import { setMessage } from 'src-new/utils/message';
import { TAssignedQuestion, TSearchBlock, TSingleQuestion } from 'types/lessonTask';

type TTransferSingleQuestionProps = {
    fromObject: TAssignedQuestion;
    toObject: TAssignedQuestion;
    item: TSingleQuestion;
};
type TTransferQuestionsBlockProps = {
    fromObject: TAssignedQuestion;
    toObject: TAssignedQuestion;
    item: TSearchBlock;
};

type TTransferSingleQuestionResult = {
    filteredObject: TAssignedQuestion | undefined;
    updatedObject: TAssignedQuestion | undefined;
};

// Утилы для переноса вопроса или блока вопросов
export const transferSingleQuestion = (data: TTransferSingleQuestionProps): TTransferSingleQuestionResult => {
    const { fromObject, toObject, item } = data;

    const isAlreadyExist = toObject?.singleQuestionsList?.find(({ id }) => id === item.id);
    const isFromSearchBlock = !!item.filters.topics.length;
    // одиночные вопросы можно перетащить только вправо,
    // если это не одиночный вопрос, который перетащили из блока вопросов
    if (!isAlreadyExist) {
        const filteredObject = {
            ...fromObject,
            singleQuestionsList:
                fromObject?.singleQuestionsList?.filter(
                    ({ questionContent }) => questionContent?.id !== item?.questionContent?.id,
                ) ?? [],
        };
        const updatedObject = isFromSearchBlock
            ? {
                  ...toObject,
                  searchBlocksList: toObject?.searchBlocksList?.map((block) => {
                      if (!isFiltersUpdated(block.filters, item.filters)) {
                          return {
                              ...block,
                              questionsContentList: [item, ...block.questionsContentList],
                          };
                      }
                      return block;
                  }),
              }
            : {
                  ...toObject,
                  singleQuestionsList: [item, ...toObject.singleQuestionsList],
              };

        return {
            filteredObject,
            updatedObject,
        };
    }
    return {
        filteredObject: undefined,
        updatedObject: undefined,
    };
};

export const transferSingleQuestionFromBlock = (data: TTransferSingleQuestionProps): TTransferSingleQuestionResult => {
    const { fromObject, toObject, item } = data;

    const isAlreadyExist = toObject?.singleQuestionsList?.find(
        ({ questionContent }) => questionContent?.id === item?.questionContent?.id,
    );

    if (!isAlreadyExist) {
        const filteredObject = {
            ...fromObject,
            searchBlocksList: fromObject?.searchBlocksList.map((block) => {
                if (block.id === item.id) {
                    return {
                        ...block,
                        questionsContentList: block.questionsContentList.filter(
                            ({ questionContent }) => questionContent?.id !== item.questionContent?.id,
                        ),
                    };
                }
                return block;
            }),
        };
        const updatedObject = {
            ...toObject,
            singleQuestionsList: toObject?.singleQuestionsList?.length
                ? [item, ...toObject.singleQuestionsList]
                : [item],
        };

        return {
            filteredObject,
            updatedObject,
        };
    }
    return {
        filteredObject: undefined,
        updatedObject: undefined,
    };
};

export const transferQuestionsBlock = (data: TTransferQuestionsBlockProps): TTransferSingleQuestionResult => {
    const { fromObject, toObject, item } = data;

    const isBlockAlreadyExist = toObject?.searchBlocksList?.some(({ filters }) =>
        isFiltersEqual(filters, item.filters),
    );

    if (!isBlockAlreadyExist) {
        const deletedBlockIndex = fromObject?.searchBlocksList?.findIndex(({ filters }) =>
            isFiltersEqual(filters, item.filters),
        );

        const filteredObject = {
            ...fromObject,
            searchBlocksList: deletedBlockIndex
                ? [
                      ...fromObject?.searchBlocksList.slice(0, deletedBlockIndex),
                      ...fromObject?.searchBlocksList.slice(deletedBlockIndex + 1),
                  ]
                : [...fromObject?.searchBlocksList.slice(1, fromObject?.searchBlocksList.length)],
        };
        const updatedObject = {
            ...toObject,
            searchBlocksList: [item, ...(toObject?.searchBlocksList ?? [])],
        };

        return {
            filteredObject,
            updatedObject,
        };
    } else {
        setMessage({ text: 'Данный блок уже отобран в вопрос', status: 'warning' });
        return {
            filteredObject: undefined,
            updatedObject: undefined,
        };
    }
};

import { EvaluationType } from '@lms-elements/educational-plan';
import { Subject } from 'src-new/components/lms-elements/CourseTable';
import { EducationalPlanData } from 'store/reducers/educationalPlan';
import { EduCourseType, ResponseEducationPlan } from 'types/educationalPlan';

export const convertEvaluationTypeToSelection = (type: string | null): string => {
    switch (type) {
        case EvaluationType.DIFFERENTIATED:
            return 'Диф';
        case EvaluationType.BINARY:
            return 'Зачет';
        default:
            return 'Не выбрано';
    }
};

export const convertSelectionToEvaluationType = (selection: string | undefined): string => {
    switch (selection) {
        case 'Диф':
            return EvaluationType.DIFFERENTIATED;
        case 'Зачет':
            return EvaluationType.BINARY;
        default:
            return EvaluationType.DIFFERENTIATED;
    }
};

export const getNormalizedResponseData = (educationPlan: ResponseEducationPlan): EducationalPlanData => {
    const mainBasicCourses: Subject[] = [];
    const mainAdditionalCourses: Subject[] = [];
    const selectiveBasicCourses: Subject[] = [];
    const selectiveAdditionalCourses: Subject[] = [];

    educationPlan.educationalPlanItems.map((item) => {
        switch (item.type) {
            case EduCourseType.COMMON:
                mainBasicCourses.push({
                    id: Number(item.id),
                    courseId: item.course.id,
                    name: item.course.title,
                    inWeek: item.weekLessonsCount !== 0 ? String(item.weekLessonsCount) : '-',
                    inYear: '',
                    fact: item.course.lessonCount !== 0 ? String(item.course.lessonCount) : '-',
                    lessonsInWeek: item.weekOnlineLessonsCount !== 0 ? String(item.weekOnlineLessonsCount) : '-',
                    lessonsInYear: '',
                    lecturesInWeek: item.weekFlowLecturesCount !== 0 ? String(item.weekFlowLecturesCount) : '-',
                    lecturesInYear: '',
                    selection: convertEvaluationTypeToSelection(item.evaluationType),
                    takeIntoAccount: item.takeIntoAccount,
                } as Subject);
                break;
            case EduCourseType.NON_SUBJECT:
                mainAdditionalCourses.push({
                    id: Number(item.id),
                    courseId: item.course.id,
                    name: item.course.title,
                    inWeek: item.weekLessonsCount !== 0 ? String(item.weekLessonsCount) : '-',
                    inYear: '',
                    fact: item.course.lessonCount !== 0 ? String(item.course.lessonCount) : '-',
                    lessonsInWeek: item.weekOnlineLessonsCount !== 0 ? String(item.weekOnlineLessonsCount) : '-',
                    lessonsInYear: '',
                    lecturesInWeek: item.weekFlowLecturesCount !== 0 ? String(item.weekFlowLecturesCount) : '-',
                    lecturesInYear: '',
                    selection: convertEvaluationTypeToSelection(item.evaluationType),
                    takeIntoAccount: item.takeIntoAccount,
                } as Subject);
                break;
            case EduCourseType.GIA_PREPARATION:
                selectiveBasicCourses.push({
                    id: Number(item.id),
                    courseId: item.course.id,
                    name: item.course.title,
                    inWeek: item.weekLessonsCount !== 0 ? String(item.weekLessonsCount) : '-',
                    inYear: '',
                    fact: item.course.lessonCount !== 0 ? String(item.course.lessonCount) : '-',
                    lessonsInWeek: item.weekOnlineLessonsCount !== 0 ? String(item.weekOnlineLessonsCount) : '-',
                    lessonsInYear: '',
                    selection: convertEvaluationTypeToSelection(item.evaluationType),
                    takeIntoAccount: item.takeIntoAccount,
                } as Subject);
                break;
            case EduCourseType.ELECTIVE:
                selectiveAdditionalCourses.push({
                    id: Number(item.id),
                    courseId: item.course.id,
                    name: item.course.title,
                    inWeek: item.weekLessonsCount !== 0 ? String(item.weekLessonsCount) : '-',
                    inYear: '',
                    fact: item.course.lessonCount !== 0 ? String(item.course.lessonCount) : '-',
                    lessonsInWeek: item.weekOnlineLessonsCount !== 0 ? String(item.weekOnlineLessonsCount) : '-',
                    lessonsInYear: '',
                    selection: convertEvaluationTypeToSelection(item.evaluationType),
                    takeIntoAccount: item.takeIntoAccount,
                } as Subject);
                break;
        }
    });

    return {
        id: educationPlan.id,
        data: {
            name: educationPlan.title,
            calendarPlan: educationPlan.calendarPlan,
            parallel: { id: educationPlan.parallel.id, title: educationPlan.parallel.title },
            minWeekHours: educationPlan.minHours || 0,
            freeWeekHours: educationPlan.freeHours || 0,
            mainBasicCourses: mainBasicCourses,
            mainAdditionalCourses: mainAdditionalCourses,
            selectiveBasicCourses: selectiveBasicCourses,
            selectiveAdditionalCourses: selectiveAdditionalCourses,
        },
    };
};

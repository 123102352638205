import React, { useMemo, useState } from 'react';
import { Typography } from 'antd';

import { TAppExpandableText } from './AppExpandableText.types';

const { Paragraph } = Typography;
import './style.scss';

const AppExpandableText = (props: TAppExpandableText): JSX.Element => {
    const { textStyle, fontStyle, expandText, text, ellipsis, maxRowsCount, ...rest } = { ...props };
    const [expanded, setExpanded] = useState(true);

    const classNameString = `app-expandable-text__content ${(textStyle || 'default').toString()} ${(
        fontStyle || 'default'
    ).toString()}`;

    const ellipsisConfig = useMemo(() => {
        return expanded
            ? {
                  rows: maxRowsCount ? maxRowsCount : 2,
                  expandable: true,
                  symbol: expandText ? expandText : 'развернуть',
              }
            : false;
    }, [expanded]);

    const showMore = useMemo(() => {
        const textArray = text.split('\n');

        return textArray.length > 1 || (textArray.length === 1 && textArray?.[0].length > 40);
    }, [text]);

    const setExpandedText = (event: React.MouseEvent<Element, MouseEvent>) => {
        event.stopPropagation();
        setExpanded(!expanded);
    };
    const getFormattedText = (text: string) => {
        const length = text.split('\n').length;
        return text.split('\n').map((str, i) => (
            <>
                <span key={`p_${i}`}>{str}</span>
                {length !== i + 1 && <br />}
            </>
        ));
    };
    return (
        <div className={'app-expandable-text'}>
            <Paragraph
                ellipsis={ellipsis !== undefined ? ellipsisConfig : expanded}
                className={classNameString}
                {...rest}
            >
                {expanded ? getFormattedText(text)[0] : getFormattedText(text)}
            </Paragraph>
            {ellipsis === undefined && showMore && (
                <span
                    className={`app-expandable-text__controller footnotes`}
                    onClick={(event) => setExpandedText(event)}
                >
                    {expandText ? expandText : `показать ${expanded ? 'больше' : 'меньше'}`}
                </span>
            )}
        </div>
    );
};

export default AppExpandableText;

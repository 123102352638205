import React, { useCallback } from 'react';
import { Form } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { Button, ButtonViewEnum, FieldDropdown, FieldForm, TextareaField } from '@lms-elements/atomic';

import { DropdownActionsFormProps, DropdownActionsFormValuesFields } from './DropdownWithActions.types';

import './DropdownWithActions.scss';

const CnDropdownActionsForm = cn('dropdownActionsForm');

const codifierRegexp = /[\wА-Яа-я]+.[\wА-Яа-я]+.\d+.\d+.\d+|^(?![\s\S])/g;

export const DropdownWithActions: React.FC<DropdownActionsFormProps> = ({
    onClickCancel,
    onChangeAdd,
    textButtonAdd = 'Добавить',
    name,
    initialValue = {},
    placeholder,
    allCodifierValues = [],
    options = [],
    isNeedTextField = false,
    needTextarea = false,
    needCodifierValue,
    title,
    noSkills = false,
    needDisableOrder,
}) => {
    const validate = useCallback((value: string) => (value ? undefined : 'error'), []);
    const validateCodifierExists = useCallback(
        (codifier: string | undefined) =>
            allCodifierValues?.includes(codifier || '') ? 'This codifier already exists' : undefined,
        [allCodifierValues],
    );
    const validateCodifierStructure = useCallback(
        (codifier: string | undefined) => (codifier?.match(codifierRegexp) === null ? 'Not valid' : undefined),
        [],
    );

    const handleFormSubmit = useCallback(
        (values: Record<string, { title: string; order: string }>) => {
            onChangeAdd(values);
            onClickCancel();
        },
        [onChangeAdd, onClickCancel],
    );

    return (
        <div className={CnDropdownActionsForm()}>
            <Form onSubmit={handleFormSubmit} initialValues={initialValue}>
                {({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <div className={CnDropdownActionsForm('flex')}>
                            {title && <div className={CnDropdownActionsForm('title')}>{title}</div>}
                            {isNeedTextField && noSkills && (
                                <FieldForm
                                    name={`${name}.${DropdownActionsFormValuesFields.order}`}
                                    placeholder={'№'}
                                    isOnlyNumbers
                                    customClassName={CnDropdownActionsForm('order')}
                                    needDeleteIcon={false}
                                    validators={[validate]}
                                    disable={needDisableOrder}
                                />
                            )}
                            {isNeedTextField ? (
                                <FieldForm
                                    name={`${name}.${DropdownActionsFormValuesFields.title}`}
                                    placeholder={placeholder}
                                    validators={[validate]}
                                    customClassName={CnDropdownActionsForm('tit')}
                                />
                            ) : needTextarea ? (
                                <TextareaField
                                    name={`${name}.${DropdownActionsFormValuesFields.title}`}
                                    placeholder={placeholder}
                                    validators={[validate]}
                                    isField
                                />
                            ) : (
                                <FieldDropdown
                                    name={`${name}.${DropdownActionsFormValuesFields.title}`}
                                    placeholder={placeholder}
                                    validators={[validate]}
                                    options={options}
                                    needBlueIcon={true}
                                />
                            )}
                        </div>
                        {needCodifierValue && (
                            <div className={CnDropdownActionsForm('codifierValueInputWrapper')}>
                                <FieldForm
                                    name={`${name}.${DropdownActionsFormValuesFields.codifierValue}`}
                                    placeholder="Введите значение кодификатора (X.X.0.0.0)"
                                    validators={[validateCodifierExists, validateCodifierStructure]}
                                />
                            </div>
                        )}
                        <div className={CnDropdownActionsForm('buttons', { isNeedTextField })}>
                            <Button type="submit" view={ButtonViewEnum.action} size="l">
                                {textButtonAdd}
                            </Button>
                            <Button view={ButtonViewEnum.bordered} size="l" onClick={onClickCancel}>
                                Отмена
                            </Button>
                        </div>
                    </form>
                )}
            </Form>
        </div>
    );
};

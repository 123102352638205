import React, { useEffect, useState } from 'react';
import { AppText } from 'src-new/ui';

import { ELessonTabTitle, TLessonsColTabsProps, TLessonTabTypes } from './LessonsColTabs.types';

import './style.scss';

export const LessonsColTabs: React.FC<TLessonsColTabsProps> = ({ tabs, selectedTab, handleTabClick }) => {
    const [selected, setSelected] = useState<TLessonTabTypes | undefined>();

    useEffect(() => {
        setSelected(selectedTab);
    }, [selectedTab]);

    const onTabClick = (handledType: TLessonTabTypes) => {
        if (handledType === selected) {
            setSelected(undefined);
            handleTabClick?.(undefined);
        } else {
            setSelected(handledType);
            handleTabClick?.(handledType);
        }
    };

    return (
        <div className={'lessons-col-tabs'}>
            {tabs.map((tab, index) => (
                <div
                    key={index}
                    className={`lessons-col-tabs__item ${tab.count ? '' : 'disabled'} ${
                        selected === tab.type ? 'selected' : ''
                    }`}
                    onClick={() => onTabClick(tab.type)}
                >
                    <div className={'lessons-col-tabs__item_count'}>
                        <AppText text={tab.count} fontStyle={'footnotes'} />
                    </div>
                    <div className={'lessons-col-tabs__item_title'}>
                        <AppText text={ELessonTabTitle[tab.type]} fontStyle={'footnotes'} />
                    </div>
                </div>
            ))}
        </div>
    );
};
export const LessonsColTabsMemo = React.memo(LessonsColTabs);

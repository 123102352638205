import { getApiRequest } from 'api';
import { Student } from 'types/students';
import { User } from 'types/user';

export interface StudentGroupResponse {
    id: number;
    title: string;
    students: Student[];
    curators: User[];
    educationalPlan: number;
}

export const getStudentGroups = (): Promise<StudentGroupResponse[]> => {
    return getApiRequest('/student-group/');
};

import { getApiRequest, patchApiRequest, postApiRequest } from 'api';
import { MedicalCertificates, MedicalCertificatesReasons } from 'types/certificates';

export interface CertificateCreateProps {
    files: (File | undefined)[];
    student: number;
    startDate: string;
    expirationDate: string;
    type: MedicalCertificatesReasons;
    isApproved: boolean;
    isImage?: boolean;
}

export interface CertificateUpdateProps {
    isApproved: boolean;
    startDate?: string;
    expirationDate?: string;
    type?: MedicalCertificatesReasons;
    comment?: string;
}

export const getMedicalCertificates = async (student_ids?: number[]): Promise<MedicalCertificates[]> => {
    return await getApiRequest('/certificates/', {
        params: {
            student_ids: student_ids?.join(','),
        },
    });
};

export const createMedicalCertificate = async ({
    files,
    startDate,
    expirationDate,
    student,
    type,
    isApproved,
    isImage,
}: CertificateCreateProps): Promise<MedicalCertificates> => {
    const data = new FormData();

    files.forEach((file) => file && data.append('files', file));
    data.append('student', String(student));
    data.append('startDate', startDate);
    data.append('expirationDate', expirationDate);
    data.append('type', String(type));
    data.append('isApproved', String(isApproved));
    data.append('isImage', String(isImage));

    return await postApiRequest('/certificates/create/', data);
};

export const updateMedicalCertificate = async (params: {
    data: CertificateUpdateProps;
    certificateId: number;
}): Promise<MedicalCertificates> => {
    return await patchApiRequest(`/certificates/partial-update/${params.certificateId}/`, params.data);
};

import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { Status } from 'pages/QuestionBankPage/types';
import {
    EssayAnswerData,
    EssayAnswerDataResponse,
    InsertWordsAnswerData,
    InsertWordsAnswerDataResponse,
    TestAnswerData,
    TestAnswerDataResponse,
    TextAnswerData,
    TrueFalseAnswerData,
} from 'types/answerAttempt';
import { FileTypes, IFile } from 'types/file';
import { ContentMaterialTypeEnum } from 'types/materials';

import { AnswersTypeEnum } from 'components/QuestionPopUp/QuestionPopUp.types';
import { createUrlQuery } from 'utils/createUrlQuery';

import { createFile, fetchDeleteFiles } from './file';
import { DifficultyEnum } from './questionCreate';

export interface IPostMaterial {
    lesson: number;
    content: string;
    html?: string;
    question?: number;
    typeOfContent: ContentMaterialTypeEnum;
    type: TMaterialType;
}

export type TMaterialType = 'main' | 'additional';

export interface IPostMaterialResponse {
    id: number;
    materialType: string;
    lesson: number;
    content: string;
    html?: string;
    question?: number;
    typeOfContent: ContentMaterialTypeEnum;
}

export interface IMaterialFile {
    id: number;
    files: IFile[];
    material: number;
    course?: number;
}

export interface IUploadMaterialParams {
    id?: number;
    material: IPostMaterial;
    filesForPatch: number[];
    file?: File;
    files?: File[];
    filesForDelete?: number[];
}

export interface TrainingSearchBlockFilters {
    text: string;
    topics?: number[];
    difficulty?: DifficultyEnum[];
    answersType?: AnswersTypeEnum[];
    skillsOr?: number[];
    skillsAnd?: number[];
    excludeIds?: number[];
    id: number | null;
    status?: Status;
    all_user_questions?: boolean;
}

export const postMaterial = async (value: IUploadMaterialParams): Promise<IPostMaterialResponse> => {
    return api
        .post<Promise<IPostMaterial>, AxiosResponse<IPostMaterialResponse>>(`/learning-material/create/`, {
            ...value.material,
        })
        .then((res) => {
            return res;
        })
        .then(async (res) => {
            if (res?.data.id && value.file) {
                await createFile({
                    file: value.file,
                    type: FileTypes.COMMON,
                    materialId: res?.data.id,
                });
            }
            if (res?.data.id && value.files) {
                await Promise.all(
                    value.files.map((file) =>
                        createFile({
                            file,
                            type: FileTypes.COMMON,
                            materialId: res?.data.id,
                        }),
                    ),
                );
            }
            if (res?.data.id && value.filesForPatch.length) {
                await getMaterialFile({ materialId: res.data.id }).then(async (fileSet) => {
                    if (fileSet.length && fileSet[0].id) {
                        await patchFilesMaterials(value.filesForPatch, fileSet[0].id);
                    }
                });
            }
            return res?.data;
        })
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const patchFilesMaterials = (fileIds: number[], materialId: number): Promise<never> => {
    return api
        .patch<never, AxiosResponse<never>>(`/material-file-set/${materialId}/upload/`, { fileIds })
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export interface LearningMaterialsContent {
    id: number;
    content: string;
    html?: string;
    order: number;
    question?: number;
    typeOfContent: ContentMaterialTypeEnum;
}

export interface CheckAnswerResponse {
    isCorrect: boolean;
    incomplete?: boolean;
    file?: { id: number; url: string; title: string }[] | null;
    data:
        | EssayAnswerDataResponse
        | InsertWordsAnswerDataResponse
        | TestAnswerDataResponse
        | TextAnswerData
        | TrueFalseAnswerData;
}

export const getMaterials = async (
    lessonId: number,
    materialType: TMaterialType,
): Promise<LearningMaterialsContent[]> => {
    return await api
        .get<Promise<LearningMaterialsContent[]>, AxiosResponse<LearningMaterialsContent[]>>(
            `/learning-material/?lesson=${lessonId}&type=${materialType}`,
        )
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const getMaterialFile = ({
    materialId,
    lesson,
}: {
    materialId?: number;
    lesson?: number;
}): Promise<IMaterialFile[]> => {
    return api
        .get<never, AxiosResponse<IMaterialFile[]>>(
            `/material-file-set/${createUrlQuery({ material: materialId, lesson })}`,
        )
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const deleteMaterial = (materialId: number, filesForDelete?: number[]): Promise<number> => {
    return api
        .delete<never, number>(`/learning-material/delete/${materialId}/`)
        .then(async (id) => {
            await Promise.all(filesForDelete?.map((file) => fetchDeleteFiles(file)) || []);
            return id;
        })
        .then((id) => id)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const patchMaterial = (
    id: number,
    value: IPostMaterial,
    filesForPatch: number[],
    files?: File[],
    filesForDelete?: number[],
): Promise<IPostMaterialResponse> => {
    return api
        .patch<Promise<IPostMaterial>, AxiosResponse<IPostMaterialResponse>>(
            `/learning-material/partial-update/${id}/`,
            {
                ...value,
            },
        )
        .then(async (res) => {
            if (filesForPatch.length) {
                const fileSet = await getMaterialFile({ materialId: res.data.id });
                if (fileSet.length && fileSet[0].id) {
                    await patchFilesMaterials(filesForPatch, fileSet[0].id);
                }
            }
            if (id && files) {
                await Promise.all(
                    files.map((file) =>
                        createFile({
                            file,
                            type: FileTypes.COMMON,
                            materialId: id,
                        }),
                    ),
                );
            }
            if (filesForDelete) {
                await Promise.all(filesForDelete.map((file) => fetchDeleteFiles(file)));
            }
            return res?.data;
        })
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const patchMaterialsOrdering = (lessonId: number, ordering: number[]): Promise<{ ordering: number[] }> => {
    return api
        .patch<Promise<{ ordering: number[] }>, AxiosResponse<{ ordering: number[] }>>(
            `/lesson/${lessonId}/materials/ordering/`,
            {
                ordering,
            },
        )
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export interface ExerciseAnswer {
    material: number;
    data: EssayAnswerData | InsertWordsAnswerData | TestAnswerData | TextAnswerData | TrueFalseAnswerData;
    questionId?: number;
}

export const checkExerciseAnswer = (params: ExerciseAnswer): Promise<CheckAnswerResponse> => {
    return api
        .post<Promise<CheckAnswerResponse>, AxiosResponse<CheckAnswerResponse>>(`/codifier/answer/check/`, params)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export interface ExerciseCorrectAnswer {
    material: number;
    id?: number;
    data?: unknown;
}

export const getExercisesCorrectAnswers = (lessonId: number): Promise<ExerciseCorrectAnswer[]> =>
    api
        .get<ExerciseCorrectAnswer[]>(`/codifier/answer/show/?lesson=${lessonId}`)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });

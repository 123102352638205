import React, { FC, useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';
import { AppButton, AppInput, AppModal, AppText } from '@frontend-modules/ui-kit';
import { E_COLORS } from 'src-new/constants';
import { fetchLogin } from 'store/actions/auth';
import { useAppDispatch, useAppSelector } from 'store/store';

import { IReAuthModalProps } from './ReAuthModal.types';

import './ReAuthModal.styles.scss';
const CnReAuthModal = cn('re-auth-modal');

/**
 * @description - модальное окно, ре-авторизации
 * если у пользователя сдохли токены - мы сначала предложим
 * ему заново авторизоваться - и только потом уже выкидываем.
 * @param props
 */
const ReAuthModal: FC<IReAuthModalProps> = (props) => {
    const { onLogin, onLogout, isShowReAuthModal, setIsShowReAuthModal } = props;
    const reAuthEmail = useAppSelector((state) => state?.auth.reAuthEmail);
    const dispatch = useAppDispatch();
    const isShowModal = isShowReAuthModal && !!reAuthEmail;

    const [password, setPassword] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);
    const [isShowError, setShowError] = useState(false);
    const [unSuccessLoginCount, setUnSuccessLoginCount] = useState(3);

    const onCloseModal = () => {
        onLogout();
        setIsShowReAuthModal(false);
    };

    const onPasswordChange = (e: string) => {
        setPassword(e);
    };

    /** */
    const onLoginClick = () => {
        setShowError(false);
        if (reAuthEmail && password) {
            const authParams = { username: reAuthEmail, password };
            setIsUpdating(true);
            void dispatch(fetchLogin(authParams)).then((actionData) => {
                const { requestStatus } = actionData.meta;
                setIsUpdating(false);
                if (requestStatus === 'rejected') {
                    setShowError(true);
                    setUnSuccessLoginCount((prevState) => {
                        const newCount = prevState - 1;
                        if (newCount === 0) {
                            onCloseModal();
                        }
                        return newCount;
                    });
                }
                if (requestStatus === 'fulfilled') {
                    setIsShowReAuthModal(false);
                    // @ts-ignore
                    onLogin(actionData.payload?.access);
                }
            });
        }
    };

    useEffect(() => {
        if (isShowReAuthModal) {
            if (!reAuthEmail) {
                onCloseModal();
            }
        }
    }, [isShowReAuthModal]);

    return (
        <AppModal
            isShow={isShowModal}
            setShow={onCloseModal}
            title={'Время сессии истекло'}
            className={CnReAuthModal()}
            description={'Пожалуйста, введите пароль, чтобы продолжить работу'}
            footer={
                <AppButton
                    isLoading={isUpdating}
                    type={'primary'}
                    label={'Продолжить'}
                    disabled={!password.length}
                    onClick={onLoginClick}
                />
            }
        >
            <div className={CnReAuthModal('content')}>
                <AppInput
                    type="password"
                    placeholder={'Введите пароль'}
                    label={'Пароль'}
                    errors={isShowError ? ['Проверьте введенный пароль'] : null}
                    onChange={onPasswordChange}
                />
                {unSuccessLoginCount < 3 && isShowError && (
                    <AppText
                        color={E_COLORS.coral_color}
                        textStyle={'Little'}
                        text={`Осталось попыток: ${unSuccessLoginCount}`}
                    />
                )}
            </div>
        </AppModal>
    );
};

export default ReAuthModal;

import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { UserInfo } from 'api/services/user';
import { fetchLogout } from 'store/actions/auth';
import { changeAcademicYearAction, changePasswordAction, getUserInfoAction } from 'store/actions/user';
import { FetchStatus } from 'types/api';

interface UserStore {
    fetchStatus: FetchStatus;
    changePasswordStatus: FetchStatus;
    info?: UserInfo;
    error: unknown;
}

const initialState: UserStore = {
    fetchStatus: FetchStatus.INITIAL,
    changePasswordStatus: FetchStatus.INITIAL,
    info: undefined,
    error: null,
};

const userSlice = createSlice<UserStore, SliceCaseReducers<UserStore>>({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchLogout.fulfilled, (state) => {
            state.info = undefined;
            state.error = null;
            state.fetchStatus = FetchStatus.INITIAL;
        });
        // Fetching user info
        builder.addCase(getUserInfoAction.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getUserInfoAction.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.info = payload;
            state.error = null;
        });
        builder.addCase(getUserInfoAction.rejected, (state, { error }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.info = undefined;
            state.error = error;
        });
        builder.addCase(changePasswordAction.pending, (state) => {
            state.changePasswordStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(changePasswordAction.fulfilled, (state) => {
            state.changePasswordStatus = FetchStatus.FETCHED;
            state.error = null;
        });
        builder.addCase(changePasswordAction.rejected, (state, { error }) => {
            state.changePasswordStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(changeAcademicYearAction.pending, (state) => {
            state.error = null;
        });
        builder.addCase(changeAcademicYearAction.fulfilled, (state, { payload }) => {
            if (state.info) {
                const updatedInfo: UserInfo = {
                    ...state.info,
                    academicYear: payload.academicYear,
                };
                state.info = { ...updatedInfo };
            }

            state.error = null;
        });
        builder.addCase(changeAcademicYearAction.rejected, (state, { error }) => {
            state.error = error;
        });
    },
});

export const userReducer = userSlice.reducer;

import { ITrainingQuestionDataResponse } from '@fsd-shared/models/materials';
import { E_ANSWERS_TYPE, E_QUESTION_TYPE_INFO, E_QUESTIONS_DIFFICULTY } from 'src-new/constants';
import { getAnswersCountText, getCorrectAnswersCountText } from 'src-new/utils';

export const getQuestionMeta = (question?: ITrainingQuestionDataResponse): Array<string> => {
    const { difficulty, answersType, data } = question || {};
    const difficultyText = difficulty ? E_QUESTIONS_DIFFICULTY[difficulty] : undefined;
    const answersTypeText = answersType ? E_QUESTION_TYPE_INFO[answersType] : undefined;
    const totalAnswersCount =
        answersType === 'test' ? data?.answerOptions.length : answersType === E_ANSWERS_TYPE.true_false ? 2 : undefined;
    const correctAnswersCount = answersType === 'test' || answersType === 'true_false' ? 1 : undefined;
    const totalAnswersCountText = totalAnswersCount ? getAnswersCountText(totalAnswersCount) : undefined;
    const correctAnswersCountText = correctAnswersCount ? getCorrectAnswersCountText(correctAnswersCount) : undefined;
    return [difficultyText, answersTypeText, totalAnswersCountText, correctAnswersCountText].filter(
        Boolean,
    ) as Array<string>;
};

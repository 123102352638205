import { useEffect, useRef } from 'react';

export const usePreventUnload = (handler?: (event: BeforeUnloadEvent) => void): void => {
    const eventListenerRef = useRef<(event: BeforeUnloadEvent) => void>();

    useEffect(() => {
        eventListenerRef.current = (event: BeforeUnloadEvent) => {
            const returnValue = handler?.(event);

            if (typeof returnValue === 'string') {
                return (event.returnValue = returnValue);
            }

            if (event.defaultPrevented) {
                return (event.returnValue = '');
            }
        };
    }, [handler]);

    useEffect(() => {
        const eventListener = (event: BeforeUnloadEvent) => eventListenerRef.current?.(event);
        window.addEventListener('beforeunload', eventListener);

        return () => {
            window.removeEventListener('beforeunload', eventListener);
        };
    }, []);
};

import { ResponseCourseType } from 'api/services/course';
import { CourseGroup } from 'types/courseGroup';
import { SubjectCourse } from 'types/subject';

export const normalizeMethodistCourses = (
    courseGroups: CourseGroup[],
    courseData: ResponseCourseType[],
): SubjectCourse[] => {
    return courseData.map((course) => {
        const { id: courseId, title: courseTitle, parallels, isBlockEdit } = course;

        return {
            courseTitle,
            courseId,
            parallels,
            isBlockEdit,
            groups: courseGroups
                .filter((group) => group.course.id === courseId)
                .map((group) => ({
                    ...group,
                    groupTitle: group.title,
                })),
        };
    });
};

import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { Subject } from 'api/services/subject';
import { getCourseGroupDetailsAction } from 'store/actions/courseGroup';
import {
    getCoursesBySubject,
    getMethodistSubjects,
    getPrincipalSubjects,
    getSubjectDetailsAction,
    getTeacherSubjects,
} from 'store/actions/subject';
import { normalizeMethodistCourses } from 'store/normalizers/subject';
import { normalizeTeacherCourses } from 'store/normalizers/subject/normalizeTeacherCourses';
import { FetchStatus } from 'types/api';
import { MethodistSubjectData, SubjectCourse, TeacherCourse } from 'types/subject';

type SubjectsMap = {
    [subjectId: number]: {
        id: number;
        title: string;
        shortName?: string;
        studentsCount?: number;
        courseGroupsCount?: number;
        courseQuantity?: number;
        courses: SubjectCourse[];
        teacherCourses: TeacherCourse[];
        status: FetchStatus;
        ordering: number;
    };
};

interface SubjectsState {
    fetchStatus: FetchStatus;
    subjectDetailsStatus: FetchStatus;
    methodistSubjectStatus: FetchStatus;
    methodistSubjectsData: MethodistSubjectData[];
    subjectsData: SubjectsMap;
    subjectDetails?: Subject;
    error?: unknown;
}

const initialState: SubjectsState = {
    fetchStatus: FetchStatus.INITIAL,
    methodistSubjectStatus: FetchStatus.INITIAL,
    subjectDetailsStatus: FetchStatus.INITIAL,
    methodistSubjectsData: [],
    subjectsData: {},
    error: null,
};

export const subjectsSlice = createSlice<SubjectsState, SliceCaseReducers<SubjectsState>>({
    name: 'subjects',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(getCourseGroupDetailsAction.fulfilled, (state, { payload }) => {
            const courseId = payload.course.id;
            const title = payload.title;
            state.methodistSubjectsData = state.methodistSubjectsData.map((subject) => {
                if (subject.courses.some((course) => course.courseId === courseId)) {
                    return {
                        ...subject,
                        courses: subject.courses.map((course) => {
                            if (course.courseId === courseId) {
                                return {
                                    ...course,
                                    groups: course.groups.map((group) => {
                                        if (group.id === payload.id) {
                                            return {
                                                ...payload,
                                                groupTitle: title,
                                            };
                                        }

                                        return group;
                                    }),
                                };
                            }

                            return course;
                        }),
                    };
                }

                return subject;
            });
        });
        builder
            .addCase(getSubjectDetailsAction.pending, (state) => {
                state.subjectDetailsStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getSubjectDetailsAction.fulfilled, (state, { payload }) => {
                state.subjectDetailsStatus = FetchStatus.FETCHED;
                state.subjectDetails = payload;
                state.error = null;
            })
            .addCase(getSubjectDetailsAction.rejected, (state, { error }) => {
                state.subjectDetailsStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getTeacherSubjects.fulfilled, (state, { payload }) => {
                state.fetchStatus = FetchStatus.FETCHED;
                state.subjectsData = payload.reduce((data, { id, ...subject }) => {
                    data[id] = {
                        ...subject,
                        id,
                        courses: [],
                        teacherCourses: [],
                        status: FetchStatus.INITIAL,
                    };

                    return data;
                }, {} as SubjectsMap);
            })
            .addCase(getMethodistSubjects.fulfilled, (state, { payload }) => {
                state.methodistSubjectStatus = FetchStatus.FETCHED;
                state.subjectsData =
                    payload && payload.length
                        ? payload.reduce((data, { id, title, ordering }) => {
                              data[id] = {
                                  id,
                                  title,
                                  ordering,
                                  courseGroupsCount: 0,
                                  studentsCount: 0,
                                  courses: [],
                                  teacherCourses: [],
                                  status: FetchStatus.INITIAL,
                              };

                              return data;
                          }, {} as SubjectsMap)
                        : [];
            })
            .addCase(getPrincipalSubjects.fulfilled, (state, { payload }) => {
                state.methodistSubjectStatus = FetchStatus.FETCHED;
                state.subjectsData = payload.reduce((data, { id, ...subject }) => {
                    data[id] = {
                        ...subject,
                        id,
                        courses: [],
                        teacherCourses: [],
                        status: FetchStatus.INITIAL,
                    };

                    return data;
                }, {} as SubjectsMap);
            })
            .addCase(getCoursesBySubject.fulfilled, (state, { payload }) => {
                const { subjectId, courses: courseData, courseGroups, isTeacher } = payload;

                state.subjectsData[subjectId] = {
                    ...state.subjectsData[subjectId],
                    courses: isTeacher ? [] : normalizeMethodistCourses(courseGroups, courseData),
                    teacherCourses: isTeacher ? normalizeTeacherCourses(courseGroups, courseData) : [],
                    status: FetchStatus.FETCHED,
                };
            });
    },
});

export const resetSubjectState = subjectsSlice.actions.reset as ActionCreatorWithoutPayload;
export const subjectReducer = subjectsSlice.reducer;

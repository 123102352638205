import React, { useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { CodifierElement, CodifierElementIconEnum } from '@lms-elements/atomic';

import { getDateWithTime } from 'utils/date';

import { IRefs, QuestionPoolProps } from './QuestionPool.types';
import { typeQuestions } from './utils';

import './QuestionPool.scss';

const cnQuestionPool = cn('question_pool');

const Refs: React.FC<IRefs> = React.memo(({ title, description, type, setIsQuestionType, setCanDrop }) => {
    const handleElementClick = useCallback(() => {
        setIsQuestionType(type);
        setCanDrop?.(false);
    }, [setCanDrop, setIsQuestionType, type]);

    return (
        <div>
            <div className={cnQuestionPool('codifier-element-wrapper')} onClick={handleElementClick}>
                <CodifierElement title={title} description={description} icon={CodifierElementIconEnum.question} />
            </div>
        </div>
    );
});

const FUNC_STAB = () => undefined;

export const QuestionPool: React.FC<QuestionPoolProps> = ({
    setIsQuestionType = FUNC_STAB,
    isDisabled = false,
    autoCheckOnly = false,
    setCanDrop,
    revisions = [],
}) => {
    const filteredQuestionTypes = useMemo(
        () => typeQuestions.filter((type) => (autoCheckOnly ? type.description === 'автопроверка' : true)),
        [autoCheckOnly],
    );

    const handleSelectQuestionType = useCallback(
        (type: string) => {
            if (!isDisabled) {
                setIsQuestionType(type);
            }
        },
        [isDisabled, setIsQuestionType],
    );

    return (
        <div className={cnQuestionPool()}>
            <div className={cnQuestionPool('head')}>
                <div className={cnQuestionPool('header')}>
                    <span>Выберите нужный тип вопроса</span>
                </div>
                <div className={cnQuestionPool('questions', { disabled: isDisabled })}>
                    {filteredQuestionTypes.map((value, index) => (
                        <Refs
                            title={value.title}
                            description={value.description}
                            key={`QuestionType_${index + 1}`}
                            type={value.type}
                            setIsQuestionType={handleSelectQuestionType}
                            setCanDrop={setCanDrop}
                        />
                    ))}
                </div>
            </div>
            {revisions.length !== 0 && (
                <div className={cnQuestionPool('comment')}>
                    <div className={cnQuestionPool('commentHeader')}>Комментарии:</div>
                    <div className={cnQuestionPool('commentText')} id="scroll-bar">
                        {[...revisions].reverse().map((revision, index) => (
                            <div className={cnQuestionPool('revision')} key={index}>
                                <div className={cnQuestionPool('revision-date')}>
                                    {getDateWithTime(revision.dateCreate)}
                                </div>
                                <div className={cnQuestionPool('revision-comment')}>{revision.comment}</div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

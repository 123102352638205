import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { cn } from '@bem-react/classname';
import { CodifierResponseData, CodifierSkillsResponseData } from 'api/services/codifier';
import {
    noQuestionInBlock,
    noQuestionInParallel,
    noQuestionInSection,
    noQuestionInTheme,
    noSkillInArea,
    noSkillInParallel,
} from 'pages/CodifierPage/utils';
import { OptionsLocation } from 'pages/QuestionBankPage/utils';

import { CodifierDropdownGrade } from 'components/CodifierDropdownGrade';
import { CodifierDropdownSection } from 'components/CodifierDropdownSection';
import { DropdownActionsFormValues } from 'components/DropdownWithActions';
import { SubjectName } from 'components/SubjectName';
import { ThemeCodifier } from 'components/ThemeCodifier';

import { ReplaceWindow } from './ReplaceWindow';
import { getLessonLocation, getSkillLocation, LessonLocation, SkillLocation } from './utils';

import './Codifier.scss';

const cnCodifier = cn('codifier');

export enum CodifierEventType {
    SUBJECT = 'subject',
    PARALLEL = 'parallel',
    SECTION = 'section',
    BLOCK = 'block',
    TOPIC = 'topic',
    METASUBJECT = 'metasubject',
    SKILL = 'skill',
}

export interface ReplaceParams {
    id: number;
    type: CodifierEventType;
    subject: number;
    section?: number;
    block?: number;
    parallel?: number;
}

interface CodifierProps {
    subjects?: CodifierResponseData[];
    skills?: CodifierSkillsResponseData[];
    isEditable?: boolean;
    onParallelSelect?: (data: { grade: number; subject: number }) => void;
    onParallelPatch?: (data: { grade: number; id: number }) => void;
    onObjectlDelete?: (
        type: CodifierEventType,
        id: number,
        parallelId?: number,
        sectionId?: number,
        blockId?: number,
    ) => void;
    onObjectReplace?: (params: {
        type: CodifierEventType;
        id: number;
        section?: number;
        block?: number;
        parallel?: number;
    }) => void;
    onSectionCreate?: (data: { parallel: number; title: string; order: string }) => void;
    onSectionPatch?: (data: { id: number; title: string; order?: string; parallel?: number }) => void;
    onBlockCreate?: (data: { section: number; title: string; order: string; parallel: number }) => void;
    onBlockPatch?: (data: { id: number; title: string; order?: string; parallel?: number; section: number }) => void;
    onSkillCreate?: (data: { metasubject: number; title: string; codifier?: string }) => void;
    onSkillPatch?: (data: { id: number; title: string; codifier?: string }) => void;
    onTopicCreate?: (data: { section: number; block: number; title: string; order: number; parallel: number }) => void;
    onTopicPatch?: (data: {
        id: number;
        title: string;
        block: number;
        order?: string;
        parallel: number;
        section?: number;
    }) => void;
    handleSelectTopicCheckbox?: (data: LessonLocation) => void;
    handleSelect?: (type: string, id: number) => void;
    handleSelectSkillCheckbox?: (data: SkillLocation) => void;
    hasTopicCheckbox?: boolean;
    topicLocations?: LessonLocation[];
    skillLocations?: SkillLocation[];
    highlightedLeafs?: OptionsLocation;
    skillsCodifier?: boolean;
    canEditSubject?: boolean;
    questionBank?: boolean;
    isHighlight?: boolean;
    initSelectedLocation?: {
        [type: string]: number;
    };
    setMounted?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Codifier: React.FC<CodifierProps> = ({
    subjects = [],
    skills = [],
    isEditable = false,
    onParallelSelect,
    onParallelPatch,
    onObjectlDelete,
    onSectionCreate,
    onSectionPatch,
    onBlockCreate,
    onBlockPatch,
    onTopicCreate,
    onTopicPatch,
    handleSelectTopicCheckbox,
    hasTopicCheckbox = false,
    handleSelect,
    topicLocations = [],
    skillsCodifier = false,
    handleSelectSkillCheckbox,
    skillLocations = [],
    canEditSubject,
    onSkillCreate,
    onSkillPatch,
    isHighlight,
    initSelectedLocation,
    setMounted,
}) => {
    const [selectedMetaArea, setSelectedMetaArea] = useState({ subjectId: -1, gradeId: -1, metaAreaId: -1 });
    const [selectedSkill, setSelectedSkill] = useState({ subjectId: -1, gradeId: -1, metaAreaId: -1, skillId: -1 });
    const [canHover, setCanHover] = useState(true);

    const [selectedLocation, setSelectedLocation] = useState<Record<string, number> | undefined>(initSelectedLocation);
    const [replaceOpened, setReplaceOpened] = useState(false);
    const [replaceParams, setReplaceParams] = useState({ id: 0, type: CodifierEventType.SECTION } as ReplaceParams);

    const handleCanHover = useCallback(() => {
        setCanHover((prev) => !prev);
    }, []);

    const onCloseReplaceClick = useCallback(() => setReplaceOpened(false), []);

    const handleParallelSelect = useCallback(
        (subject: number, values: Record<string, { title: string }>) => {
            const selectedGrade = parseInt(values.createTheme.title);
            if (onParallelSelect && !isNaN(selectedGrade)) {
                onParallelSelect({
                    grade: selectedGrade,
                    subject,
                });
            }
        },
        [onParallelSelect],
    );

    const handleParallelPatch = useCallback(
        (parallel: number, values: Record<string, { title: string }>) => {
            const selectedGrade = parseInt(values.editParallel.title);
            if (onParallelPatch && !isNaN(selectedGrade)) {
                onParallelPatch({
                    grade: selectedGrade,
                    id: parallel,
                });
            }
        },
        [onParallelPatch],
    );

    const handleDelete = useCallback(
        (type: CodifierEventType, id: number, parallelId?: number, sectionId?: number, blockId?: number) => {
            setCanHover(true);
            if (onObjectlDelete) {
                onObjectlDelete(type, id, parallelId, sectionId, blockId);
            }
        },
        [onObjectlDelete],
    );

    const handleReplace = useCallback(
        (params: {
            type: CodifierEventType;
            id: number;
            subject: number;
            section?: number;
            block?: number;
            parallel?: number;
        }) => () => {
            setReplaceOpened(true);
            setReplaceParams({
                id: params.id,
                type: params.type,
                subject: params.subject,
                section: params.section,
                block: params.block,
                parallel: params.parallel,
            });
        },
        [],
    );

    const handleSectionCreate = useCallback(
        (parallel: number, values: Record<string, { title: string; order: string }>) => {
            if (onSectionCreate) {
                onSectionCreate({
                    title: values.createSection.title,
                    parallel: parallel,
                    order: values.createSection.order,
                });
            }
        },
        [onSectionCreate],
    );

    const handleSectionPatch = useCallback(
        (id: number, values: Record<string, { title: string; order: string }>, parallel?: number) => {
            onSectionPatch?.({ id: id, title: values.editSection.title, order: values.editSection.order, parallel });
        },
        [onSectionPatch],
    );

    const handleBlockCreate = useCallback(
        (params: { id: number }, values: Record<string, { title: string; order: string }>, parallel: number) => {
            if (onBlockCreate) {
                onBlockCreate({
                    title: values.createBlock.title,
                    order: values.createBlock.order,
                    section: params.id,
                    parallel: parallel,
                });
            }
        },
        [onBlockCreate],
    );

    const handleBlockPatch = useCallback(
        (
            block: number,
            values: Record<string, { title: string; order: string }>,
            section: number,
            sectionId: number,
            parallel?: number,
        ) => {
            if (onBlockPatch) {
                onBlockPatch({
                    title: values.editBlock.title,
                    id: block,
                    order: values.editBlock.order,
                    section: sectionId,
                    parallel: parallel,
                });
            }
        },
        [onBlockPatch],
    );

    const handleTopicCreate = useCallback(
        (
            params: { id: number; order?: number },
            values: Record<string, { title: string; order: string }>,
            section: number,
            parallel: number,
        ) => {
            const { id, order } = params;

            if (onTopicCreate && section && parallel) {
                onTopicCreate({
                    block: id,
                    section: section,
                    order: order ?? +values.createTheme?.order ?? 0,
                    title: values.createThemeLesson?.title || values.createTheme?.title || '',
                    parallel: parallel,
                });
            }
        },
        [onTopicCreate],
    );

    const handleTopicPatch = useCallback(
        (
            topic: number,
            values: Record<string, { title: string; order: string }>,
            parallel: number,
            block: number,
            section?: number,
        ) => {
            if (onTopicPatch) {
                onTopicPatch({
                    title: values.editLesson.title,
                    id: topic,
                    order: values.editLesson.order,
                    section: section,
                    parallel: parallel,
                    block: block,
                });
            }
        },
        [onTopicPatch],
    );

    const handleTopicSelectCheckbox = useCallback(
        (topicId: number) => {
            if (handleSelectTopicCheckbox) {
                handleSelectTopicCheckbox(getLessonLocation(subjects, topicId));
            }
        },
        [handleSelectTopicCheckbox, subjects],
    );

    const handleSkillSelectCheckbox = useCallback(
        (skillId: number) => {
            if (handleSelectSkillCheckbox) {
                handleSelectSkillCheckbox(getSkillLocation(skills, skillId));
            }
        },
        [handleSelectSkillCheckbox, skills],
    );

    const handleSelectAnything = useCallback(
        (type: CodifierEventType, id: number) => {
            setSelectedLocation({
                [type]: id,
            });

            if (handleSelect) {
                handleSelect(type, id);
            }
        },
        [handleSelect],
    );

    const handleSkillCreate = useCallback(
        (metasubject: number, values: Record<string, DropdownActionsFormValues>) => {
            if (onSkillCreate) {
                onSkillCreate({
                    metasubject,
                    title: values.createSection.title,
                    codifier: values.createSection.codifierValue,
                });
            }
        },
        [onSkillCreate],
    );

    const handleSkillPatch = useCallback(
        (skill: number, values: Record<string, DropdownActionsFormValues>) => {
            if (onSkillPatch) {
                onSkillPatch({
                    id: skill,
                    title: values.editSkill.title,
                    codifier: values.editSkill.codifierValue,
                });
            }
        },
        [onSkillPatch],
    );

    const history = useHistory();

    const handleCreateQuestion = useCallback(
        (id: number, subject?: number) => {
            setCanHover(true);
            history.push(`/codifier/create-question/${id}${subject ? `?subjects=${subject}` : ''}`);
        },
        [history],
    );

    return skillsCodifier ? (
        <div className={cnCodifier('container')}>
            {skills.map((subject, subjectIndex) => (
                <SubjectName
                    title={subject.title}
                    id={subject.id}
                    parallels={subject.parallels}
                    isClickMore={false}
                    isOpenedInit={!!skillLocations.filter((value) => value.subjectId === subject.id).length}
                    isHighlighted={isHighlight && selectedLocation?.subject === subject.id}
                    isEditable={isEditable}
                    key={`Subject ${subject.id}`}
                    handleCreateClick={handleParallelSelect}
                    handleSelect={handleSelectAnything}
                    canHover={canHover}
                    handleCanHover={handleCanHover}
                    setMounted={setMounted}
                    index={subjectIndex}
                >
                    {subject.parallels?.length !== 0 ? (
                        subject.parallels?.map((parallel, parallelIndex) => (
                            <CodifierDropdownGrade
                                id={parallel.id}
                                numberGrade={parallel.grade}
                                nextSection={1 + Number(parallel.metasubjects?.length)}
                                sectionsCount={Number(parallel.metasubjects?.length)}
                                parallels={subject.parallels}
                                isHighlighted={isHighlight && selectedLocation?.parallel === parallel.id}
                                isOpenedInit={
                                    !!skillLocations.filter(
                                        (value) =>
                                            value.parallelId === parallel.id ||
                                            (value.subjectId === subject.id && parallel.grade === value.parallel),
                                    ).length
                                }
                                isEditable={isEditable}
                                key={`Subject ${subject.id} grade ${parallel.grade}`}
                                onDeleteClick={handleDelete}
                                onCreateClick={handleSectionCreate}
                                onEditClick={handleParallelPatch}
                                onSelect={handleSelectAnything}
                                skillsCodifier={skillsCodifier}
                                disabled={!parallel.metasubjects?.length}
                                canHover={canHover}
                                handleCanHover={handleCanHover}
                                index={parallelIndex}
                                canDelete={noSkillInParallel(parallel)}
                                canReplace={false}
                            >
                                {parallel.metasubjects?.map((areas, index) => (
                                    <div className={cnCodifier('skill')} key={`area ${index}`}>
                                        <ThemeCodifier
                                            id={areas.id}
                                            type={CodifierEventType.METASUBJECT}
                                            metaAreaIndex={index}
                                            isThemeSubject={true}
                                            title={areas.title}
                                            themeNumber={index + 1}
                                            numQuestions={areas.skills?.length || 0}
                                            numQuestionsUnpublished={0}
                                            isSelectedInit={Boolean(
                                                isHighlight && selectedLocation?.topic === areas.id,
                                            )}
                                            isOpenedInit={
                                                !!skillLocations.filter((value) => value.metaAreaId === areas.id).length
                                            }
                                            isEditable={isEditable}
                                            skillsTheme={true}
                                            numSkill={areas.skills?.length}
                                            setSelectedMetaArea={setSelectedMetaArea}
                                            selectedMetaArea={selectedMetaArea}
                                            hasCheckbox={false}
                                            subjectIndex={subjectIndex}
                                            parallelIndex={parallelIndex}
                                            allCodifierValues={areas.skills?.map((skill) => skill.codifier) || []}
                                            onCreateClick={handleSkillCreate}
                                            onEditClick={handleSectionPatch}
                                            onDeleteClick={handleDelete}
                                            canHover={canHover}
                                            handleCanHover={handleCanHover}
                                            themeIndex={index}
                                            canDelete={noSkillInArea(areas)}
                                            canReplace={false}
                                        >
                                            {areas.skills?.map((skill, indexSkill) => (
                                                <ThemeCodifier
                                                    key={`skill ${indexSkill} area ${index}`}
                                                    id={skill.id}
                                                    type={CodifierEventType.SKILL}
                                                    isThemeSubject={true}
                                                    title={skill.title}
                                                    codifierValue={skill.codifier}
                                                    themeNumber={index + 1}
                                                    numSkill={indexSkill + 1}
                                                    numQuestions={0}
                                                    numQuestionsUnpublished={0}
                                                    allCodifierValues={
                                                        areas.skills?.map((skill) => skill.codifier) || []
                                                    }
                                                    isSelectedInit={false}
                                                    isEditable={isEditable}
                                                    skillsTheme={true}
                                                    isChild={true}
                                                    setSelectedSkill={setSelectedSkill}
                                                    selectedSkill={selectedSkill}
                                                    metaAreaIndex={index}
                                                    subjectIndex={subjectIndex}
                                                    parallelIndex={parallelIndex}
                                                    hasCheckbox={hasTopicCheckbox}
                                                    handleSelectCheckbox={handleSkillSelectCheckbox}
                                                    isOpenedInit={
                                                        !!skillLocations.filter((value) => value.skillId === skill.id)
                                                            .length
                                                    }
                                                    isCheckedInit={
                                                        hasTopicCheckbox &&
                                                        !!skillLocations.filter((value) => value.skillId === skill.id)
                                                            .length
                                                    }
                                                    onEditClick={handleSkillPatch}
                                                    onDeleteClick={handleDelete}
                                                    canHover={canHover}
                                                    handleCanHover={handleCanHover}
                                                    themeIndex={indexSkill}
                                                    blockIndex={index}
                                                />
                                            ))}
                                        </ThemeCodifier>
                                    </div>
                                ))}
                            </CodifierDropdownGrade>
                        ))
                    ) : (
                        <div className={cnCodifier('empty')}>Нет параллелей</div>
                    )}
                </SubjectName>
            ))}
        </div>
    ) : (
        <div className={cnCodifier('container')}>
            {subjects.map((subject, index) => (
                <SubjectName
                    title={subject.title}
                    id={subject.id}
                    parallels={subject.parallels}
                    isClickMore={false}
                    isOpenedInit={!!topicLocations.filter((value) => value.subjectId === subject.id).length}
                    isHighlighted={isHighlight && selectedLocation?.subject === subject.id}
                    isEditable={isEditable}
                    key={`Subject ${subject.id}`}
                    handleCreateClick={handleParallelSelect}
                    handleSelect={handleSelectAnything}
                    canEdit={canEditSubject}
                    canHover={canHover}
                    handleCanHover={handleCanHover}
                    setMounted={setMounted}
                    index={index}
                >
                    {subject.parallels?.length !== 0 ? (
                        subject.parallels?.map((parallel, index) => (
                            <CodifierDropdownGrade
                                id={parallel.id}
                                numberGrade={parallel.grade}
                                nextSection={1 + Number(parallel.sections?.length)}
                                sectionsCount={Number(parallel.sections?.length)}
                                isHighlighted={isHighlight && selectedLocation?.parallel === parallel.id}
                                parallels={subject.parallels}
                                isOpenedInit={
                                    !!topicLocations.filter(
                                        (value) =>
                                            value.parallelId === parallel.id ||
                                            (value.subjectId === subject.id && parallel.grade === value.parallel),
                                    ).length
                                }
                                isEditable={isEditable}
                                key={`Subject ${subject.id} grade ${parallel.grade}`}
                                onDeleteClick={handleDelete}
                                onCreateClick={handleSectionCreate}
                                onEditClick={handleParallelPatch}
                                onSelect={handleSelectAnything}
                                skillsCodifier={skillsCodifier}
                                disabled={!parallel.sections?.length}
                                canHover={canHover}
                                handleCanHover={handleCanHover}
                                index={index}
                                canDelete={noQuestionInParallel(parallel)}
                                canReplace={false}
                            >
                                {parallel.sections?.map((section, sectionIndex) => (
                                    <CodifierDropdownSection
                                        id={section.id}
                                        type={CodifierEventType.SECTION}
                                        isTheme={false}
                                        themeNext={Number(section.blocks?.length || 0) + 1}
                                        themeLessonNext={Number(section.blocks?.length || 0) + 1}
                                        order={section.order + 1}
                                        isEditable={isEditable}
                                        isHighlighted={isHighlight && selectedLocation?.section === section.id}
                                        isOpenedInit={
                                            !!topicLocations.filter((value) => value.sectionId === section.id).length
                                        }
                                        key={`Section ${section.id}`}
                                        title={section.title}
                                        onDeleteClick={(type, id) => handleDelete(type, id, parallel.id)}
                                        onCreateClick={handleBlockCreate}
                                        onEditClick={handleSectionPatch}
                                        onSelect={handleSelectAnything}
                                        disabled={!section.blocks?.length}
                                        canHover={canHover}
                                        handleCanHover={handleCanHover}
                                        index={sectionIndex}
                                        canDelete={noQuestionInSection(section)}
                                        onReplaceClick={handleReplace({
                                            id: section.id,
                                            type: CodifierEventType.SECTION,
                                            parallel: parallel.id,
                                            subject: subject.id,
                                            section: section.id,
                                        })}
                                        parallel={parallel.id}
                                        parentId={parallel.id}
                                    >
                                        {section.blocks?.map((blockEl, blockIndex) => (
                                            <CodifierDropdownSection
                                                id={blockEl.id}
                                                isTheme={true}
                                                themeLessonNext={(blockEl?.topics?.length ?? 0) + 1}
                                                isHighlighted={isHighlight && selectedLocation?.block === blockEl.id}
                                                order={blockEl.order + 1}
                                                isEditable={isEditable}
                                                isOpenedInit={
                                                    !!topicLocations.filter((value) => value.blockId === blockEl.id)
                                                        .length
                                                }
                                                key={`Theme ${blockEl.id}`}
                                                title={blockEl.title}
                                                type={CodifierEventType.BLOCK}
                                                onDeleteClick={(type, id) =>
                                                    handleDelete(type, id, parallel.id, section.id)
                                                }
                                                onCreateClick={(params, values) =>
                                                    handleTopicCreate(params, values, section.id, parallel.id)
                                                }
                                                onEditClick={(id, values, parentId, parallelId) =>
                                                    handleBlockPatch(id, values, parentId, section.id, parallelId)
                                                }
                                                onSelect={handleSelectAnything}
                                                childrenOrder={blockEl?.topics?.length}
                                                disabled={!blockEl?.topics?.length}
                                                canHover={canHover}
                                                handleCanHover={handleCanHover}
                                                index={blockIndex}
                                                canDelete={noQuestionInBlock(blockEl, blockEl?.topics ?? [])}
                                                onReplaceClick={handleReplace({
                                                    id: blockEl.id,
                                                    type: CodifierEventType.BLOCK,
                                                    section: section.id,
                                                    parallel: parallel.id,
                                                    subject: subject.id,
                                                })}
                                                parallel={parallel.id}
                                            >
                                                {blockEl?.topics?.map((topic, topicIndex) => (
                                                    <ThemeCodifier
                                                        id={topic.id}
                                                        subjectId={subject.id}
                                                        type={CodifierEventType.TOPIC}
                                                        needOrder
                                                        isThemeSubject={true}
                                                        title={topic.title}
                                                        themeNumber={topic.order + 1}
                                                        numQuestions={topic.questionsQuantity}
                                                        numQuestionsUnpublished={topic.questionsQuantityUnpublished}
                                                        handleCreateQuestion={handleCreateQuestion}
                                                        isSelectedInit={Boolean(
                                                            isHighlight && selectedLocation?.topic === topic.id,
                                                        )}
                                                        isCheckedInit={
                                                            hasTopicCheckbox &&
                                                            !!topicLocations.filter(
                                                                (value) => value.topicId === topic.id,
                                                            ).length
                                                        }
                                                        isEditable={isEditable}
                                                        handleSelectThemeLesson={handleSelectAnything}
                                                        handleSelectCheckbox={handleTopicSelectCheckbox}
                                                        onEditClick={(id, values, parallel, blockId, section) =>
                                                            handleTopicPatch(
                                                                id,
                                                                values,
                                                                parallel,
                                                                blockId ? blockId : blockEl?.id,
                                                                section,
                                                            )
                                                        }
                                                        key={`LessonTheme ${topic.id}`}
                                                        onDeleteClick={(type, id) =>
                                                            handleDelete(type, id, parallel.id, section.id, blockEl.id)
                                                        }
                                                        hasCheckbox={hasTopicCheckbox}
                                                        canHover={canHover}
                                                        handleCanHover={handleCanHover}
                                                        themeIndex={topicIndex}
                                                        blockIndex={blockIndex}
                                                        canDelete={noQuestionInTheme(topic)}
                                                        onReplaceClick={handleReplace({
                                                            id: topic.id,
                                                            type: CodifierEventType.TOPIC,
                                                            parallel: parallel.id,
                                                            subject: subject.id,
                                                            section: section.id,
                                                            block: blockEl.id,
                                                        })}
                                                        parallel={parallel.id}
                                                        section={section.id}
                                                        block={blockEl.id}
                                                    />
                                                ))}
                                            </CodifierDropdownSection>
                                        ))}
                                    </CodifierDropdownSection>
                                ))}
                            </CodifierDropdownGrade>
                        ))
                    ) : (
                        <div className={cnCodifier('empty')}>Нет параллелей</div>
                    )}
                </SubjectName>
            ))}
            {replaceOpened && (
                <div className={cnCodifier('modalContainer')}>
                    <ReplaceWindow onCloseClick={onCloseReplaceClick} params={replaceParams} subjects={subjects} />
                </div>
            )}
        </div>
    );
};

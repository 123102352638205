import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { ResponseLesson } from 'types/lesson';

export const getLessons = (topicId?: number, ids?: number[]): Promise<ResponseLesson[]> => {
    const getUrl = `/lesson/${topicId === undefined ? '' : `?topic=${topicId}`}${
        ids?.length && topicId ? `&include_ids=${ids.join(',')}` : ''
    }`;
    return api
        .get<never, AxiosResponse<ResponseLesson[]>>(getUrl)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { FieldDropdown } from '@lms-elements/atomic';
import { CodifierSelector } from 'containers/CodifierSelector/CodifierSelector';
import { SelectedElement } from 'containers/CodifierSelector/utils';
import { useOuterClick } from 'hooks/useOuterClick';
import { useWindowResize } from 'hooks/useWindowResize';
import { ISelectedValues } from 'pages/QuestionCreationPage/QuestionCreationPage.types';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';

import { Codifier } from 'components/Codifier';
import {
    getCodifierSelectorInitValues,
    getLessonLocation,
    getSkillLocation,
    LessonLocation,
    SkillLocation,
} from 'components/Codifier/utils';

import { useQuestionLocationRows } from './useQuestionLocationRows';
import { getLocationSkillString, getLocationString } from './utils';

import './QuestionLocationDropdown.scss';

const cnQuestionLocationDropdown = cn('question_location_dropdown');

interface QuestionLocationDropdownProps {
    selectedTopics?: number[];
    topicsLocation?: string;
    selectedSkills?: { ids: number[]; location: string };
    handleSelectLesson?: (values: ISelectedValues) => void;
    handleSelectSkill?: (values: ISelectedValues) => void;
    isSkill?: boolean;
    name: string;
    placeholder?: string;
    validators?: ((value: string) => undefined | string)[];
    onMultiSelect?: (items: { lessonLocation?: LessonLocation[]; skillLocation?: SkillLocation[] }) => void;
    flagReset?: boolean;
    setFlagReset?: (value: boolean) => void;
    searchPage?: boolean;
    disableValidation?: boolean;
    isEditPage?: boolean;
    showAllSubjects?: boolean;
    selectedSubject?: number;
    codifierSelectorInitValues?: SelectedElement;
}

export const QuestionLocationDropdown: React.FC<QuestionLocationDropdownProps> = ({
    isSkill = false,
    handleSelectLesson,
    handleSelectSkill,
    selectedSkills = { ids: [], location: '' },
    selectedTopics,
    name,
    placeholder,
    validators = [],
    onMultiSelect,
    flagReset,
    setFlagReset,
    searchPage = false,
    disableValidation,
    showAllSubjects,
    selectedSubject,
}) => {
    const subjects = useAppSelector((store) => store.codifier.codifierData);
    const skills = useAppSelector((store) => store.codifier.codifierSkillsData);
    const fetchStatus = useAppSelector((store) => store.codifier.fetchStatus);
    const codifierMap = useAppSelector((store) => ({
        subjectsMap: store.codifier.subjectsMap,
        parallelsMap: store.codifier.parallelsMap,
        sectionsMap: store.codifier.sectionsMap,
        blocksMap: store.codifier.blocksMap,
        topicsMap: store.codifier.topicsMap,
    }));
    const { input } = useField<string>(name);

    const isTablet = useWindowResize();
    const [location, setLocation] = useState<LessonLocation[]>([]);
    const [locationSkill, setLocationSkill] = useState<SkillLocation[]>([]);

    const dropdownPlaceholder = useMemo(() => {
        if (placeholder) {
            return placeholder;
        }

        if (isSkill) {
            return 'Выберите из списка';
        }

        return isTablet ? 'Выберите тему для сохранения вопроса' : 'Выберите тему, подтему для сохранения вопроса';
    }, [placeholder, isSkill, isTablet]);

    useEffect(() => {
        if (flagReset) {
            setLocation([]);
            setLocationSkill([]);
            setFlagReset?.(false);
        }
    }, [flagReset, setFlagReset]);

    useEffect(() => {
        if (selectedSkills.location && !locationSkill.length) {
            setLocationSkill(selectedSkills.ids.map((s) => getSkillLocation(skills, s)));
            input.onChange(selectedSkills.location);
        }
    }, [input, locationSkill.length, selectedSkills.ids, selectedSkills.location, skills]);

    useEffect(() => {
        if (fetchStatus === FetchStatus.FETCHED && selectedTopics?.length && !location.length && subjects) {
            const newLocation = selectedTopics.map((l) => getLessonLocation(subjects, l));
            setLocation(newLocation);
            input.onChange(getLocationString(newLocation));
        }
    }, [fetchStatus, input, location.length, selectedTopics, subjects]);

    const handleSelectLessonCheckbox = useCallback(
        (topicId: number) => {
            if (subjects) {
                const data = getLessonLocation(subjects, topicId);
                setLocation((currentLocation) => {
                    const newLocation = currentLocation.filter((value) => value.topicId === data.topicId).length
                        ? currentLocation.filter((value) => value.topicId !== data.topicId)
                        : [...currentLocation, { ...data }];

                    if (onMultiSelect) {
                        onMultiSelect({ lessonLocation: newLocation });
                    }

                    input.onChange(getLocationString(newLocation));
                    if (handleSelectLesson) {
                        handleSelectLesson({
                            location: getLocationString(newLocation),
                            ids: newLocation.map((value) => value.topicId),
                        });
                    }

                    return newLocation;
                });
            }
        },
        [handleSelectLesson, input, onMultiSelect, subjects],
    );

    const validate = useCallback((value: string) => (value ? undefined : 'error'), []);

    const handleSelectSkillCheckbox = useCallback(
        (data: SkillLocation) => {
            setLocationSkill((currentLocationSkill) => {
                const newLocationSkill = currentLocationSkill.filter((value) => value.skillId === data.skillId).length
                    ? currentLocationSkill.filter((value) => value.skillId !== data.skillId)
                    : [...currentLocationSkill, { ...data }];

                if (onMultiSelect) {
                    onMultiSelect({ skillLocation: newLocationSkill });
                }

                input.onChange(getLocationSkillString(newLocationSkill));
                if (handleSelectSkill) {
                    handleSelectSkill({
                        location: getLocationSkillString(newLocationSkill),
                        ids: newLocationSkill.map((value) => value.skillId),
                    });
                }

                return newLocationSkill;
            });
        },
        [handleSelectSkill, input, onMultiSelect],
    );

    const ref = useRef<HTMLDivElement>(null);

    const [isOpened, setIsOpened] = useState(false);

    useOuterClick(ref, setIsOpened);
    const rows = useQuestionLocationRows(
        ref,
        fetchStatus === FetchStatus.FETCHED ? input.value : '',
        locationSkill.length || location.length,
        isOpened,
    );

    const fieldValidators = useMemo(() => (searchPage || disableValidation ? [] : [validate, ...validators]), [
        disableValidation,
        searchPage,
        validate,
        validators,
    ]);

    const codifierSelectorInitValues = useMemo(() => {
        return getCodifierSelectorInitValues(codifierMap, selectedTopics);
    }, [codifierMap, selectedTopics]);

    const resetInput = useRef(() => {
        input.onChange('');
        setLocation([]);
    });

    useEffect(() => {
        resetInput.current();
    }, [selectedSubject]);

    return (
        <div
            className={cnQuestionLocationDropdown('container', {
                isOpened,
                isSelected: Boolean(location.length) || Boolean(locationSkill.length),
            })}
            ref={ref}
            title={input?.value ?? ''}
        >
            {isSkill ? (
                <FieldDropdown
                    options={[]}
                    name={name}
                    validators={fieldValidators}
                    placeholder={dropdownPlaceholder}
                    child={
                        <div className={cnQuestionLocationDropdown('container_children')}>
                            <Codifier
                                skills={skills}
                                isEditable={false}
                                handleSelectSkillCheckbox={handleSelectSkillCheckbox}
                                skillLocations={locationSkill}
                                hasTopicCheckbox
                                skillsCodifier
                            />
                        </div>
                    }
                    isTextArea={true}
                    rows={rows}
                    isLocationDropdown
                ></FieldDropdown>
            ) : (
                <FieldDropdown
                    options={[]}
                    name={name}
                    validators={fieldValidators}
                    placeholder={dropdownPlaceholder}
                    disabled={fetchStatus !== FetchStatus.FETCHED}
                    child={
                        <div className={cnQuestionLocationDropdown('container_children')}>
                            {fetchStatus === FetchStatus.FETCHED && (
                                <CodifierSelector
                                    onTopicCheckboxSelect={handleSelectLessonCheckbox}
                                    initValues={codifierSelectorInitValues}
                                    showSelectSubject={!showAllSubjects}
                                    selectedSubject={selectedSubject}
                                    withCheckboxes
                                />
                            )}
                        </div>
                    }
                    isTextArea={true}
                    rows={rows}
                    isLocationDropdown
                ></FieldDropdown>
            )}
        </div>
    );
};

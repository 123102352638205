import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-final-form';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { cn } from '@bem-react/classname';
import { createDefaultQuestion, IQuestionForTeacher } from '@lms-elements/test-task';
import { questionType } from '@lms-elements/test-task/build/@types/packages/TestTask/src/Question/Expanded/ExpandedQuestion.types';
import { IFormValuesCreateQuestions } from 'pages/QuestionCreationPage/QuestionCreationPage.types';
import { QuestionsList } from 'pages/QuestionCreationPage/QuestionsList';

import { getHeaderForForm } from 'utils/questions';

const cnQuestionCreationPage = cn('question_creation_page');

interface QuestionsProps {
    canDrop: boolean;
    setCanDrop: (value: React.SetStateAction<boolean>) => void;
    questionType: questionType;
    onDelete: (props: FieldArrayRenderProps<IQuestionForTeacher, HTMLInputElement>) => void;
    isTextEditor: boolean;
    flagDelete: boolean;
}

export const Questions: React.FC<QuestionsProps> = ({
    canDrop,
    setCanDrop,
    questionType,
    onDelete,
    isTextEditor,
    flagDelete,
}) => {
    const formApi = useForm<IFormValuesCreateQuestions>();

    const state = formApi.getState();

    useEffect(() => {
        const questions = [createDefaultQuestion(questionType, isTextEditor || flagDelete)].map((value) => value);

        if (!canDrop && !state.values.questions.length && isTextEditor !== null && questions[0]) {
            formApi.change(
                'questions',
                [createDefaultQuestion(questionType, isTextEditor || flagDelete)].map((value) => value),
            );
        }
    }, [canDrop, flagDelete, formApi, isTextEditor, questionType, state?.values?.questions?.length]);

    const handleQuestionDelete = useCallback(
        (fieldsProps: FieldArrayRenderProps<IQuestionForTeacher, HTMLInputElement>) => {
            if (fieldsProps.fields.length === 1) {
                setCanDrop(true);
            }

            formApi.change('questions', []);

            onDelete(fieldsProps);
        },
        [formApi, onDelete, setCanDrop],
    );

    return (
        <div className={cnQuestionCreationPage('type_question')}>
            <div>
                <div className={cnQuestionCreationPage('question_form_header')}>{getHeaderForForm(questionType)}</div>
                <FieldArray name="questions">
                    {(props: FieldArrayRenderProps<IQuestionForTeacher, HTMLInputElement>) => (
                        <QuestionsList props={props} questionType={questionType} onDelete={handleQuestionDelete} />
                    )}
                </FieldArray>
            </div>
        </div>
    );
};

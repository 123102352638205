import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

export interface FormState {
    [formName: string]: {
        errors?: Record<string, string>;
        formName: string;
    };
}

interface FormErrorPayload {
    errors: Record<string, string>;
    formName: string;
}

const initialState: FormState = {};

const formErrorSlice = createSlice<FormState, SliceCaseReducers<FormState>>({
    name: 'formError',
    initialState,
    reducers: {
        setFormError(state, action: PayloadAction<FormErrorPayload>) {
            state[action.payload.formName] = action.payload;
        },
    },
});

export const formErrorReducer = formErrorSlice.reducer;
export const { setFormError } = formErrorSlice.actions;

import {
    ITrainingCountRequest,
    ITrainingProgressRequest,
    ITrainingQuestionRequest,
    ITrainingSearchBlockRequest,
    ITrainingSearchBlockResponse,
    ITrainingSearchBlocksQuestionsData,
} from '@fsd-shared/models/materials';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getQuestionSearchByQueryFilters } from 'api/services/questionCreate';
import { IQuestion } from 'containers/QuestionSearchResult/QuestionSearchResult';
import { ISearchBlock } from 'containers/QuestionSearchResult/types';
import { Status } from 'pages/QuestionBankPage/types';

import { convertQueryFiltersToPageFilters } from 'utils/searchBlocks';

import {
    getMaterialsMeta,
    getTrainingCount,
    getTrainingProgress,
    getTrainingQuestions,
    postTrainingSearchBlocks,
} from './materialsApi';

export const getTrainingProgressAction = createAsyncThunk(
    'materials/getTrainingProgressAction',
    async (params: ITrainingProgressRequest) => getTrainingProgress(params),
);

export const getMaterialsMetaAction = createAsyncThunk('materials/getMaterialsMetaAction', async (course: number) =>
    getMaterialsMeta(course),
);

export const getTrainingQuestionsAction = createAsyncThunk(
    'materials/getTrainingQuestionsAction',
    async (params: ITrainingQuestionRequest & { isLoadingMore?: boolean }) => {
        delete params?.isLoadingMore;
        return getTrainingQuestions(params);
    },
);

export const getTrainingCountAction = createAsyncThunk(
    'materials/getTrainingCountAction',
    async (params: ITrainingCountRequest) => getTrainingCount(params),
);

export const getTrainingSearchBlocksQuestionsAction = createAsyncThunk(
    'materials/getTrainingSearchBlocksQuestionsAction',
    async (params: ITrainingSearchBlockRequest): Promise<ITrainingSearchBlocksQuestionsData> => {
        const initSearchBlocks = await postTrainingSearchBlocks(params);
        const singleQuestions: IQuestion[] = [];
        const searchBlocks: ISearchBlock[] = [];
        let excludesAndSingleIds: number[] = [];
        const singleQuestionsIds: number[] = [];

        await Promise.all(
            initSearchBlocks.map(async ({ filters, single }) => {
                if (single) {
                    singleQuestions.push((await getQuestionSearchByQueryFilters(filters))[0]);
                    if (filters?.id) {
                        singleQuestionsIds.push(filters.id);
                    }
                }
                excludesAndSingleIds = [...(filters?.excludeIds ?? []), ...excludesAndSingleIds];
            }),
        );

        const initExcludesIds = excludesAndSingleIds.filter((item) => !singleQuestionsIds?.includes(item));
        await Promise.all(
            initSearchBlocks
                .filter((item) => !item.single)
                .map(async ({ filters, single, id }, index) => {
                    if (!single) {
                        searchBlocks.push({
                            id: id,
                            numSearch: index + 1,
                            questions: await getQuestionSearchByQueryFilters({
                                ...filters,
                                excludeIds: [],
                                status: Status.Published,
                            }),
                            filters: convertQueryFiltersToPageFilters(filters),
                        });
                    }
                }),
        );

        return { singleQuestions, searchBlocks, initSearchBlocks, initExcludesIds };
    },
);

import React, { useEffect, useState } from 'react';
import { Tabs } from 'antd';

import { TAppTabs } from './AppTabs.types';

import './style.scss';
export const AppTabs: React.FC<TAppTabs> = (props) => {
    const { tabsList, activeTabKey, onTabClick, tabPosition = 'top', ...rest } = props;
    const [activeKey, setActiveKey] = useState(activeTabKey);

    useEffect(() => {
        setActiveKey(activeTabKey);
    }, [activeTabKey]);

    const handleTabClick = (key: string) => {
        setActiveKey(key);
        onTabClick(key);
    };
    return (
        <Tabs
            className={'app-tab'}
            activeKey={activeKey}
            onTabClick={(key) => handleTabClick(key)}
            tabPosition={tabPosition}
            items={tabsList}
            {...rest}
        />
    );
};

import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import * as ScheduleService from 'store/actions/studentSchedule';
import { FetchStatus } from 'types/api';
import {
    TChartInfo,
    TEventRooms,
    TLessonInformationNormalizes,
    TLessonSchedules,
    TParallel,
} from 'types/studentScheduleTypes';

export interface StudentsScheduleState {
    getStatus: FetchStatus;
    getChartStatus: FetchStatus;
    postStatus: FetchStatus;
    filterTree: TParallel[];
    wasReset: boolean;
    selectedDay: string;
    lessonSchedules: TLessonSchedules[];
    eventRooms: TEventRooms[];
    chartData: TChartInfo[];
    filters: number[];
    lessonsInformation: TLessonInformationNormalizes;
    error: unknown;
}
export const initialState: StudentsScheduleState = {
    getStatus: FetchStatus.INITIAL,
    postStatus: FetchStatus.INITIAL,
    getChartStatus: FetchStatus.INITIAL,
    filterTree: [],
    wasReset: false,
    selectedDay: '', // as 'YYYY-MM-DD'
    lessonSchedules: [],
    eventRooms: [],
    filters: [],
    chartData: [],
    lessonsInformation: {} as TLessonInformationNormalizes,
    error: null,
};

const StudentScheduleSlice = createSlice<StudentsScheduleState, SliceCaseReducers<StudentsScheduleState>>({
    name: 'studentSchedule',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(ScheduleService.clearScheduleListAction, (state) => {
            state.lessonSchedules = [];
            state.eventRooms = [];
            state.chartData = [];
            state.wasReset = true;
        });
        builder.addCase(ScheduleService.clearLessonInfoAction, (state) => {
            state.lessonsInformation = {} as TLessonInformationNormalizes;
        });
        builder.addCase(ScheduleService.setFiltersAction, (state, { payload }) => {
            state.filters = payload;
        });
        builder.addCase(ScheduleService.setSelectedDayAction, (state, { payload }) => {
            state.selectedDay = payload;
        });
        builder.addCase(ScheduleService.getFilterTreeAction.pending, (state) => {
            state.getStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(ScheduleService.getFilterTreeAction.fulfilled, (state, { payload }) => {
            state.getStatus = FetchStatus.FETCHED;
            state.filterTree = payload;
            state.error = null;
        });
        builder.addCase(ScheduleService.getFilterTreeAction.rejected, (state, { error }) => {
            state.getStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(ScheduleService.getDayScheduleAction.pending, (state) => {
            state.postStatus = FetchStatus.FETCHING;
            state.error = null;
            state.wasReset = false;
        });
        builder.addCase(ScheduleService.getDayScheduleAction.fulfilled, (state, { payload }) => {
            state.postStatus = FetchStatus.FETCHED;
            state.lessonSchedules = payload;
            state.error = null;
            state.wasReset = false;
        });
        builder.addCase(ScheduleService.getDayScheduleAction.rejected, (state, { error }) => {
            state.postStatus = FetchStatus.ERROR;
            state.error = error;
            state.wasReset = false;
        });
        builder.addCase(ScheduleService.getLessonInformationAction.pending, (state) => {
            state.getStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(ScheduleService.getLessonInformationAction.fulfilled, (state, { payload }) => {
            state.getStatus = FetchStatus.FETCHED;
            state.lessonsInformation = payload;
            state.error = null;
        });
        builder.addCase(ScheduleService.getLessonInformationAction.rejected, (state, { error }) => {
            state.getStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(ScheduleService.getEventRoomInformationAction.pending, (state) => {
            state.getStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(ScheduleService.getEventRoomInformationAction.fulfilled, (state, { payload }) => {
            state.getStatus = FetchStatus.FETCHED;
            state.lessonsInformation = payload;
            state.error = null;
        });
        builder.addCase(ScheduleService.getEventRoomInformationAction.rejected, (state, { error }) => {
            state.getStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(ScheduleService.getDayScheduleChartAction.pending, (state) => {
            state.getChartStatus = FetchStatus.FETCHING;
            state.chartData = [];
            state.error = null;
        });
        builder.addCase(ScheduleService.getDayScheduleChartAction.fulfilled, (state, { payload }) => {
            state.getChartStatus = FetchStatus.FETCHED;
            state.chartData = payload;
            state.error = null;
        });
        builder.addCase(ScheduleService.getDayScheduleChartAction.rejected, (state, { error }) => {
            state.getChartStatus = FetchStatus.ERROR;
            state.error = error;
        });
    },
});

export const studentScheduleReducer = StudentScheduleSlice.reducer;

import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Button, ButtonViewEnum } from '@lms-elements/atomic';
import { useOuterClick } from '@lms-elements/hooks';

import { INSERT_WORD_POPUP_WIDTH } from './constants';
import { InsertWordOption } from './InsertWordOption';
import { IInsertWordPopUpProps } from './InsertWordPopUp.types';

import './InsertWordPopUp.scss';

const cx = cn('custom-editor-insert-word-popup');

export const InsertWordPopUp: React.FC<IInsertWordPopUpProps> = ({
    options,
    setOptions,
    onSave,
    onCancel,
    setInPopup,
    left,
    top,
}) => {
    const onOptionEnter = useCallback(
        (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key.toLowerCase() === 'enter') {
                e.preventDefault();
                setOptions((prev) => [...prev.slice(0, index + 1), '', ...prev.slice(index + 1)]);
                setActiveIndex(index + 1);
            }
        },
        [setOptions],
    );

    const onOptionSave = useCallback(
        (index: number) => (option: string) =>
            setOptions((prev) => prev.map((_option, _index) => (_index === index ? option : _option))),
        [setOptions],
    );

    const onOptionDelete = useCallback(
        (index: number) => () =>
            options.length > 1 ? setOptions((prev) => prev.filter((_, _index) => _index !== index)) : setOptions(['']),
        [options.length, setOptions],
    );

    const style: CSSProperties = useMemo(() => ({ left, top }), [left, top]);

    const [position, setPosition] = useState<'left' | 'right'>('right');

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handlePopUpStyles = () => {
            if (ref.current) {
                if (left + INSERT_WORD_POPUP_WIDTH < window.innerWidth) {
                    setPosition('right');
                } else {
                    setPosition('left');
                }
            }
        };
        window.addEventListener('resize', handlePopUpStyles, { passive: true });
        handlePopUpStyles();
        return () => window.removeEventListener('resize', handlePopUpStyles);
    }, [left, top]);

    const onPopupClick = useCallback(() => setInPopup(true), [setInPopup]);

    useOuterClick(ref, setInPopup);

    const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

    const activeInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (activeInputRef.current && activeIndex !== undefined) {
            activeInputRef.current.focus();
        }
    }, [activeIndex]);

    const onOptionFocus = useCallback((index: number) => () => setActiveIndex(index), []);

    useEffect(() => () => setActiveIndex(undefined), []);

    return (
        <div ref={ref} className={cx({ position })} style={style} onClick={onPopupClick}>
            <div className={cx('content', { position })}>
                <h4 className={cx('title')}>Трансформировать в пропуск</h4>
                {options.map((option, index) => (
                    <div key={`${index}`} className={cx('option-wrapper')}>
                        <InsertWordOption
                            onDelete={onOptionDelete(index)}
                            onEnter={onOptionEnter(index)}
                            onSave={onOptionSave(index)}
                            startValue={option}
                            ref={index === activeIndex ? activeInputRef : null}
                            onFocus={onOptionFocus(index)}
                        />
                        {index === 0 && (
                            <span className={cx('enter-prompt')}>*enter с новой строки - новый вариант ответа</span>
                        )}
                    </div>
                ))}
                <div className={cx('buttons')}>
                    <Button customClasses={cx('save')} view={ButtonViewEnum.action} size="m" onClick={onSave}>
                        Сохранить
                    </Button>
                    <Button customClasses={cx('cancel')} view={ButtonViewEnum.bordered} size="m" onClick={onCancel}>
                        Отмена
                    </Button>
                </div>
            </div>
        </div>
    );
};

import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { useErrorProcess } from 'hooks/useErrorProcess';
import { clearError } from 'store/reducers/error';
import { useAppSelector } from 'store/store';

import { Alert } from 'components/Alert';

import './ErrorModal.scss';

const CnModal = cn('modal');

export const ErrorModal: React.FC = () => {
    const {
        descriptions,
        error,
        extra,
        isFrontend,
        needHideButtons,
        needHideErrorIcon,
        isBlockLesson,
        isOlBlocked,
        disableCancelButton = false,
        isLastAttemptDeliting,
        isCourseDeleting,
        customDescription,
        createCertificate,
        isTimezoneError,
        actionButtonClick,
        cancelButtonClick,
    } = useAppSelector((store) => store.error);

    const dispatch = useDispatch();
    const history = useHistory();

    const [showExtra, setShowExtra] = useState(false);

    useErrorProcess(error);

    const handleActionButtonClick = useCallback(() => {
        dispatch(clearError());

        if (actionButtonClick) {
            return actionButtonClick();
        }

        if (isFrontend) {
            return history.goBack();
        }

        document.location.reload();
    }, [actionButtonClick, dispatch, history, isFrontend]);

    const handleModalClose = useCallback(() => {
        dispatch(clearError());

        if (isFrontend) {
            history.goBack();
        }
    }, [dispatch, history, isFrontend]);

    const handleShowExtra = useCallback(() => {
        if (cancelButtonClick) cancelButtonClick();
        else setShowExtra(true);
    }, [cancelButtonClick]);

    const extraInfo = useMemo(() => (extra ? JSON.stringify(extra, null, 2) : undefined), [extra]);

    const actionText = useMemo(() => {
        switch (true) {
            case isTimezoneError:
                return 'Понятно';
            case createCertificate:
                return 'Утвердить';
            case isLastAttemptDeliting || isCourseDeleting:
                return 'Удалить';
            case !isBlockLesson:
                return 'Перезагрузить';
            case isOlBlocked:
                return 'Вернуться';
            default:
                return 'Скачать';
        }
    }, [createCertificate, isBlockLesson, isCourseDeleting, isTimezoneError, isLastAttemptDeliting, isOlBlocked]);

    const cancelText = useMemo(() => {
        switch (true) {
            case isLastAttemptDeliting || createCertificate || isCourseDeleting:
                return 'Отмена';
            case isBlockLesson:
                return 'Продолжить';
            default:
                return 'Подробнее';
        }
    }, [createCertificate, isBlockLesson, isCourseDeleting, isLastAttemptDeliting]);

    return (
        <>
            {error && (
                <div className={CnModal()}>
                    <Alert
                        errorInfo={error}
                        errorDescriptions={customDescription ? [customDescription] : descriptions}
                        errorExtra={extraInfo}
                        actionText={actionText}
                        cancelText={cancelText}
                        actionCallback={handleActionButtonClick}
                        cancelCallback={handleShowExtra}
                        withCloseButton={!isFrontend}
                        closeCallback={handleModalClose}
                        showExtra={showExtra}
                        needHideErrorIcon={needHideErrorIcon}
                        showCancelButton={!showExtra && !disableCancelButton && !isTimezoneError}
                        needHideButtons={needHideButtons}
                        isBlockLesson={isBlockLesson}
                        isLastAttempt={isLastAttemptDeliting || isCourseDeleting}
                        createCertificate={createCertificate}
                    />
                </div>
            )}
        </>
    );
};

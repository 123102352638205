import { HomeworkItemProps, LessonHomeworkItemProps } from '@lms-elements/atomic';
import moment from 'moment';
import { AssignmentProgressPreview } from 'types/assignmentProgress';
import { FilterTypes, IHomeworkItem } from 'types/homework';
import { LessonTypes, ScheduleEvent } from 'types/schedule';

import { combineHomeworks } from './homework';

const normalizeHomeworkItem = (
    homework: IHomeworkItem | AssignmentProgressPreview,
): HomeworkItemProps & { courseId?: number } => {
    const homeworkItem = Object.keys(homework).includes('type');
    const isMandatory = homeworkItem
        ? (homework as IHomeworkItem).type.isMandatory
        : (homework as AssignmentProgressPreview).assignment.type.isMandatory;
    return {
        ...homework,
        assignedDate: homework.assignedDate ?? '',
        title: homework.title ?? '',
        courseGroupId: homework.courseGroup?.id,
        courseId: homework.course?.id,
        lessonId: typeof homework.lesson === 'number' ? homework.lesson : homework.lesson?.id ?? 0,
        subjectId: typeof homework.subject === 'number' ? homework.subject : homework.subject?.id ?? 0,
        description: 'description' in homework ? homework.description ?? '' : '',
        taskId: Number(homework.id),
        extraInfo: {
            label: isMandatory ? 'Обязательное' : 'Рекомендованное',
        },
    };
};

export interface HomeworkObject {
    [key: string]: {
        date: string;
        data: HomeworkItemProps[];
        assigned: HomeworkItemProps[];
    };
}

export const getHomeworksObject = (
    homeworks: Record<FilterTypes, (IHomeworkItem | AssignmentProgressPreview)[]>,
    isStudent?: boolean,
): HomeworkObject => {
    const {
        [FilterTypes.ASSIGNED]: assignedTasks,
        [FilterTypes.DEADLINE_FOR_COMPLETE]: studentTasks,
        [FilterTypes.DEADLINE_FOR_CHECK]: teacherTasks,
    } = homeworks;

    const data = (isStudent
        ? studentTasks
        : combineHomeworks<IHomeworkItem | AssignmentProgressPreview>(teacherTasks)
    ).reduce((result, homework) => {
        const date = moment(homework.deadlineForComplete).format('YYYY-MM-DD');
        if (result[date]) {
            result[date].data.push(normalizeHomeworkItem(homework));
        } else {
            result[date] = {
                data: [normalizeHomeworkItem(homework)],
                assigned: [],
                date,
            };
        }

        return result;
    }, {} as HomeworkObject);

    assignedTasks.forEach((task) => {
        const assignedDate = moment(task.assignedDate).format('YYYY-MM-DD');

        if (data[assignedDate]) {
            data[assignedDate].assigned.push(normalizeHomeworkItem(task));
        } else {
            data[assignedDate] = {
                assigned: [normalizeHomeworkItem(task)],
                data: [],
                date: assignedDate,
            };
        }
    });

    return data;
};

export const getLinkToLesson = (
    lesson: ScheduleEvent | LessonHomeworkItemProps,
    isEventRoom?: boolean,
    isProctor?: boolean,
    isAssembly?: boolean,
): string => {
    if (isAssembly) return '';
    const lessonTab = isEventRoom
        ? 'online_lesson'
        : moment(lesson.datetimeEnd).isBefore()
        ? 'attendance'
        : 'materials';
    return isProctor && isEventRoom
        ? `/proctoring/event-room/${lesson.id}`
        : `/subjects/${lesson.subject.id}/${lesson.lesson.id}/${lessonTab}?course_group=${lesson.courseGroup.id}`;
};

export const getLabelByScheduleType = (type: LessonTypes): string => {
    switch (type) {
        case LessonTypes.ONLINE:
            return 'Онлайн-занятие';
        case LessonTypes.SELFPREPARATION:
            return 'Самоподготовка';
        case LessonTypes.TEST:
            return 'Контрольная работа';
        case LessonTypes.PARENT_MEETING:
            return 'Встреча с родителем';
        default:
            return 'Онлайн-занятие';
    }
};

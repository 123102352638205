import { isEmptyList } from '@frontend-modules/frontend-utils';
import moment from 'moment';
import { payloadTimeFormatter } from 'src-new/utils';
import { IAssignmentItem } from 'types/assignment';
import { AssignmentProgressStatus } from 'types/assignmentProgress';
import {
    AssignmentProgress,
    EAssignedTypes,
    EDeadlineTypes,
    IHomeworkItem,
    Task,
    TCreateDeferredAssignmentAction,
    TPostDeferredAssignmentResponse,
    TUpdateDeferredAssignmentAction,
} from 'types/homework';
import { MarkData } from 'types/mark';
import { Student } from 'types/students';

import { ITaskDate } from 'components/Task/Task';
import { EDateTimeTypesList } from 'components/Task/utils';

interface HomeworkTableFormValues {
    selectAll: {
        taskId: number;
        checked: boolean;
        disabled: boolean;
    }[];
    students: {
        studentId: number;
        tasks: {
            taskId: number;
            checked: boolean;
            blocked?: boolean;
            overdued?: boolean;
            repeatBlocked?: boolean;
        }[];
    }[];
}

export const getAssignedTasks = (blockTasks: Task[]): Task[] => {
    const tasks = blockTasks.filter((task) => task.isPosted);

    return tasks;
};

export const getAllTasks = (tasks: Task[], progresses?: AssignmentProgress[]): Task[] => {
    const tasksWithoutAssignment = (progresses ?? [])
        .filter(({ taskId }) => !tasks.some((task) => task.id === taskId))
        .reduce((result, homework) => {
            if (result.length === 0 || !result.some((task) => task.id === homework.taskId)) {
                result.push({
                    id: homework.taskId,
                    title: homework.taskTitle,
                    type: homework.taskType,
                    isTemplate: false,
                    isForMethodist: false,
                    isPosted: true,
                    assignedDate: homework.assignedDate,
                    deadlineForComplete: homework.deadlineForComplete,
                    assignedType: homework?.assignedType ?? EAssignedTypes.DATE_TIME,
                    deadlineType: homework?.deadlineType ?? EDeadlineTypes.DATE_TIME,
                    assignedDelay: homework?.assignedDelay,
                    deadlineDelay: homework?.deadlineDelay,
                    timeToComplete: homework?.timeToComplete,
                    allStudents: homework?.allStudents,
                });
            }

            return result;
        }, [] as Task[]);

    const tasksWithAssignment = tasks.map((task) => {
        const taskWithAllStudents = progresses?.find(({ taskId, allStudents }) => taskId === task.id && allStudents);
        return taskWithAllStudents
            ? {
                  ...task,
                  allStudents: taskWithAllStudents.allStudents,
              }
            : task;
    });

    return [...tasksWithAssignment, ...tasksWithoutAssignment];
};

export const getHomeworkTableInitValues = (
    students: Student[],
    tasks: Task[],
    assignmentProgress?: AssignmentProgress[],
    repeatTaskForm?: boolean,
    expelledStudents?: Student[],
): HomeworkTableFormValues => {
    const allTasks = getAllTasks(tasks, assignmentProgress);

    const selectAll = allTasks.map((task) => ({
        taskId: task.id,
        checked: !!task.allStudents,
        disabled: !!repeatTaskForm,
    }));

    const studentTasksChecked = assignmentProgress?.reduce((result, homework) => {
        if (result[homework.studentId]) {
            result[homework.studentId].tasks.push(homework.taskId);
        } else {
            result[homework.studentId] = {
                tasks: [homework.taskId],
            };
        }
        return result;
    }, {} as { [studentId: number]: { tasks: number[] } });

    const studentTasks = allTasks.map((task) => ({
        taskId: task.id,
        checked: false,
        blocked: false,
        overdued: moment().isAfter(task.deadlineForComplete),
        repeatBlocked: false,
    }));

    const studentValues = students.map((student) => {
        const tasks = studentTasks.map((task) => {
            if (studentTasksChecked && studentTasksChecked[student.id]) {
                const checkTask = studentTasksChecked[student.id].tasks.includes(task.taskId);

                const currentProgress = assignmentProgress?.reduce(
                    (bestProgress, progress) => {
                        return progress.studentId === student.id &&
                            progress.taskId === task.taskId &&
                            progress.id > bestProgress.id
                            ? progress
                            : bestProgress;
                    },
                    { id: -Infinity } as AssignmentProgress,
                );

                const isExpelledStudent = expelledStudents?.some(({ id }) => id === student.id);
                const blockCheckboxByExpelling = isExpelledStudent && (!checkTask || repeatTaskForm);

                const isBlocked =
                    currentProgress?.status === AssignmentProgressStatus.COMPLETED ||
                    currentProgress?.status === AssignmentProgressStatus.ON_CHECK;

                return {
                    ...task,
                    checked: checkTask && !repeatTaskForm,
                    blocked: repeatTaskForm ? false : isBlocked || blockCheckboxByExpelling,
                    repeatBlocked: (checkTask && repeatTaskForm && !isBlocked) || blockCheckboxByExpelling,
                };
            } else {
                return {
                    ...task,
                    repeatBlocked: repeatTaskForm && false,
                };
            }
        });
        return {
            studentId: student.id,
            tasks: [...tasks],
        };
    });

    return {
        students: studentValues,
        selectAll,
    };
};

export const addTaskToValues = (
    values: HomeworkTableFormValues,
    task: Task,
    assignmentProgress: AssignmentProgress[],
    repeatTaskForm: boolean,
): HomeworkTableFormValues => {
    const newValues = JSON.parse(JSON.stringify(values)) as HomeworkTableFormValues;

    const studentTasksChecked = assignmentProgress?.reduce((result, homework) => {
        if (result[homework.studentId]) {
            result[homework.studentId].tasks.push(homework.taskId);
        } else {
            result[homework.studentId] = {
                tasks: [homework.taskId],
            };
        }

        return result;
    }, {} as { [studentId: number]: { tasks: number[] } });

    newValues.selectAll.push({ taskId: task.id, checked: false, disabled: repeatTaskForm });

    newValues.students = newValues.students.map((student) => {
        if (studentTasksChecked && studentTasksChecked[student.studentId]) {
            const checkTask = studentTasksChecked[student.studentId].tasks.includes(task.id);

            const currentProgress = assignmentProgress?.reduce(
                (bestProgress, progress) => {
                    return progress.studentId === student.studentId &&
                        progress.taskId === task.id &&
                        progress.id > bestProgress.id
                        ? progress
                        : bestProgress;
                },
                { id: -Infinity } as AssignmentProgress,
            );

            const isBlocked =
                currentProgress?.status === AssignmentProgressStatus.COMPLETED ||
                currentProgress?.status === AssignmentProgressStatus.ON_CHECK;

            student.tasks.push({
                taskId: task.id,
                checked: checkTask && !repeatTaskForm,
                blocked: repeatTaskForm ? false : isBlocked,
                repeatBlocked: checkTask && repeatTaskForm && !isBlocked,
            });
        } else {
            student.tasks.push({
                taskId: task.id,
                checked: false,
                blocked: false,
                repeatBlocked: repeatTaskForm && false,
            });
        }

        return student;
    });

    return newValues;
};

export const removeTaskFromValues = (values: HomeworkTableFormValues, taskId: number): HomeworkTableFormValues => {
    const newValues = { ...values };

    newValues.selectAll = newValues.selectAll.filter((taskInValues) => taskInValues.taskId !== taskId);
    newValues.students = newValues.students.map((student) => {
        const studentTasksList = [...student.tasks].filter((studentTask) => studentTask.taskId !== taskId);

        return {
            ...student,
            tasks: studentTasksList,
        };
    });

    return newValues;
};

export const getAssignmentTask = (
    lessonAssignment: IAssignmentItem[],
    isForMethodist = false,
    assignmentProgress?: Omit<IHomeworkItem, 'courseGroup'>[],
    deferredAssignments?: TPostDeferredAssignmentResponse[],
): Task[] => {
    const tasks = lessonAssignment?.reduce((result, assignment) => {
        const deferredAssigned = deferredAssignments?.find((homework) => {
            return homework.assignment === assignment.id;
        });
        const assigned = assignmentProgress?.find((homework) => {
            return homework.assignment.id === assignment.id;
        });

        const data = deferredAssigned ?? assigned;

        result.push({
            isPosted: data !== undefined,
            id: assignment.id,
            title: assignment.title,
            type: assignment.type,
            isTemplate: assignment.isTemplate,
            isForMethodist: isForMethodist,
            assignedDate: data?.assignedDate,
            deadlineForComplete: data?.deadlineForComplete,
            assignedDelay: (data as TPostDeferredAssignmentResponse)?.assignedDelay,
            deadlineDelay: (data as TPostDeferredAssignmentResponse)?.deadlineDelay,
            timeToComplete: (data as TPostDeferredAssignmentResponse)?.timeToComplete,
            assignedType: (data as TPostDeferredAssignmentResponse)?.assignedType ?? EAssignedTypes.DATE_TIME,
            deadlineType: (data as TPostDeferredAssignmentResponse)?.deadlineType ?? EDeadlineTypes.DATE_TIME,
        });

        return result;
    }, [] as Task[]);
    return tasks || [];
};

export interface TasksDates {
    [taskId: number]: ITaskDate;
}
export interface HomeworksStatus {
    id: number;
    status: 'auto' | 'notAuto' | 'homework' | 'notHomework';
    autoIssue: boolean;
    autoIssueTime: string;
    autoIssuetasks: number;
    autoIssueSetUp: boolean;
    givenHometaskTitle: string;
    autoIssuetotalTasks: number;
    passOffTasks: number;
    toCheckTasks: number;
}

export const getAssignmentProgressData = (
    lessonHomeworks: Omit<IHomeworkItem, 'courseGroup'>[],
    deferredAssignments?: (Task & TPostDeferredAssignmentResponse)[],
): AssignmentProgress[] => {
    const assignments = lessonHomeworks.map((homework) => {
        const taskId = homework.assignment.id;

        const marks: MarkData[] = [];
        if (homework.marks) {
            marks.push(
                ...homework.marks.map((mark) => ({
                    ...mark,
                    author: mark?.author?.id,
                    assignedType: EAssignedTypes.DATE_TIME,
                    deadlineType: EDeadlineTypes.DATE_TIME,
                })),
            );
        }

        if (homework.teacherMark?.length) {
            marks.push(
                ...homework.teacherMark.map((mark) => ({
                    ...mark,
                    author: mark.author?.id,
                    assignedType: EAssignedTypes.DATE_TIME,
                    deadlineType: EDeadlineTypes.DATE_TIME,
                })),
            );
        }

        return {
            taskId,
            marks,
            taskTitle: homework.title ?? 'ДЗ',
            taskType: homework.type,
            id: Number(homework.id),
            status: homework.status,
            studentId: homework.student.id,
            assignedDate: homework.assignedDate,
            deadlineForComplete: homework.deadlineForComplete,
            assignedType: EAssignedTypes.DATE_TIME,
            deadlineType: EDeadlineTypes.DATE_TIME,
            timeToComplete: homework.timeToComplete,
            teacher: homework.teacher,
        };
    });
    const deferred = deferredAssignments
        ? deferredAssignments?.map((homework) => {
              const taskId = homework.assignment;

              const marks: MarkData[] = [];

              return homework.students.map((student) => {
                  return {
                      taskId,
                      marks,
                      taskTitle: homework.title ?? 'ДЗ',
                      taskType: homework.type,
                      id: Number(homework.id),
                      status: AssignmentProgressStatus.DEFERRED_ASSIGNMENT,
                      studentId: student,
                      assignedDate: homework.assignedDate,
                      deadlineForComplete: homework.deadlineForComplete,
                      assignedType: homework.assignedType,
                      deadlineType: homework.deadlineType,
                      timeToComplete: homework.timeToComplete,
                      teacher: homework?.teacher,
                      errorsList: homework.errorsList,
                      allStudents: homework.allStudents,
                  };
              });
          })
        : [];
    return [...assignments, ...deferred.flat()];
};

interface ConferenceDates {
    datetimeStart: string;
}

export const getTasksDate = (
    dates: ITaskDate,
    conferenceData?: ConferenceDates,
): {
    assignedDate?: string;
    assignedDelay?: number;
    deadlineForComplete?: string;
    deadlineDelay?: number;
    timeToComplete?: number;
    assignedType: EAssignedTypes;
    deadlineType: EDeadlineTypes;
} => {
    const conferenceStart = moment(conferenceData?.datetimeStart);

    let assignedDate, assignedDelay, deadlineForComplete, deadlineDelay, assignedType, deadlineType;
    switch (dates.assignedType) {
        case EDateTimeTypesList.DATE_TIME:
            assignedDate = dates.timeStart;
            assignedDelay = undefined;
            assignedType = EAssignedTypes.DATE_TIME;
            break;
        case EDateTimeTypesList.TIME:
            assignedDate = undefined;
            assignedDelay = dates.startTimeDelay;
            assignedType = EAssignedTypes.TIME;
            break;
        case EDateTimeTypesList.NONE:
            assignedDate = moment().format();
            assignedDelay = undefined;
            assignedType = EAssignedTypes.NONE;
            break;
        default:
            assignedDate = undefined;
            assignedDelay = undefined;
            assignedType = EAssignedTypes.NONE;
            break;
    }

    switch (dates.deadlineType) {
        case EDateTimeTypesList.DATE_TIME:
            deadlineForComplete = dates?.timeEnd;
            deadlineDelay = undefined;
            deadlineType = EDeadlineTypes.DATE_TIME;
            break;
        case EDateTimeTypesList.TIME:
        default:
            deadlineForComplete = undefined;
            deadlineDelay = dates?.endTimeDelay;
            deadlineType = EDeadlineTypes.TIME;
            break;
    }

    return {
        assignedDate,
        assignedDelay,
        deadlineForComplete,
        deadlineDelay,
        timeToComplete: dates.timeToComplete,
        assignedType,
        deadlineType,
    };
};

interface AssignmentSubmitData {
    createdDeferredAssignments: TCreateDeferredAssignmentAction[];
    updatedDeferredAssignments: TUpdateDeferredAssignmentAction[];
    deletedDeferredAssignmentsIds: number[];
    deletedAssignmentIds: { id: number }[];
}

interface GetSubmitDataParams {
    values: HomeworkTableFormValues;
    tasks: Task[];
    assignmentProgress?: Omit<IHomeworkItem, 'courseGroup'>[];
    courseGroup?: number;
    repeatTaskForm?: boolean;
    eventRoomId?: number;
    deferredAssignments: TPostDeferredAssignmentResponse[];
}

export const getSubmitData = (params: GetSubmitDataParams): AssignmentSubmitData => {
    const { values, tasks, assignmentProgress, courseGroup, repeatTaskForm, deferredAssignments, eventRoomId } = params;

    const createdDeferredAssignments: TCreateDeferredAssignmentAction[] = [];
    const updatedDeferredAssignments: TUpdateDeferredAssignmentAction[] = [];
    const deletedDeferredAssignmentsIds: number[] = [];
    const deletedAssignmentIds: { id: number }[] = [];

    // Список выбранных заданий к выдаче
    const selectedAssignments = getSelectedAssignments({ values, tasks });
    const unselectedAssignments = tasks.filter(({ id }) => !selectedAssignments.some((selected) => selected.id === id));

    if (selectedAssignments?.length && unselectedAssignments?.length) {
        const deletedAssignmentProgress = getDeletedProgresses(
            assignmentProgress?.filter(({ assignment }) =>
                unselectedAssignments.some(({ id }) => id === assignment.id),
            ),
        );

        const deletedDeferredAssignment = deferredAssignments?.filter(({ assignment }) => {
            return unselectedAssignments.some(({ id }) => id === assignment);
        });

        if (deletedAssignmentProgress) {
            deletedAssignmentIds?.push(
                ...deletedAssignmentProgress?.map((item) => {
                    return {
                        id: +item.id,
                    };
                }),
            );
        }
        if (deletedDeferredAssignment) {
            deletedDeferredAssignmentsIds?.push(...deletedDeferredAssignment?.map((item) => item.id));
        }
    }
    if (selectedAssignments?.length) {
        selectedAssignments.forEach((selectedTask) => {
            const {
                isTaskPosted,
                isSelectedAllStudents,
                selectedStudents,
                selectedDeferredAssignments,
                lastDeferredAssignment,
                selectedAssignmentProgress,
                lastAssignmentProgress,
                deletedAssignmentProgress,
            } = getAssignmentData({ values, selectedTask, deferredAssignments, assignmentProgress });

            // Выбраны ученики и задание создается впервые -> создается новая выдача
            const isCreate = (!isTaskPosted || repeatTaskForm) && !isEmptyList(selectedStudents);
            // Задание создается не впервые и оно не является отложенной выдачей
            const isUpdateAssignment = !isCreate && !lastDeferredAssignment && !!selectedAssignmentProgress;
            // Задание создается не впервые и оно является отложенной выдачей
            const isUpdateDeferredAssignment = !isCreate && !!lastDeferredAssignment;
            // Есть удаленные прогрессы
            const isDeleteProgress = !isEmptyList(deletedAssignmentProgress);
            //Убрали галочку "Выдать всем"
            const isSelectedAllUnchecked = !isSelectedAllStudents && !!selectedTask.allStudents;
            const isSelectedAllChecked = isSelectedAllStudents && !selectedTask.allStudents;

            if (isCreate) {
                const data = getCreatedDeferredAssignment({
                    selectedTask,
                    isSelectedAllStudents,
                    selectedStudents,
                    courseGroup,
                    eventRoomId,
                });
                if (data) {
                    createdDeferredAssignments.push(data);
                }
            }
            if (isUpdateAssignment) {
                // Если задание уже создавалось, но не как отложенная выдача,
                // и изменился список студентов, то
                // создаем новую выдачу по последнему прогрессу, старые выдачи не трогаем
                const assignmentProgressStudents = selectedAssignmentProgress?.map(({ student }) => student.id);
                const newSelectedStudents = selectedStudents
                    ? selectedStudents?.filter((item) => !assignmentProgressStudents?.includes(item))
                    : [];

                if (!isEmptyList(newSelectedStudents)) {
                    const data = getCreatedDeferredAssignment({
                        selectedTask,
                        lastAssignmentProgress,
                        isSelectedAllStudents,
                        selectedStudents: newSelectedStudents,
                        courseGroup,
                        eventRoomId,
                    });
                    if (data) {
                        createdDeferredAssignments.push(data);
                    }
                }
            }
            if (isUpdateDeferredAssignment) {
                //Список студентов ТОЛЬКО для отложенных выдач
                let selectedDeferredAssignmentsStudents = selectedStudents.filter((item) => {
                    const progressStudents = selectedAssignmentProgress?.map(({ student }) => student.id);
                    return !progressStudents?.includes(item);
                });
                const oldDeferredAssignmentsStudents = selectedDeferredAssignments
                    .map(({ students }) => students)
                    .flat();

                const isDeferredAssignmentChanged =
                    oldDeferredAssignmentsStudents
                        .filter((x) => !selectedDeferredAssignmentsStudents.includes(x))
                        .concat(
                            selectedDeferredAssignmentsStudents.filter(
                                (x) => !oldDeferredAssignmentsStudents.includes(x),
                            ),
                        ).length || isSelectedAllChecked;

                // Если список студентов не пуст и изменен, то
                // редактируем выдачи, иначе просто удаляем
                const isNeedUpdate =
                    (!isEmptyList(selectedDeferredAssignmentsStudents) || isSelectedAllStudents) &&
                    isDeferredAssignmentChanged;
                // Если список студентов пуст, то удаляем все выдачи по заданию
                const isNeedDelete = isEmptyList(selectedDeferredAssignmentsStudents);

                if (isNeedUpdate) {
                    if (!isSelectedAllStudents) {
                        // Перебираем все выдачи
                        // Если выдача не последняя, то обновляем/удаляем ее в зависимости от обновленного списка студентов
                        // Последнюю выдачу просто редактируем по оставшемуся списку студентов
                        selectedDeferredAssignments.forEach((assignment) => {
                            const isLast = assignment.id === lastDeferredAssignment?.id;

                            if (!isLast) {
                                const newList = assignment.students.filter((item) =>
                                    selectedDeferredAssignmentsStudents.includes(item),
                                );
                                const listHasChanged =
                                    !isEmptyList(assignment?.students) &&
                                    !isEmptyList(newList) &&
                                    newList.join() !== assignment?.students?.join();
                                isEmptyList(newList)
                                    ? deletedDeferredAssignmentsIds.push(assignment.id)
                                    : listHasChanged
                                    ? updatedDeferredAssignments.push({
                                          // @ts-ignore
                                          id: assignment.id,
                                          allStudents: false,
                                          students: newList,
                                      })
                                    : undefined;
                                selectedDeferredAssignmentsStudents = selectedDeferredAssignmentsStudents.filter(
                                    (item) => !newList.includes(item),
                                );
                            } else if (isLast) {
                                const listHasChanged =
                                    !isEmptyList(assignment?.students) &&
                                    !isEmptyList(selectedDeferredAssignmentsStudents) &&
                                    selectedDeferredAssignmentsStudents.join() !== assignment?.students?.join();

                                isEmptyList(selectedDeferredAssignmentsStudents)
                                    ? deletedDeferredAssignmentsIds.push(assignment.id)
                                    : listHasChanged
                                    ? updatedDeferredAssignments.push({
                                          // @ts-ignore
                                          id: lastDeferredAssignment.id,
                                          allStudents: isSelectedAllStudents,
                                          students: !isSelectedAllStudents
                                              ? selectedDeferredAssignmentsStudents
                                              : undefined,
                                      })
                                    : undefined;
                            }
                        });
                    } else {
                        //Если выбрано "Выдать всем", просто обновляем последнюю выдачу
                        updatedDeferredAssignments.push({
                            // @ts-ignore
                            id: lastDeferredAssignment.id,
                            allStudents: isSelectedAllStudents,
                            students: !isSelectedAllStudents ? selectedStudents : undefined,
                        });
                    }
                } else if (isNeedDelete) {
                    deletedDeferredAssignmentsIds?.push(...selectedDeferredAssignments?.map((item) => item.id));
                }
            }
            if (isDeleteProgress && !repeatTaskForm) {
                deletedAssignmentIds.push(...deletedAssignmentProgress);
            }
            if (isSelectedAllUnchecked) {
                const selectedDeferredAssignment = selectedDeferredAssignments.find(
                    ({ assignment }) => assignment === selectedTask.id,
                );

                if (selectedDeferredAssignment) {
                    updatedDeferredAssignments.push({
                        // @ts-ignore
                        id: selectedDeferredAssignment.id,
                        allStudents: false,
                        students: selectedDeferredAssignment.students,
                    });
                }
            }
        });
    } else {
        deletedDeferredAssignmentsIds?.push(...deferredAssignments?.map((item) => item.id));

        const progressesToDelete = getDeletedProgresses(assignmentProgress);

        if (progressesToDelete) {
            deletedAssignmentIds?.push(...progressesToDelete);
        }
    }
    return {
        createdDeferredAssignments,
        updatedDeferredAssignments,
        deletedDeferredAssignmentsIds,
        deletedAssignmentIds,
    };
};

export const sortByFullName = (
    first: { firstName: string; lastName: string },
    second: { firstName: string; lastName: string },
): number => (`${first.lastName} ${first.firstName}` > `${second.lastName} ${second.firstName}` ? 1 : -1);

// Ниже 4 вспомогательные функции к getSubmitData
const getCreatedDeferredAssignment = (params: {
    selectedTask: Task;
    isSelectedAllStudents: boolean;
    selectedStudents: number[];
    lastAssignmentProgress?: Omit<IHomeworkItem, 'courseGroup'>;
    courseGroup?: number;
    eventRoomId?: number;
    deleted?: { id: number }[];
}): TCreateDeferredAssignmentAction | undefined => {
    const {
        selectedTask,
        isSelectedAllStudents,
        selectedStudents,
        courseGroup,
        eventRoomId,
        deleted,
        lastAssignmentProgress,
    } = params;
    if (courseGroup) {
        const assignedDate = selectedTask?.assignedDate ?? lastAssignmentProgress?.assignedDate ?? undefined;
        const deadlineForComplete =
            selectedTask?.deadlineForComplete ?? lastAssignmentProgress?.deadlineForComplete ?? undefined;

        return {
            assignment: selectedTask.id,
            allStudents: isSelectedAllStudents,
            students: !isSelectedAllStudents ? selectedStudents : undefined,
            deadlineForComplete: deadlineForComplete ? payloadTimeFormatter(deadlineForComplete) : undefined,
            assignedDate: assignedDate ? payloadTimeFormatter(assignedDate) : undefined,
            courseGroup: courseGroup,
            teacher: selectedTask?.teacher ?? undefined,
            assignedDelay: selectedTask?.assignedDelay,
            deadlineDelay: selectedTask?.deadlineDelay,
            timeToComplete: selectedTask?.timeToComplete,
            assignedType:
                (selectedTask?.assignedDate || selectedTask?.assignedDelay !== undefined) && selectedTask?.assignedType
                    ? selectedTask?.assignedType
                    : EAssignedTypes.DATE_TIME,
            deadlineType:
                (selectedTask?.deadlineForComplete || selectedTask?.deadlineDelay) && selectedTask?.deadlineType
                    ? selectedTask?.deadlineType
                    : EDeadlineTypes.DATE_TIME,
            deletedAssignmentIds: deleted,
        };
    } else if (eventRoomId) {
        return {
            assignment: selectedTask.id,
            allStudents: isSelectedAllStudents,
            students: !isSelectedAllStudents ? selectedStudents : undefined,
            teacher: selectedTask?.teacher ?? undefined,
            assignedType: EAssignedTypes.DATE_TIME,
            deadlineType: EDeadlineTypes.DATE_TIME,
            eventRoom: eventRoomId,
            deletedAssignmentIds: deleted,
        };
    }
    return undefined;
};
const getAssignmentData = (params: {
    values: HomeworkTableFormValues;
    selectedTask: Task;
    deferredAssignments: TPostDeferredAssignmentResponse[];
    assignmentProgress: Omit<IHomeworkItem, 'courseGroup'>[] | undefined;
}): {
    isTaskPosted: boolean;
    isSelectedAllStudents: boolean;
    selectedDeferredAssignments: TPostDeferredAssignmentResponse[];
    lastDeferredAssignment: TPostDeferredAssignmentResponse | undefined;
    selectedStudents: number[];
    selectedAssignmentProgress: Omit<IHomeworkItem, 'courseGroup'>[] | undefined;
    lastAssignmentProgress: Omit<IHomeworkItem, 'courseGroup'> | undefined;
    deletedAssignmentProgress: { id: number }[];
} => {
    const { values, selectedTask, deferredAssignments, assignmentProgress } = params;

    const isSelectedAllStudents = !!values.selectAll.find(({ taskId }) => taskId === selectedTask.id)?.checked;
    const isTaskPosted = !!selectedTask.isPosted;

    // cписок отложенных выдач на выбранное задание
    const selectedDeferredAssignments = deferredAssignments
        .filter((item) => item.assignment === selectedTask.id)
        .sort((a, b) => a.id - b.id);
    // последняя отложенная выдача
    const lastDeferredAssignment = !isEmptyList(selectedDeferredAssignments)
        ? selectedDeferredAssignments.reduce((acc, curr) => (acc.id > curr.id ? acc : curr))
        : undefined;

    //список всех студентов, для которых выбрано задание
    const selectedStudents: number[] = values.students
        .filter((item) => {
            const task = item.tasks.find(({ taskId }) => taskId === selectedTask.id);
            return task?.checked && !task.blocked;
        })
        ?.map(({ studentId }) => studentId);
    const blockedSelectedStudents = values.students
        .filter((item) => {
            const task = item.tasks.find(({ taskId }) => taskId === selectedTask.id);
            return task?.checked && task.blocked;
        })
        ?.map(({ studentId }) => studentId);

    const assignmentProgressList = assignmentProgress?.filter(({ assignment }) => assignment.id == selectedTask.id);

    const deletedAssignmentProgress = assignmentProgressList
        ? assignmentProgressList
              .map(({ student, id, status }) => {
                  if (
                      !selectedStudents.includes(student.id) &&
                      !blockedSelectedStudents.includes(student.id) &&
                      status !== AssignmentProgressStatus.COMPLETED &&
                      status !== AssignmentProgressStatus.ON_CHECK
                  ) {
                      return { id: +id };
                  }
              })
              .filter(Boolean)
        : [];
    const selectedAssignmentProgress = assignmentProgressList?.filter(({ id }) => {
        // Выбираем только те прогрессы, которые не пойдут под удаление
        // @ts-ignore
        const ids = deletedAssignmentProgress.map(({ id }) => id);
        return !ids.includes(id);
    });

    // последний созданный прогресс
    const lastAssignmentProgress = !isEmptyList(selectedAssignmentProgress)
        ? selectedAssignmentProgress?.reduce((acc, curr) => (acc.id > curr.id ? acc : curr))
        : undefined;

    return {
        isTaskPosted,
        isSelectedAllStudents,
        selectedDeferredAssignments,
        lastDeferredAssignment,
        selectedStudents,
        selectedAssignmentProgress,
        lastAssignmentProgress,
        // @ts-ignore
        deletedAssignmentProgress,
    };
};
const getSelectedAssignments = (params: { values: HomeworkTableFormValues; tasks: Task[] }): Task[] => {
    const { values, tasks } = params;
    //выбранные к выдаче задания (по галочкам у учеников)
    const checkedTasksIds = values.students
        .map(({ tasks }) =>
            tasks.map((item) => {
                if (item.checked) {
                    return item.taskId;
                }
            }),
        )
        .flat()
        .filter(Boolean);
    // удаляем повторяющиеся значения из checkedTasksIds
    const uniqCheckedTasksIds = Array.from(new Set(checkedTasksIds));
    // Список выбранных заданий к выдаче
    return tasks.filter((item) => uniqCheckedTasksIds.includes(item.id));
};

const getDeletedProgresses = (assignmentProgress: Omit<IHomeworkItem, 'courseGroup'>[] | undefined) => {
    // При удалении прогрессов для каждого конкретного студента удаляем по одному (последнему) прогрессу
    // Ищем студентов, у которых несколько прогрессов
    const studentsDuplicates = assignmentProgress
        ?.map((item) => item.student.id)
        ?.filter((id, index, ids) => {
            return ids.indexOf(id) !== index;
        });
    // Для каждого студента находим прогресс, созданный последним - он пойдет под удаление
    const duplicatedProgressesToDelete = studentsDuplicates
        ?.map((studentId) => {
            const progresses = assignmentProgress?.filter(({ student }) => student.id === studentId);
            return !isEmptyList(progresses)
                ? progresses?.reduce((acc, curr) => (acc.id > curr.id ? acc : curr))?.id
                : undefined;
        })
        .filter(Boolean);

    // Собираем прогрессы под удаление:
    // если нет учеников, у которых несколько прогрессов - удаляем все
    // если такие ученики есть, то удаляем для каждого из них последний прогресс, и прогрессы остальных студентов
    const progressesToDelete =
        !isEmptyList(duplicatedProgressesToDelete) && !isEmptyList(studentsDuplicates)
            ? assignmentProgress
                  ?.filter(({ id, student }) => {
                      return (
                          !studentsDuplicates?.includes(student.id) ||
                          (studentsDuplicates?.includes(student.id) && duplicatedProgressesToDelete?.includes(id))
                      );
                  })
                  .map((item) => {
                      return {
                          id: +item.id,
                      };
                  })
            : assignmentProgress?.map((item) => {
                  return {
                      id: +item.id,
                  };
              });

    return progressesToDelete;
};

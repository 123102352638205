import { api } from 'api';
import { AxiosError } from 'axios';

export interface Recording {
    id: number;
    link: string;
    duration: string;
    preview: string;
    datetimeStart: string;
    isPublished: boolean;
}

export interface MeetingData {
    id: number;
    title: string;
    recordings: Recording[];
}

export const getMeetingRecordings = (lessonSchedule?: number, eventRoom?: number): Promise<MeetingData | string> => {
    return api
        .post<MeetingData>(`/meeting/recordings/`, { lessonSchedule: lessonSchedule, eventRoom: eventRoom })
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            if (err.response?.status === 400) {
                return 'Встреча не состоялась';
            }

            throw JSON.stringify(err.response?.data);
        });
};

import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { isEmpty } from '@frontend-modules/frontend-utils';
import { AppButton, AppText } from '@frontend-modules/ui-kit';
import {
    getTrainingCountAction,
    getTrainingSearchBlocksQuestionsAction,
    initTrainerData,
} from '@fsd-entities/materials';
import { ContentLoader } from '@fsd-shared/ui';
import { useAppDispatch, useAppSelector } from 'store/store';

import { IMethodistTrainerCardProps } from './MethodistTrainerCard.types';

import './MethodistTrainerCard.styles.scss';

const CnMethodistTrainerCard = cn('methodist-trainer-card');
export const MethodistTrainerCard: FC<IMethodistTrainerCardProps> = (props) => {
    const { lessonId, subjectId, courseGroupId } = props;
    const { info, isLoading } = useAppSelector((state) => state.newMaterials?.training?.trainingCountInfo) || {};
    const { isLoading: isSearchBlocksLoading } =
        useAppSelector((state) => state.newMaterials?.training?.trainingSearchBlocksData) || {};
    const { questionsCount, searchBlocksCount, id: trainerId } = info || {};
    const dispatch = useAppDispatch();
    const [isTrainingLoading, setIsTrainingLoading] = useState(false);
    const history = useHistory();
    const isQuestionsNotExist = isEmpty(info);
    const isButtonLoading = isTrainingLoading || isSearchBlocksLoading;

    const onEditTraining = async () => {
        try {
            if (lessonId) {
                setIsTrainingLoading(true);
                const action = isQuestionsNotExist ? 'create' : 'edit';
                const editOrCreateTrainerPath = `/subjects/${subjectId}/${lessonId}/task/search-questions/fill-trainer/${trainerId}/${action}${
                    courseGroupId ? `?course_group=${courseGroupId}` : ''
                }`;
                if (trainerId) {
                    await dispatch(getTrainingSearchBlocksQuestionsAction({ training: trainerId })).then(() => {
                        history.push(editOrCreateTrainerPath);
                    });
                } else {
                    dispatch(initTrainerData());
                    history.push(editOrCreateTrainerPath);
                }
                setIsTrainingLoading(false);
            }
        } catch (e) {
            setIsTrainingLoading(false);
            console.log(e);
        }
    };

    useEffect(() => {
        if (lessonId) {
            void dispatch(getTrainingCountAction({ lesson: lessonId }));
        }
    }, [lessonId]);

    if (isLoading) {
        return <ContentLoader height={400} />;
    }

    return isQuestionsNotExist ? (
        <AppButton
            isFullWidth={false}
            isLoading={isButtonLoading}
            className={CnMethodistTrainerCard('button')}
            label={'Наполнить'}
            onClick={onEditTraining}
        />
    ) : (
        <div className={CnMethodistTrainerCard()}>
            <div className={CnMethodistTrainerCard('line')}>
                <AppText text={String(questionsCount)} textStyle={'DesktopH4'} />
                <AppText text={'вопросов'} textStyle={'DesktopFontBody'} />
            </div>
            <div className={CnMethodistTrainerCard('line')}>
                <AppText text={String(searchBlocksCount)} textStyle={'DesktopH4'} />
                <AppText text={'поисков'} textStyle={'DesktopFontBody'} />
            </div>
            <AppButton
                isFullWidth={false}
                isLoading={isButtonLoading}
                className={CnMethodistTrainerCard('button')}
                label={'Изменить'}
                onClick={onEditTraining}
            />
        </div>
    );
};

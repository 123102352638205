import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { Record } from 'immutable';
import { getLessonsAndHomeworksAction } from 'store/actions/courseLessons';
import {
    deleteStudentHomeworksAction,
    fetchHomework,
    getAssignedAndOnCheckHomeworkAction,
    getAssignmentProgressPreviewAction,
    getCompletedHomeworkAction,
    getHomeworkPreviewBetweenDatesAction,
    getHomeworksBetweenDatesAction,
    getProgressesStatisticsAction,
    getStudentsTasksAction,
    postDeferredAssignmentAction,
    postStudentHomeworksAction,
    putStudentHomeworksAction,
    setIsMandatoryAction,
} from 'store/actions/homework';
import { FetchStatus } from 'types/api';
import { AssignmentProgressPreview } from 'types/assignmentProgress';
import { FilterTypes, IHomeworkItem, ProgressesStatisticsData, TPostDeferredAssignmentResponse } from 'types/homework';

export interface HomeworkState {
    homeworks: IHomeworkItem[];
    scheduleHomeworks: Record<FilterTypes, IHomeworkItem[]>;
    scheduleHomeworkPreview: Record<FilterTypes, AssignmentProgressPreview[]>;
    lessonHomeworks: Omit<IHomeworkItem, 'courseGroup'>[];
    deferredAssignments: TPostDeferredAssignmentResponse[];
    themeHomeworks: { [lessonId: number]: AssignmentProgressPreview[] };
    completedHomeworks: AssignmentProgressPreview[];
    fetchStatus: FetchStatus;
    fetchDeferredAssignmentStatus: FetchStatus;
    fetchDeferredAssignmentChangeStatus: FetchStatus;
    fetchAssignmentProgressPreviewStatus: FetchStatus;
    scheduleHomeworksStatus: FetchStatus;
    fetchCompletedHWStatus: FetchStatus;
    // progresses statistics
    progressesStatistics: ProgressesStatisticsData[];
    homeworkPreview: AssignmentProgressPreview[];
    fetchStatisticsStatus: FetchStatus;
    isMandatory: boolean;
    error: unknown;
}

const initialState: HomeworkState = {
    fetchStatus: FetchStatus.INITIAL,
    fetchDeferredAssignmentStatus: FetchStatus.INITIAL,
    fetchDeferredAssignmentChangeStatus: FetchStatus.INITIAL,
    fetchCompletedHWStatus: FetchStatus.INITIAL,
    scheduleHomeworksStatus: FetchStatus.INITIAL,
    lessonHomeworks: [],
    deferredAssignments: [],
    themeHomeworks: {},
    homeworks: [],
    scheduleHomeworks: {
        [FilterTypes.ASSIGNED]: [],
        [FilterTypes.DEADLINE_FOR_CHECK]: [],
        [FilterTypes.DEADLINE_FOR_COMPLETE]: [],
    },
    scheduleHomeworkPreview: {
        [FilterTypes.ASSIGNED]: [],
        [FilterTypes.DEADLINE_FOR_CHECK]: [],
        [FilterTypes.DEADLINE_FOR_COMPLETE]: [],
    },
    completedHomeworks: [],
    // progresses statistics
    progressesStatistics: [],
    fetchStatisticsStatus: FetchStatus.INITIAL,
    fetchAssignmentProgressPreviewStatus: FetchStatus.INITIAL,
    homeworkPreview: [],
    error: null,
    isMandatory: false,
};

const homeworkSlice = createSlice<HomeworkState, SliceCaseReducers<HomeworkState>>({
    name: 'homework',
    initialState,
    reducers: {
        resetHomeworkState() {
            return initialState;
        },
        resetLessonHomeworksState(state) {
            state.fetchStatus = initialState.fetchStatus;
            state.lessonHomeworks = initialState.lessonHomeworks;
            state.fetchDeferredAssignmentStatus = initialState.fetchDeferredAssignmentStatus;
            state.fetchDeferredAssignmentChangeStatus = initialState.fetchDeferredAssignmentChangeStatus;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setIsMandatoryAction.fulfilled, (state, { payload }) => {
            state.fetchAssignmentProgressPreviewStatus = FetchStatus.FETCHING;
            state.isMandatory = payload;
        });
        builder.addCase(fetchHomework.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(fetchHomework.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.homeworks = payload || [];
        });
        builder.addCase(fetchHomework.rejected, (state, { error }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(postStudentHomeworksAction.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(postStudentHomeworksAction.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.lessonHomeworks = (state.lessonHomeworks || []).concat(
                payload.map((newAssignmentProgress) => ({
                    ...newAssignmentProgress,
                    description: newAssignmentProgress.description ?? '',
                    assignment: {
                        id: newAssignmentProgress.assignment.id,
                    },
                    selectedQuestions: [],
                    marks: [],
                    teacherMark: [],
                })),
            );
        });
        builder.addCase(postStudentHomeworksAction.rejected, (state, { error }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(putStudentHomeworksAction.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(putStudentHomeworksAction.fulfilled, (state) => {
            state.fetchStatus = FetchStatus.FETCHED;
        });
        builder.addCase(putStudentHomeworksAction.rejected, (state, { error }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(deleteStudentHomeworksAction.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(deleteStudentHomeworksAction.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.lessonHomeworks = state.lessonHomeworks.filter(
                (homework) => !payload.map(({ id }) => id).includes(Number(homework.id)),
            );
        });
        builder.addCase(deleteStudentHomeworksAction.rejected, (state, { error }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(getAssignedAndOnCheckHomeworkAction.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getAssignedAndOnCheckHomeworkAction.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.homeworks = payload || [];
        });
        builder.addCase(getAssignedAndOnCheckHomeworkAction.rejected, (state, { error }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(getLessonsAndHomeworksAction.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getLessonsAndHomeworksAction.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.themeHomeworks = payload.homeworksMap;
            state.error = null;
        });
        builder.addCase(getLessonsAndHomeworksAction.rejected, (state, { error }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(getCompletedHomeworkAction.pending, (state) => {
            state.fetchCompletedHWStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getCompletedHomeworkAction.fulfilled, (state, { payload }) => {
            state.fetchCompletedHWStatus = FetchStatus.FETCHED;
            state.completedHomeworks = payload || [];
        });
        builder.addCase(getCompletedHomeworkAction.rejected, (state, { error }) => {
            state.fetchCompletedHWStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(getProgressesStatisticsAction.pending, (state) => {
            state.fetchStatisticsStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getProgressesStatisticsAction.fulfilled, (state, { payload }) => {
            state.fetchStatisticsStatus = FetchStatus.FETCHED;
            state.progressesStatistics = payload;
        });
        builder.addCase(getProgressesStatisticsAction.rejected, (state, { error }) => {
            state.fetchStatisticsStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder
            .addCase(getHomeworksBetweenDatesAction.pending, (state) => {
                state.scheduleHomeworksStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getHomeworksBetweenDatesAction.fulfilled, (state, { payload }) => {
                state.scheduleHomeworksStatus = FetchStatus.FETCHED;
                state.scheduleHomeworks = {
                    ...state.scheduleHomeworks,
                    ...payload,
                };
                state.error = null;
            })
            .addCase(getHomeworksBetweenDatesAction.rejected, (state, { error }) => {
                state.scheduleHomeworksStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getHomeworkPreviewBetweenDatesAction.pending, (state) => {
                state.scheduleHomeworksStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getHomeworkPreviewBetweenDatesAction.fulfilled, (state, { payload }) => {
                state.scheduleHomeworksStatus = FetchStatus.FETCHED;
                state.scheduleHomeworkPreview = {
                    ...state.scheduleHomeworkPreview,
                    ...payload,
                };
                state.error = null;
            })
            .addCase(getHomeworkPreviewBetweenDatesAction.rejected, (state, { error }) => {
                state.scheduleHomeworksStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getStudentsTasksAction.pending, (state) => {
                state.fetchStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getStudentsTasksAction.fulfilled, (state, { payload }) => {
                state.fetchStatus = FetchStatus.FETCHED;
                state.lessonHomeworks = payload || [];
                state.error = null;
            })
            .addCase(getStudentsTasksAction.rejected, (state, { error }) => {
                state.fetchStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(postDeferredAssignmentAction.pending, (state) => {
                state.fetchDeferredAssignmentStatus = FetchStatus.FETCHING;
                state.fetchDeferredAssignmentChangeStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(postDeferredAssignmentAction.fulfilled, (state, { payload }) => {
                const { res, isGetter } = payload;
                state.fetchDeferredAssignmentStatus = FetchStatus.FETCHED;

                if (!isGetter) {
                    state.fetchDeferredAssignmentChangeStatus = FetchStatus.FETCHED;
                }
                state.deferredAssignments = res;
                state.error = null;
            })
            .addCase(postDeferredAssignmentAction.rejected, (state, { error }) => {
                state.fetchDeferredAssignmentStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getAssignmentProgressPreviewAction.pending, (state) => {
                state.fetchStatus = FetchStatus.FETCHING;
                state.fetchAssignmentProgressPreviewStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getAssignmentProgressPreviewAction.fulfilled, (state, { payload }) => {
                state.homeworkPreview = payload;
                state.fetchAssignmentProgressPreviewStatus = FetchStatus.FETCHED;
                state.fetchStatus = FetchStatus.FETCHED;
            })
            .addCase(getAssignmentProgressPreviewAction.rejected, (state, { error }) => {
                state.fetchStatus = FetchStatus.ERROR;
                state.fetchAssignmentProgressPreviewStatus = FetchStatus.ERROR;

                state.error = error;
            });
    },
});

const resetHomeworkState = homeworkSlice.actions.resetHomeworkState as ActionCreatorWithoutPayload<string>;
const resetLessonHomeworksState = homeworkSlice.actions
    .resetLessonHomeworksState as ActionCreatorWithoutPayload<string>;
export { resetHomeworkState, resetLessonHomeworksState };
export const homeworkReducer = homeworkSlice.reducer;

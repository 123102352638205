import { AttendanceData } from '@lms-elements/atomic';

export enum PeriodTypes {
    QUARTER = 'quarter',
    TRIMESTER = 'trimester',
    SEMESTER = 'semester',
}

export interface Student {
    id: number;
    firstName: string;
    lastName: string;
    photoUrl: string;
    school?: string;
    enrollDate?: string;
    dismissDate?: string;
    isAdaptation?: boolean;
}

export interface ICourseLesson {
    id: number;
    title: string;
}

export interface ICourseTopic {
    id: number;
    title: string;
    lessons: ICourseLesson[];
}

export interface ICourseQuarter {
    quarter: number;
    topics: ICourseTopic[];
}

export interface ICourseStructure {
    subject: number;
    quarters: ICourseQuarter[];
}

export interface IMark {
    id: number;
    subject: number;
    topic: number;
    quarter: number;
    lesson: number;
    comment?: string | null;
    lessonType?: string;
    student: number;
    score?: number;
    type?: string;
    weight?: number;
    date?: string;
    withAttendanceTooltip?: boolean;
    attendenceData?: AttendanceData;
    commentLabel?: string;
    filesLabel?: string;
    files?: {
        title: string;
        url: string;
    }[];
    needTrimComment?: boolean;
    maxCommentLength?: number;
}

export interface IStatus {
    id: number;
    subject: number;
    topic: number;
    quarter: number;
    lesson: number;
    student: number;
    date?: string;
    status: AttendanceStatus;
}

export enum AttendanceStatus {
    ABSENT = 'absent',
    PRESENT = 'present',
    LATE = 'late',
    ABSENT_WITH_REASON = 'absent_with_reason',
    ABSENT_DUE_ILLNESS = 'absent_due_illness',
}

export interface IQuarterMark {
    id: number;
    subject: number;
    student: number;
    quarter: number;
    average?: number;
    isTest?: boolean;
    testApproved?: boolean;
    score?: number;
}

export enum DragScrollStateEnum {
    notScrolling = 0,
    scrollStart = 1,
    scrollEnd = 2,
}

export enum SubjectType {
    COMMON = 'common',
    NON_SUBJECT = 'non_subject',
    ELECTIVE = 'elective',
    GIA_PREPARATION = 'gia_preparation',
}

export interface CourseAssignment {
    id: number;
    courseId: number;
    groupId: number;
    title: string;
    type: SubjectType;
    subject?: number;
}

export interface Teacher {
    id: number;
    firstName: string;
    lastName: string;
    patronymic: string;
    photoUrl?: string;
}

export interface TransferredMarks {
    id: number;
    student: number;
    course: number;
    period: number;
    scores: (2 | 3 | 4 | 5)[];
    reportCard: string;
}

import { getApiRequest } from 'api';

export interface Period {
    id: number;
    dateStart: string;
    dateEnd: string;
}

export interface PeriodResponse {
    id: number;
    title: string;
    periodType: string;
    period: Period[];
}

export const getPeriods = (groupId: string): Promise<PeriodResponse> => {
    return getApiRequest(`/course-groups/${groupId}/period/`);
};

import { QuestionStatus } from 'api/services/questionBank';
import { Answer, DifficultyEnum, Topic } from 'api/services/questionCreate';
import {
    EssayQuestionData,
    InsertWordQuestionData,
    TestQuestionData,
    TextQuestionData,
    TrueFalseQuestionData,
} from 'types/question';

export interface ITextAnswer {
    text: string;
}

export interface Revision {
    id: number;
    comment: string;
    dateCreate: string;
}

export enum AnswersTypeEnum {
    test = 'test',
    text = 'text',
    essay = 'essay',
    true_false = 'true_false',
    insert_words = 'insert_words',
}

export enum PopUpType {
    revision = 'revision',
    search_question = 'search_question',
}

export interface IQuestionPopUpProps {
    id: number;
    text: string;
    type: PopUpType;
    answerOptions: Answer[];
    author?: string;
    authorId?: number;
    authorMainRole?: string;
    userId?: number;
    topics: Topic[];
    skills: Topic[];
    difficulty: DifficultyEnum;
    onAddQuestion?: (value: { id: number; text: string; content?: string; html?: string }) => () => void;
    onRemoveQuestion?: (id: number) => () => void;
    onExcludeQuestion?: (id: number) => () => void;
    onIncludeQuestion?: (id: number) => () => void;
    onClosePopUp?: () => void;
    onPublishClick?: (id: number) => void;
    onRevisionClick?: (values: { question: number; comment: string }) => void;
    html?: string;
    statusType?: QuestionStatus;
    revisions?: Revision[];
    content?: string;
    isCorrect?: boolean;
    answers_type: AnswersTypeEnum;
    canExclude?: boolean;
    canInclude?: boolean;
    canAdd?: boolean;
    canRemove?: boolean;
    isTeacher?: boolean;
    isMethodist?: boolean;
    data?: TestQuestionData | InsertWordQuestionData | TrueFalseQuestionData | EssayQuestionData | TextQuestionData;
}

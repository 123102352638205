import React from 'react';
import { EditFile, User } from '@lms-elements/icons';
import { ScheduleResponseData } from 'api/services/schedule';
import moment from 'moment';
import { EventTypes } from 'types/events';
import { AssemblyTypes, ScheduleData, ScheduleEvent } from 'types/schedule';

export const normalizeScheduleEvents = (data: ScheduleResponseData): ScheduleData => {
    const { lessonSchedule = [], assemblySchedule = [], eventRoomSchedule = [] } = data;
    const normalizeLessonSchedule = (lessonSchedule.map((event) => {
        return {
            ...event,
            subject: { id: event.subject.id, title: event.course.title },
            date: moment(event.datetimeStart).format('YYYY-MM-DD'),
        };
    }) as unknown) as ScheduleEvent[];
    const normalizeEventRooms = (eventRoomSchedule.map((event) => {
        return {
            ...event,
            date: moment(event.datetimeStart).format('YYYY-MM-DD'),
            subject: { id: event.subject.id, title: event.course.title },
            IconElement: <EditFile />,
            isEventRoom: true,
            customTitle: `${event.type === EventTypes.TEST ? 'Контрольная' : event.course.title}`,
        };
    }) as unknown) as ScheduleEvent[];

    const normalizeAssemblies = (assemblySchedule.map((event) => {
        return {
            ...event,
            type: AssemblyTypes.PARENT_MEETING,
            date: moment(event.datetimeStart).format('YYYY-MM-DD'),
            IconElement: <User />,
            isAssembly: true,
            customTitle: 'Встреча с родителем',
            subject: { title: event.parentName },
            lesson: {
                title: `Ученик: ${event.student.lastName} ${event.student.firstName}, ${event.studentGroup.title}`,
            },
            courseGroup: {
                title: `${event.parentName}, ученик - ${event.student.lastName} ${event.student.firstName}, ${event.studentGroup.title}`,
            },
        };
    }) as unknown) as ScheduleEvent[];

    return [...normalizeLessonSchedule, ...normalizeEventRooms, ...normalizeAssemblies]
        .sort((a, b) => moment(a.datetimeStart).diff(b.datetimeStart))
        .reduce((result, lesson) => {
            if (!result[lesson.date]) {
                result[lesson.date] = {
                    date: lesson.date,
                    data: [lesson],
                };
            } else {
                result[lesson.date].data.push(lesson);
            }

            return result;
        }, {} as ScheduleData);
};

export const normalizeScheduleDayEvents = (data: ScheduleResponseData, date: string): ScheduleEvent[] => {
    const { lessonSchedule = [], assemblySchedule = [], eventRoomSchedule = [] } = data;
    const normalizeLessonSchedule = (lessonSchedule.map((event) => {
        return {
            ...event,
            date: date,
        };
    }) as unknown) as ScheduleEvent[];
    const normalizeEventRooms = (eventRoomSchedule.map((event) => {
        return {
            ...event,
            date: date,
            IconElement: <EditFile />,
            isEventRoom: true,
        };
    }) as unknown) as ScheduleEvent[];
    const normalizeAssemblies = (assemblySchedule.map((event) => {
        return {
            ...event,
            type: AssemblyTypes.PARENT_MEETING,
            date: date,
            IconElement: <User />,
            isAssembly: true,
            customTitle: 'Встреча с родителем',
            subject: { title: event.parentName },
            lesson: {
                title: `Ученик: ${event.student.lastName} ${event.student.firstName}, ${event.studentGroup.title}`,
            },
            courseGroup: {
                title: `${event.parentName}, ученик - ${event.student.lastName} ${event.student.firstName}, ${event.studentGroup.title}`,
            },
        };
    }) as unknown) as ScheduleEvent[];

    return [...normalizeLessonSchedule, ...normalizeEventRooms, ...normalizeAssemblies].sort((a, b) =>
        moment(a.datetimeStart).diff(b.datetimeStart),
    );
};

import { patchApiRequest } from 'api';

export interface PatchAssignmentMarkParams {
    id: number;
    score?: number;
    comment?: string;
}

interface AssignmentMark {
    id: number;
    score: number;
    comment?: string;
}

export const changeAssignmentMark = ({ id, ...params }: PatchAssignmentMarkParams): Promise<AssignmentMark> => {
    return patchApiRequest(`assignment-mark/partial-update/${id}/`, params);
};

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Field, useField, useForm } from 'react-final-form';
import { EditorState } from 'draft-js';
import debounce from 'lodash.debounce';
import { CustomEditor } from 'src-new/components/lms-elements/CustomEditor';

import { IEditorFieldProps } from './EditorField.types';

export const EditorField: React.FC<IEditorFieldProps> = ({
    name,
    placeholder,
    isBlocked,
    insertWords = false,
    disableActionBlocks = false,
    savePastedStyles,
}) => {
    const { input } = useField<EditorState>(name);
    const { change } = useForm();
    const [editorState, setEditorState] = useState(input.value || EditorState.createEmpty());

    const [isError, setIsError] = useState(false);

    const ref = useRef<HTMLDivElement>(null);

    // !!! DONT TOUCH
    useEffect(() => {
        setEditorState(() => input.value || EditorState.createEmpty());
    }, [input.value, name]);

    const updateFormState = useMemo(
        () =>
            debounce(
                (
                    editorState: EditorState,
                    name: string,
                    isBlocked: boolean,
                    change: (name: string, value: EditorState) => void,
                ) => {
                    if (!isBlocked) {
                        change(name, editorState);
                    }
                },
                5,
            ),
        [],
    );

    useEffect(() => {
        updateFormState(editorState, name, isBlocked, change);
    }, [change, editorState, isBlocked, name, updateFormState]);

    useEffect(() => {
        setIsError(() => editorState.getCurrentContent().getPlainText().trim().length === 0);
    }, [editorState]);

    return (
        <Field<EditorState> name={name}>
            {({ meta }) => (
                <div ref={ref}>
                    <CustomEditor
                        editorState={editorState}
                        setEditorState={setEditorState}
                        isError={isError && meta.modified}
                        placeholder={placeholder}
                        insertWords={insertWords}
                        disableActionBlocks={disableActionBlocks}
                        savePastedStyles={savePastedStyles}
                        spellCheck
                    />
                </div>
            )}
        </Field>
    );
};

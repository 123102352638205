import React, { useEffect } from 'react';
import { cn } from '@bem-react/classname';
import { AppText } from '@frontend-modules/ui-kit';
import { Radio, RadioChangeEvent } from 'antd';

import { ISingleAnswerExerciseOptions, ISingleAnswerExerciseProps } from './SingleAnswerExercise.types';

import './SingleAnswerExercise.scss';

const CnSingleAnswerExercise = cn('single-answer-exercise');

export const SingleAnswerExercise: React.FC<ISingleAnswerExerciseProps> = (props) => {
    const { localAnswerValue, answerData, exerciseInfo, setLocalAnswerValue } = props;
    const { answerOptions, correctness } = answerData;
    const onChange = (e: RadioChangeEvent) => {
        setLocalAnswerValue(e.target.value);
    };

    useEffect(() => {
        if (!localAnswerValue) {
            answerOptions?.forEach((item: ISingleAnswerExerciseOptions) => {
                if (item?.correctness) {
                    setLocalAnswerValue(item.value as any);
                }
            });
        }
    }, [answerOptions]);

    return (
        <div className={CnSingleAnswerExercise()}>
            {exerciseInfo}
            <AppText text={'*один правильный ответ'} textStyle={'DesktopFontBody'} />
            <Radio.Group disabled={correctness !== undefined} onChange={onChange} value={localAnswerValue}>
                <div className={CnSingleAnswerExercise('options')}>
                    {answerOptions?.map((item: ISingleAnswerExerciseOptions, index: number) => {
                        return (
                            <div
                                key={`binary-exercise.${index}.${item.value}`}
                                className={CnSingleAnswerExercise('options-item', { type: item?.correctness })}
                            >
                                <Radio defaultChecked={!!item.correctness} value={item.value}>
                                    <div
                                        className={CnSingleAnswerExercise('label-text')}
                                        dangerouslySetInnerHTML={{ __html: item.label }}
                                    />
                                </Radio>
                            </div>
                        );
                    })}
                </div>
            </Radio.Group>
        </div>
    );
};

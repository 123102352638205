import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getQuestionDetailed, getQuestions, QuestionStatus } from 'api/services/questionBank';
import {
    fetchAssignmentSearchBlocks,
    getQuestionSearch,
    getQuestionSearchByQueryFilters,
    postSearchQuestions,
    SearchBlockGet,
    SearchBlockPost,
} from 'api/services/questionCreate';
import { QuestionSearchBarFieldsValues } from 'containers/QuestionSearchBar';
import { IQuestion } from 'containers/QuestionSearchResult/QuestionSearchResult';
import { ISearchBlock } from 'containers/QuestionSearchResult/types';
import { Status } from 'pages/QuestionBankPage/types';

import { createUrlQuery } from 'utils/createUrlQuery';
import { convertQueryFiltersToPageFilters } from 'utils/searchBlocks';

type QuestionFilters = {
    questionIds: number[];
    searchBlocks: SearchBlockGet[];
    searchBlocksWithSingleQuestion: SearchBlockGet[];
};

export const fetchAssignmentSearchBlocksAction = createAsyncThunk(
    'questionCreate/fetchAssignmentSearchBlocks',
    async (assignmentId: number) => {
        const assignmentSearchBlocks = await fetchAssignmentSearchBlocks(assignmentId);

        const formValuesAccumulator: { [questionNumber in number]: SearchPageState } = {};
        const singleQuestionsAccumulator: { [questionNumber in number]: SingleQuestions } = {};

        const separatedFilters = assignmentSearchBlocks.reduce(
            (result, searchBlock) => {
                const { filters, questionNumber: questionNum } = searchBlock;

                formValuesAccumulator[questionNum] = {
                    questionNum,
                    filters: [],
                    excludesIds: [],
                    rightSearchBlock: [],
                    leftSearchBlock: [],
                };
                singleQuestionsAccumulator[questionNum] = {
                    questionNum,
                    questions: [],
                };

                if (filters.id) {
                    if (!result.questionIds.includes(filters.id)) {
                        result.questionIds.push(filters.id);
                    }

                    result.searchBlocksWithSingleQuestion.push(searchBlock);
                }

                if (!filters.id) {
                    result.searchBlocks.push(searchBlock);
                }

                return result;
            },
            {
                questionIds: [],
                searchBlocks: [],
                searchBlocksWithSingleQuestion: [],
            } as QuestionFilters,
        );

        if (separatedFilters.questionIds.length > 0) {
            await getQuestions(createUrlQuery({ include_ids: separatedFilters.questionIds })).then((questions) => {
                separatedFilters.searchBlocksWithSingleQuestion.forEach(({ filters, questionNumber }) => {
                    const question = questions.find(({ id }) => filters.id === id);

                    if (question) {
                        singleQuestionsAccumulator[questionNumber].questions.push(question);
                    }
                });
            });
        }

        await Promise.all(
            separatedFilters.searchBlocks.map(async (searchBlock) => {
                const { excludeIds = [], ...restFilters } = searchBlock.filters;
                const questions = await getQuestionSearchByQueryFilters({
                    ...restFilters,
                    status: Status.Published,
                    all_user_questions: true,
                });

                if (searchBlock.filters.id !== undefined && searchBlock.filters.id && searchBlock.filters.id > 0) {
                    singleQuestionsAccumulator[searchBlock.questionNumber].questions.push(...questions);
                } else {
                    const newSearchBlock = {
                        questions,
                        id: searchBlock.id,
                        filters: convertQueryFiltersToPageFilters(searchBlock.filters),
                        numSearch: formValuesAccumulator[searchBlock.questionNumber].rightSearchBlock.length + 1,
                        excludesIds: formValuesAccumulator[searchBlock.questionNumber].excludesIds.push(...excludeIds),
                    };
                    formValuesAccumulator[searchBlock.questionNumber].rightSearchBlock.push(newSearchBlock);
                    formValuesAccumulator[searchBlock.questionNumber].leftSearchBlock.push(newSearchBlock);
                }
            }),
        );

        return {
            assignmentSearchBlocks,
            formValues: Object.values(formValuesAccumulator),
            questions: Object.values(singleQuestionsAccumulator),
        };
    },
);

export const postSearchQuestionAction = createAsyncThunk(
    'questionCreate/getQuestionBlock',
    async (values: SearchBlockPost) => {
        return await postSearchQuestions(values);
    },
);

export const questionDetailAction = createAsyncThunk('questionCreate/getQuestionDetail', async (id: number) => {
    return await getQuestionDetailed(id);
});

export const questionBlockAction = createAsyncThunk(
    'questionCreate/questionBlock',
    async (values: QuestionSearchBarFieldsValues & { status?: QuestionStatus; all_user_questions?: boolean }) => {
        return await getQuestionSearch(values);
    },
);

export interface SearchPageState {
    questionNum: number;
    filters: QuestionSearchBarFieldsValues[];
    rightSearchBlock: ISearchBlock[];
    leftSearchBlock: ISearchBlock[];
    excludesIds: number[];
}

export const saveStateSearchPageStore = createAction(
    'questionCreate/saveStateSearchPage',
    (formValues: SearchPageState) => {
        return {
            payload: {
                values: formValues,
            },
        };
    },
);

export const addEmptyFormValuesStore = createAction('questionCreate/addEmptyFormValuesStore', () => {
    return {
        payload: {},
    };
});

export const addEmptyQuestionsStore = createAction('questionCreate/addEmptyQuestionsStore', () => {
    return {
        payload: {},
    };
});

export interface SingleQuestions {
    questionNum: number;
    questions: IQuestion[];
}

export const saveCreateQuestionsStore = createAction(
    'questionCreate/SaveCreateQuestionsStore',
    (questions: SingleQuestions, action: 'create' | 'save') => {
        return {
            payload: {
                questions: questions,
                action: action,
            },
        };
    },
);

export const resetSearchBlocksStore = createAction('questionCreate/resetSearchBlocks', () => {
    return {
        payload: {},
    };
});

export const getAssignmentSearchBlocksAction = createAsyncThunk(
    'questionCreate/getAssignmentSearchBlocks',
    async (assignmentId: number) => {
        return await fetchAssignmentSearchBlocks(assignmentId);
    },
);

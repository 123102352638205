import React, { FC } from 'react';
import { AppButton } from '@frontend-modules/ui-kit';
import { getTrainingProgressAction } from '@fsd-entities/materials';
import { showErrorToast } from '@fsd-shared/utils';
import { useAppDispatch, useAppSelector } from 'store/store';

import { INextTrainingQuestionButtonProps } from './NextTrainingQuestionButton.types';

export const NextTrainingQuestionButton: FC<INextTrainingQuestionButtonProps> = (props) => {
    const { lessonId, courseGroupId, setLocalAnswerValue } = props;
    const dispatch = useAppDispatch();
    const { isCheckQuestionLoading, question } =
        useAppSelector((store) => store.newMaterials?.training?.exercise) || {};

    const isDisableNextButton = isCheckQuestionLoading || !question?.answerData?.correctness;
    const onNextQuestionClick = () => {
        setLocalAnswerValue(undefined);
        if (lessonId && courseGroupId) {
            const params = {
                lesson: lessonId,
                courseGroup: courseGroupId,
                next: true,
            };
            void dispatch(getTrainingProgressAction(params)).then((resp) => {
                // @ts-ignore
                const errorData = resp?.error?.message;
                if (errorData) {
                    const errorText = JSON.parse(errorData)?.extra?.fields;
                    if (errorText) {
                        showErrorToast(errorText);
                    }
                }
            });
        }
    };

    return (
        <AppButton
            isDisabled={isDisableNextButton}
            label={'Следующий вопрос'}
            onClick={onNextQuestionClick}
            isFullWidth={false}
        />
    );
};

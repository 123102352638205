import { getApiRequest } from 'api';
import { EventRoom } from 'api/services/proctoring';
import { EventTypes } from 'types/events';

export type GetEventRoomsParams = {
    lesson?: number;
    lesson_ids?: string;
    event_type?: EventTypes;
    students?: number[];
    datetime_start_before?: string;
    datetime_start_after?: string;
};

export const getEventRooms = (params: GetEventRoomsParams): Promise<EventRoom[]> => {
    return getApiRequest('/event-room/', { params });
};

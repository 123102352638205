import { getApiRequest } from 'api';

export interface AcademicYear {
    id: number;
    title: string;
    isCurrent: boolean;
}

export const getAcademicYear = (): Promise<AcademicYear[]> => {
    return getApiRequest(`/academic-year/`);
};

import { questionsBankPageData } from 'constants/cypress/question-bank-page';

import React, { useCallback, useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Folders, LittleArrow } from '@lms-elements/icons';
import { useCodifierContext } from 'containers/CodifierSelector/context';
import { SectionElement } from 'containers/CodifierSelector/SectionElement';
import { useAppSelector } from 'store/store';

import { CodifierEventType } from 'components/Codifier/Codifier';

import './ParallelElement.scss';

const cx = cn('parallel-element');

interface ParallelElementProps {
    parallelId: number;
    parallelIndex?: number;
}

export const ParallelElement: React.FC<ParallelElementProps> = ({ parallelId, parallelIndex }) => {
    const parallelData = useAppSelector((store) => store.codifier.parallelsMap[parallelId]);

    const {
        onElementClick,
        selectedElement: { parallels, sections },
    } = useCodifierContext();

    const [isSelected, setIsSelected] = useState(false);
    const [isOpened, setIsOpened] = useState(false);

    useEffect(() => {
        setIsSelected(!sections?.length && parallels?.[0] === parallelId);
        setIsOpened(Boolean(parallels?.includes(parallelId)));
    }, [parallelId, parallels, sections]);

    const isDisabled = parallelData.sections.length === 0;

    const handleSubjectClick = useCallback(() => {
        if (!isDisabled && !isSelected) {
            onElementClick(CodifierEventType.PARALLEL, parallelId);
        }
    }, [isDisabled, isSelected, onElementClick, parallelId]);

    return (
        <div className={cx()}>
            <div
                className={cx('container', { selected: isSelected })}
                data-cy={
                    typeof parallelIndex === 'number'
                        ? questionsBankPageData.getParallelContainer(parallelIndex)
                        : undefined
                }
            >
                <div
                    className={cx('header', { selected: isSelected, disabled: isDisabled })}
                    onClick={handleSubjectClick}
                >
                    <div className={cx('open', { opened: isOpened, disabled: isDisabled })}>
                        <LittleArrow />
                    </div>
                    <Folders className={cx('folders', { opened: isOpened, disabled: isDisabled })} />
                    <div className={cx('title')}>{parallelData.title}</div>
                </div>
            </div>
            {isOpened && !isDisabled && (
                <div className={cx('elements-container', { selected: isSelected })}>
                    {parallelData.sections.map((sectionId, sectionIndex) => (
                        <SectionElement key={sectionId} sectionId={sectionId} sectionIndex={sectionIndex} />
                    ))}
                </div>
            )}
        </div>
    );
};

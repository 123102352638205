import { DAY_IN_SECONDS } from 'common/constants';
import moment, { Moment } from 'moment';

/**
 * @example capitalizeWord('1 марта 2021', 2) === 1 Марта 2021
 * @param text source text
 * @param wordIndex the ordinal number of the word in the text, starting from 1
 * @returns text with capitalize word
 */
const capitalizeWord = (text: string, wordIndex: number): string => {
    const result = text
        .split(' ')
        .map((word, index) => {
            if (index === wordIndex - 1) {
                return word[0].toUpperCase() + word.slice(1);
            }

            return word;
        })
        .join(' ');

    return result;
};

/**
 * Returns overdue status
 * @param deadline deadline date
 */

export const checkOverdue = (deadline?: string | number): boolean => {
    return deadline ? Number(new Date()) - Number(new Date(deadline)) > 0 : false;
};

export const plusDaysToCurrentDate = (date: Date, days: number): Date =>
    new Date(date.getTime() + days * DAY_IN_SECONDS);
export const minusDaysToCurrentDate = (date: Date, days: number): Date =>
    new Date(date.getTime() - days * DAY_IN_SECONDS);

/**
 * Return format date
 * @param date incoming date
 */

export const formatDate = (date: Date | string): string => {
    return moment(date).format('LL').split(' ').slice(0, 2).join(' ');
};

export const convertDateToHtmlDateString = (date?: Date | string): string => moment(date).format('YYYY-MM-DD');
export const getDateString = (date?: Date | string): string => moment(date).format('DD.MM.YYYY');

export const getDayOfMonth = (date: Date | string): number => moment(date).get('date');

export const getTwoCharsDate = (date: Date): string => moment(date).format('DD');

export const getDateWithAbbreviatedMonth = (date: Date): string => moment(date).format('DD MMM');

/**
 * gets a date and returns the number of minutes before the date
 * @param date - incoming date
 * @returns string
 */

export const getNumberMinutesBeforeDate = (date: Date | string): string => {
    const rightTime = moment(date).fromNow();
    return rightTime.includes('через') || rightTime.includes('in')
        ? rightTime
              .split(' ')
              .slice(1, 3)
              .map((word, index) => {
                  if (index === 1 && word.length > 4) {
                      return `${word.split('').slice(0, 3).join('')}.`;
                  }

                  return word;
              })
              .join(' ')
        : rightTime;
};

/**
 * Return date string 'D MMMM' with capitalaze month name
 * @example 1 марта -> 1 Марта
 * @param date incoming date
 * @returns
 */
export const getCapitalizeMonth = (date?: Date | string): string => {
    const result = moment(date).format('D MMMM');

    return capitalizeWord(result, 2);
};

export const getDayOfMonthTitle = (date: Date | string): string => {
    const monthDay = getDayOfMonth(date);

    return monthDay === 1 ? getCapitalizeMonth(date) : String(monthDay);
};

export const getWeekday = (date: Date | string): string | undefined => {
    const startDateWeekday = moment(date).startOf('month').weekday();
    const countDaysToWeekEnd = 7 - startDateWeekday;
    const monthDay = getDayOfMonth(date);

    if (monthDay <= countDaysToWeekEnd) {
        const result = moment(date).format('dd');

        return capitalizeWord(result, 1);
    }
};

export const getDaysInMonth = (date: Date | string): number => {
    return moment(date, 'YYYY-MM').daysInMonth();
};

export const getWeekdayNumber = (date: Date | string): number => {
    return moment(date).weekday();
};

export const getCapitalizeStartOfWeek = (date: Date | string): string => {
    const startOfWeek = moment(date).startOf('week').format('D MMMM YYYY');

    return capitalizeWord(startOfWeek, 2);
};

export const getCapitalizeEndOfWeek = (date: Date | string): string => {
    const endOfWeek = moment(date).endOf('week').format('D MMMM YYYY');

    return capitalizeWord(endOfWeek, 2);
};

export const getCapitalizeMonthAndYear = (date?: Date | string | [number, number]): string => {
    let monthAndYear = moment(date).format('MMMM YYYY');

    if (Array.isArray(date)) {
        const [month, year] = date;
        monthAndYear = moment().set({ month, year }).format('MMMM YYYY');
    }

    return capitalizeWord(monthAndYear, 1);
};

export const getStartOfMonth = (date?: Date | string): string => {
    return moment(date).startOf('month').format('YYYY-MM-DD');
};

export const getEndOfMonth = (date?: Date | string): string => {
    return moment(date).endOf('month').format('YYYY-MM-DD');
};

export const getStartOfWeek = (date?: Date | string, format?: string): string => {
    return moment(date).startOf('week').format(format);
};

export const getEndOfWeek = (date?: Date | string, format?: string): string => {
    return moment(date).endOf('week').format(format);
};

/**
 *
 * @param date start week date
 * @returns array of dates
 */

export const getWeekArray = (date: Date | string): string[] => {
    const start = moment(date);
    const resultArray = [];

    for (let i = 0; i < 7; i = i + 1) {
        const newDate = moment(start).add(i, 'd').format('YYYY-MM-DD');
        resultArray.push(newDate);
    }

    return resultArray;
};

export const getCalendarWeekTitle = (date: Date | string): string => {
    const weekday = capitalizeWord(moment(date).format('dd'), 1);

    if (weekday === 'Пн') {
        return `${weekday} - ${getCapitalizeMonth(date)}`;
    }

    return `${weekday} - ${getDayOfMonthTitle(date)}`;
};

export const getTimeArray = (startTime: number, maxTime: number, step = 60): string[] => {
    const resultArray: string[] = [];
    const startTimeMinutes = startTime * 60;
    const endTimeMinutes = maxTime * 60;
    for (let i = startTimeMinutes; i <= endTimeMinutes; i = i + step) {
        const hours = Math.floor(i / 60);
        const minutes = i % 60;
        const newHours = String(hours).length < 2 ? `0${hours}` : String(hours);
        const newMinutes = String(minutes).length < 2 ? `0${minutes}` : String(minutes);

        resultArray.push(`${newHours}:${newMinutes}`);
    }

    return resultArray;
};

type TimeStatus = 'future' | 'present' | 'late' | 'past';
export const getTimeStatus = (start: Date | string, end: Date | string): TimeStatus => {
    const lateTimeStart = moment(start).add(15, 'm').format();
    const isBefore = moment().isBefore(start);
    const isPresent = moment().isBetween(start, lateTimeStart);
    const isLate = moment().isBetween(lateTimeStart, end);

    if (isBefore) {
        return 'future';
    }

    if (isPresent) {
        return 'present';
    }

    if (isLate) {
        return 'late';
    }

    return 'past';
};

export const getTimeBeforeDate = (date: Date | string): string => {
    return moment(date).toNow(true);
};

export const getTimeAfterDate = (date: Date | string): string => {
    return moment(date).fromNow(true);
};

/**
 * return ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс']
 */
export const getWeekdayNames = (): string[] => {
    return new Array(7).fill(' ').map((_, index) => {
        switch (index) {
            case 0:
                return 'Пн';
            case 1:
                return 'Вт';
            case 2:
                return 'Ср';
            case 3:
                return 'Чт';
            case 4:
                return 'Пт';
            case 5:
                return 'Сб';
            default:
                return 'Вс';
        }
    });
};

interface CalendarDropdownDay {
    label: string;
    date: string;
    inactive?: boolean;
}
export const getCalendarDropdownDays = (month: number, year: number): CalendarDropdownDay[] => {
    const start = moment().set({ month, year }).startOf('month');
    const daysCount = start.daysInMonth();

    return new Array(daysCount).fill(' ').map((_, index) => {
        const date = moment(start).add(index, 'd');
        return {
            label: date.format('D'),
            inactive: false,
            date: date.format('YYYY-MM-DD'),
        };
    });
};

/**
 * @example 2021-05-06T15:30 -> 06.05.2021 в 15:30
 * @param date incomind date
 * @returns
 */
export const getDatepickerSelectTitle = (date: string | Date): string => {
    const resultDate = moment(date).format('DD.MM.YYYY');
    const resultTime = moment(date).format('HH:mm');
    if (resultTime === '00:00') {
        return `${resultDate}`;
    }
    return `${resultDate} в ${resultTime}`;
};

export const getSelectedDate = (day?: string | Date, time?: string): moment.Moment => {
    const [hours, minutes] = time?.split(':') || [0, 0];
    const newDate = moment(day).add(hours, 'h').add(minutes, 'minute');

    return newDate;
};

export interface DateComponents {
    month: number;
    year: number;
    dateWithoutTime?: string;
    time?: string;
    dateWithMonth?: string;
}
export const getDateComponents = (date?: string | Date): DateComponents => {
    return {
        month: moment(date)?.get('month') || moment().get('month'),
        year: moment(date)?.get('year') || moment().get('year'),
        dateWithoutTime: date ? moment(date)?.format('YYYY-MM-DD') || moment().format('YYYY-MM-DD') : undefined,
        time: date ? moment(date)?.format('HH:mm') || moment(date).format('HH:mm') : undefined,
        dateWithMonth: date
            ? moment().isBefore(date) && moment().format('D') !== moment(date).format('D')
                ? getCapitalizeMonth(date)
                : 'сегодня'
            : undefined,
    };
};

/**
 *
 * @param date verified date
 * @param inactiveCondition date inactive condition or callback to check
 * @returns
 */
export const isInactive = (
    date: Date | string,
    inactiveCondition?: 'past' | 'future' | ((date: Date | string) => boolean),
): boolean => {
    if (inactiveCondition === 'past') {
        return moment().startOf('day').isAfter(date);
    }

    if (inactiveCondition === 'future') {
        return !moment().endOf('day').isAfter(date);
    }

    if (inactiveCondition instanceof Function) {
        return inactiveCondition(date);
    }

    return false;
};

export const getDateWithoutYear = (date: Date | string): string => {
    return moment(date).format('DD.MM');
};

export const checkIsPeriodEnd = (periodEnd: Date | string, date?: Date | string): boolean => {
    return moment(date).isAfter(periodEnd);
};

/**
 * @example 2021-05-06T15:30 -> 06 Мая в 15:30
 * @param date incoming date
 * @returns
 */
export const getDateWithTime = (date?: Date | string): string =>
    capitalizeWord(moment(date).format('D MMMM [в] HH:mm'), 2);

export const isDateTomorrow = (date: Date | string): boolean => {
    const endOfToday = moment().endOf('day');
    const endOfTomorrow = moment().add(1, 'day').endOf('day');

    return moment(date).isBetween(endOfToday, endOfTomorrow);
};

export const getTodayData = (): string => {
    return moment().format('YYYY-MM-DD');
};

/**
 *
 * @param dates array of dates
 * @returns earliest date
 */
export const getEarliestDate = (dates: string[]): string => {
    return dates.reduce((closestDate, date) => {
        return moment(closestDate).isBefore(date) ? moment(closestDate).format() : moment(date).format();
    }, moment().format());
};

export const getNumberHoursBeforeDate = (date: Date | string | Moment): number => {
    return moment().diff(date);
};

export const checkIsBeetween = (start: Date | string, end: Date | string): boolean => {
    return moment().isBetween(start, end);
};

/**
 *
 * @param first first date
 * @param second second date
 * @param granularity level of granularity comparison
 * @returns
 */
export const checkIsSameDates = (
    first: Moment | Date | string,
    second: Moment | Date | string,
    granularity: moment.unitOfTime.StartOf = 'minute',
): boolean => {
    const secondDate = moment(second);
    return moment(first).isSame(secondDate, granularity);
};

/**
 *
 * @param startLesson - start time of lesson
 * @param numberOfMinutes - number of minutes before start user can change lesson
 * @returns
 */
export const checkCanEditLesson = (startLesson?: Date | string | Moment, numberOfMinutes = 30): boolean => {
    if (!startLesson) {
        return true;
    }

    return moment().add(numberOfMinutes, 'minute').isBefore(startLesson);
};

export const checkIsDayBefore = (first: Date | string, second?: Date | string): boolean => {
    const secondMoment = moment(second).startOf('day');
    return moment(first).isBefore(secondMoment);
};

type AddMinutesData = {
    date?: string | Date;
    minutes?: number;
};

export const addMinutes = ({ date, minutes = 0 }: AddMinutesData): string => {
    return moment(date).add(minutes, 'minutes').format();
};

/**
 *
 * @param correctTime [HH:MM] текущее время по МСК;
 */
export const checkTimezone = (correctTime: string): boolean => {
    const [correctHours, correctMinutes] = correctTime?.split(':').map(Number);
    const currentTime = moment.tz('Europe/Moscow');
    const currentHours = currentTime.get('hours');
    const currentMinutes = currentTime.get('minutes');

    switch (true) {
        case Math.abs(correctHours - currentHours) > 1:
        case correctHours !== currentHours && Math.abs(correctMinutes - currentMinutes) < 45:
        case correctHours === currentHours && Math.abs(correctMinutes - currentMinutes) > 15:
            return false;
        default:
            return true;
    }
};

export const getDiffBetweenDates = (
    start: string,
    end: string,
    unitOfTime: moment.unitOfTime.Diff = 'minute',
): number => {
    return moment(start).diff(end, unitOfTime);
};

export const normalizeTimeToMonitoring = (start?: string, end?: string): string => {
    return `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`;
};

export type QueryValue = string | number | (string | number)[];

export const createUrlQuery = (pairs: Partial<Record<string, QueryValue>>): string => {
    const queries: string[] = [];

    Object.entries(pairs).forEach(([key, value = []]) => {
        if (Array.isArray(value) && value.length) {
            queries.push(`${key}=${value.join(',')}`);
        } else if (typeof value !== 'object' && value !== '' && value !== null) {
            queries.push(`${key}=${value}`);
        }
    });

    return `?${queries.join('&')}`;
};

import React, { ClipboardEvent, useCallback } from 'react';

type CopyCutHandler = (event: ClipboardEvent<HTMLSpanElement>) => void;
export const useHandleCopyCut = (text: string): CopyCutHandler => {
    const handleCopyCut = useCallback(
        (e: React.ClipboardEvent<HTMLSpanElement>) => {
            e.preventDefault();
            navigator.clipboard
                .writeText(text)
                .then(() => false)
                .catch((err) => console.error(err));
        },
        [text],
    );

    return handleCopyCut;
};

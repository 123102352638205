import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    createActionProgress,
    getActionCreate,
    getActionProgress,
    getActionStudents,
    postActionDetails,
} from 'api/services/groupActions';
import { IGroupActionFilters } from 'src-new/components/group-actions';
import { deleteEmptyParams, normalizeFiltersForSetAction, normalizeParamsForActions } from 'src-new/utils';
import {
    IActionDetailsRequest,
    ICreateActionProgressActionsParams,
    IGetActionProgressRequest,
} from 'types/groupActions';

export const getActionStudentsAction = createAsyncThunk(
    'groupAction/getActionStudentsAction',
    async (params: IGroupActionFilters) => {
        const newParams = normalizeParamsForActions(params);
        if (newParams) {
            return await getActionStudents(newParams);
        }
    },
);

export const getActionsListAction = createAsyncThunk(
    'groupAction/getActionsListAction',
    async (params: IGetActionProgressRequest) => {
        return await getActionProgress(params);
    },
);
export const getMoreActionsListAction = createAsyncThunk(
    'groupAction/getMoreActionsListAction',
    async (params: IGetActionProgressRequest) => {
        return await getActionProgress(params);
    },
);

export const actionDetailsAction = createAsyncThunk(
    'groupAction/actionDetailsAction',
    async (params: IActionDetailsRequest) => {
        if (params) {
            return await postActionDetails(params);
        }
    },
);
export const getActionCreateAction = createAsyncThunk('groupAction/getActionCreateAction', async () => {
    return await getActionCreate();
});

export const createActionProgressAction = createAsyncThunk(
    'groupAction/createActionProgressAction',
    async (params: ICreateActionProgressActionsParams) => {
        const { filters, ...otherParams } = params;
        const normalizeFilters = normalizeFiltersForSetAction(filters);
        const normalizeParams = { filters: normalizeFilters, ...otherParams };
        const newParams = deleteEmptyParams(normalizeParams);
        if (newParams) {
            return await createActionProgress(newParams);
        }
    },
);

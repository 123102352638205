import { getNoun } from '@frontend-modules/frontend-utils';

export const getAnswersCountText = (count: number): string => {
    return `${count} ${getNoun(count, 'вариант', 'варианта', 'вариантов')} ответа`;
};
export const getSkillsCountText = (count: number): string => {
    return `${count} ${getNoun(count, 'навык', 'навыка', 'навыков')}:`;
};
export const getCorrectAnswersCountText = (count: number): string => {
    return `Вопрос с ${count} ${getNoun(count, 'правильным', 'правильными', 'правильными')} ${getNoun(
        count,
        'ответом',
        'ответами',
        'ответами',
    )}`;
};
export const getRightTaskTitle = (numTask: number): string => {
    if (numTask === 1) {
        return 'в первый вопрос';
    }
    if (numTask === 2) {
        return 'во второй вопрос';
    }
    if (numTask === 3) {
        return 'в третий вопрос';
    }
    if (numTask === 4) {
        return 'в четвертый вопрос';
    }
    if (numTask === 5) {
        return 'в пятый вопрос';
    }
    if (numTask === 6) {
        return 'в шестой вопрос';
    }
    if (numTask === 7) {
        return 'в седьмой вопрос';
    }
    if (numTask === 8) {
        return 'в восьмой вопрос';
    }
    if (numTask === 9) {
        return 'в девятый вопрос';
    }
    if (numTask === 10) {
        return 'в десятый вопрос';
    }
    if (numTask === 11) {
        return 'в одиннадцатый вопрос';
    }
    if (numTask === 12) {
        //стоит ли для такого либу подключать?
        return 'в двенадцатый вопрос';
    }
    if (numTask === 13) {
        return 'в тринадцатый вопрос';
    }
    if (numTask === 14) {
        return 'в четырнадцатый вопрос';
    }
    if (numTask === 15) {
        return 'в пятнадцатый вопрос';
    }
    if (numTask === 16) {
        return 'в шестнадцатый вопрос';
    }
    if (numTask === 17) {
        return 'в семнадцатый вопрос';
    }
    if (numTask === 18) {
        return 'в восемнадцатый вопрос';
    }
    if (numTask === 19) {
        return 'в девятнадцатый вопрос';
    }
    return `в вопрос №${numTask}`;
};

import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { News, NewsElement } from 'api/services/news';
import {
    createNewsAction,
    deleteNewsAction,
    getNewsByDatesAction,
    getNewsByIdAction,
    getUnreadNewsStatusAction,
    updateNewsAction,
    updateNewsLikesAction,
} from 'store/actions/news';
// import { normalizeNewsElements } from 'store/normalizers/news';
import { FetchStatus } from 'types/api';

interface NewsState {
    allDataFetchStatus: FetchStatus;
    createNewsStatus: FetchStatus;
    updateNewsStatus: FetchStatus;
    updateNewsLikesStatus: FetchStatus;
    getNewsByIdStatus: FetchStatus;
    getNewsStatus: FetchStatus;
    deleteNewsStatus: FetchStatus;
    seletedNews: News;
    elements: NewsElement[];
    error: unknown;
    news: News[];
    hasUnreadNews: boolean;
}

const initialState: NewsState = {
    allDataFetchStatus: FetchStatus.INITIAL,
    getNewsStatus: FetchStatus.INITIAL,
    updateNewsLikesStatus: FetchStatus.INITIAL,
    createNewsStatus: FetchStatus.INITIAL,
    updateNewsStatus: FetchStatus.INITIAL,
    getNewsByIdStatus: FetchStatus.INITIAL,
    deleteNewsStatus: FetchStatus.INITIAL,
    error: null,
    elements: [],
    seletedNews: {} as News,
    news: [],
    hasUnreadNews: false,
};

const newsSlice = createSlice<NewsState, SliceCaseReducers<NewsState>>({
    name: 'news',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getUnreadNewsStatusAction.fulfilled, (state, { payload }) => {
            state.hasUnreadNews = payload.unreadNewsExists;
        });
        builder
            .addCase(createNewsAction.pending, (state) => {
                state.createNewsStatus = FetchStatus.FETCHING;
            })
            .addCase(createNewsAction.fulfilled, (state) => {
                state.createNewsStatus = FetchStatus.FETCHED;
            })
            .addCase(createNewsAction.rejected, (state) => {
                state.createNewsStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(getNewsByIdAction.pending, (state) => {
                state.getNewsByIdStatus = FetchStatus.FETCHING;
            })
            .addCase(getNewsByIdAction.fulfilled, (state, { payload }) => {
                state.getNewsByIdStatus = FetchStatus.FETCHED;
                state.seletedNews = payload;
                state.elements = payload.elements;
            })
            .addCase(getNewsByIdAction.rejected, (state) => {
                state.getNewsByIdStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(updateNewsAction.pending, (state) => {
                state.updateNewsStatus = FetchStatus.FETCHING;
            })
            .addCase(updateNewsAction.fulfilled, (state) => {
                state.updateNewsStatus = FetchStatus.FETCHED;
            })
            .addCase(updateNewsAction.rejected, (state) => {
                state.updateNewsStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(getNewsByDatesAction.pending, (state) => {
                state.getNewsStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getNewsByDatesAction.fulfilled, (state, { payload }) => {
                state.getNewsStatus = FetchStatus.FETCHED;
                state.news = payload;
                state.error = null;
            })
            .addCase(getNewsByDatesAction.rejected, (state, { error }) => {
                state.getNewsStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(updateNewsLikesAction.pending, (state) => {
                state.updateNewsLikesStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(updateNewsLikesAction.fulfilled, (state, { payload }) => {
                state.updateNewsLikesStatus = FetchStatus.FETCHED;
                state.news = state.news.map((el) => {
                    if (el.id === payload.id) {
                        return {
                            ...el,
                            like: payload.like,
                            likesCount: payload.likesCount,
                        };
                    }
                    return el;
                });

                state.seletedNews = { ...state.seletedNews, like: payload.like, likesCount: payload.likesCount };

                state.error = null;
            })
            .addCase(updateNewsLikesAction.rejected, (state, { error }) => {
                state.updateNewsLikesStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(deleteNewsAction.pending, (state) => {
                state.deleteNewsStatus = FetchStatus.FETCHING;
            })
            .addCase(deleteNewsAction.fulfilled, (state) => {
                state.deleteNewsStatus = FetchStatus.FETCHED;
            })
            .addCase(deleteNewsAction.rejected, (state) => {
                state.deleteNewsStatus = FetchStatus.ERROR;
            });
    },
});

export const resetNewsState = newsSlice.actions.reset as ActionCreatorWithoutPayload;
export const newsReducer = newsSlice.reducer;

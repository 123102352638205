import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

/**
 * @description переводим дату из DD.MM.YYYY в Dayjs
 * @param {string} date
 */
const formatTableDateToDayjs = (date: string): Dayjs => {
    const day = date.slice(0, 2);
    const month = date.slice(3, 5);
    const year = date.slice(6, 10);
    return dayjs(`${month}-${day}-${year}`);
};

/**
 * @description переводим дату из Dayjs в YYYY-MM-DD
 * @param {Dayjs} date
 */
const formatDayjsToParamsDate = (date: Dayjs): string => {
    return dayjs(date).format('YYYY-MM-DD');
};

/**
 * @description переводим дату из Dayjs в DD.MM.YYYY
 * @param {Dayjs} date
 */
const formatDayjsToTableDate = (date: Dayjs): string => {
    return date.format('DD.MM.YYYY');
};

/**
 * @description переводим дату из js в DD.MM.YYYY
 * @param {Date | string} date
 */
const formatJSToTableDate = (date: Date | string): string => {
    const newDate = typeof date == 'string' ? new Date(date) : date;
    const day = `0${newDate.getDate()}`.slice(-2);
    const month = `0${newDate.getMonth() + 1}`.slice(-2);
    const year = newDate.getFullYear();
    return `${day}.${month}.${year}`;
};

/**
 * @description переводим дату из DD.MM.YYYY в YYYY-MM-DD
 * @param {string} date
 */
const getTableDateToParamsDate = (date: string): string => {
    const dayjsDate = formatTableDateToDayjs(date);
    return formatDayjsToParamsDate(dayjsDate);
};

/**
 * @description переводим дату из js в HH:mm
 * @param {string} date
 */
const getTableDateToTime = (date: string): string => {
    const newDate = dayjs(date).utcOffset(3);
    const hour = `0${newDate.hour()}`.slice(-2);
    const minute = `0${newDate.minute()}`.slice(-2);
    return `${hour}:${minute}`;
};

const getDateWithTime = (date: Date | string): string => {
    const day = dayjs(date).format('DD.MM.YYYY');
    const newDate = typeof date == 'string' ? new Date(date) : date;
    const hour = `0${newDate.getHours()}`.slice(-2);
    const minute = `0${newDate.getMinutes()}`.slice(-2);
    return `${day} ${hour}:${minute}`;
};
const getDateWithOffsetMs = (offset: number): Date => {
    const date = new Date();
    date.setMilliseconds(date.getSeconds() + offset);
    return date;
};
export {
    formatDayjsToParamsDate,
    formatDayjsToTableDate,
    formatJSToTableDate,
    formatTableDateToDayjs,
    getDateWithOffsetMs,
    getDateWithTime,
    getTableDateToParamsDate,
    getTableDateToTime,
};

import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { AssignmentMark } from 'api/services/assignment/getAssignmentMarks';
import { AttendanceMark } from 'api/services/attendance/getAttendanceMarks';
import { IMark, IStatus } from 'src-new/components/lms-elements/PerformanceTable/types';
import {
    changeAttendanceMarkAction,
    createAttendanceMarkAction,
    deleteAttendanceMarkAction,
    getAttendanceMarksAction,
    getLessonAttendanceDataAction,
    getMarksAction,
    getStudentMarksAction,
    patchStudentPresenceAction,
} from 'store/actions/attendance';
import { normalizeAttendanceData } from 'store/normalizers/attendance';
import { FetchStatus } from 'types/api';
import { AttendanceData } from 'types/attendance';
import { MarkData } from 'types/mark';

export interface AttendanceState {
    fetchStatus: FetchStatus;
    getAttendMarksStatus: FetchStatus;
    getMarksStatus: FetchStatus;
    marks: IMark[];
    mark: AssignmentMark[];
    dataMap: Record<number, AttendanceData>;
    status: IStatus[];
    attendMark: AttendanceMark[];
    error: unknown;
}

const initialState: AttendanceState = {
    fetchStatus: FetchStatus.INITIAL,
    getMarksStatus: FetchStatus.INITIAL,
    getAttendMarksStatus: FetchStatus.INITIAL,
    status: [],
    mark: [],
    dataMap: {},
    marks: [],
    attendMark: [],
    error: null,
};

const attendanceSlice = createSlice<AttendanceState, SliceCaseReducers<AttendanceState>>({
    name: 'attendance',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLessonAttendanceDataAction.pending, (state) => {
                state.fetchStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getLessonAttendanceDataAction.fulfilled, (state, { payload }) => {
                state.fetchStatus = FetchStatus.FETCHED;
                state.dataMap = normalizeAttendanceData(payload);
                state.error = null;
            })
            .addCase(getLessonAttendanceDataAction.rejected, (state, { error }) => {
                state.fetchStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder.addCase(patchStudentPresenceAction.fulfilled, (state, { payload }) => {
            state.dataMap[payload.id] = {
                ...state.dataMap[payload.id],
                ...payload,
                marks: payload.marks.reduce((marks, mark) => {
                    marks.push({
                        id: mark.id,
                        score: mark.score,
                        comment: mark.comment,
                        weight: mark.weight,
                        author: mark.author?.id,
                        authorFirstName: mark.author?.firstName,
                        authorLastName: mark.author?.lastName,
                        authorPatronymic: mark.author?.patronymic,
                    });
                    return marks;
                }, [] as MarkData[]),
            };
        });
        builder.addCase(createAttendanceMarkAction.fulfilled, (state, { payload }) => {
            if (payload) {
                const { attendanceLesson, ...markData } = payload;
                state.dataMap[attendanceLesson] = {
                    ...state.dataMap[attendanceLesson],
                    marks: state.dataMap[attendanceLesson].marks.concat([markData]),
                };
            }
        });
        builder.addCase(changeAttendanceMarkAction.fulfilled, (state, { payload }) => {
            const { attendanceLesson, ...markData } = payload;
            state.dataMap[attendanceLesson] = {
                ...state.dataMap[attendanceLesson],
                marks: state.dataMap[attendanceLesson].marks.map((mark) => {
                    if (mark.id === markData.id) {
                        return markData;
                    }

                    return mark;
                }),
            };
        });
        builder.addCase(deleteAttendanceMarkAction.fulfilled, (state, { payload }) => {
            const { attendanceLesson, markId } = payload;
            state.dataMap[attendanceLesson] = {
                ...state.dataMap[attendanceLesson],
                marks: state.dataMap[attendanceLesson].marks.filter((mark) => mark.id !== markId),
            };
        });

        builder.addCase(getAttendanceMarksAction.pending, (state) => {
            state.getAttendMarksStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getAttendanceMarksAction.fulfilled, (state, { payload }) => {
            state.getAttendMarksStatus = FetchStatus.FETCHED;
            state.attendMark = payload;
            state.error = null;
        });
        builder.addCase(getAttendanceMarksAction.rejected, (state, { error }) => {
            state.getAttendMarksStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(getMarksAction.pending, (state) => {
            state.getMarksStatus = FetchStatus.FETCHING;
        });
        builder.addCase(getMarksAction.fulfilled, (state, { payload }) => {
            const { marks, stats } = payload;
            state.marks = state.marks.filter(({ id }) => !marks.some(({ id: markId }) => markId === id)).concat(marks);
            state.status = state.status
                .filter(({ id }) => !stats.some(({ id: statId }) => statId === id))
                .concat(stats);
            state.getMarksStatus = FetchStatus.FETCHED;
            state.error = null;
        });
        builder.addCase(getMarksAction.rejected, (state, { error }) => {
            state.getMarksStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(getStudentMarksAction.pending, (state) => {
            state.getMarksStatus = FetchStatus.FETCHING;
            state.status = [];
        });
        builder.addCase(getStudentMarksAction.fulfilled, (state, { payload }) => {
            const { marks, stats } = payload;
            state.marks = state.marks.filter(({ id }) => !marks.some(({ id: markId }) => markId === id)).concat(marks);
            state.status = state.status
                .filter(({ id }) => !stats.some(({ id: statId }) => statId === id))
                .concat(stats);
            state.getMarksStatus = FetchStatus.FETCHED;
            state.error = null;
        });
        builder.addCase(getStudentMarksAction.rejected, (state, { error }) => {
            state.getMarksStatus = FetchStatus.ERROR;
            state.error = error;
        });
    },
});

export const attendanceReducer = attendanceSlice.reducer;
export const resetAttendanceState = attendanceSlice.actions.reset as ActionCreatorWithoutPayload<string>;

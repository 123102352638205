import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { Checkbox, RoomCard } from '@lms-elements/atomic';
import { checkIsBeetween, decreaseDate } from '@lms-elements/utils';
import { PageLoader } from 'containers/PageLoader';
import { useRole } from 'hooks';
import { patchMissedLessonAction } from 'store/actions/lessonSchedule';
import { getMeetingRecordingsAction, postMeetingRecordingAction } from 'store/actions/meeting';
import { joinRoomAction, rejoinRoomAction } from 'store/actions/proctoring';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';
import { EventTypes } from 'types/events';

import { FilesContainer } from './FilesContainer';
import { getFiles } from './utils';

import './OnlineLessonTab.scss';

const CnOnlineLessonTab = cn('onlineLessonTab');

interface IOnlineLessonTabProps {
    lessonScheduleId?: number;
    lessonId: number;
    isOvered?: boolean;
    isStarted?: boolean;
    isTest?: boolean;
    courseGroupId?: number;
}

export const OnlineLessonTab: React.FC<IOnlineLessonTabProps> = ({
    lessonScheduleId,
    lessonId,
    isOvered,
    isStarted,
    isTest,
    courseGroupId,
}) => {
    const dispatch = useDispatch();
    const { isStudent } = useRole();
    const history = useHistory();

    const { fetchMeetingStatus, meeting, meetingFiles, fetchMeetingFilesStatus } = useAppSelector(
        (store) => store.meeting,
    );
    const { eventRooms, getEventRoomsStatus, changeEventRoomStatus } = useAppSelector((store) => store.proctoring);

    const { info } = useAppSelector((store) => store.user);

    const currentEventRoom = useMemo(() => eventRooms.find(({ isJoined }) => isJoined), [eventRooms]);

    const isLoading =
        fetchMeetingStatus === FetchStatus.FETCHING ||
        fetchMeetingFilesStatus === FetchStatus.FETCHING ||
        changeEventRoomStatus === FetchStatus.FETCHING ||
        getEventRoomsStatus === FetchStatus.FETCHING;

    useEffect(() => {
        if (isOvered && lessonScheduleId && !meeting[lessonScheduleId]) {
            dispatch(getMeetingRecordingsAction({ lessonSchedule: lessonScheduleId }));
        }
    }, [isOvered, dispatch, lessonScheduleId, meeting]);

    const selectedMeeting = useMemo(() => {
        if (lessonScheduleId) {
            return meeting[lessonScheduleId];
        }
    }, [lessonScheduleId, meeting]);

    const handleVideoCheckboxChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, id: number) => {
            const isActive = e.target.checked;
            if (lessonScheduleId && selectedMeeting) {
                dispatch(
                    postMeetingRecordingAction({
                        lessonSchedule: lessonScheduleId,
                        id: id,
                        isPublished: isActive,
                    }),
                );
            }
        },
        [lessonScheduleId, selectedMeeting, dispatch],
    );

    const files = useMemo(() => getFiles(meetingFiles), [meetingFiles]);

    const records = useMemo(() => {
        if (selectedMeeting) {
            return selectedMeeting.recordings;
        }
    }, [selectedMeeting]);

    const isEmpty = !records?.length && !files?.length && !eventRooms?.length && !lessonScheduleId;

    const handleJoinClick = useCallback(
        (id: number) => {
            dispatch(joinRoomAction({ newId: id, lesson: lessonId, studentId: info?.id, event_type: EventTypes.TEST }));
        },
        [dispatch, info?.id, lessonId],
    );

    const handleRecordClick = useCallback(
        (id: number) => () => {
            if (isStudent) dispatch(patchMissedLessonAction({ id: id, recordViewed: true }));
        },
        [dispatch, isStudent],
    );

    const handleRejoinClick = useCallback(
        (id: number) => {
            const leaveId = eventRooms.find((el) => el.students?.some((el) => el.id === info?.id))?.id;
            if (leaveId !== undefined) {
                dispatch(
                    rejoinRoomAction({
                        leaveId: leaveId,
                        newId: id,
                        studentId: info?.id,
                        lesson: lessonId,
                        event_type: EventTypes.TEST,
                    }),
                );
            }
        },
        [dispatch, eventRooms, info?.id, lessonId],
    );

    const handleRoomClick = useCallback(
        (id: number) => {
            history.push(`/proctoring/event-room/${id}`);
        },
        [history],
    );

    const needHideButton =
        !isStudent ||
        checkIsBeetween(
            decreaseDate({ date: currentEventRoom?.datetimeStart ?? '', amount: 10, unit: 'm' }),
            currentEventRoom?.datetimeEnd ?? '',
        );

    if (isLoading) {
        return <PageLoader showLoading={isLoading} />;
    }
    if (isStudent && isEmpty && !isLoading) {
        return (
            <div className={CnOnlineLessonTab('empty')}>
                {!isStarted ? (
                    <h2 className={CnOnlineLessonTab('empty-title')}>
                        {isTest && !lessonScheduleId ? 'Комнаты еще не созданы!' : 'Онлайн-занятие будет позже!'}
                    </h2>
                ) : (
                    <h2 className={CnOnlineLessonTab('empty-title')}>Запись онлайн занятия будет позже!</h2>
                )}
            </div>
        );
    }

    return (
        <div className={CnOnlineLessonTab({ isOvered, isStudent })}>
            {isTest && eventRooms.length !== 0 && (
                <>
                    <div className={CnOnlineLessonTab('roomsContainer')}>
                        <div className={CnOnlineLessonTab('rooms')}>
                            {eventRooms.map((el) => (
                                <RoomCard
                                    {...el}
                                    key={el.id}
                                    onJoinClick={el.isJoined ? undefined : handleJoinClick}
                                    onRejoinClick={handleRejoinClick}
                                    onCardClick={isStudent ? undefined : handleRoomClick}
                                    needHideButton={needHideButton}
                                    isStudent
                                />
                            ))}
                        </div>
                    </div>
                </>
            )}
            {isOvered && (
                <>
                    {Boolean(records?.length) && (
                        <div className={CnOnlineLessonTab('videoContainer')}>
                            {fetchMeetingStatus === FetchStatus.FETCHED &&
                                records?.map((record, index) => {
                                    return (
                                        <div key={`${record.id}-${index}`}>
                                            <div className={CnOnlineLessonTab('videoAvailability')}>
                                                <h2 className={CnOnlineLessonTab('videoAvailabilityTitle')}>
                                                    Запись занятия
                                                </h2>
                                                {!isStudent && (
                                                    <Checkbox
                                                        label="доступна"
                                                        defaultChecked={record?.isPublished}
                                                        handleCheckboxChange={(e) =>
                                                            handleVideoCheckboxChange(e, record.id)
                                                        }
                                                    />
                                                )}
                                            </div>
                                            <a
                                                className={CnOnlineLessonTab('record-wrapper')}
                                                key={record.link}
                                                href={record.link}
                                                target="_blank"
                                                rel="noreferrer"
                                                onClick={handleRecordClick(lessonId)}
                                            >
                                                <img
                                                    className={CnOnlineLessonTab('record-preview')}
                                                    src={record.preview}
                                                    alt="record"
                                                />
                                                <div className={CnOnlineLessonTab('duration-title')}>
                                                    {record.duration}
                                                </div>
                                            </a>
                                        </div>
                                    );
                                })}
                        </div>
                    )}
                </>
            )}
            {isTest && eventRooms.length !== 0 ? (
                <FilesContainer
                    lessonScheduleId={lessonScheduleId}
                    courseGroupId={courseGroupId}
                    lessonId={lessonId}
                    isOvered={isOvered}
                    isTest
                />
            ) : (
                <FilesContainer
                    lessonScheduleId={lessonScheduleId}
                    courseGroupId={courseGroupId}
                    lessonId={lessonId}
                    isOvered={isOvered}
                />
            )}
        </div>
    );
};

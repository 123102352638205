import { IInsertWordAnswerData, TAnswerCorrectness } from '@fsd-shared/models/materials';

export const getInsertWordInputStatus = (
    value?: string,
    answerOptions?: IInsertWordAnswerData['answerOptions'],
): TAnswerCorrectness | string => {
    let correctness: TAnswerCorrectness | string = '';
    answerOptions?.forEach((item) => {
        if (item.selectedWord?.trim() === value?.trim() && !correctness) {
            correctness = item?.correctness ?? '';
        }
    });
    return correctness;
};

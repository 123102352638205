import { getApiRequest } from 'api';
import { MarkData } from 'types/mark';

export interface AttendanceMark {
    id?: number;
    marks: MarkData[];
    lesson: {
        id: number;
        title: string;
    };
    student: number;
    course: number;
}

export interface AttendanceParams {
    lessonIds: number[];
    studentIds: number[];
}

export interface AttendanceStudentParams {
    mark_date_before: string;
    mark_date_after: string;
    student: number;
}

export interface AttendanceStudentByDateParams {
    mark_date_before: string;
    mark_date_after: string;
    lessonIds: number[];
    studentIds: number[];
}

export const getAttendanceMarks = (params: AttendanceParams): Promise<AttendanceMark[]> => {
    return getApiRequest(
        `/attendance-lesson/marks/?lessons=${params.lessonIds.join(',')}&students=${params.studentIds.join(',')}`,
    );
};

export const getAttendanceStudentMarks = (params: AttendanceStudentParams): Promise<AttendanceMark[]> => {
    return getApiRequest(
        `/attendance-lesson/marks/?mark_date_before=${params.mark_date_before}&mark_date_after=${params.mark_date_after}&students=${params.student}`,
    );
};

export const getAttendanceMarksByDate = (params: AttendanceStudentByDateParams): Promise<AttendanceMark[]> => {
    return getApiRequest(
        `/attendance-lesson/marks/?mark_date_before=${params.mark_date_before}&mark_date_after=${
            params.mark_date_after
        }&lessons=${params.lessonIds.join(',')}&students=${params.studentIds.join(',')}`,
    );
};

import React, { useMemo } from 'react';
import { getNoun } from '@frontend-modules/frontend-utils';
import { AppText } from '@frontend-modules/ui-kit';
import { QuestionsListBar } from 'src-new/components/lesson-task';
import { E_COLORS } from 'src-new/constants';

import { TTasksQuestionsListProps } from './TasksQuestionsList.types';

import './style.scss';

export const TasksQuestionsList: React.FC<TTasksQuestionsListProps> = (props) => {
    const { questionsList, isNoType, handleChangeList, handleQuestionClick } = props;

    const allQuestionsCount = useMemo(() => {
        const notEmptyQuestions = questionsList.filter(({ questionsCount }) => !!questionsCount);

        return notEmptyQuestions.length;
    }, [questionsList]);

    return (
        <div className={'tasks-questions-list'}>
            <div className={'tasks-questions-list__title'}>
                <AppText text={'Вопросы'} textStyle={'DesktopH2'} color={E_COLORS.dark_grey_color_2} />
                <AppText
                    text={`всего будет задано ${allQuestionsCount} ${getNoun(
                        allQuestionsCount,
                        'вопрос',
                        'вопроса',
                        'вопросов',
                    )}`}
                    color={E_COLORS.dark_grey_color_2}
                />
            </div>
            <QuestionsListBar
                questionList={questionsList}
                handleChangeList={handleChangeList}
                handleQuestionClick={handleQuestionClick}
                isNoType={isNoType}
            />
        </div>
    );
};

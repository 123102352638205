import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { isEmptyList } from '@frontend-modules/frontend-utils';
import { AppButton, AppFontIcon, AppText, AppTextareaInput } from '@frontend-modules/ui-kit';
import { getInitialFilters } from 'src-new/components/lesson-task';
import { E_COLORS, E_COMPLEXITY_OPTIONS, E_QUESTION_TYPE, E_SKILLS_FILTERS } from 'src-new/constants';
import { AppSelect, AppSkeleton } from 'src-new/ui';
import { fetchCodifierSkillsDataAction } from 'store/actions/codifier';
import { getSubjectDataByIdAction, getSubjectsDataAction, setSelectedBlockAction } from 'store/actions/lessonTask';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';
import { QueryParams } from 'types/lessonTask';

import { MultipleSelector } from './multiple-selector';
import {
    TQuestionComplicity,
    TQuestionDifficultyField,
    TQuestionSearchBar,
    TQuestionSkill,
    TQuestionSkillField,
    TQuestionTypes,
    TQuestionTypesField,
    TSearchData,
    TTreeData,
} from './QuestionSearchBar.types';
import { TreeSelector } from './tree-selector';

import './style.scss';

const questionsComplicityNormalize = Object.values(E_COMPLEXITY_OPTIONS) as TQuestionComplicity[];
const skillsUnionTypesNormalize = Object.values(E_SKILLS_FILTERS).map((item) => {
    return {
        label: <AppText text={item as TQuestionSkill} textStyle={'DesktopFontBody'} />,
        value: item as TQuestionSkill,
    };
});
export const QuestionSearchBar: React.FC<TQuestionSearchBar> = (props) => {
    const dispatch = useDispatch();

    const {
        subjectId,
        isShowEssayOption,
        isLoading = false,
        filtersData,
        handleSearchButtonClick,
        handleCreateNewQuestion,
        handleDeleteBlock,
    } = props;

    const { codifierSkillsData } = useAppSelector((store) => store.codifier);
    const { selectedBlock, subjectsTree, searchTreeStatus } = useAppSelector((store) => store.lessonTask);

    const questionTypeListNormalize = isShowEssayOption
        ? (Object.values(E_QUESTION_TYPE) as TQuestionTypes[])
        : (Object.values(E_QUESTION_TYPE) as TQuestionTypes[]).filter((item) => item !== E_QUESTION_TYPE.essay);

    const [selectedSubjectId, setSelectedSubjectId] = useState<number>(0);
    const [topics, setTopics] = useState<string[]>([]);
    const [skills, setSkills] = useState<string[]>([]);
    const [skillsUnionType, setSkillsUnionType] = useState<TQuestionSkill>('Все');
    const [description, setDescription] = useState<string>('');
    const [complicity, setComplicity] = useState<TQuestionComplicity[]>(questionsComplicityNormalize);
    const [types, setTypes] = useState<TQuestionTypes[]>(questionTypeListNormalize);

    const topicsTreeNormalize: TTreeData[] = useMemo(() => {
        const data = subjectsTree.find((item) => +item.id === +selectedSubjectId);

        const parallels = data?.parallels ?? [];
        return parallels.map((parallel) => {
            const { id, grade, sections = [] } = parallel;
            return {
                label: <AppText text={`${grade} класс`} textStyle={'DesktopFontBody'} />,
                value: `parallel${id}`,
                checkable: false,
                isLeaf: !sections.length,
                children: sections.map((section) => {
                    const { id, title, blocks = [] } = section;

                    return {
                        label: <AppText text={title} textStyle={'DesktopFontBody'} />,
                        value: `parallel${parallel.id}-section${id}`,
                        checkable: false,
                        isLeaf: !blocks.length,
                        children: blocks?.map((block) => {
                            const { id, title, topics = [] } = block;

                            return {
                                label: <AppText text={title} textStyle={'DesktopFontBody'} />,
                                value: `parallel${parallel.id}-section${section.id}-block${id}`,
                                checkable: false,
                                isLeaf: !topics.length,
                                children: topics?.map((topic) => {
                                    const { id, title } = topic;

                                    return {
                                        label: <AppText text={title} textStyle={'DesktopFontBody'} />,
                                        value: `parallel${parallel.id}-section${section.id}-block${block.id}-${id}`,
                                        checkable: true,
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        });
    }, [subjectsTree, selectedSubjectId]);
    const skillsTreeNormalize: TTreeData[] = useMemo(() => {
        return codifierSkillsData?.map((subject) => {
            const { id, title, parallels = [] } = subject;

            return {
                label: <AppText text={title} textStyle={'DesktopFontBody'} />,
                value: `subject${id}`,
                checkable: false,
                isLeaf: !parallels.length,
                children: parallels.map((parallel) => {
                    const { id, grade, metasubjects = [] } = parallel;

                    return {
                        label: <AppText text={`${grade} класс`} textStyle={'DesktopFontBody'} />,
                        value: `subject${subject.id}-parallel${parallel.id}`,
                        checkable: false,
                        isLeaf: !metasubjects.length,
                        children: metasubjects.map((metasubject) => {
                            const { id, title, skills = [] } = metasubject;

                            return {
                                label: <AppText text={title} textStyle={'DesktopFontBody'} />,
                                value: `subject${subject.id}-parallel${parallel.id}-metasubject${metasubject.id}`,
                                checkable: false,
                                isLeaf: !skills.length,
                                children: skills?.map((skill) => {
                                    const { id, title } = skill;

                                    return {
                                        label: <AppText text={title} textStyle={'DesktopFontBody'} />,
                                        value: `subject${subject.id}-parallel${parallel.id}-metasubject${metasubject.id}-${id}`,
                                        checkable: true,
                                    };
                                }),
                            };
                        }),
                    };
                }),
            };
        });
    }, [codifierSkillsData]);

    const isSearchButtonDisabled = !topics.length;
    const subjectsTreeNormalize = Object.values(subjectsTree).map((item) => {
        return {
            value: `${item.id}`,
            label: item.title,
        };
    });
    const selectedSubject = subjectsTreeNormalize.find(({ value }) => value === `${selectedSubjectId}`);

    const setInitialFilters = (filters: QueryParams | undefined) => {
        const initialFilters = filters
            ? getInitialFilters({
                  filters: filters,
                  tree: subjectsTree,
                  codifierSkillsData,
                  isShowEssayOption,
              })
            : undefined;

        if (initialFilters) {
            const {
                subjectId,
                topicsValues,
                skillsValues,
                skillsUnionTypeValue,
                textValue,
                complicityValues,
                answersTypeValue,
            } = initialFilters;

            setSelectedSubjectId(subjectId);
            setTopics(topicsValues);
            setSkills(skillsValues);
            setSkillsUnionType(skillsUnionTypeValue);
            setDescription(textValue);
            setComplicity(complicityValues);
            setTypes(answersTypeValue);
        } else {
            setSelectedSubjectId(subjectId);
            setTopics([]);
            setSkills([]);
            setSkillsUnionType('Все');
            setDescription('');
            setComplicity(questionsComplicityNormalize);
            setTypes(questionTypeListNormalize);
        }
    };

    useEffect(() => {
        dispatch(getSubjectsDataAction());
    }, []);
    useEffect(() => setSelectedSubjectId(subjectId), [subjectId]);
    useEffect(() => {
        if (selectedSubjectId && !isEmptyList(subjectsTree)) {
            dispatch(getSubjectDataByIdAction(selectedSubjectId));
        }
        dispatch(fetchCodifierSkillsDataAction());
    }, [selectedSubjectId, subjectsTree.length]);

    useEffect(() => {
        setInitialFilters(selectedBlock?.block.filters);
    }, [selectedBlock]);

    useEffect(() => {
        setInitialFilters(filtersData);
    }, [filtersData]);

    const handleQuestionDescription = (desc: string) => {
        setDescription(desc);
    };

    const onMultipleSelect = (field: 'complicity' | 'types', list: string[]) => {
        if (field === 'complicity') {
            setComplicity(list as TQuestionComplicity[]);
        }
        if (field === 'types') {
            setTypes(list as TQuestionTypes[]);
        }
    };
    const onTreeSelect = (field: 'topics' | 'skills', list: string[]) => {
        if (field === 'topics') {
            setTopics(list);
        }
        if (field === 'skills') {
            setSkills(list);
        }
    };
    const onSkillsUnionTypeSelect = (unionType: TQuestionSkill) => {
        setSkillsUnionType(unionType);
    };
    const onSubjectSelect = (id: number) => {
        setSelectedSubjectId(id);
        setTopics([]);
    };

    const handleSearchButtonClickLocal = () => {
        const searchFilters: TSearchData = {
            topics: topics.map((topicId) => +topicId.split('-').slice(-1)),
            // @ts-ignore
            answers_type: Object.keys(E_QUESTION_TYPE)
                .map((TYPE) => {
                    if (types.includes(E_QUESTION_TYPE[TYPE as TQuestionTypesField])) {
                        return TYPE;
                    }
                })
                .filter((item) => item),
            status: 'published',
            all_user_questions: 'true',
        };
        if (description) {
            searchFilters.text = description;
        }
        if (skills.length) {
            const field: TQuestionSkillField =
                (Object.keys(E_SKILLS_FILTERS).find(
                    (SKILL) => E_SKILLS_FILTERS[SKILL as TQuestionSkillField] === skillsUnionType,
                ) as TQuestionSkillField) ?? 'skills_and';
            searchFilters[field] = skills.map((skillId) => +skillId.split('-').slice(-1));
        }
        if (complicity.length) {
            // @ts-ignore
            searchFilters.difficulty = Object.keys(E_COMPLEXITY_OPTIONS)
                .map((COMPLEXITY) => {
                    if (complicity.includes(E_COMPLEXITY_OPTIONS[COMPLEXITY as TQuestionDifficultyField])) {
                        return COMPLEXITY;
                    }
                })
                .filter((item) => item);
        }
        handleSearchButtonClick(searchFilters, selectedBlock);
        dispatch(setSelectedBlockAction(undefined));
        resetAllFilters();
    };

    const handleEditBlock = () => {
        dispatch(setSelectedBlockAction(undefined));
        resetAllFilters();
    };
    const handleDeleteBlockLocal = () => {
        if (selectedBlock) {
            handleDeleteBlock(selectedBlock);
        }
        dispatch(setSelectedBlockAction(undefined));
        resetAllFilters();
    };
    const resetAllFilters = () => {
        setTypes(questionTypeListNormalize);
        setComplicity(questionsComplicityNormalize);
        setDescription('');
        setSkillsUnionType('Все');
        setSkills([]);
        setTopics([]);
    };

    return (
        <div className={'question-search-bar'}>
            {isLoading ? (
                <AppSkeleton width={'100%'} height={'100%'} />
            ) : (
                <>
                    <div className={'question-search-bar__content'}>
                        <AppText text={'Поиск'} textStyle={'DesktopH2'} />
                        <div className={'question-search-bar__content_filters'}>
                            <AppTextareaInput
                                rows={1}
                                defaultValue={description}
                                style={{ maxHeight: 200, height: 70 }}
                                placeholder={'Название вопроса содержит'}
                                onChange={handleQuestionDescription}
                            />
                            <div className={'question-search-bar__content_filters-subject'}>
                                <AppText
                                    text={'Где искать'}
                                    textStyle={'DesktopFootNotes'}
                                    color={E_COLORS.light_grey_color_2}
                                />
                                <AppSelect
                                    options={subjectsTreeNormalize}
                                    placeholder={'Выберите предмет'}
                                    notFoundContent={'Ничего не найдено'}
                                    value={selectedSubject}
                                    showSearch
                                    loading={searchTreeStatus === FetchStatus.FETCHING}
                                    handleSelect={({ value }) => onSubjectSelect(+value)}
                                    filterOption={(input, option) =>
                                        (String(option?.label).toLowerCase() ?? '').includes(input.toLowerCase())
                                    }
                                />
                                <TreeSelector
                                    label={''}
                                    placeholder={'Выберите темы'}
                                    defaultValue={topics}
                                    notFoundContent={'Ничего не найдено'}
                                    treeData={topicsTreeNormalize}
                                    expandOnTreeTitles
                                    handleTreeSelect={(selectedTopics: string[]) =>
                                        onTreeSelect('topics', selectedTopics)
                                    }
                                />
                            </div>
                            <MultipleSelector
                                label={'Сложность'}
                                defaultValue={complicity}
                                placeholder={'Не выбрано'}
                                options={questionsComplicityNormalize}
                                handleMultipleSelect={(selectedComplicity: string[]) =>
                                    onMultipleSelect('complicity', selectedComplicity)
                                }
                            />
                            <MultipleSelector
                                label={'Допустимые типы'}
                                placeholder={'Не выбрано'}
                                defaultValue={types}
                                options={questionTypeListNormalize}
                                valueType={'count'}
                                handleMultipleSelect={(selectedTypes: string[]) =>
                                    onMultipleSelect('types', selectedTypes)
                                }
                            />
                            <TreeSelector
                                label={'Навыки'}
                                placeholder={'Выберите навыки'}
                                defaultValue={skills}
                                treeData={skillsTreeNormalize}
                                expandOnTreeTitles
                                handleTreeSelect={(selectedSkills: string[]) => onTreeSelect('skills', selectedSkills)}
                                isResponsivePosition
                                additionalContent={
                                    <AppSelect
                                        options={skillsUnionTypesNormalize}
                                        defaultValue={skillsUnionTypesNormalize[0]}
                                        handleSelect={({ value }) => onSkillsUnionTypeSelect(value as TQuestionSkill)}
                                    />
                                }
                            />
                        </div>
                    </div>
                    <div className={'question-search-bar__controls'}>
                        <div className={'question-search-bar__controls_save'}>
                            <AppButton
                                label={selectedBlock ? 'Редактировать поиск' : 'Подобрать вопрос'}
                                type={'primary'}
                                disabled={isSearchButtonDisabled}
                                onClick={handleSearchButtonClickLocal}
                            />
                            {!!selectedBlock && (
                                <div
                                    className={'question-search-bar__controls_delete'}
                                    onClick={handleDeleteBlockLocal}
                                >
                                    <AppFontIcon icon={'delete-m'} color={E_COLORS.coral_color} />
                                </div>
                            )}
                        </div>

                        <div
                            className={'question-search-bar__controls_create'}
                            onClick={selectedBlock ? handleEditBlock : handleCreateNewQuestion}
                        >
                            <AppText
                                text={selectedBlock ? 'отмена' : 'или создайте новый'}
                                color={E_COLORS.firm_blue_color}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};
export const QuestionSearchBarMemo = React.memo(QuestionSearchBar);

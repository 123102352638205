import { deleteApiRequest, getApiRequest, patchApiRequest, postApiRequest } from 'api';
import {
    IAssignmentItem,
    LessonDetails,
    TBlockParam,
    TCodifierSubjectData,
    TNewTaskData,
    TResponseQuestion,
    TResponseQuestionDetailed,
    TSearchBlockGet,
    TSubjectData,
    TTaskData,
    TUpdateLessonTaskResponse,
} from 'types/lessonTask';

export const getQuestionDetails = (id: number): Promise<TResponseQuestionDetailed> => {
    return getApiRequest(`/codifier/question/${id}/overview/`);
};
export const getLessonDetails = (id: number): Promise<LessonDetails> => {
    return getApiRequest(`/lesson/${id}/`);
};
export const getTaskData = async (id: number): Promise<IAssignmentItem> => {
    return getApiRequest(`/assignment/${id}`);
};

export const getAssignedTasks = async (id?: number): Promise<TSearchBlockGet[]> => {
    return getApiRequest(`/codifier/assignment-search-block/?assignment=${id}`);
};

export const getQuestionsByQuery = async (query: string): Promise<TResponseQuestion[]> => {
    return getApiRequest(`/codifier/question/${query}`);
};

export const updateLessonTask = async (taskData: TTaskData): Promise<TUpdateLessonTaskResponse> => {
    const { id, ...rest } = taskData;

    return patchApiRequest(`/assignment/partial-update/${id}/`, { ...rest });
};
export const createLessonTask = async (taskData: TNewTaskData): Promise<TUpdateLessonTaskResponse> => {
    return postApiRequest(`/assignment/create/`, {
        ...taskData,
        courseGroup: taskData?.isTemplate ? undefined : taskData?.courseGroup,
    });
};
export const createLessonTaskQuestion = async (blocks: TBlockParam[]): Promise<TBlockParam[]> => {
    return postApiRequest(`/codifier/assignment-search-block/bulk-create/`, blocks);
};
export const updateLessonTaskQuestion = async (blocks: TBlockParam & { id: number }[]): Promise<TSearchBlockGet[]> => {
    return patchApiRequest(`/codifier/assignment-search-block/bulk-update/`, blocks);
};
export const deleteLessonTaskQuestion = async (ids: number[]): Promise<void> => {
    const params = ids.map((id) => {
        return { id };
    });
    return deleteApiRequest(`/codifier/assignment-search-block/bulk-delete/`, { data: params });
};

export const getSubjectsData = async (): Promise<TSubjectData[]> => {
    return getApiRequest(`/codifier/subject/`);
};
export const getSubjectDataById = (subjectId: number): Promise<TCodifierSubjectData[]> => {
    return getApiRequest(`/codifier/tree/?subject=${subjectId}`);
};

import React from 'react';
import { Route } from 'react-router-dom';

export const PrivateRoute: React.FC<{ path: string | string[]; isExact?: boolean }> = ({ path, children, isExact }) => {
    return (
        <Route exact={isExact} path={path}>
            {children}
        </Route>
    );
};

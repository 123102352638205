import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { AppFontIcon } from '@frontend-modules/ui-kit';
import { MAX_FILE_SIZE_MB } from 'src-new/constants';
import { error } from 'store/reducers/error';

import { getMaxFileSizeBytes } from 'utils/getMaxFileSizeBytes';

import { TAppFileUploader } from './AppFileUploader.types';

import './style.scss';
export const AppFileUploader: React.FC<TAppFileUploader> = (props: TAppFileUploader) => {
    const { maxFileSize = MAX_FILE_SIZE_MB, isLoading = false, formats, handleFileUpload } = props;

    const fileInput = useRef<HTMLInputElement>(null);

    const dispatch = useDispatch();

    const maxFileSizeInBytes = getMaxFileSizeBytes(maxFileSize);

    const onAddFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target?.files?.[0];

        if (file) {
            const isFileSizeCorrect = file.size < maxFileSizeInBytes;
            if (isFileSizeCorrect) {
                handleFileUpload(file);
            } else {
                dispatch(
                    error({
                        message: `${file.name} слишком большой! Размер файла не должен превышать ${maxFileSize}МБ`,
                        extra: {
                            needHideButtons: true,
                            isFrontend: false,
                        },
                    }),
                );
            }
            e.target.value = '';
        }
    };

    return (
        <label className={`app-file-uploader ${isLoading ? 'disabled' : ''}`}>
            {isLoading ? (
                <div className={'app-file-uploader__loader rotating'}>
                    <AppFontIcon icon={'reboot-l'} color={'#0088E9'} />
                </div>
            ) : (
                <AppFontIcon icon={'plus-l'} color={'#0088E9'} />
            )}
            <input
                ref={fileInput}
                className={'app-file-uploader__input'}
                type="file"
                accept={formats}
                onChange={onAddFile}
            />
        </label>
    );
};

import React, { FC } from 'react';
import { cn } from '@bem-react/classname';
import { EmptyTrainerCard } from '@fsd-entities/materials';
import { ContentLoader } from '@fsd-shared/ui';
import { MethodistTrainerCard, StudentContent, TeacherTrainingList } from '@fsd-widgets/materials';
import { useAppSelector } from 'store/store';

import { ITrainerTabProps } from './TrainerTab.types';

import './TrainerTab.styles.scss';

const CnTrainerTab = cn('trainer-tab');

export const TrainerTab: FC<ITrainerTabProps> = (props) => {
    const { lessonId, courseGroupId, subjectId, subjectPageType } = props;
    const { roles } = useAppSelector((state) => state.newMaterials?.meta) || {};
    const { isStudent, isTeacher, isMethodist } = roles || {};
    const isShowLoading = roles === undefined;
    const isOld = subjectPageType === 'old';

    if (isShowLoading) {
        return (
            <div className={CnTrainerTab({ isOld })}>
                <ContentLoader height={400} />
            </div>
        );
    }

    return isOld ? (
        <div className={CnTrainerTab({ isOld })}>
            {isMethodist ? (
                <MethodistTrainerCard subjectId={subjectId} lessonId={lessonId} courseGroupId={courseGroupId} />
            ) : (
                <EmptyTrainerCard type={'noAccess'} />
            )}
        </div>
    ) : (
        <div className={CnTrainerTab()}>
            {isStudent && <StudentContent lessonId={lessonId} courseGroupId={courseGroupId} />}
            {isTeacher && <TeacherTrainingList lessonId={lessonId} courseGroupId={courseGroupId} />}
        </div>
    );
};

import { TaskProgressInterval } from 'containers/TaskBlock/TaskBlock.types';
import dayjs, { Dayjs } from 'dayjs';
import timezone from 'dayjs/plugin/timezone';

import { ITaskDate } from './Task';

interface IGetTimeDisableValueReturn {
    startHour?: number;
    endHour?: number;
    startMinute?: number;
    endMinute?: number;
    isEndSame?: boolean;
    isStartSame?: boolean;
}
export interface ITaskDateOptions {
    dateStartList: { label: string; value: EDateTimeTypesList }[];
    dateEndList: { label: string; value: EDateTimeTypesList }[];
    timeToComplete?: { label: string; value: EDateTimeTypesList }[];
}

export enum EDateTimeTypesList {
    DATE_TIME = 'DATE_TIME',
    TIME = 'TIME',
    NONE = '',
}
export const getTaskDateInitialValues = (onlyDatesOptions?: boolean): ITaskDateOptions => {
    if (onlyDatesOptions) {
        return {
            dateStartList: [{ label: 'Дата и время', value: EDateTimeTypesList.DATE_TIME }],
            dateEndList: [{ label: 'Дата и время', value: EDateTimeTypesList.DATE_TIME }],
        };
    }

    return {
        dateStartList: [
            { label: 'Точные дата и время', value: EDateTimeTypesList.DATE_TIME },
            { label: 'Выдать сейчас', value: EDateTimeTypesList.NONE },
            { label: 'По началу онлайн-занятия', value: EDateTimeTypesList.TIME },
        ],
        dateEndList: [
            { label: 'Точные дата и время', value: EDateTimeTypesList.DATE_TIME },
            { label: 'Через время после выдачи задания', value: EDateTimeTypesList.TIME },
        ],
        timeToComplete: [
            { label: 'Не ограничено', value: EDateTimeTypesList.NONE },
            { label: 'Выбрать время', value: EDateTimeTypesList.TIME },
        ],
    };
};

type Values = {
    timeStart?: string;
    timeEnd?: string;
    startTimeDelay?: number;
    endTimeDelay?: number;
    timeToComplete?: number;
};

type DateValidationError = {
    timeStart?: string;
    timeEnd?: string;
    startTimeDelay?: string;
    endTimeDelay?: string;
    timeToComplete?: string;
};

export const getTimeZoneOffsetHours = (): number => {
    return new Date().getTimezoneOffset() / 60 + 3;
};

export const dateValidator = (
    values: Values,
    interval?: TaskProgressInterval,
    calendarStartType?: EDateTimeTypesList,
    calendarDurationType?: EDateTimeTypesList,
): DateValidationError => {
    const { timeStart, timeEnd, endTimeDelay, timeToComplete, startTimeDelay } = values;
    const isStartNow = calendarStartType === EDateTimeTypesList.NONE;
    const { datetimeStart, datetimeEnd } = interval || {};
    const timeStartDJS = isStartNow ? dayjs() : dayjs(timeStart);
    const timeEndDJS = dayjs(timeEnd);
    const datetimeStartDJS = dayjs(datetimeStart);
    const datetimeEndDJS = dayjs(datetimeEnd);
    let dateStartValid = false;
    let dateEndValid = false;

    if (timeStart || isStartNow) {
        let isAfter = true;
        let isBetween = true;

        if (timeEnd) {
            isAfter = timeStartDJS?.isBefore(timeEndDJS, 'minute');
        }

        if (datetimeStart && datetimeEnd) {
            timeStartDJS?.isSame(timeEndDJS, 'minute');
            isBetween =
                (timeStartDJS.isAfter(datetimeStartDJS, 'minute') && timeStartDJS.isBefore(datetimeEndDJS, 'minute')) ||
                timeStartDJS.isSame(datetimeStartDJS, 'minute') ||
                timeStartDJS.isSame(datetimeEndDJS, 'minute');
        }

        dateStartValid = isAfter && isBetween;
    }

    if (timeEnd) {
        let isAfter = true;
        let isBetween = true;

        if (timeStart || isStartNow) {
            isAfter = timeEndDJS?.isAfter(timeStartDJS, 'minute');
        }

        if (datetimeStart && datetimeEnd) {
            isBetween =
                (timeEndDJS.isAfter(datetimeStartDJS, 'minute') && timeEndDJS.isBefore(datetimeEndDJS, 'minute')) ||
                timeEndDJS.isSame(datetimeStartDJS, 'minute') ||
                timeEndDJS.isSame(datetimeEndDJS, 'minute');
        }

        dateEndValid = isAfter && isBetween;
    }

    return {
        timeStart: dateStartValid ? undefined : 'error',
        timeEnd: dateEndValid ? undefined : 'error',
        endTimeDelay: endTimeDelay ? undefined : 'error',
        startTimeDelay:
            calendarStartType === EDateTimeTypesList.TIME && startTimeDelay !== undefined ? undefined : 'error',
        timeToComplete: calendarDurationType !== EDateTimeTypesList.NONE && timeToComplete ? undefined : 'error',
    };
};

/**
 * @description определяем нужно ли дизейблить текущую дату
 * @param date
 * @param interval
 * @return {boolean}
 */
export const disabledDate = (date: Dayjs, interval?: { datetimeStart?: string; datetimeEnd?: string }): boolean => {
    dayjs.extend(timezone);

    const { datetimeStart, datetimeEnd } = interval || {};
    let intervalStart = false;
    let intervalEnd = false;

    const currDate = dayjs().tz('Europe/Moscow');
    const start = dayjs(datetimeStart).tz('Europe/Moscow');
    const end = dayjs(datetimeEnd).tz('Europe/Moscow');

    const past = date.isBefore(currDate.format('YYYY-MM-DD'), 'day');

    if (datetimeStart) {
        intervalStart = date.isAfter(start.format('YYYY-MM-DD'), 'day');
    }

    if (datetimeEnd) {
        intervalEnd = date.isBefore(end.format('YYYY-MM-DD'), 'day');
    }
    return past || intervalEnd || intervalStart;
};

/**
 * @description определяем перечень часов которые нужно задизейблить
 *              в зависимости от даты
 * @param date
 * @param value
 */
export const disabledHours = (date?: string, value?: IGetTimeDisableValueReturn): Array<number | never> => {
    const { startHour, endHour, isStartSame, isEndSame } = value || {};
    const array: Array<number | never> = [];

    if (date === undefined) {
        for (let i = 0; i <= 24; i++) {
            array.push(i);
        }
    }
    if (isStartSame) {
        for (let i = 0; i < (startHour ?? 0); i++) {
            array.push(i);
        }
    }
    if (isEndSame) {
        for (let j = (endHour ?? 24) + 1; j <= 24; j++) {
            array.push(j);
        }
    }

    return array;
};

/**
 * @description определяем перечень минут которые нужно задизейблить
 *              в зависимости от часа и даты
 * @param hour
 * @param date
 * @param value
 */
export const disabledMinutes = (
    hour: number,
    date?: string,
    value?: IGetTimeDisableValueReturn,
): Array<number | never> => {
    const { startHour, endHour, startMinute, endMinute, isStartSame, isEndSame } = value || {};
    const array: Array<number | never> = [];

    if (date === undefined) {
        for (let i = 0; i <= 60; i += 10) {
            array.push(i);
        }
    }
    if (hour == startHour) {
        if (isStartSame) {
            for (let i = 0; i < (startMinute ?? 0); i += 10) {
                array.push(i);
            }
        }
    }
    if (hour == endHour) {
        if (isEndSame) {
            const minutes = endMinute !== undefined ? endMinute + 10 : 70;
            for (let j = minutes; j <= 60; j += 10) {
                array.push(j);
            }
        }
    }
    return array;
};

/**
 * @description подготавливаем даты для календаря
 *              для сравнения и получения допустимых дат
 * @param date
 * @param interval
 */
export const getTimeDisableValue = (date?: Dayjs, interval?: TaskProgressInterval): IGetTimeDisableValueReturn => {
    const { datetimeStart, datetimeEnd } = interval || {};
    dayjs.extend(timezone);
    const start = dayjs(datetimeStart).tz('Europe/Moscow');
    const end = dayjs(datetimeEnd).tz('Europe/Moscow');
    const isStartSame = date?.isSame(start.format('YYYY-MM-DD'), 'day');
    const isEndSame = date?.isSame(end.format('YYYY-MM-DD'), 'day');

    let startHour;
    let startMinute;
    let endHour;
    let endMinute;

    if (datetimeStart) {
        startHour = start.hour();
        startMinute = start.minute();
    }

    if (datetimeEnd) {
        endHour = end.hour();
        endMinute = end.minute();
    }

    return { startHour, startMinute, endMinute, endHour, isEndSame, isStartSame };
};

import { patchApiRequest } from 'api';
import { ResponseEducationalPlanItem } from 'types/educationalPlan';

import { PostEducationalPlanItem } from './postEducationalPlan';

export interface PatchItemParams {
    educationalPlanItems: PostEducationalPlanItem[];
}

export const editEducationalPlanItem = (params: PatchItemParams): Promise<ResponseEducationalPlanItem[]> => {
    return patchApiRequest(`/educational-plan-item/bulk-update/`, params.educationalPlanItems);
};

import { E_COLORS } from 'src-new/constants';

export const baseChartOptions = {
    legend: 'none',
    chartArea: { width: '90%', height: '90%', top: 5 },
    focusTarget: 'category',
    vAxis: {
        gridlines: {
            color: E_COLORS.light_grey_color_3,
        },
        baselineColor: E_COLORS.light_grey_color_3,
        textStyle: {
            color: E_COLORS.light_grey_color_1,
            fontSize: 13,
        },
    },
    hAxis: {
        gridlines: {
            color: E_COLORS.light_grey_color_3,
        },
        baselineColor: E_COLORS.light_grey_color_3,
        textStyle: {
            color: E_COLORS.light_grey_color_1,
            fontSize: 13,
        },
    },
    tooltip: {
        isHtml: true,
        trigger: 'focus',
        ignoreBounds: true,
    },
};

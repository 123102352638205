import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { Task } from '@lms-elements/icons';
import i18next from 'i18next';

import './HomeworkStatus.scss';

const CnHomeworkStatus = cn('homeworkStatus');

export interface HomeworkStatusProps {
    id: number;
    status: 'auto' | 'notAuto' | 'homework' | 'notHomework';
    autoIssue: boolean;
    autoIssueTime: string;
    autoIssuetasks: number;
    autoIssueSetUp: boolean;
    givenHometaskTitle: string;
    autoIssuetotalTasks: number;
    passOffTasks: number;
    toCheckTasks: number;
    withoutBorder?: boolean;
    isStudent?: boolean;
}

export const HomeworkStatus: React.FC<HomeworkStatusProps> = ({
    id,
    status,
    autoIssue,
    autoIssueSetUp,
    autoIssuetotalTasks,
    autoIssuetasks,
    givenHometaskTitle,
    passOffTasks,
    toCheckTasks,
    withoutBorder,
    isStudent,
}) => {
    const title = useMemo(() => autoIssue || !givenHometaskTitle, [autoIssue, givenHometaskTitle]);

    const titleInfo = useMemo(() => {
        if (isStudent && !autoIssue) {
            return 'нет заданий';
        }
        if (isStudent && autoIssueSetUp) {
            return `Автовыдача ${i18next.t('assignedTask', { count: autoIssuetasks })}`;
        }
        if (autoIssue && !autoIssueSetUp) {
            return 'Автовыдача не настроена';
        }
        if (autoIssue && autoIssueSetUp) {
            return `Автовыдача для ${autoIssuetasks} из ${autoIssuetotalTasks} заданий`;
        }

        return 'ДЗ не было выдано';
    }, [autoIssuetasks, autoIssueSetUp, autoIssuetotalTasks, autoIssue, isStudent]);

    return (
        <div key={id} className={CnHomeworkStatus({ status, withoutBorder })}>
            <div className={CnHomeworkStatus('icon', { status, withoutBorder })}>
                <Task />
            </div>
            <div className={CnHomeworkStatus('info')}>
                {title && <div className={CnHomeworkStatus('title', { status, withoutBorder })}>{titleInfo}</div>}
                {Boolean(givenHometaskTitle) && (
                    <ul className={CnHomeworkStatus('hometask')}>
                        <li>{`задано: ${givenHometaskTitle}`}</li>
                        {Boolean(passOffTasks) && <li>{`сдано: ${i18next.t('task', { count: passOffTasks })}`}</li>}
                        {Boolean(toCheckTasks) && !isStudent && (
                            <li>{`к проверке: ${i18next.t('task', { count: toCheckTasks })}`}</li>
                        )}
                    </ul>
                )}
            </div>
        </div>
    );
};

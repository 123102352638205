import { createAsyncThunk } from '@reduxjs/toolkit';
import { loadMetaCatalogRequest, loadMetaRequest } from 'api/services/meta';
import { normalizeCatalog, normalizeMetaOptionsList } from 'store/normalizers/meta';
import { IMetaCatalogRequestPayload, IMetaMoreCatalogRequestPayload } from 'types/meta';

export const loadMetaAction = createAsyncThunk('meta/loadMeta', async () => {
    const response = await loadMetaRequest();
    return normalizeMetaOptionsList(response);
});

export const loadMetaCatalogAction = createAsyncThunk(
    'meta/loadMetaCatalog',
    async (payload: IMetaCatalogRequestPayload) => {
        const { catalogData, searchValue } = payload;
        const requestConfig = {
            url: catalogData?.url,
            params: {
                [catalogData?.query]: searchValue,
                limit: catalogData?.limit,
                currentPage: 1,
            },
        };
        const response = await loadMetaCatalogRequest(requestConfig);

        const normalizedRes = normalizeCatalog(response.results, catalogData?.url);
        return {
            url: catalogData?.url,
            list: normalizedRes,
            pagination: response.pagination,
        };
    },
);
export const loadMoreMetaCatalogAction = createAsyncThunk(
    'meta/loadMoreMetaCatalog',
    async (payload: IMetaMoreCatalogRequestPayload) => {
        const { catalogData, searchValue, page } = payload;
        const requestConfig = {
            url: catalogData?.url,
            params: {
                [catalogData?.query]: searchValue,
                currentPage: page,
                limit: catalogData?.limit,
            },
        };
        const response = await loadMetaCatalogRequest(requestConfig);

        const normalizedRes = normalizeCatalog(response.results, catalogData?.url);
        return {
            url: catalogData?.url,
            list: normalizedRes,
            pagination: response.pagination,
        };
    },
);

import React, { useCallback, useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Avatar } from '@lms-elements/atomic';
import { PatchTeacherMarkParams, PostAssignmentMark } from 'api/services/marks';

import { IStatusCardProps, StatusCard } from 'components/StatusCard';

import './StudentTab.scss';

const CnStudentTab = cn('studentTab');

interface IStudentTabProps {
    name: string;
    source: string;
    tabs: IStatusCardProps[];
    selectedTask: number;
    isAdaptation?: boolean;
    isOnline?: boolean;
    onTabChange?: (id: number) => void;
    onStudentClick?: () => void;
    onAcademicPerfomance?: () => void;
    onMarkDelete?: (markId: number, attemptMarkNumber?: number, teacherMark?: boolean) => void;
    onMarkChange?: (
        changeMarksOptions: (PatchTeacherMarkParams & {
            valueCanChange?: boolean;
            teacherMark?: boolean;
            attemptMarkNumber?: number;
        })[],
    ) => void;
    onMarkAdd?: (addMarksOptions: PostAssignmentMark[]) => void;
    onCancel?: () => void;
}

export const StudentTab: React.FC<IStudentTabProps> = ({
    name,
    tabs,
    selectedTask,
    onTabChange,
    source,
    onStudentClick,
    onAcademicPerfomance,
    onMarkDelete,
    onMarkAdd,
    onMarkChange,
    isAdaptation,
    isOnline,
    onCancel,
}) => {
    const [selected, setSelected] = useState(selectedTask);
    const handleCardClick = useCallback(
        (id: number) => {
            setSelected((prev) => {
                if (prev === id) {
                    return prev;
                }

                onTabChange?.(id);

                return id;
            });
        },
        [onTabChange],
    );

    return (
        <div className={CnStudentTab()}>
            <div className={CnStudentTab('student')}>
                <div className={CnStudentTab('title')}>Ученик</div>
                <Avatar
                    source={source}
                    alt={'Фото'}
                    name={name}
                    onAvatarClick={onStudentClick}
                    isOnAdaptation={isAdaptation}
                    isOnline={isOnline}
                />
            </div>
            <div className={CnStudentTab('tabs')} id="scroll-bar">
                {tabs.map((item) => (
                    <div key={item.id} className={CnStudentTab('tab')}>
                        <StatusCard
                            {...item}
                            isSelected={item.id === selected}
                            onCardClick={handleCardClick}
                            onMarkDelete={onMarkDelete}
                            onMarkAdd={onMarkAdd}
                            onMarkChange={onMarkChange}
                            onCancel={onCancel}
                            onAcademicPerfomance={onAcademicPerfomance}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

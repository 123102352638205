import { StudentsStatisticsTariff } from 'api/services/student';
import { StudentGroupStatistics } from 'api/services/studentGroups/getStudentsGroupStatistics';
import { classLetterAndNumberRegExp } from 'containers/ActiveClasses/ActiveClasses.constants';
import { sortGroupParallels, sortGroupTitles } from 'containers/ActiveClasses/utils';
import moment from 'moment';

interface GridCellsParams {
    studentsStatisticsTariff: StudentsStatisticsTariff[];
    studentGroupStatistics: StudentGroupStatistics[];
}

export const normalizeGridCells = ({
    studentGroupStatistics,
    studentsStatisticsTariff,
}: GridCellsParams): (StudentGroupStatistics & { tariff: string })[][] => {
    const grid = new Map<string, (StudentGroupStatistics & { tariff: string })[]>();
    studentGroupStatistics.map((group) => {
        const title = (classLetterAndNumberRegExp.exec(group.title) ?? [''])[0];
        if (grid.has(title)) {
            const newCells = (grid.get(title) ?? []).concat({
                ...group,
                tariff: studentsStatisticsTariff.find(({ id }) => id === group.tariff)?.title ?? '-',
                dateCreate: moment(group.dateCreate).format('DD.MM.YYYY'),
            } as StudentGroupStatistics & { tariff: string });
            grid.set(title, newCells);
        } else {
            grid.set(title, [
                {
                    ...group,
                    tariff: studentsStatisticsTariff.find(({ id }) => id === group.tariff)?.title ?? '-',
                    dateCreate: moment(group.dateCreate).format('DD.MM.YYYY'),
                } as StudentGroupStatistics & { tariff: string },
            ]);
        }
    });
    return Array.from(grid, ([title, group]) => ({ title, group }))
        .sort(sortGroupTitles)
        .map(({ group }) => {
            return group.sort(sortGroupParallels);
        });
};

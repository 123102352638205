import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { createFile, fetchDeleteFiles, patchFile } from 'api/services/file';
import { handleLesson } from 'api/services/lesson';
import {
    checkExerciseAnswer,
    deleteMaterial,
    ExerciseAnswer,
    getExercisesCorrectAnswers,
    getMaterialFile,
    getMaterials,
    IMaterialFile,
    IPostMaterial,
    IPostMaterialResponse,
    IUploadMaterialParams,
    LearningMaterialsContent,
    patchMaterial,
    patchMaterialsOrdering,
    postMaterial,
    TMaterialType,
} from 'api/services/materials';
import { getQuestions, ResponseQuestion } from 'api/services/questionBank';
import { FileTypes, IFile, IUploadedFile } from 'types/file';
import { IMaterial } from 'types/materials';

import { createUrlQuery } from 'utils/createUrlQuery';
import { getExerciseQuestions } from 'utils/materials';

export const createMaterialAction = createAsyncThunk(
    'materials/createMaterial',
    async (value: { materials: IUploadMaterialParams[]; lessonId: number; tempFiles?: IUploadedFile[] }) => {
        return await Promise.all(value.materials.map((value) => postMaterial(value)))
            .then((data) => {
                if (value.tempFiles?.length) {
                    Promise.all(
                        value.tempFiles.map((file) => {
                            const materialIndex = value.materials.findIndex(
                                (material) => material.id === file.materialId,
                            );

                            if (materialIndex >= 0) {
                                return patchFile({
                                    id: file.id,
                                    materialId: data[materialIndex].id,
                                });
                            }
                        }),
                    ).catch((res) => console.error(res));
                }

                return data;
            })
            .then((data) =>
                patchMaterialsOrdering(
                    value.lessonId,
                    data.map((material) => material.id),
                ),
            );
    },
);

export const deleteMaterialAction = createAsyncThunk(
    'materials/deleteMaterials',
    async ({ materialIds, filesForDelete }: { materialIds: number[]; filesForDelete: number[] }) => {
        return await Promise.all(materialIds.map((id, index) => deleteMaterial(id, index === 0 ? filesForDelete : [])));
    },
);

export const patchMaterialAction = createAsyncThunk(
    'materials/patchMaterials',
    async (value: {
        materials: {
            id: number;
            value: IPostMaterial;
            filesForPatch: number[];
            files?: File[];
            filesForDelete?: number[];
        }[];
        tempFiles?: IUploadedFile[];
    }) => {
        return await Promise.all(
            value.materials.map((material) =>
                patchMaterial(
                    material.id,
                    material.value,
                    material.filesForPatch,
                    material.files,
                    material.filesForDelete,
                ),
            ),
        ).then((data) => {
            if (value.tempFiles?.length) {
                Promise.all(
                    value.tempFiles.map((file) => {
                        const materialIndex = value.materials.findIndex((material) => material.id === file.materialId);
                        if (materialIndex >= 0) {
                            return patchFile({
                                id: file.id,
                                materialId: data[materialIndex].id,
                            });
                        }
                    }),
                ).catch((res) => console.error(res));
            }

            return data;
        });
    },
);

export const postMaterialWithOrderingAction = createAsyncThunk(
    'materials/postMaterialsWithOrdering',
    async (value: {
        materials: IUploadMaterialParams[];
        ordering: number[];
        replacedIds: number[];
        lessonId: number;
        tempFiles?: IUploadedFile[];
    }) => {
        return await Promise.all(value.materials.map((value) => postMaterial(value)))
            .then((data) => data.map((value) => value.id))
            .then((ids) => {
                if (value.tempFiles?.length) {
                    Promise.all(
                        value.tempFiles.map((file) => {
                            const materialIndex = value.materials.findIndex(
                                (material) => material.id === file.materialId,
                            );

                            if (materialIndex >= 0) {
                                return patchFile({
                                    id: file.id,
                                    materialId: ids[materialIndex],
                                });
                            }
                        }),
                    ).catch((res) => console.error(res));
                }

                return ids;
            })
            .then((ids) => {
                const newOrdering = value.ordering.map((id) =>
                    value.replacedIds.includes(id) ? ids[value.replacedIds.indexOf(id)] : id,
                );

                return patchMaterialsOrdering(value.lessonId, newOrdering);
            });
    },
);

export const patchMaterialWithOrderingAction = createAsyncThunk(
    'materials/patchMaterialsWithOrdering',
    async (value: {
        materials: { id: number; value: IPostMaterial; filesForPatch: number[] }[];
        ordering: number[];
        lessonId: number;
        tempFiles?: IUploadedFile[];
    }) => {
        const arrayPromises: Promise<IPostMaterialResponse>[] = [];
        value.materials.forEach((value) => {
            arrayPromises.push(patchMaterial(value.id, value.value, value.filesForPatch));
        });
        return await Promise.all(arrayPromises)
            .then((data) => {
                if (value.tempFiles?.length) {
                    Promise.all(
                        value.tempFiles.map((file) => {
                            const materialIndex = value.materials.findIndex(
                                (material) => material.id === file.materialId,
                            );
                            if (materialIndex >= 0) {
                                return patchFile({
                                    id: file.id,
                                    materialId: data[materialIndex].id,
                                });
                            }
                        }),
                    ).catch((res) => console.error(res));
                }

                return data;
            })
            .then(() => patchMaterialsOrdering(value.lessonId, value.ordering));
    },
);

export const postMaterialFileAction = createAsyncThunk('materials/postFile', async (file: File) => {
    return await createFile({
        file,
        type: FileTypes.COMMON,
    });
});

export const deleteMaterialFileAction = createAsyncThunk('materials/deleteFile', async (file: number) => {
    return await fetchDeleteFiles(file).then(() => ({ id: file }));
});

export const clearMaterialsFilesAction = createAsyncThunk('materials/clearFiles', async (files: number[]) => {
    return await Promise.all(files.map((file) => fetchDeleteFiles(file)));
});

export const resetPostMaterialFileStatusAction = createAction('materials/resetPostMaterialFileStatus', () => {
    return {
        payload: null,
    };
});
export const resetLastCheckedAnswerAction = createAction('materials/resetLastCheckedAnswer', () => {
    return {
        payload: null,
    };
});

export const resetMaterialsStatusesAction = createAction('materials/resetMaterialsStatuses', () => {
    return {
        payload: null,
    };
});

export interface FileForFilset {
    file: IFile;
    material: number;
}

const extensionRegExp = /([0-9a-zA-Z]+)$/;

export const createTempMaterials = createAsyncThunk(
    'materials/createTempMaterials',
    async (params: {
        initialId: number;
        isEditPage: boolean;
        formValues: IMaterial[];
        lessonId: number;
        subjectId: number;
        exerciseMaterialIndex?: number;
        exerciseMaterialId?: number;
        courseGroupId?: number;
        materials: (IPostMaterial & { isDeleted: boolean })[];
        materialsFiles: {
            materialIndex: number;
            file: File;
        }[];
    }) => {
        const { formValues, materials, materialsFiles, ...restParams } = params;

        const files = materialsFiles.map(({ file }) => file);

        return await Promise.all(
            files.map((file) =>
                createFile({
                    file,
                    type: FileTypes.COMMON,
                }),
            ),
        ).then((res) => {
            const fileMap = materialsFiles
                .map((materialFiles, index) => {
                    if (res[index]) {
                        return {
                            ...materialFiles,
                            uploadedFile: {
                                ...res[index],
                                name: materialFiles.file.name,
                            },
                        };
                    }
                })
                .reduce((result, materialFiles) => {
                    if (materialFiles && result[materialFiles.materialIndex]) {
                        result[materialFiles.materialIndex].push(materialFiles.uploadedFile);

                        return result;
                    }

                    if (materialFiles) {
                        result[materialFiles.materialIndex] = [materialFiles.uploadedFile];
                    }

                    return result;
                }, {} as Record<number, (IUploadedFile & { name: string })[]>);

            const filesForTempFileset: FileForFilset[] = materialsFiles.map((materialFile, index) => {
                const fileExtension = `.${
                    extensionRegExp.exec(materialFile.file.name)?.[0] || materialFile.file.type.split('/')[1]
                }`;
                const title = materialFile.file.name.replace(fileExtension, `_${fileExtension}`);

                return {
                    file: {
                        title,
                        fileExtension,
                        id: res[index].id,
                        fileUrl: res[index].file,
                        isAvailableToStudents: true,
                        shouldUseOnConference: true,
                    },
                    material: formValues[materialFile.materialIndex].id,
                };
            });

            return {
                ...restParams,
                materials: materials.map((material, index) => {
                    return {
                        ...material,
                        id: formValues[index].id,
                        files: fileMap[index]?.map((file) => ({
                            ...file,
                            materialId: formValues[index].id,
                        })),
                    };
                }),
                filesForTempFileset,
            };
        });
    },
);

export const checkExerciseAnswerAction = createAsyncThunk(
    'materials/checkExerciseAnswer',
    async (params: ExerciseAnswer) => {
        return await checkExerciseAnswer(params).then((answerData) => ({
            materialId: params.material,
            data: answerData,
            answers: params?.data,
            isCorrect: answerData.isCorrect,
            questionId: params.questionId,
        }));
    },
);

export const getExercisesCorrectAnswersAction = createAsyncThunk(
    'materials/getExercisesCorrectAnswers',
    async (lessonId: number) => await getExercisesCorrectAnswers(lessonId),
);

type BlockEditMaterialsParams = {
    lessonId: number;
    isBlockEdit: boolean;
};

export const blockEditMaterialsAction = createAsyncThunk(
    'materials/blockEditMaterials',
    ({ lessonId, isBlockEdit }: BlockEditMaterialsParams) => {
        return handleLesson({
            update: {
                id: lessonId,
                isBlockEdit: isBlockEdit,
            },
        });
    },
);

type GetMaterialDataActionParams = {
    lessonId: number;
    materialType: TMaterialType;
    isMaterialCreationPage?: boolean;
};

type GetMaterialsDataParams = {
    lessonId: number;
    materials: LearningMaterialsContent[];
    files: IMaterialFile[];
};

type MaterialsCreationPageData = {
    lessonId: number;
    files: IMaterialFile[];
    questions: ResponseQuestion[];
    materials: LearningMaterialsContent[];
};

const getMaterialsCreationPageData = async ({
    lessonId,
    materials,
    files,
}: GetMaterialsDataParams): Promise<MaterialsCreationPageData> => {
    const exerciseQuestionsIds = getExerciseQuestions(materials);
    let questions: ResponseQuestion[] = [];
    if (exerciseQuestionsIds.length) {
        questions = await getQuestions(createUrlQuery({ include_ids: exerciseQuestionsIds }));
    }

    return {
        lessonId,
        files,
        questions,
        materials: materials,
    };
};

type MaterialsTabData = {
    lessonId: number;
    files: IMaterialFile[];
    materials: LearningMaterialsContent[];
    questions: ResponseQuestion[];
};

const getMaterialsTabData = async ({
    lessonId,
    materials,
    files,
}: GetMaterialsDataParams): Promise<MaterialsTabData> => {
    const exerciseQuestionsIds = getExerciseQuestions(materials);
    let questions: ResponseQuestion[] = [];
    if (exerciseQuestionsIds.length) {
        questions = await getQuestions(createUrlQuery({ include_ids: exerciseQuestionsIds }));
    }

    return {
        lessonId,
        files,
        materials,
        questions,
    };
};

export const getMaterialsDataAction = createAsyncThunk(
    'materials/getMaterialsData',
    ({ lessonId, materialType, isMaterialCreationPage }: GetMaterialDataActionParams) => {
        return Promise.all([getMaterials(lessonId, materialType), getMaterialFile({ lesson: lessonId })]).then(
            async ([materials, files]) => {
                if (isMaterialCreationPage) {
                    return getMaterialsCreationPageData({
                        lessonId,
                        materials,
                        files,
                    });
                }

                return getMaterialsTabData({
                    lessonId,
                    materials,
                    files,
                });
            },
        );
    },
);

export const addNewQuestionForExerciseMaterialAction = createAsyncThunk(
    'materials/addNewQuestionForExerciseMaterial',
    (questionId: number) => {
        return getQuestions(createUrlQuery({ include_ids: [questionId] }));
    },
);

import { CuratorDashboard } from 'api/services/curatorDashboard';
import { AbsentStudent } from 'types/students';

export const getAbsentStudentList = (curatorDashboard: CuratorDashboard[]): AbsentStudent[] => {
    const students = curatorDashboard.map((item) => {
        const usersId = item.usersInMeeting.map((user) => user.id);
        return item.courseGroup.students.filter((student) => !usersId.includes(student.id));
    });
    return students.flat();
};

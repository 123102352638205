import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useCourseGroupQuery = (): { courseGroupId?: number } => {
    const location = useLocation();
    const groupId = useMemo(() => {
        const group = new URLSearchParams(location.search).get('course_group');
        const groupId = Number(group);

        if (!group || isNaN(groupId)) {
            return undefined;
        }

        return groupId;
    }, [location]);

    return { courseGroupId: groupId };
};

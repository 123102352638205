import React from 'react';
import { cn } from '@bem-react/classname';
import { ConfigProvider, Radio } from 'antd';
import { ThemeConfig } from 'antd/lib/config-provider/context';
import { AppText } from 'src-new/ui';

import { IAppRadio } from './AppRadio.types';
const CnAppRadio = cn('app-radio');

import './AppRadio.scss';

const theme: ThemeConfig = {
    token: {
        colorPrimary: '0088E9',
    },
};
const AppRadio: React.FC<IAppRadio> = (props) => {
    const { item } = props;
    const { label, value } = item || {};

    return (
        <ConfigProvider theme={theme}>
            <Radio prefixCls={CnAppRadio()} value={value}>
                <AppText text={label ?? ''} fontStyle={'fontBody'} />
            </Radio>
        </ConfigProvider>
    );
};

export default AppRadio;

import { useHistory, useLocation } from 'react-router-dom';
import { parseQueryParamsToObject } from 'src-new/utils';
type TUseQueryPopupsParams = {
    openPopup: (modal: { [key: string]: string | number | boolean }) => void;
    closeLastPopup: () => void;
    replaceLastPopup: (modal: { [key: string]: string | number | boolean }) => void;
    isCurrentPopup: (type: string) => boolean;
};
type TModal = { [key: string]: string | boolean | number };
const useQueryPopupsParams = (): TUseQueryPopupsParams => {
    const history = useHistory();
    const MAX_COUNT_MODALS = 4;

    /**
     * @description - Обновляет список модальных окон в URL. Автоматически устанавливает ранжирование (layer)
     * @param updatedModalList - Новый список модальных окон.
     * @param isReplaceHistory
     */
    const updateModalListInUrl = (updatedModalList: TModal[], isReplaceHistory?: boolean) => {
        for (let i = 0; i < updatedModalList.length; i++) {
            updatedModalList[i]['layer'] = updatedModalList.length - i;
        }
        const url = new URL(window.location.href);
        url.searchParams.set('modals', JSON.stringify(updatedModalList));
        isReplaceHistory
            ? history.replace({ pathname: history.location.pathname, search: url.search })
            : history.push({ pathname: history.location.pathname, search: url.search });
    };

    /**
     * @description - устанавливаем в URL параметр modals
     */
    const openPopup = (modal: TModal) => {
        const locationSearch = history.location.search;
        const parsedQueries = parseQueryParamsToObject(locationSearch, 'modals');

        if (parsedQueries.modals) {
            let newModalList: TModal[] = [...parsedQueries?.modals, modal];
            const formattedModalList: Array<TModal> = [...newModalList];
            for (let i = 0; i < formattedModalList.length - MAX_COUNT_MODALS; i++) {
                formattedModalList[i]['isHide'] = true;
            }
            newModalList = [...formattedModalList];
            updateModalListInUrl(newModalList);
        }
    };

    /**
     * @description - закрываем последнюю открытую модалку, очищая ее из URL
     */
    const closeLastPopup = () => {
        const locationSearch = history.location.search;
        const parsedQueries = parseQueryParamsToObject(locationSearch, 'modals');
        const modalList = parsedQueries.modals as TModal[];
        let newModalList: Array<TModal> = modalList.filter((modal, index) => index !== modalList.length - 1);
        const formattedModalList: Array<TModal> = [...newModalList];

        if (formattedModalList.length > 0) {
            let indexToOpen = formattedModalList.findLastIndex((modal) => modal['isHide']);
            if (indexToOpen === -1) indexToOpen = formattedModalList.length - 1;
            formattedModalList[indexToOpen]['isHide'] = false;
        }
        newModalList = [...formattedModalList];
        updateModalListInUrl(newModalList, true);
    };

    /**
     * @description - заменяем последнюю открытую модалку
     */
    const replaceLastPopup = (modal: TModal) => {
        const locationSearch = history.location.search;
        const parsedQueries = parseQueryParamsToObject(locationSearch, 'modals');
        const modalList = parsedQueries.modals as TModal[];
        const newModalList = modalList.filter((modal, index) => index !== modalList.length - 1);
        const formattedModalList = [...newModalList, modal];

        updateModalListInUrl(formattedModalList);
    };
    /**
     * @description - проверяем по типу, является ли модалка самой верхней
     */
    const isCurrentPopup = (type: string): boolean => {
        const locationSearch = history.location.search;
        const parsedQueries = parseQueryParamsToObject(locationSearch, 'modals');
        const modalTypesList = parsedQueries.modals?.map(({ type }) => type);

        return modalTypesList?.[modalTypesList?.length - 1] === type;
    };

    return {
        openPopup,
        closeLastPopup,
        replaceLastPopup,
        isCurrentPopup,
    };
};

export default useQueryPopupsParams;

import React, { FC } from 'react';
import { Drawer } from 'antd';

import { IAppDrawerProps } from './AppDrawer.types';

/**
 * @description - компонент модалка "сбоку"
 * @param props
 * @constructor
 */
const AppDrawer: FC<IAppDrawerProps> = (props) => {
    return <Drawer {...props} />;
};

export default AppDrawer;

import { isListExist } from '@frontend-modules/frontend-utils';
import { setMessage } from 'src-new/utils';

export const showErrorToast = (errors: string | Array<string>): void => {
    if (errors) {
        const allErrors = [];
        for (const error of errors) {
            if (Array.isArray(error)) {
                allErrors.push(...error);
            } else {
                allErrors.push(error);
            }
        }
        if (isListExist(allErrors)) {
            const config = {
                status: 'error' as const,
                text: allErrors.join(',\n'),
                key: allErrors.join(',\n'),
                duration: 5,
            };
            setMessage(config);
        }
    }
};

import dayjs, { Dayjs } from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { IAppTableItems, IAppTableRow } from 'src-new/ui/table/app-table/AppTable.types';
import {
    deleteEmptyParams,
    formatDayjsToTableDate,
    formatJSToTableDate,
    formatTableDateToDayjs,
    getTableDateToParamsDate,
    IAdditionalDaysDayJs,
} from 'src-new/utils';
import { eduWeeksType, IPeriod, IUpdateCalendarPlanPeriodParams, periodsType } from 'types/calendarPlan';

import { getCountOfDaysAndWeek, getDaysJsArray } from './calendarTableActions';
dayjs.extend(minMax);

const periodHeaderRow: IAppTableRow = [
    { title: 'Отчетный период' },
    { title: 'Даты начала', subtitle: '(задается вручную)' },
    { title: 'Даты окончания', subtitle: '(задается вручную)' },
    { title: 'Количество учебных дней' },
    { title: 'Количество учебных недель', subtitle: '(задается вручную)' },
];

/**
 * @description формируем последнюю строку для таблицы периодов обучения
 * @param {string} yearDateStart
 * @param {string} yearDateEnd
 * @param {number} yearDaysCount
 * @param {number} yearWeeksCount
 * @return IAppTableRow
 */
const getPeriodFooterRow = (
    yearDateStart?: string,
    yearDateEnd?: string,
    yearDaysCount?: number,
    yearWeeksCount?: number,
): IAppTableRow => {
    return [
        { title: 'Весь учебный год', isFill: true, isBoldTitle: true },
        { title: yearDateStart ? formatJSToTableDate(yearDateStart) : '-', isFill: true, isBoldTitle: true },
        { title: yearDateEnd ? formatJSToTableDate(yearDateEnd) : '-', isFill: true, isBoldTitle: true },
        { title: yearDaysCount ?? '-', isFill: true, isBoldTitle: true },
        { title: yearWeeksCount ?? '-', isFill: true, isBoldTitle: true },
    ];
};

/**
 * @description получаем количество строку таблицы
 * в зависимости от типа уч периода
 * @param {periodsType} periodType
 * @return number
 */
const getRowCount = (periodType?: periodsType): number => {
    switch (periodType) {
        case 'year':
            return 1;
        case 'trimester':
            return 3;
        case 'quarter':
            return 4;
        default:
            return 2;
    }
};

/**
 * @description получаем заголовок строки
 * в зависимости от типа уч периода
 * @param {number} index
 * @param {periodsType} periodType
 * @param {string} title
 * @return string
 */
const getPeriodTitle = (index: number, periodType?: periodsType, title?: string): string => {
    if (title) {
        return title;
    }
    const number = index + 1;
    switch (periodType) {
        case 'year':
            return `${number} год`;
        case 'trimester':
            return `${number} триместр`;
        case 'quarter':
            return `${number} четверть`;
        default:
            return `${number} семестр`;
    }
};

/**
 * @description формируем строку для таблицы периодов обучения
 * @param {IPeriod} period
 * @param {number} index
 * @param {periodsType} periodType
 * @return IAppTableRow
 */
const getPeriodRow = (period: IPeriod, index: number, periodType?: periodsType): IAppTableRow => {
    const { title, dateEnd, dateStart, daysCount, weeksCount, id } = period;
    const periodTitle = getPeriodTitle(index, periodType, title);
    return [
        { title: periodTitle, id: id },
        { isActive: true, title: dateStart ? formatJSToTableDate(dateStart) : undefined, type: 'date' },
        { isActive: true, title: dateEnd ? formatJSToTableDate(dateEnd) : undefined, type: 'date' },
        { title: daysCount ?? '-' },
        { isActive: true, title: weeksCount ?? '-', type: 'inputNumber', isInput: true },
    ];
};

/**
 * @description формируем ОБНОВЛЕННЫЕ данные о периодах для отправки на бэк
 * @param {IAppTableItems} table
 * @return Array<IUpdateCalendarPlanPeriodParams>
 */
const getUpdatePeriodParams = (table?: IAppTableItems): Array<IUpdateCalendarPlanPeriodParams> | undefined => {
    const slicedTable = table?.slice(1, -1);
    const params = slicedTable?.map((item) => {
        const { id, title } = item[0];
        const { title: dateStart } = item[1];
        const { title: dateEnd } = item[2];
        const { title: daysCount } = item[3];
        const { title: weeksCount } = item[4];
        if (id) {
            const formatDateStart = dateStart ? getTableDateToParamsDate(dateStart?.toString()) : undefined;
            const formatDateEnd = dateEnd ? getTableDateToParamsDate(dateEnd?.toString()) : undefined;
            return (
                deleteEmptyParams({
                    id: Number(id),
                    title: title?.toString(),
                    dateStart: formatDateStart,
                    dateEnd: formatDateEnd,
                    daysCount: Number(daysCount),
                    weeksCount: Number(weeksCount),
                }) ?? ({} as IUpdateCalendarPlanPeriodParams)
            );
        }
        return {} as IUpdateCalendarPlanPeriodParams;
    });
    const filteredParams = params?.filter((item) => Object.keys(item).length !== 0);
    return filteredParams?.length == 0 ? undefined : filteredParams;
};

const getTotalCountOfDaysAndWeek = (
    dayjsDates: Array<Dayjs>,
    daysCountList: Array<number>,
    weeksCountList: Array<number>,
) => {
    const minDate = dayjs.min(dayjsDates)?.format('DD.MM.YYYY');
    const maxDate = dayjs.max(dayjsDates)?.format('DD.MM.YYYY');
    let daysCount = 0;
    let weeksCount = 0;
    if (daysCountList.length > 0) {
        daysCount = daysCountList?.reduce((previousValue, currentValue) => {
            return previousValue + currentValue;
        });
    }
    if (weeksCountList.length > 0) {
        weeksCount = weeksCountList?.reduce((previousValue, currentValue) => {
            return previousValue + currentValue;
        });
    }

    return { minDate, maxDate, daysCount, weeksCount };
};

/**
 * @description ищем начальную и конечную дату
 * и общее количество учебных дней и недель
 * @param {IAppTableItems} table
 * @return IAppTableItems
 */
const updateStartEndDateAndCountOfDayWeeksPeriodTable = (table: IAppTableItems): IAppTableItems => {
    if (table?.length) {
        const lastRowIndex = table.length - 1;
        const lastRow = table[lastRowIndex];
        const slicedTable = table?.slice(1, -1) ?? [];
        const dates = slicedTable?.map((item) => [
            item?.[1]?.title?.toString() ?? '',
            item?.[2]?.title?.toString() ?? '',
        ]);
        const datesList = ([] as string[])?.concat(...dates)?.filter((item) => item !== '');
        const dayjsDates = datesList?.map((item) => formatTableDateToDayjs(item));
        const daysCountList = slicedTable?.map((item) => Number(item?.[3]?.title) ?? 0);
        const weeksCountList = slicedTable?.map((item) => Number(item?.[4]?.title) ?? 0);

        const { minDate, maxDate, daysCount, weeksCount } = getTotalCountOfDaysAndWeek(
            dayjsDates,
            daysCountList,
            weeksCountList,
        );

        const newLastRow = [
            lastRow[0],
            { ...lastRow[1], title: minDate },
            { ...lastRow[2], title: maxDate },
            { ...lastRow[3], title: daysCount },
            { ...lastRow[4], title: weeksCount },
        ];
        return [...table.slice(0, -1), newLastRow];
    }
    return table;
};

/**
 * @description считаем количество дней и недель
 * @param periodTable
 * @param holidayTable
 * @param additionalDates
 * @param eduWeek
 */
const updateCountOfDayWeeksPeriodTable = (
    periodTable: IAppTableItems,
    holidayTable: IAppTableItems,
    additionalDates: IAdditionalDaysDayJs,
    eduWeek?: eduWeeksType,
): IAppTableItems => {
    const { holidays, additionalWorkingDays } = additionalDates;
    const periodTableLength = periodTable?.length;
    if (periodTableLength) {
        const slicedPeriodTable = periodTable.slice(1, -1) ?? [];
        const slicedHolidayTable = holidayTable.slice(1, -1) ?? [];
        const slicedHolidayTableLength = slicedHolidayTable.length;
        let holidayPeriodsDates = [] as Array<Dayjs>;

        const arrayDayJsToTable = (array: Array<Dayjs>) => {
            return array?.map((item) => formatDayjsToTableDate(item));
        };

        const arrayTableToDayJs = (array: Array<string>) => {
            return array?.map((item) => formatTableDateToDayjs(item));
        };

        // собираем все выходные дни которые указаны в каникулах
        for (let i = 0; i < slicedHolidayTableLength; i++) {
            const startHolidayTable = slicedHolidayTable?.[i]?.[1]?.title?.toString();
            const endHolidayTable = slicedHolidayTable?.[i]?.[2]?.title?.toString();
            if (startHolidayTable && endHolidayTable) {
                holidayPeriodsDates = [...holidayPeriodsDates, ...getDaysJsArray(startHolidayTable, endHolidayTable)];
            }
        }

        //добавляем к каникулам доп не учебные дни и форматим их в строки
        const holidayPeriodsParamsDates = [
            ...arrayDayJsToTable(Array.from(holidayPeriodsDates)),
            ...arrayDayJsToTable(holidays),
        ];

        // доп учебные дни
        const additionalWorkingDaysParamsDate = arrayDayJsToTable(additionalWorkingDays);

        const holidayPeriodsParamsDatesLength = holidayPeriodsParamsDates?.length;

        const table = slicedPeriodTable.map((item) => {
            let newDaysCount = item?.[3]?.title;
            let newWeekCount = item?.[4]?.title;
            const startPeriodTable = item?.[1]?.title?.toString();
            const endPeriodTable = item?.[2]?.title?.toString();
            if (startPeriodTable && endPeriodTable) {
                // превращаем учебный период в Set из строк дат, что бы потом можно было сравнить и удалить лишнее
                const periodDayJs = new Set(arrayDayJsToTable(getDaysJsArray(startPeriodTable, endPeriodTable)));

                // удаляем из периода выходные
                for (let j = 0; j < holidayPeriodsParamsDatesLength; j++) {
                    periodDayJs.delete(holidayPeriodsParamsDates[j]);
                }

                // добавляем доп учебные дни
                const additionalWorkingDaysParamsDateLength = additionalWorkingDaysParamsDate?.length;
                for (let k = 0; k < additionalWorkingDaysParamsDateLength; k++) {
                    periodDayJs.add(additionalWorkingDaysParamsDate[k]);
                }

                // форматим даты в Dayjs
                const newPeriodDayJs = arrayTableToDayJs(Array.from(periodDayJs));

                const { dayCount, weekCount } = getCountOfDaysAndWeek(newPeriodDayJs, eduWeek);

                if (newDaysCount !== dayCount) {
                    newDaysCount = dayCount;
                    newWeekCount = weekCount;
                }
            }
            return [...item.slice(0, 3), { ...item?.[3], title: newDaysCount }, { ...item?.[4], title: newWeekCount }];
        });
        return [...periodTable.slice(0, 1), ...table, ...periodTable.slice(-1)];
    }
    return periodTable;
};

/**
 * @description формируем таблицу периодов обучения
 * @param {Array<IPeriod>} periods
 * @param {periodsType} periodType
 * @param {number} yearDaysCount
 * @param {number} yearWeeksCount
 * @param {boolean} isCreate
 * @return {IAppTableItems}
 */
const getPeriodTable = (
    periods?: Array<IPeriod>,
    periodType?: periodsType,
    yearDaysCount?: number,
    yearWeeksCount?: number,
    isCreate?: boolean,
): IAppTableItems => {
    const periodList = periods ?? Array(getRowCount(periodType)).fill({});
    const periodsLength = periods?.length ?? 0;
    const yearDateStart = periods?.[0]?.dateStart;
    const yearDateEnd = periods?.[periodsLength - 1]?.dateEnd;
    const rows = isCreate ? [] : periodList.map((item, index) => getPeriodRow(item, index, periodType));
    const footerRow = getPeriodFooterRow(yearDateStart, yearDateEnd, yearDaysCount, yearWeeksCount);
    return [periodHeaderRow, ...rows, footerRow];
};

export {
    getPeriodTable,
    getUpdatePeriodParams,
    updateCountOfDayWeeksPeriodTable,
    updateStartEndDateAndCountOfDayWeeksPeriodTable,
};

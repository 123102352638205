import { CodifierResponseData, CodifierSubject } from 'api/services/codifier';

import { CodifierSubjectData } from './getCodifierMaps';

type SubjectsMap = {
    subjectsIds: number[];
    subjectsMap: {
        [id: number]: CodifierSubjectData;
    };
};

export const getSubjectsMaps = (subjects: CodifierSubject[]): SubjectsMap => {
    return subjects.reduce(
        (maps, subject) => {
            maps.subjectsIds.push(subject.id);

            maps.subjectsMap[subject.id] = {
                ...subject,
                parallels: [],
                isFetched: false,
            };

            return maps;
        },
        {
            subjectsIds: [],
            subjectsMap: {},
        } as SubjectsMap,
    );
};

export const getSubjectsInCodifier = (subjects: CodifierSubject[]): CodifierResponseData[] => {
    const data: CodifierResponseData[] = [];

    subjects.forEach((elem) => {
        data.push({
            id: elem.id,
            title: elem.title,
        });
    });

    return data;
};

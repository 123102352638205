import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { LittleArrow } from '@lms-elements/icons';
import { useCodifierContext } from 'containers/CodifierSelector/context';
import { ParallelElement } from 'containers/CodifierSelector/ParallelElement';
import { getCodifierSubjectDataAction } from 'store/actions/codifier';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';

import { CodifierEventType } from 'components/Codifier/Codifier';

import './SubjectElement.scss';

const cx = cn('subject-element');

interface SubjectElementProps {
    subjectId: number;
    isOpened?: boolean;
    isShowHeader?: boolean;
}

export const SubjectElement: React.FC<SubjectElementProps> = ({
    subjectId,
    isOpened: isOpenedProp,
    isShowHeader = true,
}) => {
    const dispatch = useDispatch();
    const subjectData = useAppSelector((store) => store.codifier.subjectsMap[subjectId]);
    const { fetchStatus } = useAppSelector((store) => store.codifier);
    const history = useHistory();

    const { onElementClick, selectedElement, withCheckboxes } = useCodifierContext();

    const [isSelected, setIsSelected] = useState(false);
    const [isOpened, setIsOpened] = useState(isOpenedProp);

    useEffect(() => {
        const searchParams = new URLSearchParams(history.location.search);

        if (searchParams.get('subjects') && fetchStatus === FetchStatus.INITIAL) {
            dispatch(getCodifierSubjectDataAction(Number(searchParams.get('subjects'))));
        }
    }, [dispatch, fetchStatus, history.location.search]);

    useEffect(() => {
        setIsSelected(
            !withCheckboxes && !selectedElement.parallels?.length && selectedElement.subjects?.[0] === subjectId,
        );
        setIsOpened(Boolean(selectedElement.subjects?.includes(subjectId)) || isOpenedProp);
    }, [selectedElement.parallels?.length, subjectId, selectedElement.subjects, withCheckboxes, isOpenedProp]);

    const isDisabled = subjectData.parallels.length === 0;

    const handleSubjectClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            if (!isDisabled && !isSelected) {
                onElementClick(CodifierEventType.SUBJECT, subjectId);
            }
        },
        [isDisabled, isSelected, onElementClick, subjectId],
    );

    return (
        <div className={cx()}>
            {isShowHeader && (
                <div className={cx('container')}>
                    <div className={cx('header', { disabled: isDisabled })} onClick={handleSubjectClick}>
                        <div className={cx('open', { opened: isOpened, disabled: isDisabled })}>
                            <LittleArrow />
                        </div>
                        <div className={cx('title')}>{subjectData.title}</div>
                    </div>
                </div>
            )}

            {isOpened && !isDisabled && (
                <div className={cx('elements-container')}>
                    {subjectData.parallels.map((parallelId, parallelIndex) => (
                        <ParallelElement key={parallelId} parallelId={parallelId} parallelIndex={parallelIndex} />
                    ))}
                </div>
            )}
        </div>
    );
};

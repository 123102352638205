import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { QuestionsReviewData, QuestionsRevisionData } from 'api/services/question';
import { getQuestionsForReviewAction, getQuestionsOnRevisionAction } from 'store/actions/question';
import { FetchStatus } from 'types/api';

interface QuestionsReviewRevisionState {
    questionsFetchStatus: FetchStatus;
    questionsForReviewData?: QuestionsReviewData;
    questionsOnRevisionData?: QuestionsRevisionData;
}

const initialState: QuestionsReviewRevisionState = {
    questionsFetchStatus: FetchStatus.INITIAL,
    questionsForReviewData: {} as QuestionsReviewData,
    questionsOnRevisionData: {} as QuestionsRevisionData,
};

const questionsReviewRevisionSlice = createSlice<
    QuestionsReviewRevisionState,
    SliceCaseReducers<QuestionsReviewRevisionState>
>({
    name: 'formError',
    initialState,
    reducers: {
        reset(state) {
            state.questionsFetchStatus = FetchStatus.INITIAL;
            state.questionsForReviewData = {} as QuestionsReviewData;
            state.questionsOnRevisionData = {} as QuestionsRevisionData;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getQuestionsForReviewAction.pending, (state) => {
                state.questionsFetchStatus = FetchStatus.FETCHING;
            })
            .addCase(getQuestionsForReviewAction.fulfilled, (state, { payload }) => {
                state.questionsFetchStatus = FetchStatus.FETCHED;
                state.questionsForReviewData = payload;
            })
            .addCase(getQuestionsForReviewAction.rejected, (state) => {
                state.questionsFetchStatus = FetchStatus.ERROR;
            });

        builder
            .addCase(getQuestionsOnRevisionAction.pending, (state) => {
                state.questionsFetchStatus = FetchStatus.FETCHING;
            })
            .addCase(getQuestionsOnRevisionAction.fulfilled, (state, { payload }) => {
                state.questionsFetchStatus = FetchStatus.FETCHED;
                state.questionsOnRevisionData = payload;
            })
            .addCase(getQuestionsOnRevisionAction.rejected, (state) => {
                state.questionsFetchStatus = FetchStatus.ERROR;
            });
    },
});

export const questionsReviewRevisionReducer = questionsReviewRevisionSlice.reducer;
export const resetQuestionReviewState = questionsReviewRevisionSlice.actions
    .reset as ActionCreatorWithoutPayload<string>;

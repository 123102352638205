import { ContentBlock, ContentState, EditorState, genKey } from 'draft-js';
import { Map } from 'immutable';
import { BlockTypes } from 'src-new/components/lms-elements/CustomEditor/types/shared.types';

export const addNewBlockAt = (
    editorState: EditorState,
    pivotBlockKey: string,
    newBlockType: BlockTypes,
    initialData = Map({}),
    initialText = '',
): EditorState => {
    const content = editorState.getCurrentContent();
    const blockMap = content.getBlockMap();
    const block = blockMap.get(pivotBlockKey);

    if (!block) {
        throw new Error(`The pivot key - ${pivotBlockKey} is not present in blockMap.`);
    }

    const blocksBefore = blockMap.toSeq().takeUntil((value) => value === block);
    const blocksAfter = blockMap
        .toSeq()
        .skipUntil((value) => value === block)
        .rest();
    const newBlockKey = genKey();

    const newBlock = new ContentBlock({
        key: newBlockKey,
        type: newBlockType,
        text: initialText,
        depth: 0,
        data: initialData,
    });

    const newBlockMap = blocksBefore
        .concat(
            [
                [pivotBlockKey, block],
                [newBlockKey, newBlock],
            ],
            blocksAfter,
        )
        .toOrderedMap();

    const selection = editorState.getSelection();

    const newContent = content.merge({
        blockMap: newBlockMap,
        selectionBefore: selection,
        selectionAfter: selection.merge({
            anchorKey: newBlockKey,
            anchorOffset: 0,
            focusKey: newBlockKey,
            focusOffset: 0,
            isBackward: false,
        }),
    }) as ContentState;

    return EditorState.push(editorState, newContent, 'split-block');
};

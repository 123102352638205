import {
    EntityTypes,
    getEntityDataByType,
    INSERT_WORD_REPLACE,
    insertationToTemplate,
} from '@lms-elements/custom-editor';
import { createDefaultQuestion, IQuestionForTeacher } from '@lms-elements/test-task';
import { IDetailedAnswerOption } from '@lms-elements/test-task/build/@types/packages/TestTask/src/Question/Expanded/DetailedAnswerFields';
import { IMultipleAnswerOption } from '@lms-elements/test-task/build/@types/packages/TestTask/src/Question/Expanded/MultipleAnswerFields';
import { IOpenAnswerOption } from '@lms-elements/test-task/build/@types/packages/TestTask/src/Question/Expanded/OpenAnswerFields';
import { truthfulnessArr } from '@lms-elements/test-task/build/@types/packages/TestTask/src/Question/Expanded/OpenAnswerFields/OpenAnswerFields.types';
import { CodifierResponseData, CodifierSkillsResponseData } from 'api/services/codifier';
import { PostQuestion, QuestionStatus, ResponseQuestionDetailed } from 'api/services/questionBank';
import { DifficultyEnum } from 'api/services/questionCreate';
import { convertFromRaw, convertToRaw, EditorState, RawDraftContentState } from 'draft-js';
import convertToHtml from 'draftjs-to-html';
import {
    EssayQuestionData,
    InsertWordQuestionData,
    questionType,
    TestQuestionData,
    TextQuestionData,
    TrueFalseQuestionData,
} from 'types/question';

import { getLessonLocation, getSkillLocation } from 'components/Codifier/utils';
import { getLocationSkillString, getLocationString } from 'components/QuestionLocationDropdown/utils';
import { getQuestionType, getQuestionTypeForBackend } from 'utils/questions';

import { IFormValuesCreateQuestions, QuestionMarkup } from './QuestionCreationPage.types';

type insertWordsData = {
    key: string;
    val: {
        data: {
            options: string[];
            text: string;
        };
    };
};

export const getQuestionText = (question: EditorState | string): string =>
    typeof question === 'object' ? question.getCurrentContent().getPlainText() : question;

export const getQuestionContent = (question: EditorState | string): string | undefined =>
    typeof question === 'object' ? JSON.stringify(convertToRaw(question.getCurrentContent())) : undefined;

export const getDifficultyForBackend = (difficulty: string): DifficultyEnum => {
    switch (difficulty) {
        case 'Лёгкая':
            return DifficultyEnum.easy;
        case 'Средняя':
            return DifficultyEnum.medium;
        default:
            return DifficultyEnum.hard;
    }
};

export const getDifficulty = (difficulty: string | undefined): string => {
    switch (difficulty) {
        case DifficultyEnum.easy:
            return 'Лёгкая';
        case DifficultyEnum.medium:
            return 'Средняя';
        default:
            return 'Тяжёлая';
    }
};

export const getAnswerOptionText = (option: EditorState | string): string =>
    typeof option === 'object' ? option.getCurrentContent().getPlainText() : option;

const getAnswerOption = (option: EditorState | string) =>
    typeof option === 'object'
        ? JSON.stringify(convertToRaw(option.getCurrentContent()))
        : JSON.stringify({ text: option });

export const getQuestionAnswersData = (
    questionData: IQuestionForTeacher,
    markups?: QuestionMarkup,
): TestQuestionData | InsertWordQuestionData | TrueFalseQuestionData | EssayQuestionData | TextQuestionData => {
    const { type, question, randomOrder, answerOptions, rightAnswer } = questionData;
    let questionAnswersData = {} as
        | TestQuestionData
        | InsertWordQuestionData
        | TrueFalseQuestionData
        | EssayQuestionData
        | TextQuestionData;
    switch (type) {
        case 'detailed':
            questionAnswersData =
                answerOptions?.reduce(
                    (result, answer) => {
                        return {
                            acceptFile: result.acceptFile && (answer as IDetailedAnswerOption).isFileAvailable,
                            acceptText: result.acceptFile && (answer as IDetailedAnswerOption).isTextAvailable,
                        };
                    },
                    { acceptFile: true, acceptText: true } as EssayQuestionData,
                ) || ({ acceptFile: true, acceptText: true } as EssayQuestionData);
            break;
        case 'insert-words':
            getEntityDataByType(question as EditorState, 'INSERT_WORD' as EntityTypes).forEach(
                ({ key, val }: insertWordsData) => {
                    if ((questionAnswersData as InsertWordQuestionData).gaps) {
                        (questionAnswersData as InsertWordQuestionData).gaps.push({
                            index: Number(key),
                            wordOptions: [
                                { word: val.data.text },
                                ...val.data.options.map((option) => {
                                    return {
                                        word: option,
                                    };
                                }),
                            ],
                        });
                    } else {
                        questionAnswersData = {
                            gaps: [
                                {
                                    index: Number(key),
                                    wordOptions: [
                                        { word: val.data.text },
                                        ...val.data.options
                                            .filter((option) => option.trim().length > 0)
                                            .map((option) => {
                                                return {
                                                    word: option,
                                                };
                                            }),
                                    ],
                                },
                            ],
                        };
                    }
                },
            );
            break;
        case 'multiple':
            questionAnswersData = {
                randomAnswerOrder: Boolean(randomOrder),
                answerOptions: (answerOptions as IMultipleAnswerOption[]).map((option, index) => {
                    return {
                        content: getAnswerOption(option.answer),
                        html: (markups?.answersHTML || [])[index],
                        text: getAnswerOptionText(option.answer),
                        correct: option.isCorrect,
                    };
                }),
            } as TestQuestionData;
            break;
        case 'open':
            questionAnswersData = {
                randomAnswerOrder: Boolean(randomOrder),
                answerOptions: ((answerOptions as IOpenAnswerOption[]) ?? []).map((option) => {
                    return {
                        text: getAnswerOptionText(option.answer),
                        percentOfScore: parseInt(option.truthfulness.replace(/[^\d]/g, '')),
                    };
                }),
            } as TextQuestionData;
            break;
        default:
            questionAnswersData = { valid: rightAnswer === 'Верно' };
            break;
    }

    return questionAnswersData;
};

const convertInsertWord = (editorState: EditorState) =>
    convertToHtml(convertToRaw(editorState.getCurrentContent()), undefined, undefined, ({ type }, text) => {
        if (type === EntityTypes.INSERT_WORD) {
            return INSERT_WORD_REPLACE;
        }
        return text as string;
    });

export const getPostQuestionData = (
    value: IFormValuesCreateQuestions,
    question: IQuestionForTeacher,
    topics: number[],
    skills: number[],
    questionType: questionType,
    markups?: QuestionMarkup,
    fileIds?: number[],
): PostQuestion => {
    const questionHtml =
        questionType === 'insert-words' && question.question instanceof EditorState
            ? convertInsertWord(question.question)
            : markups?.questionHTML;

    return {
        text:
            questionType === 'insert-words' && question.question instanceof EditorState
                ? insertationToTemplate(question.question).replace(new RegExp(INSERT_WORD_REPLACE, 'g'), '[...]')
                : getQuestionText(question.question),
        content: getQuestionContent(question.question),
        html: questionHtml,
        answersType: getQuestionTypeForBackend(questionType),
        difficulty: getDifficultyForBackend(value.complexity),
        // TODO: изменить когда будет логика выставления оценки согласно отвеченным вопросам
        maximumScore: 1,
        topics: topics,
        skills: skills,
        status: value.toggle ? QuestionStatus.Published : QuestionStatus.NotPublished,
        data: getQuestionAnswersData(question, markups),
        fileIds: fileIds,
    };
};

export const getInitFormValue = (isForMaterials: boolean, isPublished?: boolean): IFormValuesCreateQuestions => ({
    toggle: isForMaterials || Boolean(isPublished),
    complexity: 'Средняя',
    topicsLocation: '',
    skillsLocation: '',
    questions: [],
    isFromMaterials: isForMaterials,
});

export const getInitFormValues = (
    question: ResponseQuestionDetailed,
    codifier: CodifierResponseData[],
    skills: CodifierSkillsResponseData[],
    isFromMaterials: boolean,
): IFormValuesCreateQuestions => {
    const type = getQuestionType(question.answersType);
    const form: IFormValuesCreateQuestions = {
        toggle: true,
        complexity: '',
        topicsLocation: '',
        skillsLocation: '',
        questions: [],
        isFromMaterials: false,
    };
    form.isFromMaterials = isFromMaterials;
    form.complexity = getDifficulty(question.difficulty);
    form.toggle = question?.status === QuestionStatus.Published;
    form.topicsLocation = getLocationString(question.topics?.map((q) => getLessonLocation(codifier, q.id)) || []);
    form.skillsLocation = getLocationSkillString(
        question.skills ? question.skills.map((s) => getSkillLocation(skills, s.id)) : [],
    );
    form.questions = [createDefaultQuestion(type)].map((value) => value);
    form.questions[0].question =
        question.content && question.content !== '{}'
            ? EditorState.createWithContent(convertFromRaw(JSON.parse(question.content)))
            : question.text || '';
    form.questions[0].answerOptions = [];
    if (type === 'binary') {
        form.questions[0].rightAnswer = (question.data as TrueFalseQuestionData).valid ? 'Верно' : 'Неверно';
    }

    if (type === 'detailed') {
        form.questions[0].answerOptions.push({
            isFileAvailable: (question.data as EssayQuestionData).acceptFile,
            isTextAvailable: (question.data as EssayQuestionData).acceptText,
        });
    }

    if (type === 'multiple') {
        form.questions[0].randomOrder = (question.data as TestQuestionData).randomAnswerOrder;
        form.questions[0].answerOptions = (question.data as TestQuestionData).answerOptions.map((option) => {
            const isEmptyAnswer = option.content === '{}' || option.content === '';
            let answer: string | EditorState = '';

            if (!isEmptyAnswer) {
                const rawAnswer = JSON.parse(option.content) as { text: string } | RawDraftContentState;
                answer =
                    Object.keys(rawAnswer).length === 1
                        ? (rawAnswer as { text: string }).text
                        : EditorState.createWithContent(convertFromRaw(rawAnswer as RawDraftContentState));
            }

            return {
                answer,
                isCorrect: option.correct,
            };
        });
    }

    if (type === 'open') {
        form.questions[0].randomOrder = (question.data as TextQuestionData).randomAnswerOrder;
        form.questions[0].answerOptions = (question.data as TextQuestionData).answerOptions?.map((option) => {
            return {
                answer: option.text,
                truthfulness: `${
                    [100, 75, 50, 25].includes(option.percentOfScore) ? option.percentOfScore : 100
                } % от оценки` as typeof truthfulnessArr[number],
            };
        });
    }

    return form;
};

const isEmptyQuestion = (question?: EditorState | string): boolean => {
    if (!question) return false;

    if (typeof question === 'string') {
        return question.trim().length === 0;
    }

    return question.getCurrentContent().getPlainText().trim().length === 0;
};

export const validateQuestion = (questionData: IQuestionForTeacher): string | undefined => {
    const { type, ...question } = questionData;

    if (isEmptyQuestion(question.question)) {
        return 'question is empty';
    }

    switch (type) {
        case 'multiple':
        case 'open':
            if (!question.answerOptions?.length) {
                return 'no answer options';
            }

            return question.answerOptions.reduce<string | undefined>((error, option) => {
                if ('answer' in option) {
                    return isEmptyQuestion(option.answer) ? 'option answer is empty' : undefined;
                } else {
                    return 'no answer field';
                }
            }, undefined);
        case 'insert-words':
            return getEntityDataByType(question.question as EditorState, 'INSERT_WORD' as EntityTypes).length > 0
                ? undefined
                : 'no gaps';
        default:
            return undefined;
    }
};

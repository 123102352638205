import { api } from 'api';
import { ResponseCourseType } from 'api/services/course';
import { AxiosError, AxiosResponse } from 'axios';
import { CourseType } from 'types/course';

export interface PostCourseParams {
    subjectId: number;
    subjectAlterTitle?: string;
    parallels: number[];
    title: string;
    description?: string;
    aboutTeacher?: string;
    task?: string;
    lessonCount: number;
    methodists: number[];
    courseType: CourseType[];
    duration: number;
    preview?: File;
    courseVideo?: File;
}

export const postCourse = (params: PostCourseParams): Promise<ResponseCourseType> => {
    const {
        subjectId,
        subjectAlterTitle,
        parallels,
        title,
        description,
        aboutTeacher,
        task,
        lessonCount,
        courseType,
        duration,
        preview,
        courseVideo,
        methodists,
    } = params;

    const data = new FormData();

    data.append('subjectId', String(subjectId));

    if (subjectAlterTitle !== undefined) data.append('subjectAlterTitle', subjectAlterTitle);

    parallels.forEach((parallel) => {
        data.append('parallels', String(parallel));
    });

    data.append('title', title);

    if (description !== undefined) data.append('description', description);

    if (aboutTeacher !== undefined) data.append('aboutTeacher', aboutTeacher);

    if (task !== undefined) data.append('task', task);

    data.append('lessonCount', String(lessonCount));

    courseType.forEach((type) => {
        data.append('courseType', type);
    });

    methodists.forEach((methodist) => data.append('methodists', methodist.toString()));

    data.append('duration', String(duration));

    if (preview !== undefined) data.append('preview', preview);

    if (courseVideo !== undefined) data.append('courseVideo', courseVideo);

    return api
        .post<Promise<ResponseCourseType>, AxiosResponse<ResponseCourseType>>('/course/create/', data, {
            headers: {
                'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
                Accept: 'application/json',
            },
        })
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

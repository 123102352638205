import React from 'react';
import { AddKinescopeVideo } from '@fsd-features/AddKinescopeVideo';
import { KinescopeVideo } from '@fsd-shared/ui-kit';

import { TKinescopeVideoCard } from './KinescopeVideoCard.types';

export const KinescopeVideoCard: React.FC<TKinescopeVideoCard> = (props) => {
    const { isEditable, name, onDelete, ...videoProps } = props;

    return isEditable ? (
        <AddKinescopeVideo name={name} onDelete={onDelete} {...videoProps} />
    ) : (
        <KinescopeVideo {...videoProps} />
    );
};

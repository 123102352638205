import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    changeMeetingFile,
    ChangeMeetingFileParams,
    createMeetingFile,
    CreateMeetingFileParam,
    deleteMeetingFile,
    getMeetingFiles,
    getMeetingRecordings,
    getMeetingTemplateFiles,
    PatchMeetingRecordingData,
    postMeetingRecording,
} from 'api/services/meeting';

export const getMeetingRecordingsAction = createAsyncThunk(
    'meeting/getMeetingRecordings',
    (param: { lessonSchedule?: number; eventRoom?: number }) => {
        return getMeetingRecordings(param.lessonSchedule, param.eventRoom).then((data) => {
            if (typeof data === 'string') {
                return {
                    lessonSchedule: param.lessonSchedule,
                    eventRoom: param.eventRoom,
                    error: data,
                };
            }

            return {
                ...data,
                lessonSchedule: param.lessonSchedule,
                eventRoom: param.eventRoom,
            };
        });
    },
);

type PatchMeetingParams = {
    lessonSchedule: number;
};

export const postMeetingRecordingAction = createAsyncThunk(
    'meeting/patchMeetingRecording',
    ({ lessonSchedule, ...params }: PatchMeetingParams & PatchMeetingRecordingData) => {
        return postMeetingRecording(params).then((data) => ({
            ...data,
            lessonSchedule,
        }));
    },
);

type GetFilesParams = {
    lessonSchedule?: number;
    lesson: number;
};

export const getMeetingFilesAction = createAsyncThunk(
    'meeting/getMeetingFiles',
    async ({ lessonSchedule, lesson }: GetFilesParams) => {
        if (lessonSchedule) {
            return await getMeetingFiles(lessonSchedule);
        }

        return await getMeetingTemplateFiles(lesson);
    },
);

export const createMeetingFileAction = createAsyncThunk(
    'meeting/createMeetingFile',
    (params: CreateMeetingFileParam) => {
        return createMeetingFile(params);
    },
);

export const deleteMeetingFileAction = createAsyncThunk('meeting/deleteMeetingFile', (fileId: number) => {
    return deleteMeetingFile(fileId).then(() => ({
        fileId,
    }));
});

export const changeMeetingFileAction = createAsyncThunk(
    'meeting/changeMeetingFile',
    (params: ChangeMeetingFileParams) => {
        return changeMeetingFile(params);
    },
);

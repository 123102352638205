import React from 'react';
import { cn } from '@bem-react/classname';
import { Input } from 'antd';

import { NEW_LINE_DELIMITER } from './InsertWordsExercise.config';
import { IInsertWordsOptionProps } from './InsertWordsExercise.types';
import { getInsertWordInputStatus } from './InsertWordsExercise.utils';

import './InsertWordsExercise.scss';

const CnInsertWordsExercise = cn('insert-word-exercise');

export const InsertWordsExercise: React.FC<IInsertWordsOptionProps> = (props) => {
    const { question, answerData, localAnswerValue, setLocalAnswerValue } = props;
    const { correctness, answerOptions } = answerData || {};
    const onChangeValue = (value: string, index: number) => {
        setLocalAnswerValue({ ...localAnswerValue, [index]: { value: value } });
    };

    return (
        <div className={CnInsertWordsExercise()}>
            {question.map((part, index) => {
                const answerValue = typeof part === 'number' ? answerOptions?.[part]?.selectedWord || '' : '';
                const value = localAnswerValue?.[index]?.value || answerValue;
                return typeof part === 'number' ? (
                    <Input
                        className={CnInsertWordsExercise('input', {
                            correctness: getInsertWordInputStatus(value, answerOptions),
                        })}
                        key={index}
                        value={value}
                        disabled={!!correctness}
                        onChange={(event) => onChangeValue(event.target.value, index)}
                    />
                ) : (
                    part
                        .split(new RegExp(`(${NEW_LINE_DELIMITER})`))
                        .map((item, index) =>
                            item === NEW_LINE_DELIMITER ? (
                                <br key={index} />
                            ) : (
                                <span
                                    key={index}
                                    className={CnInsertWordsExercise('text')}
                                    dangerouslySetInnerHTML={{ __html: item }}
                                />
                            ),
                        )
                );
            })}
        </div>
    );
};

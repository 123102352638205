import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { cn } from '@bem-react/classname';
import { Title } from '@lms-elements/atomic';
import { useCourseGroupQuery, useRole } from 'hooks';
import { useTaskPopup } from 'src-new/hooks';
import { getAssignmentProgressIntervalAction, getAssignmentProgressTeachersListAction } from 'store/actions/assignment';
import { useAppDispatch, useAppSelector } from 'store/store';
import { ExecutionTimes } from 'types/assignment';
import { Task as TaskData } from 'types/homework';

import { Task } from 'components/Task';
import { ITaskDate } from 'components/Task/Task';
import { getPathParts } from 'utils/url';

import './TaskBlock.scss';

interface ITaskBlockProps {
    blockTitle?: string;
    tasks?: TaskData[];
    course?: number;
    courseId?: number;
    lesson?: number;
    subject?: number;
    hasConference?: boolean;
    onSelect?: (taskId: number, isSelect: boolean) => void;
    onDateSelect?: (taskId: number, dates: ITaskDate) => void;
    onTeacherSelect?: (taskId: number, id: number) => void;
    onAssignmentDelete?: (id: number) => void;
    disabledSelect?: boolean;
    repeatTask?: boolean;
    lessonSchedule?: number;
}

const CnTaskBlock = cn('tasks-container');

const tasksStab: TaskData[] = [];

export const TaskBlock: React.FC<ITaskBlockProps> = ({
    tasks = tasksStab,
    course,
    courseId,
    lesson,
    subject,
    onSelect,
    onDateSelect,
    onAssignmentDelete,
    hasConference,
    blockTitle = 'Выберите те, которые хотите выдать',
    disabledSelect,
    repeatTask,
    lessonSchedule,
    onTeacherSelect,
}) => {
    const dispatch = useAppDispatch();
    const { isTeacher } = useRole();

    const tasksProgressInterval = useAppSelector((state) => state.assignemnt.assignmentsProgressInterval);
    const { lessonDetails } = useAppSelector((store) => store.courseLesson);

    const [stateValues, setStateValues] = useState(tasks.map((task) => ({ ...task })));

    useEffect(() => {
        setStateValues(tasks);
    }, [tasks]);

    const history = useHistory();
    const TaskPopup = useTaskPopup();
    const { courseGroupId } = useCourseGroupQuery();
    const id = useMemo(() => getPathParts(history.location.pathname)[1], [history.location.pathname]);
    const courseIdFromUrl = useMemo(() => lessonDetails[Number(id)]?.course?.id, [lessonDetails, id]);
    const { isPrincipal, isMethodist, isProctor } = useRole();

    useEffect(() => {
        const hasAccessToTeachers = isPrincipal || isMethodist || isProctor;

        if ((courseIdFromUrl || courseId) && hasAccessToTeachers) {
            void dispatch(getAssignmentProgressTeachersListAction(courseId ?? courseIdFromUrl));
        }
    }, [courseIdFromUrl, courseId]);

    const [subjectId, conferenceId] = useMemo(() => getPathParts(history.location.pathname), [history]);
    const onClickCreate = useCallback(() => {
        if (isTeacher && course && lesson) {
            TaskPopup.openTaskPopup({
                courseGroupId: course,
                subjectId: subject,
                lessonId: lesson,
                purpose: 'create',
            });
        } else {
            history.push(
                `/subjects/${subjectId}/${conferenceId}/tasks/create${
                    courseGroupId ? `?course_group=${courseGroupId}` : ''
                }`,
            );
        }
    }, [conferenceId, courseGroupId, history, subjectId]);

    const handleSubmit = useCallback((id: number, dates: ITaskDate) => {
        if ((dates.timeStart || dates.startTimeDelay) && (dates.timeEnd || dates.endTimeDelay)) {
            setStateValues((prev) =>
                prev.map((task) => {
                    if (task.id == id) task.isPosted = true;
                    return task;
                }),
            );
        }
    }, []);

    const handleDelete = useCallback(
        (id: number) => {
            setStateValues((prev) => prev.filter((task) => task.id !== id));
            if (onAssignmentDelete) {
                onAssignmentDelete(id);
            }
        },
        [onAssignmentDelete],
    );

    const handleTaskTitleClick = useCallback(
        (taskId: number) => {
            if (isTeacher && course && lesson) {
                TaskPopup.openTaskPopup({
                    subjectId: subject,
                    courseGroupId: course,
                    lessonId: lesson,
                    taskId: taskId,
                    purpose: 'edit',
                });
            } else {
                history.push(
                    `/subjects/${subjectId}/${conferenceId}/tasks/edit/${taskId}${
                        courseGroupId ? `?course_group=${courseGroupId}` : ''
                    }`,
                );
            }
        },
        [conferenceId, courseGroupId, history, subjectId, isTeacher, course, lesson],
    );

    const ref = useRef(document.createElement('div'));

    const handleScroll = useCallback(
        (value: number) => {
            setTimeout(() => {
                ref.current.scrollTo({
                    top: value - 249,
                    behavior: 'smooth',
                });
            }, 10);
        },
        [ref],
    );

    const firstRender = useRef(true);
    useEffect(() => {
        if (!lessonSchedule || !firstRender.current) return;

        stateValues.forEach((task) => {
            if (tasksProgressInterval[task.id]) return;
            if (!task?.type?.executionTime.includes('in_class' as ExecutionTimes)) return;

            void dispatch(
                getAssignmentProgressIntervalAction({
                    lessonSchedule,
                    assignmentId: task.id,
                }),
            );
        });

        firstRender.current = false;
    }, [stateValues, lessonSchedule, tasksProgressInterval]);

    return (
        <div className={CnTaskBlock({ disabledSelect })}>
            <Title
                name={'Задания'}
                onClickAdd={() => ''}
                inscription={blockTitle}
                onClickMove={onClickCreate}
                isTask={true}
            />
            <div className={CnTaskBlock('tasks')} ref={ref}>
                {stateValues
                    .filter((task) => (repeatTask ? task.isPosted : true))
                    .map((task) => (
                        <Task
                            id={task.id}
                            title={task.title}
                            isForMethodist={task.isForMethodist}
                            isTemplate={task.isTemplate}
                            isPosted={task.isPosted}
                            key={`TaskId_${task.id}`}
                            handleDelete={handleDelete}
                            handleSubmit={handleSubmit}
                            handleScroll={handleScroll}
                            onSelect={onSelect?.bind(null, task.id)}
                            onDateSelect={onDateSelect?.bind(null, task.id)}
                            onTeacherSelect={onTeacherSelect?.bind(null, task.id)}
                            onTitleClick={handleTaskTitleClick}
                            onlyDatesOptions={!hasConference}
                            disabledSelect={disabledSelect}
                            canRepeatTask={repeatTask}
                            interval={tasksProgressInterval[task.id]}
                        />
                    ))}
            </div>
        </div>
    );
};

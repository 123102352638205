import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAssignmentProgressPreview } from 'api/services/homework';
import { getLessonDetails } from 'api/services/lesson';
import { fetchStudentData } from 'api/services/student';
import {
    deleteStudentTask,
    fetchStudentHomeworkById,
    fetchStudentHomeworksByLesson,
    getStudentTasks,
} from 'api/services/studentTasks';

interface getStudentTasksParam {
    studentId: number;
    lessonId: number;
    isMandatory?: boolean;
}
export const getStudentTasksAction = createAsyncThunk(
    'studentTasks/getStudentTasks',
    async ({ studentId, lessonId, isMandatory }: getStudentTasksParam) => {
        return await Promise.all([
            getStudentTasks(studentId, lessonId, isMandatory),
            getLessonDetails(lessonId),
            fetchStudentData(studentId),
        ]);
    },
);
export const fetchStudentHomeworksAction = createAsyncThunk(
    'homework/fetchStudentHomeworks',
    async (params: { lessonId: number; isMandatory?: boolean }) => {
        const { lessonId, isMandatory } = { ...params };
        return await fetchStudentHomeworksByLesson(lessonId, isMandatory);
    },
);

export const getStudentTaskById = createAsyncThunk('studentTasks/getStudentTaskById', async (id: number) => {
    return await fetchStudentHomeworkById(id);
});

type Params = {
    student: number;
    lesson?: number;
    status?: string;
    is_mandatory?: boolean;
};

export const getStudentTaskPreview = createAsyncThunk(
    'studentTasks/getStudentTaskPreview',
    ({ student, lesson, status, is_mandatory }: Params) => {
        return getAssignmentProgressPreview({ student, lesson, status, is_mandatory });
    },
);

export const deleteStudentTaskAction = createAsyncThunk(
    'studentTasks/deleteStudentTask',
    ({ id, studentId, lessonId }: { id: number; studentId: number; lessonId: number }, { dispatch }) => {
        return deleteStudentTask(id).then(() => {
            void dispatch(getStudentTasksAction({ studentId, lessonId }));
        });
    },
);

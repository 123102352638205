import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { AppText } from '@frontend-modules/ui-kit';
import { AnswerAttemptPostResponse } from 'api/services/answerAttempt';
import { PatchTeacherMarkParams, PostAssignmentMark } from 'api/services/marks';
import { StrokeArrowRightIcon } from 'assets';
import { PageLoader } from 'containers/PageLoader';
import { StudentTask } from 'containers/StudentTask';
import { useLoader, useRole, useWindowWidth } from 'hooks';
import { getQueryAcademicPerfomanceParams, getQuerySearchParams } from 'pages/LessonCard/utils';
import { getChangeAttemptMarkParams } from 'pages/StudentTasks/StudentTasks.utils';
import { HardModal } from 'src-new/components/hard-modal';
import { useGetPopupLessonData, useStudentTasksViewPopup, useSubjectsRedirect } from 'src-new/hooks';
import {
    changeAssignmentAdditionalMarkAction,
    changeAssignmentMarkAction,
    changeAssignmentMarksAction,
    createAssignmentAdditionalMarkAction,
    deleteAssignmentAdditionalMarkWithCheckAction,
} from 'store/actions/marks';
import { deleteStudentTaskAction, getStudentTasksAction } from 'store/actions/studentTasks';
import { clearError } from 'store/reducers/error';
import { resetProctoringState } from 'store/reducers/proctoring';
import { resetStudentTaskData } from 'store/reducers/studentTasks';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';

import { MarkField } from 'components/AttendanceMark/AttendanceMarkForm';
import { StudentTab } from 'components/StudentTab';
import { createTabs } from 'utils/studentsTasks';

import { TDeletedMark } from './StudentTasksViewPopup.types';

const cnStudentTasks = cn('student_tasks');

import { IStudentTasksViewPopup } from 'types/lessonTask';

import './style.scss';
const StudentTasksViewPopup: React.FC<IStudentTasksViewPopup> = (props) => {
    const { urlProps, ...rest } = props;
    const { lessonId, courseGroupId, taskId, studentId, subjectId } = urlProps;
    const StudentTasksViewPopup = useStudentTasksViewPopup();

    const { courseInfo, topicInfo, lessonInfo } = useGetPopupLessonData({
        lessonId,
        courseGroupId,
        taskId,
        isNeedFetch: StudentTasksViewPopup.isCurrent,
    });
    const history = useHistory();
    const { isPrincipal: isPrincipalRole, isSupport, isTeacher, isStudent } = useRole();
    useSubjectsRedirect({
        modalType: 'TASKS_VIEW',
        urlProps: urlProps,
    });

    const isTablet = useWindowWidth(1024);
    const breadcrumbsHeader = useMemo(() => {
        return courseInfo?.title && topicInfo?.title ? (
            <div className={'student-tasks-view-popup__breadcrumbs'}>
                <AppText text={courseInfo.title ?? ''} textStyle={'DesktopH4'} color={'#919399'} />
                <StrokeArrowRightIcon width={4} height={8} />
                <AppText text={topicInfo.title ?? ''} textStyle={'DesktopH4'} color={'#919399'} />
                <StrokeArrowRightIcon width={4} height={8} />
                <AppText text={lessonInfo?.title ?? ''} textStyle={'DesktopH4'} />
            </div>
        ) : (
            <></>
        );
    }, [courseInfo, topicInfo]);

    //Ниже логика, перенесенная со страницы просмотра домашки (будет заменена после рефакторинга)
    const dispatch = useDispatch();

    const [currentTab, setCurrentTab] = useState<number>(+taskId);
    const [deletedMarks, setDeletedMarks] = useState<TDeletedMark[]>([]);
    const [canDelete, setCanDelete] = useState(false);

    // TODO: изменить, когда будут известны все отличия между support и завучем. Сейчас support видит все тоже самое что завуч
    const isPrincipal = isPrincipalRole || isSupport;

    const {
        studentTasks,
        lesson,
        studentData,
        fetchStatus: studentTasksStatus,
        taskFetchStatus,
        taskDeletingStatus,
        patchStatus: markPatchStatus,
        deleteStatus: markDeleteStatus,
        postStatus: markPostStatus,
        getEventRoomsStatus,
        answerAttempts,
        info,
    } = useAppSelector((store) => ({
        studentTasks: store.studentTasks.studentTasks,
        lesson: store.studentTasks.lesson,
        studentData: store.studentTasks.studentData,
        fetchStatus: store.studentTasks.fetchStatus,
        taskFetchStatus: store.studentTasks.taskFetchStatus,
        taskDeletingStatus: store.studentTasks.taskDeletingStatus,
        patchStatus: store.mark.patchStatus,
        deleteStatus: store.mark.deleteStatus,
        postStatus: store.mark.postStatus,
        getEventRoomsStatus: store.proctoring.getEventRoomsStatus,
        answerAttempts: store.answerAttempts.data,
        info: store.user.info,
    }));

    useLoader([
        studentTasksStatus,
        markDeleteStatus,
        markPostStatus,
        markPatchStatus,
        getEventRoomsStatus,
        taskDeletingStatus,
    ]);

    const studentName = useMemo(() => {
        if (studentData) {
            return `${studentData.user.firstName} ${studentData.user.lastName}`;
        }
    }, [studentData]);

    const isAdaptation = useMemo(() => studentData?.isAdaptation || studentTasks[0]?.student.isAdaptation, [
        studentTasks,
        studentData,
    ]);

    const studentPhoto = useMemo(() => studentData?.user.photoUrl || '', [studentData]);

    const tabs = useMemo(() => createTabs(studentTasks, answerAttempts, isPrincipal, isStudent, info?.id), [
        studentTasks,
        isPrincipal,
        isStudent,
        info,
        answerAttempts,
    ]);

    const groupId = useMemo(() => {
        if (studentData) {
            // TODO: remove after update GET-request student-card/<studentID>/
            return +courseGroupId || studentTasks[0].courseGroup?.id;
        }
    }, [courseGroupId, studentData, studentTasks]);

    const assignmentId = useMemo(() => studentTasks.find((task) => Number(task.id) === currentTab)?.assignment.id, [
        currentTab,
        studentTasks,
    ]);

    const handleTabChange = (id: number) => {
        setCurrentTab(id);
        StudentTasksViewPopup.replaceLastStudentTasksViewPopup({
            subjectId,
            lessonId,
            courseGroupId,
            taskId: id,
            studentId,
        });
    };

    const handleStudentClick = useCallback(() => {
        if (groupId && lesson?.course?.id && !isStudent) {
            history.push(
                `/profile/student/${studentId}/notes/${getQuerySearchParams({
                    lessonId: lessonId,
                    subjectId: lesson.course.id,
                    groupId: groupId,
                })}`,
            );
        } else history.push(`/`);
    }, [groupId, history, lesson, lessonId, studentId, isStudent]);

    const handleCancel = useCallback(() => {
        setDeletedMarks([]);
    }, []);

    const handleMarkDelete = useCallback((markId: number, attemptMarkNumber?: number, teacherMark?: boolean) => {
        setDeletedMarks((prev) => {
            return [
                ...prev,
                {
                    markId,
                    attemptMarkNumber,
                    teacherMark,
                },
            ];
        });
    }, []);

    const handleMarkAdd = useCallback(
        (addMarksOptions: PostAssignmentMark[]) => {
            dispatch(createAssignmentAdditionalMarkAction(addMarksOptions));
        },
        [dispatch],
    );

    const handleMarkChange = useCallback(
        (changeMarksOptions: (PatchTeacherMarkParams & { valueCanChange?: boolean; teacherMark?: boolean })[]) => {
            const teacherMarks = changeMarksOptions
                .filter((mark) => mark.teacherMark)
                .map(({ id, score, comment }) => ({
                    id,
                    score,
                    comment,
                }));
            const assignmentMarks = changeMarksOptions.filter((mark) => !mark.teacherMark);

            if (teacherMarks.length) {
                dispatch(changeAssignmentAdditionalMarkAction({ marks: teacherMarks, taskId: currentTab }));
            }

            if (assignmentMarks.length) {
                dispatch(
                    changeAssignmentMarksAction({
                        marks: assignmentMarks
                            .filter((mark) => mark.valueCanChange)
                            .map(({ score, id, comment }) => ({
                                score,
                                id,
                                comment,
                            })),
                        taskId: currentTab,
                    }),
                );
            }

            setCanDelete(true);
        },
        [currentTab, dispatch],
    );

    const handleErrorModalCancelButtonClick = useCallback(() => {
        dispatch(clearError());
    }, [dispatch]);

    const handleErrorModalActionButtonClick = useCallback(() => {
        dispatch(
            deleteStudentTaskAction({
                id: tabs.find((task) => task.id === currentTab)?.id ?? 0,
                studentId: +studentId,
                lessonId: +lessonId,
            }),
        );
    }, [courseGroupId, currentTab, dispatch, history, lessonId, studentId, tabs]);

    const handleTaskCheck = useCallback(
        (value: { mark: MarkField; answerAttempt: AnswerAttemptPostResponse }) => {
            const { mark, answerAttempt } = value;
            if (mark.selectedMark && answerAttempt.mark) {
                const changeValues = getChangeAttemptMarkParams(
                    { score: mark.selectedMark, comment: mark.comment },
                    answerAttempt.mark,
                );

                dispatch(changeAssignmentMarkAction({ ...changeValues, taskId: currentTab }));
            }
        },
        [dispatch, currentTab],
    );

    const handleMarksClick = useCallback(() => {
        history.push(
            `/performance${getQueryAcademicPerfomanceParams({
                courseId: studentTasks[0].course?.id,
                subjectId: studentTasks[0].subject?.id,
                groupId: studentTasks[0].courseGroup?.id,
            })}`,
        );
    }, [history, studentTasks]);

    useEffect(() => {
        dispatch(getStudentTasksAction({ studentId: Number(studentId), lessonId: Number(lessonId) }));
    }, [dispatch, lessonId, studentId]);

    useEffect(() => {
        if (canDelete && !!deletedMarks.length && !!answerAttempts) {
            dispatch(
                deleteAssignmentAdditionalMarkWithCheckAction({
                    marks: deletedMarks
                        .filter(
                            ({ attemptMarkNumber }) =>
                                typeof attemptMarkNumber === 'number' &&
                                answerAttempts.some(({ attempt }) => attempt === attemptMarkNumber),
                        )
                        .map(({ attemptMarkNumber }) => {
                            return answerAttempts.find(({ attempt }) => attempt === attemptMarkNumber)?.id as number;
                        }),
                    teacherMarks: deletedMarks.filter(({ teacherMark }) => teacherMark).map(({ markId }) => markId),
                    allMarks: tabs.find(({ id }) => Number(id) === taskId)?.marks?.filter((el) => el.attemptMarkNumber)
                        .length,
                    taskId: currentTab,
                    numberOfMarks: tabs.find((task) => task.id === currentTab)?.marks?.length ?? 0,
                    handleCancelButtonClick: handleErrorModalCancelButtonClick,
                    handleActionButtonClick: handleErrorModalActionButtonClick,
                }),
            );
            setDeletedMarks([]);
            setCanDelete(false);
        }
    }, [
        answerAttempts,
        canDelete,
        currentTab,
        deletedMarks,
        dispatch,
        handleErrorModalActionButtonClick,
        handleErrorModalCancelButtonClick,
        isTeacher,
        studentTasks,
        tabs,
        taskId,
    ]);

    useEffect(() => {
        if (markPostStatus === FetchStatus.FETCHED) {
            dispatch(getStudentTasksAction({ studentId: +studentId, lessonId: +lessonId }));
        }
    }, [dispatch, lessonId, markPostStatus, studentId]);

    useEffect(
        () => () => {
            dispatch(resetStudentTaskData());
            dispatch(resetProctoringState());
        },
        [dispatch],
    );

    return (
        <HardModal
            key={'student-tasks-view'}
            className={'student-tasks-view-popup'}
            size={'large'}
            placement={'left'}
            getContainer={false}
            title={breadcrumbsHeader}
            customWrapperStyle={{
                width: '100%',
                maxWidth: isTablet ? '100%' : '65%',
                minWidth: isTablet ? 'unset' : '850px',
            }}
            autoFocus={false}
            {...rest}
        >
            <div className={'student-tasks-view-popup__container'}>
                <div className={cnStudentTasks('container')}>
                    <StudentTab
                        tabs={tabs}
                        name={studentName || 'Студент'}
                        source={studentPhoto}
                        selectedTask={currentTab}
                        onTabChange={handleTabChange}
                        onStudentClick={handleStudentClick}
                        onMarkDelete={handleMarkDelete}
                        onMarkChange={handleMarkChange}
                        onMarkAdd={handleMarkAdd}
                        isAdaptation={isAdaptation}
                        onCancel={handleCancel}
                        onAcademicPerfomance={handleMarksClick}
                        isOnline={studentData?.user.online}
                    />
                    <div className={cnStudentTasks('task_container')}>
                        {currentTab !== undefined && (
                            <PageLoader showLoading={taskFetchStatus === FetchStatus.FETCHING} />
                        )}
                        {assignmentId !== undefined && (
                            <StudentTask
                                lessonId={lessonId}
                                assignmentId={assignmentId}
                                assignmentProgressId={currentTab}
                                onTaskCheck={handleTaskCheck}
                                studentId={+studentId}
                                isTeacher
                            />
                        )}
                    </div>
                </div>
            </div>
        </HardModal>
    );
};
export default StudentTasksViewPopup;

import { TrainingSearchBlockFilters } from 'api/services/materials';
import { DifficultyEnum } from 'api/services/questionCreate';
import { QuestionSearchBarFieldsValues } from 'containers/QuestionSearchBar';
import { optionsType } from 'pages/QuestionSearchPage/utils';

import { LessonLocation, SkillLocation } from 'components/Codifier/utils';
import { AnswersTypeEnum } from 'components/QuestionPopUp/QuestionPopUp.types';

export const convertQueryFiltersToPageFilters = (
    queryFilters: Partial<TrainingSearchBlockFilters>,
): QuestionSearchBarFieldsValues => {
    const skillsSelect = queryFilters.skillsAnd?.length ? 'Все' : 'Любой из нижеперечисленных';
    const convertSkills = (skills: number[]) => skills.map((skill) => ({ skillId: skill } as SkillLocation));

    return {
        name: queryFilters.text,
        location: queryFilters.topics?.map((topicId) => (({ topicId } as unknown) as LessonLocation)),
        complexity: queryFilters.difficulty?.map((difficultyItem) => {
            switch (difficultyItem) {
                case DifficultyEnum.medium:
                    return 'Средняя';
                case DifficultyEnum.easy:
                    return 'Низкая';
                case DifficultyEnum.hard:
                    return 'Высокая';
                default:
                    return difficultyItem;
            }
        }),
        types: queryFilters.answersType?.map((answerTypeItem) => {
            switch (answerTypeItem) {
                case AnswersTypeEnum.text:
                    return 'Вопрос - тестовый, с текстовым ответом';
                case AnswersTypeEnum.test:
                    return 'Вопрос - тестовый';
                case AnswersTypeEnum.essay:
                    return 'Вопрос - открытый вопрос - эссе';
                case AnswersTypeEnum.true_false:
                    return 'Вопрос - верно или нет';
                case AnswersTypeEnum.insert_words:
                    return 'Вопрос - вставить слова';
                default:
                    return answerTypeItem;
            }
        }),
        skillsSelect,
        skills: convertSkills(skillsSelect === 'Все' ? queryFilters.skillsAnd || [] : queryFilters.skillsOr || []),
    };
};

export const convertPageFiltersToQueryFilters = (
    pageFilters: QuestionSearchBarFieldsValues,
    excludeIds: number[] = [],
): Partial<TrainingSearchBlockFilters> => ({
    text: pageFilters.name || undefined,
    topics: pageFilters.location?.map((block) => block.topicId) || [],
    difficulty:
        pageFilters.complexity?.length === 3
            ? undefined
            : pageFilters.complexity?.map((item) => {
                  switch (item) {
                      case 'Средняя':
                          return DifficultyEnum.medium;
                      case 'Низкая':
                          return DifficultyEnum.easy;
                      case 'Высокая':
                          return DifficultyEnum.hard;
                      default:
                          return DifficultyEnum.easy;
                  }
              }),
    answersType:
        pageFilters.types?.length === optionsType.length
            ? undefined
            : pageFilters.types?.map((type) => {
                  switch (type) {
                      case 'Вопрос - тестовый, с текстовым ответом':
                          return AnswersTypeEnum.text;
                      case 'Вопрос - тестовый':
                          return AnswersTypeEnum.test;
                      case 'Вопрос - открытый вопрос - эссе':
                          return AnswersTypeEnum.essay;
                      case 'Вопрос - верно или нет':
                          return AnswersTypeEnum.true_false;
                      case 'Вопрос - вставить слова':
                          return AnswersTypeEnum.insert_words;
                      default:
                          return AnswersTypeEnum.text;
                  }
              }),
    excludeIds,
    skillsAnd: pageFilters.skillsSelect === 'Все' ? pageFilters.skills?.map((skill) => skill.skillId) || [] : [],
    skillsOr: pageFilters.skillsSelect === 'Все' ? [] : pageFilters.skills?.map((skill) => skill.skillId) || [],
});

import { postApiRequest } from 'api';
import { ResponseEducationalPlanItem } from 'types/educationalPlan';

import { PostEducationalPlanItem } from '.';

export interface PostItem {
    educationalPlanItems: PostEducationalPlanItem[];
}

export const createEducationalPlanItem = (params: PostItem): Promise<ResponseEducationalPlanItem[]> => {
    return postApiRequest('/educational-plan-item/bulk-create/', params.educationalPlanItems);
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getQuestionsForReview } from 'api/services/question';
import { getQuestionsOnRevision } from 'api/services/question/getQuestionsOnRevision';

export const getQuestionsForReviewAction = createAsyncThunk(
    'questionBank/getQuestionsForReview',
    (parallel?: number) => {
        return getQuestionsForReview(parallel);
    },
);

export const getQuestionsOnRevisionAction = createAsyncThunk('questionBank/getQuestionsOnRevision', () => {
    return getQuestionsOnRevision();
});

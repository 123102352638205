import React from 'react';
import { AppMultipleMonthpicker } from '@frontend-modules/ui-kit';
import dayjs, { Dayjs } from 'dayjs';

import { TComparativeMonthsSelector } from './ComparativeMonthsSelector.types';

export const ComparativeMonthsSelector: React.FC<TComparativeMonthsSelector> = (props) => {
    const { selectedDate, onMonthSelected, defaultDates } = props;
    const currentDate = dayjs();

    const onMonthSelectedLocal = (date?: Dayjs[]) => {
        const list = date ? date.map((item) => item.date(1).format('YYYY-MM-DD')) : [];
        onMonthSelected(list);
    };
    const getDisabledDate = (current: Dayjs) => {
        return (
            current > currentDate || current < currentDate.subtract(5, 'years') || current.isSame(selectedDate, 'month')
        );
    };

    return (
        //TODO: решить проблему с гигантским типом у компонента
        // @ts-ignore
        <AppMultipleMonthpicker
            defaultValue={defaultDates?.map((item) => dayjs(item))}
            onChange={(_, dayjs) => onMonthSelectedLocal(dayjs)}
            label={'Сравнить с'}
            deprecatedValue={selectedDate}
            disabledDate={getDisabledDate}
        />
    );
};

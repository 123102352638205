import { ContentMaterialTypeEnum, IMaterial } from 'types/materials';

export const getMaterialsFilesPost = (material: IMaterial): File | undefined => {
    switch (material.type) {
        case ContentMaterialTypeEnum.audio:
            return material.audioData.file instanceof File ? material.audioData.file : undefined;
        case ContentMaterialTypeEnum.video:
        case ContentMaterialTypeEnum.kinescope:
            return material.videoData.file instanceof File ? material.videoData.file : undefined;
        default:
            return undefined;
    }
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { CreateNote, deleteNote, EditNote, getNotes, patchNote, postNote } from 'api/services/notes';

export const getNotesAction = createAsyncThunk('note/getNotes', async (studentId: number) => {
    return await getNotes(studentId);
});

export const createNoteAction = createAsyncThunk('note/createNote', async (values: CreateNote) => {
    return postNote(values);
});

export const changeNoteAction = createAsyncThunk('note/changeNote', async (values: EditNote) => {
    return await patchNote(values);
});

export const deleteNoteAction = createAsyncThunk('note/deleteNote', async (id: number) => {
    const result = await deleteNote(id);
    return { ...result, id };
});

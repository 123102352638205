import { CodifierResponseData, CodifierSkillsResponseData } from 'api/services/codifier';
import { ResponseQuestionDetailed } from 'api/services/questionBank';
import { Answer } from 'api/services/questionCreate';
import { QuestionSearchBarFieldsValues } from 'containers/QuestionSearchBar';
import { SearchPageState } from 'store/actions/questionCreate';
import { TestQuestionData, TextQuestionData } from 'types/question';

import { getLessonLocation, getSkillLocation } from 'components/Codifier/utils';
import { AnswersTypeEnum } from 'components/QuestionPopUp/QuestionPopUp.types';

export const optionsType = [
    'Вопрос - верно или нет',
    'Вопрос - тестовый, с текстовым ответом',
    'Вопрос - тестовый',
    'Вопрос - открытый вопрос - эссе',
    'Вопрос - вставить слова',
];

export const validators = {
    locationValidators: [(value: string): string | undefined => (value ? undefined : 'error')],
    complexityValidators: [
        (value: string): string | undefined => (value && value !== 'Не выбрано' ? undefined : 'error'),
    ],
    typeValidators: [(value: string): string | undefined => (value && value !== 'Не выбрано' ? undefined : 'error')],
};

export const getAnswers = (question: ResponseQuestionDetailed): Answer[] => {
    const { answersType, data } = question;

    switch (answersType) {
        case AnswersTypeEnum.test:
            return (data as TestQuestionData).answerOptions?.map((option, index) => ({
                ...option,
                id: index,
                order: index,
                isCorrect: option.correct,
                acceptFile: false,
                acceptText: false,
                percentOfScore: 100,
            }));
        case AnswersTypeEnum.text:
            return (data as TextQuestionData).answerOptions?.map((option, index) => ({
                ...option,
                content: '{}',
                html: `<span>${option.text}</span>`,
                id: index,
                order: index,
                isCorrect: true,
                acceptFile: false,
                acceptText: false,
                percentOfScore: option.percentOfScore,
            }));
        default:
            return [];
    }
};

export const updateFormValues = (
    codifierTree: CodifierResponseData[],
    skillsTree: CodifierSkillsResponseData[],
    formValues: SearchPageState[],
): SearchPageState[] => {
    return formValues.map((value) => {
        const filters: QuestionSearchBarFieldsValues[] = [];
        const rightSearchBlock = value.rightSearchBlock.map((block) => {
            const location = block.filters?.location?.map(({ topicId }) => getLessonLocation(codifierTree, topicId));
            const skills = block.filters?.skills?.map(({ skillId }) => getSkillLocation(skillsTree, skillId));

            const newFilters = {
                ...block.filters,
                location,
                skills,
            };

            filters.push(newFilters);

            return {
                ...block,
                filters: newFilters,
            };
        });

        return {
            ...value,
            filters,
            rightSearchBlock,
            leftSearchBlock: rightSearchBlock,
        };
    });
};

import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const useGetThemeQuery = (initValue: number): [number, (themeId: number | ((id: number) => number)) => void] => {
    const history = useHistory();
    const location = useLocation();

    const themeId = useMemo(() => {
        const theme = new URLSearchParams(location.search).get('theme');
        const themeId = Number(theme);

        if (!theme || isNaN(themeId)) {
            return initValue;
        }

        return themeId;
    }, [initValue, location]);

    const setThemeId = useCallback(
        (callback: number | ((id: number) => number)) => {
            const searchParams = new URLSearchParams(location.search);

            searchParams.set('theme', String(typeof callback === 'number' ? callback : callback(themeId)));

            history.replace(`${location.pathname}?${searchParams.toString()}`);
        },
        [history, location.pathname, location.search, themeId],
    );

    return [themeId, setThemeId];
};

import React from 'react';
import { cn } from '@bem-react/classname';
import { AppText, AppTextareaInput } from '@frontend-modules/ui-kit';

import { IOpenAnswerExerciseProps } from './OpenAnswerExercise.types';

import './OpenAnswerExercise.scss';

const CnOpenAnswerExercise = cn('open-answer-exercise');

export const OpenAnswerExercise: React.FC<IOpenAnswerExerciseProps> = (props) => {
    const { exerciseInfo, localAnswerValue, correctness, setLocalAnswerValue } = props;

    return (
        <div className={CnOpenAnswerExercise()}>
            {exerciseInfo}
            <AppText text={'*открытый ответ'} textStyle={'DesktopFontBody'} />
            <AppTextareaInput
                disabled={correctness !== undefined}
                placeholder={'Введите ответ'}
                value={localAnswerValue ?? ''}
                onChange={setLocalAnswerValue}
                className={CnOpenAnswerExercise('input', { correctness: correctness })}
            />
        </div>
    );
};

import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { cn } from '@bem-react/classname';
import { StatusLabelEnum } from '@lms-elements/atomic';
import { AttendanceLine, IAttendanceLineProps } from 'containers/AttendanceLine';
import { PageLoader } from 'containers/PageLoader';
import {
    changeAttendanceMarkAction,
    createAttendanceMarkAction,
    deleteAttendanceMarkAction,
    getLessonAttendanceDataAction,
    patchStudentPresenceAction,
} from 'store/actions/attendance';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';
import { MarkScore } from 'types/mark';

import { normalizePresenceValues } from 'utils/presence';

import './AttendanceTab.scss';

const CnAttendanceTab = cn('attendanceTab');
export interface IAttendanceTabProps {
    lessonId: number;
    courseGroupId?: number;
    eventRoomId?: number;
    subjectId?: number;
    onStudentAvatarClick?: (studetnId: number) => void;
    isOvered?: boolean;
    isStudent?: boolean;
}

export const AttendanceTab: React.FC<IAttendanceTabProps> = ({
    lessonId,
    courseGroupId,
    eventRoomId,
    onStudentAvatarClick,
    isOvered,
    isStudent,
}) => {
    const dispatch = useDispatch();
    const { fetchStatus, dataMap: studentsAttendanceMap } = useAppSelector((store) => store.attendance);
    const { info } = useAppSelector((store) => store.user);

    const studentsAttendance = useMemo(
        () =>
            Object.values(studentsAttendanceMap).sort((first, second) =>
                `${first.studentLastName} ${first.studentFirstName}` >
                `${second.studentLastName} ${second.studentFirstName}`
                    ? 1
                    : -1,
            ),
        [studentsAttendanceMap],
    );

    // TODO: need changed logic edit marks?
    const teacherId = useMemo(() => info?.id, [info?.id]);

    const isLoading = fetchStatus === FetchStatus.FETCHING;

    useEffect(() => {
        dispatch(getLessonAttendanceDataAction({ lessonId, courseGroupId, eventRoom: eventRoomId }));
    }, [dispatch, lessonId, courseGroupId, eventRoomId]);

    const presenceSelectHandler = useCallback(
        (id: number, values: { status: StatusLabelEnum; lateMinutes: number }) => {
            const normalizedValues = normalizePresenceValues(values);

            dispatch(patchStudentPresenceAction({ id, values: normalizedValues }));
        },
        [dispatch],
    );

    const createMark = useCallback(
        (attendanceLesson: number, values: { score?: MarkScore; comment?: string }[]) => {
            values.forEach((value) => {
                const newMark = {
                    score: value.score,
                    comment: value.comment || undefined,
                    attendanceLesson,
                };
                dispatch(createAttendanceMarkAction(newMark));
            });
        },
        [dispatch],
    );

    const changeMark = useCallback(
        (studentId: number, values: { score?: MarkScore; comment?: string; markId: number }[]) => {
            values.forEach((value) => {
                const newMark = {
                    markId: value.markId,
                    score: value.score || null,
                    comment: value.comment || undefined,
                };
                dispatch(changeAttendanceMarkAction(newMark));
            });
        },
        [dispatch],
    );

    const deleteMark = useCallback(
        (markId: number, attendanceLesson: number) => {
            dispatch(deleteAttendanceMarkAction({ markId, attendanceLesson }));
        },
        [dispatch],
    );

    const handleAvatarClick = useCallback(
        (studetnId: number) => {
            if (onStudentAvatarClick) {
                onStudentAvatarClick(studetnId);
            }
        },
        [onStudentAvatarClick],
    );

    return (
        <div className={CnAttendanceTab()}>
            <PageLoader showLoading={isLoading} />
            <table className={CnAttendanceTab('table')}>
                <thead className={CnAttendanceTab('tableHead')}>
                    <tr>
                        <th className={CnAttendanceTab('studentsHeader')}>Ученик</th>
                        <th className={CnAttendanceTab('attendanceHeader')}>Посещение урока</th>
                        <th className={CnAttendanceTab('garnituresHeader')}>Камера</th>
                        <th className={CnAttendanceTab('marksHeader')}>Оценки и комментарии</th>
                    </tr>
                </thead>
                <tbody>
                    {fetchStatus === FetchStatus.FETCHED &&
                        studentsAttendance.map((student: IAttendanceLineProps) => (
                            <AttendanceLine
                                key={student.id}
                                {...student}
                                teacherId={teacherId}
                                presenceSelectHandler={presenceSelectHandler}
                                createMark={createMark}
                                changeMark={changeMark}
                                onDeleteMark={deleteMark}
                                onAvatarClick={handleAvatarClick.bind(null, Number(student.studentId))}
                                isOvered={isOvered}
                                isStudent={isStudent}
                                isOnline={student.isOnline}
                            />
                        ))}
                </tbody>
            </table>
        </div>
    );
};

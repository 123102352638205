import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { MeetingData, MeetingFileData } from 'api/services/meeting';
import {
    changeMeetingFileAction,
    createMeetingFileAction,
    deleteMeetingFileAction,
    getMeetingFilesAction,
    getMeetingRecordingsAction,
    postMeetingRecordingAction,
} from 'store/actions/meeting';
import { FetchStatus } from 'types/api';

interface MeetingState {
    fetchMeetingStatus: FetchStatus;
    fetchMeetingFilesStatus: FetchStatus;
    meeting: {
        [scheduleLesson: number]: MeetingData;
    };
    meetingFiles: MeetingFileData[];
    error: unknown;
}

const initialState: MeetingState = {
    fetchMeetingStatus: FetchStatus.INITIAL,
    fetchMeetingFilesStatus: FetchStatus.INITIAL,
    meeting: {},
    meetingFiles: [],
    error: null,
};

export const meetingSlice = createSlice<MeetingState, SliceCaseReducers<MeetingState>>({
    name: 'meeting',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMeetingRecordingsAction.pending, (state) => {
                state.fetchMeetingStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getMeetingRecordingsAction.fulfilled, (state, { payload }) => {
                const { lessonSchedule, eventRoom, ...meetingData } = payload;

                if (meetingData.error) {
                    state.fetchMeetingStatus = FetchStatus.ERROR;
                    state.error = meetingData.error;
                } else {
                    state.fetchMeetingStatus = FetchStatus.FETCHED;
                    if (eventRoom) state.meeting[eventRoom] = meetingData as MeetingData;
                    if (lessonSchedule) state.meeting[lessonSchedule] = meetingData as MeetingData;
                }
            })
            .addCase(getMeetingRecordingsAction.rejected, (state, { error }) => {
                state.fetchMeetingStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(postMeetingRecordingAction.pending, (state) => {
                state.error = null;
            })
            .addCase(postMeetingRecordingAction.fulfilled, (state, { payload }) => {
                const { lessonSchedule, ...meetingData } = payload;
                state.meeting[lessonSchedule] = {
                    ...state.meeting[lessonSchedule],
                    ...meetingData,
                };
            })
            .addCase(postMeetingRecordingAction.rejected, (state, { error }) => {
                state.error = error;
            });

        builder
            .addCase(getMeetingFilesAction.pending, (state) => {
                state.fetchMeetingFilesStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getMeetingFilesAction.fulfilled, (state, { payload }) => {
                state.fetchMeetingFilesStatus = FetchStatus.FETCHED;
                state.meetingFiles = payload;
            })
            .addCase(getMeetingFilesAction.rejected, (state, { error }) => {
                state.fetchMeetingFilesStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder.addCase(createMeetingFileAction.fulfilled, (state, { payload }) => {
            state.meetingFiles = [...state.meetingFiles, payload];
        });
        builder.addCase(deleteMeetingFileAction.fulfilled, (state, { payload }) => {
            const { fileId } = payload;

            state.meetingFiles = state.meetingFiles.filter((file) => file.id !== fileId);
        });
        builder.addCase(changeMeetingFileAction.fulfilled, (state, { payload }) => {
            state.meetingFiles = state.meetingFiles.map((file) => {
                if (file.id === payload.id) {
                    return payload;
                }

                return file;
            });
        });
    },
});

export const resetMeetingState = meetingSlice.actions.reset as ActionCreatorWithoutPayload;
export const meetingReducer = meetingSlice.reducer;

import React, { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    AuthCard,
    AuthFooter,
    AuthHeader,
    AuthorizationLayout,
    ChangePasswordCard,
    ResetPasswordCard,
} from '@frontend-modules/elements';
import { AppText } from '@frontend-modules/ui-kit';
import { CheckCircle, LogoWithTextSvg } from 'assets';
import { useQuery } from 'hooks';
import { AuthBanner } from 'src-new/components/auth/AuthBanner';
import { changePasswordAction, resetPasswordAction, validateResetToken } from 'store/actions/passwordRecovery';
import {
    resetPasswordRecoveryErrors,
    resetPasswordRecoveryStatuses,
    setPasswordRecoveryEmail,
} from 'store/reducers/passwordRecovery';
import { useAppDispatch, useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';

const REDIRECT_TIMEOUT = 5000;

/** */
export const PasswordRecovery: FC = () => {
    const router = useHistory();
    const dispatch = useAppDispatch();
    const {
        passwordRecoveryEmail,
        resetPasswordStatus,
        changePasswordStatus,
        resetPasswordError,
        changePasswordError,
    } = useAppSelector((state) => state.passwordRecovery) || {};
    const [tokenError, setTokenError] = useState(false);

    const query = useQuery();
    const token = useMemo(() => query.get('token'), [query]);

    useEffect(() => {
        if (token) {
            void dispatch(validateResetToken(token)).then((res) => {
                if (res.meta.requestStatus === 'rejected') {
                    setTokenError(true);
                    setTimeout(() => router.push('/signin'), REDIRECT_TIMEOUT);
                }
            });
        }
    }, [dispatch, router, token]);

    const localSetPasswordRecoveryEmail = (email: string) => {
        dispatch(setPasswordRecoveryEmail(email));
    };
    const localResetPasswordRecoveryStatuses = () => {
        dispatch(resetPasswordRecoveryStatuses());
    };
    const localChangePasswordAction = (params: any) => {
        void dispatch(changePasswordAction(params));
    };

    const localResetPasswordAction = (params: any) => {
        void dispatch(resetPasswordAction({ ...params, realm: 'LMS' }));
    };

    useEffect(() => {
        return () => {
            void dispatch(resetPasswordRecoveryErrors());
        };
    }, []);

    return (
        <AuthorizationLayout banner={<AuthBanner />}>
            <AuthHeader logoWithText={<LogoWithTextSvg />} />
            {tokenError ? (
                <AuthCard isMobile>
                    <AppText text={'Ссылка недействительна, запросите новую'} textStyle={'DesktopH4'} />
                </AuthCard>
            ) : token ? (
                <ChangePasswordCard
                    token={`${token}`}
                    changePasswordAction={localChangePasswordAction}
                    resetPasswordRecoveryStatuses={localResetPasswordRecoveryStatuses}
                    loginPath={'/signin'}
                    router={router}
                    isLoading={changePasswordStatus === FetchStatus.FETCHING}
                    isComplete={changePasswordStatus === FetchStatus.FETCHED}
                    checkImage={<CheckCircle />}
                    changePassErrors={changePasswordError}
                />
            ) : (
                <ResetPasswordCard
                    setPasswordRecoveryEmail={localSetPasswordRecoveryEmail}
                    resetPasswordRecoveryStatuses={localResetPasswordRecoveryStatuses}
                    resetPasswordAction={localResetPasswordAction}
                    passwordRecoveryEmail={passwordRecoveryEmail}
                    loginPath={'/signin'}
                    router={router}
                    isLoading={resetPasswordStatus === FetchStatus.FETCHING}
                    isComplete={resetPasswordStatus === FetchStatus.FETCHED}
                    resetPasswordError={resetPasswordError}
                />
            )}
            <AuthFooter router={router} />
        </AuthorizationLayout>
    );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getScheduleByDateRange, getUserScheduleByDateRange } from 'api/services/schedule';

import { convertDateToHtmlDateString } from 'utils/date';

export const fetchSchedule = createAsyncThunk('schedule/fetchSchedule', (date: Date) => {
    const formatedDate = convertDateToHtmlDateString(date);
    return getScheduleByDateRange(formatedDate, formatedDate).then((scheduleData) => ({
        scheduleData,
        date: formatedDate,
    }));
});

type GetScheduleParams = {
    start: string;
    end: string;
};

export const getScheduleByDateRangeAction = createAsyncThunk(
    'schedule/getSchedule',
    ({ start, end }: GetScheduleParams) => {
        return getScheduleByDateRange(start, end, true);
    },
);

type GetUserScheduleParams = GetScheduleParams & { userId: number };

export const getUserScheduleAction = createAsyncThunk(
    'schedule/getUserShedule',
    ({ userId, start, end }: GetUserScheduleParams) => {
        return getUserScheduleByDateRange(userId, start, end, true);
    },
);

import { useEffect } from 'react';

export const useCheckPackageVersion = (): void => {
    useEffect(() => {
        const handleWindowFocus = () => {
            fetch('/version.json')
                .then((res) => res.json())
                .then((data: { version: string }) => {
                    if (data?.version === process.env.REACT_APP_VERSION || (!data && !process.env.REACT_APP_VERSION)) {
                        return;
                    }

                    (window.location.reload as (force?: boolean) => void)(true);
                })
                .catch(() => console.error('не найден файл!'));
        };

        window.addEventListener('focus', handleWindowFocus, true);

        return () => {
            window.removeEventListener('focus', handleWindowFocus, true);
        };
    });
};

import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Entity } from 'draft-js';
import { getSelectionEntity, getSelectionText } from 'draftjs-utils';
import { AddInsertionPopUp } from 'src-new/components/lms-elements/CustomEditor/components/InsertWord/AddInsertionPopUp';
import { InsertWordPopUp } from 'src-new/components/lms-elements/CustomEditor/components/InsertWord/InsertWordPopUp';
import { InsertWordData } from 'src-new/components/lms-elements/CustomEditor/types/entities.types';
import { EntityTypes } from 'src-new/components/lms-elements/CustomEditor/types/shared.types';
import {
    addEntity,
    createRectRelativeToScroll,
    isPhraseSelected,
    isRectAInRectB,
    removeEntityFromSelection,
} from 'src-new/components/lms-elements/CustomEditor/utils';

import { IInsertWordProviderProps, PopupType, Position } from './InsertWordProvider.types';

export const InsertWordProvider: React.FC<IInsertWordProviderProps> = ({
    editorState,
    onChange,
    editorRect,
    containerRef,
}) => {
    const [popupPosition, setPopupPosition] = useState<Position | undefined>(undefined);
    const [popupType, setPopupType] = useState<PopupType>(PopupType.CREATE);
    const [currentInsertationOptions, setCurrentInsertationOptions] = useState<string[]>(['']);
    const [selectedText, setSelectedText] = useState('');
    const [inPopup, setInPopup] = useState(false);

    const discardChanges = useCallback(() => {
        setPopupType(PopupType.CREATE);
        setCurrentInsertationOptions(['']);
        setSelectedText('');
        setPopupPosition(undefined);
        setInPopup(() => false);
    }, []);

    const handlePopupPosition = useCallback(
        (scrollTop: number, scrollLeft: number, top: number, left: number) => {
            if (inPopup) {
                return;
            }

            if (!isPhraseSelected(editorState) && !getSelectionEntity(editorState)) {
                discardChanges();
                return;
            }

            const winSelection = window.getSelection();
            if (!winSelection?.rangeCount || winSelection?.rangeCount <= 0 || winSelection.type === 'Caret') {
                return;
            }

            const selectionRect = window.getSelection()?.getRangeAt?.(0).getBoundingClientRect();

            if (!selectionRect || !editorRect || !isRectAInRectB(selectionRect, editorRect)) {
                discardChanges();
                return;
            }

            setSelectedText(getSelectionText(editorState));

            const selectionRectRelativeToScroll = createRectRelativeToScroll(selectionRect, scrollTop, scrollLeft);

            setPopupPosition({
                left: selectionRectRelativeToScroll.left - left + selectionRectRelativeToScroll.width / 2,
                top: selectionRectRelativeToScroll.top - top + selectionRectRelativeToScroll.height,
            });
        },
        [discardChanges, editorRect, editorState, inPopup],
    );

    useEffect(() => {
        const container = containerRef.current;
        if (!container) {
            return;
        }

        const handleResize = () =>
            handlePopupPosition(
                container.scrollTop,
                container.scrollLeft,
                container.getBoundingClientRect().top,
                container.getBoundingClientRect().left,
            );

        window.addEventListener('resize', handleResize, { passive: true });
        container.addEventListener('scroll', handleResize, { passive: true });

        handleResize();
        container.style.position = 'relative';

        return () => {
            window.removeEventListener('resize', handleResize);
            container.removeEventListener('scroll', handleResize);
        };
    }, [containerRef, handlePopupPosition]);

    useEffect(() => {
        const selectedEntity = getSelectionEntity(editorState);

        if (!selectedEntity) {
            setPopupType(PopupType.CREATE);
            setCurrentInsertationOptions(['']);
            return;
        }

        const currentEntity = editorState.getCurrentContent().getEntity(selectedEntity);

        if (currentEntity.getType() === EntityTypes.INSERT_WORD && popupType !== PopupType.UPDATE) {
            const { options } = currentEntity.getData() as InsertWordData;
            setCurrentInsertationOptions(options);
            setPopupType(PopupType.UPDATE);
        }
    }, [editorState, popupType]);

    const createWordInsertEntity = useCallback(() => {
        addEntity({
            editorState,
            onChange,
            entityType: EntityTypes.INSERT_WORD,
            mutability: 'IMMUTABLE',
            text: selectedText,
            entityData: { text: selectedText, options: [getSelectionText(editorState)] },
            selectAfterCreate: true,
            insertSpaceAfter: false,
        });
    }, [editorState, onChange, selectedText]);

    const onSave = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();

            const selectedEntity = getSelectionEntity(editorState);

            const filteredOptions = currentInsertationOptions.filter(Boolean);

            if (filteredOptions.length) {
                Entity.mergeData(selectedEntity, { options: filteredOptions } as InsertWordData);
            } else {
                removeEntityFromSelection({ editorState, onChange });
            }

            discardChanges();
        },
        [currentInsertationOptions, discardChanges, editorState, onChange],
    );

    const onCancel = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();

            const selectedEntity = getSelectionEntity(editorState);
            const currentEntity = editorState.getCurrentContent().getEntity(selectedEntity);

            if (currentEntity.getType() === EntityTypes.INSERT_WORD) {
                const { options } = currentEntity.getData() as InsertWordData;
                if (!options.find(Boolean)) {
                    removeEntityFromSelection({ editorState, onChange });
                }
            }
            discardChanges();
        },
        [discardChanges, editorState, onChange],
    );

    useEffect(() => {
        if (!isPhraseSelected(editorState)) {
            setInPopup(false);
        }
    }, [editorState]);

    return containerRef.current
        ? ReactDOM.createPortal(
              <>
                  {popupPosition ? (
                      popupType === PopupType.CREATE ? (
                          <AddInsertionPopUp {...popupPosition} onClick={createWordInsertEntity} />
                      ) : (
                          <InsertWordPopUp
                              {...popupPosition}
                              options={currentInsertationOptions}
                              setOptions={setCurrentInsertationOptions}
                              onSave={onSave}
                              onCancel={onCancel}
                              setInPopup={setInPopup}
                          />
                      )
                  ) : null}
              </>,
              containerRef.current,
          )
        : null;
};

import { api, getApiRequest, patchApiRequest } from 'api';
import { AxiosError, AxiosResponse } from 'axios';

export interface Topic {
    id: number;
    title: string;
    order: number;
    questionsQuantity: number;
    questionsQuantityUnpublished: number;
    questionsQuantityRevision: number;
}

export interface Block {
    id: number;
    title: string;
    order: number;
    section?: number | undefined;
    topics?: Topic[];
}

export interface Section {
    id: number;
    title: string;
    order: number;
    blocks?: Block[];
}

export interface Parallel {
    id: number;
    grade: number;
    sections?: Section[];
}

export interface CodifierResponseData {
    id: number;
    title: string;
    parallels?: Parallel[];
}

export const fetchCodifierData = (): Promise<CodifierResponseData[]> => {
    return api
        .get<never, AxiosResponse<CodifierResponseData[]>>(`/codifier/tree/`)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export interface PostParallelParams {
    grade: number;
    subject: number;
}
interface PostParallelResponse {
    id: number;
    grade: number;
    subject: number;
}

export const postCodifierParallel = (params: PostParallelParams): Promise<PostParallelResponse> => {
    return api
        .post<PostParallelResponse, AxiosResponse<PostParallelResponse>>(`/codifier/parallel/create/`, {
            grade: params.grade,
            subject: params.subject,
        })
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const deleteCodifierParallel = (parallelId: number): Promise<number> => {
    return api
        .delete<number, AxiosResponse<number>>(`/codifier/parallel/delete/${parallelId}/`)
        .then(() => parallelId)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export interface PostSectionParams {
    title: string;
    parallel: number;
}

interface PostSectionResponse {
    id: number;
    order: number;
    title: string;
    parallel: number;
}

export const postCodifierSection = (params: PostSectionParams): Promise<PostSectionResponse> => {
    return api
        .post<PostSectionResponse, AxiosResponse<PostSectionResponse>>(`/codifier/section/create/`, {
            title: params.title,
            parallel: params.parallel,
        })
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const deleteCodifierSection = (sectionId: number): Promise<number> => {
    return api
        .delete<number, AxiosResponse<number>>(`/codifier/section/delete/${sectionId}/`)
        .then(() => sectionId)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

interface PostBlockParams {
    title: string;
    section: number;
}

interface PostBlockResponse {
    id: number;
    title: string;
    section: number;
    order: number;
}

export const postCodifierBlock = (params: PostBlockParams): Promise<PostBlockResponse> => {
    return api
        .post<PostBlockResponse, AxiosResponse<PostBlockResponse>>(`/codifier/block/create/`, {
            title: params.title,
            section: params.section,
        })
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const deleteCodifierBlock = (blockId: number): Promise<number> => {
    return api
        .delete<number, AxiosResponse<number>>(`/codifier/block/delete/${blockId}/`)
        .then(() => blockId)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};
interface PostTopicParams {
    title: string;
    block: number;
    order: number;
}

interface PostTopicResponse {
    id: number;
    title: string;
    order: number;
    section: number;
    block?: number;
}

export const postCodifierTopic = (params: PostTopicParams): Promise<PostTopicResponse> => {
    return api
        .post<PostTopicResponse, AxiosResponse<PostTopicResponse>>(`/codifier/topic/create/`, {
            title: params.title,
            block: params.block,
            order: params.order,
        })
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const deleteCodifierTopic = (topicId: number): Promise<number> => {
    return api
        .delete<number, AxiosResponse<number>>(`/codifier/topic/delete/${topicId}/`)
        .then(() => topicId)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export interface Skill {
    id: number;
    title: string;
    codifier?: string;
}

export interface MetaSubjects {
    id: number;
    title: string;
    skills?: Skill[];
}

export interface ParallelSkills {
    id: number;
    grade: number;
    metasubjects?: MetaSubjects[];
}

export interface CodifierSkillsResponseData {
    id: number;
    title: string;
    parallels?: ParallelSkills[];
}

export const fetchCodifierSkillsData = (): Promise<CodifierSkillsResponseData[]> => {
    return api
        .get<never, AxiosResponse<CodifierSkillsResponseData[]>>(`/codifier/tree/skills/`)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

interface PostSkillParams {
    title: string;
    metasubject: number;
    codifier?: string;
}

interface PostSkillResponse {
    id: number;
    title: string;
    order: number;
    metasubject: number;
    codifier?: string;
}

export const postCodifierSkill = (params: PostSkillParams): Promise<PostSkillResponse> => {
    return api
        .post<PostSkillResponse, AxiosResponse<PostSkillResponse>>(`/codifier/skill/create/`, {
            title: params.title,
            metasubject: params.metasubject,
            codifier: params.codifier,
        })
        .then((res) => res?.data)
        .catch((err: AxiosError<{ error: string }>) => {
            throw err?.response?.data.error;
        });
};

export const deleteCodifierSkill = (skillId: number): Promise<number> => {
    return api
        .delete<number, AxiosResponse<number>>(`/codifier/skill/delete/${skillId}/`)
        .then(() => skillId)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

interface PostMetasubjectParams {
    title: string;
    parallel: number;
}

interface PostMetasubjectResponse {
    id: number;
    title: string;
    order: number;
    parallel: number;
}

export const postCodifierMetasubject = (params: PostMetasubjectParams): Promise<PostMetasubjectResponse> => {
    return api
        .post<PostMetasubjectResponse, AxiosResponse<PostMetasubjectResponse>>(`/codifier/metasubject/create/`, {
            title: params.title,
            parallel: params.parallel,
        })
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const deleteCodifierMetasubject = (metasubjectId: number): Promise<number> => {
    return api
        .delete<number, AxiosResponse<number>>(`/codifier/metasubject/delete/${metasubjectId}/`)
        .then(() => metasubjectId)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const patchCodifierParallel = (value: { id: number; grade: number }): Promise<PostParallelResponse> => {
    return api
        .patch<Promise<{ id: number; grade: number }>, AxiosResponse<PostParallelResponse>>(
            `/codifier/parallel/patial-update/${value.id}/`,
            { grade: value.grade },
        )
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const patchCodifierSection = (value: {
    id: number;
    title?: string;
    parallel?: number;
}): Promise<PostSectionResponse> => {
    return api
        .patch<Promise<{ id: number; title: string; parallel: number }>, AxiosResponse<PostSectionResponse>>(
            `/codifier/section/partial-update/${value.id}/`,
            { title: value.title, parallel: value.parallel },
        )
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const patchCodifierBlock = (value: {
    id: number;
    title?: string;
    section?: number;
}): Promise<PostBlockResponse> => {
    return api
        .patch<Promise<{ id: number; title: string; section: number }>, AxiosResponse<PostBlockResponse>>(
            `/codifier/block/partial-update/${value.id}/`,
            { title: value.title, section: value.section },
        )
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const patchCodifierTopic = (value: {
    id: number;
    title?: string;
    block: number;
}): Promise<PostTopicResponse> => {
    return api
        .patch<
            Promise<{ id: number; title: string; section: number; block: number }>,
            AxiosResponse<PostTopicResponse>
        >(`/codifier/topic/partial-update/${value.id}/`, {
            title: value.title,
            block: value.block,
        })
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const patchCodifierMetasubject = (value: { id: number; title: string }): Promise<PostMetasubjectResponse> => {
    return api
        .patch<Promise<{ id: number; title: string }>, AxiosResponse<PostMetasubjectResponse>>(
            `/codifier/metasubject/partial-update/${value.id}/`,
            { title: value.title },
        )
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const patchCodifierSkill = (value: {
    id: number;
    title: string;
    codifier?: string;
}): Promise<PostSkillResponse> => {
    return api
        .patch<Promise<{ id: number; title: string }>, AxiosResponse<PostSkillResponse>>(
            `/codifier/skill/partial-update/${value.id}/`,
            { title: value.title, codifier: value.codifier },
        )
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export interface CodifierSubject {
    id: number;
    title: string;
    shortName: string;
}

export interface Ordering {
    ordering: number[];
}

export const getCodifierSubjects = (): Promise<CodifierSubject[]> => {
    return getApiRequest('/codifier/subject/');
};

export const getCodifierSubjectData = (subjectId: number): Promise<CodifierResponseData[]> => {
    return getApiRequest(`/codifier/tree/?subject=${subjectId}`);
};

export const getCodifierDataByTopics = (topics: number[]): Promise<CodifierResponseData[]> => {
    return getApiRequest(`/codifier/tree/?topics=${topics.join(',')}`);
};

export const patchSectionOrdering = (value: { id: string; ordering: number[] }): Promise<Ordering> => {
    return patchApiRequest(`/codifier/parallel/${value.id}/section-ordering/`, { ordering: value.ordering });
};

export const patchBlockOrdering = (value: { id: string; ordering: number[] }): Promise<Ordering> => {
    return patchApiRequest(`/codifier/section/${value.id}/block-ordering/`, { ordering: value.ordering });
};

export const patchThemeOrdering = (value: { id: string; ordering: number[] }): Promise<Ordering> => {
    return patchApiRequest(`/codifier/block/${value.id}/topic-ordering/`, { ordering: value.ordering });
};

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { Answer } from '@lms-elements/test-task';
import { IQuestion } from '@lms-elements/test-task/build/@types/packages/TestTask/src/Answer';
import { useDownloadTxtFile } from 'src-new/hooks';
import { error } from 'store/reducers/error';
import { useAppSelector } from 'store/store';

interface AnswerWrapperProps {
    name: string;
    disabled?: boolean;
    needHideFileAddButton?: boolean;
    needValidation?: boolean;
    needHideFileDeleteButton?: boolean;
    isOnCheck?: boolean;
    needQuestionLink?: boolean;
    onDeleteFile: (questionId: number, fileId: number) => void;
    onUploadFile: (
        questionId: number,
        file: File,
        index: number,
        uploadCallback: (index: number, percent: number) => void,
    ) => void;
    isDraft?: boolean;
    onChangeFilesProgress: (progresses: number[]) => void;
}
const CnAnswer = cn('student-task-answer');

export const AnswerWrapper: React.FC<AnswerWrapperProps> = ({
    name,
    disabled,
    needHideFileAddButton,
    needValidation,
    needHideFileDeleteButton,
    isOnCheck,
    needQuestionLink,
    isDraft,
    onDeleteFile,
    onUploadFile,
    onChangeFilesProgress,
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    useDownloadTxtFile(containerRef);

    const dispatch = useDispatch();
    const questionFiles = useAppSelector((state) => state.answerAttempts.questionFiles);
    const { input } = useField<IQuestion & { id: number }>(name);
    const { input: uploadProgressesInput } = useField<number[]>(`${name}.uploadProgresses`, { subscription: {} });
    const [uploadProgresses, setUploadProgresses] = useState<number[]>([]);

    useEffect(() => {
        uploadProgressesInput.onChange(uploadProgresses);
    }, [uploadProgressesInput, uploadProgresses]);

    const history = useHistory();

    const handleFileDelete = useCallback(
        (fileIndex: number) => {
            const fileId = questionFiles[input.value.id]?.files.find(({ index }) => fileIndex === index)?.id;

            if (fileId && onDeleteFile) {
                onDeleteFile(input.value.id, fileId);
            }
        },
        [input.value.id, onDeleteFile, questionFiles],
    );

    const handleUploadProgress = useCallback(
        (index: number, percent: number) => {
            let newProgresses: number[] = [];

            setUploadProgresses((progresses) => {
                progresses[index] = percent;

                const result = [...progresses];
                newProgresses = result;

                return result;
            });

            onChangeFilesProgress(newProgresses);
        },
        [onChangeFilesProgress],
    );

    const handleFileUpload = useCallback(
        (file: File, index: number) => {
            onChangeFilesProgress([0]);
            onUploadFile?.(input.value.id, file, index, handleUploadProgress);
        },
        [handleUploadProgress, input.value.id, onUploadFile],
    );

    const handleQuestionLinkClick = useCallback(
        (id: number) => {
            history.push(`/questions/edit/${id}`);
        },
        [history],
    );

    const handleFileOversize = useCallback(
        (errorMessage: string) => {
            dispatch(
                error({
                    message: errorMessage,
                    extra: {
                        needHideButtons: true,
                        isFrontend: false,
                    },
                }),
            );
        },
        [dispatch],
    );

    return (
        <div className={CnAnswer()} ref={containerRef}>
            <Answer
                name={name}
                questionClassName={CnAnswer('question')}
                optionsClassName={CnAnswer('options')}
                disabled={disabled}
                needHideFileAddButton={needHideFileAddButton}
                correctAnswers={input.value.correctAnswers}
                needValidation={needValidation}
                needHideFileDeleteButton={
                    (isOnCheck && (input.value.file?.length ?? 0) < 2) || needHideFileDeleteButton
                }
                needHideFileSendButton
                withEditor
                randomOrder={input.value.randomOrder}
                needHideFileEditButton
                onDeleteFile={handleFileDelete}
                onUploadFile={handleFileUpload}
                needQuestionLink={needQuestionLink}
                onQuestionLinkClick={() => handleQuestionLinkClick(input.value.id)}
                onFileOversize={handleFileOversize}
                isDraft={isDraft}
                needHideCopyToClipboardButton
            />
        </div>
    );
};

import { useEffect, useState } from 'react';
import { useRole } from 'hooks';
import { TInfo } from 'src-new/components/new-subjects/popups/lesson-popup/LessonPopup.types';
import { useGetSubjectsTree } from 'src-new/hooks/new-subjects/use-get-subjects-tree';
import { isEmptyList } from 'src-new/utils';
import { useAppSelector } from 'store/store';
import { TSelectedCourse } from 'types/newSubjectsTypes';

type TUseGetPopupLessonDataProps = {
    lessonId: number;
    courseGroupId: number;
    taskId?: number;
    courseId?: number;
    isNeedFetch?: boolean;
};
type TUseGetPopupLessonDataResponse = {
    courseInfo: TSelectedCourse | undefined;
    topicInfo: TInfo | undefined;
    lessonInfo: TInfo | undefined;
};
export const useGetPopupLessonData = (props: TUseGetPopupLessonDataProps): TUseGetPopupLessonDataResponse => {
    const { lessonId, courseId, courseGroupId, taskId, isNeedFetch = true } = props;

    const { isStudent, isTeacher } = useRole();
    const { getTreeByUrl } = useGetSubjectsTree();

    const [courseInfo, setCourseInfo] = useState<TSelectedCourse>();
    const [topicInfo, setTopicInfo] = useState<TInfo>();
    const [lessonInfo, setLessonInfo] = useState<TInfo>();
    const [isInfoGets, setIsInfoGets] = useState(false);

    const { lessonsTree, topicsTree, selectedCourse } = useAppSelector((store) => store.newSubject);

    // Первоначально пытаемся получить необходимую информацию из стора
    useEffect(() => {
        if (+courseGroupId === selectedCourse.id || courseId === selectedCourse.courseId) {
            if (courseGroupId && selectedCourse) {
                setCourseInfo(selectedCourse);
            }
            if (lessonId && !isEmptyList(topicsTree)) {
                const topic = topicsTree.find(({ lessons }) => lessons?.includes(+lessonId));
                if (topic) {
                    setTopicInfo({
                        id: topic.id,
                        title: topic.title,
                    });
                }
            }
            if (lessonId && !isEmptyList(lessonsTree)) {
                const lesson = {
                    id: +lessonId,
                    title: lessonsTree.find((item) => item.id === +lessonId)?.title ?? '',
                };
                setLessonInfo(lesson);
            }
        } else if (
            !isEmptyList(topicsTree) &&
            !isEmptyList(lessonsTree) &&
            selectedCourse &&
            lessonId &&
            !courseId &&
            !courseGroupId
        ) {
            //Если курс, тема и урок были получены только исходя из id урока, то просто записываем полученную информацию
            setCourseInfo(selectedCourse);
            const topic = topicsTree.find(({ lessons }) => lessons?.includes(+lessonId));
            if (topic) {
                setTopicInfo({
                    id: topic.id,
                    title: topic.title,
                });
            }
            const lesson = {
                id: +lessonId,
                title: lessonsTree.find((item) => item.id === +lessonId)?.title ?? '',
            };
            setLessonInfo(lesson);
        }
        setIsInfoGets(true);
    }, [lessonId, courseGroupId, courseId, selectedCourse, topicsTree, lessonsTree]);
    // Если необходимой информации в сторе нет, делаем запросы к апи
    useEffect(() => {
        if (!lessonInfo?.title && isInfoGets && (isStudent || isTeacher) && isNeedFetch) {
            const params = {
                course: {
                    id: +courseGroupId,
                    courseId: courseId ? +courseId : undefined,
                },
                topic: topicInfo?.id,
                lesson: +lessonId,
            };

            getTreeByUrl(params);
        }
    }, [isInfoGets, isStudent, isTeacher, courseGroupId]);

    return {
        courseInfo,
        topicInfo,
        lessonInfo,
    };
};

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AppFontIcon } from '@frontend-modules/ui-kit';
import { useRole } from 'hooks';
import {
    LessonInfo,
    LessonsColTabsMemo,
    NewSubjectsCardMemo,
    SubjectsElementsSkeleton,
    TLessonSchedules,
    TLessonsColContent,
    TLessonTab,
    TLessonTabTypes,
    TSubjectLesson,
    WarningSign,
} from 'src-new/components/new-subjects';
import {
    TBorderStyle,
    TBrightnessStyle,
} from 'src-new/components/new-subjects/entities/new-course-card/NewSubjectsCard.types';
import { EMPTY_ARRAY_4, EMPTY_ARRAY_10 } from 'src-new/constants';
import { AppSwitch, AppText } from 'src-new/ui';
import { getLastViewedLessonByCourse, getNearestLessonId, getUid } from 'src-new/utils';
import { isTimeExpired } from 'src-new/utils/date-n-time/getCurrentTime';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';

export const LessonsColContent: React.FC<TLessonsColContent> = (props) => {
    const lessonTarget = useRef<HTMLDivElement>(null);
    const { isTeacher } = useRole();

    const [lessonTypeFilter, setLessonTypeFilter] = useState<TLessonTabTypes | undefined>();
    const [isPastLessonsVisible, setIsPastLessonsVisible] = useState<boolean>(false);

    const { isLessonListLoading, isCurious, handleLessonSelect, handleAddLessonClick, handleEditLessonClick } = props;

    const { lessonsTree, topicsTree, selectedCourse, selectedTopicId, getLessonsTreeStatus } = useAppSelector(
        (store) => store.newSubject,
    );

    const isLessonsListEmpty = getLessonsTreeStatus === FetchStatus.FETCHED && !lessonsTree.length;
    const isWarningVisible = !!selectedCourse?.recalculation && getLessonsTreeStatus === FetchStatus.FETCHED;

    const reserveLessonsCount = useMemo(() => lessonsTree.filter((item) => item.type === 'reserve')?.length, [
        lessonsTree,
    ]);
    const getLessonTypeFilterList = useMemo((): TLessonTab[] => {
        let onlineLessonsCount = 0,
            testLessonsCount = 0,
            selfPreparationCount = 0,
            needAttentionCount = 0;

        lessonsTree.forEach((item) => {
            onlineLessonsCount += item.lessonSchedules.filter((lesson) => lesson.type === 'online_lesson').length;
            testLessonsCount += item.lessonSchedules.filter((lesson) => lesson.type === 'test_lesson').length;
            selfPreparationCount += item.lessonSchedules.filter((lesson) => lesson.type === 'selfpreparation_lesson')
                .length;
            if (isTeacher && item.metrics?.some(({ status }) => status === 'critical')) {
                needAttentionCount += 1;
            } else if (item.progress?.some((lesson) => lesson.status === 'expired')) {
                needAttentionCount += 1;
            }
        });
        return [
            {
                type: 'online_lesson',
                count: onlineLessonsCount,
            },
            {
                type: 'test_lesson',
                count: testLessonsCount,
            },
            {
                type: 'selfpreparation_lesson',
                count: selfPreparationCount,
            },
            {
                type: 'need_attention',
                count: needAttentionCount,
            },
        ];
    }, [lessonsTree]);
    const targetLessonId = useMemo(() => {
        //для любознательного контент скроллится до последнего просмотренного урока или до первого урока в списке
        if (isCurious) {
            const viewedLesson = getLastViewedLessonByCourse(selectedCourse.id);
            return viewedLesson ? viewedLesson.lesson : lessonsTree[0]?.id;
        }

        //для остальных - до ближайшего по времени урока
        return getNearestLessonId(lessonsTree);
    }, [lessonsTree, selectedCourse]);

    // Скролл до нужного урока в списке
    useEffect(() => {
        if (lessonTarget.current && !isLessonListLoading) {
            lessonTarget.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        }
    }, [isLessonListLoading, lessonTarget]);

    // При смене темы сбрасываем установленный фильтр
    useEffect(() => {
        setLessonTypeFilter(undefined);
    }, [selectedTopicId]);

    const handlePastLessonFilterSet = (checked: boolean) => {
        setIsPastLessonsVisible(checked);
    };
    const handleLessonTypeSelected = (type: TLessonTabTypes | undefined) => {
        setLessonTypeFilter(type);
    };

    /**
     * @description getLessonOrderById - возвращает порядковый номер урока,
     * который рассчитывается по индексу урока в topicsTree
     * @param {number} lessonId
     * @return number
     */
    const getLessonOrderById = (lessonId: number): number => {
        const lessonIdsList = topicsTree
            .map(({ lessons }) => lessons)
            .flat(1)
            .filter((item) => !!item);
        return lessonIdsList.findIndex((item) => item === lessonId) + 1;
    };
    /**
     * @description getBorderByLessonType - возвращает цвет обводки для карточки урока:
     * голубой - если выбранный тип урока содержится в списке конкретного урока, иначе - серый
     * @param {TSubjectLesson} lesson
     * @return TBorderStyle
     */
    const getBorderByLessonType = (lesson: TSubjectLesson): TBorderStyle => {
        switch (lessonTypeFilter) {
            case 'online_lesson':
            case 'test_lesson':
            case 'selfpreparation_lesson':
                return lesson.lessonSchedules.find((item) => item.type === lessonTypeFilter) ? 'blue' : 'gray';
            case 'need_attention':
                return isTeacher && lesson.metrics?.some((item) => item.status === 'critical')
                    ? 'blue'
                    : lesson?.progress?.find((item) => item.status === 'expired')
                    ? 'blue'
                    : 'gray';
            default:
                return 'blue';
        }
    };
    /**
     * @description getBrightnessByDate - возвращает тип яркости для карточки урока:
     * яркий - если выбран фильтр isPastLessonsVisible и урок еще не прошел конкретного урока, иначе - тусклый
     * @param {TLessonSchedules[]} lessons
     * @return TBrightnessStyle
     */
    const getBrightnessByDate = (lessons: TLessonSchedules[]): TBrightnessStyle => {
        if (isPastLessonsVisible) {
            const notPastLesson = lessons.find((item) => !isTimeExpired(item.datetimeEnd));
            return notPastLesson ? 'bright' : 'dim';
        }
        return 'bright';
    };

    return (
        <div className={'subjects-table-content__col lesson-col'} id={'lesson-col'}>
            {isLessonListLoading ? (
                <>
                    <div className={'subjects-table-content__col_header'}>
                        <SubjectsElementsSkeleton type={'title'} />
                        {!isCurious && <SubjectsElementsSkeleton type={'title'} />}
                    </div>
                    {!isCurious && (
                        <div className={'tabs-loading-container'} style={{ gap: '4px' }}>
                            {EMPTY_ARRAY_4.map(() => (
                                <SubjectsElementsSkeleton key={getUid()} type={'option'} />
                            ))}
                        </div>
                    )}
                    <div className={'subjects-table-content__col_wrapper'}>
                        <div className={'subjects-table-content__col_wrapper-list'}>
                            {EMPTY_ARRAY_10.map(() => (
                                <SubjectsElementsSkeleton
                                    key={getUid()}
                                    type={!isCurious ? 'lesson-card' : 'course-card'}
                                />
                            ))}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className={'subjects-table-content__col_header'}>
                        <div className={'subjects-table-content__col_header-title'}>
                            <AppText text={`Уроки (${lessonsTree.length})`} fontStyle={'H2'} />
                            {isTeacher && <AppText text={`Резерв: ${reserveLessonsCount}`} fontStyle={'footnotes'} />}
                        </div>
                        {!isCurious && (
                            <div className={'switcher'}>
                                <AppText text={'Прошедшие'} fontStyle={'fontbutton'} />
                                <AppSwitch
                                    checked={isPastLessonsVisible}
                                    withIcons
                                    onChange={(checked) => handlePastLessonFilterSet(checked)}
                                />
                            </div>
                        )}
                    </div>
                    {isWarningVisible && (
                        <WarningSign
                            message={`Тип и время всех уроков после ${getLessonOrderById(
                                selectedCourse?.recalculation ?? 0,
                            )} будут изменены после пересчёта`}
                        />
                    )}
                    {!isCurious && (
                        <LessonsColTabsMemo
                            tabs={getLessonTypeFilterList}
                            selectedTab={lessonTypeFilter}
                            handleTabClick={(type) => {
                                handleLessonTypeSelected(type);
                            }}
                        />
                    )}
                    <div className={'subjects-table-content__col_wrapper'}>
                        {!isLessonsListEmpty ? (
                            <div
                                className={`subjects-table-content__col_wrapper-list ${
                                    isTeacher ? 'with-add-elements' : ''
                                }`}
                            >
                                {lessonsTree.map((value, index) => {
                                    return (
                                        <React.Fragment key={value.id}>
                                            {isTeacher && !index && (
                                                <div className={'add-lesson'}>
                                                    <div
                                                        className={'add-lesson__button'}
                                                        onClick={handleAddLessonClick?.bind(this, index)}
                                                    >
                                                        <AppFontIcon icon={'plus-m'} color={'#ffffff'} />
                                                    </div>
                                                </div>
                                            )}
                                            <div ref={value.id === targetLessonId ? lessonTarget : null}>
                                                <NewSubjectsCardMemo
                                                    title={`${getLessonOrderById(value.id)} урок — ${value.title}`}
                                                    progress={value?.progress}
                                                    brightnessStyle={getBrightnessByDate(value.lessonSchedules)}
                                                    borderStyle={getBorderByLessonType(value)}
                                                    hasHoverTrigger={false}
                                                    metrics={value?.metrics}
                                                    LessonInfo={
                                                        !isCurious ? <LessonInfo lessonInfo={value} /> : undefined
                                                    }
                                                    handleCardClick={() => handleLessonSelect(value.id)}
                                                    handleEditLessonClick={handleEditLessonClick?.bind(this, value)}
                                                    isTeacher={isTeacher}
                                                />
                                            </div>
                                            {isTeacher && (
                                                <div className={'add-lesson'}>
                                                    <div
                                                        className={'add-lesson__button'}
                                                        onClick={handleAddLessonClick?.bind(this, index + 1)}
                                                    >
                                                        <AppFontIcon icon={'plus-m'} color={'#ffffff'} />
                                                    </div>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        ) : (
                            <div className={'subjects-table-content__col_wrapper-empty'}>
                                <AppText text={'Нет ни одного урока'} fontStyle={'H3'} />
                            </div>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
export const LessonsColContentMemo = React.memo(LessonsColContent);

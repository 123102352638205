import { api, getApiRequest } from 'api';
import { AxiosError } from 'axios';

interface OAuthParams {
    clientId: string;
    redirectUri: string;
    responseType: string;
    state: string;
    scope: string;
    csrfmiddlewaretoken?: string;
}

export const checkOAuth = ({ clientId, redirectUri, responseType, ...restParams }: OAuthParams): Promise<unknown> => {
    return getApiRequest('/authorize/', {
        params: {
            ...restParams,
            client_id: clientId,
            redirect_uri: redirectUri,
            response_type: responseType,
        },
    });
};

export const oauth = ({
    clientId,
    redirectUri,
    responseType,
    state,
    scope,
    csrfmiddlewaretoken,
}: OAuthParams): Promise<{ url: string }> => {
    const data = new FormData();

    data.append('client_id', clientId);
    data.append('redirect_uri', redirectUri);
    data.append('response_type', responseType);
    data.append('state', state);
    data.append('scope', scope);
    data.append('allow', 'Authorize');
    if (csrfmiddlewaretoken) {
        data.append('csrfmiddlewaretoken', csrfmiddlewaretoken);
    }

    data.append('nonce', '');
    data.append('code_challenge', '');
    data.append('code_challenge_method', '');
    data.append('claims', '');
    // data.append('csrfmiddlewaretoken', 'oXbbuw7EObFNIXKMPGe21Pb7pVizchEooZOlC4GFOtNegI9QNDpnCCbemVyTi4Zs');

    return api
        .post<{ url: string }>(
            `/authorize/?client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUri}&response_type=${responseType}`,
            data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
                    Accept: 'application/json',
                },
            },
        )
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

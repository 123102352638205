import { createAction } from '@reduxjs/toolkit';
import { ITaskCreateFormValue } from 'pages/SubjectCourse/SubjectCourse';

export const addFormValuesStore = createAction('createTask/addFormValues', (formValues: ITaskCreateFormValue) => {
    return {
        payload: {
            values: formValues,
        },
    };
});

export const resetFormValuesStore = createAction('createTask/resetFormValues', () => {
    return {
        payload: {
            values: {
                title: '',
                description: '',
                counter: 1,
                isTemplate: true,
                type: '',
                markEvaluationType: 'по среднему баллу',
                attemptQuantity: 0,
            },
        },
    };
});

import { TSummaryChartDataItem } from '@fsd-shared/models/statisticsMonitoring';

import { TRadarChartItem, TRadarData, TRadarItem } from './SummaryCriteriaChart.types';

export const getRadarList = (chartData?: TSummaryChartDataItem[]): TRadarItem[] => {
    return chartData
        ? chartData?.map(({ label, color }) => {
              return {
                  title: label,
                  color: color,
              };
          })
        : [];
};

export const getRadarChartData = (chartData?: TSummaryChartDataItem[], isPositive?: boolean): TRadarData => {
    const criterias = chartData?.[0]?.points?.map(({ label }) => label);

    const radarData = criterias
        ? criterias?.map((criteria, index) => {
              const data: TRadarChartItem = {
                  criteriaNum: index + 1,
              };
              chartData?.forEach(({ label, points }, index) => {
                  const target = points.find(({ label }) => label === criteria);

                  data[label] = isPositive
                      ? target?.value.current ?? 0
                      : (target?.value.max ?? 0) - (target?.value.current ?? 0);
              });
              return data;
          })
        : [];

    const maxVal = radarData.reduce((acc, res): number => {
        const { criteriaNum, ...points } = res;
        const max = Math.max(...Object.values({ ...points }));
        acc = max > acc ? max : acc;

        return acc;
    }, 20);
    const ticksStep = maxVal <= 100 ? 10 : maxVal <= 200 ? 20 : 50;

    return {
        data: radarData,
        key: 'criteriaNum',
        ticksCount: Math.trunc(maxVal / ticksStep) + 1,
    };
};

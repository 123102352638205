import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    deleteAssignment,
    fetchAssignments,
    getAssignmentMarks,
    getAssignmentsByLesson,
    getAssignmentTypes,
    patchAssignment,
    PatchAssignmentParams,
    postAssignment,
    PostAssignmentParams,
} from 'api/services/assignment';
import {
    getAssignmentProgressTeachersList,
    getAssignmentStudentMarks,
} from 'api/services/assignment/getAssignmentMarks';
import { getAssignmentProgressInterval } from 'api/services/assignment/getAssignmentProgressInterval';
import {
    createAssignmentSearchBlocks,
    deleteAssignmentSearchBlocksArray,
    DifficultyEnum,
    PatchAsignmentSearchBlockParams,
    patchAssignmentSearchBlock,
    SearchBlockGet,
    SearchBlockPost,
} from 'api/services/questionCreate';

import { AnswersTypeEnum } from 'components/QuestionPopUp/QuestionPopUp.types';

import { SearchPageState, SingleQuestions } from './questionCreate';

export const fetchAssignment = createAsyncThunk('assignment/fetchAssignment', async (id: number) => {
    return await fetchAssignments(id);
});

type PostAssignmentActionParams = {
    assignmentParams: PostAssignmentParams;
    searchBlocksValues: SearchPageState[];
    questions: SingleQuestions[];
};

export const postNewAssignmentAction = createAsyncThunk(
    'assignment/postAssignment',
    async ({ assignmentParams, searchBlocksValues, questions }: PostAssignmentActionParams, { dispatch }) => {
        return await postAssignment(assignmentParams)
            .then(async (assignment) => {
                const assignmentSearchBlocks = searchBlocksValues
                    .flatMap((block) =>
                        block.rightSearchBlock.map((searchBlock) => {
                            const { filters, id } = searchBlock;
                            const questionsIds = searchBlock.questions.map((item) => item.id);
                            const isEmptyQuestionBlock = !questionsIds.some(
                                (question) => !block.excludesIds.includes(question),
                            );

                            const difficulty: DifficultyEnum[] = [];

                            if (filters?.complexity?.length !== 3) {
                                if (filters?.complexity?.includes('Низкая')) {
                                    difficulty.push(DifficultyEnum.easy);
                                }
                                if (filters?.complexity?.includes('Средняя')) {
                                    difficulty.push(DifficultyEnum.medium);
                                }
                                if (filters?.complexity?.includes('Высокая')) {
                                    difficulty.push(DifficultyEnum.hard);
                                }
                            }

                            const answersType: AnswersTypeEnum[] = [];
                            if (filters?.types?.length !== 5) {
                                if (filters?.types?.includes('Вопрос - верно или нет')) {
                                    answersType.push(AnswersTypeEnum.true_false);
                                }
                                if (filters?.types?.includes('Вопрос - тестовый, с текстовым ответом')) {
                                    answersType.push(AnswersTypeEnum.text);
                                }
                                if (filters?.types?.includes('Вопрос - тестовый')) {
                                    answersType.push(AnswersTypeEnum.test);
                                }
                                if (filters?.types?.includes('Вопрос - открытый вопрос - эссе')) {
                                    answersType.push(AnswersTypeEnum.essay);
                                }
                                if (filters?.types?.includes('Вопрос - вставить слова')) {
                                    answersType.push(AnswersTypeEnum.insert_words);
                                }
                            }

                            if (!isEmptyQuestionBlock) {
                                return {
                                    id,
                                    questionNumber: block.questionNum,
                                    assignment: assignment.id,
                                    filters: {
                                        answersType,
                                        text: filters?.name ? filters.name : undefined,
                                        topics: filters?.location ? filters.location.map((item) => item.topicId) : [],
                                        difficulty: difficulty,
                                        skillsOr:
                                            filters?.skillsSelect !== 'Все' && filters?.skills
                                                ? filters.skills.map((skill) => skill.skillId || 0)
                                                : [],
                                        skillsAnd:
                                            filters?.skillsSelect === 'Все' && filters?.skills
                                                ? filters.skills.map((skill) => skill.skillId || 0)
                                                : [],
                                        excludeIds: block.excludesIds,
                                    },
                                };
                            }
                        }),
                    )
                    .filter(Boolean) as SearchBlockPost[];

                const newSearchBlocks = assignmentSearchBlocks
                    .filter((searchBlock) => searchBlock.id === undefined)
                    .concat(
                        questions.flatMap((questionNumber) =>
                            questionNumber.questions.map((question) => ({
                                questionNumber: questionNumber.questionNum,
                                assignment: assignment.id,
                                filters: {
                                    text: undefined,
                                    topics: [],
                                    difficulty: [],
                                    answersType: [],
                                    skillsOr: [],
                                    skillsAnd: [],
                                    excludeIds: [],
                                    id: question.id,
                                },
                            })),
                        ),
                    )
                    .filter((block) => block.questionNumber);

                let postedSearchBlocks: SearchBlockGet[] = [];
                if (newSearchBlocks.length) {
                    const normalizeNewSearchBlocks: SearchBlockPost[] = [];

                    newSearchBlocks
                        .sort((a, b) => a.questionNumber - b.questionNumber)
                        .forEach((item, index) => {
                            if (index) {
                                const diff = item.questionNumber - newSearchBlocks[index - 1].questionNumber;
                                const prevNum = normalizeNewSearchBlocks[index - 1].questionNumber;
                                normalizeNewSearchBlocks.push({
                                    ...item,
                                    questionNumber: diff ? prevNum + 1 : prevNum,
                                });
                            } else if (item.questionNumber !== 1) {
                                normalizeNewSearchBlocks.push({
                                    ...item,
                                    questionNumber: 1,
                                });
                            } else {
                                normalizeNewSearchBlocks.push({ ...item });
                            }
                        });

                    postedSearchBlocks = await createAssignmentSearchBlocks(normalizeNewSearchBlocks);
                }

                return {
                    assignment,
                    postedSearchBlocks,
                };
            })
            .then((result) => {
                void dispatch(
                    getAssignmentsByLessonAction({
                        courseGroup: assignmentParams.courseGroup,
                        lesson: assignmentParams.lesson,
                    }),
                );

                return result;
            });
    },
);

export const deleteAssignmentAction = createAsyncThunk('assignment/delete', async (id: number) => {
    return await deleteAssignment(id);
});

export const getAssignmentMarksAction = createAsyncThunk(
    'assignment/getMarks',
    async (params: { lessonIds: number[]; studentIds: number[] }) => {
        return await getAssignmentMarks(params);
    },
);
export const getAssignmentProgressTeachersListAction = createAsyncThunk(
    'assignment/getAssignmentProgressTeachersList',
    async (id: number) => {
        return await getAssignmentProgressTeachersList(id);
    },
);

export const getAssignmentStudentMarksAction = createAsyncThunk(
    'assignment/getStudentMarks',
    async (params: { mark_date_before: string; mark_date_after: string; student: number }) => {
        return await getAssignmentStudentMarks(params);
    },
);

export const getAssignmentProgressIntervalAction = createAsyncThunk(
    'assignment/getProgressInterval',
    async ({ lessonSchedule, assignmentId }: { lessonSchedule: number; assignmentId: number }) => {
        return {
            interval: await getAssignmentProgressInterval(lessonSchedule, assignmentId),
            assignmentId,
        };
    },
);

type PUpdateAssignmentActionParams = {
    assignmentParams: PatchAssignmentParams;
    searchBlocksValues: SearchPageState[];
    currentSearchBlocks: SearchBlockGet[];
    questions: SingleQuestions[];
    isUpdate: boolean;
};

export const updateAssignmentNewAction = createAsyncThunk(
    'assignment/update',
    async (
        {
            assignmentParams,
            searchBlocksValues,
            questions,
            isUpdate,
            currentSearchBlocks,
        }: PUpdateAssignmentActionParams,
        { dispatch },
    ) => {
        return await patchAssignment(assignmentParams)
            .then(async (assignment) => {
                const assignmentSearchBlocks = searchBlocksValues
                    .flatMap((block) => {
                        return block.rightSearchBlock.map((searchBlock) => {
                            const { filters, id } = searchBlock;
                            const questionsIds = searchBlock.questions.map((item) => item.id);
                            const isEmptyQuestionBlock = !questionsIds.some(
                                (question) => !block.excludesIds.includes(question),
                            );

                            const difficulty: DifficultyEnum[] = [];

                            if (filters?.complexity?.length !== 3) {
                                if (filters?.complexity?.includes('Низкая')) {
                                    difficulty.push(DifficultyEnum.easy);
                                }
                                if (filters?.complexity?.includes('Средняя')) {
                                    difficulty.push(DifficultyEnum.medium);
                                }
                                if (filters?.complexity?.includes('Высокая')) {
                                    difficulty.push(DifficultyEnum.hard);
                                }
                            }

                            const answersType: AnswersTypeEnum[] = [];
                            if (filters?.types?.length !== 5) {
                                if (filters?.types?.includes('Вопрос - верно или нет')) {
                                    answersType.push(AnswersTypeEnum.true_false);
                                }
                                if (filters?.types?.includes('Вопрос - тестовый, с текстовым ответом')) {
                                    answersType.push(AnswersTypeEnum.text);
                                }
                                if (filters?.types?.includes('Вопрос - тестовый')) {
                                    answersType.push(AnswersTypeEnum.test);
                                }
                                if (filters?.types?.includes('Вопрос - открытый вопрос - эссе')) {
                                    answersType.push(AnswersTypeEnum.essay);
                                }
                                if (filters?.types?.includes('Вопрос - вставить слова')) {
                                    answersType.push(AnswersTypeEnum.insert_words);
                                }
                            }

                            if (!isEmptyQuestionBlock) {
                                return {
                                    id,
                                    questionNumber: block.questionNum,
                                    assignment: assignment.id,
                                    filters: {
                                        answersType,
                                        text: filters?.name ? filters.name : undefined,
                                        topics: filters?.location ? filters.location.map((item) => item.topicId) : [],
                                        difficulty: difficulty,
                                        skillsOr:
                                            filters?.skillsSelect !== 'Все' && filters?.skills
                                                ? filters.skills.map((skill) => skill.skillId || 0)
                                                : [],
                                        skillsAnd:
                                            filters?.skillsSelect === 'Все' && filters?.skills
                                                ? filters.skills.map((skill) => skill.skillId || 0)
                                                : [],
                                        excludeIds: block.excludesIds,
                                    },
                                };
                            }
                        });
                    })
                    .filter(Boolean) as SearchBlockPost[];

                const updatedSearchBlocks = assignmentSearchBlocks.filter(
                    (searchBlock) => searchBlock.id !== undefined,
                ) as PatchAsignmentSearchBlockParams[];

                const newSearchBlocks = assignmentSearchBlocks
                    .concat(
                        questions.flatMap((questionNumber) =>
                            questionNumber.questions.map((question) => ({
                                questionNumber: questionNumber.questionNum,
                                assignment: assignment.id,
                                filters: {
                                    text: undefined,
                                    topics: [],
                                    difficulty: [],
                                    answersType: [],
                                    skillsOr: [],
                                    skillsAnd: [],
                                    excludeIds: [],
                                    id: question.id,
                                },
                            })),
                        ),
                    )
                    .filter((block) => block.questionNumber);

                const deleteSearchBlocks = currentSearchBlocks
                    .filter((searchBlock) => searchBlock.assignment === assignment.id)
                    .map((searchBlock) => searchBlock.id) as number[];

                let postedSearchBlocks: SearchBlockGet[] = [];

                if (newSearchBlocks.length) {
                    const normalizeNewSearchBlocks: SearchBlockPost[] = [];

                    newSearchBlocks
                        .sort((a, b) => a.questionNumber - b.questionNumber)
                        .forEach((item, index) => {
                            if (index) {
                                const diff = item.questionNumber - newSearchBlocks[index - 1].questionNumber;
                                const prevNum = normalizeNewSearchBlocks[index - 1].questionNumber;
                                normalizeNewSearchBlocks.push({
                                    ...item,
                                    questionNumber: diff ? prevNum + 1 : prevNum,
                                });
                            } else if (item.questionNumber !== 1) {
                                normalizeNewSearchBlocks.push({
                                    ...item,
                                    questionNumber: 1,
                                });
                            } else {
                                normalizeNewSearchBlocks.push({ ...item });
                            }
                        });

                    postedSearchBlocks = await createAssignmentSearchBlocks(normalizeNewSearchBlocks);
                }

                let patchedSearchBlocks: SearchBlockGet[] = [];
                if (isUpdate) {
                    // dispatch(updateSearchBlocksAction(updatedSearchBlocks));
                    patchedSearchBlocks = await Promise.all(
                        updatedSearchBlocks.map((blockParams) => patchAssignmentSearchBlock(blockParams)),
                    );
                }

                let deletedSearchBlocks: number[] = [];
                if (deleteSearchBlocks.length) {
                    // dispatch(deleteAssignmentSearchBlocksAction(deleteSearchBlocks));
                    deletedSearchBlocks = await deleteAssignmentSearchBlocksArray(
                        deleteSearchBlocks.map((id) => ({ id })),
                    );
                }

                return {
                    assignment,
                    postedSearchBlocks,
                    patchedSearchBlocks,
                    deletedSearchBlocks,
                };
            })
            .then((result) => {
                void dispatch(
                    getAssignmentsByLessonAction({
                        courseGroup: assignmentParams.courseGroup,
                        lesson: result.assignment.lesson,
                    }),
                );

                return result;
            });
    },
);

export const getAssignmentsByLessonAction = createAsyncThunk(
    `assignment/getAssignmentsByLesson`,
    async (params: { lesson: number; courseGroup?: number }) => {
        return getAssignmentsByLesson({ lesson: params.lesson, course_group: params.courseGroup });
    },
);

export const getAssignmentTypesAction = createAsyncThunk('assignment/getAssignmentTypes', () => {
    return getAssignmentTypes();
});

import React from 'react';
import { cn } from '@bem-react/classname';
import { ConfigProvider, Input } from 'antd';

import { IAppInput } from './AppInput.types';

import './style.scss';
const CnAppInput = cn('app-input');

const inputTheme = {
    token: {
        colorPrimary: '#0088E9',
        colorError: '#ea5959',
        colorBorder: '#EDEEF2',
    },
};

export const AppInput = (props: IAppInput): JSX.Element => {
    const { type, isSmall, onChange } = props;

    const onInputNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target;

        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
            onChange?.(e);
        }
    };
    const getInput = () => {
        switch (type) {
            case 'password':
                return <Input.Password {...props} />;
            case 'inputNumber':
                return <Input {...props} onChange={onInputNumberChange} />;
            default:
                return <Input {...props} />;
        }
    };

    return (
        <div className={CnAppInput({ isSmall })}>
            <ConfigProvider theme={inputTheme}>{getInput()}</ConfigProvider>
        </div>
    );
};

import { getApiRequest } from 'api';
import { AttendanceStatus } from 'src-new/components/lms-elements/PerformanceTable/types';

export interface Absence {
    id: number;
    datetimeStart: string;
    status: AttendanceStatus;
    lesson: number;
    course: number;
    subject: number;
}
export const getLessonAbsence = async (params: {
    date_start: string;
    date_end: string;
    student?: number;
    status?: AttendanceStatus[];
}): Promise<Absence[]> => {
    return await getApiRequest(`/attendance-lesson/absence/`, {
        params: {
            date_start: params.date_start,
            date_end: params.date_end,
            student: params.student,
            status: params.status?.join(','),
        },
    });
};

import { PostEducationalPlanItem } from 'api/services/educationalPlan';

export const getNormalizedItemsMap = (
    params: PostEducationalPlanItem[],
    educationalPlan: number,
): PostEducationalPlanItem[] => {
    return params.map((item) => {
        return {
            id: item.id && item.id !== -1 ? item.id : undefined,
            educationalPlan: educationalPlan,
            course: item.course,
            type: item.type,
            yearLessonsCount: Number(item.yearLessonsCount.toFixed()),
            weekLessonsCount: item.weekLessonsCount,
            yearOnlineLessonsCount: Number(item.yearOnlineLessonsCount.toFixed()),
            weekOnlineLessonsCount: item.weekOnlineLessonsCount,
            yearFlowLecturesCount: Number(item.yearFlowLecturesCount.toFixed()),
            weekFlowLecturesCount: item.weekFlowLecturesCount,
            evaluationType: item.evaluationType,
            takeIntoAccount: item.takeIntoAccount,
        };
    });
};

import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { Student } from 'types/students';

type ReturnType = {
    id: number;
    title: string;
    students: Student[];
};

export const getCourseGroupStudents = async (courseGroupId: number): Promise<ReturnType> => {
    return await api
        .get<never, AxiosResponse<ReturnType>>(`/course-groups/${courseGroupId}/students/`)
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { GroupStatistics } from 'api/services/courseGroups/getGroupStatistics';
import { Period } from 'api/services/courseGroups/getPeriods';
import { Topic } from 'api/services/courseGroups/getTopics';
import { PatchCourseGroupsParams } from 'api/services/courseGroups/patchCourseGroups';
import {
    getAllEducationalPlanWithCourseGroupsAction,
    getCourseGroupDetailsAction,
    getCourseGroupDetailsWithStudentsAction,
    getCourseGroupExpelledStudentsAction,
    getCourseGroupsAction,
    getCourseGroupsByAcademicYearAction,
    getCourseGroupsByCourseAction,
    getCourseGroupsBySubjectAndYearAction,
    getCourseGroupStudentsAction,
    getEducationalPlanWithCourseGroupsAction,
    getGroupStatisticsAction,
    getPeriodsAction,
    getPeriodTopicsAction,
    getPeriodTopicsWithStudentAction,
    getTopicsAction,
    patchCourseGroupsAction,
    PeriodTopics,
    resetCourseGroupDataAction,
} from 'store/actions/courseGroup';
import { createLessonAction, deleteLessonAction, handleLessonScheduleAction } from 'store/actions/courseLessons';
import { getCourseGroupsMap } from 'store/normalizers/courseGroup/getCourseGroupMap';
import { FetchStatus } from 'types/api';
import { CourseGroup, CourseGroupExpelledStudents } from 'types/courseGroup';
import { EducationalPlan, ResponseEducationPlan } from 'types/educationalPlan';
import { Student } from 'types/students';

import { insertItemIntoArray } from 'utils/insertItemIntoArray';

interface CourseGroupState {
    fetchGroupsStatus: FetchStatus;
    fetchGroupDetailsStatus: FetchStatus;
    fetchCourseGroupStudentStatus: FetchStatus;
    fetchPeriodTopicsStatus: FetchStatus;
    fetchPeriodStatus: FetchStatus;
    getGroupsWithEduPlanStatus: FetchStatus;
    getPeriodTopicsWithStudentStatus: FetchStatus;
    getGroupStatistics: FetchStatus;
    groupStatistics: GroupStatistics;
    patchStatus: FetchStatus;
    courseGroups: (CourseGroup & { endToEndLessonOrdering?: number[]; students?: Student[] })[];
    courseGroupExpelledStudents: CourseGroupExpelledStudents | null;
    fetchGroupsExpelledStudentsStatus: FetchStatus;
    courseGroupsMap: {
        [groupId: number]: CourseGroup & { endToEndLessonOrdering?: number[]; students?: Student[] };
    };
    periods: Period[];
    periodTopics: PeriodTopics[];
    topics: Topic[];
    educationalPlans: EducationalPlan[];
    eduPlan: ResponseEducationPlan;
    error: unknown;
    patchParams: PatchCourseGroupsParams;
    selectedCourseGroup?: number;
}

const initialState = {
    fetchGroupsStatus: FetchStatus.INITIAL,
    fetchPeriodTopicsStatus: FetchStatus.INITIAL,
    fetchGroupDetailsStatus: FetchStatus.INITIAL,
    fetchCourseGroupStudentStatus: FetchStatus.INITIAL,
    patchStatus: FetchStatus.INITIAL,
    fetchPeriodStatus: FetchStatus.INITIAL,
    getGroupsWithEduPlanStatus: FetchStatus.INITIAL,
    getPeriodTopicsWithStudentStatus: FetchStatus.INITIAL,
    getGroupStatistics: FetchStatus.INITIAL,
    fetchGroupsExpelledStudentsStatus: FetchStatus.INITIAL,
    courseGroups: [],
    courseGroupExpelledStudents: null,
    courseGroupsMap: {},
    periods: [],
    topics: [],
    periodTopics: [],
    groupStatistics: {
        allLessonsCount: 0,
        allOlLessonsCount: 0,
        allSpLessonsCount: 0,
        passedLessonsCount: 0,
        passedOlLessonsCount: 0,
        passedSpLessonsCount: 0,
        reserveLessonsAvailable: 0,
        reserveLessonsCurrent: 0,
        currentTopic: 0,
    },
    eduPlan: {
        id: 0,
        title: '',
        parallel: {
            id: 0,
            title: '',
        },
        calendarPlan: {
            id: 0,
            title: '',
        },
        educationalPlanItems: [],
    },
    educationalPlans: [],
    patchParams: {
        id: 0,
        teachers: [],
    },
    error: null,
};

const courseGroupSlice = createSlice<CourseGroupState, SliceCaseReducers<CourseGroupState>>({
    name: 'courseGroup',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(resetCourseGroupDataAction, (state) => {
                state.courseGroups = [];
            })
            .addCase(getCourseGroupsAction.pending, (state) => {
                state.fetchGroupsStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getCourseGroupsAction.fulfilled, (state, { payload }) => {
                state.fetchGroupsStatus = FetchStatus.FETCHED;
                state.courseGroups = payload;
                state.error = null;
            })
            .addCase(getCourseGroupsAction.rejected, (state, { error }) => {
                state.fetchGroupsStatus = FetchStatus.ERROR;
                state.error = error;
            })
            .addCase(getCourseGroupExpelledStudentsAction.pending, (state) => {
                state.fetchGroupsExpelledStudentsStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getCourseGroupExpelledStudentsAction.fulfilled, (state, { payload }) => {
                state.fetchGroupsExpelledStudentsStatus = FetchStatus.FETCHED;
                state.courseGroupExpelledStudents = payload;
                state.error = null;
            })
            .addCase(getCourseGroupExpelledStudentsAction.rejected, (state, { error }) => {
                state.fetchGroupsExpelledStudentsStatus = FetchStatus.ERROR;
                state.error = error;
            })
            .addCase(getCourseGroupsByAcademicYearAction.pending, (state) => {
                state.fetchGroupsStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getCourseGroupsByAcademicYearAction.fulfilled, (state, { payload }) => {
                state.fetchGroupsStatus = FetchStatus.FETCHED;
                state.courseGroups = payload;
                state.error = null;
            })
            .addCase(getCourseGroupsByAcademicYearAction.rejected, (state, { error }) => {
                state.fetchGroupsStatus = FetchStatus.ERROR;
                state.error = error;
            })

            .addCase(getPeriodsAction.pending, (state) => {
                state.fetchPeriodStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getPeriodsAction.fulfilled, (state, { payload }) => {
                state.fetchPeriodStatus = FetchStatus.FETCHED;
                state.periods = payload;
                state.error = null;
            })

            .addCase(getPeriodsAction.rejected, (state, { error }) => {
                state.fetchPeriodStatus = FetchStatus.ERROR;
                state.error = error;
            })

            .addCase(getTopicsAction.pending, (state) => {
                state.fetchGroupsStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getTopicsAction.fulfilled, (state, { payload }) => {
                state.fetchGroupsStatus = FetchStatus.FETCHED;
                state.topics = payload;
                state.error = null;
            })
            .addCase(getTopicsAction.rejected, (state, { error }) => {
                state.fetchGroupsStatus = FetchStatus.ERROR;
                state.error = error;
            })

            .addCase(getPeriodTopicsAction.pending, (state) => {
                state.fetchPeriodTopicsStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getPeriodTopicsAction.fulfilled, (state, { payload }) => {
                state.fetchPeriodTopicsStatus = FetchStatus.FETCHED;
                state.periodTopics = payload;
                state.error = null;
            })
            .addCase(getPeriodTopicsAction.rejected, (state, { error }) => {
                state.fetchPeriodTopicsStatus = FetchStatus.ERROR;
                state.error = error;
            })

            .addCase(getCourseGroupsBySubjectAndYearAction.pending, (state) => {
                state.fetchGroupsStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getCourseGroupsBySubjectAndYearAction.fulfilled, (state, { payload }) => {
                state.fetchGroupsStatus = FetchStatus.FETCHED;
                state.courseGroups = payload;
                state.error = null;
            })
            .addCase(getCourseGroupsBySubjectAndYearAction.rejected, (state, { error }) => {
                state.fetchGroupsStatus = FetchStatus.ERROR;
                state.error = error;
            })

            .addCase(getCourseGroupsByCourseAction.pending, (state) => {
                state.fetchGroupsStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getCourseGroupsByCourseAction.fulfilled, (state, { payload }) => {
                state.fetchGroupsStatus = FetchStatus.FETCHED;
                state.courseGroups = payload;
                state.error = null;
            })

            .addCase(getCourseGroupsByCourseAction.rejected, (state, { error }) => {
                state.fetchGroupsStatus = FetchStatus.ERROR;
                state.error = error;
            })

            .addCase(getCourseGroupDetailsAction.pending, (state) => {
                state.fetchGroupDetailsStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getCourseGroupDetailsAction.fulfilled, (state, { payload }) => {
                state.fetchGroupDetailsStatus = FetchStatus.FETCHED;
                state.courseGroups = state.courseGroups.find((group) => group.id === payload.id)
                    ? state.courseGroups.map((group) => {
                          if (payload.id === group.id) {
                              return {
                                  ...group,
                                  ...payload,
                              };
                          }
                          return group;
                      })
                    : [payload];
                state.error = null;
            })
            .addCase(getCourseGroupDetailsAction.rejected, (state, { error }) => {
                state.fetchGroupDetailsStatus = FetchStatus.ERROR;
                state.error = error;
            })
            .addCase(createLessonAction.fulfilled, (state, { payload }) => {
                const { order, courseId, id } = payload;
                state.courseGroups = state.courseGroups.map((courseGroup) => {
                    if (courseGroup.course.id === courseId) {
                        const newOrdering = insertItemIntoArray(courseGroup.endToEndLessonOrdering ?? [], id, order);

                        return {
                            ...courseGroup,
                            endToEndLessonOrdering: newOrdering,
                        };
                    }

                    return courseGroup;
                });
            })
            .addCase(deleteLessonAction.fulfilled, (state, { payload }) => {
                const { lessonId, courseId } = payload;

                state.courseGroups = state.courseGroups.map((courseGroup) => {
                    if (courseGroup.course.id === courseId) {
                        const newOrdering = courseGroup.endToEndLessonOrdering?.filter((id) => lessonId !== id) ?? [];

                        return {
                            ...courseGroup,
                            endToEndLessonOrdering: newOrdering,
                        };
                    }

                    return courseGroup;
                });
            })
            .addCase(getCourseGroupStudentsAction.pending, (state) => {
                state.fetchCourseGroupStudentStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getCourseGroupStudentsAction.fulfilled, (state, { payload }) => {
                const { id, students } = payload;
                state.fetchCourseGroupStudentStatus = FetchStatus.FETCHED;
                state.courseGroups = state.courseGroups.map((courseGroup) => {
                    if (courseGroup.id === id) {
                        return {
                            ...courseGroup,
                            students,
                        };
                    }

                    return courseGroup;
                });
                state.error = null;
            })
            .addCase(getCourseGroupStudentsAction.rejected, (state, { error }) => {
                state.fetchCourseGroupStudentStatus = FetchStatus.ERROR;
                state.error = error;
            })
            .addCase(getCourseGroupDetailsWithStudentsAction.pending, (state) => {
                state.fetchGroupDetailsStatus = FetchStatus.FETCHING;
            })
            .addCase(getCourseGroupDetailsWithStudentsAction.fulfilled, (state, { payload }) => {
                state.fetchGroupDetailsStatus = FetchStatus.FETCHED;
                state.courseGroupsMap = getCourseGroupsMap([payload[0]], payload[1]);
            })
            .addCase(patchCourseGroupsAction.rejected, (state, { error }) => {
                state.patchStatus = FetchStatus.ERROR;
                state.error = error;
            })
            .addCase(patchCourseGroupsAction.pending, (state) => {
                state.patchStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(patchCourseGroupsAction.fulfilled, (state, { payload }) => {
                state.patchStatus = FetchStatus.FETCHED;
                state.patchParams = {
                    id: payload.id,
                    teachers: payload.teachers.map((item) => item.id),
                };
                state.error = null;
            })
            .addCase(getAllEducationalPlanWithCourseGroupsAction.rejected, (state, { error }) => {
                state.getGroupsWithEduPlanStatus = FetchStatus.ERROR;
                state.error = error;
            })

            .addCase(getAllEducationalPlanWithCourseGroupsAction.fulfilled, (state, { payload }) => {
                state.getGroupsWithEduPlanStatus = FetchStatus.FETCHED;
                state.courseGroups = payload[0];
                state.educationalPlans = payload[1];
                state.error = null;
            })

            .addCase(getEducationalPlanWithCourseGroupsAction.rejected, (state, { error }) => {
                state.getGroupsWithEduPlanStatus = FetchStatus.ERROR;
                state.error = error;
            })

            .addCase(getEducationalPlanWithCourseGroupsAction.fulfilled, (state, { payload }) => {
                state.getGroupsWithEduPlanStatus = FetchStatus.FETCHED;
                state.courseGroups = payload[0];
                state.eduPlan = payload[1];
                state.error = null;
            })

            .addCase(getPeriodTopicsWithStudentAction.rejected, (state, { error }) => {
                state.getPeriodTopicsWithStudentStatus = FetchStatus.ERROR;
                state.error = error;
            })

            .addCase(getPeriodTopicsWithStudentAction.fulfilled, (state, { payload }) => {
                state.getPeriodTopicsWithStudentStatus = FetchStatus.FETCHED;
                const {
                    groupId,
                    periodTopics,
                    periods,
                    students: { id, students },
                } = payload;
                state.periodTopics = periodTopics;
                state.periods = periods;
                state.selectedCourseGroup = groupId;

                state.courseGroups = state.courseGroups.map((courseGroup) => {
                    if (courseGroup.id === id) {
                        return {
                            ...courseGroup,
                            students,
                        };
                    }

                    return courseGroup;
                });
                state.error = null;
            })

            .addCase(getGroupStatisticsAction.pending, (state) => {
                state.getGroupStatistics = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getGroupStatisticsAction.fulfilled, (state, { payload }) => {
                state.getGroupStatistics = FetchStatus.FETCHED;
                state.groupStatistics = payload;
                state.error = null;
            })

            .addCase(getGroupStatisticsAction.rejected, (state, { error }) => {
                state.getGroupStatistics = FetchStatus.ERROR;
                state.error = error;
            });
    },
});

export const resetCourseGroupState = courseGroupSlice.actions.reset as ActionCreatorWithoutPayload<string>;
export const courseGroupReducer = courseGroupSlice.reducer;

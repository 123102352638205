import React from 'react';
import { cn } from '@bem-react/classname';
import { AppTableCell, IAppTableCellElementData } from 'src-new/ui';

import { IAppTable } from './AppTable.types';

import './AppTable.scss';

const CnAppTable = cn('app-table');

export const AppTable: React.FC<IAppTable> = (props) => {
    const { items, getActiveCellElementData } = props;

    const returnData = (cellData: IAppTableCellElementData) => {
        getActiveCellElementData?.(cellData);
    };

    return (
        <div className={CnAppTable()}>
            {items?.map((row, rowIndex) => (
                <div key={`row-${rowIndex}`} className={CnAppTable('row')}>
                    {row?.map((cell, cellIndex) => (
                        <AppTableCell
                            key={`cell-${rowIndex}-${cellIndex}`}
                            cellIndex={cellIndex}
                            rowIndex={rowIndex}
                            getActiveElementData={returnData}
                            {...cell}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
};

import { ActionCreatorWithoutPayload, createSlice } from '@reduxjs/toolkit';
import { EventRoom, ProctoringEvent } from 'api/services/proctoring';
import {
    changeEventRoomAction,
    createEventRoomAction,
    deleteEventRoomAction,
    getEventRoomDetailsAction,
    getEventRoomsByDates,
    getEventRoomsByLessonAndTypeAction,
    getEventsByDateAction,
    getProctorsAction,
    getStudentEventsAction,
    getStudentsEventRoomsByLessonAndTypeAction,
    joinRoomAction,
    rejoinRoomAction,
} from 'store/actions/proctoring';
import {
    getCourses,
    getProcotors,
    getStudentEvents,
    normalizeEventRooms,
    normalizeEventRoomsRange,
} from 'store/normalizers/proctoring';
import { FetchStatus } from 'types/api';
import { StudentEvent } from 'types/events';
import { StaffProfile } from 'types/staffProfile';
import { User } from 'types/user';

export type RoomsMap = {
    [date: string]: {
        day: string;
        title?: string;
        rooms: RoomCardProps[];
    };
};

export interface RoomCardProps {
    id: number;
    title?: string;
    date: string;
    dayOfWeek: string;
    time: string;
    teachers: string[];
    allPlace: number;
    occupiedPlace: number;
    isLocked?: boolean;
    isJoined?: boolean;
    isRejoined?: boolean;
    course?: string;
    isStudent?: boolean;
    needHideCourse?: boolean;
    needHideButton?: boolean;
    onJoinClick?: (id: number) => void;
    onRejoinClick?: (id: number) => void;
    onEditClick?: (id: number) => void;
    onCardClick?: (id: number) => void;
    students?: User[];
    courseId?: number;
    dayTitle: string;
    datetimeStart: string;
    datetimeEnd: string;
}

interface ProctoringState {
    getEventsStatus: FetchStatus;
    getProctorsStatus: FetchStatus;
    getEventRoomsStatus: FetchStatus;
    createEventRoomStatus: FetchStatus;
    deleteEventRoomStatus: FetchStatus;
    changeEventRoomStatus: FetchStatus;
    getEventRoomsRangeStatus: FetchStatus;
    events: ProctoringEvent[];
    studentEvents: StudentEvent[];
    proctors: StaffProfile[];
    proctorsFromRooms: User[];
    coursesFromRooms: { id: number; title: string }[];
    eventRooms: RoomCardProps[];
    eventRoomsMap: RoomsMap;
    eventRoomDetails?: EventRoom;
}

const initialState: ProctoringState = {
    getEventsStatus: FetchStatus.INITIAL,
    getProctorsStatus: FetchStatus.INITIAL,
    getEventRoomsStatus: FetchStatus.INITIAL,
    createEventRoomStatus: FetchStatus.INITIAL,
    changeEventRoomStatus: FetchStatus.INITIAL,
    deleteEventRoomStatus: FetchStatus.INITIAL,
    getEventRoomsRangeStatus: FetchStatus.INITIAL,
    events: [],
    studentEvents: [],
    eventRooms: [],
    proctorsFromRooms: [],
    coursesFromRooms: [],
    eventRoomsMap: {} as RoomsMap,
    proctors: [],
};

const proctoringSlice = createSlice({
    name: 'proctoring',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEventsByDateAction.pending, (state) => {
                state.getEventsStatus = FetchStatus.FETCHING;
            })
            .addCase(getEventsByDateAction.fulfilled, (state, { payload }) => {
                state.getEventsStatus = FetchStatus.FETCHED;
                state.events = payload;
            })
            .addCase(getEventsByDateAction.rejected, (state) => {
                state.getEventsStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(getProctorsAction.pending, (state) => {
                state.getProctorsStatus = FetchStatus.FETCHING;
            })
            .addCase(getProctorsAction.fulfilled, (state, { payload }) => {
                state.getProctorsStatus = FetchStatus.FETCHED;
                state.proctors = payload;
            })
            .addCase(getProctorsAction.rejected, (state) => {
                state.getProctorsStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(createEventRoomAction.pending, (state) => {
                state.createEventRoomStatus = FetchStatus.FETCHING;
            })
            .addCase(createEventRoomAction.fulfilled, (state, { payload }) => {
                state.createEventRoomStatus = FetchStatus.FETCHED;
                // TODO: нужно добавить обновление state.eventRooms
                state.eventRooms = [...state.eventRooms, ...normalizeEventRooms([payload])];
            })
            .addCase(createEventRoomAction.rejected, (state) => {
                state.createEventRoomStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(deleteEventRoomAction.pending, (state) => {
                state.deleteEventRoomStatus = FetchStatus.FETCHING;
            })
            .addCase(deleteEventRoomAction.fulfilled, (state, { payload: deletedRoomId }) => {
                state.deleteEventRoomStatus = FetchStatus.FETCHED;
                state.eventRooms = state.eventRooms.filter(({ id }) => id !== deletedRoomId);
            })
            .addCase(deleteEventRoomAction.rejected, (state) => {
                state.deleteEventRoomStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(getEventRoomDetailsAction.pending, (state) => {
                state.getEventRoomsStatus = FetchStatus.FETCHING;
            })
            .addCase(getEventRoomDetailsAction.fulfilled, (state, { payload }) => {
                state.getEventRoomsStatus = FetchStatus.FETCHED;
                state.eventRoomDetails = payload;
            })
            .addCase(getEventRoomDetailsAction.rejected, (state) => {
                state.getEventRoomsStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(getEventRoomsByLessonAndTypeAction.pending, (state) => {
                state.getEventRoomsStatus = FetchStatus.FETCHING;
            })
            .addCase(getEventRoomsByLessonAndTypeAction.fulfilled, (state, { payload }) => {
                state.getEventRoomsStatus = FetchStatus.FETCHED;
                state.eventRooms = normalizeEventRooms(payload);
            })
            .addCase(getEventRoomsByLessonAndTypeAction.rejected, (state) => {
                state.getEventRoomsStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(getStudentsEventRoomsByLessonAndTypeAction.pending, (state) => {
                state.getEventRoomsStatus = FetchStatus.FETCHING;
            })
            .addCase(getStudentsEventRoomsByLessonAndTypeAction.fulfilled, (state, { payload }) => {
                state.getEventRoomsStatus = FetchStatus.FETCHED;
                state.eventRooms = normalizeEventRooms(payload[0], payload[1]);
            })
            .addCase(getStudentsEventRoomsByLessonAndTypeAction.rejected, (state) => {
                state.getEventRoomsStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(changeEventRoomAction.pending, (state) => {
                state.changeEventRoomStatus = FetchStatus.FETCHING;
            })
            .addCase(changeEventRoomAction.fulfilled, (state, { payload: newEventRoom }) => {
                state.changeEventRoomStatus = FetchStatus.FETCHED;
                state.eventRooms = [
                    ...state.eventRooms.filter(({ id }) => id !== newEventRoom.id),
                    ...normalizeEventRooms([newEventRoom]),
                ];
            })
            .addCase(changeEventRoomAction.rejected, (state) => {
                state.changeEventRoomStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(rejoinRoomAction.pending, (state) => {
                state.changeEventRoomStatus = FetchStatus.FETCHING;
            })
            .addCase(rejoinRoomAction.fulfilled, (state, { payload }) => {
                state.changeEventRoomStatus = FetchStatus.FETCHED;
                state.eventRooms = normalizeEventRooms(payload[0], payload[1]);
            })
            .addCase(rejoinRoomAction.rejected, (state) => {
                state.changeEventRoomStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(joinRoomAction.pending, (state) => {
                state.changeEventRoomStatus = FetchStatus.FETCHING;
            })
            .addCase(joinRoomAction.fulfilled, (state, { payload }) => {
                state.changeEventRoomStatus = FetchStatus.FETCHED;
                state.eventRooms = normalizeEventRooms(payload[0], payload[1]);
            })
            .addCase(joinRoomAction.rejected, (state) => {
                state.changeEventRoomStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(getEventRoomsByDates.pending, (state) => {
                state.getEventRoomsRangeStatus = FetchStatus.FETCHING;
            })
            .addCase(getEventRoomsByDates.fulfilled, (state, { payload }) => {
                state.getEventRoomsRangeStatus = FetchStatus.FETCHED;
                state.eventRoomsMap = normalizeEventRoomsRange(payload);
                state.proctorsFromRooms = getProcotors(payload);
                state.coursesFromRooms = getCourses(payload);
            })
            .addCase(getEventRoomsByDates.rejected, (state) => {
                state.getEventRoomsRangeStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(getStudentEventsAction.pending, (state) => {
                state.getEventsStatus = FetchStatus.FETCHING;
            })
            .addCase(getStudentEventsAction.fulfilled, (state, { payload }) => {
                state.getEventsStatus = FetchStatus.FETCHED;
                state.studentEvents = getStudentEvents(payload);
            })
            .addCase(getStudentEventsAction.rejected, (state) => {
                state.getEventsStatus = FetchStatus.ERROR;
            });
    },
});

export const resetProctoringState = proctoringSlice.actions.reset as ActionCreatorWithoutPayload;
export const proctoringReducer = proctoringSlice.reducer;

import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { ITaskCreateFormValue } from 'pages/SubjectCourse/SubjectCourse';
import { addFormValuesStore, resetFormValuesStore } from 'store/actions/createTask';

export interface ICreateTaskState {
    formValues: ITaskCreateFormValue;
    questions: string[]; //TODO Next Page
}

export const initialState: ICreateTaskState = {
    formValues: {
        title: '',
        description: '',
        isTemplate: true,
        type: '',
        attemptQuantity: 0,
    },
    questions: [],
};

const createTaskSlice = createSlice<ICreateTaskState, SliceCaseReducers<ICreateTaskState>>({
    name: 'createTask',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(addFormValuesStore, (state, { payload }) => {
            state.formValues = payload.values;
        });
        builder.addCase(resetFormValuesStore, (state, { payload }) => {
            state.formValues = payload.values;
        });
    },
});

export const createTaskReducer = createTaskSlice.reducer;

import i18next from 'i18next';

import { LessonLocation, SkillLocation } from 'components/Codifier/utils';

const getEnding = (num: number) => {
    let end = '';
    if (num % 10 === 1 && Math.floor((num % 100) / 10) !== 1) {
        end = 'а';
    }
    if (num % 10 > 1 && num % 10 < 5 && Math.floor((num % 100) / 10) !== 1) {
        end = 'ы';
    }
    return end;
};

export const getLocationString = (location: LessonLocation[]): string => {
    if (location.length === 0) {
        return '';
    }

    let returnString = `${location.length} тем${getEnding(location.length)}:`;
    location.forEach((value) => {
        const buf = `  ${value.subject}, ${value.parallel} класс, ${value.section}, ${
            value.block ? value.block + ', ' : ''
        } ${value.topic}\n`;
        buf.replace(new RegExp('(^|\\s)(' + value.subject + ')(\\s|$)', 'ig'), '$1<b>$2</b>$3');
        returnString += buf;
    });
    return returnString;
};

export const getLocationSkillString = (location: SkillLocation[]): string => {
    if (location.length === 0) {
        return '';
    }
    let returnString = `${i18next.t('skill', { count: location.length })}:`;
    location.forEach((value) => {
        const buf = `  ${value.subject}, ${value.parallel} класс, ${value.metaArea}, ${value.skill}\n`;
        buf.replace(new RegExp('(^|\\s)(' + value.subject + ')(\\s|$)', 'ig'), '$1<b>$2</b>$3');
        returnString += buf;
    });
    return returnString;
};

export const findRowsCount = (locationString: string, items: number, symbols: number): number => {
    if (!locationString.includes('\n')) {
        return Math.floor(locationString.length / symbols) + 1;
    }
    let rows = items,
        bufLen = 0;
    for (let i = 0; i < locationString.length; i++) {
        if (locationString[i] === '\n') {
            rows += Math.floor(bufLen / symbols);
            bufLen = 0;
        } else {
            bufLen++;
        }
    }
    return rows;
};

import React, { useCallback, useMemo } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { ValidationErrors } from 'final-form';
import arrayMutators from 'final-form-arrays';
import { useFormError } from 'hooks/useFormError';
import { AttendanceStatus } from 'src-new/components/lms-elements/PerformanceTable/types';
import { getAllTasks } from 'src-new/utils';
import { AssignmentProgress, Task } from 'types/homework';

import { HomeworkTable, HomeworkTableFormValues } from 'components/HomeworkTable';

interface AssigningHomeworkFormProps {
    tasks: Task[];
    assignmentProgress?: AssignmentProgress[];
    students: {
        id: number;
        firstName: string;
        lastName: string;
        photoUrl: string;
        status?: AttendanceStatus;
    }[];
    initialValues?: HomeworkTableFormValues;
    onSubmit?: (values: HomeworkTableFormValues) => void;
    onChange?: (values: HomeworkTableFormValues, pristine: boolean) => void;
    onStudentCellClick?: (studentId: number) => void;
    repeatTaskForm?: boolean;
    withAttendance?: boolean;
    needDisableCheckboxes?: boolean;
}

export const AssigningHomeworkForm: React.FC<AssigningHomeworkFormProps> = ({
    tasks,
    students,
    initialValues,
    onSubmit,
    onChange,
    onStudentCellClick,
    repeatTaskForm,
    assignmentProgress,
    withAttendance,
    needDisableCheckboxes,
}) => {
    const handleFormSubmit = useCallback(
        (values) => {
            if (onSubmit) {
                onSubmit(values);
            }
        },
        [onSubmit],
    );

    const hasSelectAllRow = useMemo(
        () => initialValues && Object.prototype.hasOwnProperty.call(initialValues, 'selectAll'),
        [initialValues],
    );

    const allTasks = useMemo(() => getAllTasks(tasks, assignmentProgress), [assignmentProgress, tasks]);

    const handleFormError = useFormError('assigningHomeworkForm');
    const handleFormChange = useCallback(
        (state: { values: HomeworkTableFormValues; pristine: boolean; errors: ValidationErrors }) => {
            handleFormError(state);
            if (onChange) {
                setTimeout(() => onChange(state.values, state.pristine), 0);
            }
        },
        [onChange, handleFormError],
    );

    const validateCheckboxes = useCallback((values: HomeworkTableFormValues) => {
        if (values.students) {
            const taskCheckboxes = values.students.reduce((result, student) => {
                student.tasks.forEach((task) => {
                    if (result[task.taskId]) {
                        result[task.taskId].push(task.checked);
                    } else {
                        result[task.taskId] = [task.checked];
                    }
                });

                return result;
            }, {} as { [taskId: number]: boolean[] });

            const isAnyChecked = Object.entries(taskCheckboxes).reduce((result, entry) => {
                const isChecked = entry[1].reduce((result, value) => result || value, false);

                return result || isChecked;
            }, false);

            if (!isAnyChecked) {
                return { tasks: 'not assigned' };
            }
        }

        return undefined;
    }, []);

    return (
        <Form
            onSubmit={handleFormSubmit}
            initialValues={initialValues}
            mutators={{
                ...arrayMutators,
                setCheckboxes: (task: [{ taskIndex: number; taskId: number; oldValue: boolean }], state, tools) => {
                    if (!task[0].oldValue) {
                        students
                            .map((_, index) => {
                                return `students[${index}].tasks[${task[0].taskIndex}].checked`;
                            })
                            .forEach((field, index) => {
                                const student = students[index];
                                const currentCheckbox = initialValues?.students
                                    .find((student) => student.studentId === students[index].id)
                                    ?.tasks.find((studentTask) => studentTask.taskId === task[0].taskId);
                                tools.changeValue(state, field, () => {
                                    return (
                                        !currentCheckbox?.repeatBlocked ||
                                        (withAttendance && student.status && student.status !== AttendanceStatus.ABSENT)
                                    );
                                });
                            });
                    }
                },
                resetCheckbox: (task: [{ taskIndex: number; oldValue: boolean }], state, tools) => {
                    if (task[0].oldValue) {
                        tools.changeValue(state, `selectAll[${task[0].taskIndex}].checked`, () => {
                            return false;
                        });
                    }
                },
            }}
            validate={validateCheckboxes}
        >
            {(formProps) => (
                <form onSubmit={formProps.handleSubmit}>
                    <HomeworkTable
                        tasks={allTasks}
                        students={students}
                        formProps={formProps}
                        hasSelectAllRow={hasSelectAllRow}
                        onStudentClick={onStudentCellClick}
                        repeatTask={repeatTaskForm}
                        withAttendance={withAttendance}
                        needDisableCheckboxes={needDisableCheckboxes}
                    />
                    <FormSpy
                        subscription={{ values: true, modified: true, pristine: true, errors: true }}
                        onChange={handleFormChange}
                    />
                </form>
            )}
        </Form>
    );
};

import { CodifierResponseData, CodifierSkillsResponseData } from 'api/services/codifier';
import { SelectedElement } from 'containers/CodifierSelector/utils';
import {
    CodifierBlockData,
    CodifierParallelData,
    CodifierSectionData,
    CodifierSubjectData,
    CodifierTopicData,
} from 'store/normalizers/codifier';

import { LessonThemeCodifier, MetaAreas, SectionCodifier, Skills, ThemeCodifier } from './types';

const subjectsTitles: string[] = ['Химия', 'Физика', 'Тригонометрия', 'Алгебра'];
const gradesList: number[] = [5, 6, 7, 8, 9, 10, 11];
const sections: SectionCodifier[] = [
    {
        id: 1,
        grade: 7,
        idSubject: 1,
        title: 'Давление тел',
    },
    {
        id: 2,
        grade: 7,
        idSubject: 1,
        title: 'Электрический ток',
    },
];

const metaAreas: MetaAreas[] = [
    {
        id: 1,
        grade: 7,
        title: 'Мета-предметная область №1',
        numSkills: 3,
    },
    {
        id: 2,
        grade: 7,
        title: 'Мета-предметная область №2',
        numSkills: 20,
    },
    {
        id: 2,
        grade: 7,
        title: 'Мета-предметная область №2',
        numSkills: 7,
    },
];

const skills: Skills[] = [
    {
        id: 1,
        idMetaAreas: 1,
        title:
            '1. Соблюдать правила безопасного труда. бла блаблаблырапрвимсроив рвиаоврпраовисв ир ироымсрмит иофвмицупмкви рывмсроав ирцупейгвсвисрамп',
    },
    {
        id: 2,
        idMetaAreas: 1,
        title:
            '2. оаврполаврмоаимолаи иовпарпангеукупанвмиимт ифпвмпиыфо иовимр иыотслсьмлоплаопл олырвоцрагупагп првыпарвыпаыо шшвошцаршур овпмаврд',
    },
];

const themes: ThemeCodifier[] = [
    {
        id: 1,
        idSection: 1,
        title: 'Давление. Закон Паскаля. Гидростатическое давление',
    },
    {
        id: 2,
        idSection: 1,
        title: 'Давление. Закон Паскаля. Гидростатическое давление',
    },
];
const lessonThemes: LessonThemeCodifier[] = [
    {
        id: 1,
        idTheme: 1,
        idSection: 1,
        title: 'Давление',
        numQuestions: 20,
        numQuestionsUnpublished: 20,
    },
    {
        id: 2,
        idTheme: 1,
        idSection: 1,
        title: 'Закон Паскаля',
        numQuestions: 20,
        numQuestionsUnpublished: 20,
    },
    {
        id: 3,
        idTheme: 1,
        idSection: 1,
        title: 'Гидростатическое давление',
        numQuestions: 0,
        numQuestionsUnpublished: 0,
    },
    {
        id: 4,
        idTheme: 0,
        idSection: 1,
        title: 'Гидростатическое давление 2',
        numQuestions: 3,
        numQuestionsUnpublished: 3,
    },
];

export const getGradesOptions = (grades: number[]): string[] => {
    return gradesList.filter((grade) => !grades.includes(grade)).map((grade) => `${grade} класс`);
};

export const createCodifierSkills = (data: {
    subjectsTitles: string[];
    gradesList: number[];
    metaAreas: MetaAreas[];
    skills: Skills[];
}): CodifierSkillsResponseData[] => {
    return data.subjectsTitles.map((subjectTitle, subjectIndex) => {
        if (subjectTitle === 'Физика') {
            return {
                id: subjectIndex,
                title: subjectTitle,
                parallels: [7, 8, 9].map((grade, gradeIndex) => ({
                    id: gradeIndex,
                    grade,
                    metasubjects: data.metaAreas
                        .filter((sections) => sections.grade === grade)
                        .map((section) => ({
                            id: Number(section.id),
                            title: String(section.title),
                            skills: data.skills
                                .filter((topic) => topic.idMetaAreas === subjectIndex)
                                .map((topic) => ({
                                    id: Number(topic.id),
                                    title: String(topic.title),
                                })),
                        })),
                })),
            };
        }

        return {
            id: subjectIndex,
            title: subjectTitle,
            parallels: [],
        };
    });
};

export const createCodifierData = (data: {
    subjectsTitles: string[];
    gradesList: number[];
    sections: SectionCodifier[];
    themes: ThemeCodifier[];
    lessonThemes: LessonThemeCodifier[];
}): CodifierResponseData[] => {
    return data.subjectsTitles.map((subjectTitle, subjectIndex) => {
        if (subjectTitle === 'Физика') {
            return {
                id: subjectIndex,
                title: subjectTitle,
                parallels: [7, 8, 9].map((grade, gradeIndex) => ({
                    id: gradeIndex,
                    grade,
                    sections: data.sections
                        .filter((section) => section.grade === grade && section.idSubject === subjectIndex)
                        .map((section, sectionIndex) => ({
                            id: section.id,
                            title: section.title,
                            order: sectionIndex,
                            blocks: data.themes
                                .filter((block) => block.idSection === subjectIndex)
                                .map((block, blockOrder) => ({
                                    id: block.id,
                                    title: block.title,
                                    order: blockOrder,
                                    section: section.id,
                                })),
                            topics: data.lessonThemes
                                .filter((topic) => topic.idSection === sectionIndex)
                                .map((topic, topicOrder) => ({
                                    id: topic.id,
                                    title: topic.title,
                                    order: topicOrder,
                                    block: topic.idTheme,
                                    questionsQuantity: topic.numQuestions,
                                    questionsQuantityUnpublished: Number(topic.numQuestionsUnpublished),
                                })),
                        })),
                })),
            };
        }

        return {
            id: subjectIndex,
            title: subjectTitle,
            parallels: [],
        };
    });
};

export const createInitSkills = (): CodifierSkillsResponseData[] => {
    return createCodifierSkills({
        subjectsTitles,
        gradesList,
        metaAreas,
        skills,
    });
};

export const createInitValues = (): CodifierResponseData[] => {
    return createCodifierData({
        subjectsTitles,
        gradesList,
        sections,
        themes,
        lessonThemes,
    });
};

export interface LessonLocation {
    subject: string;
    subjectId: number;
    subjectOrder: number;
    parallel: number;
    parallelId: number;
    parallelOrder: number;
    section: string;
    sectionId: number;
    sectionOrder: number;
    block: string | undefined;
    blockId: number | undefined;
    blockOrder: number | undefined;
    topic: string;
    topicId: number;
    topicOrder: number;
}

export interface SkillLocation {
    subject: string;
    subjectId: number;
    subjectOrder: number;
    parallel: number;
    parallelId: number;
    parallelOrder: number;
    metaArea: string;
    metaAreaId: number;
    metaAreaOrder: number;
    skill: string;
    skillId: number;
    skillOrder: number;
}

export const getSkillLocation = (skills: CodifierSkillsResponseData[], skillId: number): SkillLocation => {
    const location: SkillLocation = {
        subject: '',
        subjectId: 0,
        subjectOrder: 0,
        parallel: 0,
        parallelId: 0,
        parallelOrder: 0,
        metaArea: '',
        metaAreaId: 0,
        metaAreaOrder: 0,
        skill: '',
        skillId: skillId,
        skillOrder: skillId,
    };
    skills.map((subject, indexSub) => {
        subject.parallels?.map((parallel, indexPar) => {
            parallel.metasubjects?.map((meta, indexMeta) => {
                meta.skills?.map((skill, indexSkill) => {
                    if (skill.id === skillId) {
                        location.subject = subject.title;
                        location.subjectId = subject.id;
                        location.subjectOrder = indexSub + 1;
                        location.parallel = parallel.grade;
                        location.parallelId = parallel.id;
                        location.parallelOrder = indexPar + 1;
                        location.metaArea = meta.title;
                        location.metaAreaId = meta.id;
                        location.metaAreaOrder = indexMeta + 1;
                        location.skill = skill.title;
                        location.skillOrder = indexSkill + 1;
                    }
                });
            });
        });
    });
    return location;
};

export const getLessonLocation = (subjects: CodifierResponseData[], lessonId: number): LessonLocation => {
    const location: LessonLocation = {
        subject: '',
        subjectId: 0,
        subjectOrder: 0,
        parallel: 0,
        parallelId: 0,
        parallelOrder: 0,
        section: '',
        sectionId: 0,
        sectionOrder: 0,
        block: '',
        blockId: 0,
        blockOrder: 0,
        topic: '',
        topicId: lessonId,
        topicOrder: lessonId,
    };
    subjects.map((subject, indexSub) => {
        subject.parallels?.map((parallel, indexPar) => {
            parallel.sections?.map((section, indexSec) => {
                section.blocks?.map((block, indexBlock) => {
                    block.topics?.map((topic, indexLes) => {
                        if (topic.id === lessonId) {
                            location.subject = subject.title;
                            location.subjectId = subject.id;
                            location.subjectOrder = indexSub + 1;
                            location.parallel = parallel.grade;
                            location.parallelId = parallel.id;
                            location.parallelOrder = indexPar + 1;
                            location.section = section.title;
                            location.sectionId = section.id;
                            location.sectionOrder = indexSec + 1;
                            location.topic = topic.title;
                            location.topicOrder = indexLes + 1;
                            location.block = block.title;
                            location.blockId = block.id;
                            location.blockOrder = indexBlock;
                        }
                    });
                });
            });
        });
    });
    return location;
};

type SearchParams = {
    subject: number;
    parallel: number;
    section: number;
    block: number;
    topic: number;
};

export const getLocation = (subjects: CodifierResponseData[], params: SearchParams): LessonLocation => {
    const {
        subject: subjectId = 0,
        parallel: parallelId = 0,
        section: sectionId = 0,
        block: blockId = 0,
        topic: topicId = 0,
    } = params;
    const location: LessonLocation = {
        subjectId,
        parallelId,
        sectionId,
        blockId,
        topicId,
        subject: '',
        subjectOrder: 0,
        parallel: 0,
        parallelOrder: 0,
        section: '',
        sectionOrder: 0,
        block: '',
        blockOrder: 0,
        topic: '',
        topicOrder: 0,
    };

    const parallels = subjects.flatMap((subject) => subject.parallels);
    const sections = parallels.flatMap((parallel) => parallel?.sections);
    const blocks = sections.flatMap((section) => section?.blocks);
    const topics = blocks.flatMap((block) => {
        return block?.topics?.map((topic) => {
            return {
                ...topic,
                block: block?.id,
            };
        });
    });

    const topicData = topics.find((topic) => topic?.id === topicId);
    const blockData = blocks.find((block) => block?.id === blockId || block?.id === topicData?.block);
    const sectionData = sections.find(
        (section) =>
            section?.id === sectionId ||
            section?.id === blockData?.section ||
            topics?.some((topic) => topic?.id === topicData?.id),
    );
    const parallelData = parallels.find(
        (parallel) =>
            parallel?.id === parallelId || parallel?.sections?.some((section) => section.id === sectionData?.id),
    );
    const subjectData = subjects.find(
        (subject) =>
            subject.id === subjectId || subject.parallels?.some((parallel) => parallel.id === parallelData?.id),
    );

    location.subjectId = subjectData?.id ?? subjectId;
    location.parallelId = parallelData?.id ?? parallelId;
    location.sectionId = sectionData?.id ?? sectionId;
    location.blockId = blockData?.id ?? blockId;
    location.topicId = topicData?.id ?? topicId;

    return location;
};

type CodifierMap = {
    subjectsMap: {
        [id: number]: CodifierSubjectData;
    };
    parallelsMap: {
        [id: number]: CodifierParallelData;
    };
    sectionsMap: {
        [id: number]: CodifierSectionData;
    };
    blocksMap: {
        [id: number]: CodifierBlockData;
    };
    topicsMap: {
        [id: number]: CodifierTopicData;
    };
};

export const getCodifierSelectorInitValues = (codifierMap: CodifierMap, topics?: number[]): SelectedElement => {
    const element: SelectedElement = {
        subjects: [],
        parallels: [],
        sections: [],
        blocks: [],
        topics: [],
    };

    element.topics = topics?.map((topicId) => {
        if (
            codifierMap.topicsMap[topicId]?.block &&
            !element.blocks?.includes(codifierMap.topicsMap[topicId].block as number)
        ) {
            element.blocks = (element.blocks ?? []).concat([codifierMap.topicsMap[topicId].block as number]);
        }

        if (
            codifierMap.topicsMap[topicId]?.section &&
            !element.sections?.includes(codifierMap.topicsMap[topicId].section as number)
        ) {
            element.sections = (element.sections ?? []).concat([codifierMap.topicsMap[topicId].section as number]);
        }

        return topicId;
    });

    if (element.blocks?.length) {
        element.blocks.forEach((blockId) => {
            if (!element.sections?.includes(codifierMap.blocksMap[blockId].section)) {
                element.sections?.push(codifierMap.blocksMap[blockId].section);
            }
        });
    }

    if (element.sections?.length) {
        element.sections.forEach((sectionId) => {
            if (!element.parallels?.includes(codifierMap.sectionsMap[sectionId].parallel)) {
                element.parallels?.push(codifierMap.sectionsMap[sectionId].parallel);
            }
        });
    }

    if (element.parallels?.length) {
        element.parallels.forEach((parallelId) => {
            if (!element.subjects?.includes(codifierMap.parallelsMap[parallelId].subject)) {
                element.subjects?.push(codifierMap.parallelsMap[parallelId].subject);
            }
        });
    }

    return element;
};

export const getSubject = (codifier: CodifierResponseData[], topicId: number): number => {
    return (
        codifier.find((subject) =>
            subject.parallels?.some((parallel) =>
                parallel.sections?.some((section) =>
                    section.blocks?.some((block) => block.topics?.some((topic) => topic.id === topicId)),
                ),
            ),
        )?.id ?? 0
    );
};

import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from 'store/store';

import { CodifierEventType } from 'components/Codifier/Codifier';

import { CodifierContext } from './context';
import { SubjectElement } from './SubjectElement';
import { getSelectedElements, SelectedElement } from './utils';

interface CodifierSelectorProps {
    onElementSelect?: (type: CodifierEventType, id: number) => void;
    onTopicCheckboxSelect?: (topicId: number) => void;
    initValues?: SelectedElement;
    withCheckboxes?: boolean;
    showSelectSubject?: boolean;
    selectedSubject?: number;
    isShowHeader?: boolean;
}

export const CodifierSelector: React.FC<CodifierSelectorProps> = ({
    onElementSelect,
    withCheckboxes,
    onTopicCheckboxSelect,
    initValues,
    showSelectSubject,
    selectedSubject,
    isShowHeader = true,
}) => {
    const { subjectsIds } = useAppSelector((store) => store.codifier);

    const onElementClick = useCallback(
        (type: CodifierEventType, id: number) => {
            setCodifierContext((prev) => {
                return {
                    ...prev,
                    selectedElement: getSelectedElements(prev.selectedElement, type, id, withCheckboxes),
                };
            });

            onElementSelect?.(type, id);

            if (onTopicCheckboxSelect && withCheckboxes && type === CodifierEventType.TOPIC) {
                onTopicCheckboxSelect(id);
            }
        },
        [onElementSelect, onTopicCheckboxSelect, withCheckboxes],
    );

    const [codifierContext, setCodifierContext] = useState({
        onElementClick,
        selectedElement: initValues ?? {},
        withCheckboxes,
    });

    const [isSubjectUpdates, setIsSubjectUpdates] = useState(false);

    useEffect(() => {
        setIsSubjectUpdates(true);
    }, [selectedSubject]);

    useEffect(() => {
        if (isSubjectUpdates && selectedSubject && withCheckboxes) {
            setCodifierContext((prev) => ({
                ...prev,
                selectedElement: initValues ?? {
                    subjects: [selectedSubject],
                    parallels: [],
                    sections: [],
                    blocks: [],
                    topics: [],
                },
            }));

            setIsSubjectUpdates(false);
        }
    }, [initValues, withCheckboxes, isSubjectUpdates, selectedSubject]);

    return (
        <CodifierContext.Provider value={codifierContext}>
            {subjectsIds
                .filter((subjectId) => (showSelectSubject ? subjectId === selectedSubject : true))
                .map((subjectId) => (
                    <SubjectElement
                        key={subjectId}
                        subjectId={subjectId}
                        isOpened={showSelectSubject}
                        isShowHeader={isShowHeader}
                    />
                ))}
        </CodifierContext.Provider>
    );
};

import React, { useEffect } from 'react';
import { cn } from '@bem-react/classname';
import { AppText } from '@frontend-modules/ui-kit';
import { Checkbox } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';

import { IMultiplyAnswerExerciseOptions, IMultiplyAnswerExerciseProps } from './MultiplyAnswerExercise.types';

import './MultiplyAnswerExercise.scss';

const CnSingleAnswerExercise = cn('single-answer-exercise');

export const MultiplyAnswerExercise: React.FC<IMultiplyAnswerExerciseProps> = (props) => {
    const { localAnswerValue, answerData, exerciseInfo, setLocalAnswerValue } = props;
    const { answerOptions, correctness } = answerData;
    const onChange = (e: CheckboxValueType[]) => {
        setLocalAnswerValue(e);
    };

    useEffect(() => {
        if (!localAnswerValue) {
            const values: CheckboxValueType[] = [];
            answerOptions?.forEach((item: IMultiplyAnswerExerciseOptions) => {
                if (item?.correctness) {
                    values.push(item.value);
                }
            });
            setLocalAnswerValue(values);
        }
    }, [answerOptions]);

    return (
        <div className={CnSingleAnswerExercise({ isCorrect: correctness === 'correct' })}>
            {exerciseInfo}
            <AppText text={'*несколько правильных ответов'} textStyle={'DesktopFontBody'} />
            <Checkbox.Group disabled={correctness !== undefined} onChange={onChange} value={localAnswerValue}>
                <div className={CnSingleAnswerExercise('options')}>
                    {answerOptions?.map((item: IMultiplyAnswerExerciseOptions, index: number) => {
                        return (
                            <div
                                key={`binary-exercise.${index}.${item.value}`}
                                className={CnSingleAnswerExercise('options-item', { type: item?.correctness })}
                            >
                                <Checkbox defaultChecked={!!item.correctness} value={item.value}>
                                    <div
                                        className={CnSingleAnswerExercise('label-text')}
                                        dangerouslySetInnerHTML={{ __html: item.label }}
                                    />
                                </Checkbox>
                            </div>
                        );
                    })}
                </div>
            </Checkbox.Group>
        </div>
    );
};

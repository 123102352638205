import { postApiRequest } from 'api';

interface GetAssignmentProgressIntervalResponse {
    datetimeStart: null | string;
    datetimeEnd: null | string;
}

interface GetAssignmentProgressIntervalBody {
    lesson_schedule: number;
    assignment: number;
}

export const getAssignmentProgressInterval = async (
    lessonSchedule: number,
    assignment: number,
): Promise<GetAssignmentProgressIntervalResponse> => {
    return await postApiRequest<GetAssignmentProgressIntervalResponse, GetAssignmentProgressIntervalBody>(
        '/assignment-progress/get-interval/',
        {
            lesson_schedule: lessonSchedule,
            assignment,
        },
    );
};

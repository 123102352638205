import React from 'react';
import { ConfigProvider, Steps } from 'antd';

import { TAppProgressBarProps } from './AppProgressBarTypes';

const AppProgressBar = (props: TAppProgressBarProps): JSX.Element => {
    const { stepIconSize = 40, ...rest } = props;
    const theme = {
        token: {
            controlHeight: stepIconSize,
            colorTextDescription: 'inherit',
            colorTextLabel: 'inherit',
        },
    };
    return (
        <ConfigProvider theme={theme}>
            <Steps {...rest} />
        </ConfigProvider>
    );
};

export default AppProgressBar;

import { TGroupActionTableItem } from 'src-new/components/group-actions';
import { IActionsStudentItem } from 'types/groupActions';

const normalizeTableData = (data: Array<IActionsStudentItem>): Array<TGroupActionTableItem> => {
    return data?.map((item) => {
        const { lastName, firstName, patronymic, id, ...otherItem } = item;
        return {
            ...otherItem,
            fullname: `${lastName} ${firstName} ${patronymic}`,
            key: id.toString(),
        };
    });
};

export { normalizeTableData };

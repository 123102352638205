import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cn } from '@bem-react/classname';
import { Button, ButtonViewEnum } from '@lms-elements/atomic';
import { DeleteIcon } from '@lms-elements/icons';
import { CodifierResponseData } from 'api/services/codifier';
import { patchCodifierBlockAction, patchCodifierSectionAction, patchCodifierTopicAction } from 'store/actions/codifier';
import { useAppSelector } from 'store/store';

import { CodifierEventType, ReplaceParams } from 'components/Codifier/Codifier';
import { CodifierDropdownGrade } from 'components/CodifierDropdownGrade';
import { CodifierDropdownSection } from 'components/CodifierDropdownSection';

import './ReplaceWindow.scss';

const cnReplaceWindow = cn('modalWindow');

interface ReplaceWindowProps {
    params: ReplaceParams;
    onCloseClick: () => void;
    subjects?: CodifierResponseData[];
}

export const ReplaceWindow: React.FC<ReplaceWindowProps> = ({ params, onCloseClick, subjects }) => {
    const dispatch = useDispatch();
    const { codifierData } = useAppSelector((store) => store.codifier);

    const handleClose = useCallback(() => onCloseClick(), [onCloseClick]);

    const [selectedParallel, setSelectedParallel] = useState(params.parallel);
    const [selectedSection, setSelectedSection] = useState(params.section);
    const [selectedBlock, setSelectedBlock] = useState(params.block);

    const replaceLabels = useMemo(() => {
        switch (params.type) {
            case CodifierEventType.SECTION:
                return {
                    buttonLabel: 'Переместить раздел',
                    title: 'Перемещение раздела',
                    desc: 'Выберите параллель, в которую вы хотите перенести выбранный вами раздел',
                };
            case CodifierEventType.BLOCK:
                return {
                    buttonLabel: 'Переместить блок',
                    title: 'Перемещение блока',
                    desc: 'Выберите раздел, в который вы хотите перенести выбранный вами блок',
                };
            case CodifierEventType.TOPIC:
                return {
                    buttonLabel: 'Переместить тему',
                    title: 'Перемещение темы',
                    desc: 'Выберите раздел и блок, в который вы хотите перенести выбранную вами тему',
                };
            default:
                break;
        }
    }, [params.type]);

    const handleElementSelect = useCallback((type: CodifierEventType, id: number) => {
        switch (type) {
            case CodifierEventType.PARALLEL:
                setSelectedParallel(id);
                break;
            case CodifierEventType.SECTION:
                setSelectedSection(id);
                setSelectedBlock(undefined);
                break;
            case CodifierEventType.BLOCK:
                setSelectedBlock(id);
                break;
        }
    }, []);

    const handleApproveReplace = useCallback(() => {
        if (params.type === CodifierEventType.TOPIC) {
            const oldOrdering = codifierData
                .find((el) => el.parallels?.some((el) => el.id === params.parallel))
                ?.parallels?.find((el) => el.id === params.parallel)
                ?.sections?.find((el) => el.id === params.section)
                ?.blocks?.find((el) => el.id === params.block)
                ?.topics?.map((el) => el.id);

            const order = (oldOrdering?.findIndex((topic) => topic === params.id) ?? 0) + 1;
            const selectedTopic = codifierData
                .find((el) => el.parallels?.some((el) => el.id === params.parallel))
                ?.parallels?.find((el) => el.id === params.parallel)
                ?.sections?.find((el) => el.id === params.section)
                ?.blocks?.find((el) => el.id === params.block)
                ?.topics?.find((el) => el.id === params.id);

            const orderInCurrenSection = codifierData
                .find((el) => el.parallels?.some((el) => el.id === params.parallel))
                ?.parallels?.find((el) => el.id === params.parallel)
                ?.sections?.find((el) => el.id === selectedSection)
                ?.blocks?.find((el) => el.id === selectedBlock)
                ?.topics?.map((el) => el.id);

            if (selectedBlock) {
                dispatch(
                    patchCodifierTopicAction({
                        id: params.id,
                        section: selectedSection,
                        block: selectedBlock,
                        subject: params.subject,
                        parallel: params.parallel,
                        oldSection: params.section,
                        oldBlock: params.block,
                        newPlaceOrdering: orderInCurrenSection,
                        title: selectedTopic?.title,
                        order: order,
                        ordering: oldOrdering,
                    }),
                );
            }

            onCloseClick();
        }
        if (params.type === CodifierEventType.BLOCK) {
            const sectionOrdering = codifierData
                .find((el) => el.parallels?.some((el) => el.id === params.parallel))
                ?.parallels?.find((el) => el.id === params.parallel)
                ?.sections?.find((el) => el.id === selectedSection)
                ?.blocks?.map((el) => el.id);
            const oldSectionOrdering = codifierData
                .find((el) => el.parallels?.some((el) => el.id === params.parallel))
                ?.parallels?.find((el) => el.id === params.parallel)
                ?.sections?.find((el) => el.id === params.section)
                ?.blocks?.map((el) => el.id);

            const selectedBlock = codifierData
                .find((el) => el.parallels?.some((el) => el.id === params.parallel))
                ?.parallels?.find((el) => el.id === params.parallel)
                ?.sections?.find((el) => el.id === params.section)
                ?.blocks?.find((el) => el.id === params.id);

            dispatch(
                patchCodifierBlockAction({
                    id: params.id,
                    section: selectedSection,
                    sectionOrdering: sectionOrdering,
                    subject: params.subject,
                    parallel: params.parallel,
                    oldSection: params.section,
                    title: selectedBlock?.title,
                    order: selectedBlock?.order,
                    ordering: oldSectionOrdering,
                }),
            );
            onCloseClick();
        }
        if (params.type === CodifierEventType.SECTION) {
            const selectedSection = codifierData
                .find((el) => el.parallels?.some((el) => el.id === params.parallel))
                ?.parallels?.find((el) => el.id === params.parallel)
                ?.sections?.find((el) => el.id === params.section);

            const targetParallelOrder = codifierData
                .find((el) => el.parallels?.some((el) => el.id === params.parallel))
                ?.parallels?.find((el) => el.id === selectedParallel)
                ?.sections?.map((el) => el.id);

            const currentParallelOrder = codifierData
                .find((el) => el.parallels?.some((el) => el.id === params.parallel))
                ?.parallels?.find((el) => el.id === params.parallel)
                ?.sections?.map((el) => el.id);

            dispatch(
                patchCodifierSectionAction({
                    id: params.id,
                    parallel: selectedParallel,
                    subject: params.subject,
                    oldParallel: params.parallel,
                    title: selectedSection?.title,
                    parallelOrdering: targetParallelOrder,
                    ordering: currentParallelOrder,
                    order: selectedSection?.order,
                }),
            );
            onCloseClick();
        }
    }, [
        dispatch,
        onCloseClick,
        params.block,
        params.id,
        params.parallel,
        params.section,
        params.subject,
        params.type,
        selectedBlock,
        selectedParallel,
        selectedSection,
    ]);

    const parallels = useMemo(() => subjects?.find((el) => el.id === params.subject)?.parallels, [
        params.subject,
        subjects,
    ]);

    const sections = useMemo(
        () =>
            subjects?.find((el) => el.id === params.subject)?.parallels?.find((el) => el.id === params.parallel)
                ?.sections,
        [params.parallel, params.subject, subjects],
    );

    return (
        <div className={cnReplaceWindow('')}>
            <div className={cnReplaceWindow('deleteIcon')}>
                <DeleteIcon onClick={handleClose} />
            </div>
            <div className={cnReplaceWindow('title')}>{replaceLabels?.title}</div>
            <div className={cnReplaceWindow('text')}>{replaceLabels?.desc}</div>
            <div className={cnReplaceWindow('elements')}>
                {params.type === CodifierEventType.SECTION &&
                    parallels?.map((parallel, index) => (
                        <CodifierDropdownGrade
                            id={parallel.id}
                            numberGrade={parallel.grade}
                            nextSection={1 + Number(parallel.sections?.length)}
                            sectionsCount={Number(parallel.sections?.length)}
                            parallels={parallels}
                            key={`Subject ${index} grade ${parallel.grade}`}
                            index={index}
                            canHover={false}
                            onSelect={handleElementSelect}
                            needHideBlue
                            isHighlighted={parallel.id === selectedParallel}
                        />
                    ))}
                {params.type === CodifierEventType.BLOCK &&
                    sections?.map((section, sectionIndex) => (
                        <CodifierDropdownSection
                            id={section.id}
                            type={CodifierEventType.SECTION}
                            isTheme={false}
                            themeNext={Number(section.blocks?.length) + 1}
                            themeLessonNext={Number(section.blocks?.length) + 1}
                            order={section.order + 1}
                            key={`Section ${section.id}`}
                            title={section.title}
                            index={sectionIndex}
                            canHover={false}
                            onSelect={handleElementSelect}
                            isHighlighted={section.id === selectedSection}
                            needHideBlue
                        />
                    ))}
                {params.type === CodifierEventType.TOPIC && (
                    <div className={cnReplaceWindow('topic')}>
                        {sections?.map((section, sectionIndex) => (
                            <CodifierDropdownSection
                                id={section.id}
                                type={CodifierEventType.SECTION}
                                isTheme={false}
                                themeNext={Number(section.blocks?.length) + 1}
                                themeLessonNext={Number(section.blocks?.length) + 1}
                                order={section.order + 1}
                                key={`Section ${section.id}`}
                                title={section.title}
                                index={sectionIndex}
                                canHover={false}
                                isOpenedInit={section.id === params.section}
                                onSelect={handleElementSelect}
                                isHighlighted={section.id === selectedSection}
                            >
                                {section.blocks?.map((block, blockIndex) => (
                                    <CodifierDropdownSection
                                        id={block.id}
                                        type={CodifierEventType.BLOCK}
                                        isTheme={true}
                                        themeNext={Number(block) + 1}
                                        themeLessonNext={(block?.topics?.length ?? 0) + 1}
                                        key={`Section ${block.id}`}
                                        order={block.order + 1}
                                        title={block.title}
                                        index={blockIndex}
                                        canHover={false}
                                        onSelect={handleElementSelect}
                                        isHighlighted={block.id === selectedBlock}
                                        needHideBlue
                                    />
                                ))}
                            </CodifierDropdownSection>
                        ))}
                    </div>
                )}
            </div>
            <div className={cnReplaceWindow('button')}>
                <Button
                    view={ButtonViewEnum.action}
                    size="m"
                    onClick={handleApproveReplace}
                    disabled={
                        (selectedParallel === params.parallel && params.type === CodifierEventType.SECTION) ||
                        (selectedSection === params.section && params.type === CodifierEventType.BLOCK) ||
                        (selectedBlock === params.block && params.type === CodifierEventType.TOPIC)
                    }
                >
                    {replaceLabels?.buttonLabel}
                </Button>
            </div>
        </div>
    );
};

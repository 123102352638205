import { StudentGroupResponse } from 'api/services/studentGroups';
import { StudentGroupMap } from 'store/reducers/studentGroups';

export const normalizeStudentGroup = (groups: StudentGroupResponse[]): StudentGroupMap => {
    return groups.reduce((result, group) => {
        result[group.id] = group;

        return result;
    }, {} as StudentGroupMap);
};

import { postApiRequest } from 'api';
import { EduCourseType, ResponseEducationPlan } from 'types/educationalPlan';

export interface PostEducationalPlanItem {
    id?: number;
    educationalPlan?: number;
    course: number;
    type: EduCourseType;
    yearLessonsCount: number;
    weekLessonsCount: number;
    yearOnlineLessonsCount: number;
    weekOnlineLessonsCount: number;
    yearFlowLecturesCount: number;
    weekFlowLecturesCount: number;
    evaluationType: string;
    takeIntoAccount: boolean;
}

export interface PostEducationalPlan {
    id?: number;
    title: string;
    parallel: number;
    calendarPlan: number;
    freeHours: number;
    minHours: number;
    educationalPlanItems?: PostEducationalPlanItem[];
}

export const postEducationalPlan = (params: PostEducationalPlan): Promise<ResponseEducationPlan> => {
    return postApiRequest('/educational-plan/create/', params);
};

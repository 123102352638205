import i18next from 'i18next';
import { Parallel } from 'types/parallel';

export const getParallelsTitle = (parallels: Parallel[]): string => {
    if (parallels.length) {
        return [...parallels]
            .sort(
                (first, second) =>
                    Number(first.titleShort ?? first.title.split(' ')[0]) -
                    Number(second.titleShort ?? second.title.split(' ')[0]),
            )
            .reduce((label, parallel, index, parallels) => {
                if (parallels.length === 1) {
                    return parallel.title;
                }

                if (index === 0) {
                    return parallel.titleShort ?? parallel.title.split(' ')[0];
                }

                if (index === parallels.length - 1) {
                    return `${label}, ${parallel.titleShort ?? parallel.title.split(' ')[0]} ${i18next.t('parallel', {
                        count: parallels.length,
                    })}`;
                }

                return `${label}, ${parallel.titleShort ?? parallel.title.split(' ')[0]}`;
            }, '');
    }

    return '';
};

export const getClassTitle = (parall: Parallel[]): string => {
    const parallels = parall.slice().sort((a, b) => a.id - b.id);
    if (parallels.length) {
        return parallels.reduce((label, parallel, index, parallels) => {
            if (parallels.length === 1) {
                return parallel.title.length === 1
                    ? parallel.title.slice(0, 1) + ' класс'
                    : parallel.title.slice(0, 2) + ' класс';
            }

            if (index === 0) {
                return parallel.titleShort ?? parallel.title.split(' ')[0];
            }

            if (index === parallels.length - 1) {
                return `${label}, ${parallel.titleShort ?? parallel.title.split(' ')[0]} ${i18next.t('class', {
                    count: parallels.length,
                })}`;
            }

            return `${label}, ${parallel.titleShort ?? parallel.title.split(' ')[0]}`;
        }, '');
    }

    return '';
};

import { TAssignedQuestions, TQuestionItemRequest } from 'types/lessonTask';

export const getMappedQuestions = (questionsList: TAssignedQuestions): TQuestionItemRequest[] => {
    return Object.values(questionsList).flatMap(({ singleQuestionsList, searchBlocksList }) => [
        ...(singleQuestionsList
            ? singleQuestionsList?.map((item) => {
                  const { assignment, questionNumber, id, filters, questionContent, single } = item;
                  return {
                      id: single ? id : questionContent?.id,
                      assignment,
                      questionNumber,
                      filters: {
                          text: undefined,
                          topics: [],
                          difficulty: [],
                          answersType: [],
                          skillsOr: [],
                          skillsAnd: [],
                          excludeIds: [],
                          id: single ? filters.id : questionContent?.id,
                      },
                  };
              })
            : []),
        ...(searchBlocksList
            ? searchBlocksList?.map((item) => {
                  const { assignment, questionNumber, id, filters } = item;

                  return {
                      id,
                      assignment,
                      questionNumber,
                      filters,
                  };
              })
            : []),
    ]);
};

import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import * as LibService from 'store/actions/library';
import { FetchStatus } from 'types/api';
import { TBook, TBookAuthors, TBookCourse, TBookType, TLibraryTree, TPaginate } from 'types/libraryTypes';

export interface LibraryState {
    getStatus: FetchStatus;
    getCourseListStatus: FetchStatus;
    getAuthorsStatus: FetchStatus;
    postStatus: FetchStatus;
    patchStatus: FetchStatus;
    postBookStatus: FetchStatus;
    patchBookStatus: FetchStatus;
    deleteStatus: FetchStatus;
    postFileStatus: FetchStatus;
    patchFileStatus: FetchStatus;
    deleteFileStatus: FetchStatus;
    setUrlStatus: FetchStatus;
    booksList: TBook[];
    bookListPaginate: TPaginate;
    selectedBook: TBook;
    bookTypesList: TBookType[];
    libraryTree: TLibraryTree[];
    bookCoursesList: TBookCourse[];
    bookAuthorsList: TBookAuthors[];
    bookAuthorsListPaginate: TPaginate;
    createdAuthor: TBookAuthors;
    editedAuthor: TBookAuthors;
    error: unknown;
}
export const initialState: LibraryState = {
    getStatus: FetchStatus.INITIAL,
    getCourseListStatus: FetchStatus.INITIAL,
    getAuthorsStatus: FetchStatus.INITIAL,
    postStatus: FetchStatus.INITIAL,
    patchStatus: FetchStatus.INITIAL,
    deleteStatus: FetchStatus.INITIAL,
    postBookStatus: FetchStatus.INITIAL,
    patchBookStatus: FetchStatus.INITIAL,
    postFileStatus: FetchStatus.INITIAL,
    patchFileStatus: FetchStatus.INITIAL,
    deleteFileStatus: FetchStatus.INITIAL,
    setUrlStatus: FetchStatus.INITIAL,
    booksList: [],
    bookListPaginate: {} as TPaginate,
    selectedBook: {} as TBook,
    bookTypesList: [],
    bookCoursesList: [],
    libraryTree: [],
    bookAuthorsList: [],
    bookAuthorsListPaginate: {} as TPaginate,
    createdAuthor: {} as TBookAuthors,
    editedAuthor: {} as TBookAuthors,
    error: null,
};

const librarySlice = createSlice<LibraryState, SliceCaseReducers<LibraryState>>({
    name: 'library',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(LibService.resetLibraryAction, (state) => {
            state.selectedBook = {} as TBook;
            state.booksList = [];
            state.libraryTree = [];
        });
        builder.addCase(LibService.resetLibraryStatusesAction, (state) => {
            state.getStatus = FetchStatus.INITIAL;
            state.getCourseListStatus = FetchStatus.INITIAL;
            state.getAuthorsStatus = FetchStatus.INITIAL;
            state.postStatus = FetchStatus.INITIAL;
            state.patchStatus = FetchStatus.INITIAL;
            state.deleteStatus = FetchStatus.INITIAL;
            state.postBookStatus = FetchStatus.INITIAL;
            state.patchBookStatus = FetchStatus.INITIAL;
            state.setUrlStatus = FetchStatus.INITIAL;
            state.selectedBook = {} as TBook;
        });

        builder.addCase(LibService.getLibraryTreeAction.pending, (state) => {
            state.getStatus = FetchStatus.FETCHING;
            state.libraryTree = [];
            state.error = null;
        });
        builder.addCase(LibService.getLibraryTreeAction.fulfilled, (state, { payload }) => {
            state.getStatus = FetchStatus.FETCHED;
            state.libraryTree = payload;
            state.error = null;
        });
        builder.addCase(LibService.getLibraryTreeAction.rejected, (state, { error }) => {
            state.getStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(LibService.getBookTypesListAction.pending, (state) => {
            state.getStatus = FetchStatus.FETCHING;
            state.bookTypesList = [];
            state.error = null;
        });
        builder.addCase(LibService.getBookTypesListAction.fulfilled, (state, { payload }) => {
            state.getStatus = FetchStatus.FETCHED;
            state.bookTypesList.push(...payload);
            state.error = null;
        });
        builder.addCase(LibService.getBookTypesListAction.rejected, (state, { error }) => {
            state.getStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(LibService.getBooksListAction.pending, (state) => {
            state.getStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(LibService.getBooksListAction.fulfilled, (state, { payload }) => {
            if (!payload.pagination.currentPage || payload.pagination.currentPage === 1) {
                state.booksList = payload.results.slice();
            } else {
                state.booksList.push(...payload.results.slice());
            }
            state.bookListPaginate = payload.pagination;
            state.error = null;
            state.getStatus = FetchStatus.FETCHED;
        });
        builder.addCase(LibService.getBooksListAction.rejected, (state, { error }) => {
            state.getStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(LibService.getBookByIdAction.pending, (state) => {
            state.getStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(LibService.getBookByIdAction.fulfilled, (state, { payload }) => {
            state.getStatus = FetchStatus.FETCHED;
            state.selectedBook = { ...payload };
            state.error = null;
        });
        builder.addCase(LibService.getBookByIdAction.rejected, (state, { error }) => {
            state.getStatus = FetchStatus.ERROR;
            state.error = error.message;
        });

        builder.addCase(LibService.createBookAction.pending, (state) => {
            state.postBookStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(LibService.createBookAction.fulfilled, (state, payload) => {
            state.postBookStatus = FetchStatus.FETCHED;
            state.selectedBook = payload.payload;
            state.error = null;
        });
        builder.addCase(LibService.createBookAction.rejected, (state, { error }) => {
            state.postBookStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(LibService.editBookAction.pending, (state) => {
            state.patchBookStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(LibService.editBookAction.fulfilled, (state) => {
            state.patchBookStatus = FetchStatus.FETCHED;
            state.error = null;
        });
        builder.addCase(LibService.editBookAction.rejected, (state, { error }) => {
            state.patchBookStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(LibService.deleteBookAction.pending, (state) => {
            state.deleteStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(LibService.deleteBookAction.fulfilled, (state, { payload }) => {
            state.deleteStatus = FetchStatus.FETCHED;
            state.booksList = state.booksList.filter((book) => book.id !== payload.id);
            const id = state.libraryTree.findIndex((item) => item.id === payload.courseId);
            if (id) {
                const typeId = state.libraryTree[id].bookTypes.findIndex((type) => type.id === payload.typeId);
                state.libraryTree[id].bookTypes[typeId].booksQuantity -= 1;
            }
        });
        builder.addCase(LibService.deleteBookAction.rejected, (state, { error }) => {
            state.deleteStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(LibService.getBookCoursesListAction.pending, (state) => {
            state.getCourseListStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(LibService.getBookCoursesListAction.fulfilled, (state, { payload }) => {
            state.getCourseListStatus = FetchStatus.FETCHED;
            state.bookCoursesList = payload.map((course) => {
                return {
                    id: course.id,
                    title: `${course.title} (${course.parallels[0].title})`,
                };
            });
            state.bookCoursesList = state.bookCoursesList.sort((a, b) =>
                a.title.replace(/^(?:«|")/, '') > b.title.replace(/^(?:«|")/, '') ? 1 : -1,
            );
            state.error = null;
        });
        builder.addCase(LibService.getBookCoursesListAction.rejected, (state, { error }) => {
            state.getCourseListStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(LibService.getAuthorsListAction.rejected, (state, { error }) => {
            state.getAuthorsStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(LibService.getAuthorsListAction.fulfilled, (state, { payload }) => {
            state.getAuthorsStatus = FetchStatus.FETCHED;
            if (payload.pagination.currentPage === 1) {
                state.bookAuthorsList = payload.results;
            } else {
                state.bookAuthorsList.push(...payload.results);
            }
            state.bookAuthorsListPaginate = payload.pagination;
        });
        builder.addCase(LibService.getAuthorsListAction.pending, (state) => {
            state.getAuthorsStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(LibService.createAuthorAction.rejected, (state, { error }) => {
            state.postStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(LibService.createAuthorAction.fulfilled, (state, { payload }) => {
            state.createdAuthor = payload[0];
            state.postStatus = FetchStatus.FETCHED;
        });
        builder.addCase(LibService.createAuthorAction.pending, (state) => {
            state.postStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(LibService.editAuthorAction.rejected, (state, { error }) => {
            state.patchStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(LibService.editAuthorAction.fulfilled, (state, { payload }) => {
            state.editedAuthor = payload[0];
            state.patchStatus = FetchStatus.FETCHED;
        });
        builder.addCase(LibService.editAuthorAction.pending, (state) => {
            state.patchStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(LibService.deleteAuthorAction.rejected, (state, { error }) => {
            state.deleteStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(LibService.deleteAuthorAction.fulfilled, (state, { payload }) => {
            state.deleteStatus = FetchStatus.FETCHED;
            state.bookAuthorsList = payload.results.slice();
        });
        builder.addCase(LibService.deleteAuthorAction.pending, (state) => {
            state.deleteStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(LibService.createBookFileAction.pending, (state) => {
            state.postFileStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(LibService.createBookFileAction.fulfilled, (state) => {
            state.postFileStatus = FetchStatus.FETCHED;
            state.error = null;
        });
        builder.addCase(LibService.createBookFileAction.rejected, (state, { error }) => {
            state.postFileStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(LibService.editBookFileAction.pending, (state) => {
            state.patchFileStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(LibService.editBookFileAction.fulfilled, (state) => {
            state.patchFileStatus = FetchStatus.FETCHED;
            state.error = null;
        });
        builder.addCase(LibService.editBookFileAction.rejected, (state, { error }) => {
            state.patchFileStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(LibService.deleteBookFileAction.pending, (state) => {
            state.deleteFileStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(LibService.deleteBookFileAction.fulfilled, (state) => {
            state.deleteFileStatus = FetchStatus.FETCHED;
            state.error = null;
        });
        builder.addCase(LibService.deleteBookFileAction.rejected, (state, { error }) => {
            state.deleteFileStatus = FetchStatus.ERROR;
            state.error = error;
        });
    },
});

export const libraryReducer = librarySlice.reducer;

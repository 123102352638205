import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Form } from 'react-final-form';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { cn } from '@bem-react/classname';
import { IQuestion } from '@lms-elements/test-task/build/@types/packages/TestTask/src/Answer';
import { TMaterialType } from 'api/services/materials';
import { PageLoader } from 'containers/PageLoader';
import arrayMutators from 'final-form-arrays';
import { useRole } from 'hooks';
import { ExpandedMaterialContent } from 'src-new/containers/MaterialsPool/ExpandedMaterial/ExpandedMaterialContent';
import { AppProgressBar, AppText } from 'src-new/ui';
import { setMessage } from 'src-new/utils/message';
import {
    checkExerciseAnswerAction,
    getExercisesCorrectAnswersAction,
    getMaterialsDataAction,
} from 'store/actions/material';
import { setLearningMaterialViewedAction } from 'store/actions/newSubjects';
import { getAssignmentProgressQuestionsAction } from 'store/actions/questionBank';
import { resetTempMaterials } from 'store/reducers/materials';
import { useAppDispatch, useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';
import { IMaterial } from 'types/materials';

import { getAutoCheckAnswerData } from 'utils/answerAttempt';
import { checkIsExerciseFull, filterMaterials, getExerciseQuestions, normalizeMaterialsData } from 'utils/materials';

import './MaterialsTab.scss';

const CnMaterialsTab = cn('materialsTab');

const isEditorOnText = true;

export const MaterialsTab: React.FC<{ lessonId: number | undefined; type: TMaterialType }> = ({ lessonId, type }) => {
    const dispatch = useAppDispatch();
    const { isStudent } = useRole();

    const { getStatusLessons } = useAppSelector((store) => store.courseLesson);

    const {
        materials,
        files,
        exrciseAnswers,
        exercisesWithCorrectAnswers,
        getStatusExercisesCorrectAnswers,
        questions,
        fetchAllDataStatus,
        tempMaterials,
        lastCheckedAnswer,
    } = useAppSelector((store) => store.materials);

    const isLoading =
        getStatusExercisesCorrectAnswers === FetchStatus.FETCHING ||
        getStatusLessons === FetchStatus.FETCHING ||
        fetchAllDataStatus === FetchStatus.FETCHING;
    const questionTarget = useRef<HTMLDivElement>(null);

    const allExercisesIds: number[] = materials
        .map((item) => {
            if (item.typeOfContent === 'exercise' && !isNaN(Number(item.content))) {
                return +item.content;
            }
            return 0;
        })
        .filter((item) => item);
    const filteredMaterials = useMemo(() => filterMaterials(materials, exercisesWithCorrectAnswers), [
        materials,
        exercisesWithCorrectAnswers,
    ]);

    const resolvedQuestions: number[] = useMemo(() => {
        if (filteredMaterials?.some(checkIsExerciseFull)) {
            const questions = getExerciseQuestions(filteredMaterials);
            return questions;
        }
        return [];
    }, [filteredMaterials]);

    const materialsNormalized = useMemo(() => {
        if (fetchAllDataStatus === FetchStatus.FETCHED) {
            return normalizeMaterialsData({
                materials: filteredMaterials,
                files,
                questions,
                answers: exrciseAnswers,
            });
        }
        return [];
    }, [fetchAllDataStatus, filteredMaterials, files, questions, exrciseAnswers]);
    const isEmpty = !materialsNormalized.length;

    const isResolveExercise = (id: number | undefined) => {
        if (id) {
            const lastResolveExerciseId = resolvedQuestions ? resolvedQuestions[resolvedQuestions.length - 1] : 0;
            return lastResolveExerciseId === +id;
        }
        return false;
    };
    const isLastExercise = (id?: number, allExList?: number[]) => {
        if (allExList?.length && id) {
            const lastExercise = allExList[allExList.length - 1];

            return lastExercise === id;
        }
        return false;
    };

    const handleCheckAnswer = useCallback(
        (questionData: IQuestion, materialId: number, questionId?: number) => {
            const checkData = getAutoCheckAnswerData(questionData);
            setTimeout(() => questionTarget?.current?.scrollIntoView({ block: 'nearest', behavior: 'smooth' }), 300);

            void dispatch(
                checkExerciseAnswerAction({
                    material: materialId,
                    data: checkData,
                    questionId: questionId,
                }),
            );
        },
        [dispatch],
    );

    // Показываем сообщение при выполнении последнего упражнения урока
    useEffect(() => {
        const showTooltip = isLastExercise(lastCheckedAnswer, allExercisesIds);
        if (showTooltip) {
            setMessage({ status: 'success', text: 'Вы выполнили последнее упражнение урока!', duration: 3 });
        }
    }, [lastCheckedAnswer]);

    useEffect(() => {
        if (lessonId) {
            let answerPromise: any;
            if (type === 'main') {
                answerPromise = dispatch(getExercisesCorrectAnswersAction(lessonId));
            }

            const materialsPromise = dispatch(getMaterialsDataAction({ lessonId: lessonId, materialType: type }));
            return () => {
                materialsPromise.abort();
                answerPromise?.abort();
            };
        }
    }, [dispatch, lessonId, type]);

    useEffect(() => {
        if (tempMaterials && lessonId) {
            void dispatch(getMaterialsDataAction({ lessonId: lessonId, materialType: type }));
            dispatch(resetTempMaterials());
        }
    }, [dispatch, lessonId, tempMaterials, type]);

    useEffect(() => {
        if (fetchAllDataStatus === FetchStatus.FETCHED && resolvedQuestions.length) {
            void dispatch(getAssignmentProgressQuestionsAction(resolvedQuestions));
            questionTarget?.current?.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
        }
    }, [dispatch, fetchAllDataStatus, resolvedQuestions]);

    useEffect(() => {
        const exercisesList = filteredMaterials.filter(({ typeOfContent }) => typeOfContent === 'exercise');

        if (!!exercisesList.length && isStudent && type === 'main') {
            const exercisesIds = exercisesList.map(({ id }) => id);
            void dispatch(setLearningMaterialViewedAction({ exercises: exercisesIds }));
        }
    }, [filteredMaterials]);

    //Разбивает контент на блоки по кол-ву упражнений в материалах
    const normalizeMaterialContent = (
        fields: IMaterial[],
    ): {
        title: string;
        description: React.ReactNode;
        icon?: React.ReactNode;
    }[] => {
        const contentBlocks: { title: string; description: React.ReactNode; icon?: React.ReactNode }[] = [];

        //список индексов всех упражнений урока
        const allExercisesIndexes = materials
            .map((item, index) => {
                if (item.typeOfContent === 'exercise') {
                    return index + 1;
                }
            })
            .filter((item) => item);

        //список индексов открытых упражнений
        const availableExercisesIds = fields
            .map((item, index) => {
                if (item.type === 'exercise') {
                    return index + 1;
                }
            })
            .filter((item) => item);

        //кол-во блоков данного урока
        const allBlocksCount =
            materials[0]?.typeOfContent !== 'exercise' &&
            materials?.[materials.length - 1]?.typeOfContent !== 'exercise'
                ? allExercisesIndexes.length + 1
                : allExercisesIndexes.length;

        //интервалы, на которые нужно разбить доступный для отображения контент
        const blockIntervals = availableExercisesIds.map((id, index) => {
            const startIndex = index === 0 ? 0 : availableExercisesIds[index - 1];

            return {
                startIndex: startIndex,
                endIndex: id,
            };
        });

        //если весь контент уже доступен и разбит на количество блоков, большее, чем кол-во упражнений,
        // то добавляем оставшийся контент
        if (fields.length === materials.length && allBlocksCount > allExercisesIndexes.length) {
            blockIntervals.push({
                startIndex: availableExercisesIds[availableExercisesIds.length - 1],
                endIndex: materials.length,
            });
        }
        //количество упражнений, еще не отображенных на странице
        const unAvailableBlocksCount = allBlocksCount - blockIntervals.length;

        //если контент разбивается на более, чем один блок, то отображаем шаги
        if (allBlocksCount > 1) {
            blockIntervals?.forEach((item, index) => {
                const newArray: IMaterial[] = fields.slice(item.startIndex, item.endIndex);

                contentBlocks.push({
                    title: '',
                    icon: (
                        <div className={'block-number actual'}>
                            <AppText text={index + 1} fontStyle={'fontBody'} />
                        </div>
                    ),
                    description: (
                        <>
                            {newArray?.map((material, newArrayIndex) => {
                                const url = typeof material.url === 'string' ? material.url : undefined;

                                const questionId = material?.exerciseData?.questionId;
                                const materialSubBlockIndex = (item.startIndex || 0) + newArrayIndex;

                                return (
                                    <div
                                        className={CnMaterialsTab('fields')}
                                        key={`Material${material.id}`}
                                        ref={isResolveExercise(questionId) ? questionTarget : undefined}
                                    >
                                        <ExpandedMaterialContent
                                            name={`materials[${materialSubBlockIndex}]`}
                                            type={material.type}
                                            isEditorOnText={isEditorOnText}
                                            url={url}
                                            isStudentView
                                            areDragging
                                            videoUrl={material?.videoData?.url}
                                            onCheckAnswer={(questionData, materialId) =>
                                                handleCheckAnswer(questionData, materialId, questionId)
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </>
                    ),
                });
            });
            if (unAvailableBlocksCount > 1) {
                //если не раскрыто более одного блока, то показываем номер следующего и последнего
                contentBlocks.push({
                    title: ``,
                    icon: (
                        <div className={'block-number'}>
                            <AppText text={availableExercisesIds.length + 1} fontStyle={'fontBody'} />
                        </div>
                    ),
                    description: (
                        <div className={'task-text-info'}>
                            <AppText text={'Выполните упражнение для продолжения'} fontStyle={'H2'} />
                        </div>
                    ),
                });
                contentBlocks.push({
                    title: ``,
                    icon: (
                        <div className={'block-number'}>
                            <AppText text={allBlocksCount} fontStyle={'fontBody'} />
                        </div>
                    ),
                    description: <></>,
                });
            } else if (unAvailableBlocksCount === 1) {
                //если не раскрыт один блок, то показываем номер последнего
                contentBlocks.push({
                    title: ``,
                    description: (
                        <div className={'task-text-info'}>
                            <AppText text={'Выполните упражнение для продолжения'} fontStyle={'H2'} />
                        </div>
                    ),
                    icon: (
                        <div className={'block-number'}>
                            <AppText text={allBlocksCount} fontStyle={'fontBody'} />
                        </div>
                    ),
                });
            }
        }

        return contentBlocks;
    };

    if (isLoading) {
        return <PageLoader showLoading={isLoading} />;
    }
    if (isEmpty && !isLoading) {
        return (
            <div className={CnMaterialsTab('empty')}>
                <h2 className={CnMaterialsTab('empty-title')}>Материалы еще не созданы</h2>
            </div>
        );
    }

    return (
        <div className={CnMaterialsTab()}>
            {!!materialsNormalized.length && (
                <div className={CnMaterialsTab('materials')} id={'materials-tab-container'}>
                    <Form
                        onSubmit={() => undefined}
                        initialValues={{
                            materials: materialsNormalized,
                        }}
                        mutators={{ ...arrayMutators }}
                    >
                        {({ handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <FieldArray name="materials">
                                    {({ fields }: FieldArrayRenderProps<IMaterial, HTMLInputElement>) => {
                                        const materialContent = normalizeMaterialContent(fields.value);

                                        if (!materialContent.length) {
                                            return (
                                                <>
                                                    {fields?.map((name, index) => {
                                                        const url =
                                                            typeof fields.value[index].url === 'string'
                                                                ? (fields.value[index].url as string)
                                                                : undefined;
                                                        return (
                                                            <React.Fragment key={`Material${fields.value[index].id}`}>
                                                                {fields.value[index] && (
                                                                    <div className={CnMaterialsTab('fields')}>
                                                                        <ExpandedMaterialContent
                                                                            name={name}
                                                                            type={fields.value[index].type}
                                                                            isEditorOnText={isEditorOnText}
                                                                            url={url}
                                                                            isStudentView
                                                                            areDragging
                                                                            videoUrl={
                                                                                fields.value[index]?.videoData?.url
                                                                            }
                                                                            onCheckAnswer={(questionData, materialId) =>
                                                                                handleCheckAnswer(
                                                                                    questionData,
                                                                                    materialId,
                                                                                    fields.value[index]?.exerciseData
                                                                                        ?.questionId,
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                )}
                                                            </React.Fragment>
                                                        );
                                                    })}
                                                </>
                                            );
                                        } else {
                                            return (
                                                <AppProgressBar
                                                    current={materialContent.length - 1}
                                                    initial={0}
                                                    direction="vertical"
                                                    items={materialContent}
                                                />
                                            );
                                        }
                                    }}
                                </FieldArray>
                            </form>
                        )}
                    </Form>
                </div>
            )}
        </div>
    );
};

import { AttendanceResponseData } from 'api/services/attendance';
import { LessonScheduleResponse } from 'api/services/lessonSchedule';
import { EventRoom } from 'api/services/proctoring';
import moment from 'moment';
import { AttendanceStatus } from 'src-new/components/lms-elements/PerformanceTable/types';
import { LessonScheduleAndAttandenceData } from 'store/reducers/academicPerformance';

type Params = {
    lessons: number[];
    students: number[];
    lessonSchedule: LessonScheduleResponse[];
    lessonAttendance: AttendanceResponseData[];
    eventRooms: EventRoom[];
};

export const normalizeAcademicPerformance = ({
    lessons,
    students,
    lessonSchedule,
    lessonAttendance,
    eventRooms,
}: Params): LessonScheduleAndAttandenceData => {
    return lessons.reduce((data, lessonId) => {
        const schedule = lessonSchedule
            .filter(({ lesson }) => lesson === lessonId)
            .sort((a, b) => moment(a.datetimeStart).diff(b.datetimeStart));

        const eventRoom = eventRooms
            .filter((eventRoom) => eventRoom.lesson.id === lessonId)
            .sort((a, b) => moment(a.datetimeStart).diff(b.datetimeStart));
        const attendance = lessonAttendance.filter(({ lesson }) => lesson === lessonId);

        // Необходимо брать schedule по приоритету (онлайн, контрольная, самоподготовка и тд)
        const selectedSchedule = (): LessonScheduleResponse | EventRoom => {
            const online = schedule.find((item) => item.type === 'online_lesson');
            const test = schedule.find((item) => item.type === 'test_lesson');
            const self = schedule.find((item) => item.type === 'selfpreparation_lesson');
            const parent = schedule.find((item) => item.type === 'parent_meeting');

            if (online) {
                return online;
            }
            if (test) {
                return test;
            }
            if (self) {
                return self;
            }
            if (parent) {
                return parent;
            }
            return schedule[0];
        };
        let verifySchedule: LessonScheduleResponse | EventRoom = selectedSchedule();

        if (!verifySchedule && eventRoom[0]) verifySchedule = eventRoom[0];
        if (!verifySchedule) return data;

        data[lessonId] = {
            id: lessonId,
            date: verifySchedule.datetimeStart
                ? moment(verifySchedule.datetimeStart).format('DD.MM')
                : 'не задана дата',
            studentMarks: students.map((studentId) => {
                const studentAttendence = attendance.find(({ student }) => student.id === studentId);

                return {
                    studentId,
                    isAbsentWithReason: studentAttendence?.status === AttendanceStatus.ABSENT_WITH_REASON,
                    isAbsentDueIllness: studentAttendence?.status === AttendanceStatus.ABSENT_DUE_ILLNESS,
                    isAbsent: studentAttendence?.status === AttendanceStatus.ABSENT,
                };
            }),
        };

        return data;
    }, {} as LessonScheduleAndAttandenceData);
};

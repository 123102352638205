import { MarkField } from './AttendanceMarkForm.types';
/**
 * @description проверяет нужен ли для оценки коментарий определенной длинны
 * @param {MarkField} mark
 * @return {boolean | undefined}
 */
const getIsValidCommentSize = (mark: MarkField): boolean => {
    const isBadMark = mark?.selectedMark === 3 || mark?.selectedMark === 2;
    const isValidCommentSize = (mark?.comment?.length ?? 0) > 30;
    return mark && (isBadMark ? isValidCommentSize : true);
};

const isValidComments = (marks: MarkField[]): boolean => {
    return marks?.filter((mark) => mark.valueCanChange).every((mark) => getIsValidCommentSize(mark));
};

export { getIsValidCommentSize, isValidComments };

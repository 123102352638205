import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';

export interface PutAssignmentProgress {
    id: number;
    deadlineForComplete: string;
    assignedDate: string;
}

export const putStudentTask = async (tasks: PutAssignmentProgress[]): Promise<unknown> => {
    return api
        .put<Promise<unknown>, AxiosResponse<unknown>>(`/assignment-progress/bulk-update/`, tasks)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { changeAdaptation, ChangeAdaptationParams, getAdaptation } from 'api/services/adaptation';

export const getAdaptationAction = createAsyncThunk('adaptation/getAdaptation', (studentId: number) =>
    getAdaptation(studentId),
);

export const changeAdaptationAction = createAsyncThunk(
    'adaptation/changeAdaptation',
    (changeParams: ChangeAdaptationParams[] & { studentId?: number }) =>
        Promise.all(changeParams.map((changeParam) => changeAdaptation(changeParam))),
);

import { postApiRequest } from 'api';
import { CreateRoomResponse } from 'api/services/proctoring';
import { payloadTimeFormatter } from 'src-new/utils/date-n-time';

export interface CreateRoomData {
    event: number;
    datetimeStart: string;
    duration: number;
    maxStudents?: number;
    leaders?: number[];
}

export const createEventRoom = (roomData: CreateRoomData): Promise<CreateRoomResponse> => {
    const { datetimeStart, event, duration, maxStudents, leaders } = { ...roomData };
    const startDate = payloadTimeFormatter(datetimeStart);

    const payload: CreateRoomData = {
        event,
        duration,
        maxStudents,
        leaders,
        datetimeStart: startDate,
    };
    return postApiRequest('/event-room/create/', payload);
};

import { ActionCreatorWithoutPayload, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { IMaterialFile, LearningMaterialsContent } from 'api/services/materials';
import { ResponseQuestion } from 'api/services/questionBank';
import { IQuestion } from 'containers/QuestionSearchResult/QuestionSearchResult';
import { ISearchBlock } from 'containers/QuestionSearchResult/types';
import {
    addNewQuestionForExerciseMaterialAction,
    checkExerciseAnswerAction,
    clearMaterialsFilesAction,
    createMaterialAction,
    createTempMaterials,
    deleteMaterialAction,
    deleteMaterialFileAction,
    getExercisesCorrectAnswersAction,
    getMaterialsDataAction,
    patchMaterialAction,
    patchMaterialWithOrderingAction,
    postMaterialFileAction,
    postMaterialWithOrderingAction,
    resetLastCheckedAnswerAction,
    resetMaterialsStatusesAction,
    resetPostMaterialFileStatusAction,
} from 'store/actions/material';
import { FetchStatus } from 'types/api';
import { IUploadedFile } from 'types/file';
import { IMaterial } from 'types/materials';

import { StudentAnswerData } from 'utils/questions';

export interface TempMaterial extends LearningMaterialsContent {
    files?: IUploadedFile[];
    isDeleted: boolean;
}

export interface TempMaterialsData {
    initialId: number;
    subjectId: number;
    lessonId: number;
    courseGroupId?: number;
    exerciseMaterialIndex?: number;
    exerciseMaterialId?: number;
    fromEditPage: boolean;
    materials: TempMaterial[];
}

export interface MaterialState {
    getStatusMaterials: FetchStatus;
    postStatusMaterials: FetchStatus;
    getStatusFiles: FetchStatus;
    patchStatusMaterials: FetchStatus;
    patchStatusMaterialOrdering: FetchStatus;
    patchStatusMaterialWithOrdering: FetchStatus;
    deleteStatusMaterials: FetchStatus;
    postStatusMaterialFile: FetchStatus;
    deleteStatusMaterialFile: FetchStatus;
    getStatusExercisesCorrectAnswers: FetchStatus;
    lastCreatedFile: IUploadedFile | undefined;
    createdFiles: IUploadedFile[];
    materials: LearningMaterialsContent[];
    // selfPreparation: boolean;
    files: IMaterialFile[];
    tempMaterials: TempMaterialsData | null;
    tempFiles: IMaterialFile[];
    error: unknown;
    checkAnswerStatus: FetchStatus;
    exrciseAnswers: {
        [exerciseId: number]: StudentAnswerData;
    };
    lastCheckedAnswer: number | undefined;
    exercisesWithCorrectAnswers: number[];
    filesForDelete: number[];
    // Fetch All Materials Data
    fetchAllDataStatus: FetchStatus;
    materialsData: IMaterial[];
    questions: ResponseQuestion[];
}

export const initialState: MaterialState = {
    getStatusMaterials: FetchStatus.INITIAL,
    postStatusMaterials: FetchStatus.INITIAL,
    getStatusFiles: FetchStatus.INITIAL,
    patchStatusMaterials: FetchStatus.INITIAL,
    patchStatusMaterialOrdering: FetchStatus.INITIAL,
    patchStatusMaterialWithOrdering: FetchStatus.INITIAL,
    deleteStatusMaterials: FetchStatus.INITIAL,
    postStatusMaterialFile: FetchStatus.INITIAL,
    deleteStatusMaterialFile: FetchStatus.INITIAL,
    getStatusExercisesCorrectAnswers: FetchStatus.INITIAL,
    lastCreatedFile: undefined,
    lastCheckedAnswer: undefined,
    createdFiles: [],
    materials: [],
    files: [],
    tempMaterials: null,
    tempFiles: [],
    error: null,
    checkAnswerStatus: FetchStatus.INITIAL,
    exrciseAnswers: {},
    exercisesWithCorrectAnswers: [],
    filesForDelete: [],
    // Fetch All Materials Data
    fetchAllDataStatus: FetchStatus.INITIAL,
    materialsData: [],
    questions: [],
};

const materialsSlice = createSlice<MaterialState, SliceCaseReducers<MaterialState>>({
    name: 'material',
    initialState,
    reducers: {
        resetTempMaterials(state) {
            state.tempMaterials = null;
            state.tempFiles = [];
        },
        resetMaterials(state) {
            state.materials = [];
            state.materialsData = [];
        },
        addExerciseMaterialQuestion(state, action: PayloadAction<ResponseQuestion>) {
            if (state.tempMaterials) {
                const { exerciseMaterialId } = state.tempMaterials;

                const newQuestions = [...state.questions.filter(({ id }) => id !== action.payload.id), action.payload];

                return {
                    ...state,
                    tempMaterials: {
                        ...state.tempMaterials,
                        materials: state.tempMaterials.materials.map((material) => {
                            if (material.id === exerciseMaterialId) {
                                return {
                                    ...material,
                                    content: String(action.payload.id),
                                };
                            }
                            return material;
                        }),
                    },
                    questions: newQuestions,
                };
            }
        },
        changeTempMaterial(
            state,
            action: PayloadAction<{
                materialId: number;
                newData: Partial<
                    TempMaterialsData & {
                        trainerQuestions: { singleQuestions: IQuestion[]; searchBlocks: ISearchBlock[] };
                    }
                >;
            }>,
        ) {
            const { materialId, newData } = action.payload;

            if (state.tempMaterials) {
                const updatedTempMaterials = {
                    ...state.tempMaterials,
                    materials: state.tempMaterials.materials.map((material) => {
                        if (material.id === materialId) {
                            return {
                                ...material,
                                ...newData,
                            };
                        }

                        return material;
                    }),
                };

                state.tempMaterials = updatedTempMaterials;
            }
        },
        addFilesForDelete(state, action: PayloadAction<number[]>) {
            state.filesForDelete = [
                ...state.filesForDelete,
                ...action.payload.filter((fileId) =>
                    state.createdFiles.length
                        ? state.createdFiles.some((createdFile) => createdFile.id !== fileId)
                        : true,
                ),
            ];
        },
        resetFilesForDelete(state) {
            state.filesForDelete = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetLastCheckedAnswerAction, (state) => {
            state.lastCheckedAnswer = undefined;
        });
        builder.addCase(createMaterialAction.pending, (state) => {
            state.postStatusMaterials = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(createMaterialAction.fulfilled, (state) => {
            state.postStatusMaterials = FetchStatus.FETCHED;
            state.error = null;
        });
        builder.addCase(createMaterialAction.rejected, (state, { error }) => {
            state.postStatusMaterials = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(deleteMaterialAction.pending, (state) => {
            state.deleteStatusMaterials = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(deleteMaterialAction.fulfilled, (state) => {
            state.deleteStatusMaterials = FetchStatus.FETCHED;
            state.error = null;
        });
        builder.addCase(deleteMaterialAction.rejected, (state, { error }) => {
            state.deleteStatusMaterials = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(patchMaterialAction.pending, (state) => {
            state.patchStatusMaterials = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(patchMaterialAction.fulfilled, (state) => {
            state.patchStatusMaterials = FetchStatus.FETCHED;
            state.error = null;
        });
        builder.addCase(patchMaterialAction.rejected, (state, { error }) => {
            state.patchStatusMaterials = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(postMaterialWithOrderingAction.pending, (state) => {
            state.patchStatusMaterialOrdering = FetchStatus.FETCHING;
            state.postStatusMaterials = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(postMaterialWithOrderingAction.fulfilled, (state) => {
            state.patchStatusMaterialOrdering = FetchStatus.FETCHED;
            state.postStatusMaterials = FetchStatus.FETCHED;
            state.error = null;
        });
        builder.addCase(postMaterialWithOrderingAction.rejected, (state, { error }) => {
            state.patchStatusMaterialOrdering = FetchStatus.ERROR;
            state.postStatusMaterials = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(patchMaterialWithOrderingAction.pending, (state) => {
            state.patchStatusMaterialWithOrdering = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(patchMaterialWithOrderingAction.fulfilled, (state) => {
            state.patchStatusMaterialWithOrdering = FetchStatus.FETCHED;
            state.error = null;
        });
        builder.addCase(patchMaterialWithOrderingAction.rejected, (state, { error }) => {
            state.patchStatusMaterialWithOrdering = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(postMaterialFileAction.pending, (state) => {
            state.postStatusMaterialFile = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(postMaterialFileAction.fulfilled, (state, { payload }) => {
            state.postStatusMaterialFile = FetchStatus.FETCHED;
            state.lastCreatedFile = payload;
            state.createdFiles = [...state.createdFiles, payload];
            state.error = null;
        });
        builder.addCase(postMaterialFileAction.rejected, (state, { error }) => {
            state.postStatusMaterialFile = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(deleteMaterialFileAction.pending, (state) => {
            state.deleteStatusMaterialFile = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(deleteMaterialFileAction.fulfilled, (state, { payload }) => {
            state.deleteStatusMaterialFile = FetchStatus.FETCHED;
            state.lastCreatedFile = undefined;
            state.createdFiles = state.createdFiles.filter((value) => value.id !== payload?.id);
            state.error = null;
        });
        builder.addCase(deleteMaterialFileAction.rejected, (state, { error }) => {
            state.deleteStatusMaterialFile = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(resetPostMaterialFileStatusAction, (state) => {
            state.postStatusMaterialFile = FetchStatus.INITIAL;
        });
        builder.addCase(resetMaterialsStatusesAction, (state) => {
            state.getStatusFiles = FetchStatus.INITIAL;
            state.getStatusMaterials = FetchStatus.INITIAL;
            state.postStatusMaterials = FetchStatus.INITIAL;
            state.deleteStatusMaterials = FetchStatus.INITIAL;
            state.patchStatusMaterials = FetchStatus.INITIAL;
            state.patchStatusMaterialOrdering = FetchStatus.INITIAL;
            state.postStatusMaterialFile = FetchStatus.INITIAL;
            state.deleteStatusMaterialFile = FetchStatus.INITIAL;
            state.fetchAllDataStatus = FetchStatus.INITIAL;
            state.createdFiles = [];
            state.tempMaterials = null;
            state.tempFiles = [];
            state.lastCreatedFile = undefined;
        });
        builder.addCase(clearMaterialsFilesAction.pending, (state) => {
            state.deleteStatusMaterialFile = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(clearMaterialsFilesAction.fulfilled, (state) => {
            state.deleteStatusMaterialFile = FetchStatus.INITIAL;
            state.lastCreatedFile = undefined;
            state.createdFiles = [];
            state.files = [];
            state.error = null;
        });
        builder.addCase(clearMaterialsFilesAction.rejected, (state, { error }) => {
            state.deleteStatusMaterialFile = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(createTempMaterials.pending, (state) => {
            state.postStatusMaterialFile = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(createTempMaterials.fulfilled, (state, { payload }) => {
            const { isEditPage, filesForTempFileset, ...tempMaterialsData } = payload;

            state.tempMaterials = {
                ...tempMaterialsData,
                fromEditPage: isEditPage,
                materials: tempMaterialsData.materials.map((material, index) => ({
                    ...material,
                    order: index,
                })),
            };

            filesForTempFileset.forEach((fileData) => {
                const tempFilesIndex = state.tempFiles.findIndex((fileset) => fileset.material === fileData.material);

                if (tempFilesIndex !== -1) {
                    state.tempFiles[tempFilesIndex].files.push(fileData.file);
                } else {
                    state.tempFiles.push({
                        id: fileData.material,
                        material: fileData.material,
                        files: [fileData.file],
                    });
                }
            });

            state.postStatusMaterialFile = FetchStatus.FETCHED;
            state.error = null;
        });
        builder.addCase(createTempMaterials.rejected, (state, { error }) => {
            state.postStatusMaterialFile = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(checkExerciseAnswerAction.pending, (state) => {
            state.checkAnswerStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(checkExerciseAnswerAction.fulfilled, (state, { payload }) => {
            const { materialId, ...exerciseAnswerData } = payload;
            state.checkAnswerStatus = FetchStatus.FETCHED;
            state.exrciseAnswers[materialId] = { ...exerciseAnswerData };

            if (payload.isCorrect) {
                state.lastCheckedAnswer = payload.questionId;
                if (!state.exercisesWithCorrectAnswers.includes(materialId)) {
                    state.exercisesWithCorrectAnswers = [...state.exercisesWithCorrectAnswers, materialId];
                }
            }
            state.error = null;
        });
        builder.addCase(checkExerciseAnswerAction.rejected, (state, { error }) => {
            state.checkAnswerStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(getExercisesCorrectAnswersAction.pending, (state) => {
            state.getStatusExercisesCorrectAnswers = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getExercisesCorrectAnswersAction.fulfilled, (state, { payload }) => {
            state.getStatusExercisesCorrectAnswers = FetchStatus.FETCHED;
            state.exercisesWithCorrectAnswers = payload.map((correctAnswer) => correctAnswer.material);
        });
        builder.addCase(getExercisesCorrectAnswersAction.rejected, (state, { error }) => {
            state.getStatusExercisesCorrectAnswers = FetchStatus.ERROR;
            state.error = error;
        });
        builder
            .addCase(getMaterialsDataAction.pending, (state) => {
                state.fetchAllDataStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getMaterialsDataAction.fulfilled, (state, { payload }) => {
                state.fetchAllDataStatus = FetchStatus.FETCHED;
                state.materials = payload.materials;
                state.files = payload.files;
                state.questions = payload.questions;
                state.error = null;
            })
            .addCase(getMaterialsDataAction.rejected, (state, { error }) => {
                state.fetchAllDataStatus = FetchStatus.ERROR;
                state.materialsData = [];
                state.error = error;
            });
        builder.addCase(addNewQuestionForExerciseMaterialAction.fulfilled, (state, { payload }) => {
            const newQuestions = [...state.questions.filter(({ id }) => id !== payload[0]?.id), payload[0]];

            state.questions = newQuestions;
            if (state.tempMaterials) {
                const { exerciseMaterialId } = state.tempMaterials;

                state.tempMaterials = {
                    ...state.tempMaterials,
                    materials: state.tempMaterials.materials.map((material) => {
                        if (material.id === exerciseMaterialId) {
                            return {
                                ...material,
                                content: String(payload[0]?.id),
                            };
                        }
                        return material;
                    }),
                };
            }
        });
    },
});
const resetTempMaterials = materialsSlice.actions.resetTempMaterials as ActionCreatorWithoutPayload<string>;
const resetMaterials = materialsSlice.actions.resetMaterials as ActionCreatorWithoutPayload<string>;
const resetFilesForDeleteAction = materialsSlice.actions.resetFilesForDelete as ActionCreatorWithoutPayload<string>;
export { resetFilesForDeleteAction, resetMaterials, resetTempMaterials };
export const { addExerciseMaterialQuestion, addFilesForDelete } = materialsSlice.actions;
export const materialsReducer = materialsSlice.reducer;

import React, { useEffect, useMemo, useRef } from 'react';
import { useFormState } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { AnswerAttemptPostResponse } from 'api/services/answerAttempt';
import { getAnswerAttempts, IFormValues } from 'containers/StudentTask/utils';
import { useRole } from 'hooks';
import { updateAttemptDraft } from 'store/actions/answerAttempt';
import { saveDraft } from 'store/reducers/answerAttempts';
import { useAppSelector } from 'store/store';
import { AnswerAttemptData } from 'types/answerAttempt';
import { FetchStatus } from 'types/api';

interface DraftComponentProps {
    studentId: number;
    selectedAttempt: AnswerAttemptPostResponse | null;
    allFilesUploaded: boolean;
    isHardDeadlineLeft?: boolean;
}

interface PatchData {
    id: string;
    tasks: AnswerAttemptData;
    assignmentProgress: number;
}

const THREE_MINUTES = 180000;

export const DraftComponent: React.FC<DraftComponentProps> = ({ studentId, selectedAttempt, isHardDeadlineLeft }) => {
    const { isStudent, isTeacher } = useRole();

    const dispatch = useDispatch();

    const { postAnswerAttemptStatus, data, questionFiles, postEvaluateStatus } = useAppSelector(
        (state) => state.answerAttempts,
    );
    const draftAttempId = useAppSelector((state) => state.answerAttempts.draftAttempId);

    const { task } = useAppSelector((state) => state.studentTasks);

    const { values } = useFormState<IFormValues>({ subscription: { values: true } });

    const answerAttemptsData = useMemo(() => {
        return isTeacher ? data?.filter((attempt) => attempt.student === studentId) || [] : data;
    }, [data, isTeacher, studentId]);

    const patchData = useMemo(() => {
        const filteredQuestions = values.questions.filter(
            (el) =>
                el.answer || (Array.isArray(el.file) && el.file.length !== 0) || questionFiles[el.id]?.files?.length,
        );

        const esseyQuestion = filteredQuestions.filter(({ type }) => type === 'detailed');
        const allFilesUpload = esseyQuestion.every(({ uploadProgresses }) =>
            ((uploadProgresses as unknown) as number[])?.filter(Boolean).every((progress) => progress === 101),
        );

        if (isStudent && draftAttempId && task && filteredQuestions.length) {
            const answerAttempts = getAnswerAttempts(
                { attempt: values.attempt, questions: filteredQuestions },
                task,
                selectedAttempt,
                questionFiles,
            );

            return {
                id: String(draftAttempId),
                tasks: answerAttempts,
                assignmentProgress: answerAttempts.assignmentProgress,
            };
        }
    }, [draftAttempId, isStudent, questionFiles, selectedAttempt, task, values.attempt, values.questions]);

    useEffect(() => {
        const filteredQuestions = values.questions.filter(
            (el) =>
                el.answer || (Array.isArray(el.file) && el.file.length !== 0 && questionFiles[el.id]?.files?.length),
        );

        if (
            !values.attempt &&
            isStudent &&
            !filteredQuestions.every(({ correctAnswers }) => correctAnswers?.length) &&
            postAnswerAttemptStatus === FetchStatus.INITIAL &&
            data !== null &&
            answerAttemptsData?.every((el) => !el.mark && !el.isDraft) &&
            task
        ) {
            const answerAttempts = getAnswerAttempts(
                {
                    attempt: values.attempt,
                    questions: filteredQuestions,
                },
                task,
                selectedAttempt,
                questionFiles,
            );

            dispatch(
                saveDraft({
                    assignmentProgress: answerAttempts.assignmentProgress,
                    answers: answerAttempts.answers,
                }),
            );
        }
    }, [
        answerAttemptsData,
        data,
        dispatch,
        isStudent,
        postAnswerAttemptStatus,
        questionFiles,
        selectedAttempt,
        values.attempt,
        values.questions,
        task,
    ]);

    const saveDraftRef = useRef((patchData: PatchData) => {
        dispatch(
            saveDraft({
                assignmentProgress: patchData.assignmentProgress,
                attemptId: Number(patchData.id),
                answers: patchData.tasks.answers,
            }),
        );
    });

    useEffect(() => {
        if (patchData && postEvaluateStatus === FetchStatus.INITIAL) {
            saveDraftRef.current(patchData);
        }
    }, [dispatch, patchData, postEvaluateStatus]);

    useEffect(() => {
        const draftInterval = setInterval(() => {
            if (!isHardDeadlineLeft) {
                dispatch(updateAttemptDraft());
            }
        }, THREE_MINUTES);

        return () => {
            clearInterval(draftInterval);
        };
    }, [dispatch, draftAttempId, isHardDeadlineLeft]);

    return null;
};

import React, { useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { QuestionsBlockContainer, TQuestionsBlockContainerDrag } from 'src-new/components/lesson-task';

import './style.scss';

/**
 * @description QuestionsBlockContainerDrag - обертка над QuestionsBlockContainer для реализации перетаскивания
 * @props TQuestionsBlockContainerDrag
 * dragData - используется для dnd
 * mainProps - используется для QuestionsBlockContainer
 * */
export const QuestionsBlockContainerDrag: React.FC<TQuestionsBlockContainerDrag> = (props) => {
    const { dragData, mainProps } = props;

    const [{ isDragging }, dragBlock, preview] = useDrag(
        () => ({
            item: () => {
                return {
                    mainProps,
                    dragData,
                };
            },
            type: 'block',
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [props],
    );
    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, []);

    return (
        <div ref={dragBlock} className={`drag-container ${isDragging ? 'isDragging' : ''}`}>
            <QuestionsBlockContainer {...mainProps} />
        </div>
    );
};

import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { TSingleQuestionContainer } from 'src-new/components/lesson-task';
import { useDownloadTxtFile } from 'src-new/hooks';
import { getSafetyHtml } from 'src-new/utils';
import { getQuestionDetailsAction, setOpenedQuestionIdAction } from 'store/actions/lessonTask';

import { AnswersTypeEnum } from 'components/QuestionPopUp/QuestionPopUp.types';
import { getInsertWordsPreviewHtml } from 'utils/questions';

import './style.scss';
/**
 * @description SingleQuestionContainer - компонент одиночного вопроса
 * @props TSingleQuestionContainer
 * */
export const SingleQuestionContainer: React.FC<TSingleQuestionContainer> = (props) => {
    const dispatch = useDispatch();
    const containerRef = useRef<HTMLDivElement>(null);
    useDownloadTxtFile(containerRef);
    const { question, isActiveBorder = false, isDragging, isExcluded } = props;

    const handleQuestionClick = () => {
        if (question?.id) {
            dispatch(setOpenedQuestionIdAction(question?.id));
            dispatch(getQuestionDetailsAction(question?.id));
        }
    };

    const questionContextPreview =
        question?.answersType === AnswersTypeEnum.insert_words && question?.html
            ? getInsertWordsPreviewHtml({ content: question.content, html: question?.html, text: question?.text })
            : question?.html || question?.text || '';

    return (
        <div
            ref={containerRef}
            className={`single-question-container ${isActiveBorder ? 'active-border' : ''} ${
                isDragging ? 'dragging' : ''
            } ${isExcluded ? 'excluded' : ''}`}
            style={isDragging ? { opacity: '1' } : {}}
            onClick={handleQuestionClick}
        >
            <span
                className={'single-question-container__text'}
                dangerouslySetInnerHTML={{ __html: getSafetyHtml(questionContextPreview ?? '') }}
                title={question?.text}
            />
        </div>
    );
};
export const SingleQuestionContainerMemo = React.memo(SingleQuestionContainer);

import { IUploadedFile } from 'types/file';
import { ContentMaterialTypeEnum, IMaterial } from 'types/materials';

export const getFilesForPatch = (material: IMaterial, createdFiles: IUploadedFile[]): number[] => {
    if (material.type === ContentMaterialTypeEnum.photo && material.imageData) {
        return material.imageData.data
            .filter((value) => createdFiles.some((file) => file.id === value.id))
            .map((value) => value.id || -1)
            .filter((value) => value !== -1);
    }
    return [];
};

import React from 'react';
import { AppText } from '@frontend-modules/ui-kit';
import { TTextButtonProps } from '@fsd-shared/ui-kit/TextButton/TextButton.types';
import { E_COLORS } from 'src-new/constants';

import './TextButton.style.scss';

export const TextButton: React.FC<TTextButtonProps> = (props) => {
    const {
        label,
        handleClick,
        wrapperClassName,
        textStyle = 'DesktopFontBody',
        color = E_COLORS.firm_blue_color,
        disabled = false,
    } = props;
    return (
        <div className={`text-button ${wrapperClassName ?? ''} ${disabled ? 'disabled' : ''}`} onClick={handleClick}>
            <AppText text={label} textStyle={textStyle} color={disabled ? E_COLORS.light_grey_color_1 : color} />
        </div>
    );
};

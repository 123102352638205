import React, { FC } from 'react';
import { cn } from '@bem-react/classname';
import { AppFontIcon, AppText } from '@frontend-modules/ui-kit';
import { E_COLORS } from 'src-new/constants';

import { IStudentTrainerStatisticProps } from './StudentTrainerStatistic.types';
import { getLastTenProgress } from './StudentTrainerStatistic.utils';

import './StudentTrainerStatistic.styles.scss';
const CnStudentTrainerStatistic = cn('student-trainer-statistic');

export const StudentTrainerStatistic: FC<IStudentTrainerStatisticProps> = (props) => {
    const { statistics } = props;
    const { completedCount, correctCount, incorrectCount, progress } = statistics || {};

    const lastTenProgressColors = getLastTenProgress(progress);

    return (
        <div className={CnStudentTrainerStatistic()}>
            <div className={CnStudentTrainerStatistic('text')}>
                <AppText text={'Пройдено вопросов'} textStyle={'DesktopFootNotes'} />
                <AppText text={String(completedCount ?? 0)} textStyle={'DesktopH1'} />
            </div>
            <div className={CnStudentTrainerStatistic('general-statistics')}>
                <div className={CnStudentTrainerStatistic('general-statistics-card')}>
                    <AppFontIcon icon={'smile-emoji-m'} color={E_COLORS.main_dark_icons} />
                    <AppText text={String(correctCount ?? 0)} textStyle={'DesktopH6'} />
                </div>
                <div className={CnStudentTrainerStatistic('general-statistics-card')}>
                    <AppFontIcon icon={'sad-emoji-m'} color={E_COLORS.main_dark_icons} />
                    <AppText text={String(incorrectCount ?? 0)} textStyle={'DesktopH6'} />
                </div>
            </div>
            <div className={CnStudentTrainerStatistic('last-ten-statistics')}>
                <span className={CnStudentTrainerStatistic('last-ten-statistics-title')}>Последние 10 ответов</span>
                <div className={CnStudentTrainerStatistic('last-ten-statistics-list')}>
                    {lastTenProgressColors.map((item, index) => {
                        return (
                            <div
                                key={`last-ten-statistics-item-${index}`}
                                className={CnStudentTrainerStatistic('last-ten-statistics-item')}
                                style={{ backgroundColor: item }}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

import React, { useEffect, useMemo } from 'react';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { DropdownCustom, IDropdownType, IDropdownValues } from '@lms-elements/atomic';
import { EMPTY_VALIDATORS } from 'common/constants';

import { IAttemptDropdownProps } from './AttemptDropdown.types';
import { getAttemptByLabel, getAttemptDropdownOptionsList } from './AttemptDropdown.utils';

import './AttemptDropdown.scss';

const CnAttemptDropdown = cn('attemptDropdown');

export const AttemptDropdown: React.FC<IAttemptDropdownProps> = ({ answerAttempts, name, onSelectedAttemptChange }) => {
    const { input } = useField<IDropdownValues>(name);
    const attemptDropdownOptions = useMemo(() => getAttemptDropdownOptionsList(answerAttempts || []), [answerAttempts]);

    useEffect(() => {
        onSelectedAttemptChange?.(getAttemptByLabel(answerAttempts, input.value.title));
    }, [answerAttempts, input, onSelectedAttemptChange]);
    return (
        <div className={CnAttemptDropdown()}>
            <DropdownCustom
                name={name}
                options={attemptDropdownOptions}
                placeholder="Выберите попытку"
                validators={EMPTY_VALIDATORS}
                type={IDropdownType.optionWithId}
                isField
            />
        </div>
    );
};

import React from 'react';
import { AppText } from '@frontend-modules/ui-kit';
import { Table } from 'antd';
import { E_COLORS } from 'src-new/constants';

import { TTableComponent, TTableData } from './CriteriaTableContent.types';

import './CriteriaTableContent.style.scss';
export const CriteriaTableContent = <T,>(props: TTableComponent<TTableData<T>>): JSX.Element => {
    const { columns, tableData, summaryList, childrenOnTop, loading, onRowClick } = props;

    return (
        <div className={'criteria-table-content'}>
            {childrenOnTop}
            <Table
                locale={{ emptyText: 'По указанным фильтрам ничего не найдено' }}
                columns={columns}
                dataSource={tableData}
                pagination={false}
                bordered={false}
                loading={loading}
                scroll={{ x: 'max-content' }}
                summary={() => (
                    <Table.Summary fixed={'top'}>
                        <Table.Summary.Row>
                            {summaryList?.map((item, index) => {
                                return (
                                    <Table.Summary.Cell key={`summary-${index}`} index={index} align={'center'}>
                                        {item !== '-' && (
                                            <AppText
                                                text={item}
                                                textStyle={'DesktopFontBody'}
                                                color={E_COLORS.light_grey_color_2}
                                            />
                                        )}
                                    </Table.Summary.Cell>
                                );
                            })}
                        </Table.Summary.Row>
                    </Table.Summary>
                )}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: () => onRowClick?.(record.teacherId),
                    };
                }}
                sticky={{ offsetScroll: 0 }}
            />
        </div>
    );
};

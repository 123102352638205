import dayjs, { Dayjs } from 'dayjs';

export const getFormattedMonth = (date: Dayjs): string => {
    const month = date.format('MMMM, YYYY');
    return month[0].toUpperCase() + month.slice(1);
};
export const getInMonthText = (date?: string): string => {
    if (date) {
        const monthName = dayjs(date).format('MMMM');
        let result;

        switch (monthName) {
            case 'январь':
                result = 'в январе';
                break;
            case 'февраль':
                result = 'в феврале';
                break;
            case 'март':
                result = 'в марте';
                break;
            case 'апрель':
                result = 'в апреле';

                break;
            case 'май':
                result = 'в мае';

                break;
            case 'июнь':
                result = 'в июне';
                break;
            case 'июль':
                result = 'в июле';
                break;
            case 'август':
                result = 'в августе';
                break;
            case 'сентябрь':
                result = 'в сентябре';
                break;
            case 'октябрь':
                result = 'в октябре';
                break;
            case 'ноябрь':
                result = 'в ноябре';
                break;
            case 'декабрь':
                result = 'в декабре';
                break;
            default:
                result = 'в месяце';
                break;
        }

        return result;
    }
    return 'в месяце';
};

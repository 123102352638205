import { TField } from './TeachersInfoModal.types';

export const getInfoFields: TField[] = [
    {
        title: 'Должность',
        fieldName: 'currentPosition',
    },
    {
        title: 'Преподаваемые дисциплины',
        fieldName: 'subjects',
    },
    {
        title: 'Общий стаж работы',
        fieldName: 'generalExperience',
    },
    {
        title: 'Стаж в данной должности',
        fieldName: 'currentExperience',
    },
    {
        title: 'Образование',
        fieldName: 'education',
    },
    {
        title: 'Квалификация по диплому',
        fieldName: 'upProfessional',
    },
    {
        title: 'Направление подготовки и/или специальность',
        fieldName: 'specialization',
    },
];

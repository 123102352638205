import { questionsBankPageData } from 'constants/cypress/question-bank-page';

import React, { useCallback, useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';
import { LittleArrow } from '@lms-elements/icons';
import { useCodifierContext } from 'containers/CodifierSelector/context';
import { TopicElement } from 'containers/CodifierSelector/TopicElement';
import { useAppSelector } from 'store/store';

import { CodifierEventType } from 'components/Codifier/Codifier';

import './BlockElement.scss';

const cx = cn('block-element');

interface BlockElementProps {
    blockId: number;
    blockIndex?: number;
}

export const BlockElement: React.FC<BlockElementProps> = ({ blockId, blockIndex }) => {
    const blockData = useAppSelector((store) => store.codifier.blocksMap[blockId]);

    const {
        onElementClick,
        selectedElement: { blocks, topics },
        withCheckboxes,
    } = useCodifierContext();

    const [isSelected, setIsSelected] = useState(false);
    const [isOpened, setIsOpened] = useState(false);

    useEffect(() => {
        setIsSelected(!withCheckboxes && !topics?.length && blocks?.[0] === blockId);
        setIsOpened(
            Boolean(
                blocks?.includes(blockId) ||
                    (withCheckboxes && blockData?.topics.some((topic) => topics?.includes(topic))),
            ),
        );
    }, [blocks, blockId, topics?.length, withCheckboxes, topics, blockData.topics]);

    const isDisabled = blockData.topics.length === 0;

    const handleSubjectClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            if (!isDisabled && !isSelected) {
                onElementClick(CodifierEventType.BLOCK, blockId);
            }
        },
        [isDisabled, isSelected, onElementClick, blockId],
    );

    return (
        <div className={cx()}>
            <div
                className={cx('container', { selected: isSelected })}
                data-cy={
                    typeof blockIndex === 'number' ? questionsBankPageData.getBlockContainer(blockIndex) : undefined
                }
            >
                <div
                    className={cx('header', { selected: isSelected, disabled: isDisabled })}
                    onClick={handleSubjectClick}
                >
                    <div className={cx('open', { opened: isOpened, disabled: isDisabled })}>
                        <LittleArrow />
                    </div>
                    <div className={cx('title')}>{blockData.title}</div>
                </div>
            </div>
            {isOpened && !isDisabled && (
                <div className={cx('elements-container', { selected: isSelected })}>
                    {blockData.topics.map((topicId, topicIndex) => (
                        <TopicElement key={topicId} topicId={topicId} topicIndex={topicIndex} />
                    ))}
                </div>
            )}
        </div>
    );
};

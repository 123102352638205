import { RefObject, useEffect } from 'react';
import { downloadTxtFileFromUrlByLink } from 'src-new/utils';

export const useDownloadTxtFile = (ref: RefObject<HTMLDivElement>): void => {
    useEffect(() => {
        if (ref.current) {
            const downloadTxt = (event: Event) => {
                const url = (event?.target as HTMLAnchorElement)?.href;
                if (url) {
                    event.preventDefault();
                    void downloadTxtFileFromUrlByLink(url);
                }
            };

            const txtElems = ref.current?.querySelectorAll('.download_txt_file');
            txtElems?.forEach((elem) => {
                elem.addEventListener('click', downloadTxt);
            });

            return () => {
                const txtElems = ref.current?.querySelectorAll('.download_txt_file');
                txtElems?.forEach((elem) => {
                    elem.removeEventListener('click', downloadTxt);
                });
            };
        }
    }, [ref.current]);
};

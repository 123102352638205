import { AssignmentType } from './assignment';

export interface AssignmentProgress {
    id: number;
    status: 'deferredAssignment' | 'onCheck' | 'assigned' | 'completed';
    assignment: number;
    student: number;
    deadlineForComplete: string;
    assignedDate: string;
    teacher: number;
    conference: number;
    isViewed: boolean;
}

export interface StudentGroup {
    id: number;
    groupTitle: string;
    studentsQuantity: number;
    assignmentQuantity: number;
    nextLessonTime: string;
    nextAssignmentDeadline: string;
    teacherFirstName: string;
    teacherLastName: string;
    students: {
        id: number;
        firstName: string;
        lastName: string;
        photoUrl: string;
    }[];
}

export enum AssignmentProgressStatus {
    DEFERRED_ASSIGNMENT = 'deferred_assignment',
    ON_CHECK = 'on_check',
    ASSIGNED = 'assigned',
    COMPLETED = 'completed',
}
export enum AssignmentProgressType {
    TEST = 'test',
    INDEPENDENT = 'independent',
    CLASSROOM = 'classroom',
    HOMEWORK = 'homework',
    TRAINING = 'training',
    TEMPLATE = 'template',
}

export interface AssignmentProgressPreview {
    id: number;
    status: AssignmentProgressStatus;
    title?: string;
    subject: number;
    course: {
        id: number;
        title: string;
    };
    lesson: number;
    assignment: {
        id: number;
        title: string;
        description: string;
        type: AssignmentType;
    };
    courseGroup: {
        id: number;
        title: string;
    };
    assignedDate?: string;
    completeDate?: string;
    deadlineForComplete?: string;
    deadlineForCheck?: string;
}

import { EventRoom } from 'api/services/proctoring';

import { getParallelsTitle } from 'utils/getParallelsTitle';

type Course = {
    id: number;
    title: string;
};

export const getCourses = (rooms: EventRoom[]): Course[] => {
    return rooms.reduce((courses, { course, parallels }) => {
        if (!courses.some(({ id }) => id === course.id)) {
            courses.push({
                ...course,
                title: `${course.title}${parallels ? ` - ${getParallelsTitle(parallels)}` : ''}`,
            });
        }

        return courses;
    }, [] as Course[]);
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    CertificateCreateProps,
    CertificateUpdateProps,
    createMedicalCertificate,
    getMedicalCertificates,
    updateMedicalCertificate,
} from 'api/services/certificate/medicalCerificate';

export const getMedicalCertificatesAction = createAsyncThunk(
    'certificate/getMedicalCertificates',
    (student_ids?: number[]) => {
        return getMedicalCertificates(student_ids);
    },
);

export const createMedicalCertificateAction = createAsyncThunk(
    'certificate/createMedicalCertificate',
    (data: CertificateCreateProps) => {
        return createMedicalCertificate(data);
    },
);

export const updateMedicalCertificateAction = createAsyncThunk(
    'certificate/updateMedicalCertificate',
    ({ data, certificateId }: { data: CertificateUpdateProps; certificateId: number }) => {
        return updateMedicalCertificate({ data, certificateId });
    },
);

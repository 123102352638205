import { ContentMaterialTypeEnum, IMaterial } from 'types/materials';

export const checkIsMaterialChanged = (material: IMaterial, initialMaterial: IMaterial): boolean => {
    switch (material.type) {
        case ContentMaterialTypeEnum.header:
            return (
                JSON.stringify(material.headerData) !== JSON.stringify(initialMaterial.headerData) ||
                material.html !== initialMaterial.html
            );
        case ContentMaterialTypeEnum.audio:
        case ContentMaterialTypeEnum.video:
            return (
                JSON.stringify(material.audioData) !== JSON.stringify(initialMaterial.audioData) ||
                JSON.stringify(material.videoData) !== JSON.stringify(initialMaterial.videoData) ||
                material.url !== initialMaterial.url
            );
        case ContentMaterialTypeEnum.text:
            return (
                JSON.stringify(material.textData) !== JSON.stringify(initialMaterial.textData) ||
                material.html !== initialMaterial.html
            );
        case ContentMaterialTypeEnum.photo:
            return JSON.stringify(material.imageData) !== JSON.stringify(initialMaterial.imageData);
        case ContentMaterialTypeEnum.exercise:
            return JSON.stringify(material.exerciseData) !== JSON.stringify(initialMaterial.exerciseData);
        case ContentMaterialTypeEnum.table:
            return JSON.stringify(material.tableData) !== JSON.stringify(initialMaterial.tableData);
        default:
            return true;
    }
};

import { CalendarPlan as CalendarPlanElements } from '@lms-elements/educational-plan';
import { ICalendarPlan } from 'types/calendarPlan';

export const getNormalizedCalendarPlan = (calendarPlan: ICalendarPlan): CalendarPlanElements => {
    return {
        id: calendarPlan.id,
        title: calendarPlan.title,
        weekCount: Number(calendarPlan.yearWeeksCount),
        dayCount: Number(calendarPlan.yearDaysCount),
    };
};

import { getApiRequest } from 'api';
import { EventTypes } from 'types/events';

export interface ProctoringEvent {
    id: number;
    lesson: {
        id: number;
        title: string;
    };
    course: {
        id: number;
        title: string;
    };
    parallels: {
        id: number;
        title: string;
        titleShort: string;
    }[];
    type: EventTypes;
    dateStart: string;
    dateEnd: string;
    // field for student
    subject?: number;
    courseGroup?: number;
}

export type GetEventParams = {
    date?: string;
    lesson?: number;
    course?: number;
};

export const getEvents = (params: GetEventParams): Promise<ProctoringEvent[]> => {
    return getApiRequest(`/event/`, { params });
};

import { patchApiRequest } from 'api';
import { MarkScore } from 'types/mark';

import { AttendanceMarkResponse } from '.';

export interface PatchAttendanceMarkParams {
    score?: MarkScore | null;
    comment?: string | null;
}

export const changeAttendanceMark = async (
    markId: number,
    params: PatchAttendanceMarkParams,
): Promise<AttendanceMarkResponse> => {
    return await patchApiRequest<AttendanceMarkResponse, PatchAttendanceMarkParams>(
        `/mark-attendance-lesson/partial-update/${markId}/`,
        params,
    );
};

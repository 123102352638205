import React, { useMemo } from 'react';
import { AppFontIcon, AppText } from '@frontend-modules/ui-kit';
import { ProgressBar } from 'src-new/components/new-subjects';

import { TSubjectCourseCardProps } from './NewSubjectsCard.types';

import './style.scss';

/**
 * @description Component for displaying theme/course/subject card content
 * @param {TSubjectCourseCardProps} params
 * */
export const NewSubjectsCard: React.FC<TSubjectCourseCardProps> = (params: TSubjectCourseCardProps) => {
    const {
        title,
        additionalTitle,
        metrics,
        progress,
        isSelected = false,
        backgroundStyle = 'light',
        borderStyle = 'none',
        brightnessStyle = 'bright',
        hasHoverTrigger = true,
        LessonInfo,
        MobileContent,
        isTeacher,
        handleCardClick,
        handleEditLessonClick,
    } = params;

    const styleClassConstructor = useMemo(() => {
        return `${backgroundStyle} border-${borderStyle} ${brightnessStyle} ${isSelected ? 'selected' : ''} ${
            LessonInfo ? 'lesson-card' : hasHoverTrigger ? 'hovered' : ''
        }`;
    }, [backgroundStyle, borderStyle, brightnessStyle, isSelected]);

    const onCardClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        handleCardClick?.();
    };
    const onEditClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        handleEditLessonClick?.();
    };

    return (
        <div className={`subject-course-card ${styleClassConstructor}`} onClick={(event) => onCardClick(event)}>
            <div className={`subject-course-card__title ${additionalTitle ? 'one-line' : 'multiple'}`}>
                <AppText text={title} textStyle={'DesktopH4'} />
                {!!additionalTitle && (
                    <div className={'additional-title'}>
                        <AppText text={additionalTitle} textStyle={'DesktopFootNotes'} color={'#919399'} />
                    </div>
                )}
                {isTeacher && (
                    <div className={'subject-course-card__title_edit-btn'} onClick={onEditClick}>
                        <AppFontIcon icon={'pencil-l'} size={20} color={'#919399'} />
                    </div>
                )}
            </div>
            {!!metrics && !!metrics.length && (
                <>
                    <div className={'subject-course-card__metrics'}>
                        <span className={'divider'} />
                        <div className={'subject-course-card__metrics_list'}>
                            {metrics.map(({ label, status }, index) => {
                                return (
                                    <div
                                        className={`subject-course-card__metrics_list-item ${status}`}
                                        key={`metric-${index}`}
                                    >
                                        <AppText text={label} textStyle={'DesktopFootNotes'} color={'inherit'} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </>
            )}
            {LessonInfo && (
                <div className={'subject-course-card__lesson-info'}>
                    <span className={'divider'} />
                    {LessonInfo}
                </div>
            )}
            {!!progress && !!progress.length && (
                <div className={'subject-course-card__progress'}>
                    <ProgressBar progress={progress} />
                </div>
            )}
            {!!MobileContent && <div className={'subject-course-card__mobile-content'}>{MobileContent}</div>}
        </div>
    );
};

export const NewSubjectsCardMemo = React.memo(NewSubjectsCard);

import React, { useEffect, useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';
import { MultiplyAnswerExercise } from '@fsd-entities/materials/ui/Exercise/MultiplyAnswerExercise';
import { IInsertWordAnswerData, ITestAnswerData, ITrueFalseAnswerData } from '@fsd-shared/models/materials';

import { IExerciseLocalValue, IExerciseProps } from './Exercise.types';
import { InsertWordsExercise } from './InsertWordsExercise';
import { OpenAnswerExercise } from './OpenAnswerExercise';
import { SingleAnswerExercise } from './SingleAnswerExercise';

import './Exercise.styles.scss';

const CnExercise = cn('exercise');

export const Exercise: React.FC<IExerciseProps> = (props) => {
    const { exercise, changeLocalAnswer } = props;
    const { question, type, multiply, answerData, id } = exercise;
    const { correctness } = answerData || {};
    const [localAnswerValue, setLocalAnswer] = useState<IExerciseLocalValue>();

    const exerciseInfo = useMemo(() => {
        if (typeof question === 'string') {
            return <div className={`${CnExercise('question')}`} dangerouslySetInnerHTML={{ __html: question }} />;
        }
        return null;
    }, [question]);

    const onChangeValue = (value: IExerciseLocalValue) => {
        setLocalAnswer(value);
        changeLocalAnswer?.(type, value);
    };
    // если следующий вопрос будет точно таким же, то локальные данные ответов нужно сбросить,
    // иначе подставятся предыдущие ответы
    // поэтому если correctness === undefined, то вопрос обновился и введенные ответы можно сбросить
    useEffect(() => {
        if (!answerData?.correctness) {
            setLocalAnswer(undefined);
        }
    }, [answerData?.correctness]);

    switch (type) {
        case 'true_false':
            return (
                <SingleAnswerExercise
                    type={type}
                    exerciseInfo={exerciseInfo}
                    answerData={answerData as ITrueFalseAnswerData}
                    localAnswerValue={localAnswerValue?.single}
                    setLocalAnswerValue={(value) => onChangeValue({ single: value })}
                />
            );
        case 'test':
            return multiply ? (
                <MultiplyAnswerExercise
                    type={type}
                    exerciseInfo={exerciseInfo}
                    answerData={answerData as ITestAnswerData}
                    localAnswerValue={localAnswerValue?.multiple}
                    setLocalAnswerValue={(value) => onChangeValue({ multiple: value })}
                />
            ) : (
                <SingleAnswerExercise
                    type={type}
                    exerciseInfo={exerciseInfo}
                    answerData={answerData as ITestAnswerData}
                    localAnswerValue={localAnswerValue?.single}
                    setLocalAnswerValue={(value) => onChangeValue({ single: value })}
                />
            );
        case 'insert_words':
            return (
                <InsertWordsExercise
                    question={question as Array<number | string>}
                    answerData={answerData as IInsertWordAnswerData}
                    localAnswerValue={localAnswerValue?.insertWords}
                    setLocalAnswerValue={(value) => onChangeValue({ insertWords: value })}
                />
            );
        case 'text':
            return (
                <OpenAnswerExercise
                    exerciseInfo={exerciseInfo}
                    correctness={correctness}
                    localAnswerValue={localAnswerValue?.text}
                    setLocalAnswerValue={(value) => onChangeValue({ text: value })}
                />
            );
        default:
            return null;
    }
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    createCalendarPlan,
    createCalendarPlanPeriod,
    createSchoolHolidays,
    deleteCalendarPlan,
    deleteCalendarPlanPeriod,
    deleteSchoolHolidays,
    getCalendarPlan,
    getCalendarPlanList,
    patchCalendarPlan,
    patchCalendarPlanPeriod,
    patchSchoolHolidays,
} from 'api/services/calendarPlan';
import {
    ICreateCalendarPlanParams,
    ICreateCalendarPlanPeriodParams,
    ICreateSchoolHolidaysParam,
    IDeleteCalendarPlanPeriodParams,
    IDeleteSchoolHolidaysParams,
    IUpdateCalendarPlan,
    IUpdateCalendarPlanPeriodParams,
    IUpdateSchoolHolidaysParam,
} from 'types/calendarPlan';

export const createCalendarPlanAction = createAsyncThunk(
    'calendarPlan/createCalendarPlan',
    async (params: ICreateCalendarPlanParams) => {
        return await createCalendarPlan(params);
    },
);

export const createCalendarPlanPeriodAction = createAsyncThunk(
    'calendarPlan/createCalendarPlanPeriod',
    async (params: ICreateCalendarPlanPeriodParams) => {
        return await createCalendarPlanPeriod(params);
    },
);

export const createSchoolHolidaysAction = createAsyncThunk(
    'calendarPlan/createSchoolHolidays',
    async (params: Array<ICreateSchoolHolidaysParam>) => {
        return await createSchoolHolidays(params);
    },
);

export const deleteCalendarPlanAction = createAsyncThunk(
    'calendarPlan/deleteCalendarPlan',
    async (id: number, { dispatch }) => {
        return await deleteCalendarPlan(id).then(() => dispatch(getCalendarPlanListAction()));
    },
);

export const deleteCalendarPlanPeriodAction = createAsyncThunk(
    'calendarPlan/deleteCalendarPlanPeriod',
    async (params: IDeleteCalendarPlanPeriodParams) => {
        return await deleteCalendarPlanPeriod(params);
    },
);

export const deleteSchoolHolidaysAction = createAsyncThunk(
    'calendarPlan/deleteSchoolHolidays',
    async (params: IDeleteSchoolHolidaysParams) => {
        return await deleteSchoolHolidays(params);
    },
);

export const getCalendarPlanAction = createAsyncThunk('calendarPlan/getCalendarPlan', async (id: number) => {
    return await getCalendarPlan(id);
});

export const getCalendarPlanListAction = createAsyncThunk('calendarPlan/getCalendarPlanList', async () => {
    return await getCalendarPlanList();
});

export const patchCalendarPlanAction = createAsyncThunk(
    'calendarPlan/patchCalendarPlan',
    async (data: { id: number; params: IUpdateCalendarPlan }) => {
        const { id, params } = data;
        return await patchCalendarPlan(id, params);
    },
);

export const patchCalendarPlanPeriodAction = createAsyncThunk(
    'calendarPlan/patchCalendarPlanPeriod',
    async (params: Array<IUpdateCalendarPlanPeriodParams>) => {
        return await patchCalendarPlanPeriod(params);
    },
);

export const patchSchoolHolidaysAction = createAsyncThunk(
    'calendarPlan/patchSchoolHolidays',
    async (params: Array<IUpdateSchoolHolidaysParam>) => {
        return await patchSchoolHolidays(params);
    },
);

import { EditorState } from 'draft-js';

export const truthfulnessArr = ['100 % от оценки', '75 % от оценки', '50 % от оценки', '25 % от оценки'] as const;

export interface IOpenAnswerOption {
    answer: string | EditorState;
    truthfulness: typeof truthfulnessArr[number];
}

export interface IOpenAnswerFieldsProps {
    name: string;
    option: IOpenAnswerOption;
    onOptionDelete: () => void;
    isBlocked: boolean;
    disableActionBlocks?: boolean;
    savePastedStyles?: boolean;
}

import { ChartWrapperOptions } from 'react-google-charts';
import { isEmptyList } from '@frontend-modules/frontend-utils';
import { TTeachersQuestionsCreatedDiagram } from '@fsd-shared/models/statisticsMonitoring';
import { getDaysInMonth, transformHexColor } from '@fsd-shared/utils';
import { Dayjs } from 'dayjs';
import { E_COLORS } from 'src-new/constants';

import { chartOptions } from './CriteriaChart.config';
import { ESegmentTitle } from './CriteriaChart.constants';
import {
    TChartData,
    TChartLegendItem,
    TChartRow,
    TChartSegmentObject,
    TGetChartData,
    TGetChartDataProps,
    TGetDataProps,
    TGetOptionsProps,
} from './CriteriaChart.types';

export const getSortedData = (data: TTeachersQuestionsCreatedDiagram): TChartData[] => {
    const lineDiagram = data.diagramProgress.data,
        stepDiagram = data.diagramMin.data;
    const diagramTransformed = lineDiagram.map((lineItem) => {
        const stepItem = stepDiagram.find(({ label }) => label === lineItem.label);

        return {
            name: lineItem.label,
            color: lineItem.color,
            target: {
                list: stepItem?.points ?? [],
                count:
                    stepItem?.points.reduce((acc, res): number => {
                        acc += res.value;
                        return acc;
                    }, 0) ?? 0,
            },
            current: {
                list: lineItem.points,
                count:
                    lineItem.points.reduce((acc, res): number => {
                        acc += res.value;
                        return acc;
                    }, 0) ?? 0,
            },
        };
    });
    return diagramTransformed.sort((a, b) => b.target.count - a.target.count);
};
export const getLegendListInitial = (data: TTeachersQuestionsCreatedDiagram): TChartLegendItem[] => {
    const diagramData = data.diagramProgress.data;
    return diagramData.map(({ label, color, info }) => {
        return {
            name: label,
            title: `${label} ${info}`,
            color: color,
            isSelected: label === ESegmentTitle.all,
            key: `legend-${label}`,
        };
    });
};
export const getChartData = (props: TGetChartDataProps): TGetChartData => {
    const { selectedSegmentList, currentDate, data, showAllHorizontalTicks = true } = props;

    const daysCountInMonth = getDaysInMonth(currentDate);
    const maxValue = getMaxQuestionsCount(data);

    const filteredData = data.filter(({ name }) => selectedSegmentList.includes(name));
    const duplicatedSegments = getDuplicatedSegments(filteredData);
    const graySegment = duplicatedSegments.slice(-1)[0];
    const segmentToDelete = duplicatedSegments.filter((item) => item !== graySegment);

    const needShowOnlyLines = selectedSegmentList.includes(ESegmentTitle.all);
    const needShowSplitSegment =
        selectedSegmentList.length > 1 &&
        !selectedSegmentList.includes(ESegmentTitle.all) &&
        !isEmptyList(segmentToDelete);

    const renderOptions = getOptions({
        data,
        selectedSegmentList,
        daysCountInMonth,
        maxValue,
        segmentToDelete,
        graySegment,
        needShowOnlyLines,
        needShowSplitSegment,
        showAllHorizontalTicks,
    });

    const renderData = getData({
        selectedSegmentList,
        daysCountInMonth,
        data,
        segmentToDelete,
        needShowOnlyLines,
        currentDate,
        needShowSplitSegment,
    });

    return {
        renderOptions,
        renderData,
    };
};

const getMaxQuestionsCount = (data: TChartData[]) => {
    return data.reduce((acc: number, res): number => {
        const maxTargetVal = Math.max(...res.target.list.map(({ value }) => value));
        const maxCurrentVal = Math.max(...res.current.list.map(({ value }) => value));
        const maxVal = maxTargetVal < maxCurrentVal ? maxCurrentVal : maxTargetVal;

        if (acc < maxVal) {
            acc = maxVal;
        }
        return acc;
    }, 0);
};
const getOptions = (props: TGetOptionsProps): ChartWrapperOptions['options'] => {
    const {
        data,
        selectedSegmentList,
        daysCountInMonth,
        maxValue,
        segmentToDelete,
        graySegment,
        needShowOnlyLines,
        needShowSplitSegment,
        showAllHorizontalTicks,
    } = props;

    const seriesList = selectedSegmentList.reduce((acc: TChartSegmentObject[], res): TChartSegmentObject[] => {
        const color = data.find(({ name }) => name === res)?.color ?? E_COLORS.light_grey_color_3;
        const segmentOpt = {
            areaOpacity: 0.9,
            color: transformHexColor(color, 90, 0.99),
            lineWidth: 0,
            enableInteractivity: false,
        };
        const lineOpt = {
            color: color,
            type: 'line',
        };

        let seriesOpt;

        if (needShowOnlyLines) {
            seriesOpt = res === ESegmentTitle.all ? [segmentOpt, lineOpt] : [lineOpt];
        } else if (needShowSplitSegment) {
            seriesOpt = segmentToDelete.includes(res)
                ? [lineOpt]
                : graySegment === res
                ? [{ ...segmentOpt, color: E_COLORS.light_grey_color_3, areaOpacity: 1 }, lineOpt]
                : [segmentOpt, lineOpt];
        } else {
            seriesOpt = [segmentOpt, lineOpt];
        }

        acc.push(...seriesOpt);
        return acc;
    }, [] as TChartSegmentObject[]);
    //@ts-ignore
    const verticalPoints: number[] = [...Array(maxValue + 1)]
        .map((_, index) => (!index || index % 5 === 0 || index === maxValue ? index : undefined))
        .filter((item) => item !== undefined);
    const horizontalPoints: number[] = [...Array(daysCountInMonth)]
        .map((_, index) => index + 1)
        .filter((val) => (showAllHorizontalTicks ? val : val % 2 !== 0));

    return {
        ...chartOptions,
        vAxis: {
            ...chartOptions.vAxis,
            ticks: verticalPoints,
        },
        hAxis: {
            ...chartOptions.hAxis,
            ticks: horizontalPoints,
        },
        tooltip: {
            ...chartOptions.tooltip,
            ignoreBounds: showAllHorizontalTicks,
        },
        series: seriesList,
    };
};
const getData = (props: TGetDataProps): (string | number | null | { [key: string]: any })[][] => {
    const {
        selectedSegmentList,
        daysCountInMonth,
        data,
        segmentToDelete,
        needShowOnlyLines,
        needShowSplitSegment,
        currentDate,
    } = props;

    const arr: (string | number | null | { [key: string]: any })[][] = [
        [
            { type: 'number', role: 'domain' },
            { type: 'string', role: 'tooltip', p: { html: true } },
            ...selectedSegmentList.reduce((acc, res): string[] => {
                let colsArr;

                if (needShowOnlyLines) {
                    colsArr = res === ESegmentTitle.all ? [res, `${res}Line`] : [`${res}Line`];
                } else if (needShowSplitSegment) {
                    colsArr = segmentToDelete.includes(res) ? [`${res}Line`] : [res, `${res}Line`];
                } else {
                    colsArr = [res, `${res}Line`];
                }

                acc.push(...colsArr);
                return acc;
            }, [] as string[]),
        ],
    ];

    const filteredData = data.filter(({ name }) => selectedSegmentList.includes(name));
    [...Array(daysCountInMonth)].forEach((_, index) => {
        const points = filteredData.reduce((acc, { name, target, current }): TChartRow[] => {
            let rowsArr;

            const areaValue = target.list.find(({ label }) => label === `${index + 1}`)?.value ?? null;
            const lineValue = current.list.find(({ label }) => label === `${index + 1}`)?.value ?? null;

            if (needShowOnlyLines) {
                rowsArr = name === ESegmentTitle.all ? [areaValue, lineValue] : [lineValue];
            } else if (needShowSplitSegment) {
                rowsArr = segmentToDelete.includes(name) ? [lineValue] : [areaValue, lineValue];
            } else {
                rowsArr = [areaValue, lineValue];
            }

            acc.push({
                name,
                chart: rowsArr,
                tooltip: lineValue,
            });
            return acc;
        }, [] as TChartRow[]);

        const chartPoints = points.map(({ chart }) => chart).flat();
        const tooltipContent = getTooltipContent(points, index + 1, currentDate);

        arr.push([index + 1, tooltipContent, ...chartPoints]);
    });
    return arr;
};
const getDuplicatedSegments = (data: TChartData[]): string[] => {
    const dupls = data.filter((item, index, list) => {
        const listString = list.map(({ target }) => target.list.map(({ value }) => value).join());
        delete listString[index];
        const target = item.target.list.map(({ value }) => value).join();

        return listString.some((item) => item === target);
    });

    return dupls.map(({ name }) => name);
};
const getTooltipContent = (row: TChartRow[], dayNum: number, date?: Dayjs) => {
    const tooltipContentDate = date ? getDateString(date, dayNum) : '-';
    const tooltipData = row.map(({ name, tooltip }) => {
        return {
            text: name === ESegmentTitle.all ? 'Создано вопросов' : `${name} вопросы`,
            value: tooltip ?? 0,
        };
    });

    return `<div class="criteria-chart__tooltip">
                <div class="criteria-chart__tooltip_title">${tooltipContentDate}</div>
                ${tooltipData
                    .map(
                        ({ text, value }) =>
                            `<div class="criteria-chart__tooltip_row"><div>${text}</div><div>${value}</div></div>`,
                    )
                    .join('')}
    </div>`;
};
const getDateString = (date: Dayjs, day: number) => {
    return `${date.date(day).format('DD.MM.YYYY')} г.`;
};

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { HARD_MODAL_ENUMS, URL_SEARCH_ENUM } from 'src-new/components/layouts/Modals/Modals.config';

import { TPopupsState, TRowPopup } from './Modals.types';
let timeout: NodeJS.Timeout;
/**
 * @description - хук получения состояния сложной модалки
 */
export const useGetPopupsState = (): TPopupsState => {
    const { search } = useLocation();

    const [rawPopups, setRawPopups] = useState<TRowPopup[]>([]);

    const [mountedPopups, setMountedPopups] = useState(rawPopups);

    useEffect(() => {
        const query = new URLSearchParams(search);
        const queryParam = query.get(URL_SEARCH_ENUM.MODALS);
        const queryList = queryParam ? JSON.parse(queryParam) : [];
        const currentPopups = queryList?.map((item: TRowPopup) => {
            //@ts-ignore
            if (item?.type && HARD_MODAL_ENUMS[item.type.toUpperCase()]) {
                return item;
            }
        });
        setRawPopups(currentPopups);
    }, [search]);

    useEffect(() => {
        if (rawPopups) {
            if (timeout) {
                clearTimeout(timeout);
            }
            setMountedPopups(rawPopups);
        } else {
            timeout = setTimeout(() => {
                setMountedPopups([]);
            }, 300);
        }
    }, [rawPopups]);

    useEffect(() => {
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, []);

    return {
        mountedPopups,
        popups: rawPopups,
    };
};

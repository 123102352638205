import { getApiRequest } from 'api';

import { ScheduleResponseData } from '.';

/**
 *
 * @param start - date 'YYYY-MM-DD'
 * @param end - date 'YYYY-MM-DD'
 * @returns
 */
export const getScheduleByDateRange = (
    start: string,
    end: string,
    isPublished?: boolean,
): Promise<ScheduleResponseData> => {
    return getApiRequest(
        `/schedule/?datetime_start=${start}&datetime_end=${end}${isPublished ? '&lesson_is_published=true' : ''}`,
    );
};

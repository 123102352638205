import { deleteEmptyParams } from '@frontend-modules/frontend-utils';
import { IGroupActionFilters } from 'src-new/components/group-actions';
import {
    IActionsParamsFilter,
    TActionsFilterCondition,
    TActionsFilterField,
    TActionsFilterOption,
} from 'types/groupActions';
import { IFilterSelect } from 'types/meta';

export const normalizeFiltersForSetAction = (filters: IFilterSelect[]): IActionsParamsFilter[] => {
    return filters?.map((item) => {
        return {
            field: item.field as TActionsFilterField,
            value: item.option?.value || item?.value,
            label: item.option?.children || item?.value,
            option: item.type as TActionsFilterOption,
            condition: item?.condition as TActionsFilterCondition,
        };
    });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const normalizeParamsForActions = (params: IGroupActionFilters) => {
    const { filters, pagination, ...otherParams } = params;

    const normalizeFilters = filters?.map((item) => {
        return {
            field: item.field as TActionsFilterField,
            value: item.option?.value || item?.value,
            option: item.type as TActionsFilterOption,
            condition: item?.condition as TActionsFilterCondition,
        };
    });

    const normalizeParams = { filters: normalizeFilters, ...pagination, ...otherParams };
    return deleteEmptyParams(normalizeParams);
};

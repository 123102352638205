import React, { FC, useEffect, useRef, useState } from 'react';
import { authSsoStateResponder, authStateResponder } from 'api';
import { ssoLogout } from 'api/services/token';
import NotAllowedModal from 'src-new/components/auth/NotAllowedModal';
import ReAuthModal from 'src-new/components/auth/ReAuthModal';
import { setIsLoggedInAction } from 'store/actions/auth';
import { useAppDispatch } from 'store/store';

import { removeAllTokens } from 'utils/token';

import { IHttpInterceptorProps } from './HttpInterceptor.types';

const HttpInterceptor: FC<IHttpInterceptorProps> = (props) => {
    const { onReLoginStart } = props;
    const [isShowReAuthModal, setIsShowReAuthModal] = useState(false);
    const [isShowNotAllowedModal, setIsShowNotAllowedModal] = useState(false);
    // здесь хранятся экземпляры resolve для запоминания упавших в 401 запросов
    const [reAuthResolve, setReAuthResolve] = useState<Array<Function | null> | null>([]);

    const dispatch = useAppDispatch();

    /**
     * @description - перезапрашиваем упавшие запросы
     */
    const onReAuthResolve = (accessToken?: string) => {
        if (reAuthResolve?.length) {
            reAuthResolve.forEach((resolve) => resolve?.(accessToken));
            setReAuthResolve(null); // Сбрасываем переменную состояния после использования
        }
    };

    /**
     * @description разлогиниваем пользователя
     */
    const onLogout = () => {
        onReAuthResolve();
        dispatch(setIsLoggedInAction(false));
        removeAllTokens();
        void ssoLogout();
        document.location.href = `/signin`;
        removeAllTokens();
    };

    const timerRef = useRef<NodeJS.Timeout | null>(null);

    const on403Logout = () => {
        if (timerRef?.current) {
            clearInterval(timerRef?.current);
        }
        timerRef.current = setTimeout(() => {
            setIsShowNotAllowedModal(true);
            onReLoginStart();
        }, 500);
    };

    /**
     * @description обработка упавшего рефреша
     */
    const onRefreshError = () => {
        onReLoginStart();
        setIsShowReAuthModal(true);
    };

    /**
     * @description сохраняем упавшие запросы
     * @param resolve
     */
    const collectCallback = (resolve: Function) => {
        setReAuthResolve((prevState) => [...(prevState || []), resolve]);
    };

    useEffect(() => {
        authSsoStateResponder(onRefreshError);
        authStateResponder(collectCallback, on403Logout);
    }, [dispatch]);

    return (
        <>
            {isShowReAuthModal ? (
                <ReAuthModal
                    onLogin={onReAuthResolve}
                    onLogout={onLogout}
                    setIsShowReAuthModal={setIsShowReAuthModal}
                    isShowReAuthModal={isShowReAuthModal}
                />
            ) : null}
            {isShowNotAllowedModal ? (
                <NotAllowedModal
                    onLogout={onLogout}
                    setIsShowModal={setIsShowNotAllowedModal}
                    isShowModal={isShowNotAllowedModal}
                />
            ) : null}
        </>
    );
};

export default HttpInterceptor;

import { patchApiRequest } from 'api';

import { PeriodMark } from './getPeriodMark';

export interface PatchPeriodMarkParams {
    score?: number | null;
    passed?: boolean | null;
    notCertified: boolean;
    id: string;
}

export const patchPeriodMark = (params: PatchPeriodMarkParams): Promise<PeriodMark> => {
    return patchApiRequest(`/period-mark/partial-update/${params.id}/`, {
        score: params.score,
        passed: params.passed,
        notCertified: params.notCertified,
    });
};

import React, { useEffect, useMemo, useState } from 'react';
import { isEmptyList } from '@frontend-modules/frontend-utils';
import { AppCollapse, AppFontIcon, AppText } from '@frontend-modules/ui-kit';
import { TCriteriaType } from '@fsd-shared/models/statisticsMonitoring';
import { E_COLORS } from 'src-new/constants';

import { TCriteriaCollapseProps } from './CriteriaCollapse.types';

import './CriteriaCollapse.style.scss';

export const CriteriaCollapse: React.FC<TCriteriaCollapseProps> = (props) => {
    const { criteriaList, needCloseAll, onOpenCollapseItem } = props;
    const [openedCriteria, setOpenedCriteria] = useState<string[]>([]);

    useEffect(() => {
        if (needCloseAll) {
            setOpenedCriteria([]);
        }
    }, [needCloseAll]);
    const renderItems = useMemo(() => {
        return criteriaList.map(({ label, content, isCompleted, value }) => {
            return {
                key: value,
                title: (
                    <div className={'criteria-collapse__header'}>
                        <AppFontIcon
                            icon={'check-s'}
                            color={isCompleted ? E_COLORS.firm_blue_color : E_COLORS.light_grey_color_3}
                        />
                        <AppText text={label} textStyle={'DesktopH4'} />
                    </div>
                ),
                content: content,
            };
        });
    }, [criteriaList]);

    const onChangeLocal = (key: string | string[]) => {
        //TODO: поправить AppCollapse, чтобы можно было передать key
        if (Array.isArray(key)) {
            if (isEmptyList(key)) {
                setOpenedCriteria([]);
            } else {
                const types = key.map((item) => criteriaList[+item]?.value as TCriteriaType);
                const type = types[types.length - 1];
                const newKey = key[key.length - 1];

                if (!openedCriteria.includes(newKey)) {
                    onOpenCollapseItem?.(type);
                    setOpenedCriteria((prevState) => [...prevState, newKey]);
                } else {
                    setOpenedCriteria(key);
                }
            }
        } else if (key) {
            const type = criteriaList[+key]?.value as TCriteriaType;
            if (!openedCriteria.includes(type)) {
                onOpenCollapseItem?.(type);
                setOpenedCriteria((prevState) => [...prevState, `${key}`]);
            } else {
                setOpenedCriteria((prevState) => prevState.filter((item) => item !== `${key}`));
            }
        }
    };
    return (
        <div className={'criteria-collapse'}>
            <AppCollapse
                activeKey={openedCriteria}
                expandIcon={({ isActive }) => (
                    <div className={`criteria-collapse__icon ${isActive ? 'active' : ''}`}>
                        <AppFontIcon icon={'drops-right-s'} />
                    </div>
                )}
                expandIconPosition={'end'}
                bordered={false}
                items={renderItems}
                onChange={onChangeLocal}
            />
        </div>
    );
};

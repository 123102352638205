import React from 'react';
import { cn } from '@bem-react/classname';
import { Tooltip, TooltipViewEnum } from '@lms-elements/atomic';
import { Cam, CamDisabled, UserCircle, UserCircleDisabled } from '@lms-elements/icons';

import './AttendanceGarnitureStatus.scss';

const CnAttendanceGarnitureStatus = cn('attendanceGarnitureStatus');

interface IAttendanceGarnitureStatusProps {
    isCameraDisabled: boolean;
    isUserDisabled: boolean;

    cameraDescription?: string;
    userDescription?: string;

    showDescriptions?: boolean;
}

export const AttendanceGarnitureStatus: React.FC<IAttendanceGarnitureStatusProps> = ({
    isCameraDisabled,
    isUserDisabled,
    cameraDescription,
    userDescription,
    showDescriptions,
}) => {
    return (
        <div className={CnAttendanceGarnitureStatus()}>
            <div className={CnAttendanceGarnitureStatus('device')}>
                {isCameraDisabled ? <CamDisabled /> : <Cam />}
                {cameraDescription && showDescriptions && (
                    <Tooltip view={TooltipViewEnum.comment}>
                        <span>{cameraDescription}</span>
                    </Tooltip>
                )}
            </div>
            <div className={CnAttendanceGarnitureStatus('device')}>
                {isUserDisabled ? <UserCircleDisabled /> : <UserCircle />}
                {userDescription && showDescriptions && (
                    <Tooltip view={TooltipViewEnum.comment}>
                        <span>{userDescription}</span>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

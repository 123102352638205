import React, { useCallback } from 'react';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import { questionType } from '@lms-elements/test-task/build/@types/packages/TestTask/src/Question/Expanded/ExpandedQuestion.types';
import { IQuestionForTeacher } from 'src-new/components/lms-elements/TestTask';
import { ExpandedQuestion } from 'src-new/components/lms-elements/TestTask/Question';

interface QuestionsListProps {
    props: FieldArrayRenderProps<IQuestionForTeacher, HTMLInputElement>;
    questionType: questionType;
    onDelete: (props: FieldArrayRenderProps<IQuestionForTeacher, HTMLInputElement>) => void;
}

export const QuestionsList: React.FC<QuestionsListProps> = ({ props, questionType, onDelete }) => {
    const handleQuestionDelete = useCallback(() => {
        onDelete(props);
    }, [onDelete, props]);

    return (
        <div>
            {props.fields.map((name, index) => (
                <ExpandedQuestion
                    key={index}
                    name={name}
                    type={questionType}
                    onDelete={handleQuestionDelete}
                    savePastedStyles={false}
                    disableActionBlocks
                    needHideSmartBoardToggle
                />
            ))}
        </div>
    );
};

import React from 'react';
import Latex from 'react-latex-next';
import { DraftDecorator } from 'draft-js';
import { useHandleCopyCut } from 'src-new/components/lms-elements/CustomEditor/hooks/useHandleCopyCut';
import { EntityTypes } from 'src-new/components/lms-elements/CustomEditor/types/shared.types';

import { ILatexWrapperProps } from './LatexDecorator.types';

const findLatexEntities: DraftDecorator['strategy'] = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === EntityTypes.LATEX;
    }, callback);
};

const LatexWrapper: React.FC<ILatexWrapperProps> = ({ decoratedText, children }) => {
    const handleCopyCut = useHandleCopyCut(decoratedText);

    return children ? (
        <span onCopy={handleCopyCut} onCut={handleCopyCut}>
            <span style={{ display: 'none' }}>{children}</span>
            <Latex>{decoratedText}</Latex>
        </span>
    ) : (
        <></>
    );
};

export const latexDecorator = {
    strategy: findLatexEntities,
    component: LatexWrapper,
};

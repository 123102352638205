export enum E_COLORS {
    dark_grey_color_2 = '#2c2d2e',
    firm_blue_color = '#0088e9',
    dark_grey_color_1 = '#68788D',
    dark_grey_color_3 = '#585c6d',
    coral_color = '#ea5959',
    mint_color = '#2F929A',
    white_color = '#fff',
    light_grey_color_1 = '#c0c2c9',
    light_grey_color_2 = '#919399',
    light_grey_color_3 = '#EDEEF2',
    grey_2 = '#EDEEF2',
    main_dark_icons = '#585c6f',
}

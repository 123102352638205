import React, { useState } from 'react';
import { getUid } from '@frontend-modules/frontend-utils';
import { AppButton, AppDatepicker, AppModal, AppText, AppTextareaInput } from '@frontend-modules/ui-kit';
import { checkIsBefore } from '@lms-elements/utils';
import { ConfigProvider, ThemeConfig } from 'antd';
import { TLessonSchedules, WarningSign } from 'src-new/components/new-subjects';
import { EMPTY_ARRAY_2 } from 'src-new/constants';
import { AppSwitch } from 'src-new/ui';
import { payloadTimeFormatter } from 'src-new/utils';

import { emptyOlineLessonSchedule, emptySelfpreparationLessonSchedule } from './EditLessonModal.constants';
import { TEditLessonModal } from './EditLessonModal.types';

import './style.scss';
export const EditLessonModal: React.FC<TEditLessonModal> = (props: TEditLessonModal) => {
    const { lesson, error, onModalClose, onSaveLesson } = props;
    const { id, title, lessonSchedules, type } = lesson;

    const onlineLessonSchedule = lessonSchedules.find(({ type }) => type === 'online_lesson');
    const selfpreparationLessonSchedule = lessonSchedules.find(({ type }) => type === 'selfpreparation_lesson');

    const [onlineLesson, setOnlineLesson] = useState<TLessonSchedules | undefined>(
        onlineLessonSchedule
            ? {
                  ...onlineLessonSchedule,
                  datetimeStart: onlineLessonSchedule?.datetimeStart?.split('+')[0],
                  datetimeEnd: onlineLessonSchedule?.datetimeEnd?.split('+')[0],
              }
            : undefined,
    );
    const [selfpreparationLesson, setSelfpreparationLesson] = useState<TLessonSchedules | undefined>(
        selfpreparationLessonSchedule
            ? {
                  ...selfpreparationLessonSchedule,
                  datetimeStart: selfpreparationLessonSchedule?.datetimeStart?.split('+')[0],
                  datetimeEnd: selfpreparationLessonSchedule?.datetimeEnd?.split('+')[0],
              }
            : undefined,
    );
    const [lessonTitle, setLessonTitle] = useState(title);

    const dateError = checkIsBefore(selfpreparationLesson?.datetimeEnd, selfpreparationLesson?.datetimeStart);
    const isNoLessons = !selfpreparationLesson && !onlineLesson;
    const isSaveButtonDisabled =
        isNoLessons ||
        dateError ||
        !lessonTitle ||
        (selfpreparationLesson && !selfpreparationLesson?.datetimeStart) ||
        (selfpreparationLesson && !selfpreparationLesson?.datetimeEnd);
    const schedulesList: { type: 'self' | 'online'; title: string; schedule: TLessonSchedules | undefined }[] = [
        {
            type: 'self',
            title: 'Самоподготовка',
            schedule: selfpreparationLesson,
        },
        {
            type: 'online',
            title: 'Онлайн-занятие',
            schedule: onlineLesson,
        },
    ];
    const datepickerTheme: ThemeConfig = {
        components: {
            DatePicker: {
                controlPaddingHorizontalSM: 2,
                paddingSM: 10,
                paddingXS: 4,
                paddingXXS: 2,
                marginXXS: 4,
                controlHeightSM: 24,
            },
        },
    };

    const getErrorMessage = (): string => {
        if (error) {
            // @ts-ignore
            const errorData = JSON.parse(error.message) as { message: string; extra: { fields: string[] } };

            return errorData.extra.fields[0] ?? 'Не удалось переместить урок';
        }
        return '';
    };
    const onSwitchChanged = (val: boolean, type: 'self' | 'online') => {
        if (type === 'self') {
            val
                ? setSelfpreparationLesson(selfpreparationLessonSchedule ?? emptySelfpreparationLessonSchedule)
                : setSelfpreparationLesson(undefined);
        } else {
            val ? setOnlineLesson(onlineLessonSchedule ?? emptyOlineLessonSchedule) : setOnlineLesson(undefined);
        }
    };
    const onDateChanged = (val: string, type: 'self' | 'online', point: 'start' | 'end') => {
        if (type === 'self' && selfpreparationLesson) {
            const lesson =
                point === 'start'
                    ? {
                          ...selfpreparationLesson,
                          datetimeStart: val,
                      }
                    : {
                          ...selfpreparationLesson,
                          datetimeEnd: val,
                      };
            setSelfpreparationLesson(lesson);
        } else if (onlineLesson) {
            setOnlineLesson({
                ...onlineLesson,
                datetimeStart: val,
            });
        }
    };
    /**
     * @description handleSaveLesson - метод, вызываемый при сохранении урока
     * Если данные не изменились, вызовется метод onModalClose, иначе - onSaveLesson
     * (если lessonSchedule был удален, то передастся его id с отрицательным знаком, если добавлен новый - id = 0)
     *
     * */
    const handleSaveLesson = () => {
        const isTitlesEqual = title === lessonTitle.trim();
        const isOnlineLessonEqual =
            onlineLessonSchedule?.datetimeStart === payloadTimeFormatter(onlineLesson?.datetimeStart ?? '');
        const isSelpreparationLessonsEqual =
            selfpreparationLessonSchedule?.datetimeStart ===
                payloadTimeFormatter(selfpreparationLesson?.datetimeStart ?? '') &&
            selfpreparationLessonSchedule?.datetimeEnd ===
                payloadTimeFormatter(selfpreparationLesson?.datetimeEnd ?? '');

        if (isTitlesEqual && isOnlineLessonEqual && isSelpreparationLessonsEqual) {
            onModalClose();
        } else {
            const schedules: TLessonSchedules[] = [];
            if ((onlineLesson || onlineLessonSchedule) && !isOnlineLessonEqual) {
                const isLessonScheduleDeleted = onlineLessonSchedule && !onlineLesson;
                //@ts-ignore
                const lessonHandling: TLessonSchedules = onlineLesson ?? onlineLessonSchedule;
                schedules.push({
                    ...lessonHandling,
                    id: isLessonScheduleDeleted ? -lessonHandling.id : onlineLesson?.id ?? 0,
                    datetimeStart: lessonHandling.datetimeStart
                        ? payloadTimeFormatter(lessonHandling.datetimeStart)
                        : '',
                });
            }
            if ((selfpreparationLesson || selfpreparationLessonSchedule) && !isSelpreparationLessonsEqual) {
                const isLessonScheduleDeleted = selfpreparationLessonSchedule && !selfpreparationLesson;
                //@ts-ignore
                const lessonHandling: TLessonSchedules = selfpreparationLesson ?? selfpreparationLessonSchedule;
                schedules.push({
                    ...lessonHandling,
                    id: isLessonScheduleDeleted ? -lessonHandling.id : lessonHandling?.id ?? 0,
                    datetimeStart: payloadTimeFormatter(lessonHandling.datetimeStart),
                    datetimeEnd: payloadTimeFormatter(lessonHandling.datetimeEnd),
                });
            }
            const lessonToSave = {
                ...lesson,
                title: lessonTitle,
                lessonSchedules: schedules,
            };

            onSaveLesson(lessonToSave);
        }
    };

    return (
        <AppModal
            isShow={!!id}
            setShow={() => undefined}
            isShowCloseIcon={false}
            wrapClassName={'edit-lesson-modal__wrapper'}
            centered
        >
            <div className={'edit-lesson-modal'}>
                <div className={'edit-lesson-modal__title'}>
                    <AppText text={'Редактирование урока'} textStyle={'DesktopH3'} />
                </div>
                {getErrorMessage() && <WarningSign message={getErrorMessage()} type={'error'} />}
                <div className={'edit-lesson-modal__title-input'}>
                    <AppText text={'Название урока'} textStyle={'DesktopFootNotes'} color={'#919399'} />
                    <AppTextareaInput
                        maxLength={256}
                        showCount
                        size={'middle'}
                        disabled={type === 'common'}
                        defaultValue={lessonTitle}
                        onChange={(value: string) => setLessonTitle(value.trim())}
                    />
                </div>
                {schedulesList.map((scheduleItem) => {
                    const { type, schedule, title } = scheduleItem;

                    return (
                        <div className={'edit-lesson-modal__type-checkbox'} key={title}>
                            <div className={'edit-lesson-modal__type-checkbox_switcher'}>
                                <AppSwitch
                                    checked={!!schedule}
                                    withIcons
                                    size={'small'}
                                    onChange={(val) => onSwitchChanged(val, type)}
                                />
                                <AppText text={title} textStyle={'DesktopFontBody'} />
                            </div>
                            <div className={`edit-lesson-modal__type-checkbox_timepicker ${schedule ? 'visible' : ''}`}>
                                {EMPTY_ARRAY_2.map((item, index) => {
                                    const title = index ? 'Конец' : 'Начало';
                                    const dateType = index ? 'end' : 'start';
                                    const defaultValue = index ? schedule?.datetimeEnd : schedule?.datetimeStart;

                                    if ((type === 'online' && index) || !schedule) {
                                        return <></>;
                                    }
                                    return (
                                        <div className={`time-point ${dateType}`} key={getUid()}>
                                            <AppText text={title} textStyle={'DesktopFootNotes'} color={'#919399'} />
                                            <ConfigProvider theme={datepickerTheme}>
                                                <AppDatepicker
                                                    placeholder={'Дата и время'}
                                                    // @ts-ignore
                                                    showTime={{ format: 'HH:mm', minuteStep: 10 }}
                                                    visibleFormat={'DD MMM YYYY HH:mm'}
                                                    returnFormat={'YYYY-MM-DD HH:mm'}
                                                    defaultValue={defaultValue}
                                                    status={type === 'self' && dateError ? 'error' : undefined}
                                                    allowClear={false}
                                                    onChange={(val) => onDateChanged(val, type, dateType)}
                                                />
                                            </ConfigProvider>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
                <div className={'edit-lesson-modal__btns'}>
                    <AppButton label={'Отмена'} type={'outline'} onClick={onModalClose} />
                    <AppButton label={'Сохранить'} disabled={isSaveButtonDisabled} onClick={handleSaveLesson} />
                </div>
            </div>
        </AppModal>
    );
};

import React, { useMemo, useState } from 'react';
import { AppCollapse } from '@frontend-modules/ui-kit';
import { TextButton } from '@fsd-shared/ui-kit';

import { TCollapseWithButtonProps } from './CollapseWithButton.types';

import './CollapseWithButton.style.scss';
export const CollapseWithButton: React.FC<TCollapseWithButtonProps> = (props) => {
    const { header, showHeaderArrow = false, content } = props;

    const [opened, setOpened] = useState(0);

    const renderContent = useMemo(() => {
        return (
            <div className={'collapse-with-button__content'}>
                {content}
                <div className={'collapse-with-button__content_collapse-btn'}>
                    <TextButton label={'Свернуть'} handleClick={() => setOpened(1)} />
                </div>
            </div>
        );
    }, [content]);

    return (
        <div className={'collapse-with-button'}>
            <AppCollapse
                activeKey={opened}
                size={'middle'}
                panelProps={{ showArrow: showHeaderArrow }}
                onChange={() => setOpened((prevState) => Number(!prevState))}
                items={[
                    { title: <div className={'collapse-with-button__header'}>{header}</div>, content: renderContent },
                ]}
            />
        </div>
    );
};

import React from 'react';
import { cn } from '@bem-react/classname';
import { Button, ButtonViewEnum } from '@lms-elements/atomic';
import { CloseBlue, Warning } from '@lms-elements/icons';

import './Alert.scss';

interface IAlertProps {
    errorInfo: string;
    errorDescriptions?: string[];
    errorExtra?: string;
    actionText: string;
    cancelText: string;
    actionCallback: React.MouseEventHandler<HTMLButtonElement>;
    cancelCallback: React.MouseEventHandler<HTMLButtonElement>;
    closeCallback?: React.MouseEventHandler<HTMLButtonElement>;
    withCloseButton?: boolean;
    showExtra?: boolean;
    showCancelButton?: boolean;
    needHideButtons?: boolean;
    needHideErrorIcon?: boolean;
    isBlockLesson?: boolean;
    isLastAttempt?: boolean;
    createCertificate?: boolean;
}

const CnAlert = cn('alert');
const CnButton = cn('button');

export const Alert: React.FC<IAlertProps> = ({
    errorInfo,
    errorDescriptions,
    errorExtra,
    actionText,
    cancelText,
    actionCallback,
    cancelCallback,
    withCloseButton,
    closeCallback,
    showExtra,
    needHideErrorIcon,
    showCancelButton = true,
    needHideButtons,
    isBlockLesson,
    isLastAttempt,
    createCertificate,
}) => {
    return (
        <div className={CnAlert({ modalWarning: isBlockLesson || isLastAttempt || createCertificate })}>
            {!needHideErrorIcon && <Warning className={CnAlert('icon')} />}
            <div className={CnAlert('info', { modalWarning: isBlockLesson || isLastAttempt || createCertificate })}>
                {errorInfo}
            </div>
            {errorDescriptions && errorDescriptions.length > 0 && (
                <div className={CnAlert('descriptions-wrapper')} key="error-descriptions">
                    {errorDescriptions.map((description, index) => (
                        <p
                            key={index}
                            className={CnAlert('description', {
                                modalWarning: isBlockLesson || isLastAttempt || createCertificate,
                            })}
                        >
                            {description}
                        </p>
                    ))}
                </div>
            )}
            {showExtra && errorExtra && (
                <React.Fragment key={'extra'}>
                    <div className={CnAlert('extra-label')}>Подробнее:</div>
                    <div className={CnAlert('extra-wrapper')}>
                        <pre className={CnAlert('extra')}>{errorExtra}</pre>
                    </div>
                </React.Fragment>
            )}
            {!needHideButtons && (
                <div key="buttons" className={CnAlert('buttons', { withoutCancelButton: !showCancelButton })}>
                    <Button
                        view={ButtonViewEnum.action}
                        size="m"
                        onClick={actionCallback}
                        customClasses={CnButton('action-button', { isLastAttempt: isLastAttempt })}
                    >
                        {actionText}
                    </Button>
                    {showCancelButton && (
                        <Button view={ButtonViewEnum.bordered} size="m" onClick={cancelCallback}>
                            {cancelText}
                        </Button>
                    )}
                </div>
            )}
            {withCloseButton && (
                <button key="close-button" className={CnAlert('close-button')} onClick={closeCallback}>
                    <CloseBlue />
                </button>
            )}
        </div>
    );
};

import { ContentMaterialTypeEnum, IMaterial } from 'types/materials';

export const getMaterialsFilesPostArray = (material: IMaterial): File[] | undefined => {
    if (
        Array.isArray(material.documentData) &&
        material.type === ContentMaterialTypeEnum.document &&
        material.documentData.some((value) => value instanceof File)
    ) {
        return material.documentData.filter((value) => value instanceof File) as File[];
    }
};

import { message } from 'antd';

import { TMessageProps } from './setMessage.types';

const messageStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
};

export const setMessage = (props: TMessageProps): void => {
    const { text, status, duration, maxCount = 5, key } = { ...props };

    if (maxCount) {
        message.config({
            maxCount: maxCount,
        });
    }

    switch (status) {
        case 'success':
            void message.success({
                content: text,
                style: messageStyle,
                duration: duration || 1.5,
                key,
            });
            break;
        case 'error':
            void message.error({
                content: text,
                style: messageStyle,
                duration: duration || 1.5,
                key,
            });
            break;
        case 'warning':
            void message.warning({
                content: text,
                style: messageStyle,
                duration: duration || 1.5,
                key,
            });
            break;
        case 'loading':
            void message.loading({
                content: text,
                style: messageStyle,
                duration: duration || 1.5,
                key,
            });
            break;
        default:
        case 'info':
            void message.info({
                content: text,
                style: messageStyle,
                duration: duration || 1.5,
                key,
            });
            break;
    }
};

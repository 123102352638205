import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import {
    Button,
    ButtonViewEnum,
    DropdownCustom,
    FieldDropdown,
    IDropdownType,
    Toggle,
    Tooltip,
} from '@lms-elements/atomic';
import { Basket, DeleteIcon, Loading, NoteBlanks, Questions } from '@lms-elements/icons';
import { HistoryIcon } from 'assets';
import { ISelectedValues } from 'pages/QuestionCreationPage/QuestionCreationPage.types';
import { postQuestionFileAction } from 'store/actions/questionBank';
import { error } from 'store/reducers/error';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';
import { UserRoles } from 'types/user';

import { getSubject } from 'components/Codifier/utils';
import { QuestionLocationDropdown } from 'components/QuestionLocationDropdown';
import { getDateWithTime } from 'utils/date';
import { getMaxFileSizeBytes } from 'utils/getMaxFileSizeBytes';

import { INPUT_ACCEPT_FILE_TYPES, MAX_FILE_SIZE_MB } from './PropertyMenu.constants';

import './PropertyMenu.scss';

const CnPropertyMenu = cn('propertyMenu');

const FILE_INPUT_PLACEHOLDER = 'Здесь появится ссылка на файл';

interface PropertyMenuProps {
    isMethodist?: boolean;
    onClickCancel: () => void;
    handleSelectLesson?: (values: ISelectedValues) => void;
    handleSelectSkills?: (values: ISelectedValues) => void;
    isForMaterials: boolean;
    isEditPage?: boolean;
    handleClickDelete?: () => void;
    toggleChecked?: boolean;
    selectedTopics?: number[];
    topicsLocation?: string;
    selectedSkills?: { ids: number[]; location: string };
    isDisabledButton?: boolean;
    canEdit?: boolean;
    isDisabledCancelButton?: boolean;
    onSelectSubject?: (subjectId: number) => void;
    initSelectedSubject?: number;
    isSearchPage?: boolean;
    onHistoryClick?: () => void;
}

const LOCATION_PLACEHOLDER = 'Выберите тему';

export const PropertyMenu: React.FC<PropertyMenuProps> = ({
    isMethodist,
    isForMaterials,
    onClickCancel,
    handleSelectSkills,
    isEditPage = false,
    handleClickDelete = () => '',
    toggleChecked = true,
    handleSelectLesson,
    isDisabledButton,
    selectedTopics,
    topicsLocation,
    selectedSkills = { ids: [], location: '' },
    canEdit,
    isDisabledCancelButton,
    onSelectSubject,
    initSelectedSubject,
    isSearchPage,
    onHistoryClick,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const validate = useCallback((value: string) => (value ? undefined : 'error'), []);

    const showButton = useMemo(() => !isEditPage || canEdit, [canEdit, isEditPage]);

    const codifierData = useAppSelector((store) => store.codifier.codifierData);
    const { detailedQuestion, fileLink, historyQuestion, postStatusQuestionFile } = useAppSelector(
        (store) => store.questions,
    );
    const subjectsMap = useAppSelector((store) => store.codifier.subjectsMap);

    const [historyVisible, setHistoryVisible] = useState(false);

    const handleHistoryClick = useCallback(() => {
        onHistoryClick?.();
        setHistoryVisible(true);
    }, [onHistoryClick]);

    const subjectDropdownOptions = useMemo(
        () =>
            Object.values(subjectsMap)
                .map((subject) => ({ id: subject.id, title: subject.title }))
                .sort((a, b) => (a.title > b.title ? 1 : -1)),
        [subjectsMap],
    );

    const [selectedSubject, setSelectedSubject] = useState<number>(
        initSelectedSubject ??
            detailedQuestion?.subjects?.[0].id ??
            Number(new URLSearchParams(history.location.search).get('subjects')),
    );

    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        const length = fileLink.length;
        if (length) {
            setInputValue(fileLink[length - 1].url);
        }
    }, [fileLink, fileLink.length]);

    useEffect(() => {
        if (isEditPage && codifierData.length && detailedQuestion && initSelectedSubject === undefined) {
            setSelectedSubject(getSubject(codifierData, detailedQuestion.topics?.[0]?.id));
        }
    }, [codifierData, detailedQuestion, detailedQuestion.topics, initSelectedSubject, isEditPage]);

    const handleSelectSubject = useCallback(
        (value: { id: number }) => {
            onSelectSubject?.(value.id);
            setSelectedSubject(value.id);

            handleSelectLesson?.({
                location: '',
                ids: [],
            });

            const searchParams = new URLSearchParams(history.location.search);
            searchParams.set('subjects', String(value.id));
            history.push(`${history.location.pathname}?${searchParams.toString()}`);
        },
        [handleSelectLesson, history, onSelectSubject],
    );

    const [uploadProgress, setUploadProgress] = useState(0);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target?.files?.[0];
        const maxFileSizeInBytes = getMaxFileSizeBytes(MAX_FILE_SIZE_MB);

        if (file && file.size > maxFileSizeInBytes) {
            return dispatch(
                error({
                    message: `${file.name} слишком большой! Размер файла не должен превышать ${MAX_FILE_SIZE_MB}МБ`,
                    extra: {
                        needHideButtons: true,
                        isFrontend: false,
                    },
                }),
            );
        }

        if (file) {
            dispatch(
                postQuestionFileAction({
                    file: file,
                    question: isEditPage ? detailedQuestion.id : undefined,
                    onUploadProgress: setUploadProgress,
                }),
            );
        }
    };

    const handleClick = useCallback(() => {
        if (inputValue) {
            void navigator.clipboard.writeText(inputValue);
        }
    }, [inputValue]);

    const [hovered, setHovered] = useState(false);
    const [tooltipState, setTooltipState] = useState({
        top: -9999,
        left: -9999,
    });

    const handleDocumentScroll = useCallback(() => {
        setHovered(false);
    }, []);

    const handleInfoMouseEnter = useCallback(
        (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
            const info = e.currentTarget as HTMLDivElement;

            const { x, y, height, width } = info.getBoundingClientRect();
            setTooltipState({
                top: y + height,
                left: x + width,
            });

            document.addEventListener('touchmove', handleDocumentScroll);
            document.addEventListener('mousewheel', handleDocumentScroll);
            document.addEventListener('scroll', handleDocumentScroll);

            setHovered(true);
        },
        [handleDocumentScroll],
    );

    const handleInfoMouseLeave = useCallback(() => {
        setTooltipState({ top: -9999, left: -9999 });

        document.removeEventListener('touchmove', handleDocumentScroll);
        document.removeEventListener('mousewheel', handleDocumentScroll);
        document.removeEventListener('scroll', handleDocumentScroll);

        setHovered(false);
    }, [handleDocumentScroll]);

    const convertRole = (el: UserRoles) => {
        if (el === UserRoles.TEACHER) return 'Учитель';
        if (el === UserRoles.METHODIST) return 'Методист';
        if (el === UserRoles.PRINCIPAL) return 'Завуч';
        if (el === UserRoles.SUPPORT) return 'Поддержка';
    };

    return (
        <div className={CnPropertyMenu()}>
            <div className={CnPropertyMenu('header')}>
                <div className={CnPropertyMenu('title')}>Свойства</div>
                {historyVisible && (
                    <div className={CnPropertyMenu('modal')}>
                        <div className={CnPropertyMenu('window')}>
                            <DeleteIcon className={CnPropertyMenu('del')} onClick={() => setHistoryVisible(false)} />
                            <div className={CnPropertyMenu('titl')}>История изменений задания</div>
                            <div className={CnPropertyMenu('flex-title')}>
                                <div className={CnPropertyMenu('flex-date')}>Дата и время</div>
                                <div className={CnPropertyMenu('flex-fio')}>ФИО</div>
                                <div className={CnPropertyMenu('flex-date')}>Роль пользователя</div>
                                <div className={CnPropertyMenu('flex-date')}>Действие</div>
                            </div>
                            <div className={CnPropertyMenu('flex-container')} id="scroll-bar">
                                {historyQuestion.map((element) => (
                                    <div key={element.datetime} className={CnPropertyMenu('flex')}>
                                        <div className={CnPropertyMenu('flex-date')}>
                                            {getDateWithTime(element.datetime)}
                                        </div>
                                        <div className={CnPropertyMenu('flex-fio')}>{element.user}</div>
                                        <div className={CnPropertyMenu('flex-date')}>
                                            {convertRole(element.userRoles[0])}
                                        </div>
                                        <div className={CnPropertyMenu('flex-date')}>
                                            {element.action === 'create' ? 'Создал' : 'Отредактировал'}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                <div className={CnPropertyMenu('buttons')}>
                    {isEditPage && <HistoryIcon className={CnPropertyMenu('history')} onClick={handleHistoryClick} />}
                    {showButton && (
                        <Button
                            type="submit"
                            view={ButtonViewEnum.action}
                            size="l"
                            customClasses={CnPropertyMenu('save', { disabled: isDisabledButton })}
                            disabled={isDisabledButton}
                        >
                            {isEditPage ? 'Редактировать' : 'Сохранить'}
                        </Button>
                    )}
                    <Button
                        view={ButtonViewEnum.bordered}
                        size="l"
                        customClasses={CnPropertyMenu('cancel')}
                        onClick={onClickCancel}
                        disabled={isDisabledCancelButton}
                    >
                        {showButton ? 'Отмена' : 'Назад'}
                    </Button>
                    {isEditPage && canEdit && (
                        <Button
                            view={ButtonViewEnum.bordered}
                            size={'l'}
                            disabled={false}
                            icon={<Basket />}
                            onClick={handleClickDelete}
                            customClasses={CnPropertyMenu('delete')}
                        />
                    )}
                </div>
            </div>
            <div className={CnPropertyMenu('row')}>
                <div className={CnPropertyMenu('name')}>Утвержден</div>
                <div className={CnPropertyMenu('toggle')}>
                    <Field type="checkbox" name="toggle">
                        {({ input }) => (
                            <Toggle
                                {...input}
                                defaultChecked={toggleChecked}
                                disabled={!isMethodist || isForMaterials || isSearchPage}
                                handleToggleChange={input.onChange}
                            />
                        )}
                    </Field>
                    <div className={CnPropertyMenu('note')}>*будет доступен всем преподавателям</div>
                </div>
            </div>
            <div className={CnPropertyMenu('row')}>
                <div className={CnPropertyMenu('name')}>Расположение</div>
                <div className={CnPropertyMenu('location')}>
                    <div className={CnPropertyMenu('location-subject-dropdown')}>
                        <DropdownCustom
                            name="subject"
                            options={subjectDropdownOptions}
                            initValue={subjectsMap[selectedSubject]}
                            validators={[]}
                            placeholder={'Выберите предмет'}
                            isField={false}
                            type={IDropdownType.optionWithId}
                            getValue={handleSelectSubject}
                            isNeedSearch
                        />
                    </div>
                    {Boolean(selectedSubject) && (
                        <QuestionLocationDropdown
                            handleSelectLesson={handleSelectLesson}
                            selectedTopics={selectedTopics}
                            topicsLocation={topicsLocation}
                            name={'topicsLocation'}
                            isEditPage={isEditPage}
                            placeholder={LOCATION_PLACEHOLDER}
                            selectedSubject={selectedSubject}
                        />
                    )}
                </div>
            </div>
            <div className={CnPropertyMenu('row')}>
                <div className={CnPropertyMenu('name')}>Сложность</div>
                <div className={CnPropertyMenu('complexity')}>
                    <FieldDropdown
                        validators={[validate]}
                        name={'complexity'}
                        options={['Лёгкая', 'Средняя', 'Тяжёлая']}
                        needBlueIcon={true}
                        placeholder={'Выберите из списка'}
                    />
                </div>
            </div>
            <div className={CnPropertyMenu('row')}>
                <div className={CnPropertyMenu('name')}>Навыки</div>
                <div className={CnPropertyMenu('location')}>
                    <QuestionLocationDropdown
                        handleSelectSkill={handleSelectSkills}
                        isSkill
                        selectedSkills={selectedSkills}
                        name={'skillsLocation'}
                        disableValidation
                    />
                </div>
            </div>
            <div className={CnPropertyMenu('row')}>
                <div className={CnPropertyMenu('name')} />
                <div className={CnPropertyMenu('file-container')}>
                    <label className={CnPropertyMenu('file-input-label')}>
                        {postStatusQuestionFile === FetchStatus.FETCHING ? (
                            <div className={CnPropertyMenu('loading-icon')}>
                                <Loading />
                            </div>
                        ) : (
                            <>
                                <span>Добавить файл</span>
                                <input type="file" accept={INPUT_ACCEPT_FILE_TYPES} onChange={handleChange} />
                            </>
                        )}
                    </label>
                    {postStatusQuestionFile === FetchStatus.FETCHING && (
                        <progress className={CnPropertyMenu('upload-progress')} max={100} value={uploadProgress} />
                    )}
                    {postStatusQuestionFile === FetchStatus.FETCHED && (
                        <div className={CnPropertyMenu('input-container')}>
                            <input
                                className={CnPropertyMenu('input')}
                                type="text"
                                placeholder={FILE_INPUT_PLACEHOLDER}
                                value={inputValue}
                                title={FILE_INPUT_PLACEHOLDER}
                            />
                            <div className={CnPropertyMenu('icon', { copy: true })}>
                                <NoteBlanks onClick={handleClick} />
                            </div>
                            <div
                                className={CnPropertyMenu('icon')}
                                onMouseEnter={handleInfoMouseEnter}
                                onMouseLeave={handleInfoMouseLeave}
                                onTouchStart={handleInfoMouseEnter}
                            >
                                <Questions />
                                {hovered && (
                                    <>
                                        <div className={CnPropertyMenu('tooltip')} style={tooltipState}>
                                            <Tooltip withPointer={false}>
                                                Используйте ссылку, полученную после добавления файла, чтобы добавить
                                                его в ваш вопрос
                                            </Tooltip>
                                        </div>
                                        <div className={CnPropertyMenu('tooltip-pointer')} />
                                    </>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

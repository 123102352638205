import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cn } from '@bem-react/classname';
import { getUid, isListExist } from '@frontend-modules/frontend-utils';
import { AppFontIcon } from '@frontend-modules/ui-kit';
import { Dropdown, DropdownLabel, StatusLabelEnum } from '@lms-elements/atomic';
import { Expand, HwProcess, Passed } from '@lms-elements/icons';
import { E_COLORS } from 'src-new/constants';
import { patchStudentPresenceAction } from 'store/actions/attendance';
import { AssignmentProgressStatus } from 'types/assignmentProgress';
import { AssignedHomeWork, AssignedTasks } from 'types/homework';
import { MarkData } from 'types/mark';

import { AttendanceMark } from 'components/AttendanceMark';
import { convertAttendanceStatus } from 'utils/convertAttandanceStatus';
import { getDateWithoutYear, getDateWithTime } from 'utils/date';
import { normalizePresenceValues } from 'utils/presence';

import './AssignedHomeworkLine.scss';

const CnAssignedHomeworkLine = cn('assignedHomeworkLine');

interface HomeworkLineProps {
    student: AssignedHomeWork;
    showCompletedTasks: boolean;
    showAssignedTasks: boolean;
    showOnCheckTasks: boolean;
    showDeferredAssignedTasks?: boolean;
    withAttendance?: boolean;
    editAttendance?: boolean;
    submitChangeMark?: (values: { selectedMark?: number; comment?: string; markId: number }[]) => void;
    onDeleteMark?: (id: number) => void;
    onHomeworkClick?: (taskId: number) => void;
}

export const AssignedHomeworkLine: React.FC<HomeworkLineProps> = ({
    student,
    showCompletedTasks,
    showAssignedTasks,
    showOnCheckTasks,
    showDeferredAssignedTasks,
    withAttendance = false,
    editAttendance = false,
    submitChangeMark,
    onDeleteMark,
    onHomeworkClick,
}) => {
    const dispatch = useDispatch();

    const [openedMarksLists, setOpenedMarksLists] = useState<number[]>([]);
    const handleExpandIconClick = (index: number) => {
        if (openedMarksLists?.includes(index)) {
            setOpenedMarksLists((prevState) => prevState?.filter((item) => item !== index));
        } else {
            setOpenedMarksLists((prevState) => [...prevState, index]);
        }
    };
    const getLatestMark = useCallback((marks?: MarkData[]): MarkData[] => {
        if (marks?.length) {
            return [
                marks.reduce((highestMark, currentMark) => {
                    return highestMark.score && currentMark.score && highestMark.id > currentMark.id
                        ? highestMark
                        : currentMark;
                }),
            ];
        }
        return [];
    }, []);

    const getTaskInfo = (
        studentTask: AssignedTasks,
        isDeferredAssigned: boolean,
        hasDeferredAssignedErrors: boolean,
    ): string => {
        const startDate = studentTask?.assignedDate
            ? `${isDeferredAssigned ? 'Будет выдано:' : 'Выдано:'} ${getDateWithTime(studentTask?.assignedDate)}\n`
            : '';
        const endDate = studentTask.deadlineForComplete
            ? `Cдать до: ${getDateWithTime(studentTask.deadlineForComplete)}\n`
            : '';
        const timeToComplete = studentTask.timeToComplete
            ? `Время на выполнение (мин): ${
                  studentTask.timeToComplete ? studentTask.timeToComplete : 'не ограничено'
              }\n`
            : '';
        const teacherName = `${studentTask?.teacher?.lastName ?? ''} ${studentTask?.teacher?.firstName ?? ''} ${
            studentTask?.teacher?.patronymic ?? ''
        }`.trim();
        const teacher = `Преподаватель: ${teacherName ? teacherName : '-'}`;

        const errorList = hasDeferredAssignedErrors ? `\nОшибки:\n${studentTask?.errorsList?.join('\n')}` : '';

        return `${startDate}${endDate}${timeToComplete}${teacher}${errorList}`;
    };

    const presenceSelectHandler = (id: number, values: { status: StatusLabelEnum; lateMinutes: number }) => {
        if (id) {
            const normalizedValues = normalizePresenceValues(values);
            dispatch(patchStudentPresenceAction({ id, values: normalizedValues }));
        }
    };
    return (
        <>
            {withAttendance && (
                <td style={editAttendance ? { width: '205px' } : undefined}>
                    {editAttendance
                        ? student.status && (
                              <Dropdown
                                  status={convertAttendanceStatus(student.status)}
                                  lateTime={student.lateDuration ?? 0}
                                  selectHandler={presenceSelectHandler?.bind(null, Number(student?.attendanceId))}
                              />
                          )
                        : student.status && (
                              <DropdownLabel status={convertAttendanceStatus(student.status)} needHideIcon />
                          )}
                </td>
            )}
            {student.tasks.map((task, index) => {
                const isListOpened = openedMarksLists.includes(index);

                return (
                    <td key={index} className={CnAssignedHomeworkLine({ isChecked: task?.[0].status === 'on_check' })}>
                        {task && (
                            <div className={CnAssignedHomeworkLine('icon-wrapper')}>
                                {task.slice(0, isListOpened ? task.length : 2).map((studentTask) => {
                                    const isAssigned =
                                        studentTask?.status === AssignmentProgressStatus.ASSIGNED && showAssignedTasks;
                                    const isDeferredAssigned =
                                        studentTask?.status === AssignmentProgressStatus.DEFERRED_ASSIGNMENT &&
                                        showDeferredAssignedTasks;
                                    const isCompletedWithoutMarks =
                                        studentTask?.status === AssignmentProgressStatus.COMPLETED &&
                                        showCompletedTasks &&
                                        !studentTask?.marks?.length;
                                    const isOnCheck =
                                        studentTask?.status === AssignmentProgressStatus.ON_CHECK && showOnCheckTasks;
                                    const withMarks =
                                        studentTask?.status === AssignmentProgressStatus.COMPLETED &&
                                        showCompletedTasks &&
                                        (studentTask?.marks || []).length > 0;
                                    const hasDeferredAssignedErrors =
                                        isDeferredAssigned && isListExist(studentTask?.errorsList);

                                    const title = getTaskInfo(
                                        studentTask,
                                        !!isDeferredAssigned,
                                        !!hasDeferredAssignedErrors,
                                    );
                                    return (
                                        <React.Fragment key={getUid()}>
                                            {(isAssigned ||
                                                isDeferredAssigned ||
                                                isCompletedWithoutMarks ||
                                                isOnCheck ||
                                                withMarks) && (
                                                <>
                                                    <div
                                                        className={CnAssignedHomeworkLine('icon', {
                                                            isCompleted: isCompletedWithoutMarks || withMarks,
                                                            isCheck: isOnCheck,
                                                        })}
                                                        onClick={onHomeworkClick?.bind(null, studentTask.id)}
                                                        title={title}
                                                    >
                                                        {withMarks && (
                                                            <AttendanceMark
                                                                marks={getLatestMark(studentTask.marks)}
                                                                isNeedAddMark={false}
                                                                submitChangeMark={submitChangeMark}
                                                                onDeleteMark={onDeleteMark}
                                                            />
                                                        )}
                                                        {isAssigned && <HwProcess />}
                                                        {isCompletedWithoutMarks && (
                                                            <AppFontIcon
                                                                icon={'homework-m'}
                                                                color={'#ea5959'}
                                                                size={24}
                                                            />
                                                        )}
                                                        {isOnCheck && showOnCheckTasks && <Passed />}
                                                        {isDeferredAssigned && showDeferredAssignedTasks && (
                                                            <AppFontIcon
                                                                icon={'homework-a-m'}
                                                                color={
                                                                    hasDeferredAssignedErrors
                                                                        ? E_COLORS.coral_color
                                                                        : E_COLORS.dark_grey_color_3
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                    {isDeferredAssigned && studentTask?.assignedDate && (
                                                        <span className={CnAssignedHomeworkLine('assignment-date')}>
                                                            {getDateWithoutYear(studentTask?.assignedDate)}
                                                        </span>
                                                    )}
                                                </>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                                <Expand
                                    className={CnAssignedHomeworkLine('expand-icon', {
                                        isVisible: task.length > 2,
                                        isOpened: isListOpened,
                                    })}
                                    onClick={handleExpandIconClick.bind(this, index)}
                                />
                            </div>
                        )}
                    </td>
                );
            })}
        </>
    );
};

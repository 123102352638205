import React from 'react';
import { TTreeData } from 'src-new/components/lesson-task';

export const getTreeSelectorTagBreadcrumbs = (params: { treeData: TTreeData[]; breadcrumbs: string }): JSX.Element => {
    const { breadcrumbs, treeData } = params;

    const keyList = breadcrumbs?.split('-');
    const depths = {} as { [key: number]: React.ReactNode };

    treeData.forEach((firstDepth) => {
        const key1 = keyList?.[0];
        if (key1 && firstDepth.value === key1) {
            depths[0] = firstDepth.label;

            if (firstDepth.children) {
                const key2 = `${keyList[0]}-${keyList[1]}`;
                const secondDepth = firstDepth.children.find(({ value }) => value === key2);

                if (secondDepth) {
                    depths[1] = secondDepth?.label;

                    if (secondDepth.children) {
                        const key3 = `${keyList[0]}-${keyList[1]}-${keyList[2]}`;

                        const thirdDepth = secondDepth.children.find(({ value }) => value === key3);

                        if (thirdDepth) {
                            depths[2] = thirdDepth?.label;
                        }
                    }
                }
            }
        }
    });

    return (
        <div className={'tree-selector__tag-label_breadcrumbs'}>
            {depths[0]}
            {depths[0] ? ',' : ''} {depths[1]}
            {depths[1] ? ',' : ''} <br /> {depths[2]}
            {depths[2] ? ':' : ''}
        </div>
    );
};

import React from 'react';
import { isEmptyList } from '@frontend-modules/frontend-utils';
import { CodifierSkillsResponseData } from 'api/services/codifier';
import {
    TGetInitialFilters,
    TGetInitialFiltersProps,
    TQuestionComplicity,
    TQuestionDifficultyField,
    TQuestionSkill,
    TQuestionTypes,
    TQuestionTypesField,
} from 'src-new/components/lesson-task';
import { E_COMPLEXITY_OPTIONS, E_QUESTION_TYPE } from 'src-new/constants';
import { TCodifierSubjectData } from 'types/lessonTask';

const getCurrentTreeBranch = (params: { topicsIds?: number[]; tree?: TCodifierSubjectData[] }) => {
    const { topicsIds, tree } = params;

    if (topicsIds && tree) {
        return tree?.find((item) =>
            item.parallels?.find((parallel) =>
                parallel?.sections?.find((section) =>
                    section?.blocks?.find((block) => block?.topics?.find((topic) => topicsIds.includes(topic.id))),
                ),
            ),
        );
    }
    return undefined;
};
const getTopicsValues = (params: { topicsIds?: number[]; tree?: TCodifierSubjectData }) => {
    const { topicsIds, tree } = params;
    const topicsValues = [] as string[];

    if (topicsIds && tree) {
        const parallels = tree?.parallels ?? [];

        parallels.forEach((parallel) =>
            parallel.sections?.forEach((section) =>
                section.blocks?.forEach((block) =>
                    block.topics?.forEach(({ id, title }) => {
                        if (topicsIds.includes(id)) {
                            topicsValues.push(`parallel${parallel.id}-section${section.id}-block${block.id}-${id}`);
                        }
                    }),
                ),
            ),
        );
    }

    return topicsValues;
};
const getSkillsValues = (params: { skillsList: number[]; tree?: CodifierSkillsResponseData[] }) => {
    const { skillsList, tree } = params;
    const skillsValues = [] as string[];

    if (!isEmptyList(skillsList) && tree) {
        tree?.forEach((subject) =>
            subject.parallels?.forEach((parallel) =>
                parallel.metasubjects?.forEach((metasubject) =>
                    metasubject.skills?.forEach(({ id }) => {
                        if (skillsList.includes(id)) {
                            skillsValues.push(
                                `subject${subject.id}-parallel${parallel.id}-metasubject${metasubject.id}-${id}`,
                            );
                        }
                    }),
                ),
            ),
        );
    }

    return skillsValues;
};
const getComplicityValues = (list?: TQuestionDifficultyField[]) => {
    if (list && !isEmptyList(list)) {
        return list.map((item) => E_COMPLEXITY_OPTIONS[item as TQuestionDifficultyField]);
    }
    return Object.values(E_COMPLEXITY_OPTIONS) as TQuestionComplicity[];
};
const getAnswerTypesValues = (list?: TQuestionTypesField[], isShowEssayOption?: boolean) => {
    if (list && !isEmptyList(list)) {
        return list.map((item) => E_QUESTION_TYPE[item as TQuestionTypesField]);
    }

    return isShowEssayOption
        ? (Object.values(E_QUESTION_TYPE) as TQuestionTypes[])
        : (Object.values(E_QUESTION_TYPE) as TQuestionTypes[]).filter((item) => item !== E_QUESTION_TYPE.essay);
};

export const getInitialFilters = (props: TGetInitialFiltersProps): TGetInitialFilters => {
    const { filters, tree, codifierSkillsData, isShowEssayOption } = props;
    const { text, topics, difficulty, answersType, skillsOr, skillsAnd } = filters;

    const data = getCurrentTreeBranch({ topicsIds: topics, tree });

    const topicsValues = getTopicsValues({ topicsIds: topics, tree: data });
    const skillsValues = getSkillsValues({ skillsList: skillsOr ?? skillsAnd ?? [], tree: codifierSkillsData });
    const complicityValues = getComplicityValues(difficulty);
    const answersTypeValue = getAnswerTypesValues(answersType, isShowEssayOption);
    const skillsUnionTypeValue: TQuestionSkill = skillsAnd ? 'Любой из нижеперечисленных' : 'Все';

    return {
        topicsValues: topicsValues,
        skillsValues: skillsValues,
        complicityValues,
        answersTypeValue,
        skillsUnionTypeValue,
        textValue: text ?? '',
        subjectId: data?.id ?? 0,
    };
};

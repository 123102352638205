import React, { useCallback, useMemo } from 'react';
import { FormRenderProps } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { Avatar } from '@lms-elements/atomic';
import { AssignmentProgress, Task } from 'types/homework';
import { Student } from 'types/students';

import { GroupAvatars } from 'components/GroupAvatars';

import { AssignedHomeworkLine } from './AssignedHomeWorkLine';
import { HomeworkLine } from './HomeworkLine';
import { getAssignedHomeworks } from './utils';

import './HomeworkTable.scss';

const CnHomeworkTable = cn('homeworkTable');

export interface HomeworkTableFormValues {
    selectAll: {
        taskId: number;
        checked: boolean;
        disabled: boolean;
    }[];
    students: {
        studentId: number;
        tasks: {
            taskId: number;
            checked: boolean;
            blocked?: boolean;
            repeatBlocked?: boolean;
        }[];
    }[];
}

interface HomeworkTableProps {
    tasks: Task[];
    students: Student[];
    assignmentProgress?: AssignmentProgress[];
    hasSelectAllRow?: boolean;
    isEditForm?: boolean;
    formProps?: FormRenderProps<HomeworkTableFormValues, Partial<HomeworkTableFormValues>>;
    showCompletedTasks?: boolean;
    showAssignedTasks?: boolean;
    showOnCheckTasks?: boolean;
    showDeferredAssignedTasks?: boolean;
    withAttendance?: boolean;
    submitChangeMark?: (values: { selectedMark?: number; comment?: string; markId: number }[]) => void;
    onDeleteMark?: (id: number) => void;
    onHomeworkClick?: (studentId: number, taskId: number) => void;
    onStudentClick?: (studentId: number) => void;
    repeatTask?: boolean;
    needDisableCheckboxes?: boolean;
    editAttendance?: boolean;
}

export const HomeworkTable: React.FC<HomeworkTableProps> = ({
    tasks,
    students,
    assignmentProgress,
    hasSelectAllRow,
    formProps,
    isEditForm = true,
    showCompletedTasks = true,
    showAssignedTasks = true,
    showOnCheckTasks = true,
    showDeferredAssignedTasks = true,
    withAttendance = false,
    editAttendance = false,
    submitChangeMark,
    onDeleteMark,
    onHomeworkClick,
    onStudentClick,
    repeatTask,
    needDisableCheckboxes,
}) => {
    const isTaskSelected = useMemo(() => tasks.length > 0, [tasks]);

    const assignedHomeworks = useMemo(() => getAssignedHomeworks(students, tasks, assignmentProgress), [
        students,
        assignmentProgress,
        tasks,
    ]);

    const makeHandlerStudentCellClick = useCallback(
        (studentId: number) => {
            return () => {
                if (onStudentClick) {
                    onStudentClick(studentId);
                }
            };
        },
        [onStudentClick],
    );

    const handleHomeworkClick = useCallback(
        (studentId: number, taskId: number) => {
            if (onHomeworkClick) {
                onHomeworkClick(studentId, taskId);
            }
        },
        [onHomeworkClick],
    );

    return (
        <table className={CnHomeworkTable({ hasSelectAllRow })}>
            <thead className={CnHomeworkTable('header')}>
                <tr>
                    <th className={CnHomeworkTable('studentsHeader', { withAttendance: withAttendance })}>Ученик</th>
                    {withAttendance ? (
                        <>
                            <th className={CnHomeworkTable('tasksHeader', { withAttendance: withAttendance })}>
                                Посещение
                            </th>
                            {tasks.map((_, index) => (
                                <th
                                    key={index}
                                    className={CnHomeworkTable('tasksHeader', { withAttendance: withAttendance })}
                                >
                                    {isEditForm ? 'Выдача' : 'Статус'}
                                </th>
                            ))}
                        </>
                    ) : (
                        <th colSpan={tasks.length} className={CnHomeworkTable('tasksHeader')}>
                            Выдаем:
                        </th>
                    )}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className={CnHomeworkTable('studentsHeader')}></td>
                    {!isTaskSelected && !withAttendance && (
                        <th className={CnHomeworkTable('task')}>
                            <div className={CnHomeworkTable('taskTitle')}>Выберите задание в левой части экрана</div>
                        </th>
                    )}
                    {withAttendance && <td className={CnHomeworkTable('studentsHeader')}></td>}
                    {tasks.map((task) => (
                        <th
                            key={task.id}
                            className={CnHomeworkTable('task')}
                            title={task.isTemplate ? `Ш-${task.title}` : task.title}
                        >
                            <div className={CnHomeworkTable('taskTitle')}>
                                {task.isTemplate ? `Ш-${task.title}` : task.title}
                            </div>
                        </th>
                    ))}
                </tr>
                {hasSelectAllRow && isEditForm && (
                    <tr className={CnHomeworkTable('assignAllRow')}>
                        <td className={CnHomeworkTable('assignAllGroup')}>
                            <div className={CnHomeworkTable('group')}>
                                <GroupAvatars
                                    avatars={students.map((student) => {
                                        return student.photoUrl;
                                    })}
                                />
                                <span className={CnHomeworkTable('groupLabel')}>
                                    {withAttendance ? 'Выдать присутствующим' : 'Выдать всем'}
                                </span>
                            </div>
                        </td>
                        <HomeworkLine
                            name="selectAll"
                            onChange={formProps?.form.mutators.setCheckboxes}
                            withAttendance={withAttendance}
                            needDisableCheckboxes={
                                needDisableCheckboxes ||
                                (withAttendance && students.every(({ status }) => status === undefined))
                            }
                        />
                    </tr>
                )}
                {students?.map((student, index) => (
                    <tr key={student.id} className={CnHomeworkTable('studentRow')}>
                        <td
                            className={CnHomeworkTable('studentCell')}
                            onClick={makeHandlerStudentCellClick(student.id)}
                        >
                            <Avatar
                                name={`${student.lastName} ${student.firstName}`}
                                source={student.photoUrl}
                                alt={'Фото'}
                                isOnAdaptation={student.isAdaptation}
                                isOnline={student.online}
                            />
                        </td>
                        {isEditForm && (
                            <HomeworkLine
                                name={`students[${index}].tasks`}
                                onChange={formProps?.form.mutators.resetCheckbox}
                                repeatTask={repeatTask}
                                withAttendance={withAttendance}
                                status={student.status}
                                needDisableCheckboxes={needDisableCheckboxes}
                            />
                        )}
                        {!isEditForm &&
                            assignedHomeworks
                                .filter((homework) => homework.studentId === student.id)
                                .map((student) => (
                                    <AssignedHomeworkLine
                                        key={student.studentId}
                                        student={student}
                                        showCompletedTasks={showCompletedTasks}
                                        showAssignedTasks={showAssignedTasks}
                                        showOnCheckTasks={showOnCheckTasks}
                                        showDeferredAssignedTasks={showDeferredAssignedTasks}
                                        withAttendance={withAttendance}
                                        editAttendance={editAttendance}
                                        submitChangeMark={submitChangeMark}
                                        onDeleteMark={onDeleteMark}
                                        onHomeworkClick={handleHomeworkClick.bind(null, student.studentId)}
                                    />
                                ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

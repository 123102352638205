import { eduWeeksType, periodsType } from 'types/calendarPlan';

export const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY || '6LcXBFUeAAAAACa3D5Suv3ML8eDWvJMxHxofUYav';
export const TARIFF_CURIOUS_ID = 12;
export const TARIFF_CURIOUS_TITLE = 'curious';
export const PASSWORD_PATTERN = /^[a-z0-9_-]+$/i;

export const DEFAULT_TABLE_PAGE = 1;
export const DEFAULT_TABLE_PAGE_SIZE = 16;
export const LIST_PAGINATE_LIMIT = 20;
export const GROUP_ACTIONS_PAGE_TAB_NAME = 'group-actions-page-tab-name';

export const studyPeriods: Array<{ value: periodsType; label: string }> = [
    { value: 'quarter', label: 'Четверть' },
    { value: 'trimester', label: 'Триместр' },
    { value: 'half_year', label: 'Пол года' },
    { value: 'year', label: 'Год' },
];
export const eduWeeks: Array<{ value: eduWeeksType; label: string }> = [
    { value: 5, label: 'Пятидневная' },
    { value: 6, label: 'Шестидневная' },
];

export const weekdaysRu = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
export const monthRu = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
];

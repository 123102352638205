import moment from 'moment';

export const getSelectedDate = (day?: string | Date, time?: string): moment.Moment => {
    const [hours, minutes] = time?.split(':') || [0, 0];

    return moment(day, '', 'RU')
        .subtract(1, 'days')
        .endOf('days')
        .add(1, 'minutes')
        .add(hours, 'hours')
        .add(minutes, 'minutes');
};

import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { cn } from '@bem-react/classname';
import { AppText } from '@frontend-modules/ui-kit';
import { Checkbox, File } from '@lms-elements/atomic';
import { useRole } from 'hooks';
import {
    createMeetingFileAction,
    deleteMeetingFileAction,
    getCourseGroupMeetingFilesAction,
    getCourseMeetingFilesAction,
    updateMeetingFileAction,
} from 'store/actions/meetingFiles';
import { resetMeetingFilesState } from 'store/reducers/meetingFiles';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';
import {
    TCreateCourseGroupMeetingFilePayload,
    TCreateCourseMeetingFilePayload,
    TUpdateCourseGroupMeetingFilePayload,
    TUpdateCourseMeetingFilePayload,
} from 'types/meetingFiles';

import { AddFiles } from 'components/AddFiles';

import { IFilesContainerProps, TFile } from './FilesContainer.types';

import './FilesContainer.scss';

const cx = cn('files-container');

export const FilesContainer: React.FC<IFilesContainerProps> = ({
    lessonScheduleId,
    courseGroupId,
    lessonId,
    isOvered,
    isTest,
}) => {
    const dispatch = useDispatch();
    const { courseMeetingFiles, courseGroupMeetingFiles, getMeetingFilesStatus } = useAppSelector(
        (store) => store.meetingFiles,
    );
    const { fetchStatus } = useAppSelector((store) => store.schedule);
    const { info } = useAppSelector((store) => store.user);

    const { isStudent, isTeacher, isMethodist: isMethodistRole, isPrincipal: isPrincipalRole, isSupport } = useRole();
    // TODO: изменить, когда будут известны все отличия между support и завучем. Сейчас support видит все тоже самое что завуч
    const isPrincipal = isPrincipalRole || isSupport;

    const isMethodist = isMethodistRole || isPrincipal;

    const isCourseGroup = !!courseGroupId && !!lessonScheduleId;

    const needfetchFiles =
        (!lessonScheduleId && !courseGroupId) ||
        fetchStatus === FetchStatus.FETCHED ||
        getMeetingFilesStatus === FetchStatus.INITIAL;

    useEffect(() => {
        if (needfetchFiles) {
            if (isCourseGroup) {
                //@ts-ignore
                dispatch(getCourseGroupMeetingFilesAction({ lessonSchedule: lessonScheduleId }));
            } else if (lessonId && !courseGroupId) {
                dispatch(getCourseMeetingFilesAction({ lesson: lessonId }));
            }
        }
    }, [dispatch, needfetchFiles, lessonId, lessonScheduleId]);

    useEffect(() => {
        return () => {
            dispatch(resetMeetingFilesState());
        };
    }, [dispatch]);
    const files: TFile[] = useMemo(() => {
        if (courseGroupMeetingFiles.length) {
            return courseGroupMeetingFiles.map((file) => {
                const { id, isAvailableToStudents, meetingFile, useOnMeeting } = file;
                const { id: meetingFileId, fileUrl, isTemplate, title, extension, uploadedBy } = meetingFile;
                return {
                    id,
                    isAvailableToStudents,
                    useOnMeeting,
                    isTemplate,
                    meetingFileId,
                    fileUrl,
                    title,
                    extension,
                    uploadedBy,
                };
            });
        }
        if (courseMeetingFiles.length) {
            return courseMeetingFiles;
        }
        return [];
    }, [courseMeetingFiles, courseGroupMeetingFiles]);

    const isCanUpload = (isMethodist && !courseGroupId) || ((isMethodist || isTeacher) && isCourseGroup);
    const isShowContent = !!files.length || isCanUpload;

    const handleFilesCheckboxChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const checkbox = e.target;
            const params: TUpdateCourseMeetingFilePayload | TUpdateCourseGroupMeetingFilePayload = {
                id: Number(checkbox.id),
            };

            if (checkbox.name === 'useOnMeeting') {
                params.useOnMeeting = checkbox.checked;
            } else if (checkbox.name === 'isAvailableToStudents') {
                params.isAvailableToStudents = checkbox.checked;
            } else {
                params.isTemplate = checkbox.checked;
            }

            dispatch(
                updateMeetingFileAction({
                    params,
                    isCourseGroup,
                    lessonScheduleId,
                }),
            );
        },
        [dispatch, files, lessonId, lessonScheduleId],
    );

    const handleUploadFile = useCallback(
        (file: File) => {
            const params: TCreateCourseMeetingFilePayload | TCreateCourseGroupMeetingFilePayload = lessonScheduleId
                ? {
                      file,
                      lessonSchedule: lessonScheduleId,
                  }
                : {
                      file,
                      lesson: lessonId,
                  };

            if (file) {
                dispatch(
                    createMeetingFileAction({
                        params,
                        isCourseGroup,
                        userInfo: info,
                    }),
                );
            }
        },
        [dispatch, isMethodist, isTeacher, lessonId, lessonScheduleId],
    );

    const handleDeleteFile = useCallback(
        (fileId: number) => {
            dispatch(
                deleteMeetingFileAction({
                    id: fileId,
                    isCourseGroup,
                }),
            );
        },
        [dispatch, files, isMethodist],
    );

    return (
        <div className={cx({ student_view: isStudent, isTest })}>
            {isStudent ? (
                <>
                    <h2 className={cx('filesTitle')}>Файлы</h2>
                    {!files.length && (
                        <AppText text={'Список файлов пуст'} color={'#919399'} textStyle={'DesktopFootNotes'} />
                    )}
                    {files?.map((item, index) => (
                        <File
                            key={index}
                            name={`${item.title}.${item.extension}`}
                            url={item.fileUrl}
                            id={String(item.id)}
                            isTemplate={item.isTemplate}
                            needHideSendButton
                            needHideDeleteButton
                            needHideEditButton
                        />
                    ))}
                </>
            ) : isShowContent ? (
                <>
                    <div className={cx('files')}>
                        <AddFiles
                            files={files}
                            onUpload={handleUploadFile}
                            onDeleteFile={handleDeleteFile}
                            canUpload={isCanUpload}
                            canEdit={isMethodist && isCourseGroup}
                        />
                    </div>
                    {isCourseGroup && !!files.length && !isOvered && (
                        <div className={cx('filesUseOnConference')}>
                            <div className={cx('filesUseOnConferenceTitle')}>Использовать во время занятия</div>
                            {files?.map((file) => (
                                <div key={file.id} className={cx('filesUseOnConferenceCheckbox')}>
                                    <Checkbox
                                        id={String(file.id)}
                                        checked={file.useOnMeeting}
                                        disabled={getMeetingFilesStatus === FetchStatus.FETCHING}
                                        handleCheckboxChange={handleFilesCheckboxChange}
                                        name="useOnMeeting"
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    {isCourseGroup && !!files.length && (
                        <div className={cx('filesAvailability')}>
                            <div className={cx('filesAvailabilityTitle')}>Доступно ученикам</div>
                            {files?.map((file) => (
                                <div key={file.id} className={cx('filesAvailabilityCheckbox')}>
                                    <Checkbox
                                        id={String(file.id)}
                                        checked={file.isAvailableToStudents}
                                        disabled={getMeetingFilesStatus === FetchStatus.FETCHING}
                                        handleCheckboxChange={handleFilesCheckboxChange}
                                        name="isAvailableToStudents"
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                    {isMethodist && !!files.length && (
                        <div className={cx('filesIsTemplate')}>
                            <div className={cx('filesIsTemplateTitle')}>Использовать как шаблон</div>
                            {files?.map((file) => {
                                return (
                                    <div key={file.id} className={cx('filesIsTemplateCheckbox')}>
                                        <Checkbox
                                            id={String(file.id)}
                                            checked={file.isTemplate}
                                            handleCheckboxChange={handleFilesCheckboxChange}
                                            name="isTemplate"
                                            disabled={
                                                (file.isTemplate && !isCourseGroup) ||
                                                getMeetingFilesStatus === FetchStatus.FETCHING
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    {isMethodist && !!files.length && !isCourseGroup && (
                        <div className={cx('filesAuthor')}>
                            <div className={cx('filesIsTemplateTitle')}>Автор файла</div>
                            {files?.map((file) => {
                                return (
                                    <div key={file.id} className={cx('filesIsTemplateCheckbox')}>
                                        <AppText
                                            text={`${file?.uploadedBy?.lastName} ${file?.uploadedBy?.firstName} ${file?.uploadedBy?.patronymic}`}
                                            textStyle={'DesktopFootNotes'}
                                            color={'#919399'}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </>
            ) : (
                <></>
            )}
        </div>
    );
};

import React from 'react';
import { Chart, GoogleChartWrapper, ReactGoogleChartProps } from 'react-google-charts';
import { GoogleChartWrapperChartType } from 'react-google-charts/dist/types';

type TAppAreaChart = Omit<ReactGoogleChartProps, 'chartType'> & {
    type?: GoogleChartWrapperChartType;
    onChartSelect?: (chartWrapper: GoogleChartWrapper) => void;
    onChartReady?: () => void;
};

export const AppAreaChart = (props: TAppAreaChart): JSX.Element => {
    const { data, options, type = 'AreaChart', width = '100%', height = '100%', onChartSelect, onChartReady } = props;
    const chartEvents = [];

    if (onChartSelect) {
        chartEvents.push({
            eventName: 'ready',
            //@ts-ignore
            callback: ({ chartWrapper, google }) => {
                const chart = chartWrapper.getChart();
                onChartReady?.();

                google.visualization.events.addListener(chart, 'select', () => onChartSelect(chartWrapper));
            },
        });
    }
    return (
        <Chart
            chartType={type}
            width={width}
            height={height}
            data={data}
            options={options}
            //@ts-ignore
            chartEvents={chartEvents}
        />
    );
};

import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { FileTypes, IUploadedFile } from 'types/file';

export interface ICreateFileParams {
    type?: FileTypes;
    lessonId?: number;
    materialId?: number;
    file: File;
    onUploadProgress?: (percent: number) => void;
}

export const createFile = (params: ICreateFileParams): Promise<IUploadedFile> => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
            Accept: 'application/json',
        },
        onUploadProgress: function (progressEvent: { loaded: number; total: number }) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            params.onUploadProgress?.(percentCompleted >= 100 ? 99 : percentCompleted);
        },
    };

    const { type = FileTypes.COMMON, lessonId, materialId, file } = params;
    const data = new FormData();

    data.append('file', file);
    data.append('type', type);

    switch (true) {
        case lessonId !== undefined:
            data.append('lesson_id', String(lessonId));
            break;
        case type === FileTypes.COMMON && materialId !== undefined:
            data.append('material_id', String(materialId));
            break;
        default:
            break;
    }

    return api
        .post<Promise<IUploadedFile>, AxiosResponse<IUploadedFile>>(`/file/create/`, data, config)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const fetchDeleteFiles = (idFile: number): Promise<{ id: number } | undefined> => {
    return api
        .delete<Promise<{ id: number }>, AxiosResponse<{ id: number }>>(`/file/delete/${idFile}/`)
        .then((res) => res?.data ?? { id: idFile })
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export interface PatchFileParams {
    id: number;
    type?: FileTypes;
    isAvailableToStudents?: boolean;
    shouldUseOnConference?: boolean;
    materialId?: number | null;
    lessonId?: number | null;
}

export interface PatchFileResp {
    id: number;
    type: FileTypes;
    isAvailableToStudents: boolean;
    shouldUseOnConference: boolean;
    materialId: number | null;
    lessonId: number | null;
}

/**
 * Patch file
 * @param params id: number,
 * isAvailableStudents: boolean,
 * shouldUseOnConference: boolean
 * @returns
 */

export const patchFile = async (params: PatchFileParams): Promise<PatchFileResp> => {
    const { id, type = FileTypes.COMMON, isAvailableToStudents, shouldUseOnConference, materialId, lessonId } = params;

    const formData = new FormData();
    formData.append('type', type);
    if (isAvailableToStudents !== undefined) formData.append('isAvailableToStudents', String(isAvailableToStudents));
    if (shouldUseOnConference !== undefined) formData.append('shouldUseOnConference', String(shouldUseOnConference));

    switch (true) {
        case lessonId !== undefined:
            formData.append('lesson', String(lessonId));
            break;
        case type === FileTypes.COMMON && materialId !== undefined:
            formData.append('material', String(materialId));
            break;
        default:
            break;
    }

    return await api
        .patch<never, AxiosResponse<PatchFileResp>>(`/file/partial-update/${id}/`, formData)
        .then((res) => ({
            ...res?.data,
            id: params.id,
        }))
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

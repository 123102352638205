import React, { FC, useMemo } from 'react';
import { useQueryPopupsParams } from 'src-new/hooks';
import { AppDrawer } from 'src-new/ui';

import { IHardModalProps } from './HardModal.types';

/**
 * @description - Компонент рендера "Сложной модалки"
 * @constructor
 */
const HardModal: FC<IHardModalProps> = (props) => {
    const { children, isOpen, layer, urlProps, customWrapperStyle, handleCloseModal, ...rest } = props;

    const Popup = useQueryPopupsParams();

    // @ts-ignore
    const layerRightAbsolute = useMemo(() => `${layer === 1 ? 0 : layer * 50}px`, [layer]);

    const childrenWithProps = React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
            return React.cloneElement(child, { urlProps });
        }
        return child;
    });

    const handleCloseModalLocal = () => {
        handleCloseModal?.();
        Popup.closeLastPopup();
    };
    return (
        <AppDrawer
            {...rest}
            open={isOpen}
            onClose={handleCloseModalLocal}
            contentWrapperStyle={{ right: layerRightAbsolute, ...customWrapperStyle }}
        >
            {childrenWithProps}
        </AppDrawer>
    );
};

export default HardModal;

import {
    ActionBlockTypes,
    IBlockRendererObject,
} from 'src-new/components/lms-elements/CustomEditor/types/shared.types';

import { ActionBlockComponentBlockProps } from './ActionBlockComponent/ActionBlockComponent.types';
import { ActionBlockComponent } from './ActionBlockComponent';

export const getActionBlockRenderer = (
    type: ActionBlockTypes,
): IBlockRendererObject<ActionBlockComponentBlockProps> => ({
    component: ActionBlockComponent,
    props: {
        type,
    },
});

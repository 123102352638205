import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCourses } from 'api/services/course';
import { getCourseGroups } from 'api/services/courseGroups';
import { getStaffProfileById } from 'api/services/staffProfile/staffProfile';
import { getSubjectDetails, getSubjects } from 'api/services/subject';

export const getTeacherSubjects = createAsyncThunk('subject/getTeacherSubjects', () => {
    return getSubjects();
});

export const getMethodistSubjects = createAsyncThunk('subject/getMethodistSubjects', (methodistId: number) => {
    return getStaffProfileById(methodistId).then(({ subjects }) => subjects);
});

type CourseBySubjectParams = {
    subjectId: number;
    isTeacher?: boolean;
};

export const getCoursesBySubject = createAsyncThunk(
    'subject/getCoursesBySubject',
    ({ subjectId, isTeacher }: CourseBySubjectParams) => {
        return Promise.all([getCourses({ subject_id: subjectId }), getCourseGroups({ subject: subjectId })]).then(
            ([courses, courseGroups]) => ({
                subjectId,
                courses,
                courseGroups: courseGroups,
                isTeacher,
            }),
        );
    },
);

export const getSubjectDetailsAction = createAsyncThunk('subject/getSubjectDetails', async (subjectId: number) => {
    return await getSubjectDetails(subjectId);
});

export const getPrincipalSubjects = createAsyncThunk('subject/getPrincipalSubjects', () => {
    return getSubjects();
});

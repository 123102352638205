import { isListExist } from '@frontend-modules/frontend-utils';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getLessonDetails } from 'api/services/lesson';
import { handleLessonSchedule } from 'api/services/lessonSchedule';
import * as NewSubjectService from 'api/services/newSubjects';
import { TARIFF_CURIOUS_TITLE } from 'App/constants';
import { TTeacherProfile } from 'src-new/components/new-subjects';
import { THandleLessonSchedules } from 'types/lessonSchedules';
import {
    TCourseGroup,
    TGetCourseGroupsLessonsProps,
    TGetCourseTreeParams,
    TGetCourseTreePayload,
    TGetLessonsTreeParams,
    TGetStudentsListParams,
    TGetStudentsProgressListProps,
    THandleLessonScheduleActionProps,
    TLocalStorageItem,
    TSelectedCourse,
} from 'types/newSubjectsTypes';

export const resetTeacherInfoAction = createAction('newSubjects/resetTeacherInfo', () => {
    return { payload: {} as TTeacherProfile };
});
export const setSelectedCourseAction = createAction('newSubjects/setSelectedCourseId', (course: TSelectedCourse) => {
    return { payload: { ...course } };
});
export const setSelectedTopicIdAction = createAction('newSubjects/setSelectedTopicId', (id: number) => {
    return {
        payload: id,
    };
});
export const setSelectedLessonIdAction = createAction('newSubjects/setSelectedLessonId', (id: number) => {
    return {
        payload: id,
    };
});
// Статистика уроков
export const getLessonsStatisticAction = createAsyncThunk(
    'newSubjects/getLessonsStatistic',
    async (courseId: number) => {
        return await NewSubjectService.getLessonsStatistic(courseId);
    },
);
// Получение иерархии уроков
/**
 * @description getStudentsTreeByURLAction - получаем все дерево по значениям параметров в юрле
 * Так же устанавливает в сторе выбранные параметры курса, и темы
 */
export const getStudentsTreeByURLAction = createAsyncThunk(
    'newSubjects/getStudentsTreeByURL',
    async (params: TLocalStorageItem) => {
        const { course, topic, lesson } = params;
        let selectedCourse: TSelectedCourse = {} as TSelectedCourse;

        const courseTree = (await NewSubjectService.getCourseTree()) as TGetCourseTreePayload;

        // Если из информации есть только id урока, значит ссылка на урок КУРСА
        // В этом случае получаем id курса по уроку и пытаемся получить дерево по нему
        const hasOnlyLessonId = !!lesson && !course.id && !topic;
        const lessonData = hasOnlyLessonId ? await getLessonDetails(lesson) : undefined;
        const courseIdByLessonData = lessonData?.course.id;

        const commonCoursesList = courseTree.common?.map((item) => item.studentGroup.courseGroups).flat(1);
        const commonCourse = commonCoursesList.find((item) =>
            courseIdByLessonData ? item.course.id === courseIdByLessonData : item.id === course.id,
        );
        const choiceCourse = courseTree?.choice.find((item) =>
            courseIdByLessonData ? item.course.id === courseIdByLessonData : item.id === course.id,
        );
        const additionalCourse = courseTree?.additional.find((item) =>
            courseIdByLessonData ? item.course.id === courseIdByLessonData : item.id === course.id,
        );

        if (commonCourse) {
            selectedCourse = {
                id: commonCourse.id,
                courseId: commonCourse.course.id,
                title: commonCourse.course.title,
                type: 'common',
                isCurious: commonCourse.tariff === TARIFF_CURIOUS_TITLE,
                courseGroupTitle: commonCourse?.title,
                recalculation: commonCourse.recalculation,
            };
        } else if (choiceCourse) {
            selectedCourse = {
                id: choiceCourse.id,
                courseId: choiceCourse.course.id,
                title: choiceCourse.course.title,
                type: 'choice',
                isCurious: choiceCourse.tariff === TARIFF_CURIOUS_TITLE,
                courseGroupTitle: choiceCourse?.title,
                recalculation: choiceCourse.recalculation,
            };
        } else if (additionalCourse) {
            selectedCourse = {
                id: additionalCourse.id,
                courseId: additionalCourse.course.id,
                title: additionalCourse.course.title,
                type: 'additional',
                isCurious: additionalCourse.tariff === TARIFF_CURIOUS_TITLE,
                courseGroupTitle: additionalCourse?.title,
                recalculation: additionalCourse.recalculation,
            };
        } else {
            const courseByCourseId =
                commonCoursesList.find((item) => item.course.id === course.courseId) ??
                courseTree?.choice.find((item) => item.course.id === course.courseId) ??
                courseTree?.additional.find((item) => item.course.id === course.courseId);

            selectedCourse = courseByCourseId
                ? {
                      id: courseByCourseId.id,
                      courseId: courseByCourseId.course.id,
                      title: courseByCourseId.course.title,
                      isCurious: courseByCourseId.tariff === TARIFF_CURIOUS_TITLE,
                      courseGroupTitle: courseByCourseId?.title,
                      recalculation: courseByCourseId?.recalculation,
                  }
                : {
                      id: course.id,
                  };
        }

        const topicTree = selectedCourse.id
            ? await NewSubjectService.getTopicsBySelectedCourse({ courseGroup: selectedCourse.id })
            : [];

        const topicId = topic || topicTree.find((item) => item?.lessons?.includes(lesson))?.id || 0;
        const lessonTree = topicId
            ? await NewSubjectService.getLessonsBySelectedTopic({
                  courseGroup: selectedCourse.id,
                  topic: topicId,
              })
            : [];

        return {
            courseTree,
            topicTree,
            lessonTree,
            selectedCourse: selectedCourse,
            selectedTopicId: topicId,
        };
    },
);
/**
 * @description getTeachersTreeByURLAction - получаем все дерево по значениям параметров в юрле
 * Так же устанавливает в сторе выбранные параметры курса, и темы
 */
export const getTeachersTreeByURLAction = createAsyncThunk(
    'newSubjects/getTeachersTreeByURL',
    async (params: TLocalStorageItem) => {
        const { course, topic, lesson } = params;
        const courseTree = (await NewSubjectService.getCourseTree({ role: 'teacher' })) as TCourseGroup[];

        const courseData = courseTree
            .map((item) => item.courseGroups)
            .flat(1)
            .find((item) => item.id === course.id);
        const subjectId = courseTree.find(({ id, courseGroups }) =>
            courseGroups.some(({ id }) => courseData?.id === id),
        )?.id;
        const selectedCourse: TSelectedCourse = {
            id: course.id,
            subjectId: subjectId ?? 0,
            courseId: courseData?.course.id,
            title: courseData?.course.title,
            courseGroupTitle: courseData?.title,
            recalculation: courseData?.recalculation ?? null,
        };

        const topicTree = selectedCourse.id
            ? await NewSubjectService.getTopicsBySelectedCourse({
                  courseGroup: course.id,
                  role: 'teacher',
              })
            : [];

        const topicId = topic || topicTree.find((item) => item?.lessons?.includes(lesson))?.id || 0;

        const lessonTree = await NewSubjectService.getLessonsBySelectedTopic({
            courseGroup: course.id,
            topic: topicId,
            role: 'teacher',
        });

        return {
            courseTree,
            topicTree,
            lessonTree,
            selectedCourse: selectedCourse,
            selectedTopicId: topicId,
        };
    },
);
/**
 * @description getStudentsCourseTreeAction - получает список курсов для учеников
 * Так же устанавливает в сторе первый курс как выбранный
 */
export const getStudentsCourseTreeAction = createAsyncThunk(
    'newSubjects/getStudentsCourseTree',
    async (params?: TGetCourseTreeParams) => {
        let firstCourse: TSelectedCourse = {} as TSelectedCourse;

        const courseTree = (await NewSubjectService.getCourseTree(params)) as TGetCourseTreePayload;

        if (courseTree?.common?.length) {
            firstCourse = {
                id: courseTree.common[0].studentGroup.courseGroups[0].id,
                courseId: courseTree.common[0].studentGroup.courseGroups[0].course.id,
                title: courseTree.common[0].studentGroup.courseGroups[0].course.title,
                type: 'common',
                isCurious: courseTree.common[0].studentGroup.courseGroups[0].tariff === TARIFF_CURIOUS_TITLE,
                courseGroupTitle: courseTree.common[0]?.studentGroup.courseGroups[0].title,
                recalculation: courseTree.common[0].studentGroup.courseGroups[0].recalculation,
            };
        } else if (courseTree?.choice?.length) {
            firstCourse = {
                id: courseTree.choice[0].id,
                courseId: courseTree.choice[0].course.id,
                title: courseTree.choice[0].course.title,
                type: 'choice',
                isCurious: courseTree.choice[0].tariff === TARIFF_CURIOUS_TITLE,
                courseGroupTitle: courseTree.choice[0].title,
                recalculation: courseTree.choice[0].recalculation,
            };
        } else if (courseTree?.additional?.length) {
            firstCourse = {
                id: courseTree.additional[0].id,
                courseId: courseTree.additional[0].course.id,
                title: courseTree.additional[0].course.title,
                type: 'additional',
                isCurious: courseTree.additional[0].tariff === TARIFF_CURIOUS_TITLE,
                courseGroupTitle: courseTree.additional[0].title,
                recalculation: courseTree.additional[0].recalculation,
            };
        }

        return {
            courseTree,
            selectedCourse: firstCourse,
        };
    },
);
/**
 * @description getTeachersCourseTreeAction - получает список курсов для учителей
 * Так же устанавливает в сторе первый курс как выбранный
 */
export const getTeachersCourseTreeAction = createAsyncThunk(
    'newSubjects/getTeachersCourseTree',
    async (params?: TGetCourseTreeParams) => {
        const courseTree = (await NewSubjectService.getCourseTree(params)) as TCourseGroup[];
        const firstCourse: TSelectedCourse = {
            id: courseTree?.[0].courseGroups[0].id,
            subjectId: courseTree?.[0].id,
            courseId: courseTree?.[0].courseGroups[0].course.id,
            title: courseTree?.[0].courseGroups[0].course.title,
            courseGroupTitle: courseTree?.[0].courseGroups[0].title,
            recalculation: courseTree?.[0].courseGroups[0].recalculation,
        };

        return {
            courseTree,
            selectedCourse: firstCourse,
        };
    },
);
/**
 * @description getTreeByFirstItemsAction - по цепочке получает список тем и уроков по первым значениям
 * Так же устанавливает в сторе первую тему из списка как выбранную
 */
export const getTreeByFirstItemsAction = createAsyncThunk(
    'newSubjects/getTreeByFirstItems',
    async (course: TSelectedCourse) => {
        const topicTree = course.id
            ? await NewSubjectService.getTopicsBySelectedCourse({ courseGroup: course.id })
            : [];
        const selectedTopic = topicTree?.[0].id;
        const lessonTree = await NewSubjectService.getLessonsBySelectedTopic({
            courseGroup: course.id,
            topic: selectedTopic,
        });

        return {
            topicTree,
            lessonTree,
            selectedTopicId: selectedTopic,
        };
    },
);
/**
 * @description getTreeByKnownItemsAction - по цепочке получает список тем и список уроков по заданным значениям
 * Так же устанавливает в сторе первую тему из списка как выбранную
 */
export const getTreeByKnownItemsAction = createAsyncThunk(
    'newSubjects/getTreeByKnownItems',
    async (params: TLocalStorageItem) => {
        const { course, topic } = params;
        const topicTree = course.id
            ? await NewSubjectService.getTopicsBySelectedCourse({ courseGroup: course.id })
            : [];

        const lessonTree = await NewSubjectService.getLessonsBySelectedTopic({
            courseGroup: course.id,
            topic: topic ?? 0,
        });

        return {
            topicTree,
            lessonTree,
            selectedTopicId: topic,
        };
    },
);
/**
 * @description getStudentsTreeByTimeItemsAction - по цепочке получает список тем и уроков ученика по вычисленным значениям
 * (по ближайшему уроку к текущему времени)
 * Так же устанавливает в сторе полученную тему как выбранную
 */
export const getStudentsTreeByTimeItemsAction = createAsyncThunk(
    'newSubjects/getStudentsTreeByTimeItems',
    async (selectedCourse: TSelectedCourse) => {
        const { currentTopic } = await NewSubjectService.getLessonsStatistic(selectedCourse.id);

        const topicTree = selectedCourse.id
            ? await NewSubjectService.getTopicsBySelectedCourse({ courseGroup: selectedCourse.id })
            : [];

        const lessonTree = await NewSubjectService.getLessonsBySelectedTopic({
            courseGroup: selectedCourse.id,
            topic: currentTopic,
        });

        return {
            topicTree,
            lessonTree,
            selectedTopicId: currentTopic,
        };
    },
);
/**
 * @description getTeachersTreeByTimeItemsAction - по цепочке получает список тем и уроков учителя по вычисленным значениям
 * (по ближайшему уроку к текущему времени)
 * Так же устанавливает в сторе полученную тему как выбранную
 */
export const getTeachersTreeByTimeItemsAction = createAsyncThunk(
    'newSubjects/getTeachersTreeByTimeItems',
    async (selectedCourse: TSelectedCourse) => {
        const { currentTopic } = await NewSubjectService.getLessonsStatistic(selectedCourse.id);

        const topicTree = selectedCourse.id
            ? await NewSubjectService.getTopicsBySelectedCourse({
                  courseGroup: selectedCourse.id,
                  role: 'teacher',
              })
            : [];

        const lessonTree = await NewSubjectService.getLessonsBySelectedTopic({
            courseGroup: selectedCourse.id,
            topic: currentTopic,
            role: 'teacher',
        });

        return {
            topicTree,
            lessonTree,
            selectedTopicId: currentTopic,
        };
    },
);
/**
 * @description getLessonsBySelectedCourseAndTopicTreeAction - получает список уроков по выбранным значениям курса и темы
 * Так же устанавливает в сторе полученную тему как выбранную
 */
export const getLessonsBySelectedCourseAndTopicTreeAction = createAsyncThunk(
    'newSubjects/getLessonsBySelectedCourseAndTopicTree',
    async (params: TGetLessonsTreeParams) => {
        const lessonsTree = await NewSubjectService.getLessonsBySelectedTopic(params);
        return {
            lessonsTree,
            ...params,
        };
    },
);

export const getTeacherProfileAction = createAsyncThunk('newSubjects/getTeacherProfile', async (id: number) => {
    return await NewSubjectService.getTeacherProfile(id);
});

export const setLearningMaterialViewedAction = createAsyncThunk(
    'newSubjects/setLearningMaterialViewed',
    async (params: { exercises: number[] }) => {
        return await NewSubjectService.setLearningMaterialViewed(params);
    },
);
export const getStudentsListAction = createAsyncThunk(
    'newSubjects/getStudentsList',
    async (params: TGetStudentsListParams) => {
        return await NewSubjectService.getStudentsList(params);
    },
);
export const getStudentsProgressListAction = createAsyncThunk(
    'newSubjects/getStudentsProgressList',
    async (params: TGetStudentsProgressListProps) => {
        return await NewSubjectService.getStudentsProgressList(params);
    },
);
export const getCourseGroupsLessonsAction = createAsyncThunk(
    'newSubjects/getCourseGroupsLessons',
    async (params: TGetCourseGroupsLessonsProps) => {
        return await NewSubjectService.getCourseGroupsLessons(params);
    },
);
export const handleLessonScheduleAction = createAsyncThunk(
    'newSubjects/handleLessonSchedule',
    async (params: THandleLessonScheduleActionProps) => {
        //если редактируется урок курс-группы, то формируем lesson-schedule для запроса на обновление
        if (isListExist(params.lessonSchedules)) {
            const lessonSchedules: THandleLessonSchedules = {};
            params?.lessonSchedules?.forEach((lesson) => {
                // id = 0 - добавился новый lessonSchedule
                // id < 0 - lessonSchedule удален
                // id > 0 - lessonSchedule отредактирован
                lesson.id > 0
                    ? (lessonSchedules.update = {
                          ...lesson,
                          datetimeStart: lesson.datetimeStart ? lesson.datetimeStart : undefined,
                          datetimeEnd:
                              lesson.datetimeEnd && lesson.type !== 'online_lesson' ? lesson.datetimeEnd : undefined,
                      })
                    : lesson.id < 0
                    ? (lessonSchedules.delete = Math.abs(lesson.id))
                    : (lessonSchedules.create = {
                          type: lesson.type,
                          datetimeStart: lesson.datetimeStart ? lesson.datetimeStart : undefined,
                          datetimeEnd:
                              lesson.datetimeEnd && lesson.type !== 'online_lesson' ? lesson.datetimeEnd : undefined,
                      });
            });
            const isNeedUseUpdateMethod = lessonSchedules.create && lessonSchedules.delete && !lessonSchedules.update;
            //если один LessonSchedule удален, а другой создан, то выполняем запрос на обновление, а не удаление и создание
            if (isNeedUseUpdateMethod && lessonSchedules.delete && lessonSchedules.create) {
                lessonSchedules.update = {
                    id: lessonSchedules.delete,
                    type: lessonSchedules.create.type,
                    datetimeStart: lessonSchedules.create.datetimeStart ?? undefined,
                    datetimeEnd: lessonSchedules.create.datetimeEnd ?? undefined,
                };
                lessonSchedules.delete = undefined;
                lessonSchedules.create = undefined;
            }
            return handleLessonSchedule({
                ...params,
                lessonSchedules: lessonSchedules,
            });
        } else {
            return await handleLessonSchedule({
                ...params,
                lessonSchedules: undefined,
            });
        }
    },
);

import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';

export const deleteStudentTasks = async (tasks: { id: number }[]): Promise<{ id: number }[]> => {
    return api
        .delete<Promise<{ id: number }[]>, AxiosResponse<{ id: number }[]>>(`/assignment-progress/bulk-delete/`, {
            data: tasks,
        })
        .then((res) => res?.data || tasks)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

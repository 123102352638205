import React, { useCallback, useMemo } from 'react';
import { Field, FieldRenderProps, useFormState } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { cn } from '@bem-react/classname';
import { RadioField, Toggle } from '@lms-elements/atomic';
import { Basket } from '@lms-elements/icons';
import { getObjectPropertyByString } from '@lms-elements/utils';

import { DetailedAnswerFields } from './DetailedAnswerFields';
import { EditorField } from './EditorField';
import { binaryAnswer, IAnswerOption, IExpandedQuestionProps } from './ExpandedQuestion.types';
import { AddOptionButton, IMultipleAnswerOption, MultipleAnswerFields } from './MultipleAnswerFields';
import { IOpenAnswerOption, OpenAnswerFields } from './OpenAnswerFields';
import { TextareaFieldWrapper } from './TextareaFieldWrapper';
import { chooseAddOptionButtonText, createNewAnswerOption } from './utils';

import './ExpandedQuestion.scss';

const QuestionCn = cn('expanded-question');

const ARRAY_ELEM_CALL_REGEXP = /(\[\d\]){1}$/g;

const SQUARE_BRACKETS_REGEXP = /\[|\]/g;

const Question: React.FC<IExpandedQuestionProps> = ({
    name,
    type,
    onDelete,
    detailedCheckboxesValidators = [],
    multipleCheckboxesValidators = [],
    isBlocked = false,
    disableActionBlocks = false,
    needHideSmartBoardToggle,
    savePastedStyles,
}) => {
    const { values } = useFormState();

    const isEditor = useMemo(() => typeof getObjectPropertyByString(values, `${name}.question`) === 'object', [
        name,
        values,
    ]);

    const QuestionField = useMemo(
        () =>
            isEditor ? (
                <div className={QuestionCn('editor-wrapper', { type })}>
                    <EditorField
                        isBlocked={isBlocked}
                        name={`${name}.question`}
                        placeholder="Введите текст вопроса"
                        insertWords={type === 'insert-words'}
                        disableActionBlocks={disableActionBlocks}
                        savePastedStyles={savePastedStyles}
                    />
                </div>
            ) : (
                <TextareaFieldWrapper name={`${name}.question`} placeholder="Введите текст вопроса" />
            ),
        [disableActionBlocks, isBlocked, isEditor, name, savePastedStyles, type],
    );

    const { fields: questionsFields } = useFieldArray(name.replace(ARRAY_ELEM_CALL_REGEXP, ''));

    const { fields: answerOptionsFields } = useFieldArray<IAnswerOption, HTMLInputElement>(`${name}.answerOptions`);

    const moveMultipleFieldsHandler = useCallback(
        (dragIndex: number, hoverIndex: number) => answerOptionsFields.swap(dragIndex, hoverIndex),
        [answerOptionsFields],
    );

    const index = useMemo(
        () => Number(name.match(ARRAY_ELEM_CALL_REGEXP)?.pop()?.replace(SQUARE_BRACKETS_REGEXP, '')),
        [name],
    );

    const handleDeleteCard = useCallback(() => {
        void questionsFields.remove(index);
        if (onDelete) {
            onDelete();
        }
    }, [index, onDelete, questionsFields]);

    return (
        <div className={QuestionCn({ type })} data-testid="expanded-question">
            <div className={QuestionCn('top-row')}>
                {type === 'multiple' && (
                    <div className={QuestionCn('toggle')}>
                        <Field name={`${name}.randomOrder`} subscription={{ value: true }} type="checkbox">
                            {({ input }: FieldRenderProps<boolean>) => (
                                <div className={QuestionCn('toggle-wraper')}>
                                    <Toggle
                                        checked={input.checked}
                                        disabled={false}
                                        handleToggleChange={(e) => input.onChange(e)}
                                    />
                                    <span className="randorm-label">Случайный порядок</span>
                                </div>
                            )}
                        </Field>
                    </div>
                )}
                {QuestionField}
            </div>

            <div>
                {answerOptionsFields.map((fieldName, index) => {
                    switch (type) {
                        case 'multiple':
                            return (
                                <MultipleAnswerFields
                                    name={fieldName}
                                    key={fieldName}
                                    option={answerOptionsFields.value[index] as IMultipleAnswerOption}
                                    onOptionCopy={() => answerOptionsFields.push(answerOptionsFields.value[index])}
                                    onOptionDelete={() => answerOptionsFields.remove(index)}
                                    moveFieldsHandler={moveMultipleFieldsHandler}
                                    index={index}
                                    checkboxesValidators={multipleCheckboxesValidators}
                                    isBlocked={isBlocked}
                                    disableActionBlocks={disableActionBlocks}
                                    savePastedStyles={savePastedStyles}
                                />
                            );
                        case 'open':
                            return (
                                <OpenAnswerFields
                                    name={fieldName}
                                    key={fieldName}
                                    option={answerOptionsFields.value[index] as IOpenAnswerOption}
                                    onOptionDelete={() => answerOptionsFields.remove(index)}
                                    isBlocked={isBlocked}
                                    disableActionBlocks={disableActionBlocks}
                                    savePastedStyles={savePastedStyles}
                                />
                            );
                        case 'detailed':
                            return (
                                <DetailedAnswerFields
                                    name={fieldName}
                                    key={fieldName}
                                    checkboxesValidators={detailedCheckboxesValidators}
                                    needHideSmartBoardToggle={needHideSmartBoardToggle}
                                />
                            );
                        default:
                            return <></>;
                    }
                })}
                {(() => {
                    switch (type) {
                        case 'binary':
                            return (
                                <div className={QuestionCn('binary')}>
                                    <RadioField
                                        customClassNames={[QuestionCn('binary-radio')]}
                                        name={`${name}.rightAnswer`}
                                        value={'Верно' as binaryAnswer}
                                    />
                                    <RadioField
                                        customClassNames={[QuestionCn('binary-radio')]}
                                        name={`${name}.rightAnswer`}
                                        value={'Неверно' as binaryAnswer}
                                    />
                                </div>
                            );
                        case 'multiple':
                        case 'open':
                            return (
                                <AddOptionButton
                                    text={chooseAddOptionButtonText(type)}
                                    onClick={() => answerOptionsFields.push(createNewAnswerOption(type, isEditor))}
                                />
                            );
                        default:
                            return <></>;
                    }
                })()}
            </div>
            <button onClick={handleDeleteCard} className={QuestionCn('delete-card')} type="button">
                <Basket />
            </button>
        </div>
    );
};

export default Question;

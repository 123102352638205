import { checkIsKinescopeUrlValid } from '@fsd-features/AddKinescopeVideo';
import { Header } from '@lms-elements/materials-header';
import { ImageData } from '@lms-elements/materials-image';
import { IUploadAudioProps } from '@lms-elements/materials-media/build/@types/packages/MaterialsMedia/src/Audio/Expanded/ExpandedAudioMaterial.types';
import { IMaterialFile, LearningMaterialsContent } from 'api/services/materials';
import { ResponseQuestion } from 'api/services/questionBank';
import { ContentState, convertFromRaw, EditorState, RawDraftContentState } from 'draft-js';
import {
    ContentMaterialTypeEnum,
    ExerciseData,
    IMaterial,
    IMaterialDocument,
    IMaterialImage,
    IMaterialTable,
} from 'types/materials';

import { getFileTitle } from 'utils/file';
import { getStudentQuestionsData, normalizeQuestionData, StudentAnswerData } from 'utils/questions';

interface NormalizeMaterialsDataParams {
    materials: (LearningMaterialsContent & { isDeleted?: boolean })[];
    files: IMaterialFile[];
    questions?: ResponseQuestion[];
    answers?: {
        [exerciseId: number]: StudentAnswerData;
    };
}

export const normalizeMaterialsData = (params: NormalizeMaterialsDataParams): IMaterial[] => {
    const { materials, files, questions, answers } = params;

    return [...materials]
        .sort((a, b) => a.order - b.order)
        .map((value) => {
            let textData: string | EditorState = '';
            let documentData: (File | string | IMaterialDocument)[] = [];
            let headerData: Header = {} as Header;
            let imageData: IMaterialImage = {} as IMaterialImage;
            let audioData = { file: {} as IUploadAudioProps, songName: '' };
            let videoData = { url: '' };
            let url = undefined;
            let tableData = {
                table: {
                    table: [[EditorState.createEmpty()]],
                    editorBuffer: EditorState.createEmpty(),
                },
                title: '',
            } as IMaterialTable;
            let exerciseData = {} as ExerciseData;

            if (value.typeOfContent === ContentMaterialTypeEnum.audio) {
                const fileUrl = files?.find((file) => file.material === value.id)?.files[0]?.fileUrl || '';
                const content = JSON.parse(value.content) as { url: string; songName: string };
                url = fileUrl || content.url;
                audioData = {
                    file: {} as IUploadAudioProps,
                    songName: content.songName,
                };
            }

            if (value.typeOfContent === ContentMaterialTypeEnum.video) {
                const fileUrl = files?.find((file) => file.material === value.id)?.files[0]?.fileUrl || '';
                const content = JSON.parse(value.content) as { url: string };
                let correctEmbedUrl = '';
                if (correctEmbedUrl.includes('youtube')) {
                    const embedVideo = content.url.replace('watch?v=', 'embed/');
                    correctEmbedUrl = embedVideo;
                } else if (correctEmbedUrl.includes('vimeo')) {
                    const embedVideo = content.url.replace('vimeo.com', 'player.vimeo.com/video');
                    correctEmbedUrl = embedVideo;
                } else if (correctEmbedUrl.includes('facebook')) {
                    const startUrl = 'https://www.facebook.com/plugins/video.php?href=';
                    const embedVideo = correctEmbedUrl.replace('/', '%2F').replace(':', '%3A');
                    correctEmbedUrl = startUrl + embedVideo;
                }

                videoData = { url: correctEmbedUrl || fileUrl || content.url };
            }

            if (value.typeOfContent === ContentMaterialTypeEnum.text) {
                const isTextarea = JSON.parse(value.content) === value.content ? true : false;
                if (isTextarea) {
                    textData = value.content;
                } else {
                    try {
                        const content = convertFromRaw(JSON.parse(value.content) as RawDraftContentState);
                        textData = EditorState.createWithContent(content);
                    } catch (error) {
                        textData = EditorState.createWithContent(ContentState.createFromText(value.content));
                    }
                }
            }

            if (value.typeOfContent === ContentMaterialTypeEnum.photo) {
                const imageFiles = files.find((file) => file.material === value.id)?.files || [];

                const content = JSON.parse(value.content) as {
                    data: ImageData[];
                    description: string;
                };

                /**
                 * При копировании курса изменяются id файлов в новосозданном курсе, поэтому id'шники, которые хранятся в content'е становятся неактуальными
                 * Для того, чтобы сохранить выбранную последовательность картинок, нужно собрать все старые id (contentIds), и определить исходную последовательность создания файлов (initialIds)
                 * На основании этих двух последовательностей можно определить в какой последовательности нужно отрисовывать файлы с новыми id
                 *  */
                if (content.data) {
                    const contentIds = content?.data?.map((imageData) => imageData.id)?.filter(Boolean) as number[];
                    const initialIds = [...contentIds]?.sort((a, b) => b - a);

                    imageData = {
                        ...content,
                        data:
                            content?.data?.map((imageFile) => {
                                if (!imageFile.id) {
                                    return imageFile;
                                }

                                const fileIndex = initialIds.findIndex((id) => id === imageFile.id);
                                const currentFile = imageFiles[fileIndex];

                                if (currentFile) {
                                    return {
                                        ...imageFile,
                                        data: currentFile.fileUrl,
                                    };
                                } else {
                                    return imageFile;
                                }
                            }) ?? [],
                    };
                } else {
                    imageData = {
                        data: [
                            {
                                data: '',
                                width: 0,
                            },
                        ],
                        description: '',
                    };
                }
            }

            if (value.typeOfContent === ContentMaterialTypeEnum.document) {
                const contentData = (JSON.parse(value.content) as { data: IMaterialDocument[] }) || { data: [] };
                const filesData =
                    files
                        .find((file) => file.material === value.id)
                        ?.files?.map((file) => ({
                            id: file.id,
                            name: getFileTitle(file.title, file.fileExtension),
                            url: file.fileUrl,
                        })) || [];
                filesData?.forEach((file) => {
                    const contentDataIndex = contentData?.data?.findIndex((document) => document.id === file.id);
                    if (contentDataIndex !== -1 && contentData?.data) {
                        contentData.data[contentDataIndex] = file;
                    } else {
                        contentData?.data?.push(file);
                    }
                });
                documentData = contentData?.data;
            }

            if (value.typeOfContent === ContentMaterialTypeEnum.header) {
                headerData = JSON.parse(value.content) as Header;
            }

            if (value.typeOfContent === ContentMaterialTypeEnum.table) {
                const content = JSON.parse(value.content) as {
                    table: {
                        size: { x: number; y: number };
                        table?: Array<RawDraftContentState[]>;
                        html: Array<string[]>;
                        widths: number[];
                        heights: number[];
                        minHeights: number[];
                        editorBuffer: RawDraftContentState;
                    };
                    title: string;
                };

                const editorBuffer = EditorState.createWithContent(convertFromRaw(content.table.editorBuffer));

                const table = content?.table?.table?.map((row) =>
                    row.map((cell) => {
                        const content =
                            typeof cell === 'string' ? ContentState.createFromText(cell) : convertFromRaw(cell);
                        return EditorState.createWithContent(content);
                    }),
                );

                tableData = {
                    ...content,
                    table: {
                        ...content.table,
                        editorBuffer,
                        table,
                    },
                };
            }

            if (value.typeOfContent === ContentMaterialTypeEnum.exercise && questions?.length) {
                const questionData = questions?.find((question) => question.id === Number(value.content));

                if (questionData) {
                    const answer = answers && answers[value.id] ? answers[value.id] : undefined;
                    const [studentQuestionsData] = getStudentQuestionsData([questionData]);
                    exerciseData = {
                        ...normalizeQuestionData(studentQuestionsData, answer),
                        isFull: true,
                        questionId: Number(value.content),
                        isActual: studentQuestionsData.isActual,
                    };
                }
            }

            const typeOfContent =
                value.typeOfContent === ContentMaterialTypeEnum.video && checkIsKinescopeUrlValid(videoData.url)
                    ? ContentMaterialTypeEnum.kinescope
                    : value.typeOfContent;
            return {
                textData,
                documentData,
                headerData,
                imageData,
                audioData,
                videoData,
                tableData,
                files:
                    files.filter((file) => file.material === value.id)?.flatMap((file) => file.files) || ([] as File[]),
                url,
                id: value.id,
                type: typeOfContent,
                html: value.html || '',
                exerciseData,
                isDeleted: Boolean(value.isDeleted),
            };
        });
};

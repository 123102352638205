import { StudentGroupStatisticsTariff } from 'api/services/studentGroups/getStudentsGroupStatisticsTariffs';
import { alphabet, groupNumberRegExp, letterRegExp } from 'containers/ActiveClasses/ActiveClasses.constants';
import { User } from 'types/user';

export const sortGroupTitles = (first: { title: string }, second: { title: string }): number => {
    const firstClassLetter = (letterRegExp.exec(first.title) ?? [''])[0];
    const secondClassLetter = (letterRegExp.exec(second.title) ?? [''])[0];
    const firstClassNumber = Number((groupNumberRegExp.exec(first.title) ?? [''])[0]);
    const secondClassNumber = Number((groupNumberRegExp.exec(second.title) ?? [''])[0]);
    const sortNumber = alphabet.indexOf(firstClassLetter) - alphabet.indexOf(secondClassLetter);
    if (!alphabet.includes(firstClassLetter) && alphabet.includes(secondClassLetter)) {
        return 1;
    }
    if (alphabet.includes(firstClassLetter) && !alphabet.includes(secondClassLetter)) {
        return -1;
    }
    return sortNumber !== 0 ? sortNumber : firstClassNumber - secondClassNumber;
};

export const sortGroupParallels = (first: { title: string }, second: { title: string }): number => {
    const firstParallel = Number((groupNumberRegExp.exec(first.title) ?? ['0'])[0]);
    const secondParallel = Number((groupNumberRegExp.exec(second.title) ?? ['0'])[0]);
    return firstParallel - secondParallel;
};

export const tutorName = (curators: User[]): string => {
    const curatorsNames: string[] = [];
    curators.map((curator) =>
        curatorsNames.push(`${curator.firstName} ${curator.lastName[0]}.${(curator?.patronymic ?? [''])[0]}.`),
    );
    return curatorsNames.join(', ');
};

export const sortParallels = (first: { parallel: string }, second: { parallel: string }): number => {
    return Number(first.parallel) - Number(second.parallel);
};

export const sortClassLetters = (first: { classLetter: string }, second: { classLetter: string }): number => {
    if (!alphabet.includes(first.classLetter) && alphabet.includes(second.classLetter)) {
        return 1;
    }
    if (alphabet.includes(first.classLetter) && !alphabet.includes(second.classLetter)) {
        return -1;
    }
    return alphabet.indexOf(first.classLetter) - alphabet.indexOf(second.classLetter);
};

export const studentsCount = (item: { id: number; title: string; studentsCount: number }[]): number => {
    return item.reduce((sum, { studentsCount }) => sum + studentsCount, 0);
};

export const vacanciesCount = (item: StudentGroupStatisticsTariff[]): number => {
    return item.reduce((sum, { tariffs }) => {
        const sudentGroupSum = tariffs.reduce((sum, { vacanciesCount }) => sum + vacanciesCount, 0);
        return sum + sudentGroupSum;
    }, 0);
};

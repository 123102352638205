import { AnswerAttemptPostResponse } from 'api/services/answerAttempt';

export const normalizeAnswerAttempt = (attempts: AnswerAttemptPostResponse[]): AnswerAttemptPostResponse[] => {
    return [...attempts]
        .map((attempt) => {
            return {
                ...attempt,
                answers: attempt.answers.map((answer) => {
                    return {
                        ...answer,
                        files: [...(answer.files ?? []).sort((a, b) => a.id - b.id)],
                    };
                }),
            };
        })
        .sort((a, b) => a.id - b.id);
};

import { EditorState } from 'draft-js';

export interface IMultipleAnswerOption {
    answer: string | EditorState;
    isCorrect: boolean;
}

export interface IMultipleAnswerFieldsProps {
    name: string;
    option: IMultipleAnswerOption;
    onOptionDelete: () => void;
    onOptionCopy: () => void;
    moveFieldsHandler: (dragIndex: number, hoverIndex: number) => void;
    index: number;
    checkboxesValidators?: ((value: boolean) => undefined | string)[];
    isBlocked: boolean;
    disableActionBlocks?: boolean;
    savePastedStyles?: boolean;
}

export const itemTypes = {
    MULTIPLE_ANSWER_FIELDS: 'MULTIPLE_ANSWER_FIELDS',
};

export interface IDragItem {
    index: number;
    name: string;
    type: string;
}

import { RefObject, useEffect, useMemo, useState } from 'react';

import { findRowsCount } from './utils';

const PX_FOR_SYMBOL = 8;
const PADDING = 44;

export const useQuestionLocationRows = (
    ref: RefObject<HTMLDivElement>,
    text: string,
    items: number,
    isOpened: boolean,
): number => {
    const [symbols, setSymbols] = useState(1);
    const [width, setWidth] = useState(PX_FOR_SYMBOL + PADDING);

    useEffect(() => {
        setWidth(ref.current?.offsetWidth || PX_FOR_SYMBOL + PADDING);
    }, [isOpened, ref]);

    useEffect(() => {
        setSymbols(Math.floor((width - PADDING) / PX_FOR_SYMBOL));
    }, [ref, width]);

    const rows = useMemo(() => findRowsCount(text, items, symbols), [items, symbols, text]);

    return rows;
};

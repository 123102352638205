import { ThemeConfig } from 'antd';

export const tableConfigProviderTheme: ThemeConfig = {
    token: {
        colorBgBase: 'transparent',
        colorBgContainer: 'transparent',
        colorBorderSecondary: 'transparent',
        colorBgTextActive: 'transparent',
        colorBgTextHover: 'transparent',
        colorTextHeading: '#252232',
    },
};

import { TBlockParam, TQuestionItemRequest } from 'types/lessonTask';

export const normalizeQuestionsOrder = (questions: TQuestionItemRequest[]): TQuestionItemRequest[] => {
    const normalizedList: TBlockParam[] = [];

    questions
        .sort((a, b) => a.questionNumber - b.questionNumber)
        .forEach((item, index) => {
            if (index) {
                const diff = item.questionNumber - questions[index - 1].questionNumber;
                const prevNum = normalizedList[index - 1].questionNumber;
                normalizedList.push({
                    ...item,
                    questionNumber: diff ? prevNum + 1 : prevNum,
                });
            } else if (item.questionNumber !== 1) {
                normalizedList.push({
                    ...item,
                    questionNumber: 1,
                });
            } else {
                normalizedList.push({ ...item });
            }
        });

    return normalizedList;
};

const IMAGE_ALLOWED_EXTENSIONS = [
    '.png',
    '.jpeg',
    '.gif',
    '.odg',
    '.otg',
    '.svg',
    '.jpg',
    '.heic',
    '.tif',
    '.tiff',
    '.bmp',
];
const VIDEO_ALLOWED_EXTENSIONS = ['.mp4', '.webm', '.avi', '.mkv', '.mov'];
const DOCUMENT_ALLOWED_EXTENSIONS = [
    '.pdf',
    '.odt',
    '.ott',
    '.sxw',
    '.rtf',
    '.doc',
    '.docx',
    '.wpd',
    '.txt',
    '.html',
    '.ods',
    '.ots',
    '.sxc',
    '.xls',
    '.xlsx',
    '.csv',
    '.tsv',
    '.odp',
    '.otp',
    '.sxi',
    '.ppt',
    '.pages',
    '.pptx',
    '.numbers',
];
const AUDIO_ALLOWED_EXTENSIONS = [
    '.aac',
    '.ac3',
    '.aif',
    '.aiff',
    '.amr',
    '.aob',
    '.ape',
    '.asf',
    '.aud',
    '.aud',
    '.awb',
    '.bin',
    '.bwg',
    '.cdr',
    '.flac',
    '.gpx',
    '.ics',
    '.iff',
    '.m',
    '.m3u',
    '.m3u8',
    '.m4a',
    '.m4b',
    '.m4p',
    '.m4r',
    '.mid',
    '.midi',
    '.mod',
    '.mp3',
    '.mpa',
    '.mpp',
    '.msc',
    '.msv',
    '.mts',
    '.nkc',
    '.ogg',
    '.ps',
    '.ra',
    '.ram',
    '.sdf',
    '.sib',
    '.sln',
    '.spl',
    '.srt',
    '.srt',
    '.vb',
    '.wav',
    '.wav',
    '.wave',
    '.wm',
    '.wma',
    '.wpd',
    '.xsb',
    '.xwb',
];
const ARCHIVE_ALLOWED_EXTENSIONS = ['.zip', '.rar', '.7zip'];
const CUSTOM_ALLOWED_EXTENSIONS = ['.sb3'];

export const ACCEPT_FILE_TYPES = [
    ...IMAGE_ALLOWED_EXTENSIONS,
    ...VIDEO_ALLOWED_EXTENSIONS,
    ...DOCUMENT_ALLOWED_EXTENSIONS,
    ...AUDIO_ALLOWED_EXTENSIONS,
    ...ARCHIVE_ALLOWED_EXTENSIONS,
    ...CUSTOM_ALLOWED_EXTENSIONS,
];

export const MAX_FILE_SIZE_MB = 200;

export const INPUT_ACCEPT_FILE_TYPES = ACCEPT_FILE_TYPES.concat(
    ACCEPT_FILE_TYPES.map((type) => type.toUpperCase()),
).join(',');

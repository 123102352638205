import {
    AssignmentLevels,
    AssignmentType,
    ExecutionTimes,
    VerificationTypes,
} from 'src-new/components/lesson-task/LessonTaskPage.types';
import { ASSIGNMENT_LEVELS_MAPPING, EXECUTION_TIMES_MAPPING, VERIFICATION_TYPE_MAPPING } from 'src-new/constants';

const transformExecutionTime = (props: ExecutionTimes[]): string =>
    props.map((time) => EXECUTION_TIMES_MAPPING[time] || '')?.join(', ');

const transformAssignmentLevels = (level?: AssignmentLevels): string => (level ? ASSIGNMENT_LEVELS_MAPPING[level] : '');

const transformVerificationType = (props: VerificationTypes[]): string =>
    props.map((type) => VERIFICATION_TYPE_MAPPING[type] || '')?.join(', ');

export const getQuestionDetails = (
    currentAssignment: AssignmentType | undefined,
): { title: string; description: string }[] => {
    return [
        {
            title: 'Тип проверки задания: ',
            description: transformVerificationType(currentAssignment?.verificationType ?? []),
        },
        {
            title: 'Время выполнения задания: ',
            description: transformExecutionTime(currentAssignment?.executionTime ?? []),
        },
        {
            title: 'Кол-во попыток выполнения задания: ',
            description: currentAssignment?.attemptsQuantity
                ? currentAssignment?.attemptsQuantity === 1
                    ? '1'
                    : 'неограниченно'
                : '',
        },
        {
            title: 'Вес: ',
            description: `${currentAssignment?.weight ?? ''}`,
        },
        {
            title: 'Уровень задания: ',
            description: transformAssignmentLevels(currentAssignment?.assignmentLevel),
        },
        {
            title: currentAssignment?.isMandatory ? 'Задание обязательно к выполнению' : '',
            description: '',
        },
    ];
};

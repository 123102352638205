import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { QuestionSearchBarFieldsValues } from 'containers/QuestionSearchBar';
import { optionsType } from 'pages/QuestionSearchPage/utils';
import {
    EssayQuestionData,
    InsertWordQuestionData,
    TestQuestionData,
    TextQuestionData,
    TrueFalseQuestionData,
} from 'types/question';

import { AnswersTypeEnum } from 'components/QuestionPopUp/QuestionPopUp.types';
import { createUrlQuery } from 'utils/createUrlQuery';

import { TrainingSearchBlockFilters } from './materials';
import { QuestionStatus } from './questionBank';

export enum DifficultyEnum {
    easy = 'easy',
    medium = 'medium',
    hard = 'hard',
}

export interface QueryParams {
    text?: string;
    topics: number[];
    difficulty?: DifficultyEnum[];
    answersType?: AnswersTypeEnum[];
    skillsOr: number[];
    skillsAnd: number[];
    excludeIds: number[];
    id: number | null;
}

export interface GetSearchQuestions {
    id: number;
    text: string;
    difficulty: DifficultyEnum;
    content?: string;
    author: {
        id: number;
        firstName: string;
        lastName: string;
        patronymic: string;
        online: boolean;
        lastSeen: string;
    };
    lessons: {
        id: number;
    }[];
    topics: {
        id: number;
    };
    skills: {
        id: number;
        title: string;
    }[];
    isActual?: boolean;
    isDeleted?: boolean;
    answersType: AnswersTypeEnum;
    status: QuestionStatus;
    timestamp: string;
    html?: string;
    multiply: boolean;
    data: TestQuestionData | InsertWordQuestionData | TrueFalseQuestionData | EssayQuestionData | TextQuestionData;
    revisions?: {
        id: number;
        comment: string;
        dateCreate: string;
    }[];
}

export interface SearchBlockGet {
    id?: number;
    assignment: number;
    questionNumber: number;
    single?: boolean;
    filters: QueryParams;
    quantity: number;
}

export interface SearchBlockPost {
    id?: number;
    assignment: number;
    questionNumber: number;
    filters: QueryParams;
}

export interface Topic {
    id: number;
    title: string;
}

export interface Answer {
    id: number;
    content: string;
    html?: string;
    order: number;
    isCorrect: boolean;
    acceptFile: boolean;
    acceptText: boolean;
    percentOfScore: number;
}

export interface QuestionDetail {
    id: number;
    text: string;
    content: string;
    difficulty: DifficultyEnum;
    answersType: AnswersTypeEnum;
    timestamp: string;
    author: {
        id: number;
        firstName: string;
        lastName: string;
        patronymic: string;
    };
    topics: Topic[];
    skills: {
        id: number;
        title: string;
    }[];
    data: TestQuestionData | InsertWordQuestionData | TrueFalseQuestionData | EssayQuestionData | TextQuestionData;
    html?: string;
    status: QuestionStatus;
    revisions?: {
        id: number;
        comment: string;
        dateCreate: string;
    }[];
}

export const getQuestionSearch = (
    values: QuestionSearchBarFieldsValues & { status?: QuestionStatus; all_user_questions?: boolean },
): Promise<GetSearchQuestions[]> => {
    const params = createUrlQuery({
        topics: values.location?.map((block) => block.topicId),
        skills_and: values.skillsSelect === 'Все' ? values.skills?.map((skillLocation) => skillLocation.skillId) : [],
        skills_or: values.skillsSelect === 'Все' ? [] : values.skills?.map((skillLocation) => skillLocation.skillId),
        difficulty:
            !values.complexity?.length || values.complexity?.length === 3
                ? []
                : values.complexity.map((complexity) =>
                      complexity.replace('Средняя', 'medium').replace('Низкая', 'easy').replace('Высокая', 'hard'),
                  ),
        answers_type:
            values.types?.length === optionsType.length
                ? []
                : values.types?.map((type) =>
                      type
                          .replace('Вопрос - тестовый, с текстовым ответом', 'text')
                          .replace('Вопрос - тестовый', 'test')
                          .replace('Вопрос - открытый вопрос - эссе', 'essay')
                          .replace('Вопрос - верно или нет', 'true_false')
                          .replace('Вопрос - вставить слова', 'insert_words'),
                  ),
        text: values.name,
        status: QuestionStatus.Published,
        all_user_questions: values.all_user_questions ? 'true' : undefined,
    });

    return api
        .get<never, AxiosResponse<GetSearchQuestions[]>>(`/codifier/question/${params}`)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const getQuestionSearchByQueryFilters = ({
    topics,
    skillsAnd,
    skillsOr,
    text,
    difficulty,
    answersType,
    id,
    excludeIds,
    status,
    all_user_questions,
}: Partial<TrainingSearchBlockFilters>): Promise<GetSearchQuestions[]> => {
    if (id) {
        return api
            .get<GetSearchQuestions>(`/codifier/question/${id}/`)
            .then((res) => [res.data])
            .catch((err: AxiosError<{ error: string }>) => {
                throw err?.response?.data.error;
            });
    }

    const filtersQuery = createUrlQuery({
        topics,
        text: text,
        difficulty: difficulty,
        answers_type: answersType,
        skills_and: skillsAnd,
        skills_or: skillsOr,
        exclude_ids: excludeIds,
        status: status === undefined ? undefined : status,
        all_user_questions: all_user_questions === undefined ? undefined : String(all_user_questions),
    });

    return api
        .get<GetSearchQuestions[]>(`/codifier/question/${filtersQuery}`)
        .then((res) => res?.data?.filter((question) => (excludeIds ? !excludeIds.includes(question.id) : true)))
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const fetchAssignmentSearchBlocks = (assignmentId: number): Promise<SearchBlockGet[]> =>
    api
        .get<SearchBlockGet[]>(`/codifier/assignment-search-block/?assignment=${assignmentId}`)
        .then((res) => res?.data)
        .catch((error: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(error.response?.data);
        });

export const postSearchQuestions = ({
    assignment,
    questionNumber,
    filters,
}: SearchBlockPost): Promise<SearchBlockGet> => {
    return api
        .post<Promise<SearchBlockGet>, AxiosResponse<SearchBlockGet>>(`/codifier/assignment-search-block/create/`, {
            assignment: assignment,
            questionNumber: questionNumber,
            filters: filters,
        })
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const createAssignmentSearchBlocks = (blocks: SearchBlockPost[]): Promise<SearchBlockGet[]> => {
    return api
        .post<Promise<SearchBlockGet[]>, AxiosResponse<SearchBlockGet[]>>(
            `/codifier/assignment-search-block/bulk-create/`,
            blocks.map(({ assignment, questionNumber, filters }) => ({
                assignment: assignment,
                questionNumber: questionNumber,
                filters: filters,
            })),
        )
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const getQuestionDetail = (id: number): Promise<QuestionDetail> => {
    return api
        .get<never, AxiosResponse<QuestionDetail>>(`/codifier/question/${id}/overview/`)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export interface PatchAsignmentSearchBlockParams {
    id: number;
    questionNumber?: number;
    filters?: QueryParams;
}

export const patchAssignmentSearchBlock = (params: PatchAsignmentSearchBlockParams): Promise<SearchBlockGet> => {
    const { id, questionNumber, filters } = params;

    return api
        .patch<Promise<SearchBlockGet>, AxiosResponse<SearchBlockGet>>(
            `/codifier/assignment-search-block/partial-update/${id}/`,
            {
                questionNumber,
                filters,
            },
        )
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const deleteAssignmentSearchBlock = (searchBlockId: number): Promise<number> => {
    return api
        .delete<Promise<number>, AxiosResponse<number>>(`/codifier/assignment-search-block/delete/${searchBlockId}/`)
        .then(() => searchBlockId)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const deleteAssignmentSearchBlocksArray = (searchBlocksForDelete: { id: number }[]): Promise<number[]> => {
    return api
        .delete<void>(`/codifier/assignment-search-block/bulk-delete/`, {
            data: searchBlocksForDelete,
        })
        .then(() => searchBlocksForDelete.map(({ id }) => id))
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

import { RefObject, useEffect } from 'react';

export const useOuterClickField = (ref: RefObject<HTMLElement>, callback: () => void, isNeed = true): void => {
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent): void => {
            if (ref.current && !ref.current.contains(<Node>event.target) && isNeed) {
                callback();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return (): void => document.removeEventListener('mousedown', handleClickOutside);
    }, [ref, callback, isNeed]);
};

import { IAction } from 'types/groupActions';

const normalizeActionsList = (data: Array<IAction>): Array<IAction> => {
    return data?.map((item) => {
        const { data } = item;
        return {
            ...item,
            data: {
                ...data,
                sorting: data.sorting ?? [],
            },
        };
    });
};

export { normalizeActionsList };

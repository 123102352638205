import { createSlice } from '@reduxjs/toolkit';
import { getCuratorDashboardAction } from 'store/actions/curatorDashboard';
import { getAbsentStudentList } from 'store/normalizers/curatorDashboard/absentStudentList';
import { getAbsentTeacherList } from 'store/normalizers/curatorDashboard/absentTeacherList';
import { getLessons } from 'store/normalizers/curatorDashboard/lessons';
import { FetchStatus } from 'types/api';
import { CuratorLesson } from 'types/lesson';
import { AbsentStudent } from 'types/students';
import { Teacher } from 'types/teacher';
interface CuratorDashboardState {
    getCuratorDashboardStatus: FetchStatus;
    absentStudents: AbsentStudent[];
    absentTeachers: (Teacher & { lessonScheduleId: number })[];
    lessons: CuratorLesson[];
    error: unknown;
}

const initialState: CuratorDashboardState = {
    getCuratorDashboardStatus: FetchStatus.INITIAL,
    absentStudents: [],
    absentTeachers: [],
    lessons: [],
    error: null,
};

const curatorDashboardSlice = createSlice({
    name: 'curatorDashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCuratorDashboardAction.pending, (state) => {
            state.getCuratorDashboardStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getCuratorDashboardAction.fulfilled, (state, { payload }) => {
            state.getCuratorDashboardStatus = FetchStatus.FETCHED;
            state.absentStudents = getAbsentStudentList(payload);
            state.absentTeachers = getAbsentTeacherList(payload);
            state.lessons = getLessons(payload);
            state.error = null;
        });
        builder.addCase(getCuratorDashboardAction.rejected, (state, { error }) => {
            state.getCuratorDashboardStatus = FetchStatus.ERROR;
            state.error = error;
        });
    },
});

export const curatorDashboardReducer = curatorDashboardSlice.reducer;

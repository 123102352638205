import { StatusLabelEnum } from '@lms-elements/atomic';
import { PatchAttendanceParams } from 'api/services/attendance';
import { AttendanceStatus } from 'src-new/components/lms-elements/PerformanceTable/types';

/**
 * Returns normalized values for patch
 * @param values used for rendering Dropdown component
 * @returns
 */

export const normalizePresenceValues = (values: {
    status: StatusLabelEnum;
    lateMinutes: number;
}): PatchAttendanceParams => {
    let normalizeStatus: AttendanceStatus;

    switch (values.status) {
        case StatusLabelEnum.isLate:
            normalizeStatus = AttendanceStatus.LATE;
            break;
        case StatusLabelEnum.wasAbsent:
            normalizeStatus = AttendanceStatus.ABSENT;
            break;
        case StatusLabelEnum.illness:
            normalizeStatus = AttendanceStatus.ABSENT_DUE_ILLNESS;
            break;
        case StatusLabelEnum.seriousReason:
            normalizeStatus = AttendanceStatus.ABSENT_WITH_REASON;
            break;
        default:
            normalizeStatus = AttendanceStatus.PRESENT;
            break;
    }

    const normalizeValues = {
        status: normalizeStatus,
        lateDuration: values.lateMinutes,
    };

    return normalizeValues;
};

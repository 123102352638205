import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { Note } from 'api/services/notes';
import { changeNoteAction, createNoteAction, deleteNoteAction, getNotesAction } from 'store/actions/notes';
import { FetchStatus } from 'types/api';

export interface NoteState {
    getStatus: FetchStatus;
    postStatus: FetchStatus;
    patchStatus: FetchStatus;
    deleteStatus: FetchStatus;
    notes: Note[];
    error: unknown;
}

export const initialState: NoteState = {
    getStatus: FetchStatus.INITIAL,
    postStatus: FetchStatus.INITIAL,
    patchStatus: FetchStatus.INITIAL,
    deleteStatus: FetchStatus.INITIAL,
    notes: [],
    error: null,
};

const noteSlice = createSlice<NoteState, SliceCaseReducers<NoteState>>({
    name: 'note',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getNotesAction.pending, (state) => {
            state.getStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getNotesAction.fulfilled, (state, { payload }) => {
            state.getStatus = FetchStatus.FETCHED;
            state.notes = payload ?? [];
            state.error = null;
        });
        builder.addCase(getNotesAction.rejected, (state, { error }) => {
            state.getStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(createNoteAction.pending, (state) => {
            state.postStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(createNoteAction.fulfilled, (state, { payload }) => {
            state.postStatus = FetchStatus.FETCHED;
            state.notes = [...state.notes, payload];
            state.error = null;
        });
        builder.addCase(createNoteAction.rejected, (state, { error }) => {
            state.postStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(changeNoteAction.pending, (state) => {
            state.patchStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(changeNoteAction.fulfilled, (state, { payload }) => {
            state.patchStatus = FetchStatus.FETCHED;
            state.notes = [...state.notes.filter((note) => note.id !== payload.id), payload];
            state.error = null;
        });
        builder.addCase(changeNoteAction.rejected, (state, { error }) => {
            state.patchStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(deleteNoteAction.pending, (state) => {
            state.deleteStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(deleteNoteAction.fulfilled, (state, { payload }) => {
            state.deleteStatus = FetchStatus.FETCHED;
            if (state.notes) {
                state.notes = state.notes.filter(({ id }) => id !== payload.id);
            }
            state.error = null;
        });
        builder.addCase(deleteNoteAction.rejected, (state, { error }) => {
            state.deleteStatus = FetchStatus.ERROR;
            state.error = error;
        });
    },
});

export const resetNotesState = noteSlice.actions.reset as ActionCreatorWithoutPayload;
export const noteReducer = noteSlice.reducer;

import React from 'react';
import { getNoun } from '@frontend-modules/frontend-utils';
import { AppFontIcon, AppText } from '@frontend-modules/ui-kit';
import { SingleQuestionContainerDrag, TQuestionsBlockContainer } from 'src-new/components/lesson-task';
import { E_COLORS } from 'src-new/constants';

import './style.scss';
/**
 * @description QuestionsBlockContainer - компонент блока с вопросами
 * @props TQuestionsBlockContainer
 * */
export const QuestionsBlockContainer: React.FC<TQuestionsBlockContainer> = (props) => {
    const {
        questionsList,
        searchNum,
        isActiveBlock = false,
        isDragging,
        canDragQuestions,
        areaName,
        handleSettingsClick,
        excludeIds,
    } = props;
    const questionsCount = questionsList?.length;
    const questionsListSliced = isDragging && questionsCount > 20 ? questionsList.slice(0, 20) : questionsList;
    const blocksTitle = questionsCount
        ? `Найдено ${questionsCount} ${getNoun(questionsCount, 'вопрос', 'вопроса', 'вопросов')}`
        : `Пока вопросов не найдено`;

    return (
        <div
            className={`questions-block-container ${areaName} ${isActiveBlock ? 'active-block' : ''} ${
                isDragging ? 'dragging' : ''
            } `}
            style={isDragging ? { opacity: '1' } : {}}
        >
            <div className={'questions-block-container__header'}>
                <div className={'questions-block-container__header_left'}>
                    <AppFontIcon icon={'mechanism-fill-l'} size={20} onClick={handleSettingsClick} />
                    <AppText
                        text={`${blocksTitle}${isActiveBlock ? ' - редактирование' : ''}`}
                        textStyle={'DesktopH3'}
                    />
                </div>
                <div className={'questions-block-container__header_right'}>
                    <AppText
                        text={`Поиск ${searchNum}`}
                        textStyle={'DesktopFootNotes'}
                        color={E_COLORS.light_grey_color_2}
                    />
                </div>
            </div>
            <div className={'questions-block-container__list'}>
                {questionsCount ? (
                    questionsListSliced.map((item, index) => {
                        const isExcluded = excludeIds?.includes(item?.questionContent?.id ?? 0);
                        return (
                            <SingleQuestionContainerDrag
                                key={`SingleInBlockDrag-${isDragging ? 'dragging' : ''}-${areaName}-${
                                    item.questionContent?.id
                                }`}
                                mainProps={{
                                    question: item.questionContent,
                                    isExcluded: excludeIds?.includes(item?.questionContent?.id ?? 0),
                                }}
                                dragData={item}
                                canDrag={canDragQuestions && !isExcluded}
                                isIndependent={false}
                            />
                        );
                    })
                ) : (
                    <AppText
                        text={'При добавлении в “банк” релевантных поиску вопросов они появятся в этом блоке.'}
                        textStyle={'DesktopFontBody'}
                    />
                )}
            </div>
        </div>
    );
};
export const QuestionsBlockContainerMemo = React.memo(QuestionsBlockContainer);

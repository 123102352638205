import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

export const getApiRequest = <ResponseType, BodyType>(
    api: AxiosInstance,
    link: string,
    body?: BodyType,
): Promise<ResponseType> =>
    api
        .get<ResponseType>(link, body)
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });

export const patchApiRequest = <ResponseType, BodyType>(
    api: AxiosInstance,
    link: string,
    body?: BodyType,
    config?: AxiosRequestConfig,
): Promise<ResponseType> =>
    api
        .patch<ResponseType>(link, body, config)
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });

export const postApiRequest = <ResponseType, BodyType>(
    api: AxiosInstance,
    link: string,
    body?: BodyType,
    config?: AxiosRequestConfig,
): Promise<ResponseType> =>
    api
        .post<ResponseType>(link, body, config)
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });

export const deleteApiRequest = <ResponseType, BodyType>(
    api: AxiosInstance,
    link: string,
    params?: BodyType,
): Promise<ResponseType> =>
    api
        .delete<ResponseType>(link, params)
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });

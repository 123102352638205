import React, { CSSProperties, useEffect, useRef, useState } from 'react';

import { IScrollPaginateContainerProps } from './ScrollPaginateContainer.types';

import './ScrollPaginateContainer.styles.scss';

export const ScrollPaginateContainer: React.FC<IScrollPaginateContainerProps> = (props) => {
    const {
        className,
        children,
        onEndReached,
        onEndReachedThreshold = 0.5,
        horizontal,
        height,
        paddingBottom = 20,
    } = props;

    const listInnerRef = useRef<HTMLDivElement>(null);
    const [isReached, setIsReached] = useState(false);

    const style: CSSProperties = {
        flexDirection: horizontal ? 'row' : 'column',
        height: height,
    };

    /**
     * @description следим за появлением конца списка в зависимости от onEndReachedThreshold
     *              что бы вернуть функцию, что список закончился
     */
    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            if (Math.floor(scrollTop + clientHeight) >= Math.floor(scrollHeight) * onEndReachedThreshold) {
                if (!isReached) {
                    onEndReached?.();
                }
                setIsReached(true);
            } else {
                setIsReached(false);
            }
        }
    };

    /**
     * @description следим за изменением размеров контейнера и размеров самого списка контента,
     *              чтобы инициировать функцию onEndReached если контент меньше контейнера
     */
    useEffect(() => {
        if (listInnerRef.current) {
            const { children: childrenNode } = listInnerRef.current;
            const { scrollHeight } = listInnerRef.current;
            const childrenHeight = childrenNode.item(0)?.clientHeight ?? 0;
            if (childrenHeight < scrollHeight) {
                onEndReached?.();
            }
        }
    }, [listInnerRef?.current?.scrollHeight, listInnerRef?.current?.children?.item(0)?.clientHeight]);

    return (
        <div
            ref={listInnerRef}
            onScroll={onScroll}
            className={`scroll-paginate-container ${className ?? ''}`}
            style={style}
        >
            <div>
                {children}
                <div style={{ paddingBottom: paddingBottom }} />
            </div>
        </div>
    );
};

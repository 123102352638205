import { postApiRequest } from 'api';

export interface PatchMeetingRecordingData {
    id: number;
    isPublished: boolean;
}

export interface ChangedMeetingData {
    id: number;
    isPublished: boolean;
}

export const postMeetingRecording = (params: PatchMeetingRecordingData): Promise<ChangedMeetingData> => {
    return postApiRequest(`/meeting/recordings/update/`, params);
};

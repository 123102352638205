import { IExerciseLocalValue } from '@fsd-entities/materials';
import { TQuestionType, TTrainingQuestionAnswer } from '@fsd-shared/models/materials';

export const checkAnswerData = (
    type: TQuestionType,
    answer: IExerciseLocalValue,
): TTrainingQuestionAnswer | undefined => {
    const { insertWords, single, text, multiple } = answer;
    const answerArray = insertWords ? Object.values(insertWords) : [];
    switch (type) {
        case 'true_false':
            return { answer: single === true };
        case 'test': {
            const answer = single ? [single] : multiple;
            return answer ? { selectedOptions: answer } : { selectedOptions: [] };
        }
        case 'text':
            return text ? { selectedOption: String(text) } : { selectedOption: '' };
        case 'insert_words':
            return answerArray
                ? {
                      selectedOptions: answerArray.map((ans, gapIndex) => ({
                          gapIndex,
                          selectedWord: ans?.value ?? '',
                      })),
                  }
                : { selectedOptions: [] };
        default:
            return undefined;
    }
};

import { getApiRequest } from 'api';
import { CanFillCourse } from 'types/course';

/**
 * Return list of teachers who can fill course
 * @param subjectId
 * @returns
 */
export const getCanFillCourseList = (subjectId: number): Promise<CanFillCourse[]> => {
    return getApiRequest(`/staff-profile/?subject=${subjectId}`);
};

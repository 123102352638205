import React, { useEffect, useRef, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { useTouched } from '@lms-elements/hooks';
import { getObjectPropertyByString } from '@lms-elements/utils';

import { ITextInputFieldProps } from './TextInputField.types';

const TextInputFieldCn = cn('expanded-question-input');

export const TextInputField: React.FC<ITextInputFieldProps> = ({ name, placeholder }) => {
    const { values } = useFormState();
    const { change } = useForm();

    const [value, setValue] = useState(getObjectPropertyByString<string>(values, name) || '');

    // !!! DONT TOUCH
    useEffect(() => {
        setValue(() => getObjectPropertyByString<string>(values, name) || '');
    }, [name, values]);

    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsError(() => value.trim().length === 0);
    }, [value]);

    const ref = useRef<HTMLInputElement>(null);
    const touched = useTouched(ref);

    return (
        <Field name={name} validate={(value: string) => (value ? undefined : 'error')}>
            {() => (
                <input
                    spellCheck
                    ref={ref}
                    className={TextInputFieldCn({ isError: isError && touched })}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onBlur={() => change(name, value)}
                />
            )}
        </Field>
    );
};

import { getApiRequest } from 'api';
import { MonitoringProps } from 'types/monitoring';

export const getMonitoringInfo = async (params: { status: string; date: string }): Promise<MonitoringProps> => {
    return await getApiRequest('/schedule/monitoring/', {
        params: {
            status: params.status,
            date: params.date,
        },
    });
};

import { getApiRequest, postApiRequest } from 'api';
import {
    TChartInfo,
    TDaySchedule,
    TGetDayScheduleParams,
    TLessonInformation,
    TParallel,
} from 'types/studentScheduleTypes';

export const getFilterTree = (): Promise<TParallel[]> => {
    return getApiRequest('/student-group/tree/');
};
export const getDaySchedule = (params: TGetDayScheduleParams): Promise<TDaySchedule> => {
    return postApiRequest(`/schedule/student-groups/day/`, params);
};
export const getLessonInformation = (id: number): Promise<TLessonInformation> => {
    return postApiRequest(`/schedule/student-groups/lesson-schedule/`, { id: id });
};
export const getEventRoomInformation = (id: number): Promise<TLessonInformation> => {
    return postApiRequest(`/schedule/student-groups/event-room/`, { id: id });
};
export const getDayScheduleChart = (params: TGetDayScheduleParams): Promise<TChartInfo[]> => {
    return postApiRequest('/schedule/student-groups/timeline/', params);
};

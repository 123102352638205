import { IQuestion } from '@lms-elements/test-task/build/@types/packages/TestTask/src/Answer';
import { AnswerAttemptPostResponse } from 'api/services/answerAttempt';
import { InsertWordsAnswerData, TestAnswerData, TextAnswerData, TrueFalseAnswerData } from 'types/answerAttempt';

type ReturnType = InsertWordsAnswerData | TestAnswerData | TextAnswerData | TrueFalseAnswerData;
export const getAutoCheckAnswerData = (questionData: IQuestion): ReturnType => {
    const { type, answer } = questionData;
    switch (type) {
        case 'binary':
            return { answer: answer === 'true' };
        case 'single':
        case 'multiple':
            return answer ? { selectedOptions: Array.isArray(answer) ? answer : [answer] } : { selectedOptions: [] };
        case 'open':
            return answer ? { selectedOption: Array.isArray(answer) ? answer[0] : answer } : { selectedOption: '' };
        case 'insert-words':
            return answer
                ? {
                      selectedOptions: Array.isArray(answer)
                          ? answer
                                .map((ans, gapIndex) => ({
                                    gapIndex,
                                    selectedWord: ans,
                                }))
                                .filter((answerData) => Boolean(answerData?.selectedWord))
                          : [
                                {
                                    gapIndex: 0,
                                    selectedWord: answer,
                                },
                            ].filter((answerData) => Boolean(answerData?.selectedWord)),
                  }
                : { selectedOptions: [] };
        default:
            return {} as ReturnType;
    }
};

export const getAttemptQuestions = (answerAttempt: AnswerAttemptPostResponse): number[] => {
    return answerAttempt.answers?.map(({ questionId }) => questionId).filter(Boolean) ?? [];
};

export const getAttemptQuantityInfo = (
    attempts: AnswerAttemptPostResponse[] | null,
): { lastAttempt: number; attemptsQuantity: number } => {
    if (attempts === null) {
        return {
            lastAttempt: 0,
            attemptsQuantity: 0,
        };
    }

    const lastAttempt = attempts
        .filter(({ isDraft }) => !isDraft)
        .reduce(
            (lastAttemptNumber, attempt) => (lastAttemptNumber < attempt.attempt ? attempt.attempt : lastAttemptNumber),
            0,
        );

    return {
        lastAttempt,
        attemptsQuantity: attempts[0]?.attemptsQuantity ?? 1,
    };
};

import { getApiRequest } from 'api';
import { MarkData } from 'types/mark';

export interface AssignmentMark {
    id: number;
    marks: MarkData[];
    student: {
        id: number;
    };
    lesson: number;
    assignmentType: {
        id: number;
        title: string;
        assignmentLevel: string;
    };
    course: number;
    teacherMark: MarkData[];
}

export interface AssignmentParams {
    lessonIds: number[];
    studentIds: number[];
}

export interface AssignmentStudentParams {
    mark_date_before: string;
    mark_date_after: string;
    student: number;
}

export interface AssignmentByDateParams {
    mark_date_before: string;
    mark_date_after: string;
    lessonIds: number[];
    studentIds: number[];
}
export interface ITeacher {
    id: number;
    lastName: string;
    firstName: string;
    patronymic: string;
}

export const getAssignmentProgressTeachersList = (id: number): Promise<ITeacher[]> => {
    return getApiRequest(`/assignment-progress/teachers/?course=${id}`);
};

export const getAssignmentMarks = (params: AssignmentParams): Promise<AssignmentMark[]> => {
    return getApiRequest(
        `/assignment-progress/marks/?lesson_ids=${params.lessonIds.join(',')}&student_ids=${params.studentIds.join(
            ',',
        )}&status=completed`,
    );
};

export const getAssignmentStudentMarks = (params: AssignmentStudentParams): Promise<AssignmentMark[]> => {
    return getApiRequest(
        `/assignment-progress/marks/?student_ids=${params.student}&mark_date_before=${params.mark_date_before}&mark_date_after=${params.mark_date_after}&status=completed,on_check`,
    );
};

export const getAssignmentMarksByDate = (params: AssignmentByDateParams): Promise<AssignmentMark[]> => {
    return getApiRequest(
        `/assignment-progress/marks/?lesson_ids=${params.lessonIds.join(',')}&student_ids=${params.studentIds.join(
            ',',
        )}&mark_date_before=${params.mark_date_before}&mark_date_after=${
            params.mark_date_after
        }&status=completed,on_check`,
    );
};

import { getApiRequest } from 'api';

export interface GroupStatistics {
    allLessonsCount: number;
    allOlLessonsCount: number;
    allSpLessonsCount: number;
    passedLessonsCount: number;
    passedOlLessonsCount: number;
    passedSpLessonsCount: number;
    reserveLessonsAvailable: number;
    reserveLessonsCurrent: number;
    currentTopic: number;
}

export const getGroupStatistics = (groupId: number): Promise<GroupStatistics> => {
    return getApiRequest(`/course-groups/${groupId}/lessons-statistics/`);
};

import { patchApiRequest } from 'api';
import { NewsElement } from 'api/services/news';
import { ContentMaterialTypeEnum } from 'types/materials';

interface UpdateNewsElement {
    id: number;
    content: string;
    html: string;
    typeOfContent: ContentMaterialTypeEnum;
}

export const updateNewsElements = (elements: UpdateNewsElement[]): Promise<NewsElement & { news: number }> => {
    return patchApiRequest('/news-element/bulk-update/', elements);
};

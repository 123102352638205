import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { getStudentDataAction, postStudentProfileData } from 'store/actions/student';
import { FetchStatus } from 'types/api';
import { StudentProfile } from 'types/students';

export interface StudentState {
    getStudentDataStatus: FetchStatus;
    postStudentProfileData: FetchStatus;
    studentData: StudentProfile | null;
    error: unknown;
}

const initialState: StudentState = {
    getStudentDataStatus: FetchStatus.INITIAL,
    postStudentProfileData: FetchStatus.INITIAL,
    studentData: null,
    error: null,
};

const studentSlice = createSlice<StudentState, SliceCaseReducers<StudentState>>({
    name: 'student',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getStudentDataAction.pending, (state) => {
            state.getStudentDataStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getStudentDataAction.fulfilled, (state, { payload }) => {
            state.getStudentDataStatus = FetchStatus.FETCHED;
            state.studentData = payload;
            state.error = null;
        });
        builder.addCase(getStudentDataAction.rejected, (state, { error }) => {
            state.getStudentDataStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(postStudentProfileData.pending, (state) => {
            state.postStudentProfileData = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(postStudentProfileData.fulfilled, (state, { payload }) => {
            state.postStudentProfileData = FetchStatus.FETCHED;
            state.studentData = payload;
            state.error = null;
        });
        builder.addCase(postStudentProfileData.rejected, (state, { error }) => {
            state.postStudentProfileData = FetchStatus.ERROR;
            state.error = error;
        });
    },
});

export const resetStudentDataState = studentSlice.actions.reset as ActionCreatorWithoutPayload;
export const studentReducer = studentSlice.reducer;

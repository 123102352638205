import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAssignmentMarksByDate, getAssignmentStudentMarks } from 'api/services/assignment/getAssignmentMarks';
import {
    changeAttendanceMark,
    changeStudentPresence,
    createAttendanceMark,
    deleteAttendanceMark,
    getAttendanceMarks,
    getAttendanceStudentMarks,
    getLessonAttendance,
    PatchAttendanceParams,
    PostAttandenceMarkParams,
} from 'api/services/attendance';
import { getAttendanceMarksByDate } from 'api/services/attendance/getAttendanceMarks';
import { getLessonAbsence } from 'api/services/attendance/getLessonAbsence';
import { AcademicStudent, getMarksAndStats, getStudentMarksAndStatuses } from 'pages/AcademicPerformance/utils';
import { ICourseStructure } from 'src-new/components/lms-elements/PerformanceTable/types';
import { MarkScore } from 'types/mark';

import { Course } from 'utils/academicPerformance';

export const getLessonAttendanceDataAction = createAsyncThunk(
    'attedance/getLessonAttendance',
    async (params: { lessonId: number; courseGroupId?: number; studentIds?: number[]; eventRoom?: number }) => {
        return await getLessonAttendance(params);
    },
);

type PatchPresenceData = {
    id: number;
    values: PatchAttendanceParams;
};

export const patchStudentPresenceAction = createAsyncThunk(
    'attedance/patchPresence',
    async ({ id, values }: PatchPresenceData) => {
        return await changeStudentPresence(id, values);
    },
);

export const createAttendanceMarkAction = createAsyncThunk(
    'attedance/createMark',
    async (params: PostAttandenceMarkParams) => {
        return await createAttendanceMark(params);
    },
);

type PatchAttendanceMarkData = {
    markId: number;
    score?: MarkScore | null;
    comment?: string | null;
};

export const changeAttendanceMarkAction = createAsyncThunk(
    'attedance/changeMark',
    async ({ markId, ...params }: PatchAttendanceMarkData) => {
        return await changeAttendanceMark(markId, {
            ...params,
            comment: params.comment ? params.comment : '',
        });
    },
);

type DeleteAttendanceMarkData = {
    markId: number;
    attendanceLesson: number;
};

export const deleteAttendanceMarkAction = createAsyncThunk(
    'attedance/deleteMark',
    async ({ markId, attendanceLesson }: DeleteAttendanceMarkData) => {
        return await deleteAttendanceMark(markId).then(() => ({ markId, attendanceLesson }));
    },
);

export const getAttendanceMarksAction = createAsyncThunk(
    'attendance/getMarks',
    async (params: { lessonIds: number[]; studentIds: number[] }) => {
        return await getAttendanceMarks(params);
    },
);

export const getMarksAction = createAsyncThunk(
    'marks/getMarks',
    async (params: {
        lessonIds: number[];
        studentIds: number[];
        course: ICourseStructure[];
        students: AcademicStudent[];
        start: string;
        end: string;
        quarter: number;
    }) => {
        const studentsIds = params.students.map((student) => student.id);
        const attendMark = await getAttendanceMarksByDate({
            mark_date_before: params.end,
            mark_date_after: params.start,
            studentIds: studentsIds,
            lessonIds: params.lessonIds,
        });
        const assignMark = await getAssignmentMarksByDate({
            mark_date_before: params.end,
            mark_date_after: params.start,
            studentIds: studentsIds,
            lessonIds: params.lessonIds,
        });
        const attendanceLessons = await getLessonAttendance(params);
        return getMarksAndStats(
            attendMark,
            assignMark,
            attendanceLessons,
            params.course,
            params.students,
            params.quarter,
        );
    },
);

export const getStudentMarksAction = createAsyncThunk(
    'marks/getStudentMarks',
    async (params: { start: string; end: string; course: Course[]; quarter: 0 | 1 | 2 | 3 | 4; id: number }) => {
        const attendMark = await getAttendanceStudentMarks({
            mark_date_before: params.end,
            mark_date_after: params.start,
            student: params.id,
        });
        const assignMark = await getAssignmentStudentMarks({
            mark_date_before: params.end,
            mark_date_after: params.start,
            student: params.id,
        });
        const lessonAbsence = await getLessonAbsence({
            date_start: params.start,
            date_end: params.end,
            student: params.id,
        });
        return getStudentMarksAndStatuses(
            attendMark,
            assignMark,
            lessonAbsence,
            params.course,
            params.quarter,
            params.id,
        );
    },
);

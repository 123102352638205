import { getApiRequest } from 'api';

export interface PeriodMarkFinal {
    student: number;
    finalScore: number;
    course: number;
    finalPassed: boolean;
    finalNotCertified: boolean;
}

export interface PeriodMarkFinalParams {
    course: number;
    students: number[];
}

export const getPeriodMarkFinal = (params: PeriodMarkFinalParams): Promise<PeriodMarkFinal[]> => {
    return getApiRequest(`/period-mark/final/?course=${params.course}&students=${params.students.join(',')}`);
};

export const getPeriodStudentMarkFinal = (id: number): Promise<PeriodMarkFinal[]> => {
    return getApiRequest(`/period-mark/final/?students=${id}`);
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    changeNewsElementsOrdering,
    createNews,
    CreateNewsElement,
    CreateNewsElementResponse,
    createNewsElements,
    createNewsFile,
    deleteNews,
    deleteNewsElements,
    deleteNewsFile,
    getNews,
    getNewsById,
    getUnreadNewsStatus,
    updateNews,
    updateNewsElements,
} from 'api/services/news';
import { ContentMaterialTypeEnum } from 'types/materials';

interface NewsFilters {
    date_before: string;
    date_after: string;
}

interface CreateNewsActionParams {
    materials: {
        id: number;
        content: string;
        html: string;
        typeOfContent: ContentMaterialTypeEnum;
    }[];
    files: {
        [materialOrder: number]: File[] | undefined;
    };
}

export const getUnreadNewsStatusAction = createAsyncThunk('news/getUnreadNewsStatus', () => {
    return getUnreadNewsStatus();
});

const createAddedFilesToElements = (
    elements: CreateNewsElementResponse[],
    files: { [materialOrder: number]: File[] | undefined },
) => {
    const fileParams = Object.entries(files)
        .filter(([, files]) => files && files.length > 0)
        .flatMap(([order, files]) => {
            return (files as File[]).map((file) => ({
                file,
                newsElement: elements[Number(order)].id,
            }));
        });
    return Promise.all(fileParams.map(({ newsElement, file }) => createNewsFile(newsElement, file)));
};

export const createNewsAction = createAsyncThunk('news/createNews', ({ materials, files }: CreateNewsActionParams) => {
    return createNews()
        .then(({ id }) => {
            const elements: CreateNewsElement[] = materials.map(({ content, html, typeOfContent }) => ({
                content,
                html,
                typeOfContent,
                news: id,
            }));

            return createNewsElements(elements);
        })
        .then((elements) => createAddedFilesToElements(elements, files));
});

export const getNewsByIdAction = createAsyncThunk('news/getNewsById', (id: number) => {
    return getNewsById(id);
});

interface UpdateNewsParams {
    newsId: number;
    deletedNewsElements: number[];
    newElementsData: CreateNewsActionParams;
    changedElementsData: {
        id: number;
        content: string;
        html: string;
        typeOfContent: ContentMaterialTypeEnum;
    }[];
    files: {
        [materialId: number]: File[];
    };
    deletedFiles: number[];
    ordering: number[];
}

export const updateNewsAction = createAsyncThunk(
    'news/updateNews',
    async ({
        newsId,
        deletedNewsElements,
        newElementsData,
        changedElementsData,
        files,
        deletedFiles,
        ordering,
    }: UpdateNewsParams) => {
        const newElements = newElementsData.materials.map(({ content, html, typeOfContent }) => ({
            content,
            html,
            typeOfContent,
            news: newsId,
        }));

        const createFilesParams = Object.entries(files).flatMap(([newsElement, files]) => {
            return files.map((file) => ({
                file,
                newsElement: Number(newsElement),
            }));
        });

        const [createdElements] = await Promise.all([
            newElements.length ? createNewsElements(newElements) : null,
            changedElementsData.length ? updateNewsElements(changedElementsData) : null,
            deletedNewsElements.length ? deleteNewsElements(deletedNewsElements) : null,
            createFilesParams.map(({ newsElement, file }) => createNewsFile(newsElement, file)),
            deletedFiles.map((id) => deleteNewsFile(id)),
        ]);

        if (createdElements) {
            void (await createAddedFilesToElements(createdElements, newElementsData.files));
        }

        const newOrdering = ordering.map((materialId) => {
            const findNewMaterialIndex = newElementsData.materials.findIndex(({ id }) => id === materialId);

            if (createdElements !== null && findNewMaterialIndex > -1 && createdElements[findNewMaterialIndex]) {
                return createdElements[findNewMaterialIndex].id;
            }

            return materialId;
        });

        return changeNewsElementsOrdering(newsId, newOrdering);
    },
);

export const getNewsByDatesAction = createAsyncThunk('news/getNewsByDates', (params: NewsFilters) => {
    return getNews(params);
});

export const updateNewsLikesAction = createAsyncThunk(
    'news/updateNewsLikes',
    (params: { id: number; like: boolean }) => {
        return updateNews(params.id, params.like);
    },
);

export const deleteNewsAction = createAsyncThunk('news/deleteNews', (id: number) => {
    return deleteNews(id);
});

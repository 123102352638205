import { CodifierElementIconEnum } from '@lms-elements/atomic';
import { Header } from '@lms-elements/materials-header';
import { ImageData } from '@lms-elements/materials-image';
import { IMaterialAudio } from '@lms-elements/materials-media/build/@types/packages/MaterialsMedia/src/Audio/Expanded/ExpandedAudioMaterial.types';
import { TableData } from '@lms-elements/materials-table';
import { IQuestionForStudent } from '@lms-elements/test-task';
import { QueryParams } from 'api/services/questionCreate';
import { IQuestion } from 'containers/QuestionSearchResult/QuestionSearchResult';
import { ISearchBlock } from 'containers/QuestionSearchResult/types';
import { EditorState } from 'draft-js';
import { IFile } from 'types/file';

export interface MaterialType {
    title: string;
    description: string;
    type: ContentMaterialTypeEnum;
    icon: CodifierElementIconEnum;
}

export enum ContentMaterialTypeEnum {
    text = 'text',
    table = 'table',
    header = 'header',
    video = 'video',
    kinescope = 'kinescope',
    audio = 'audio',
    document = 'document',
    photo = 'photo',
    exercise = 'exercise',
}

export interface IMaterialDocument {
    id?: number;
    name: string;
    url: string;
}

export interface IMaterialImage {
    data: ImageData[];
    description: string;
}

export interface IMaterialTable {
    table: TableData;
    title: string;
}

export interface ExerciseData extends IQuestionForStudent {
    isFull?: boolean;
    questionId?: number;
    isActual: boolean;
}

export interface IMaterial {
    id: number;
    type: ContentMaterialTypeEnum;
    textData: string | EditorState;
    documentData: (File | string | IMaterialDocument)[];
    headerData: Header;
    imageData: IMaterialImage;
    audioData: IMaterialAudio;
    videoData: {
        url: string;
        file?: File;
    };
    files: IFile[];
    html: string;
    url: string | undefined;
    tableData: IMaterialTable;
    exerciseData: ExerciseData;
    isDeleted: boolean;
}

export enum QuestionSelectAction {
    exerciseCreate = 'exerciseCreate',
    exerciseEdit = 'exerciseEdit',
}

import React, { useEffect, useMemo, useState } from 'react';
import { isEmptyList, useWindowWidth } from '@frontend-modules/frontend-utils';
import { AppCheckbox, AppSkeleton, AppText } from '@frontend-modules/ui-kit';
import { ChartsLegendItem, GoogleChartWrapper } from '@fsd-shared/ui-kit';
import { E_COLORS } from 'src-new/constants';

import { TChartLegendItem, TCriteriaChartProps } from './CriteriaChart.types';
import { getChartData, getLegendListInitial, getSortedData } from './CriteriaChart.utils';

import './CriteriaChart.style.scss';
export const CriteriaChart: React.FC<TCriteriaChartProps> = (props) => {
    const { chartData, currentDate, isLoading } = props;
    const sortedChartData = chartData ? getSortedData(chartData) : [];
    const isTablet = useWindowWidth(1256);
    const [legendList, setLegendList] = useState([] as TChartLegendItem[]);

    useEffect(() => {
        if (chartData) {
            setLegendList(getLegendListInitial(chartData));
        }
    }, [chartData]);
    const selectedSegmentList: string[] = legendList
        .filter(({ isSelected }) => isSelected)
        .map(({ name }) => name)
        .sort((a, b) => {
            const first = sortedChartData.find(({ name }) => name === a);
            const second = sortedChartData.find(({ name }) => name === b);

            return (second?.target?.count ?? 0) - (first?.target?.count ?? 0);
        });

    const isNoData = isEmptyList(selectedSegmentList);

    const { renderOptions, renderData } = useMemo(
        () =>
            getChartData({
                selectedSegmentList,
                currentDate,
                data: sortedChartData,
                showAllHorizontalTicks: !isTablet,
            }),
        [selectedSegmentList, sortedChartData, currentDate],
    );

    const onCheckboxChange = (state: boolean, legendKey: string) => {
        setLegendList((prevState) =>
            prevState.map((item) => {
                if (item.key === legendKey) {
                    return {
                        ...item,
                        isSelected: state,
                    };
                }
                return item;
            }),
        );
    };
    const isInvalidData = useMemo(() => {
        const columnsToCheck = [...Array(renderData[0].length - 2)].map((_, index) => {
            return 2 + index;
        });

        return renderData.slice(1).every((row) => {
            return columnsToCheck.every((index) => row[index] === null);
        });
    }, [renderData]);
    return (
        <div className={'criteria-chart'}>
            {isLoading ? (
                <>
                    <AppSkeleton.Input active style={{ height: 300, width: '100%' }} />
                </>
            ) : (
                <>
                    <div className={'criteria-chart__legend'}>
                        {legendList.map(({ key, isSelected, name, color, title }) => {
                            return (
                                <ChartsLegendItem key={key} color={color}>
                                    <AppCheckbox
                                        onChange={(val) => onCheckboxChange(val, key)}
                                        defaultValue={isSelected}
                                        label={<AppText text={title} textStyle={'DesktopFootNotes'} />}
                                    />
                                </ChartsLegendItem>
                            );
                        })}
                    </div>
                    <div className={'criteria-chart__chart-container'}>
                        {isNoData ? (
                            <AppText
                                text={`Выберите уровень сложности в меню ${!isTablet ? 'слева' : 'сверху'}`}
                                textStyle={'DesktopH3'}
                                color={E_COLORS.light_grey_color_2}
                            />
                        ) : (
                            <GoogleChartWrapper
                                chartType="ComboChart"
                                width="100%"
                                height="400px"
                                data={renderData}
                                options={renderOptions}
                                isNoData={isInvalidData}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

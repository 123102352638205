import { IExercise, IInsertWordsQuestionAnswer, TTrainingQuestionAnswer } from '@fsd-shared/models/materials';

/**
 * @description если тип вопроса insert_words проверяем что бы все поля были заполнены
 * @param answer
 * @param exercise
 */
export const checkInsertWordAnswerSend = (
    answer: TTrainingQuestionAnswer | undefined,
    exercise: IExercise | undefined,
): boolean => {
    const { type, question } = exercise || {};
    if (type === 'insert_words' && Array.isArray(question)) {
        const { selectedOptions } = (answer as IInsertWordsQuestionAnswer | undefined) || {};
        // считаем сколько полей нужно было заполнить
        const expectedAnswerLength = question.filter((part) => typeof part === 'number').length;
        // считаем введенные ответы проверяя их на пустоту и обрезаем лишние пробелы
        const filteredAnswer = selectedOptions?.filter((item) => !!item?.selectedWord?.trim());
        const currentAnswerLength = filteredAnswer?.length;
        return expectedAnswerLength === currentAnswerLength;
    }
    return true;
};

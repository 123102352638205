import { TAssignedQuestion, TAssignedQuestions, TSearchBlock, TSingleQuestion } from 'types/lessonTask';

export const deleteTask = (position: number, currentState?: TAssignedQuestions): TAssignedQuestions | undefined => {
    if (currentState) {
        // @ts-ignore
        const newList: TAssignedQuestion[] = Object.values(currentState)
            .map((assignment, index) => {
                if (position !== index + 1) {
                    return assignment;
                }
            })
            .filter(Boolean);

        const newVal: TAssignedQuestions = {};

        newList?.forEach((item, index) => {
            newVal[index + 1] = item;
        });

        return newVal;
    }
    return undefined;
};
export const addTask = (params: {
    position: number;
    task: TAssignedQuestion;
    currentState?: TAssignedQuestions;
}): TAssignedQuestions => {
    const { position, task, currentState } = params;
    return currentState && Object.values(currentState).length
        ? {
              ...currentState,
              [position]: task,
          }
        : position === 1
        ? { [position]: task }
        : { 1: task, [position]: task };
};

export const addSingleQuestion = (params: {
    position: number;
    question: TSingleQuestion;
    currentState?: TAssignedQuestions;
}): TAssignedQuestions => {
    const { position, question, currentState } = params;

    return currentState
        ? {
              ...currentState,
              [position]: {
                  ...currentState?.[position],
                  singleQuestionsList: [question, ...(currentState?.[position]?.singleQuestionsList ?? [])],
              } as TAssignedQuestion,
          }
        : {
              [position]: {
                  singleQuestionsList: [question],
              } as TAssignedQuestion,
          };
};

export const addQuestionsBlock = (params: {
    position: number;
    blockPosition: number;
    block: TSearchBlock;
    currentState: TAssignedQuestions;
}): TAssignedQuestions => {
    const { position, blockPosition, block, currentState } = params;

    return {
        ...currentState,
        [position]: {
            ...currentState[position],
            searchBlocksList: [
                ...currentState[position]?.searchBlocksList?.slice(0, blockPosition - 1),
                block,
                ...currentState[position]?.searchBlocksList.slice(blockPosition),
            ],
        },
    };
};

export const deleteQuestionsBlock = (params: {
    position: number;
    blockPosition: number;
    currentState: TAssignedQuestions;
}): TAssignedQuestions => {
    const { position, blockPosition, currentState } = params;

    return {
        ...currentState,
        [position]: {
            ...currentState[position],
            searchBlocksList: [
                ...currentState[position]?.searchBlocksList?.slice(0, blockPosition - 1),
                ...currentState[position]?.searchBlocksList.slice(blockPosition),
            ],
        },
    };
};

import { DraftEntityMutability, EditorState, Modifier } from 'draft-js';
import { getEntityRange, getSelectionEntity } from 'draftjs-utils';
import { EntityTypes } from 'src-new/components/lms-elements/CustomEditor/types/shared.types';

interface IAddEntityProps {
    editorState: EditorState;
    onChange: (editorState: EditorState) => void;
    text: string;
    entityType: EntityTypes;
    mutability: DraftEntityMutability;
    entityData?: Record<string, unknown>;
    selectAfterCreate?: boolean;
    insertSpaceAfter?: boolean;
}

export const addEntity = ({
    editorState,
    onChange,
    text,
    entityType,
    mutability,
    entityData,
    selectAfterCreate = false,
    insertSpaceAfter = true,
}: IAddEntityProps): void => {
    const currentEntity = getSelectionEntity(editorState);
    let selection = editorState.getSelection();

    if (currentEntity) {
        const entityRange = getEntityRange(editorState, currentEntity);
        const isBackward = selection.getIsBackward();
        if (isBackward) {
            selection = selection.merge({
                anchorOffset: entityRange.end,
                focusOffset: entityRange.start,
            });
        } else {
            selection = selection.merge({
                anchorOffset: entityRange.start,
                focusOffset: entityRange.end,
            });
        }
    }
    const entityKey = editorState
        .getCurrentContent()
        .createEntity(entityType, mutability, entityData)
        .getLastCreatedEntityKey();

    let contentState = Modifier.replaceText(
        editorState.getCurrentContent(),
        selection,
        text,
        editorState.getCurrentInlineStyle(),
        entityKey,
    );
    let newEditorState = EditorState.push(editorState, contentState, 'insert-characters');

    selection = newEditorState.getSelection().merge({
        anchorOffset: selection.getAnchorOffset() + text.length,
        focusOffset: selection.getAnchorOffset() + text.length,
    });
    newEditorState = EditorState.acceptSelection(newEditorState, selection);

    if (insertSpaceAfter) {
        contentState = Modifier.insertText(
            newEditorState.getCurrentContent(),
            selection,
            ' ',
            newEditorState.getCurrentInlineStyle(),
            undefined,
        );
    }

    if (selectAfterCreate) {
        newEditorState = EditorState.push(newEditorState, contentState, 'insert-characters');

        selection = newEditorState.getSelection().merge({
            anchorOffset: selection.getAnchorOffset() - text.length,
            focusOffset: selection.getAnchorOffset(),
        });
        newEditorState = EditorState.acceptSelection(newEditorState, selection);
    }

    onChange(EditorState.push(newEditorState, contentState, 'insert-characters'));
};

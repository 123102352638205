import React, { SetStateAction } from 'react';
import { EditorState, Modifier } from 'draft-js';
import { getEntityRange } from 'draftjs-utils';

interface IremoveEntityFromSelectionProps {
    entity: string;
    editorState: EditorState;
    onChange: React.Dispatch<SetStateAction<EditorState>>;
}

export const removeLatexEntity = ({ entity, editorState, onChange }: IremoveEntityFromSelectionProps): void => {
    let selection = editorState.getSelection();
    const entityRange = getEntityRange(editorState, entity);
    const isBackward = selection.getIsBackward();
    if (isBackward) {
        selection = selection.merge({
            anchorOffset: entityRange.end,
            focusOffset: entityRange.start,
        });
    } else {
        selection = selection.merge({
            anchorOffset: entityRange.start,
            focusOffset: entityRange.end,
        });
    }
    let contentState = Modifier.applyEntity(editorState.getCurrentContent(), selection, null);
    let newEditorState = EditorState.push(editorState, contentState, 'apply-entity');

    contentState = Modifier.removeRange(
        newEditorState.getCurrentContent(),
        selection,
        isBackward ? 'backward' : 'forward',
    );
    newEditorState = EditorState.push(newEditorState, contentState, 'delete-character');
    selection = newEditorState.getSelection().merge({
        anchorOffset: selection.getAnchorOffset(),
        focusOffset: selection.getAnchorOffset(),
    });
    newEditorState = EditorState.acceptSelection(newEditorState, selection);

    onChange(newEditorState);
};

import React, { useState } from 'react';
import { AppButton, AppFontIcon, AppNumberInput, AppSelect, AppText, AppTextareaInput } from '@frontend-modules/ui-kit';
import { EReasonsTitles, TReason } from '@fsd-shared/models/statisticsMonitoring';
import { E_COLORS } from 'src-new/constants';

import { TTeacherWorkingSettings } from './TeacherWorkingSettings.types';

import './TeacherWorkingSettings.style.scss';
export const TeacherWorkingSettings: React.FC<TTeacherWorkingSettings> = (props) => {
    const {
        defaultValue = 0,
        maxValue = 22,
        minValue = 0,
        defaultReasonValue,
        error,
        defaultCustomReason,
        onApply,
    } = props;

    const [selectedReason, setSelectedReason] = useState(defaultReasonValue);
    const [value, setValue] = useState(defaultValue);
    const [customReason, setCustomReason] = useState(defaultCustomReason);

    const reasonsOptions = ['medical_certificate', 'not_month_start', 'teacher_vacation', 'other'].map((item) => {
        return {
            label: EReasonsTitles[item as TReason],
            value: item,
        };
    });

    const isButtonDisabled = selectedReason === 'other' ? !customReason : !selectedReason;

    const onApplySettings = () => {
        if (selectedReason) {
            onApply({
                value: value,
                changeReason: selectedReason,
                comment: selectedReason === 'other' ? customReason : undefined,
            });
        }
    };

    return (
        <div className={'teacher-working-settings'}>
            <div className={'teacher-working-settings__settings'}>
                <div className={'teacher-working-settings__settings_counter'}>
                    <AppText text={'Кол-во'} textStyle={'DesktopFootNotes'} color={E_COLORS.light_grey_color_2} />
                    <AppNumberInput
                        defaultValue={value}
                        addonBefore={
                            <AppFontIcon
                                className={`control-btn ${
                                    value - 1 < minValue || value === minValue ? 'disabled' : ''
                                }`}
                                icon={'minus-l'}
                                size={20}
                                onClick={setValue.bind(this, (prevState) => prevState - 1)}
                            />
                        }
                        addonAfter={
                            <AppFontIcon
                                className={`control-btn ${
                                    maxValue && (value + 1 > maxValue || value === maxValue) ? 'disabled' : ''
                                }`}
                                icon={'plus-s'}
                                size={20}
                                onClick={setValue.bind(this, (prevState) => prevState + 1)}
                            />
                        }
                        min={minValue}
                        onChange={setValue}
                        max={maxValue}
                        parser={(value) => value.replace(/\D/g, '')} // Удаляет все нецифровые символы
                        precision={0}
                    />
                </div>
                <AppSelect
                    label={'Причина'}
                    options={reasonsOptions}
                    defaultValue={defaultReasonValue}
                    placeholder={'Выберите вариант'}
                    onChange={(_: string, value: TReason) => setSelectedReason(value)}
                />
                {selectedReason === 'other' && (
                    <div className={'teacher-working-settings__settings_custom-reason'}>
                        <AppTextareaInput
                            label={'Комментарий'}
                            placeholder={'Напишите причину'}
                            defaultValue={customReason}
                            onChange={setCustomReason}
                        />
                    </div>
                )}
            </div>
            {error?.map((err, index) => (
                <AppText
                    key={`error-${index}`}
                    text={err}
                    textStyle={'DesktopFootNotes'}
                    color={E_COLORS.coral_color}
                />
            ))}
            <AppButton label={'Применить'} disabled={isButtonDisabled} onClick={onApplySettings} />
        </div>
    );
};

import React from 'react';
import { AppText } from '@frontend-modules/ui-kit';
import { TUserAvatar } from '@fsd-shared/ui-kit/UserAvatar/UserAvatar.types';
import { E_COLORS } from 'src-new/constants';

import './UserAvatar.style.scss';
export const UserAvatar: React.FC<TUserAvatar> = (props) => {
    const {
        imageUrl,
        initials,
        color = E_COLORS.firm_blue_color,
        size = 48,
        borderless = true,
        isOnline,
        onAvatarClick,
    } = props;

    const style = {
        background: `${color}15`,
        width: size,
        height: size,
        border: borderless ? 'none' : '1px solid #fff',
    };

    return (
        <div className={'user-avatar__wrapper'}>
            <div className={'user-avatar'} style={style} onClick={onAvatarClick}>
                {imageUrl ? (
                    <img src={imageUrl} alt={'preview'} />
                ) : initials ? (
                    <AppText text={initials} textStyle={size > 32 ? 'DesktopH4' : 'DesktopFootNotes'} color={color} />
                ) : (
                    <></>
                )}
            </div>
            {isOnline && <div className={'user-avatar__wrapper_online'} />}
        </div>
    );
};

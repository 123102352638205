import React, { RefObject } from 'react';
import { EditorState } from 'draft-js';

export interface IInsertWordProviderProps {
    editorState: EditorState;
    onChange: React.Dispatch<React.SetStateAction<EditorState>>;
    editorRect: DOMRect | undefined;
    containerRef: RefObject<HTMLDivElement>;
}

export type Position = {
    top: number;
    left: number;
};

export enum PopupType {
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
}

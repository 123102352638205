import React, { useEffect, useState } from 'react';

import './style.scss';

export const AppSkeletonCustom: React.FC<{ isFetching: boolean; zIndex?: number }> = ({ isFetching, zIndex }) => {
    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
        setIsShow(isFetching);
    }, [isFetching]);

    return isShow ? <div className={'app-skelet'} style={{ zIndex: zIndex || 100 }} /> : null;
};

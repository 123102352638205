import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { ResponseRevisionQuestion } from 'api/services/revisionQuestion';
import {
    createRevisionQuestionAction,
    deleteRevisionQuestionAction,
    updateRevisionQuestionAction,
} from 'store/actions/revisionQuestion';
import { FetchStatus } from 'types/api';

export interface RevisionQuestionState {
    fetchStatus: FetchStatus;
    revisionQuestion: ResponseRevisionQuestion;
    error: unknown;
}

export const initialState: RevisionQuestionState = {
    fetchStatus: FetchStatus.INITIAL,
    revisionQuestion: {} as ResponseRevisionQuestion,
    error: undefined,
};

const revisionQuestionSlice = createSlice<RevisionQuestionState, SliceCaseReducers<RevisionQuestionState>>({
    name: 'revisionQuestion',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createRevisionQuestionAction.pending, (state) => {
                state.fetchStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(createRevisionQuestionAction.fulfilled, (state, { payload }) => {
                state.fetchStatus = FetchStatus.FETCHED;
                state.revisionQuestion = payload;
                state.error = null;
            })
            .addCase(createRevisionQuestionAction.rejected, (state, { error }) => {
                state.fetchStatus = FetchStatus.ERROR;
                state.error = error;
            });

        builder
            .addCase(updateRevisionQuestionAction.pending, (state) => {
                state.fetchStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(updateRevisionQuestionAction.fulfilled, (state, { payload }) => {
                state.fetchStatus = FetchStatus.FETCHED;
                state.revisionQuestion = payload;
                state.error = null;
            })
            .addCase(updateRevisionQuestionAction.rejected, (state, { error }) => {
                state.fetchStatus = FetchStatus.ERROR;
                state.error = error;
            });

        builder
            .addCase(deleteRevisionQuestionAction.pending, (state) => {
                state.fetchStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(deleteRevisionQuestionAction.fulfilled, (state) => {
                state.fetchStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(deleteRevisionQuestionAction.rejected, (state, { error }) => {
                state.fetchStatus = FetchStatus.ERROR;
                state.error = error;
            });
    },
});

export const revisionQuestionReducer = revisionQuestionSlice.reducer;

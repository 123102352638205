import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';

interface Author {
    id: number;
    firstName: string;
    lastName: string;
    isTeacher: boolean;
    isCurator: boolean;
    isMethodist: boolean;
    isStudent: boolean;
    isPrincipal: boolean;
}

export interface Note {
    id: number;
    author: Author;
    text: string;
    timestamp: string;
    student: number;
}

export interface PostNoteResponse {
    id: number;
    author: number;
    text: string;
    timestamp: string;
    student: number;
}

export interface CreateNote {
    text: string;
    student: number;
}

interface ResponsePatchedNote {
    id?: number;
    author: number;
    text: string;
    timestamp: string;
    student: number;
}

export interface DeleteNote {
    id: number;
}

export interface EditNote {
    id: number;
    text: string;
    student: number;
}

export const getNotes = async (studentId: number): Promise<Note[] | undefined> => {
    return await api
        .get<Promise<Note[]>, AxiosResponse<Note[]>>(`/profile-note/?student=${studentId}`)
        .then((res) => res.data || undefined)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const postNote = (values: CreateNote): Promise<Note> => {
    return api
        .post<Promise<PostNoteResponse>, AxiosResponse<PostNoteResponse>>(`/profile-note/create/`, {
            text: values.text,
            student: values.student,
        })
        .then((res) => res?.data)
        .then(({ author, ...note }) => ({
            ...note,
            author: {
                id: author,
                firstName: '',
                isCurator: true,
                isMethodist: true,
                isPrincipal: true,
                isStudent: true,
                isTeacher: true,
                lastName: '',
            },
        }))
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const deleteNote = (id: number): Promise<{ id: string }> => {
    return api
        .delete<Promise<{ id: string }>, AxiosResponse<{ id: string }>>(`/profile-note/delete/${id}/`)
        .then(() => ({
            id: String(id),
        }))
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const patchNote = (values: EditNote): Promise<Note> => {
    return api
        .patch<Promise<ResponsePatchedNote>, AxiosResponse<ResponsePatchedNote>>(`/profile-note/update/${values.id}/`, {
            text: values.text,
            student: values.student,
        })
        .then((res) => ({
            id: values.id,
            ...res?.data,
        }))
        .then(({ author, ...note }) => ({
            ...note,
            author: {
                id: author,
                firstName: '',
                isCurator: true,
                isMethodist: true,
                isPrincipal: true,
                isStudent: true,
                isTeacher: true,
                lastName: '',
            },
        }))
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

import React, { useMemo } from 'react';
import { CloseIcon, emptyPreview, StrokeArrowRightIcon } from 'assets';
import { getPdfViewerUrl } from 'src-new/components/library-page/utils';
import { AppModal, AppScrollPaginateContainer, AppSkeleton, AppText } from 'src-new/ui';
import { isEmptyList } from 'src-new/utils';
import { TBookAuthors } from 'types/libraryTypes';

import { TLibraryModalParams } from './LibraryModal.types';

import './style.scss';

export const LibraryModal: React.FC<TLibraryModalParams> = (params) => {
    const {
        title,
        booksList,
        bookListPaginate,
        isFetching,
        isShow,
        handleLibraryPaginate,
        handleLibraryClose,
    } = params;
    const { currentPage, maxPage } = bookListPaginate;

    const hasBooks = useMemo(() => !isEmptyList(booksList), [booksList]);
    const isModalShow = useMemo(() => hasBooks && isShow, [hasBooks, isShow]);

    const getAuthorsString = (authorsList: TBookAuthors[]): string => {
        const result = authorsList.map((item) => `${item.lastName} ${item.firstName} ${item.patronymic}`);
        return result.join(', ');
    };
    const getTitlesString = (list: string[]): string => {
        return list.filter((item, index, list) => list.indexOf(item) == index).join(', ');
    };

    const handleClose = () => {
        handleLibraryClose();
    };
    const onScrollHandle = (currPage: number) => {
        if (!isFetching && hasBooks) {
            handleLibraryPaginate(currPage);
        }
    };
    const onBookClick = (url: string) => {
        document.open(getPdfViewerUrl(url), '_blank', 'noopener noreferrer');
    };
    return (
        <AppModal className={'library-modal'} isShow={isModalShow} closeIcon={<CloseIcon />} afterClose={handleClose}>
            <div className={'library-modal-content'}>
                {isFetching ? (
                    <AppSkeleton height={200} />
                ) : (
                    <>
                        <div className={'library-modal-content__breadcrumbs'}>
                            <div>
                                <AppText text={'Библиотека'} fontStyle={'H2'} />
                            </div>
                            <StrokeArrowRightIcon />
                            <div>
                                <AppText text={title} fontStyle={'H2'} />
                            </div>
                        </div>

                        <AppScrollPaginateContainer
                            customClassName={'library-modal-content__container-scrollable'}
                            onScrollHandle={onScrollHandle}
                            needReset={currentPage === 1}
                            maxPage={maxPage}
                        >
                            <div className={'library-modal-content__book-list'}>
                                {booksList.map((book, index) => {
                                    const { id, preview, title, courseTypeBooks, authors, files } = book;

                                    return (
                                        <div
                                            key={id}
                                            className={'library-modal-content__book-list_item'}
                                            onClick={() => onBookClick(files[0]?.url)}
                                        >
                                            <AppText text={index + 1} fontStyle={'fontBody'} />
                                            <div className={'book-info'}>
                                                <div className={'book-info__preview'}>
                                                    <img src={preview || emptyPreview} alt={'book-preview'} />
                                                </div>
                                                <div className={'book-info__content'}>
                                                    <div className={'book-info__content_title'}>
                                                        <AppText text={title} fontStyle={'H4'} />
                                                        <AppText
                                                            text={getTitlesString(
                                                                courseTypeBooks.map((item) => item.type.title),
                                                            )}
                                                            fontStyle={'footnotes'}
                                                        />
                                                    </div>
                                                    <div className={'book-info__content_footer'}>
                                                        <AppText
                                                            text={getAuthorsString(authors)}
                                                            fontStyle={'footnotes'}
                                                        />
                                                        <AppText
                                                            text={getTitlesString(
                                                                courseTypeBooks.map((item) => item.course.title),
                                                            )}
                                                            fontStyle={'footnotes'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </AppScrollPaginateContainer>
                    </>
                )}
            </div>
        </AppModal>
    );
};

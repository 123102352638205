export enum AssignmentLevels {
    ADMINISTRATIVE = 'administrative',
    TEACHER = 'teacher',
}
export enum ExecutionTimes {
    IN_CLASS = 'in_class',
    AT_HOME = 'at_home',
}
export enum VerificationTypes {
    MANUAL = 'manual',
    AUTO = 'auto',
}

export type AssignmentType = {
    id: number;
    title: string;
    attemptsQuantity: number;
    verificationType: VerificationTypes[]; // тип проверки: ручная или автоматическая
    executionTime: ExecutionTimes[]; // время выполнения задания: дома или на уроке
    isMandatory: boolean; // обязательный или необязательный
    assignmentLevel: AssignmentLevels;
    weight?: number;
    delayAutoCompletion?: number; // количество минут после soft-deadline для отправки ДЗ
};

export type TAssignmentItem = {
    id: number;
    title: string;
    description?: string;
    topic: {
        id: number;
        title: string;
    };
    isTemplate: boolean;
    type: AssignmentType;
    lesson: {
        id: number;
    };
    author: number;
};

export type AssignmentResponse = {
    id: number;
    lesson: number;
    type: AssignmentType;
    author: number;
    title: string;
    description: string;
    isTemplate: boolean;
    courseGroup?: number;
};

export type TNormalizedQuestion = {
    questionNumber: number;
    questionsCount: number;
    searchBlocksCount: number;
};

import { useEffect, useState } from 'react';
const CHAT_URI = process.env.REACT_APP_CHAT_TEST_HOST;
/**
 *
 * @param isShow
 */
export const useOGChatWidget = (isShow: boolean | undefined): void => {
    const [isChatLoad, setChatLoad] = useState(false);

    const onChangeHasUnread = (value: boolean) => {
        const icoFavicon = document.getElementById('ico-favicon') as HTMLLinkElement;
        const svgFavicon = document.getElementById('svg-favicon') as HTMLLinkElement;
        const data = value ? 'unread_' : '';
        const ico = `/${data}favicon.ico`;
        const svg = `/${data}favicon.svg`;
        if (icoFavicon) icoFavicon.href = ico;
        if (svgFavicon) svgFavicon.href = svg;
    };

    useEffect(() => {
        const script = document.createElement('script');
        script.src = CHAT_URI ?? '';
        script.onload = () => setChatLoad(true);
        script.onerror = (e) => console.error('Script loading error:', e);
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script); // Удаляем скрипт при размонтировании компонента
        };
    }, []);

    useEffect(() => {
        if (isChatLoad) {
            const chatWidgetContainer = document.getElementById('chatWidgetContainer');
            if (chatWidgetContainer) {
                chatWidgetContainer?.remove();
            }
            const cont = document.createElement('div');
            cont.id = 'chatWidgetContainer';
            cont.style.position = 'fixed';
            cont.style.bottom = '0';
            cont.style.right = '0';
            cont.style.zIndex = '100';
            document.body.appendChild(cont);

            // @ts-ignore
            window?.ChatWidget?.startWidget({
                elementId: 'chatWidgetContainer',
                chatType: 'chat',
                isHide: !isShow,
                onChangeHasUnread,
                iconPlacement: {
                    bottom: 20,
                    right: 100,
                    position: 'absolute',
                },
            });
        }
    }, [isChatLoad]);

    useEffect(() => {
        const isHide = !isShow;
        if (isChatLoad) {
            // @ts-ignore
            window?.ChatWidget?.updateWidgetConfig({
                onChangeHasUnread,
                isHide: isHide,
            });
        }

        if (isHide) {
            onChangeHasUnread(false);
        }
    }, [isChatLoad, isShow]);
};

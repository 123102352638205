import React, { CSSProperties, useMemo } from 'react';
import { cn } from '@bem-react/classname';

import { IAddInsertionPopUpProps } from './AddInsertionPopUp.types';

import './AddInsertionPopUp.scss';

const cx = cn('custom-editor-add-insertion-popup');

export const AddInsertionPopUp = React.forwardRef<HTMLButtonElement, IAddInsertionPopUpProps>(
    ({ top, left, ...props }, ref) => {
        const style: CSSProperties = useMemo(() => ({ top, left }), [left, top]);

        return (
            <div className={cx()} style={style}>
                <button {...props} type="button" className={cx('content')} ref={ref}>
                    <span>+ Пропуск</span>
                </button>
            </div>
        );
    },
);

import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getCourses } from 'api/services/course';
import * as LibService from 'api/services/library';
import {
    TAuthorMainInfo,
    TBook,
    TBookAuthors,
    TBookCreatePayload,
    TBookEditPayload,
    TBookFileEditPayload,
    TBookFilePayload,
    TCreateCourseTypePayload,
    TEditCourseTypePayload,
    TGetAuthorsListPayload,
    TGetBooksListPayload,
    TGetLibraryTreePayload,
} from 'types/libraryTypes';

export const resetLibraryAction = createAction('library/resetLibrary', () => {
    return {
        payload: 'null',
    };
});
export const resetLibraryStatusesAction = createAction('library/resetLibraryStatuses', () => {
    return {
        payload: 'null',
    };
});

export const getLibraryTreeAction = createAsyncThunk(
    'library/getLibraryTree',
    async (params: TGetLibraryTreePayload) => {
        return await LibService.getLibraryTree(params);
    },
);

//КУРС-ТИП
export const createCourseTypeAction = createAsyncThunk(
    'library/createCourseType',
    async (params: TCreateCourseTypePayload[]) => {
        return await LibService.createCourseType(params);
    },
);
export const editCourseTypeAction = createAsyncThunk(
    'library/editCourseType',
    async (params: TEditCourseTypePayload[]) => {
        return await LibService.editCourseType(params);
    },
);

export const getBookCoursesListAction = createAsyncThunk('library/getAllCourses', async () => {
    return await getCourses();
});
export const getBookTypesListAction = createAsyncThunk('library/getBookTypesList', async () => {
    return await LibService.getBookTypesList();
});

//КРУД ПО КНИГЕ
export const createBookAction = createAsyncThunk('library/createBook', async (params: TBookCreatePayload) => {
    const authorsIdList = await authorsCreationHelper(params.authors);
    const createBookPayload = { ...params.creationParams };
    createBookPayload.authors = authorsIdList;

    const res = await LibService.createBook(createBookPayload);

    const courseTypePayload = params.courseType.map((item) => {
        return {
            course: item.course,
            type: item.type,
            book: res.id,
        };
    });
    try {
        await LibService.createCourseType(courseTypePayload);
        if (params.file) {
            await LibService.createBookFile({
                book: res.id,
                file: params.file,
            });
        }
    } catch (err) {
        void params?.callback?.(res?.id || 0);
        throw err;
    }

    return res;
});
export const editBookAction = createAsyncThunk('library/editBook', async (params: TBookEditPayload) => {
    const authorsIdList = params.authors.length ? await authorsCreationHelper(params.authors) : [];
    const editBookPayload = { ...params.editParams };
    editBookPayload.data.authors = authorsIdList;

    if (params.courseType?.length) {
        await courseTypeCreationHelper(params.courseType);
    }
    if (params.courseTypeIds?.length) {
        await LibService.deleteCourseType(params.courseTypeIds);
    }

    const res = await LibService.editBook(editBookPayload);
    if (params.file && params.fileId) {
        await LibService.editBookFile({
            id: params.fileId,
            data: {
                book: res.id,
                file: params.file,
            },
        });
    }
    if (params.file && !params.fileId) {
        await LibService.createBookFile({
            book: res.id,
            file: params.file,
        });
    }
    if (!params.file && params.fileId) {
        await LibService.deleteBookFile(params.fileId);
    }
    return res;
});
export const deleteBookAction = createAsyncThunk('library/deleteBook', async (book: TBook) => {
    await LibService.deleteBook(book.id);
    return { id: book.id, courseId: book.courseTypeBooks[0].course.id, typeId: book.courseTypeBooks[0].type.id };
});
export const getBooksListAction = createAsyncThunk('library/getBooksList', async (params: TGetBooksListPayload) => {
    return await LibService.getBooksList(params);
});
export const getBookByIdAction = createAsyncThunk('library/getBookById', async (id: number) => {
    return await LibService.getBookById(id);
});

//КРУД ПО АВТОРАМ
export const createAuthorAction = createAsyncThunk('library/createAuthor', async (params: TAuthorMainInfo[]) => {
    return await LibService.createAuthor(params);
});
export const editAuthorAction = createAsyncThunk('library/editAuthor', async (params: TBookAuthors[]) => {
    return await LibService.editAuthor(params);
});
export const deleteAuthorAction = createAsyncThunk('library/deleteAuthor', async (ids: number[]) => {
    await LibService.deleteAuthor(ids);

    return await LibService.getAuthorsList();
});
export const getAuthorsListAction = createAsyncThunk('library/getAuthorsList', (params?: TGetAuthorsListPayload) => {
    return LibService.getAuthorsList(params);
});

//КРУД ПО ФАЙЛАМ
export const createBookFileAction = createAsyncThunk('library/createBookFile', async (params: TBookFilePayload) => {
    return await LibService.createBookFile(params);
});
export const editBookFileAction = createAsyncThunk('library/editBookFile', async (params: TBookFileEditPayload) => {
    return await LibService.editBookFile(params);
});
export const deleteBookFileAction = createAsyncThunk('library/deleteBookFile', async (id: number) => {
    return await LibService.deleteBookFile(id);
});

const authorsCreationHelper = async (authorsList: string[]): Promise<number[]> => {
    const authorsToCreate: TAuthorMainInfo[] = [];
    const authorsIdList: number[] = [];
    authorsList.forEach((item) => {
        if (!Number(item)) {
            const authorInfo = item.split(' ');
            const list: string[] = [];

            authorInfo.forEach((item) => {
                if (item.includes('.')) {
                    const splited = item.replace(/\.$/gm, '').split('.');
                    splited.forEach((item) => list.push(`${item}.`));
                } else {
                    list.push(item);
                }
            });

            authorsToCreate.push({
                firstName: list[1],
                patronymic: list[2] || '',
                lastName: list[0],
            });
        } else {
            authorsIdList.push(+item);
        }
    });
    if (authorsToCreate.length) {
        const createdAuthors = await LibService.createAuthor(authorsToCreate);
        if (createdAuthors.length) {
            authorsIdList.push(...createdAuthors.map((item) => item.id));
        }
    }

    return authorsIdList;
};
const courseTypeCreationHelper = async (list: { id: number; course: number; type: number; book: number }[]) => {
    const courseTypeToCreate: TCreateCourseTypePayload[] = [];
    const courseTypeToEdit: TEditCourseTypePayload[] = [];
    list?.forEach((item) => {
        if (!item?.id) {
            courseTypeToCreate.push({
                course: item.course,
                type: item.type,
                book: item.book,
            });
        } else {
            courseTypeToEdit.push(item);
        }
    });
    if (courseTypeToCreate.length) {
        await LibService.createCourseType(courseTypeToCreate);
    }
    if (courseTypeToEdit.length) {
        await LibService.editCourseType(courseTypeToEdit);
    }
};

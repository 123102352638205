import { HARD_MODAL_ENUMS } from 'src-new/components/layouts/Modals/Modals.config';
import { useQueryPopupsParams } from 'src-new/hooks';
import { TCreateQuestionQuery } from 'types/lessonTask';

import { TCreateQuestionParams, TCreateQuestionPopup } from './useCreateQuestionPopup.types';

const useCreateQuestionPopup = (): TCreateQuestionPopup => {
    const Popup = useQueryPopupsParams();

    const getQuery = (params: TCreateQuestionParams) => {
        const {
            type = 'CREATE_QUESTION',
            purpose = 'create',
            isTrainer,
            assignment,
            lessonId,
            courseGroupId,
            taskId,
            num,
            subjectId,
        } = params;

        const query: TCreateQuestionQuery = {
            type: HARD_MODAL_ENUMS[type],
            lessonId,
            subjectId,
            courseGroupId,
            purpose,
            num,
        };

        if (taskId) {
            query.taskId = taskId;
        }
        if (isTrainer) {
            query.isTrainer = isTrainer;
        }
        if (assignment) {
            query.assignment = assignment;
        }

        return query;
    };

    const openCreateQuestionPopup = (params: TCreateQuestionParams) => {
        Popup.openPopup(getQuery(params));
    };

    const closeLastCreateQuestionPopup = () => {
        Popup.closeLastPopup();
    };

    const replaceLastCreateQuestionPopup = (params: TCreateQuestionParams) => {
        Popup.replaceLastPopup(getQuery(params));
    };

    const isCurrent = Popup.isCurrentPopup(HARD_MODAL_ENUMS.CREATE_QUESTION);

    return {
        isCurrent,
        openCreateQuestionPopup,
        closeLastCreateQuestionPopup,
        replaceLastCreateQuestionPopup,
    };
};

export default useCreateQuestionPopup;

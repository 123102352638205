import { LearningMaterialsContent } from 'api/services/materials';
import { ContentMaterialTypeEnum } from 'types/materials';

const sortMaterials = (materials: LearningMaterialsContent[]): LearningMaterialsContent[] =>
    [...materials].sort((currentMaterial, nextMaterial) => (currentMaterial.order > nextMaterial.order ? 1 : 0));

export const filterMaterials = (
    materials: LearningMaterialsContent[],
    exercisesWithCorrectAnswers: number[],
): LearningMaterialsContent[] => {
    const sortedMaterials = sortMaterials(materials);
    const filteredMaterials: LearningMaterialsContent[] = [];

    let needAddMaterials = true;
    sortedMaterials.forEach((material) => {
        if (!needAddMaterials) {
            return;
        }

        filteredMaterials.push(material);

        if (material.typeOfContent === ContentMaterialTypeEnum.exercise) {
            if (!exercisesWithCorrectAnswers.includes(material.id)) {
                needAddMaterials = false;
            }
        }
    });

    return filteredMaterials;
};

import { api } from 'api';
import { AxiosError } from 'axios';

export const deleteAssignment = (id: number): Promise<{ id: number }> => {
    return api
        .delete(`/assignment/delete/${id}/`)
        .then(() => ({ id }))
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const typeQuestions = [
    {
        title: 'Вопрос-тестовый',
        description: 'автопроверка',
        type: 'multiple',
    },
    {
        title: 'Тестовый, с текстовым ответом',
        description: 'автопроверка',
        type: 'open',
    },
    {
        title: 'Открытый вопрос - эссе',
        description: 'ручная проверка',
        type: 'detailed',
    },
    {
        title: 'Верно или нет',
        description: 'автопроверка',
        type: 'binary',
    },
    {
        title: 'Заполните пробелы',
        description: 'автопроверка',
        type: 'insert-words',
    },
    // {
    //     title: 'Классификация',
    //     description: 'автопроверка',
    //     type: 'binary',
    // },
];

import { codifierPageData } from 'constants/cypress/codifier-page';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Dots, Folders, LittleArrow, Plus } from '@lms-elements/icons';
import { useOuterClickField } from 'hooks/useOuterClickField';

import { CodifierEventType } from 'components/Codifier/Codifier';
import { getGradesOptions } from 'components/Codifier/utils';
import { DropdownWithActions } from 'components/DropdownWithActions';
import { ThemeTooltip } from 'components/ThemeTooltip';

import './CodifierDropdownGrade.scss';

const cnCodifierDropdownGrade = cn('codifier_dropdown_grade');

interface CodifierDropdownGradeProps {
    id: number;
    numberGrade: number;
    sectionsCount?: number;
    onDeleteClick?: (type: CodifierEventType, id: number) => void;
    onCreateClick?: (id: number, values: Record<string, { title: string; order: string }>) => void;
    onEditClick?: (id: number, values: Record<string, { title: string }>) => void;
    onSelect?: (type: CodifierEventType, id: number) => void;
    handleCopyClick?: () => void;
    nextSection?: number;
    isEditable?: boolean;
    isOpenedInit?: boolean;
    isHighlighted?: boolean;
    skillsCodifier?: boolean;
    parallels?: {
        grade: number;
    }[];
    disabled?: boolean;
    canHover?: boolean;
    handleCanHover?: () => void;
    index: number;
    canDelete?: boolean;
    canReplace?: boolean;
    needHideBlue?: boolean;
}

export const CodifierDropdownGrade: React.FC<CodifierDropdownGradeProps> = ({
    id,
    numberGrade,
    onCreateClick,
    onEditClick,
    handleCopyClick,
    sectionsCount = 0,
    onDeleteClick,
    onSelect,
    nextSection = 1,
    children,
    disabled = false,
    isEditable = true,
    isOpenedInit = false,
    isHighlighted = false,
    skillsCodifier = true,
    canHover = true,
    handleCanHover,
    parallels,
    index,
    canDelete = true,
    canReplace = true,
    needHideBlue = false,
}) => {
    const [isButtonsVisible, setIsButtonsVisible] = useState(false);
    const [isOpened, setIsOpened] = useState(isOpenedInit);
    const [isCreated, setIsCreated] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [isEditedForm, setIsEditedForm] = useState(false);
    const [isAdded, setIsAdded] = useState(false);

    const gradesAvailable = useMemo(() => {
        if (parallels) {
            return getGradesOptions(parallels?.map((parallel) => parallel.grade));
        }

        return [];
    }, [parallels]);

    const tooltipRef = useRef(null);
    const hideTooltip = useCallback(() => {
        setIsButtonsVisible(false);
        setIsEdited(false);
        if (handleCanHover) {
            handleCanHover();
        }
    }, [handleCanHover]);
    useOuterClickField(tooltipRef, hideTooltip);

    const grades = useMemo(
        () =>
            gradesAvailable
                ? [...gradesAvailable, `${numberGrade} класс`].sort((prev, next) => parseInt(prev) - parseInt(next))
                : [`${numberGrade} класс`],
        [gradesAvailable, numberGrade],
    );

    const handleDeleteClick = useCallback(() => {
        if (onDeleteClick && id) {
            onDeleteClick(CodifierEventType.PARALLEL, id);
        }
    }, [id, onDeleteClick]);

    const handleHoverButtons = useCallback(
        (value) => {
            if (canHover) {
                setIsButtonsVisible(value);
            }
        },
        [canHover],
    );

    const handleHeaderClick = useCallback(() => {
        if (!disabled) {
            if (!needHideBlue) setIsOpened(!isOpened);
            if (onSelect) {
                onSelect(CodifierEventType.PARALLEL, id);
            }
        }
    }, [disabled, needHideBlue, isOpened, onSelect, id]);

    const handleCreateButtonClick = useCallback(() => {
        if (!isEdited && !isEditedForm) {
            setIsCreated(!isCreated);
            if (handleCanHover) {
                handleCanHover();
            }
            setIsButtonsVisible(false);
        }
    }, [isCreated, handleCanHover, isEditedForm, isEdited]);

    const handleEditButtonClick = useCallback(() => {
        if (!isEditedForm && !isCreated) {
            setIsEdited(!isEdited);
            if (handleCanHover) {
                handleCanHover();
            }
        }
    }, [isEdited, handleCanHover, isEditedForm, isCreated]);

    const handleEditFormClick = useCallback(() => {
        setIsEditedForm((prev) => !prev);
        if (handleCanHover && !canHover && !isEdited) {
            handleCanHover();
        }
        setIsButtonsVisible(false);
        setIsEdited(false);
    }, [canHover, handleCanHover, isEdited]);

    const handleAddFormClick = useCallback(
        (values: Record<string, { title: string; order: string }>) => {
            if (onCreateClick) {
                onCreateClick(id, values);
            }
            setIsAdded(true);
        },
        [id, onCreateClick],
    );

    const handleEditFormSubmit = useCallback(
        (values: Record<string, { title: string }>) => {
            if (onEditClick) {
                onEditClick(id, values);
            }
        },
        [id, onEditClick],
    );

    const correctPlaceholder = useMemo(
        () => (skillsCodifier ? `${nextSection}. Мета-предметная область: введите название` : `Название`),
        [skillsCodifier, nextSection],
    );

    useEffect(() => {
        if (!(isEdited || isCreated || isEditedForm)) {
            if (disabled && isOpened && !isAdded) {
                setIsOpened(false);
            }
            if (!disabled && isAdded) {
                setIsOpened(true);
                setIsAdded(false);
            }
        }
    }, [disabled, isOpened, isEdited, isCreated, isEditedForm, isAdded]);

    return (
        <>
            <div
                className={cnCodifierDropdownGrade('container', {
                    highlighted: isHighlighted || isEdited || isCreated || isEditedForm,
                })}
                onMouseEnter={handleHoverButtons.bind(null, true)}
                onMouseLeave={handleHoverButtons.bind(null, false)}
                data-cy={codifierPageData.getGradeContainer(index)}
            >
                <div
                    className={cnCodifierDropdownGrade('header', {
                        highlighted: isHighlighted || isEdited || isCreated || isEditedForm,
                        disabled,
                    })}
                    onClick={handleHeaderClick}
                >
                    <LittleArrow
                        className={cnCodifierDropdownGrade('arrow', {
                            arrow_is_opened: isOpened || isEdited || isCreated || isEditedForm,
                            disabled,
                        })}
                    />
                    <Folders
                        className={cnCodifierDropdownGrade('folders', {
                            opened: (isOpened || isEdited || isCreated || isEditedForm) && !needHideBlue,
                            disabled,
                        })}
                    />
                    <h4>{numberGrade} класс</h4>
                </div>
                <div className={cnCodifierDropdownGrade('buttons')}>
                    {(isButtonsVisible || isEdited) && isEditable && (
                        <>
                            <div className={cnCodifierDropdownGrade('button')} onClick={handleCreateButtonClick}>
                                <Plus />
                            </div>
                            <div className={cnCodifierDropdownGrade('button')} onClick={handleEditButtonClick}>
                                <Dots />
                            </div>
                        </>
                    )}
                    {isEdited && (
                        <div ref={tooltipRef}>
                            <ThemeTooltip
                                isTheme={false}
                                canEdit={grades.length > 1}
                                handleCopyClick={handleCopyClick}
                                handleDeleteClick={handleDeleteClick}
                                handleEditClick={handleEditFormClick}
                                canDelete={canDelete}
                                canReplace={canReplace}
                            />
                        </div>
                    )}
                </div>
            </div>
            {(isOpened || isCreated || isEditedForm) && (
                <div
                    className={cnCodifierDropdownGrade('children_container', {
                        opened: isCreated || (isOpened && sectionsCount != 0),
                    })}
                >
                    {isCreated && (
                        <DropdownWithActions
                            isNeedTextField={true}
                            name={'createSection'}
                            placeholder={correctPlaceholder}
                            onChangeAdd={handleAddFormClick}
                            onClickCancel={handleCreateButtonClick}
                            textButtonAdd={'Создать'}
                            title={!skillsCodifier ? 'Раздел' : undefined}
                            noSkills={!skillsCodifier}
                            initialValue={{ createSection: { title: ``, order: String(nextSection) } }}
                        />
                    )}
                    {isEditedForm && (
                        <DropdownWithActions
                            name={'editParallel'}
                            placeholder={correctPlaceholder}
                            initialValue={{
                                editParallel: { title: `${numberGrade} класс`, order: String(nextSection) },
                            }}
                            options={grades}
                            onChangeAdd={handleEditFormSubmit}
                            onClickCancel={handleEditFormClick}
                            textButtonAdd={'Изменить'}
                        />
                    )}
                    {isOpened && children}
                </div>
            )}
        </>
    );
};

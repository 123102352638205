import { isListExist } from '@frontend-modules/frontend-utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCourseGroupStudents } from 'api/services/courseGroups';
import { getStudentsTasksPreview } from 'api/services/homework';
import { getLessonDetails, getLessons, handleLesson } from 'api/services/lesson';
import { getLessonSchedule, handleLessonSchedule, LessonScheduleResponse } from 'api/services/lessonSchedule';
import { payloadTimeFormatter } from 'src-new/utils';
import { AssignmentProgressPreview } from 'types/assignmentProgress';
import { CourseLesson, CreateLessonParams, PatchLessonParams, ResponseLesson } from 'types/lesson';
import {
    PatchLessonScheduleParams,
    PostLessonScheduleParams,
    THandleLessonScheduleProps,
    THandleLessonSchedules,
} from 'types/lessonSchedules';
import { Student } from 'types/students';

import { sortLessons } from 'utils/sortLessons';

export const getLessonsAction = createAsyncThunk(
    'courseLessons/getLessons',
    async ({ topicId }: { topicId?: number } = {}) => sortLessons(await getLessons(topicId)),
);

export const getLessonsAndHomeworksAction = createAsyncThunk(
    'courseLessons/getLessonsAndHomeworks',
    async (params: {
        topicId: number;
        courseGroupId?: number;
        isStudent?: boolean;
        lessonIds?: number[];
    }): Promise<{
        homeworksMap: Record<number, AssignmentProgressPreview[]>;
        lessons: ResponseLesson[];
        lessonSchedules: LessonScheduleResponse[];
    }> => {
        const { topicId, courseGroupId, isStudent, lessonIds } = params;
        const resultLessons: ResponseLesson[] = [];
        let lessonSchedules: LessonScheduleResponse[] = [];

        const sortedLessons = sortLessons(await getLessons(topicId, lessonIds));

        resultLessons.push(...sortedLessons);

        const students: Student[] = [];

        if (courseGroupId) {
            if (!isStudent) {
                students.push(...(await getCourseGroupStudents(courseGroupId).then((res) => res.students)));
            }
            if (sortedLessons.length) {
                lessonSchedules = await getLessonSchedule(
                    sortedLessons.map((lesson) => lesson.id),
                    courseGroupId,
                );
            }
        }

        if (!resultLessons.length) {
            return {
                homeworksMap: {},
                lessonSchedules: [],
                lessons: resultLessons,
            };
        }

        if (!students.length) {
            return {
                homeworksMap: {},
                lessonSchedules: lessonSchedules,
                lessons: resultLessons,
            };
        }
        return await getStudentsTasksPreview(
            sortedLessons.map((lesson) => lesson.id),
            students.map((student) => student.id),
        )
            .then((homeworks) => {
                const homeworksMap = homeworks.reduce((result, homework) => {
                    const lessonId = homework.lesson;

                    if (lessonId) {
                        result[lessonId] = result[lessonId] ? result[lessonId].concat([homework]) : [homework];
                    }

                    return result;
                }, {} as { [lessonId: number]: AssignmentProgressPreview[] });

                return {
                    homeworksMap,
                    lessonSchedules,
                    lessons: resultLessons,
                };
            })
            .catch(() => {
                return {
                    homeworksMap: {},
                    lessonSchedules,
                    lessons: resultLessons,
                };
            });
    },
);

export const createLessonAction = createAsyncThunk(
    'courseLessons/createLesson',
    async ({ newLesson, courseId }: { newLesson: CreateLessonParams; courseId: number }) => {
        const { order, title, topic, description, isTest } = newLesson;

        const lesson = await handleLesson({
            create: {
                title,
                topic,
                order,
                description,
                isTest,
            },
        });
        return {
            ...lesson.create,
            courseId,
            order,
        };
    },
);

export type UpdateLessonParams = {
    changedLesson: PatchLessonParams & { order?: number };
    schedulesParams?: {
        createLessonSchedules: PostLessonScheduleParams[];
        updateLessonSchedules: PatchLessonScheduleParams[];
        deleteLessonSchedule: number[];
    };
    courseGroup?: number;
};

export const handleLessonScheduleAction = createAsyncThunk(
    'courseLessons/handleLessonSchedule',
    async ({
        lessonParams,
        topic,
        courseId,
    }: {
        lessonParams: UpdateLessonParams;
        topic?: number;
        courseId: number;
    }) => {
        const { changedLesson, schedulesParams, courseGroup } = lessonParams;
        const { id, title, order, description, isTest, type: lessonType } = changedLesson;

        if (courseGroup) {
            const payload: THandleLessonScheduleProps = {
                courseGroup: courseGroup,
                lesson: id,
                order: lessonType === 'common' ? undefined : order,
                topic,
                title: lessonType === 'common' ? undefined : title,
                description: lessonType === 'common' ? undefined : description,
            };
            if (
                schedulesParams &&
                (isListExist(schedulesParams.deleteLessonSchedule) ||
                    isListExist(schedulesParams.createLessonSchedules) ||
                    isListExist(schedulesParams.updateLessonSchedules))
            ) {
                const lessonSchedules: THandleLessonSchedules = {};
                const createdLessonSchedules = schedulesParams.createLessonSchedules[0];
                const updatedLessonSchedules = schedulesParams.updateLessonSchedules[0];
                const deletedLessonSchedules = schedulesParams.deleteLessonSchedule[0];

                if (createdLessonSchedules) {
                    //@ts-ignore
                    lessonSchedules.create = {
                        //@ts-ignore
                        type: createdLessonSchedules?.type,
                        datetimeStart: createdLessonSchedules?.datetimeStart
                            ? payloadTimeFormatter(createdLessonSchedules.datetimeStart)
                            : undefined,
                        datetimeEnd:
                            createdLessonSchedules.datetimeEnd && updatedLessonSchedules?.type !== 'online_lesson'
                                ? payloadTimeFormatter(createdLessonSchedules.datetimeEnd)
                                : undefined,
                    };
                }
                if (updatedLessonSchedules?.id) {
                    //@ts-ignore
                    lessonSchedules.update = {
                        id: updatedLessonSchedules.id,
                        //@ts-ignore
                        type: updatedLessonSchedules?.type,
                        datetimeStart: updatedLessonSchedules.datetimeStart
                            ? payloadTimeFormatter(updatedLessonSchedules.datetimeStart)
                            : undefined,
                        datetimeEnd:
                            updatedLessonSchedules.datetimeEnd && updatedLessonSchedules?.type !== 'online_lesson'
                                ? payloadTimeFormatter(updatedLessonSchedules.datetimeEnd)
                                : undefined,
                    };
                }
                if (deletedLessonSchedules) {
                    lessonSchedules.delete = deletedLessonSchedules;
                }

                const handleLessonScheduleParams =
                    lessonType === 'reserve'
                        ? {
                              ...payload,
                              lessonSchedules: lessonSchedules,
                          }
                        : {
                              lesson: payload.lesson,
                              courseGroup: payload.courseGroup,
                              lessonSchedules: lessonSchedules,
                          };
                const res = await handleLessonSchedule(handleLessonScheduleParams);
                const deleted = res.lessonSchedules.delete
                    ? {
                          ...res,
                          lessonSchedules: res.lessonSchedules.delete,
                      }
                    : undefined;
                const updated = res.lessonSchedules.update
                    ? {
                          ...res,
                          lessonSchedules: res.lessonSchedules.update,
                      }
                    : undefined;
                const created = res.lessonSchedules.create
                    ? {
                          ...res,
                          lessonSchedules: res.lessonSchedules.create,
                      }
                    : undefined;

                return {
                    lessonSchedule: {
                        deletedLessonSchedules: deleted ? { ...deleted, order } : undefined,
                        updatedLessonSchedules: updated ? { ...updated, order } : undefined,
                        createdLessonSchedules: created ? { ...created, order } : undefined,
                    },
                };
            }

            const updatedLessonSchedules = await handleLessonSchedule(payload);
            return {
                lessonSchedule: {
                    updatedLessonSchedules: { ...updatedLessonSchedules, order },
                },
            };
        } else {
            const lessons = await handleLesson({
                update: {
                    id,
                    title,
                    order,
                    description,
                    topic,
                    isTest,
                },
            });

            return {
                lesson: { ...lessons.update, order },
            };
        }
    },
);

export const deleteLessonAction = createAsyncThunk(
    'courseLessons/deleteLesson',
    async ({ lessonId, courseId }: { lessonId: number; courseId: number }) => {
        const res = await handleLesson({
            delete: lessonId,
        });
        return {
            lessonId: res.delete,
            courseId,
        };
    },
);

export const getLessonDataAction = createAsyncThunk(
    'courseLesson/getLessonData',
    async (lessonId: number) => await getLessonDetails(lessonId),
);

export const changeLessonTopicAction = createAsyncThunk(
    'courseLessons/changeLessonTopic',
    async ({ lessonParams, newTopic }: { lessonParams: CourseLesson; newTopic: number }) => {
        const { id, title, description, isTest } = lessonParams;
        return await handleLesson({
            update: {
                id,
                title,
                description,
                topic: newTopic,
                isTest,
            },
        });
    },
);

import {
    ActionCreatorWithoutPayload,
    ActionCreatorWithPayload,
    createSlice,
    PayloadAction,
    SliceCaseReducers,
} from '@reduxjs/toolkit';
import { AnswerAttemptPostResponse } from 'api/services/answerAttempt';
import moment from 'moment';
import {
    createAttemptAction,
    createQuestionFileAction,
    getAnswerAttemptAction,
    getAnswerAttemptByProgressAction,
    patchAnswerAttemptAction,
    postEvaluateAction,
    updateAttemptDraft,
} from 'store/actions/answerAttempt';
import { normalizeAnswerAttempt } from 'store/normalizers/answerAttempt';
import {
    AnswerAttemptData,
    EssayAnswerData,
    InsertWordsAnswerData,
    TestAnswerData,
    TextAnswerData,
    TrueFalseAnswerData,
} from 'types/answerAttempt';
import { FetchStatus } from 'types/api';

import { getAttemptQuantityInfo } from 'utils/answerAttempt';

export type QuestionFiles = {
    [questionId: number]: {
        files: {
            id: number;
            index: number;
        }[];
    };
};

export interface AnswerAttemptsState {
    postAnswerAttemptStatus: FetchStatus;
    patchAnswerAttemptStatus: FetchStatus;
    createQuestionFileStatus: FetchStatus;
    patchDraftStatus: FetchStatus;
    postEvaluateStatus: FetchStatus;
    fetchStatus: FetchStatus;
    createAttemptStatus: FetchStatus;
    data: AnswerAttemptPostResponse[] | null;
    error: unknown;
    lastAttempt?: number;
    attemptsQuantity?: number;
    questionFiles: QuestionFiles;
    draftAttempId?: number;
    draftData?: AnswerAttemptData & { id?: number };
    draftTime?: string;
}

const initialState: AnswerAttemptsState = {
    postAnswerAttemptStatus: FetchStatus.INITIAL,
    patchAnswerAttemptStatus: FetchStatus.INITIAL,
    createQuestionFileStatus: FetchStatus.INITIAL,
    postEvaluateStatus: FetchStatus.INITIAL,
    patchDraftStatus: FetchStatus.INITIAL,
    fetchStatus: FetchStatus.INITIAL,
    createAttemptStatus: FetchStatus.INITIAL,
    questionFiles: {},
    data: null,
    error: null,
};

interface DeleteFileParams {
    questionId: number;
    fileId: number;
}

interface DraftParams {
    attemptId?: number;
    assignmentProgress: number;
    answers: {
        question: number;
        file?: File[];
        files?: number[];
        data: TextAnswerData | TrueFalseAnswerData | EssayAnswerData | InsertWordsAnswerData | TestAnswerData;
        isEssay?: boolean | undefined;
    }[];
}

const answerAttemptsSlice = createSlice<AnswerAttemptsState, SliceCaseReducers<AnswerAttemptsState>>({
    name: 'answersAttempts',
    initialState,
    reducers: {
        resetAnswerAttemptsState() {
            return initialState;
        },
        resetAnswerAttemptsStatus(state) {
            state.fetchStatus = FetchStatus.INITIAL;
        },
        resetAnswerAttemptsData(state) {
            state.data = [];
            state.error = null;
            state.postAnswerAttemptStatus = FetchStatus.INITIAL;
        },
        deleteQuestionFile(state, action: PayloadAction<DeleteFileParams>) {
            if (state.questionFiles[action.payload.questionId]?.files) {
                state.questionFiles[action.payload.questionId].files = state.questionFiles[
                    action.payload.questionId
                ].files.filter(({ id }) => id !== action.payload.fileId);
            }
        },
        saveDraft(state, { payload }: PayloadAction<DraftParams>) {
            const { attemptId, answers, assignmentProgress } = payload;

            state.draftData = {
                assignmentProgress,
                id: attemptId ? attemptId : state.draftData?.id,
                answers: answers.map(({ question, data, files = [] }) => {
                    return {
                        question,
                        data,
                        files: Array.from(
                            new Set([
                                ...((data as EssayAnswerData)?.file ?? []).map(Number),
                                ...files,
                                ...(state.questionFiles[question]?.files ?? []).map(({ id }) => id),
                            ]),
                        ),
                    };
                }),
            };
            state.patchDraftStatus = FetchStatus.INITIAL;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(postEvaluateAction.pending, (state) => {
                state.postEvaluateStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(postEvaluateAction.fulfilled, (state) => {
                state.postEvaluateStatus = FetchStatus.FETCHED;
                state.data = [];
                state.draftAttempId = undefined;
                state.draftData = undefined;
                state.draftTime = undefined;
                state.patchDraftStatus = FetchStatus.INITIAL;
                state.fetchStatus = FetchStatus.INITIAL;
                state.error = null;
            })
            .addCase(postEvaluateAction.rejected, (state, { error }) => {
                state.postEvaluateStatus = FetchStatus.ERROR;
                state.data = null;
                state.error = error;
            })
            .addCase(getAnswerAttemptAction.pending, (state) => {
                state.fetchStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getAnswerAttemptAction.fulfilled, (state, { payload }) => {
                state.fetchStatus = FetchStatus.FETCHED;
                state.data = normalizeAnswerAttempt(payload);
                const { lastAttempt, attemptsQuantity } = getAttemptQuantityInfo(payload);

                state.lastAttempt = lastAttempt;
                state.attemptsQuantity = attemptsQuantity;
                state.error = null;
            })
            .addCase(getAnswerAttemptAction.rejected, (state, { error }) => {
                state.fetchStatus = FetchStatus.ERROR;
                state.data = [];
                state.error = error;
            })
            .addCase(getAnswerAttemptByProgressAction.pending, (state) => {
                state.fetchStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getAnswerAttemptByProgressAction.fulfilled, (state, { payload }) => {
                state.fetchStatus = FetchStatus.FETCHED;
                state.data = normalizeAnswerAttempt(payload);
                const { lastAttempt, attemptsQuantity } = getAttemptQuantityInfo(payload);

                state.lastAttempt = lastAttempt;
                const lastAttemptData = state.data?.[lastAttempt];
                if (lastAttemptData?.isDraft) {
                    state.draftTime = moment(lastAttemptData.dateUpdate).format('LT');
                    state.draftAttempId = lastAttemptData.id;
                } else {
                    state.draftTime = undefined;
                    state.draftAttempId = undefined;
                }
                state.attemptsQuantity = attemptsQuantity;
                state.error = null;
            })
            .addCase(getAnswerAttemptByProgressAction.rejected, (state, { error }) => {
                state.fetchStatus = FetchStatus.ERROR;
                state.data = [];
                state.error = error;
            })
            .addCase(createQuestionFileAction.pending, (state) => {
                state.createQuestionFileStatus = FetchStatus.FETCHING;
            })
            .addCase(createQuestionFileAction.fulfilled, (state, { payload }) => {
                state.createQuestionFileStatus = FetchStatus.FETCHED;

                if (state.questionFiles[payload.questionId]?.files) {
                    state.questionFiles[payload.questionId].files = [
                        ...state.questionFiles[payload.questionId].files,
                        { id: payload.file.id, index: payload.index },
                    ];
                } else {
                    state.questionFiles[payload.questionId] = {
                        files: [{ id: payload.file.id, index: payload.index }],
                    };
                }
            })
            .addCase(createQuestionFileAction.rejected, (state, { error }) => {
                state.createQuestionFileStatus = FetchStatus.ERROR;
            })
            .addCase(patchAnswerAttemptAction.pending, (state) => {
                state.patchAnswerAttemptStatus = FetchStatus.FETCHING;
            })
            .addCase(patchAnswerAttemptAction.fulfilled, (state) => {
                state.patchAnswerAttemptStatus = FetchStatus.FETCHED;
                state.questionFiles = initialState.questionFiles;
            })
            .addCase(createAttemptAction.rejected, (state, { error }) => {
                state.createAttemptStatus = FetchStatus.ERROR;
                state.error = error;
            })
            .addCase(createAttemptAction.pending, (state) => {
                state.createAttemptStatus = FetchStatus.FETCHING;
            })
            .addCase(createAttemptAction.fulfilled, (state, { payload }) => {
                state.createAttemptStatus = FetchStatus.FETCHED;
            })
            .addCase(updateAttemptDraft.pending, (state) => {
                if (state.draftData) {
                    state.patchDraftStatus = FetchStatus.FETCHING;
                }
            })
            .addCase(updateAttemptDraft.fulfilled, (state, { payload }) => {
                if (payload && state.draftData) {
                    state.patchDraftStatus = FetchStatus.FETCHED;
                    state.error = null;
                    state.draftData = {
                        ...state.draftData,
                        id: payload.id,
                    };
                    state.draftTime = moment(payload.dateUpdate).format('LT');
                    state.draftAttempId = payload.id;
                }
            })
            .addCase(updateAttemptDraft.rejected, (state) => {
                state.patchDraftStatus = FetchStatus.ERROR;
            });
    },
});

const resetAnswerAttemptsData = answerAttemptsSlice.actions.resetAnswerAttemptsData as ActionCreatorWithoutPayload;
const resetAnswerAttemptsState = answerAttemptsSlice.actions.resetAnswerAttemptsState as ActionCreatorWithoutPayload;
const resetAnswerAttemptsStatus = answerAttemptsSlice.actions.resetAnswerAttemptsStatus as ActionCreatorWithoutPayload;
const deleteQuestionFile = answerAttemptsSlice.actions.deleteQuestionFile as ActionCreatorWithPayload<DeleteFileParams>;
const saveDraft = answerAttemptsSlice.actions.saveDraft as ActionCreatorWithPayload<DraftParams>;

export { deleteQuestionFile, resetAnswerAttemptsData, resetAnswerAttemptsState, resetAnswerAttemptsStatus, saveDraft };
export const answerAttemptsReducer = answerAttemptsSlice.reducer;

import { ELinkSeparatingLine, ESupportedLinkTypes } from '@fsd-features/AddKinescopeVideo/AddKinescopeVideo.config';

export const transformVideoUrlToShow = (link?: string): string => {
    switch (true) {
        case link?.includes(ESupportedLinkTypes.kinescope):
            return link ? addEmbed(link, ELinkSeparatingLine.kinescope) : '';
        default:
            return link ?? '';
    }
};

const addEmbed = (link: string, separator: string): string => {
    return link.replace(separator, `${separator}/embed`);
};

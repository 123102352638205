import React, { useState } from 'react';
import { cn } from '@bem-react/classname';
import { Button, ButtonViewEnum } from '@lms-elements/atomic';
import { Calendar, ConfigProvider, Select } from 'antd';
import { ThemeConfig } from 'antd/lib/config-provider/context';
import locale from 'antd/lib/date-picker/locale/ru_RU';
import { monthRu } from 'App/constants';
import dayjs, { Dayjs } from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { AppSelect } from 'src-new/ui';

import 'dayjs/plugin/localeData';
import 'dayjs/locale/ru';

import { IAppCalendar } from './AppCalendar.types';

import './AppCalendar.scss';

dayjs.locale('ru');
dayjs.extend(updateLocale);
dayjs.updateLocale('ru', {
    months: monthRu,
});

const CnAppCalendar = cn('app-calendar');

export const AppCalendar: React.FC<IAppCalendar> = (props) => {
    const { buttonTitle, style, disabledDate, defaultValue, initialDate, onDateSelect, onButtonAction } = props;
    const [calendarValue, setCalendarValue] = useState<Dayjs | undefined>();
    const onButtonClick = () => {
        if (calendarValue) {
            onButtonAction?.(calendarValue);
        }
    };

    const onSelect = (date: Dayjs) => {
        setCalendarValue(date);
        onDateSelect?.(date);
    };

    const disableButton = !calendarValue || disabledDate?.(calendarValue);

    const theme: ThemeConfig = {
        token: {
            colorPrimary: '#0088E9',
            colorTextBase: '#252232',
        },
    };

    return (
        <div className={CnAppCalendar()} style={style}>
            <div className={CnAppCalendar('element')}>
                <ConfigProvider prefixCls={CnAppCalendar()} theme={theme}>
                    <Calendar
                        locale={locale}
                        onSelect={onSelect}
                        fullscreen={false}
                        defaultValue={defaultValue}
                        disabledDate={disabledDate}
                        value={initialDate}
                        headerRender={({ value, onChange }) => {
                            const start = 0;
                            const end = 12;
                            const monthOptions = [];

                            let current = value.clone();
                            const localeData = value.localeData();
                            const months = [];
                            for (let i = 0; i < 12; i++) {
                                current = current.month(i);
                                months.push(localeData.monthsShort(current));
                            }

                            for (let i = start; i < end; i++) {
                                monthOptions.push(
                                    <Select.Option key={i} value={i} className="month-item">
                                        {months[i]}
                                    </Select.Option>,
                                );
                            }

                            const year = value.year();
                            const month = value.month();
                            const options = [];
                            for (let i = year - 10; i < year + 10; i += 1) {
                                options.push(
                                    <Select.Option key={i} value={i} className="year-item">
                                        {i}
                                    </Select.Option>,
                                );
                            }
                            return (
                                <div className={CnAppCalendar('line')}>
                                    <AppSelect
                                        size={'small'}
                                        isBoldText
                                        dropdownMatchSelectWidth={false}
                                        value={year}
                                        handleSelect={({ value: newYear }) => {
                                            const now = value.clone().year(Number(newYear));
                                            onChange(now);
                                        }}
                                    >
                                        {options}
                                    </AppSelect>
                                    <AppSelect
                                        size={'small'}
                                        isBoldText
                                        dropdownMatchSelectWidth={false}
                                        value={month}
                                        handleSelect={({ value: newMonth }) => {
                                            const now = value.clone().month(Number(newMonth));
                                            onChange(now);
                                        }}
                                    >
                                        {monthOptions}
                                    </AppSelect>
                                </div>
                            );
                        }}
                    />
                </ConfigProvider>
            </div>
            {buttonTitle && (
                <div className={CnAppCalendar('element')}>
                    <Button disabled={disableButton} view={ButtonViewEnum.action} size={'l'} onClick={onButtonClick}>
                        {buttonTitle}
                    </Button>
                </div>
            )}
        </div>
    );
};

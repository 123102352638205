import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    AnswerAttemptPostResponse,
    getAnswerAttemptInfo,
    getAnswerAttemptsByProgress,
    patchAnswerAttempt,
    postAnswerAttempt,
} from 'api/services/answerAttempt';
import { postEvaluate } from 'api/services/assignment/postEvaluate';
import { createFile } from 'api/services/file';
import { AnswerAttemptsState } from 'store/reducers/answerAttempts';
import {
    AnswerAttemptData,
    EssayAnswerData,
    InsertWordsAnswerData,
    TestAnswerData,
    TextAnswerData,
    TrueFalseAnswerData,
} from 'types/answerAttempt';

import { fetchStudentHomeworksAction, getStudentTaskById } from './studentTasks';

export interface PatchDraftActionParams {
    tasks: AnswerAttemptData;
    id: string;
}

type PatchAnswerAttemptActionParams = {
    answersData: AnswerAttemptData;
};
export const patchAnswerAttemptAction = createAsyncThunk(
    'homework/patchAnswerAttempt',
    ({ answersData }: PatchAnswerAttemptActionParams, { dispatch }) => {
        const filteredAnswers = deleteEmptyAnswers(answersData);

        const essayQuestions = filteredAnswers.answers
            ?.filter(({ isEssay, answerId }) => isEssay && answerId)
            .map(({ answerId, data, files = [] }) => ({
                answerId: answerId as number,
                files: [...(data as EssayAnswerData).file.map(Number), ...files],
            }));

        return Promise.all(
            essayQuestions.map(({ answerId, files }) => {
                return patchAnswerAttempt({ answerId, newAnswerAttemptParams: { files } });
            }),
        ).then(() => {
            void dispatch(getStudentTaskById(filteredAnswers.assignmentProgress));
            void dispatch(getAnswerAttemptByProgressAction(filteredAnswers.assignmentProgress));
        });
    },
);

export const getAnswerAttemptAction = createAsyncThunk('homework/getAnswerAttempt', async (assignment: number) => {
    return await getAnswerAttemptInfo(assignment);
});

export const getAnswerAttemptByProgressAction = createAsyncThunk(
    'homework/getAnswerAttemptByProgress',
    async (assignmentProgress: number) => {
        return await getAnswerAttemptsByProgress(assignmentProgress);
    },
);

interface EvaluateParams {
    id: number;
    lessonId: number;
}

type Config = {
    state: { answerAttempts: AnswerAttemptsState };
};

export const updateAttemptDraft = createAsyncThunk<AnswerAttemptPostResponse | undefined, void, Config>(
    'answerAttempt/updateDraft',
    (_, { getState }) => {
        const { draftData } = getState().answerAttempts;
        const filteredAnswers = draftData ? deleteEmptyAnswers(draftData) : draftData;

        if (filteredAnswers?.assignmentProgress && filteredAnswers?.answers) {
            return postAnswerAttempt({
                assignmentProgress: filteredAnswers.assignmentProgress,
                answers: filteredAnswers.answers,
            });
        }
    },
);

export const postEvaluateAction = createAsyncThunk<void, EvaluateParams, Config>(
    'answersAttempts/postEvaluate',
    async ({ id, lessonId }: EvaluateParams, { dispatch }) => {
        return dispatch(updateAttemptDraft())
            .then(async () => {
                await postEvaluate(String(id));
            })
            .then(() => {
                void dispatch(fetchStudentHomeworksAction({ lessonId: lessonId }));
                void dispatch(getStudentTaskById(id));
            });
    },
);

export const createQuestionFileAction = createAsyncThunk(
    'answersAttempts/createQuestionFile',
    ({ file, questionId, index, onUploadProgress }: CreateFileParams) => {
        return createFile({ file, onUploadProgress })
            .then((file) => {
                setTimeout(() => {
                    onUploadProgress?.(101);
                }, 500);

                return {
                    questionId,
                    file,
                    index,
                };
            })
            .catch(() => {
                onUploadProgress?.(-1);

                throw new Error(`Не удалось загрузить файл ${file.name}!`);
            });
    },
);

type CreateFileParams = {
    questionId: number;
    file: File;
    index: number;
    onUploadProgress?: (percent: number) => void;
};

const deleteEmptyAnswers = (
    answersData: AnswerAttemptData | (AnswerAttemptData & { id?: number | undefined }),
): AnswerAttemptData | (AnswerAttemptData & { id?: number | undefined }) => {
    const filteredAnswers = answersData.answers
        .map((item) => {
            const isAnswerFilesListEmpty = item?.files ? !item?.files?.length : false;
            const isAnswerFileListEmpty = item?.file ? !item?.file?.length : false;

            const isEssayAnswerTextDataEmpty = (item.data as EssayAnswerData)?.text
                ? !(item.data as EssayAnswerData)?.text?.trim()
                : false;
            const isEssayAnswerFileDataEmpty = (item.data as EssayAnswerData)?.file
                ? !(item.data as EssayAnswerData)?.file?.length
                : false;

            const isTextAnswerDataEmpty = (item.data as TextAnswerData)?.selectedOption
                ? !(item.data as TextAnswerData)?.selectedOption?.trim()
                : false;

            const insertWordsAnswerDataEmpty = (item.data as InsertWordsAnswerData)?.selectedOptions
                ?.map((word, index) => {
                    if (word && word?.selectedWord && !word?.selectedWord?.trim()) {
                        return index;
                    }
                })
                .filter((item) => item !== undefined);

            if (insertWordsAnswerDataEmpty?.length) {
                //если вопрос с типом "insertWords" и есть слова, состоящие только из пробелов, то удаляем их

                return {
                    ...item,
                    data: {
                        // @ts-ignore
                        selectedOptions: item.data.selectedOptions.filter((item, index) => {
                            if (!insertWordsAnswerDataEmpty.includes(index)) {
                                return item;
                            }
                        }),
                    },
                };
            } else if (isEssayAnswerTextDataEmpty && (!isEssayAnswerFileDataEmpty || !isAnswerFilesListEmpty)) {
                //если вопрос с типом "Essay" и загружен файл, но поле текст - пустое, то удаляем его

                return {
                    ...item,
                    data: {
                        file: (item.data as EssayAnswerData)?.file,
                    },
                };
            } else if (
                (!isEssayAnswerTextDataEmpty || !isEssayAnswerFileDataEmpty) &&
                (!isAnswerFileListEmpty || !isAnswerFilesListEmpty) &&
                !isTextAnswerDataEmpty
            ) {
                //если вопрос не содержит проблем в типах "Essay", "InsertWords" и "Text", то ничего с ним не делаем
                return item;
            }
            return undefined;
        })
        .filter((item) => item !== undefined);

    return {
        ...answersData,
        answers: filteredAnswers as {
            file?: File[];
            files?: number[];
            question: number;
            answerId?: number;
            data: EssayAnswerData | InsertWordsAnswerData | TestAnswerData | TextAnswerData | TrueFalseAnswerData;
            isEssay?: boolean;
        }[],
    };
};
export const createAttemptAction = createAsyncThunk(
    'homework/createAttempt',
    async (assignmentProgress: number) => {
        return await postAnswerAttempt({assignmentProgress, answers: []});
    },
);

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AppText } from '@frontend-modules/ui-kit';
import { Tag, TreeSelect } from 'antd';
import { SmallArrowIcon } from 'assets';
import { CustomTagProps } from 'rc-select/lib/BaseSelect';
import { TTreeSelectorProps } from 'src-new/components/lesson-task';
import { E_COLORS } from 'src-new/constants';

import { getTreeSelectorTagBreadcrumbs } from './TreeSelector.utils';

import './style.scss';
const MIN_HEIGHT = 500;
const MIN_WIDTH = 700;

export const TreeSelector: React.FC<TTreeSelectorProps> = (props) => {
    const {
        label,
        treeData,
        placeholder = '',
        defaultValue,
        notFoundContent,
        additionalContent,
        expandOnTreeTitles,
        isResponsivePosition = false,
        handleTreeSelect,
    } = props;
    const [initialValues, setInitialValues] = useState<string[]>([]);
    const [wrapContainerHeight, setWrapContainerHeight] = useState<number>(MIN_HEIGHT);

    const wrapContainer = useRef<HTMLDivElement>(null);

    const TagRender = (props: CustomTagProps) => {
        const { label, value, closable, onClose } = props;

        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };
        return (
            <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose}>
                <div className={'tree-selector__tag-label'}>
                    {getTreeSelectorTagBreadcrumbs({ treeData, breadcrumbs: value })} <br /> {label}
                </div>
            </Tag>
        );
    };
    useEffect(() => {
        setInitialValues(defaultValue ?? []);
    }, [defaultValue]);

    const handleTreeSelectInitial = useCallback(
        (list: string[]) => {
            handleTreeSelect(list);
            const height =
                wrapContainer?.current?.clientHeight && list.length ? wrapContainer?.current?.clientHeight : MIN_HEIGHT;
            setWrapContainerHeight(height < MIN_HEIGHT ? MIN_HEIGHT : height);
        },
        [wrapContainer?.current?.clientHeight],
    );

    return (
        <div className={'tree-selector'}>
            <AppText text={label} textStyle={'DesktopFootNotes'} color={E_COLORS.light_grey_color_2} />
            {additionalContent}
            <div ref={wrapContainer}>
                <TreeSelect
                    getPopupContainer={(trigger) => trigger?.parentElement}
                    placeholder={placeholder}
                    multiple
                    value={initialValues}
                    maxTagTextLength={20}
                    suffixIcon={<SmallArrowIcon />}
                    onChange={handleTreeSelectInitial}
                    treeData={treeData}
                    showSearch={false}
                    treeExpandAction={expandOnTreeTitles ? 'click' : false}
                    tagRender={TagRender}
                    notFoundContent={notFoundContent}
                    dropdownStyle={{ minHeight: MIN_HEIGHT, height: wrapContainerHeight, minWidth: MIN_WIDTH }}
                    treeLine
                    treeCheckable
                    listHeight={wrapContainerHeight}
                    popupClassName={`tree-selector__popup ${
                        wrapContainerHeight <= MIN_HEIGHT && isResponsivePosition && !initialValues.length
                            ? 'up'
                            : 'down'
                    }`}
                    size={'large'}
                />
            </div>
        </div>
    );
};

import { IPagination } from '@fsd-shared/models/app';
import {
    IMaterialsMeta,
    ITrainingCountInfo,
    ITrainingProgress,
    ITrainingQuestionInfo,
    ITrainingSearchBlockResponse,
} from '@fsd-shared/models/materials';
import { createSlice } from '@reduxjs/toolkit';
import { QuestionSearchBarFieldsValues } from 'containers/QuestionSearchBar';
import { IQuestion } from 'containers/QuestionSearchResult/QuestionSearchResult';
import { ISearchBlock } from 'containers/QuestionSearchResult/types';

import {
    getMaterialsMetaAction,
    getTrainingCountAction,
    getTrainingProgressAction,
    getTrainingQuestionsAction,
    getTrainingSearchBlocksQuestionsAction,
} from './materialsActions';
import { normalizeCorrectAnswerToQuestion, normalizeMaterialsMeta, normalizeQuestion } from './normalizers';

export interface IMaterialsState {
    meta?: IMaterialsMeta;
    training?: {
        exercise?: Partial<ITrainingProgress> & {
            isQuestionLoading?: boolean;
            isCheckQuestionLoading?: boolean;
        };
        questionList?: {
            list?: Array<ITrainingQuestionInfo>;
            pagination?: IPagination;
            isLoading?: boolean;
            isLoadingMore?: boolean;
        };
        trainingCountInfo?: {
            info?: ITrainingCountInfo;
            isLoading?: boolean;
        };
        trainingSearchBlocksData?: {
            isLoading?: boolean;
            initSearchBlocks?: Array<ITrainingSearchBlockResponse>;
            trainerSearches?: {
                singleQuestions: IQuestion[];
                rightSearchBlocks: ISearchBlock[];
                leftSearchBlocks: ISearchBlock[];
            };
            initExcludesIds?: Array<number>;
            numSearch?: number;
            filters?: QuestionSearchBarFieldsValues[];
        };
    };
}

const initialState: IMaterialsState = {};

const materialsSlice = createSlice({
    name: 'materials',
    initialState,
    reducers: {
        setEditTraining: (state, { payload }) => {
            const {
                filters: newFilters,
                numSearch: newNumSearch,
                singleQuestions: newSingleQuestions,
                rightSearchBlocks: newRightSearchBlocks,
                leftSearchBlocks: newLeftSearchBlocks,
            } = payload || {};
            const { numSearch: oldNumSearch, filters: oldFilters } = state.training?.trainingSearchBlocksData || {};
            const {
                singleQuestions: oldSingleQuestions,
                rightSearchBlocks: oldRightSearchBlocks,
                leftSearchBlocks: oldLeftSearchBlocks,
            } = state.training?.trainingSearchBlocksData?.trainerSearches || {};
            state.training = {
                ...state.training,
                trainingSearchBlocksData: {
                    ...state.training?.trainingSearchBlocksData,
                    filters: newFilters ?? oldFilters,
                    numSearch: newNumSearch ?? oldNumSearch,
                    trainerSearches: {
                        singleQuestions: newSingleQuestions ?? oldSingleQuestions,
                        rightSearchBlocks: newRightSearchBlocks ?? oldRightSearchBlocks,
                        leftSearchBlocks: newLeftSearchBlocks ?? oldLeftSearchBlocks,
                    },
                },
            };
        },
        initTrainerData: (state) => {
            state.training = {
                ...state.training,
                trainingSearchBlocksData: {
                    initSearchBlocks: [],
                    trainerSearches: {
                        singleQuestions: [],
                        rightSearchBlocks: [],
                        leftSearchBlocks: [],
                    },
                    initExcludesIds: [],
                },
            };
        },
        resetTrainerData: (state) => {
            state.training = {
                ...state.training,
                trainingSearchBlocksData: undefined,
            };
        },
        resetExercise: (state) => {
            state.training = {
                ...state.training,
                exercise: undefined,
            };
        },
    },
    /**
     *
     * @param builder
     */
    extraReducers: (builder) => {
        builder.addCase(getMaterialsMetaAction.fulfilled, (state, { payload }) => {
            state.meta = normalizeMaterialsMeta(payload);
        });
        builder.addCase(getTrainingProgressAction.pending, (state, { meta }) => {
            const { check } = meta.arg;
            const isCheck = !!check;
            state.training = {
                ...state.training,
                exercise: {
                    ...state.training?.exercise,
                    isQuestionLoading: true,
                    isCheckQuestionLoading: isCheck,
                },
            };
        });
        builder.addCase(getTrainingProgressAction.fulfilled, (state, { payload }) => {
            const { question, statistics, answer } = payload;
            const normalizedQuestion = normalizeQuestion(question);
            const newQuestion = answer
                ? normalizeCorrectAnswerToQuestion(answer.data, normalizedQuestion)
                : normalizedQuestion;
            state.training = {
                ...state.training,
                exercise: {
                    question: newQuestion,
                    statistics: statistics,
                    isQuestionLoading: false,
                    isCheckQuestionLoading: false,
                },
            };
        });
        builder.addCase(getTrainingProgressAction.rejected, (state) => {
            state.training = {
                ...state?.training,
                exercise: {
                    ...state.training?.exercise,
                    isQuestionLoading: false,
                    isCheckQuestionLoading: false,
                },
            };
        });
        builder.addCase(getTrainingQuestionsAction.pending, (state, { meta }) => {
            const isMore = meta.arg.isLoadingMore;

            state.training = {
                ...state.training,
                questionList: {
                    ...state.training?.questionList,
                    isLoading: !isMore,
                    isLoadingMore: isMore,
                },
            };
        });
        builder.addCase(getTrainingQuestionsAction.fulfilled, (state, { payload }) => {
            const isMore = state?.training?.questionList?.isLoadingMore;

            let newQuestions = payload?.results ?? [];
            if (isMore) {
                newQuestions = [...(state?.training?.questionList?.list ?? []), ...newQuestions];
            }

            state.training = {
                ...state.training,
                questionList: {
                    ...state.training?.questionList,
                    pagination: payload.pagination,
                    list: newQuestions,
                    isLoadingMore: false,
                    isLoading: false,
                },
            };
        });
        builder.addCase(getTrainingQuestionsAction.rejected, (state) => {
            state.training = {
                ...state?.training,
                questionList: {
                    ...state.training?.questionList,
                    isLoading: false,
                    isLoadingMore: false,
                },
            };
        });
        builder.addCase(getTrainingCountAction.pending, (state) => {
            state.training = {
                ...state?.training,
                trainingCountInfo: { ...state?.training?.trainingCountInfo, isLoading: true },
            };
        });
        builder.addCase(getTrainingCountAction.fulfilled, (state, { payload }) => {
            state.training = {
                ...state?.training,
                trainingCountInfo: { info: payload, isLoading: false },
            };
        });
        builder.addCase(getTrainingCountAction.rejected, (state) => {
            state.training = {
                ...state?.training,
                trainingCountInfo: { ...state?.training?.trainingCountInfo, isLoading: false },
            };
        });
        builder.addCase(getTrainingSearchBlocksQuestionsAction.pending, (state) => {
            state.training = {
                ...state.training,
                trainingSearchBlocksData: {
                    ...state.training?.trainingSearchBlocksData,
                    isLoading: true,
                },
            };
        });
        builder.addCase(getTrainingSearchBlocksQuestionsAction.fulfilled, (state, { payload }) => {
            state.training = {
                ...state.training,
                trainingSearchBlocksData: {
                    ...state.training?.trainingSearchBlocksData,
                    isLoading: false,
                    initSearchBlocks: payload?.initSearchBlocks || {},
                    trainerSearches: {
                        singleQuestions: payload?.singleQuestions || [],
                        rightSearchBlocks: payload?.searchBlocks || [],
                        leftSearchBlocks: payload?.searchBlocks || [],
                    },
                    initExcludesIds: payload?.initExcludesIds || [],
                },
            };
        });
        builder.addCase(getTrainingSearchBlocksQuestionsAction.rejected, (state) => {
            state.training = {
                ...state.training,
                trainingSearchBlocksData: {
                    ...state.training?.trainingSearchBlocksData,
                    isLoading: false,
                },
            };
        });
    },
});
export const { setEditTraining, resetExercise, initTrainerData, resetTrainerData } = materialsSlice.actions;

export const materialsReducer = materialsSlice.reducer;

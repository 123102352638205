import { createAsyncThunk } from '@reduxjs/toolkit';
import { ssoPasswordReset, ssoPasswordResetConfirm, ssoPasswordResetValidate } from 'api/services/token';

export const changePasswordAction = createAsyncThunk(
    'passwordRecovery/changePassword',
    async (params: { token: string; newPassword: string }) => {
        return await ssoPasswordResetConfirm({
            token: params.token,
            password: params.newPassword,
        });
    },
);

export const validateResetToken = createAsyncThunk('passwordRecovery/validateToken', async (token: string) => {
    return await ssoPasswordResetValidate(token);
});

export const resetPasswordAction = createAsyncThunk(
    'registration/postResetPassword',
    async (params: { email: string; realm: string }) => {
        return await ssoPasswordReset(params);
    },
);

import React, { useCallback } from 'react';
import { cn } from '@bem-react/classname';
import { Basket } from '@lms-elements/icons';

import { IInsertWordOptionProps } from './InsertWordOption.types';

import './InsertWordOption.scss';

const cx = cn('insert-word-popup-option');

export const InsertWordOption = React.forwardRef<HTMLInputElement, IInsertWordOptionProps>(
    ({ onEnter, onDelete, onSave, startValue, ...props }, ref) => {
        const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => onSave(e.target.value), [onSave]);

        return (
            <div className={cx()}>
                <input
                    spellCheck
                    {...props}
                    type="text"
                    className={cx('input')}
                    value={startValue}
                    onChange={onChange}
                    onKeyDown={onEnter}
                    ref={ref}
                />
                <button type="button" className={cx('delete')} onClick={onDelete}>
                    <Basket />
                </button>
            </div>
        );
    },
);

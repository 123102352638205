import { Parallel } from 'api/services/studentGroups/getStudentGroupStatisticsParallels';
import { StudentGroupStatistics } from 'api/services/studentGroups/getStudentsGroupStatistics';
import { groupNumberRegExp, letterRegExp } from 'containers/ActiveClasses/ActiveClasses.constants';
import { sortClassLetters, sortParallels } from 'containers/ActiveClasses/utils';
import { StudentGroupStatistic } from 'types/reporting';

interface StudentGroupStatisticParams {
    studentGroupStatisticsParallels: Parallel[];
    studentGroupStatistics: StudentGroupStatistics[];
}

export const studentGroupStatisticCells = ({
    studentGroupStatisticsParallels,
    studentGroupStatistics,
}: StudentGroupStatisticParams): StudentGroupStatistic => {
    const parallels = new Map<string, { classCount: number; studentCount: number }>();
    const classLetters = new Map<string, { classCount: number; studentCount: number }>();
    studentGroupStatisticsParallels.map(({ studentGroup }) => {
        studentGroup.map((group) => {
            const cellGroup = studentGroupStatistics.find(({ id }) => id === group.id);
            const parallel = (groupNumberRegExp.exec(cellGroup?.title ?? '') ?? [''])[0];
            if (parallels.has(parallel)) {
                const newClassCount = (parallels.get(parallel)?.classCount ?? 0) + 1;
                const newStudentCount = (parallels.get(parallel)?.studentCount ?? 0) + Number(group.studentsCount);
                parallels.set(parallel, { classCount: newClassCount, studentCount: newStudentCount });
            } else {
                parallels.set(parallel, { classCount: 1, studentCount: Number(group.studentsCount) });
            }
            const classLetter = (letterRegExp.exec(cellGroup?.title ?? '') ?? [''])[0];

            if (classLetters.has(classLetter)) {
                const newClassCount = (classLetters.get(classLetter)?.classCount ?? 0) + 1;
                const newStudentCount =
                    (classLetters.get(classLetter)?.studentCount ?? 0) + Number(group.studentsCount);
                classLetters.set(classLetter, { classCount: newClassCount, studentCount: newStudentCount });
            } else {
                classLetters.set(classLetter, { classCount: 1, studentCount: Number(group.studentsCount) });
            }
        });
    });
    return {
        parallels: Array.from(parallels, ([parallel, classes]) => ({ parallel, classes })).sort(sortParallels),
        classLetters: Array.from(classLetters, ([classLetter, classes]) => ({ classLetter, classes })).sort(
            sortClassLetters,
        ),
    };
};

import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import moment from 'moment';
import { getMonitoringInfoAction, getNextMonitoringInfoAction } from 'store/actions/monitoring';
import { FetchStatus } from 'types/api';
import { MonitoringProps } from 'types/monitoring';

export interface MonitoringState {
    getMonitoringInfoStatus: FetchStatus;
    getNextMonitoringInfoStatus: FetchStatus;
    monitoringInfo: MonitoringProps | null;
    nextMonitoringInfo: string | null;
    error: unknown;
}

const initialState: MonitoringState = {
    getMonitoringInfoStatus: FetchStatus.INITIAL,
    getNextMonitoringInfoStatus: FetchStatus.INITIAL,
    nextMonitoringInfo: null,
    monitoringInfo: null,
    error: null,
};

const monitoringSlice = createSlice<MonitoringState, SliceCaseReducers<MonitoringState>>({
    name: 'monitoring',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMonitoringInfoAction.pending, (state) => {
            state.getMonitoringInfoStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getMonitoringInfoAction.fulfilled, (state, { payload }) => {
            state.getMonitoringInfoStatus = FetchStatus.FETCHED;
            state.monitoringInfo = {
                ...payload,
                data: payload.data.sort((a, b) => moment(a.datetimeStart).diff(b.datetimeStart)),
            };
            state.nextMonitoringInfo = payload.next;
            state.error = null;
        });
        builder.addCase(getMonitoringInfoAction.rejected, (state, { error }) => {
            state.getMonitoringInfoStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder
            .addCase(getNextMonitoringInfoAction.pending, (state) => {
                state.getMonitoringInfoStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getNextMonitoringInfoAction.fulfilled, (state, { payload }) => {
                if (payload === null) {
                    state.getMonitoringInfoStatus = FetchStatus.FETCHED;
                    state.error = null;
                    return;
                }
                state.monitoringInfo = {
                    ...payload,
                    data:
                        state.monitoringInfo?.data.concat(
                            payload.data.sort((a, b) => moment(a.datetimeStart).diff(b.datetimeStart)),
                        ) ?? [],
                };
                state.nextMonitoringInfo = payload.next;
                state.getMonitoringInfoStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(getNextMonitoringInfoAction.rejected, (state, { error }) => {
                state.getMonitoringInfoStatus = FetchStatus.ERROR;
                state.error = error;
            });
    },
});

export const resetMonitoringState = monitoringSlice.actions.reset as ActionCreatorWithoutPayload;
export const monitoringReducer = monitoringSlice.reducer;

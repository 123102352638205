import React, { useMemo } from 'react';
import { getUid } from '@frontend-modules/frontend-utils';
import { AppTable } from '@frontend-modules/ui-kit';

import { tableConfigProviderTheme } from './TableBorderless.config';
import { TTableBorderlessProps } from './TableBorderless.types';

import './TableBorderless.style.scss';
export const TableBorderless: React.FC<TTableBorderlessProps> = (props) => {
    const { columns, rows, align = 'center', isNeedNumbering = false, loading } = props;

    const renderColumns = useMemo(() => {
        return columns.map(({ title, name }) => {
            return {
                title,
                dataIndex: name,
                key: name,
                align,
                className: 'table-borderless__col',
            };
        });
    }, [columns, align]);
    const renderData = useMemo(() => {
        return rows.map((item) => {
            return {
                ...item,
                key: getUid(),
            };
        });
    }, [rows]);

    return (
        <div className={'table-borderless'}>
            <AppTable
                isNeedNumbering={isNeedNumbering}
                columns={renderColumns}
                dataSource={renderData}
                tableConfigProviderTheme={tableConfigProviderTheme}
                pagination={false}
                loading={loading}
                rowClassName={'table-borderless__row'}
            />
        </div>
    );
};

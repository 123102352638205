import React from 'react';
import { cn } from '@bem-react/classname';
import { Modal } from 'antd';
import { CloseIcon } from 'assets';
import { AppButton, AppText } from 'src-new/ui';

import { IAppModalProps } from './AppDeclineAcceptModal.types';

import './AppDeclineAcceptModal.scss';

const CnAppDeclineAcceptModal = cn('app-decline-accept-modal');

const AppDeclineAcceptModal: React.FC<IAppModalProps> = (props) => {
    const {
        isShow,
        setShow,
        title,
        text,
        onAcceptClick,
        onDeclineClick,
        acceptButtonTitle,
        declineButtonTitle,
        isNegative,
    } = props;
    const isShowButtons = acceptButtonTitle && declineButtonTitle;

    const onCancel = () => {
        setShow?.(false);
    };

    return (
        <Modal
            prefixCls={CnAppDeclineAcceptModal()}
            {...props}
            onCancel={onCancel}
            open={isShow}
            title={''}
            footer={null}
            closeIcon={<CloseIcon />}
            width={420}
        >
            {title && (
                <div className={CnAppDeclineAcceptModal('title')}>
                    <AppText fontStyle={'Title'} text={title} />
                </div>
            )}
            {text && (
                <div className={CnAppDeclineAcceptModal('text')}>
                    <AppText fontStyle={'fontBody'} text={text} />
                </div>
            )}
            {isShowButtons && (
                <div className={CnAppDeclineAcceptModal('buttons')}>
                    {acceptButtonTitle && (
                        <AppButton
                            mainColor={isNegative ? 'red' : undefined}
                            text={acceptButtonTitle}
                            onButtonClick={() => onAcceptClick?.()}
                        />
                    )}
                    {declineButtonTitle && (
                        <AppButton
                            type={'default'}
                            text={declineButtonTitle}
                            onButtonClick={() => onDeclineClick?.()}
                        />
                    )}
                </div>
            )}
        </Modal>
    );
};

export default AppDeclineAcceptModal;

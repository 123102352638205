import React, { useCallback, useEffect, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import { FormulasIcon } from '@lms-elements/icons';
import { EditorState } from 'draft-js';
import { getEntityRange, getSelectionEntity } from 'draftjs-utils';
import { LatexPopUp } from 'src-new/components/lms-elements/CustomEditor/components/Latex/LatexPopUp';
import { OptionButton } from 'src-new/components/lms-elements/CustomEditor/components/OptionButton';
import { EntityTypes, ICustomOptionProps } from 'src-new/components/lms-elements/CustomEditor/types/shared.types';
import { addEntity, removeLatexEntity } from 'src-new/components/lms-elements/CustomEditor/utils';

import './LatexOption.scss';

const LatexOptionCn = cn('latex-option');

export const LatexOption: React.FC<ICustomOptionProps> = ({ editorState, onChange }) => {
    const [isPopUpShown, setIsPopUpShown] = useState(false);

    const togglePopup = useCallback(() => setIsPopUpShown((prev) => !prev), []);

    const [latex, setLatex] = useState('');

    const insertLatex = useCallback(
        () =>
            addEntity({
                editorState,
                onChange,
                text: latex,
                entityType: EntityTypes.LATEX,
                mutability: 'MUTABLE',
            }),
        [editorState, latex, onChange],
    );

    const [entity, setEntity] = useState<string | undefined>();

    useEffect(() => {
        const selectionState = editorState.getSelection();
        const startOffset = selectionState.getStartOffset();
        const currentEntity = getSelectionEntity(editorState);

        if (selectionState.getHasFocus() && startOffset !== 0 && currentEntity) {
            const contentState = editorState.getCurrentContent();
            const range = getEntityRange(editorState, currentEntity);

            if (startOffset !== range.start) {
                setTimeout(() => {
                    setIsPopUpShown((prev) => {
                        if (!currentEntity) {
                            return prev;
                        }

                        if (contentState.getEntity(currentEntity).getType() === EntityTypes.LATEX) {
                            const entityRange = getEntityRange(editorState, currentEntity);
                            setEntity(currentEntity);
                            setLatex(entityRange.text);
                            return true;
                        }

                        setLatex('');
                        return false;
                    });
                });
            }
        }
    }, [editorState, isPopUpShown]);

    const ref = useRef<HTMLInputElement>(null);

    const focusPopupInput = useCallback(
        () =>
            setTimeout(() => {
                ref.current?.focus();
            }),
        [],
    );

    useEffect(() => {
        if (isPopUpShown) {
            focusPopupInput();
        }
    }, [isPopUpShown, focusPopupInput]);

    const handleSave = useCallback(
        (entity?: string) => {
            if (latex === '' && entity) {
                removeLatexEntity({ editorState, onChange, entity });
                setIsPopUpShown(false);
                setEntity(undefined);
                return;
            }

            insertLatex();
            setIsPopUpShown(false);
            setEntity(undefined);
            setLatex('');
        },
        [editorState, insertLatex, latex, onChange],
    );

    const handleCancelClick = useCallback(() => {
        const currentEntity = getSelectionEntity(editorState);
        const contentState = editorState.getCurrentContent();
        const selection = editorState.getSelection();

        if (currentEntity && contentState.getEntity(currentEntity).getType() === EntityTypes.LATEX) {
            const entityRange = getEntityRange(editorState, currentEntity);
            const selectionWithOffset = selection.merge({
                focusOffset: entityRange.end + 1,
                anchorOffset: entityRange.end + 1,
            });
            onChange(EditorState.acceptSelection(editorState, selectionWithOffset));
        }

        setIsPopUpShown(false);
        setEntity(undefined);
    }, [editorState, onChange]);

    const handlePopupOutsideClick = useCallback(() => {
        const currentEntity = getSelectionEntity(editorState);
        const contentState = editorState.getCurrentContent();
        const isOnLatex = currentEntity && contentState.getEntity(currentEntity).getType() === EntityTypes.LATEX;

        if (!isOnLatex) {
            setIsPopUpShown(false);
            setEntity(undefined);
        }
    }, [editorState]);

    return (
        <div className={LatexOptionCn('container')}>
            <OptionButton active={isPopUpShown} onClick={togglePopup}>
                <FormulasIcon className={LatexOptionCn('icon', { active: isPopUpShown })} />
            </OptionButton>
            {isPopUpShown && (
                <div className={LatexOptionCn('popup-container')}>
                    <LatexPopUp
                        entity={entity}
                        latex={latex}
                        onOutsideClick={handlePopupOutsideClick}
                        setLatex={setLatex}
                        setIsShown={handleCancelClick}
                        onSaveClick={handleSave}
                        onCancelClick={handleCancelClick}
                        ref={ref}
                    />
                </div>
            )}
        </div>
    );
};

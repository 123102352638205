import axios from 'axios';

const downloadTxtFileFromUrlByLink = async (url: string): Promise<void> => {
    const response = await axios.get(url, { responseType: 'blob' });
    const fileName = url?.split('/')?.reverse()?.[0] ?? 'file';
    const blob = new Blob([response.data], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export { downloadTxtFileFromUrlByLink };

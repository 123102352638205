export const getTabId = (tabName: string, isStudent?: boolean): number => {
    const lowercaseName = tabName?.toLowerCase();
    switch (lowercaseName) {
        case 'materials':
            return 0;
        case 'academic_performance':
            return isStudent ? -1 : 1;
        case 'online_lesson':
            return 2;
        case 'tasks':
            return 3;
        case 'attendance':
            return 4;
        case 'additional_materials':
            return 5;
        case 'trainer':
            return 6;
        default:
            return -1;
    }
};

export const getTabLink = (tabId: number): string => {
    switch (tabId) {
        case 0:
            return 'materials';
        case 1:
            return 'academic_performance';
        case 2:
            return 'online_lesson';
        case 3:
            return 'tasks';
        case 4:
            return 'attendance';
        case 5:
            return 'additional_materials';
        case 6:
            return 'trainer';
        default:
            return 'materials';
    }
};

export const getQuerySearchParams = ({
    lessonId,
    subjectId,
    groupId,
}: {
    lessonId?: number;
    subjectId?: number;
    groupId?: number;
}): string => {
    const paramsArray = [];
    if (!!lessonId || !!subjectId || !!groupId) {
        if (lessonId) {
            paramsArray.push(`lesson=${lessonId}`);
        }
        if (subjectId) {
            paramsArray.push(`subject=${subjectId}`);
        }
        if (groupId) {
            paramsArray.push(`course_group=${groupId}`);
        }
        let link = '?';
        paramsArray.map((param, index) => {
            if (index > 0) {
                link += `&${param}`;
            } else {
                link += param;
            }
        });

        return link;
    }
    return '';
};

export const getQueryAcademicPerfomanceParams = ({
    courseId,
    subjectId,
    groupId,
}: {
    courseId?: number;
    subjectId?: number;
    groupId?: number;
}): string => {
    const paramsArray = [];
    if (!!courseId || !!subjectId || !!groupId) {
        if (courseId) {
            paramsArray.push(`course=${courseId}`);
        }
        if (subjectId) {
            paramsArray.push(`subject=${subjectId}`);
        }
        if (groupId) {
            paramsArray.push(`group=${groupId}`);
        }
        let link = '?';
        paramsArray.map((param, index) => {
            if (index > 0) {
                link += `&${param}`;
            } else {
                link += param;
            }
        });
        return link;
    }
    return '';
};

import {
    AssignmentLevels,
    ExecutionTimes,
    VerificationTypes,
} from 'src-new/components/lesson-task/LessonTaskPage.types';

export const EXECUTION_TIMES_MAPPING = {
    [ExecutionTimes.AT_HOME]: 'дома',
    [ExecutionTimes.IN_CLASS]: 'на уроке',
};

export const ASSIGNMENT_LEVELS_MAPPING = {
    [AssignmentLevels.ADMINISTRATIVE]: 'административное задание',
    [AssignmentLevels.TEACHER]: 'учительское задание',
};

export const VERIFICATION_TYPE_MAPPING = {
    [VerificationTypes.AUTO]: 'автоматическая проверка',
    [VerificationTypes.MANUAL]: 'ручная проверка',
};
export const TEST_LESSON_ID = 5;

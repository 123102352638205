import { getApiRequest, patchApiRequest } from 'api';

export type TAlert = {
    id: number;
    title: string;
    text: string;
};
export const getAlertsList = (): Promise<TAlert[]> => {
    return getApiRequest('/alert/');
};
export const setAlertRead = (id: number): Promise<TAlert> => {
    const data = {
        isRead: true,
    };
    return patchApiRequest(`/alert/partial-update/${id}/`, data);
};

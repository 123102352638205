import { IMaterialFile } from 'api/services/materials';
import { FileTypes, IUploadedFile } from 'types/file';
import { IMaterial, IMaterialDocument } from 'types/materials';

export const getTempFiles = (tempFiles: IMaterialFile[], materials: IMaterial[]): IUploadedFile[] => {
    return tempFiles.flatMap((tempMaterialFile) => {
        const material = materials.find((material) => material.id === tempMaterialFile.material);

        return tempMaterialFile.files
            .filter(
                (file) =>
                    material &&
                    !(
                        material.type === 'document' &&
                        !material.documentData.some((document) => (document as IMaterialDocument).id === file.id)
                    ),
            )
            .map((file) => ({
                id: file.id,
                file: file.fileUrl,
                materialId: tempMaterialFile.material,
                conferenceId: null,
                lessonId: null,
                type: FileTypes.COMMON,
            }));
    });
};

import React from 'react';
import { cn } from '@bem-react/classname';

import { IOptionButtonProps } from './OptionButton.types';

import './OptionButton.scss';

const CnOptionButton = cn('option-button');

export const OptionButton: React.FC<IOptionButtonProps> = ({ children, active = false, ...props }) => {
    return (
        <button {...props} type="button" className={CnOptionButton({ active })}>
            {children}
        </button>
    );
};

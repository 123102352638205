import { TTab } from 'src-new/components/new-subjects/popups/lesson-popup/LessonPopup.types';
import { parseQueryParamsToObject } from 'src-new/utils';

export const getTabLink = (tabId: number): TTab => {
    switch (tabId) {
        case 0:
            return 'materials';
        case 1:
            return 'academic_performance';
        case 2:
            return 'online_lesson';
        case 3:
            return 'tasks';
        case 4:
            return 'attendance';
        case 5:
            return 'progress';
        case 6:
            return 'additional_materials';
        case 7:
            return 'trainer';
        default:
            return 'materials';
    }
};
export const getTabId = (tabName: string, isStudent?: boolean): number => {
    const lowercaseName = tabName?.toLowerCase();
    switch (lowercaseName) {
        case 'materials':
            return 0;
        case 'academic_performance':
            return isStudent ? -1 : 1;
        case 'online_lesson':
            return 2;
        case 'tasks':
            return 3;
        case 'attendance':
            return 4;
        case 'progress':
            return 5;
        case 'additional_materials':
            return 6;
        case 'trainer':
            return 7;
        default:
            return -1;
    }
};
export const getTabFromUrl = (): TTab => {
    const locationSearch = location.search;
    const parsedQueries = parseQueryParamsToObject(locationSearch, 'modals')?.modals?.[0];

    //@ts-ignore
    return parsedQueries?.tab;
};

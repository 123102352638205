import { api, getApiRequest, patchApiRequest } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import {
    EssayQuestionData,
    InsertWordQuestionData,
    TestQuestionData,
    TextQuestionData,
    TrueFalseQuestionData,
} from 'types/question';
import { UserRoles } from 'types/user';

import { AnswersTypeEnum } from 'components/QuestionPopUp/QuestionPopUp.types';

import { DifficultyEnum } from './questionCreate';

export enum QuestionStatus {
    Published = 'published',
    NotPublished = 'not_published',
    OnRevision = 'on_revision',
}

export interface ResponseQuestion {
    id: number;
    text: string;
    content?: string;
    html?: string;
    isActual: boolean;
    difficulty: DifficultyEnum;
    status: QuestionStatus;
    author: {
        id?: number;
        firstName: string;
        lastName: string;
        patronymic: string | null;
        online?: boolean;
        lastSeen?: string;
        mainRole: string;
    };
    topics: { id: number }[];
    skills: {
        id: number;
        title: string;
    }[];
    multiply: boolean;
    answersType: AnswersTypeEnum;
    timestamp: string;
    data: TestQuestionData | InsertWordQuestionData | TrueFalseQuestionData | EssayQuestionData | TextQuestionData;
    revisions?: {
        id: number;
        comment: string;
        dateCreate: string;
    }[];
}

export const getQuestions = (options: string): Promise<ResponseQuestion[]> => {
    return api
        .get<never, AxiosResponse<ResponseQuestion[]>>(`/codifier/question/${options}`)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export interface PostQuestion {
    text: string;
    content?: string;
    html?: string;
    answersType: AnswersTypeEnum;
    difficulty: DifficultyEnum;
    maximumScore: number;
    topics: number[];
    skills: number[];
    data: TestQuestionData | InsertWordQuestionData | TrueFalseQuestionData | EssayQuestionData | TextQuestionData;
    fileIds?: number[];
    status?: QuestionStatus;
    isActual?: boolean;
    timestamp?: string;
}

export interface PartialUpdateQuestion {
    text?: string;
    answersType?: AnswersTypeEnum;
    difficulty?: DifficultyEnum;
    maximumScore?: number;
    status?: QuestionStatus;
    skills?: number[];
    topics?: number[];
    html?: string;
    data?: TestQuestionData | InsertWordQuestionData | TrueFalseQuestionData | EssayQuestionData | TextQuestionData;
    content?: string;
}

export interface ResponsePartialUpdateQuestion {
    id: number;
    text: string;
    answersType: AnswersTypeEnum;
    difficulty: DifficultyEnum;
    status: QuestionStatus;
    timestamp: string;
    html: string;
    data: TestQuestionData | InsertWordQuestionData | TrueFalseQuestionData | EssayQuestionData | TextQuestionData;
    content: string;
}

export interface PostQuestionResponse extends PostQuestion {
    id: number;
}

export interface PostQuestionFile {
    question?: number;
    file: File;
    onUploadProgress?: (percent: number) => void;
}

export interface FileLink {
    id: number;
    url: string;
}

export interface PatchQuestionFile extends FileLink {
    question?: number;
}

export const postQuestion = (value: PostQuestion): Promise<PostQuestionResponse> => {
    return api
        .post<Promise<PostQuestion>, AxiosResponse<PostQuestionResponse>>(`/codifier/question/create/`, {
            ...value,
        })
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export interface ResponseQuestionDetailed {
    id: number;
    text?: string;
    content?: string;
    html?: string;
    difficulty: DifficultyEnum;
    answersType: AnswersTypeEnum;
    status?: QuestionStatus;
    timestamp?: string;
    author?: {
        id?: number;
        firstName: string;
        lastName: string;
        patronymic: string;
        mainRole: string;
    };
    topics: {
        id: number;
        title: string;
    }[];
    subjects?: {
        id: number;
    }[];
    skills: {
        id: number;
        title: string;
    }[];
    data: TestQuestionData | InsertWordQuestionData | TrueFalseQuestionData | EssayQuestionData | TextQuestionData;
    revisions?: {
        id: number;
        comment: string;
        dateCreate: string;
    }[];
}

export const getQuestionDetailed = (id: number): Promise<ResponseQuestionDetailed> => {
    return api
        .get<never, AxiosResponse<ResponseQuestionDetailed>>(`/codifier/question/${id}/overview/`)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const putQuestion = (value: { id: number; question: PostQuestion }): Promise<PostQuestionResponse> => {
    return api
        .put<Promise<PostQuestion>, AxiosResponse<PostQuestionResponse>>(
            `/codifier/question/update/${value.id}/`,
            value.question,
        )
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

// @ts-ignore
export const patchQuestion = (value: { id: number; payload }): Promise<PostQuestionResponse> => {
    return api
        .patch<Promise<PostQuestion>, AxiosResponse<PostQuestionResponse>>(
            `/learning-material/partial-update/${value.id}/`,
            value.payload,
        )
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const partialUpdateQuestion = (params: {
    id: number;
    values: PartialUpdateQuestion;
}): Promise<ResponsePartialUpdateQuestion> =>
    patchApiRequest(`/codifier/question/partial-update/${params.id}/`, params.values);

export const deleteQuestion = (id: number): Promise<number> => {
    return api
        .delete<Promise<never>, AxiosResponse<never>>(`/codifier/question/delete/${id}/`)
        .then(() => id)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const postQuestionFile = (params: PostQuestionFile): Promise<FileLink> => {
    const config = {
        headers: {
            'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
            Accept: 'application/json',
        },
        onUploadProgress: function (progressEvent: { loaded: number; total: number }) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            params.onUploadProgress?.(percentCompleted);
        },
    };

    const data = new FormData();

    data.append('file', params.file);
    if (params.question) {
        data.append('question', String(params.question));
    }

    return api
        .post<Promise<FileLink>, AxiosResponse<FileLink>>(`/question-file/`, data, config)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const patchQuestionFile = (params: { id: number; question: number }): Promise<PatchQuestionFile> =>
    patchApiRequest(`question-file/partial-update/${String(params.id)}/`, { ...params });

export interface ResponseStudentQuestion {
    id: number;
    text: string;
    content?: string;
    html?: string;
    difficulty: DifficultyEnum;
    answersType: AnswersTypeEnum;
    answerOptionsQuantity: number;
    correctAnswerOptionsQuantity: number;
    randomOrder?: boolean;
    isActual: boolean;
    answerOptions?: {
        id: string;
        content: string;
        html?: string;
        text: string;
        order: number;
        acceptFile: boolean | null;
        acceptText: boolean | null;
    }[];
    multiply?: boolean;
    isTemplate: boolean;
    timestamp: string;
    acceptFile?: boolean;
    acceptText?: boolean;
}

export interface HistoryQuestion {
    user: string;
    datetime: string;
    action: string;
    userRoles: UserRoles[];
}

export const getHistoryQuestion = (id: number): Promise<HistoryQuestion[]> => {
    return getApiRequest(`/codifier/question/${id}/history/`);
};

import { paramsConfig } from './parseQueryParamsToObject.config';
import { IParseQueryParamsToObject, TParamsTypes } from './parseQueryParamsToObject.types';
/**
 * @description  - из location.search готовит объект с данными, если в URL будет JSON - автоматически распарсит
 * @param search - query строка
 * @param paramsType - параметры которые нужно обработать
 */
const parseQueryParamsToObject = (search: string, paramsType: TParamsTypes): IParseQueryParamsToObject => {
    const paramArr = search.split('&') || [];
    const params = paramsConfig[paramsType];
    paramArr?.forEach((param) => {
        const [key, val] = param.replace('?', '').split('=');
        try {
            //@ts-ignore
            params[key] = JSON?.parse(decodeURIComponent(val.replace(/\+/g, '%20')));
        } catch (e) {
            //@ts-ignore
            params[key] = decodeURIComponent(val);
        }
    });
    return params;
};

export default parseQueryParamsToObject;

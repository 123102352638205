import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import Latex from 'react-latex-next';
import { INSERT_WORD_REPLACE } from '@lms-elements/custom-editor';
import { IMultipleAnswerOption } from '@lms-elements/test-task/build/@types/packages/TestTask/src/Question/Expanded/MultipleAnswerFields';
import { questionType as typeOfQuestion } from '@lms-elements/test-task/build/@types/packages/TestTask/src/Question/Minimized/MinimizedQuestion.types';
import { convertToRaw, EditorState } from 'draft-js';
import convertToHtml from 'draftjs-to-html';

import { IFormValuesCreateQuestions, QuestionMarkup } from './QuestionCreationPage.types';

enum EntityTypes {
    LATEX = 'LATEX',
    LINK = 'LINK',
    INSERT_WORD = 'INSERT_WORD',
}

interface QuestionMarkupProps extends QuestionMarkup {
    id: number;
}

const HiddenQuestionMarkup: React.FC<QuestionMarkupProps> = ({ questionHTML, answersHTML, id }) => {
    return (
        <div>
            <div id={`question-${id}_hidden-markup`}>
                <Latex>
                    {questionHTML
                        .replaceAll('&lt; ', '< ')
                        .replaceAll('&gt;', '>')
                        .replaceAll('&lt;', '< ')
                        .replaceAll('&amp;', '& ')}
                </Latex>
            </div>
            <div id={`answers-${id}`}>
                {answersHTML.map((answerHTML, index) => (
                    <div key={`answer-${index}`} id={`answer-${id}-${index}hidden-markup`}>
                        <Latex>
                            {answerHTML
                                .replaceAll('&lt; ', '< ')
                                .replaceAll('&gt;', '>')
                                .replaceAll('&lt;', '< ')
                                .replaceAll('&amp;', '& ')}
                        </Latex>
                    </div>
                ))}
            </div>
        </div>
    );
};

export const useEditorsToHTML = (
    values: IFormValuesCreateQuestions,
    questionType: typeOfQuestion,
): {
    getMarkup: () => Omit<QuestionMarkup, 'id'>[];
    render: () => React.ReactPortal | undefined;
} => {
    // Creation hiddenDiv
    const [hiddenNode, setHiddenNode] = useState<HTMLDivElement | undefined>(undefined);

    useEffect(() => {
        const hiddenDiv = document.createElement('div');
        hiddenDiv.style.display = 'none';
        setHiddenNode(hiddenDiv);
        document.querySelector('html')?.appendChild(hiddenDiv);

        return () => {
            document.querySelector('html')?.removeChild(hiddenDiv);
        };
    }, []);

    // render markups
    const questionEditors: {
        question: EditorState | null;
        answers?: (EditorState | null)[];
    }[] = useMemo(() => {
        return values.questions
            .map((question) => {
                const questionEditor = typeof question.question === 'object' ? question.question : null;
                let answerEditors = [] as (EditorState | null)[];
                if (question.answerOptions) {
                    switch (questionType) {
                        case 'multiple':
                        case 'open':
                            answerEditors = (question.answerOptions as IMultipleAnswerOption[])?.map(({ answer }) =>
                                typeof answer === 'object' ? answer : null,
                            );
                            break;
                        default:
                            answerEditors = [] as EditorState[];
                            break;
                    }
                }

                return {
                    question: questionEditor,
                    answers: answerEditors,
                };
            })
            .filter(Boolean);
    }, [values.questions, questionType]);

    const render = useCallback(() => {
        const questionHtml = questionEditors.map((questionEditor) =>
            Object.entries(questionEditor).reduce((result, [key, value]) => {
                if (key === 'question') {
                    if (value) {
                        const editorState = value as EditorState;
                        result.question = convertToHtml(
                            convertToRaw(editorState.getCurrentContent()),
                            undefined,
                            undefined,
                            ({ type, data }, text) => {
                                if (type === EntityTypes.LATEX && hiddenNode) {
                                    return text as string;
                                }
                                if (type === EntityTypes.INSERT_WORD) {
                                    return INSERT_WORD_REPLACE;
                                }

                                if (type === EntityTypes.LINK) {
                                    const { url, target } = data as { url: string; target: string };
                                    const isTXT = url.includes('.txt');
                                    return `<a href="${url}" target="${target}" class="${
                                        isTXT ? 'download_txt_file' : ''
                                    }">${text as string}</a>`;
                                }
                                return undefined;
                            },
                        );

                        return result;
                    }

                    result.question = '<p></p>';

                    return result;
                }

                if (key === 'answers' && Array.isArray(value)) {
                    const editorStates = value;

                    result.answers = editorStates.map((state) => {
                        if (state) {
                            return convertToHtml(
                                convertToRaw(state.getCurrentContent()),
                                undefined,
                                undefined,
                                ({ type }, text) => {
                                    if (type === EntityTypes.LATEX && hiddenNode) {
                                        return text as string;
                                    }

                                    return undefined;
                                },
                            );
                        }

                        return '<p></p>';
                    });

                    return result;
                }

                return result;
            }, {} as { question: string; answers: string[] }),
        );

        return (
            hiddenNode &&
            ReactDOM.createPortal(
                questionHtml.map((question, index) => (
                    <HiddenQuestionMarkup
                        key={index}
                        questionHTML={question.question}
                        answersHTML={question.answers}
                        id={index}
                    />
                )),
                hiddenNode,
            )
        );
    }, [hiddenNode, questionEditors]);

    // get markups
    const getMarkup = useCallback(() => {
        if (hiddenNode) {
            const hiddenChilden = Array.from(hiddenNode.children);

            const markups = hiddenChilden.map((markup) => {
                const [questionMarkup, answersMarkup] = Array.from(markup.children) as [HTMLDivElement, HTMLDivElement];

                const answersHTML = Array.from(answersMarkup.children).map((answer) => answer.innerHTML);

                return {
                    answersHTML,
                    questionHTML: questionMarkup.innerHTML,
                };
            });

            return markups;
        }

        return [] as Omit<QuestionMarkup, 'id'>[];
    }, [hiddenNode]);

    return {
        getMarkup,
        render,
    };
};

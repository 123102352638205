import React from 'react';
import { Field, FieldRenderProps } from 'react-final-form';
import { Checkbox } from '@lms-elements/atomic';
import { composeValidators } from '@lms-elements/utils';

import { ICheckboxFieldProps } from './CheckboxField.types';

export const CheckboxField: React.FC<ICheckboxFieldProps> = ({ name, validators = [], isError, className }) => {
    return (
        <div className={className}>
            <Field
                type="checkbox"
                name={name}
                validate={composeValidators(...validators, () => (isError ? 'error' : undefined))}
            >
                {({ input }: FieldRenderProps<boolean>) => {
                    return (
                        <Checkbox
                            checked={input.checked}
                            handleCheckboxChange={(e) => input.onChange(e)}
                            onFocus={(e) => input.onFocus(e)}
                            onBlur={(e) => input.onBlur(e)}
                            isError={isError}
                        />
                    );
                }}
            </Field>
        </div>
    );
};

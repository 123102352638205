export const getNoun = (number: number, one: string, two: string, five: string): string => {
    let num = Math.abs(number);
    num %= 100;
    if (num >= 5 && num <= 20) {
        return five;
    }
    num %= 10;
    if (num === 1) {
        return one;
    }
    if (num >= 2 && num <= 4) {
        return two;
    }
    return five;
};
export const getNumbersNoun = (val: number): string => {
    const isTens = val > 10 && val < 90 && val % 10 === 0;

    if (val === 0) {
        return `0`;
    } else if (isTens) {
        return `${val}-ти`;
    } else if (val === 90) {
        return `90-та`;
    } else if (val < 20) {
        return `${val}${getNumberEnding(val)}`;
    } else {
        const lastNam = val % 10;
        return `${val}${getNumberEnding(lastNam)}`;
    }
};
const getNumberEnding = (val: number): string => {
    switch (val) {
        case 1:
            return `-го`;
        case 2:
        case 3:
        case 4:
            return `-x`;
        case 7:
        case 8:
            return `-ми`;
        default:
            return `-ти`;
    }
};

import { RefObject, useCallback, useMemo, useState } from 'react';

export const useGetScrollPosition = (
    element: RefObject<HTMLDivElement>,
    horizontal?: boolean,
): { isScrollEnd: boolean; isScrollStart: boolean; onScroll: () => void } => {
    const [scroll, setScroll] = useState(0);

    const onScroll = useCallback(() => {
        const scrollTop = horizontal ? element.current?.scrollLeft : element.current?.scrollTop;
        setScroll(scrollTop ? scrollTop : 0);
    }, [element, horizontal]);

    const isScrollEnd = useMemo(
        () =>
            !(
                ((horizontal ? element.current?.scrollWidth : element.current?.scrollHeight) ?? 0) - scroll - 20 <=
                    ((horizontal ? element.current?.clientWidth : element.current?.clientHeight) ?? 0) &&
                (horizontal ? element.current?.clientWidth : element.current?.clientHeight) !== 0
            ),
        [horizontal, element, scroll],
    );

    const isScrollStart = useMemo(
        () => scroll - 40 >= 0 && (horizontal ? element.current?.scrollWidth : element.current?.scrollHeight) !== 0,
        [element, horizontal, scroll],
    );

    return { isScrollEnd, isScrollStart, onScroll };
};

import React, { FC, useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';
import {
    checkAnswerData,
    EmptyTrainerCard,
    Exercise,
    getTrainingProgressAction,
    IExerciseLocalValue,
    resetExercise,
} from '@fsd-entities/materials';
import { CheckTrainingQuestionButton } from '@fsd-features/materials/checkTrainingQuestion';
import { NextTrainingQuestionButton } from '@fsd-features/materials/getNextTrainingQuestion';
import { TQuestionType, TTrainingQuestionAnswer } from '@fsd-shared/models/materials';
import { AppSkeletonCard } from '@fsd-shared/ui';
import { useAppDispatch, useAppSelector } from 'store/store';

import { IStudentContentProps } from './StudentContent.types';
import { StudentTrainerStatistic } from './StudentTrainerStatistic';

import './StudentContent.styles.scss';

const CnStudentContent = cn('trainer-tab-student-content');

export const StudentContent: FC<IStudentContentProps> = (props) => {
    const { lessonId, courseGroupId } = props;
    const dispatch = useAppDispatch();
    const { question, isQuestionLoading, statistics } =
        useAppSelector((store) => store.newMaterials?.training?.exercise) || {};

    const [localAnswerValue, setLocalAnswerValue] = useState<TTrainingQuestionAnswer | undefined>();
    const isShowSkeleton = isQuestionLoading === undefined || (isQuestionLoading && !question);
    const isEmptyData = isQuestionLoading === false && !question;

    const changeLocalAnswer = (type: TQuestionType, value: IExerciseLocalValue) => {
        setLocalAnswerValue(checkAnswerData(type, value));
    };

    useEffect(() => {
        if (lessonId && courseGroupId) {
            void dispatch(getTrainingProgressAction({ lesson: lessonId, courseGroup: courseGroupId }));
        }
    }, [lessonId && courseGroupId]);

    useEffect(() => {
        return () => void dispatch(resetExercise());
    }, []);

    if (isEmptyData) {
        return <EmptyTrainerCard type={'noMaterials'} />;
    }

    if (isShowSkeleton) {
        return (
            <div className={CnStudentContent()}>
                <AppSkeletonCard height={226} borderRadius={14} />
                <AppSkeletonCard width={166} height={226} borderRadius={14} />
            </div>
        );
    }

    return (
        <div className={CnStudentContent()}>
            {!!question && (
                <div className={CnStudentContent('exercise')}>
                    <Exercise exercise={question} changeLocalAnswer={changeLocalAnswer} />
                    <div className={CnStudentContent('exercise-buttons')}>
                        <CheckTrainingQuestionButton
                            lessonId={lessonId}
                            courseGroupId={courseGroupId}
                            answer={localAnswerValue}
                            questionId={question?.id}
                        />
                        <NextTrainingQuestionButton
                            lessonId={lessonId}
                            setLocalAnswerValue={setLocalAnswerValue}
                            courseGroupId={courseGroupId}
                        />
                    </div>
                </div>
            )}
            <StudentTrainerStatistic statistics={statistics} />
        </div>
    );
};

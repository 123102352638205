import React, { ReactNode } from 'react';
import { isEmptyList } from '@frontend-modules/frontend-utils';
import { Task } from '@lms-elements/icons';
import { CalendarSmallIcon, TimeSmallIcon } from 'assets';
import { TSubjectLesson } from 'src-new/components/new-subjects';
import LessonTypeTag from 'src-new/components/students-schedule/lesson-type-tag/LessonTypeTag';
import { useGetLessonHomeworks } from 'src-new/hooks';
import { AppText } from 'src-new/ui';
import { getDateByMoscow, getTimeByMoscow } from 'src-new/utils';

import './style.scss';

export const LessonInfo: React.FC<{ lessonInfo: TSubjectLesson }> = ({ lessonInfo }) => {
    const { homeworkTitle } = useGetLessonHomeworks(lessonInfo);

    const getTime = (start: string, end: string): ReactNode => {
        if (start && end) {
            const startDay = getDateByMoscow(start),
                endDay = getDateByMoscow(end),
                startTime = getTimeByMoscow(start),
                endTime = getTimeByMoscow(end);

            if (startDay === endDay) {
                return (
                    <div className={'time-container'}>
                        <div className={'time-container__item'}>
                            <CalendarSmallIcon />
                            <AppText text={startDay} fontStyle={'descriptor'} />
                        </div>
                        <div className={'time-container__item'}>
                            <TimeSmallIcon />
                            <AppText text={`${startTime} — ${endTime}`} fontStyle={'descriptor'} />
                        </div>
                    </div>
                );
            }
            return (
                <div className={'time-container'}>
                    <div className={'time-container__item'}>
                        <CalendarSmallIcon />
                        <AppText text={startDay} fontStyle={'descriptor'} />
                    </div>
                    <div className={'time-container__item'}>
                        <TimeSmallIcon />
                        <AppText text={startTime} fontStyle={'descriptor'} />
                    </div>
                    <AppText text={` — `} fontStyle={'descriptor'} />
                    <div className={'time-container__item'}>
                        <CalendarSmallIcon />
                        <AppText text={endDay} fontStyle={'descriptor'} />
                    </div>
                    <div className={'time-container__item'}>
                        <TimeSmallIcon />
                        <AppText text={endTime} fontStyle={'descriptor'} />
                    </div>
                </div>
            );
        }
        return <></>;
    };

    const noLessonSchedules = isEmptyList(lessonInfo.lessonSchedules);
    const isTestLesson = noLessonSchedules && lessonInfo.isTest;

    return (
        <div className={'lesson-info'}>
            <div className={'lesson-info__container'}>
                <div className={'lesson-info__container_item'}>
                    <Task />
                    <AppText text={homeworkTitle} fontStyle={'footnotes'} />
                </div>
                {lessonInfo.lessonSchedules.map((item) => (
                    <div key={item.id} className={'lesson-info__container_item'}>
                        <LessonTypeTag type={item.type} />
                        {getTime(item.datetimeStart, item.datetimeEnd)}
                    </div>
                ))}
                {noLessonSchedules && isTestLesson && (
                    <div className={'lesson-info__container_item'}>
                        <LessonTypeTag type={'test_lesson'} />
                    </div>
                )}
            </div>
        </div>
    );
};

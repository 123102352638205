import { getApiRequest } from 'api';
import { CourseGroup } from 'types/courseGroup';

export interface SubjectAndYear {
    subject: number;
    year: number;
}

export const getCourseGroupsBySubjectAndYear = (params: SubjectAndYear): Promise<CourseGroup[]> => {
    return getApiRequest(`/course-groups/?subject=${params.subject}&academic_year=${params.year}`);
};

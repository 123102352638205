import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';

export interface Subject {
    id: number;
    title: string;
    shortName: string;
    studentsCount: number;
    courseGroupsCount: number;
    courseQuantity: number;
    ordering: number;
}

/**
 * Returns subject data from server
 */

export const getSubjects = async (): Promise<Subject[]> => {
    return await api
        .get<Promise<Subject[]>, AxiosResponse<Subject[]>>(`/subject/`)
        .then((res) => res.data || undefined)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const getSubjectDetails = async (subjectId: number): Promise<Subject> => {
    return await api
        .get<Promise<Subject>, AxiosResponse<Subject>>(`subject/${subjectId}/`)
        .then((res) => res.data || undefined)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

import { api, postApiRequest } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { AssignmentType } from 'types/assignment';
import { AssignmentProgressStatus } from 'types/assignmentProgress';
import {
    EAssignedTypes,
    EDeadlineTypes,
    IHomeworkItem,
    TPostDeferredAssignment,
    TPostDeferredAssignmentResponse,
} from 'types/homework';
import { Student } from 'types/students';

export interface AssigningStudentTask {
    assignment: number;
    student: number;
    deadlineForComplete: string;
    assignedDate: string;
    courseGroup?: number;
    teacher?: number;
    assignedDelay?: number;
    deadlineDelay?: number;
    timeToComplete?: number;
    assignedType: EAssignedTypes;
    deadlineType: EDeadlineTypes;
}

interface PostHomeworkItemResponse {
    id: string;
    status: AssignmentProgressStatus;
    title?: string;
    description?: string;
    assignedDate?: string;
    type: AssignmentType;
    student: Student;
    assignment: {
        id: number;
    };
    completeDate?: string;
    deadlineForComplete?: string;
    deadlineForCheck?: string;
    timeToComplete: number;
}

export const postStudentTask = async (tasks: AssigningStudentTask[]): Promise<PostHomeworkItemResponse[]> => {
    return api
        .post<Promise<IHomeworkItem[]>, AxiosResponse<IHomeworkItem[]>>(`/assignment-progress/bulk-create/`, tasks)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const postDeferredAssignment = async (
    params: TPostDeferredAssignment,
): Promise<TPostDeferredAssignmentResponse[]> => {
    return postApiRequest('/api/v2/deferred-assignment/', params);
};

import React from 'react';
import { AppText } from 'src-new/ui';
import { TInterval } from 'src-new/utils/date-n-time/timeСonversion';

type TScheduleTimelineProps = {
    intervalList: TInterval[];
    intervalHeight: number;
};
const ScheduleTimeline: React.FC<TScheduleTimelineProps> = ({ intervalList, intervalHeight }) => {
    const intervalListLastIndex = intervalList.length - 1;
    return (
        <>
            {intervalList.map((timeItem, index) => {
                return (
                    <div key={`time-col: ${timeItem.start}`}>
                        <div
                            style={{
                                height: `${intervalHeight}px`,
                            }}
                        >
                            <AppText text={timeItem.start} fontStyle={'descriptor'} />
                        </div>
                        {intervalListLastIndex === index && (
                            <div
                                style={{
                                    height: `1px`,
                                }}
                            >
                                <AppText text={timeItem.end} fontStyle={'descriptor'} />
                            </div>
                        )}
                    </div>
                );
            })}
        </>
    );
};
export default ScheduleTimeline;

import { HARD_MODAL_ENUMS } from 'src-new/components/layouts/Modals/Modals.config';
import { useQueryPopupsParams } from 'src-new/hooks';
import { TSearchQuestionsQuery } from 'types/lessonTask';

import { TSearchQuestionsParams, TSearchQuestionsPopup } from './useSearchQuestionsPopup.types';

const useSearchQuestionsPopup = (): TSearchQuestionsPopup => {
    const Popup = useQueryPopupsParams();

    const getQuery = (params: TSearchQuestionsParams) => {
        const {
            type = 'SEARCH_QUESTIONS',
            purpose = 'create',
            isTrainer,
            lessonId,
            courseGroupId,
            subjectId,
            assignment,
            num,
            taskTypeId,
        } = params;

        const query: TSearchQuestionsQuery = {
            type: HARD_MODAL_ENUMS[type],
            lessonId,
            courseGroupId,
            purpose,
            subjectId,
            taskTypeId,
        };

        if (assignment) {
            query.assignment = assignment;
        }
        if (num) {
            query.num = num;
        }
        if (isTrainer) {
            query.isTrainer = isTrainer;
        }

        return query;
    };

    const openSearchQuestionsPopup = (params: TSearchQuestionsParams) => {
        Popup.openPopup(getQuery(params));
    };

    const closeLastSearchQuestionsPopup = () => {
        Popup.closeLastPopup();
    };

    const replaceLastSearchQuestionsPopup = (params: TSearchQuestionsParams) => {
        Popup.replaceLastPopup(getQuery(params));
    };

    const isCurrent = Popup.isCurrentPopup(HARD_MODAL_ENUMS.SEARCH_QUESTIONS);

    return {
        openSearchQuestionsPopup,
        closeLastSearchQuestionsPopup,
        replaceLastSearchQuestionsPopup,
        isCurrent,
    };
};

export default useSearchQuestionsPopup;

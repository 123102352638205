import { timezoneError } from 'constants/errors';

import {
    ActionCreatorWithoutPayload,
    createSlice,
    PayloadAction,
    SerializedError,
    SliceCaseReducers,
} from '@reduxjs/toolkit';
import { getLessonScheduleAndAttendanceData } from 'store/actions/academicPerformance';
import { getAcademicYearAction } from 'store/actions/academicYear';
import { getActiveClassesAction } from 'store/actions/activeClasses';
import { getAdaptationProgressAction } from 'store/actions/adaptationProgress';
import {
    createAttemptAction,
    createQuestionFileAction,
    getAnswerAttemptAction,
    getAnswerAttemptByProgressAction,
    patchAnswerAttemptAction,
    updateAttemptDraft,
} from 'store/actions/answerAttempt';
import {
    deleteAssignmentAction,
    fetchAssignment,
    getAssignmentsByLessonAction,
    getAssignmentTypesAction,
    postNewAssignmentAction,
    updateAssignmentNewAction,
} from 'store/actions/assignment';
import {
    changeAttendanceMarkAction,
    createAttendanceMarkAction,
    deleteAttendanceMarkAction,
    getLessonAttendanceDataAction,
    getMarksAction,
    getStudentMarksAction,
    patchStudentPresenceAction,
} from 'store/actions/attendance';
import { fetchLogout } from 'store/actions/auth';
import { getCalendarPlanAction } from 'store/actions/calendarPlan';
import {
    deleteCodifierBlockAction,
    deleteCodifierMetasubjectAction,
    deleteCodifierParallelAction,
    deleteCodifierSectionAction,
    deleteCodifierSkillAction,
    deleteCodifierTopicAction,
    fetchCodifierDataAction,
    fetchCodifierSkillsDataAction,
    getCodifierDataByTopicsAction,
    getCodifierSubjectDataAction,
    getCodifierSubjectsAction,
    patchCodifierBlockAction,
    patchCodifierMetasubjectAction,
    patchCodifierParallelAction,
    patchCodifierSectionAction,
    patchCodifierSkillAction,
    patchCodifierTopicAction,
    postCodifierBlockAction,
    postCodifierMetasubjectAction,
    postCodifierParallelAction,
    postCodifierSectionAction,
    postCodifierSkillAction,
    postCodifierTopicAction,
} from 'store/actions/codifier';
import {
    blockCourseAction,
    changeCourseAction,
    createCourseAction,
    deleteCourseAction,
    getAllCoursesForParallel,
    getCourseBySubjectAction,
    getCourseDetailsAction,
    getCoursesAction,
} from 'store/actions/course';
import {
    getAllEducationalPlanWithCourseGroupsAction,
    getCourseGroupDetailsAction,
    getCourseGroupDetailsWithStudentsAction,
    getCourseGroupExpelledStudentsAction,
    getCourseGroupsAction,
    getCourseGroupsByAcademicYearAction,
    getCourseGroupsBySubjectAndYearAction,
    getCourseGroupStudentsAction,
    getPeriodTopicsWithStudentAction,
    patchCourseGroupsAction,
} from 'store/actions/courseGroup';
import {
    changeLessonTopicAction,
    createLessonAction,
    deleteLessonAction,
    getLessonDataAction,
    getLessonsAction,
    getLessonsAndHomeworksAction,
    handleLessonScheduleAction,
} from 'store/actions/courseLessons';
import { changeThemeAction, createThemeAction, deleteThemeAction, getThemesAction } from 'store/actions/courseThemes';
import { getParallelsAction } from 'store/actions/edu';
import {
    deleteEducationalPlanAction,
    getAllCalendarPlansAction,
    getEducationalParallelsAction,
    getEducationalPlanAction,
    getEducationPLanWithParallelsAndCalendar,
    patchEducationalPlanAction,
    postEducationalPlanAction,
} from 'store/actions/educationalPlan';
import { setError } from 'store/actions/error';
import { deleteFileAction } from 'store/actions/file';
import {
    deleteStudentHomeworksAction,
    fetchHomework,
    getAssignedAndOnCheckHomeworkAction,
    getAssignedHomeworksAction,
    getAssignmentProgressPreviewAction,
    getCompletedHomeworkAction,
    getHomeworkPreviewBetweenDatesAction,
    getHomeworksBetweenDatesAction,
    getProgressesStatisticsAction,
    getStudentsTasksAction,
    postDeferredAssignmentAction,
    postStudentHomeworksAction,
    putStudentHomeworksAction,
} from 'store/actions/homework';
import {
    getLessonScheduleAction,
    getMeetingJoinRedirectAction,
    getMeetingWithCheckAction,
    patchMissedLessonAction,
} from 'store/actions/lessonSchedule';
import {
    changeAssignmentAdditionalMarkAction,
    changeAssignmentMarkAction,
    changeAssignmentMarksAction,
    createAssignmentAdditionalMarkAction,
    deleteAssignmentAdditionalMarkAction,
    deleteAssignmentAdditionalMarkWithCheckAction,
    getTransferredMarksAction,
} from 'store/actions/marks';
import {
    addNewQuestionForExerciseMaterialAction,
    checkExerciseAnswerAction,
    clearMaterialsFilesAction,
    createMaterialAction,
    createTempMaterials,
    deleteMaterialAction,
    deleteMaterialFileAction,
    getExercisesCorrectAnswersAction,
    getMaterialsDataAction,
    patchMaterialAction,
    patchMaterialWithOrderingAction,
    postMaterialFileAction,
    postMaterialWithOrderingAction,
} from 'store/actions/material';
import {
    createMedicalCertificateAction,
    getMedicalCertificatesAction,
    updateMedicalCertificateAction,
} from 'store/actions/medicalCertificate';
import {
    changeMeetingFileAction,
    deleteMeetingFileAction,
    getMeetingFilesAction,
    getMeetingRecordingsAction,
    postMeetingRecordingAction,
} from 'store/actions/meeting';
import { createMeetingFileAction } from 'store/actions/meetingFiles';
import { getMonitoringInfoAction, getNextMonitoringInfoAction } from 'store/actions/monitoring';
import {
    createNewsAction,
    deleteNewsAction,
    getNewsByDatesAction,
    getNewsByIdAction,
    updateNewsAction,
    updateNewsLikesAction,
} from 'store/actions/news';
import { changeNoteAction, createNoteAction, deleteNoteAction, getNotesAction } from 'store/actions/notes';
import {
    getFirstArchiveNotificationsPageAction,
    getNextArchiveNotificationsPageAction,
    getNotificationsPageAction,
    getUnreadNotificationsCountAction,
} from 'store/actions/notifications';
import { oauthAction } from 'store/actions/oauth';
import { getPeriodMarkAction, getPeriodStudentMarkAction, patchPeriodMarkAction } from 'store/actions/periodMark';
import {
    changeEventRoomAction,
    createEventRoomAction,
    deleteEventRoomAction,
    getEventRoomDetailsAction,
    getEventRoomsByDates,
    getEventRoomsByLessonAndTypeAction,
    getEventsByDateAction,
    getProctorsAction,
    getStudentEventsAction,
    joinRoomAction,
    rejoinRoomAction,
} from 'store/actions/proctoring';
import { getQuestionsForReviewAction, getQuestionsOnRevisionAction } from 'store/actions/question';
import {
    deleteQuestionAction,
    getAssignmentProgressQuestionsAction,
    getQuestionDetailedAction,
    getQuestionsAction,
    partialUpdateQuestionAction,
    patchQuestionAction,
    postQuestionAction,
    postQuestionFileAction,
} from 'store/actions/questionBank';
import {
    fetchAssignmentSearchBlocksAction,
    postSearchQuestionAction,
    questionBlockAction,
    questionDetailAction,
} from 'store/actions/questionCreate';
import {
    createRevisionQuestionAction,
    deleteRevisionQuestionAction,
    updateRevisionQuestionAction,
} from 'store/actions/revisionQuestion';
import { fetchSchedule, getScheduleByDateRangeAction, getUserScheduleAction } from 'store/actions/schedule';
import {
    getStaffProfileByIdAction,
    getStaffProfilesAction,
    updateStaffProfileAction,
} from 'store/actions/staffProfile';
import { getStudentDataAction, postStudentProfileData } from 'store/actions/student';
import { getStudentGroupsAction } from 'store/actions/studentGroups';
import { getDayScheduleAction } from 'store/actions/studentSchedule';
import {
    deleteStudentTaskAction,
    fetchStudentHomeworksAction,
    getStudentTaskById,
    getStudentTaskPreview,
    getStudentTasksAction,
} from 'store/actions/studentTasks';
import {
    getCoursesBySubject,
    getMethodistSubjects,
    getPrincipalSubjects,
    getSubjectDetailsAction,
    getTeacherSubjects,
} from 'store/actions/subject';
import { getCurrentTimeAction, getUserInfoAction } from 'store/actions/user';

import { convertError } from 'utils/convertError';
import { checkTimezone } from 'utils/date';

interface ErrorState {
    error?: string | null;
    descriptions?: string[];
    extra?: Record<string, number | unknown | string> | null;
    isFrontend?: boolean;
    isTimezoneError?: boolean;
    needHideButtons?: boolean;
    isBlockLesson?: boolean;
    needHideErrorIcon?: boolean;
    isOlBlocked?: boolean;
    disableCancelButton?: boolean;
    isLastAttemptDeliting?: boolean;
    isCourseDeleting?: boolean;
    customDescription?: string;
    createCertificate?: boolean;
    actionButtonClick?: () => void;
    cancelButtonClick?: () => void;
}

const initialState: ErrorState = {
    error: null,
};

interface ErrorPayload {
    message: string;
    extra: {
        isBlockLesson: boolean;
        needHideErrorIcon: boolean;
        needHideButtons?: boolean;
        isFrontend?: boolean;
        createCertificate?: boolean;
        isCourseDeleting?: boolean;
    } & Record<string, unknown>;
    actionButtonClick?: () => void;
    cancelButtonClick?: () => void;
}

const reducer = (state: ErrorState, action: { error: SerializedError; meta: { aborted?: boolean } }) => {
    const { error, meta } = action;
    if (!meta.aborted) {
        const errorData = convertError(error);
        if (typeof errorData === 'string') {
            state.error = errorData;
        } else {
            state.error = errorData.error;
            state.extra = errorData.extra;
        }
    }
};

export const errorSlice = createSlice<ErrorState, SliceCaseReducers<ErrorState>>({
    name: 'error',
    initialState,
    reducers: {
        ready: () => {
            return initialState;
        },
        error: (state, action: PayloadAction<ErrorPayload>) => {
            const {
                isBlockLesson,
                needHideErrorIcon,
                needHideButtons,
                isFrontend,
                createCertificate,
                isCourseDeleting,
                ...restExtraParams
            } = action.payload.extra;

            state.error = action.payload.message === 'Rejected' ? 'Ой, что-то пошло не так!' : action.payload.message;
            state.extra = restExtraParams;
            state.isFrontend = typeof isFrontend === 'boolean' ? isFrontend : true;
            state.isBlockLesson = isBlockLesson;
            state.needHideErrorIcon = needHideErrorIcon;
            state.actionButtonClick = action.payload.actionButtonClick;
            state.cancelButtonClick = action.payload.cancelButtonClick;
            state.needHideButtons = needHideButtons;
            state.createCertificate = createCertificate;
            state.isCourseDeleting = isCourseDeleting;
            if (action.payload.extra.isOlBlocked !== undefined)
                state.isOlBlocked = Boolean(action.payload.extra.isOlBlocked);
            if (action.payload.extra.disableCancelButton !== undefined)
                state.disableCancelButton = Boolean(action.payload.extra.disableCancelButton);
            if (action.payload.extra.isLastAttemptDeliting !== undefined)
                state.isLastAttemptDeliting = Boolean(action.payload.extra.isLastAttemptDeliting);
            if (action.payload.extra.customDescription !== undefined)
                state.customDescription = String(action.payload.extra.customDescription);
        },
        clear: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCurrentTimeAction.fulfilled, (state, { payload }) => {
            const isCorrectTimezone = checkTimezone(payload.time);

            if (!isCorrectTimezone) {
                state.error = timezoneError.error;
                state.descriptions = timezoneError.descriptions;
                state.isTimezoneError = true;
                state.actionButtonClick = () => undefined;
            }
        });
        builder.addCase(setError, (state, { payload }) => {
            state.error = payload;
            state.needHideButtons = true;
        });
        builder
            .addCase(fetchHomework.rejected, reducer)
            .addCase(getMonitoringInfoAction.rejected, reducer)
            .addCase(fetchSchedule.rejected, reducer)
            .addCase(getNextMonitoringInfoAction.rejected, reducer)
            .addCase(deleteFileAction.rejected, reducer)
            .addCase(getStaffProfilesAction.rejected, reducer)
            .addCase(updateStaffProfileAction.rejected, reducer)
            .addCase(getStaffProfileByIdAction.rejected, reducer)
            .addCase(getActiveClassesAction.rejected, reducer)
            .addCase(getLessonAttendanceDataAction.rejected, reducer)
            .addCase(patchStudentPresenceAction.rejected, reducer)
            .addCase(getCourseGroupsByAcademicYearAction.rejected, reducer)
            .addCase(getCourseGroupExpelledStudentsAction.rejected, reducer)
            .addCase(getMeetingWithCheckAction.rejected, reducer)
            .addCase(getScheduleByDateRangeAction.rejected, reducer)
            .addCase(createCourseAction.rejected, reducer)
            .addCase(getCoursesAction.rejected, reducer)
            .addCase(changeCourseAction.rejected, reducer)
            .addCase(deleteCourseAction.rejected, reducer)
            .addCase(fetchAssignment.rejected, reducer)
            .addCase(postStudentHomeworksAction.rejected, reducer)
            .addCase(putStudentHomeworksAction.rejected, reducer)
            .addCase(deleteStudentHomeworksAction.rejected, reducer)
            .addCase(fetchCodifierDataAction.rejected, reducer)
            .addCase(postCodifierParallelAction.rejected, reducer)
            .addCase(deleteCodifierParallelAction.rejected, reducer)
            .addCase(deleteCodifierSectionAction.rejected, reducer)
            .addCase(postCodifierBlockAction.rejected, reducer)
            .addCase(deleteCodifierBlockAction.rejected, reducer)
            .addCase(postCodifierTopicAction.rejected, reducer)
            .addCase(deleteCodifierTopicAction.rejected, reducer)
            .addCase(fetchCodifierSkillsDataAction.rejected, reducer)
            .addCase(postCodifierMetasubjectAction.rejected, reducer)
            .addCase(deleteCodifierMetasubjectAction.rejected, reducer)
            .addCase(postCodifierSkillAction.rejected, reducer)
            .addCase(deleteCodifierSkillAction.rejected, reducer)
            .addCase(getQuestionsAction.rejected, reducer)
            .addCase(postQuestionAction.rejected, reducer)
            .addCase(getQuestionDetailedAction.rejected, reducer)
            .addCase(deleteQuestionAction.rejected, reducer)
            .addCase(patchQuestionAction.rejected, reducer)
            .addCase(getAssignedAndOnCheckHomeworkAction.rejected, reducer)
            .addCase(patchCodifierParallelAction.rejected, reducer)
            .addCase(patchCodifierSectionAction.rejected, reducer)
            .addCase(patchCodifierBlockAction.rejected, reducer)
            .addCase(patchCodifierTopicAction.rejected, reducer)
            .addCase(patchCodifierMetasubjectAction.rejected, reducer)
            .addCase(patchCodifierSkillAction.rejected, reducer)
            .addCase(deleteAssignmentAction.rejected, reducer)
            .addCase(getLessonDataAction.rejected, reducer)
            .addCase(getLessonsAndHomeworksAction.rejected, reducer)
            .addCase(createMaterialAction.rejected, reducer)
            .addCase(deleteMaterialAction.rejected, reducer)
            .addCase(patchMaterialAction.rejected, reducer)
            .addCase(postMaterialWithOrderingAction.rejected, reducer)
            .addCase(patchMaterialWithOrderingAction.rejected, reducer)
            .addCase(fetchStudentHomeworksAction.rejected, reducer)
            .addCase(getStudentTasksAction.rejected, reducer)
            .addCase(getAssignmentProgressQuestionsAction.rejected, reducer)
            .addCase(fetchLogout.rejected, reducer)
            .addCase(getCompletedHomeworkAction.rejected, reducer)
            .addCase(createAttemptAction.rejected, (state: ErrorState, action: { error: SerializedError }) => {
                const { error } = action;
                const errorData = convertError(error);

                if (typeof errorData !== 'string') {
                    const validationErr = errorData.extra?.fields;

                    if (Array.isArray(validationErr)) {
                        state.error = validationErr?.length ? validationErr.join(',') : JSON.stringify(errorData.extra);
                    } else {
                        const errors = Object.values(validationErr as Object).map((item) => JSON.stringify(item));
                        state.error = errors?.length ? errors.join(',') : JSON.stringify(errorData.extra);
                    }
                } else {
                    state.error = error.message;
                }

                state.needHideButtons = true;
            })
            .addCase(postDeferredAssignmentAction.rejected, (state: ErrorState, action: { error: SerializedError }) => {
                const { error } = action;
                const errorData = convertError(error);

                if (typeof errorData !== 'string') {
                    const validationErr = errorData.extra?.fields;

                    if (Array.isArray(validationErr)) {
                        state.error = validationErr?.length ? validationErr.join(',') : JSON.stringify(errorData.extra);
                    } else {
                        const errors = Object.values(validationErr as Object).map((item) => JSON.stringify(item));
                        state.error = errors?.length ? errors.join(',') : JSON.stringify(errorData.extra);
                    }
                } else {
                    state.error = error.message;
                }

                state.needHideButtons = true;
            })
            .addCase(createMeetingFileAction.rejected, (state: ErrorState, action: { error: SerializedError }) => {
                const { error } = action;
                const errorData = convertError(error);

                if (typeof errorData !== 'string') {
                    const validationErr = (errorData.extra as { fields: string[] })?.fields;
                    state.error = validationErr ? validationErr.join(',') : `${errorData.extra}`;
                } else {
                    state.error = error.message;
                }

                state.needHideButtons = true;
            })

            .addCase(postMaterialFileAction.rejected, (state: ErrorState, action: { error: SerializedError }) => {
                const { error } = action;
                const errorData = convertError(error);

                if (typeof errorData !== 'string') {
                    state.extra = errorData.extra;
                }

                state.error = 'Не удалось загрузить файл. Закройте это окно и попробуйте еще раз.';
                state.needHideButtons = true;
            })
            .addCase(updateAttemptDraft.rejected, (state: ErrorState, action: { error: SerializedError }) => {
                const { error } = action;
                const errorData = convertError(error);

                if (typeof errorData !== 'string') {
                    const validationErr = errorData.extra?.fields;

                    if (Array.isArray(validationErr)) {
                        state.error = validationErr?.length ? validationErr.join(',') : JSON.stringify(errorData.extra);
                    } else {
                        const errors = Object.values(validationErr as Object).map((item) => JSON.stringify(item));
                        state.error = errors?.length ? errors.join(',') : JSON.stringify(errorData.extra);
                    }
                } else {
                    state.error = error.message;
                }

                // state.error = 'Последняя попытка сохранения черновика была неудачной!';
                state.needHideButtons = true;
            })
            .addCase(deleteMaterialFileAction.rejected, reducer)
            .addCase(getCourseDetailsAction.rejected, reducer)
            .addCase(getProgressesStatisticsAction.rejected, reducer)
            .addCase(getUserInfoAction.rejected, reducer)
            .addCase(getNewsByDatesAction.rejected, reducer)
            .addCase(updateNewsLikesAction.rejected, reducer)
            .addCase(clearMaterialsFilesAction.rejected, reducer)
            .addCase(createTempMaterials.rejected, reducer)
            .addCase(checkExerciseAnswerAction.rejected, reducer)
            .addCase(getExercisesCorrectAnswersAction.rejected, reducer)
            .addCase(getStudentTaskById.rejected, reducer)
            .addCase(getAnswerAttemptAction.rejected, reducer)
            .addCase(postNewAssignmentAction.rejected, reducer)
            .addCase(postCodifierSectionAction.rejected, reducer)
            .addCase(getLessonsAction.rejected, reducer)
            .addCase(createLessonAction.rejected, reducer)
            .addCase(handleLessonScheduleAction.rejected, reducer)
            .addCase(deleteLessonAction.rejected, reducer)
            .addCase(getThemesAction.rejected, reducer)
            .addCase(createThemeAction.rejected, reducer)
            .addCase(changeThemeAction.rejected, reducer)
            .addCase(deleteThemeAction.rejected, reducer)
            .addCase(getNotesAction.rejected, reducer)
            .addCase(createNoteAction.rejected, reducer)
            .addCase(changeNoteAction.rejected, reducer)
            .addCase(deleteNoteAction.rejected, reducer)
            .addCase(postSearchQuestionAction.rejected, reducer)
            .addCase(questionDetailAction.rejected, reducer)
            .addCase(questionBlockAction.rejected, reducer)
            .addCase(fetchAssignmentSearchBlocksAction.rejected, reducer)
            .addCase(getStudentDataAction.rejected, reducer)
            .addCase(updateAssignmentNewAction.rejected, reducer)
            .addCase(getParallelsAction.rejected, reducer)
            .addCase(getCourseBySubjectAction.rejected, reducer)
            .addCase(getSubjectDetailsAction.rejected, reducer)
            .addCase(getAssignedHomeworksAction.rejected, reducer)
            .addCase(getHomeworksBetweenDatesAction.rejected, reducer)
            .addCase(getCourseGroupDetailsAction.rejected, reducer)
            .addCase(getCourseGroupsAction.rejected, reducer)
            .addCase(getCourseGroupStudentsAction.rejected, reducer)
            .addCase(getStudentsTasksAction.rejected, reducer)
            .addCase(getAssignmentTypesAction.rejected, reducer)
            .addCase(getUnreadNotificationsCountAction.rejected, reducer)
            .addCase(getNotificationsPageAction.rejected, reducer)
            .addCase(getFirstArchiveNotificationsPageAction.rejected, reducer)
            .addCase(getNextArchiveNotificationsPageAction.rejected, reducer)
            .addCase(createAttendanceMarkAction.rejected, reducer)
            .addCase(changeAttendanceMarkAction.rejected, reducer)
            .addCase(deleteAttendanceMarkAction.rejected, reducer)
            .addCase(getLessonScheduleAction.rejected, reducer)
            .addCase(getUserScheduleAction.rejected, reducer)
            .addCase(getCodifierSubjectsAction.rejected, reducer)
            .addCase(getCodifierSubjectDataAction.rejected, reducer)
            .addCase(getCodifierDataByTopicsAction.rejected, reducer)
            .addCase(getAdaptationProgressAction.rejected, reducer)
            .addCase(blockCourseAction.rejected, reducer)
            .addCase(getQuestionsForReviewAction.rejected, reducer)
            .addCase(createAssignmentAdditionalMarkAction.rejected, reducer)
            .addCase(changeAssignmentAdditionalMarkAction.rejected, reducer)
            .addCase(deleteAssignmentAdditionalMarkAction.rejected, reducer)
            .addCase(getCourseGroupDetailsWithStudentsAction.rejected, reducer)
            .addCase(getMaterialsDataAction.rejected, reducer)
            .addCase(addNewQuestionForExerciseMaterialAction.rejected, reducer)
            .addCase(getMeetingRecordingsAction.rejected, reducer)
            .addCase(postMeetingRecordingAction.rejected, reducer)
            .addCase(getMeetingFilesAction.rejected, reducer)
            .addCase(deleteMeetingFileAction.rejected, reducer)
            .addCase(changeMeetingFileAction.rejected, reducer)
            .addCase(getAnswerAttemptByProgressAction.rejected, reducer)
            .addCase(getMethodistSubjects.rejected, reducer)
            .addCase(getCoursesBySubject.rejected, reducer)
            .addCase(getPrincipalSubjects.rejected, reducer)
            .addCase(getAllCoursesForParallel.rejected, reducer)
            .addCase(getEducationalPlanAction.rejected, reducer)
            .addCase(postEducationalPlanAction.rejected, reducer)
            .addCase(patchEducationalPlanAction.rejected, reducer)
            .addCase(deleteEducationalPlanAction.rejected, reducer)
            .addCase(getEducationalParallelsAction.rejected, reducer)
            .addCase(getAllCalendarPlansAction.rejected, reducer)
            .addCase(getCalendarPlanAction.rejected, reducer)
            .addCase(getEducationPLanWithParallelsAndCalendar.rejected, reducer)
            .addCase(getQuestionsOnRevisionAction.rejected, reducer)
            .addCase(createRevisionQuestionAction.rejected, reducer)
            .addCase(updateRevisionQuestionAction.rejected, reducer)
            .addCase(deleteRevisionQuestionAction.rejected, reducer)
            .addCase(partialUpdateQuestionAction.rejected, reducer)
            .addCase(getTeacherSubjects.rejected, reducer)
            .addCase(getStudentGroupsAction.rejected, reducer)
            .addCase(changeAssignmentMarkAction.rejected, reducer)
            .addCase(changeAssignmentMarksAction.rejected, reducer)
            .addCase(patchCourseGroupsAction.rejected, reducer)
            .addCase(getAssignmentsByLessonAction.rejected, reducer)
            .addCase(getAssignmentProgressPreviewAction.rejected, reducer)
            .addCase(getStudentTaskPreview.rejected, reducer)
            .addCase(deleteStudentTaskAction.rejected, reducer)
            .addCase(getHomeworkPreviewBetweenDatesAction.rejected, reducer)
            .addCase(postStudentProfileData.rejected, reducer)
            .addCase(oauthAction.rejected, reducer)
            .addCase(getMeetingJoinRedirectAction.rejected, reducer)
            .addCase(getEventsByDateAction.rejected, reducer)
            .addCase(getProctorsAction.rejected, reducer)
            .addCase(getEventRoomsByLessonAndTypeAction.rejected, reducer)
            .addCase(getEventRoomDetailsAction.rejected, reducer)
            .addCase(createEventRoomAction.rejected, reducer)
            .addCase(patchMissedLessonAction.rejected, reducer)
            .addCase(deleteEventRoomAction.rejected, reducer)
            .addCase(changeEventRoomAction.rejected, reducer)
            .addCase(getEventRoomsByDates.rejected, reducer)
            .addCase(getStudentEventsAction.rejected, reducer)
            .addCase(rejoinRoomAction.rejected, reducer)
            .addCase(joinRoomAction.rejected, reducer)
            .addCase(createQuestionFileAction.rejected, (state, { error }) => {
                state.error = error.message;
                state.needHideButtons = true;
            })
            .addCase(postQuestionFileAction.rejected, (state, { error }) => {
                state.error = error.message;
                state.needHideButtons = true;
            })
            .addCase(getLessonScheduleAndAttendanceData.rejected, reducer)
            .addCase(getDayScheduleAction.rejected, reducer)
            .addCase(patchAnswerAttemptAction.rejected, reducer)
            .addCase(getMedicalCertificatesAction.rejected, reducer)
            .addCase(createMedicalCertificateAction.rejected, reducer)
            .addCase(updateMedicalCertificateAction.rejected, reducer)
            .addCase(deleteAssignmentAdditionalMarkWithCheckAction.rejected, reducer)
            .addCase(getAcademicYearAction.rejected, reducer)
            .addCase(getMarksAction.rejected, reducer)
            .addCase(getStudentMarksAction.rejected, reducer)
            .addCase(getAllEducationalPlanWithCourseGroupsAction.rejected, reducer)
            .addCase(getCourseGroupsBySubjectAndYearAction.rejected, reducer)
            .addCase(getPeriodTopicsWithStudentAction.rejected, reducer)
            .addCase(getPeriodMarkAction.rejected, reducer)
            .addCase(getPeriodStudentMarkAction.rejected, reducer)
            .addCase(patchPeriodMarkAction.rejected, reducer)
            .addCase(changeLessonTopicAction.rejected, reducer)
            .addCase(getTransferredMarksAction.rejected, reducer)
            .addCase(createNewsAction.rejected, reducer)
            .addCase(getNewsByIdAction.rejected, reducer)
            .addCase(updateNewsAction.rejected, reducer)
            .addCase(deleteNewsAction.rejected, reducer);
    },
});

export const { ready, error } = errorSlice.actions;
export const clearError = errorSlice.actions.clear as ActionCreatorWithoutPayload;

export const errorReducer = errorSlice.reducer;

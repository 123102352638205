import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { ContentMaterialTypeEnum, IMaterial } from 'types/materials';

interface Params {
    material: IMaterial;
    isEditor: boolean;
    materialType: ContentMaterialTypeEnum;
    markup?: string;
}

interface MaterialsData {
    id: number;
    content: string;
    html: string;
    typeOfContent: ContentMaterialTypeEnum;
}

const getMaterialsDataForNewsElement = ({ material, isEditor, materialType, markup = '' }: Params): MaterialsData => {
    let content = '.';
    let urls: { name: string; url: string }[] = [];

    if (materialType === ContentMaterialTypeEnum.document || Array.isArray(material.documentData)) {
        urls = material.documentData
            .map((document) => {
                if (typeof document === 'string') {
                    return { name: document, url: document };
                }

                if (!(document instanceof File)) {
                    return document;
                }
            })
            .filter(Boolean) as { name: string; url: string }[];
    }

    switch (materialType) {
        case ContentMaterialTypeEnum.text:
            if (isEditor) {
                content = JSON.stringify(convertToRaw((material.textData as EditorState).getCurrentContent()));
            } else {
                content = material.textData as string;
            }
            break;
        case ContentMaterialTypeEnum.audio:
            content = JSON.stringify({
                url: typeof material.audioData === 'string' ? material.audioData : material.url,
                songName: material.audioData.songName || (material.audioData.file as File)?.name,
            });
            break;
        case ContentMaterialTypeEnum.video:
        case ContentMaterialTypeEnum.kinescope:
            content = JSON.stringify({
                url: typeof material.videoData.url === 'string' ? material.videoData.url : '',
            });
            break;
        case ContentMaterialTypeEnum.photo:
            content = JSON.stringify({
                data: material.imageData?.data ?? [],
                description: material.imageData.description || '',
            });
            break;
        case ContentMaterialTypeEnum.document:
            content = JSON.stringify({
                data: urls,
            });
            break;
        case ContentMaterialTypeEnum.header:
            content = JSON.stringify(material.headerData);
            break;
        case ContentMaterialTypeEnum.table:
            content = JSON.stringify({
                ...material.tableData,
                table: {
                    ...material.tableData.table,
                    table: material.tableData.table.table?.map((row) =>
                        row.map((cell) =>
                            typeof cell === 'string'
                                ? convertToRaw(ContentState.createFromText(cell))
                                : convertToRaw(cell?.getCurrentContent()),
                        ),
                    ),
                    editorBuffer: convertToRaw(
                        typeof material.tableData.table.editorBuffer === 'string'
                            ? ContentState.createFromText(material.tableData.table.editorBuffer)
                            : material.tableData.table.editorBuffer.getCurrentContent(),
                    ),
                },
            });
            break;
        case ContentMaterialTypeEnum.exercise:
            break;
    }
    const typeOfContent =
        material.type === ContentMaterialTypeEnum.kinescope ? ContentMaterialTypeEnum.video : material.type;

    return {
        id: material.id,
        typeOfContent: typeOfContent,
        content,
        html: materialType === ContentMaterialTypeEnum.text ? markup : '.',
    };
};

interface PostNewsElementsParams {
    materials: MaterialsData[];
    files: {
        [materialOrder: number]: File[] | undefined;
    };
}

export const getMaterialsDataForPostNews = (materials: IMaterial[]): PostNewsElementsParams => {
    return materials.reduce(
        (data, material, index) => {
            data.materials.push(
                getMaterialsDataForNewsElement({
                    material,
                    isEditor: true,
                    materialType: material.type,
                    markup: material.html,
                }),
            );

            switch (material.type) {
                case ContentMaterialTypeEnum.audio:
                    data.files[index] = material.audioData.file instanceof File ? [material.audioData.file] : undefined;
                    break;
                case ContentMaterialTypeEnum.video:
                    data.files[index] = material.videoData.file instanceof File ? [material.videoData.file] : undefined;
                    break;
                case ContentMaterialTypeEnum.document:
                    data.files[index] =
                        Array.isArray(material.documentData) &&
                        material.documentData.some((value) => value instanceof File)
                            ? (material.documentData.filter((value) => value instanceof File) as File[])
                            : undefined;
                    break;
                case ContentMaterialTypeEnum.photo:
                    data.files[index] = material.imageData.data.some(({ data }) => data instanceof File)
                        ? (material.imageData.data
                              .filter(({ data }) => data instanceof File)
                              .map(({ data }) => data) as File[])
                        : undefined;
                    break;
                default:
                    break;
            }

            return data;
        },
        {
            materials: [],
            files: [],
        } as PostNewsElementsParams,
    );
};

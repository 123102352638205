import React from 'react';
import { cn } from '@bem-react/classname';
import { Basket, CopyIcon, Pencil, Plus } from '@lms-elements/icons';
import { ReplaceIcon } from 'assets';

import './ThemeTooltip.scss';

interface IThemeTooltipProps {
    handleCreateQuestion?: () => void;
    isTheme?: boolean;
    canEdit?: boolean;
    handleEditClick?: () => void;
    handleCopyClick?: () => void;
    handleDeleteClick?: () => void;
    handleReplaceClick?: () => void;
    canCopy?: boolean;
    skillsTooltip?: boolean;
    isChild?: boolean;
    canDelete?: boolean;
    canReplace?: boolean;
}

const cnThemeTooltip = cn('theme_tooltip');

const viewBoxPencilPosition = '9 -2 14 28';

export const ThemeTooltip: React.FC<IThemeTooltipProps> = ({
    handleCreateQuestion,
    isTheme = true,
    canEdit = true,
    handleEditClick,
    handleCopyClick,
    handleDeleteClick,
    handleReplaceClick,
    canCopy,
    skillsTooltip = false,
    isChild = false,
    canDelete = true,
    canReplace = true,
}) => {
    return (
        <div className={cnThemeTooltip()}>
            <div className={cnThemeTooltip('tooltip_container')}>
                <div className={cnThemeTooltip('tooltip_content')}>
                    {isTheme && !isChild && (
                        <div className={cnThemeTooltip('tooltip_button')} onClick={handleCreateQuestion}>
                            <Plus id={'plus_icon'} />
                            <span>{skillsTooltip ? 'Создать навык' : 'Создать вопрос'}</span>
                        </div>
                    )}
                    {canEdit && (
                        <div className={cnThemeTooltip('tooltip_button')} onClick={handleEditClick}>
                            <Pencil id={'pencil_circle'} viewBox={viewBoxPencilPosition} />
                            <span>Редактировать</span>
                        </div>
                    )}
                    {canCopy && !isChild && (
                        <div className={cnThemeTooltip('tooltip_button')} onClick={handleCopyClick}>
                            <CopyIcon />
                            <span>Копировать</span>
                        </div>
                    )}
                    {canReplace && (
                        <div className={cnThemeTooltip('tooltip_button')} onClick={handleReplaceClick}>
                            <ReplaceIcon id={'replace_icon'} />
                            <span>Переместить</span>
                        </div>
                    )}
                    {canDelete && (
                        <div className={cnThemeTooltip('tooltip_button')} onClick={handleDeleteClick}>
                            <Basket />
                            <span>Удалить</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

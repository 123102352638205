import { EventRoom } from 'api/services/proctoring';
import { RoomCardProps } from 'store/reducers/proctoring';

import {
    getCapitalizeMonth,
    getDateComponents,
    getDateWithoutYear,
    getWeekdayNames,
    getWeekdayNumber,
} from 'utils/date';
import { getParallelsTitle } from 'utils/getParallelsTitle';

export const normalizeEventRooms = (eventRooms: EventRoom[], studentId?: number): RoomCardProps[] => {
    return eventRooms.map(({ id, datetimeStart, maxStudents, students, leaders, course, parallels, datetimeEnd }) => {
        return {
            id,
            datetimeEnd,
            datetimeStart,
            title: '',
            date: getDateWithoutYear(datetimeStart),
            dayOfWeek: getWeekdayNames()[getWeekdayNumber(datetimeStart)],
            time: getDateComponents(datetimeStart).time ?? '',
            teachers: leaders.map((proctor) => `${proctor.lastName} ${proctor.firstName} ${proctor.patronymic ?? ''}`),
            allPlace: maxStudents,
            occupiedPlace: students.length,
            course: `${course.title}${parallels ? ` - ${getParallelsTitle(parallels)}` : ''}`,
            students: students,
            isJoined: studentId ? students.some((elem) => elem.id === studentId) : undefined,
            isRejoined: studentId
                ? students.every((elem) => elem.id !== studentId) &&
                  eventRooms.flatMap((el) => el.students).some((st) => st.id === studentId)
                : undefined,
            isLocked: students.length === maxStudents,
            courseId: course.id,
            dayTitle: getCapitalizeMonth(datetimeStart),
        };
    });
};

import { getApiRequest } from 'api';

export interface PeriodMark {
    id: number;
    score: number | null;
    student: number;
    period: number;
    course: number;
    avgScore: number;
    passed: boolean | null;
    notCertified: boolean;
}

export interface PeriodMarkParams {
    period: number[];
    course: number;
    students: number[];
}

export const getPeriodMark = (params: PeriodMarkParams): Promise<PeriodMark[]> => {
    return getApiRequest(
        `/period-mark/?period=${params.period.join(',')}&course=${params.course}&students=${params.students.join(',')}`,
    );
};

export const getPeriodStudentMark = (params: { periods: number[]; id: number }): Promise<PeriodMark[]> => {
    return getApiRequest(`/period-mark/?period=${params.periods.join(',')}&students=${params.id}`);
};

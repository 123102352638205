import { useDispatch } from 'react-redux';
import { isFiltersUpdated } from 'src-new/components/lesson-task';
import { setQuestionsStateAction } from 'store/actions/lessonTask';
import { TAssignedQuestion, TSearchBlock, TSingleQuestion } from 'types/lessonTask';

export const useQuestionsMove = (props: {
    leftBlock: TAssignedQuestion;
    rightBlock: TAssignedQuestion;
    activeQuestionNumber: number;
}): {
    onBlockQuestionMove: (
        id: number,
        type: 'include' | 'exclude',
        rightBlockSelected?: TSearchBlock,
        leftBlockSelected?: TSearchBlock,
    ) => void;
    onAddSingleQuestion: (leftBlockSingleSelected?: TSingleQuestion, leftQuestionSelected?: TSingleQuestion) => void;
    onRemoveSingleQuestion: (rightQuestionSelected?: TSingleQuestion) => void;
} => {
    const { leftBlock, rightBlock, activeQuestionNumber } = props;

    const dispatch = useDispatch();

    const setState = (props: { left?: TAssignedQuestion; right?: TAssignedQuestion; questionNum: number }) => {
        dispatch(setQuestionsStateAction(props));
    };
    const getUpdatedData = (list: TAssignedQuestion, block: TSearchBlock, id: number, type: 'include' | 'exclude') => {
        return list.searchBlocksList?.map((item) => {
            if (item.id === block.id) {
                const list = item.filters?.excludeIds ?? [];
                const filteredIds = type === 'include' ? list.filter((item) => item !== id) : [...list, id];

                return {
                    ...item,
                    filters: {
                        ...item.filters,
                        excludeIds: filteredIds,
                    },
                };
            }
            return item;
        });
    };
    const onBlockQuestionMove = (
        id: number,
        type: 'include' | 'exclude',
        rightBlockSelected?: TSearchBlock,
        leftBlockSelected?: TSearchBlock,
    ) => {
        if (rightBlockSelected) {
            const updatedData = getUpdatedData(rightBlock, rightBlockSelected, id, type);

            setState({
                right: {
                    ...rightBlock,
                    searchBlocksList: updatedData,
                },
                questionNum: activeQuestionNumber,
            });
        }
        if (leftBlockSelected) {
            const updatedData = getUpdatedData(leftBlock, leftBlockSelected, id, type);

            setState({
                left: {
                    ...leftBlock,
                    searchBlocksList: updatedData,
                },
                questionNum: activeQuestionNumber,
            });
        }
    };
    const onAddSingleQuestion = (leftBlockSingleSelected?: TSingleQuestion, leftQuestionSelected?: TSingleQuestion) => {
        const filteredBlocksList = leftBlock?.searchBlocksList
            ?.map((block) => {
                if (leftBlockSingleSelected) {
                    return {
                        ...block,
                        questionsContentList: block.questionsContentList.filter(
                            ({ questionContent }) =>
                                questionContent?.id !== leftBlockSingleSelected?.questionContent?.id,
                        ),
                    };
                }
                return block;
            })
            .filter(Boolean);

        const rightBlockSingleQuestionsList = rightBlock.singleQuestionsList ?? ([] as TSingleQuestion[]);
        const leftBlockSingleQuestionsList = leftBlock.singleQuestionsList ?? ([] as TSingleQuestion[]);
        setState({
            left: {
                ...leftBlock,
                searchBlocksList: filteredBlocksList,
                singleQuestionsList: leftQuestionSelected
                    ? [...leftBlockSingleQuestionsList, leftQuestionSelected]
                    : leftBlockSingleQuestionsList,
            },
            right: {
                ...rightBlock,
                //@ts-ignore
                singleQuestionsList:
                    leftQuestionSelected || leftBlockSingleSelected
                        ? [...rightBlockSingleQuestionsList, leftQuestionSelected ?? leftBlockSingleSelected]
                        : rightBlockSingleQuestionsList,
            },
            questionNum: activeQuestionNumber,
        });
    };
    const onRemoveSingleQuestion = (rightQuestionSelected?: TSingleQuestion) => {
        const isRightSingleQuestionFromBlock = !!rightQuestionSelected?.filters?.topics?.length;
        const leftData =
            isRightSingleQuestionFromBlock && !!rightQuestionSelected
                ? {
                      ...leftBlock,
                      searchBlocksList: leftBlock.searchBlocksList.map((block) => {
                          if (!isFiltersUpdated(block.filters, rightQuestionSelected?.filters)) {
                              return {
                                  ...block,
                                  questionsContentList: [rightQuestionSelected, ...block.questionsContentList],
                              };
                          }
                          return block;
                      }),
                  }
                : undefined;

        setState({
            right: {
                ...rightBlock,
                singleQuestionsList: rightBlock.singleQuestionsList?.filter(
                    ({ questionContent }) => rightQuestionSelected?.questionContent?.id !== questionContent?.id,
                ),
            },
            left: leftData,
            questionNum: activeQuestionNumber,
        });
    };

    return {
        onBlockQuestionMove,
        onAddSingleQuestion,
        onRemoveSingleQuestion,
    };
};

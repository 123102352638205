import React from 'react';
import { cn } from '@bem-react/classname';

import './GroupAvatars.scss';

interface IClassBadgeProps {
    avatars: string[];
}

const CnGroupAvatars = cn('groupAvatars');

export const GroupAvatars: React.FC<IClassBadgeProps> = ({ avatars }) => {
    return (
        <div className={CnGroupAvatars()}>
            {avatars.slice(0, 4).map((avatar, index) => (
                <div key={String(index)} className={CnGroupAvatars('avatarWrapper')}>
                    <img src={avatar} alt="user avatar" className={CnGroupAvatars('avatar')} />
                </div>
            ))}
        </div>
    );
};

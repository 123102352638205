import { createSlice } from '@reduxjs/toolkit';
import { changePasswordAction, resetPasswordAction } from 'store/actions/passwordRecovery';
import { FetchStatus } from 'types/api';

export interface IPasswordRecoveryState {
    resetPasswordStatus: FetchStatus;
    resetPasswordError: null;
    changePasswordStatus: FetchStatus;
    changePasswordError: null;
    passwordRecoveryEmail?: string;
}

const initialState: IPasswordRecoveryState = {
    resetPasswordStatus: FetchStatus.INITIAL,
    resetPasswordError: null,
    changePasswordStatus: FetchStatus.INITIAL,
    changePasswordError: null,
};

const passwordRecoverySlice = createSlice({
    name: 'passwordRecovery',
    initialState,
    reducers: {
        setPasswordRecoveryEmail(state, action) {
            state.passwordRecoveryEmail = action.payload;
        },
        resetPasswordRecoveryStatuses(state) {
            state.resetPasswordStatus = FetchStatus.INITIAL;
            state.changePasswordStatus = FetchStatus.INITIAL;
        },
        resetPasswordRecoveryErrors(state) {
            state.resetPasswordError = null;
            state.resetPasswordError = null;
        },
    },
    /**
     *
     * @param builder
     */
    extraReducers: (builder) => {
        builder.addCase(changePasswordAction.pending, (state) => {
            state.changePasswordStatus = FetchStatus.FETCHING;
            state.changePasswordError = null;
        });
        builder.addCase(changePasswordAction.fulfilled, (state, { payload }) => {
            state.changePasswordStatus = FetchStatus.FETCHED;
            state.changePasswordError = null;
        });
        builder.addCase(changePasswordAction.rejected, (state, { error }) => {
            state.changePasswordStatus = FetchStatus.ERROR;
            // @ts-ignore
            state.changePasswordError = JSON.parse(error?.message);
        });
        builder.addCase(resetPasswordAction.pending, (state) => {
            state.resetPasswordStatus = FetchStatus.FETCHING;
            state.resetPasswordError = null;
        });
        builder.addCase(resetPasswordAction.fulfilled, (state) => {
            state.resetPasswordStatus = FetchStatus.FETCHED;
            state.resetPasswordError = null;
        });
        builder.addCase(resetPasswordAction.rejected, (state, { error }) => {
            state.resetPasswordStatus = FetchStatus.ERROR;
            // @ts-ignore
            state.resetPasswordError = JSON.parse(error?.message);
        });
    },
});

const {
    setPasswordRecoveryEmail,
    resetPasswordRecoveryStatuses,
    resetPasswordRecoveryErrors,
} = passwordRecoverySlice.actions;
export { resetPasswordRecoveryErrors, resetPasswordRecoveryStatuses, setPasswordRecoveryEmail };
export const passwordRecoveryReducer = passwordRecoverySlice.reducer;

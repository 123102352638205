import React from 'react';
import { AppText } from '@frontend-modules/ui-kit';
import { UserAvatar } from '@fsd-shared/ui-kit';
import { getUserName } from '@fsd-shared/utils';

import { TUserInfo } from './UserInfo.types';

import './UserInfo.style.scss';
export const UserInfo: React.FC<TUserInfo> = (props): JSX.Element => {
    const { user, isShort = false, withLogo = true, onUserClick } = props;

    const mask = isShort ? 'SS:_:N:_:P' : 'SS:_:NN:_:PP';
    const nameInitials = getUserName({ name: user.firstName, surname: user.lastName }, 'N:S', true);
    const name = getUserName(
        { name: user.firstName, surname: user.lastName, patronymic: user.patronymic },
        mask,
        isShort,
    );

    return (
        <div className={`user-info ${!!onUserClick && 'clickable'}`} onClick={onUserClick}>
            {withLogo && <UserAvatar initials={nameInitials} size={32} isOnline={user.online} />}
            <AppText text={name} textStyle={'DesktopFontBody'} />
        </div>
    );
};

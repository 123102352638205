import { getApiRequest } from 'api';
import { AssignmentProgressPreview } from 'types/assignmentProgress';

export interface GetHWPreviewParams {
    status?: string;
    student?: number;
    lesson?: number;
    my_course_groups?: string;
    available_for_execution?: boolean;
    is_mandatory?: boolean;
}

export const getAssignmentProgressPreview = (params: GetHWPreviewParams): Promise<AssignmentProgressPreview[]> => {
    if (!params.is_mandatory) {
        delete params.is_mandatory;
    }
    return getApiRequest('/assignment-progress/preview/', { params });
};

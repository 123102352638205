import React, { useCallback } from 'react';
import { cn } from '@bem-react/classname';
import { Title } from '@lms-elements/atomic';
import { TFile } from 'containers/OnlineLessonTab/FilesContainer/FilesContainer.types';
import { File } from 'src-new/components/lms-elements/File';

import './AddFiles.scss';

const CnAddFiles = cn('addFile');

export type FileProps = {
    files: TFile[];
    className?: string;
    canUpload?: boolean;
    canEdit?: boolean;
    onUpload?: (file: File) => void;
    onDeleteFile?: (fileId: number) => void;
};

const MAX_FILE_SIZE_MB = 100;
const maxFileSizeInBytes = MAX_FILE_SIZE_MB * 1024 * 1024;

export const AddFiles: React.FC<FileProps> = ({
    files = [],
    className = '',
    canUpload,
    canEdit,
    onUpload,
    onDeleteFile,
}: FileProps) => {
    const handleAddClick = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();

            if (onUpload && e.target.files) {
                const file = e.target.files[0];

                if (file && file.size < maxFileSizeInBytes) {
                    onUpload(file);
                }
            }
        },
        [onUpload],
    );

    const deleteFile = useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            const fileId = e.currentTarget.id;

            if (onDeleteFile) {
                onDeleteFile(Number(fileId));
            }
        },
        [onDeleteFile],
    );

    return (
        <div className={`${CnAddFiles()} ${className}`}>
            {canUpload ? (
                <Title name={'Файлы'} onClickAdd={handleAddClick} inscription={`объёмом до ${MAX_FILE_SIZE_MB}mb`} />
            ) : (
                <h3 className={CnAddFiles('header')}>Файлы</h3>
            )}
            {files.map((item, index) => {
                const fileName = `${item.title}.${item.extension}`;

                return (
                    <div
                        className={CnAddFiles('file-wrapper', { hasAttr: fileName.length > 55 })}
                        key={index}
                        data-title={fileName}
                    >
                        <File
                            key={index}
                            name={fileName.length > 55 ? `${fileName.slice(0, 30)}...${fileName.slice(-10)}` : fileName}
                            url={item.fileUrl}
                            id={String(item.id)}
                            deleteFile={deleteFile}
                            isTemplate={item.isTemplate}
                            canEdit={canEdit}
                            needHideSendButton
                            needHideEditButton
                        />
                    </div>
                );
            })}
        </div>
    );
};

import { ITestAnswerData, ITestCheckedAnswer, ITestQuestionResponse } from '@fsd-shared/models/materials';

export const normalizeTestAnswerData = (questionData: ITestQuestionResponse): ITestAnswerData => {
    const { randomAnswerOrder, answerOptions } = questionData || {};
    return {
        randomAnswerOrder: randomAnswerOrder,
        answerOptions: answerOptions?.map((answer, index) => {
            return {
                label:
                    answer.html ||
                    ((JSON.parse(answer.content) as Record<string, [Record<string, string>]>)['blocks'] as Array<{
                        text?: string;
                    }>)[0]['text'] ||
                    '',
                value: String(answer?.uuid || index),
            };
        }),
    };
};

export const normalizeTestCorrectAnswerData = (
    answerData: ITestAnswerData,
    correctAnswer: ITestCheckedAnswer,
): ITestAnswerData => {
    const { data, isCorrect } = correctAnswer;
    const { randomAnswerOrder, answerOptions } = answerData;
    return {
        randomAnswerOrder: randomAnswerOrder,
        answerOptions: answerOptions.map((item) => {
            const answerItem = data.selectedOptions?.find((option) => option.uuid === item.value);
            if (answerItem) {
                return { ...item, correctness: answerItem.isCorrect ? 'correct' : 'incorrect' };
            }
            return { ...item };
        }),
        correctness: isCorrect ? 'correct' : 'incorrect',
    };
};

import React from 'react';
import { cn } from '@bem-react/classname';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';

import './DraftTime.scss';

const cx = cn('draft-time');

export const DraftTime: React.FC = () => {
    const draftTime = useAppSelector((store) => store.answerAttempts.draftTime);
    const updateDraftStatus = useAppSelector((store) => store.answerAttempts.patchDraftStatus);

    if (draftTime) {
        return (
            <div className={cx()}>
                <div className={cx('info')}>Черновик сохранен в {draftTime}</div>
                {updateDraftStatus === FetchStatus.ERROR && (
                    <div className={cx('error')}>Последняя попытка сохранения была неудачной</div>
                )}
            </div>
        );
    }

    return null;
};

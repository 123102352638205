import React, { useCallback, useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Button, ButtonViewEnum } from '@lms-elements/atomic';

import { AttendanceMarkForm, MarkField } from 'components/AttendanceMark/AttendanceMarkForm';

import { IRateButtonProps } from './RateButton.types';

import './RateButton.scss';

const CnRateButton = cn('studentTaskRateButton');

export const RateButton: React.FC<IRateButtonProps> = ({ onAddMark }) => {
    const [isAddMarkFormOpen, setIsAddMarkFormOpen] = useState(false);

    const handleRateButtonClick = useCallback(() => {
        setIsAddMarkFormOpen(true);
    }, []);

    const handleAddMarkFormClose = useCallback(() => {
        setIsAddMarkFormOpen(false);
    }, []);

    const handleAddMark = useCallback(
        ({ mark }: { mark: MarkField[] }) => {
            onAddMark?.(mark);
            setIsAddMarkFormOpen(false);
        },
        [onAddMark],
    );
    const addMarkInitList = useMemo(() => [{ comment: '', markId: 0, valueCanChange: true }], []);

    return (
        <div>
            {isAddMarkFormOpen && <div className={CnRateButton('formBackground')} onClick={handleAddMarkFormClose} />}
            <div className={CnRateButton('formContainer')}>
                <Button size="m" view={ButtonViewEnum.action} onClick={handleRateButtonClick}>
                    Оценить
                </Button>
                {isAddMarkFormOpen && (
                    <>
                        <div className={CnRateButton('addMarkFormWrapper')}>
                            <AttendanceMarkForm
                                initialValue={addMarkInitList}
                                onSubmit={handleAddMark}
                                closeModal={handleAddMarkFormClose}
                                withoutSubmitType
                                onceMark
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

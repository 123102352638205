import { useMemo } from 'react';
import { TAssignedQuestion } from 'types/lessonTask';

export const useGetQuestionModalControls = (props: {
    leftBlock: TAssignedQuestion;
    rightBlock: TAssignedQuestion;
    openedQuestionId?: number;
}): {
    questionsIdsToPaginate: number[] | undefined;
    hasAddButton: boolean;
    hasRemoveButton: boolean;
    hasIncludeButton: boolean;
    hasExcludeButton: boolean;
    canPaginateRight: boolean;
    canPaginateLeft: boolean;
} => {
    const { leftBlock, rightBlock, openedQuestionId = 0 } = props;

    const questionsIdsToPaginate = useMemo(() => {
        //@ts-ignore
        const listFromLeftBlock: number[] | undefined =
            leftBlock?.searchBlocksList
                ?.find(
                    ({ questionsContentList }) =>
                        !!questionsContentList.some(({ questionContent }) => questionContent?.id === openedQuestionId),
                )
                ?.questionsContentList.map((item) => item?.questionContent?.id)
                .filter(Boolean) ?? undefined;
        //@ts-ignore
        const listFromRightBlock: number[] | undefined =
            rightBlock?.searchBlocksList
                ?.find(
                    ({ questionsContentList }) =>
                        !!questionsContentList.some(({ questionContent }) => questionContent?.id === openedQuestionId),
                )
                ?.questionsContentList.map((item) => item?.questionContent?.id)
                .filter(Boolean) ?? undefined;

        return listFromLeftBlock ?? listFromRightBlock;
    }, [openedQuestionId]);

    const hasIncludeButton = useMemo(() => {
        const rightBlockSelected = rightBlock?.searchBlocksList?.find(
            ({ questionsContentList }) =>
                !!questionsContentList.some(({ questionContent }) => questionContent?.id === openedQuestionId),
        );
        const leftBlockSelected = leftBlock?.searchBlocksList?.find(
            ({ questionsContentList }) =>
                !!questionsContentList.some(({ questionContent }) => questionContent?.id === openedQuestionId),
        );

        const excludeQuestionsIds = rightBlockSelected
            ? rightBlockSelected?.filters.excludeIds ?? []
            : leftBlockSelected?.filters.excludeIds ?? [];

        return excludeQuestionsIds.includes(openedQuestionId ?? 0);
    }, [rightBlock, leftBlock, openedQuestionId]);
    const hasExcludeButton = useMemo(() => {
        const rightBlockSelected = rightBlock.searchBlocksList?.find(
            ({ questionsContentList }) =>
                !!questionsContentList.find(({ questionContent }) => questionContent?.id === openedQuestionId),
        );
        const leftBlockSelected = leftBlock.searchBlocksList?.find(
            ({ questionsContentList }) =>
                !!questionsContentList.find(({ questionContent }) => questionContent?.id === openedQuestionId),
        );

        const excludeQuestionsIds = rightBlockSelected
            ? rightBlockSelected?.filters.excludeIds ?? []
            : leftBlockSelected?.filters.excludeIds ?? [];

        return (!!rightBlockSelected || !!leftBlockSelected) && !excludeQuestionsIds.includes(openedQuestionId ?? 0);
    }, [openedQuestionId, rightBlock, leftBlock]);

    const hasAddButton = useMemo(
        () =>
            !!leftBlock.searchBlocksList?.find(
                ({ questionsContentList }) =>
                    !!questionsContentList?.some(({ questionContent }) => questionContent?.id === openedQuestionId),
            ) && !hasIncludeButton,
        [leftBlock, rightBlock, openedQuestionId],
    );
    const hasRemoveButton = useMemo(
        () => rightBlock?.singleQuestionsList?.some(({ questionContent }) => questionContent?.id === openedQuestionId),
        [rightBlock, openedQuestionId],
    );
    const canPaginateRight = useMemo(() => {
        return questionsIdsToPaginate && openedQuestionId
            ? questionsIdsToPaginate?.findIndex((item) => item === openedQuestionId) <
                  questionsIdsToPaginate?.length - 1
            : false;
    }, [openedQuestionId]);

    const canPaginateLeft = useMemo(() => {
        return questionsIdsToPaginate && openedQuestionId
            ? questionsIdsToPaginate?.findIndex((item) => item === openedQuestionId) !== 0
            : false;
    }, [openedQuestionId]);

    return {
        questionsIdsToPaginate,
        hasAddButton,
        hasRemoveButton,
        hasIncludeButton,
        hasExcludeButton,
        canPaginateRight,
        canPaginateLeft,
    };
};

import { getApiRequest } from 'api';
import { QuestionStatus } from 'api/services/questionBank';
import { DifficultyEnum } from 'api/services/questionCreate';
import {
    EssayQuestionData,
    InsertWordQuestionData,
    TestQuestionData,
    TextQuestionData,
    TrueFalseQuestionData,
} from 'types/question';
import { User } from 'types/user';

import { AnswersTypeEnum } from 'components/QuestionPopUp/QuestionPopUp.types';

export interface QuestionForReview {
    id: number;
    text: string;
    content: string;
    html: string;
    difficulty?: DifficultyEnum;
    answersType?: AnswersTypeEnum;
    status?: QuestionStatus;
    timestamp?: string;
    author: User;
    topics?: {
        id: number;
        title: string;
    }[];
    skills?: {
        id: number;
        title: string;
    }[];
    data?: TestQuestionData | InsertWordQuestionData | TrueFalseQuestionData | EssayQuestionData | TextQuestionData;
    revisions?: {
        id: number;
        comment: string;
        dateCreate: string;
    }[];
    maximumScore?: number;
}

export interface QuestionsReviewData {
    questions: QuestionForReview[];
    countQuestionUnpublished: number;
}
export const getQuestionsForReview = (parallel?: number): Promise<QuestionsReviewData> => {
    return getApiRequest('/codifier/question/review/', { params: { parallels: parallel } });
};

export interface IQuestionResponse {
    id: number;
    text: string;
    content?: string;
    html?: string;
    isActual: boolean;
    difficulty: EQuestionDifficulty;
    status: TQuestionStatusType;
    author: IQuestionAuthor;
    topics: { id: number }[];
    skills: {
        id: number;
        title: string;
    }[];
    multiply?: boolean | null;
    answersType: TQuestionType;
    timestamp: string;
    data: ITestQuestionResponse;
    revisions?: {
        id: number;
        comment: string;
        dateCreate: string;
    }[];
}

export interface ITestQuestionResponseOption {
    content: string;
    html: string;
    text: string;
    uuid?: string;
}

export type ITestQuestionResponse = {
    randomAnswerOrder: boolean;
    answerOptions: Array<ITestQuestionResponseOption>;
};

export interface IQuestionAuthor {
    id?: number;
    firstName: string;
    lastName: string;
    patronymic: string | null;
    online?: boolean;
    lastSeen?: string;
    mainRole: string;
}

export enum EQuestionDifficulty {
    easy = 'easy',
    medium = 'medium',
    hard = 'hard',
}

export type TQuestionStatusType = 'published' | 'not_published' | 'on_revision';

export enum EQuestionStatus {
    published = 'Утверждено',
    on_revision = 'На доработке',
    not_published = 'Не утверждено',
}

export type TQuestionType = 'test' | 'text' | 'true_false' | 'insert_words';

// данные для проверки правильности ответа
export interface ITrueFalseQuestionAnswer {
    answer: boolean;
}
export interface ITestQuestionAnswer {
    selectedOptions: Array<string>;
}
export interface ITextQuestionAnswer {
    selectedOption: string;
}
export interface IInsertWordsQuestionAnswer {
    selectedOptions: Array<{ gapIndex: number; selectedWord: string }>;
}

// проверенный ответ

export interface ITrueFalseCheckedAnswer {
    data: { answer: boolean };
    isCorrect: boolean;
}
export interface ITestCheckedAnswer {
    data: { selectedOptions: Array<{ uuid: string; isCorrect: boolean }> };
    incomplete: boolean;
    isCorrect: boolean;
}
export interface ITextCheckedAnswer {
    data: { selectedOption: string };
    isCorrect: boolean;
}
export interface IInsertWordsCheckedAnswer {
    data: { selectedOptions: Array<{ selectedWord: string; isCorrect: boolean; gapIndex: number }> };
    incomplete: boolean;
    isCorrect: boolean;
}

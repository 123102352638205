import { convertFromRaw, RawDraftContentState } from 'draft-js';
import { E_ANSWERS_TYPE, E_QUESTIONS_DIFFICULTY } from 'src-new/constants';
import { getAnswersCountText, getCorrectAnswersCountText, getSkillsCountText } from 'src-new/utils';
import { TAnswer, TResponseQuestionDetailed, TTextAnswer } from 'types/lessonTask';
import {
    EssayQuestionData,
    InsertWordQuestionData,
    TestQuestionData,
    TextQuestionData,
    TrueFalseQuestionData,
} from 'types/question';

import { getInsertWordsPreviewHtml } from 'utils/questions';

export const useGetQuestionInfo = (
    questionDetails: TResponseQuestionDetailed,
): {
    correctAnswersList: (TAnswer & TTextAnswer)[];
    answersInfo: {
        count: number;
        statistic: string;
    };
    correctAnswersInfo: {
        count: number;
        statistic: string;
    };
    topicsInfo: {
        count: number;
        text: string;
    };
    skillsInfo: {
        count: number;
        text: string;
        statistic: string;
    };
    questionTitle: string;
    authorFullName: string;
    essayAnswerFormat: string;
    difficultyText: string;
    answersType: E_ANSWERS_TYPE;
    data: TestQuestionData | InsertWordQuestionData | TrueFalseQuestionData | EssayQuestionData | TextQuestionData;
    id: number;
} => {
    const { text, difficulty, answersType, data, author, topics, skills, html, content, id } = questionDetails;

    const getAllAnswers = () => {
        switch (answersType) {
            case E_ANSWERS_TYPE.test:
                return (data as TestQuestionData).answerOptions?.map((option, index) => ({
                    ...option,
                    id: index,
                    order: index,
                    isCorrect: option.correct,
                    acceptFile: false,
                    acceptText: false,
                    percentOfScore: 100,
                }));
            case E_ANSWERS_TYPE.text:
                return (data as TextQuestionData).answerOptions?.map((option, index) => ({
                    ...option,
                    content: '{}',
                    html: `<span>${option.text}</span>`,
                    id: index,
                    order: index,
                    isCorrect: true,
                    acceptFile: false,
                    acceptText: false,
                    percentOfScore: option.percentOfScore,
                }));
            default:
                return [];
        }
    };
    const getEssayAnswerFormat = () => {
        if (answersType === E_ANSWERS_TYPE.essay && data !== undefined && 'acceptFile' in data) {
            if (data?.acceptFile && !data.acceptText) return 'Файл';
            if (!data?.acceptFile && data?.acceptText) return 'Текстовое поле';
            if (data?.acceptFile && data.acceptText) return 'Файл, текстовое поле';
        }
        return '';
    };
    const getCorrectAnswersList = () => {
        const answers = getAllAnswers();

        return answers.map((answer) => {
            const rawAnswer = JSON.parse(answer.content) as TTextAnswer | RawDraftContentState;
            const isEmptyAnswer = answer.content === '{}';
            const text = isEmptyAnswer
                ? ''
                : Object.keys(rawAnswer).length === 1
                ? (rawAnswer as TTextAnswer).text
                : convertFromRaw(rawAnswer as RawDraftContentState).getPlainText();
            return {
                ...answer,
                text,
            };
        });
    };

    const totalAnswersCount =
        answersType === E_ANSWERS_TYPE.test
            ? (data as TestQuestionData)?.answerOptions.length
            : answersType === E_ANSWERS_TYPE.text
            ? (data as TextQuestionData).answerOptions.length
            : answersType === E_ANSWERS_TYPE.true_false
            ? 2
            : 0;

    const correctAnswersCount =
        answersType === E_ANSWERS_TYPE.test
            ? (data as TestQuestionData)?.answerOptions.filter((answer) => answer?.correct).length
            : answersType === E_ANSWERS_TYPE.true_false
            ? 1
            : 0;

    const questionTitle =
        answersType === E_ANSWERS_TYPE.insert_words
            ? getInsertWordsPreviewHtml({ content, html, text: text ?? '' })
            : html || text || '';

    const authorFullName = `${author?.lastName} ${author?.firstName} ${author?.patronymic}`.trim();
    const difficultyText = difficulty ? E_QUESTIONS_DIFFICULTY[difficulty] : '';
    const correctAnswersInfo = {
        count: correctAnswersCount,
        statistic: getCorrectAnswersCountText(correctAnswersCount),
    };
    const answersInfo = {
        count: totalAnswersCount,
        statistic: getAnswersCountText(totalAnswersCount),
    };
    const topicsInfo = {
        count: topics?.length,
        text: topics?.map((topic) => topic?.title).join('; '),
    };
    const skillsInfo = {
        count: skills?.length,
        text: skills?.map((skill) => skill?.title).join('; '),
        statistic: getSkillsCountText(skills?.length),
    };

    return {
        correctAnswersList: getCorrectAnswersList(),
        essayAnswerFormat: getEssayAnswerFormat(),
        correctAnswersInfo,
        answersInfo,
        topicsInfo,
        skillsInfo,
        questionTitle,
        authorFullName,
        difficultyText,
        answersType,
        data,
        id,
    };
};

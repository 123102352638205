export enum UserRoles {
    PARENT = 'parent',
    STUDENT = 'student',
    TEACHER = 'teacher',
    METHODIST = 'methodist',
    CURATOR = 'curator',
    PRINCIPAL = 'principal',
    SUPPORT = 'support',
    ILSTUDENT = 'il',
    PROCTOR = 'proctor',
    TUTOR = 'tutor',
    NEWS_EDITOR = 'news_editor',
    LIBRARIAN = 'librarian',
}

export interface User {
    id: number;
    firstName: string;
    lastName: string;
    patronymic: string | null;
    photoUrl?: string;
    online: boolean;
    lastSeen: string;
    useRoles: string[];
    atMeeting: boolean;
}
export type TUserCommon = {
    id: number;
    firstName: string;
    lastName: string;
    patronymic: string;
    photoUrl?: string;
    online: boolean;
    lastSeen: string;
    mainRole: string;
};

import { HARD_MODAL_ENUMS } from 'src-new/components/layouts/Modals/Modals.config';
import { useQueryPopupsParams } from 'src-new/hooks';
import { TLessonQuery } from 'types/lessonTask';

import { TLessonPopup, TLessonPopupParams } from './useLessonsPopup.types';

const useLessonsPopup = (): TLessonPopup => {
    const Popup = useQueryPopupsParams();

    const getQuery = (params: TLessonPopupParams) => {
        const { courseGroupId, courseId, subjectId, lessonId, taskId, tab = 'materials', type = 'LESSON' } = params;

        const query: TLessonQuery = {
            type: HARD_MODAL_ENUMS[type],
            tab: tab,
            courseGroupId,
            lessonId,
        };
        if (taskId) {
            query.taskId = taskId;
        }
        if (courseId) {
            query.courseId = courseId;
        }
        if (subjectId) {
            query.subjectId = subjectId;
        }

        return query;
    };

    const openLessonPopup = (params: TLessonPopupParams) => {
        Popup.openPopup(getQuery(params));
    };

    const closeLastLessonPopup = () => {
        Popup.closeLastPopup();
    };

    const replaceLastLessonPopup = (params: TLessonPopupParams) => {
        Popup.replaceLastPopup(getQuery(params));
    };

    const isCurrent = Popup.isCurrentPopup(HARD_MODAL_ENUMS.LESSON);

    return {
        openLessonPopup,
        closeLastLessonPopup,
        replaceLastLessonPopup,
        isCurrent,
    };
};

export default useLessonsPopup;

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    deleteCodifierBlock,
    deleteCodifierMetasubject,
    deleteCodifierParallel,
    deleteCodifierSection,
    deleteCodifierSkill,
    deleteCodifierTopic,
    fetchCodifierData,
    fetchCodifierSkillsData,
    getCodifierDataByTopics,
    getCodifierSubjectData,
    getCodifierSubjects,
    patchBlockOrdering,
    patchCodifierBlock,
    patchCodifierMetasubject,
    patchCodifierParallel,
    patchCodifierSection,
    patchCodifierSkill,
    patchCodifierTopic,
    patchSectionOrdering,
    patchThemeOrdering,
    postCodifierBlock,
    postCodifierMetasubject,
    postCodifierParallel,
    postCodifierSection,
    postCodifierSkill,
    postCodifierTopic,
} from 'api/services/codifier';

export const fetchCodifierDataAction = createAsyncThunk('codifier/fetchCodifierData', async () => {
    return await fetchCodifierData();
});

export const postCodifierParallelAction = createAsyncThunk(
    'codifier/addParallel',
    async (params: { grade: number; subject: number }) => {
        return await postCodifierParallel(params);
    },
);

export const deleteCodifierParallelAction = createAsyncThunk('codifier/deleteParallel', async (id: number) => {
    return await deleteCodifierParallel(id);
});

export const postCodifierSectionAction = createAsyncThunk(
    'codifier/addSectionWithOrder',
    async (params: { title: string; parallel: number; order: number; ordering?: number[] }) => {
        return await postCodifierSection(params).then((newSection) => {
            const newOrdering = params.ordering
                ? [...params.ordering.slice(0, params.order), newSection.id, ...params.ordering.slice(params.order)]
                : [params.order];

            return Promise.all([
                patchSectionOrdering({ id: String(params.parallel), ordering: newOrdering }),
                params.parallel,
                params.title,
            ]);
        });
    },
);

export const deleteCodifierSectionAction = createAsyncThunk(
    'codifier/deleteSection',
    async (params: { id: number; ordering?: number[]; parallelId: number }) => {
        const { id, ordering, parallelId } = params;
        const filteredOrdering = ordering?.filter((el) => el !== id);

        return await deleteCodifierSection(id).then(() => {
            if (filteredOrdering) {
                void patchSectionOrdering({ id: String(parallelId), ordering: filteredOrdering });
                return id;
            }
        });
    },
);

export const postCodifierBlockAction = createAsyncThunk(
    'codifier/addBlock',
    async (params: { title: string; section: number; order: number; ordering?: number[]; parallel: number }) => {
        return await postCodifierBlock(params).then((newBlock) => {
            const newOrdering = params.ordering
                ? [...params.ordering.slice(0, params.order), newBlock.id, ...params.ordering.slice(params.order)]
                : [params.order];
            return Promise.all([
                patchBlockOrdering({ id: String(params.section), ordering: newOrdering }),
                params.parallel,
                params.title,
                params.section,
            ]);
        });
    },
);

export const deleteCodifierBlockAction = createAsyncThunk(
    'codifier/deleteBlock',
    async (params: { id: number; ordering?: number[]; sectionId: number }) => {
        const { id, ordering, sectionId } = params;
        const filteredOrdering = ordering?.filter((el) => el !== id);

        return await deleteCodifierBlock(id).then(() => {
            if (filteredOrdering) {
                void patchBlockOrdering({ id: String(sectionId), ordering: filteredOrdering });
                return id;
            }
        });
    },
);

export const postCodifierTopicAction = createAsyncThunk(
    'codifier/addCodifierTopic',
    async (params: {
        title: string;
        block: number;
        section: number;
        order: number;
        ordering?: number[];
        parallel: number;
    }) => {
        return await postCodifierTopic(params).then((newTopic) => {
            const newOrdering =
                params.ordering && params.order
                    ? [
                          ...params.ordering.slice(0, params.order - 1),
                          newTopic.id,
                          ...params.ordering.slice(params.order - 1),
                      ]
                    : [params.order];

            return Promise.all([patchThemeOrdering({ id: String(params.block), ordering: newOrdering })]);
        });
    },
);

export const deleteCodifierTopicAction = createAsyncThunk(
    'codifier/deleteCodifierTopic',
    async (params: { id: number; ordering?: number[]; blockId: number }) => {
        const { id, ordering, blockId } = params;
        const filteredOrdering = ordering?.filter((el) => el !== id);

        return await deleteCodifierTopic(id).then(() => {
            if (filteredOrdering && blockId) {
                void patchThemeOrdering({ id: String(blockId), ordering: filteredOrdering });

                return { id, blockId };
            }
            return { id };
        });
    },
);

export const fetchCodifierSkillsDataAction = createAsyncThunk('codifier/fetchCodifierSkills', async () => {
    return await fetchCodifierSkillsData();
});

// Skills
export const postCodifierSkillAction = createAsyncThunk(
    'codifier/addSkill',
    async (params: { title: string; metasubject: number }) => {
        return await postCodifierSkill(params);
    },
);

export const deleteCodifierSkillAction = createAsyncThunk('codifier/deleteSkill', async (id: number) => {
    return await deleteCodifierSkill(id);
});

export const postCodifierMetasubjectAction = createAsyncThunk(
    'codifier/addMetasubject',
    async (params: { title: string; parallel: number }) => {
        return await postCodifierMetasubject(params);
    },
);

export const deleteCodifierMetasubjectAction = createAsyncThunk('codifier/deleteMetasubject', async (id: number) => {
    return await deleteCodifierMetasubject(id);
});

export const patchCodifierParallelAction = createAsyncThunk(
    'codifier/patchParallel',
    async (value: { id: number; grade: number }) => {
        return await patchCodifierParallel(value);
    },
);

export const patchCodifierSectionAction = createAsyncThunk(
    'codifier/patchSection',
    async (value: {
        id: number;
        order?: number;
        ordering?: number[];
        parallelOrdering?: number[];
        title?: string;
        parallel?: number;
        subject?: number;
        oldParallel?: number;
    }) => {
        const { id, title, parallel, parallelOrdering, oldParallel, order, ordering } = value;
        const filteredOrdering = ordering?.filter((el) => el !== id);
        let newOrderingInCurrentSection: number[] = [];

        if (parallelOrdering && order !== undefined) {
            //при перемещении
            newOrderingInCurrentSection = [...parallelOrdering.slice(0, order), id, ...parallelOrdering.slice(order)];
        } else if (filteredOrdering && order) {
            //при редактировании
            if (order === 1) {
                newOrderingInCurrentSection = [id, ...filteredOrdering];
            } else {
                newOrderingInCurrentSection = [
                    ...filteredOrdering.slice(0, order - 1),
                    id,
                    ...filteredOrdering.slice(order),
                ];
            }
        }

        return await Promise.all([
            patchCodifierSection(value).then(() => {
                if (filteredOrdering && oldParallel) {
                    void patchSectionOrdering({ id: String(oldParallel), ordering: filteredOrdering });
                }
                return patchSectionOrdering({ id: String(parallel), ordering: newOrderingInCurrentSection });
            }),
            String(title),
        ]);
    },
);

export const patchCodifierBlockAction = createAsyncThunk(
    'codifier/patchBlock',
    async (value: {
        id: number;
        title?: string;
        section?: number;
        sectionOrdering?: number[];
        parallel?: number;
        subject?: number;
        oldSection?: number;
        ordering?: number[];
        order?: number;
    }) => {
        const { id, title, section, sectionOrdering, oldSection, order, ordering } = value;
        const filteredOrdering = ordering?.filter((el) => el !== id);
        let newOrderingInCurrentSection: number[] = [];

        if (sectionOrdering && order !== undefined) {
            //при перемещении
            newOrderingInCurrentSection = [...sectionOrdering.slice(0, order), id, ...sectionOrdering.slice(order)];
        } else if (filteredOrdering && order) {
            //при редактировании
            if (order === 1) {
                newOrderingInCurrentSection = [id, ...filteredOrdering];
            } else {
                newOrderingInCurrentSection = [
                    ...filteredOrdering.slice(0, order - 1),
                    id,
                    ...filteredOrdering.slice(order),
                ];
            }
        }

        return await Promise.all([
            patchCodifierBlock(value).then(() => {
                if (filteredOrdering && oldSection) {
                    void patchBlockOrdering({ id: String(oldSection), ordering: filteredOrdering });
                }
                return patchBlockOrdering({ id: String(section), ordering: newOrderingInCurrentSection });
            }),
            String(title),
        ]);
    },
);

export const patchCodifierTopicAction = createAsyncThunk(
    'codifier/patchCodifierTopic',
    async (value: {
        id: number;
        title?: string;
        section?: number;
        block: number;
        subject?: number;
        oldSection?: number;
        oldBlock?: number;
        parallel?: number;
        newPlaceOrdering?: number[];
        ordering?: number[];
        order?: number;
    }) => {
        const { id, title, ordering, order, block, oldBlock, oldSection, newPlaceOrdering } = value;

        const filteredOrdering = ordering?.filter((el) => el !== value.id);
        let newOrderingInCurrentSection: number[] = [];

        if (newPlaceOrdering && order !== undefined) {
            //при перемещении
            newOrderingInCurrentSection = [
                ...newPlaceOrdering.slice(0, order - 1),
                id,
                ...newPlaceOrdering.slice(order - 1),
            ];
        } else if (!oldBlock && !oldSection && order && filteredOrdering) {
            //при редактировании
            if (order === 1) {
                newOrderingInCurrentSection = [id, ...filteredOrdering];
            } else {
                newOrderingInCurrentSection = [
                    ...filteredOrdering.slice(0, order - 1),
                    id,
                    ...filteredOrdering.slice(order),
                ];
            }
        }

        return await Promise.all([
            patchCodifierTopic(value).then(() => {
                if (oldBlock && oldSection && filteredOrdering) {
                    void patchThemeOrdering({ id: String(oldBlock), ordering: filteredOrdering });
                }
                return order && block
                    ? patchThemeOrdering({ id: String(block), ordering: newOrderingInCurrentSection })
                    : null;
            }),
            String(title),
        ]);
    },
);

export const patchCodifierMetasubjectAction = createAsyncThunk(
    'codifier/patchMetasubject',
    async (value: { id: number; title: string }) => {
        return await patchCodifierMetasubject(value);
    },
);

export const patchCodifierSkillAction = createAsyncThunk(
    'codifier/patchSkill',
    async (value: { id: number; title: string }) => {
        return await patchCodifierSkill(value);
    },
);

export const getCodifierSubjectsAction = createAsyncThunk('codifier/getSubjects', () => getCodifierSubjects());

export const getSubjectsInCodifierAction = createAsyncThunk('codifier/getSubjectsInCodifier', () =>
    getCodifierSubjects(),
);

export const getCodifierSubjectDataAction = createAsyncThunk('codifier/getSubjectData', (id: number) =>
    getCodifierSubjectData(id),
);

export const getCodifierDataByTopicsAction = createAsyncThunk('codifier/getSubjectDataByTopics', (topics: number[]) =>
    getCodifierDataByTopics(topics),
);

import { CuratorDashboard } from 'api/services/curatorDashboard';
import { CuratorLesson } from 'types/lesson';

export const getLessons = (curatorDashboard: CuratorDashboard[]): CuratorLesson[] => {
    const lessons = curatorDashboard.map((curator) => {
        const { lesson, courseGroup, usersInMeeting } = curator;
        const usersInMeetingId = usersInMeeting.map((user) => user.id);
        return {
            id: curator.lesson.id,
            lessonScheduleId: curator.id,
            groupName: courseGroup.title,
            studentsCount: courseGroup.students.length,
            lessonName: lesson.title,
            courseName: courseGroup.title,
            students: courseGroup.students
                .map((student) => {
                    const { id, firstName, lastName, photoUrl } = student;
                    return { id, firstName, lastName, photoUrl };
                })
                .filter((student) => {
                    const user = usersInMeeting.find((user) => user.id === student.id);
                    return user && !user.hasWebcam;
                }),
            studentsInLesson: courseGroup.students.filter((student) => usersInMeetingId.includes(student.id)).length,
        };
    });
    return lessons;
};

import React from 'react';
import { useDrop } from 'react-dnd';
import { getUid, isEmptyList } from '@frontend-modules/frontend-utils';
import { emptySubject } from 'assets';
import {
    CommonDragLayer,
    QuestionsBlockContainerDrag,
    SingleQuestionContainerDrag,
    TDropArea,
    TDroppedData,
    TQuestionsBlockContainerDrag,
} from 'src-new/components/lesson-task';

import './style.scss';
/**
 * @description DropArea - область для дропа элементов
 * @props TDropArea
 * */
export const DropArea: React.FC<TDropArea> = (props) => {
    const {
        questions,
        areaName,
        selectedBlock,
        handleDropItem,
        handleBlockSettingsClick,
        canDragQuestions = false,
    } = props;

    const [, drop] = useDrop(
        () => ({
            accept: ['block', 'single', 'block-single'],
            drop: (item, monitor) => {
                const type = monitor.getItemType();

                if (type === 'single' || type === 'block-single') {
                    const dragObject = item as TQuestionsBlockContainerDrag;
                    const data: TDroppedData = {
                        areaName: areaName,
                        type: type,
                        data: dragObject.dragData,
                    };
                    handleDropItem(data);
                }
                if (type === 'block') {
                    const dragObject = item as TQuestionsBlockContainerDrag;
                    const data: TDroppedData = {
                        areaName: areaName,
                        type: 'block',
                        data: dragObject.dragData,
                    };
                    handleDropItem(data);
                }
                return undefined;
            },
        }),
        [handleDropItem],
    );

    return (
        <div ref={drop} className={'drop-area'}>
            <CommonDragLayer />
            {questions?.singleQuestionsList
                ?.filter(({ questionContent }) => !!questionContent)
                .map((value) => {
                    const { filters, questionContent } = value;
                    const canDrag = canDragQuestions || !isEmptyList(filters?.topics);

                    return (
                        <SingleQuestionContainerDrag
                            key={questionContent?.id}
                            mainProps={{
                                question: questionContent,
                                isActiveBorder: true,
                            }}
                            dragData={value}
                            canDrag={canDrag}
                        />
                    );
                })}
            {questions?.searchBlocksList?.map((value, index) => {
                const isActive = selectedBlock?.number === index + 1 && selectedBlock?.side === areaName;
                return (
                    <QuestionsBlockContainerDrag
                        key={value.id ?? getUid()}
                        mainProps={{
                            questionsList: value.questionsContentList,
                            searchNum: index + 1,
                            canDragQuestions: canDragQuestions,
                            areaName,
                            excludeIds: value.filters.excludeIds,
                            isActiveBlock: isActive,
                            handleSettingsClick: handleBlockSettingsClick.bind(this, value, index + 1),
                        }}
                        dragData={value}
                    />
                );
            })}
            {areaName === 'right' &&
                !questions?.singleQuestionsList?.length &&
                !questions?.searchBlocksList?.length && (
                    <img className={'drop-area__empty-list'} src={emptySubject}></img>
                )}
        </div>
    );
};

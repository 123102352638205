import { postApiRequest } from 'api';
import { MarkScore } from 'types/mark';

import { AttendanceMarkResponse } from '.';

export interface PostAttandenceMarkParams {
    score?: MarkScore;
    comment?: string | null;
    attendanceLesson: number;
}

export const createAttendanceMark = async (params: PostAttandenceMarkParams): Promise<AttendanceMarkResponse> => {
    return await postApiRequest<AttendanceMarkResponse, PostAttandenceMarkParams>(
        `/mark-attendance-lesson/create/`,
        params,
    );
};

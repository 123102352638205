import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMeetingStatistics } from 'api/services/curatorDashboard';
import { getStudentGroups } from 'api/services/studentGroups/getStudentGroups';

export const getCuratorDashboardAction = createAsyncThunk('curatorDashboard/getCuratorDashboard', async () => {
    const statistics = await getMeetingStatistics();
    const studentGroups = await getStudentGroups();

    return statistics.map((statistic) => {
        const { courseGroup, lesson, subject } = statistic;

        const studentsExtended =
            studentGroups
                .find((studentGroup) => studentGroup.title === courseGroup.title)
                ?.students.filter((student) => courseGroup.students.includes(student.id))
                .map((student) => ({
                    ...student,
                    subject,
                    lesson,
                    courseGroup: { id: courseGroup.id, title: courseGroup.title },
                })) || [];

        const teachersExtended = courseGroup.teachers.map((teacher) => ({
            ...teacher,
            groupName: courseGroup.title,
            studentsCount: studentsExtended.length,
            lessonName: lesson.title,
        }));

        return {
            ...statistic,
            courseGroup: {
                ...courseGroup,
                students: studentsExtended,
                teachers: teachersExtended,
            },
        };
    });
});

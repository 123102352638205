import React, { useEffect, useRef, useState } from 'react';

import { TAppScrollPaginateContainerProps } from './AppScrollPaginateContainer.types';

import './style.scss';

export const AppScrollPaginateContainer = (props: TAppScrollPaginateContainerProps): JSX.Element => {
    const listInnerRef = useRef<HTMLDivElement>(null);
    const [currPage, setCurrPage] = useState(2); // первая страница и так всегда запрошена к моменту скролла

    const { customClassName, children, maxPage, needReset, onScrollHandle, maxHeight = '100%' } = { ...props };

    useEffect(() => {
        if (needReset) {
            setCurrPage(2);
        }
    }, [needReset]);

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
            const isLastPage = maxPage === 1 || currPage > maxPage;

            if (scrollTop + clientHeight >= scrollHeight && !isLastPage) {
                setCurrPage(currPage + 1);
                onScrollHandle(currPage);
            }
        }
    };

    return (
        <div
            ref={listInnerRef}
            style={{ maxHeight: maxHeight }}
            onScroll={onScroll}
            className={`scrollable-container ${customClassName || ''}`}
        >
            {children}
        </div>
    );
};

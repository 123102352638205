import { LearningMaterialsContent } from 'api/services/materials';

import { checkIsExerciseFull } from './checkIsExerciseFull';

export const getExerciseQuestions = (materials: LearningMaterialsContent[]): number[] => {
    const questions = materials.reduce((questions, material) => {
        if (checkIsExerciseFull(material)) {
            questions.add(Number(material.content));
        }

        return questions;
    }, new Set<number>());

    return Array.from(questions);
};

import { postApiRequest } from 'api';
import {
    TCourseGroupMeetingFile,
    TCreateCourseGroupMeetingFilePayload,
    TCreateCourseMeetingFilePayload,
    TGetCourseGroupMeetingFiles,
    TGetCourseGroupMeetingFilesPayload,
    TGetCourseMeetingFiles,
    TGetCourseMeetingFilesPayload,
    TMeetingFile,
    TUpdateCourseGroupMeetingFilePayload,
    TUpdateCourseMeetingFilePayload,
} from 'types/meetingFiles';

// Получение списка файлов к уроку
export const getCourseMeetingFiles = (params: TGetCourseMeetingFilesPayload): Promise<TGetCourseMeetingFiles> => {
    return postApiRequest(`/meeting/files/`, params);
};
export const getCourseGroupMeetingFiles = (
    params: TGetCourseGroupMeetingFilesPayload,
): Promise<TGetCourseGroupMeetingFiles> => {
    return postApiRequest(`/meeting/files/lesson-schedule/`, params);
};
// Создание файла к уроку
export const createCourseMeetingFile = (params: TCreateCourseMeetingFilePayload): Promise<TMeetingFile> => {
    const data = new FormData();

    data.append('file', params.file);
    data.append('lesson', String(params.lesson));

    return postApiRequest(`/meeting/files/create/`, data, {
        headers: {
            'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
            Accept: 'application/json',
        },
    });
};
export const createCourseGroupMeetingFile = (
    params: TCreateCourseGroupMeetingFilePayload,
): Promise<TCourseGroupMeetingFile> => {
    const data = new FormData();

    data.append('file', params.file);
    data.append('lessonSchedule', String(params.lessonSchedule));

    return postApiRequest(`/meeting/files/lesson-schedule/create/`, data, {
        headers: {
            'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
            Accept: 'application/json',
        },
    });
};
// Редактирование файла
export const updateCourseMeetingFile = (
    params: TUpdateCourseMeetingFilePayload,
): Promise<{ update: TMeetingFile[] }> => {
    const payload = {
        update: [params],
    };
    return postApiRequest(`/meeting/files/handle/`, payload);
};
export const updateCourseGroupMeetingFile = (
    params: TUpdateCourseGroupMeetingFilePayload,
): Promise<{ update: TCourseGroupMeetingFile[] }> => {
    const payload = {
        update: [params],
    };
    return postApiRequest(`/meeting/files/lesson-schedule/handle/`, payload);
};
// Удаление файла
export const deleteCourseMeetingFile = (ids: number[]): Promise<{ delete: number[] }> => {
    const payload = {
        delete: ids,
    };
    return postApiRequest(`/meeting/files/handle/`, payload);
};
export const deleteCourseGroupMeetingFile = (ids: number[]): Promise<{ delete: number[] }> => {
    const payload = {
        delete: ids,
    };
    return postApiRequest(`/meeting/files/lesson-schedule/handle/`, payload);
};

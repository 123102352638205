import moment from 'moment';
import { AssignmentProgressPreview, AssignmentProgressStatus } from 'types/assignmentProgress';
import { IHomeworkItem } from 'types/homework';

import { HomeworkStatusProps } from 'components/HomeworkStatus';

export const getHomeworksStatusFromAssignmentProgress = (
    assignmentProgress: Omit<IHomeworkItem | AssignmentProgressPreview, 'courseGroup'>[] | null,
): HomeworkStatusProps => {
    if (assignmentProgress && assignmentProgress.length) {
        const id = assignmentProgress[0].assignment.id;
        const totalTasks = assignmentProgress.length;
        const autoIssuetasks = assignmentProgress
            .filter((homework) => homework.status === AssignmentProgressStatus.DEFERRED_ASSIGNMENT)
            .sort((a, b) => moment(a.assignedDate).diff(b.assignedDate));
        const autoIssue = autoIssuetasks.length > 0;
        const givenHometaskTitle = !autoIssue ? `${totalTasks}ДЗ` : '';
        const passOffTasks = assignmentProgress.filter(
            (homework) => homework.status === AssignmentProgressStatus.COMPLETED,
        ).length;
        const toCheckTasks = assignmentProgress.filter(
            (homework) => homework.status === AssignmentProgressStatus.ON_CHECK,
        ).length;

        let status: 'auto' | 'notAuto' | 'homework' | 'notHomework';
        if (autoIssue) {
            status = autoIssue ? 'auto' : 'notAuto';
        } else {
            status = givenHometaskTitle ? 'homework' : 'notHomework';
        }

        return {
            autoIssueSetUp: autoIssue,
            autoIssuetotalTasks: totalTasks,
            autoIssuetasks: autoIssuetasks.length,
            autoIssueTime: autoIssuetasks[0]?.assignedDate || '',
            toCheckTasks,
            id,
            status,
            autoIssue,
            givenHometaskTitle,
            passOffTasks,
        };
    }

    return {
        id: 1,
        status: 'homework',
        autoIssue: false,
        autoIssueSetUp: false,
        autoIssuetotalTasks: 0,
        autoIssuetasks: 0,
        autoIssueTime: '',
        givenHometaskTitle: '',
        passOffTasks: 0,
        toCheckTasks: 0,
    };
};

import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Select, Tag } from 'antd';
const { Option } = Select;
import { ArrowClick } from '@lms-elements/icons';
import { AppText } from 'src-new/ui';
import { setMessage } from 'src-new/utils/message';

import { TAppTreeSelect, TNormalizedList, TOptions } from './AppTreeSelect.types';

import './style.scss';

type TTagRenderProps = {
    value: string;
    onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    closable: boolean;
};
//TODO: переписать компонент более удобно для переиспользования

/*
 * Если нет необходимости в том, чтобы можно было выбрать только одну подопцию из многих,
 * то лучше использовать оригинальный компонент TreeSelect из antd
 *
 * Выбранные опции записываются в selectedList в формате "{optionId}-{optionChildId}"
 * в таком же формате ожидаются и дефолтные значения для селектора: defaultSelectedOptions
 * */

export const AppTreeSelect: React.FC<TAppTreeSelect> = (props: TAppTreeSelect) => {
    const { customClassName, options, placeholder, mode, defaultSelectedOptions, handleSelect, ...rest } = { ...props };
    const [openedOptionsList, setOpenedOptionsList] = useState<string[]>([]);
    const [selectedList, setSelectedList] = useState<string[]>([]);

    useEffect(() => {
        const defaultValuesIds = defaultSelectedOptions?.map((option) => option.split('-')[0]) || [];

        setOpenedOptionsList([...defaultValuesIds]);
    }, [defaultSelectedOptions]);
    useEffect(() => handleSelect(selectedList), [selectedList]);

    const defaultValues = useMemo(() => {
        if (defaultSelectedOptions?.length && options.length) {
            setSelectedList(defaultSelectedOptions);
            return defaultSelectedOptions.map((option) => {
                const [optionId, childId] = option.split('-');
                const selectedOption = options.find((item) => item.value === optionId) as TOptions;
                const selectedChild = selectedOption?.children?.find(
                    (item) => item.value === childId,
                ) as TNormalizedList;

                return {
                    value: optionId,
                    label: `${selectedOption?.label} / ${selectedChild?.label}`,
                    children: selectedOption.children,
                };
            });
        } else {
            return [];
        }
    }, [defaultSelectedOptions, options]);

    const sortedOptions = useMemo(() => {
        if (defaultValues.length && options.length) {
            const defaultValuesIds: string[] = defaultValues.map((item) => item.value);
            const filteredOptions = options.filter((item) => !defaultValuesIds.includes(item.value as string));
            filteredOptions.unshift(...defaultValues);
            return filteredOptions;
        }
        return options;
    }, [options, defaultValues]);

    const TagRender = (props: TTagRenderProps) => {
        const { closable, onClose, value } = props;

        const getText = useMemo(() => {
            const selectedItem = selectedList?.find((item) => item.includes(value))?.split('-');
            const selectedOption = options.find((item) => item.value === selectedItem?.[0]) as TOptions;
            const selectedChild = selectedOption?.children?.find(
                (item) => item.value === selectedItem?.[1],
            ) as TNormalizedList;

            return `${selectedOption?.label} / ${selectedChild?.label}`;
        }, [selectedList, value]);

        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };

        return (
            <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
                {getText}
            </Tag>
        );
    };

    const handleDropdownClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, option: TNormalizedList) => {
        event.stopPropagation();
        event.preventDefault();

        if (openedOptionsList.includes(option.value)) {
            setOpenedOptionsList((prevState) => prevState.filter((item) => item !== option.value));
        } else {
            setOpenedOptionsList([...openedOptionsList, option.value]);
        }
    };
    const handleOptionSelect = (
        option: TNormalizedList,
        child: TNormalizedList,
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    ) => {
        event.preventDefault();

        const handledItem = `${option.value}-${child.value}`;

        if (selectedList.includes(handledItem)) {
            setSelectedList((prevState) => prevState.filter((item) => item !== handledItem));
        } else {
            const childToDelete = selectedList.findIndex((item) => item.includes(option.value));

            if (childToDelete < 0) {
                setSelectedList([...selectedList, handledItem]);
            } else {
                const newArr = [...selectedList];
                newArr.splice(childToDelete, 1, handledItem);
                setSelectedList(newArr);
                event.stopPropagation();
            }
        }
    };
    const handleEmptyOptionSelect = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        setMessage({ status: 'info', text: 'Невозможно установить связь курс-тип' });
    };

    const handleClearField = (val: string) => {
        setSelectedList((prevState) => prevState.filter((item) => !item.includes(val)));
        setOpenedOptionsList((prevState) => prevState.filter((item) => item !== val));
    };

    return (
        <Select
            className={`app-tree-select ${customClassName || ''}`}
            mode={mode}
            maxTagCount={'responsive'}
            placeholder={placeholder}
            key={defaultValues?.join()}
            defaultValue={defaultValues}
            showSearch
            notFoundContent={'Ничего не найдено'}
            filterOption={(input, option) => (String(option?.label).toLowerCase() ?? '').includes(input.toLowerCase())}
            tagRender={TagRender}
            onDeselect={handleClearField}
            menuItemSelectedIcon={false}
            {...rest}
        >
            {/*@ts-ignore*/}
            {sortedOptions.map((item: TOptions, index) => (
                <Option
                    key={index}
                    value={item.value}
                    label={item.label}
                    className={`app-tree-select__dropdown ${openedOptionsList.includes(item?.value) ? 'opened' : ''}`}
                >
                    <div
                        className={'app-tree-select__dropdown_header'}
                        onClick={(event) => handleDropdownClick(event, item)}
                    >
                        <span>{item.label}</span>
                        <div className={'arrow-icon'}>
                            <ArrowClick />
                        </div>
                    </div>
                    <div className={'app-tree-select__dropdown-list'}>
                        {item.children?.length ? (
                            item.children.map((child) => (
                                <div
                                    key={`${(item as TNormalizedList).value}-${child.value}`}
                                    className={`app-tree-select__dropdown-list_item`}
                                    onClick={(event) => handleOptionSelect(item, child, event)}
                                >
                                    <Checkbox
                                        checked={selectedList?.includes(
                                            `${(item as TNormalizedList).value}-${child.value}`,
                                        )}
                                    >
                                        {child.label}
                                    </Checkbox>
                                </div>
                            ))
                        ) : (
                            <div
                                key={item.value}
                                className={`app-tree-select__dropdown-list_item empty`}
                                onClick={(event) => handleEmptyOptionSelect(event)}
                            >
                                <AppText text={'нет доступных типов'} fontStyle={'footnotes'} />
                            </div>
                        )}
                    </div>
                </Option>
            ))}
        </Select>
    );
};

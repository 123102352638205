import React, { useMemo } from 'react';
import { AppText } from '@frontend-modules/ui-kit';
import { AppAreaChart } from 'src-new/ui';
import { TTrainingsProgress } from 'types/newSubjectsTypes';

import './style.scss';
export const ProgressChart: React.FC<TTrainingsProgress> = (props) => {
    const { points, allCorrectAnswersCount, allAnswersCount, maxPointAnswersCount } = props;

    const lastTenPoints = points.length > 10 ? points.slice(-10) : points;
    const maxPointsVal = lastTenPoints.length ? lastTenPoints[lastTenPoints.length - 1] : 0;
    const noData = allAnswersCount <= 10;

    const commonColor = useMemo(() => {
        const percent = (allCorrectAnswersCount / allAnswersCount) * 100;
        if (percent <= 45) {
            return '#EA5959';
        }
        if (percent <= 75) {
            return '#E68B00';
        }
        return '#0088E9';
    }, [allCorrectAnswersCount, allAnswersCount]);
    const statisticColor = useMemo(() => {
        if (maxPointsVal <= 4) {
            return '#EA5959';
        }
        if (maxPointsVal <= 7) {
            return '#E68B00';
        }
        return '#0088E9';
    }, [maxPointsVal]);

    const chartDataNormalize = useMemo(() => {
        const lowVal = maxPointAnswersCount * 0.45;
        const middleVal = maxPointAnswersCount * 0.75 - lowVal;
        const highVal = maxPointAnswersCount - middleVal - lowVal;

        const arr: (string | number)[][] = [['Axis', 'Low', 'Middle', 'High', 'Line']];
        lastTenPoints.forEach((item, index) => {
            arr.push([index, lowVal, middleVal, highVal, item]);
        });
        return arr;
    }, [lastTenPoints]);

    const chartOptions = useMemo(() => {
        return {
            legend: 'none',
            height: 32,
            width: 100,
            chartArea: {
                width: '100%',
                height: 32,
            },
            hAxis: {
                textPosition: 'none',
                gridlines: {
                    color: 'transparent',
                },
                baselineColor: 'transparent',
            },
            vAxis: {
                textPosition: 'none',
                gridlines: {
                    color: 'transparent',
                },
                minValue: 0,
                maxValue: 11,
                baselineColor: 'transparent',
            },
            isStacked: true,
            series: {
                // low
                0: {
                    areaOpacity: 0.1,
                    color: '#EA5959',
                    lineDashStyle: [4, 2],
                    lineWidth: 1,
                    visibleInLegend: false,
                },

                // middle
                1: {
                    areaOpacity: 0.1,
                    color: '#E68B00',
                    lineDashStyle: [4, 2],
                    lineWidth: 1,
                    visibleInLegend: false,
                },

                // high
                2: {
                    areaOpacity: 0.1,
                    color: '#0088E9',
                    lineDashStyle: [4, 2],
                    lineWidth: 1,
                    visibleInLegend: false,
                },

                // statistic
                3: {
                    color: commonColor,
                    type: 'line',
                },
            },
            seriesType: 'area',
            enableInteractivity: false,
        };
    }, [commonColor, chartDataNormalize]);

    return (
        <div className={'progress-chart'}>
            {noData ? (
                <div className={'progress-chart__empty'}>
                    <AppText text={'Нет данных'} textStyle={'Body'} color={'#919399'} />
                </div>
            ) : (
                <AppAreaChart type={'ComboChart'} data={chartDataNormalize} options={chartOptions} height={32} />
            )}

            <div className={'progress-chart__legend'}>
                <div className={'progress-chart__legend_item'}>
                    <AppText text={`${allCorrectAnswersCount}`} textStyle={'Body'} color={commonColor} />
                    <AppText text={`/${allAnswersCount}`} color={'#999aa0'} textStyle={'Body'} />
                </div>
                <div className={'progress-chart__legend_item'}>
                    <AppText text={`${maxPointsVal}`} textStyle={'Body'} color={statisticColor} />
                    <AppText text={`/${maxPointAnswersCount}`} color={'#999aa0'} textStyle={'Body'} />
                </div>
            </div>
        </div>
    );
};

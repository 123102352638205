import { CalendarPlan } from '@lms-elements/educational-plan';
import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { EducationPlanValues } from 'src-new/components/lms-elements/EducationPlan/EducationalPlan.types';
import {
    deleteEducationalPlanAction,
    getAllCalendarPlansAction,
    getAllEducationalPlanAction,
    getEducationalParallelsAction,
    getEducationalPlanAction,
    getEducationPLanWithParallelsAndCalendar,
    patchEducationalPlanAction,
    postEducationalPlanAction,
} from 'store/actions/educationalPlan';
import { getNormalizedCalendarPlan, getNormalizedEducationPlan } from 'store/normalizers/educationalPlan';
import { getNormalizedResponseData } from 'store/normalizers/educationalPlan/getNormalizedResponseData';
import { FetchStatus } from 'types/api';
import { EducationalPlan } from 'types/educationalPlan';
import { Parallel } from 'types/parallel';

export interface EducationalPlanData {
    id: number;
    data: EducationPlanValues;
}

interface EducationalPlanState {
    getAllEducationalPlansStatus: FetchStatus;
    getEducationalPlanStatus: FetchStatus;
    postEducationalPlanStatus: FetchStatus;
    patchEducationalPlanStatus: FetchStatus;
    deleteEducationalPlanStatus: FetchStatus;
    getEducationalParallelsStatus: FetchStatus;
    getAllCalendarPlansStatus: FetchStatus;
    educationalPlan: EducationalPlanData;
    educationalPlansData: EducationalPlanData[];
    parallels: Parallel[];
    calendarPlansData: CalendarPlan[];
    calendarPlan: CalendarPlan;
    educationalPlans: EducationalPlan[];
    error: unknown;
}

const initialState = {
    getAllEducationalPlansStatus: FetchStatus.INITIAL,
    getEducationalPlanStatus: FetchStatus.INITIAL,
    postEducationalPlanStatus: FetchStatus.INITIAL,
    patchEducationalPlanStatus: FetchStatus.INITIAL,
    deleteEducationalPlanStatus: FetchStatus.INITIAL,
    getEducationalParallelsStatus: FetchStatus.INITIAL,
    getAllCalendarPlansStatus: FetchStatus.INITIAL,
    educationalPlan: {} as EducationalPlanData,
    educationalPlansData: [],
    parallels: [],
    educationalPlans: [],
    calendarPlansData: [],
    calendarPlan: {} as CalendarPlan,
    error: null,
};

const educationalPlanSlice = createSlice<EducationalPlanState, SliceCaseReducers<EducationalPlanState>>({
    name: 'educationalPlan',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getEducationalPlanAction.pending, (state) => {
                state.getEducationalPlanStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getEducationalPlanAction.fulfilled, (state, { payload }) => {
                state.getEducationalPlanStatus = FetchStatus.FETCHED;
                state.educationalPlan = getNormalizedResponseData(payload[0]);
                state.calendarPlansData = payload[1].map((value) => getNormalizedCalendarPlan(value));
                state.parallels = payload[2];
                state.error = null;
            })
            .addCase(getEducationalPlanAction.rejected, (state, { error }) => {
                state.getEducationalPlanStatus = FetchStatus.ERROR;
                state.error = error;
            });

        builder
            .addCase(postEducationalPlanAction.pending, (state) => {
                state.postEducationalPlanStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(postEducationalPlanAction.fulfilled, (state, { payload }) => {
                state.postEducationalPlanStatus = FetchStatus.FETCHED;
                state.educationalPlan = getNormalizedResponseData(payload);
                state.educationalPlansData.push(state.educationalPlan);
                state.error = null;
            })
            .addCase(postEducationalPlanAction.rejected, (state, { error }) => {
                state.postEducationalPlanStatus = FetchStatus.ERROR;
                state.error = error;
            });

        builder
            .addCase(patchEducationalPlanAction.pending, (state) => {
                state.patchEducationalPlanStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(patchEducationalPlanAction.fulfilled, (state, { payload }) => {
                state.patchEducationalPlanStatus = FetchStatus.FETCHED;
                state.educationalPlan = getNormalizedResponseData(payload);
                state.educationalPlansData = state.educationalPlansData.map((value) => {
                    if (value.id === payload.id) {
                        return state.educationalPlan;
                    }
                    return value;
                });
                state.error = null;
            })
            .addCase(patchEducationalPlanAction.rejected, (state, { error }) => {
                state.patchEducationalPlanStatus = FetchStatus.ERROR;
                state.error = error;
            });

        builder
            .addCase(deleteEducationalPlanAction.pending, (state, { meta }) => {
                state.deleteEducationalPlanStatus = FetchStatus.FETCHING;
                state.educationalPlansData = state.educationalPlansData.filter((value) => value.id !== meta.arg);
                state.error = null;
            })
            .addCase(deleteEducationalPlanAction.fulfilled, (state, { payload }) => {
                state.deleteEducationalPlanStatus = FetchStatus.FETCHED;
                state.educationalPlansData = state.educationalPlansData.filter((value) => value.id !== payload);
                state.error = null;
            })
            .addCase(deleteEducationalPlanAction.rejected, (state, { error }) => {
                state.deleteEducationalPlanStatus = FetchStatus.ERROR;
                state.error = error;
            });

        builder
            .addCase(getEducationalParallelsAction.pending, (state) => {
                state.getEducationalParallelsStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getEducationalParallelsAction.fulfilled, (state, { payload }) => {
                state.getEducationalParallelsStatus = FetchStatus.FETCHED;
                state.parallels = payload;
                state.error = null;
            })
            .addCase(getEducationalParallelsAction.rejected, (state, { error }) => {
                state.getEducationalParallelsStatus = FetchStatus.ERROR;
                state.error = error;
            });

        builder
            .addCase(getAllCalendarPlansAction.pending, (state) => {
                state.getAllCalendarPlansStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getAllCalendarPlansAction.fulfilled, (state, { payload }) => {
                state.getAllCalendarPlansStatus = FetchStatus.FETCHED;
                state.calendarPlansData = payload.map((value) => getNormalizedCalendarPlan(value));
                state.error = null;
            })
            .addCase(getAllCalendarPlansAction.rejected, (state, { error }) => {
                state.getAllCalendarPlansStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getEducationPLanWithParallelsAndCalendar.pending, (state) => {
                state.getAllEducationalPlansStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getEducationPLanWithParallelsAndCalendar.fulfilled, (state, { payload }) => {
                state.getAllEducationalPlansStatus = FetchStatus.FETCHED;
                state.educationalPlansData = payload[0].map((value) => getNormalizedEducationPlan(value));
                state.calendarPlansData = payload[1].map((value) => getNormalizedCalendarPlan(value));
                state.parallels = payload[2];
                state.error = null;
            })
            .addCase(getEducationPLanWithParallelsAndCalendar.rejected, (state, { error }) => {
                state.getAllEducationalPlansStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getAllEducationalPlanAction.pending, (state) => {
                state.getAllEducationalPlansStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getAllEducationalPlanAction.fulfilled, (state, { payload }) => {
                state.getAllEducationalPlansStatus = FetchStatus.FETCHED;
                state.educationalPlans = payload;
                state.error = null;
            })
            .addCase(getAllEducationalPlanAction.rejected, (state, { error }) => {
                state.getAllEducationalPlansStatus = FetchStatus.ERROR;
                state.error = error;
            });
    },
});

export const resetEducationalPlanState = educationalPlanSlice.actions.reset as ActionCreatorWithoutPayload<string>;
export const deleteEducationPlan = educationalPlanSlice.actions.deleteEducationPlan;
export const educationalPlanReducer = educationalPlanSlice.reducer;

import React from 'react';
import { AppFontIcon, AppText } from '@frontend-modules/ui-kit';

import { TWarningSign } from './WarningSign.types';

import './style.scss';
export const WarningSign: React.FC<TWarningSign> = (props: TWarningSign) => {
    const { message, type = 'warning' } = props;
    const textColor = type === 'error' ? '#EA5959' : '#E5A139';

    return (
        <div className={`warning-sign ${type}`}>
            <AppFontIcon icon={'exclamation-in-circle-l'} color={textColor} />
            <AppText text={message} textStyle={'DesktopFootNotes'} color={textColor} />
        </div>
    );
};

import { Subject } from 'src-new/components/lms-elements/CourseTable';
import { convertEvaluationTypeToSelection } from 'store/normalizers/educationalPlan/getNormalizedResponseData';
import { EducationalPlanData } from 'store/reducers/educationalPlan';
import { EducationalPlan, EduCourseType } from 'types/educationalPlan';

export const getNormalizedEducationPlan = (educationPlan: EducationalPlan): EducationalPlanData => {
    const mainBasicCourses: Subject[] = [];
    const mainAdditionalCourses: Subject[] = [];
    const selectiveBasicCourses: Subject[] = [];
    const selectiveAdditionalCourses: Subject[] = [];

    educationPlan.educationalPlanItems.forEach((item) => {
        switch (item.type) {
            case EduCourseType.COMMON:
                mainBasicCourses.push({
                    id: item.id,
                    courseId: item.course.id,
                    name: item.course.title,
                    inWeek: item.weekLessonsCount !== 0 ? String(item.weekLessonsCount) : '-',
                    inYear: item.yearLessonsCount !== 0 ? String(item.yearLessonsCount) : '',
                    fact: item.course.lessonCount !== 0 ? String(item.course.lessonCount) : '-',
                    lessonsInWeek: item.weekOnlineLessonsCount !== 0 ? String(item.weekOnlineLessonsCount) : '-',
                    lecturesInWeek: item.weekFlowLecturesCount !== 0 ? String(item.weekFlowLecturesCount) : '-',
                    lessonsInYear: item.yearOnlineLessonsCount !== 0 ? String(item.yearOnlineLessonsCount) : '-',
                    lecturesInYear: item.yearFlowLecturesCount !== 0 ? String(item.yearFlowLecturesCount) : '-',
                    selection: convertEvaluationTypeToSelection(item.evaluationType),
                    takeIntoAccount: item.takeIntoAccount,
                });
                break;
            case EduCourseType.NON_SUBJECT:
                mainAdditionalCourses.push({
                    id: item.id,
                    courseId: item.course.id,
                    name: item.course.title,
                    inWeek: item.weekLessonsCount !== 0 ? String(item.weekLessonsCount) : '-',
                    inYear: item.yearLessonsCount !== 0 ? String(item.yearLessonsCount) : '',
                    fact: item.course.lessonCount !== 0 ? String(item.course.lessonCount) : '-',
                    lessonsInWeek: item.weekOnlineLessonsCount !== 0 ? String(item.weekOnlineLessonsCount) : '-',
                    lecturesInWeek: item.weekFlowLecturesCount !== 0 ? String(item.weekFlowLecturesCount) : '-',
                    lessonsInYear: item.yearOnlineLessonsCount !== 0 ? String(item.yearOnlineLessonsCount) : '-',
                    lecturesInYear: item.yearFlowLecturesCount !== 0 ? String(item.yearFlowLecturesCount) : '-',
                    selection: convertEvaluationTypeToSelection(item.evaluationType),
                    takeIntoAccount: item.takeIntoAccount,
                });
                break;
            case EduCourseType.GIA_PREPARATION:
                selectiveBasicCourses.push({
                    id: item.id,
                    courseId: item.course.id,
                    name: item.course.title,
                    inWeek: item.weekLessonsCount !== 0 ? String(item.weekLessonsCount) : '-',
                    inYear: item.yearLessonsCount !== 0 ? String(item.yearLessonsCount) : '',
                    fact: item.course.lessonCount !== 0 ? String(item.course.lessonCount) : '-',
                    lessonsInWeek: item.weekOnlineLessonsCount !== 0 ? String(item.weekOnlineLessonsCount) : '-',
                    lecturesInWeek: item.weekFlowLecturesCount !== 0 ? String(item.weekFlowLecturesCount) : '-',
                    lessonsInYear: item.yearOnlineLessonsCount !== 0 ? String(item.yearOnlineLessonsCount) : '-',
                    lecturesInYear: item.yearFlowLecturesCount !== 0 ? String(item.yearFlowLecturesCount) : '-',
                    selection: convertEvaluationTypeToSelection(item.evaluationType),
                    takeIntoAccount: item.takeIntoAccount,
                });
                break;
            case EduCourseType.ELECTIVE:
                selectiveAdditionalCourses.push({
                    id: item.id,
                    courseId: item.course.id,
                    name: item.course.title,
                    inWeek: item.weekLessonsCount !== 0 ? String(item.weekLessonsCount) : '-',
                    inYear: item.yearLessonsCount !== 0 ? String(item.yearLessonsCount) : '',
                    fact: item.course.lessonCount !== 0 ? String(item.course.lessonCount) : '-',
                    lessonsInWeek: item.weekOnlineLessonsCount !== 0 ? String(item.weekOnlineLessonsCount) : '-',
                    lecturesInWeek: item.weekFlowLecturesCount !== 0 ? String(item.weekFlowLecturesCount) : '-',
                    lessonsInYear: item.yearOnlineLessonsCount !== 0 ? String(item.yearOnlineLessonsCount) : '-',
                    lecturesInYear: item.yearFlowLecturesCount !== 0 ? String(item.yearFlowLecturesCount) : '-',
                    selection: convertEvaluationTypeToSelection(item.evaluationType),
                    takeIntoAccount: item.takeIntoAccount,
                });
                break;
        }
    });

    return {
        id: educationPlan.id,
        data: {
            name: educationPlan.title,
            calendarPlan: educationPlan.calendarPlan,
            parallel: { id: educationPlan.parallel.id, title: educationPlan.parallel.title },
            minWeekHours: educationPlan.minHours || 0,
            freeWeekHours: educationPlan.freeHours || 0,
            mainBasicCourses: mainBasicCourses,
            mainAdditionalCourses: mainAdditionalCourses,
            selectiveBasicCourses: selectiveBasicCourses,
            selectiveAdditionalCourses: selectiveAdditionalCourses,
        },
    };
};

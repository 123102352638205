import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

import { getAccessToken } from 'utils/token';

export const ApiInterceptor = (api: AxiosInstance): void => {
    api.interceptors.request.use(
        (config: AxiosRequestConfig) => {
            const token = getAccessToken();

            if (token) {
                (config.headers as Record<string, string>)['Authorization'] = `Bearer ${token}`;
            }
            config.withCredentials = true;

            return config;
        },
        (error) => {
            Promise.reject(error).catch((err: AxiosError<Record<string, string>>) => {
                throw JSON.stringify(err.response?.data);
            });
        },
    );
};

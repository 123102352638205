import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { isEmptyList } from '@frontend-modules/frontend-utils';
import { AppCheckbox, AppInput, AppSelect } from '@frontend-modules/ui-kit';
import { Tag } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';

import { TMultipleSelector, TSelectorItem } from './MultipleSelector.types';

import './MultipleSelector.style.scss';

/**
 * @description MultipleSelector - компонент селектора с множественным выбором на основе AppSelect
 * Особенности компонента:
 * 1) Рендерятся кастомные теги для отображения в инпуте (TagRender) +
 * кастомный тег для отображения не уместившегося контента (MaxTagPlaceholder)
 * 2) Списки по умолчанию отсортированы по алфавиту, реализован поиск по опциям
 * 3) В начало списка всегда ставятся уже выбранные опции
 * 3) Добавлена логика работы с опцией "выбрать все":
 *      при выборе данной опции будет выбран весь список,
 *      при снятии данной опции будет снят весь список,
 *      если ранее был выбран весь список и один из элементов удалить - снимется опция "все"
 *      если выбрана данная опция, то в инпуте отображается только тег "Все"
 *      в общем списке данная опция всегда будет расположена сверху
 * Чтобы заработало, необходимо передать в список опций данный вариант как {value: ""; label: 'Все'}
 * (обязательно пустая строка в value) и передать в компонент withSelectedAll=true
 * @param {TMultipleSelector} params
 * @return {React.FC}
 */
export const MultipleSelector: FC<TMultipleSelector> = (params) => {
    const {
        defaultValue,
        options,
        handleMultipleSelect,
        onScrollEnd,
        onSearch,
        placeholder = 'Выберите из списка',
        maxTagCount = 'responsive',
        maxLabelLength = 25,
        withSelectedAll,
        needSortOptionsBySelected = true,
        withCheckboxes = false,
        searchResults,
        currentPage = 1,
        isOptionsLoading,
    } = params;

    const [selectedList, setSelectedList] = useState<TSelectorItem[]>(defaultValue);
    const [searchText, setSearchText] = useState<string | undefined>(undefined);
    const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);

    const hasSelectedAllOptions = withSelectedAll && selectedList.some(({ value }) => !value);
    const hasSearchResults = !!searchText && !isEmptyList(searchResults);

    useEffect(() => {
        const needUpdateList =
            (currentPage === 1 && isEmptyList(selectedList) && !hasSearchResults) ||
            (currentPage !== 1 && (hasSelectedAllOptions || !isDropdownOpened));

        if (!isOptionsLoading && needUpdateList) {
            setSelectedList(defaultValue);
        }
    }, [defaultValue, currentPage, isOptionsLoading]);

    const TagRender = (props: CustomTagProps) => {
        const { closable, onClose, label } = props;

        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };

        return (
            <Tag
                onMouseDown={onPreventMouseDown}
                closable={hasSelectedAllOptions ? false : closable}
                onClose={onClose}
                className={`multiple-selector__tag ${hasSelectedAllOptions ? 'no-background' : ''}`}
            >
                {label}
            </Tag>
        );
    };
    const MaxTagPlaceholder = (values: string | any[]) => {
        return (
            <Tag className={`multiple-selector__tag ${hasSelectedAllOptions ? 'hidden' : ''}`}>+ {values.length}</Tag>
        );
    };

    const sortedOptions = useMemo(() => {
        const currentOptions = hasSearchResults ? searchResults : options;
        if (selectedList?.length && currentOptions?.length && needSortOptionsBySelected) {
            const selectedValuesIds: (string | number)[] = selectedList.map((item) => item.value);
            const filteredOptions = currentOptions.filter(
                (item) => !selectedValuesIds.includes(item.value as string) && !!item.value,
            );

            const selectedAllOption = withSelectedAll ? currentOptions.find((opt) => !opt.value) : undefined;
            const newArr = selectedAllOption
                ? [selectedAllOption, ...selectedList.filter(({ value }) => value)]
                : selectedList;

            filteredOptions.unshift(...newArr);

            return filteredOptions;
        }
        return searchText ? currentOptions?.filter(({ value }) => !!value) : currentOptions;
    }, [options, selectedList, searchText, needSortOptionsBySelected, searchResults]);

    const searchFilter = (input: string, option: { label: any }) =>
        (String(option?.label).toLowerCase() ?? '').includes(input.toLowerCase());

    const onDropdownChanged = useCallback(
        (isOpened: boolean) => {
            setIsDropdownOpened(isOpened);
            if (!isOpened) {
                if (isEmptyList(selectedList)) {
                    handleMultipleSelect(options);
                    setSelectedList(options);
                } else {
                    handleMultipleSelect(selectedList);
                }
            }
        },
        [selectedList],
    );
    const onSearchLocal = (text: string) => {
        setSearchText(text);
        onSearch?.(text);
    };
    const onSelect = (val: TSelectorItem[]) => {
        if (withSelectedAll && !hasSearchResults) {
            let selectedOptions: TSelectorItem[] = [];
            const isAllJustSelected = !val[val.length - 1]?.value; //если только что выбрали "все" (он будет в конце списка)
            const isAllJustUnselected = selectedList.some(({ value }) => !value) && !val.some(({ value }) => !value);

            const filteredList = val.filter(({ value }) => !!value); //выбранный список без "все"
            const hasSelectedAll = val.some(({ value }) => !value); //есть ли среди выбранных "все"
            const isCheckedAllList = filteredList.length === options.length - 1; //выбран ли весь остальной список помимо "все"

            if (isAllJustUnselected) {
                selectedOptions = [];
            } else if (hasSelectedAll && !isCheckedAllList) {
                selectedOptions = isAllJustSelected ? options : filteredList;
            } else if (!hasSelectedAll && isCheckedAllList) {
                selectedOptions = options;
            } else {
                selectedOptions = val;
            }
            setSelectedList(selectedOptions);
            if (!isDropdownOpened) {
                handleMultipleSelect(selectedOptions);
            }
        } else {
            setSelectedList(val.filter(({ value }) => !!value));
            if (!isDropdownOpened) {
                handleMultipleSelect(val);
            }
        }
    };

    const onScroll = (event: Event) => {
        event.preventDefault();
        //@ts-ignore
        const target = event.target as HTMLDivElement;
        if (target.scrollTop + target.offsetHeight >= target.scrollHeight * 0.9) {
            onScrollEnd?.();
        }
    };
    const onClearLocal = () => {
        setSelectedList([]);
        handleMultipleSelect([]);
        onSearchLocal('');
    };
    const onDeselectLocal = (opt: string) => {
        if (opt) {
            setSelectedList((prev) => prev.filter(({ value }) => value !== opt));
        }
    };

    return (
        <div className={`multiple-selector`}>
            <AppSelect
                mode={'multiple'}
                defaultValue={selectedList ?? []}
                placeholder={placeholder}
                maxTagCount={maxTagCount}
                options={searchText ? sortedOptions?.filter(({ value }) => !!value) : sortedOptions}
                showArrow
                maxTagTextLength={maxLabelLength}
                menuItemSelectedIcon={
                    withCheckboxes
                        ? ({ value }) => (
                              <AppCheckbox
                                  onChange={() => null}
                                  defaultValue={selectedList.some((option) => option.value === value)}
                              />
                          )
                        : undefined
                }
                tagRender={TagRender}
                maxTagPlaceholder={MaxTagPlaceholder}
                onClear={onClearLocal}
                onDeselect={onDeselectLocal}
                onChange={onSelect}
                onSearch={onSearchLocal}
                filterOption={onSearch ? () => sortedOptions : searchFilter}
                virtual={false}
                onPopupScroll={(event) => onScroll(event)}
                onDropdownChanged={onDropdownChanged}
                showSearch={false}
                loading={isOptionsLoading}
                dropdownRender={(menu) => (
                    <div className={`multiple-selector__dropdown ${withCheckboxes && 'withCheckboxes'}`}>
                        <AppInput
                            size="small"
                            placeholder="Поиск"
                            value={searchText}
                            enterButton="Search"
                            onChange={onSearchLocal}
                            onKeyDown={(e) => e.stopPropagation()}
                        />
                        {menu}
                    </div>
                )}
            />
        </div>
    );
};

import { createSlice } from '@reduxjs/toolkit';
import {
    getStaffProfileByIdAction,
    getStaffProfilesAction,
    updateStaffProfileAction,
} from 'store/actions/staffProfile';
import { FetchStatus } from 'types/api';
import { StaffProfile } from 'types/staffProfile';
import { UserRoles } from 'types/user';

interface StaffProfileState {
    updateProfileStatus: FetchStatus;
    getProfilesStatus: FetchStatus;
    getProfileByIdStatus: FetchStatus;
    profile: StaffProfile | null;
    usersProfiles: Record<UserRoles, StaffProfile[]>;
    error: unknown;
}

const initialState: StaffProfileState = {
    updateProfileStatus: FetchStatus.INITIAL,
    getProfilesStatus: FetchStatus.INITIAL,
    getProfileByIdStatus: FetchStatus.INITIAL,
    profile: null,
    usersProfiles: {} as Record<UserRoles, StaffProfile[]>,
    error: null,
};

const staffProfileSlice = createSlice({
    name: 'staffProfile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getStaffProfilesAction.pending, (state) => {
            state.getProfilesStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getStaffProfilesAction.fulfilled, (state, { payload }) => {
            state.getProfilesStatus = FetchStatus.FETCHED;
            state.profile = payload.profiles;
            state.error = null;

            if (!payload.userRole) return;
            state.usersProfiles[payload.userRole] = (payload.profiles as unknown) as StaffProfile[];
        });
        builder.addCase(getStaffProfilesAction.rejected, (state, { error }) => {
            state.getProfilesStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(updateStaffProfileAction.pending, (state) => {
            state.updateProfileStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(updateStaffProfileAction.fulfilled, (state, { payload }) => {
            state.updateProfileStatus = FetchStatus.FETCHED;
            state.profile = payload;
            state.error = null;
        });
        builder.addCase(updateStaffProfileAction.rejected, (state, { error }) => {
            state.updateProfileStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(getStaffProfileByIdAction.pending, (state) => {
            state.getProfileByIdStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getStaffProfileByIdAction.fulfilled, (state, { payload }) => {
            state.getProfileByIdStatus = FetchStatus.FETCHED;
            state.profile = payload;
            state.error = null;
        });
        builder.addCase(getStaffProfileByIdAction.rejected, (state, { error }) => {
            state.getProfileByIdStatus = FetchStatus.ERROR;
            state.error = error;
        });
    },
});

export const staffProfileReducer = staffProfileSlice.reducer;

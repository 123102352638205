import React, { FC } from 'react';
import { cn } from '@bem-react/classname';
import { getInsertWordsPreviewHtml } from '@fsd-entities/materials';

import { ITeacherTrainingItemProps } from './TeacherTrainingItem.types';

import './TeacherTrainingItem.styles.scss';
const CnTeacherTrainingItem = cn('teacher-training-item');

export const TeacherTrainingItem: FC<ITeacherTrainingItemProps> = (props) => {
    const { text, html, content, answersType, onClick } = props;
    const questionTitle =
        answersType === 'insert_words' ? getInsertWordsPreviewHtml(content, html, text) : html || text;
    return (
        <div className={CnTeacherTrainingItem()} onClick={onClick}>
            <div
                className={CnTeacherTrainingItem('question')}
                dangerouslySetInnerHTML={{ __html: questionTitle ?? '' }}
            />
        </div>
    );
};

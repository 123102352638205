export enum E_QUESTION_TYPE {
    true_false = 'Вопрос - верно или нет',
    text = 'Вопрос - тестовый, с текстовым ответом',
    test = 'Вопрос - тестовый',
    essay = 'Вопрос - открытый вопрос - эссе',
    insert_words = 'Вопрос - вставить слова',
}

export enum E_SKILLS_FILTERS {
    skills_and = 'Все',
    skills_or = 'Любой из нижеперечисленных',
}
export enum E_COMPLEXITY_OPTIONS {
    easy = 'Низкая',
    medium = 'Средняя',
    hard = 'Высокая',
}
export enum E_ANSWERS_TYPE {
    test = 'test',
    text = 'text',
    essay = 'essay',
    true_false = 'true_false',
    insert_words = 'insert_words',
}

export enum E_QUESTIONS_DIFFICULTY {
    easy = 'Низкая сложность',
    medium = 'Средняя сложность',
    hard = 'Высокая сложность',
}
export enum E_QUESTION_TYPE_INFO {
    true_false = 'Вопрос - верно или нет, автопроверка',
    text = 'Вопрос - тестовый, с текстовым ответом, автопроверка',
    test = 'Вопрос - тестовый, автопроверка',
    essay = 'Открытый вопрос - эссе, ручная проверка',
    insert_words = 'Вопрос - вставить слова, автопроверка',
}
//case 'multiple':
//             return 'Вопрос - тестовый, автопроверка';
//         case 'detailed':
//             return 'Открытый вопрос - эссе, ручная проверка';
//         case 'binary':
//         case 'open':
//         case 'insert-words':
//             return

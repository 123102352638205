import { IEntitiesConfig, IMetaResponse } from 'types/meta';

const normalizeCatalog = (catalog: Array<unknown>, url: string): Array<unknown> => {
    if (url === '/specific-route/') {
        return catalog; // call normalized function here
    }
    return catalog;
};

/**
 * @description - переформатируем settings массив в объект
 * @param list
 */

const normalizeMetaOptionsList = (
    list: IMetaResponse,
): {
    [key: string]: IEntitiesConfig;
} => {
    const normalizedObj: { [key: string]: {} } = {};

    const parseSettingsList = (localList: Array<string>) => {
        const newObj: { [key: string]: boolean } = {};
        localList.forEach((key) => {
            newObj[key] = true;
        });
        return newObj;
    };
    Object.keys(list).forEach((key) => {
        normalizedObj[key] = {
            ...normalizedObj[key],
            access: list[key].access,
            sorting: list[key].sorting || [],
            filters: (list[key].filters || []).map((item: { settings: Array<string> }) => {
                return {
                    ...item,
                    settings: parseSettingsList(item.settings),
                };
            }),
        };
    });
    return normalizedObj;
};

export { normalizeCatalog, normalizeMetaOptionsList };

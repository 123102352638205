import { getApiRequest } from 'api';

export interface TransferredMarksParams {
    students: number[];
    period: number;
    courses?: number[];
}

export interface TransferredMarks {
    id: number;
    student: number;
    course: number;
    period: number;
    scores: (2 | 3 | 4 | 5)[];
    reportCard: string;
}

export const getTransferredMarks = (params: TransferredMarksParams): Promise<TransferredMarks[]> => {
    return getApiRequest(`/transferred-marks/`, {
        params: {
            students: params.students.join(','),
            period: params.period,
            courses: params.courses?.join(','),
        },
    });
};

import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { getLessonScheduleAndAttendanceData } from 'store/actions/academicPerformance';
import { normalizeAcademicPerformance } from 'store/normalizers/academicPerformance';
import { FetchStatus } from 'types/api';

export type LessonScheduleAndAttandenceData = {
    [lessonId: number]: {
        id: number;
        date: string;
        studentMarks: {
            studentId: number;
            isAbsent: boolean;
            isAbsentWithReason: boolean;
            isAbsentDueIllness: boolean;
        }[];
    };
};

interface AcademicPerformanceState {
    fetchStatus: FetchStatus;
    data: LessonScheduleAndAttandenceData;
    error: unknown;
}

const initialState: AcademicPerformanceState = {
    fetchStatus: FetchStatus.INITIAL,
    data: {},
    error: null,
};

const academicPerformanceSlice = createSlice<AcademicPerformanceState, SliceCaseReducers<AcademicPerformanceState>>({
    name: 'academicPerformance',
    initialState,
    reducers: {
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLessonScheduleAndAttendanceData.pending, (state) => {
                state.fetchStatus = FetchStatus.FETCHING;
            })
            .addCase(getLessonScheduleAndAttendanceData.fulfilled, (state, { payload }) => {
                const { lessonIds, lessonSchedule, lessonAttendance, studentIds, eventRooms } = payload;

                state.fetchStatus = FetchStatus.FETCHED;
                state.data = normalizeAcademicPerformance({
                    lessonAttendance,
                    lessonSchedule,
                    lessons: lessonIds,
                    students: studentIds,
                    eventRooms,
                });
            })
            .addCase(getLessonScheduleAndAttendanceData.rejected, (state, { error }) => {
                state.fetchStatus = FetchStatus.ERROR;
                state.error = error;
            });
    },
});

const resetAcademicPerformanceState = academicPerformanceSlice.actions.reset as ActionCreatorWithoutPayload;
export { resetAcademicPerformanceState };
export const academicPerformanceReducer = academicPerformanceSlice.reducer;

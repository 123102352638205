import React from 'react';
import { ConfigProvider, Skeleton } from 'antd';

const CnAppSkeleton = cn('app-skeleton');

import { cn } from '@bem-react/classname';

import { IAppSkeleton } from './AppSkeleton.types';

import './AppSkeleton.scss';

export const AppSkeleton: React.FC<IAppSkeleton> = (props) => {
    const { gap = 20, count = 1, width = '100%', radius = 4, height = 22 } = props;
    const list = Array(count).fill({});

    const style = {
        gap: gap,
        width: width,
    };

    const styleItem = {
        height: height,
    };
    const theme = {
        token: {
            borderRadiusSM: radius,
        },
    };

    return (
        <div className={CnAppSkeleton('container')} style={style}>
            {list.map((item, index) => (
                <div key={`${index}`} className={CnAppSkeleton('item')} style={styleItem}>
                    <ConfigProvider theme={theme}>
                        <Skeleton.Button active prefixCls={CnAppSkeleton()} />
                    </ConfigProvider>
                </div>
            ))}
        </div>
    );
};

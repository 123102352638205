import { TLessonSchedules } from 'src-new/components/new-subjects';

export const emptyOlineLessonSchedule: TLessonSchedules = {
    id: 0,
    type: 'online_lesson',
    datetimeStart: '',
    datetimeEnd: '',
};
export const emptySelfpreparationLessonSchedule: TLessonSchedules = {
    id: 0,
    type: 'selfpreparation_lesson',
    datetimeStart: '',
    datetimeEnd: '',
};

import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { TransferredMarks } from 'api/services/marks/getTransferredMarks';
import {
    changeAssignmentAdditionalMarkAction,
    createAssignmentAdditionalMarkAction,
    deleteAssignmentAdditionalMarkAction,
    getTransferredMarksAction,
} from 'store/actions/marks';
import { FetchStatus } from 'types/api';

export interface MarkState {
    postStatus: FetchStatus;
    patchStatus: FetchStatus;
    deleteStatus: FetchStatus;
    transferredMarksStatus: FetchStatus;
    transferredMarks: TransferredMarks[];
    error: unknown;
}

export const initialState: MarkState = {
    postStatus: FetchStatus.INITIAL,
    patchStatus: FetchStatus.INITIAL,
    deleteStatus: FetchStatus.INITIAL,
    transferredMarksStatus: FetchStatus.INITIAL,
    transferredMarks: [],
    error: null,
};

const markSlice = createSlice<MarkState, SliceCaseReducers<MarkState>>({
    name: 'mark',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createAssignmentAdditionalMarkAction.pending, (state) => {
                state.postStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(createAssignmentAdditionalMarkAction.fulfilled, (state) => {
                state.postStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(createAssignmentAdditionalMarkAction.rejected, (state, { error }) => {
                state.postStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(changeAssignmentAdditionalMarkAction.pending, (state) => {
                state.patchStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(changeAssignmentAdditionalMarkAction.fulfilled, (state) => {
                state.patchStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(changeAssignmentAdditionalMarkAction.rejected, (state, { error }) => {
                state.patchStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(deleteAssignmentAdditionalMarkAction.pending, (state) => {
                state.deleteStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(deleteAssignmentAdditionalMarkAction.fulfilled, (state) => {
                state.deleteStatus = FetchStatus.FETCHED;
                state.error = null;
            })
            .addCase(deleteAssignmentAdditionalMarkAction.rejected, (state, { error }) => {
                state.deleteStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getTransferredMarksAction.pending, (state) => {
                state.transferredMarksStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getTransferredMarksAction.fulfilled, (state, { payload }) => {
                state.transferredMarksStatus = FetchStatus.FETCHED;
                state.transferredMarks = state.transferredMarks.concat(payload);
                state.error = null;
            })
            .addCase(getTransferredMarksAction.rejected, (state, { error }) => {
                state.transferredMarksStatus = FetchStatus.ERROR;
                state.error = error;
            });
    },
});

export const markReducer = markSlice.reducer;

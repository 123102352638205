import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
    deleteQuestion,
    getHistoryQuestion,
    getQuestionDetailed,
    getQuestions,
    PartialUpdateQuestion,
    partialUpdateQuestion,
    patchQuestion,
    patchQuestionFile,
    PostQuestion,
    postQuestion,
    PostQuestionFile,
    postQuestionFile,
    putQuestion,
    QuestionStatus,
} from 'api/services/questionBank';
import { deleteRevisionQuestion } from 'api/services/revisionQuestion';
import { AxiosError } from 'axios';

import { createUrlQuery } from 'utils/createUrlQuery';

export const getQuestionsAction = createAsyncThunk('questionBank/getQuestions', async (options: string) => {
    return await getQuestions(options);
});

export const postQuestionAction = createAsyncThunk('questionBank/postQuestion', async (value: PostQuestion) => {
    return await postQuestion(value)
        .then((res) => {
            if (value.fileIds && res.id) {
                Promise.all(
                    value.fileIds.map((id) => {
                        return patchQuestionFile({ id: id, question: res.id });
                    }),
                ).catch((res) => console.error(res));
            }
            return res;
        })
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
});

export const refreshStatusesQuestions = createAction('questionBank/refreshStatusesQuestions', () => {
    return {
        payload: {},
    };
});

export const getQuestionDetailedAction = createAsyncThunk('questionBank/getQuestionDetailed', async (id: number) => {
    return await getQuestionDetailed(id);
});
export const setMaterialIdToEdit = createAsyncThunk('questionBank/setMaterialIdToEdit', (value: { id: number }) => {
    return value.id;
});
export const patchQuestionAction = createAsyncThunk(
    'questionBank/patchQuestion',
    async (value: { id: number; materialId: number; isFromMaterials: boolean; question: PostQuestion }) => {
        const putResult = await putQuestion(value);
        if (value.isFromMaterials) {
            const patchPayload = {
                id: value.materialId,
                payload: {
                    question: putResult.id,
                    content: putResult.id,
                },
            };
            const patchResult = await patchQuestion(patchPayload);

            return {
                oldId: value.id,
                //@ts-ignore
                newId: Number(patchResult.question),
            };
        }
        return putResult;
    },
);

export const deleteQuestionAction = createAsyncThunk('questionBank/deleteQuestion', async (id: number) => {
    return await deleteQuestion(id);
});

export const getHistoryQuestionAction = createAsyncThunk('questionBank/getHistory', async (id: number) => {
    return await getHistoryQuestion(id);
});

export const getAssignmentProgressQuestionsAction = createAsyncThunk(
    'questionBank/getAssignmentProgressQuestions',
    async (questions: number[]) => {
        if (questions.length) {
            const options = createUrlQuery({ include_ids: questions });

            return await getQuestions(options);
        }

        return [];
    },
);

export const postQuestionFileAction = createAsyncThunk('questionBank/postFile', async (values: PostQuestionFile) => {
    return await postQuestionFile(values).catch(() => {
        throw new Error(`Не удалось загрузить файл ${values.file.name}!`);
    });
});

export const partialUpdateQuestionAction = createAsyncThunk(
    'questionBank/partialUpdateQuestion',
    async (params: {
        id: number;
        values: PartialUpdateQuestion;
        revisions?: {
            id: number;
            comment: string;
            dateCreate: string;
        }[];
    }) => {
        if (params.values.status !== QuestionStatus.OnRevision && !!params.revisions?.length) {
            await Promise.all(params.revisions.map((revision) => deleteRevisionQuestion(revision.id)));
        }
        return await partialUpdateQuestion(params);
    },
);

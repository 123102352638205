import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApiRequest } from 'api';
import {
    getFirstArchiveNotificationsPage,
    getNotificationsPage,
    getUnreadMessagesCount,
    getUnreadNotificationsCount,
    NotificationsArchivePage,
} from 'api/services/notifications';
import { State } from 'store/store';

export const getUnreadNotificationsCountAction = createAsyncThunk('notifications/getUnreadNotificationsCount', () =>
    getUnreadNotificationsCount(),
);

export const getUnreadMessagesCountAction = createAsyncThunk('notifications/getUnreadMessagesCount', () =>
    getUnreadMessagesCount(),
);

export const getNotificationsPageAction = createAsyncThunk('notifications/getNotificationsPage', () =>
    getNotificationsPage(),
);

export const getFirstArchiveNotificationsPageAction = createAsyncThunk(
    'notifications/getFirstArchiveNotificationsPage',
    () => getFirstArchiveNotificationsPage(),
);

export const getNextArchiveNotificationsPageAction = createAsyncThunk(
    'notifications/getNextArchiveNotificationsPage',
    async (_, thunkApi) => {
        const state = thunkApi.getState() as State;
        const { nextArchiveNotificationsPageLink } = state.notifications;

        if (nextArchiveNotificationsPageLink === null) {
            return null;
        }

        return getApiRequest<NotificationsArchivePage, string>(nextArchiveNotificationsPageLink);
    },
);

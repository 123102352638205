import { CodifierResponseData } from 'api/services/codifier';

export type CodifierSubjectData = {
    id: number;
    title: string;
    shortName?: string;
    parallels: number[];
    isFetched?: boolean;
};

export type CodifierParallelData = {
    id: number;
    title: string;
    subject: number;
    sections: number[];
};

export type CodifierSectionData = {
    id: number;
    title: string;
    order: number;
    parallel: number;
    blocks: number[];
    topics: number[];
};

export type CodifierBlockData = {
    id: number;
    title: string;
    order: number;
    section: number;
    topics: number[];
};

export type CodifierTopicData = {
    id: number;
    title: string;
    order: number;
    block?: number;
    section?: number;
    questionsQuantity: number;
    questionsQuantityUnpublished: number;
    questionsQuantityRevision: number;
};

interface CodifierMap {
    subjectIds: number[];
    subjectsMap: {
        [id: number]: CodifierSubjectData;
    };
    parallelsMap: {
        [id: number]: CodifierParallelData;
    };
    sectionsMap: {
        [id: number]: CodifierSectionData;
    };
    blocksMap: {
        [id: number]: CodifierBlockData;
    };
    topicsMap: {
        [id: number]: CodifierTopicData;
    };
}

export const getCodifierMaps = (codifier: CodifierResponseData[] = []): CodifierMap => {
    return codifier.reduce(
        (map, subject) => {
            map.subjectIds.push(subject.id);

            map.subjectsMap[subject.id] = {
                id: subject.id,
                title: subject.title,
                isFetched: true,
                parallels:
                    subject.parallels?.map((parallel) => {
                        map.parallelsMap[parallel.id] = {
                            id: parallel.id,
                            title: `${parallel.grade} класс`,
                            subject: subject.id,
                            sections:
                                parallel.sections?.map((section) => {
                                    map.sectionsMap[section.id] = {
                                        ...map.sectionsMap[section.id],
                                        id: section.id,
                                        title: `Раздел ${section.order + 1}: ${section.title}`,
                                        order: section.order,
                                        parallel: parallel.id,
                                        blocks:
                                            section.blocks?.map((block) => {
                                                map.blocksMap[block.id] = {
                                                    id: block.id,
                                                    title: `Блок ${block.order + 1}: ${block.title}`,
                                                    order: block.order,
                                                    section: section.id,
                                                    topics:
                                                        block.topics?.map((topic) => {
                                                            map.topicsMap[topic.id] = {
                                                                ...topic,
                                                                section: section.id,
                                                                title: `Тема ${topic.order + 1}: ${topic.title}`,
                                                            };

                                                            return topic.id;
                                                        }) ?? [],
                                                };

                                                return block.id;
                                            }) ?? [],
                                        topics: [],
                                    };
                                    return section.id;
                                }) ?? [],
                        };

                        return parallel.id;
                    }) ?? [],
            };

            return map;
        },
        {
            subjectIds: [] as number[],
            subjectsMap: {} as Record<number, CodifierSubjectData>,
            parallelsMap: {} as Record<number, CodifierParallelData>,
            sectionsMap: {} as Record<number, CodifierSectionData>,
            blocksMap: {} as Record<number, CodifierBlockData>,
            topicsMap: {} as Record<number, CodifierTopicData>,
        },
    );
};

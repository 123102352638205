import { getApiRequest } from 'api';
import { HomeworkStatus, ProgressesStatisticsData } from 'types/homework';

/**
 *
 * @param status - Available values : assigned, on_check, complete
 * @example 'status=on_check'
 * @param user - Id of the requested user
 * @example 'user=1234'
 * @returns statistics about progresses
 */

export const getProgressesStatistics = async ({
    status,
    userId,
}: {
    status: HomeworkStatus;
    userId?: number;
}): Promise<ProgressesStatisticsData[]> => {
    return getApiRequest('/assignment-progress/statistics/', { params: { status: status, user: userId } });
};

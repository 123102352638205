import {
    IMaterialsMeta,
    IMaterialsMetaResponse,
    IMaterialsMetaRoles,
    TMaterialsRoles,
} from '@fsd-shared/models/materials';

const convertRoles = (roles: Array<TMaterialsRoles>): IMaterialsMetaRoles => {
    return {
        isMethodist: roles.includes('methodist'),
        isStudent: roles.includes('student'),
        isTeacher: roles.includes('teacher'),
    };
};

export const normalizeMaterialsMeta = (meta: IMaterialsMetaResponse): IMaterialsMeta => {
    const roles = convertRoles(meta.roles);
    return { ...meta, roles };
};

export const createRectRelativeToScroll = (
    rect: DOMRect,
    scrollTop?: number,
    scrollLeft?: number,
): Pick<DOMRect, 'left' | 'top' | 'width' | 'height'> => {
    const left = scrollLeft ?? (window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft);
    const top = scrollTop ?? (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop);

    return {
        left: rect.left + left,
        top: rect.top + top,
        width: rect.width,
        height: rect.height,
    };
};

import { getApiRequest, patchApiRequest } from 'api';

export interface AdaptationItem {
    id: number;
    adaptation: string;
    done: boolean;
}

export interface ChangeAdaptationParams {
    id: number;
    done: boolean;
}

export const getAdaptation = (studentId: number): Promise<AdaptationItem[]> =>
    getApiRequest(`/adaptation/${studentId}/`);

export const changeAdaptation = ({ id, ...restChangeParams }: ChangeAdaptationParams): Promise<AdaptationItem> =>
    patchApiRequest(`/adaptation/partial-update/${id}/`, restChangeParams);

import React from 'react';
import { TScheduleCardBase, TScheduleCardProps } from 'src-new/ui/schedules/app-day-schedule/AppDaySchedule.types';

import './style.scss';

const ScheduleCard = <TScheduleCard extends TScheduleCardBase>(
    props: TScheduleCardProps<TScheduleCard>,
): JSX.Element => {
    const { card, style, onCardClick, CardContent, selectedCardId } = { ...props };

    const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();

        if (onCardClick) {
            void onCardClick();
        }
    };

    return (
        <div
            className={`schedule-card ${selectedCardId === card.id ? 'selected' : ''}`}
            style={style}
            onClick={(event) => handleClick(event)}
        >
            <div className={'schedule-card__wrapper'}>
                <CardContent {...card} />
            </div>
        </div>
    );
};

export default ScheduleCard;

import { useMemo } from 'react';
import { HARD_MODAL_ENUMS } from 'src-new/components/layouts/Modals/Modals.config';
import { useGetPopupsState } from 'src-new/components/layouts/Modals/Modals.utils';

export const useLessonsPopupState = (): boolean => {
    const { popups } = useGetPopupsState();

    const isOpened = useMemo(() => {
        return !!popups.find((popup) => popup?.type === HARD_MODAL_ENUMS.LESSON);
    }, [popups]);

    return isOpened;
};

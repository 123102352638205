import React, { useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { Avatar, Dropdown, DropdownLabel, StatusLabelEnum } from '@lms-elements/atomic';
import { useRole } from 'hooks';
import { AttendanceStatus } from 'src-new/components/lms-elements/PerformanceTable/types';
import { AttendanceData } from 'types/attendance';
import { MarkScore } from 'types/mark';

import { AttendanceGarnitureStatus } from 'components/AttendanceGarnitureStatus';
import { AttendanceMark } from 'components/AttendanceMark';
import { convertAttendanceStatus } from 'utils/convertAttandanceStatus';

import './AttendanceLine.scss';

const CnAttendanceLine = cn('attendanceLine');

export interface IAttendanceLineProps extends AttendanceData {
    teacherId?: number;
    presenceSelectHandler?: (id: number, values: { status: StatusLabelEnum; lateMinutes: number }) => void;
    changeMark?: (studentId: number, values: { score?: MarkScore; comment?: string; markId: number }[]) => void;
    createMark?: (attendanceId: number, values: { score?: MarkScore; comment?: string }[]) => void;
    onDeleteMark?: (id: number, attendanceId: number) => void;
    onAvatarClick?: () => void;
    isOvered?: boolean;
    isStudent?: boolean;
}

export const AttendanceLine: React.FC<IAttendanceLineProps> = ({
    id,
    studentId,
    studentFirstName,
    studentLastName,
    studentImage,
    presenceInfo,
    cameraInfo,
    faceInfo,
    isOnline,
    marks,
    teacherId,
    presenceSelectHandler,
    changeMark,
    createMark,
    onDeleteMark,
    onAvatarClick,
    isAdaptation,
    isOvered,
    isStudent,
}) => {
    const { isPrincipal: isPrincipalRole, isSupport, isTutor } = useRole();
    // TODO: изменить, когда будут известны все отличия между support и завучем. Сейчас support видит все тоже самое что завуч
    const isPrincipal = isPrincipalRole || isSupport;
    const studentName = `${studentLastName} ${studentFirstName}`;
    const dropDownStatus = useMemo(() => convertAttendanceStatus(presenceInfo.status), [presenceInfo.status]);

    const submitChangeMark = useCallback(
        (values: { selectedMark?: MarkScore; comment?: string; markId: number }[]) => {
            if (changeMark) {
                const newValues = values.map((value) => {
                    return {
                        score: value.selectedMark,
                        comment: value.comment,
                        markId: value.markId,
                    };
                });

                changeMark(Number(studentId), newValues);
            }
        },
        [studentId, changeMark],
    );

    const submitCreateMark = useCallback(
        (values: { selectedMark?: MarkScore; comment?: string }[]) => {
            if (createMark) {
                const newValues = values.map((value) => {
                    return {
                        score: value.selectedMark,
                        comment: value.comment,
                    };
                });

                createMark(id, newValues);
            }
        },
        [id, createMark],
    );

    const handleDeleteMark = useCallback(
        (markId: number) => {
            onDeleteMark?.(markId, id);
        },
        [id, onDeleteMark],
    );

    return (
        <tr className={CnAttendanceLine()}>
            <td className={CnAttendanceLine('avatar')}>
                <Avatar
                    name={studentName}
                    source={studentImage}
                    alt={'Фото'}
                    onAvatarClick={onAvatarClick}
                    isOnAdaptation={isAdaptation}
                    isOnline={isOnline}
                />
            </td>
            <td className={CnAttendanceLine('presence')}>
                {isStudent ||
                (!isPrincipal &&
                    !isTutor &&
                    (presenceInfo.status === AttendanceStatus.ABSENT_DUE_ILLNESS ||
                        presenceInfo.status === AttendanceStatus.ABSENT_WITH_REASON)) ? (
                    <DropdownLabel status={dropDownStatus} lateTime={presenceInfo.lateMinutes} needHideIcon />
                ) : (
                    <Dropdown
                        status={dropDownStatus}
                        lateTime={presenceInfo.lateMinutes}
                        selectHandler={presenceSelectHandler?.bind(null, Number(id))}
                        needHideAbsentReasonOptions={!isPrincipal && !isTutor}
                    />
                )}
            </td>
            <td className={CnAttendanceLine('garniture')}>
                <AttendanceGarnitureStatus
                    isCameraDisabled={!cameraInfo.isTurnedOn}
                    isUserDisabled={!faceInfo.isRecognizedFace}
                    cameraDescription={cameraInfo.description}
                    userDescription={faceInfo.description}
                    showDescriptions={isOvered}
                />
            </td>
            <td className={CnAttendanceLine('marks')}>
                <AttendanceMark
                    teacherId={teacherId}
                    marks={marks}
                    submitChangeMark={submitChangeMark}
                    submitCreateMark={submitCreateMark}
                    onDeleteMark={handleDeleteMark}
                    canCreateCommentOnly
                    isStudent={isStudent}
                    isAbsolutionPosition
                />
            </td>
        </tr>
    );
};

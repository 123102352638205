export type TLessonsColTabsProps = {
    tabs: TLessonTab[];
    selectedTab?: TLessonTabTypes;
    handleTabClick?: (type: TLessonTabTypes | undefined) => void;
};

export type TLessonTab = {
    type: TLessonTabTypes;
    count: number;
};
export enum ELessonTabTitle {
    'test_lesson' = 'Контрольная',
    'online_lesson' = 'Онлайн',
    'selfpreparation_lesson' = 'Самоподготовка',
    'need_attention' = 'Требует внимания',
}

export type TLessonTabTypes = 'test_lesson' | 'online_lesson' | 'selfpreparation_lesson' | 'need_attention';

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Loader } from '@lms-elements/atomic';
import { AppButton, AppExpandableText, AppModal, AppText } from 'src-new/ui';
import { setAlertReadAction } from 'store/actions/alerts';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';

import './style.scss';

export const AlertModal: React.FC = () => {
    const dispatch = useDispatch();

    const { alertsList, patchStatus, getStatus } = useAppSelector((store) => store.alerts);
    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
        if (getStatus !== FetchStatus.FETCHING && patchStatus !== FetchStatus.FETCHING) {
            setIsShow(!!alertsList.length);
        }
    }, [alertsList, getStatus]);

    const setAlertRead = (id: number) => {
        dispatch(setAlertReadAction(id));
    };

    return (
        <AppModal isShow={isShow} closable={false} maskClosable={false} confirmLoading={true}>
            <div className={'alert-modal'}>
                <div className={'alert-modal__header'}>
                    <AppText text={'Список уведомлений:'} fontStyle={'H2'} />
                </div>
                <div className={'alert-modal__list'}>
                    {alertsList.map((alert) => (
                        <div className={'alert-modal__list_alert'} key={`Alert${alert.id}`}>
                            <div className={'alert-modal__list_alert-text'}>
                                <AppText text={alert.title} fontStyle={'main'} />
                                <AppExpandableText text={alert.text} />
                            </div>
                            <AppButton text={'Закрыть'} size={'middle'} onButtonClick={() => setAlertRead(alert.id)} />
                        </div>
                    ))}
                    {(patchStatus === FetchStatus.FETCHING || getStatus === FetchStatus.FETCHING) && (
                        <div className={'alert-modal__list-loader'}>
                            <Loader />
                        </div>
                    )}
                </div>
            </div>
        </AppModal>
    );
};

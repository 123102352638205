import {
    TCriteriaGroup,
    TCriteriaSettingState,
    TMonitoringStaff,
    TMonitoringStaffList,
    TMonitoringSubject,
    TMonitoringSubjectsList,
    TQuestionsCreatedDiagramData,
    TQuestionsCreatedProgressInfo,
    TQuestionsCreatedTableData,
    TSummaryChartDataItem,
    TSummaryTableData,
    TTeacherInfo,
    TTeacherProgress,
    TTeachersMonitoringData,
    TTeachersQuestionsCreatedDiagram,
    TTeachersWorkDays,
} from '@fsd-shared/models/statisticsMonitoring';
import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { FetchStatus } from 'types/api';

import {
    getCriteriaGroupsListAction,
    getCriteriaSettingAction,
    getMonitoringStaffListAction,
    getMonitoringSubjectsListAction,
    getQuestionsCreatedDiagramDataAction,
    getQuestionsCreatedProgressAction,
    getQuestionsCreatedTableDataAction,
    getSummaryChartDataAction,
    getSummaryTableDataAction,
    getTeacherMonitoringDataAction,
    getTeachersCriteriaStatisticAction,
    getTeachersQuestionsCreatedDiagramAction,
    getTeachesInfoAction,
    getTeachesWorkDaysAction,
    updateTeachesWorkDaysAction,
} from './statisticsMonitoringActions';

export interface IStatisticsMonitoringState {
    getStaffProfilesStatus: FetchStatus;
    getSubjectsListStatus: FetchStatus;
    getCriteriaGroupsListStatus: FetchStatus;
    getChartDataStatus: FetchStatus;
    staffProfiles?: TMonitoringStaffList & { isLoading: boolean; isLoadingMore: boolean };
    subjectsList?: TMonitoringSubjectsList & { isLoading: boolean; isLoadingMore: boolean };
    criteriaGroups?: TCriteriaGroup[];
    criteriaSettings?: TCriteriaSettingState;
    criteriaSettingsErrors?: { [key: string]: string[] };
    summaryTableData?: TSummaryTableData & { isLoading: boolean; isLoadingMore: boolean };
    questionsCreatedTableData?: TQuestionsCreatedTableData & { isLoading: boolean; isLoadingMore: boolean };
    questionsCreatedDiagramData?: TQuestionsCreatedDiagramData;
    summaryChartData?: TSummaryChartDataItem[];
    summaryCriteriaList?: string[];
    teacherMonitoringData?: TTeachersMonitoringData;
    getTeacherMonitoringDataStatus: FetchStatus;
    getTeacherProgressStatus: FetchStatus;
    getTeacherInfoStatus: FetchStatus;
    getTeachesWorkDaysStatus: FetchStatus;
    selectedTeacherWorkDays?: TTeachersWorkDays;
    selectedTeacherId?: string;
    selectedTeacherInfo?: TTeacherInfo;
    selectedTeacherProgress?: TTeacherProgress[];
    selectedTeacherQuestionsCreatedInfo?: TQuestionsCreatedProgressInfo;
    selectedTeacherQuestionsCreatedInfoError?: string[];
    teacherQuestionsCreatedDiagram?: TTeachersQuestionsCreatedDiagram;
}

export const initialState: IStatisticsMonitoringState = {
    getStaffProfilesStatus: FetchStatus.INITIAL,
    getSubjectsListStatus: FetchStatus.INITIAL,
    getCriteriaGroupsListStatus: FetchStatus.INITIAL,
    getTeacherProgressStatus: FetchStatus.INITIAL,
    getChartDataStatus: FetchStatus.INITIAL,
    getTeacherInfoStatus: FetchStatus.INITIAL,
    getTeachesWorkDaysStatus: FetchStatus.INITIAL,
    selectedTeacherWorkDays: undefined,
    questionsCreatedDiagramData: undefined,
    staffProfiles: undefined,
    subjectsList: undefined,
    selectedTeacherId: undefined,
    criteriaGroups: undefined,
    teacherMonitoringData: undefined,
    criteriaSettings: undefined,
    criteriaSettingsErrors: undefined,
    summaryTableData: undefined,
    summaryChartData: undefined,
    questionsCreatedTableData: undefined,
    summaryCriteriaList: undefined,
    getTeacherMonitoringDataStatus: FetchStatus.INITIAL,
    selectedTeacherInfo: undefined,
    selectedTeacherProgress: undefined,
    selectedTeacherQuestionsCreatedInfo: undefined,
    selectedTeacherQuestionsCreatedInfoError: undefined,
    teacherQuestionsCreatedDiagram: undefined,
};

const statisticsMonitoringSlice = createSlice<
    IStatisticsMonitoringState,
    SliceCaseReducers<IStatisticsMonitoringState>
>({
    name: 'StatisticsMonitoring',
    initialState,
    reducers: {
        setSelectedTeacher(state, action) {
            state.selectedTeacherId = action.payload;
            if (!action.payload) {
                state.selectedTeacherWorkDays = undefined;
                state.selectedTeacherQuestionsCreatedInfo = undefined;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getMonitoringStaffListAction.pending, (state, { meta }) => {
                const isFirstLoading = meta.arg?.currentPage === 1;
                state.staffProfiles = {
                    ...state.staffProfiles,
                    isLoading: isFirstLoading,
                    isLoadingMore: !isFirstLoading,
                };
            })
            .addCase(getMonitoringStaffListAction.fulfilled, (state, { payload }) => {
                const { pagination, results = [] as TMonitoringStaff[], search } = payload;
                const list = state.staffProfiles?.results ?? ([] as TMonitoringStaff[]);

                if (search) {
                    state.staffProfiles = {
                        results: list,
                        searchResults: results,
                        pagination,
                        search,
                        isLoading: false,
                        isLoadingMore: false,
                    };
                } else {
                    state.staffProfiles = {
                        pagination,
                        results: pagination?.currentPage === 1 ? results : [...list, ...results],
                        isLoading: false,
                        isLoadingMore: false,
                    };
                }
            })
            .addCase(getMonitoringStaffListAction.rejected, (state, { error }) => {
                state.staffProfiles = {
                    ...state.staffProfiles,
                    isLoading: false,
                    isLoadingMore: false,
                };
            });
        builder
            .addCase(getMonitoringSubjectsListAction.pending, (state, { meta }) => {
                const isFirstLoading = meta.arg?.currentPage === 1;
                state.subjectsList = {
                    ...state.subjectsList,
                    isLoading: isFirstLoading,
                    isLoadingMore: !isFirstLoading,
                };
            })
            .addCase(getMonitoringSubjectsListAction.fulfilled, (state, { payload }) => {
                const { pagination, results = [] as TMonitoringSubject[], search } = payload;
                const list = state.subjectsList?.results ?? ([] as TMonitoringSubject[]);

                if (search) {
                    state.subjectsList = {
                        results: list,
                        searchResults: results,
                        pagination,
                        search,
                        isLoading: false,
                        isLoadingMore: false,
                    };
                } else {
                    state.subjectsList = {
                        pagination,
                        results: pagination?.currentPage === 1 ? results : [...list, ...results],
                        isLoading: false,
                        isLoadingMore: false,
                    };
                }
            })
            .addCase(getMonitoringSubjectsListAction.rejected, (state, { error }) => {
                state.subjectsList = {
                    ...state.subjectsList,
                    isLoading: false,
                    isLoadingMore: false,
                };
            });
        builder
            .addCase(getTeachersCriteriaStatisticAction.pending, (state, { meta }) => {
                state.getTeacherProgressStatus = FetchStatus.FETCHING;
            })
            .addCase(getTeachersCriteriaStatisticAction.fulfilled, (state, { payload }) => {
                state.getTeacherProgressStatus = FetchStatus.FETCHED;

                state.selectedTeacherInfo = payload.user;
                state.selectedTeacherProgress = payload.progress;
            })
            .addCase(getTeachersCriteriaStatisticAction.rejected, (state) => {
                state.getTeacherProgressStatus = FetchStatus.ERROR;
                state.selectedTeacherProgress = undefined;
                state.selectedTeacherInfo = undefined;
            });
        builder
            .addCase(getQuestionsCreatedProgressAction.pending, (state, { meta }) => {
                state.getTeacherProgressStatus = FetchStatus.FETCHING;
                state.selectedTeacherQuestionsCreatedInfoError = undefined;
            })
            .addCase(getQuestionsCreatedProgressAction.fulfilled, (state, { payload }) => {
                state.getTeacherProgressStatus = FetchStatus.FETCHED;
                const { user, ...rest } = payload;
                state.selectedTeacherQuestionsCreatedInfo = rest;
            })
            .addCase(getQuestionsCreatedProgressAction.rejected, (state, { error, meta }) => {
                const parsedErrors = JSON.parse(error?.message ?? '')?.extra?.fields?.questionsCount?.value;

                state.selectedTeacherQuestionsCreatedInfo = {
                    ...state.selectedTeacherQuestionsCreatedInfo,
                    questionsCount: {
                        ...state.selectedTeacherQuestionsCreatedInfo?.questionsCount,
                        month:
                            meta.arg?.questionsCount?.value ??
                            state.selectedTeacherQuestionsCreatedInfo?.questionsCount?.month ??
                            0,
                    },
                };
                state.selectedTeacherQuestionsCreatedInfoError = parsedErrors;
                state.getTeacherProgressStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(getTeachesInfoAction.pending, (state, { meta }) => {
                state.getTeacherInfoStatus = FetchStatus.FETCHING;
            })
            .addCase(getTeachesInfoAction.fulfilled, (state, { payload }) => {
                state.getTeacherInfoStatus = FetchStatus.FETCHED;
                state.selectedTeacherInfo = payload;
            })
            .addCase(getTeachesInfoAction.rejected, (state) => {
                state.getTeacherInfoStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(getTeachesWorkDaysAction.pending, (state, { meta }) => {
                state.getTeachesWorkDaysStatus = FetchStatus.FETCHING;
            })
            .addCase(getTeachesWorkDaysAction.fulfilled, (state, { payload }) => {
                state.getTeachesWorkDaysStatus = FetchStatus.FETCHED;
                const { user, ...rest } = payload;
                state.selectedTeacherWorkDays = rest;
            })
            .addCase(getTeachesWorkDaysAction.rejected, (state) => {
                state.getTeachesWorkDaysStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(updateTeachesWorkDaysAction.pending, (state, { meta }) => {
                state.getTeachesWorkDaysStatus = FetchStatus.FETCHING;
                state.getTeacherProgressStatus = FetchStatus.FETCHING;
            })
            .addCase(updateTeachesWorkDaysAction.fulfilled, (state, { payload }) => {
                const { workDays, questionsCreated } = payload;
                state.getTeachesWorkDaysStatus = FetchStatus.FETCHED;
                state.getTeacherProgressStatus = FetchStatus.FETCHED;
                const { user: userWorkDays, ...restWorkDays } = workDays;
                state.selectedTeacherWorkDays = restWorkDays;
                const { user, ...restQuestionsCreated } = questionsCreated;
                state.selectedTeacherQuestionsCreatedInfo = restQuestionsCreated;
            })
            .addCase(updateTeachesWorkDaysAction.rejected, (state) => {
                state.getTeachesWorkDaysStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(getTeacherMonitoringDataAction.pending, (state, { meta }) => {
                state.getTeacherMonitoringDataStatus = FetchStatus.FETCHING;
            })
            .addCase(getTeacherMonitoringDataAction.fulfilled, (state, { payload }) => {
                state.getTeacherMonitoringDataStatus = FetchStatus.FETCHED;

                state.teacherMonitoringData = payload;
            })
            .addCase(getTeacherMonitoringDataAction.rejected, (state) => {
                state.getTeacherMonitoringDataStatus = FetchStatus.ERROR;
                state.teacherMonitoringData = undefined;
            });
        builder
            .addCase(getCriteriaGroupsListAction.pending, (state, { meta }) => {
                state.getCriteriaGroupsListStatus = FetchStatus.FETCHING;
            })
            .addCase(getCriteriaGroupsListAction.fulfilled, (state, { payload }) => {
                state.getCriteriaGroupsListStatus = FetchStatus.FETCHED;

                state.criteriaGroups = payload;
            })
            .addCase(getCriteriaGroupsListAction.rejected, (state) => {
                state.getCriteriaGroupsListStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(getCriteriaSettingAction.pending, (state, { meta }) => {
                const isSavingLoading = !!meta.arg.update;
                state.criteriaSettingsErrors = undefined;
                state.criteriaSettings = {
                    ...state.criteriaSettings,
                    isLoading: !isSavingLoading,
                    isSaving: isSavingLoading,
                };
            })
            .addCase(getCriteriaSettingAction.fulfilled, (state, { payload }) => {
                state.criteriaSettings = {
                    ...payload,
                    isLoading: false,
                    isSaving: false,
                };
            })
            .addCase(getCriteriaSettingAction.rejected, (state, { error, meta }) => {
                const parsedErrors = JSON.parse(error?.message ?? '')?.extra?.fields;
                const val = meta.arg.update;
                state.criteriaSettings = {
                    ...val,
                    isLoading: false,
                    isSaving: false,
                };
                state.criteriaSettingsErrors = parsedErrors ?? undefined;
            });
        builder
            .addCase(getSummaryTableDataAction.pending, (state, { meta }) => {
                state.summaryTableData = {
                    ...state.summaryTableData,
                    isLoading: meta.arg.currentPage === 1,
                    isLoadingMore: meta.arg.currentPage !== 1,
                };
            })
            .addCase(getSummaryTableDataAction.fulfilled, (state, { payload }) => {
                state.summaryTableData = {
                    ...payload,
                    isLoading: false,
                    isLoadingMore: false,
                };
            })
            .addCase(getSummaryTableDataAction.rejected, (state) => {
                state.summaryTableData = undefined;
            });
        builder
            .addCase(getSummaryChartDataAction.pending, (state, { meta }) => {
                state.getChartDataStatus = FetchStatus.FETCHING;
            })
            .addCase(getSummaryChartDataAction.fulfilled, (state, { payload }) => {
                state.getChartDataStatus = FetchStatus.FETCHED;
                state.summaryChartData = payload?.diagram?.data;
                state.summaryCriteriaList = payload?.diagram?.data?.[0]?.points.map(({ label }) => label);
            })
            .addCase(getSummaryChartDataAction.rejected, (state) => {
                state.getChartDataStatus = FetchStatus.ERROR;
            });
        builder
            .addCase(getTeachersQuestionsCreatedDiagramAction.pending, (state, { meta }) => {
                state.getChartDataStatus = FetchStatus.FETCHING;
            })
            .addCase(getTeachersQuestionsCreatedDiagramAction.fulfilled, (state, { payload }) => {
                state.getChartDataStatus = FetchStatus.FETCHED;
                state.teacherQuestionsCreatedDiagram = payload;
            })
            .addCase(getTeachersQuestionsCreatedDiagramAction.rejected, (state) => {
                state.getChartDataStatus = FetchStatus.ERROR;
                state.teacherQuestionsCreatedDiagram = undefined;
            });
        builder
            .addCase(getQuestionsCreatedTableDataAction.pending, (state, { meta }) => {
                const isFirstLoading = meta.arg?.currentPage === 1;
                state.questionsCreatedTableData = {
                    ...state.questionsCreatedTableData,
                    isLoading: isFirstLoading,
                    isLoadingMore: !isFirstLoading,
                };
            })
            .addCase(getQuestionsCreatedTableDataAction.fulfilled, (state, { payload }) => {
                state.questionsCreatedTableData = {
                    ...payload,
                    isLoading: false,
                    isLoadingMore: false,
                };
            })
            .addCase(getQuestionsCreatedTableDataAction.rejected, (state) => {
                state.questionsCreatedTableData = {
                    ...state.questionsCreatedTableData,
                    isLoading: false,
                    isLoadingMore: false,
                };
            });
        builder
            .addCase(getQuestionsCreatedDiagramDataAction.pending, (state, { meta }) => {
                state.getChartDataStatus = FetchStatus.FETCHING;
            })
            .addCase(getQuestionsCreatedDiagramDataAction.fulfilled, (state, { payload }) => {
                state.getChartDataStatus = FetchStatus.FETCHED;
                state.questionsCreatedDiagramData = payload;
            })
            .addCase(getQuestionsCreatedDiagramDataAction.rejected, (state) => {
                state.getChartDataStatus = FetchStatus.ERROR;
                state.questionsCreatedDiagramData = undefined;
            });
    },
});
export const statisticsMonitoringReducer = statisticsMonitoringSlice.reducer;
export const { setSelectedTeacher } = statisticsMonitoringSlice.actions;

import { EventRoom } from 'api/services/proctoring';
import { User } from 'types/user';

export const getProcotors = (rooms: EventRoom[]): User[] => {
    return rooms
        .flatMap((room) => room.leaders)
        .reduce((proctors, leader) => {
            if (!proctors.some(({ id }) => leader.id === id)) {
                proctors.push(leader);
            }
            return proctors;
        }, [] as User[]);
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { isEmptyList } from '@frontend-modules/frontend-utils';
import { AppButton } from '@frontend-modules/ui-kit';
import { Button, ButtonViewEnum } from '@lms-elements/atomic';
import { AssignedHomeworkTable } from 'containers/AssignedHomeworkTable';
import { AssigningHomeworkForm } from 'containers/AssigningHomeworkForm';
import { PageLoader } from 'containers/PageLoader';
import { TaskBlock } from 'containers/TaskBlock';
import { TLessonSchedules } from 'src-new/components/new-subjects/NewSubjects.types';
import { useLessonsPopup, useStudentTasksViewPopup } from 'src-new/hooks';
import {
    addTaskToValues,
    getAllTasks,
    getAssignedTasks,
    getAssignmentProgressData,
    getAssignmentTask,
    getHomeworkTableInitValues,
    getSubmitData,
    getTasksDate,
    isValidInterval,
    removeTaskFromValues,
    sortByFullName,
} from 'src-new/utils';
import { deleteAssignmentAction, getAssignmentsByLessonAction } from 'store/actions/assignment';
import {
    getCourseGroupDetailsWithStudentsAction,
    getCourseGroupExpelledStudentsAction,
} from 'store/actions/courseGroup';
import {
    deleteStudentHomeworksAction,
    getStudentsTasksAction,
    postDeferredAssignmentAction,
} from 'store/actions/homework';
import { resetAssignmentState } from 'store/reducers/assignment';
import { resetLessonHomeworksState } from 'store/reducers/homework';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';
import { Task, TPostDeferredAssignmentResponse } from 'types/homework';
import { UserRoles } from 'types/user';

import { HomeworkStatus } from 'components/HomeworkStatus';
import { HomeworkTableFormValues } from 'components/HomeworkTable';
import { ITaskDate } from 'components/Task/Task';
import { getHomeworksStatusFromAssignmentProgress } from 'utils/homeworkStatus';

import './style.scss';

const CnTasksTab = cn('tasksTab');

interface TasksTabProps {
    lesson: number;
    subject?: number;
    courseId?: number;
    conferenceId?: number;
    isOvered?: boolean;
    showForm?: boolean;
    showRepeatTaskForm?: boolean;
    courseGroupId?: number;
    onStudentCellClick?: (studentId: number) => void;
    lessonSchedule?: TLessonSchedules;
}

export const TeacherTaskTab: React.FC<TasksTabProps> = ({
    lesson,
    subject,
    conferenceId,
    showForm,
    showRepeatTaskForm,
    courseGroupId,
    onStudentCellClick,
    lessonSchedule,
    courseId,
}) => {
    const history = useHistory();
    const StudentTasksViewPopup = useStudentTasksViewPopup();
    const LessonPopup = useLessonsPopup();
    const [isDeferredAssignmentChanged, setIsDeferredAssignmentChanged] = useState(false);

    const { lessonAssignment, getLessonAssignmentStatus } = useAppSelector((store) => store.assignemnt);
    const {
        fetchStatus: fetchHomeworkStatus,
        fetchDeferredAssignmentStatus,
        fetchDeferredAssignmentChangeStatus,
        lessonHomeworks,
        deferredAssignments,
    } = useAppSelector((store) => store.homework);
    const { changeAssignmentMarksStatus } = useAppSelector((store) => store.newSubject);
    const { createLessonTaskStatus, updateLessonTaskStatus } = useAppSelector((store) => store.lessonTask);

    const dispatch = useDispatch();

    const { info } = useAppSelector((store) => store.user);

    const {
        courseGroupsMap,
        courseGroupExpelledStudents,
        fetchGroupsExpelledStudentsStatus,
        fetchGroupDetailsStatus,
    } = useAppSelector((store) => store.courseGroup);

    const isMethodistRole = info?.userRoles.includes(UserRoles.METHODIST);
    const isPrincipalRole = info?.userRoles.includes(UserRoles.PRINCIPAL);
    const isSupport = info?.userRoles.includes(UserRoles.SUPPORT);
    const lessonScheduleId = lessonSchedule?.id;
    const isDeferredAssignmentUpdated =
        isDeferredAssignmentChanged && fetchDeferredAssignmentStatus === FetchStatus.FETCHED;

    // TODO: изменить, когда будут известны все отличия между support и завучем. Сейчас support видит все тоже самое что и завуч
    const isPrincipal = isPrincipalRole || isSupport;
    // TODO: изменить, когда будут известны все отличия между методистом и завучем. Сейчас завуч видит все тоже самое что и методист
    const isMethodist = isMethodistRole || isPrincipal;

    const isSaving =
        fetchDeferredAssignmentStatus === FetchStatus.FETCHING || fetchHomeworkStatus === FetchStatus.FETCHING;

    const selectedCourseGroup = useMemo(() => {
        if (courseGroupId) {
            return courseGroupsMap[courseGroupId];
        }
    }, [courseGroupsMap, courseGroupId]);

    const canDeleteFileTemplate = useMemo(() => isMethodist, [isMethodist]);

    const students = useMemo(
        () =>
            fetchGroupDetailsStatus === FetchStatus.FETCHED
                ? (selectedCourseGroup?.students ?? [])
                      .concat(courseGroupExpelledStudents?.students ?? [])
                      .slice()
                      .sort(sortByFullName)
                : [],
        [courseGroupExpelledStudents?.students, fetchGroupDetailsStatus, selectedCourseGroup?.students],
    );

    useEffect(() => {
        if (
            lesson &&
            courseGroupId &&
            fetchGroupsExpelledStudentsStatus === FetchStatus.INITIAL &&
            fetchGroupDetailsStatus === FetchStatus.FETCHED
        ) {
            dispatch(getCourseGroupExpelledStudentsAction({ lessonId: lesson, courseGroupId: courseGroupId }));
        }
    }, [
        courseGroupId,
        dispatch,
        fetchGroupDetailsStatus,
        fetchGroupsExpelledStudentsStatus,
        getLessonAssignmentStatus,
        lesson,
    ]);

    useEffect(() => {
        if (
            (getLessonAssignmentStatus === FetchStatus.INITIAL && courseGroupId && lesson) ||
            createLessonTaskStatus === FetchStatus.FETCHED ||
            updateLessonTaskStatus === FetchStatus.FETCHED
        ) {
            dispatch(getAssignmentsByLessonAction({ lesson, courseGroup: courseGroupId }));
        }
    }, [courseGroupId, dispatch, getLessonAssignmentStatus, lesson, createLessonTaskStatus, updateLessonTaskStatus]);

    useEffect(() => {
        if (
            lesson &&
            courseGroupId &&
            students.length > 0 &&
            fetchGroupsExpelledStudentsStatus === FetchStatus.FETCHED &&
            (changeAssignmentMarksStatus !== FetchStatus.FETCHING ||
                changeAssignmentMarksStatus === FetchStatus.FETCHED ||
                isDeferredAssignmentUpdated)
        ) {
            if (isDeferredAssignmentUpdated) {
                dispatch(getCourseGroupExpelledStudentsAction({ lessonId: lesson, courseGroupId: courseGroupId }));
                dispatch(getCourseGroupDetailsWithStudentsAction(courseGroupId));
            } else {
                dispatch(
                    getStudentsTasksAction({ lessons: [lesson], students: students.map((student) => student.id) }),
                );
            }
            setIsDeferredAssignmentChanged(false);
        }
    }, [
        courseGroupId,
        dispatch,
        fetchGroupsExpelledStudentsStatus,
        lesson,
        students,
        changeAssignmentMarksStatus,
        isDeferredAssignmentUpdated,
    ]);

    useEffect(() => {
        if (courseGroupId && fetchDeferredAssignmentStatus === FetchStatus.INITIAL) {
            dispatch(
                postDeferredAssignmentAction({
                    filters: {
                        courseGroup: courseGroupId,
                        lesson: lesson,
                    },
                }),
            );
        }
    }, [courseGroupId, fetchDeferredAssignmentStatus]);
    useEffect(() => {
        return () => {
            dispatch(resetAssignmentState());
            dispatch(resetLessonHomeworksState());
        };
    }, [dispatch]);

    const isLoading =
        getLessonAssignmentStatus === FetchStatus.FETCHING ||
        fetchHomeworkStatus === FetchStatus.FETCHING ||
        fetchGroupsExpelledStudentsStatus === FetchStatus.FETCHING ||
        fetchGroupDetailsStatus === FetchStatus.FETCHING;

    const [showAssigningForm, setShowAssigningForm] = useState(
        showForm || new URLSearchParams(history.location.search).get('show_form') === 'true',
    );

    const [repeatTaskForm, setRepeatTaskForm] = useState(
        showRepeatTaskForm || new URLSearchParams(history.location.search).get('show_repeat_task_form') === 'true',
    );

    const onCancelButtonClick = () => {
        setRepeatTaskForm(false);
        setShowAssigningForm(false);
    };

    const handleEditTaskClick = useCallback(() => {
        setShowAssigningForm(true);
    }, []);

    const handleRepeatTaskClick = useCallback(() => {
        setRepeatTaskForm(true);
    }, []);

    const assignmentTasks = useMemo(
        () => getAssignmentTask(lessonAssignment, canDeleteFileTemplate, lessonHomeworks, deferredAssignments),
        [lessonAssignment, canDeleteFileTemplate, lessonHomeworks, deferredAssignments],
    );

    const deferredAssignmentTasks: (Task & TPostDeferredAssignmentResponse)[] = useMemo(() => {
        return deferredAssignments
            .map((assignment: TPostDeferredAssignmentResponse) => {
                const task = assignmentTasks.find((homework) => {
                    return homework.id === assignment.assignment;
                });

                if (task) {
                    return {
                        ...assignment,
                        isPosted: true,
                        title: task?.title,
                        type: task?.type,
                        isTemplate: task?.isTemplate,
                    };
                }
            })
            .filter(Boolean);
    }, [deferredAssignments, assignmentTasks]);
    const assignmentProgress = useMemo(() => getAssignmentProgressData(lessonHomeworks, deferredAssignmentTasks), [
        lessonHomeworks,
        deferredAssignmentTasks,
    ]);
    const homeworkStatus = useMemo(() => getHomeworksStatusFromAssignmentProgress(lessonHomeworks), [lessonHomeworks]);

    const [newTasks, setNewTasks] = useState<Task[]>([]);
    const [formValues, setFormValues] = useState<HomeworkTableFormValues>();

    useEffect(() => {
        if ((assignmentProgress.length || assignmentTasks.length) && students.length) {
            const tasks = getAssignedTasks(assignmentTasks);
            const allTask = getAllTasks(tasks, assignmentProgress);
            const formValues = getHomeworkTableInitValues(
                students,
                tasks,
                assignmentProgress,
                repeatTaskForm,
                courseGroupExpelledStudents?.students,
            );

            setFormValues(formValues);
            // Для повторной выдачи сбрасываем даты
            setNewTasks(
                repeatTaskForm
                    ? allTask.map((task) => {
                          return {
                              ...task,
                              assignedDate: '',
                              deadlineForComplete: '',
                          };
                      })
                    : allTask,
            );
        }
    }, [assignmentTasks, students, assignmentProgress, repeatTaskForm, showAssigningForm]);

    const handleTaskSelect = useCallback(
        (taskId: number, isSelected: boolean) => {
            const newTask = assignmentTasks.find((task) => task.id === taskId);
            const alreadyAdded = newTasks.find((task) => task.id === taskId);
            setNewTasks((prev) => {
                if (isSelected) {
                    return prev.filter((task) => task.id !== taskId);
                }

                if (newTask !== undefined && !alreadyAdded) {
                    return [...prev, { ...newTask, assignedDate: '', deadlineForComplete: '' }];
                }

                return prev;
            });

            setFormValues((values) => {
                if (isSelected && values) {
                    return removeTaskFromValues(values, taskId);
                }

                if (newTask !== undefined && values && !alreadyAdded) {
                    const newValue = addTaskToValues(values, newTask, assignmentProgress, repeatTaskForm);
                    return newValue;
                }

                return {
                    ...values,
                    selectAll: values?.selectAll.map((item) => {
                        return { ...item, disabled: !!repeatTaskForm };
                    }),
                };
            });
        },
        [assignmentProgress, assignmentTasks, repeatTaskForm, newTasks],
    );
    const handleAssigningFormChange = useCallback((values: HomeworkTableFormValues, pristine: boolean) => {
        setFormValues((currentValues) => {
            if (!pristine) {
                return values;
            }

            return currentValues;
        });
    }, []);
    const handleTeacherSelected = useCallback((taskId: number, id: number) => {
        setNewTasks((prev) => {
            const newTasksState = prev.map((task) => {
                if (task.id === taskId) {
                    return {
                        ...task,
                        teacher: id,
                    };
                }

                return task;
            });

            return newTasksState;
        });
    }, []);
    const handleDateSelected = useCallback((taskId: number, dates: ITaskDate) => {
        const lessonStartDate = lessonSchedule?.datetimeStart
            ? { datetimeStart: lessonSchedule?.datetimeStart }
            : undefined;

        setNewTasks((prev) => {
            const newTasksState = prev.map((task) => {
                if (task.id === taskId) {
                    const {
                        assignedDate,
                        deadlineForComplete,
                        assignedType,
                        deadlineType,
                        assignedDelay,
                        deadlineDelay,
                        timeToComplete,
                    } = getTasksDate(dates, lessonStartDate);

                    return {
                        ...task,
                        assignedDate,
                        deadlineForComplete,
                        assignedType,
                        deadlineType,
                        assignedDelay,
                        deadlineDelay,
                        timeToComplete,
                    };
                }

                return task;
            });

            return newTasksState;
        });
    }, []);
    const handleFormButtonClick = useCallback(() => {
        if (formValues && courseGroupId) {
            const {
                createdDeferredAssignments,
                updatedDeferredAssignments,
                deletedDeferredAssignmentsIds,
                deletedAssignmentIds,
            } = getSubmitData({
                tasks: newTasks,
                values: formValues,
                assignmentProgress: lessonHomeworks,
                courseGroup: courseGroupId,
                repeatTaskForm,
                deferredAssignments,
            });

            const isSomeCreated = !isEmptyList(createdDeferredAssignments);
            const isSomeUpdated = !isEmptyList(updatedDeferredAssignments);
            const isSomeDeleted = !isEmptyList(deletedDeferredAssignmentsIds);

            if (isSomeCreated || isSomeUpdated || isSomeDeleted) {
                dispatch(
                    postDeferredAssignmentAction({
                        filters: {
                            courseGroup: courseGroupId,
                            lesson: lesson,
                        },
                        created: isSomeCreated ? createdDeferredAssignments : undefined,
                        updated: isSomeUpdated ? updatedDeferredAssignments : undefined,
                        deleted: isSomeDeleted ? deletedDeferredAssignmentsIds : undefined,
                    }),
                );
            }

            if (deletedAssignmentIds.length) {
                dispatch(deleteStudentHomeworksAction(deletedAssignmentIds));

                // Если только удалились прогрессы, все равно перезапрашиваем отложенные выдачи,
                // тк за это время они могли превратиться в прогрессы, тогда список будет не актуальный
                if (!isSomeCreated && !isSomeUpdated && !isSomeDeleted) {
                    dispatch(
                        postDeferredAssignmentAction({
                            filters: {
                                courseGroup: courseGroupId,
                                lesson: lesson,
                            },
                            isGetter: false,
                        }),
                    );
                }
            }
        }
    }, [formValues, courseGroupId, newTasks, lessonHomeworks, repeatTaskForm, dispatch]);

    const { assigningHomeworkForm, taskForm } = useAppSelector((store) => store.formError);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (
            fetchDeferredAssignmentChangeStatus === FetchStatus.FETCHED ||
            fetchDeferredAssignmentChangeStatus === FetchStatus.ERROR
        ) {
            setIsDeferredAssignmentChanged(true);
            repeatTaskForm ? setRepeatTaskForm(false) : setShowAssigningForm(false);
        }
    }, [fetchDeferredAssignmentChangeStatus]);
    useEffect(() => {
        if (!isEmptyList(newTasks) && (assigningHomeworkForm?.errors || taskForm?.errors)) {
            const filteredTasks = repeatTaskForm
                ? newTasks.filter((task) => assignmentTasks.some(({ id }) => id === task.id))
                : newTasks;
            const hasEmptyDates = filteredTasks
                .filter(({ isPosted }) => repeatTaskForm || !isPosted)
                .some(
                    ({ deadlineForComplete, assignedDate, assignedDelay, deadlineDelay, timeToComplete }) =>
                        (!assignedDate && assignedDelay === undefined) ||
                        (!deadlineForComplete && !deadlineDelay) ||
                        timeToComplete === 0,
                );
            const hasInvalidDates = filteredTasks
                .filter(({ isPosted }) => repeatTaskForm || !isPosted)
                .some(({ assignedDate, deadlineForComplete }) => {
                    return assignedDate && deadlineForComplete
                        ? !isValidInterval(assignedDate, deadlineForComplete)
                        : false;
                });
            const hasNewCheckedTasks = newTasks.some((task) => !task.isPosted);
            const isFirstAssignment =
                !repeatTaskForm &&
                !showAssigningForm &&
                isEmptyList(lessonHomeworks) &&
                isEmptyList(deferredAssignments);
            const hasTasksWithNoStudents = !!filteredTasks?.filter(({ id }) => {
                const isTaskSelectedAll = formValues?.selectAll?.some(
                    ({ taskId, checked, disabled }) => taskId === id && checked && !disabled,
                );
                const isTaskHasStudents = formValues?.students?.some(({ tasks }) =>
                    tasks.some(({ taskId, blocked, checked }) => taskId === id && !blocked && checked),
                );
                const isTaskBlocked =
                    !isTaskHasStudents &&
                    formValues?.students?.some(({ tasks }) =>
                        tasks.some(({ taskId, blocked, checked }) => taskId === id && blocked && checked),
                    );

                return !isTaskSelectedAll && !isTaskHasStudents && !isTaskBlocked;
            }).length;

            setDisabled(
                (Boolean((assigningHomeworkForm?.errors as { tasks?: string })?.tasks) &&
                    (assigningHomeworkForm?.errors as { tasks?: string })?.tasks !== 'not assigned') ||
                    (showAssigningForm ? hasNewCheckedTasks && hasEmptyDates : hasEmptyDates) ||
                    hasInvalidDates ||
                    (hasTasksWithNoStudents &&
                        (repeatTaskForm || isFirstAssignment || (showAssigningForm && hasNewCheckedTasks))),
            );
        } else if (isEmptyList(newTasks)) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [assigningHomeworkForm, taskForm, newTasks, formValues, lessonHomeworks, deferredAssignments]);

    const hasAssignedHomeworks =
        (fetchHomeworkStatus === FetchStatus.FETCHED || fetchHomeworkStatus === FetchStatus.ERROR) &&
        (fetchDeferredAssignmentStatus === FetchStatus.FETCHED ||
            fetchDeferredAssignmentStatus === FetchStatus.ERROR) &&
        (lessonHomeworks.length || deferredAssignmentTasks.length);

    const dataFetched =
        getLessonAssignmentStatus === FetchStatus.FETCHED &&
        fetchHomeworkStatus !== FetchStatus.FETCHING &&
        fetchGroupsExpelledStudentsStatus === FetchStatus.FETCHED &&
        fetchGroupDetailsStatus === FetchStatus.FETCHED;

    const handleStudentCellClick = useCallback(
        (studentId: number) => {
            if (onStudentCellClick) {
                onStudentCellClick(studentId);
            }
        },
        [onStudentCellClick],
    );

    const handleStudentTaskClick = useCallback(
        (studentId: number, taskId: number) => {
            if (courseGroupId) {
                LessonPopup.replaceLastLessonPopup({
                    subjectId: subject ?? 0,
                    courseGroupId: courseGroupId,
                    lessonId: lesson,
                    tab: 'tasks',
                    taskId: taskId,
                });
                StudentTasksViewPopup.openStudentTasksViewPopup({
                    taskId,
                    studentId,
                    subjectId: subject ?? 0,
                    lessonId: lesson,
                    courseGroupId,
                });
            }
        },
        [courseGroupId, lesson],
    );

    const handleAssignmentDelete = useCallback(
        (id: number) => {
            dispatch(deleteAssignmentAction(id));
        },
        [dispatch],
    );

    const isContentFetching = isLoading && !dataFetched;
    const isTableShow = !showAssigningForm && !repeatTaskForm;
    const isTaskBlockShow = (!courseGroupId || !students?.length) && dataFetched && isTableShow;
    const isAssignedHomeworkTableShow = hasAssignedHomeworks && isTableShow;

    if (isContentFetching) {
        return <PageLoader showLoading={isContentFetching} />;
    }

    if (!isContentFetching && isAssignedHomeworkTableShow) {
        return (
            <div className={CnTasksTab()}>
                <AssignedHomeworkTable
                    students={students}
                    tasks={newTasks}
                    assignmentProgress={assignmentProgress}
                    homeworkStatus={homeworkStatus}
                    onEditTaskButtonClick={handleEditTaskClick}
                    onRepeatTaskButtonClick={handleRepeatTaskClick}
                    onStudentCellClick={handleStudentCellClick}
                    onHomeworkClick={handleStudentTaskClick}
                />
            </div>
        );
    }
    if (!isContentFetching && isTaskBlockShow) {
        return (
            <div className={CnTasksTab()}>
                <div className={CnTasksTab('taskCreationFrom', { withoutGroup: true })}>
                    <TaskBlock
                        blockTitle=""
                        course={courseGroupId}
                        courseId={courseId}
                        lesson={lesson}
                        subject={subject}
                        tasks={assignmentTasks}
                        onAssignmentDelete={handleAssignmentDelete}
                        hasConference={conferenceId !== undefined}
                        disabledSelect
                        lessonSchedule={lessonScheduleId}
                    />
                </div>
            </div>
        );
    }

    return (
        <div className={CnTasksTab()}>
            {dataFetched && (
                <>
                    <div className={CnTasksTab('taskCreationFrom')}>
                        <TaskBlock
                            course={courseGroupId}
                            lesson={lesson}
                            subject={subject}
                            tasks={assignmentTasks}
                            courseId={courseId}
                            onSelect={handleTaskSelect}
                            onDateSelect={handleDateSelected}
                            onTeacherSelect={handleTeacherSelected}
                            onAssignmentDelete={handleAssignmentDelete}
                            hasConference={conferenceId !== undefined}
                            repeatTask={repeatTaskForm}
                            lessonSchedule={lessonScheduleId}
                        />
                    </div>
                    <div className={CnTasksTab('tasks')}>
                        <div className={CnTasksTab('tasksHeader')}>
                            <div className={CnTasksTab('homeworkStatus')}>
                                <HomeworkStatus {...homeworkStatus} withoutBorder />
                            </div>
                            <div className={CnTasksTab('headerActionButton')}>
                                <Button
                                    view={ButtonViewEnum.action}
                                    size="l"
                                    disabled={disabled || isSaving}
                                    onClick={handleFormButtonClick}
                                >
                                    {repeatTaskForm ? 'Выдать повторно' : 'Сохранить и выдать'}
                                </Button>
                                {(showAssigningForm || repeatTaskForm) && (
                                    <AppButton
                                        label={'Отмена'}
                                        type={'outline'}
                                        onClick={() => onCancelButtonClick()}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={CnTasksTab('taskAssigningForm')}>
                            <AssigningHomeworkForm
                                tasks={newTasks}
                                assignmentProgress={assignmentProgress}
                                students={students}
                                initialValues={formValues}
                                onChange={handleAssigningFormChange}
                                onStudentCellClick={handleStudentCellClick}
                                repeatTaskForm={repeatTaskForm}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

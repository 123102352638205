import dayjs, { Dayjs } from 'dayjs';
import { IAppTableCellElementData, IAppTableItems, updateTableCellTitle } from 'src-new/ui';
import { formatJSToTableDate, formatTableDateToDayjs } from 'src-new/utils';
import { eduWeeksType } from 'types/calendarPlan';

import { IAdditionalDaysDayJs, IPeriodsDateRange } from './additionalDaysTable';

type calendarOwnerTypes = 'periodTable' | 'holidayTable' | 'additionalDays' | undefined;
type disableDatesTypes = 'low' | 'high' | 'rangeAndSame' | undefined;

interface ICalendarDataFromTable {
    currentDate: Dayjs;
    borderDate?: string;
    disableDatesType?: disableDatesTypes;
}

/**
 * @description получаем данные для открытия календаря в зависимости от таблицы
 * @param {IAppTableCellElementData} cellData
 * @param {IAppTableItems} table
 * @return ICalendarDataFromTable
 */
const getCalendarDataFromTable = (
    cellData: IAppTableCellElementData,
    table?: IAppTableItems,
): ICalendarDataFromTable => {
    const { cell, row, title, type } = cellData;
    const currentDate = title ? formatTableDateToDayjs(title.toString()) : dayjs();
    let borderDate;
    let disableDatesType: disableDatesTypes;
    if (type == 'date' && cell != undefined) {
        const currentRow = table?.[row];
        const isStartDate = cell == 1;
        const currentCell = isStartDate ? currentRow?.[cell + 1] : currentRow?.[cell - 1];
        disableDatesType = isStartDate ? 'low' : 'high';
        borderDate = currentCell?.title?.toString();
    }
    return { currentDate, borderDate, disableDatesType };
};

const getDaysJsArray = (start: string | Dayjs, end: string | Dayjs): Array<Dayjs> => {
    const startDayJS = dayjs.isDayjs(start) ? start : formatTableDateToDayjs(start);
    const endDayJS = dayjs.isDayjs(end) ? end : formatTableDateToDayjs(end);
    let currentDayJS = startDayJS;
    const array = [currentDayJS];
    while (startDayJS.isBefore(endDayJS) && !currentDayJS.isSame(endDayJS, 'day')) {
        currentDayJS = currentDayJS.add(1, 'day');
        array.push(currentDayJS);
    }
    return array;
};

const getCountOfDaysAndWeek = (
    daysArray: Array<Dayjs>,
    eduWeek?: eduWeeksType,
): { dayCount: number; weekCount: number } => {
    const arrayLength = daysArray.length;
    let dayCount = 0;
    for (let i = 0; i < arrayLength; i++) {
        const isSaturday = daysArray[i].day() == 6;
        const isSunday = daysArray[i].day() == 0;
        switch (eduWeek) {
            case 6:
                if (!isSunday) dayCount++;
                break;
            default:
                if (!(isSaturday || isSunday)) dayCount++;
                break;
        }
    }
    const weekCount = Math.ceil(dayCount / (eduWeek ?? 7));
    return { dayCount, weekCount };
};

/**
 * @description считаем количество дней и недель между 2 датами
 * @param {Dayjs} date
 * @param {IAppTableItems} table
 * @param {number} cell
 * @param {number} row
 * @param {eduWeeksType} eduWeek
 * @return IAppTableItems
 */
const changeTableNumberOfDaysAndWeeksBetween = (
    date: Dayjs,
    cell: number,
    row: number,
    table: IAppTableItems,
    eduWeek?: eduWeeksType,
): IAppTableItems => {
    let startDate;
    let endDate;
    const isRev = cell == 2;
    const plusNumber = isRev ? 1 : 2;

    if (isRev) {
        endDate = date;
        const cellDate = table[row][cell - 1]?.title ?? undefined;
        startDate = cellDate ? formatTableDateToDayjs(cellDate.toString()) : undefined;
    } else {
        startDate = date;
        const cellDate = table[row][cell + 1]?.title ?? undefined;
        endDate = cellDate ? formatTableDateToDayjs(cellDate.toString()) : undefined;
    }
    let counts;

    if (startDate && endDate) {
        const daysArray = getDaysJsArray(startDate, endDate);
        counts = getCountOfDaysAndWeek(daysArray, eduWeek);
    }

    const newTable = updateTableCellTitle(table, row, cell + plusNumber, counts?.dayCount ?? 0);
    return updateTableCellTitle(newTable, row, cell + plusNumber + 1, counts?.weekCount ?? 0);
};

/**
 * @description изменяем значения дат и их разность в таблице
 * @param {Dayjs} date
 * @param {IAppTableItems} table
 * @param {IAppTableCellElementData} activeCell
 * @param {calendarOwnerTypes} activeTableName
 * @param {eduWeeksType} eduWeek
 * @return IAppTableItems
 */
const changeTableDateAndDuration = (
    date: Dayjs,
    table: IAppTableItems,
    activeCell?: IAppTableCellElementData,
    activeTableName?: calendarOwnerTypes,
    eduWeek?: eduWeeksType,
): IAppTableItems => {
    const { row, cell } = activeCell || {};
    if (row !== undefined && cell !== undefined) {
        const dateString = formatJSToTableDate(date.toDate());
        let newTable = updateTableCellTitle(table, row, cell, dateString);
        if (activeTableName == 'periodTable') {
            newTable = changeTableNumberOfDaysAndWeeksBetween(date, cell, row, newTable, eduWeek);
        }
        return newTable;
    }
    return table;
};

/**
 * @description сравниваем даты
 * @param {Dayjs} date
 * @param {string} borderDate
 * @param {boolean} higher
 * @return boolean
 */
const compareDates = (date: Dayjs, borderDate?: string, higher?: boolean): boolean => {
    const dayjsDate = borderDate ? formatTableDateToDayjs(borderDate) : undefined;
    if (dayjsDate) {
        if (higher) {
            return dayjsDate.isAfter(date, 'day');
        } else {
            return dayjsDate.isBefore(date, 'day');
        }
    }
    return false;
};

/**
 * @description отключаем даты которые больше
 * @param {Dayjs} date
 * @param {string} borderDate
 * @return boolean
 */
const disabledDateHigher = (date: Dayjs, borderDate?: string): boolean => {
    return compareDates(date, borderDate, true);
};

/**
 * @description отключаем даты которые меньше
 * @param {Dayjs} date
 * @param {string} borderDate
 * @return boolean
 */
const disabledDateLower = (date: Dayjs, borderDate?: string): boolean => {
    return compareDates(date, borderDate);
};

/**
 * @description отключаем даты которые входят в список или не попадают в промежуток
 * @param {Dayjs} date
 * @param {IAdditionalDaysDayJs} sameDates
 * @param {IPeriodsDateRange} range
 * @return boolean
 */
const disabledRangeAndSameDates = (
    date: Dayjs,
    sameDates: IAdditionalDaysDayJs,
    range?: IPeriodsDateRange,
): boolean => {
    const { holidays, additionalWorkingDays } = sameDates;
    const days = [...holidays, ...additionalWorkingDays];
    const index = days.findIndex((element) => date.isSame(element, 'day'));
    const isSame = index !== -1;
    let inRange = true;
    if (range) {
        const { end, start } = range;
        inRange = date.isBetween(start, end, 'day', '[]');
    }
    return !(inRange && !isSame);
};

export {
    changeTableDateAndDuration,
    disabledDateHigher,
    disabledDateLower,
    disabledRangeAndSameDates,
    getCalendarDataFromTable,
    getCountOfDaysAndWeek,
    getDaysJsArray,
};
export type { calendarOwnerTypes, ICalendarDataFromTable };

import { StatusLabelEnum } from '@lms-elements/atomic';
import { AttendanceStatus } from 'src-new/components/lms-elements/PerformanceTable/types';

export const convertAttendanceStatus = (status: AttendanceStatus): StatusLabelEnum => {
    switch (status) {
        case 'absent':
            return StatusLabelEnum.wasAbsent;
        case 'late':
            return StatusLabelEnum.isLate;
        case 'present':
            return StatusLabelEnum.wasInClass;
        case 'absent_with_reason':
            return StatusLabelEnum.seriousReason;
        case 'absent_due_illness':
            return StatusLabelEnum.illness;
        default:
            return StatusLabelEnum.wasInClass;
    }
};

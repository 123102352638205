import { patchApiRequest } from 'api';
import { AttendanceStatus } from 'src-new/components/lms-elements/PerformanceTable/types';

import { AttendanceResponseData } from '.';

export interface PatchAttendanceParams {
    status: AttendanceStatus;
    lateDuration: number;
}

export const changeStudentPresence = async (
    attendanceId: number,
    params: PatchAttendanceParams,
): Promise<AttendanceResponseData> => {
    return await patchApiRequest<AttendanceResponseData, PatchAttendanceParams>(
        `/attendance-lesson/partial-update/${attendanceId}/`,
        params,
    );
};

import { AttendanceResponseData } from 'api/services/attendance';
import { AttendanceStatus } from 'src-new/components/lms-elements/PerformanceTable/types';
import { AttendanceData } from 'types/attendance';
import { MarkData } from 'types/mark';

const LESSON_DURATION = 40;

/**
 *
 * @param minutes - time in minutes
 * @param typeOfInfo - attendance status
 */
const getInfoDescription = (minutes: number, typeOfInfo?: 'camera'): string => {
    const percent = Math.round((minutes / LESSON_DURATION) * 100);
    if (!typeOfInfo) {
        return `Присутствовал ${percent}% - ${minutes}м урока`;
    }

    return `Камера была включена ${percent}% - ${minutes ?? 0}м урока`;
};

/**
 *
 * @param minutes - time in minutes
 * @param status - attendance status
 */
function getInfo(minutes: number, status: AttendanceStatus): { isRecognizedFace: boolean; description?: string };
function getInfo(
    minutes: number,
    status: AttendanceStatus,
    typeOfInfo: 'camera',
): { isTurnedOn: boolean; description?: string };
function getInfo(
    minutes: number,
    status: AttendanceStatus,
    typeOfInfo?: 'camera',
): { isRecognizedFace: boolean; description?: string } | { isTurnedOn: boolean; description?: string } {
    const description = getInfoDescription(minutes, typeOfInfo);

    if (!typeOfInfo) {
        if (status === AttendanceStatus.ABSENT) {
            return {
                isRecognizedFace: false,
                description: 'Отсутствовал на уроке',
            };
        }

        if (minutes < 5) {
            return {
                isRecognizedFace: false,
                description,
            };
        }

        return { isRecognizedFace: true, description };
    }

    if (status === AttendanceStatus.ABSENT) {
        return {
            isTurnedOn: false,
            description: 'Отсутствовал на уроке',
        };
    }

    if (minutes < 5) {
        return {
            description,
            isTurnedOn: false,
        };
    }

    return {
        description,
        isTurnedOn: true,
    };
}

export const normalizeAttendanceData = (data: AttendanceResponseData[] = []): Record<number, AttendanceData> => {
    return data.reduce((attendanceMap, attendance) => {
        const { id, student: studentInfo, status, lateDuration, cameraDuration, presenceDuration, marks } = attendance;

        attendanceMap[id] = {
            id,
            studentId: studentInfo.id,
            studentImage: studentInfo?.photoUrl || '',
            studentFirstName: studentInfo.firstName,
            studentLastName: studentInfo.lastName,
            isAdaptation: studentInfo?.isAdaptation,
            presenceInfo: { status, lateMinutes: lateDuration },
            cameraInfo: getInfo(cameraDuration, status, 'camera'),
            faceInfo: getInfo(presenceDuration || 0, status),
            marks: marks.reduce((marks, mark) => {
                marks.push({
                    id: mark.id,
                    score: mark.score,
                    comment: mark.comment,
                    weight: mark.weight,
                    author: mark.author?.id,
                    authorFirstName: mark.author?.firstName,
                    authorLastName: mark.author?.lastName,
                    authorPatronymic: mark.author?.patronymic,
                });
                return marks;
            }, [] as MarkData[]),
        };

        return attendanceMap;
    }, {} as Record<number, AttendanceData>);
};

import { getItemFromLocalStorage, setItemToLocalStorage } from 'src-new/utils';
import { TLocalStorageItem } from 'types/newSubjectsTypes';

const LOCAL_STORAGE_NAME = 'lastViewedLessons';

export const getLastViewedLessonByCourse = (courseId: number): TLocalStorageItem | undefined => {
    const dataString = getItemFromLocalStorage(LOCAL_STORAGE_NAME);
    const data = dataString ? JSON.parse(dataString) : [];

    return data.find((item: TLocalStorageItem) => item.course.id === courseId);
};

export const getAllLastViewedLessons = (): TLocalStorageItem[] => {
    const dataString = getItemFromLocalStorage(LOCAL_STORAGE_NAME);

    return dataString ? JSON.parse(dataString) : [];
};
export const setViewedLesson = (newItem: TLocalStorageItem): void => {
    let newState: TLocalStorageItem[] = [];
    const prevState = getAllLastViewedLessons();

    if (prevState.length) {
        newState = prevState.filter((item: TLocalStorageItem) => item.course.id !== newItem.course.id);
    }
    newState.push(newItem);

    setItemToLocalStorage(LOCAL_STORAGE_NAME, JSON.stringify(newState));
};

import { patchApiRequest } from 'api';

import { ResponseRevisionQuestion } from './createRevisionQuestion';

export interface PatchRevisionQuestionParams {
    id: number;
    comment?: string;
    question?: number;
}

export const updateRevisionQuestion = ({
    id,
    ...params
}: PatchRevisionQuestionParams): Promise<ResponseRevisionQuestion> => {
    return patchApiRequest(`/codifier/question-revision/partial-update/${id}/`, params);
};

import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { AcademicYear } from 'api/services/academicYear';
import { getAcademicYearAction } from 'store/actions/academicYear';
import { FetchStatus } from 'types/api';

interface AcademicYearState {
    fetchAcademicYearStatus: FetchStatus;
    academicYear: AcademicYear[];
    error: unknown;
}

const initialState = {
    fetchAcademicYearStatus: FetchStatus.INITIAL,
    academicYear: [],
    error: null,
};

const academicYearSlice = createSlice<AcademicYearState, SliceCaseReducers<AcademicYearState>>({
    name: 'academic-year',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAcademicYearAction.pending, (state) => {
                state.fetchAcademicYearStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getAcademicYearAction.fulfilled, (state, { payload }) => {
                state.fetchAcademicYearStatus = FetchStatus.FETCHED;
                state.academicYear = payload;
                state.error = null;
            })
            .addCase(getAcademicYearAction.rejected, (state, { error }) => {
                state.fetchAcademicYearStatus = FetchStatus.ERROR;
                state.error = error;
            });
    },
});

export const resetAcademicYearState = academicYearSlice.actions.reset as ActionCreatorWithoutPayload<string>;
export const academicYearReducer = academicYearSlice.reducer;

import React from 'react';
import { EMPTY_ARRAY_3 } from 'src-new/constants';
import { AppSkeleton } from 'src-new/ui';
import { getUid } from 'src-new/utils';

import './style.scss';

type TCourseCardSkeleton = {
    type: 'course-card' | 'title' | 'button' | 'option' | 'lesson-card';
    isInverseBackground?: boolean;
};
export const SubjectsElementsSkeleton: React.FC<TCourseCardSkeleton> = (props: TCourseCardSkeleton) => {
    const { type, isInverseBackground = false } = props;

    const Content = () => {
        switch (type) {
            case 'button':
                return (
                    <>
                        <AppSkeleton width={'80px'} height={'18px'} />
                    </>
                );
            case 'option':
                return (
                    <>
                        <AppSkeleton key={`option-1`} width={'14px'} height={'14px'} />
                        <AppSkeleton key={`option-2`} height={'8px'} />
                    </>
                );
            case 'lesson-card':
                return (
                    <>
                        <section className={'lesson-card__header'}>
                            <AppSkeleton key={`lesson-card-header-1`} height={'12px'} />
                            <AppSkeleton key={`lesson-card-header-2`} width={'144px'} height={'12px'} />
                        </section>
                        <section className={'lesson-card__list'}>
                            {EMPTY_ARRAY_3.map(() => (
                                <article key={getUid()} className={'lesson-card__list_item'}>
                                    <AppSkeleton key={`lesson-card-list-item-1`} width={'12px'} height={'12px'} />
                                    <AppSkeleton key={`lesson-card-list-item-2`} width={'200px'} height={'8px'} />
                                </article>
                            ))}
                        </section>
                        <section className={'lesson-card__footer'}>
                            <AppSkeleton height={'8px'} />
                        </section>
                    </>
                );
            case 'course-card':
                return (
                    <>
                        <AppSkeleton key={`course-card-1`} width={'100px'} height={'18px'} />
                        <AppSkeleton key={`course-card-2`} height={'8px'} />
                    </>
                );
            case 'title':
            default:
                return (
                    <>
                        <AppSkeleton width={'100px'} height={'28px'} />
                    </>
                );
        }
    };

    return (
        <div className={`course-card-skeleton ${isInverseBackground ? 'inverse' : ''} ${type}`}>
            <Content />
        </div>
    );
};

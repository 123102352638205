/**
 * Returns an array of url parts
 * @param url - document url
 * @returns array of string
 */

export const getPathParts = (url: string): string[] => {
    return url.split('/').slice(2);
};

/**
 * Returns an array of url parts
 * @param url - document url
 * @returns array of string
 */

export const getPathBook = (url: string): string[] => {
    return url.split('book-view/');
};

export const getStudentTasksParts = (url: string): number[] => {
    return url.match(/\d+/gi)?.map((part) => Number(part)) || [];
};

import React from 'react';
import { AppButton } from '@frontend-modules/ui-kit';
import { QuestionsListBar } from 'src-new/components/lesson-task';
import { AppSkeleton } from 'src-new/ui';

import { TQuestionSearchContainerProps } from './QuestionSearchResult.types';
import { SearchResult } from './search-result';

import './style.scss';

export const QuestionSearchResult: React.FC<TQuestionSearchContainerProps> = ({
    selectedQuestionsList,
    searchResults,
    questionsList,
    handleQuestionClick,
    activeQuestionNumber,
    isLoading = false,
    handleSearchingCancel,
    handleSearchingSave,
    isTrainer,
    handleChangeQuestionList,
}) => {
    return (
        <div className={'question-search-result'}>
            <div className={'question-search-result__top-bar'}>
                <div className={'question-search-result__top-bar_questions'}>
                    {!isTrainer && (
                        <QuestionsListBar
                            questionList={questionsList}
                            orientation={'horizontal'}
                            activeNumber={activeQuestionNumber}
                            handleChangeList={handleChangeQuestionList}
                            handleQuestionClick={handleQuestionClick}
                        />
                    )}
                </div>
                <div className={'question-search-result__top-bar_submit'}>
                    <AppButton
                        label={'Сохранить вопросы'}
                        type={'primary'}
                        size={'large'}
                        onClick={handleSearchingSave.bind(this)}
                    />
                    <AppButton
                        label={'Отмена'}
                        size={'large'}
                        type={'outline'}
                        onClick={handleSearchingCancel.bind(this)}
                    />
                </div>
            </div>
            <div className={'question-search-result__search-result'}>
                {isLoading ? (
                    <div className={'question-search-result__search-result_skelet'}>
                        <AppSkeleton width={'100%'} height={'100%'} />
                        <AppSkeleton width={'100%'} height={'100%'} />
                    </div>
                ) : (
                    <SearchResult
                        selectedQuestions={selectedQuestionsList?.[activeQuestionNumber ?? 1]}
                        searchQuestions={searchResults}
                        activeQuestionNumber={activeQuestionNumber ?? 1}
                        isTrainer={isTrainer}
                    />
                )}
            </div>
        </div>
    );
};

import React from 'react';
import { AppText } from '@frontend-modules/ui-kit';
import { E_COLORS } from 'src-new/constants';

type TBarItemIcon = {
    numClass: 'common' | 'active';
    numText: string;
    disabled?: boolean;
    handleQuestionClick: () => void;
};
export const BarItemIcon: React.FC<TBarItemIcon> = (props: TBarItemIcon) => {
    const { numClass, numText, disabled, handleQuestionClick } = props;
    return (
        <div
            className={`question-number ${numClass} ${disabled ? 'disabled' : ''}`}
            onClick={handleQuestionClick.bind(this)}
        >
            <AppText
                text={`${numText}`}
                textStyle={'DesktopFontBody'}
                color={numClass === 'active' ? E_COLORS.white_color : E_COLORS.light_grey_color_1}
            />
        </div>
    );
};

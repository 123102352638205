import {
    TCriteriaGroup,
    TCriteriaSetting,
    TGetCriteriaGroupsProps,
    TGetCriteriaSettingProps,
    TGetMonitoringStaffListProps,
    TGetMonitoringSubjectsListProps,
    TGetQuestionsCreatedProgressProps,
    TGetSummaryTableDataProps,
    TGetTeachersCriteriaStatisticProps,
    TGetTeachesWorkDaysProps,
    TMonitoringStaffList,
    TMonitoringSubjectsList,
    TQuestionsCreatedDiagramData,
    TQuestionsCreatedDiagramDataFilters,
    TQuestionsCreatedProgress,
    TQuestionsCreatedTableData,
    TQuestionsCreatedTableDataProps,
    TSummaryChartData,
    TSummaryChartDataFilters,
    TSummaryTableData,
    TTeacherInfo,
    TTeachersCriteriaStatistic,
    TTeachersMonitoringData,
    TTeachersQuestionsCreatedDiagram,
    TTeachersWorkDaysRes,
} from '@fsd-shared/models/statisticsMonitoring';
import { getUrlParamsString } from '@fsd-shared/utils';
import { postApiRequest } from 'api';

import { getMonitoringRequest, postMonitoringRequest } from './requestServices';

export const getMonitoringStaffList = (props: TGetMonitoringStaffListProps): Promise<TMonitoringStaffList> => {
    const payload = {
        filters: {
            search: props.search,
            roles: props.roles,
            subjects: props.subjects,
            ids: props.ids,
        },
        currentPage: props.currentPage,
        limit: props.limit,
    };
    return postApiRequest('/api/v2/monitoring/staff-users/', payload);
};
export const getMonitoringSubjectsList = (props: TGetMonitoringSubjectsListProps): Promise<TMonitoringSubjectsList> => {
    const payload = {
        filters: {
            search: props.search,
        },
        currentPage: props.currentPage,
        limit: props.limit,
    };
    return postApiRequest('/api/v2/monitoring/subjects/', payload);
};

export const getCriteriaGroupsList = (props?: TGetCriteriaGroupsProps): Promise<TCriteriaGroup[]> => {
    const queryString = props ? getUrlParamsString(props) : '';
    return getMonitoringRequest(`/api/v1/criteria/${queryString}`);
};

export const getTeacherMonitoringData = (date?: string): Promise<TTeachersMonitoringData> => {
    return postMonitoringRequest('/api/v1/progress/', { date });
};
export const getCriteriaSetting = (props: TGetCriteriaSettingProps): Promise<TCriteriaSetting> => {
    return postMonitoringRequest('/api/v1/criterion-params/', props);
};

export const getSummaryTableData = (props: TGetSummaryTableDataProps): Promise<TSummaryTableData> => {
    return postMonitoringRequest('api/v1/table/', props);
};
export const getTeachersCriteriaStatistic = (
    props: TGetTeachersCriteriaStatisticProps,
): Promise<TTeachersCriteriaStatistic> => {
    return postMonitoringRequest('api/v1/teacher/', props);
};
export const getTeachesInfo = (id: string): Promise<TTeacherInfo> => {
    return postMonitoringRequest('api/v1/teacher/info/', { user: id });
};
export const getTeachesWorkDays = (props: TGetTeachesWorkDaysProps): Promise<TTeachersWorkDaysRes> => {
    return postMonitoringRequest('api/v1/teacher/work-days/', props);
};
export const getQuestionsCreatedProgress = (
    props: TGetQuestionsCreatedProgressProps,
): Promise<TQuestionsCreatedProgress> => {
    return postMonitoringRequest('api/v1/teacher/questions-created/', props);
};
export const getSummaryChartData = (filters: TSummaryChartDataFilters): Promise<TSummaryChartData> => {
    return postMonitoringRequest('api/v1/diagram/', filters);
};
//добавление вопросов в банк вопросов
export const getQuestionsCreatedTableData = (
    filters: TQuestionsCreatedTableDataProps,
): Promise<TQuestionsCreatedTableData> => {
    return postMonitoringRequest('api/v1/table/questions-created/', filters);
};
export const getQuestionsCreatedDiagramData = (
    filters: TQuestionsCreatedDiagramDataFilters,
): Promise<TQuestionsCreatedDiagramData> => {
    return postMonitoringRequest('api/v1/diagram/questions-created/', filters);
};
export const getTeachersQuestionsCreatedDiagram = (date?: string): Promise<TTeachersQuestionsCreatedDiagram> => {
    return postMonitoringRequest('api/v1/progress/questions-created/', { date });
};

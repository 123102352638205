import { deleteApiRequest, getApiRequest, patchApiRequest, postApiRequest } from 'api';
import {
    TAuthorMainInfo,
    TAuthorsResponse,
    TBook,
    TBookAuthors,
    TBookFile,
    TBookFileEditPayload,
    TBookFilePayload,
    TBookMainInfo,
    TBookResponse,
    TBookType,
    TCourseType,
    TCreateCourseTypePayload,
    TEditCourseTypePayload,
    TGetAuthorsListPayload,
    TGetBooksListPayload,
    TGetLibraryTreePayload,
    TLibraryTree,
} from 'types/libraryTypes';

export const getBooksList = (params: TGetBooksListPayload): Promise<TBookResponse> => {
    const { type, course, limit, currentPage } = { ...params };
    const typeQuery = type ? `type=${type}&` : '';
    return getApiRequest(
        `/codifier/library/book/?${typeQuery}course=${course}&limit=${(limit as number) || 10}&currentPage=${
            (currentPage as number) || 1
        }`,
    );
};

export const getBookById = (id: number): Promise<TBook> => {
    return getApiRequest(`/codifier/library/book/${id}/`);
};

export const getBookTypesList = (): Promise<TBookType[]> => {
    return getApiRequest(`/codifier/library/book-type/`);
};
export const createCourseType = (params: TCreateCourseTypePayload[]): Promise<TCourseType> => {
    return postApiRequest(`/codifier/library/course-type-book/bulk-create/`, params);
};
export const editCourseType = (params: TEditCourseTypePayload[]): Promise<TCourseType> => {
    return patchApiRequest(`/codifier/library/course-type-book/bulk-update/`, params);
};
export const deleteCourseType = (ids: number[]): Promise<TCourseType> => {
    const params = {
        ids: ids,
    };
    return deleteApiRequest(`/codifier/library/course-type-book/bulk-delete/`, { data: params });
};
export const getLibraryTree = (params: TGetLibraryTreePayload): Promise<TLibraryTree[]> => {
    return getApiRequest(`/codifier/library/tree/?subject=${params.subject}`);
};

export const createBook = (params: TBookMainInfo): Promise<TBook> => {
    return postApiRequest(`/codifier/library/book/create/`, params);
};

export const editBook = (params: { id: number; data: TBookMainInfo }): Promise<TBook> => {
    return patchApiRequest(`/codifier/library/book/partial-update/${params?.id}/`, params?.data);
};

export const deleteBook = (id: number): Promise<void> => {
    return deleteApiRequest(`/codifier/library/book/delete/${id}/`);
};

export const getAuthorsList = (params?: TGetAuthorsListPayload): Promise<TAuthorsResponse> => {
    const { fullname, currentPage, limit } = { ...params };

    const url = fullname
        ? `/codifier/library/book-author/?fullname=${fullname}&limit=${(limit as number) || 10}&currentPage=${
              (currentPage as number) || 1
          }`
        : `/codifier/library/book-author/?limit=${(limit as number) || 10}&currentPage=${(currentPage as number) || 1}`;

    return getApiRequest(url);
};

export const createAuthor = (params: TAuthorMainInfo[]): Promise<TBookAuthors[]> => {
    return postApiRequest(`/codifier/library/book-author/bulk-create/`, params);
};

export const editAuthor = (params: TBookAuthors[]): Promise<TBookAuthors[]> => {
    return patchApiRequest(`/codifier/library/book-author/bulk-update/`, params);
};

export const deleteAuthor = (ids: number[]): Promise<void> => {
    const params = {
        ids: ids,
    };
    return deleteApiRequest(`/codifier/library/book-author/bulk-delete/`, { data: params });
};

export const createBookFile = (params: TBookFilePayload): Promise<TBookFile> => {
    const data = new FormData();
    data.append('file', params.file);
    data.append('book', `${params.book}`);
    return postApiRequest(`/book-file/create/`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
        },
    });
};

export const editBookFile = (params: TBookFileEditPayload): Promise<TBookFile> => {
    const data = new FormData();
    data.append('file', params.data.file);
    data.append('book', `${params.data.book}`);
    return patchApiRequest(`/book-file/update/${params?.id}/`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
        },
    });
};

export const deleteBookFile = (id: number): Promise<void> => {
    return deleteApiRequest(`/book-file/delete/${id}/`);
};

import { ITrueFalseAnswerData, ITrueFalseCheckedAnswer } from '@fsd-shared/models/materials';

const TRUE_FALSE_ANSWER_OPTIONS = [
    { value: false, label: 'Неверно' },
    { value: true, label: 'Верно' },
];

export const normalizeTrueFalseAnswerData = (): ITrueFalseAnswerData => {
    return {
        answerOptions: TRUE_FALSE_ANSWER_OPTIONS,
    };
};
export const normalizeTrueFalseCorrectAnswerData = (correctAnswer: ITrueFalseCheckedAnswer): ITrueFalseAnswerData => {
    const { data, isCorrect } = correctAnswer;
    return {
        answerOptions: TRUE_FALSE_ANSWER_OPTIONS.map((item) => {
            if (item.value === data.answer) {
                return { ...item, correctness: isCorrect ? 'correct' : 'incorrect' };
            }
            return { ...item };
        }),
        correctness: isCorrect ? 'correct' : 'incorrect',
    };
};

import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { StudentGroupResponse } from 'api/services/studentGroups';
import { getStudentGroupsAction } from 'store/actions/studentGroups';
import { normalizeStudentGroup } from 'store/normalizers/studentGroups/normalizeStudentGroups';
import { FetchStatus } from 'types/api';

export type StudentGroupMap = {
    [groupId: number]: StudentGroupResponse;
};

interface StudentGroupsState {
    getStudentGroupsStatus: FetchStatus;
    studentGroupsMap: StudentGroupMap;
    error: unknown;
}

const initialState: StudentGroupsState = {
    getStudentGroupsStatus: FetchStatus.INITIAL,
    studentGroupsMap: {} as StudentGroupMap,
    error: null,
};

const studentGroupsSlice = createSlice<StudentGroupsState, SliceCaseReducers<StudentGroupsState>>({
    name: 'studentGroup',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getStudentGroupsAction.pending, (state) => {
                state.getStudentGroupsStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getStudentGroupsAction.fulfilled, (state, { payload }) => {
                state.getStudentGroupsStatus = FetchStatus.FETCHED;
                state.studentGroupsMap = normalizeStudentGroup(payload);
            })
            .addCase(getStudentGroupsAction.rejected, (state, { error }) => {
                state.getStudentGroupsStatus = FetchStatus.ERROR;
                state.error = error;
            });
    },
});

export const studentGroupReducer = studentGroupsSlice.reducer;

import React from 'react';
import { cn } from '@bem-react/classname';
import { Checkbox } from '@lms-elements/atomic';
import { TaskNoMark, TaskTemplate } from '@lms-elements/icons';

import './TaskView.scss';

export interface ITaskViewProps {
    title: string;
    isTemplate?: boolean;
    isPosted?: boolean;
    canRepeatTask?: boolean;
    isForMethodist?: boolean;
    handleSelect?: () => void;
    onTitleClick?: () => void;
    withCheckbox?: boolean;
}

const CnClassTaskView = cn('task_view');

export const TaskView: React.FC<ITaskViewProps> = ({
    title,
    isTemplate = false,
    isPosted = false,
    canRepeatTask = false,
    isForMethodist = false,
    handleSelect,
    onTitleClick,
    withCheckbox = true,
}) => {
    return (
        <div className={CnClassTaskView()}>
            {withCheckbox && (
                <Checkbox
                    handleCheckboxChange={handleSelect}
                    disabled={isPosted && !canRepeatTask}
                    defaultChecked={canRepeatTask ? true : isPosted}
                />
            )}
            {!isTemplate && <TaskNoMark />}
            {isTemplate && <TaskTemplate />}
            <div className={CnClassTaskView('main')} onClick={onTitleClick}>
                <div
                    className={CnClassTaskView('title', {
                        template: isTemplate,
                        normal: !isTemplate && isForMethodist,
                        disabledSelect: !withCheckbox,
                    })}
                >
                    <span>{title}</span>
                </div>
            </div>
        </div>
    );
};

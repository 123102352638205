import { codifierPageData } from 'constants/cypress/codifier-page';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';
import { GetMore, LittleArrow, Plus } from '@lms-elements/icons';

import { CodifierEventType } from 'components/Codifier/Codifier';
import { getGradesOptions } from 'components/Codifier/utils';
import { DropdownWithActions } from 'components/DropdownWithActions';
import { ThemeTooltip } from 'components/ThemeTooltip';

import './SubjectName.scss';

const CnSubjectName = cn('subjectName');

interface ISubjectNameProps {
    id?: number;
    title: string;
    onClickAdd?: (id: number, type: string) => void;
    onClickMore?: () => void;
    handleCopyClick?: () => void;
    handleCreateClick?: (id: number, values: Record<string, { title: string }>) => void;
    handleDeleteClick?: () => void;
    handleEditClick?: () => void;
    handleSelect?: (type: CodifierEventType, id: number) => void;
    parallels?: {
        grade: number;
    }[];
    isClickMore: boolean;
    isEditable?: boolean;
    isOpenedInit?: boolean;
    isHighlighted?: boolean;
    canEdit?: boolean;
    disabled?: boolean;
    canHover?: boolean;
    handleCanHover?: () => void;
    setMounted?: React.Dispatch<React.SetStateAction<boolean>>;
    index: number;
}

export const SubjectName: React.FC<ISubjectNameProps> = ({
    id,
    title,
    children,
    parallels,
    handleCreateClick,
    handleCopyClick,
    handleDeleteClick,
    handleEditClick,
    handleSelect,
    isClickMore,
    isEditable = true,
    isOpenedInit = false,
    isHighlighted = false,
    disabled = false,
    canEdit,
    canHover = true,
    handleCanHover,
    setMounted,
    index,
}) => {
    const [isHovered, setIsHovered] = useState(false);
    const [isClickShow, setIsClickShow] = useState(isOpenedInit);
    const [isCreated, setIsCreated] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [isAdded, setIsAdded] = useState(false);

    const grades = useMemo(() => {
        if (parallels) {
            return getGradesOptions(parallels?.map((parallel) => parallel.grade));
        }

        return [];
    }, [parallels]);

    useEffect(() => {
        setMounted?.(true);

        return () => setMounted?.(false);
    }, [setMounted]);

    const toggleShow = useCallback(() => {
        if (!disabled) {
            setIsClickShow((prev) => !prev);
            if (handleSelect && id && !isClickShow) {
                handleSelect(CodifierEventType.SUBJECT, id);
            }
        }
    }, [disabled, handleSelect, id, isClickShow]);

    const toggleHover = useCallback(
        (value) => {
            if (canHover) {
                setIsHovered(value);
            }
        },
        [canHover],
    );

    const showDots = useMemo(() => isHovered || isClickMore, [isHovered, isClickMore]);

    const handleClickAdd = useCallback(() => {
        setIsCreated((prev) => !prev);
        if (handleCanHover) {
            handleCanHover();
        }
        setIsHovered(false);
    }, [handleCanHover]);

    const handleEditButtonClick = useCallback(() => {
        setIsEdited(!isEdited);
    }, [isEdited]);

    const handleAddFormClick = useCallback(
        (values: Record<string, { title: string }>) => {
            if (handleCreateClick && id) handleCreateClick(id, values);
            setIsAdded(true);
        },
        [handleCreateClick, id],
    );

    useEffect(() => {
        if (!(isEdited || isCreated)) {
            if (disabled && isClickShow && !isAdded) {
                setIsClickShow(false);
            }
            if (!disabled && isAdded) {
                setIsClickShow(true);
                setIsAdded(false);
            }
        }
    }, [disabled, isClickShow, isEdited, isCreated, isAdded]);

    return (
        <div className={CnSubjectName()}>
            <div
                className={CnSubjectName('container', { highlighted: isHighlighted || isEdited || isCreated })}
                onMouseEnter={toggleHover.bind(null, true)}
                onMouseLeave={toggleHover.bind(null, false)}
            >
                <div
                    className={CnSubjectName('title', {
                        highlighted: isHighlighted || isEdited || isCreated,
                        disabled,
                    })}
                    data-cy={codifierPageData.getSubjectNameTitle(index)}
                    onClick={toggleShow}
                >
                    <div
                        className={CnSubjectName('open', {
                            isClickShow: isClickShow || isEdited || isCreated,
                            disabled,
                        })}
                    >
                        <LittleArrow />
                    </div>
                    <div className={CnSubjectName('subject')}>{title}</div>
                </div>
                <div className={CnSubjectName('buttons')}>
                    {(showDots || isEdited) && isEditable && (
                        <>
                            {grades && !!grades.length && (
                                <div className={CnSubjectName('icon')} onClick={handleClickAdd}>
                                    <Plus />
                                </div>
                            )}
                            {canEdit && (
                                <div className={CnSubjectName('icon', { isClickMore })} onClick={handleEditButtonClick}>
                                    <GetMore />
                                </div>
                            )}
                        </>
                    )}
                    {isEdited && isEditable && (
                        <ThemeTooltip
                            isTheme={false}
                            handleCopyClick={handleCopyClick}
                            handleDeleteClick={handleDeleteClick}
                            handleEditClick={handleEditClick}
                        />
                    )}
                </div>
            </div>
            <div
                className={CnSubjectName('children_container', {
                    opened: isCreated || (isClickShow && grades?.length),
                })}
            >
                {isCreated && (
                    <DropdownWithActions
                        isNeedTextField={false}
                        name={'createTheme'}
                        placeholder={'Выберите класс'}
                        options={grades}
                        onChangeAdd={handleAddFormClick}
                        onClickCancel={handleClickAdd}
                    />
                )}
                {isClickShow && children}
            </div>
        </div>
    );
};

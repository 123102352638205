import { AttendanceStatus } from 'src-new/components/lms-elements/PerformanceTable/types';

import { AssignmentType } from './assignment';
import { AssignmentProgressStatus } from './assignmentProgress';
import { MarkData, MarkScore } from './mark';
import { Student } from './students';

export interface Task {
    id: number;
    title: string;
    type?: AssignmentType;
    isTemplate: boolean;
    isForMethodist?: boolean;
    isPosted?: boolean;
    assignedDate?: string;
    assignedDelay?: number;
    deadlineForComplete?: string;
    deadlineDelay?: number;
    timeToComplete?: number;
    assignedType: EAssignedTypes;
    deadlineType: EDeadlineTypes;
    teacher?: number;
    allStudents?: boolean;
}

export interface AssignmentProgress {
    id: number;
    studentId: number;
    taskId: number;
    status: AssignmentProgressStatus;
    marks?: MarkData[];
    taskTitle: string;
    taskType?: AssignmentType;
    assignedDate?: string;
    assignedDelay?: number;
    deadlineForComplete?: string;
    deadlineDelay?: number;
    timeToComplete?: number;
    assignedType: EAssignedTypes;
    deadlineType: EDeadlineTypes;
    allStudents?: boolean;
    teacher?: {
        id: number;
        lastName: string;
        firstName: string;
        patronymic: string;
    };
    errorsList?: string[];
}
export interface AssignedTasks {
    id: number;
    taskId: number;
    status: AssignmentProgressStatus;
    marks?: MarkData[];
    assignedDate?: string;
    assignedDelay?: number;
    deadlineForComplete?: string;
    deadlineDelay?: number;
    timeToComplete?: number;
    assignedType: EAssignedTypes;
    deadlineType: EDeadlineTypes;
    teacher?: {
        id: number;
        lastName: string;
        firstName: string;
        patronymic: string;
    };
    errorsList?: string[];
}

export interface AssignedHomeWork {
    studentId: number;
    tasks: (AssignedTasks[] | null)[];
    status?: AttendanceStatus;
    lateDuration?: number;
    attendanceId?: number;
}

type AssignmentProgressMark = {
    id: number;
    score: MarkScore;
    weight: number;
    author?: {
        id: number;
        firstName: string;
        lastName: string;
        photoUrl: string;
        patronymic: string;
    };
};

export enum HomeworkEnum {
    independent = 'independent',
    home_laboratory = 'home_laboratory',
    recommended_written_homework = 'recommended_written_homework',
    recommended_homework = 'recommended_homework',
    written_homework = 'written_homework',
    class_laboratory = 'class_laboratory',
    home_test = 'home_test',
    control = 'control',
}

type TeacherMark = AssignmentProgressMark & { comment: string };

export interface IHomeworkItem {
    id: string;
    status: AssignmentProgressStatus;
    title?: string;
    description: string;
    marks: AssignmentProgressMark[] | null;
    assignedDate?: string;
    type: AssignmentType;
    student: Student;
    subject?: {
        id: number;
        title: string;
    };
    lesson?: {
        id: number;
    };
    assignment: {
        id: number;
        description?: string;
    };
    completeDate?: string;
    selectedQuestions: number[];
    courseGroup: {
        id: number;
        title: string;
    };
    course?: {
        id: number;
        title: string;
    };
    teacherMark: TeacherMark[];
    deadlineForComplete?: string;
    deadlineForCheck?: string;
    hardDeadline?: string;
    teacher?: {
        id: number;
        lastName: string;
        firstName: string;
        patronymic: string;
    };
    timeToComplete: number;
}

export enum FilterTypes {
    DEADLINE_FOR_CHECK = 'DEADLINE_FOR_CHECK',
    DEADLINE_FOR_COMPLETE = 'DEADLINE_FOR_COMPLETE',
    ASSIGNED = 'ASSIGNED',
}

export interface ProgressesStatisticsData {
    id: number;
    lesson: {
        id: number;
        title: string;
        topic: {
            id: number;
            title: string;
            order: number;
        };
    };
    assignment: {
        id: number;
        assignmentType: {
            id: number;
            title: string;
        };
    };
    courseGroup: {
        id: number;
        title: string;
        students: Student[];
    };
    course: {
        id: number;
        title: string;
        subject: {
            id: number;
            title: string;
        };
    };
    assignedDate?: string;
    checkedDate?: string;
    checkedProgresses: number;
    onCheckProgresses: number;
    deadlineForCheck?: string;
    deadlineForComplete?: string;
    status: AssignmentProgressStatus;
    type: HomeworkEnum;
}

export enum HomeworkStatus {
    ON_CHECK = 'on_check',
    COMPLETED = 'completed',
}
export enum EAssignedTypes {
    DATE_TIME = 'exact_time',
    TIME = 'relative_ol',
    NONE = 'now',
}
export enum EDeadlineTypes {
    DATE_TIME = 'exact_time',
    TIME = 'relative_assigned',
}
export type TCreateDeferredAssignment = {
    assignment: number;
    courseGroup?: number;
    eventRoom?: number;
    allStudents?: boolean;
    students?: number[];
    assignedType: EAssignedTypes;
    assignedDate?: string;
    assignedDelay?: number;
    deadlineType: EDeadlineTypes;
    deadlineForComplete?: string;
    deadlineDelay?: number;
    timeToComplete?: number;
    teacher?: number;
};
export type TUpdateDeferredAssignment = {
    id: number;
    allStudents: boolean;
    students?: number[];
};
export type TFiltersDeferredAssignment = {
    courseGroup?: number;
    lesson?: number;
    eventRoom?: number;
    assignment?: number;
};
export type TPostDeferredAssignment = {
    filters: TFiltersDeferredAssignment;
    create?: TCreateDeferredAssignment;
    update?: TUpdateDeferredAssignment;
    delete?: number[];
};
export type TCreateDeferredAssignmentAction = TCreateDeferredAssignment & { deletedAssignmentIds?: { id: number }[] };
export type TUpdateDeferredAssignmentAction = TUpdateDeferredAssignment & {
    deletedDeferredAssignmentsIds?: number[];
    deletedAssignmentIds?: { id: number }[];
};

export type TPostDeferredAssignmentAction = {
    filters: TFiltersDeferredAssignment;
    created?: TCreateDeferredAssignmentAction[];
    updated?: TUpdateDeferredAssignmentAction[];
    deleted?: number[];
};
export type TPostDeferredAssignmentResponse = {
    id: number;
    assignment: number;
    courseGroup: number;
    eventRoom: number;
    allStudents: boolean;
    students: number[];
    assignedType: EAssignedTypes;
    assignedDate: string;
    assignedDelay: number;
    deadlineType: EDeadlineTypes;
    deadlineForComplete: string;
    deadlineDelay: number;
    timeToComplete: number;
    teacher: {
        id: number;
        lastName: string;
        firstName: string;
        patronymic: string;
    };
    author: {
        id: number;
        lastName: string;
        firstName: string;
        patronymic: string;
    };
    canBeAssigned: string;
    errorsList: string[];
    title: string;
};

import { getApiRequest } from 'api';

export interface Lesson {
    id: number;
    title: string;
}

export interface Topic {
    id: number;
    title: string;
    lesson: Lesson[];
}

export interface TopicParams {
    groupId: number;
    dateStart: string;
    dateEnd: string;
}
export const getTopics = (params: TopicParams): Promise<Topic[]> => {
    return getApiRequest(
        `/course-groups/tree/?course_group=${params.groupId}&date_start=${params.dateStart}&date_end=${params.dateEnd}`,
    );
};

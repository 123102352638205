import { api } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { AbsentStudent } from 'types/students';
import { Teacher } from 'types/teacher';

export interface CuratorDashboardAPI {
    id: number;
    lesson: {
        id: number;
        title: string;
    };
    subject: { id: number; title: string };
    usersInMeeting: { id: number; hasWebcam: boolean; hasMicrophone: boolean; isListeningOnly: boolean }[];
    courseGroup: {
        id: number;
        title: string;
        students: number[];
        teachers: Teacher[];
    };
}

export interface CuratorDashboard extends Omit<CuratorDashboardAPI, 'courseGroup'> {
    courseGroup: {
        id: number;
        title: string;
        students: AbsentStudent[];
        teachers: Teacher[];
    };
}

export const getMeetingStatistics = async (): Promise<CuratorDashboardAPI[]> => {
    return await api
        .get<Promise<CuratorDashboardAPI[]>, AxiosResponse<CuratorDashboardAPI[]>>(`/meeting/statistics/getRealTime/`)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppText } from '@frontend-modules/ui-kit';
import { useWindowResize } from '@lms-elements/hooks';
import { userAvatar } from 'assets';
import { PageLoader } from 'containers/PageLoader';
import { ProgressBarAdvanced, ProgressChart } from 'src-new/components/new-subjects';
import { getStudentsProgressListAction } from 'store/actions/newSubjects';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';

import { TProgressTab } from './ProgressTab.types';

import './style.scss';

export const ProgressTab: React.FC<TProgressTab> = (props) => {
    const dispatch = useDispatch();
    const { isTablet } = useWindowResize();

    const { lessonId, courseId } = props;
    const { studentsProgressList, getStudentsProgressListStatus } = useAppSelector((store) => store.newSubject);

    const isLoading = getStudentsProgressListStatus === FetchStatus.FETCHING;

    useEffect(() => {
        dispatch(
            getStudentsProgressListAction({
                lesson: lessonId,
                courseGroup: courseId,
            }),
        );
    }, [lessonId, courseId]);

    if (isLoading) {
        return <PageLoader showLoading={isLoading} />;
    }
    return (
        <div className={'progress-tab'}>
            {studentsProgressList.length ? (
                <div className={`progress-tab__table ${isTablet ? 'no-chart' : ''}`}>
                    <AppText text={'Ученик'} color={'#68788D'} textStyle={'Little'} />
                    <AppText text={'Материалы'} color={'#68788D'} textStyle={'Little'} />
                    {!isTablet && <AppText text={'Тренажёр'} color={'#68788D'} textStyle={'Little'} />}
                    {studentsProgressList?.map((student, index) => {
                        const { photoUrl, firstName, lastName, exercisesProgress, trainingsProgress } = student;
                        return (
                            <>
                                <div className={'progress-tab__table_name'}>
                                    <div className={'progress-tab__table_name-photo'}>
                                        <img src={photoUrl ?? userAvatar} alt={'photo'} />
                                    </div>
                                    <AppText text={`${firstName} ${lastName}`} textStyle={'DesktopFontBody'} />
                                </div>
                                <div className={'progress-tab__table_progress'}>
                                    <ProgressBarAdvanced progress={exercisesProgress} />
                                </div>
                                {!isTablet && (
                                    <div className={'progress-tab__table_chart'}>
                                        <ProgressChart {...trainingsProgress} />
                                    </div>
                                )}
                            </>
                        );
                    })}
                </div>
            ) : (
                <AppText text={'Нет статистики'} textStyle={'DesktopH3'} />
            )}
        </div>
    );
};

import React, { FC, useMemo } from 'react';
import { AppButton } from '@frontend-modules/ui-kit';
import { getTrainingProgressAction } from '@fsd-entities/materials';
import { useAppDispatch, useAppSelector } from 'store/store';

import { ICheckTrainingQuestionButtonProps } from './CheckTrainingQuestionButton.types';
import { checkInsertWordAnswerSend } from './CheckTrainingQuestionButton.utils';

export const CheckTrainingQuestionButton: FC<ICheckTrainingQuestionButtonProps> = (props) => {
    const { lessonId, courseGroupId, questionId, answer } = props;
    const dispatch = useAppDispatch();
    const { isCheckQuestionLoading, question } =
        useAppSelector((store) => store.newMaterials?.training?.exercise) || {};
    const isCanSendInsertWordsAnswer = useMemo(() => checkInsertWordAnswerSend(answer, question), [answer, question]);
    const isDisableCheckButton = !answer || !!question?.answerData?.correctness || !isCanSendInsertWordsAnswer;

    const onCheckExerciseClick = () => {
        if (lessonId && courseGroupId && answer && questionId) {
            const params = {
                lesson: lessonId,
                courseGroup: courseGroupId,
                check: { data: answer, question: questionId },
            };
            void dispatch(getTrainingProgressAction(params));
        }
    };

    return (
        <AppButton
            label={'Проверить'}
            isLoading={isCheckQuestionLoading}
            isDisabled={isDisableCheckButton}
            onClick={onCheckExerciseClick}
            isFullWidth={false}
        />
    );
};

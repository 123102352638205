import React from 'react';
import { cn } from '@bem-react/classname';
import { Switch } from 'antd';
import { CheckedIcon, UncheckedIcon } from 'assets';

import { IAppSwitch } from './AppSwitch.types';

import './AppSwitch.scss';

const CnAppSwitch = cn('app-switch');

export const AppSwitch = (props: IAppSwitch): JSX.Element => {
    const { withIcons = false, ...rest } = props;
    return (
        <Switch
            prefixCls={CnAppSwitch()}
            {...rest}
            checkedChildren={withIcons ? <UncheckedIcon /> : undefined}
            unCheckedChildren={withIcons ? <CheckedIcon /> : undefined}
        />
    );
};

/**
 * @description устанавливает задержку выполнения переданной функции на указанное кол-во миллисекунд
 * @param fn - (args: FunctionArgsType) => void
 * @param timeoutMs - number (1000 by default)
 * */
export function debounceCaller<FunctionArgsType>(
    fn: (args: FunctionArgsType) => void,
    timeoutMs = 1000,
): (args: FunctionArgsType) => void {
    let timeout: ReturnType<typeof setTimeout> | null;

    return (args) => {
        if (timeout) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => fn(args), timeoutMs);
    };
}

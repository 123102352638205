import React, { useEffect, useState } from 'react';
import { mappedPopups } from 'src-new/components/layouts/Modals/Modals.config';

import { TMountedItem } from './Modals.types';
import { useGetPopupsState } from './Modals.utils';

/**
 * @description - Слой рендера модалок
 * @constructor
 */
const Modals: React.FC = () => {
    const { mountedPopups, popups } = useGetPopupsState();

    const [currentComponentList, setCurrentComponentList] = useState<TMountedItem[]>([]);

    useEffect(() => {
        const mountedList: TMountedItem[] = mountedPopups?.map((item) => {
            return {
                component: mappedPopups[item.type],
                key: item.type,
                urlProps: item,
            } as TMountedItem;
        });
        setCurrentComponentList(mountedList);
    }, [mountedPopups]);

    return (
        <>
            {currentComponentList?.map((component, index) => {
                // @ts-ignore
                if (component.component && !component.urlProps?.['isHide']) {
                    return React.createElement(component.component, {
                        isOpen: !!popups.find((popup) => popup?.type === component?.key),
                        key: `${component.key}-${index}`,
                        // @ts-ignore
                        layer: component.urlProps?.['layer'] || currentComponentList.length - index,
                        // @ts-ignore
                        urlProps: component.urlProps,
                        // @ts-ignore
                        zIndex: 1000 - component.urlProps?.['layer'] || 0,
                    });
                }
                return null;
            })}
        </>
    );
};

export default Modals;

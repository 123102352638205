import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    getStaffProfileById,
    getStaffProfiles,
    updateStaffProfile,
    UpdateStaffProfileData,
} from 'api/services/staffProfile';
import { UserRoles } from 'types/user';

export const getStaffProfilesAction = createAsyncThunk(
    'staffProfile/getStaffProfiles',
    async ({ userRole, subjects }: { userRole?: UserRoles; subjects?: number[] }) => {
        return {
            userRole,
            profiles: await getStaffProfiles({ userRole, subjects }),
        };
    },
);

export const updateStaffProfileAction = createAsyncThunk(
    'staffProfile/updateStaffProfile',
    ({ data, staffProfileId }: { data: UpdateStaffProfileData; staffProfileId: number }) => {
        return updateStaffProfile({ data, staffProfileId });
    },
);

export const getStaffProfileByIdAction = createAsyncThunk(
    'staffProfile/getStaffProfileById',
    (staffProfileId: number) => {
        return getStaffProfileById(staffProfileId);
    },
);

export enum FileTypes {
    TEMPLATE = 'template',
    COMMON = 'common',
}

export interface IFile {
    id: number;
    title: string;
    fileExtension: string;
    fileUrl: string;
    isAvailableToStudents: boolean;
    shouldUseOnConference: boolean;
}

export interface IUploadedFile {
    id: number;
    file: string;
    lessonId: number | null;
    materialId: number | null;
    type: FileTypes;
}

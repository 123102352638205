import { postApiRequest } from 'api';

export interface PostRevisionQuestionParams {
    comment: string;
    question: number;
}

export interface ResponseRevisionQuestion {
    id: number;
    comment: string;
    question: number;
    dateCreate: string;
}

export const createRevisionQuestion = (values: PostRevisionQuestionParams): Promise<ResponseRevisionQuestion> => {
    return postApiRequest('/codifier/question-revision/create/', values);
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    changeAcademicYear,
    changePassword,
    ChangePasswordRequestData,
    getCurrentTime,
    getUserInfo,
} from 'api/services/user';

export const getUserInfoAction = createAsyncThunk('user/getUserInfo', async () => {
    return await getUserInfo();
});

export const changePasswordAction = createAsyncThunk(
    'user/changePassword',
    (changePasswordRequestData: ChangePasswordRequestData) => changePassword(changePasswordRequestData),
);

export const changeAcademicYearAction = createAsyncThunk('user/changeAcademicYear', (yearId: number) =>
    changeAcademicYear(yearId),
);

export const getCurrentTimeAction = createAsyncThunk('user/getCurrentTime', () => getCurrentTime());

import { LessonScheduleResponse } from 'api/services/lessonSchedule';
import { CourseLesson, ResponseLesson } from 'types/lesson';
import { LessonTypes } from 'types/schedule';

import { getLabelByScheduleType } from 'utils/schedule';

export const normalizeCourseLesson = (
    lessons: ResponseLesson[],
    lessonSchedules: LessonScheduleResponse[],
): CourseLesson[] => {
    return lessons.map((lesson) => {
        const lessonSchedule = lessonSchedules.filter((schedule) => schedule.lesson === lesson.id);

        return {
            ...lesson,
            lessonItems:
                lesson?.isTest && !lessonSchedule.some(({ type }) => type === LessonTypes.TEST)
                    ? [
                          {
                              type: LessonTypes.TEST,
                              label: 'Контрольная',
                              datetimeStart: '',
                              datetimeEnd: '',
                              checked: true,
                              canEdit: true,
                          },
                      ]
                    : lessonSchedule.map(({ type, id, datetimeStart = '', datetimeEnd = '' }) => ({
                          id,
                          type,
                          datetimeStart,
                          datetimeEnd,
                          label: getLabelByScheduleType(type),
                          checked: true,
                          canEditEndData: type === LessonTypes.SELFPREPARATION,
                      })),
        };
    });
};

import { IDropdownValues } from '@lms-elements/atomic';
import { IQuestion } from '@lms-elements/test-task/build/@types/packages/TestTask/src/Answer';
import { AnswerAttemptPostResponse } from 'api/services/answerAttempt';
import { ResponseStudentQuestion } from 'api/services/questionBank';
import i18next from 'i18next';
import { isTimeExpired, setMessage } from 'src-new/utils';
import { QuestionFiles } from 'store/reducers/answerAttempts';
import {
    AnswerAttemptData,
    EssayAnswerData,
    EssayAnswerDataResponse,
    InsertWordsAnswerData,
    InsertWordsAnswerDataResponse,
    TestAnswerData,
    TestAnswerDataResponse,
    TextAnswerData,
    TrueFalseAnswerData,
} from 'types/answerAttempt';
import { IHomeworkItem } from 'types/homework';

import { AnswersTypeEnum } from 'components/QuestionPopUp/QuestionPopUp.types';
import { getAutoCheckAnswerData } from 'utils/answerAttempt';
import { normalizeQuestionData } from 'utils/questions';

import { getAttemptDropdownOptions } from './AttemptDropdown';

export interface IFormValues {
    questions: (IQuestion & { id: number })[];
    attempt?: IDropdownValues;
}

export const getStudentAnswers = (
    type: AnswersTypeEnum,
    data:
        | EssayAnswerDataResponse
        | InsertWordsAnswerDataResponse
        | TestAnswerDataResponse
        | TextAnswerData
        | TrueFalseAnswerData,
): TextAnswerData | TrueFalseAnswerData | InsertWordsAnswerData | TestAnswerData => {
    switch (type) {
        case AnswersTypeEnum.true_false:
            return { answer: (data as TrueFalseAnswerData)?.answer };
        case AnswersTypeEnum.test:
            return { selectedOptions: (data as TestAnswerDataResponse)?.selectedOptions?.map((option) => option.uuid) };
        case AnswersTypeEnum.text:
            return { selectedOption: (data as TextAnswerData)?.selectedOption };
        case AnswersTypeEnum.insert_words:
            return {
                selectedOptions: (data as InsertWordsAnswerDataResponse)?.selectedOptions?.map(
                    ({ selectedWord, gapIndex }) => ({
                        selectedWord,
                        gapIndex,
                    }),
                ),
            };
        default:
            return {} as TextAnswerData;
    }
};

export const getInitValues = (
    studentQuestions: ResponseStudentQuestion[] = [],
    questionsOrder: number[],
    attempt: Pick<AnswerAttemptPostResponse, 'isDraft' | 'attempt' | 'mark' | 'answers' | 'id'> | null,
): IFormValues => {
    const normalizedQuestions = studentQuestions.map((question) => {
        const answer = attempt?.answers?.find((answer) => answer.questionId === question.id);
        const answerData = answer
            ? {
                  data: { ...answer, file: answer.files },
                  answers: getStudentAnswers(question.answersType, answer.data),
              }
            : undefined;

        return normalizeQuestionData(question, answerData, attempt?.isDraft);
    });

    const sortedQuestions = questionsOrder
        .map((id) => {
            const foundQuestion = normalizedQuestions.find((question) => question.id === id);
            if (foundQuestion) {
                return foundQuestion;
            }
        })
        .filter(Boolean) as typeof normalizedQuestions;

    return {
        questions: sortedQuestions,
        attempt: attempt ? getAttemptDropdownOptions(attempt) : undefined,
    };
};

export const getAnswerAttempts = (
    values: IFormValues,
    selectedTask: IHomeworkItem,
    selectedAttempt: AnswerAttemptPostResponse | null,
    questionFiles: QuestionFiles,
): AnswerAttemptData => {
    const answers = values.questions.reduce(
        (result, question) => {
            if (question.type !== 'detailed') {
                result.push({
                    question: question.id,
                    data: getAutoCheckAnswerData(question),
                });
            }

            if (question.type === 'detailed') {
                result.push({
                    answerId: selectedAttempt?.answers.find((answer) => answer.questionId === question.id)?.id,
                    question: question.id,
                    data: {
                        text: question.answer,
                        file:
                            [...(question.file ?? [])]
                                ?.filter((file) => !(file instanceof File))
                                .map((file) => (file instanceof File ? null : String(file.id))) ?? [],
                    } as EssayAnswerData,
                    files: questionFiles[question.id]?.files.map(({ id }) => id),
                    isEssay: true,
                });
            }

            return result;
        },
        [] as {
            answerId?: number;
            question: number;
            data: EssayAnswerData | InsertWordsAnswerData | TestAnswerData | TextAnswerData | TrueFalseAnswerData;
            file?: File[];
            files?: number[];
            isEssay?: boolean;
        }[],
    );

    return {
        answers,
        assignmentProgress: Number(selectedTask.id),
    };
};

interface AnswerAttemptsData {
    attempt: number;
    attemptsQuantity: number;
}

export const getAttemptNumber = (task: IHomeworkItem, answerAttempt: AnswerAttemptsData | null): string => {
    return answerAttempt
        ? `${task.type.title}, ${answerAttempt.attempt} попытка из ${answerAttempt.attemptsQuantity}`
        : task.type.title;
};

export const getMarkComentCallback = (questions: ResponseStudentQuestion[]) => (
    attemptData: AnswerAttemptPostResponse,
): string => {
    const questionQuantity = questions.filter((question) => question.answersType !== AnswersTypeEnum.essay).length;

    if (questionQuantity === 0) {
        return '- оценка за попытку';
    }

    const correctAnswers = attemptData.answers.reduce((result, answer) => {
        if (answer.isCorrect) {
            result = result + 1;
        }

        return result;
    }, 0);

    return `${i18next.t('correctAnswer', { count: correctAnswers })} из ${i18next.t('question', {
        count: questionQuantity,
    })}`;
};

export const getBestAttempt = (attemptsData: AnswerAttemptPostResponse[]): AnswerAttemptPostResponse | null => {
    return attemptsData[0] || null;
};

type QuestionError = {
    answer?: string;
    file?: string;
};

const validateQuestion = (question: IQuestion & { id: number }): QuestionError => {
    switch (question.type) {
        case 'single':
        case 'multiple':
            return {
                answer: Array.isArray(question.answer) && question.answer.length > 0 ? undefined : 'error',
            };
        case 'binary':
        case 'open':
            return {
                answer: typeof question.answer === 'string' && question.answer ? undefined : 'error',
            };
        case 'detailed':
            return {
                answer:
                    !question.isTextAvailable ||
                    (typeof question.answer === 'string' && question.answer) ||
                    (Array.isArray(question.file) && question.file.length > 0)
                        ? undefined
                        : 'error',
                file:
                    !question.isFileAvailable ||
                    (typeof question.answer === 'string' && question.answer) ||
                    (Array.isArray(question.file) && question.file.length > 0)
                        ? undefined
                        : 'error',
            };
        default:
            return {};
    }
};

export const questionsValidation = (
    questions: (IQuestion & { id: number })[],
): Record<string, QuestionError> | undefined => {
    return questions.length
        ? questions.map(validateQuestion).reduce((result, error, index) => {
              result[`${index}`] = error;
              return result;
          }, {} as Record<string, QuestionError>)
        : undefined;
};

export const checkIsHardDeadlineLeft = (isTaskFinished: boolean, task?: IHomeworkItem): boolean =>
    !isTaskFinished && !task?.timeToComplete && !!task?.hardDeadline && isTimeExpired(task.hardDeadline);

export const showHardDeadlineLeftToast = (): void =>
    setMessage({ status: 'error', text: 'Время на выполнение задания истекло!', duration: 5, maxCount: 1 });

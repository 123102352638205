import { EventRoom } from 'api/services/proctoring';
import moment from 'moment';
import { RoomsMap } from 'store/reducers/proctoring';

import { normalizeEventRooms } from './normalizeEventRooms';

export const normalizeEventRoomsRange = (rooms: EventRoom[]): RoomsMap => {
    return normalizeEventRooms(rooms)
        .sort(({ date: aDate }, { date: bDate }) => moment(aDate).diff(bDate))
        .reduce((result, room) => {
            if (result[room.date]) {
                result[room.date].rooms.push(room);
                return result;
            }

            result[room.date] = {
                rooms: [room],
                day: room.dayTitle,
            };

            return result;
        }, {} as RoomsMap);
};

import React from 'react';
import { cn } from '@bem-react/classname';
import { PreloaderArrows, PreloaderLogo } from '@lms-elements/icons';

import './Loader.scss';

const CnLoader = cn('loader');

export const Loader: React.FC = () => {
    return (
        <div className={CnLoader()}>
            <div className={CnLoader('animation')}>
                <PreloaderArrows />
            </div>
            <div className={CnLoader('logo')}>
                <PreloaderLogo />
            </div>
        </div>
    );
};

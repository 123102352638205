import { IActionsDetailsItem } from 'store/reducers/groupActions';
import { IAction, IActionDetailsResponse } from 'types/groupActions';

const getUpdatedActionList = (
    list: IAction[] | undefined,
    targetId: number,
    type: 'updateItem' | 'removeItem',
    newItem: IAction | undefined,
): IAction[] | undefined => {
    if (type === 'removeItem') {
        return list?.filter((item) => item.id !== targetId);
    }
    const currentListItemIndex = list?.findIndex((item) => item.id === targetId) ?? -1;
    if (currentListItemIndex > -1 && newItem) {
        return [
            ...(list ?? [])?.slice(0, currentListItemIndex),
            newItem,
            ...(list ?? [])?.slice(currentListItemIndex + 1),
        ];
    }
    return list;
};

/**
 * @description обновляем инфу о действии
 *              если isSavedData = true то пришли данные которые сохранены на сервере
 *              если isSavedData = false то пришли, с измененными фильтрами или сортировкой
 * @param oldItems
 * @param payload
 * @param isSavedData
 */
const getUpdatedDetailsItem = (
    oldItems?: { [key: string]: IActionsDetailsItem },
    payload?: IActionDetailsResponse,
    isSavedData?: boolean,
): { [key: string]: IActionsDetailsItem } | undefined => {
    if (payload) {
        const newData = { ...payload.data, sorting: payload.data?.sorting ?? [] };

        return {
            ...oldItems,
            [payload.id]: {
                ...payload,
                data: newData,
                savedData: isSavedData ? newData : oldItems?.[payload.id]?.savedData,
            },
        };
    }
    return oldItems;
};

export { getUpdatedActionList, getUpdatedDetailsItem };

import { api, deleteApiRequest, getApiRequest, postApiRequest } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import {
    EssayAnswerData,
    EssayAnswerDataResponse,
    InsertWordsAnswerData,
    InsertWordsAnswerDataResponse,
    TestAnswerData,
    TestAnswerDataResponse,
    TextAnswerData,
    TrueFalseAnswerData,
} from 'types/answerAttempt';
import { MarkScore } from 'types/mark';

export interface AnswerAttemptPostParams {
    assignmentProgress: number;
    answers?: {
        files?: number[];
        question: number;
        data: EssayAnswerData | InsertWordsAnswerData | TestAnswerData | TextAnswerData | TrueFalseAnswerData;
    }[];
}

export interface AnswerAttemptPatchParams {
    answers?: {
        file?: File[];
        question: number;
        answerId?: number;
        data: EssayAnswerData | InsertWordsAnswerData | TestAnswerData | TextAnswerData | TrueFalseAnswerData;
    }[];
}

interface Mark {
    id: number;
    score?: MarkScore;
}

export interface AnswerAttemp {
    id: number;
    questionId: number;
    isCorrect: boolean;
    incomplete?: boolean;
    files?:
        | {
              id: number;
              url: string;
              title: string;
          }[]
        | null;
    data:
        | EssayAnswerDataResponse
        | InsertWordsAnswerDataResponse
        | TestAnswerDataResponse
        | TextAnswerData
        | TrueFalseAnswerData;
}

export interface AnswerAttemptPostResponse {
    id: number;
    attempt: number;
    attemptsQuantity: number;
    mark: Mark | null;
    answers: {
        id: number;
        questionId: number;
        isCorrect: boolean;
        incomplete?: boolean;
        files?:
            | {
                  id: number;
                  url: string;
                  title: string;
              }[]
            | null;
        data:
            | EssayAnswerDataResponse
            | InsertWordsAnswerDataResponse
            | TestAnswerDataResponse
            | TextAnswerData
            | TrueFalseAnswerData;
    }[];
    isEdited: boolean;
    selectedQuestions: number[];
    // Не приходит при патче и посте и для ученика
    student?: number;
    isDraft?: boolean;
    dateUpdate?: string;
}

export const postAnswerAttempt = (params: AnswerAttemptPostParams): Promise<AnswerAttemptPostResponse> => {
    return postApiRequest(`/api/v2/answer-attempt/`, params);
};

export const patchAnswerAttempt = (params: {
    answerId: number;
    newAnswerAttemptParams: { files: number[] };
}): Promise<AnswerAttemptPostResponse> => {
    return api
        .patch<AnswerAttemptPostResponse>(
            `/codifier/answer/partial-update/${params.answerId}/`,
            params.newAnswerAttemptParams,
        )
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const getAnswerAttemptInfo = (assignment: number): Promise<AnswerAttemptPostResponse[]> => {
    return api
        .get<unknown, AxiosResponse<AnswerAttemptPostResponse[]>>(`/codifier/answer-attempt/?assignment=${assignment}`)
        .then((res) => res?.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const getAnswerAttemptsByProgress = (assignmentProgress: number): Promise<AnswerAttemptPostResponse[]> => {
    return getApiRequest(`/codifier/answer-attempt/?assignment_progress=${assignmentProgress}`);
};

export const deleteAnswerAttempt = (answerAttemptId: number): Promise<number> => {
    return deleteApiRequest(`/codifier/answer-attempt/delete/${answerAttemptId}/`).then(() => answerAttemptId);
};

export const deleteAnswerAttempts = (answerAttempts: number[]): Promise<number[]> => {
    return deleteApiRequest('/codifier/answer-attempt/bulk-delete/', {
        data: { ids: answerAttempts },
    }).then(() => answerAttempts);
};

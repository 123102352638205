import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { MissedLesson } from 'api/services/lesson/patchMissedLesson';
import { LessonScheduleResponse } from 'api/services/lessonSchedule';
import {
    getLessonScheduleAction,
    getMeetingJoinRedirectAction,
    getMeetingWithCheckAction,
    getMissedLessonAction,
    patchMissedLessonAction,
} from 'store/actions/lessonSchedule';
import { fetchSchedule, getScheduleByDateRangeAction, getUserScheduleAction } from 'store/actions/schedule';
import {
    normalizeScheduleDayEvents,
    normalizeScheduleEvents,
} from 'store/normalizers/schedule/normalizeScheduleEvents';
import { FetchStatus } from 'types/api';
import { ScheduleData, ScheduleEvent } from 'types/schedule';

export interface ScheduleState {
    schedule: Array<ScheduleEvent>;
    sheduleDateRange: ScheduleData;
    lessonSchedule: LessonScheduleResponse[];
    userSchedule: ScheduleData;
    userScheduleStatus: FetchStatus;
    getMissedLessonStatus: FetchStatus;
    getMeetingJoinRedirectStatus: FetchStatus;
    getMeetingUserStatus: FetchStatus;
    patchMissedLessonStatus: FetchStatus;
    date: string | null;
    missedLesson: MissedLesson;
    missedLessons: MissedLesson[];
    isAppUsed: boolean;
    isOlBlocked: boolean;
    fetchStatus: FetchStatus;
    lesson: number;
    datetimeStartUsedApp: string | null;
    error: unknown;
}

const initialState: ScheduleState = {
    fetchStatus: FetchStatus.INITIAL,
    schedule: [],
    lessonSchedule: [],
    missedLessons: [],
    sheduleDateRange: {},
    userSchedule: {},
    missedLesson: {} as MissedLesson,
    isAppUsed: false,
    getMissedLessonStatus: FetchStatus.INITIAL,
    getMeetingUserStatus: FetchStatus.INITIAL,
    patchMissedLessonStatus: FetchStatus.INITIAL,
    datetimeStartUsedApp: null,
    lesson: 0,
    isOlBlocked: false,
    userScheduleStatus: FetchStatus.INITIAL,
    getMeetingJoinRedirectStatus: FetchStatus.INITIAL,
    date: null,
    error: null,
};

const scheduleSlice = createSlice<ScheduleState, SliceCaseReducers<ScheduleState>>({
    name: 'schedule',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSchedule.pending, (state) => {
                state.fetchStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(fetchSchedule.fulfilled, (state, { payload }) => {
                const { scheduleData, date } = payload;

                state.fetchStatus = FetchStatus.FETCHED;
                state.schedule =
                    normalizeScheduleDayEvents(
                        {
                            lessonSchedule: scheduleData.lessonSchedule,
                            assemblySchedule: scheduleData.assemblySchedule,
                            eventRoomSchedule: scheduleData.eventRoomSchedule,
                        },
                        date,
                    ) || [];
                state.sheduleDateRange = {
                    ...state.sheduleDateRange,
                    [date]: {
                        date,
                        data:
                            normalizeScheduleDayEvents(
                                {
                                    lessonSchedule: scheduleData.lessonSchedule,
                                    assemblySchedule: scheduleData.assemblySchedule,
                                    eventRoomSchedule: scheduleData.eventRoomSchedule,
                                },
                                date,
                            ) || [],
                    },
                };
            })
            .addCase(fetchSchedule.rejected, (state, { error }) => {
                state.fetchStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getScheduleByDateRangeAction.pending, (state) => {
                state.fetchStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getScheduleByDateRangeAction.fulfilled, (state, { payload }) => {
                state.fetchStatus = FetchStatus.FETCHED;
                state.sheduleDateRange = normalizeScheduleEvents({
                    lessonSchedule: payload.lessonSchedule,
                    assemblySchedule: payload.assemblySchedule,
                    eventRoomSchedule: payload.eventRoomSchedule,
                });
                state.error = null;
            })
            .addCase(getScheduleByDateRangeAction.rejected, (state, { error }) => {
                state.fetchStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getLessonScheduleAction.pending, (state) => {
                state.fetchStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getLessonScheduleAction.fulfilled, (state, { payload }) => {
                state.fetchStatus = FetchStatus.FETCHED;
                state.lessonSchedule = payload || [];
                state.error = null;
            })
            .addCase(getLessonScheduleAction.rejected, (state, { error }) => {
                state.fetchStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getUserScheduleAction.pending, (state) => {
                state.userScheduleStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getUserScheduleAction.fulfilled, (state, { payload }) => {
                state.userScheduleStatus = FetchStatus.FETCHED;
                state.userSchedule = normalizeScheduleEvents(payload);
                state.error = null;
            })
            .addCase(getUserScheduleAction.rejected, (state, { error }) => {
                state.userScheduleStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(patchMissedLessonAction.pending, (state) => {
                state.patchMissedLessonStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(patchMissedLessonAction.fulfilled, (state, { payload }) => {
                state.patchMissedLessonStatus = FetchStatus.FETCHED;
                state.missedLesson = payload;
                state.error = null;
            })
            .addCase(patchMissedLessonAction.rejected, (state, { error }) => {
                state.patchMissedLessonStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getMissedLessonAction.pending, (state) => {
                state.getMissedLessonStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getMissedLessonAction.fulfilled, (state, { payload }) => {
                state.getMissedLessonStatus = FetchStatus.FETCHED;
                state.missedLessons = payload.flatMap((el) => el);
                state.error = null;
            })
            .addCase(getMissedLessonAction.rejected, (state, { error }) => {
                state.patchMissedLessonStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getMeetingJoinRedirectAction.pending, (state) => {
                state.getMeetingJoinRedirectStatus = FetchStatus.FETCHING;
            })
            .addCase(getMeetingJoinRedirectAction.fulfilled, (state) => {
                state.getMeetingJoinRedirectStatus = FetchStatus.FETCHED;
            });
        builder
            .addCase(getMeetingWithCheckAction.pending, (state) => {
                state.getMeetingUserStatus = FetchStatus.FETCHING;
            })
            .addCase(getMeetingWithCheckAction.fulfilled, (state) => {
                state.getMeetingUserStatus = FetchStatus.FETCHED;
            });
    },
});

const resetScheduleState = scheduleSlice.actions.reset as ActionCreatorWithoutPayload;
export { resetScheduleState };
export const scheduleReducer = scheduleSlice.reducer;

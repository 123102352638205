import { getApiRequest } from 'api';
import { QuestionStatus } from 'api/services/questionBank';
import { DifficultyEnum } from 'api/services/questionCreate';
import {
    EssayQuestionData,
    InsertWordQuestionData,
    TestQuestionData,
    TextQuestionData,
    TrueFalseQuestionData,
} from 'types/question';

import { AnswersTypeEnum } from 'components/QuestionPopUp/QuestionPopUp.types';

export interface QuestionOnRevision {
    id: number;
    text?: string;
    html?: string;
    content?: string;
    answersType: AnswersTypeEnum;
    difficulty: DifficultyEnum;
    maximumScore: number;
    timestamp: string;
    topics: { id: number; title: string }[];
    author: {
        id?: number;
        firstName: string;
        lastName: string;
        patronymic: string | null;
        online?: boolean;
        lastSeen?: string;
    };
    skills: {
        id: number;
        title: string;
    }[];
    status: QuestionStatus;
    data: TestQuestionData | InsertWordQuestionData | TrueFalseQuestionData | EssayQuestionData | TextQuestionData;
    revisions?: {
        id: number;
        comment: string;
        dateCreate: string;
    }[];
}

export interface QuestionsRevisionData {
    questions: QuestionOnRevision[];
    countQuestionUnpublished: number;
}
export const getQuestionsOnRevision = (): Promise<QuestionsRevisionData> => {
    return getApiRequest('/codifier/question/revision/');
};

import React, { useCallback, useMemo } from 'react';
import Latex from 'react-latex-next';
import { cn } from '@bem-react/classname';
import { OptionPopUpWrapper } from 'src-new/components/lms-elements/CustomEditor/components/OptionPopUpWrapper';

import { ILatexPopUpProps } from './LatexPopUp.types';
import { isLatex } from './utils';

import './LatexPopUp.scss';

const LatexPopUpCn = cn('latex-popup');

export const LatexPopUp = React.forwardRef<HTMLInputElement, ILatexPopUpProps>(
    ({ latex, setLatex, setIsShown, onSaveClick, onCancelClick, onOutsideClick, entity }, ref) => {
        const handleCancelClick = useCallback(() => {
            onCancelClick();
            setLatex('');
        }, [setLatex, onCancelClick]);

        const isSaveDisabled = useMemo(() => !isLatex(latex) && latex !== '', [latex]);

        const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => setLatex(e.target.value), [
            setLatex,
        ]);

        const handleSaveBtnClick = useCallback(() => {
            onSaveClick(entity);
        }, [entity, onSaveClick]);

        return (
            <OptionPopUpWrapper
                onSaveClick={handleSaveBtnClick}
                setIsShown={setIsShown}
                onOutsideClick={onOutsideClick}
                onCancelClick={handleCancelClick}
                isSaveDisabled={isSaveDisabled}
            >
                <div className={LatexPopUpCn()}>
                    <h5 className={LatexPopUpCn('head')}>Вставка формулы</h5>
                    <input
                        type="text"
                        placeholder="Введите LaTeX"
                        className={LatexPopUpCn('input')}
                        value={latex}
                        onChange={onInputChange}
                        ref={ref}
                    />
                    <div className={LatexPopUpCn('preview')}>
                        <Latex>{latex}</Latex>
                    </div>
                </div>
            </OptionPopUpWrapper>
        );
    },
);

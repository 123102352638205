import React from 'react';
import { AppText } from '@frontend-modules/ui-kit';

import { TChartsLegendItem } from './ChartsLegendItem.types';

import './ChartsLegendItem.style.scss';
export const ChartsLegendItem: React.FC<TChartsLegendItem> = (props) => {
    const { color, name, children } = props;

    return (
        <div className={'charts-legend-item'}>
            <div className={'charts-legend-item__colored-block'} style={{ backgroundColor: color }} />
            {children ?? <AppText text={name ?? ''} textStyle={'DesktopFootNotes'} />}
        </div>
    );
};

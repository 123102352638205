import { useCallback, useEffect, useState } from 'react';

export const useThrottle = <ArgsType extends unknown[], ReturnType>(
    callback: (...args: ArgsType) => ReturnType,
    delay: number,
): ((...args: ArgsType) => ReturnType | void) => {
    const [isThrottled, setIsThrottled] = useState(false);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const throttledCallback = useCallback(
        (...args: ArgsType): ReturnType | void => {
            if (isThrottled) {
                return;
            }
            callback(...args);
            setIsThrottled(true);
            setTimeoutId(setTimeout(() => setIsThrottled(false), delay));
        },
        [callback, delay, isThrottled],
    );

    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);

    return throttledCallback;
};

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { cn } from '@bem-react/classname';
import { getNoun } from '@frontend-modules/frontend-utils';
import { AppButton, AppText } from '@frontend-modules/ui-kit';
import { PageLoader } from 'containers/PageLoader';
import { StudentTask } from 'containers/StudentTask';
import { useRole } from 'hooks';
import { useLoader } from 'hooks/useLoader';
import moment from 'moment';
import { getQueryAcademicPerfomanceParams } from 'pages/LessonCard/utils';
import { useLessonsPopup } from 'src-new/hooks';
import { isTimeExpired } from 'src-new/utils';
import { createAttemptAction, updateAttemptDraft } from 'store/actions/answerAttempt';
import { fetchStudentHomeworksAction } from 'store/actions/studentTasks';
import { resetAnswerAttemptsStatus } from 'store/reducers/answerAttempts';
import { resetStudentTask, resetStudentTaskStatus } from 'store/reducers/studentTasks';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';
import { AssignmentProgressStatus } from 'types/assignmentProgress';

import { StatusCard } from 'components/StatusCard';
import { Timer } from 'components/Timer';
import { createTabs } from 'utils/studentsTasks';

import { IStudentTaskTabProps } from './StudentTaskTab.types';

import './StudentTaskTab.scss';

const CnStudentTaskTab = cn('studentTaskTab');

export const StudentTaskTab: React.FC<IStudentTaskTabProps> = ({
    subjectId,
    lessonId,
    courseGroupId,
    activeTaskId,
    courseId,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const LessonPopup = useLessonsPopup();

    const fetchStatusQuestions = useAppSelector((state) => state.questions.fetchStatusQuestions);
    const info = useAppSelector((state) => state.user.info);

    const { isStudent } = useRole();

    const {
        lastAttempt,
        attemptsQuantity,
        createAttemptStatus,
        fetchStatus: fetchAnswerAttemptsStatus,
    } = useAppSelector((state) => state.answerAttempts);
    const attempts = useAppSelector((state) => state.answerAttempts.data);
    const { studentTasks } = useAppSelector((state) => state.studentTasks);

    useEffect(() => {
        dispatch(fetchStudentHomeworksAction({ lessonId: lessonId }));
    }, [dispatch, lessonId]);

    useLoader([fetchStatusQuestions, fetchAnswerAttemptsStatus]);

    const [selectedTab, setSelectedTab] = useState<number>(Number(activeTaskId ?? studentTasks[0]?.id));
    const [calculatedTimeToComplete, setCalculatedTimeToComplete] = useState<number>(0);

    const tabs = useMemo(() => createTabs(studentTasks, attempts), [studentTasks, attempts, selectedTab]);
    const selectedTask = useMemo(() => studentTasks.find((task) => Number(task.id) === selectedTab), [
        studentTasks,
        selectedTab,
    ]);
    const [showConfirmBlock, setShowConfirmBlock] = useState<boolean>(false);
    const [isTimeLeft, setIsTimeLeft] = useState<boolean>(false);

    const hasAttempts = useMemo(() => {
        if (fetchAnswerAttemptsStatus === FetchStatus.FETCHED && !!lastAttempt && attemptsQuantity !== undefined) {
            return lastAttempt < attemptsQuantity;
        }

        return false;
    }, [attemptsQuantity, fetchAnswerAttemptsStatus, lastAttempt]);

    const isEmpty = !tabs.length;

    useEffect(() => {
        const isAssigned =
            selectedTask?.status !== AssignmentProgressStatus.COMPLETED &&
            selectedTask?.status !== AssignmentProgressStatus.ON_CHECK;

        setShowConfirmBlock(
            !!selectedTask &&
                !!selectedTask?.timeToComplete &&
                !!isStudent &&
                isAssigned &&
                !selectedTask?.selectedQuestions.length,
        );
    }, [selectedTask?.id, selectedTask?.timeToComplete, isStudent]);

    const deadline = useMemo(() => {
        let deadline;
        // Хард дедлайн для сдачи задания (вычитаем 3 секунды, чтобы не выйти за рамки дедлайна)
        const deadlineForComplete = selectedTask?.hardDeadline
            ? moment(selectedTask?.hardDeadline).subtract(3, 'seconds')
            : undefined;

        if (selectedTask?.timeToComplete) {
            deadline = deadlineForComplete?.format();
        } else {
            deadline = selectedTask?.deadlineForComplete;
        }

        return deadline;
    }, [selectedTask, calculatedTimeToComplete]);

    const hasStartTimeLimitedTask =
        attempts?.length &&
        (!attempts[attempts.length - 1].answers.length || !attempts[attempts.length - 1].mark) &&
        deadline &&
        !isTimeExpired(deadline);
    const showTimer = selectedTask?.timeToComplete
        ? hasStartTimeLimitedTask
        : !hasAttempts &&
          selectedTask?.status !== AssignmentProgressStatus.COMPLETED &&
          selectedTask?.status !== AssignmentProgressStatus.ON_CHECK;
    const handleTabSelect = useCallback(
        (tabId: number) => {
            setSelectedTab(tabId);
            setIsTimeLeft(false);
            if (courseGroupId) {
                LessonPopup.replaceLastLessonPopup({
                    subjectId: subjectId ?? 0,
                    courseGroupId: courseGroupId,
                    courseId,
                    lessonId: lessonId,
                    tab: 'tasks',
                    taskId: tabId,
                });
            }
        },
        [lessonId, courseGroupId],
    );

    const handleTimeIsLeft = useCallback(() => {
        dispatch(updateAttemptDraft());
        setIsTimeLeft(true);
    }, [dispatch, selectedTask]);
    const handleMarksClick = useCallback(() => {
        history.push(
            `/performance${getQueryAcademicPerfomanceParams({
                courseId: studentTasks[0].course?.id,
                subjectId: studentTasks[0].subject?.id,
                groupId: studentTasks[0].courseGroup?.id,
            })}`,
        );
        LessonPopup.closeLastLessonPopup();
    }, [history, studentTasks]);

    useEffect(() => {
        if (createAttemptStatus === FetchStatus.FETCHED) {
            dispatch(resetStudentTaskStatus());
            dispatch(resetStudentTask());
            dispatch(resetAnswerAttemptsStatus());
        }
    }, [createAttemptStatus]);
    const approveStartTask = () => {
        if (selectedTask) {
            dispatch(createAttemptAction(+selectedTask?.id));
        }
    };

    useEffect(() => {
        if (createAttemptStatus === FetchStatus.FETCHED) {
            setShowConfirmBlock(false);
        }
    }, [createAttemptStatus]);

    useEffect(() => {
        if (selectedTask && !!selectedTask?.timeToComplete && selectedTask?.hardDeadline) {
            const deadlineForComplete = moment(selectedTask.hardDeadline).subtract(3, 'seconds');
            const secondsBeforeDeadline = deadlineForComplete?.diff(moment.now(), 'seconds');
            const secondsTimeToComplete = selectedTask.timeToComplete * 60;

            const minRes: number =
                secondsBeforeDeadline < secondsTimeToComplete ? secondsBeforeDeadline : secondsTimeToComplete;
            setCalculatedTimeToComplete(minRes);
        }
    }, [selectedTask?.hardDeadline, createAttemptStatus]);

    const getMinutesNoun = (time: number) => getNoun(time, 'минута', 'минуты', 'минут');

    const isTimerStarts = !!selectedTask && !showConfirmBlock && showTimer && !!deadline;
    const confirmBlockText = useMemo(() => {
        const minutesCalculatedTimeToComplete = Math.round(calculatedTimeToComplete / 60);
        const timeToCloseText =
            calculatedTimeToComplete >= 60
                ? `примерно ${minutesCalculatedTimeToComplete} ${getMinutesNoun(minutesCalculatedTimeToComplete)}`
                : `менее 1 минуты`;

        return !selectedTask
            ? ''
            : selectedTask?.timeToComplete <= minutesCalculatedTimeToComplete
            ? `На выполнение задания дается ${selectedTask?.timeToComplete} ${getMinutesNoun(
                  selectedTask?.timeToComplete,
              )}.`
            : `На выполнение задания дается ${selectedTask?.timeToComplete} ${getMinutesNoun(
                  selectedTask?.timeToComplete,
              )}, но до закрытия задания осталось ${timeToCloseText}`;
    }, [selectedTask, calculatedTimeToComplete]);

    if (isEmpty) {
        return (
            <div className={CnStudentTaskTab('empty')}>
                <h2 className={CnStudentTaskTab('empty-title')}>Задания ещё не выданы</h2>
            </div>
        );
    }
    return (
        <div className={CnStudentTaskTab()}>
            <div className={CnStudentTaskTab('tabs')} id="scroll-bar">
                {tabs.map((tab) => {
                    const isTaskInProgress =
                        !showConfirmBlock && tab.id === selectedTab && !isTimeExpired(deadline ? deadline : '');
                    const showLabel = tab?.timeToComplete && isTaskInProgress ? false : undefined;

                    return (
                        <div key={tab.id} className={CnStudentTaskTab('tab')}>
                            <StatusCard
                                {...tab}
                                isSelected={tab.id === selectedTab}
                                onCardClick={handleTabSelect.bind(null, tab.id)}
                                isStudent
                                onAcademicPerfomance={handleMarksClick}
                                showStatusLabel={showLabel}
                            />
                        </div>
                    );
                })}
            </div>
            {isTimerStarts ? (
                <Timer
                    deadline={deadline ? deadline : ''}
                    timeToFinish={selectedTask?.timeToComplete ? selectedTask?.timeToComplete * 60 : undefined}
                    onTimeIsLeft={handleTimeIsLeft}
                />
            ) : null}

            {!!selectedTab && <PageLoader />}
            {showConfirmBlock && !!selectedTask && (
                <div className={'task-confirm-block'}>
                    <div className={'task-confirm-block__info'}>
                        <AppText text={confirmBlockText} textStyle={'DesktopH3'} />
                        <AppText text={'По окончании времени задание автоматически отправится на проверку.'} />
                        <AppText text={'Готовы начать?'} />
                    </div>
                    <div className={'task-confirm-block__buttons'}>
                        <AppButton label={'Начать'} onClick={approveStartTask} />
                    </div>
                </div>
            )}
            {!showConfirmBlock && !!selectedTask && (
                <StudentTask
                    lessonId={lessonId}
                    assignmentId={selectedTask.assignment.id}
                    assignmentProgressId={Number(selectedTask.id)}
                    studentId={info?.id ?? 0}
                    isStudent
                    isTimeLeft={isTimeLeft}
                    deadline={deadline}
                    createNewAttempt={setShowConfirmBlock.bind(this, true)}
                />
            )}
        </div>
    );
};

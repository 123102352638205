import { weekdaysRu } from 'App/constants';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import updateLocale from 'dayjs/plugin/updateLocale';
import { IAdditionalDaysItem } from 'src-new/components/reporting';
import { IAppTableItems } from 'src-new/ui';
import { formatDayjsToParamsDate, formatDayjsToTableDate, formatTableDateToDayjs } from 'src-new/utils';
import { IUpdateCalendarPlan, periodsType } from 'types/calendarPlan';

import 'dayjs/locale/ru';

dayjs.extend(isBetween);
dayjs.extend(updateLocale);

interface IPeriodsDateRange {
    start: Dayjs;
    end: Dayjs;
}

interface IAdditionalDayRemoveParams {
    period: number;
    day: Dayjs;
}

interface IAdditionalDaysDayJs {
    holidays: Array<Dayjs>;
    additionalWorkingDays: Array<Dayjs>;
}

const lastItem: IAdditionalDaysItem = {
    title: 'Доп. учебные дни',
    plug: 'Нет дней',
    dates: [],
};

/**
 * @description количество столбцов в зависимости от учебного периода
 * @param {periodsType} periodType
 */
const getColumnCount = (periodType?: periodsType): number => {
    switch (periodType) {
        case 'year':
            return 1;
        case 'trimester':
            return 3;
        case 'quarter':
            return 4;
        default:
            return 2;
    }
};

const convertNumber = (num: number) => {
    switch (num) {
        case 1:
            return `I`;
        case 2:
            return `II`;
        case 3:
            return `III`;
        default:
            return `IV`;
    }
};

/**
 * @description заголовок в зависимости от типа учебного периода
 * @param {number} index
 * @param {periodsType} periodType
 */
const getTitle = (index: number, periodType?: periodsType) => {
    const number = convertNumber(index + 1);
    switch (periodType) {
        case 'year':
            return `${number} год`;
        case 'trimester':
            return `${number} триместр`;
        case 'quarter':
            return `${number} четверть`;
        default:
            return `${number} семестр`;
    }
};

/**
 * @description приводим дату к виду отображения
 * @param {Dayjs} date
 * @return string
 */
const getDateWithDateOfWeek = (date: Dayjs): string => {
    const formatDate = formatDayjsToTableDate(date);
    const weekday = weekdaysRu[date.day()];
    return `${formatDate} - ${weekday}`;
};

/**
 * @description форматируем списки доп учебных и не учебных дней к формату Dayjs
 * @param {Array<string>} holidays
 * @param {Array<string>} additionalWorkingDays
 * @return {IAdditionalDaysDayJs}
 */
const formatToAdditionalDays = (
    holidays?: Array<string>,
    additionalWorkingDays?: Array<string>,
): IAdditionalDaysDayJs => {
    const listDatesToDayJs = (list?: Array<string>) => {
        return list?.map((item) => dayjs(item)) ?? [];
    };
    return { holidays: listDatesToDayJs(holidays), additionalWorkingDays: listDatesToDayJs(additionalWorkingDays) };
};

/**
 * @description формируем периоды для не учебных дней если они существуют
 * @param {IAppTableItems} periodsTable
 */
const getPeriodsRangeDates = (periodsTable?: IAppTableItems): Array<IPeriodsDateRange | undefined> | undefined => {
    const periods = periodsTable?.slice(1, -1);
    return periods?.map((item) => {
        const start = item?.[1]?.title?.toString();
        const end = item?.[2]?.title?.toString();
        const startDayjs = start ? formatTableDateToDayjs(start) : undefined;
        const endDayjs = end ? formatTableDateToDayjs(end) : undefined;
        if (startDayjs && endDayjs) {
            return { start: startDayjs, end: endDayjs };
        }
        return undefined;
    });
};

/**
 * @description получаем не учебные даты которые попадают созданные периоды
 * @param {IPeriodsDateRange} periodsRanges
 * @param {Array<string>} additionalWorkingDays
 * @return {Array<Array<Dayjs>>}
 */
const getPeriodsDates = (
    periodsRanges?: Array<IPeriodsDateRange | undefined>,
    additionalWorkingDays?: Array<Dayjs>,
): Array<Array<Dayjs>> => {
    const periodsDaysList = periodsRanges?.map((period) => {
        const { start, end } = period ?? {};
        const daysByPeriods = additionalWorkingDays?.filter((day) => {
            if (start && end) {
                const date = dayjs(day);
                return date.isBetween(start, end, 'day') || date.isSame(start, 'day') || date.isSame(end, 'day');
            }
            return false;
        });
        return daysByPeriods ?? [];
    });
    return periodsDaysList ?? [];
};

/**
 * @description добавляем доп день в список
 * @param {Dayjs} day
 * @param {IAdditionalDaysDayJs} dates
 * @param {isWeekend} isWeekend
 * @return {IAdditionalDaysDayJs}
 */
const addNewAdditionalDay = (day: Dayjs, dates: IAdditionalDaysDayJs, isWeekend?: boolean): IAdditionalDaysDayJs => {
    const { holidays, additionalWorkingDays } = dates;
    let newHolidays = holidays;
    let newAdditional = additionalWorkingDays;
    if (isWeekend) {
        newHolidays = [...holidays, day];
    } else {
        newAdditional = [...additionalWorkingDays, day];
    }
    return { holidays: newHolidays, additionalWorkingDays: newAdditional };
};

/**
 * @description находим и удаляем доп день из списка
 * @param {Dayjs} day
 * @param {IAdditionalDaysDayJs} dates
 * @param {isWeekend} isWeekend
 * @return {IAdditionalDaysDayJs}
 */
const removeAdditionalDay = (day: Dayjs, dates: IAdditionalDaysDayJs, isWeekend?: boolean): IAdditionalDaysDayJs => {
    const { holidays, additionalWorkingDays } = dates;
    let newHolidays = holidays;
    let newAdditional = additionalWorkingDays;
    const isSameDates = (element: Dayjs) => {
        return day.isSame(element, 'day');
    };
    const findAndRemoveItem = (list: Array<Dayjs>) => {
        const index = list.findIndex(isSameDates);
        return index == -1 ? list : [...list.slice(0, index), ...list.slice(index + 1)];
    };

    if (isWeekend) {
        newHolidays = findAndRemoveItem(newHolidays);
    } else {
        newAdditional = findAndRemoveItem(newAdditional);
    }
    return { holidays: newHolidays, additionalWorkingDays: newAdditional };
};

/**
 * @description формируем данные для обновления данных на бэк
 * @param {IAdditionalDaysDayJs} dates
 * @param {IAppTableItems} periodTable
 * @return {IUpdateCalendarPlan}
 */
const getUpdateAdditionalDaysParams = (
    dates: IAdditionalDaysDayJs,
    periodTable?: IAppTableItems,
): IUpdateCalendarPlan | undefined => {
    const { holidays, additionalWorkingDays } = dates;
    const periodTableLastRowIndex = (periodTable?.length ?? 0) - 1;
    const yearDaysCount = periodTable?.[periodTableLastRowIndex]?.[3]?.title ?? 0;
    const yearWeeksCount = periodTable?.[periodTableLastRowIndex]?.[4]?.title ?? 0;
    const periodsRangesDates = getPeriodsRangeDates(periodTable);
    const holidayList = ([] as Array<Dayjs>).concat(...getPeriodsDates(periodsRangesDates, holidays));
    const listDayjsToParamsDate = (list?: Array<Dayjs>) => {
        return list?.map((item) => formatDayjsToParamsDate(item)) ?? [];
    };
    const allHolidays = listDayjsToParamsDate(holidayList);
    const holidaysSet = new Set(allHolidays);

    return {
        holidays: Array.from(holidaysSet),
        additionalWorkingDays: listDayjsToParamsDate(additionalWorkingDays),
        yearDaysCount: Number(yearDaysCount),
        yearWeeksCount: Number(yearWeeksCount),
    };
};

/**
 * @description формируем данные для доп учебных и не учебных дней
 * @param {IAdditionalDaysDayJs} additionalDates
 * @param {IAppTableItems} periodsTable
 * @param {periodsType} periodType
 * @return {Array<IAdditionalDaysItem>}
 */
const getAdditionalDaysTable = (
    additionalDates: IAdditionalDaysDayJs,
    periodsTable?: IAppTableItems,
    periodType?: periodsType,
): Array<IAdditionalDaysItem> => {
    const { holidays, additionalWorkingDays } = additionalDates;
    const countPeriods = getColumnCount(periodType);
    const periodsRangesDates = getPeriodsRangeDates(periodsTable);
    const holidayDatesList = getPeriodsDates(periodsRangesDates, holidays);
    const headerRow = Array(countPeriods)
        ?.fill({})
        .map((item, index) => {
            const title = getTitle(index, periodType);
            const dates = holidayDatesList?.[index] ?? [];
            const range = periodsRangesDates?.[index];
            return { title, dates, range };
        });
    const newLastItem = { ...lastItem, dates: additionalWorkingDays ?? [] };
    return [...headerRow, newLastItem];
};

export {
    addNewAdditionalDay,
    formatToAdditionalDays,
    getAdditionalDaysTable,
    getDateWithDateOfWeek,
    getUpdateAdditionalDaysParams,
    removeAdditionalDay,
};

export type { IAdditionalDayRemoveParams, IAdditionalDaysDayJs, IPeriodsDateRange };

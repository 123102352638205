import { HARD_MODAL_ENUMS } from 'src-new/components/layouts/Modals/Modals.config';
import { useQueryPopupsParams } from 'src-new/hooks';
import { TTaskQuery } from 'types/lessonTask';

import { TTaskPopup, TTaskPopupParams } from './useTaskPopup.types';

const useTaskPopup = (): TTaskPopup => {
    const Popup = useQueryPopupsParams();

    const getQuery = (params: TTaskPopupParams) => {
        const { subjectId, courseGroupId, lessonId, taskId, type = 'TASK', purpose = 'create' } = params;

        const query: TTaskQuery = {
            type: HARD_MODAL_ENUMS[type],
            purpose,
            courseGroupId,
            lessonId,
            subjectId,
        };
        if (subjectId) {
            query.subjectId = subjectId;
        }
        if (taskId) {
            query.taskId = taskId;
        }

        return query;
    };

    const openTaskPopup = (params: TTaskPopupParams) => {
        Popup.openPopup(getQuery(params));
    };

    const closeLastTaskPopup = () => {
        Popup.closeLastPopup();
    };

    const replaceLastTaskPopup = (params: TTaskPopupParams) => {
        Popup.replaceLastPopup(getQuery(params));
    };
    const isCurrent = Popup.isCurrentPopup(HARD_MODAL_ENUMS.TASK);
    return {
        isCurrent,
        openTaskPopup,
        closeLastTaskPopup,
        replaceLastTaskPopup,
    };
};

export default useTaskPopup;

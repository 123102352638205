import { ContentMaterialTypeEnum, IMaterial } from 'types/materials';
export interface MaterialFile {
    materialIndex: number;
    file: File;
}

export const getFiles = (materials: IMaterial[]): MaterialFile[] => {
    return materials.reduce((result, material, index) => {
        switch (true) {
            case !material.isDeleted &&
                material.type === ContentMaterialTypeEnum.audio &&
                material.audioData.file instanceof File:
                result.push({
                    materialIndex: index,
                    file: material.audioData.file as File,
                });
                break;
            case !material.isDeleted &&
                material.type === ContentMaterialTypeEnum.video &&
                material.videoData.file instanceof File:
                result.push({
                    materialIndex: index,
                    file: material.videoData.file as File,
                });
                break;
            case !material.isDeleted &&
                material.type === ContentMaterialTypeEnum.document &&
                material.documentData.some((value) => value instanceof File):
                result.push(
                    ...material.documentData
                        .filter((document) => document instanceof File)
                        .map((document) => ({
                            materialIndex: index,
                            file: document as File,
                        })),
                );
                break;
            default:
                break;
        }

        return result;
    }, [] as MaterialFile[]);
};

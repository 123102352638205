import { ActionCreatorWithoutPayload, createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { AssignmentMark, ITeacher } from 'api/services/assignment/getAssignmentMarks';
import { TaskProgressInterval } from 'containers/TaskBlock/TaskBlock.types';
import {
    deleteAssignmentAction,
    fetchAssignment,
    getAssignmentMarksAction,
    getAssignmentProgressIntervalAction,
    getAssignmentProgressTeachersListAction,
    getAssignmentsByLessonAction,
    getAssignmentStudentMarksAction,
    getAssignmentTypesAction,
    postNewAssignmentAction,
    updateAssignmentNewAction,
} from 'store/actions/assignment';
import { FetchStatus } from 'types/api';
import { AssignmentLevels, AssignmentResponse, AssignmentType, IAssignmentItem } from 'types/assignment';

export interface AssignmentState {
    assignments: IAssignmentItem[];
    teachersList: ITeacher[];
    lessonAssignment: IAssignmentItem[];
    templateAssignments: IAssignmentItem[];
    getTemplateAssignmentStatus: FetchStatus;
    getLessonAssignmentStatus: FetchStatus;
    getAssignmentMarksStatus: FetchStatus;
    getAssignmentsProgressIntervalStatus: FetchStatus;
    fetchStatus: FetchStatus;
    postStatus: FetchStatus;
    patchStatus: FetchStatus;
    mark: AssignmentMark[];
    assignmentsProgressInterval: Record<number, TaskProgressInterval>;
    getIdByPost: AssignmentResponse;
    getTypesStatus: FetchStatus;
    assignmentTypes: AssignmentType[];
    error: unknown;
}

const initialState: AssignmentState = {
    fetchStatus: FetchStatus.INITIAL,
    postStatus: FetchStatus.INITIAL,
    patchStatus: FetchStatus.INITIAL,
    getTemplateAssignmentStatus: FetchStatus.INITIAL,
    getLessonAssignmentStatus: FetchStatus.INITIAL,
    getAssignmentMarksStatus: FetchStatus.INITIAL,
    getAssignmentsProgressIntervalStatus: FetchStatus.INITIAL,
    lessonAssignment: [],
    assignments: [],
    templateAssignments: [],
    error: null,
    mark: [],
    teachersList: [],
    assignmentsProgressInterval: {},
    getIdByPost: {
        id: 0,
        lesson: 0,
        title: '',
        type: {
            id: 0,
            title: '',
            attemptsQuantity: 0,
            verificationType: [],
            executionTime: [],
            isMandatory: true,
            assignmentLevel: AssignmentLevels.ADMINISTRATIVE,
        },
        description: '',
        isTemplate: false,
        author: 0,
    },
    getTypesStatus: FetchStatus.INITIAL,
    assignmentTypes: [],
};

const assignmentSlice = createSlice<AssignmentState, SliceCaseReducers<AssignmentState>>({
    name: 'assignment',
    initialState,
    reducers: {
        resetIdByPost(state) {
            state.getIdByPost = initialState.getIdByPost;
        },
        reset() {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getAssignmentProgressTeachersListAction.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getAssignmentProgressTeachersListAction.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.teachersList = payload || [];
        });
        builder.addCase(getAssignmentProgressTeachersListAction.rejected, (state, { error }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(fetchAssignment.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(fetchAssignment.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.assignments = payload || [];
        });
        builder.addCase(fetchAssignment.rejected, (state, { error }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(getAssignmentProgressIntervalAction.pending, (state) => {
            state.getAssignmentsProgressIntervalStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getAssignmentProgressIntervalAction.fulfilled, (state, { payload }) => {
            state.getAssignmentsProgressIntervalStatus = FetchStatus.FETCHED;
            if (!payload.interval.datetimeEnd || !payload.interval.datetimeStart) return;

            state.assignmentsProgressInterval[payload.assignmentId] = {
                taskId: payload.assignmentId,
                datetimeStart: payload.interval.datetimeStart,
                datetimeEnd: payload.interval.datetimeEnd,
            };
        });
        builder.addCase(getAssignmentProgressIntervalAction.rejected, (state, { error }) => {
            state.getAssignmentsProgressIntervalStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(getAssignmentMarksAction.pending, (state) => {
            state.getAssignmentMarksStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getAssignmentMarksAction.fulfilled, (state, { payload }) => {
            state.getAssignmentMarksStatus = FetchStatus.FETCHED;
            state.mark = payload;
            state.error = null;
        });
        builder.addCase(getAssignmentMarksAction.rejected, (state, { error }) => {
            state.getAssignmentMarksStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder.addCase(getAssignmentStudentMarksAction.pending, (state) => {
            state.getAssignmentMarksStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getAssignmentStudentMarksAction.fulfilled, (state, { payload }) => {
            state.getAssignmentMarksStatus = FetchStatus.FETCHED;
            state.mark = payload;
            state.error = null;
        });
        builder.addCase(getAssignmentStudentMarksAction.rejected, (state, { error }) => {
            state.getAssignmentMarksStatus = FetchStatus.ERROR;
            state.error = error;
        });

        builder
            .addCase(postNewAssignmentAction.pending, (state) => {
                state.postStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(postNewAssignmentAction.fulfilled, (state, { payload }) => {
                state.postStatus = FetchStatus.FETCHED;
                state.getIdByPost = payload.assignment;
            })
            .addCase(postNewAssignmentAction.rejected, (state, { error }) => {
                state.postStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder.addCase(deleteAssignmentAction.pending, (state) => {
            state.postStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(deleteAssignmentAction.fulfilled, (state, { payload }) => {
            state.postStatus = FetchStatus.FETCHED;
            state.assignments = state.assignments.filter((assignment) => assignment.id !== payload.id);
        });
        builder.addCase(deleteAssignmentAction.rejected, (state, { error }) => {
            state.postStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder
            .addCase(updateAssignmentNewAction.pending, (state) => {
                state.patchStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(updateAssignmentNewAction.fulfilled, (state, { payload }) => {
                state.patchStatus = FetchStatus.FETCHED;
                state.getIdByPost = payload.assignment;
                state.error = null;
            })
            .addCase(updateAssignmentNewAction.rejected, (state, { error }) => {
                state.patchStatus = FetchStatus.ERROR;
                state.error = error;
            });
        builder
            .addCase(getAssignmentTypesAction.pending, (state) => {
                state.getTypesStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getAssignmentTypesAction.fulfilled, (state, { payload }) => {
                state.getTypesStatus = FetchStatus.FETCHED;
                state.assignmentTypes = payload;
                state.error = null;
            })
            .addCase(getAssignmentTypesAction.rejected, (state, { error }) => {
                state.getTypesStatus = FetchStatus.FETCHING;
                state.error = error;
            });
        builder
            .addCase(getAssignmentsByLessonAction.pending, (state) => {
                state.getLessonAssignmentStatus = FetchStatus.FETCHING;
                state.error = null;
            })
            .addCase(getAssignmentsByLessonAction.fulfilled, (state, { payload }) => {
                state.getLessonAssignmentStatus = FetchStatus.FETCHED;
                state.lessonAssignment = payload;
                state.error = null;
            })
            .addCase(getAssignmentsByLessonAction.rejected, (state, { error }) => {
                state.getLessonAssignmentStatus = FetchStatus.ERROR;
                state.error = error;
            });
    },
});

export const resetIdByPostAction = assignmentSlice.actions.resetIdByPost as ActionCreatorWithoutPayload<string>;
const resetAssignmentState = assignmentSlice.actions.reset as ActionCreatorWithoutPayload;
export { resetAssignmentState };
export const assignmentReducer = assignmentSlice.reducer;

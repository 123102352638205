import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import * as ScheduleService from 'api/services/dashboardStudentSchedule';
// import {Dayjs} from "dayjs";
import { TGetDayScheduleParams } from 'types/studentScheduleTypes';

export const clearScheduleListAction = createAction('dashboardStudentScheduleTypes/clearScheduleList', () => {
    return {
        payload: 'null',
    };
});
export const clearLessonInfoAction = createAction('dashboardStudentScheduleTypes/clearLessonInfoAction', () => {
    return {
        payload: 'null',
    };
});
export const getFilterTreeAction = createAsyncThunk('dashboardStudentScheduleTypes/getFilterTree', async () => {
    return await ScheduleService.getFilterTree();
});
export const setFiltersAction = createAction('dashboardStudentScheduleTypes/setFilters', (ids: number[]) => {
    return {
        payload: ids,
    };
});
export const setSelectedDayAction = createAction('dashboardStudentScheduleTypes/setCurrentDate', (date: string) => {
    return {
        payload: date,
    };
});

export const getDayScheduleAction = createAsyncThunk(
    'dashboardStudentScheduleTypes/getDaySchedule',
    async (params: TGetDayScheduleParams) => {
        const res = await ScheduleService.getDaySchedule(params);
        const lessons = res.lessonSchedules.map((item) => {
            return {
                ...item,
                isEventRoom: false,
            };
        });
        const rooms = res.eventRooms.map((item) => {
            return {
                ...item,
                isEventRoom: true,
            };
        });

        return [...lessons, ...rooms];
    },
);

export const getDayScheduleChartAction = createAsyncThunk(
    'dashboardStudentScheduleTypes/getDayScheduleChart',
    async (params: TGetDayScheduleParams) => {
        return await ScheduleService.getDayScheduleChart(params);
    },
);

export const getLessonInformationAction = createAsyncThunk(
    'dashboardStudentScheduleTypes/getLessonInformation',
    async (params: { id: number; selectedStudents: number[] }) => {
        const info = await ScheduleService.getLessonInformation(params.id);

        return {
            ...info,
            selectedStudents: info.students.filter((item) => params.selectedStudents.includes(item.id)),
            otherStudents: info.students.filter((item) => !params.selectedStudents.includes(item.id)),
        };
    },
);
export const getEventRoomInformationAction = createAsyncThunk(
    'dashboardStudentScheduleTypes/getEventRoomInformation',
    async (params: { id: number; selectedStudents: number[] }) => {
        const info = await ScheduleService.getEventRoomInformation(params.id);

        return {
            ...info,
            selectedStudents: info.students.filter((item) => params.selectedStudents.includes(item.id)),
            otherStudents: info.students.filter((item) => !params.selectedStudents.includes(item.id)),
        };
    },
);

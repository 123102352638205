import React, { FC } from 'react';
import { cn } from '@bem-react/classname';
import { AuthBackground } from 'assets';
const cnAuthBanner = cn('auth-banner');
import './AuthBanner.scss';

/** */
export const AuthBanner: FC = () => {
    return <div className={cnAuthBanner()} style={{ backgroundImage: `url(${AuthBackground})` }} />;
};

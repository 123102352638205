import { EditorState } from 'draft-js';

import { IDetailedAnswerOption } from './DetailedAnswerFields';
import { IAnswerOption, IQuestion, questionType } from './ExpandedQuestion.types';
import { IMultipleAnswerOption } from './MultipleAnswerFields';
import { IOpenAnswerOption } from './OpenAnswerFields';

export const chooseAddOptionButtonText = (type: questionType): string => {
    switch (type) {
        case 'multiple':
            return 'Добавить вариант ответа';
        case 'open':
            return 'Добавить вариант написания';
        case 'binary':
            return 'Добавить такой же вопрос';
        default:
            return '';
    }
};

export const createNewAnswerOption = (type: questionType, isEditor = false): IAnswerOption => {
    switch (type) {
        case 'multiple':
            return { answer: isEditor ? EditorState.createEmpty() : '', isCorrect: false } as IMultipleAnswerOption;
        case 'open':
            return {
                answer: isEditor ? EditorState.createEmpty() : '',
                truthfulness: '100 % от оценки',
            } as IOpenAnswerOption;
        case 'detailed':
            return { isFileAvailable: true, isTextAvailable: true } as IDetailedAnswerOption;
        default:
            return { answer: isEditor ? EditorState.createEmpty() : '', isCorrect: false };
    }
};

export const createDefaultQuestion = (type: questionType, isEditor = false): IQuestion => {
    switch (type) {
        case 'multiple':
            return {
                question: isEditor ? EditorState.createEmpty() : '',
                randomOrder: false,
                type: 'multiple',
                answerOptions: [
                    {
                        answer: isEditor ? EditorState.createEmpty() : '',
                        isCorrect: true,
                    } as IMultipleAnswerOption,
                    {
                        answer: isEditor ? EditorState.createEmpty() : '',
                        isCorrect: false,
                    } as IMultipleAnswerOption,
                ],
            };
        case 'open':
            return {
                question: isEditor ? EditorState.createEmpty() : '',
                type: 'open',
                answerOptions: [
                    {
                        answer: isEditor ? EditorState.createEmpty() : '',
                        truthfulness: '100 % от оценки',
                    } as IOpenAnswerOption,
                ],
            };
        case 'detailed':
            return {
                question: isEditor ? EditorState.createEmpty() : '',
                type: 'detailed',
                answerOptions: [
                    {
                        isFileAvailable: true,
                        isTextAvailable: true,
                    } as IDetailedAnswerOption,
                ],
            };
        case 'binary':
            return {
                question: isEditor ? EditorState.createEmpty() : '',
                type: 'binary',
                rightAnswer: 'Верно',
            };
        case 'insert-words':
            return {
                question: EditorState.createEmpty(),
                type: 'insert-words',
            };
    }
};

const getQuestionPage = (): string => {
    return `cy-questions-bank-page-principal`;
};

const getDropdownContainer = (): string => {
    return `cy-questions-bank-page-principal-dropdown`;
};

const getDropdownSubjectContainer = (subject: number): string => {
    return `cy-questions-bank-page-principal-dropdown-subject-${subject}`;
};

const getParallelContainer = (parallel: number): string => {
    return `cy-questions-bank-page-principal-parallel-${parallel}`;
};

const getSectionContainer = (section: number): string => {
    return `cy-questions-bank-page-principal-section-${section}`;
};

const getBlockContainer = (block: number): string => {
    return `cy-questions-bank-page-principal-block-${block}`;
};

const getTopicContainer = (topic: number): string => {
    return `cy-questions-bank-page-principal-topic-${topic}`;
};

export const questionsBankPageData = {
    getQuestionPage,
    getDropdownContainer,
    getDropdownSubjectContainer,
    selectedDropdownSubjectIndex: 0,
    getParallelContainer,
    selectedParallelIndex: 0,
    getSectionContainer,
    selectedSectionIndex: 0,
    getBlockContainer,
    selectedBlockIndex: 0,
    getTopicContainer,
    selectedTopicIndex: 0,
};

import React from 'react';
import { PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, Tooltip } from 'recharts';

import { ChartTooltip } from './ChartTooltip';
import { TSummaryCriteriaChart } from './SummaryCriteriaChart.types';
import { getRadarChartData, getRadarList } from './SummaryCriteriaChart.utils';

import './SummaryCriteriaChart.style.scss';
export const SummaryCriteriaChart: React.FC<TSummaryCriteriaChart> = (props) => {
    const { isPositive, criteriaList, chartData } = props;

    const radarList = getRadarList(chartData);
    const { data, key, ticksCount } = getRadarChartData(chartData, isPositive);

    return (
        <RadarChart height={400} width={400} outerRadius="80%" data={data}>
            <PolarGrid gridType={'circle'} />
            <Tooltip
                content={(val) => (
                    <ChartTooltip
                        radarList={radarList}
                        criteriaList={criteriaList ?? []}
                        isPositive={isPositive}
                        {...val}
                    />
                )}
            />
            <PolarAngleAxis dataKey={key} axisLineType={'circle'} />
            <PolarRadiusAxis
                orientation={'left'}
                angle={90}
                tick={{ dominantBaseline: 'text-before-edge', dx: -5 }}
                tickCount={ticksCount}
            />
            {radarList.map(({ title, color }, index) => {
                return <Radar key={`${index}-radar`} dataKey={title} stroke={color} fill={color} fillOpacity={0.1} />;
            })}
        </RadarChart>
    );
};

import React, { useCallback } from 'react';
import { useField } from 'react-final-form';
import { cn } from '@bem-react/classname';
import { ExpandedExerciseMaterial } from '@lms-elements/materials-exercise';
import { IQuestionForStudent } from '@lms-elements/test-task';
import { useAppSelector } from 'store/store';
import { FetchStatus } from 'types/api';
import { IMaterial } from 'types/materials';

interface ExpandedMaterialExerciseContentProps {
    name: string;
    onCheckAnswer?: (answer: IQuestionForStudent, id: number) => void;
    onDelete?: (index?: number) => void;
    isStudentView?: boolean;
    onCreateQuestion?: (index?: number) => void;
    onSelectQuestion?: (index?: number) => void;
    onChangeQuestion?: (index?: number) => void;
    onEditQuestion?: (index?: number) => void;
}

const cnExercise = cn('expanded-material-exercise');

const functionStab = () => undefined;

export const ExpandedMaterialExerciseContent: React.FC<ExpandedMaterialExerciseContentProps> = ({
    name,
    onCheckAnswer,
    onDelete = functionStab,
    isStudentView,
    onCreateQuestion,
    onSelectQuestion,
    onChangeQuestion,
    onEditQuestion,
}) => {
    const { input: materialInput } = useField<IMaterial>(name);

    const materialId = materialInput.value.id;

    const { checkAnswerStatus } = useAppSelector((store) => store.materials);

    const handleCheckExercise = useCallback(
        (answer: IQuestionForStudent) => {
            onCheckAnswer?.(answer, materialId);
        },
        [materialId, onCheckAnswer],
    );

    return (
        <ExpandedExerciseMaterial
            name={name}
            isStudentView={isStudentView}
            questionClassName={cnExercise('question')}
            optionsClassName={cnExercise('options')}
            onDelete={onDelete}
            onCreateQuestion={onCreateQuestion}
            onSelectQuestiuon={onSelectQuestion}
            onChangeQuestion={onChangeQuestion}
            onEditQuestion={onEditQuestion}
            onCheckAnswer={handleCheckExercise}
            isFetching={checkAnswerStatus === FetchStatus.FETCHING}
        />
    );
};

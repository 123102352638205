import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    AssigningStudentTask,
    deleteStudentTasks,
    fetchAssignedAndOnCheckHomeworks,
    fetchHomeworks,
    getAssignedHomeworks,
    getAssignmentProgressPreview,
    getHomeworkPreviewBeetweenDates,
    getHomeworksBetweenDates,
    GetHWPreviewParams,
    getProgressesStatistics,
    getStudentsTasks,
    postStudentTask,
    PutAssignmentProgress,
    putStudentTask,
} from 'api/services/homework';
import { postDeferredAssignment } from 'api/services/homework/postStudentTask';
import { payloadTimeFormatter } from 'src-new/utils/date-n-time';
import { AssignmentProgressPreview, AssignmentProgressStatus } from 'types/assignmentProgress';
import {
    EAssignedTypes,
    EDeadlineTypes,
    FilterTypes,
    HomeworkStatus,
    IHomeworkItem,
    TPostDeferredAssignmentAction,
    TPostDeferredAssignmentResponse,
} from 'types/homework';

export const fetchHomework = createAsyncThunk('homework/fetchHomework', async (isMandatory?: boolean) => {
    return await fetchHomeworks(isMandatory);
});
export const getAssignedAndOnCheckHomeworkAction = createAsyncThunk(
    'homework/getAssignedHomework',
    async (isMandatory?: boolean) => {
        return await fetchAssignedAndOnCheckHomeworks(isMandatory);
    },
);
export const getCompletedHomeworkAction = createAsyncThunk('homework/getCompletedHomework', () => {
    return getAssignmentProgressPreview({ status: AssignmentProgressStatus.COMPLETED });
});

export const postStudentHomeworksAction = createAsyncThunk(
    'homework/postStudentHomework',
    async (tasks: AssigningStudentTask[]) => {
        const payload = tasks.map((task) => {
            const startDate = payloadTimeFormatter(task.assignedDate),
                endDate = payloadTimeFormatter(task.deadlineForComplete);

            return task.teacher
                ? {
                      ...task,
                      assignedDate: startDate,
                      deadlineForComplete: endDate,
                  }
                : {
                      assignment: task.assignment,
                      student: task.student,
                      deadlineForComplete: endDate,
                      assignedDate: startDate,
                      courseGroup: task.courseGroup,
                      assignedType: EAssignedTypes.DATE_TIME,
                      deadlineType: EDeadlineTypes.DATE_TIME,
                      timeToComplete: task.timeToComplete,
                  };
        });
        return await postStudentTask(payload);
    },
);
export const postDeferredAssignmentAction = createAsyncThunk(
    'homework/postDeferredAssignment',
    async (params: TPostDeferredAssignmentAction & { isGetter?: boolean }) => {
        const { filters, created, updated, deleted, isGetter = true } = params;
        if (created || updated || deleted) {
            const createdRes = created
                ? await Promise.all(
                      created?.map(async (assignment) => {
                          const { deletedAssignmentIds, ...rest } = assignment;
                          return await postDeferredAssignment({ filters, create: rest }).then(async (res) => {
                              if (deletedAssignmentIds) {
                                  await deleteStudentTasks(deletedAssignmentIds);
                              }
                              return res;
                          });
                      }),
                  )
                : [];
            const updatedRes = updated
                ? await Promise.all(
                      updated?.map(async (assignment) => {
                          const { deletedDeferredAssignmentsIds, deletedAssignmentIds, ...rest } = assignment;

                          return await postDeferredAssignment({
                              filters,
                              update: rest,
                              delete: deletedDeferredAssignmentsIds,
                          }).then(async (res) => {
                              if (deletedAssignmentIds) {
                                  await deleteStudentTasks(deletedAssignmentIds);
                              }
                              return res;
                          });
                      }),
                  )
                : [];
            const deletedRes = deleted ? await postDeferredAssignment({ filters, delete: deleted }) : [];

            const resultArray = [...createdRes.flat(), ...updatedRes.flat(), ...deletedRes];

            return {
                res: resultArray.reduce((res: TPostDeferredAssignmentResponse[], item) => {
                    if (!res.find((v) => v.id === item.id)) {
                        res.push(item);
                    }
                    return res;
                }, []),
            };
        }

        return {
            res: await postDeferredAssignment({ filters }),
            isGetter,
        };
    },
);

export const putStudentHomeworksAction = createAsyncThunk(
    'homework/putStudentHomework',
    async (tasks: PutAssignmentProgress[]) => {
        const payload = tasks.map((task) => {
            return {
                ...task,
                assignedDate: payloadTimeFormatter(task.assignedDate),
                deadlineForComplete: payloadTimeFormatter(task.deadlineForComplete),
            };
        });
        return await putStudentTask(payload);
    },
);

export const deleteStudentHomeworksAction = createAsyncThunk(
    'homework/deleteStudentHomework',
    async (tasks: { id: number }[]) => {
        return await deleteStudentTasks(tasks);
    },
);

export const getProgressesStatisticsAction = createAsyncThunk(
    'homework/OnCheckProgressesStatistics',
    async ({ status, userId }: { status: HomeworkStatus; userId?: number }) => {
        return await getProgressesStatistics({ status, userId });
    },
);
export const getAssignedHomeworksAction = createAsyncThunk(
    'homework/getAssignedHomeworks',
    async (isMandatory?: boolean) => {
        return await getAssignedHomeworks(isMandatory);
    },
);
export const getHomeworksBetweenDatesAction = createAsyncThunk(
    'homework/getHomeworksBetweenDates',
    async (
        params: {
            filterType: FilterTypes;
            dateStart: string;
            dateEnd: string;
            statusFilters?: AssignmentProgressStatus[];
            isFilterCourseGroup?: boolean;
            isMandatory?: boolean;
        }[],
    ) => {
        return await Promise.all(
            params.map((param) => {
                const { filterType, dateStart, dateEnd, statusFilters, isFilterCourseGroup, isMandatory } = param;

                return getHomeworksBetweenDates(
                    filterType,
                    dateStart,
                    dateEnd,
                    statusFilters,
                    isFilterCourseGroup,
                    isMandatory,
                );
            }),
        ).then((responses) => {
            return responses.reduce((result, res, index) => {
                result[params[index].filterType] = res;

                return result;
            }, {} as Record<FilterTypes, IHomeworkItem[]>);
        });
    },
);

export const getHomeworkPreviewBetweenDatesAction = createAsyncThunk(
    'homework/getHomeworkPreviewBetweenDates',
    async (
        params: {
            filterType: FilterTypes;
            dateStart: string;
            dateEnd: string;
            statusFilters?: AssignmentProgressStatus[];
            isFilterCourseGroup?: boolean;
            isMandatory?: boolean;
        }[],
    ) => {
        return await Promise.all(
            params.map((param) => {
                const { filterType, dateStart, dateEnd, statusFilters, isFilterCourseGroup, isMandatory } = param;

                return getHomeworkPreviewBeetweenDates(
                    filterType,
                    dateStart,
                    dateEnd,
                    statusFilters,
                    isFilterCourseGroup,
                    isMandatory,
                );
            }),
        ).then((responses) => {
            return responses.reduce((result, res, index) => {
                result[params[index].filterType] = res;

                return result;
            }, {} as Record<FilterTypes, AssignmentProgressPreview[]>);
        });
    },
);

export const getStudentsTasksAction = createAsyncThunk(
    'homework/getStudentsTasks',
    async (params: { lessons: number[]; students: number[] }) => {
        const { lessons, students } = params;
        return await getStudentsTasks(lessons, students);
    },
);

export const getAssignmentProgressPreviewAction = createAsyncThunk(
    'homework/fetchPreview',
    (params: GetHWPreviewParams) => {
        return getAssignmentProgressPreview(params);
    },
);

export const setIsMandatoryAction = createAsyncThunk('homework/setIsMandatory', (state: boolean) => {
    return state;
});

import React, { useEffect, useState } from 'react';
import { useField } from 'react-final-form';
import { AppFontIcon, AppInput, AppText } from '@frontend-modules/ui-kit';
import { MonitorPlayIcon } from '@fsd-shared/assets';
import { KinescopeVideo } from '@fsd-shared/ui-kit';
import { E_COLORS } from 'src-new/constants';

import { TAddKinescopeVideo, TVideoValues } from './AddKinescopeVideo.types';
import { checkIsKinescopeUrlValid, validateVideo } from './AddKinescopeVideo.utils';

import './AddKinescopeVideo.style.scss';
export const AddKinescopeVideo: React.FC<TAddKinescopeVideo> = (props) => {
    const { name, url, onDelete, ...videoProps } = props;
    const [videoUrl, setVideoUrl] = useState(url);
    const [isError, setIsError] = useState(!url);

    const { input } = useField<TVideoValues>(name, { validate: validateVideo });

    const onAddLink = (link: string) => {
        const isValid = checkIsKinescopeUrlValid(link);
        if (isValid) {
            setVideoUrl(link);
            setIsError(false);
        } else {
            setVideoUrl(undefined);
            setIsError(true);
        }
    };
    useEffect(() => {
        input.onChange({
            ...input.value,
            videoData: { url: videoUrl },
        });
    }, [videoUrl]);
    return (
        <div className={'add-kinescope-video'}>
            <AppText text={'Видео из Kinescope'} textStyle={'AdaptiveH2'} />
            <div className={`add-kinescope-video-wrapper ${isError && 'error'}`}>
                {videoUrl ? <KinescopeVideo url={videoUrl} {...videoProps} /> : <MonitorPlayIcon />}
                <AppInput
                    className={`add-kinescope-video__input ${isError && 'error'}`}
                    placeholder={'Укажите ссылку на видео из Kinescope'}
                    onChange={onAddLink}
                    defaultValue={videoUrl}
                    variant={'borderless'}
                />
                <div className={'add-kinescope-video__clear-btn'} onClick={onDelete}>
                    <AppFontIcon icon={'delete-m'} color={E_COLORS.coral_color} size={30} />
                </div>
            </div>
        </div>
    );
};

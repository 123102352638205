import React from 'react';
import { cn } from '@bem-react/classname';
import { Skeleton } from 'antd';

import { IAppSkeleton } from './AppSkeletonCard.types';

import './AppSkeletonCard.styles.scss';

const CnAppSkeletonCard = cn('app-skeleton-card');

/**
 * @description AppSkeletonCard
 * @param props
 */
export const AppSkeletonCard: React.FC<IAppSkeleton> = (props) => {
    const { width = '100%', height = 22, borderRadius, margin } = props;

    const styleItem = { height, width, margin };
    const skeletonButtonStyle = { borderRadius };

    return (
        <div className={CnAppSkeletonCard('container')} style={styleItem}>
            <Skeleton.Button active prefixCls={CnAppSkeletonCard()} style={skeletonButtonStyle} />
        </div>
    );
};

import { patchApiRequest } from 'api';
import { CourseGroup } from 'types/courseGroup';

export interface PatchCourseGroupsParams {
    id: number;
    teachers: number[];
    dateStart?: string | null;
    dateEnd?: string | null;
    scheduleInfo?: string;
}

export const patchCourseGroups = ({ id, ...params }: PatchCourseGroupsParams): Promise<CourseGroup> => {
    return patchApiRequest(`/course-groups/partial-update/${id}/`, params);
};

import React, { useState } from 'react';
import { AppButton, AppInput, AppText, AppTextareaInput } from '@frontend-modules/ui-kit';
import { useRole } from 'hooks';
import { AppSelect, AppSkeleton, AppSwitch } from 'src-new/ui';

import { TTaskConfigContainerProps } from './TaskConfigContainer.types';
import { TasksQuestionsList } from './tasks-questions-list';

import './style.scss';

export const TaskConfigContainer: React.FC<TTaskConfigContainerProps> = (props) => {
    const { isTeacher, isMethodist } = useRole();
    const {
        title,
        description,
        isTaskTemplate,
        questionDetails,
        questionsList,
        typeOptionsNormalize,
        selectedType,
        isLoading = false,
        isSaveButtonDisabled = false,
        isSaveButtonLoading = false,
        isTemplateSwitcherDisabled,
        handleTaskName,
        handleTaskDescription,
        handleTypeSelect,
        handleTemplateSwitcher,
        handleChangeQuestionList,
        handleQuestionClick,
        handleSaveTask,
        handleCancel,
    } = props;

    const [isFirstTime, setIsFirstTime] = useState(true);

    const onTitleChange = (name: string) => {
        handleTaskName(name);
        setIsFirstTime(false);
    };

    return (
        <div className={'task-config-container'}>
            <div className={'task-config-container_config'}>
                {isLoading ? (
                    <>
                        <AppSkeleton height={340} width={'auto'} />
                        <AppSkeleton height={100} width={345} />
                    </>
                ) : (
                    <>
                        <div className={'task-config'}>
                            <AppInput
                                placeholder={'Название задания'}
                                defaultValue={title}
                                onChange={onTitleChange}
                                maxLength={256}
                                status={!isFirstTime && !title ? 'error' : undefined}
                            />
                            <AppTextareaInput
                                defaultValue={description}
                                rows={1}
                                style={{ maxHeight: 200, height: 70 }}
                                maxLength={500}
                                placeholder={'Описание'}
                                onChange={handleTaskDescription}
                            />
                            <div className={'task-config__main'}>
                                <div className={'task-config__main_type-selector'}>
                                    <AppText text={'Тип задания'} textStyle={'DesktopFootNotes'} />
                                    <AppSelect
                                        value={selectedType}
                                        placeholder={'Выберите'}
                                        options={typeOptionsNormalize}
                                        showSearch={false}
                                        handleSelect={({ value }) => handleTypeSelect(+value)}
                                    />
                                </div>
                            </div>
                            <div className={'task-config__details'}>
                                {questionDetails?.map((itemType, index) => {
                                    const { title, description } = itemType;

                                    return (
                                        !!title && (
                                            <div key={`${title}-${index}`} className={'task-config__details_row'}>
                                                <AppText text={title} textStyle={'DesktopFontBody'} />
                                                <AppText text={description} textStyle={'DesktopFontBody'} />
                                            </div>
                                        )
                                    );
                                })}
                            </div>
                        </div>
                        <div className={'task-submit'}>
                            <div className={'task-submit__btns'}>
                                <AppButton
                                    label={'Сохранить'}
                                    type={'primary'}
                                    loading={isSaveButtonLoading}
                                    size={'large'}
                                    disabled={isSaveButtonDisabled}
                                    onClick={handleSaveTask}
                                />
                                <AppButton label={'Отменить'} size={'large'} type={'outline'} onClick={handleCancel} />
                            </div>
                            {!(isTeacher && !isMethodist) && (
                                <div className={'task-submit__switcher'}>
                                    <div className={'task-submit__switcher_template'}>
                                        <AppText text={'Шаблон'} textStyle={'DesktopFontBody'} />
                                        <AppSwitch
                                            withIcons
                                            checked={isTaskTemplate}
                                            disabled={isTemplateSwitcherDisabled}
                                            onChange={(checked) => handleTemplateSwitcher(checked)}
                                        />
                                    </div>
                                    <AppText text={'*будет доступен всем преподавателям'} textStyle={'Little'} />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
            <div className={'task-config-container_questions'}>
                {isLoading ? (
                    <AppSkeleton height={300} width={'auto'} />
                ) : (
                    <TasksQuestionsList
                        questionsList={questionsList}
                        isNoType={!selectedType}
                        handleChangeList={handleChangeQuestionList}
                        handleQuestionClick={handleQuestionClick}
                    />
                )}
            </div>
        </div>
    );
};

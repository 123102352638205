import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { TAlert } from 'api/services/alerts';
import { getAlertsListAction, setAlertReadAction } from 'store/actions/alerts';
import { FetchStatus } from 'types/api';

export interface AlertsState {
    getStatus: FetchStatus;
    postStatus: FetchStatus;
    patchStatus: FetchStatus;
    alertsList: TAlert[];
    error: unknown;
}
export const initialState: AlertsState = {
    getStatus: FetchStatus.INITIAL,
    postStatus: FetchStatus.INITIAL,
    patchStatus: FetchStatus.INITIAL,
    alertsList: [] as TAlert[],
    error: null,
};

const alertsSlice = createSlice<AlertsState, SliceCaseReducers<AlertsState>>({
    name: 'alerts',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(getAlertsListAction.pending, (state) => {
            state.getStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(getAlertsListAction.fulfilled, (state, { payload }) => {
            state.getStatus = FetchStatus.FETCHED;
            state.alertsList = payload;
            state.error = null;
        });
        builder.addCase(getAlertsListAction.rejected, (state, { error }) => {
            state.getStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder.addCase(setAlertReadAction.pending, (state) => {
            state.patchStatus = FetchStatus.FETCHING;
            state.error = null;
        });
        builder.addCase(setAlertReadAction.fulfilled, (state, { payload }) => {
            state.patchStatus = FetchStatus.FETCHED;
            state.alertsList = state.alertsList.filter((alert) => alert.id !== payload.id);
            state.error = null;
        });
        builder.addCase(setAlertReadAction.rejected, (state, { error }) => {
            state.patchStatus = FetchStatus.ERROR;
            state.error = error;
        });
    },
});

export const alertsReducer = alertsSlice.reducer;

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { IQuestionForTeacher } from '@lms-elements/test-task';
import { questionType as typeOfQuestion } from '@lms-elements/test-task/build/@types/packages/TestTask/src/Question/Minimized/MinimizedQuestion.types';
import { patchQuestionAction, postQuestionAction } from 'store/actions/questionBank';

import { IFormValuesCreateQuestions, ISelectedValues, QuestionMarkup } from './QuestionCreationPage.types';
import { useEditorsToHTML } from './useEditorsToHtml';
import { getPostQuestionData } from './utils';

interface SubmitParams {
    isEditPage: boolean;
    questionType: typeOfQuestion;
    idPath: number | undefined;
    selectedTopics: number[];
    selectedSkills: ISelectedValues;
    formValuesState: IFormValuesCreateQuestions;
    redirectPath?: string;
    fileIds: number[];
    materialIdToEdit: number;
}

export enum EntityTypes {
    LATEX = 'LATEX',
    LINK = 'LINK',
}

interface ReturnType {
    handleSubmitForm: (value: IFormValuesCreateQuestions) => void;
    getMarkup: () => QuestionMarkup[];
    render: () => React.ReactPortal | undefined;
}

export const useSubmit = (params: SubmitParams): ReturnType => {
    const {
        isEditPage,
        questionType,
        idPath,
        selectedTopics,
        selectedSkills,
        formValuesState,
        redirectPath,
        fileIds,
        materialIdToEdit,
    } = params;
    const dispatch = useDispatch();

    const history = useHistory();

    const { getMarkup, render } = useEditorsToHTML(formValuesState, questionType);

    const handleSubmitForm = useCallback(
        (value: IFormValuesCreateQuestions) => {
            const markups = getMarkup();
            if (!isEditPage) {
                value.questions.forEach((question: IQuestionForTeacher, index) => {
                    dispatch(
                        postQuestionAction(
                            getPostQuestionData(
                                value,
                                question,
                                selectedTopics,
                                selectedSkills.ids,
                                questionType,
                                markups[index],
                                fileIds,
                            ),
                        ),
                    );
                });
            } else {
                // @ts-ignore
                value.questions.forEach((question: IQuestionForTeacher, index) => {
                    if (idPath) {
                        dispatch(
                            patchQuestionAction({
                                id: idPath,
                                materialId: materialIdToEdit,
                                isFromMaterials: formValuesState.isFromMaterials,
                                question: getPostQuestionData(
                                    value,
                                    question,
                                    selectedTopics,
                                    selectedSkills.ids,
                                    questionType,
                                    markups[index],
                                ),
                            }),
                        );
                    }
                });

                if (redirectPath) {
                    history.push(redirectPath);
                }
            }
        },
        [
            getMarkup,
            isEditPage,
            dispatch,
            selectedTopics,
            selectedSkills.ids,
            questionType,
            fileIds,
            history,
            redirectPath,
            idPath,
        ],
    );

    return {
        handleSubmitForm,
        render,
        getMarkup,
    };
};

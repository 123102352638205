export interface FiltersQuestionBank {
    search?: string;
    filterType: string;
    filterDifficulty: string;
    sortDate: string;
    status: string;
}

export enum Status {
    All = 'all',
    Published = 'published',
    NotPublished = 'not_published',
    OnRevision = 'on_revision',
}

export enum StatusDropdownLabels {
    All = 'Показать все',
    Published = 'Показать утвержденные',
    NotPublished = 'Показать неутвержденные',
    OnRevision = 'Показать на доработке',
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApiRequest } from 'api';
import { getMonitoringInfo } from 'api/services/monitoring';
import { MonitoringProps } from 'types/monitoring';

export const getMonitoringInfoAction = createAsyncThunk(
    'schedule/getMonitoringInfo',
    ({ status, date }: { status: string; date: string }) => {
        return getMonitoringInfo({ status, date });
    },
);

export const getNextMonitoringInfoAction = createAsyncThunk('schedule/getNextMonitoringInfo', (nextUrl: string) => {
    return getApiRequest<MonitoringProps, string>(nextUrl);
});

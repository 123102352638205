import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRole } from 'hooks';
import { HARD_MODAL_ENUMS } from 'src-new/components/layouts/Modals/Modals.config';
import {
    useCreateQuestionPopup,
    useLessonsPopup,
    useSearchQuestionsPopup,
    useStudentTasksViewPopup,
    useTaskPopup,
} from 'src-new/hooks';
import {
    TCreateQuestionQuery,
    TLessonQuery,
    TSearchQuestionsQuery,
    TStudentTasksViewQuery,
    TTaskQuery,
} from 'types/lessonTask';

import { TUseSubjectsRedirect } from './useSubjectsRedirect.types';

/**
 * @description хук, который редиректит между старыми и новыми предметами в зависимости от роли и страницы
 * @param {TUseSubjectsRedirect} props
 */
export const useSubjectsRedirect = (props: TUseSubjectsRedirect): void => {
    const { modalType, urlProps, isFromOldSubjects } = props;
    const { courseId } = urlProps as TLessonQuery;
    const type = HARD_MODAL_ENUMS[modalType];

    const history = useHistory();
    const { isStudent, isTeacher, isMethodist } = useRole();
    const isOnlyTeacher = isTeacher && !isMethodist;

    const LessonPopup = useLessonsPopup();
    const TaskPopup = useTaskPopup();
    const StudentTasksViewPopup = useStudentTasksViewPopup();
    const CreateQuestionPopup = useCreateQuestionPopup();
    const SearchQuestionsPopup = useSearchQuestionsPopup();

    const handleLessonOpen = () => {
        const { lessonId, courseGroupId, subjectId, courseId, tab } = urlProps as TLessonQuery;
        if (isStudent === false && isTeacher === false && LessonPopup.isCurrent) {
            history.push(
                `/subjects/${subjectId ?? courseId}/${lessonId}/${tab ?? 'materials'}?course_group=${courseGroupId}`,
            );
        } else if (isStudent === false && isTeacher === false) {
            history.push('/');
        }
    };
    const handleTaskOpen = () => {
        const { lessonId, courseGroupId, taskId, purpose, subjectId } = urlProps as TTaskQuery;

        if (isTeacher === false && TaskPopup.isCurrent) {
            const taskIdPart = purpose === 'edit' ? `/${taskId}` : '';
            history.push(
                `/subjects/${subjectId}/${lessonId}/tasks/${purpose}${taskIdPart}?course_group=${courseGroupId}`,
            );
        } else if (isTeacher === false) {
            history.push('/');
        }
    };
    const handleSearchQuestionsOpen = () => {
        const { lessonId, courseGroupId, assignment, num, purpose, subjectId } = urlProps as TSearchQuestionsQuery;
        if (isTeacher === false && SearchQuestionsPopup.isCurrent) {
            const assignmentPart =
                purpose === 'edit' ? `edit/search-questions/${num}/${assignment}` : `search-questions/${num}`;

            history.push(`/subjects/${subjectId}/${lessonId}/task/${assignmentPart}/?course_group=${courseGroupId}`);
        } else if (isTeacher === false) {
            history.push('/');
        }
    };
    const handleCreateQuestionOpen = () => {
        const { lessonId, courseGroupId, num, purpose, subjectId, assignment } = urlProps as TCreateQuestionQuery;
        if (isTeacher === false && CreateQuestionPopup.isCurrent) {
            const assignmentPart =
                purpose === 'edit'
                    ? `edit/search-questions/${num}/${assignment}/create`
                    : `search-questions/${num}/create`;

            history.push(`/subjects/${subjectId}/${lessonId}/task/${assignmentPart}/?course_group=${courseGroupId}`);
        } else if (isTeacher === false) {
            history.push('/');
        }
    };
    const handleTasksViewOpen = () => {
        const { lessonId, courseGroupId, taskId, studentId, subjectId } = urlProps as TStudentTasksViewQuery;

        if (isTeacher === false && isStudent === false && StudentTasksViewPopup.isCurrent) {
            history.push(
                `/subjects/${subjectId}/lessons/${lessonId}/tasks/students/${studentId}/${taskId}?course_group=${courseGroupId}`,
            );
        } else if (isStudent === true) {
            StudentTasksViewPopup.closeLastStudentTasksViewPopup();
        } else if (isTeacher === false && isStudent === false) {
            history.push('/');
        }
    };

    // Ниже - методы для перехода из старого функционала в новый
    // (закомментированы редиректы для роли учителя до тех пор, пока старый функционал для учителя не будет скрыт)
    const openLessonPopup = () => {
        const { lessonId, courseGroupId, subjectId, courseId, tab, taskId } = urlProps as TLessonQuery;

        if ((isStudent || isOnlyTeacher) && courseId) {
            history.push('/new-subjects');
            LessonPopup.replaceLastLessonPopup({
                lessonId,
                courseGroupId,
                subjectId,
                courseId,
                tab,
                taskId,
            });
        }
    };
    const openTaskPopup = () => {
        const { lessonId, courseGroupId, taskId, purpose, subjectId } = urlProps as TTaskQuery;

        if (isOnlyTeacher) {
            history.push('/new-subjects');
            LessonPopup.replaceLastLessonPopup({
                lessonId,
                courseGroupId: courseGroupId,
                subjectId,
                tab: 'tasks',
                taskId,
            });
            TaskPopup.openTaskPopup({
                lessonId,
                courseGroupId: courseGroupId,
                subjectId,
                purpose,
                taskId,
            });
        }
    };
    const openSearchQuestionsPopup = () => {
        const {
            lessonId,
            courseGroupId,
            assignment,
            num,
            purpose,
            subjectId,
            taskTypeId,
        } = urlProps as TSearchQuestionsQuery;

        if (isOnlyTeacher) {
            history.push('/new-subjects');
            LessonPopup.replaceLastLessonPopup({
                lessonId,
                courseGroupId: courseGroupId,
                subjectId,
                tab: 'tasks',
                taskId: assignment,
            });
            TaskPopup.openTaskPopup({
                lessonId,
                courseGroupId: courseGroupId,
                subjectId,
                purpose,
                taskId: assignment,
            });
            SearchQuestionsPopup.openSearchQuestionsPopup({
                lessonId,
                courseGroupId: courseGroupId,
                subjectId,
                purpose,
                num,
                taskTypeId,
            });
        }
    };
    const openCreateQuestionPopup = () => {
        const {
            lessonId,
            courseGroupId,
            assignment,
            num,
            purpose,
            prevPurpose,
            subjectId,
        } = urlProps as TCreateQuestionQuery;

        if (isOnlyTeacher && lessonId && courseGroupId && assignment && purpose && prevPurpose && subjectId) {
            history.push('/new-subjects');
            LessonPopup.replaceLastLessonPopup({
                lessonId,
                courseGroupId,
                subjectId,
                tab: 'tasks',
                taskId: assignment,
            });
            TaskPopup.openTaskPopup({
                lessonId,
                courseGroupId,
                subjectId,
                purpose: prevPurpose,
                taskId: assignment,
            });
            CreateQuestionPopup.openCreateQuestionPopup({
                lessonId,
                courseGroupId,
                subjectId,
                purpose,
                assignment,
                num: num ?? 1,
            });
        }
    };
    const openTasksViewPopup = () => {
        const { lessonId, courseGroupId, subjectId, studentId, taskId } = urlProps as TStudentTasksViewQuery;

        if (isStudent) {
            history.push('/new-subjects');
            LessonPopup.replaceLastLessonPopup({
                subjectId,
                courseGroupId: courseGroupId,
                lessonId,
                tab: 'tasks',
                taskId,
            });
        } else if (isOnlyTeacher) {
            history.push('/new-subjects');
            LessonPopup.replaceLastLessonPopup({
                subjectId,
                courseGroupId: courseGroupId,
                lessonId,
                tab: 'tasks',
                taskId,
            });
            StudentTasksViewPopup.openStudentTasksViewPopup({
                subjectId,
                courseGroupId,
                lessonId,
                studentId,
                taskId,
            });
        }
    };

    useEffect(() => {
        switch (type) {
            case 'lesson':
                isFromOldSubjects ? openLessonPopup() : handleLessonOpen();
                break;
            case 'task':
                isFromOldSubjects ? openTaskPopup() : handleTaskOpen();
                break;
            case 'search_questions':
                isFromOldSubjects ? openSearchQuestionsPopup() : handleSearchQuestionsOpen();
                break;
            case 'create_question':
                isFromOldSubjects ? openCreateQuestionPopup() : handleCreateQuestionOpen();
                break;
            case 'tasks_view':
                isFromOldSubjects ? openTasksViewPopup() : handleTasksViewOpen();
                break;
        }
    }, [type, isStudent, isTeacher, courseId]);
};

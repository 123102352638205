import {
    CreateQuestionPopup,
    LessonPopup,
    SearchQuestionsPopup,
    StudentTasksViewPopup,
    TaskPopup,
} from 'src-new/components/new-subjects/popups';

// сделать из этого один общий enum для всех URL параметров
export const URL_SEARCH_ENUM = {
    MODALS: 'modals',
};

export const HARD_MODAL_ENUMS = {
    LESSON: 'lesson',
    TASK: 'task',
    SEARCH_QUESTIONS: 'search_questions',
    CREATE_QUESTION: 'create_question',
    TASKS_VIEW: 'tasks_view',
};

export const mappedPopups = {
    [HARD_MODAL_ENUMS.LESSON]: LessonPopup,
    [HARD_MODAL_ENUMS.TASK]: TaskPopup,
    [HARD_MODAL_ENUMS.SEARCH_QUESTIONS]: SearchQuestionsPopup,
    [HARD_MODAL_ENUMS.CREATE_QUESTION]: CreateQuestionPopup,
    [HARD_MODAL_ENUMS.TASKS_VIEW]: StudentTasksViewPopup,
};

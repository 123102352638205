import { patchApiRequest } from 'api';

import { AdditionalMark } from './';

export interface PatchTeacherMarkParams {
    id: number;
    score?: number;
    comment?: string;
}

export const changeTeacherMarks = (params: PatchTeacherMarkParams[]): Promise<AdditionalMark[]> => {
    return patchApiRequest(`/teacher-mark/bulk-update/`, params);
};

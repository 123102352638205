import moment from 'moment';
import { TLessonSchedules, TSubjectLesson } from 'src-new/components/new-subjects';
import { getCurrentDateByMoscow } from 'src-new/utils/date-n-time';

export const getNearestLessonId = (lessons: TSubjectLesson[]): number => {
    // @ts-ignore
    const lessonSchedules: TLessonSchedules[] = lessons
        .map((item) => {
            const onlineLesson = item.lessonSchedules.find((item) => item.type === 'online_lesson');
            const testLesson = item.lessonSchedules.find((item) => item.type === 'test_lesson');
            const selfpreparationLesson = item.lessonSchedules.find((item) => item.type === 'selfpreparation_lesson');

            return onlineLesson ?? testLesson ?? selfpreparationLesson;
        })
        .filter((item) => !!item);

    const currentTime = getCurrentDateByMoscow();
    const sortedLessons = lessonSchedules.sort((a, b) => {
        const difPrev = Math.abs(moment(a.datetimeStart).diff(moment(currentTime)));
        const difNext = Math.abs(moment(b.datetimeStart).diff(moment(currentTime)));

        return difPrev - difNext;
    });

    return lessons.find((item) => item.lessonSchedules.includes(sortedLessons[0]))?.id ?? 0;
};

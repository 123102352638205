import { cookiesDomain } from 'constants/apiMethods';

import { jwtDecode } from 'jwt-decode';

import { getCookie, removeCookie, setCookie } from './cookies';

export const getAccessToken = (): string => <string>getCookie('access_token') || '';
export const getRefreshToken = (): string => <string>getCookie('refresh_token') || '';
export const setAccessToken = (token: string): string | undefined => {
    let tokenData;
    try {
        tokenData = jwtDecode(token);
    } catch (e) {
        tokenData = undefined;
    }
    const expires = tokenData?.exp ? new Date(tokenData?.exp * 1000) : new Date();

    return setCookie('access_token', token, {
        secure: true,
        expires: expires,
        domain: cookiesDomain,
        sameSite: 'none',
        path: '/',
    });
};
export const setRefreshToken = (token: string): string | undefined => {
    let tokenData;
    try {
        tokenData = jwtDecode(token);
    } catch (e) {
        tokenData = undefined;
    }
    const expires = tokenData?.exp ? new Date(tokenData?.exp * 1000) : new Date();

    return setCookie('refresh_token', token, {
        secure: true,
        expires: expires,
        domain: cookiesDomain,
        sameSite: 'none',
        path: '/',
    });
};

export const removeAccessToken = (): void => removeCookie('access_token', { domain: cookiesDomain });
export const removeRefreshToken = (): void => removeCookie('refresh_token', { domain: cookiesDomain });

export const removeAllTokens = (): void => {
    removeRefreshToken();
    removeAccessToken();
};

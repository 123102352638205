import { E_COLORS } from 'src-new/constants';

type TProgressStatus = 'correct' | 'incorrect' | 'unknown';

const E_STATISTIC_COLORS = {
    correct: E_COLORS.firm_blue_color,
    incorrect: E_COLORS.coral_color,
    unknown: E_COLORS.grey_2,
};

const formatProgressStatus = (progress?: Array<boolean>): Array<TProgressStatus> => {
    return progress?.map((item) => (item ? 'correct' : 'incorrect')) ?? [];
};

const getLastTenProgress = (progress?: Array<boolean>): Array<string> => {
    const array = Array(10).fill('unknown') as Array<TProgressStatus>;
    return [...formatProgressStatus(progress), ...array].slice(0, 10).map((item) => E_STATISTIC_COLORS[item]);
};

export { getLastTenProgress };

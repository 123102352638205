import { useDispatch } from 'react-redux';
import {
    TAreaName,
    transferQuestionsBlock,
    transferSingleQuestion,
    transferSingleQuestionFromBlock,
} from 'src-new/components/lesson-task';
import { setQuestionsStateAction, setSelectedBlockAction } from 'store/actions/lessonTask';
import { TAssignedQuestion, TSearchBlock, TSingleQuestion } from 'types/lessonTask';

export const useQuestionsDrop = (props: {
    leftBlock: TAssignedQuestion;
    rightBlock: TAssignedQuestion;
    activeQuestionNumber: number;
}): {
    handleDropBlock: (areaName: TAreaName, data: TSearchBlock) => void;
    handleDropSingle: (areaName: TAreaName, data: TSingleQuestion) => void;
    handleDropSingleBlock: (data: TSingleQuestion) => void;
} => {
    const dispatch = useDispatch();

    const { leftBlock, rightBlock, activeQuestionNumber } = props;

    const handleDropBlock = (areaName: TAreaName, data: TSearchBlock) => {
        const transferData = {
            fromObject: areaName === 'left' ? rightBlock : leftBlock,
            toObject: areaName === 'left' ? leftBlock : rightBlock,
            item: data,
        };
        const { filteredObject, updatedObject } = transferQuestionsBlock(transferData);

        if (filteredObject && updatedObject) {
            dispatch(
                setQuestionsStateAction({
                    left: areaName === 'left' ? updatedObject : filteredObject,
                    right: areaName === 'left' ? filteredObject : updatedObject,
                    questionNum: activeQuestionNumber,
                }),
            );
        }
        dispatch(setSelectedBlockAction());
    };
    const handleDropSingle = (areaName: TAreaName, data: TSingleQuestion) => {
        const transferData = {
            fromObject: areaName === 'left' ? rightBlock : leftBlock,
            toObject: areaName === 'left' ? leftBlock : rightBlock,
            item: data,
        };
        const { filteredObject, updatedObject } = transferSingleQuestion(transferData);

        if (filteredObject && updatedObject) {
            dispatch(
                setQuestionsStateAction({
                    left: areaName === 'left' ? updatedObject : filteredObject,
                    right: areaName === 'left' ? filteredObject : updatedObject,
                    questionNum: activeQuestionNumber,
                }),
            );
        }
    };
    const handleDropSingleBlock = (data: TSingleQuestion) => {
        const transferData = {
            fromObject: leftBlock,
            toObject: rightBlock,
            item: data,
        };
        const { filteredObject, updatedObject } = transferSingleQuestionFromBlock(transferData);

        if (filteredObject && updatedObject) {
            dispatch(
                setQuestionsStateAction({
                    left: filteredObject,
                    right: updatedObject,
                    questionNum: activeQuestionNumber,
                }),
            );
        }
    };

    return {
        handleDropBlock,
        handleDropSingle,
        handleDropSingleBlock,
    };
};

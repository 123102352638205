import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useRole } from 'hooks';
import { getLastViewedLessonByCourse } from 'src-new/utils';
import {
    getStudentsCourseTreeAction,
    getStudentsTreeByTimeItemsAction,
    getStudentsTreeByURLAction,
    getTeachersCourseTreeAction,
    getTeachersTreeByTimeItemsAction,
    getTeachersTreeByURLAction,
    getTreeByFirstItemsAction,
    getTreeByKnownItemsAction,
} from 'store/actions/newSubjects';
import { TSelectedCourse } from 'types/newSubjectsTypes';

import { TUpdateTree, TUseGetSubjectsTreeResponse } from './useGetSubjectsTree.types';

/**
 * @description Хук для получения дерева предметов в зависимости от роли пользователя
 * @return {TUseGetSubjectsTreeResponse}
 */
export const useGetSubjectsTree = (): TUseGetSubjectsTreeResponse => {
    const { isStudent, isTeacher } = useRole();
    const dispatch = useDispatch();
    const location = useLocation();

    //Получаем дерево курсов для студентов
    const getStudentsCourseTree = () => {
        dispatch(getStudentsCourseTreeAction());
    };
    //Получаем дерево курсов для учителей
    const getTeachersCourseTree = () => {
        dispatch(getTeachersCourseTreeAction({ role: 'teacher' }));
    };
    // Получаем дерево тем и уроков для студентов в зависимости от выбранного курса
    // для курса ЛЗ - либо из local.storage, либо по первым из списка
    // для остальных курсов - по ближайшему уроку
    // (При условии, что courseGroupId выбрана пользователем, а не взята из юрлы)
    const getStudentsRestTree = (selectedCourse: TSelectedCourse) => {
        const { id: courseGroupId, isCurious } = selectedCourse;
        //Открыта ли модалка урока
        const modalIsOpen = location.search.includes('lessonId');

        if (courseGroupId && !modalIsOpen) {
            if (isCurious) {
                const dataFromStorage = getLastViewedLessonByCourse(courseGroupId);

                if (dataFromStorage) {
                    dispatch(getTreeByKnownItemsAction(dataFromStorage));
                } else {
                    dispatch(getTreeByFirstItemsAction(selectedCourse));
                }
            } else {
                void dispatch(getStudentsTreeByTimeItemsAction(selectedCourse));
            }
        }
    };

    // Получаем дерево тем и уроков для учителей в зависимости от выбранного курса по ближайшему уроку
    const getTeachersRestTree = (selectedCourse: TSelectedCourse) => {
        dispatch(getTeachersTreeByTimeItemsAction(selectedCourse));
    };
    const getStudentsTreeByUrl = (params: TUpdateTree) => {
        dispatch(getStudentsTreeByURLAction(params));
    };
    const getTeachersTreeByUrl = (params: TUpdateTree) => {
        dispatch(getTeachersTreeByURLAction(params));
    };

    const getCoursesTree = () => {
        if (isStudent) {
            getStudentsCourseTree();
            return;
        }
        if (isTeacher) {
            getTeachersCourseTree();
            return;
        }
    };
    const getRestTree = (selectedCourse: TSelectedCourse) => {
        if (isStudent) {
            getStudentsRestTree(selectedCourse);
            return;
        }
        if (isTeacher) {
            getTeachersRestTree(selectedCourse);
            return;
        }
    };
    const getTreeByUrl = (params: TUpdateTree) => {
        if (isStudent) {
            getStudentsTreeByUrl(params);
            return;
        }
        if (isTeacher) {
            getTeachersTreeByUrl(params);
            return;
        }
    };

    return {
        getCoursesTree,
        getRestTree,
        getTreeByUrl,
    };
};

import React from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';
import {
    QuestionsBlockContainerMemo,
    SingleQuestionContainerMemo,
    TQuestionsBlockContainerDrag,
    TSingleQuestionContainerDrag,
} from 'src-new/components/lesson-task';

import './style.scss';
/**
 * @description Компонент для кастомной отрисовки перетаскиваемого элемента
 * (Без него будет перетаскиваться скриншот элемента, который обрезается по родительскому контейнеру)
 * @return {JSX.Element | null}
 * */
export const CommonDragLayer = (): JSX.Element | null => {
    const { item, itemType, currentOffset, initialOffset, isDragging } = useDragLayer((monitor) => ({
        item: monitor.getItem() as TQuestionsBlockContainerDrag | TSingleQuestionContainerDrag,
        itemType: monitor.getItemType(),
        currentOffset: monitor.getSourceClientOffset() as XYCoord,
        initialOffset: monitor.getInitialSourceClientOffset() as XYCoord,
        isDragging: monitor.isDragging(),
    }));

    if (!isDragging) {
        return null;
    }

    const renderItem = () => {
        if (itemType === 'block') {
            const { mainProps } = item as TQuestionsBlockContainerDrag;

            return (
                <div
                    style={
                        !initialOffset || !currentOffset
                            ? { display: 'none' }
                            : { transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)` }
                    }
                >
                    <QuestionsBlockContainerMemo isDragging {...mainProps} />
                </div>
            );
        } else if (itemType === 'single' || itemType === 'block-single') {
            const { mainProps } = item as TSingleQuestionContainerDrag;

            return (
                <div
                    style={
                        !initialOffset || !currentOffset
                            ? { display: 'none' }
                            : { transform: `translate(${currentOffset.x}px, ${currentOffset.y}px)` }
                    }
                >
                    <SingleQuestionContainerMemo isDragging {...mainProps} />
                </div>
            );
        } else return null;
    };

    return <div className={'common-drag-layer'}>{renderItem()}</div>;
};

import React, { useCallback, useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';
import { OpenLinkIcon } from '@lms-elements/icons';
import { LinkData } from 'src-new/components/lms-elements/CustomEditor/types/entities.types';
import { downloadTxtFileFromUrlByLink } from 'src-new/utils';

import { ILinkWrapperProps } from './LinkWrapper.types';

import './LinkWrapper.scss';

const LinkWrapperCn = cn('link-wrapper');

const POPUP_SIZE = 34;

/**
 *
 * @param prm
 * @param prm.entityKey
 * @param prm.contentState
 * @param prm.children
 */
export const LinkWrapper: React.FC<ILinkWrapperProps> = ({ entityKey, contentState, children }) => {
    const [isPopUpShown, setIsPopUpShown] = useState(false);
    const [popUpStyles, setPopUpStyles] = useState({
        top: -9999,
        left: -9999,
    });

    const handleDocumentScroll = useCallback(() => {
        setIsPopUpShown(false);
    }, []);

    const handleLinkMouseEnter = useCallback(
        (e: React.MouseEvent<HTMLSpanElement> | React.TouchEvent<HTMLSpanElement>) => {
            const link = e.currentTarget as HTMLDivElement;

            const { x, y } = link.getBoundingClientRect();
            setPopUpStyles({
                top: y + POPUP_SIZE / 3,
                left: x - POPUP_SIZE + 2,
            });

            document.addEventListener('touchmove', handleDocumentScroll);
            document.addEventListener('mousewheel', handleDocumentScroll);
            document.addEventListener('scroll', handleDocumentScroll);

            setIsPopUpShown(true);
        },
        [handleDocumentScroll],
    );

    const handleLinkMouseLeave = useCallback(() => {
        setPopUpStyles({ top: -9999, left: -9999 });

        document.removeEventListener('touchmove', handleDocumentScroll);
        document.removeEventListener('mousewheel', handleDocumentScroll);
        document.removeEventListener('scroll', handleDocumentScroll);

        setIsPopUpShown(false);
    }, [handleDocumentScroll]);

    const { url, target } = useMemo(() => contentState.getEntity(entityKey).getData() as LinkData, [
        contentState,
        entityKey,
    ]);

    const openLink = useCallback(() => {
        if (url.includes('.txt')) {
            void downloadTxtFileFromUrlByLink(url);
        } else {
            const linkTab = window.open(url, target);
            if (linkTab) {
                linkTab.focus();
            }
        }
    }, [target, url]);

    return (
        <span className={LinkWrapperCn()} onMouseEnter={handleLinkMouseEnter} onMouseLeave={handleLinkMouseLeave}>
            <a href={url} target={target}>
                {children}
            </a>
            {isPopUpShown && (
                <div className={LinkWrapperCn('popup')} style={popUpStyles} onClick={openLink}>
                    <OpenLinkIcon />
                </div>
            )}
        </span>
    );
};

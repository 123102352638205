import React, { useCallback, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Button, ButtonViewEnum, Checkbox } from '@lms-elements/atomic';
import { AttendanceStatus } from 'src-new/components/lms-elements/PerformanceTable/types';
import { AssignmentProgressStatus } from 'types/assignmentProgress';
import { AssignmentProgress, Task } from 'types/homework';

import { HomeworkStatus, HomeworkStatusProps } from 'components/HomeworkStatus';
import { HomeworkTable } from 'components/HomeworkTable';

import './AssignedHomeworkTable.scss';

const CnAssignedHomeworkTable = cn('assignedHomeworkTable');

interface AssignedHomeworkTableProps {
    homeworkStatus: HomeworkStatusProps;
    tasks: Task[];
    students: {
        id: number;
        firstName: string;
        lastName: string;
        photoUrl: string;
        status?: AttendanceStatus;
    }[];
    assignmentProgress?: AssignmentProgress[];
    withAttendance?: boolean;
    disabledButton?: boolean;
    onEditTaskButtonClick?: () => void;
    onRepeatTaskButtonClick?: () => void;
    onStudentCellClick?: (studentId: number) => void;
    onHomeworkClick?: (studentId: number, taskId: number) => void;
}

export const AssignedHomeworkTable: React.FC<AssignedHomeworkTableProps> = ({
    homeworkStatus,
    tasks,
    students,
    assignmentProgress,
    withAttendance,
    disabledButton,
    onEditTaskButtonClick,
    onRepeatTaskButtonClick,
    onStudentCellClick,
    onHomeworkClick,
}) => {
    const [showCompletedTasks, setShowCompletedTasks] = useState(true);
    const [showAssignedTasks, setShowAssignedTasks] = useState(true);
    const [showOnCheckTasks, setShowOnCheckTasks] = useState(true);
    const [showDeferredAssignedTasks, setShowDeferredAssignedTasks] = useState(true);

    const handleCheckboxChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const checkbox = e.target;
        const { name, checked } = checkbox;

        switch (name) {
            case AssignmentProgressStatus.COMPLETED:
                setShowCompletedTasks(checked);
                break;
            case AssignmentProgressStatus.ASSIGNED:
                setShowAssignedTasks(checked);
                break;
            case AssignmentProgressStatus.ON_CHECK:
                setShowOnCheckTasks(checked);
                break;
            case AssignmentProgressStatus.DEFERRED_ASSIGNMENT:
                setShowDeferredAssignedTasks(checked);
                break;
            default:
                break;
        }
    }, []);

    return (
        <section className={CnAssignedHomeworkTable()}>
            <header className={CnAssignedHomeworkTable('header')}>
                <div className={CnAssignedHomeworkTable('up-part')}>
                    <div className={CnAssignedHomeworkTable('homeworkStatus')}>
                        <HomeworkStatus {...homeworkStatus} withoutBorder={true} />
                    </div>
                    <div className={CnAssignedHomeworkTable('headerActionButtons')}>
                        <Button
                            view={ButtonViewEnum.bordered}
                            size="l"
                            onClick={onRepeatTaskButtonClick}
                            disabled={disabledButton}
                        >
                            Повторная выдача
                        </Button>
                        <Button
                            view={ButtonViewEnum.action}
                            size="l"
                            onClick={onEditTaskButtonClick}
                            disabled={disabledButton}
                        >
                            Редактировать выдачу
                        </Button>
                    </div>
                </div>

                <div className={CnAssignedHomeworkTable('filterCheckboxes')}>
                    <Checkbox
                        label="Проверить"
                        name={AssignmentProgressStatus.ON_CHECK}
                        onChange={handleCheckboxChange}
                        defaultChecked
                    />
                    <Checkbox
                        label="Не сдано"
                        name={AssignmentProgressStatus.ASSIGNED}
                        onChange={handleCheckboxChange}
                        defaultChecked
                    />
                    <Checkbox
                        label="Оценено"
                        name={AssignmentProgressStatus.COMPLETED}
                        onChange={handleCheckboxChange}
                        defaultChecked
                    />
                    <Checkbox
                        label="Автовыдача"
                        name={AssignmentProgressStatus.DEFERRED_ASSIGNMENT}
                        onChange={handleCheckboxChange}
                        defaultChecked
                    />
                </div>
            </header>
            <div className={CnAssignedHomeworkTable('table-wrapper')}>
                <HomeworkTable
                    tasks={tasks}
                    students={students}
                    assignmentProgress={assignmentProgress}
                    isEditForm={false}
                    showCompletedTasks={showCompletedTasks}
                    showAssignedTasks={showAssignedTasks}
                    showOnCheckTasks={showOnCheckTasks}
                    showDeferredAssignedTasks={showDeferredAssignedTasks}
                    withAttendance={withAttendance}
                    onStudentClick={onStudentCellClick}
                    onHomeworkClick={onHomeworkClick}
                />
            </div>
        </section>
    );
};

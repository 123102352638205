import { INavigationIconButton } from '@frontend-modules/elements';
import { INavigationMenuRoles } from '@fsd-shared/models/navigation';

const handleDownloadClick = (): Document => document.open('/anydesk', '_blank', 'noopener noreferrer');

const handleSmartBoardBtnClick = (): Document =>
    document.open('https://smartboard.og1.ru/', '_blank', 'noopener noreferrer');

const downloadAnyDeskButton = { icon: 'anydesk-l', onClick: handleDownloadClick };
const smartBoardButton = { icon: 'pencil-l', onClick: handleSmartBoardBtnClick };

export const getNavigationOptionsButtons = (roles: INavigationMenuRoles): Array<INavigationIconButton> => {
    const { isCurious } = roles;
    return isCurious ? [downloadAnyDeskButton] : [downloadAnyDeskButton, smartBoardButton];
};

import React from 'react';
import { emptyLessonsPreview } from 'assets';
import { AppText } from 'src-new/ui';

import './style.scss';
export const EmptyList: React.FC = () => {
    return (
        <div className={'empty-content'}>
            <AppText text={'Вы не записаны ни на один курс'} fontStyle={'H1'} />
            <div className={'empty-content__image'}>
                <img src={emptyLessonsPreview} alt="empty" />
            </div>
        </div>
    );
};

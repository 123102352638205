import { api, getApiRequest, patchApiRequest } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { UserRoles } from 'types/user';

export interface UserInfo {
    id: number;
    externalId?: string;
    firstName: string;
    lastName: string;
    patronymic: string;
    photoUrl: string;
    email?: string;
    userRoles: UserRoles[];
    academicYear: TAcademicYear;
    isAppUsed: boolean;
    datetimeStartUsedApp: null | string;
    isOlBlocked: boolean;
    tariff: number;
    tariffTitle: string;
    monitoringURL?: string;
    googleTableUrls?: string[];
}

export interface ChangePasswordRequestData {
    password: string;
    repPassword: string;
    oldPassword: string;
}
export type TAcademicYear = {
    id: number;
    title: string;
};
export type TChangeAcademicYearResponse = {
    id: number;
    academicYear: TAcademicYear;
};

/**
 * Fetch current user data
 * @returns user info
 */

export const getUserInfo = (): Promise<UserInfo> => {
    return api
        .get<Promise<UserInfo>, AxiosResponse<UserInfo>>(`/user/`)
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });
};

export const changePassword = (changePasswordRequestData: ChangePasswordRequestData): Promise<unknown> =>
    api
        .post<unknown>(`/user/change-password/`, changePasswordRequestData)
        .then((res) => res.data)
        .catch((err: AxiosError<Record<string, string>>) => {
            throw JSON.stringify(err.response?.data);
        });

export const changeAcademicYear = (yearId: number): Promise<TChangeAcademicYearResponse> => {
    const params = {
        academicYear: yearId,
    };
    return patchApiRequest('/user/partial-update/', params);
};

export const getCurrentTime = (): Promise<{ time: string }> => {
    return getApiRequest('/user/current-time/');
};

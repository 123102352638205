import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCourseDetails, patchThemesOrdering } from 'api/services/course';
import {
    deleteTheme,
    getThemes,
    patchTheme,
    postTheme,
    PostThemeParams,
    publishTheme,
    ResponseTheme,
} from 'api/services/courseThemes';

export const getThemesAction = createAsyncThunk('courseThemes/getThemes', async (courseId: number) => {
    return await getThemes(courseId);
});

export const publishThemeAction = createAsyncThunk('courseThemes/publishTheme', async (themeId: number) =>
    publishTheme(themeId),
);

export const createThemeAction = createAsyncThunk(
    'courseThemes/createTheme',
    async (params: { values: PostThemeParams & { order: number }; ordering: number[] }) => {
        return await postTheme(params.values)
            .then((newTheme) => {
                const newOrdering = [
                    ...params.ordering.slice(0, params.values.order),
                    newTheme.id,
                    ...params.ordering.slice(params.values.order),
                ];
                return patchThemesOrdering({
                    ordering: newOrdering,
                    courseId: params.values.course,
                });
            })
            .then(({ ordering }) => {
                return {
                    ordering,
                    newTheme: {
                        ...params.values,
                        id: ordering[params.values.order],
                    } as ResponseTheme,
                };
            });
    },
);

export const changeThemeAction = createAsyncThunk(
    'courseThemes/changeTheme',
    async (params: { theme: ResponseTheme; courseId: number; ordering: number[] }) => {
        const { theme, courseId, ordering } = params;
        return await patchTheme(theme)
            .then((updatedTheme) => {
                const orderingWithoutUpdatetedTheme = ordering.filter((themeId) => themeId !== updatedTheme.id);
                const newOrdering = [
                    ...orderingWithoutUpdatetedTheme.slice(0, theme.order),
                    updatedTheme.id,
                    ...orderingWithoutUpdatetedTheme.slice(theme.order),
                ];
                return patchThemesOrdering({
                    ordering: newOrdering,
                    courseId,
                });
            })
            .then(async ({ ordering }) => {
                const course = await getCourseDetails(courseId);

                return {
                    ordering,
                    course,
                };
            })
            .then(({ ordering, course }) => {
                return {
                    ordering,
                    course,
                    newTheme: theme,
                };
            });
    },
);

export const deleteThemeAction = createAsyncThunk(
    'courseThemes/deleteTheme',
    async (params: { themeId: number; ordering: number[]; courseId: number }) => {
        return await deleteTheme(params.themeId).then(() => patchThemesOrdering(params));
    },
);

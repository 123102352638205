import { patchApiRequest } from 'api';
import { ResponseEducationPlan } from 'types/educationalPlan';

import { PostEducationalPlan } from './postEducationalPlan';

export const patchEducationalPlan = ({
    id,
    ...params
}: PostEducationalPlan & { ordering: number[] }): Promise<ResponseEducationPlan> => {
    return patchApiRequest(`/educational-plan/update/${String(id)}/`, params);
};

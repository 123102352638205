import React from 'react';
import { AppButton, AppText } from '@frontend-modules/ui-kit';
import { Trash } from '@lms-elements/icons';
import { E_COLORS } from 'src-new/constants';

type TBarItemDescription = {
    orientation: 'vertical' | 'horizontal';
    isEmptyQuestion: boolean;
    questionNumber: number;
    isNoType: boolean;
    questionsCount: number;
    searchBlocksCount: number;
    handleQuestionClick: (num: number) => void;
    handleDeleteQuestion: (num: number) => void;
    isLastEmptyQuestion: boolean;
};
export const BarItemDescription: React.FC<TBarItemDescription> = (props: TBarItemDescription) => {
    const {
        orientation,
        isEmptyQuestion,
        questionNumber,
        isNoType,
        questionsCount,
        searchBlocksCount,
        isLastEmptyQuestion,
        handleDeleteQuestion,
        handleQuestionClick,
    } = props;

    return orientation === 'vertical' ? (
        <div className={'question-item'}>
            {isEmptyQuestion ? (
                <div className={'question-item__add-btn'}>
                    <AppButton
                        label={'Наполнить'}
                        type={'primary'}
                        disabled={questionNumber === 1 && isNoType}
                        onClick={handleQuestionClick.bind(this, questionNumber)}
                    />
                </div>
            ) : (
                <div className={'question-item__description'}>
                    <AppText
                        text={`${questionsCount} отобранных вопросов в списке`}
                        textStyle={'DesktopFontBody'}
                        color={E_COLORS.dark_grey_color_2}
                    />
                    <AppText
                        text={`${searchBlocksCount} поисков`}
                        textStyle={'DesktopFontBody'}
                        color={E_COLORS.dark_grey_color_2}
                    />
                </div>
            )}
            {/*Чтобы было невозможно удалить последний ПУСТОЙ вопрос*/}
            {!isLastEmptyQuestion && (
                <div className={'question-item__delete-btn'} onClick={handleDeleteQuestion.bind(this, questionNumber)}>
                    <Trash />
                </div>
            )}
        </div>
    ) : (
        <></>
    );
};

import { IInsertWordAnswerData, IInsertWordsCheckedAnswer } from '@fsd-shared/models/materials';

const INSERT_WORD_REPLACE = '<input type="insert-word" />';
const INSERT_WORD_REGEX = /(<p>).*(<\/p>)/gm;

const getQuestionParts = (question: string): Array<string> => {
    const result: string[] = [];
    let lastIndex = 0;

    const getQuestion = (text: string): Array<string> => {
        const questionPartExec = INSERT_WORD_REGEX.exec(text);

        if (questionPartExec !== null) {
            if (result.length === 0 && questionPartExec.index > 0) {
                result.push(`${question.slice(0, questionPartExec.index)}</br>`);
            }

            lastIndex = questionPartExec.index + questionPartExec[0].length;

            result.push(`${questionPartExec[0].slice(3, questionPartExec[0].length - 4)}</br>`);
            return getQuestion(text);
        }

        if (lastIndex > 0 && lastIndex !== question.length - 1) {
            result.push(`${question.slice(lastIndex, question.length)}`.replace('<p>', ''));
        }

        return result;
    };

    return getQuestion(question);
};

export const normalizeInsertWordQuestion = (question: string): Array<string | number> => {
    if (question?.length) {
        const questionParts = getQuestionParts(question);

        if (questionParts.length) {
            return questionParts
                .flatMap((questionPart) => questionPart.split(new RegExp(`(${INSERT_WORD_REPLACE})`)))
                .reduce<{ parts: (string | number)[]; count: number }>(
                    (accum, part) =>
                        part === INSERT_WORD_REPLACE
                            ? { parts: [...accum.parts, accum.count], count: accum.count + 1 }
                            : { parts: [...accum.parts, part], count: accum.count },
                    { parts: [], count: 0 },
                ).parts;
        }

        return question
            .split(new RegExp(`(${INSERT_WORD_REPLACE})`))
            .reduce<{ parts: (string | number)[]; count: number }>(
                (accum, part) =>
                    part === INSERT_WORD_REPLACE
                        ? { parts: [...accum.parts, accum.count], count: accum.count + 1 }
                        : { parts: [...accum.parts, part], count: accum.count },
                { parts: [], count: 0 },
            ).parts;
    }
    return [];
};

export const normalizeInsertWordCorrectAnswerData = (
    correctAnswer: IInsertWordsCheckedAnswer,
): IInsertWordAnswerData => {
    const { incomplete, isCorrect, data } = correctAnswer;

    return {
        incomplete,
        correctness: isCorrect ? 'correct' : 'incorrect',
        answerOptions: data.selectedOptions.map(({ selectedWord, isCorrect, gapIndex }) => ({
            selectedWord,
            correctness: isCorrect ? 'correct' : 'incorrect',
            gapIndex,
        })),
    };
};

import { ITrainingSearchBlockRequest, ITrainingSearchBlockResponse } from '@fsd-shared/models/materials';
import { IQuestion } from 'containers/QuestionSearchResult/QuestionSearchResult';
import { ISearchBlock } from 'containers/QuestionSearchResult/types';

import { convertPageFiltersToQueryFilters } from 'utils/searchBlocks';

const getUpdateSearchBlocksRequestParams = (
    searchBlocks?: ISearchBlock[],
    excludesQuestions?: Array<number>,
): ITrainingSearchBlockRequest['updateData'] => {
    return searchBlocks
        ?.map(({ filters, id }) => ({
            block: id,
            filters: convertPageFiltersToQueryFilters(filters || {}, excludesQuestions ?? []),
        }))
        ?.filter((item) => !!item?.block) as ITrainingSearchBlockRequest['updateData'];
};

const getCreateSearchBlocksRequestParams = (
    searchBlocks?: ISearchBlock[],
    singleQuestions?: IQuestion[],
    excludesIds?: Array<number>,
): ITrainingSearchBlockRequest['createData'] => {
    return searchBlocks
        ?.map(({ filters }) => ({
            filters: convertPageFiltersToQueryFilters(filters || {}, excludesIds ?? []),
        }))
        ?.concat(singleQuestions?.map((item) => ({ filters: { id: item.id } })) ?? []);
};

export const getSearchBlocksRequestParams = (
    rightSearchBlocks?: ISearchBlock[],
    singleQuestions?: IQuestion[],
    excludesQuestions?: Array<number>,
    initSearchBlocks?: Array<ITrainingSearchBlockResponse>,
): Omit<ITrainingSearchBlockRequest, 'training'> => {
    const initOnlySearchBlocks = initSearchBlocks?.filter((item) => !item?.single);
    const initSingleQuestions = initSearchBlocks?.filter((item) => item?.single);

    // если у блока нет id, значит он новый
    const createdSearchBlocks = rightSearchBlocks?.filter((item) => !item.id);

    // вытаскиваем id блоков которые сейчас выбраны
    const rightSearchBlocksIds = rightSearchBlocks?.filter((item) => item?.id)?.map((item) => item.id);

    // ищем какие элементы были при инициализации, но в выбранных нет, значит эти блоки нужно удалить
    // вытаскиваем id этих блоков
    const deletedSearchBlocksIds =
        initOnlySearchBlocks
            ?.filter((item) => item.id && !rightSearchBlocksIds?.includes(item?.id))
            ?.map((item) => item.id) || [];

    // ищем какие блоки были при инициализации и в выбранных есть, значит эти блоки нужно обновить
    // забираем их id
    const updatedSearchBlocksIds =
        initOnlySearchBlocks
            ?.filter((item) => item.id && rightSearchBlocksIds?.includes(item?.id))
            ?.map((item) => item.id) || [];

    // ищем блоки вопросов, которые нужно обновить, сравнивая их id со списком id блоков для обновления
    const updatedSearchBlocks = rightSearchBlocks?.filter(
        (item) => item?.id && updatedSearchBlocksIds?.includes(item?.id),
    );

    // получаем id единичных вопросов
    const singleQuestionsIds = singleQuestions?.map((item) => item.id);
    const initSingleQuestionsIds = initSingleQuestions?.map((item) => item.filters?.id);

    // ищем вопросы, которых не было при инициализации, но в выбранных есть, значит эти вопросы нужно создать
    const createSingleQuestions = singleQuestions?.filter(
        (item) => item.id && !initSingleQuestionsIds?.includes(item.id),
    );

    // ищем id блоков, которые были при инициализации, но в выбранных нет, значит эти вопросы нужно удалить
    const deleteQuestionIds =
        initSingleQuestions
            ?.filter((item) => item?.filters?.id && !singleQuestionsIds?.includes(item.filters.id))
            .map((item) => item.id) || [];

    const excludesSingleQuestionsIds = singleQuestionsIds?.filter((item) => !deleteQuestionIds.includes(item));

    const excludesIds = [...(excludesSingleQuestionsIds ?? []), ...(excludesQuestions ?? [])];

    return {
        createData: getCreateSearchBlocksRequestParams(createdSearchBlocks, createSingleQuestions, excludesIds),
        updateData: getUpdateSearchBlocksRequestParams(updatedSearchBlocks, excludesIds),
        deleteData: [...deletedSearchBlocksIds, ...deleteQuestionIds],
    };
};

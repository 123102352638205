import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { createTransform } from 'redux-persist';
import { fetchLogin, fetchLogout, setIsLoggedInAction } from 'store/actions/auth';
import { getUserInfoAction } from 'store/actions/user';
import { FetchStatus } from 'types/api';

import { setAccessToken, setRefreshToken } from 'utils/token';

export interface Answer {
    response: string;
}

export interface AuthState {
    isLoggedIn?: boolean;
    fetchStatus: FetchStatus;
    reAuthEmail?: string;
    error: unknown;
    logoutFetchStatus: FetchStatus;
}

const initialState: AuthState = {
    fetchStatus: FetchStatus.INITIAL,
    error: null,
    logoutFetchStatus: FetchStatus.INITIAL,
};

const authSlice = createSlice<AuthState, SliceCaseReducers<AuthState>>({
    name: 'auth',
    initialState,
    reducers: {
        resetAuthErrors(state) {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setIsLoggedInAction, (state, { payload }) => {
            if (!payload) {
                state.reAuthEmail = undefined;
            }
            state.fetchStatus = FetchStatus.FETCHED;
            state.isLoggedIn = payload;
        });
        // сохраняем емейл для переавторизации при login as
        builder.addCase(getUserInfoAction.fulfilled, (state, { payload }) => {
            state.reAuthEmail = payload.email;
        });
        builder.addCase(fetchLogin.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.isLoggedIn = true;
            setAccessToken(payload?.access || '');
            setRefreshToken(payload?.refresh || '');
            state.logoutFetchStatus = FetchStatus.INITIAL;
            state.error = null;
        });
        builder.addCase(fetchLogin.rejected, (state, { error }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.error = error;
        });
        builder
            .addCase(fetchLogout.fulfilled, (state) => {
                state.fetchStatus = FetchStatus.INITIAL;
                state.isLoggedIn = false;
                state.fetchStatus = FetchStatus.INITIAL;
                state.reAuthEmail = undefined;
                state.error = null;
                state.logoutFetchStatus = FetchStatus.FETCHED;
            })
            .addCase(fetchLogout.pending, (state) => {
                state.logoutFetchStatus = FetchStatus.FETCHING;
            })
            .addCase(fetchLogout.rejected, (state) => {
                state.logoutFetchStatus = FetchStatus.ERROR;
            });
    },
});
export const { resetAuthErrors } = authSlice.actions;

export const authBlackList = createTransform(
    null,
    (state: AuthState) => {
        const newState = state ?? {};

        if ('fetchStatus' in newState) {
            newState.fetchStatus = FetchStatus.INITIAL;
        }
        if ('logoutFetchStatus' in newState) {
            newState.logoutFetchStatus = FetchStatus.INITIAL;
        }

        return newState;
    },
    { whitelist: ['auth'] },
);
export const authReducer = authSlice.reducer;

import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { loadMetaAction, loadMetaCatalogAction, loadMoreMetaCatalogAction } from 'store/actions/meta';
import { FetchStatus } from 'types/api';
import { ICatalog, IEntitiesConfig } from 'types/meta';

export interface IMetaState {
    fetchStatus: FetchStatus;
    entities?: {
        actions?: IEntitiesConfig;
    };
    catalog: {
        api: ICatalog;
    };
    errors: unknown;
}
export const initialState: IMetaState = {
    fetchStatus: FetchStatus.INITIAL,
    errors: [],
    entities: {},
    catalog: {
        api: {},
    },
};

const metaSlice = createSlice<IMetaState, SliceCaseReducers<IMetaState>>({
    name: 'meta',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loadMetaAction.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.errors = [];
        });
        builder.addCase(loadMetaAction.fulfilled, (state, { payload }) => {
            state.fetchStatus = FetchStatus.FETCHED;
            state.entities = payload;
            state.errors = [];
        });
        builder.addCase(loadMetaAction.rejected, (state, { error }) => {
            state.fetchStatus = FetchStatus.ERROR;
            state.errors = error;
        });
        builder.addCase(loadMetaCatalogAction.pending, (state, { meta }) => {
            const payload = meta?.arg;
            state.catalog = {
                ...state.catalog,
                api: {
                    ...state?.catalog?.api,
                    [payload?.catalogData?.url]: {
                        ...state?.catalog?.api?.[payload?.catalogData?.url],
                        fetchStatus: FetchStatus.FETCHING,
                    },
                },
            };
        });
        builder.addCase(loadMetaCatalogAction.fulfilled, (state, { payload }) => {
            state.catalog = {
                ...state.catalog,
                api: {
                    ...state?.catalog?.api,
                    [payload.url]: {
                        list: payload.list,
                        pagination: payload.pagination,
                        fetchStatus: FetchStatus.FETCHED,
                    },
                },
            };
        });
        builder.addCase(loadMetaCatalogAction.rejected, (state, { meta }) => {
            const payload = meta.arg;
            state.catalog = {
                ...state.catalog,
                api: {
                    ...state?.catalog?.api,
                    [payload?.catalogData?.url]: {
                        ...state?.catalog?.api?.[payload?.catalogData?.url],
                        fetchStatus: FetchStatus.ERROR,
                    },
                },
            };
        });

        builder.addCase(loadMoreMetaCatalogAction.pending, (state, { meta }) => {
            const payload = meta?.arg;
            state.catalog = {
                ...state.catalog,
                api: {
                    ...state?.catalog?.api,
                    [payload?.catalogData?.url]: {
                        ...state?.catalog?.api?.[payload?.catalogData?.url],
                        fetchStatus: FetchStatus.FETCHING_MORE,
                    },
                },
            };
        });
        builder.addCase(loadMoreMetaCatalogAction.fulfilled, (state, { payload }) => {
            state.catalog = {
                ...state.catalog,
                api: {
                    ...state?.catalog?.api,
                    [payload.url]: {
                        list: [...(state?.catalog?.api?.[payload.url]?.list || []), ...payload.list],
                        pagination: payload.pagination,
                        fetchStatus: FetchStatus.FETCHED,
                    },
                },
            };
        });
        builder.addCase(loadMoreMetaCatalogAction.rejected, (state, { meta }) => {
            const payload = meta.arg;
            state.catalog = {
                ...state.catalog,
                api: {
                    ...state?.catalog?.api,
                    [payload?.catalogData?.url]: {
                        ...state?.catalog?.api?.[payload?.catalogData?.url],
                        fetchStatus: FetchStatus.ERROR,
                    },
                },
            };
        });
    },
});

export const metaReducer = metaSlice.reducer;

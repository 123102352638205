import React, { FC, useEffect, useState } from 'react';
import { Modal } from 'antd';

import { IAppModalProps } from './AppModal.types';

const AppModal: FC<IAppModalProps> = (props) => {
    const { isShow, title, children, footer = null, ...rest } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        setIsModalOpen(isShow);
    }, [isShow]);

    const setShow = (state: boolean) => {
        setIsModalOpen(state);
    };
    const onCancel = () => {
        setShow?.(false);
    };

    return (
        <Modal footer={footer} {...props} onCancel={onCancel} open={isModalOpen} title={title || ''} {...rest}>
            {children}
        </Modal>
    );
};

export default AppModal;

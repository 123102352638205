import React, { FC } from 'react';
import { cn } from '@bem-react/classname';
import { BigOgLogoSvg, ContentLoaderSvg } from '@fsd-shared/assets';

import { IContentLoaderProps } from './ContentLoader.types';

import './ContentLoader.styles.scss';

const CnContentLoader = cn('loaders-content-loader');
export const ContentLoader: FC<IContentLoaderProps> = (props) => {
    const { height } = props;
    return (
        <div className={CnContentLoader()} style={{ height }}>
            <div className={CnContentLoader('animation')}>
                <ContentLoaderSvg />
            </div>
            <div className={CnContentLoader('logo')}>
                <BigOgLogoSvg />
            </div>
        </div>
    );
};

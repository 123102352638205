import { ResponseCourse } from 'api/services/course';
import { CourseGroup } from 'types/courseGroup';
import { TeacherCourse } from 'types/subject';

export const normalizeTeacherCourses = (courseGroups: CourseGroup[], courseData: ResponseCourse[]): TeacherCourse[] => {
    return courseData.map((course) => {
        const groups = courseGroups.filter((group) => group.course.id === course.id);
        return {
            id: course.id,
            title: course.title,
            canEdit: true, // is teacher;
            canFill: false, // is teacher;
            topicsQuantity: course.topicsQuantity ?? 0,
            lessonsQuantity: course.lessonsQuantity ?? 0,
            studentsQuantity: groups.reduce((quantity, { studentsQuantity }) => quantity + studentsQuantity, 0),
            studentGroups: groups.map((group) => {
                return {
                    id: group.id,
                    groupTitle: group.title,
                    studentsQuantity: group.studentsQuantity,
                    assignmentQuantity: group.progressesQuantity ?? 0,
                    nextAssignmentDeadline: group.nextProgressDeadline,
                    teacher: group.teachers[0],
                    courseAssignmentId: group.id,
                    lessonsQuantity: group.lessonsQuantity,
                };
            }),
        };
    });
};

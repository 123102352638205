import React, { useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';
import { useAppSelector } from 'store/store';

import { Loader } from 'components/Loader';

import './PageLoader.scss';

const CnPageLoader = cn('pageLoader');

export const PageLoader: React.FC<{ showLoading?: boolean; zIndex?: number }> = React.memo(
    ({ showLoading = true, zIndex }) => {
        const { isLoading } = useAppSelector((store) => store.loader);

        const [isShow, setIsShow] = useState(true);

        useEffect(() => {
            setIsShow(true);

            const setTimeoutId = setTimeout(() => {
                setIsShow(false);
            }, 1000);

            return () => {
                clearTimeout(setTimeoutId);
            };
        }, [isLoading, showLoading]);

        if ((isShow || isLoading) && showLoading) {
            return (
                <div className={CnPageLoader()} style={{ zIndex }}>
                    <Loader />
                </div>
            );
        }

        return null;
    },
);

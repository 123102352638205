import { INSERT_WORD_REPLACE } from '@lms-elements/custom-editor';

interface IParsedQuestionContent {
    blocks: {
        text: string;
    }[];
    entityMap: {
        data: {
            text: string;
            options: string[];
        };
    }[];
}

export const getInsertWordsPreviewHtml = (content?: string, html?: string, text?: string): string => {
    if (content && html) {
        const parsedContent = JSON.parse(content) as IParsedQuestionContent;

        return Object.values(parsedContent.entityMap).reduce(
            (htmlAccumulator, entity) =>
                htmlAccumulator.replace(
                    INSERT_WORD_REPLACE,
                    `<input style="text-align: center; margin: 0 2px; padding-block-end: 3px; margin-block-start: 3px; border: 1px solid var(--color-blue-border); border-radius: 4px;" type="text" disabled value="${entity.data.text}" />`,
                ),
            html || '',
        );
    }

    return text || '';
};

import { IPostMaterial, TMaterialType } from 'api/services/materials';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { ContentMaterialTypeEnum, IMaterial } from 'types/materials';

export const getMaterialsDataForPost = (
    material: IMaterial,
    lesson: number,
    isEditor: boolean,
    materialType: ContentMaterialTypeEnum,
    type: TMaterialType,
    markup?: string,
): IPostMaterial => {
    let content = '.';
    let urls: { name: string; url: string }[] = [];
    let question: number | undefined = undefined;

    if (materialType === ContentMaterialTypeEnum.document || Array.isArray(material.documentData)) {
        urls = material.documentData
            .map((document) => {
                if (typeof document === 'string') {
                    return { name: document, url: document };
                }

                if (!(document instanceof File)) {
                    return document;
                }
            })
            .filter(Boolean) as { name: string; url: string }[];
    }

    switch (materialType) {
        case ContentMaterialTypeEnum.text:
            if (isEditor) {
                content = JSON.stringify(convertToRaw((material.textData as EditorState).getCurrentContent()));
            } else {
                content = material.textData as string;
            }
            break;
        case ContentMaterialTypeEnum.audio:
            content = JSON.stringify({
                url: typeof material.audioData === 'string' ? material.audioData : material.url,
                songName: material.audioData.songName || (material.audioData.file as File)?.name,
            });
            break;
        case ContentMaterialTypeEnum.video:
        case ContentMaterialTypeEnum.kinescope:
            content = JSON.stringify({
                url: typeof material.videoData.url === 'string' ? material.videoData.url : '',
            });
            break;
        case ContentMaterialTypeEnum.photo:
            content = JSON.stringify({
                data: material.imageData?.data ?? [],
                description: material.imageData.description || '',
            });
            break;
        case ContentMaterialTypeEnum.document:
            content = JSON.stringify({
                data: urls,
            });
            break;
        case ContentMaterialTypeEnum.header:
            content = JSON.stringify(material.headerData);
            break;
        case ContentMaterialTypeEnum.table:
            content = JSON.stringify({
                ...material.tableData,
                table: {
                    ...material.tableData.table,
                    table: material.tableData.table.table?.map((row) =>
                        row.map((cell) =>
                            typeof cell === 'string'
                                ? convertToRaw(ContentState.createFromText(cell))
                                : convertToRaw(cell?.getCurrentContent()),
                        ),
                    ),
                    editorBuffer: convertToRaw(
                        typeof material.tableData.table.editorBuffer === 'string'
                            ? ContentState.createFromText(material.tableData.table.editorBuffer)
                            : material.tableData.table.editorBuffer.getCurrentContent(),
                    ),
                },
            });
            break;
        case ContentMaterialTypeEnum.exercise:
            content = JSON.stringify(material.exerciseData.questionId || '');
            question = material.exerciseData.questionId;
            break;
    }

    const typeOfContent =
        material.type === ContentMaterialTypeEnum.kinescope ? ContentMaterialTypeEnum.video : material.type;

    return {
        lesson,
        typeOfContent,
        content,
        html: materialType === ContentMaterialTypeEnum.text ? markup : '.',
        question,
        type,
    };
};

import { Notification } from 'api/services/notifications';
import { ViewedNotificationWithExtraInfo } from 'types/notifications';

import { normalizeNotification } from './normalizeNotification';

export const normalizeViewedNotifications = (notifications: Notification[]): ViewedNotificationWithExtraInfo[] => {
    const baseNormalizedNotifications: ViewedNotificationWithExtraInfo[] = [];

    notifications.forEach((notification) => {
        const normalizedNotification = normalizeNotification(notification);
        if (normalizedNotification) {
            baseNormalizedNotifications.push({
                ...normalizedNotification,
                lessonId: notification.data.lesson,
                subjectId: notification.data.subject.id,
                courseGroupId: notification.data.courseGroup?.id,
                type: notification.type,
            });
        }
    });

    return baseNormalizedNotifications;
};

import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { cn } from '@bem-react/classname';
import { DeferredHomeworkIcon, HwProcess, Passed } from '@lms-elements/icons';
import { AssignmentProgressStatus } from 'types/assignmentProgress';

import { AttendanceMark } from 'components/AttendanceMark';
import { checkOverdue, getDateWithTime, getTimeAfterDate, getTimeBeforeDate } from 'utils/date';

import { IStatusCardProps } from './StatusCard.types';

import './StatusCard.scss';

const CnInfoStatus = cn('infoStatus');

export const StatusCard: React.FC<IStatusCardProps> = ({
    id,
    title,
    passed = '',
    isPassed,
    marks,
    onMarkDelete,
    onMarkAdd,
    onMarkChange,
    isSelected = false,
    deadline = '',
    onCardClick,
    isStudent,
    onCancel,
    completed,
    onAcademicPerfomance,
    status,
    assignedDate = '',
    extraInfo,
    showStatusLabel = true,
}) => {
    const needMainTitle = title?.includes('основное');
    const overdued = useMemo(() => checkOverdue(deadline), [deadline]);
    const showOverdued = showStatusLabel && overdued;
    const checked = completed && Boolean(marks?.length);

    const handleCardClick = useCallback(() => {
        if (onCardClick) {
            onCardClick(id);
        }
    }, [onCardClick, id]);

    const statusLabel = useMemo(() => {
        if (!checked && passed) {
            const timePassed = getDateWithTime(passed);
            return `Сдал ${timePassed}`;
        }

        if (!checked && overdued) {
            const overdueTime = getTimeAfterDate(deadline);
            return `Просрочено на ${overdueTime}`;
        }

        if (!checked) {
            const beforeDeadlineTime = getTimeBeforeDate(deadline);
            return `До сдачи ${beforeDeadlineTime}`;
        }

        return '';
    }, [passed, checked, overdued, deadline, showStatusLabel]);

    const handleCreateMarkSubmit = useCallback(
        (marks: { selectedMark?: number; comment?: string }[]) => {
            onMarkAdd?.(
                marks.map((mark) => ({
                    score: mark.selectedMark,
                    comment: mark.comment,
                    assignmentProgress: id,
                })),
            );
        },
        [id, onMarkAdd],
    );

    const handleChangeMarkSubmit = useCallback(
        (
            newMarks: {
                selectedMark?: number;
                comment?: string;
                markId: number;
                valueCanChange?: boolean;
                teacherMark?: boolean;
                attemptMarkNumber?: number;
            }[],
        ) => {
            onMarkChange?.(
                newMarks.map((mark) => ({
                    id: Number(mark.markId),
                    score: mark.selectedMark,
                    comment: mark.comment,
                    valueCanChange: mark.valueCanChange,
                    teacherMark: mark.teacherMark,
                    attemptMarkNumber: mark.attemptMarkNumber,
                })),
            );
        },
        [onMarkChange],
    );

    const statusCardRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (statusCardRef.current) {
            statusCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
    }, [isSelected]);

    return (
        <div
            ref={isSelected ? statusCardRef : undefined}
            className={CnInfoStatus({ selected: isSelected, over: showOverdued && overdued && isSelected })}
            onClick={handleCardClick}
        >
            {needMainTitle && <div className={CnInfoStatus('titleMain')}>Выданые</div>}
            <div
                className={CnInfoStatus('title', { notMain: !needMainTitle, extraInfo: Boolean(extraInfo) })}
                title={title}
            >
                {title}
            </div>
            {extraInfo && (
                <div className={CnInfoStatus('extraInfo')}>
                    {extraInfo?.IconElement}
                    {extraInfo?.label}
                </div>
            )}
            <div className={CnInfoStatus('status')}>
                <div className={CnInfoStatus('icon', { mark: Boolean(marks?.length) })}>
                    {checked && marks ? (
                        <AttendanceMark
                            marks={marks}
                            submitCreateMark={handleCreateMarkSubmit}
                            submitChangeMark={handleChangeMarkSubmit}
                            onDeleteMark={onMarkDelete}
                            isStudent={isStudent}
                            onCancel={onCancel}
                            onAcademicPerfomance={onAcademicPerfomance}
                            statusCard
                        />
                    ) : isPassed ? (
                        <Passed />
                    ) : status === AssignmentProgressStatus.DEFERRED_ASSIGNMENT ? (
                        <div
                            className={CnInfoStatus('color', { over: showOverdued && overdued })}
                            title={`Будет выдано ${getDateWithTime(assignedDate)}`}
                        >
                            <DeferredHomeworkIcon />
                        </div>
                    ) : (
                        <div className={CnInfoStatus('color', { over: showOverdued && overdued })}>
                            {!!showStatusLabel && <HwProcess />}
                        </div>
                    )}
                </div>
                {statusLabel && showStatusLabel && <div className={CnInfoStatus('timePassed')}>{statusLabel}</div>}
            </div>
        </div>
    );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getLessonAttendance } from 'api/services/attendance';
import { getLessonSchedule } from 'api/services/lessonSchedule';
import { getEventRooms } from 'api/services/proctoring';
import { EventTypes } from 'types/events';

type getLessonScheduleAndAttendanceDataParams = {
    courseGroupId: number;
    lessonIds: number[];
    studentIds: number[];
};

export const getLessonScheduleAndAttendanceData = createAsyncThunk(
    'academicPerformance/getLessonScheduleAndAttendance',
    ({ courseGroupId, lessonIds, studentIds }: getLessonScheduleAndAttendanceDataParams) => {
        return Promise.all([
            getLessonSchedule(lessonIds, courseGroupId),
            getLessonAttendance({ lessonIds, studentIds }),
            getEventRooms({ lesson_ids: lessonIds.join(','), event_type: EventTypes.TEST }),
        ]).then(([lessonSchedule, lessonAttendance, eventRooms]) => ({
            lessonIds,
            lessonSchedule,
            lessonAttendance,
            studentIds,
            eventRooms,
        }));
    },
);

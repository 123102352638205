import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
    getCourseGroupDetails,
    getCourseGroups,
    getCourseGroupsByAcademicYear,
    getCourseGroupsByCourse,
    getCourseGroupsBySubjectAndYear,
    getCourseGroupStudents,
    getPeriods,
    getTopics,
    patchCourseGroups,
} from 'api/services/courseGroups';
import { getCourseGroupExpelledStudents } from 'api/services/courseGroups/getCourseGroupExplelledStudents';
import { getGroupStatistics } from 'api/services/courseGroups/getGroupStatistics';
import { getStudentCourseGroups } from 'api/services/courseGroups/getStudentCourseGroups';
import { Topic } from 'api/services/courseGroups/getTopics';
import { PatchCourseGroupsParams } from 'api/services/courseGroups/patchCourseGroups';
import { getAllEducationalPlans, getEducationalPlan } from 'api/services/educationalPlan';
import moment from 'moment';

export const getCourseGroupDetailsAction = createAsyncThunk(
    'courseGroup/getCourseGroupDetails',
    async (courseGroupId: number) => {
        return await getCourseGroupDetails(courseGroupId);
    },
);

export const getPeriodsAction = createAsyncThunk('courseGroup/getPeriods', async (groupId: string) => {
    return await getPeriods(groupId).then((period) =>
        period.period.sort((a, b) => moment(a.dateStart).diff(b.dateStart)),
    );
});

export const getCourseGroupExpelledStudentsAction = createAsyncThunk(
    'courseGroup/expelledStudents',
    async ({ courseGroupId, lessonId }: { courseGroupId: number; lessonId: number }) => {
        return await getCourseGroupExpelledStudents({ courseGroupId, lessonId });
    },
);

export const getTopicsAction = createAsyncThunk(
    'courseGroup/getTopics',
    async (params: { groupId: number; dateStart: string; dateEnd: string }) => {
        return await getTopics(params);
    },
);

export const getCourseGroupsByAcademicYearAction = createAsyncThunk(
    'courseGroup/getCourseGroupsByAcademicYear',
    async (year: number) => {
        return await getCourseGroupsByAcademicYear(year);
    },
);

export const getCourseGroupsBySubjectAndYearAction = createAsyncThunk(
    'courseGroup/getCourseGroupsBySubjectAndYear',
    async (params: { subject: number; year: number }) => {
        return await getCourseGroupsBySubjectAndYear(params);
    },
);

export const getCourseGroupDetailsWithStudentsAction = createAsyncThunk(
    'courseGroup/getCourseGroupDetailsWithStudents',
    async (courseGroupId: number) => {
        return await Promise.all([getCourseGroupDetails(courseGroupId), getCourseGroupStudents(courseGroupId)]);
    },
);

export const getCourseGroupsAction = createAsyncThunk(
    'courseGroup/getCourseGroups',
    async (params: { course?: number } = {}) => {
        return await getCourseGroups(params);
    },
);

export const getCourseGroupStudentsAction = createAsyncThunk(
    'courseGroup/getCourseGroupStudents',
    async (courseGroupId: number) => {
        return await getCourseGroupStudents(courseGroupId);
    },
);

export const getCourseGroupsByCourseAction = createAsyncThunk(
    'courseGroup/getCourseGroupsByCourse',
    async (courseId: number) => {
        return await getCourseGroupsByCourse(courseId);
    },
);

export const patchCourseGroupsAction = createAsyncThunk('courseGroup/update', async (data: PatchCourseGroupsParams) => {
    return await patchCourseGroups(data);
});

export const getAllEducationalPlanWithCourseGroupsAction = createAsyncThunk(
    'courseGroup/getAllEducationalPlanWithCourseGroups',
    async () => {
        return await Promise.all([getCourseGroups(), getAllEducationalPlans()]);
    },
);

export const getEducationalPlanWithCourseGroupsAction = createAsyncThunk(
    'courseGroup/getEducationalPlanWithCourseGroups',
    async (params: { studentId: number; planId: number }) => {
        return await Promise.all([getStudentCourseGroups(String(params.studentId)), getEducationalPlan(params.planId)]);
    },
);

export interface PeriodTopics {
    id: number;
    dateStart: string;
    dateEnd: string;
    topics: Topic[];
}

export const getPeriodTopicsAction = createAsyncThunk('courseGroup/getPeriodTopics', async (groupId: number) => {
    return getPeriods(String(groupId)).then(async (period) => {
        return await Promise.all(
            period.period
                .sort((a, b) => moment(a.dateStart).diff(b.dateStart))
                .map(async (period) => {
                    return {
                        id: period.id,
                        dateStart: period.dateStart,
                        dateEnd: period.dateEnd,
                        topics: await getTopics({
                            groupId: groupId,
                            dateStart: period.dateStart,
                            dateEnd: period.dateEnd,
                        }),
                        courseGroup: groupId,
                    };
                }),
        );
    });
});
export const resetCourseGroupDataAction = createAction('courseGroup/resetCourseGroup', () => {
    return { payload: undefined };
});
export const getPeriodTopicsWithStudentAction = createAsyncThunk(
    'courseGroup/getPeriodTopicsWithStudents',
    async (groupId: number) => {
        const periods = await getPeriods(String(groupId)).then((periods) => {
            return { ...periods, period: periods.period.sort((a, b) => moment(a.dateStart).diff(b.dateStart)) };
        });

        return await Promise.all([
            await Promise.all(
                periods.period
                    .sort((a, b) => moment(a.dateStart).diff(b.dateStart))
                    .map(async (period) => {
                        return {
                            id: period.id,
                            dateStart: period.dateStart,
                            dateEnd: period.dateEnd,
                            topics: await getTopics({
                                groupId: groupId,
                                dateStart: period.dateStart,
                                dateEnd: period.dateEnd,
                            }),
                        };
                    }),
            ),
            periods.period,
            getCourseGroupStudents(groupId),
        ]).then(([periodTopics, periods, students]) => ({
            periodTopics,
            periods,
            students,
            groupId,
        }));
    },
);

export const getGroupStatisticsAction = createAsyncThunk(
    'courseGroup/getCourseGroupStatistics',
    async (courseGroupId: number) => {
        return await getGroupStatistics(courseGroupId);
    },
);
